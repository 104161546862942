import {
  Button,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImagePicker from '../ImagePicker';
import ImagePickerBlue from '../ImagePicker/ImagePickerBlue';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNewDomain, editDomain, getDomain } from '../../actions/domain';
import Loader from '../Loader';
import { useEffect } from 'react';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FieldItem,
  FormWrapper,
  TagsForm,
  FormControl,
} from './StyledComponents';

const CreateNewDomain = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isCreatingDomain, list, isEditingDomain } = useSelector(
    (state) => state.domain,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [domainImg, setDomainImg] = useState('');
  const [domainBlueImg, setDomainBlueImg] = useState('');
  const selectedDomain = list?.find((domain) => domain.id === params.domainId);
  useEffect(() => {
    dispatch(getDomain());
  }, []);

  useEffect(() => {
    if (params.domainId) {
      reset({
        name: selectedDomain.name,
        description: selectedDomain.description,
        approval_status: selectedDomain.approval_status,
        type: selectedDomain.type,
      });
      setDomainImg(selectedDomain.img_url);
      setDomainBlueImg(selectedDomain.img_url_blue);
    }
  }, [params.domainId]);

  const onFileLoad = (file) => {
    setDomainImg(file);
  };
  const onBlueFileLoad = (file) => {
    setDomainBlueImg(file);
  };
  const onSubmitHandler = (data) => {
    let domainFormData = new FormData();
    domainFormData.append('name', data.name);
    domainFormData.append('description', data.description);
    domainFormData.append('img_url', domainImg);
    domainFormData.append('img_url_blue', domainBlueImg);
    domainFormData.append('approval_status', data.approval_status);
    domainFormData.append('type', data.type);
    if (params.domainId) {
      dispatch(editDomain(domainFormData, params.domainId));
    } else {
      dispatch(createNewDomain(domainFormData));
    }
    setTimeout(() => {
      navigate('/l/domainList');
    }, 3000);
  };
  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.domainId ? 'Create Domain' : 'Edit Domain'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('name', {
                        required: 'Please enter product name',
                      })}
                      name="name"
                      label={'Name'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('description', {
                        required: 'Please enter product description',
                      })}
                      name="description"
                      label={'Description'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('approval_status', {
                        required: 'Please select a status',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Approval Status"
                      error={!!errors.approval_status}
                      defaultValue={
                        params.domainId
                          ? selectedDomain?.approval_status
                          : 'Accepted'
                      }
                    >
                      <MenuItem value="Accepted" key="Accepted">
                        Accepted
                      </MenuItem>
                      <MenuItem value="Pending" key="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem value="Rejected" key="Rejected">
                        Rejected
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('type', {
                        required: 'Please select a type',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Type"
                      error={!!errors.type}
                      defaultValue={
                        params.domainId ? selectedDomain?.type : 'Subject'
                      }
                    >
                      <MenuItem value="Subject" key="Subject">
                        Subject
                      </MenuItem>
                      <MenuItem value="Exam" key="Exam">
                        Exam
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <label htmlFor="">Icon</label>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImagePicker
                        onFileLoad={onFileLoad}
                        imageUrl={domainImg}
                      />
                    </div>
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <label htmlFor="">Icon Blue</label>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImagePickerBlue
                        onFileLoad={onBlueFileLoad}
                        imageUrl={domainBlueImg}
                      />
                    </div>
                  </FieldItem>
                </FormControl>

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isCreatingDomain || isEditingDomain}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isCreatingDomain || isEditingDomain ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default CreateNewDomain;
