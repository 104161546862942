import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

const initialState = {
  data: {},
  statistics: null,
  questionList: [],
  reportedQuestionsList: [],
  reviewQuestionsList: [],
  questionPaginationInfo: {
    currentPage: 0,
    pageSize: 0,
    totalPages: 1,
  },
  reportedQuestionsPaginationInfo: {
    currentPage: 0,
    pageSize: 0,
    totalPages: 1,
  },
  reviewQuestionsPaginationInfo: {
    currentPage: 0,
    pageSize: 0,
    totalPages: 1,
  },
  streak: {},
  isSkipped: false,
  isDeleteFail: false,
  isDeleteSuccess: false,
  isTimeOver: false,
  isTimeout: false,
  questionById: {},
  isPostNewSolutionSuccess: false,
  isPostNewSolutionFail: false,
  purchasedTime: 0,
  unansweredQuestion: [],
  newQuestion: {},
  imageURL: '',
  votingReportQuestion: {},
  votedReportQuestion: [],
  isPostVotingReportQuestion: false,
  publicQuestionData: {},
  examAnalytics: {},
  isUnlockQuestionSuccess: false,
  isUnlockQuestionFail: false,
  createQuestionType: 'OneWord',
  isfetchingQuestionStats: false,
  isStatsFetched: false,
  milestone: {},
  isFetchingMilestone: false,
  isMilestoneFetched: false,
  autoReveal: true,
  autoUnlockSolution: false,
  showCorrectAnswerOnSkip: true,
  purchaseTimeWithoutPopup: false,
  likedCount: null,
  isPutLikeSuccess: false,
};

export const question = createReducer(initialState, {
  [types.RESET_QUESTIONS]() {
    return Object.assign({}, initialState);
  },
  [types.CREATE_QUESTION_TYPE](state, action) {
    const newState = Object.assign({}, state, {
      createQuestionType: action.data,
      isDeleteFail: false,
      isDeleteSuccess: false,
    });

    return newState;
  },
  [types.POST_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isPostQuestionSuccess: false,
      isPostQuestionFail: false,
    });

    return newState;
  },
  [types.POST_QUESTION_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isPostQuestionSuccess: true,
      newQuestion: action.data,
    });

    return newState;
  },
  [types.GET_PUBLIC_QUESTION_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      publicQuestionData: action.payload,
    });
    return newState;
  },
  [types.GET_PUBLIC_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      publicQuestionTitle: '',
      publicQuestionList: [],
      publicRelatedQuestionSet: [],
      publicLiveCompetition: [],
    });
    return newState;
  },
  [types.POST_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isPostQuestionFail: true,
    });

    return newState;
  },

  [types.GET_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isQuestionFetching: true,
      isPutLikeSuccess: false,
    });

    return newState;
  },
  [types.GET_QUESTION_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isQuestionFetching: false,
      data: action.data || {},
      isAnswerSubmit: null,
      purchasedTime: action.data.purchased_time || 0,
      answerStatus: {},
    });

    if (action.data?.id) {
      // clear old question attempt data
      newState.isAnswerSuccess = false;
    }
    return newState;
  },
  [types.GET_QUESTION_FAIL](state, action) {
    const newState = Object.assign({}, state, {
      isQuestionFetching: false,
      questionFetchFail: true,
      error: action.data,
      data: action.data || {},
    });

    return newState;
  },

  [types.GET_USER_MILESTONE_START](state) {
    const newState = Object.assign({}, state, {
      isFetchingMilestone: true,
    });

    return newState;
  },

  [types.CHANGE_AUTOREVEAL_STATUS_ON](state) {
    const newState = Object.assign({}, state, {
      autoReveal: true,
    });

    return newState;
  },

  [types.CHANGE_AUTOREVEAL_STATUS_OFF](state) {
    const newState = Object.assign({}, state, {
      autoReveal: false,
    });

    return newState;
  },

  [types.CHANGE_UNLOCKSOL_STATUS_ON](state) {
    const newState = Object.assign({}, state, {
      autoUnlockSolution: true,
    });

    return newState;
  },

  [types.CHANGE_UNLOCKSOL_STATUS_OFF](state) {
    const newState = Object.assign({}, state, {
      autoUnlockSolution: false,
    });

    return newState;
  },

  [types.CORRECT_ANSWER_ON_SKIP_ON](state) {
    const newState = Object.assign({}, state, {
      showCorrectAnswerOnSkip: true,
    });

    return newState;
  },

  [types.CORRECT_ANSWER_ON_SKIP_OFF](state) {
    const newState = Object.assign({}, state, {
      showCorrectAnswerOnSkip: false,
    });

    return newState;
  },
  [types.PURCHASE_TIME_WITH_POPUP_STATUS_ON](state) {
    const newState = Object.assign({}, state, {
      purchaseTimeWithoutPopup: true,
    });

    return newState;
  },

  [types.PURCHASE_TIME_WITH_POPUP_STATUS_OFF](state) {
    const newState = Object.assign({}, state, {
      purchaseTimeWithoutPopup: false,
    });

    return newState;
  },
  [types.GET_USER_MILESTONE_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isFetchingMilestone: false,
      isMilestoneFetched: true,
      milestone: action.data || {},
    });

    return newState;
  },
  [types.GET_USER_MILESTONE_FAIL](state, action) {
    const newState = Object.assign({}, state, {
      isFetchingMilestone: false,
      isMilestoneFetched: false,
      error: action.data,
      milestone: action.data || {},
    });

    return newState;
  },

  [types.GET_QUESTION_STATS_START](state) {
    const newState = Object.assign({}, state, {
      isfetchingQuestionStats: true,
      // clear old question attempt data
      isAnswerSuccess: false,
    });

    return newState;
  },
  [types.GET_QUESTION_STATS_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isfetchingQuestionStats: false,
      statistics: action.data || {},
      isAnswerSubmit: null,
      isStatsFetched: action.data.total_attempted > 0,
    });

    return newState;
  },
  [types.GET_QUESTION_STATS_FAIL](state, action) {
    const newState = Object.assign({}, state, {
      isfetchingQuestionStats: false,
      error: action.data,
      statistics: action.data || {},
      isStatsFetched: false,
    });

    return newState;
  },

  [types.POST_ANSWER_START](state) {
    return {
      ...state,
      isSubmittingAnswer: true,
      isAnswerSuccess: false,
      isAnswerFail: false,
      isSkipped: false,
      isTimeout: false,
      isTimeOver: false,
      postedAnswerId: null,
      postAnswerSuccess: null,
    };
  },
  [types.POST_ANSWER_SUCCESS](state, action) {
    if (!action.data)
      return {
        ...state,
        postedAnswerId: action.id,
        postAnswerSuccess: true,
      };

    return {
      ...state,
      isSubmittingAnswer: false,
      isAnswerSuccess: true,
      answerStatus: action.data,
      isSkipped: action.data.question_skipped,
      isTimeout: action.data.timeout,
      currentStreakBadgeName:
        action.data.progress_obj.current_streak_badge_name,
      currentDayStreakBadgeName:
        action.data.progress_obj.current_day_streak_badge_name,
      streakCount: action.data.progress_obj.current_streak_count,
      streakTarget: action.data.progress_obj.current_streak_target,
      streakDayCount: action.data.progress_obj.current_day_streak_count,
      streakDayTarget: action.data.progress_obj.current_day_streak_target,
      totalTagBadges: action.data.progress_obj.total_badges,
      isTimeOver: false,
      categoryScore: action.data.progress_obj.current_tag_score,
      competitionScore: action.data.progress_obj.competition_overall_score,
    };
  },
  [types.POST_ANSWER_FAIL](state, action) {
    return {
      ...state,
      error: action.data,
      answerStatus: {},
      isSubmittingAnswer: false,
      isAnswerFail: true,
    };
  },
  [types.UNLOCK_QUESTION_START](state) {
    return {
      ...state,
      isUnlockQuestionSuccess: false,
      isUnlockQuestionFail: false,
    };
  },
  [types.UNLOCK_QUESTION_SUCCESS](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        solution_text: action.payload.data.solution_text,
      },
      solution: action.payload.data.solution,
      isUnlockQuestionSuccess: true,
      isUnlockQuestionFail: false,
    };
  },
  [types.UNLOCK_QUESTION_FAIL](state) {
    return {
      ...state,
      isUnlockQuestionSuccess: false,
      isUnlockQuestionFail: true,
    };
  },
  [types.GET_REPORTED_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isFetchingReportedQuestion: true,
    });

    return newState;
  },
  [types.GET_REPORTED_QUESTION_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isFetchingReportedQuestion: false,
      reportedQuestionsList: action.data.data,
      reportedQuestionsPaginationInfo: {
        currentPage: action.data.current_page - 1, // zero-based
        pageSize: action.data.page_size,
        totalPages: action.data.total_pages,
      },
    });

    return newState;
  },
  [types.GET_REPORTED_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isFetchingReportedQuestion: false,
    });

    return newState;
  },
  [types.GET_UNDER_REVIEW_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isFetchingUnderReviewQuestion: true,
    });

    return newState;
  },
  [types.GET_UNDER_REVIEW_QUESTION_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isFetchingUnderReviewQuestion: false,
      reviewQuestionsList: action.data.data,
      reviewQuestionsPaginationInfo: {
        currentPage: action.data.current_page - 1, // zero-based
        pageSize: action.data.page_size,
        totalPages: action.data.total_pages,
      },
    });

    return newState;
  },
  [types.GET_UNDER_REVIEW_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isFetchingUnderReviewQuestion: false,
    });

    return newState;
  },
  [types.PUT_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isQuestionUpdated: false,
      isPutQuestionSuccess: false,
      isPutQuestionFail: false,
    });

    return newState;
  },
  [types.PUT_QUESTION_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isQuestionUpdated: true,
      isPutQuestionSuccess: true,
    });

    return newState;
  },
  [types.PUT_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isQuestionUpdated: false,
      isPutQuestionFail: true,
    });

    return newState;
  },
  [types.PUT_SOLUTION_RATE_START](state) {
    const newState = Object.assign({}, state, {
      isRatedFalse: false,
    });

    return newState;
  },
  [types.PUT_SOLUTION_RATE_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isRatedFalse: true,
    });

    return newState;
  },
  [types.PUT_SOLUTION_RATE_FAIL](state) {
    const newState = Object.assign({}, state, {
      isRatedFalse: false,
    });

    return newState;
  },
  [types.PUT_REVIEW_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isReviewQuestionUpdated: false,
      isPutReviewQuestionSuccess: false,
    });

    return newState;
  },
  [types.PUT_REVIEW_QUESTION_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isReviewQuestionUpdated: true,
      isPutReviewQuestionSuccess: true,
    });

    return newState;
  },
  [types.PUT_REVIEW_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isReviewQuestionUpdated: false,
      isPutReviewQuestionFail: true,
    });

    return newState;
  },
  [types.DELETE_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isDeleteSuccess: false,
      isDeleteFail: false,
    });

    return newState;
  },
  [types.DELETE_QUESTION_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isDeleteSuccess: true,
    });

    return newState;
  },
  [types.DELETE_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isDeleteFail: true,
    });

    return newState;
  },
  [types.GET_QUESTION_LIST_START](state) {
    return {
      ...state,
      questionList: [],
      isListLoading: true,
      isListFetchSuccess: false,
    };
  },
  [types.GET_QUESTION_LIST_SUCCESS](state, action) {
    return {
      ...state,
      questionList: action.data.questions,
      questionPaginationInfo: {
        currentPage: action.data.current_page - 1, // zero-based
        pageSize: action.data.page_size,
        totalPages: action.data.total_pages,
      },
      isListLoading: false,
      isListFetchSuccess: true,
      isTimeOver: false,
      isCreateAllowed: true,
    };
  },
  [types.GET_QUESTION_LIST_FAIL](state) {
    return { ...state, isListLoading: false };
  },
  [types.GET_STREAK_START](state) {
    const newState = Object.assign({}, state, {
      isStreakRecieved: false,
    });

    return newState;
  },
  [types.GET_STREAK_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isStreakRecieved: true,
      streak: action.data,
      totalTagBadges: action.data.total_badges,
      currentStreakBadgeName: action.data.current_streak_badge_name,
      currentDayStreakBadgeName: action.data.current_day_streak_badge_name,
      streakCount: action.data.current_streak_count,
      streakTarget: action.data.current_streak_target,
      streakDayCount: action.data.current_day_streak_count,
      streakDayTarget: action.data.current_day_streak_target,
      categoryScore: action.data.current_tag_score,
    });

    return newState;
  },
  [types.GET_STREAK_FAIL](state) {
    const newState = Object.assign({}, state, {
      isStreakRecieved: false,
    });

    return newState;
  },
  [types.PUT_SAVE_STREAK_START](state) {
    return {
      ...state,
      isUpdatingStreak: true,
    };
  },

  [types.PUT_SAVE_STREAK_SUCCESS](state, action) {
    return {
      ...state,
      streak: action.data.data.progress_obj,
      currentStreakBadgeName:
        action.data.data.progress_obj.current_streak_badge_name,
      currentDayStreakBadgeName:
        action.data.data.progress_obj.current_day_streak_badge_name,
      streakCount: action.data.data.progress_obj.current_streak_count,
      streakTarget: action.data.data.progress_obj.current_streak_target,
      streakDayCount: action.data.data.progress_obj.current_day_streak_count,
      streakDayTarget: action.data.data.progress_obj.current_day_streak_target,
      // categoryScore: action.data.data.progress_obj.current_tag_score,
      isUpdatingStreak: false,
    };
  },
  [types.PUT_SAVE_STREAK_FAIL](state) {
    return {
      ...state,
      isUpdatingStreak: false,
    };
  },
  [types.SET_TIME_OUT](state) {
    return { ...state, isTimeOver: true };
  },
  [types.POST_REPORT_QUESTION_START](state) {
    return {
      ...state,
      isPostReportQuestionSuccess: false,
      isPostReportQuestionFail: false,
    };
  },
  [types.POST_REPORT_QUESTION_SUCCESS](state) {
    return {
      ...state,
      isPostReportQuestionSuccess: true,
    };
  },
  [types.POST_REPORT_QUESTION_FAIL](state) {
    return { ...state, isPostReportQuestionFail: true };
  },
  [types.RESET_QUESTION_BY_ID](state) {
    return {
      ...state,
      questionById: {},
    };
  },
  [types.RESET_QUESTION_DATA](state) {
    return {
      ...state,
      data: {},
    };
  },
  [types.GET_QUESTION_BY_ID_START](state) {
    return {
      ...state,
      questionById: {},
      isFetchingQuestionById: true,
      // clear old question attempt data
      isAnswerSuccess: false,
    };
  },
  [types.GET_QUESTION_BY_ID_SUCCESS](state, action) {
    return {
      ...state,
      // this is for mock test flexible play
      data: action.data,
      // this is for question detail page
      questionById: action.data,
      isFetchingQuestionById: false,
    };
  },
  [types.GET_QUESTION_BY_ID_FAIL](state) {
    return { ...state, isFetchingQuestionById: false };
  },

  [types.GET_UNANSWERED_QUESTION_BY_ID_START](state) {
    return {
      ...state,
      unansweredQuestion: [],
      isFetchingQuestionById: true,
    };
  },
  [types.GET_UNANSWERED_QUESTION_BY_ID_SUCCESS](state, action) {
    return {
      ...state,
      unansweredQuestion: action.data,
      isFetchingQuestionById: false,
    };
  },
  [types.GET_UNANSWERED_QUESTION_BY_ID_FAIL](state) {
    return { ...state, isFetchingQuestionById: false };
  },

  [types.POST_NEW_SOLUTION_START](state) {
    return {
      ...state,
      isPostNewSolutionSuccess: false,
      isPostNewSolutionFail: false,
    };
  },
  [types.POST_NEW_SOLUTION_SUCCESS](state) {
    return {
      ...state,
      isPostNewSolutionSuccess: true,
    };
  },
  [types.POST_NEW_SOLUTION_FAIL](state) {
    return { ...state, isPostNewSolutionFail: true };
  },
  [types.PUT_REPORTED_QUESTION_START](state) {
    const newState = Object.assign({}, state, {
      isPutReportedQuestionFail: false,
      isPutReportedQuestionSuccess: false,
    });

    return newState;
  },
  [types.PUT_REPORTED_QUESTION_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isPutReportedQuestionSuccess: true,
    });

    return newState;
  },
  [types.PUT_REPORTED_QUESTION_FAIL](state) {
    const newState = Object.assign({}, state, {
      isPutReportedQuestionFail: true,
    });

    return newState;
  },

  [types.PUT_SOLUTION_RATING_START](state) {
    const newState = Object.assign({}, state, {
      isPostingRating: true,
    });
    return newState;
  },
  [types.PUT_SOLUTION_RATING_SUCCESS](state) {
    const newState = Object.assign({}, state, {
      isPostingRating: false,
    });
    return newState;
  },
  [types.PUT_SOLUTION_RATING_FAIL](state) {
    const newState = Object.assign({}, state, {
      isPostingRating: false,
    });
    return newState;
  },

  [types.PUT_LIKE_START](state) {
    const newState = Object.assign({}, state, {
      isPutLikeFail: false,
      isPutLikeSuccess: false,
    });

    return newState;
  },
  [types.PUT_LIKE_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isPutLikeSuccess: true,
      isPutDislikeSuccess: false,
      likedCount: action.data,
    });

    return newState;
  },
  [types.PUT_LIKE_FAIL](state) {
    const newState = Object.assign({}, state, {
      isPutLikeFail: true,
      isPutLikeSuccess: false,
    });

    return newState;
  },
  [types.PUT_DISLIKE_START](state) {
    const newState = Object.assign({}, state, {
      isPutDislikeFail: false,
      isPutDislikeSuccess: false,
    });

    return newState;
  },
  [types.PUT_DISLIKE_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      isPutDislikeSuccess: true,
      isPutLikeSuccess: true,
      likedCount: action.data,
    });

    return newState;
  },
  [types.PUT_DISLIKE_FAIL](state) {
    const newState = Object.assign({}, state, {
      isPutDislikeFail: true,
    });

    return newState;
  },
  [types.GET_MORE_TIME_START](state) {
    const newState = Object.assign({}, state, {
      isGettingMoreTime: true,
      purchasedTime: 0,
    });

    return newState;
  },
  [types.GET_MORE_TIME_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      purchasedTime: action.data.purchased_time || 0,
      isGettingMoreTime: false,
    });

    return newState;
  },
  [types.GET_MORE_TIME_FAIL](state) {
    const newState = Object.assign({}, state, {
      isGettingMoreTime: false,
    });

    return newState;
  },
  [types.RESET_PURCHASE_TIME](state) {
    const newState = Object.assign({}, state, {
      purchasedTime: 0,
    });

    return newState;
  },
  [types.GET_REVIEW_TEMPLATE_START](state) {
    const newState = Object.assign({}, state, {
      ...state,
      templates: [],
      isGotTemplates: false,
      isPostNewTemplateSuccess: false,
      isDeleteNewTemplateSuccess: false,
    });
    return newState;
  },
  [types.GET_REVIEW_TEMPLATE_SUCCESS](state, action) {
    const newState = Object.assign({}, state, {
      ...state,
      templates: action.data,
      isGotTemplates: true,
    });
    return newState;
  },
  [types.GET_REVIEW_TEMPLATE_FAIL](state) {
    const newState = Object.assign({}, state, {
      ...state,
      templates: [],
      isGotTemplates: false,
    });
    return newState;
  },
  [types.POST_REVIEW_TEMPLATE_START](state) {
    return {
      ...state,
      isPostNewTemplateSuccess: false,
    };
  },
  [types.POST_REVIEW_TEMPLATE_SUCCESS](state) {
    return {
      ...state,
      isPostNewTemplateSuccess: true,
    };
  },
  [types.POST_REVIEW_TEMPLATE_FAIL](state) {
    return {
      ...state,
      isPostNewTemplateSuccess: false,
    };
  },
  [types.DELETE_REVIEW_TEMPLATE_START](state) {
    return {
      ...state,
      isDeleteNewTemplateSuccess: false,
    };
  },
  [types.DELETE_REVIEW_TEMPLATE_SUCCESS](state) {
    return {
      ...state,
      isDeleteNewTemplateSuccess: true,
    };
  },
  [types.DELETE_REVIEW_TEMPLATE_FAIL](state) {
    return {
      ...state,
      isDeleteNewTemplateSuccess: false,
    };
  },
  [types.GET_VOTING_REPORT_QUESTION_SUCCESS](state, action) {
    return {
      ...state,
      votingReportQuestion: action.data,
    };
  },
  [types.GET_VOTING_REPORT_QUESTION_FAIL](state) {
    return {
      ...state,
      votingReportQuestion: {},
      isVotingReportQuestionGetFail: true,
    };
  },
  [types.GET_VOTED_REPORT_QUESTION_SUCCESS](state, action) {
    return {
      ...state,
      // votedReportQuestion: action.data?.data,
      votedReportQuestion: action.data,
    };
  },
  [types.GET_VOTED_REPORT_QUESTION_FAIL](state) {
    return {
      ...state,
      votedReportQuestion: [],
    };
  },
  [types.POST_VOTING_REPORT_QUESTION_SUCCESS](state) {
    return {
      ...state,
      isPostVotingReportQuestion: true,
    };
  },
  [types.POST_VOTING_REPORT_QUESTION_FAIL](state) {
    return {
      ...state,
      isPostVotingReportQuestion: false,
    };
  },
  [types.GET_EXAM_ANALYTICS_START](state) {
    return {
      ...state,
      isGettingExamAnalytics: true,
    };
  },
  [types.GET_EXAM_ANALYTICS_SUCCESS](state, action) {
    return {
      ...state,
      isGettingExamAnalytics: false,
      examAnalytics: action.data,
    };
  },
  [types.SET_EXAM_ANALYTICS](state, action) {
    if (!Object.values(state.examAnalytics).length) return { ...state };

    const newState = { ...state };
    newState.examAnalytics.questions = newState.examAnalytics.questions.map(
      (question) =>
        question.id === action.data.id
          ? {
              ...question,
              status: action.data.status,
            }
          : question,
    );

    const newStatistics = {
      attempted: 0,
      timeout: 0,
      skipped: 0,
      unattempted: 0,
    };
    newState.examAnalytics.questions.forEach((question) => {
      newStatistics[question.status] += 1;
    });
    newState.examAnalytics.statistics = newStatistics;

    return newState;
  },
  [types.GET_EXAM_ANALYTICS_FAIL](state) {
    return {
      ...state,
      isGettingExamAnalytics: false,
      isGettingExamAnalyticsFail: true,
    };
  },
  [types.GET_QUESTION_HINT_START](state) {
    return {
      ...state,
      isGettingQuestionHint: true,
      isGettingHintFail: false,
      isGettingHintSuccess: false,
    };
  },
  [types.GET_QUESTION_HINT_SUCCESS](state, action) {
    return {
      ...state,
      isGettingQuestionHint: false,
      isGettingHintFail: false,
      isGettingHintSuccess: true,
      hint: action.payload.data.hint,
    };
  },
  [types.GET_QUESTION_HINT_FAIL](state) {
    return {
      ...state,
      isGettingQuestionHint: false,
      isGettingHintFail: true,
      isGettingHintSuccess: false,
    };
  },
});

export { question as default };
