import React from 'react';
import { Button, Divider, Modal } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import ModalCloseIcon from '../../images/ModalCloseIcon.svg';
import {
  PreviewModalButtonGroup,
  PreviewModalCloseButton,
  PreviewModalHeader,
  PreviewModalSubject,
  PreviewModalWrapper,
} from './StyledComponents';
import { getEmailTemplateList } from '../../actions/emailingList';
import { useDispatch, useSelector } from 'react-redux';

const PreviewModal = ({ open, setOpen, subject, body, sendEmail }) => {
  const dispatch = useDispatch();
  const { emailSentData, isSendingEmail } = useSelector(
    (state) => state.emailingList,
  );
  const handleClose = () => {
    setOpen(false);
    dispatch(getEmailTemplateList());
  };

  return (
    <Modal open={!!open}>
      {emailSentData?.success && emailSentData?.total_email_sent >= 0 ? (
        <PreviewModalWrapper style={{ width: '50%' }}>
          <PreviewModalCloseButton onClick={handleClose}>
            <img src={ModalCloseIcon} alt={'close-icon'} />
          </PreviewModalCloseButton>
          <PreviewModalHeader style={{ textAlign: 'center' }}>
            {emailSentData?.total_email_sent} Email(s) Sent!
          </PreviewModalHeader>
          <PreviewModalButtonGroup sx={{ justifyContent: 'center' }}>
            <>
              <Button variant="contained" onClick={handleClose}>
                OK
              </Button>
            </>
          </PreviewModalButtonGroup>
        </PreviewModalWrapper>
      ) : (
        <PreviewModalWrapper>
          <PreviewModalCloseButton onClick={handleClose}>
            <img src={ModalCloseIcon} alt={'close-icon'} />
          </PreviewModalCloseButton>
          <PreviewModalHeader>Email Preview</PreviewModalHeader>
          <div>
            <PreviewModalSubject>Subject: </PreviewModalSubject>
            {subject}
          </div>
          <Divider />
          <div>{ReactHtmlParser(body)}</div>
          <PreviewModalButtonGroup
            sx={{ justifyContent: open === 2 ? 'space-between' : 'center' }}
          >
            {open === 2 ? (
              <>
                <Button variant="outlined" onClick={handleClose}>
                  Edit
                </Button>
                <Button
                  variant="contained"
                  disabled={isSendingEmail}
                  onClick={() => sendEmail()}
                >
                  Send
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" onClick={handleClose}>
                  OK
                </Button>
              </>
            )}
          </PreviewModalButtonGroup>
        </PreviewModalWrapper>
      )}
    </Modal>
  );
};

export default PreviewModal;
