import { styled } from '@mui/styles';
import Modal from '@mui/material/Modal';

const drawerWidth = '240px';
const drawerWidthCollapsed = '72px';

export const StyledLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.palette.background.main,

  '& .layout--only-header': {
    flexDirection: 'column',
  },

  '& .layout__appbar': {
    transition: 'margin 0.3s ease, width 0.3s ease !important',
    backgroundColor: `${theme.palette.background.main} !important`,
    boxShadow: `0 2px 4px 0 rgba(${theme.palette.text.primary}, 0.15) !important`,
    marginLeft: drawerWidthCollapsed,
    width: `calc(100% - ${drawerWidthCollapsed}) !important`,

    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },

  '& .layout__appBarShift': {
    width: `calc(100% - ${drawerWidth}) !important`,
    marginLeft: drawerWidth,
    transition: 'margin 0.3s ease-out, width 0.3s ease-out !important',

    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      marginLeft: 0,
      transition: 'none',
    },
  },

  '& .layout__menuButton': {
    marginRight: '16px',
    color: theme.palette.component.color,
  },

  '& .hide': {
    display: 'none',
  },

  '& .layout__content': {
    flexGrow: 1,
    transition: 'margin 0.3s ease 0.2',
    display: 'flex',
    flexDirection: 'column',

    '&--shift': {
      transition: 'margin 0.3s ease-out 0.2',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  '& .layout__content--shift': {
    flexGrow: 1,
    transition: 'margin 0.3s ease-out 0.2',
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'column',
  },

  '& .layout__sidebar__header': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: drawerWidth,
    zIndex: 1500,
    boxShadow: `0 2px 4px 0 ${theme.palette.component.shadowColor2} !important`,

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },

  '&.no_sidebar': {
    '& .wrapper': {
      padding: 0,
    },
  },

  '& .wrapper': {
    padding: '24px 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
    },
  },
  '& .app-loader': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
  },
}));

export const StyledWrapper = styled('div')(({ theme }) => ({
  padding: '24px 0',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    padding: '10px 0',
  },
}));

export const StyledAppLoader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: '100%',
});

export const StyledDomainPrompt = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .domain-prompt__container': {
    maxWidth: '560px',
    width: '100%',

    '& .domain-prompt__container__content': {
      padding: '30px 20px',
      paddingBottom: '55px',

      h6: {
        marginBottom: '2px',
      },

      '& .caption': {
        marginBottom: '20px',
        display: 'block',
        color: theme.palette.component.color,
        lineHeight: '1.4',
      },

      '& .domain-prompt__container__content__input': {
        marginBottom: '30px',
      },
    },
  },
}));
