import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Invitation from './images/invitation.svg';
import RightToggleIcon from './images/RightToggleIcon.svg';
import { difficulties } from '../../constants/examDifficulty';

const TrendingMockTests = ({ styles, headline, collegeRelatedData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const trendingMockTests = collegeRelatedData?.recommended_exams;
  const currentItem = trendingMockTests?.[currentIndex];
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % trendingMockTests?.length);
  };
  return (
    <>
      <span className={styles.contentLeft_Heading1}>{headline}</span>
      <div
        style={{
          borderRadius: '10px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
          margin: '15px 0px 20px 0px',
          position: 'relative',
        }}
      >
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Typography className={styles.levelBadge}>
            {
              difficulties.find(
                (item) =>
                  item.value === parseInt(currentItem?.difficulty_level),
              )?.label
            }
          </Typography>
        </Box>

        <div style={{ padding: '0px 20px 20px 20px' }}>
          <Box display={'flex'} alignItems={'center'}>
            <img
              src={currentItem?.image_url || Invitation}
              alt={''}
              width={'50px'}
            />{' '}
            &nbsp;&nbsp;&nbsp;
            <Box>
              <span className={styles.hotTopicsHeading}>
                {currentItem?.exam_name}
              </span>
            </Box>
          </Box>
          <div className="mocktest__item--content">
            <div>
              <Typography variant="caption">Questions</Typography>
              <Typography color="primary" className="mocktest__item--number">
                {currentItem?.total_questions}
              </Typography>
            </div>
            <div className="vertical-line"></div>
            <div>
              <Typography variant="caption">Duration</Typography>
              <Typography color="primary" className="mocktest__item--number">
                {currentItem?.exam_duration} mins
              </Typography>
            </div>
            <div className="vertical-line"></div>
            <div>
              <Typography variant="caption">Mode</Typography>
              <Typography color="primary" className="mocktest__item--number">
                {currentItem?.exam_type}
              </Typography>
            </div>
          </div>
        </div>
        <Box
          textAlign="right"
          sx={{ position: 'absolute', top: '50%', right: '-20px' }}
        >
          <IconButton onClick={handleNextClick}>
            <img src={RightToggleIcon} width={'20px'} />
          </IconButton>
        </Box>
      </div>
    </>
  );
};

export default TrendingMockTests;
