import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MockTestCard from '../MockTestCard';
import DeleteIcon from '../images/DeleteIcon.svg';
import MockTestStoreEmptyState from '../images/MockTestStoreEmptyState.svg';
import UnlockProgressModal from './UnlockProgressModal';

function getDurationRange(totalQuestions) {
  return `${parseInt(totalQuestions * 0.5)} - ${parseInt(
    totalQuestions * 1.5,
  )}`;
}

function EmptyMockTestStore() {
  return (
    <Box
      className="mocktest__empty"
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <img
        src={MockTestStoreEmptyState}
        alt="MockTestStoreEmptyState"
        className="mocktest__emptyImg"
      />
      <span className="emptyState__heading1">
        No Mock Tests matching your criteria!
      </span>
      <span className="emptyState__heading2">
        Sit Tight and Stay Tuned for New Tests.
      </span>
    </Box>
  );
}

const AllTestTab = ({
  show,
  isAdmin,
  templates,
  tagList,
  onBuy,
  onDelete,
  prerequisite,
  getMockTestPrerequisite,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);

  function showProgress(test) {
    getMockTestPrerequisite(test.id);
    setSelectedTest(test);
    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
    setSelectedTest(null);
  }

  useEffect(() => {
    if (state?.dashboardData && state?.from === '/l/dashbaord') {
      if (!state?.is_unlocked) {
        showProgress(state?.dashboardData);
      }
    }
  }, []);

  if (!show) return null;
  return (
    <>
      <UnlockProgressModal
        showModal={showModal}
        handleClose={handleClose}
        selectedTest={selectedTest}
        tags={tagList.filter((tag) =>
          selectedTest?.tag_array?.includes(tag.id),
        )}
        prerequisite={prerequisite?.status}
        navigate={navigate}
      />
      <div className="mocktest__items">
        {templates?.length === 0 && <EmptyMockTestStore />}
        {templates?.map((test) => (
          <MockTestCard
            key={test?.id}
            title={test?.name}
            imageUrl={test?.image_url}
            tags={tagList.filter((tag) => test?.tag_array?.includes(tag.id))}
            difficulty={test?.difficulty_level}
            questionsText="Questions"
            questionsValue={test?.no_questions}
            timeText="Duration"
            timeValue={getDurationRange(test?.no_questions)}
            mode={test?.exam_mode}
            credits={test?.cost_in_credits || 100}
            btnText={test?.is_unlocked ? 'Buy Test' : 'Unlock Now'}
            onClick={() =>
              test?.is_unlocked ? onBuy(test) : showProgress(test)
            }
            extra={
              isAdmin && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Button onClick={() => onDelete(test)}>
                    <img src={DeleteIcon} />
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`edit/${test?.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              )
            }
          />
        ))}
      </div>
    </>
  );
};

export default AllTestTab;
