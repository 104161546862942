import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import Diamond from '../../images/Gemsnew.svg';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import Tick from '../QuestionList/images/TickVector.svg';
import UserIcon from './images/UserIcon.svg';
import '../QuestionList/index.scss';

const MilestoneBar = ({ milestoneData }) => {
  const isMobileScreen = () => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');

    if (mediaQuery.matches) {
      return true;
    } else {
      return false;
    }
  };
  const roadMap = milestoneData?.all_milestone_details;
  const filteredTempRoadMap = roadMap?.filter(
    (r) => r?.student_count <= milestoneData?.total_students,
  );
  const filteredRoadMap = filteredTempRoadMap
    ?.concat(filteredTempRoadMap.slice(-1))
    ?.slice(-3, -1);
  const unachievedRoadMap = roadMap
    ?.filter((r) => r?.student_count > milestoneData?.total_students)
    ?.slice(0, isMobileScreen() ? 2 : 5);
  const tempPoints = filteredRoadMap?.concat(unachievedRoadMap);
  let occupied = 0;
  const displayPoints = tempPoints?.map((d) => {
    let percentage =
      (d?.student_count / tempPoints?.[tempPoints.length - 1]?.student_count) *
      100;
    let finalPercentage = percentage - occupied;
    occupied = percentage;
    return { ...d, mL: finalPercentage };
  });
  const max = unachievedRoadMap?.length;

  return (
    <Grid container className="milestoneContainer">
      <Grid item sm={8} xs={12} style={{ display: 'block' }}>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <div className="progressContainer">
            <div className="milestoneHolder">
              {displayPoints &&
                displayPoints.map((d, index) => (
                  <Tooltip
                    key={index}
                    interactive={'true'}
                    enterTouchDelay={50}
                    title={
                      <div
                        className="progressTooltipContainer"
                        style={{ background: 'white', padding: '10px' }}
                      >
                        <Grid
                          container
                          className="tooltipTopSection"
                          spacing={3}
                        >
                          <Grid item xs={6}>
                            <span className="milestoneText">
                              Milestone Target
                            </span>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="progressQuestionCount">
                              <span className="progressQuestionCountText1">
                                {d.student_count}
                              </span>{' '}
                              <br />
                              <span style={{ color: 'black' }}>Questions</span>
                            </div>
                          </Grid>
                        </Grid>
                        <hr />
                        <div className="tooltipBottomSection">
                          <div className="tooltipBottomSectionText">
                            Conquer and Earn Rewards
                          </div>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <img
                                src={d.image_url}
                                height="45px"
                                width="45px"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Grid
                                container
                                spacing={3}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <img
                                    src={CreditIcon}
                                    width="20px"
                                    height="20px"
                                  />
                                </Grid>
                                <Grid item>
                                  <span className="tooltipGemsCount">
                                    {d.credits}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={3}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <img
                                    src={Diamond}
                                    width="20px"
                                    height="20px"
                                  />
                                </Grid>
                                <Grid item>
                                  <span className="tooltipGemsCount">
                                    {d.gems}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    }
                    arrow
                    placement="bottom-end"
                  >
                    <div
                      className="milestoneAchieved"
                      style={{
                        marginLeft: `calc(${d.mL}% - 15px)`,
                      }}
                    >
                      {d?.student_count > milestoneData?.total_students && (
                        <img
                          src={UserIcon}
                          style={{ height: '100%' }}
                          alt="diamond"
                        />
                      )}
                      {d?.student_count <= milestoneData?.total_students && (
                        <img
                          src={Tick}
                          style={{ width: '15px' }}
                          alt="diamond"
                        />
                      )}
                    </div>
                  </Tooltip>
                ))}
            </div>
            <div
              className="progress"
              style={{
                width: `${(milestoneData?.total_students /
                  unachievedRoadMap?.[max - 1]?.student_count) *
                  100}%`,
              }}
            >
              <div className="currentStatus">
                {milestoneData?.total_students}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item sm={4} xs={12} className="labelContainer">
        <Typography variant="subtitle2" color="primary" className="label">
          User Tracker:
        </Typography>
        <Typography variant="subtitle2" color="primary" className="count">
          {milestoneData?.total_students}
        </Typography>
        <Typography variant="subtitle2" className="unit">
          Users
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MilestoneBar;
