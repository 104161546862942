import React from 'react';
import {
  BadgePopupContainerBox,
  BadgeCountBox,
  BadgeParentGrid,
  Header,
  StyledBadgesContainer,
  StyledBadgesCount,
  StyledCustomCard,
  StyledTipsContainer,
} from './DashboardStyledComponent';
import { Box, Typography } from '@mui/material';
import EmptyBadgeIcon from './images/empty_badge_icom.png';

const Tips = ({ userBadges, badgeCount }) => {
  const [showBadgeData, setShowBadgeData] = React.useState('');

  return (
    <StyledTipsContainer>
      <Header>Quick Success Tips</Header>

      <StyledCustomCard
        style={{ marginBottom: '8px', borderRadius: '8px', padding: '8px' }}
      >
        🌟 Boost your credits! Start making questions and earn credits which can
        be redeemed in Abekus Store.
      </StyledCustomCard>

      <StyledCustomCard
        style={{ marginBottom: '8px', borderRadius: '8px', padding: '7px' }}
      >
        🔥 Streaks make you earn badges and boosts your accuracy.
      </StyledCustomCard>

      <StyledCustomCard
        style={{ marginBottom: '8px', borderRadius: '8px', padding: '7px' }}
      >
        💰 1 Credits = ₹ 1, Perform and Earn.
      </StyledCustomCard>

      <StyledBadgesContainer style={{ display: 'flex' }}>
        <StyledBadgesCount style={{ width: '25%' }}>
          <span style={{ fontSize: '1.1rem', fontWeight: '900' }}>
            {badgeCount || 0}
          </span>
          <br />
          <span>Badges</span>
        </StyledBadgesCount>
        <BadgeParentGrid>
          {userBadges.length !== 0 ? (
            userBadges.slice(0, 5).map((badge) => (
              <Box
                onMouseEnter={() => {
                  setShowBadgeData(badge?.name);
                }}
                onMouseLeave={() => {
                  setShowBadgeData('');
                }}
                key={badge?.id}
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <img style={{ width: '35px' }} src={badge?.image_url}></img>
                <BadgeCountBox>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      color: 'white',
                      fontWeight: '100',
                    }}
                  >
                    {badge?.badge_count}
                  </Typography>
                </BadgeCountBox>
                {badge?.name === showBadgeData && (
                  <BadgePopupContainerBox>
                    <Box sx={{ width: '90%', height: '90%', display: 'flex' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          marginX: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '50px',
                            height: '50px',
                            marginTop: '10%',
                          }}
                          component={'img'}
                          src={badge?.image_url}
                        ></Box>
                      </Box>
                      <Box sx={{ height: '100%' }}>
                        <Typography
                          sx={{
                            fontSize: '0.7rem',
                            fontWeight: 500,
                            color: 'black',
                          }}
                        >
                          {badge?.name}
                        </Typography>
                        <Typography sx={{ fontSize: '0.6rem' }}>
                          {badge?.description}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '0.7rem',
                            fontWeight: 500,
                            color: '#25507B',
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{ fontSize: '0.7rem', fontWeight: 500 }}
                          >
                            Owned Badges:
                          </Typography>{' '}
                          {badge?.badge_count}
                        </Typography>
                      </Box>
                    </Box>
                  </BadgePopupContainerBox>
                )}
              </Box>
            ))
          ) : (
            <Box display="flex" gap="10px" alignItems="center">
              <img src={EmptyBadgeIcon} alt="icon" width={30} height={30} />
              <Box>
                <Typography fontSize={'12px'} color="black" fontWeight={500}>
                  Level up Your Skills:{' '}
                </Typography>
                <Typography fontSize={'12px'} color="black" fontWeight={500}>
                  Earn Badges through Practice and Dedication!
                </Typography>
              </Box>
            </Box>
          )}
        </BadgeParentGrid>
      </StyledBadgesContainer>
    </StyledTipsContainer>
  );
};

export default Tips;
