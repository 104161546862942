import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import UserDetails from '../components/UserDetails';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    tag: state.tag.list,
    allTags: state.tag.allTags,
    user: state.user,
    domain: state.domain.list,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
