import moment from 'moment';

const day = 86400;
const hour = 3600;
const minute = 60;

const helpers = {
  getURLParameterByName: (name, url) => {
    let curl = url;
    let cname = name;
    if (!curl) {
      curl = window.location.href;
    }
    cname = cname.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${cname}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(curl);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ''));
  },

  getCategoryList: (list, filter) => {
    if (filter) {
      return list.filter((item) => item.is_user_active);
    }

    return list;
  },

  stripHTMLTags: (inputString = '') => {
    return inputString?.replace(/<\/?[^>]+(>|$)/g, '');
  },

  sanitizeHTML: (inputHTMLString = '') => {
    if (inputHTMLString === '<p><br></p>') {
      return '';
    }

    return inputHTMLString?.toString().replace('<p><br></p>', '');
  },

  getRandomString: (length = 50) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * charactersLength));

    return result;
  },

  getDaysRemaining: (duration) => {
    return Math.floor(duration / day);
  },

  getHoursRemaining: (duration) => {
    const daysout = helpers.getDaysRemaining(duration);
    return Math.floor((duration - daysout * day) / hour);
  },

  getMinutesRemaining: (duration) => {
    const daysout = helpers.getDaysRemaining(duration);
    const hoursout = helpers.getHoursRemaining(duration);
    return Math.floor((duration - daysout * day - hoursout * hour) / minute);
  },

  getSecondsRemaining: (duration) => {
    const daysout = helpers.getDaysRemaining(duration);
    const hoursout = helpers.getHoursRemaining(duration);
    const minutesout = helpers.getMinutesRemaining(duration);
    return duration - daysout * day - hoursout * hour - minutesout * minute;
  },

  getRemainingTime: (datetime, duration, unit = 'seconds') => {
    // the datetime is in utc format
    const future = moment.utc(datetime);
    future.add(duration, unit);
    return Math.abs(future.diff(new Date(), unit));
  },

  isTimeGone: (time) => {
    return moment.utc(time).isBefore(new Date());
  },

  getTimeDifference: (first, second, unit = 'seconds') => {
    return Math.abs(moment.utc(first).diff(moment.utc(second), unit));
  },
};

export function expectedCTCFormatter(ctc) {
  if (ctc < 100000) {
    return `${(ctc / 1000).toFixed(2)}K`;
  }

  if (ctc < 10000000) {
    return `${(ctc / 100000).toFixed(2)}L`;
  }

  return `${(ctc / 10000000).toFixed(1)}Cr`;
}

export const removeCommas = (salaryWithCommas) => {
  if (!salaryWithCommas) {
    return salaryWithCommas;
  }
  if (salaryWithCommas.toString().includes(',')) {
    const sal = salaryWithCommas.replace(/,/g, '');
    return parseInt(sal, 10);
  }
  return salaryWithCommas;
};

export const addInlineStylesToParagraphs = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const paragraphs = doc.querySelectorAll('p');

  paragraphs.forEach((p) => {
    p.style.margin = '0';
    p.style.padding = '0';
  });
  const serializer = new XMLSerializer();
  let serializedContent = '';
  doc.body.childNodes.forEach((node) => {
    serializedContent += serializer.serializeToString(node);
  });

  return serializedContent;
};

export const getHostAlias = (hostUrl) => {
  switch (hostUrl) {
    case 'localhost:11000':
      return 'localhost-11000';
    case 'localhost:12000':
      return 'localhost-12000';
    case 'staging.abekus.com':
      return 'staging-learning';
    case 'staging.jobs.abekus.com':
      return 'staging-jobs';
    case 'jobs.abekus.com':
      return 'prod-jobs';
    case 'abekus.com':
      return 'prod-learning';
    case 'jobs.abekus.ai':
      return 'prod-jobs';
    case 'abekus.ai':
      return 'prod-learning';
    default:
      return null;
  }
};

export default helpers;
