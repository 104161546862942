import * as types from './types';
import apiClient from '../api/apiclient.ts';

function getJobReviewStart() {
  return {
    type: types.GET_JOB_REVIEW_START,
  };
}

function getJobReviewSuccess({ data }) {
  return {
    type: types.GET_JOB_REVIEW_SUCCESS,
    data: data,
  };
}

function getJobReviewFail() {
  return {
    type: types.GET_JOB_REVIEW_FAIL,
  };
}

export function getJobReview(
  status,
  searchUser,
  page,
  rowsPerPage,
  sortBy,
  sortOrderByTime,
) {
  return (dispatch) => {
    dispatch(getJobReviewStart());

    apiClient
      .getJobApi()
      .jobApiReviewJobs(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        page,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        undefined,
        sortBy,
        sortOrderByTime,
        status,
        searchUser,
        undefined,
      )
      .then((resp) => {
        dispatch(getJobReviewSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getJobReviewFail());
      });
  };
}
function getOrgReviewStart() {
  return {
    type: types.GET_ORG_REVIEW_START,
  };
}

function getOrgReviewSuccess(data) {
  return {
    type: types.GET_ORG_REVIEW_SUCCESS,
    data: data,
  };
}

function getOrgReviewFail() {
  return {
    type: types.GET_ORG_REVIEW_FAIL,
  };
}

export function getOrgReview(
  status,
  debouncedValue,
  page,
  rowsPerPage,
  sortBy,
  sortOrderByTime,
) {
  return (dispatch) => {
    dispatch(getOrgReviewStart());

    apiClient
      .getOrganisationAPI()
      .organizationApiReviewOrganizations(
        undefined,
        page,
        rowsPerPage,
        debouncedValue === '' ? undefined : debouncedValue,
        sortBy,
        sortOrderByTime,
        status === '' ? undefined : status,
      )
      .then((resp) => {
        dispatch(getOrgReviewSuccess(resp.data));
      })
      .catch(() => {
        dispatch(getOrgReviewFail());
      });
  };
}
function orgBulkStatusUpdateStart() {
  return {
    type: types.ORG_BULK_STATUS_UPDATE_START,
  };
}

function orgBulkStatusUpdateSuccess(data) {
  return {
    type: types.ORG_BULK_STATUS_UPDATE_SUCCESS,
    data: data,
  };
}

function orgBulkStatusUpdateFail() {
  return {
    type: types.ORG_BULK_STATUS_UPDATE_FAIL,
  };
}

export function orgBulkStatusUpdate(
  data,
  sortBy,
  sortOrderByTime,
  page,
  rowsPerPage,
) {
  return (dispatch) => {
    dispatch(orgBulkStatusUpdateStart());

    apiClient
      .getOrganisationAPI()
      .organizationApiBulkUpdateOrganizationStatus(data)
      .then((resp) => {
        dispatch(orgBulkStatusUpdateSuccess(resp.data));
        dispatch(
          getOrgReview('', '', page, rowsPerPage, sortBy, sortOrderByTime),
        );
      })
      .catch(() => {
        dispatch(orgBulkStatusUpdateFail());
      });
  };
}
function jobBulkStatusUpdateStart() {
  return {
    type: types.JOBS_BULK_STATUS_UPDATE_START,
  };
}

function jobBulkStatusUpdateSuccess(data) {
  return {
    type: types.JOBS_BULK_STATUS_UPDATE_SUCCESS,
    data: data,
  };
}

function jobBulkStatusUpdateFail() {
  return {
    type: types.JOBS_BULK_STATUS_UPDATE_FAIL,
  };
}

export function jobBulkStatusUpdate(
  data,
  status,
  searchUser,
  sortBy,
  sortOrderByTime,
  page,
  rowsPerPage,
) {
  return (dispatch) => {
    dispatch(jobBulkStatusUpdateStart());

    apiClient
      .getJobApi()
      .jobApiBulkJobStatusUpdate(data)
      .then((resp) => {
        dispatch(jobBulkStatusUpdateSuccess(resp.data));
        dispatch(
          getJobReview(
            status,
            searchUser,
            page,
            rowsPerPage,
            sortBy,
            sortOrderByTime,
          ),
        );
      })
      .catch(() => {
        dispatch(jobBulkStatusUpdateFail());
      });
  };
}

function jobPremiumUpdateStart() {
  return {
    type: types.JOBS_PREMIUM_UPDATE_START,
  };
}

function jobPremiumUpdateSuccess(data) {
  return {
    type: types.JOBS_PREMIUM_UPDATE_SUCCESS,
    data: data,
  };
}

function jobPremiumUpdateFail() {
  return {
    type: types.JOBS_PREMIUM_UPDATE_FAIL,
  };
}

export function jobPremiumUpdate(
  jobId,
  orgId,
  data,
  status,
  searchUser,
  sortBy,
  sortOrderByTime,
  page,
  rowsPerPage,
) {
  return (dispatch) => {
    dispatch(jobPremiumUpdateStart());

    apiClient
      .getJobApi()
      .jobApiUpdateJobAdmin(jobId, orgId, data)
      .then((resp) => {
        dispatch(jobPremiumUpdateSuccess(resp.data));
        dispatch(
          getJobReview(
            status,
            searchUser,
            page,
            rowsPerPage,
            sortBy,
            sortOrderByTime,
          ),
        );
      })
      .catch(() => {
        dispatch(jobPremiumUpdateFail());
      });
  };
}

function deleteJobStart() {
  return {
    type: types.DELETE_JOBS_START,
  };
}

function deleteJobSuccess(data) {
  return {
    type: types.DELETE_JOBS_SUCCESS,
    data: data,
  };
}

function deleteJobFail() {
  return {
    type: types.DELETE_JOBS_FAIL,
  };
}

export function deleteJob(
  orgId,
  jobId,
  status,
  searchUser,
  page,
  rowsPerPage,
  sortBy,
  sortOrderByTime,
) {
  return (dispatch) => {
    dispatch(deleteJobStart());

    return apiClient
      .getJobApi()
      .jobApiDeleteJob(jobId, orgId)
      .then((resp) => {
        dispatch(deleteJobSuccess(resp.data));
        dispatch(
          getJobReview(
            status,
            searchUser,
            page,
            rowsPerPage,
            sortBy,
            sortOrderByTime,
          ),
        );
      })
      .catch((err) => {
        dispatch(deleteJobFail());
        return err.response.data;
      });
  };
}
