import { alpha } from '@mui/material/styles';

export const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
    gridGap: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(),
    },
  },
  card: {
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
  },
  cardActionArea: {
    flex: 1,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: theme.spacing(1),

      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
  },
  cardIcon: {
    width: theme.typography.pxToRem(80),
    height: theme.typography.pxToRem(80),
    marginBottom: theme.typography.pxToRem(10),
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(50),
      height: theme.typography.pxToRem(50),
      marginBottom: 0,
      marginRight: theme.typography.pxToRem(10),
    },
  },
  cardOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
  },
  cardCheck: {
    position: 'absolute',
    fontSize: 28,
    top: theme.typography.pxToRem(5),
    right: theme.typography.pxToRem(5),
  },
  selectAllBtnTop: {
    width: 'max-content',
    border: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  selectAllBtnBottom: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginInline: theme.spacing(3),
    marginBlock: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '36px',
    letterSpacing: '0.4px',
    color: '#25507B',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  heading2: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#25507B',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  discardBtn: {
    border: '1px solid #25507B',
    borderRadius: '8px',
    height: '45px',
    width: '204px',
    padding: '10px 60px 10px 60px',
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center',
    color: '#25507B',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      background: 'white',
      // color: '#fff',
      border: 'none',
      borderRadius: '0px',
    },
  },
  saveBtn: {
    marginLeft: '20px',
    background: '#25507B',
    border: '1px solid #25507B',
    borderRadius: '8px',
    height: '45px',
    padding: '10px 60px 10px 60px',
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center',
    width: '204px',
    color: '#fff',
    '&:hover': {
      background: '#25507B',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      marginLeft: '0px',
      border: 'none',
      borderRadius: '0px',
    },
  },
  serialNo: {
    width: '40px',
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid #25507B',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#25507B',
    fontSize: '18px',
  },
  selectedSerialNo: {
    width: '40px',
    height: '40px',
    background: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #4CAF50',
    color: '#4CAF50',
    fontSize: '18px',
  },
  subCategoryContainer: {
    height: '55vh',
    overflow: 'auto',
  },
  subcategoryBox: {
    background: '#F1F2F7',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    padding: '10px 15px',
    margin: '15px 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedSubcategoryBox: {
    background: '#F6FBF6',
    boxShadow: '0px 0px 2px #4CAF50',
    borderRadius: '5px',
    padding: '10px 15px',
    margin: '15px 10px',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  categoryName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#25507B',
  },
  selectedCategoryName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4CAF50',
  },
  questionNumber: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#76767F',
  },
  subTopicsHeading: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  selectAllContainer: {
    border: ' 1px solid #76767F',
    borderRadius: '4px',
    padding: '6px 10px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    // color: '#76767F',
    width: 'fit-content',
    marginRight: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  allSubtopicsList: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },

  selectedCounter: {
    color: '#3572B0',
  },
  btnContainer: {
    margin: '0px 0px 16px 0px',
    paddingRight: '28px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      bottom: '0px',
      left: '0px',
      padding: '0px',
      margin: '0px',
    },
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
