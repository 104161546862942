import React from 'react';
import {
  Button,
  Chip,
  Accordion,
  AccordionDetails,
  Grid,
  Typography,
} from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import { Icon } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './index.scss';

const List = ({ questionList, handleDeleteAction, tag }) => {
  const getStatusChipColor = (question) => {
    if (question.question_status === 'Active') {
      return 'primary';
    }
    return 'default';
  };
  const getTagById = (id) => {
    return tag.allTags.find((tag) => tag.id === id) || {};
  };
  const convertUtcToIst = (utcTime) => {
    return moment.utc(utcTime).utcOffset(330);
  };
  return (
    <>
      {questionList.map((question, index) => {
        return (
          <Accordion className="question-list-item" expanded key={index}>
            <AccordionDetails style={{ padding: 0 }}>
              <div className="questionList_element">
                <div className="category">
                  <Typography
                    variant="subtitle2"
                    className="text"
                    color="primary"
                  >
                    {getTagById(question.tag_id)?.name}
                  </Typography>
                </div>
                <Typography
                  sx={{ position: 'absolute', right: '0px', top: '8px' }}
                >
                  {moment(convertUtcToIst(question.created_at)).fromNow()}
                </Typography>
                <div className="details_box">
                  <div className="questionBody">
                    <Typography variant="subtitle2" className="question_text">
                      {index + 1}.
                      {question.question_text
                        ? ReactHtmlParser(question.question_text)
                        : 'No question body available'}
                    </Typography>
                  </div>
                  <div className="utils">
                    {question.question_status === 'Draft' && (
                      <>
                        <NavLink
                          style={{ display: 'flex' }}
                          to={`/l/question/edit/${question.id}`}
                        >
                          <Icon className="edit">edit</Icon>
                        </NavLink>
                        <Icon
                          className="del"
                          onClick={() => handleDeleteAction(question.id)}
                        >
                          delete
                        </Icon>
                      </>
                    )}
                    {question?.question_status && (
                      <Chip
                        variant="outlined"
                        label={question.question_status}
                        color={getStatusChipColor(question)}
                        sx={[
                          {
                            fontWeight: '600',
                          },
                          question.question_status === 'UnderReview' && {
                            color: '#EE9D00',
                            borderColor: '#EE9D00',
                          },
                          question.question_status === 'Active' && {
                            color: '#388E3C',
                            borderColor: '#388E3C',
                          },
                          question.question_status === 'Blocked' && {
                            color: '#D60505',
                            borderColor: '#D60505',
                          },
                          question.question_status === 'Draft' && {
                            color: '#76767F',
                            borderColor: '#76767F',
                          },
                        ]}
                      />
                    )}
                    <Grid container>
                      <NavLink
                        to={`/l/question/${question.id}`}
                        state={{
                          question: question,
                          tag: tag,
                          showQuestionStats: true,
                        }}
                      >
                        <Button
                          aria-label="view details"
                          color="primary"
                          variant="contained"
                          size="small"
                          className="btn"
                        >
                          View Details
                        </Button>
                      </NavLink>
                    </Grid>
                  </div>
                </div>
                {question.question_status === 'Blocked' &&
                  question.rejection_reason && (
                    <div className="blockReason">
                      {question.rejection_reason}
                    </div>
                  )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default List;
