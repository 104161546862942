import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';

import reducer from './reducers';
import AllRoutes from './routes';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import config from './config';

import './index.scss';

function configureStore(initialState) {
  const middlewares = [thunkMiddleware];

  // Log only in development
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  const enhancer = compose(applyMiddleware(...middlewares));
  return createStore(reducer, initialState, enhancer);
}

const store = configureStore({});

const rollbarConfig = {
  accessToken: config.ROLLBAR_ACCESS_TOKEN,
  environment: config.ENV,
};

const root = createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RollbarProvider config={rollbarConfig}>
          <SnackbarProvider>
            <RollbarErrorBoundary>
              <Provider store={store}>
                <Router>
                  <AllRoutes />
                </Router>
              </Provider>
            </RollbarErrorBoundary>
          </SnackbarProvider>
        </RollbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
