import ReviewIcon from '../components/AdminFeatures/img/reviewIcon.svg';
import AdminReferrals from '../components/AdminFeatures/img/AdminReferrals.svg';
import BulkEmail from '../components/AdminFeatures/img/BulkEmail.svg';
import CheckBugs from '../components/AdminFeatures/img/CheckBugs.svg';
import CommentReport from '../components/AdminFeatures/img/CommentReport.svg';
import DataManagement from '../components/AdminFeatures/img/DataManagement.svg';
import Domains from '../components/AdminFeatures/img/Domains.svg';
import Reported from '../components/AdminFeatures/img/Reported.svg';
import StoreAdmin from '../components/AdminFeatures/img/StoreAdmin.svg';

export const featureArray = [
  { id: 'review', name: 'Review', icon: ReviewIcon, link: '/l/underReview' },
  {
    id: 'bulk-email',
    name: 'Bulk Email',
    icon: BulkEmail,
    link: '/l/bulkEmail',
  },
  { id: 'domains', name: 'Domains', icon: Domains, link: '/l/domainList' },
  {
    id: 'referrals',
    name: 'Admin Referrals',
    icon: AdminReferrals,
    link: '/l/referral',
  },
  { id: 'store', name: 'Store Admin', icon: StoreAdmin, link: '/l/storeAdmin' },
  {
    id: 'data-management',
    name: 'Data Management',
    icon: DataManagement,
    link: '/l/datamanagement',
  },
  { id: 'reported', name: 'Reported', icon: Reported, link: '/l/reported' },
  {
    id: 'comment',
    name: 'Comment Report',
    icon: CommentReport,
    link: '/l/comment-reports',
  },
  {
    id: 'check-bugs',
    name: 'Check Bugs',
    icon: CheckBugs,
    link: '/l/reportedBugs',
  },
  { id: 'badges', name: 'Badge Admin', icon: CheckBugs, link: '/l/badgeAdmin' },
  {
    id: 'levels',
    name: 'Level Admin',
    icon: CheckBugs,
    link: '/l/level-admin',
  },
  {
    id: 'review-orgs',
    name: 'Review Orgs',
    icon: AdminReferrals,
    link: '/l/reviewOrgs',
  },
  {
    id: 'review-jobs',
    name: 'Review Jobs',
    icon: StoreAdmin,
    link: '/l/reviewJobs',
  },
  {
    id: 'college-milestone',
    name: 'College Milestone',
    icon: StoreAdmin,
    link: '/l/college/milestone',
  },
  {
    id: 'admin-degrees',
    name: 'Admin Degrees',
    icon: StoreAdmin,
    link: '/l/admin/degrees',
  },
  {
    id: 'email-preferences',
    name: 'Email Preference',
    icon: StoreAdmin,
    link: '/l/admin/email-preference',
  },
  {
    id: 'coupon',
    name: 'Admin Coupon',
    icon: StoreAdmin,
    link: '/l/admin/coupon',
  },
  {
    id: 'admin-user-management',
    name: 'User Management',
    icon: ReviewIcon,
    link: '/l/user-management',
  },
];

export const SALARY_FREQUENCY = [
  { id: 'Weekly', label: 'Per Week' },
  { id: 'Yearly', label: 'Per Annum' },
  { id: 'Monthly', label: 'Per Month' },
];
