import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './index.scss';
import SidebarHeader from './SidebarHeader';
import { openDomainModal } from '../../actions/domain';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Pinnedicon from './img/pin.svg';
import Pinicon from './img/topin.svg';
import FeedbackIcon from './img/feedback.svg';

import Hidden from '@mui/material/Hidden';
import {
  BottomBarContainer,
  BottomBarGrid,
  BottomBarItem,
  BottomBarText,
  TextwithPin,
  FeedBack,
  FeedbackButton,
  useStyles,
} from './SidebarStyledComponent';
import { getInitialOptions } from '../../constants/Sidebar';

export default function Sidebar({
  handleDrawerClose,
  open,
  user,
  isMobile,
  domains = [],
}) {
  const DrawerList = styled(List)(() => ({
    background: 'linear-gradient(176.02deg, #25507B 0%, #3572B0 100%)',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
      borderRadius: '4px',
    },
  }));

  const userInfo = user.data;
  const isAdmin = userInfo?.user_type === 'Admin';
  const isSales = userInfo?.user_type === 'SalesExecutive';

  const location = useLocation();
  const initialOptions = getInitialOptions(isAdmin, isSales);

  const [options, setOptions] = useState(initialOptions);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const handlePinToggle = (id) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) =>
        option.id === id
          ? { ...option, pinned: !option.pinned }
          : { ...option, pinned: false },
      );

      const pinnedOptions = updatedOptions.filter((option) => option.pinned);
      const pinnedOptionIds = pinnedOptions.map((option) => option.id);
      localStorage.setItem('pinnedOption', JSON.stringify(pinnedOptionIds));

      const url = pinnedOptions?.[0]?.url || '';
      localStorage.setItem('pinnedOptionUrl', JSON.stringify(url));

      return updatedOptions;
    });
  };

  useEffect(() => {
    if (!user?.data?.selected_domain) {
      dispatch(openDomainModal());
    }
  }, [user?.data?.selected_domain]);

  useEffect(() => {
    const pinnedOption = JSON.parse(localStorage.getItem('pinnedOption')) || [];
    const updatedOptions = options.map((option) => ({
      ...option,
      pinned: pinnedOption.includes(option.id),
    }));
    setOptions(updatedOptions);
  }, []);

  useEffect(() => {
    const currentPathname = location.pathname;
    const initialActiveOption = options.find(
      (option) => `/${option.url}` === currentPathname,
    );
    if (initialActiveOption) {
      setActiveOption(initialActiveOption.name);
    }
    localStorage.removeItem('onboarding_done');
  }, [location.pathname]);

  const pinnedOption = options.filter((option) => option.pinned);
  const unpinnedOptions = options.filter((option) => !option.pinned);

  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedDomain = domains?.find(
    (domain) => domain.id === userInfo.selected_domain,
  );

  const handleMenuItemSelect = (name, url) => {
    if (!isMobile) {
      if (name.includes('Jobs')) {
        window.open(url, '_blank');
      } else {
        setActiveOption(name);
        return;
      }
    } else {
      setShowAllOptions(false);
    }
  };

  const handleMoreClick = () => {
    setShowAllOptions(!showAllOptions);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.bottom-bar')) {
      setShowAllOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <main style={{ position: 'relative' }}>
      <Hidden smDown>
        {isMobile && open && (
          <Box
            className="sidebar_backdrop"
            onClick={() => handleDrawerClose()}
          />
        )}
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className="drawer"
          classes={{
            root: classnames(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
            paper: classnames(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <SidebarHeader open={open} handleDrawerClose={handleDrawerClose} />
          <Divider />
          <div className="drawer__last">
            <div className="drawer__last__domain">
              <Grid
                onClick={() => {
                  if (isMobile) handleDrawerClose();
                  dispatch(openDomainModal());
                }}
                className="walkthrough_target_domain domain_box"
              >
                <Typography className="ellipsis" style={{ flexGrow: 1 }}>
                  {selectedDomain ? selectedDomain.name : 'Select Domain'}
                </Typography>
                <EditIcon fontSize="medium" />
              </Grid>
            </div>
          </div>
          <DrawerList>
            <div style={{ height: 'fit-content', overflow: 'auto' }}>
              {pinnedOption.map((option) => (
                <NavLink
                  to={`/${option.url}`}
                  onClick={() => handleMenuItemSelect(option.name, option.url)}
                  key={option.id}
                >
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <Tooltip
                      title={option.name}
                      disableHoverListener={open}
                      placement="right"
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          background:
                            option.name === activeOption
                              ? '#6186B8'
                              : 'transparent',
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            ml: open ? 0.5 : 3,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                          style={{ marginRight: '10' }}
                        >
                          <img src={option.icon} width={20}></img>
                        </ListItemIcon>
                        <TextwithPin>
                          <ListItemText
                            primary={option.name}
                            sx={{ opacity: open ? 1 : 0 }}
                            style={{ color: 'white' }}
                          />
                          <img
                            src={option.pinned && Pinnedicon}
                            width={20}
                            onClick={() => handlePinToggle(option.id)}
                            style={{ display: open ? 'inline' : 'none' }}
                          />
                        </TextwithPin>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                </NavLink>
              ))}
              {unpinnedOptions.map((option) => (
                <NavLink
                  to={
                    option.name?.includes('Jobs')
                      ? `${option.url}`
                      : `/${option.url}`
                  }
                  onClick={() => handleMenuItemSelect(option.name, option.url)}
                  className="unpinned"
                  key={option.id}
                  target={option.name === 'Jobs by Abekus' ? '_blank' : ''}
                >
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <Tooltip
                      title={
                        <Typography fontSize={'10px'} padding={'5px'}>
                          {option.name}
                        </Typography>
                      }
                      disableHoverListener={open}
                      placement="right"
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          background:
                            option.name === activeOption
                              ? '#6186B8'
                              : 'transparent',
                        }}
                        style={{ marginRight: '10' }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            ml: open ? 0.5 : 3,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <img src={option.icon} width="20px"></img>
                        </ListItemIcon>
                        <TextwithPin>
                          <ListItemText
                            primary={option.name}
                            sx={{ opacity: open ? 1 : 0 }}
                            style={{ color: 'white' }}
                          />
                          {open && (
                            <img
                              src={!option.pinned && Pinicon}
                              style={{ opacity: !isMobile ? 1 : 0 }}
                              width="20px"
                              onClick={() => handlePinToggle(option.id)}
                              className="pin-icon"
                            />
                          )}
                        </TextwithPin>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                </NavLink>
              ))}
            </div>
          </DrawerList>
          <NavLink to={`/l/contact-us`} onClick={handleMenuItemSelect}>
            <FeedBack key={'11'}>
              <Tooltip
                title={'FeedBack'}
                disableHoverListener={open}
                placement="right"
              >
                <FeedbackButton>
                  <ListItemIcon
                    sx={{
                      ml: open ? 0 : -1,
                    }}
                  >
                    <img src={FeedbackIcon} width="20px"></img>
                  </ListItemIcon>
                  <ListItemText
                    primary={'Feedback'}
                    sx={{ opacity: open ? 1 : 0 }}
                    style={{ color: 'white' }}
                  />
                </FeedbackButton>
              </Tooltip>
            </FeedBack>
          </NavLink>
        </Drawer>
      </Hidden>

      {!(
        location?.pathname.includes('/answer') ||
        location?.pathname.includes('/voting')
      ) && (
        <Hidden smUp>
          <BottomBarContainer className="bottom-bar">
            {showAllOptions && (
              <BottomBarGrid
                style={{
                  borderBottom: '2px solid #FFFFFF66',
                }}
              >
                {initialOptions.slice(3).map((option) => (
                  <BottomBarItem
                    key={option.id}
                    to={
                      option.name?.includes('Jobs')
                        ? `${option.url}`
                        : `/${option.url}`
                    }
                    onClick={() =>
                      handleMenuItemSelect(option.name, option.url, option.url)
                    }
                    target={option.name === 'Jobs by Abekus' ? '_blank' : ''}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img src={option.icon} alt={option.name} />
                      <BottomBarText>{option.name}</BottomBarText>
                    </Box>
                  </BottomBarItem>
                ))}
                <BottomBarItem
                  to={`/l/contact-us`}
                  onClick={handleMenuItemSelect}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={FeedbackIcon}
                      style={{ width: '16px', margin: '0 auto' }}
                      alt="Feedback"
                    />
                    <BottomBarText>FeedBack</BottomBarText>
                  </Box>
                </BottomBarItem>
                <BottomBarItem
                  onClick={() => {
                    dispatch(openDomainModal());
                    handleMoreClick();
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <EditIcon style={{ width: '16px', margin: '0 auto' }} />
                    <BottomBarText>
                      {selectedDomain ? selectedDomain.name : 'Select Domain'}
                    </BottomBarText>
                  </Box>
                </BottomBarItem>
              </BottomBarGrid>
            )}
            <BottomBarGrid>
              {initialOptions.slice(0, 3).map((option) => (
                <BottomBarItem
                  key={option.id}
                  to={
                    option.name?.includes('Jobs')
                      ? `${option.url}`
                      : `/${option.url}`
                  }
                  onClick={() => handleMenuItemSelect(option.name, option.url)}
                  target={option.name === 'Jobs by Abekus' ? '_blank' : ''}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img src={option.icon} alt={option.name} />
                    <BottomBarText>{option.name}</BottomBarText>
                  </Box>
                </BottomBarItem>
              ))}
              <BottomBarItem
                onClick={handleMoreClick}
                style={{ marginTop: '-4px' }}
              >
                <Typography
                  style={{
                    fontSize: '10px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span style={{ fontSize: '14px', fontWeight: '900' }}>
                    {'...'}
                  </span>
                  <span>More</span>
                </Typography>
              </BottomBarItem>
            </BottomBarGrid>
          </BottomBarContainer>
        </Hidden>
      )}
    </main>
  );
}
