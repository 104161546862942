import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const DoughnutChart = ({
  data,
  colors,
  xticks = false,
  yticks = false,
  maintainAspectRatio = true,
  type,
}) => {
  const options = {
    scales: {
      x: {
        grid: { display: false },
        ticks: xticks,
        display: false,
      },
      y: {
        grid: { display: false },
        display: yticks,
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: maintainAspectRatio,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: type === 'playCompetition' && '80%',
    labels: ['Correct', 'Incorrect', 'Timed Out', 'Skipped'],
  };

  const doughnutData = {
    labels:
      type === 'playCompetition'
        ? options.labels.map((label) => `${label}`)
        : Object.keys(data).map((name) => `Level ${name}`),
    datasets: [
      {
        data: Object.values(data),
        borderColor: colors || 'rgba(255, 99, 132, 0.2)',
        backgroundColor: colors || 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut options={options} data={doughnutData} />;
};

export default DoughnutChart;
