import { styled } from '@mui/styles';

export const StyledParentComponent = styled('div')(({ theme }) => ({
  '& .load-comments-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    padding: '25px 0',
  },

  '& .comments-loader': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .discussion-pane-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '3vh',
  },

  '& .discussion-heading': {
    color: theme.palette.primary.main,
  },

  '& .new-discussion': {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    padding: '1% 2.5%',
    width: '100%',
    gap: '2%',
    justifyContent: 'center',
    boxShadow: `0 0 2px ${theme.palette.component.shadowColor}`,
    borderRadius: '8px',
  },

  '& .new-discussion-text-wrapper': {
    flexGrow: 1,
  },

  '& .new-discussion-label': {
    color: theme.palette.common.black,
    fontSize: '0.8em',
    fontWeight: 500,
    display: 'block',
  },

  '& .no-comments-wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5% 5%',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor}`,
    borderRadius: '10px',
  },

  '& .no-comments-header': {
    color: theme.palette.primary.main,
  },

  '& .main-comment-wrapper': {
    padding: '2% 0',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    gap: '20px',
    fontSize: '1rem',
  },

  '& .comment-card-wrapper': {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'flex-start',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 0 2px ${theme.palette.component.shadowColor}`,
    padding: '2% 2%',
    borderRadius: '10px',
  },

  '& .comment-main': {
    flexGrow: 1,
    width: '97%',
  },

  '& .sub-comment-wrapper': {
    fontSize: '0.9em',
    boxShadow: 'none',
    padding: '0',
  },

  '& .votes-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3%',
    fontWeight: 500,
    color: theme.palette.text.main,
  },

  '& .vote-buttons': {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '0',
  },

  '& .vote-buttons > img': {
    verticalAlign: 'middle',
  },

  '& .downvote-icon': {
    transform: 'rotate(180deg)',
  },

  '& .comment-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .comment-user-details': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: theme.palette.text.main,
  },

  '& .comment-user-sub-details': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
  },

  '& .user-avatar': {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    objectPosition: 'center',
    objectFit: 'cover',
  },

  '& .comment-type': {
    fontStyle: 'italic',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.common.warning}`,
    paddingInline: '15px',
    fontWeight: 400,
    color: theme.palette.common.warning,
  },

  '& .comment-type-blue': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },

  '& .comment-card-text': {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  '& .comment-text': {
    whiteSpace: 'normal',
    color: theme.palette.common.black,
    wordWrap: 'normal',
    wordBreak: 'break-word',
  },

  '& .comment-buttons': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .comment-buttons > button': {
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
    color: theme.palette.text.main,
    cursor: 'pointer',
  },

  '& .comment-buttons, & .sub-comment-wrapper, & .new-comment-input-wrapper-sub': {
    width: '100%',
    marginLeft: '3%',
  },

  '& .load-more-comments': {
    flexGrow: '1',
    marginLeft: '3%',
  },

  '& .load-more-comments > button': {
    cursor: 'pointer',
    border: 'none',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    float: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    '& .discussion-pane-wrapper': {
      fontSize: '0.7rem',
    },

    '& .comment-buttons': {
      width: 'auto',
      marginLeft: '0',
    },

    '& .comment-buttons > button': {
      fontSize: '0.8em',
    },

    '& .comment-card-wrapper': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1% 1%',
    },

    '& .comment-main': {
      width: '100%',
    },

    '& .votes-container': {
      width: 'auto',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '5px',
      gap: '5px',
    },

    '& .sub-comment-wrapper, & .new-comment-input-wrapper-sub': {
      marginLeft: '5%',
    },

    '& .comment-type': {
      fontSize: '0.7em',
    },
  },
  '@media screen and (max-width: 350px)': {
    '& .comment-type': {
      display: 'none',
    },
  },
}));
