import * as types from './types';
import apiClient from '../api/apiclient';
import { getDashboard } from './dashboard';

export function initRememberCategoryId() {
  const categoryRememberList =
    JSON.parse(localStorage.getItem('abekus_instructions-seen-categories')) ||
    [];

  return {
    type: types.REMEMBER_CATEGORY_ID,
    data: categoryRememberList,
  };
}

export function rememberCategoryId(id) {
  const categoryRememberList =
    JSON.parse(localStorage.getItem('abekus_instructions-seen-categories')) ||
    [];
  if (!categoryRememberList.includes(id)) {
    categoryRememberList.push(id);
  }

  localStorage.setItem(
    'abekus_instructions-seen-categories',
    JSON.stringify(categoryRememberList),
  );

  return {
    type: types.REMEMBER_CATEGORY_ID,
    data: categoryRememberList,
  };
}

export function removeCategoryId(id) {
  const categoryRememberList =
    JSON.parse(localStorage.getItem('abekus_instructions-seen-categories')) ||
    [];

  const matchedIndex = categoryRememberList.findIndex(
    (savedId) => savedId === id,
  );

  if (matchedIndex !== -1) {
    categoryRememberList.splice(matchedIndex, 1);
  }

  localStorage.setItem(
    'abekus_instructions-seen-categories',
    JSON.stringify(categoryRememberList),
  );

  return {
    type: types.REMEMBER_CATEGORY_ID,
    data: categoryRememberList,
  };
}

export function setPreferencesStart() {
  return {
    type: types.SET_PREFERENCES_START,
  };
}

export function setPreferencesSuccess({ data }) {
  return {
    type: types.SET_PREFERENCES_SUCCESS,
    data: data,
  };
}

export function setPreferencesFail() {
  return {
    type: types.SET_PREFERENCES_FAIL,
  };
}

// toDo -modify the comments
export function setPreferences(tagId, subCategories) {
  return (dispatch) => {
    if (!tagId) return;
    dispatch(setPreferencesStart());

    apiClient
      .getTagApi()
      .tagApiSetTagPreference(tagId, subCategories)
      .then((resp) => {
        dispatch(setPreferencesSuccess({ data: resp.data }));
        dispatch(getStats(tagId));
      })
      .catch(() => {
        dispatch(setPreferencesFail());
      });
  };
}

export function getStatsSuccess({ data }) {
  return {
    type: types.GET_STATS_SUCCESS,
    data: data,
  };
}

export function getStatsFail() {
  return {
    type: types.GET_STATS_FAIL,
  };
}

export function getStats(tagId, callback, errorCallback) {
  return (dispatch) => {
    if (!tagId) return;
    dispatch({
      type: types.GET_STATS_START,
    });

    apiClient
      .getTagApi()
      .tagApiGetTagStartStatistics(tagId)
      .then((resp) => {
        dispatch(getStatsSuccess({ data: resp.data }));
        callback?.();
      })
      .catch((err) => {
        dispatch(getStatsFail());
        errorCallback?.(err.response.data);
      });
  };
}

export function unlockCategory(tagId) {
  return (dispatch) => {
    dispatch({
      type: types.UNLOCK_CATEGORY_START,
      payload: tagId,
    });
    apiClient
      .getCreditsApi()
      .creditsApiUseCredits({
        action_type: 'CategoryUnlock',
        tag_id: tagId,
      })
      .then((resp) => {
        dispatch({
          type: types.UNLOCK_CATEGORY_SUCCESS,
          payload: { ...resp.data, tagId },
        });
        const path = localStorage.getItem('path');
        if (path == '/dashboard') {
          dispatch(getDashboard());
        }
      })
      .catch(() => {});
  };
}
