import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const totalReferrals = createReducer(
  {
    list: [],
    isFetchingAllReferrals: false,
  },
  {
    [types.GET_TOTAL_REFERRALS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingAllReferrals: true,
      });

      return newState;
    },
    [types.GET_TOTAL_REFERRALS_SUCCESS](state, action) {
      return {
        ...state,
        isFetchingAllReferrals: false,
        list: action.data,
        errorStatus: null,
      };
    },
    [types.GET_TOTAL_REFERRALS_FAIL](state, action) {
      return {
        ...state,
        isFetchingAllReferrals: false,
        errorStatus: action.data,
      };
    },
  },
);
