import React, { useRef, useState } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from './images/twitter_logo_new.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import Result from './CompetitionResult';
import { CustomDialogBox } from '../DialogBox';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import config from '../../config';

import LeaderboardTable from '../Leaderboard/LeaderboardTable';
import helpers from '../../lib/helpers';

import { styles } from './styles';
import { Hidden } from '@mui/material';
import NoCreditDialog from '../Dialog/NoCreditDialog';
import UnlockDialog from '../Dialog/UnlockDialog';
import UploadResumeDialog from './UploadResumeDialog';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import Scholar from '../../images/scholar.svg';
import gemsWhite from '../../images/gemsWhite.svg';
import Rank from './images/rank.svg';
import Gem from '../../images/Gemsnew.svg';
import FirstRank from './images/firstRank.svg';
import SecondRank from './images/secondRank.svg';
import ThirdRank from './images/thirdRank.svg';
import Back from './images/back.png';
import CompetitionStartDialog from './CompetitionStartDialog';
import {
  getCertificatePath,
  getCompetitionById,
  getCompetitionDetailAnalytics,
  getRecommendations,
  getStartStatistics,
  postRegistration,
} from '../../actions/competition';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLeaderboard } from '../../actions/leaderboard';

const changeMetaDetails = ({ newMetaDetails, metaRef }) => {
  const title = document.querySelector('title');
  const desc = document.querySelector('meta[name="description"]');

  if (metaRef && !metaRef.current?.title) {
    metaRef.current = {
      title: title.innerHTML,
      desc: desc.content,
    };
  }

  title.innerHTML = newMetaDetails.title;
  desc.content = newMetaDetails.desc;
};

const CompetitionDetails = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const textInputRef = useRef();
  const [showResult, setShowResult] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [
    isRegisteredCompetitionDialogVisible,
    setIsRegisteredCompetitionDialogVisible,
  ] = useState(false);
  const [
    isUploadReasumeDialogVisible,
    setIsUploadReasumeDialogVisible,
  ] = useState(false);
  const [
    isInsufficientCreditsDialogVisible,
    setIsInsufficientCreditsDialogVisible,
  ] = useState(false);
  const [resumeUrl, setResumeUrl] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openAnalyticsDialog, setOpenAnalyticsDialog] = useState(false);
  const { isLoggedOut, loginSuccess } = useSelector((state) => state.auth);
  const { resume_url, credits, data } = useSelector((state) => state.user);
  const {
    details,
    scoreboardList,
    adminAnalyticsData,
    statisticsData,
  } = useSelector((state) => state.competitions);
  const prevIsLoggedOutRef = useRef(null);
  const metaRef = useRef({});

  useEffect(() => {
    prevIsLoggedOutRef.current = isLoggedOut;
    const competitionId = params.competitionId;
    dispatch(getCompetitionById(competitionId)).then((competition) => {
      dispatch(getStartStatistics(competition.id));
      dispatch(getCompetitionDetailAnalytics(competition.id));
      const resumeUpload = helpers.getURLParameterByName('resume-upload');
      if (resumeUpload && !isLoggedOut && competition?.is_registered) {
        setIsUploadReasumeDialogVisible(true);
      }
    });

    return () => {
      changeMetaDetails({ newMetaDetails: metaRef.current });
    };
  }, []);

  useEffect(() => {
    if (details.name) {
      const newMetaDetails = {
        title: details.name,
        desc: helpers.stripHTMLTags(details.social_description),
      };
      changeMetaDetails({ newMetaDetails, metaRef });
    }
  }, [details]);

  useEffect(() => {
    dispatch(getCompetitionById(params.competitionId))
      .then((competition) => {
        dispatch(getRecommendations(competition.id));
        dispatch(
          getLeaderboard({
            competitionId: competition.id,
            leaderboardType: 'competition',
          }),
        );
      })
      .catch(() => {
        // Unable to fetch competition details
      });
  }, [params.competitionId]);

  useEffect(() => {
    if (
      prevIsLoggedOutRef.current !== null &&
      prevIsLoggedOutRef.current !== isLoggedOut
    ) {
      window.location.reload();
    }

    prevIsLoggedOutRef.current = isLoggedOut;
  }, [isLoggedOut]);
  useEffect(() => {
    if (resume_url && resume_url !== resumeUrl) setResumeUrl(resume_url);
  }, [resume_url]);

  const handleShowModal = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleShowResult = () => {
    setShowResult(true);
  };

  const onBack = () => {
    setShowResult(false);
  };

  const handleViewAll = () => {
    const isOngoing = moment().isBefore(moment(details?.end_date));
    navigate('/competition', { state: { isOngoing } });
  };

  const handleUnlockCancel = () => {
    setIsRegisteredCompetitionDialogVisible(false);
  };

  const handleRegisterConfirm = () => {
    const competitionDetails = details || {};
    const { search } = location;
    if (credits < details?.participation_credits ?? 0) {
      setIsInsufficientCreditsDialogVisible(true);
    } else {
      if (loginSuccess) {
        dispatch(postRegistration(competitionDetails.id));
      } else {
        navigate(`/signup${search}`, {
          state: {
            background: location,
            from: location,
          },
        });
      }
    }
    setIsRegisteredCompetitionDialogVisible(false);
  };

  const handleRegister = () => {
    const { search } = location;
    if (isLoggedOut) {
      navigate(`/signup${search}`, {
        state: {
          background: location,
          from: location,
        },
      });
    } else {
      if (
        ['FresherJob', 'Internship', 'ExperiencedJob'].includes(
          details?.competition_type,
        ) &&
        !resumeUrl
      ) {
        setIsUploadReasumeDialogVisible(true);
      } else {
        setIsRegisteredCompetitionDialogVisible(true);
      }
    }
  };

  const handleInsufficuentCreditDialogClose = () => {
    setIsInsufficientCreditsDialogVisible(false);
  };

  const handlePlay = () => {
    const competitionDetails = details || {};
    if (
      ['FresherJob', 'Internship', 'ExperiencedJob'].includes(
        details?.competition_type,
      ) &&
      resumeUrl.length === 0
    ) {
      setIsUploadReasumeDialogVisible(true);
    } else {
      navigate(`/l/answer/${competitionDetails.id}/competition`);
    }
  };

  const renderInvite = (competitionDetails) => {
    const { classes } = props;
    const referralURL = `${config.WEBSITE_URL}/competition/${competitionDetails.alias}?ref=${data.referral_hash}`;
    return (
      <Grid item>
        <div className={classes.referralLink}>
          <input
            type="hidden"
            value={referralURL}
            ref={textInputRef}
            className={classes.referralLinkInput}
            title={referralURL}
          />
          {document.queryCommandSupported('copy') ? (
            <>
              {copySuccess ? (
                <Typography variant="caption" color="success">
                  {copySuccess}
                </Typography>
              ) : (
                <a style={{ cursor: 'pointer' }} onClick={copyToClipboard}>
                  <Typography variant="caption" color="primary">
                    <LinkIcon />
                  </Typography>
                </a>
              )}
            </>
          ) : null}
        </div>
      </Grid>
    );
  };

  const renderSocialShare = (competitionDetails) => {
    const referralURL = `${config.WEBSITE_URL}/competition/${competitionDetails.alias}?ref=${data?.referral_hash}`;

    return (
      <Grid item style={{ display: 'flex' }}>
        <Typography variant="body1" gutterBottom>
          Share
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <FacebookShareButton
              url={referralURL}
              quote={helpers.stripHTMLTags(
                competitionDetails.social_description,
              )}
            >
              <FacebookIcon color="primary" />
            </FacebookShareButton>
          </Grid>
          <Grid item>
            <TwitterShareButton
              url={referralURL}
              title={helpers.stripHTMLTags(
                competitionDetails.social_description,
              )}
            >
              <img src={TwitterIcon} />
            </TwitterShareButton>
          </Grid>
          <Grid item>
            <WhatsappShareButton
              url={referralURL}
              title={helpers.stripHTMLTags(
                competitionDetails.social_description,
              )}
            >
              <WhatsAppIcon color="success" />
            </WhatsappShareButton>
          </Grid>
          <Grid item>
            <LinkedinShareButton
              url={referralURL}
              title={competitionDetails.name}
              summary={helpers.stripHTMLTags(
                competitionDetails.social_description,
              )}
            >
              <LinkedInIcon color="primary" />
            </LinkedinShareButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRegistrationTime = (competitionDetails) => {
    if (
      competitionDetails.is_registered &&
      moment().isBetween(
        competitionDetails.start_date,
        competitionDetails.end_date,
      )
    ) {
      return (
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Typography variant="caption" color="textSecondary">
              Competition is active
            </Typography>
            <Button
              variant="contained"
              color="success"
              fullWidth={true}
              onClick={handleShowModal}
            >
              Start
            </Button>
          </Grid>
          <UploadResumeDialog
            visible={isUploadReasumeDialogVisible}
            onClose={() => setIsUploadReasumeDialogVisible(false)}
            dialogTitleMessage="Upload Your Resume"
            onConfirm={() => {
              setIsUploadReasumeDialogVisible(false);
              navigate(`/l/answer/${competitionDetails.id}/competition`);
            }}
          />
        </Grid>
      );
    } else if (
      competitionDetails.is_registered &&
      moment().isBefore(competitionDetails.start_date)
    ) {
      return (
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Button
              variant="contained"
              color="success"
              fullWidth={true}
              disabled
            >
              Start
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              Competition will be active after{' '}
              {moment(competitionDetails.start_date).fromNow(true)}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (
      competitionDetails.is_registered &&
      moment().isAfter(competitionDetails.end_date)
    ) {
      return (
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              onClick={handleShowResult}
              style={{ marginTop: 10 }}
            >
              View Results
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              Competition has ended
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (
      !competitionDetails.is_registered &&
      moment().isBetween(
        competitionDetails.reg_start_date,
        competitionDetails.reg_end_date,
      )
    ) {
      return (
        <>
          <Grid container spacing={2} item>
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              container
              spacing={1}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={handleRegister}
              >
                Register
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                Registration will close&nbsp;
                {moment(competitionDetails.reg_end_date).fromNow()}
              </Typography>
            </Grid>
          </Grid>
          <UploadResumeDialog
            visible={isUploadReasumeDialogVisible}
            onClose={() => setIsUploadReasumeDialogVisible(false)}
            dialogTitleMessage="Upload Your Resume"
            onConfirm={() => {
              setIsUploadReasumeDialogVisible(false);
              setIsRegisteredCompetitionDialogVisible(true);
            }}
          />
          <UnlockDialog
            visible={isRegisteredCompetitionDialogVisible}
            dialogTitleMessage={
              'Are you sure you want to register for this competition?'
            }
            onConfirm={handleRegisterConfirm}
            onClose={handleUnlockCancel}
            category={{
              category_unlock_credits: details?.participation_credits ?? 0,
            }}
          />

          <NoCreditDialog
            visible={isInsufficientCreditsDialogVisible}
            onClose={handleInsufficuentCreditDialogClose}
            // category={selectedCategory}
            credits={credits ?? 0}
          />
        </>
      );
    } else if (moment().isBefore(competitionDetails.reg_start_date)) {
      return (
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled
            >
              Register
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              Registration will start in{' '}
              {moment(competitionDetails.reg_start_date).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled
            >
              Register
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              Registrations for this competition are closed
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textInputRef.current.value).then(
      function() {},
      function() {},
    );
    setCopySuccess('Copied!', () => {
      setTimeout(() => {
        setCopySuccess('');
      }, 3000);
    });
  };

  const certificateDownloadHandler = () => {
    const competitionDetails = details || {};
    dispatch(getCertificatePath(competitionDetails.id));
  };

  const handleAnalyticsClose = () => {
    setOpenAnalyticsDialog(false);
  };
  const { classes } = props;
  const competitionDetails = details || {};

  return (
    <>
      {!showResult && (
        <>
          <img
            src={competitionDetails.competition_header_url}
            alt={competitionDetails.name}
            className={classes.bgImage}
          />
          <img
            src={Back}
            alt="Go Back"
            className={classes.back}
            onClick={handleViewAll}
          />
        </>
      )}
      <Container>
        {!showResult && (
          <Paper className={classes.competitionDetailsWrapper} elevation="0">
            <Grid
              item
              container
              className={classes.competitionDetailsHeader}
              spacing={1}
            >
              <Grid item md={2} sm={4} xs={4}>
                <img
                  src={competitionDetails.image_url}
                  alt={competitionDetails.name}
                  className={classes.competitionDetailsImage}
                />
              </Grid>
              <Grid item md={7} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.competitionName}
                >
                  {competitionDetails.name || ''}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {moment(competitionDetails.start_date).format(
                    'MMM DD, YYYY hh:mma',
                  )}{' '}
                  -{' '}
                  {moment(competitionDetails.end_date).format(
                    'MMM DD, YYYY hh:mma',
                  )}
                </Typography>
                {competitionDetails.category_names &&
                  competitionDetails.category_names.length &&
                  competitionDetails.category_names.map((category) => (
                    <Chip
                      key={category}
                      size="small"
                      label={category}
                      className={classes.categoryChip}
                    />
                  ))}
              </Grid>
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                style={{ position: 'relative' }}
              >
                <Box display="flex" justifyContent="flex-end">
                  {renderSocialShare(competitionDetails)}
                  {renderInvite(competitionDetails)}
                </Box>
                <Box>
                  {data?.user_type === 'Admin' && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      onClick={() => setOpenAnalyticsDialog(true)}
                    >
                      Analytics
                    </Button>
                  )}
                </Box>
                <Hidden mdDown>
                  <Box style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    {renderRegistrationTime(competitionDetails)}
                  </Box>
                </Hidden>
              </Grid>
              <Hidden smUp>
                <Grid item container>
                  {renderRegistrationTime(competitionDetails)}
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
        )}

        {/* start */}
        {!showResult ? (
          <Grid container spacing={2}>
            <Grid
              className={classes.competitionDetails}
              item
              md={competitionDetails.rewards_list?.length ? 9 : 12}
              sm={12}
            >
              <Paper
                className={classes.competitionDetailsWrapper}
                elevation="0"
              >
                <Grid item container spacing={4}>
                  <Grid item>
                    <Typography variant="h5" gutterBottom>
                      Description
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.longText}
                    >
                      {ReactHtmlParser(competitionDetails.description)}
                    </Typography>
                  </Grid>
                </Grid>

                {!!competitionDetails.rewards && (
                  <Grid item container spacing={4}>
                    <Grid item>
                      <Typography variant="h5" gutterBottom>
                        Rewards
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.longText}
                      >
                        {ReactHtmlParser(competitionDetails.rewards)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {!!competitionDetails.rules && (
                  <Grid item container spacing={4}>
                    <Grid item>
                      <Typography variant="h5" gutterBottom>
                        Rules
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.longText}
                      >
                        {ReactHtmlParser(competitionDetails.rules)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {!!competitionDetails.additional_notes && (
                  <Grid item container spacing={4}>
                    <Grid item>
                      <Typography variant="h5" gutterBottom>
                        Additional Notes
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.longText}
                      >
                        {ReactHtmlParser(competitionDetails.additional_notes)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {moment().isAfter(competitionDetails.start_date) &&
                scoreboardList &&
                scoreboardList.length ? (
                  <Grid
                    item
                    container
                    spacing={4}
                    direction="column"
                    maxWidth={'calc(100vw - 32px) !important'}
                  >
                    <Grid item>
                      <Typography variant="h5" gutterBottom>
                        Leaderboard
                      </Typography>
                    </Grid>
                    <Grid item maxWidth={'100% !important'} overflow={'auto'}>
                      <LeaderboardTable />
                    </Grid>
                    <Grid container item justifyContent="flex-end">
                      <NavLink
                        to={`/l/leaderboard?competition=${competitionDetails.id}`}
                      >
                        View all participants
                      </NavLink>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
            {competitionDetails.rewards_list?.length ? (
              <Grid item md={3} sm={12} style={{ width: '100%' }}>
                <Paper style={{ paddingRight: '16px' }}>
                  <Grid>
                    <Typography className={classes.rewardsText}>
                      Rewards
                    </Typography>
                  </Grid>
                  <Grid mb={{ xs: '30px' }}>
                    {competitionDetails.rewards_list?.map((list) => (
                      <Card
                        key={list.rank_from}
                        className={
                          list.rank_from === 1
                            ? classes.cardRank_1
                            : list.rank_from === 2
                            ? classes.cardRank_2
                            : list.rank_from === 3
                            ? classes.cardRank_3
                            : classes.cardRank_all
                        }
                      >
                        {' '}
                        {list.rank_from > 3 && (
                          <>
                            <img
                              src={Rank}
                              className={classes.rankTag}
                              alt="rank of user"
                            />
                            <Typography
                              style={{
                                fontWeight: 500,
                                width: '47px',
                                fontSize: '14px',
                              }}
                            >
                              {list.rank_from}
                              {list.rank_to ? -list.rank_to : '+'}
                            </Typography>
                          </>
                        )}
                        {list.rank_from === 1 ? (
                          <img
                            alt="gems icon"
                            src={gemsWhite}
                            width="16px"
                            style={{ marginLeft: '10px' }}
                          />
                        ) : (
                          <img
                            alt="gems icon"
                            src={Gem}
                            width="16px"
                            style={{ marginLeft: '10px' }}
                          />
                        )}{' '}
                        <Typography
                          style={{
                            fontWeight: 500,
                            paddingLeft: '5px',
                            paddingRight: '10px',
                          }}
                        >
                          {' '}
                          {list.gems}
                        </Typography>
                        {list.rank_from < 4 && (
                          <img
                            alt="credit icon"
                            src={CreditIcon}
                            width="18px"
                            style={{ marginLeft: '20px' }}
                          />
                        )}
                        {list.rank_from < 4 && (
                          <Typography
                            style={{
                              fontWeight: 500,
                              paddingLeft: '5px',
                            }}
                          >
                            {list.credits}
                          </Typography>
                        )}
                        {list.rank_from === 1 && (
                          <>
                            <img
                              alt="top scorer icon"
                              src={Scholar}
                              width="18px"
                              style={{ marginLeft: '15px' }}
                            />
                            <Typography
                              style={{
                                fontSize: '11px',
                                fontWeight: 500,
                                width: '78px',
                                paddingLeft: '5px',
                              }}
                            >
                              {' '}
                              Certificate of Achievement
                            </Typography>
                          </>
                        )}
                        {list.rank_from < 4 && list.rank_from > 1 && (
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 500,
                              paddingRight: list.rank_from === 2 ? '31px' : '',
                            }}
                          >
                            {' '}
                            <img
                              alt="top rank holder icon"
                              src={Scholar}
                              width="18px"
                              style={{ marginLeft: '20px' }}
                            />
                            COA
                          </Typography>
                        )}
                        {list.rank_from === 1 && (
                          <img
                            src={FirstRank}
                            alt="top rank holder icon"
                            className={classes.rankIcon}
                          />
                        )}
                        {list.rank_from === 2 && (
                          <img
                            src={SecondRank}
                            alt="second rank holder icon"
                            className={classes.rankIcon}
                          />
                        )}
                        {list.rank_from === 3 && (
                          <img
                            src={ThirdRank}
                            alt="third rank holder icon"
                            className={classes.rankIcon}
                          />
                        )}
                      </Card>
                    ))}
                  </Grid>
                  <Typography className={classes.rewardsBottomText_1}>
                    Everyone else will be awarded 5 Abekus Gems for
                    participation!
                  </Typography>

                  <Typography className={classes.rewardsBottomText_2}>
                    You can redeem these Gems on our newly launched{' '}
                    <Link to="/l/store">Abekus Store!</Link> The best part is,
                    now you can save your earned Gems to redeem better and
                    bigger rewards on the store!
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          <Result
            {...props}
            selectedCompetition={competitionDetails}
            certificateDownloadHandler={certificateDownloadHandler}
            isCertificateAvailable={competitionDetails.is_certificate_available}
            categories={competitionDetails.category_names}
            onBack={onBack}
          />
        )}
        {openAnalyticsDialog ? (
          <CustomDialogBox
            open={openAnalyticsDialog}
            onClose={handleAnalyticsClose}
            maxWidth={'sm'}
            actions={
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={handleAnalyticsClose}
                style={{ height: '40px', width: '150px' }}
              >
                Close
              </Button>
            }
            title={
              <Typography
                style={{
                  color: '#25507B',
                  fontSize: '25px',
                  fontWeight: 500,
                  lineHeight: '48px',
                }}
              >
                Admin Analytics
              </Typography>
            }
          >
            <Typography className={classes.adminAnalyticsData}>
              Competition Generated User :{' '}
              {adminAnalyticsData?.competition_generated_users}
            </Typography>
            <Typography className={classes.adminAnalyticsData}>
              Credits Consumed : {adminAnalyticsData?.credits_consumed}
            </Typography>
            <Typography className={classes.adminAnalyticsData}>
              Days Played : {adminAnalyticsData?.days_played}
            </Typography>
            <Typography className={classes.adminAnalyticsData}>
              New User Added : {adminAnalyticsData?.new_user_added}
            </Typography>
            <Typography className={classes.adminAnalyticsData}>
              Question Played: {adminAnalyticsData?.questions_played}
            </Typography>
            <Typography className={classes.adminAnalyticsData}>
              User Count: {adminAnalyticsData?.user_count}
            </Typography>
          </CustomDialogBox>
        ) : null}
        {openModal ? (
          <CompetitionStartDialog
            openModal={openModal}
            handleModalClose={handleModalClose}
            competitionDetails={competitionDetails}
            handlePlay={handlePlay}
            startStatistics={statisticsData}
          />
        ) : null}
      </Container>
    </>
  );
};

export default withStyles(styles)(CompetitionDetails);
