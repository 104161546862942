import theme from '../theme';

export const callStatusOptions = [
  { key: 'AgreedToSignup', value: 'AgreedToSignup' },
  { key: 'MissedConversion', value: 'MissedConversion' },
  { key: 'NotInterested', value: 'NotInterested' },
  { key: 'CallBackLater', value: 'CallBackLater' },
  { key: 'Converted', value: 'Converted' },
  { key: 'NotReceived', value: 'NotReceived' },
];
export const salesStatusOptions = [
  { key: 'NoActionTaken', value: 'NoActionTaken' },
  { key: 'LinkedInRequestSent', value: 'LinkedInRequestSent' },
  { key: 'CommunicatingOnLinkedin', value: 'CommunicatingOnLinkedin' },
  { key: 'CommunicatingOnCall', value: 'CommunicatingOnCall' },
  { key: 'FirstEmailSent', value: 'FirstEmailSent' },
  { key: 'CommunicatingOnEmail', value: 'CommunicatingOnEmail' },
  { key: 'CommunicatingOnWhatsapp', value: 'CommunicatingOnWhatsapp' },
  { key: 'LeadFailed', value: 'LeadFailed' },
  { key: 'LeadConverted', value: 'LeadConverted' },
];

export const salesLeadTypeOptions = [
  { key: 'Company', value: 'Company' },
  { key: 'College', value: 'College' },
  { key: 'ConsultingCompany', value: 'ConsultingCompany' },
  { key: 'Applicant', value: 'Applicant' },
];
export const salesStatusFilter = [
  { id: undefined, label: 'All' },
  { id: 'NoActionTaken', label: 'No Action Taken' },
  { id: 'LinkedInRequestSent', label: 'LinkedIn Request Sent' },
  { id: 'CommunicatingOnLinkedin', label: 'Communicating On  Linkedin' },
  { id: 'CommunicatingOnCall', label: 'Communicating On Call' },
  { id: 'FirstEmailSent', label: 'First Email Sent' },
  { id: 'CommunicatingOnEmail', label: 'Communicating On Email' },
  { id: 'CommunicatingOnWhatsapp', label: 'Communicating On Whatsapp' },
  { id: 'LeadFailed', label: 'Lead Failed' },
  { id: 'LeadConverted', label: 'Lead Converted' },
];

export const getLeadStatusChipColor = (status) => {
  switch (status) {
    case 'LeadFailed':
      return theme.palette.error.main;
    case 'LeadConverted':
      return theme.palette.success.main;
    case 'LinkedInRequestSent':
      return theme.palette.playColors.purple;
    case 'CommunicatingOnLinkedin':
      return theme.palette.playColors.gold;
    case 'FirstEmailSent':
      return theme.palette.playColors.cyan;
    case 'CommunicatingOnEmail':
      return theme.palette.playColors.orange;
    case 'CommunicatingOnWhatsapp':
      return theme.palette.playColors.blue;
    case 'CommunicatingOnCall':
      return theme.palette.playColors.pink;
    case 'NoActionTaken':
      return theme.palette.playColors.sky;
    default:
      break;
  }
};

export const getSalesUserStatus = (status) => {
  switch (status) {
    case 'SignUpPending':
      return 'Sign Up Pending';
    case 'OnBoardingPending':
      return 'OnBoarding Pending';
    case 'JobPostingPeneding':
      return 'Job Posting Pending';
    case 'JobPostActive':
      return 'Job Post Active';
    case 'JobPostPaused':
      return 'Job Post Paused';
    case 'JobPostUnderReview':
      return 'Job Post UnderReview';
    case 'JobPostDraft':
      return 'Job Post Draft';
    case 'JobPostClosed':
      return 'Job Post Closed';
    default:
      break;
  }
};

export const excelFieldmapping = {
  phone_number: 'Mobile Number',
  company_name: 'Business Name',
  name: 'First Name',
  job_role_name: 'Designation',
  email: 'Email Address',
  comment: 'comment',
  sales_status: 'Sales Status',
};
