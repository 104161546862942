import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const voting = createReducer(
  {
    question: {},
    history: {},
    error: {},
  },
  {
    [types.GET_VOTING_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        question: action.payload,
        error: {},
      };
    },
    [types.GET_VOTING_QUESTION_START](state) {
      return {
        ...state,
        votingResponse: {},
      };
    },
    [types.GET_VOTING_QUESTION_FAIL](state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    [types.POST_VOTING_QUESTION_RESPONSE_SUCCESS](state, action) {
      return {
        ...state,
        votingResponse: action.payload,
      };
    },
    [types.POST_VOTING_QUESTION_RESPONSE_ERROR](state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    [types.GET_HISTORY_VOTED_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        history: {
          ...action.payload,
          review_history_list: Object.values(
            action.payload?.question_wise_reports,
          ),
        },

        error: {},
      };
    },
    [types.GET_VOTING_REPORTED_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        history: {
          ...action.payload,
          review_history_list: Object.values(
            action.payload?.question_wise_reports,
          ),
        },

        error: {},
      };
    },
  },
);
