import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import PractiseTagBox from './PractiseTagBox';

import {
  TagBoxContainerSmall,
  TagBoxContainer,
  AnalyticsBox,
  AnalyticsName,
  AnalyticsValue,
  AnalyticsContainer,
} from './UserProfileStyledComponent';

const CompetitionTab = ({ analytics, isSelf }) => {
  const [currentIndex, setCurrentIndex] = React.useState(1);

  let bestRank = 0,
    totalCorrect = 0,
    totalIncorrect = 0,
    totalQuestionsCount = 0;
  Object.values(analytics?.competitions || {}).forEach((comp) => {
    if (bestRank === 0) {
      bestRank = comp.overall.score_rank;
    } else {
      bestRank = Math.min(comp.overall.score_rank, bestRank);
    }
    totalCorrect += comp.overall?.total_correct_count;
    totalIncorrect += comp.overall?.total_incorrect_count;
    totalQuestionsCount += comp.overall?.total_question_count;
  });

  return (
    <Grid>
      <AnalyticsContainer>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {Object.values(analytics?.competitions || {})?.length}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Total Competitions</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>{bestRank}</AnalyticsValue>
          <AnalyticsName variant="caption">Best Rank</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {totalQuestionsCount}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Total Ques. Solved</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {`${Math.round(
              (totalCorrect / (totalCorrect + totalIncorrect) +
                Number.EPSILON) *
                100,
            ) || 0}%`}
          </AnalyticsValue>
          <AnalyticsName>Accuracy</AnalyticsName>
        </AnalyticsBox>
      </AnalyticsContainer>
      {!Object.values(analytics?.competitions || {}).length && (
        <Typography color="primary" className="no-activity-text">
          No activity in this section!
        </Typography>
      )}
      <TagBoxContainer>
        {Object.values(analytics?.competitions || {})?.map((comp) => (
          <PractiseTagBox
            key={comp.id}
            data={{ ...comp, ...comp.overall, overall: undefined }}
            isCompetition={true}
            isSelf={isSelf}
          />
        ))}
      </TagBoxContainer>
      <TagBoxContainerSmall>
        <SwipeableViews onChangeIndex={(index) => setCurrentIndex(index + 1)}>
          {Object.values(analytics?.competitions || {})?.map((comp) => (
            <Box style={{ margin: '0px 5px' }} key={comp.id}>
              <PractiseTagBox
                data={{ ...comp, ...comp.overall, overall: undefined }}
                isCompetition={true}
                isSelf={isSelf}
              />
            </Box>
          ))}
        </SwipeableViews>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            justifyContent: 'center',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            {currentIndex}
          </Typography>
          <Typography color="primary" fontSize="14px">
            /{Object.values(analytics?.competitions || {})?.length}
          </Typography>
        </Box>
      </TagBoxContainerSmall>
    </Grid>
  );
};

export default CompetitionTab;
