import * as React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Box, Card, TextField, Chip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  tagInputContainer: {
    borderRadius: 4,
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  textField: {
    height: '100%',
    alignItems: 'center',
  },
  input: {
    height: '100%',
  },
  valid: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
    },
  },
  invalid: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,

    '& .MuiChip-deleteIcon': {
      color: theme.palette.error.main,
    },
  },

  formLabel: {
    marginBottom: theme.spacing(1),
  },
}));
function EmailBlock({ setInputValue, setTags, tags, inputValue }) {
  const inputref = React.useRef(null);
  const classes = useStyles();

  const handleContainerClick = () => {
    inputref.current.focus();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const handleInputPaste = (e) => {
    e.preventDefault();

    const dataStr = e.clipboardData.getData('Text');
    const emails = dataStr?.split(/[ ,\n]+/);
    const newTags = [];
    emails.forEach((email) => {
      if (isValidEmail(email)) {
        newTags.push({ value: email, isValid: true });
      } else {
        newTags.push({ value: email, isValid: false });
      }
    });

    setTags([...tags, ...newTags]);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      e.preventDefault();
      pushTag(inputValue);
      setInputValue('');
    } else if (e.key === 'Backspace' && !inputValue) {
      popTag();
    }
  };

  const isValidEmail = (tag) => {
    return tag.match(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );
  };

  const pushTag = (tag) => {
    if (!tag) return;

    if (isValidEmail(tag)) {
      setTags([...tags, { value: tag, isValid: true }]);
    } else {
      setTags([...tags, { value: tag, isValid: false }]);
    }
  };

  const popTag = () => {
    const newTags = [...tags];
    newTags.pop();
    setTags(newTags);
  };

  const handleEditTag = (tag) => {
    const filteredList = tags?.filter((item) => item.value !== tag?.value);
    setTags(filteredList);
    setInputValue(tag?.value);
  };

  return (
    <Grid xs={10} mt="8px" pb="0px">
      <Box display="flex" flexDirection="column">
        <Box>
          <Box
            className={classes.tagInputContainer}
            component={Card}
            variant="outlined"
            elevation={1}
            onClick={handleContainerClick}
          >
            <Grid container spacing={1}>
              {tags &&
                tags.map((tag, index) => (
                  <Grid item key={index}>
                    <Chip
                      label={tag.value}
                      onDelete={() => handleDelete(index)}
                      onClick={() => handleEditTag(tag)}
                      clickable
                      variant="outlined"
                      className={cx(classes.valid, {
                        [classes.invalid]: !tag.isValid,
                      })}
                    />
                  </Grid>
                ))}
              <Grid item display={'flex'} flexGrow={1}>
                <TextField
                  inputRef={inputref}
                  type="text"
                  autoFocus
                  fullWidth
                  onKeyDown={handleInputKeyDown}
                  onChange={handleInputChange}
                  onPaste={handleInputPaste}
                  value={inputValue}
                  classes={{
                    root: classes.textField,
                  }}
                  sx={{
                    flexGrow: '1',
                    '& fieldset': {
                      border: 'none',
                    },
                    '& .MuiInputBase-input': {
                      height: '32px',
                      padding: '0px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default EmailBlock;
