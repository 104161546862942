import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import DragAndDrop from '../DragAndDrop';
import {
  ClearAllText,
  EmailingListArguments,
  EmailingListAttachmentDisplay,
  EmailingListButton,
  EmailingListButtonCollection,
  EmailingListHeader,
  EmailingListInputWrapper,
  EmailingListLabel,
  EmailingListModalWrapper,
  EmailingListPlaceholder,
  EmailingListSelect,
  EmailingListSelects,
  EmailingListWrapper,
  SubtractEmailText,
} from './StyledComponents';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import EmailBlock from '../EmailBlock';
import { Radio, RadioGroup } from '@mui/material/';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearEmailingListState,
  createEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplateById,
  getEmailTemplateList,
  postEmailAttachment,
  removeAttachment,
  sendEmail,
  updateEmailTemplate,
} from '../../actions/emailingList';
import RichTextEditor from '../RichTextEditor';
import PreviewModal from './PreviewModal';
import { Clear } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import EmailingListSnackbars, {
  EmailingListSnackbarv2,
} from './EmailingListSnackbars';
import { getCompetitions } from '../../actions/competition';
import { nanoid } from 'nanoid';
import SubtractEmailsDialog from './SubtractEmailsDialog';
import { addInlineStylesToParagraphs } from '../../lib/helpers';

const EmailingList = () => {
  const { reset, control, setValue } = useForm({
    defaultValues: {
      selectedTemplate: '',
      selectedCompetition: '',
      selectedDomain: '',
      emailList: [],
      excludeList: [],
      listedUsers: 'all-users',
      emailFrom: 'hello@abekus.co',
      subject: '',
      body: '',
      templateArguments: [{ key: '', value: '' }],
      schedule: '',
      excludeHours: null,
      password: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'templateArguments',
    control,
    rules: {
      required: 'Please add at least 1 item',
    },
  });

  const selectedTemplate = useWatch({ control, name: 'selectedTemplate' });
  const selectedCompetition = useWatch({
    control,
    name: 'selectedCompetition',
  });
  const emailList = useWatch({ control, name: 'emailList' });
  const excludeList = useWatch({ control, name: 'excludeList' });
  const is_competition_completed = useWatch({
    control,
    name: 'is_competition_completed',
  });
  const subject = useWatch({ control, name: 'subject' });
  const body = addInlineStylesToParagraphs(useWatch({ control, name: 'body' }));
  const selectedDomain = useWatch({ control, name: 'selectedDomain' });
  const fromEmail = useWatch({ control, name: 'emailFrom' });
  const listedUsers = useWatch({ control, name: 'listedUsers' });
  const schedule = useWatch({ control, name: 'schedule' });
  const excludeHours = useWatch({ control, name: 'excludeHours' });
  const password = useWatch({ control, name: 'password' });
  const templateArguments = useWatch({ control, name: 'templateArguments' });

  const templateList = useSelector((state) => state.emailingList.templates);
  const domainList = useSelector((state) => state.domain.list);
  const competitionList = useSelector((state) => state.competitions?.list);
  const uploadedAttachmentUrls = useSelector(
    (state) => state.emailingList.uploadedAttachmentUrls || [],
  );
  const existingAttachmentUrls = useSelector((state) => {
    const unsignedUrls =
      state.emailingList.selectedTemplate?.attachment_urls?.unsigned;
    return (
      state.emailingList.selectedTemplate?.attachment_urls?.signed?.map(
        (url, index) => [url, unsignedUrls[index]],
      ) || []
    );
  });
  const selectedTemplateDetails = useSelector(
    (state) => state.emailingList.selectedTemplate,
  );

  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [excludeInputValue, setExcludeInputValue] = useState('');
  const [previewModalOpen, setPreviewModalOpen] = useState(() => false);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [attachmentUploadStatus, setAttachmentUploadStatus] = useState([]);
  const [openNewTemplateModal, setOpenNewTemplateModal] = useState(() => false);
  const [openUpdateTemplateModal, setOpenUpdateTemplateModal] = useState(
    () => false,
  );
  const [newTemplateName, setNewTemplateName] = useState('');
  const [snackbarDetails, setSnackbarDetails] = useState(() => ({
    severity: 'success',
    message: '',
    open: false,
  }));
  const [openSubtractEmailDialog, setOpenSubtractEmailDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmailTemplateList());
    dispatch(getCompetitions());
    return () => {
      dispatch(clearEmailingListState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedTemplate !== '') {
      dispatch(getEmailTemplateById(selectedTemplate));
    } else {
      reset();
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplateDetails !== null) {
      setValue('subject', selectedTemplateDetails.subject);
      const templateArguments = Object.entries(
        selectedTemplateDetails.template_args || { key: 'value' },
      ).map((argument) => ({
        key: argument[0],
        value: argument[1],
      }));
      setValue('templateArguments', templateArguments);
      setValue('body', selectedTemplateDetails.template_body);
    }
  }, [selectedTemplateDetails]);

  useEffect(() => {
    setAttachmentUploadStatus(Array.from(attachmentsToUpload).map(() => true));
    Array.from(attachmentsToUpload)?.forEach((file, index) => {
      dispatch(
        postEmailAttachment(file, () => {
          setAttachmentUploadStatus((prevState) => [
            ...prevState.slice(0, index),
            false,
            ...prevState.slice(index + 1),
          ]);
        }),
      );
    });
  }, [dispatch, attachmentsToUpload]);

  const fileNameExtractor = new RegExp('email_attachments/(.[^?]*)');

  const handleDelete = (type, index) => {
    dispatch(removeAttachment(type, index));
  };

  const attachmentsViewer = (urls, type) =>
    urls.map((url, index) => {
      if (!url) return null;
      return (
        <EmailingListAttachmentDisplay key={nanoid()}>
          <NavLink to={url}>{url.match(fileNameExtractor)?.[1]}</NavLink>
          <IconButton onClick={() => handleDelete(type, index)}>
            <Clear />
          </IconButton>
        </EmailingListAttachmentDisplay>
      );
    });

  const discardChanges = () => {
    if (selectedTemplate === '') {
      reset();
      return;
    }
    dispatch(getEmailTemplateById(selectedTemplate));
  };

  const handleUpdate = () => {
    let processedArguments = {};

    templateArguments?.forEach((argument) => {
      processedArguments = {
        ...processedArguments,
        [argument.key]: argument.value,
      };
    });

    dispatch(
      updateEmailTemplate(
        {
          newTemplateName,
          subject,
          body,
          templateArguments: processedArguments,
          attachmentUrls: [
            ...uploadedAttachmentUrls.map((url) => url.attachment_url),
            ...existingAttachmentUrls.map((url) => url[1]),
          ],
          templateId: selectedTemplate,
        },
        (id) => {
          setValue('selectedTemplate', id);
          setSnackbarDetails({
            open: true,
            message: 'Updated Successfully',
            severity: 'success',
          });
          setOpenUpdateTemplateModal(false);
          dispatch(getEmailTemplateList());
        },
      ),
    );
  };

  const handleTemplateDelete = () => {
    dispatch(
      deleteEmailTemplate(selectedTemplate, () => {
        reset();
        setSnackbarDetails({
          open: true,
          message: 'Deleted Successfully',
          severity: 'success',
        });
      }),
    );
  };

  const handleTemplateCreate = () => {
    let processedArguments = {};

    templateArguments?.forEach((argument) => {
      processedArguments = {
        ...processedArguments,
        [argument.key]: argument.value,
      };
    });
    dispatch(
      createEmailTemplate(
        {
          templateName: newTemplateName,
          subject,
          templateBody: body,
          templateArgs: processedArguments,
          attachmentUrls: uploadedAttachmentUrls.map(
            (url) => url.attachment_url,
          ),
        },
        (id) => {
          setValue('selectedTemplate', id);
          setSnackbarDetails({
            open: true,
            message: 'Created a New Template',
            severity: 'success',
          });
          setOpenNewTemplateModal(false);
        },
      ),
    );
  };

  const postEmail = () => {
    const newUserOnly = listedUsers === 'new-users';
    const existingUserOnly = listedUsers === 'existing-users';
    const mappedEmailList = emailList.map((email) => email.value);
    const mappedExcludeEmailList = excludeList.map((email) => email.value);
    let processedArguments = {};

    templateArguments?.forEach((argument) => {
      processedArguments = {
        ...processedArguments,
        [argument.key]: argument.value,
      };
    });
    const data = {
      emailList: mappedEmailList,
      exclude_list: mappedExcludeEmailList,
      is_competition_completed,
      subject,
      body,
      selectedDomain,
      competitionId: selectedCompetition,
      fromEmail,
      new_user_only: newUserOnly,
      existing_users_only: existingUserOnly,
      schedule,
      excludeHours: excludeHours?.length ? excludeHours : null,
      password,
      urls: [
        ...uploadedAttachmentUrls.map((url) => url.attachment_url),
        ...existingAttachmentUrls.map((url) => url[1]),
      ],
      templateArguments: processedArguments,
    };
    dispatch(
      sendEmail(
        data,
        () => {
          setSnackbarDetails({
            open: true,
            message: 'Successfully Sent',
            severity: 'success',
          });
        },
        (message) => {
          setSnackbarDetails({
            open: true,
            message: message,
            severity: 'error',
          });
        },
      ),
    );
  };

  const handleModalClose = () => {
    setOpenNewTemplateModal(false);
    setOpenUpdateTemplateModal(false);
  };

  const templateData = templateList.templates?.find(
    (template) => template.id === selectedTemplate,
  );

  const handleSubEmail = (list) => {
    const mappedList = list.map((b) => b.value);
    const filteredEmailList = emailList.filter((a) => {
      const splitValue = a.value?.split('-_-')[1] || a.value;
      return !mappedList.includes(splitValue);
    });

    reset({
      emailList: filteredEmailList,
      subject: templateData ? templateData.subject : subject,
      body: templateData ? templateData.template_body : body,
    });
    setOpenSubtractEmailDialog(false);
  };

  const handleClearAllEmails = () => {
    reset({
      emailList: [],
      subject: templateData ? templateData.subject : subject,
      body: templateData ? templateData.template_body : body,
      excludeList: excludeList,
      password: password,
      schedule: schedule,
    });
    setInputValue('');
  };
  const handleClearAllExcludeEmails = () => {
    reset({
      emailList: emailList,
      subject: templateData ? templateData.subject : subject,
      body: templateData ? templateData.template_body : body,
      excludeList: [],
      password: password,
      schedule: schedule,
    });
  };

  const newTemplateModalBody = (
    <Modal open={openNewTemplateModal}>
      <EmailingListModalWrapper>
        <Typography variant="h6">{'Create a new template'}</Typography>
        <TextField
          value={newTemplateName}
          placeholder="Enter a name for the template..."
          onChange={(event) => setNewTemplateName(event.target.value)}
          size="small"
        />
        <EmailingListButtonCollection>
          <Button variant="contained" color="error" onClick={handleModalClose}>
            Close
          </Button>
          <EmailingListButton
            variant="contained"
            color="success"
            custommarginleft="auto"
            onClick={handleTemplateCreate}
            disabled={newTemplateName === ''}
          >
            {'Create'}
          </EmailingListButton>
        </EmailingListButtonCollection>
      </EmailingListModalWrapper>
    </Modal>
  );
  const updateTemplateModalBody = (
    <Modal open={openUpdateTemplateModal}>
      <EmailingListModalWrapper>
        <Typography variant="h6">{'Update Template'}</Typography>
        <TextField
          value={newTemplateName}
          placeholder="Enter a name for the template..."
          onChange={(event) => setNewTemplateName(event.target.value)}
          size="small"
        />
        <EmailingListButtonCollection>
          <Button variant="contained" color="error" onClick={handleModalClose}>
            Close
          </Button>
          <EmailingListButton
            variant="contained"
            color="success"
            custommarginleft="auto"
            onClick={handleUpdate}
            disabled={newTemplateName === ''}
          >
            {'Update'}
          </EmailingListButton>
        </EmailingListButtonCollection>
      </EmailingListModalWrapper>
    </Modal>
  );

  return (
    <EmailingListWrapper>
      {newTemplateModalBody}
      {updateTemplateModalBody}
      <EmailingListSnackbars />
      <EmailingListSnackbarv2
        snackbarDetails={snackbarDetails}
        setSnackbarDetails={setSnackbarDetails}
      />
      {previewModalOpen && (
        <PreviewModal
          open={previewModalOpen}
          setOpen={setPreviewModalOpen}
          subject={subject}
          body={body}
          sendEmail={postEmail}
        />
      )}
      <EmailingListHeader
        variant="h1"
        display={'flex'}
        align="center"
        justifyContent={'space-between'}
      >
        <Box>Send Bulk Emails</Box>
      </EmailingListHeader>

      <EmailingListSelects>
        <Controller
          render={({ field: { onChange, value } }) => (
            <EmailingListInputWrapper>
              <EmailingListLabel> Templates</EmailingListLabel>
              <EmailingListSelect
                value={value}
                onChange={onChange}
                defaultValue=""
                size="small"
                displayEmpty
                renderValue={
                  value !== ''
                    ? undefined
                    : () => (
                        <EmailingListPlaceholder>
                          Select a Template
                        </EmailingListPlaceholder>
                      )
                }
              >
                <MenuItem value="">Select a Template</MenuItem>
                {templateList.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.template_name}
                  </MenuItem>
                ))}
              </EmailingListSelect>
            </EmailingListInputWrapper>
          )}
          name={'selectedTemplate'}
          control={control}
        />
        <Controller
          render={({ field: { onChange, value } }) => (
            <EmailingListInputWrapper>
              <EmailingListLabel>Domain ID</EmailingListLabel>
              <EmailingListSelect
                value={value}
                onChange={onChange}
                size="small"
                displayEmpty
                renderValue={
                  value !== ''
                    ? undefined
                    : () => (
                        <EmailingListPlaceholder>
                          Select a Domain
                        </EmailingListPlaceholder>
                      )
                }
              >
                <MenuItem value="">Select a Domain</MenuItem>
                {domainList.map((domain) => (
                  <MenuItem value={domain.id} key={domain.id}>
                    {domain.name}
                  </MenuItem>
                ))}
              </EmailingListSelect>
            </EmailingListInputWrapper>
          )}
          name={'selectedDomain'}
          control={control}
        />
        <Controller
          render={({ field: { onChange, value } }) => (
            <EmailingListInputWrapper>
              <EmailingListLabel>Competition ID</EmailingListLabel>
              <EmailingListSelect
                value={value}
                onChange={onChange}
                size="small"
                displayEmpty
                renderValue={
                  value !== ''
                    ? undefined
                    : () => (
                        <EmailingListPlaceholder>
                          Select a Competition
                        </EmailingListPlaceholder>
                      )
                }
              >
                <MenuItem value="">Select a Competition</MenuItem>
                {competitionList.map((competition) => (
                  <MenuItem value={competition.id} key={competition.id}>
                    {competition.name}
                  </MenuItem>
                ))}
              </EmailingListSelect>
            </EmailingListInputWrapper>
          )}
          name={'selectedCompetition'}
          control={control}
        />
      </EmailingListSelects>

      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <EmailingListLabel>
                Emails ({emailList?.length})
              </EmailingListLabel>
              <ClearAllText onClick={handleClearAllEmails}>
                {' '}
                (Clear All)
              </ClearAllText>
            </Box>
            <EmailBlock
              inputValue={inputValue}
              setInputValue={setInputValue}
              tags={value}
              setTags={onChange}
            />
          </>
        )}
        name={'emailList'}
        control={control}
      />
      <Grid
        display={'flex'}
        justifyContent={'flex-end'}
        item
        xs={2}
        onClick={() => setOpenSubtractEmailDialog(true)}
      >
        <SubtractEmailText>Subtract Emails</SubtractEmailText>
      </Grid>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <EmailingListLabel>Exclude Emails</EmailingListLabel>
              <ClearAllText onClick={handleClearAllExcludeEmails}>
                {' '}
                (Clear All)
              </ClearAllText>
            </Box>
            <EmailBlock
              inputValue={excludeInputValue}
              setInputValue={setExcludeInputValue}
              tags={value}
              setTags={onChange}
            />
          </>
        )}
        name={'excludeList'}
        control={control}
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <EmailingListInputWrapper>
            <EmailingListLabel>
              Only competition completed users
            </EmailingListLabel>
            <EmailingListSelect
              value={value}
              onChange={onChange}
              size="small"
              displayEmpty
              renderValue={
                value !== ''
                  ? undefined
                  : () => (
                      <EmailingListPlaceholder>None</EmailingListPlaceholder>
                    )
              }
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </EmailingListSelect>
          </EmailingListInputWrapper>
        )}
        name={'is_competition_completed'}
        control={control}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <RadioGroup row value={value} onChange={onChange}>
            <FormControlLabel
              control={<Radio />}
              label={'New Users Only'}
              value={'new-users'}
            />
            <FormControlLabel
              control={<Radio />}
              label={'Existing Users Only'}
              value={'existing-users'}
            />
            <FormControlLabel
              control={<Radio />}
              label={'All Users '}
              value={'all-users'}
            />
          </RadioGroup>
        )}
        name={'listedUsers'}
        control={control}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <EmailingListInputWrapper>
            <EmailingListLabel>From Email</EmailingListLabel>
            <EmailingListSelect value={value} onChange={onChange} size="small">
              <MenuItem value={'kshitij@abekus.co'}>kshitij@abekus.co</MenuItem>
              <MenuItem value={'hello@abekus.co'}>hello@abekus.co</MenuItem>
              <MenuItem value={'simran@abekus.co'}>simran@abekus.co</MenuItem>
              <MenuItem value={'ahmar@abekus.co'}>ahmar@abekus.co</MenuItem>
              <MenuItem value={'vamika@abekus.co'}>vamika@abekus.co</MenuItem>
            </EmailingListSelect>
          </EmailingListInputWrapper>
        )}
        name={'emailFrom'}
        control={control}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <EmailingListInputWrapper>
            <EmailingListLabel>Subject</EmailingListLabel>
            <TextField
              name="subject"
              onChange={onChange}
              placeholder="Enter Email Subject..."
              variant="outlined"
              value={value}
              size="small"
            />
          </EmailingListInputWrapper>
        )}
        name={'subject'}
        control={control}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <EmailingListInputWrapper>
            <EmailingListLabel>Body Text</EmailingListLabel>
            <RichTextEditor
              onChange={onChange}
              value={value}
              placeholder="Enter Your Template"
              compress={false}
            />
          </EmailingListInputWrapper>
        )}
        name={'body'}
        control={control}
      />

      {fields.map((field, index) => (
        <EmailingListArguments key={field.id}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <EmailingListInputWrapper>
                <EmailingListLabel>Key</EmailingListLabel>
                <TextField
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Key"
                  size="small"
                />
              </EmailingListInputWrapper>
            )}
            name={`templateArguments.${index}.key`}
            control={control}
          />
          <Controller
            render={({ field: { onChange, value } }) => (
              <EmailingListInputWrapper>
                <EmailingListLabel>Value</EmailingListLabel>
                <TextField
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Value"
                  size="small"
                />
              </EmailingListInputWrapper>
            )}
            name={`templateArguments.${index}.value`}
            control={control}
          />
          {templateArguments?.length > 1 && (
            <IconButton
              onClick={() => {
                remove(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </EmailingListArguments>
      ))}

      <Button
        onClick={() => {
          append({
            key: '',
            value: '',
          });
        }}
        variant="contained"
      >
        Add
      </Button>

      <EmailingListInputWrapper>
        <EmailingListLabel>Attachment</EmailingListLabel>
        <DragAndDrop
          ref={inputRef}
          setFiles={setAttachmentsToUpload}
          multiple
        />
      </EmailingListInputWrapper>

      {Array.from(attachmentsToUpload).map((file, index) =>
        attachmentUploadStatus[index] ? (
          <EmailingListAttachmentDisplay key={nanoid()}>
            {file.name}
            <CircularProgress />
          </EmailingListAttachmentDisplay>
        ) : null,
      )}
      {attachmentsViewer(
        uploadedAttachmentUrls.map(
          (attachment) => attachment.signed_attachment_url,
        ),
        1,
      )}
      {attachmentsViewer(
        existingAttachmentUrls.map((url) => url[0]),
        2,
      )}

      <EmailingListArguments>
        <Controller
          render={({ field: { onChange, value } }) => (
            <EmailingListInputWrapper>
              <EmailingListLabel>Schedule the Email</EmailingListLabel>
              <DateTimePicker
                value={value === '' ? null : moment(value)}
                onChange={(value) => {
                  onChange(moment(value).toISOString(true));
                }}
                slotProps={{ textField: { size: 'small' } }}
              />
            </EmailingListInputWrapper>
          )}
          name={'schedule'}
          control={control}
        />

        <Controller
          render={({ field: { onChange, value } }) => (
            <EmailingListInputWrapper>
              <EmailingListLabel>Exclude Hours</EmailingListLabel>
              <TextField
                type="number"
                value={value}
                onChange={onChange}
                size="small"
                placeholder="Enter Excluding Hours..."
                onWheel={(event) => event.target.blur()}
              />
            </EmailingListInputWrapper>
          )}
          name={'excludeHours'}
          control={control}
        />
      </EmailingListArguments>

      <Controller
        render={({ field: { onChange, value } }) => (
          <EmailingListInputWrapper>
            <EmailingListLabel>Password</EmailingListLabel>
            <TextField
              onChange={onChange}
              placeholder="Enter Admin Password"
              value={value}
              type="password"
              size="small"
            />
          </EmailingListInputWrapper>
        )}
        name={'password'}
        control={control}
      />

      <EmailingListButtonCollection>
        <EmailingListButton
          customcolor="#7B7B7B"
          variant="outlined"
          onClick={discardChanges}
        >
          Discard
        </EmailingListButton>

        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            setNewTemplateName('');
            setOpenNewTemplateModal(true);
          }}
        >
          Create Template
        </Button>
        {selectedTemplate && (
          <>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                setNewTemplateName(selectedTemplateDetails?.template_name);
                setOpenUpdateTemplateModal(true);
              }}
            >
              Update Template
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleTemplateDelete}
            >
              Delete Template
            </Button>
          </>
        )}

        <EmailingListButton
          custommarginleft="auto"
          onClick={() => setPreviewModalOpen(1)}
          variant="outlined"
        >
          Preview
        </EmailingListButton>
        <EmailingListButton
          onClick={() => setPreviewModalOpen(2)}
          variant="contained"
        >
          Send
        </EmailingListButton>
      </EmailingListButtonCollection>
      {openSubtractEmailDialog ? (
        <SubtractEmailsDialog
          handleOpen={openSubtractEmailDialog}
          handleClose={() => setOpenSubtractEmailDialog(false)}
          handleDataSubmit={(list) => handleSubEmail(list)}
        />
      ) : null}
    </EmailingListWrapper>
  );
};

export default EmailingList;
