import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import {
  AppWrapper,
  NoContent,
  StyledLoader,
  TagListName,
  TagListAvatar,
  NoTag,
  TagListHeader,
  TagButton,
} from './StyledComponents';
import Card from '../Card';
import SearchBox from '../SearchBox';
import { withRouter } from '../../routes';

const AddDomains = (props) => {
  const [domainList, setDomainList] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setDomainList(props.domain.list);
  }, [props.domain.list]);

  useEffect(() => {
    const { getTag, getDomain } = props;
    getTag();
    getDomain();
  }, []);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
    filterList();
  };

  const renderLoader = () => {
    return (
      <NoContent>
        <StyledLoader size={44} />
      </NoContent>
    );
  };
  const filterList = () => {
    const filteredList = props.domain.list.filter(
      (tag) =>
        tag.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        tag.description?.toLowerCase().includes(searchInput.toLowerCase()),
    );
    setDomainList(filteredList);
  };

  const renderDomainsList = () => {
    const { navigate } = props;
    return (
      <div>
        <SearchBox value={searchInput} handleSearch={handleSearch} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domainList?.length > 0 ? (
              domainList.map((tag) => (
                <TableRow key={tag.id}>
                  <TableCell
                    onClick={() => {
                      navigate({
                        pathname: `/l/categories/${tag.id}`,
                        search: `${tag.id}`,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <TagListName>
                      <TagListAvatar alt={tag.name} src={tag.img_url_blue} />
                      {`${tag.name}`}
                    </TagListName>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate({
                        pathname: `/l/categories/${tag.id}`,
                        search: `${tag.id}`,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {tag.description}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ minWidth: { xs: '50px', sm: '150px' } }}
                  >
                    <EditIcon
                      onClick={() => {
                        navigate({
                          pathname: `/l/editDomain/${tag.id}`,
                          search: `${tag.id}`,
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoTag>No Such tag found </NoTag>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const cardHeader = (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <TagListHeader>Domains</TagListHeader>
      <div>
        <NavLink to="/l/createNewDomain">
          <TagButton variant="outlined">Add Domain</TagButton>
        </NavLink>
      </div>
    </div>
  );

  const cardContent =
    props.tag.list.length > 0 ? renderDomainsList() : renderLoader();

  return (
    <Container maxWidth={false}>
      <AppWrapper>
        {cardHeader}
        <Card content={cardContent} />
      </AppWrapper>
    </Container>
  );
};

export default withRouter(AddDomains);
