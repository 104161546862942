import * as React from 'react';
import classnames from 'classnames';
import { Grid, Divider, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import pic1 from './images/Body.svg';
import pic2 from './images/database.svg';
import pic3 from './images/Preview.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&.active': {
      fontSize: '16px',
      fontWeight: 500,
    },

    '& .primary': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
  },
  divider: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 'auto',
    minHeight: '150px',
    marginLeft: '18px',
    width: '2px',
  },
  textLabelContainer: {
    padding: '2px 0px 0px 8px',
  },
  avatar: {
    width: 36,
    height: 36,
  },
}));

const FormProgress = ({ step }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item>
        <Grid item>
          <div
            className={classnames(classes.labelContainer, {
              active: step === 1,
            })}
          >
            <Avatar
              className={classnames(classes.avatar, { primary: step >= 1 })}
            >
              <img src={pic1} />
            </Avatar>
            <div className={classnames(classes.textLabelContainer)}>
              Question Body
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid item>
        <div className={classes.contentContainer}>
          <Divider
            orientation="vertical"
            color="grey"
            className={classes.divider}
          />
        </div>
      </Grid>

      <Grid container item>
        <Grid item>
          <div
            className={classnames(classes.labelContainer, {
              active: step === 2,
            })}
          >
            <Avatar
              className={classnames(classes.avatar, { primary: step >= 2 })}
            >
              <img src={pic2} />
            </Avatar>

            <div
              className={classnames(classes.textLabelContainer, {
                active: step === 2,
              })}
            >
              Meta Data
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid item>
        <div className={classes.contentContainer}>
          <Divider
            orientation="vertical"
            color="grey"
            className={classes.divider}
          />
        </div>
      </Grid>

      <Grid container item>
        <Grid item>
          <div
            className={classnames(classes.labelContainer, {
              active: step === 3,
            })}
          >
            <Avatar
              className={classnames(classes.avatar, { primary: step >= 3 })}
            >
              <img src={pic3} />
            </Avatar>

            <div
              className={classnames(classes.textLabelContainer, {
                active: step === 3,
              })}
            >
              Preview
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormProgress;
