import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { jobPremiumUpdate } from '../../actions/adminJobs';

const JobPremiumUpdateDialog = ({
  open,
  onClose,
  selectedOrg,
  status,
  searchUser,
  sortBy,
  sortOrderByTime,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onDetailsSubmit = (data) => {
    dispatch(
      jobPremiumUpdate(
        selectedOrg?.id,
        selectedOrg?.org_id,
        data,
        status,
        searchUser,
        sortBy,
        sortOrderByTime,
        page,
        rowsPerPage,
      ),
    );
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <>
        <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography variant="h6" className={'dialog-title'} color="primary">
            Update Details
          </Typography>
        </Grid>
        <DialogContent className="dialog-content">
          <Box>
            <Grid mb={2}>
              <label>Premium Status</label>
              <Select
                {...register('is_premium', {
                  required: 'Please select premium status',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                error={!!errors.is_premium}
                helperText={!!errors.is_premium?.message}
                sx={{
                  height: '40px',
                }}
                defaultValue={selectedOrg?.is_premium?.toString()}
              >
                <MenuItem value={'true'} key={'true'}>
                  True
                </MenuItem>
                <MenuItem value={'false'} key={'false'}>
                  False
                </MenuItem>
              </Select>
            </Grid>

            <Grid mb={2}>
              <label>Job Credits</label>
              <TextField
                {...register('job_credits', {
                  required: false,
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                defaultValue={selectedOrg?.job_credits}
                error={!!errors.job_credits}
                helperText={errors.job_credits?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
            <Grid mb={2}>
              <label>Expires On</label>
              <TextField
                {...register('expires_on', {
                  required: 'Please enter the expiry date',
                })}
                name="expires_on"
                margin="dense"
                variant="outlined"
                className="text-input-field"
                defaultValue={
                  selectedOrg?.expires_on
                    ? moment(selectedOrg.expires_on).format('YYYY-MM-DD')
                    : ''
                }
                error={!!errors.expires_on}
                helperText={errors.expires_on?.message}
                fullWidth
                type="date"
              />
            </Grid>
            <Grid mb={2}>
              <label>Employee Access Expires On</label>
              <TextField
                {...register('employer_access_expires_on', {
                  required: 'Please enter the expiry date',
                })}
                type="date"
                margin="dense"
                variant="outlined"
                fullWidth
                defaultValue={
                  selectedOrg?.employer_access_expires_on
                    ? moment(selectedOrg.employer_access_expires_on).format(
                        'YYYY-MM-DD',
                      )
                    : ''
                }
                error={!!errors.employer_access_expires_on}
                helperText={errors.employer_access_expires_on?.message}
              />
            </Grid>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ marginTop: '20px' }}
          >
            <Button variant="outlined" type="button" onClick={onClose}>
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={handleSubmit(onDetailsSubmit)}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default JobPremiumUpdateDialog;
