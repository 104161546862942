import { styled } from '@mui/styles';

export const StyledRateQuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '& .icon': {
    display: 'flex',
    color: theme.palette.component.color,
  },
}));

export const StyledIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.component.color,
}));
