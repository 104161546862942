import React from 'react';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import makeStyles from '@mui/styles/makeStyles';
import Logo from './img/New_logo_full.svg';
import LogoSmall from './img/New_logo_small.svg';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
  },
}));

export default function SidebarHeader({
  open,
  handleDrawerClose,
  isDrawer = true,
}) {
  const classes = useStyles();

  return (
    <div className={classnames('drawer__header', classes.toolbar)}>
      <NavLink to={`/l/practise`}>
        {open ? (
          <img
            src={Logo}
            alt="Abekus"
            width={140}
            style={{ marginRight: '20px' }}
          />
        ) : (
          <img src={LogoSmall} alt="Abekus" height={27} />
        )}
      </NavLink>

      {open && isDrawer && (
        <IconButton onClick={handleDrawerClose} size="large">
          <ChevronLeftIcon style={{ color: '#fff' }} />
        </IconButton>
      )}
    </div>
  );
}
