import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowBack, Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import '../ReportBug/ReportedBugs/index.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  deleteAdminCoupons,
  getAdminCoupons,
} from '../../actions/adminCoupons';
import moment from 'moment';
import { getProductsList } from '../../actions/storeAdmin';
const AdminCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminCouponsData } = useSelector((state) => state.AdminCoupons);
  const { productsList = [] } = useSelector((state) => state.storeAdmin);

  useEffect(() => {
    dispatch(getAdminCoupons());
    dispatch(getProductsList());
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteAdminCoupons(id));
  };
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
        className="collegeListContainer"
      >
        {' '}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <IconButton onClick={() => navigate('/l/adminFeatures')}>
              <ArrowBack />
            </IconButton>
            <Typography>Admin Coupon</Typography>
          </Box>
          <div>
            <NavLink to="/l/admin/create/coupon">
              <Button variant="outlined">Add Coupon</Button>
            </NavLink>
          </div>
        </div>
        <>
          <div className="table-container">
            <Table
              style={{
                backgroundColor: 'white',
                maxWidth: '100%',
                overflow: 'auto',
              }}
            >
              <TableHead>
                {adminCouponsData?.coupons?.length === 0 ? (
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    No Records Found
                  </Typography>
                ) : (
                  <TableRow style={{ minWidth: '40rem' }}>
                    <TableCell>Code</TableCell>

                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      Coupon Type Use
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Expire On
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Percent Discount
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Item
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Is Active
                    </TableCell>

                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {adminCouponsData?.coupons?.map((data, idx) => (
                  <TableRow key={idx} className="table-row-bugs">
                    <TableCell
                      style={{ padding: '1rem 4px', paddingLeft: '10px' }}
                    >
                      {data.code}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {data.coupon_type_use}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {moment(data.expire_on).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {data.percent_discount}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {
                        productsList?.find((item) => item.id === data.item_id)
                          ?.name
                      }
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {data?.is_active?.toString()}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                      <Edit
                        onClick={() => {
                          navigate({
                            pathname: `/l/admin/create/coupon/${data.id}`,
                            search: `${data.id}`,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                      <Delete onClick={() => handleDelete(data.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      </Container>
    </>
  );
};

export default AdminCoupon;
