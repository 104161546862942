import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Link, useLocation } from 'react-router-dom';

import Branding from '../Branding';
import { StyledAppHeader } from './StyledComponent';

export default () => {
  const location = useLocation();

  return (
    <StyledAppHeader>
      <Container maxWidth={false}>
        <div className="app-header-wrapper">
          <Branding />

          <div className="header-content"></div>
          <Link
            to={{
              pathname: '/signup',
              search: location.search,
            }}
            state={{ background: location, from: location }}
          >
            <Button variant="outlined" color="primary" type="button">
              Sign Up
            </Button>
          </Link>
        </div>
      </Container>
    </StyledAppHeader>
  );
};
