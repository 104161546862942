import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { List, ListItem, IconButton, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChoiceEdit from '../ChoiceEdit';
import PropTypes from 'prop-types';
import { StyledEditQuestion } from './StyledComponent';

const CreateOptions = ({
  value = [],
  onChange = () => null,
  metaData,
  setValue,
  correctOptions = [],
  setCorrectOptions,
  questionType,
  clearErrors,
}) => {
  const [editingOptionIndex, setEditingOptionIndex] = React.useState(-1);
  const [answers, setAnswers] = React.useState(correctOptions);

  const onChoiceClick = (optionNumber) => {
    clearErrors();
    if (questionType === 'MultipleCorrect') {
      if (answers.includes(optionNumber)) {
        setAnswers(answers.filter((opt) => opt !== optionNumber));
      } else {
        setAnswers([...answers, optionNumber]);
      }
    } else {
      setAnswers([optionNumber]);
    }
  };

  const handleCancel = () => {
    setEditingOptionIndex(-1);
  };

  React.useEffect(() => {
    setCorrectOptions(answers);
  }, [answers]);

  const handleOptionAdd = (option) => {
    if (editingOptionIndex !== -1) {
      // Edit mode
      const newValue = [...value];
      newValue[editingOptionIndex].option_text = option;
      onChange(newValue);
      setEditingOptionIndex(-1);
    } else {
      // Add mode
      const newValue = [...value, { option_text: option }].map(
        (value, index) => ({
          option_text: value.option_text,
          option_serial_no: index + 1,
        }),
      );
      onChange(newValue);
    }
  };

  const handleOptionEdit = (index) => () => {
    setEditingOptionIndex(index);
  };

  const handleOptionDelete = (index) => () => {
    const newValue = value
      .filter((_, i) => i !== index)
      .map((opt, i) => ({
        ...opt,
        option_serial_no: i + 1,
      }));
    onChange(newValue);
    if (setValue) {
      if (questionType === 'MultipleCorrect') {
        setValue(
          'answer_option_no',
          answers.filter((option) => option === index + 1),
        );
        setAnswers(answers.filter((option) => option === index + 1));
      } else {
        setValue('answer_option_no', [newValue[0]?.option_serial_no]);
        setAnswers([newValue[0]?.option_serial_no]);
      }
    }
  };

  return (
    <StyledEditQuestion>
      <React.Fragment>
        <Typography color="#000" fontWeight="500" mb={2}>
          Options{' '}
          <i style={{ fontSize: '12px' }}>(Minimum 2 options required)</i>
        </Typography>
        {value?.length ? (
          <div className="form-group answer-text">
            <Box>
              <List>
                {value.map((option, index) => (
                  <ListItem
                    key={`option-${index}`}
                    divider={index !== value.length - 1}
                    className={
                      editingOptionIndex === index
                        ? 'forCustomToolbar1'
                        : 'forCustomToolbar'
                    }
                    style={
                      editingOptionIndex !== index
                        ? {
                            marginBottom: 10,
                            border: 'none !important',
                            borderBottom: 'none',
                          }
                        : {
                            marginBottom: 10,
                            border: 'none !important',
                            borderBottom: 'none',
                          }
                    }
                  >
                    {editingOptionIndex === index ? (
                      <>
                        <Box mr={2} className="choice-label">
                          {index + 1}
                        </Box>
                        <ChoiceEdit
                          value={value?.[editingOptionIndex]?.option_text ?? ''}
                          onChange={handleOptionAdd}
                          metaData={metaData}
                          forEdit={index + 1}
                          handleCancel={handleCancel}
                        />
                      </>
                    ) : (
                      <>
                        <Box mr={1} className="choice-label">
                          {index + 1}
                        </Box>

                        <Box
                          className={
                            editingOptionIndex !== index ? 'forListItem' : ''
                          }
                          style={{
                            backgroundColor: answers.includes(index + 1)
                              ? '#e6ffec'
                              : '',
                          }}
                          onClick={handleOptionEdit(index)}
                        >
                          {ReactHtmlParser(option.option_text)}
                        </Box>

                        <IconButton onClick={() => onChoiceClick(index + 1)}>
                          {answers.includes(index + 1) ? (
                            <CheckCircleOutlineIcon
                              color="success"
                              fontSize="medium"
                            />
                          ) : (
                            <CheckCircleOutlineIcon fontSize="medium" />
                          )}
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={handleOptionDelete(index)}
                          size="medium"
                        >
                          <DeleteIcon fontSize="medium" />
                        </IconButton>
                      </>
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          </div>
        ) : (
          <div />
        )}
        <div className="form-group answer-multiple">
          <div>
            <ChoiceEdit
              value={''}
              onChange={handleOptionAdd}
              metaData={metaData}
            />
          </div>
        </div>
      </React.Fragment>
    </StyledEditQuestion>
  );
};

CreateOptions.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CreateOptions;
