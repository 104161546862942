import { styled } from '@mui/styles';

export const StyledComponentWrapper = styled('div')({
  '& #select-domain': {
    width: '70%',
  },
  '& .select-category-wrapper': {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  '& .select-category': {
    flex: 2,
  },
  '& .category-selection-wrapper': {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    paddingLeft: '0.5rem',
  },
  '& .choose-category': {
    width: '40%',
  },
  '& .category__weightage': {
    marginTop: '0.3rem',
  },
  '& .rewardsWrapper': {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  '& .rangeInput': {
    width: '25%',
  },
  '& .rewards-input': {
    width: '20%',
  },
  '& .reward_bar': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  '& .table-col': {
    width: '25%',
  },
});
