import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import ReactSelect from 'react-select';
import RichTextEditor from './../RichTextEditor';
import { MobileDateTimePicker as DateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import helpers from '../../lib/helpers';

import { useStyles } from './styles';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DomainChangeDialog from '../Dialog/DomainChangeDialog';
import ChooseSubcategoryDialog from '../MockTestCreate/ChooseSubcategoryDialog';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { StyledComponentWrapper } from './StyledComponent';
import { getActiveJobs } from '../../actions/competition';

const competition_levels = [
  { value: 1, name: 'easy' },
  { value: 2, name: 'medium' },
  { value: 3, name: 'hard' },
];

const CompetitionCreate = (props) => {
  const {
    tag,
    domain,
    postCompetition,
    getCompetitionById,
    competition,
    uploadCompetitionImg,
  } = props;

  const { details } = competition;

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const competitionId = params.competitionId;

  const classes = useStyles();
  const [showDomainChangeDialog, setShowDomainChangeDialog] = React.useState(
    false,
  );
  const [requestedDomain, setRequestedDomain] = React.useState({});

  const [loading, setLoading] = React.useState(false);
  const [activeJobsData, setActiveJobsData] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState({});

  const [
    showSubcategoryAddDialog,
    setShowSubcategoryAddDialog,
  ] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const domain_id = watch('domain_id');
  const domainList = domain.list;
  const categoryList = watch('category_list');
  const competitionTypes = [
    'Fresher Job',
    'Internship',
    'Experienced Job',
    'General',
  ].map((comp) => ({
    label: comp,
    value: comp.split(' ').join(''),
  }));

  const categoryCurrentlyChoosed = watch('categoryTag');

  const tagArray = watch('tag_array');

  const tagWeightages = watch('category_weights');

  const tagMap = watch('tag_map');
  const duplicateCompetition = helpers.getURLParameterByName(
    'base_competition',
  );
  const weightageInput = watch('weightage');
  const editParentTag = watch('edit_parent_tag');
  const rewardsFrom = watch('rewards_from');
  const rewardsTo = watch('rewards_to');
  const rewardsCredits = watch('rewards_credits');
  const rewardsGems = watch('rewards_gems');
  const regStartDate = watch('reg_start_date');
  const regEndDate = watch('reg_end_date');
  const startDate = watch('start_date');
  const endDate = watch('end_date');

  const [categoriesList, setCategoriesList] = React.useState([]);
  const [rewards, setRewards] = React.useState([]);
  const {
    competitionImgUrl,
    uploadCompetitionImgSuccess,
    uploadCompetitionHeaderSuccess,
    competitionHeaderUrl,
    isUploadingCompetitionImg,
  } = useSelector((state) => state.competitions);
  React.useEffect(() => {
    if (competitionId) {
      getCompetitionById(competitionId);
    } else if (duplicateCompetition) {
      getCompetitionById(duplicateCompetition);
    }
  }, [competitionId, duplicateCompetition, getCompetitionById]);

  React.useEffect(() => {
    if (startDate === null && regStartDate !== null) {
      setValue('start_date', regStartDate);
    }
    if (endDate === null && regEndDate !== null) {
      setValue('end_date', regEndDate);
    }
  }, [regStartDate, regEndDate]);

  React.useEffect(() => {
    reset(details);

    if (details?.competition_type) {
      const competitionType = competitionTypes.find(
        (type) => type.value === details.competition_type,
      );
      setValue('competition_type', competitionType);
    }

    // restoring tag map
    const subcategories = details?.subcategory_weights;

    if (subcategories) {
      let newTagMap = new Map();
      if (details?.category_list) {
        Object.keys(details?.category_list).map(function(categoryIdx) {
          let categoryId = details?.category_list[categoryIdx];
          let subcategoryMap = newTagMap.get(categoryId) || new Map();

          if (subcategories[categoryId])
            Object.keys(subcategories[categoryId]).map((category) => {
              subcategoryMap.set(category, subcategories[categoryId][category]);
            });
          newTagMap.set(categoryId, subcategoryMap);
        });
      }

      setValue('tag_map', newTagMap);
    }

    // restoring tagArray
    let newTagArray = [];
    for (let idx in details?.category_list) {
      const id = details?.category_list[idx];
      tag.category_by_domain[details.domain_id].filter((category) => {
        if (category.id === id) {
          newTagArray.push(category);
          return category;
        }
      });
    }
    setValue('tag_array', newTagArray);

    setRewards(details.rewards_list);
  }, [details]);

  React.useEffect(() => {
    const fetchJobs = async () => {
      const res = await dispatch(getActiveJobs());
      setActiveJobsData(res?.jobs);
    };

    fetchJobs();
  }, []);

  React.useEffect(() => {
    setCategoriesList(tag.category_by_domain[domain_id] || []);
  }, [domain_id, tag]);

  React.useEffect(() => {
    setValue('name', watch('name')?.trimStart());
  }, [watch('name')]);

  const handleDomainRejected = () => {
    setShowDomainChangeDialog(false);
  };

  const handleDomainAccepted = () => {
    setShowDomainChangeDialog(false);
    setValue('domain_id', requestedDomain.id);
    setCategoriesList(tag.category_by_domain[requestedDomain.id] || []);
    setValue('category_list', []);
    setRequestedDomain({});
  };

  const addCategory = () => {
    if (categoryCurrentlyChoosed && weightageInput) {
      const newTagArray = tagArray
        ? [...tagArray, categoryCurrentlyChoosed]
        : [categoryCurrentlyChoosed];
      const newTagWeightages = tagWeightages
        ? [...tagWeightages, parseInt(weightageInput)]
        : [parseInt(weightageInput)];
      let newTagMap = tagMap || new Map();
      if (!newTagMap.has(categoryCurrentlyChoosed.id))
        newTagMap.set(categoryCurrentlyChoosed.id, null);
      setValue('tag_map', newTagMap);
      setValue('tag_array', newTagArray);
      setValue('category_weights', newTagWeightages);
      setValue('tag', '');
      setValue('weightage', '');
      clearErrors('tag_array');
    }
  };
  const removeCategory = (tagIndex) => {
    const newTagArray = tagArray?.filter((_, index) => index !== tagIndex);
    const newTagWeightages = tagWeightages?.filter(
      (_, index) => index !== tagIndex,
    );

    setValue('tag_array', newTagArray);
    setValue('category_weights', newTagWeightages);
  };

  const addReward = () => {
    if (rewardsFrom && rewardsTo && rewardsCredits && rewardsGems) {
      if (
        rewards?.length > 0 &&
        parseInt(rewards[rewards.length - 1].rank_to) + 1 !==
          parseInt(rewardsFrom) &&
        rewards?.some((item) => item.rank_from == rewardsFrom) &&
        rewards?.some((item) => item.rank_to == rewardsTo)
      ) {
        return setError(
          'rewards',
          {
            message: `Next Range should start from ${parseInt(
              rewards[rewards.length - 1].rank_to,
            ) + 1}`,
          },
          {
            shouldFocus: true,
          },
        );
      } else {
        setError('rewards', {});
      }

      if (parseInt(rewardsFrom) > parseInt(rewardsTo)) {
        return setError(
          'rewards',
          { message: `Invalid Range` },
          {
            shouldFocus: true,
          },
        );
      }

      const newRewards =
        rewards?.length > 0
          ? [
              ...rewards,
              {
                rank_from: rewardsFrom,
                rank_to: rewardsTo,
                credits: rewardsCredits,
                gems: rewardsGems,
              },
            ]
          : [
              {
                rank_from: rewardsFrom,
                rank_to: rewardsTo,
                credits: rewardsCredits,
                gems: rewardsGems,
              },
            ];
      setRewards(newRewards.sort((a, b) => a.rank_from - b.rank_from));
      setValue('rewards_from', '');
      setValue('rewards_to', '');
      setValue('rewards_credits', '');
      setValue('rewards_gems', '');
    }
  };

  const removeReward = (rewardIdx) => {
    const newRewards = rewards.filter((reward, index) => index !== rewardIdx);
    setRewards(newRewards);
  };

  const onSubmit = (data) => {
    setLoading(true);

    if (
      (!Array.isArray(tagArray) || tagArray.length === 0) &&
      details.categories_list === null
    )
      return setError(
        'tag_array',
        { message: 'Please add atleast one Category' },
        {
          shouldFocus: true,
        },
      );

    let subcategoryWeights = {};
    if (tagMap && tagArray.length > 0) {
      tagArray.forEach((tag) => {
        if (tagMap.get(tag.id))
          subcategoryWeights[tag.id] = Object.fromEntries(tagMap.get(tag.id));
      });
    }

    let competitionForm = {
      name: data.name,
      description: data.description,
      short_description: data.short_description,
      social_description: data.social_description,
      question_limit: data.question_limit,
      participation_credits: data.participation_credits,
      reg_start_date: data.reg_start_date,
      reg_end_date: data.reg_end_date,
      start_date: data.start_date,
      end_date: data.end_date,
      is_private: data.is_private,
      domain_id: data.domain_id,
      category_list: tagArray.map((tag) => tag.id),
      category_weights: tagWeightages,
      subcategory_weights: subcategoryWeights,
      show_leaderboard: data.show_leaderboard,
      competition_level: data.competition_level,
      competition_type: data.competition_type?.value,
      total_coa: data.total_coa || null,
      additional_notes: helpers.sanitizeHTML(data.additional_notes),
      rules: helpers.sanitizeHTML(data.rules),
      rewards_list: rewards?.length ? rewards : [],
      image_url: competitionImgUrl.length
        ? competitionImgUrl
        : details.image_url,
      competition_header_url: competitionHeaderUrl.length
        ? competitionHeaderUrl
        : details.competition_header_url,
      job_id: selectedJob?.id,
    };
    if (competitionId) {
      competitionForm.id = competitionId;
      postCompetition(competitionForm)
        .then(() => {
          enqueueSnackbar('Competition is successfully updated', {
            variant: 'success',
          });
          setTimeout(() => {
            navigate('/competition');
            setLoading(false);
          }, 3000);
        })
        .catch(() => {
          enqueueSnackbar('Sorry! Unable to update competition', {
            variant: 'error',
          });
          setLoading(false);
        });
    } else {
      postCompetition(competitionForm)
        .then(() => {
          enqueueSnackbar('Competition is successfully created', {
            variant: 'success',
          });
          setTimeout(() => {
            navigate('/competition');
            setLoading(false);
          }, 3000);
        })
        .catch(() => {
          enqueueSnackbar('Sorry! Unable to create competition', {
            variant: 'error',
          });
          setLoading(false);
        });
    }
  };

  if (competition?.isFetchingCompetitionById) {
    return (
      <Container style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />;
        </Box>
      </Container>
    );
  }
  const handleScrollForNumberInputs = (e) => e.target.blur();
  const handleImageUpload = (e) => {
    const imgFile = new FormData();
    imgFile.append('competition_image_file', e.target.files[0]);
    uploadCompetitionImg(watch('name'), imgFile);
  };
  const handleHeaderUpload = (e) => {
    const imgFile = new FormData();
    imgFile.append('competition_header_file', e.target.files[0]);
    uploadCompetitionImg(watch('name'), imgFile);
  };
  return (
    <StyledComponentWrapper>
      <Container>
        <Box display="flex" py={2}>
          <Box flex="1" fontWeight="fontWeightRegular" fontSize={21}>
            Create Competition
          </Box>
        </Box>

        <Card elevation={0} square>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Name</label>
              <TextField
                {...register('name', {
                  required: 'Please enter the competition name',
                })}
                margin="dense"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Description</label>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: 'Please enter the description',
                }}
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    metaData={{
                      domain_id: domain_id,
                    }}
                  />
                )}
              />

              {errors.description ? (
                <FormHelperText error>
                  {errors.description?.message}
                </FormHelperText>
              ) : null}
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Short Description</label>
              <Controller
                control={control}
                name="short_description"
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    metaData={{
                      domain_id: domain_id,
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Social Description</label>
              <Controller
                control={control}
                name="social_description"
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    metaData={{
                      domain_id: domain_id,
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Questions Limit</label>
              <TextField
                {...register('question_limit', {
                  required: 'Please enter the maximum number of questions',
                  min: { value: 30, message: 'The value should be >= 30' },
                  pattern: {
                    value: !/^\d*$/,
                    message:
                      'Please enter a valid number for maximum number of questions',
                  },
                })}
                type="number"
                onWheel={handleScrollForNumberInputs}
                margin="dense"
                variant="outlined"
                error={!!errors.question_limit}
                helperText={errors.question_limit?.message}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Credits</label>
              <TextField
                {...register('participation_credits', {
                  required: 'Please enter the value',
                  min: {
                    value: 0,
                    message: 'The value should not be -ve',
                  },
                })}
                type="number"
                onWheel={handleScrollForNumberInputs}
                margin="dense"
                variant="outlined"
                error={!!errors.participation_credits}
                helperText={errors.participation_credits?.message}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Job Associated</label>

              <ReactSelect
                options={activeJobsData}
                value={selectedJob}
                onChange={(val) => {
                  setSelectedJob(val);
                }}
                getOptionLabel={(option) => option.job_role_details?.name}
                getOptionValue={(option) => option.id}
                className={classes.select}
              />
            </FormControl>

            <Grid container justifyContent="space-evenly">
              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <label className={classes.label}>
                    Registration Start Date And Time
                  </label>
                  <Controller
                    name="reg_start_date"
                    control={control}
                    defaultValue={new Date()}
                    rules={{
                      required: 'Please select registration start date',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        margin="dense"
                        id="date-picker-dialog"
                        format="MM/DD/YYYY HH:mm"
                        value={moment(value || new Date())}
                        showTodayButton
                        onChange={(date) =>
                          onChange(moment(date).toISOString(true))
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        error={!!errors.reg_start_date}
                        placeholder="Select a date and time"
                      />
                    )}
                  />

                  {errors.reg_start_date ? (
                    <FormHelperText error>
                      {errors.reg_start_date?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <label className={classes.label}>
                    Registration End Date And Time
                  </label>
                  <Controller
                    name="reg_end_date"
                    control={control}
                    defaultValue={new Date()}
                    rules={{ required: 'Please select registration end date' }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        margin="dense"
                        id="date-picker-dialog"
                        format="MM/DD/YYYY HH:mm"
                        value={moment(value || new Date())}
                        showTodayButton
                        onChange={(date) =>
                          onChange(moment(date).toISOString(true))
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        error={!!errors.reg_end_date}
                        placeholder="Select a date and time"
                      />
                    )}
                  />
                  {errors.reg_end_date ? (
                    <FormHelperText error>
                      {errors.reg_end_date?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <label className={classes.label}>Start Date And Time</label>
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue={new Date()}
                    rules={{ required: 'Please select competition start date' }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        margin="dense"
                        id="date-picker-dialog"
                        format="MM/DD/YYYY HH:mm"
                        value={moment(value || new Date())}
                        showTodayButton
                        onChange={(date) =>
                          onChange(moment(date).toISOString(true))
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        error={!!errors.start_date}
                        placeholder="Select a date and time"
                      />
                    )}
                  />

                  {errors.start_date ? (
                    <FormHelperText error>
                      {errors.start_date?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                  error={!!errors.end_date}
                >
                  <label className={classes.label}>End Date And Time</label>
                  <Controller
                    name="end_date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: 'Please select competition end date' }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        margin="dense"
                        id="date-picker-dialog"
                        format="MM/DD/YYYY HH:mm"
                        value={moment(value || new Date())}
                        showTodayButton
                        onChange={(date) =>
                          onChange(moment(date).toISOString(true))
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        error={!!errors.end_date}
                        placeholder="Select a date and time"
                      />
                    )}
                  />
                  {errors.end_date ? (
                    <FormHelperText error>
                      {errors.end_date?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <label className={classes.label}>Competition Image</label>
                  <InputBase
                    {...register('competition_image', {
                      required: details.image_url
                        ? false
                        : 'Please upload competition Image',
                    })}
                    margin="dense"
                    className={classes.inputBase}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={handleImageUpload}
                    error={!!errors.competition_image}
                    helperText={!!errors.competition_image}
                    disabled={watch('name') ? false : true}
                  />
                  {watch('name') ? (
                    ''
                  ) : (
                    <span style={{ color: 'red' }}>
                      Competition Name is required to upload
                    </span>
                  )}
                  {isUploadingCompetitionImg ? (
                    <Loader />
                  ) : (
                    <>
                      {uploadCompetitionImgSuccess && (
                        <Box mt={1}>
                          <img
                            src={competitionImgUrl}
                            className={classes.previewImage}
                            alt="Competition Preview"
                          />
                        </Box>
                      )}
                    </>
                  )}

                  {!competitionImgUrl.length && details.image_url && (
                    <Box mt={1}>
                      <img
                        src={details.image_url}
                        className={classes.previewImage}
                        alt="Competition"
                      />
                    </Box>
                  )}
                  {errors.competition_image ? (
                    <FormHelperText error>
                      {errors.competition_image?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <label className={classes.label}>Competition Header</label>
                  <InputBase
                    {...register('competition_header', {
                      required: details.image_url
                        ? false
                        : 'Please upload competition header',
                    })}
                    margin="dense"
                    className={classes.inputBase}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={handleHeaderUpload}
                    error={!!errors.competition_header}
                    disabled={watch('name') ? false : true}
                  />
                  {watch('name') ? (
                    ''
                  ) : (
                    <span style={{ color: 'red' }}>
                      Competition Name is required to upload
                    </span>
                  )}
                  {isUploadingCompetitionImg ? (
                    <Loader />
                  ) : (
                    <>
                      {uploadCompetitionHeaderSuccess && (
                        <Box mt={1}>
                          <img
                            src={competitionHeaderUrl}
                            className={classes.previewHeaderImage}
                            alt="Competition Header Preview"
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {!competitionHeaderUrl.length &&
                    details.competition_header_url && (
                      <Box mt={1}>
                        <img
                          src={details.competition_header_url}
                          height={64}
                          className={classes.previewHeaderImage}
                          alt="Competition Header"
                        />
                      </Box>
                    )}
                  {errors.competition_header ? (
                    <FormHelperText error>
                      {errors.competition_header?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justifyContent="space-evenly">
              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <Controller
                    control={control}
                    name="is_private"
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              onChange={(event) => {
                                field.onChange(event.target.checked);
                              }}
                            />
                          }
                          label="Private"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item className={classes.halfGrid}>
                <FormControl
                  fullWidth
                  margin="dense"
                  className={classes.formControl}
                >
                  <Controller
                    control={control}
                    name="show_leaderboard"
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              onChange={(event) => {
                                field.onChange(event.target.checked);
                              }}
                            />
                          }
                          label="Show Leaderboard"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Domain</label>
              <Controller
                control={control}
                name="domain_id"
                rules={{
                  required: 'Please select a domain',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    options={domainList}
                    value={domainList.find((c) => c.id === value)}
                    onChange={(val) => {
                      const current_categories =
                        categoryList?.map((category) => category.id) || [];
                      const dropdown_categories = tag.category_by_domain[
                        val.id
                      ].map((category) => category.id);
                      if (
                        current_categories.every((value) =>
                          dropdown_categories.includes(value),
                        )
                      ) {
                        onChange(val.id);
                      } else {
                        setShowDomainChangeDialog(true);
                        setRequestedDomain(val);
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    className={classes.select}
                  />
                )}
              />
              {errors.domain_id ? (
                <FormHelperText error>
                  {errors.domain_id?.message}
                </FormHelperText>
              ) : null}
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Competition Type</label>
              <Controller
                control={control}
                name="competition_type"
                defaultValue={competitionTypes.find(
                  (type) => type.value === 'General',
                )}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    options={competitionTypes}
                    value={value}
                    onChange={(val) => onChange(val)}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    className={classes.select}
                  />
                )}
              />
            </FormControl>

            <div className="category-selection-wrapper">
              <FormControl
                margin="dense"
                className={'choose-category'}
                error={!!errors.category_list}
              >
                <label className={classes.label}>Categories</label>

                <Controller
                  control={control}
                  name="categoryTag"
                  rules={{
                    validate: () => tagArray?.length !== 0,
                  }}
                  render={({ field }) => {
                    return (
                      <div className={classes.category_select_wrapper}>
                        <ReactSelect
                          {...field}
                          options={categoriesList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          className={classes.select_category}
                        />
                      </div>
                    );
                  }}
                />
                {errors.category_list ? (
                  <FormHelperText error>
                    {errors.category_list?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <div className="category__weightage">
                <div>
                  <label htmlFor="">Weightage</label>
                  <div className="field-item">
                    <TextField
                      {...register('weightage', {
                        min: {
                          value: 0,
                          message: 'The value cannot be negative',
                        },
                      })}
                      fullWidth
                      type="number"
                      onWheel={handleScrollForNumberInputs}
                      placeholder="Weightage %"
                      size="small"
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.weightage}
                      helperText={errors.weightage?.message}
                    />
                  </div>
                </div>
              </div>

              <Box
                height={'5rem'}
                display={'flex'}
                alignItems={'flex-end'}
                paddingBottom={'0.5rem'}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addCategory}
                >
                  Add
                </Button>
              </Box>

              {categoryCurrentlyChoosed && (
                <Box
                  height={'5rem'}
                  display={'flex'}
                  alignItems={'flex-end'}
                  paddingBottom={'0.5rem'}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowSubcategoryAddDialog(true)}
                  >
                    Add Subcategories
                  </Button>
                </Box>
              )}

              {(editParentTag || categoryCurrentlyChoosed) && (
                <ChooseSubcategoryDialog
                  {...{
                    tagInput: categoryCurrentlyChoosed,
                    tagMap,
                    editParentTag,
                    subTagDialogOpen: showSubcategoryAddDialog,
                    setSubTagDialogOpen: setShowSubcategoryAddDialog,
                    setValueParent: setValue,
                  }}
                />
              )}
            </div>

            {tagArray?.map((tag, index) => (
              <div className="createtest__tag" key={tag.id}>
                <p>
                  {tag.name}{' '}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSubcategoryAddDialog(true);
                      setValue('edit_parent_tag', tag);
                    }}
                    href=""
                  >
                    (edit subcategories)
                  </a>
                </p>
                <p>{tagWeightages ? tagWeightages[index] : 100}%</p>

                <IconButton
                  onClick={() => removeCategory(index)}
                  size="small"
                  color="primary"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
            {errors.categoryTag?.type === 'validate' &&
            tagArray.length === 0 ? (
              <FormHelperText error>
                Please select atleast one category
              </FormHelperText>
            ) : null}
            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Level</label>

              <Controller
                control={control}
                name="competition_level"
                rules={{
                  required: 'Please select a level',
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactSelect
                    options={competition_levels}
                    value={competition_levels.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    className={classes.select}
                  />
                )}
              />
              {errors.competition_level ? (
                <FormHelperText error>
                  {errors.competition_level?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Total Certificates</label>
              <TextField
                {...register('total_coa', {
                  required: 'Please enter number of certificates',
                  min: {
                    value: 0,
                    message: 'The value cannot be negative',
                  },
                })}
                fullWidth
                type="number"
                onWheel={handleScrollForNumberInputs}
                placeholder="Total Certificates"
                size="small"
                margin="dense"
                variant="outlined"
                className="text-input-field"
                error={!!errors.total_coa}
                helperText={errors.total_coa?.message}
              />
            </FormControl>
            <div
              style={{
                background: '#F7F8F8',
                padding: '1rem 0.5rem',
                borderRadius: '10px',
              }}
            >
              <FormControl
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <label className={classes.label}>Rewards</label>
                <Controller
                  control={control}
                  name="rewards"
                  render={() => (
                    <div className="rewardsWrapper">
                      <div className="rangeInput">
                        <TextField
                          {...register('rewards_from', {
                            min: {
                              value: 0,
                              message: 'The value cannot be negative',
                            },
                          })}
                          fullWidth
                          type="number"
                          onWheel={handleScrollForNumberInputs}
                          label="From Rank"
                          size="small"
                          margin="dense"
                          variant="outlined"
                          className="text-input-field"
                          error={!!errors.rewards_from}
                          helperText={errors.rewards_from?.message}
                        />
                      </div>
                      <div className="rewards-input">
                        <TextField
                          {...register('rewards_to', {
                            min: {
                              value: 1,
                              message: 'The value should not be 0 or less',
                            },
                          })}
                          fullWidth
                          type="number"
                          onWheel={handleScrollForNumberInputs}
                          label="To Rank"
                          size="small"
                          margin="dense"
                          variant="outlined"
                          className="text-input-field"
                          error={!!errors.rewards_to}
                          helperText={errors.rewards_to?.message}
                        />
                      </div>

                      <div className="rewards-input">
                        <TextField
                          {...register('rewards_credits', {
                            min: {
                              value: 0,
                              message: 'The value cannot be negative',
                            },
                          })}
                          fullWidth
                          type="number"
                          onWheel={handleScrollForNumberInputs}
                          label="Credits"
                          size="small"
                          margin="dense"
                          variant="outlined"
                          className="text-input-field"
                          error={!!errors.rewards_credits}
                          helperText={errors.rewards_credits?.message}
                        />
                      </div>
                      <div className="rewars-input">
                        <TextField
                          {...register('rewards_gems', {
                            min: {
                              value: 0,
                              message: 'The value cannot be negative',
                            },
                          })}
                          fullWidth
                          type="number"
                          onWheel={handleScrollForNumberInputs}
                          label="Gems"
                          size="small"
                          margin="dense"
                          variant="outlined"
                          className="text-input-field"
                          error={!!errors.rewards_gems}
                          helperText={errors.rewards_gems?.message}
                        />
                      </div>
                      <Button
                        color="primary"
                        style={{ height: '42px' }}
                        variant="outlined"
                        onClick={addReward}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                />
              </FormControl>
              {errors.rewards ? (
                <FormHelperText error style={{ marginLeft: '1rem' }}>
                  {errors.rewards?.message}
                </FormHelperText>
              ) : null}

              {rewards?.length > 0 && (
                <Table style={{ margin: '1rem 0' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-col">From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Credits</TableCell>
                      <TableCell>Gems</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rewards.map((reward, index) => {
                      return (
                        <TableRow className="reward_bar" key={index}>
                          <TableCell className="table-col">
                            <Typography varient="body1">
                              {reward.rank_from}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-col">
                            <Typography varient="body1">
                              {reward.rank_to}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-col">
                            <Typography varient="body1">
                              {reward.credits}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-col">
                            <Typography varient="body1">
                              {reward.gems}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-col">
                            <Button onClick={() => removeReward(index)}>
                              <CloseIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </div>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Rules</label>
              <Controller
                control={control}
                name="rules"
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    metaData={{
                      domain_id: domain_id,
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <label className={classes.label}>Additional Notes</label>
              <Controller
                control={control}
                name="additional_notes"
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    metaData={{
                      domain_id: domain_id,
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl margin="dense" className={classes.formControl}>
              <div className={classes.wrapper}>
                <Button
                  disabled={loading || isUploadingCompetitionImg}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {loading || isUploadingCompetitionImg ? (
                    <CircularProgress />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </FormControl>
          </form>
        </Card>
        {/* <MessageBar
        type={status}
        open={isStatusMessageVisible}
        onClose={this.handleMessageClose}
        message={message}
      /> */}
        <DomainChangeDialog
          visible={showDomainChangeDialog}
          requested_domain={requestedDomain}
          dialogTitleMessage="Do you really want to change the Domain?"
          onConfirm={handleDomainAccepted}
          onClose={handleDomainRejected}
        />
      </Container>
    </StyledComponentWrapper>
  );
};
export default CompetitionCreate;
