import axios from 'axios';
import {
  ADD_COMMENT_TO_QUESTION_FAIL,
  ADD_COMMENT_TO_QUESTION_START,
  ADD_COMMENT_TO_QUESTION_SUCCESS,
  CLEAR_COMMENTS_FOR_QUESTION_STATE,
  CLEAR_HOT_TOPICS_DISCUSSIONS_STATE,
  CLEAR_RECENT_ATTEMPT_DISCUSSIONS_STATE,
  CLEAR_VOTE_A_COMMENT_STATE,
  GET_COMMENTS_FOR_QUESTION_FAIL,
  GET_COMMENTS_FOR_QUESTION_START,
  GET_COMMENTS_FOR_QUESTION_SUCCESS,
  GET_HOT_TOPICS_DISCUSSIONS_FAIL,
  GET_HOT_TOPICS_DISCUSSIONS_START,
  GET_HOT_TOPICS_DISCUSSIONS_SUCCESS,
  GET_RECENT_ATTEMPT_DISCUSSIONS_FAIL,
  GET_RECENT_ATTEMPT_DISCUSSIONS_START,
  GET_RECENT_ATTEMPT_DISCUSSIONS_SUCCESS,
  REPORT_COMMENT_CLEAR,
  REPORT_COMMENT_FAIL,
  REPORT_COMMENT_START,
  REPORT_COMMENT_SUCCESS,
  VOTE_A_COMMENT_FAIL,
  VOTE_A_COMMENT_START,
  VOTE_A_COMMENT_SUCCESS,
} from './types';
import config from '../config';

export function getRecentAttemptDiscussionsStart() {
  return {
    type: GET_RECENT_ATTEMPT_DISCUSSIONS_START,
  };
}

export function getRecentAttemptDiscussionsSuccess({ data }) {
  return {
    type: GET_RECENT_ATTEMPT_DISCUSSIONS_SUCCESS,
    data,
  };
}

export function getRecentAttemptDiscussionsFail({ data }) {
  return {
    type: GET_RECENT_ATTEMPT_DISCUSSIONS_FAIL,
    data,
  };
}

export function getRecentAttemptDiscussions() {
  // fill this
}

export function clearRecentAttemptDiscussionState() {
  return {
    type: CLEAR_RECENT_ATTEMPT_DISCUSSIONS_STATE,
  };
}

export function getHotTopicDiscussionsStart() {
  return {
    type: GET_HOT_TOPICS_DISCUSSIONS_START,
  };
}

export function getHotTopicDiscussionsSuccess({ data }) {
  return {
    type: GET_HOT_TOPICS_DISCUSSIONS_SUCCESS,
    data,
  };
}

export function getHotTopicDiscussionsFail({ data }) {
  return {
    type: GET_HOT_TOPICS_DISCUSSIONS_FAIL,
    data,
  };
}

export function getHotTopicDiscussions() {
  // fill this
}

export function clearHotTopicDiscussionsState() {
  return {
    type: CLEAR_HOT_TOPICS_DISCUSSIONS_STATE,
  };
}

export function getCommentsForQuestionStart() {
  return {
    type: GET_COMMENTS_FOR_QUESTION_START,
  };
}

export function getCommentsForQuestionSuccess({ data }) {
  return {
    type: GET_COMMENTS_FOR_QUESTION_SUCCESS,
    data,
  };
}

export function getCommentsForQuestionFail({ data }) {
  return {
    type: GET_COMMENTS_FOR_QUESTION_FAIL,
    data,
  };
}

export function getCommentsForQuestion(questionId) {
  return (dispatch, getState) => {
    dispatch(getCommentsForQuestionStart());
    const { auth } = getState();

    axios
      .get(`${config.API_URL}/question/${questionId}/comment`, {
        headers: {
          token: auth.token,
        },
      })
      .then((res) => {
        dispatch(getCommentsForQuestionSuccess({ data: res.data.data }));
      })
      .catch((err) => {
        dispatch(getCommentsForQuestionFail({ data: err }));
      });
  };
}

export function clearCommentsForQuestionState() {
  return {
    type: CLEAR_COMMENTS_FOR_QUESTION_STATE,
  };
}

export function voteACommentStart() {
  return {
    type: VOTE_A_COMMENT_START,
  };
}

export function voteACommentSuccess() {
  return {
    type: VOTE_A_COMMENT_SUCCESS,
  };
}

export function voteACommentFail({ data }) {
  return {
    type: VOTE_A_COMMENT_FAIL,
    data,
  };
}

export function voteAComment(questionId, commentId, voteType) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(voteACommentStart());
    axios
      .put(
        `${config.API_URL}/question/${questionId}/comment/${commentId}/vote`,
        null,
        {
          headers: {
            token: auth.token,
          },
          params: {
            vote: voteType,
          },
        },
      )
      .then(() => dispatch(voteACommentSuccess()))
      .catch((err) => dispatch(voteACommentFail({ data: err })));
  };
}

export function clearVoteACommentState() {
  return {
    type: CLEAR_VOTE_A_COMMENT_STATE,
  };
}

export function reportCommentStart() {
  return {
    type: REPORT_COMMENT_START,
  };
}

export function reportCommentSuccess() {
  return {
    type: REPORT_COMMENT_SUCCESS,
  };
}

export function reportCommentFail({ data }) {
  return {
    type: REPORT_COMMENT_FAIL,
    data,
  };
}

export function reportComment(
  questionId,
  commentId,
  reportType,
  details,
  messageSettingCallback,
) {
  return (dispatch, getState) => {
    dispatch(reportCommentStart());
    const { auth } = getState();
    const body = { details };
    const headers = {
      'Content-Type': 'application/json',
      token: auth.token,
    };
    const params = {
      report_type: reportType,
    };
    axios
      .post(
        `${config.API_URL}/question/${questionId}/comment/${commentId}/report`,
        body,
        { headers, params },
      )
      .then(() => {
        dispatch(reportCommentSuccess());
        messageSettingCallback('Report Submitted');
      })
      .catch((err) => {
        if (
          err?.response?.data?.error === 'comment_is_already_submitted' ||
          err?.response?.data?.error === 'comment_is_already_reported'
        ) {
          messageSettingCallback('Report Already Submitted!');
          dispatch(reportCommentSuccess());
          return;
        }
        dispatch(reportCommentFail({ data: err }));
      });
  };
}

export function clearReportComment() {
  return {
    type: REPORT_COMMENT_CLEAR,
  };
}

export function addCommentQuestionStart() {
  return {
    type: ADD_COMMENT_TO_QUESTION_START,
  };
}

export function addCommentToQuestionSuccess({ data }) {
  return {
    type: ADD_COMMENT_TO_QUESTION_SUCCESS,
    data,
  };
}

export function addCommentToQuestionFail({ data }) {
  return {
    type: ADD_COMMENT_TO_QUESTION_FAIL,
    data,
  };
}

export function addCommentToQuestion(
  comment,
  commentType,
  questionId,
  parentCommentId,
) {
  return (dispatch, getState) => {
    dispatch(addCommentQuestionStart());
    const { auth } = getState();
    const headers = {
      'Content-Type': 'application/json',
      token: auth.token,
    };
    const body = {
      comment,
      comment_type: commentType,
      parent_comment_id: parentCommentId,
    };

    axios
      .post(`${config.API_URL}/question/${questionId}/comment`, body, {
        headers,
      })
      .then((res) => {
        dispatch(addCommentToQuestionSuccess({ data: res.data.data }));
      })
      .catch((err) => dispatch(addCommentToQuestionFail({ data: err })));
  };
}
