import * as React from 'react';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
  Checkbox,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.common.black,
    fontSize: 'inherit',
    paddingBottom: theme.spacing(1),
  },
}));

const AnswerIncorrectOption = ({
  questionData,
  onChange,
  value = { reason_type: '', correct_values: [] },
}) => {
  const classes = useStyles();
  const { options, question_type } = questionData;

  const onOptionChange = (option) => {
    onChange({
      reason_type: 'OptionIncorrect',
      correct_values: [+option.target.value],
    });
  };

  const onOneWordTextChange = (e) => {
    onChange({
      reason_type: 'AnswerIncorrect',
      correct_text: e.target.value,
    });
  };

  const onMultipleCorrectChange = (e) => {
    const val = e.target.value;
    let currentValues = value?.correct_values || [];
    if (currentValues.includes(val)) {
      currentValues = currentValues.filter((v) => v !== val);
    } else {
      currentValues = [...currentValues, parseInt(val)];
    }
    onChange({
      reason_type: 'OptionIncorrect',
      correct_values: currentValues,
    });
  };

  return (
    <div>
      {['MultipleChoice', 'MultipleCorrect'].includes(question_type) ? (
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.formLabel}>
            Select correct option
          </FormLabel>
          {question_type === 'MultipleChoice' ? (
            <RadioGroup
              aria-label="option"
              name="option"
              onChange={onOptionChange}
              value={value?.correct_values?.[0] || ''}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.option_id}
                  value={option.option_serial_no}
                  control={<Radio />}
                  label={option.option_text}
                />
              ))}
            </RadioGroup>
          ) : (
            <Grid display="flex" flexDirection="column">
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.option_serial_no}
                  label={option.option_text}
                  control={
                    <Checkbox
                      checked={value?.correct_values?.includes(
                        option.option_serial_no,
                      )}
                      onChange={(e) => onMultipleCorrectChange(e)}
                    />
                  }
                />
              ))}
            </Grid>
          )}
        </FormControl>
      ) : question_type === 'OneWord' ? (
        <FormControl fullWidth>
          <FormLabel component="legend" className={classes.formLabel}>
            Enter your answer
          </FormLabel>
          <TextField
            margin="normal"
            variant="outlined"
            size="small"
            value={value?.correct_text || ''}
            onChange={onOneWordTextChange}
          />
        </FormControl>
      ) : null}
    </div>
  );
};

export default AnswerIncorrectOption;
