import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBadge, getBadges } from '../../actions/badges';
import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SearchBox from '../SearchBox';
import { Delete } from '@mui/icons-material';
import {
  TagListName,
  TagListHeader,
  TagListAvatar,
  TagAction,
  NoTag,
} from './StyledComponents';

export const CreateBadge = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const { badgeData } = useSelector((state) => state.badges);
  useEffect(() => {
    dispatch(getBadges(searchTerm, page + 1, rowsPerPage));
  }, [dispatch, page, rowsPerPage, searchTerm]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleDeleteBadge = (id) => {
    dispatch(deleteBadge(id, searchTerm, page + 1, rowsPerPage));
  };

  const handleEditBadge = (id) => {
    const selectedBadge = badgeData.badges?.find((badge) => badge.id === id);
    navigate(
      {
        pathname: `/l/editBadge/${id}`,
        search: `${id}`,
      },
      {
        state: { selectedBadge },
      },
    );
  };
  return (
    <Container maxWidth={false}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <TagListHeader>Badges</TagListHeader>
        <TagAction>
          <NavLink to="/l/createNewBadge">
            <Button color="primary" variant="outlined">
              Add Badges
            </Button>
          </NavLink>
        </TagAction>
      </div>
      <div>
        <SearchBox
          value={searchTerm}
          handleSearch={(e) => setSearchTerm(e.target.value)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Segement</TableCell>
              <TableCell>Milestone No</TableCell>
              <TableCell>Gem Reward</TableCell>
              <TableCell>Credit Reward</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {badgeData.badges?.map((data) => (
              <TableRow key={data.id}>
                <TableCell style={{ cursor: 'pointer' }}>
                  <TagListName>
                    <TagListAvatar alt={data.name} src={data.image_url} />
                    {data.name}
                  </TagListName>
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  {data.description}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>{data.type}</TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  {data.segment}
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.milestone_no}
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.gem_reward ?? 0}
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.credit_reward ?? 0}
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    onClick={() => handleEditBadge(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Delete
                    onClick={() => handleDeleteBadge(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!badgeData.badges && <NoTag>No Such tag found </NoTag>}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="nav"
        page={page}
        rowsPerPage={rowsPerPage}
        count={badgeData.total_pages * badgeData.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
      />
    </Container>
  );
};

export default CreateBadge;
