import axios from 'axios';
import * as types from './types';

import config from './../config';

export function getNotificationsStart() {
  return {
    type: types.GET_NOTIFICATIONS_START,
  };
}

export function getNotificationsSuccess({ data }) {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    data,
  };
}

export function getNotificationsFail() {
  return {
    type: types.GET_NOTIFICATIONS_FAIL,
  };
}

export function getNotifications({ page, page_size, body }) {
  return (dispatch, getState) => {
    dispatch(getNotificationsStart());
    const { auth } = getState();
    const params = Object.assign({}, body);
    axios
      .get(
        `${config.API_URL}/notifications?page=${page}&page_size=${page_size}`,
        {
          params,
          headers: {
            token: auth.token,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((resp) => {
        dispatch(getNotificationsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getNotificationsFail());
      });
  };
}

export function getNewNotificationsStart() {
  return {
    type: types.GET_NEW_NOTIFICATIONS_START,
  };
}

export function getNewNotificationsSuccess({ data }) {
  return {
    type: types.GET_NEW_NOTIFICATIONS_SUCCESS,
    data,
  };
}

export function getNewNotificationsFail() {
  return {
    type: types.GET_NEW_NOTIFICATIONS_FAIL,
  };
}

export function getNewNotifications() {
  return (dispatch, getState) => {
    dispatch(getNewNotificationsStart());
    const { auth } = getState();

    axios
      .get(`${config.API_URL}/notifications/new`, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(getNewNotificationsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getNewNotificationsFail());
      });
  };
}
