import {
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_START,
  GET_LEADERBOARD_SUCCESS,
} from './types';
import axios from 'axios';
import config from './../config';

export const getLeaderboardStart = () => ({
  type: GET_LEADERBOARD_START,
});

export const getLeaderboardSuccess = ({ data }) => ({
  type: GET_LEADERBOARD_SUCCESS,
  data,
});

export const getLeaderboardFail = ({ data }) => ({
  type: GET_LEADERBOARD_FAIL,
  data,
});

let getLeaderBoardAbortController = null;
export const getLeaderboard = ({
  pageNumber,
  pageSize,
  timePeriod,
  tagId,
  domain,
  domainId,
  userSearchQuery,
  competitionId,
  onlyFollowed,
  userType,
  leaderboardType,
}) => async (dispatch, getState) => {
  const {
    auth: { token },
  } = getState();
  dispatch(getLeaderboardStart());

  if (getLeaderBoardAbortController) {
    getLeaderBoardAbortController.abort();
  }

  getLeaderBoardAbortController = new AbortController();

  const params = {
    page: pageNumber,
    page_size: pageSize,
    time_period: timePeriod,
    tag_id: tagId,
    domain,
    domain_id: domainId,
    user_search_query: userSearchQuery,
    competition: competitionId,
    only_followed: onlyFollowed,
    user_type: userType,
  };

  if (params.competition) {
    params.time_period = 'competition';
  }

  try {
    const res = await axios.get(`${config.API_URL}/leaderboard`, {
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      params: params,
      signal: getLeaderBoardAbortController.signal,
    });
    dispatch(getLeaderboardSuccess({ data: { ...res.data, leaderboardType } }));
  } catch (error) {
    dispatch(
      getLeaderboardFail({
        data: error,
      }),
    );
  }
};

export const getAuthorLeaderboard = ({
  pageNumber,
  pageSize,
  onlyFollowed,
  domainId,
  tagId,
  userSearchQuery,
  sortBy,
  sortOrder,
  leaderboardType,
}) => async (dispatch, getState) => {
  const {
    auth: { token },
  } = getState();

  dispatch(getLeaderboardStart());

  const params = {
    page: pageNumber,
    page_size: pageSize,
    only_followed: onlyFollowed,
    domain_id: domainId,
    tag_id: tagId,
    user_search_query: userSearchQuery,
    sort_by: sortBy,
    sort_order: sortOrder,
  };

  try {
    const res = await axios.get(`${config.API_URL}/user/author-leaderboard`, {
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      params: params,
    });
    dispatch(getLeaderboardSuccess({ data: { ...res.data, leaderboardType } }));
  } catch (error) {
    dispatch(
      getLeaderboardFail({
        data: error,
      }),
    );
  }
};
