import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Typography, FormHelperText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import UploadResumeIcon from './images/upload-resume-icon.svg';
import { uploadResume } from '../../actions/user';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import PdfViewer from '../PdfViewer';
import RuleBox from '../RuleBox';
import { DELETE_RESUME } from '../../actions/types';

const rules = [
  'File format: Your resume or CV should be in PDF format.',
  'File size: The maximum allowed size for your resume or CV is 3 MB.',
  'Updated information: Please make sure to upload an updated version of your resume or CV that includes your latest experience and skills.',
  'Original and genuine information: All the information mentioned in your resume or CV must be original and truthful. Providing false information can result in your account being suspended or terminated.',
];

const UploadResumeDialog = ({ visible = false, onConfirm, onClose }) => {
  const dispatch = useDispatch();
  const [inputRef, setInputRef] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const isResumeUploadingSuccess = useSelector(
    (state) => state.user?.isResumeUploadingSuccess,
  );
  const isResumeUploadingInProgress = useSelector(
    (state) => state.user?.isResumeUploadingInProgress,
  );
  const [uploadingFailed, setUploadingFailed] = useState(false);

  const handleChange = async (e) => {
    const { files } = e.target;
    setUploadingFailed(false);
    setResumeFile(null);
    dispatch({ type: DELETE_RESUME });
    dispatch(uploadResume(files[0]));
    setResumeFile(files[0]);
  };

  useEffect(() => {
    if (
      isResumeUploadingSuccess === false &&
      isResumeUploadingInProgress === false
    ) {
      setUploadingFailed(true);
    }
  }, [isResumeUploadingSuccess, isResumeUploadingInProgress]);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      handleFile(droppedFile);
    }
  };

  const handleFile = (file) => {
    setUploadingFailed(false);
    setResumeFile(null);
    dispatch({ type: DELETE_RESUME });
    dispatch(uploadResume(file));
    setResumeFile(file);
  };
  return (
    <Dialog open={visible} onClose={onClose} maxWidth="100vw">
      <Box
        width={{ md: '70vw' }}
        padding={{ xs: '10px 15px', md: '20px 30px !important' }}
        position="relative"
      >
        <Typography
          variant="h4"
          color="primary"
          className="reasumeUpload__heading"
        >
          Join the Competition and Secure Your Dream Job
        </Typography>
        <Typography variant="subtitle1" color="primary">
          Upload Your Updated Resume for Registration.
        </Typography>
        <Box position="absolute" right="15px" top="15px">
          <HighlightOffTwoToneIcon
            style={{ color: '#25507B', cursor: 'pointer' }}
            onClick={onClose}
          />
        </Box>
        <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box sx={{ transform: 'scale(0)' }} position="absolute">
              <input
                type="file"
                id="resume"
                accept="application/pdf, application/vnd.ms-excel"
                onChange={handleChange}
                ref={(ref) => setInputRef(ref)}
              />
            </Box>
            {!resumeFile ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box onClick={() => inputRef?.click()}>
                  <Box display="flex" flexDirection="column">
                    <img src={UploadResumeIcon} height="300px" width="300px" />
                    <Button variant="contained" color="primary">
                      Upload Resume/CV
                    </Button>
                  </Box>
                </Box>
                <RuleBox
                  heading="When uploading your resume or CV on our platform, please consider the following instructions for the best experience:"
                  points={rules}
                />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                width="100%"
                padding="0px"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  style={{ marginBottom: '15px' }}
                  onClick={() => inputRef?.click()}
                >
                  Update
                </Button>
                <PdfViewer
                  file={resumeFile}
                  style={{
                    maxHeight: '50vh',
                    overflow: 'scroll',
                    border: '1px solid #0000001f',
                  }}
                />
                {uploadingFailed && (
                  <FormHelperText error>
                    Something failed. Please try again later!
                  </FormHelperText>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  mt="10px"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    disabled={isResumeUploadingInProgress}
                    style={{ background: '#3572B0' }}
                  >
                    {!isResumeUploadingInProgress ? (
                      'REGISTER'
                    ) : (
                      <Loader style={{ color: 'white' }} />
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </Box>
    </Dialog>
  );
};

export default UploadResumeDialog;
