import React from 'react';
import DisabledNextIconForPagination from '../../images/DisabledNextIconForPagination.svg';
import DisabledBackIconForPagination from '../../images/DisabledBackIconForPagination.svg';
import NextIconForPagination from '../../images/NextIconForPagination.svg';
import BackIconForPagination from '../../images/BackIconForPagination.svg';
import { nanoid } from 'nanoid';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {
  LeaderboardPaginationWrapper,
  LeaderboardPaginationRowView,
} from './StyledComponents';
import Select from '@mui/material/Select';

const CustomPagination = ({
  count,
  page,
  rowsPerPage,
  rowsPerPageOption,
  onRowsPerPageChange,
  onPageChange,
}) => {
  const numberOfPages = count / rowsPerPage > 1 ? count / rowsPerPage : 1;
  return (
    <LeaderboardPaginationWrapper>
      Page:
      <Select
        disableUnderline={true}
        variant={'standard'}
        value={page}
        onChange={(event) => onPageChange(event.target.value)}
      >
        {[...Array(numberOfPages).keys()].map((pageNumber) => (
          <MenuItem key={nanoid()} value={pageNumber + 1}>
            {pageNumber + 1}
          </MenuItem>
        ))}
      </Select>
      Rows:
      <Select
        disableUnderline={true}
        variant={'standard'}
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
      >
        {rowsPerPageOption.map((row) => (
          <MenuItem key={nanoid()} value={row}>
            {row}
          </MenuItem>
        ))}
      </Select>
      <LeaderboardPaginationRowView>
        <div>
          {`${(page - 1) * rowsPerPage + 1} - ${page * rowsPerPage}`} of{' '}
          {count || 1}
        </div>
        <IconButton disabled={page <= 1} onClick={() => onPageChange(page - 1)}>
          <img
            src={
              page <= 1 ? DisabledBackIconForPagination : BackIconForPagination
            }
            alt={'back-icon'}
          />
        </IconButton>
        <IconButton
          disabled={page >= count / rowsPerPage}
          onClick={() => onPageChange(page + 1)}
        >
          <img
            src={
              page >= count / rowsPerPage
                ? DisabledNextIconForPagination
                : NextIconForPagination
            }
            alt={'next-icon'}
          />
        </IconButton>
      </LeaderboardPaginationRowView>
    </LeaderboardPaginationWrapper>
  );
};

export default CustomPagination;
