import { styled } from '@mui/styles';

export const StyledCard = styled('div')(({ theme }) => ({
  marginBottom: '20px',
  background: theme.palette.common.white,
  borderRadius: '4px',

  '& .card-heading': {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    background: theme.palette.common.white,
    textTransform: 'uppercase',
    padding: '15px',
    borderBottom: `1px solid ${theme.palette.component.shadowColor2}`,
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
  },

  '& .card-content': {
    padding: '15px',
  },
}));
