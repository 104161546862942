import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostedJobsByTheUser } from '../../../actions/salesDashboard';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TablePagination,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import config from '../../../config';

const JobsPosted = ({ salesLead }) => {
  const dispatch = useDispatch();
  const { postedJobsList } = useSelector((state) => state.SalesDashboard);
  const token = localStorage.getItem('projq__auth__token');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getPostedJobsByTheUser(page + 1, rowsPerPage, salesLead?.id));
  }, [page, rowsPerPage]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleJobDetailsRedirect = (alias) => {
    window.open(`${config.JOBS_URL}/job-search/${alias}`, '_blank');
  };

  const handleApplicantDetailsRedirect = (alias) => {
    window.open(
      `${config.JOBS_URL}/login?token=${token}&alias=${alias}&readonly=true`,
      '_blank',
    );
  };

  return (
    <Box sx={{ overflow: 'auto', height: '60vh' }}>
      {!postedJobsList?.jobs?.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          There are no previous Job Posts.
        </Typography>
      ) : (
        <Table sx={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Job Title</TableCell>
              <TableCell align="center">Applicants Count</TableCell>
              <TableCell align="center">Shorlisted</TableCell>
              <TableCell align="center">Hired</TableCell>
              <TableCell align="center">Date Created</TableCell>
              <TableCell align="center">Jobs Details</TableCell>
              <TableCell align="center">Applicants Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {postedJobsList?.jobs?.map((attempt) => (
                <TableRow key={attempt.alias}>
                  <TableCell align="center">
                    <div className="sales__list__name">
                      {attempt?.job_role_name || 'NA'}
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <div className="sales__list__name">
                      {attempt?.total_applicants}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="sales__list__name">
                      {attempt?.total_shortlisted}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="sales__list__name">
                      {attempt?.total_hired}
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ cursor: 'pointer' }}>
                    <div className="sales__list__name">
                      <Tooltip
                        title={
                          <Box sx={{ padding: '10px', color: 'white' }}>
                            <Typography variant="h6">
                              {moment
                                .utc(attempt?.created_at)
                                .local()
                                .format('DD/MM/YYYY, hh:mm a')}
                            </Typography>
                          </Box>
                        }
                        placement="left"
                      >
                        {moment.utc(attempt?.created_at).fromNow()}
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleJobDetailsRedirect(attempt?.alias)}
                  >
                    <LaunchIcon fontSize="small" />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleApplicantDetailsRedirect(attempt?.alias)
                    }
                  >
                    <LaunchIcon fontSize="small" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      )}
      <TablePagination
        component="nav"
        page={page}
        rowsPerPage={rowsPerPage}
        count={postedJobsList.total_pages * postedJobsList.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
        className="scoreboard__navigation"
      />
    </Box>
  );
};

export default JobsPosted;
