import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  postEducationDetails,
  uploadStudentVerification,
} from '../../actions/collegeDashboard';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { sendCollegeAutocompleteQuery } from '../../actions/user';

const AddCollegeDialog = ({ open, onClose }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { docUrl, isUploadingStudentDoc } = useSelector(
    (state) => state.collegeDashboard,
  );
  const onChangeFunction = () => {
    dispatch(uploadStudentVerification(inputRef.current.files[0]));
  };
  const onSubmitHandler = (data) => {
    data.verification_doc_url = docUrl.url;
    data.college_name = data.college_name?.college_name;
    dispatch(postEducationDetails(data));
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              sx={{ color: '#25507B', fontSize: '24px', fontWeight: 600 }}
            >
              Change College & Explore Stats
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
            <IconButton onClick={onClose}>
              <CancelIcon sx={{ color: '#25507B' }} />
            </IconButton>
          </Grid>
        </Grid>

        <Typography
          sx={{ color: '#25507B', fontSize: '16px', fontWeight: 400 }}
        >
          Update your college and discover its analytics to improve your
          learning experience.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ paddingBottom: '10px' }}>
          <label>College</label>
          <div>
            <Controller
              control={control}
              name="college_name"
              defaultValue=""
              rules={{
                required: 'This Field is Required',
              }}
              render={({ field }) => (
                <>
                  <AsyncCreatableSelect
                    {...field}
                    cacheOptions
                    noOptionsMessage={() => 'Search College to show options...'}
                    className="text-input-field"
                    placeholder="Search College..."
                    loadOptions={(query, callback) =>
                      dispatch(sendCollegeAutocompleteQuery(query, callback))
                    }
                    getOptionLabel={(option) =>
                      option.college_name
                        ? option.college_name
                        : `Add New:  "${option.value}"`
                    }
                    getOptionValue={(option) =>
                      option.id ? option.id : option.value
                    }
                  />
                </>
              )}
            />
            <FormHelperText error={!!errors.college_name}>
              {errors.college_name?.message}
            </FormHelperText>
          </div>
        </Grid>
        <Grid sx={{ paddingBottom: '10px' }}>
          <label>Degree</label>
          <TextField
            {...register('degree', {
              required: 'This field is required',
            })}
            fullWidth
            placeholder="Enter degree pursuing..."
            variant="outlined"
            margin="dense"
            inputProps={{
              style: {
                height: '10px',
              },
            }}
            error={!!errors.degree}
            helperText={errors.degree?.message}
          />
        </Grid>
        <Grid sx={{ paddingBottom: '10px' }}>
          <label>Field of Study</label>
          <TextField
            {...register('specialisation', {
              required: 'This field is required',
            })}
            fullWidth
            placeholder="Enter your field of specialization..."
            variant="outlined"
            margin="dense"
            inputProps={{
              style: {
                height: '10px',
              },
            }}
            error={!!errors.specialisation}
            helperText={errors.specialisation?.message}
          />
        </Grid>
        <Grid sx={{ paddingBottom: '10px' }} container spacing={3}>
          <Grid item sm={6} xs={12}>
            <label>Start Date</label>
            <TextField
              {...register('start_date', {
                required: 'This field is required',
              })}
              fullWidth
              variant="outlined"
              margin="dense"
              type="date"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              error={!!errors.start_date}
              helperText={errors.start_date?.message}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label>
              End date <i>(Expected)</i>
            </label>
            <TextField
              {...register('end_date', {
                required: 'This field is required',
              })}
              fullWidth
              variant="outlined"
              margin="dense"
              type="date"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              error={!!errors.end_date}
              helperText={errors.end_date?.message}
            />
          </Grid>
        </Grid>
        <Grid sx={{ paddingBottom: '10px' }} container spacing={3}>
          <Grid item sm={6} xs={12}>
            <label>Grade</label>
            <TextField
              {...register('grade', {
                required: 'This field is required',
              })}
              fullWidth
              placeholder="Enter overall/last semester grade..."
              variant="outlined"
              margin="dense"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              error={!!errors.grade}
              helperText={errors.grade?.message}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label>Roll Number</label>
            <TextField
              {...register('roll_no', {
                required: 'This field is required',
              })}
              fullWidth
              placeholder="Enter your unique roll number..."
              variant="outlined"
              margin="dense"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              error={!!errors.roll_no}
              helperText={errors.roll_no?.message}
            />
          </Grid>
        </Grid>
        <label>Student Verification</label>
        <Grid
          display={'flex'}
          alignItems={'center'}
          sx={{ paddingBottom: '10px' }}
          container
          spacing={3}
        >
          <Grid item sm={9} xs={12}>
            <TextField
              fullWidth
              placeholder="Upload College ID / Fee Receipt of Your College"
              variant="outlined"
              margin="dense"
              inputProps={{
                style: {
                  height: '10px',
                },
              }}
              value={docUrl && docUrl?.url}
              disabled
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Button
              variant="outlined"
              sx={{ fontSize: '10px' }}
              fullWidth
              component="label"
              type="button"
              disabled={isUploadingStudentDoc}
            >
              <input
                id="file-input"
                type="file"
                hidden
                ref={inputRef}
                onChange={onChangeFunction}
              />
              <FileUploadIcon /> Browse Files
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          sm={5}
          xs={12}
          display={'flex'}
          justifyContent={'flex-start'}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit(onSubmitHandler)}
            disabled={isUploadingStudentDoc}
          >
            Submit
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCollegeDialog;
