import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Divider, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff,
} from '@mui/icons-material/';
import MessageBar from '../MessageBar';
import helpers, { getHostAlias } from '../../lib/helpers';
import Loader from '../Loader';
import Google from './img/google.png';
import Config from './../../config';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../../constants';

import { withRouter } from '../../routes';
import { useState } from 'react';
import { useEffect } from 'react';
import { StyledLogin } from './StyledComponent';

const Login = (props) => {
  const referralCode = helpers.getURLParameterByName('ref');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [loginStatus, setLoginStatus] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const hostUrl = window.location.host;

  useEffect(() => {
    const token = helpers.getURLParameterByName('token');
    const redirectPath = helpers.getURLParameterByName('redirectTo');
    const resumeReviewSearchTermInJobs = helpers.getURLParameterByName(
      'searchTerm',
    );

    if (token) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
    }
    if (redirectPath) {
      localStorage.setItem('projq__redirect_path', redirectPath);
    }
    if (resumeReviewSearchTermInJobs) {
      localStorage.setItem('ai_search_term', resumeReviewSearchTermInJobs);
    }
  }, []);

  useEffect(() => {
    if (props.auth?.loginSuccess) {
      const savedRedirect = localStorage.getItem('projq__redirect_path');
      if (savedRedirect?.startsWith('/public-question')) {
        const selectedCategory = helpers.getURLParameterByName(
          'selectedCategory',
          savedRedirect,
        );
        const selectedSubCategory = helpers.getURLParameterByName(
          'selectedSubCategory',
          savedRedirect,
        );
        const selectedQuestion = helpers.getURLParameterByName(
          'selectedQuestion',
          savedRedirect,
        );
        const selectedQuestionDomain = helpers.getURLParameterByName(
          'selectedQuestionDomain',
          savedRedirect,
        );
        if (
          selectedCategory &&
          selectedQuestion &&
          selectedSubCategory &&
          selectedQuestionDomain
        ) {
          localStorage.removeItem('projq__redirect_path');
          navigate('/l/practise', {
            state: {
              selectedCategory,
              selectedQuestion,
              selectedSubCategory,
              selectedQuestionDomain,
            },
          });
        }
      } else {
        let from = { pathname: '/l' };

        if (savedRedirect) {
          from.pathname = savedRedirect;
        } else if (location.state && location.state.from) {
          from = location.state.from;
        }
        navigate(from);
      }
    }
  }, [props.auth?.loginSuccess]);

  useEffect(() => {
    if (props.auth?.loginFail) {
      if (props.auth?.loginFailStatus.error === 'user_inactive') {
        setIsStatusMessageVisible(true);
        setLoginStatus('error');
        setLoginMessage(
          'Unable to login, Please activate your account by using the link already sent via email.',
        );
        setIsLoading(false);
      } else {
        setIsStatusMessageVisible(true);
        setLoginStatus('error');
        setLoginMessage('Unable to login');
        setIsLoading(false);
      }
    }
  }, [props.auth?.loginFail]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleGoogleClick = () => {
    const { location } = props;
    if (location.state && location.state.from && location.state.from.pathname) {
      localStorage.setItem(
        'projq__redirect_path',
        location.state.from.pathname,
      );
    }

    if (location.state?.background) {
      window.location.href = `${
        Config.API_URL
      }/google_signup?host=${getHostAlias(
        hostUrl,
      )}&referral_hash=${referralCode}&redirectTo=${
        location.state.background.pathname
      }`;
    } else {
      window.location.href = `${
        Config.API_URL
      }/google_signup?host=${getHostAlias(
        hostUrl,
      )}&referral_hash=${referralCode}`;
    }
  };

  const validate = (credentials) => {
    const errors = {};

    if (!credentials.password) {
      errors.password = 'Please enter password';
    }

    if (!credentials.email) {
      errors.email = 'Please enter email';
    } else if (
      !credentials.email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)
    ) {
      errors.email = 'Please enter a valid email';
    }

    return errors;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { location } = props;
    if (location?.state?.from?.pathname)
      localStorage.setItem(
        'projq__redirect_path',
        location.state.from.pathname,
      );

    const credentials = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    const errors = validate(credentials);

    if (!Object.keys(errors).length) {
      setIsLoading(true);
      props.postLogin(credentials);
    } else {
      // Save validation errors in state
      setErrors(errors);
    }
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const { location } = props;

  return (
    <StyledLogin className="login">
      <div className="login-heading">Welcome Back</div>
      <Typography color="primary">
        To login, please enter your credentials.
      </Typography>
      <Divider
        style={{ background: 'white', height: '3px', marginTop: '10px' }}
      />
      <div className="login-form">
        <p className="errors">{errors.length ? 'Unable to login' : ''}</p>
        <form onSubmit={onSubmit}>
          <div className="text-field">
            <label>Email Address</label>
            <TextField
              name="email"
              variant="outlined"
              type="email"
              value={email}
              onChange={handleChange}
              margin="dense"
              className="text-input"
              placeholder="Enter your email address"
            />
            <br />
          </div>
          <div className="text-field">
            <label>Password</label>
            <TextField
              type={isPasswordVisible ? 'text' : 'password'}
              variant="outlined"
              name="password"
              onChange={handleChangePassword}
              value={password}
              margin="dense"
              className="text-input"
              placeholder="Enter your password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visiility"
                      onClick={togglePassword}
                      size="large"
                    >
                      {isPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />
          </div>
          <div className="login-extras">
            <NavLink
              to={{
                pathname: `/password/reset`,
                search: location.search,
              }}
              state={{
                background:
                  location && location.state && location.state.background,
                from: location && location.state && location.state.from,
              }}
            >
              Forgot your password?
            </NavLink>
          </div>
          <Button
            variant="contained"
            className="btn-primary"
            fullWidth={true}
            type="submit"
            disabled={isLoading}
            color="primary"
          >
            {isLoading ? (
              <Loader size={20} color="inherit" shouldShowLoadingText={false} />
            ) : (
              'Login'
            )}
          </Button>
        </form>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="subtitle1" color="primary">
          OR
        </Typography>
      </div>
      <div className="google-btn-conatiner">
        <Button
          variant="outlined"
          className="google-login-button"
          fullWidth={true}
          type="button"
          onClick={handleGoogleClick}
        >
          <img alt="google_icon" src={Google} height={20} />
          Login with Google
        </Button>
      </div>
      <Divider
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          background: 'white',
          height: '3px',
        }}
      />
      <div className="login-extras center">
        Need an account?&nbsp;
        <NavLink
          to={{
            pathname: `/signup`,
            search: location.search,
          }}
          state={{
            background: location && location.state && location.state.background,
            from: location && location.state && location.state.from,
          }}
        >
          Register
        </NavLink>
      </div>
      <MessageBar
        type={loginStatus}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={loginMessage}
      />
    </StyledLogin>
  );
};

Login.defaultProps = {
  postLogin: () => {},
};

Login.propTypes = {
  postLogin: PropTypes.func,
};

export default withRouter(Login);
