import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GetMoreTime({
  visible = false,
  onConfirm,
  onClose,
  credits,
}) {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="confirm-category-unlock"
      aria-describedby="Unlock category"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want to get more time?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will consume {credits} credits and you will get additional
          30 seconds. Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
