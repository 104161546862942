import {
  Divider,
  Grid,
  Hidden,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import React, { useState } from 'react';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LockIcon from '@mui/icons-material/Lock';
import classNames from 'classnames';
import './QuestionCard.scss';
import { NavLink } from 'react-router-dom';
import ConsumeCreditModal from '../ConsumeCreditModal';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  ButtonContainerMultipart,
  QuestionCardHeader,
  QuestionCardSolution,
} from './QuestionCardStyledComponent';
import { ParentContainer } from '../PlayAnswer/PlayAnswerStyledComponent';

function Header({ index, title, attempt }) {
  return (
    <QuestionCardHeader>
      <NavLink to={`/l/user/questions/${attempt.id}`}>
        <Typography
          variant="subtitle1"
          style={{ lineHeight: '1.5' }}
          fontSize={{
            xs: '14px',
            sm: '16px',
          }}
        >
          Question {index}
          <Icon style={{ fontSize: 14, color: '#3572b0' }}>open_in_new</Icon>
        </Typography>
      </NavLink>
      <div className={`answer-status--badge ${title}`}>{title}</div>
      <Hidden smUp>
        <div className="extra">
          <Typography
            variant="caption"
            component="p"
            fontSize={{
              xs: '10px',
              sm: '12px',
            }}
          >
            Score:{' '}
            <span className={title}>
              {attempt.attempt_score} /{attempt.max_question_score}
            </span>
          </Typography>
          <Typography variant="caption" component="p">
            <QueryBuilderIcon fontSize="inherit" />
            {attempt.seconds_taken} sec
          </Typography>
        </div>
      </Hidden>
    </QuestionCardHeader>
  );
}

function QuestionTitle({ questionText }) {
  return (
    <Typography
      variant="subtitle1"
      color="textPrimary"
      gutterBottom
      style={{ marginBottom: 30 }}
    >
      {questionText
        ? ReactHtmlParser(questionText)
        : 'No question body available'}
    </Typography>
  );
}

function QuestionAnswer({ question, attempt }) {
  if (['MultipleChoice', 'MultipleCorrect'].includes(question.question_type))
    return (
      <ParentContainer>
        {question.options
          .sort((a, b) => a.option_serial_no - b.option_serial_no)
          .map((option, index) => {
            let optionLabel;
            let optionClass;

            if (
              !attempt?.is_attempt_correct &&
              attempt?.attempted_answer_option_no?.includes(
                option.option_serial_no,
              )
            ) {
              optionLabel = <Icon style={{ fontSize: 18 }}>clear</Icon>;
              optionClass = 'inactive';
            } else {
              optionLabel = option.option_serial_no;
            }

            if (
              question?.answer_option_no?.includes(option?.option_serial_no)
            ) {
              optionLabel = <Icon style={{ fontSize: 18 }}>check</Icon>;
              optionClass = 'active';
            }

            return (
              <div className={classNames('option', optionClass)} key={index}>
                <div className="option-label">{optionLabel}</div>
                <div className="option-text">
                  {ReactHtmlParser(option.option_text)}
                </div>
              </div>
            );
          })}
      </ParentContainer>
    );

  return (
    <div>
      <div className="option active">
        <div className="option-label">
          <Icon style={{ fontSize: 18 }}>check</Icon>
        </div>
        <div className="option-text">
          <Typography variant="body2" gutterBottom>
            {question.answer_one_word}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function QuestionSolution({ question, attempt, onUnlockSolution }) {
  if (!question.is_solution_available) return null;
  if (attempt.is_solution_unlocked && !question.solution_text) return null;

  const renderHeader = (locked = true) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="subtitle1">Solution</Typography>
      {locked && (
        <IconButton onClick={onUnlockSolution} size="large">
          <LockIcon />
        </IconButton>
      )}
    </div>
  );

  if (attempt.is_solution_unlocked)
    return (
      <QuestionCardSolution>
        {renderHeader(false)}
        <Typography variant="subtitle1">
          {ReactHtmlParser(question.solution_text)}
        </Typography>
      </QuestionCardSolution>
    );

  return <div className="questioncard__solution">{renderHeader()}</div>;
}

function QuestionDetails({ question, attempt }) {
  return (
    <Hidden smDown>
      <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item lg={4} xs={12}>
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Created by:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {`${question.user_data.first_name} ${question.user_data.last_name}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Score Earned</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {attempt.attempt_score}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Maximum Score</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {attempt.max_question_score}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">Time Taken</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {attempt.seconds_taken} sec
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );
}

function QuestionCard({ question, attempt, index, unlockSolution }) {
  const [
    isUnlockSolutionModalVisible,
    setIsUnlockSolutionModalVisible,
  ] = useState(false);

  const [multipartValue, setMultipartValue] = useState(
    attempt.sub_questions ? 0 : null,
  );

  let answerStatusText;
  if (attempt.is_attempt_correct) answerStatusText = 'Correct';
  else if (attempt.timeout) answerStatusText = 'Timeout';
  else if (attempt.question_skipped) answerStatusText = 'Skipped';
  else answerStatusText = 'Incorrect';
  const { costData } = useSelector((state) => state.credits);
  return (
    <Grid container spacing={2} wrap="nowrap" style={{ marginTop: '2em' }}>
      <Grid item xs={12} lg={8} style={{ flexGrow: 1 }}>
        <Header
          index={index}
          title={answerStatusText}
          attempt={
            attempt.sub_questions
              ? attempt.sub_questions[multipartValue]
              : attempt
          }
          multipartValue={multipartValue}
        />

        <div className="question-details-text">
          <QuestionTitle questionText={question.question_text} />
          {!attempt.sub_questions && (
            <>
              <QuestionAnswer question={question} attempt={attempt} />
              <QuestionSolution
                question={question}
                attempt={attempt}
                onUnlockSolution={() => setIsUnlockSolutionModalVisible(true)}
              />
            </>
          )}
          {attempt.sub_questions && (
            <>
              <QuestionTitle
                questionText={
                  attempt.sub_questions[multipartValue].question.question_text
                }
              />
              <QuestionAnswer
                question={attempt.sub_questions[multipartValue].question}
                attempt={attempt}
              />
              <QuestionSolution
                question={attempt.sub_questions[multipartValue].question}
                attempt={attempt}
                onUnlockSolution={() => setIsUnlockSolutionModalVisible(true)}
              />
            </>
          )}
          {attempt.sub_questions && (
            <ButtonContainerMultipart>
              <Button
                variant="contained"
                color="primary"
                disabled={0 === multipartValue}
                onClick={() =>
                  0 < multipartValue && setMultipartValue(multipartValue - 1)
                }
              >
                Prev
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={attempt.sub_questions.length - 1 === multipartValue}
                onClick={() =>
                  attempt.sub_questions.length - 1 > multipartValue &&
                  setMultipartValue(multipartValue + 1)
                }
              >
                Next
              </Button>
            </ButtonContainerMultipart>
          )}
        </div>
      </Grid>
      <QuestionDetails question={question} attempt={attempt} />

      {/* Unlock solution Modal */}
      <ConsumeCreditModal
        open={isUnlockSolutionModalVisible}
        onClose={() => setIsUnlockSolutionModalVisible(false)}
        title="Unlock Solution"
        credits={costData?.BuySolution}
        message="Ready to unlock the solution?"
        onYes={() => {
          unlockSolution(question.id);
          setIsUnlockSolutionModalVisible(false);
        }}
      />
    </Grid>
  );
}

export default QuestionCard;
