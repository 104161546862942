import React, { useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Close from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { createShippingAddress } from '../../actions/userStore';

import { countries } from '../../constants/countries';

import {
  Title,
  ShippingDetailsLabel,
  LabelContainer,
} from './StoreStyledComponent';

const ShippingDetailsDialog = ({ openDialog, closeDialog, address }) => {
  const dispatch = useDispatch();
  const [isSame, setIsSame] = useState(true);
  // const styles = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const handleShippingDetails = (data) => {
    const items = {};
    const shipping_address = {};
    const billing_address = {};
    shipping_address.name = data.name;
    shipping_address.address = data.address;
    shipping_address.pincode = data.pincode;
    shipping_address.state = data.state;
    shipping_address.country = data.country;
    shipping_address.phone_no = data.phone_no;
    shipping_address.email = data.email;
    billing_address.name = data.name;
    {
      isSame
        ? (billing_address.address = data.address)
        : (billing_address.address = data.bill_address);
    }
    {
      isSame
        ? (billing_address.pincode = data.pincode)
        : (billing_address.pincode = data.bill_pincode);
    }
    {
      isSame
        ? (billing_address.state = data.state)
        : (billing_address.state = data.bill_state);
    }
    {
      isSame
        ? (billing_address.country = data.country)
        : (billing_address.country = data.bill_country);
    }
    billing_address.phone_no = data.phone_no;
    billing_address.email = data.email;

    items.shipping = shipping_address;
    items.billing = billing_address;
    try {
      dispatch(createShippingAddress(items));
    } catch (err) {
      alert(err);
    }
    closeDialog();
  };
  useEffect(() => {
    if (address) {
      reset({
        name: address.billing?.name,
        address: address.shipping?.address,
        pincode: address.shipping?.pincode,
        state: address.shipping?.state,
        country: address.shipping?.country,
        bill_address: address.billing?.address,
        bill_pincode: address.billing?.pincode,
        bill_state: address.billing?.state,
        bill_country: address.billing?.country,
        phone_no: address.billing?.phone_no,
        email: address.billing?.email,
      });
    }
    if (address.shipping?.address === address.billing?.address) {
      setIsSame(true);
    } else setIsSame(false);
  }, [address]);

  return (
    <Dialog
      // className="dialog"
      onClose={closeDialog}
      aria-labelledby="simple-dialog-title"
      open={openDialog}
    >
      <Grid container>
        <Grid item sm={9} xs={7}>
          <Title style={{ paddingBottom: '0px' }}>Shipping Details</Title>
        </Grid>
        <Grid item sm={3} xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={closeDialog} size="large">
              <Close />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <DialogContent style={{ paddingTop: '0px' }}>
          <Grid container>
            <Grid item sm={3} xs={12}>
              <ShippingDetailsLabel>Name</ShippingDetailsLabel>
            </Grid>
            <Grid item sm={9} xs={12}>
              <TextField
                {...register('name', {
                  required: 'Please enter the name',
                  validate: (value) => !!value.trim() || 'Invalid name',
                })}
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={3} xs={12}>
              <ShippingDetailsLabel>Shipping Address</ShippingDetailsLabel>
            </Grid>
            <Grid item sm={9} xs={12}>
              <TextField
                {...register('address', {
                  required: 'Please enter the address',
                  validate: (value) => !!value.trim() || 'Invalid address',
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                rows="3"
                multiline
                placeholder="Address"
                error={!!errors.address}
                helperText={errors.address?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={3} xs={12}>
              <ShippingDetailsLabel>Pincode</ShippingDetailsLabel>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                {...register('pincode', {
                  required: 'Please enter the pincode',
                  maxLength: { value: 6, message: 'Max length exceeded' },
                  validate: (value) => !!value.trim() || 'Invalid pincode',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter number only',
                  },
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Pincode"
                type="tel"
                error={!!errors.pincode}
                helperText={errors.pincode?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>{' '}
            <LabelContainer item sm={2} xs={12}>
              <ShippingDetailsLabel style={{ paddingLeft: '5px' }}>
                State
              </ShippingDetailsLabel>
            </LabelContainer>
            <Grid item sm={4} xs={12}>
              <TextField
                {...register('state', {
                  required: 'Please enter the state',
                  validate: (value) => !!value.trim() || 'Invalid state',
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="State"
                error={!!errors.state}
                helperText={errors.state?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={3} xs={12}>
              <ShippingDetailsLabel>Country</ShippingDetailsLabel>
            </Grid>
            <Grid item sm={9} xs={12}>
              <FormControl fullWidth>
                <Select
                  {...register('country', {
                    required: 'Please select a country',
                  })}
                  style={{ marginTop: '8px', marginBottom: '4px' }}
                  variant="outlined"
                  margin="dense"
                  displayEmpty
                  placeholder="Country"
                  error={!!errors.country}
                  defaultValue={address ? address.shipping?.country : ''}
                >
                  <MenuItem value={undefined}>Country</MenuItem>
                  {countries.map((country) => (
                    <MenuItem value={country.name} key={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={9} xs={12}>
              <FormControlLabel
                label="Billing address is same as shipping address"
                labelPlacement="end"
                control={
                  <Checkbox
                    color="primary"
                    checked={isSame}
                    onChange={(e) => {
                      setIsSame(e.target.checked);
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          {!isSame ? (
            <>
              <Grid container>
                <Grid item sm={3} xs={12}>
                  <ShippingDetailsLabel>Billing Address</ShippingDetailsLabel>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <TextField
                    {...register('bill_address', {
                      required: 'Please enter the address',
                      validate: (value) => !!value.trim() || 'Invalid address',
                    })}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    rows="3"
                    multiline
                    placeholder="Billing Address"
                    error={!!errors.bill_address}
                    helperText={errors.bill_address?.message}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3} xs={12}>
                  <ShippingDetailsLabel>Pincode</ShippingDetailsLabel>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    {...register('bill_pincode', {
                      required: 'Please enter the pincode',
                      validate: (value) => !!value.trim() || 'Invalid pincode',
                      maxLength: { value: 6, message: 'Max length exceeded' },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Please enter number only',
                      },
                    })}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Pincode"
                    type="tel"
                    error={!!errors.bill_pincode}
                    helperText={errors.bill_pincode?.message}
                    FormHelperTextProps={{
                      sx: {
                        lineHeight: '14px',
                      },
                    }}
                  />
                </Grid>{' '}
                <LabelContainer item sm={2} xs={12}>
                  <ShippingDetailsLabel style={{ paddingLeft: '5px' }}>
                    State
                  </ShippingDetailsLabel>
                </LabelContainer>
                <Grid item sm={4} xs={12}>
                  <TextField
                    {...register('bill_state', {
                      required: 'Please enter the state',
                      validate: (value) => !!value.trim() || 'Invalid state',
                    })}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="State"
                    error={!!errors.bill_state}
                    helperText={errors.bill_state?.message}
                    FormHelperTextProps={{
                      sx: {
                        lineHeight: '14px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3} xs={12}>
                  <ShippingDetailsLabel>Country</ShippingDetailsLabel>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <FormControl fullWidth>
                    <Select
                      {...register('bill_country', {
                        required: 'Please select a country',
                      })}
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Country"
                      error={!!errors.country}
                      defaultValue={address ? address.billing?.country : ''}
                    >
                      <MenuItem value={undefined}>Country</MenuItem>
                      {countries.map((country) => (
                        <MenuItem value={country.name} key={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )}

          <Grid container>
            <Grid item sm={3} xs={12}>
              <ShippingDetailsLabel>Phone</ShippingDetailsLabel>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                {...register('phone_no', {
                  required: 'Please enter the phone number',
                  maxLength: { value: 15, message: 'Max length exceeded' },
                  minLength: { value: 10, message: 'Min should be 10 chars' },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter number only',
                  },
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Phone no."
                type="tel"
                error={!!errors.phone_no}
                helperText={errors.phone_no?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>
            <LabelContainer item sm={2} xs={12}>
              <ShippingDetailsLabel style={{ paddingLeft: '5px' }}>
                Email
              </ShippingDetailsLabel>
            </LabelContainer>
            <Grid item sm={4} xs={12}>
              <TextField
                {...register('email', {
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: 'Invalid email',
                  },
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Email"
                type="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                FormHelperTextProps={{
                  sx: {
                    lineHeight: '14px',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" type="button" onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                variant="text"
                type="button"
                color="primary"
                onClick={handleSubmit(handleShippingDetails)}
              >
                Proceed
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingDetailsDialog;
