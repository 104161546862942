const errorMessageMapping = {
  competition_already_finished:
    'This competition is finished. Please check the leaderboard for the results',
  no_more_attempts_remaining:
    'All the questions in this competition are finished. Keep an eye on the leaderboard for the results.',
  no_more_questions_left_in_competition_categories:
    'No more unattempted questions left in the categories of this competition.',
  competition_not_started: 'This competition is not started yet.',
  user_not_registered: 'You are not registered for this competition.',
};

export const getErrorText = (error) => {
  const msg = errorMessageMapping[error];
  if (msg) return msg;

  return 'Something Went Wrong! Please try again later.';
};
