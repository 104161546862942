import TimeLimit from '../components/MockTest/images/TimeLimit.svg';
import NoExtHelp from '../components/MockTest/images/NoExtHelp.svg';
import PauseAndResume from '../components/MockTest/images/PauseAndResume.svg';
import MultipleAttempts from '../components/MockTest/images/MultipleAttempts.svg';
import completeQuestions from '../components/MockTest/images/completeQuestions.svg';
import Remainder from '../components/MockTest/images/Remainder.svg';

export const carouselCardData1 = [
  {
    id: 1,
    title: 'Time Limit',
    description:
      'Complete the mock test within the specified time limit. The timer will be running during the test, so manage your time effectively.',
    image: TimeLimit,
  },
  {
    id: 2,
    title: 'No External Help',
    description:
      'Attempt the test individually without any external assistance. This ensures a fair evaluation of your skills and knowledge.',
    image: NoExtHelp,
  },
  {
    id: 3,
    title: 'Pausing and Resuming',
    description:
      'You have the flexibility to pause the test at any point and resume it later. This allows you to take breaks or attend to any urgent matters without losing your progress.',
    image: PauseAndResume,
  },
];

export const carouselCardData2 = [
  {
    id: 1,
    title: 'Multiple Attempts',
    description:
      'Feel free to attempt the mock test multiple times. Each attempt provides an opportunity to improve your score, identify areas of weakness, and enhance your overall performance.',
    image: MultipleAttempts,
  },
  {
    id: 2,
    title: 'Complete all Questions',
    description:
      'Answer all the questions within the given time. Leaving questions unanswered may impact your final score and ranking.',
    image: completeQuestions,
  },
  {
    id: 3,
    title: 'Submission Reminder',
    description:
      'Submit your mock test by clicking "Submit" after completion to officially submit your answers. Double-check your responses to ensure all questions are answered. Failure to submit may void your test.',
    image: Remainder,
  },
];

export const carouselCardDataMobile = [
  {
    id: 1,
    title: 'Time Limit',
    description:
      'Complete the mock test within the specified time limit. The timer will be running during the test, so manage your time effectively.',
    image: TimeLimit,
  },
  {
    id: 2,
    title: 'No External Help',
    description:
      'Attempt the test individually without any external assistance. This ensures a fair evaluation of your skills and knowledge.',
    image: NoExtHelp,
  },
  {
    id: 3,
    title: 'Pausing and Resuming',
    description:
      'You have the flexibility to pause the test at any point and resume it later. This allows you to take breaks or attend to any urgent matters without losing your progress.',
    image: PauseAndResume,
  },
  {
    id: 4,
    title: 'Multiple Attempts',
    description:
      'Feel free to attempt the mock test multiple times. Each attempt provides an opportunity to improve your score, identify areas of weakness, and enhance your overall performance.',
    image: MultipleAttempts,
  },
  {
    id: 5,
    title: 'Complete all Questions',
    description:
      'Answer all the questions within the given time. Leaving questions unanswered may impact your final score and ranking.',
    image: completeQuestions,
  },
  {
    id: 6,
    title: 'Submission Reminder',
    description:
      'Submit your mock test by clicking "Submit" after completion to officially submit your answers. Double-check your responses to ensure all questions are answered. Failure to submit may void your test.',
    image: Remainder,
  },
];
