import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Badge, Header, StyledPracticeCard } from './DashboardStyledComponent';
import practice from './images/practice.svg';
import { unlockCategory } from '../../actions/category';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import lock from './images/lock.png';
import start from './images/start.png';
import clock from './images/clock.png';
import UnlockDialog from '../Dialog/UnlockDialog';
const PracticeCard = ({
  level,
  title,
  subTopic,
  daysLeft,
  progress,
  backGround,
  unlockedUntil,
  btnBackGround,
  tagId,
  item,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState({});
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    dispatch(unlockCategory(id));
    localStorage.setItem('path', '/dashboard');
    setOpen(false);
  };

  const handleUnlock = (tagId, item) => {
    setId(tagId);
    setSelectedCategory(item);
    setOpen(true);
  };

  const handleStartQuiz = (item) => {
    const updatedItem = { ...item, ['id']: item['tag_id'] };
    if (item?.unlocked_until) {
      localStorage.setItem('dashboardPractice', JSON.stringify(updatedItem));
      navigate('/l/practise');
    }
  };

  return (
    <>
      <StyledPracticeCard
        style={{ backgroundColor: backGround, height: '240px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <div
              className=""
              style={{
                padding: '5px',
                backgroundColor: 'white',
                borderRadius: '100%',
              }}
            >
              <img src={practice} style={{ width: '25px', height: '23px' }} />
            </div>
          </Box>
          <Box>
            <Typography sx={{ textAlign: 'right' }}>
              <Badge style={{ color: '#25507B' }}>Level {level}</Badge>
            </Typography>
            {unlockedUntil && (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  fontSize: '12px',
                  fontWeight: '300',
                }}
              >
                <img src={clock} style={{ width: '10px', height: '12px' }} />
                <span>{daysLeft} Days Left</span>
              </Typography>
            )}
          </Box>
        </div>
        <div style={{ color: 'white' }}>
          <Header
            style={{
              color: 'white',
              margin: '10px 0',
              padding: '0px',
              height: '40px',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {title}
          </Header>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#FFFFFFCC',
                  fontWeight: '300',
                }}
              >
                {subTopic} Subtopics
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#FFFFFFCC',
                  fontWeight: '300',
                }}
              >
                {unlockedUntil ? progress + '%' : 'Start Practice'}
              </Typography>
            </div>
            {unlockedUntil ? (
              <>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{
                    marginTop: '10px',
                    padding: '5px',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                    width: '100%',
                  }}
                >
                  <button
                    onClick={() => handleStartQuiz(item)}
                    style={{
                      color: 'white',
                      backgroundColor: btnBackGround,
                      opacity: '0.8',
                      border: 'none',
                      padding: '10px',
                      fontSize: '14px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: '500',
                      width: '100%',
                      marginTop: '2px',
                    }}
                  >
                    <img src={start} width={14} />
                    Start Quiz
                  </button>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                <button
                  onClick={() => handleUnlock(tagId, item)}
                  style={{
                    color: 'white',
                    backgroundColor: btnBackGround,
                    opacity: '0.8',
                    border: 'none',
                    padding: '10px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                    fontWeight: '500',
                  }}
                >
                  <img src={lock} width={14} />
                  Unlock Now
                </button>
              </div>
            )}
          </div>
        </div>
      </StyledPracticeCard>
      <UnlockDialog
        visible={open}
        category={selectedCategory}
        dialogTitleMessage={'Do you want to unlock this category?'}
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default PracticeCard;
