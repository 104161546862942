import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCommentsForQuestionState,
  getCommentsForQuestion,
} from '../../actions/discussion';
import { CircularProgress } from '@mui/material';
import NewCommentInput from './NewCommentInput';
import CommentCard from './CommentCard';
import NoCommentsImage from '../../images/EmptyComments.svg';
import LoadCommentsIcon from '../../images/LoadCommentsIcon.svg';
import DiscussionSnackBars from './DiscussionSnackBars';
import Button from '@mui/material/Button';
import { StyledParentComponent } from './DiscussionPaneStyledComponent';

const DiscussionPane = ({ questionId, viewLoadState, isDisabled = false }) => {
  const [loadComments, setLoadComments] = useState(() => !viewLoadState);

  const dispatch = useDispatch();
  useEffect(() => {
    if (loadComments) {
      dispatch(getCommentsForQuestion(questionId));
    }
    return () => {
      dispatch(clearCommentsForQuestionState());
    };
  }, [dispatch, questionId, loadComments]);

  const commentProcessor = useCallback(
    (comment) => ({
      commentId: comment.id,
      username: comment.user_details.username,
      picture: comment.user_details.picture,
      time: comment.created_at,
      commentText: comment.comment,
      topLevel: comment.comment_level === 1,
      status: comment.status,
      commentType: comment.comment_type,
      numberOfVotes: comment.upvote - comment.downvote,
      questionId: comment.question_id,
      parentCommentId: comment.parent_comment_id,
      isDownvoted: comment.is_downvoted,
      isUpvoted: comment.is_upvoted,
    }),
    [],
  );

  const flattenTree = useCallback(
    (data) =>
      data?.map((datum) => {
        const processedDatum = commentProcessor(datum);
        if (!datum.sub_comments) {
          return { ...processedDatum, numberOfReplies: 0 };
        }

        const queue = datum.sub_comments;
        const flatChildren = [];

        while (queue.length > 0) {
          const currentNode = queue.shift();
          currentNode.sub_comments && queue.push(...currentNode.sub_comments);
          const { ...pruned } = currentNode;
          flatChildren.push(commentProcessor(pruned));
        }
        return {
          ...processedDatum,
          subComments: flatChildren,
          numberOfReplies: flatChildren.length,
        };
      }),
    [commentProcessor],
  );

  const fetchingCommentsForAQuestion = useSelector(
    (state) => state.discussion.fetchingCommentsForAQuestion,
  );
  const rawComments = useSelector((state) => state.discussion.comments);

  const comments = useMemo(() => {
    const deepClone = window.structuredClone
      ? window.structuredClone(rawComments)
      : JSON.parse(JSON.stringify(rawComments));
    return flattenTree(deepClone);
  }, [rawComments, flattenTree]);

  if (!loadComments) {
    return (
      <div className="load-comments-wrapper">
        <Button
          startIcon={<img src={LoadCommentsIcon} alt="load-comments-icon" />}
          variant="contained"
          onClick={() => setLoadComments(true)}
          disabled={isDisabled}
        >
          Load Discussions
        </Button>
      </div>
    );
  }

  if (fetchingCommentsForAQuestion) {
    return (
      <div className="comments-loader">
        <CircularProgress />
        <span>Loading Comments</span>
      </div>
    );
  }

  return (
    <StyledParentComponent>
      <section className="discussion-pane-wrapper">
        <DiscussionSnackBars />
        <h2 className="discussion-heading">Discussion</h2>
        <NewCommentInput questionId={questionId} topLevelComment={true} />

        <article className="main-comment-wrapper">
          {comments?.length !== 0 ? (
            comments.map((comment) => (
              <CommentCard {...comment} key={comment.commentId} />
            ))
          ) : (
            <div className="no-comments-wrapper">
              <img src={NoCommentsImage} alt="No Comments" />
              <h2 className="no-comments-header">No Comments</h2>
              <p>Be the first to comment</p>
            </div>
          )}
        </article>
      </section>
    </StyledParentComponent>
  );
};

export default DiscussionPane;
