import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Button, Grid, LinearProgress } from '@mui/material';
import Badge from './images/Badge.svg';
import Users from './images/Users.svg';
import InfoIcon from './images/infoIcon.svg';
import { withStyles } from '@mui/styles';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 5,
    marginTop: 10,
  },
  colorPrimary: {
    backgroundColor: '#D9D9D9',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#3572B0',
  },
}))(LinearProgress);

const CollegeStats = ({
  styles,
  collegeStatsData,
  collegeRelatedData,
  milestoneData,
}) => {
  const data = collegeStatsData?.stats?.map((stats) => ({
    name: stats.date,
    avg_accuracy: stats.avg_accuracy.toFixed(2),
    total_attempted: stats.total_attempted,
  }));

  const progressPercent =
    (milestoneData?.total_students /
      milestoneData.current_milestone_details?.student_count) *
    100;
  return (
    <div className={styles.containerStyle}>
      <span className={styles.contentLeft_Heading1}>College Weekly Stats</span>
      <Grid container spacing={2}>
        <Grid item sm={9} xs={11} className={styles.stats_chart}>
          <ResponsiveContainer width="100%" height={'100%'}>
            <ComposedChart
              width={'100%'}
              height={'100%'}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 0,
                left: -30,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" scale="auto" />
              <YAxis />
              <Tooltip />
              <Legend iconType="plainline" />
              <Area
                type="monotone"
                dataKey="total_attempted"
                fill="#25507B"
                fillOpacity={1}
                stroke="#25507B"
                name="Total Questions Attempted"
              />
              <Line
                type="linear"
                name="Average Accuracy"
                dataKey="avg_accuracy"
                stroke="#FF90A1"
                strokeWidth={'5px'}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Grid
            container
            spacing={1}
            display={'flex'}
            justifyContent={'space-around'}
          >
            <Grid item md={12} sm={6} textAlign={'center'}>
              <span
                className={styles.contentLeft_Heading1}
                style={{ fontSize: '14px' }}
              >
                Enrolment Index &nbsp;
                <img src={InfoIcon} alt="" />
              </span>
              <br />
              <img
                width={'100px'}
                height={'100px'}
                src={
                  milestoneData?.current_milestone_details?.image_url || Badge
                }
                alt=""
                style={{ marginTop: '5px' }}
              />
              <Grid container display={'flex'} alignItems={'flex-start'}>
                <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                  <span className={styles.collegeStats__enrollIndex}>
                    {milestoneData?.total_students}
                  </span>
                </Grid>
                <Grid item xs={8}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={progressPercent}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span className={styles.collegeStats__enrollIndexLast}>
                    {milestoneData?.current_milestone_details?.student_count}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <hr />
            <Grid
              item
              md={12}
              sm={6}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <img src={Users} alt="" width={'50px'} height={'50px'} />
              <span style={{ color: '#25507B', fontSize: '14px' }}>
                Number of Users
              </span>
              <span className={styles.noOfStudents}>
                {collegeRelatedData.total_students || 500}
              </span>
              <Button
                sx={{ marginTop: '10px' }}
                variant="contained"
                className={styles.invitationBoxBtn}
              >
                Invite Students ▶
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CollegeStats;
