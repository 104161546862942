import { styled } from '@mui/styles';
import { Box, DialogContent, Grid, Typography } from '@mui/material';

export const MainContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: '5px',
  padding: '5px',
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '900',
  padding: '0 5px',
  color: theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

export const Badge = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  padding: '2px 10px',
  borderRadius: '10px',
  fontSize: '12px',
}));

export const GoalsBadge = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: '2px 10px',
  borderRadius: '10px',
  fontSize: '12px',
}));

export const StyledPracticeCardGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '0 10px',
  width: '100%',
  padding: '10px',
  overflow: 'auto',
  [theme.breakpoints.up('xl')]: {
    gap: '0 20px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(94vw - 20px)',
    gap: '0 10px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(94vw - 30px)',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 'calc(94vw - 15px)',
  },
}));

export const StyledPracticeCard = styled(Grid)(({ theme }) => ({
  backgroundColor: (props) => props.backgroundColor,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px',
  color: theme.palette.common.white,
  borderRadius: '10px',
  marginBottom: '20px',
  width: '100%',
  height: '225px',
  [theme.breakpoints.down('lg')]: {
    width: '220px',
  },
}));

export const StyledJustifyContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledGoalsCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderLeft: '4px solid gray',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
  padding: '8px',
  borderTop: '1px solid gray',
  transition: 'border-left-color 0.3s',
  '&:hover': {
    borderLeftColor: theme.palette.secondary.main,
  },
}));

export const StyledBadgesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  border: `${theme.palette.component.color}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  borderRadius: '10px',
  padding: '8px',
  margin: '10px 0',
}));

export const StyledBadgesCount = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.secondary.dark,
  borderRight: '1px solid gray',
  padding: '2px',
}));

export const StyledPiechartContainer = styled('div')({
  maxWidth: '300px',
  minWidth: '280px',
  width: '100%',
  height: '220px',
  margin: '0 auto',
});

export const StyledPiechartInfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  padding: '5px',
  gap: '5px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
}));

export const StyledCustomCard = styled(Typography)(({ theme }) => ({
  border: `${theme.palette.component.color}`,
  padding: '5px',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  borderRadius: '10px',
  maxWidth: '100%',
}));

export const BuyBadge = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: '3px 12px 3px 12px',
  borderRadius: '25px 0px 0px 25px',
  fontSize: '12px',
  position: 'absolute',
  bottom: '4',
  right: '0',
  marginTop: '-10px',
}));

export const StyleCreditsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '30px',
  gap: '2px',
  [theme.breakpoints.down('xs')]: {
    minWidth: 'calc(100vw - 30px)',
  },
}));

export const StyledMocktestContainer = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  padding: '8px',
  position: 'relative',
  marginBottom: '5px',
  border: `${theme.palette.component.color}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  width: '100%',
  height: '120px',
  maxHeight: '160px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'calc(97vw - 35px)',
    minWidth: 'calc(97vw - 40px)',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(95vw - 25px)',
    minWidth: 'calc(95vw - 20px)',
  },
  '@media screen and (max-width:370px)': {
    maxWidth: 'calc(100vw - 15px)',
    minWidth: 'calc(100vw - 20px)',
  },
}));

export const StyledMocktestMainContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  gap: '10px',
  padding: '5px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    maxWidth: 'calc(94vw - 40px)',
    minWidth: 'calc(94vw - 45px)',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(94vw - 35px)',
    minWidth: 'calc(94vw - 30px)',
  },
  '@media screen and (max-width:370px)': {
    maxWidth: 'calc(100vw - 15px)',
    minWidth: 'calc(100vw - 20px)',
  },
}));

export const StyledMocktestInfoContainer = styled('div')(({ theme }) => ({
  padding: '4px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '5px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledMonthlyScoreContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '8fr 4fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '12fr',
  },
}));

export const StyledScoreContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '4px',
  border: `1px solid ${theme.palette.secondary.dark}`,
  padding: '4px 6px',
  borderRadius: '30px',
}));

export const StyledCenterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledScoreInfos = styled('div')(({ theme }) => ({
  width: '20%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  margin: '15px 0',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    flexDirection: 'row-reverse',
    gap: '10px 40px',
  },
}));

export const StyledScoreChartContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    flexDirection: 'column',
    gap: '10px',
  },
}));

export const StyledScoreGraphContainer = styled('div')(({ theme }) => ({
  minWidth: '550px',
  width: '100%',
  height: '180px',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '550px',
    minWidth: '450px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '340px',
    minWidth: '300px',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: '310px',
    minWidth: '250px',
  },
}));

export const StyledCompetetionContainer = styled('div')(({ theme }) => ({
  padding: '5px',
  margin: '5px 0',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledCompetetionCard = styled('div')(({ theme }) => ({
  padding: '5px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  border: `${theme.palette.component.color}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

export const StyledDiscussionContainer = styled('div')(({ theme }) => ({
  padding: '5px',
  display: 'grid',
  gap: '20px',
  gridTemplateColumns: '8fr 4fr',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledActivityContainer = styled('div')({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  width: '100%',
  padding: '10px',
});

export const StyledDaysContainer = styled('div')(({ theme }) => ({
  fontSize: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    gap: '18px',
  },
}));

export const StyledDataContainer = styled('div')({
  width: '100%',
  padding: '5px',
});

export const StyledMonthsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '10px',
  padding: '0px 8px',
  [theme.breakpoints.up('lg')]: {
    width: '97%',
    margin: '0 auto',
  },
}));

export const StyledDailyTooltip = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.text,
  fontSize: 12,
  boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
  padding: '5px',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
}));

export const StyledCalenderDateContainer = styled('div')(({ theme }) => ({
  height: '130px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  gap: '2px',
  [theme.breakpoints.down('lg')]: {
    height: '150px',
  },
  [theme.breakpoints.down('md')]: {
    height: '130px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '135px',
  },
  '@media screen and (max-width:400px)': {
    height: '170px',
  },
}));

export const StyleCalenderDate = styled('div')(({ theme }) => ({
  width: '1.87%',
  height: '14px',
  fontSize: '12px',
  cursor: 'default',
  [theme.breakpoints.down('lg')]: {
    width: '2.1%',
  },
  [theme.breakpoints.down('md')]: {
    width: '2.2%',
    height: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '11px',
  },
  '@media screen and (max-width:400px)': {
    width: '3%',
    height: '10px',
  },
}));

export const StyledTopContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '10px',
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.background,
  boxShadow: `0px 0px 2px 0px ${theme.palette.component.shadowColor}`,
  background: `linear-gradient(14.88deg, rgba(118, 158, 186, 0.6) -177.98%, rgba(147, 178, 200, 0.6) -130.73%, rgba(186, 206, 218, 0.6) -61.23%, rgba(214, 225, 231, 0.6) 2.71%, rgba(231, 237, 240, 0.6) 58.3%, rgba(238, 242, 243, 0.6) 100%)`,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledStipperContainer = styled('div')(({ theme }) => ({
  borderRight: `2px solid ${theme.palette.component.shadowColor}`,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const StyledImgContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const StyledContainerLeft = styled('div')(({ theme }) => ({
  borderRight: `2px solid ${theme.palette.component.shadowColor}`,
  width: '65%',
  padding: '0 10px',
  [theme.breakpoints.down('lg')]: {
    width: '79%',
  },
}));

export const StyledContainerRight = styled('div')(({ theme }) => ({
  width: '34%',
  display: 'flex',
  gap: '5px',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const StyledLeaderBoardContainer = styled('main')(({ theme }) => ({
  padding: '0px 5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '20px',
    padding: '0px 5px',
  },
}));

export const PlaceholderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '130px',
  width: '100%',
});

export const StyledParentGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: '5px',
  gridTemplateColumns: '8fr 4fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledParentGridTwo = styled(Grid)(({ theme }) => ({
  marginTop: '5px',
  display: 'grid',
  gridTemplateColumns: '7fr 5fr',
  [theme.breakpoints.down('lg')]: {
    marginTop: '10px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '5px',
    gridTemplateColumns: '1fr',
  },
}));

export const StyledParentGridThree = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '7fr 5fr',
    gap: '5px',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledTipsContainer = styled('main')(({ theme }) => ({
  padding: '0 5px',
  margin: '5px 0',
  color: theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    margin: '5px 0 0 0',
  },
}));

export const StyleModalContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  padding: '5px',
  borderRadius: '10px',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  border: 'none',
}));

export const StyledActionModalTitle = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '20px',
  color: theme.palette.common.black,
  gap: '4px',
  justifyContent: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    gap: '2px',
  },
}));
export const StyledActionModalTitleImage = styled('img')(({ theme }) => ({
  width: '20px',
  height: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '12px',
    height: '12px',
  },
}));
export const StyledActionModalContentContainer = styled('div')(({ theme }) => ({
  minWidth: '500px',
  maxWidth: '800px',
  width: '100%',
  display: 'flex',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minWidth: '250px',
    maxWidth: '400px',
    width: '100%',
  },
}));
export const StyledModalWrapper = styled('div')(({ theme }) => ({
  minWidth: '800px',
  maxWidth: '1200px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0 10px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '280px',
    maxWidth: '560px',
    width: '100%',
  },
}));
export const StyledTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const StyledTitleRight = styled('h2')(({ theme }) => ({
  color: theme.palette.secondary.main,
  paddingRight: '3px',
  fontSize: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const StyledLeftContainer = styled('div')(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
export const StyledRightContainer = styled('div')(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
export const DashboardWrapper = styled('div')({
  maxWidth: '1280px',
  margin: '0 auto',
  padding: '5px',
});

export const AddDetailsBadge = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  border: `none`,
  padding: '2px 14px',
  borderRadius: '10px',
  fontSize: '10px',
  cursor: 'pointer',
  marginTop: '4px',
}));

export const CreditWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  gap: '2px',
});

export const StyleInputTitle = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: '0 0 10px 0',
  padding: '0',
}));

export const BadgeParentGrid = styled(Grid)({
  width: '75%',
  textAlign: 'center',
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-start',
});

export const BadgeCountBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BadgePopupContainerBox = styled(Box)(({ theme }) => ({
  width: '250px',
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  boxShadow: 1,
  borderRadius: '5px',
  padding: '2px',
  top: '100%',
  right: '0',
  zIndex: 2,
}));
