import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import {
  GoalsBadge,
  Header,
  StyledLeaderBoardContainer,
} from './DashboardStyledComponent';
import { Link } from 'react-router-dom';
import user from './images/user.jpg';

const LeaderBoard = ({ leaderboardData }) => {
  const userName =
    leaderboardData?.curr_user_score_details?.user?.username || 'user00';
  const userFName =
    leaderboardData?.curr_user_score_details?.user?.first_name || 'Mr.';
  const userLName =
    leaderboardData?.curr_user_score_details?.user?.last_name || 'User';
  const userImg =
    leaderboardData?.curr_user_score_details?.user?.picture || user;
  const name = userFName + ' ' + userLName;
  const userAccuracy =
    leaderboardData?.curr_user_score_details?.percent_accuracy || 0;
  const userRank = leaderboardData?.curr_user_score_details?.rank || 0;
  const userScore = leaderboardData?.curr_user_score_details?.score || 0;

  return (
    <StyledLeaderBoardContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '5px',
        }}
      >
        <Header style={{ fontSize: '1rem' }}>Leader Board</Header>
        <Link to="/l/leaderboard">
          <GoalsBadge
            style={{
              backgroundColor: '#25507B',
              cursor: 'pointer',
              padding: '2px 16px',
              color: 'white',
              fontSize: '10px',
              border: 'none',
            }}
          >
            View All
          </GoalsBadge>
        </Link>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{ borderTop: '1px solid #25507B1A' }}>
            <TableRow>
              <TableCell
                sx={{
                  color: '#76767FCC',
                  fontWeight: '400',
                  fontSize: '12px',
                }}
              >
                Rank{' '}
              </TableCell>
              <TableCell
                sx={{
                  color: '#76767FCC',
                  fontWeight: '400',
                  fontSize: '12px',
                }}
              >
                Name{' '}
              </TableCell>
              <TableCell
                sx={{
                  color: '#76767FCC',
                  fontWeight: '400',
                  fontSize: '12px',
                }}
                align="center"
              >
                Score
              </TableCell>
              <TableCell
                sx={{
                  color: '#76767FCC',
                  fontWeight: '400',
                  fontSize: '12px',
                }}
                align="center"
              >
                Accuracy
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboardData?.rank_list?.slice(0, 5).map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  0{row?.rank || '00'}
                </TableCell>
                <TableCell>
                  <div
                    className=""
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    <div className="">
                      <img
                        src={row?.user?.picture || user}
                        style={{
                          width: '37px',
                          height: '37px',
                          borderRadius: '100%',
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gapY: '2px',
                      }}
                    >
                      <span
                        style={{
                          color: '#25507B',
                          fontSize: '15px',
                          fontWeight: '700',
                        }}
                      >
                        {row?.user?.first_name || 'Mr.'}{' '}
                        {row?.user?.last_name || 'User'}
                      </span>
                      <span style={{ color: '#989898B2', fontSize: '10px' }}>
                        {row?.user?.username || 'user01'}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#25507B', fontWeight: 500 }}
                >
                  {row?.score}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#25507B', fontWeight: 500 }}
                >
                  {row?.percent_accuracy}%
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                width: '100%',
                backgroundColor: '#3572B01A',
                padding: '10px 5px',
              }}
            >
              <TableCell component="th" scope="row" colSpan={4}>
                Your Performance
              </TableCell>
            </TableRow>

            <TableRow style={{ fontSize: '12px' }}>
              <TableCell component="th" scope="row">
                {userRank}
              </TableCell>
              <TableCell>
                <div
                  className=""
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                >
                  <div className="">
                    <img
                      src={userImg}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '100%',
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gapY: '2px',
                    }}
                  >
                    <span
                      style={{
                        color: '#25507B',
                        fontSize: '15px',
                        fontWeight: '700',
                      }}
                    >
                      {name}
                    </span>
                    <span style={{ color: '#989898B2', fontSize: '10px' }}>
                      {userName}
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: '#25507B', fontWeight: 500 }}
              >
                {userScore}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: '#25507B', fontWeight: 500 }}
              >
                {userAccuracy}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledLeaderBoardContainer>
  );
};

export default LeaderBoard;
