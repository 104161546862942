import React, { useEffect, useState } from 'react';
import { getOrgReview, orgBulkStatusUpdate } from '../../actions/adminJobs';
import {
  Chip,
  Container,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  Checkbox,
  Button,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  SearchRounded as SearchIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import '../ReportBug/ReportedBugs/index.css';
import { FullScreenDialog } from './FullScreenDialog';
const AdminReviewOrgs = () => {
  const dispatch = useDispatch();
  const { reviewOrgData } = useSelector((state) => state.adminJobs);

  const [status, setStatus] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrderByTime, setSortOrderByTime] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedStatusToChange, setSelectedStatusToChange] = useState('');

  useEffect(() => {
    dispatch(
      getOrgReview(
        status,
        searchUser,
        page + 1,
        rowsPerPage,
        sortBy,
        sortOrderByTime,
      ),
    );
  }, [status, searchUser, page, rowsPerPage, sortBy, sortOrderByTime]);
  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id),
      );
    }
  };
  const handleBulkStatusChange = () => {
    const data = {
      status: selectedStatusToChange,
      org_ids: selectedIds,
    };
    dispatch(
      orgBulkStatusUpdate(data, sortBy, sortOrderByTime, page + 1, rowsPerPage),
    );
    setSelectedIds([]);
  };
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
        className="collegeListContainer"
      >
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <div className="scoreboard__filters__item">
              <Select
                options={[
                  { id: '', name: 'All' },
                  { id: 'Active', name: 'Active' },
                  { id: 'UnderReview', name: 'UnderReview' },
                  { id: 'Blocked', name: 'Blocked' },
                ]}
                onChange={(option) => setStatus(option.id)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={status ? status : 'Status'}
                value={status}
                isClearable
              />
            </div>
          </Grid>

          <Grid item md={6} xs={12} alignItems="flex-end">
            <div className="scoreboard__filters__item">
              <TextField
                fullWidth
                placeholder="Search..."
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon htmlColor="#25507B" />
                    </InputAdornment>
                  ),
                }}
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <>
          <div className="table-container">
            <Table
              style={{
                backgroundColor: 'white',
                maxWidth: '100%',
                overflow: 'auto',
              }}
            >
              <TableHead>
                {reviewOrgData?.org_list?.length === 0 ? (
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    No Records Found
                  </Typography>
                ) : (
                  <TableRow style={{ minWidth: '40rem' }}>
                    <TableCell></TableCell>
                    <TableCell>Org Name</TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Website Url
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      LinkedIn Url
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      Status
                    </TableCell>
                    <TableCell
                      style={{ padding: '4px', minWidth: '8rem' }}
                      onClick={() => {
                        setSortBy('date');
                        setSortOrderByTime(
                          sortOrderByTime === 'asc' ? 'desc' : 'asc',
                        );
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5 rem',
                        }}
                      >
                        Date
                        {sortOrderByTime === 'asc' ? (
                          <ArrowUpward style={{ height: '14px' }} />
                        ) : (
                          <ArrowDownward style={{ height: '14px' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>Check</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {reviewOrgData?.org_list?.map((org, idx) => (
                  <TableRow key={idx} className="table-row-bugs">
                    <TableCell>
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(e, org.id)}
                        checked={selectedIds.includes(org.id)}
                      />
                    </TableCell>
                    <TableCell
                      style={{ padding: '1rem 4px', paddingLeft: '10px' }}
                    >
                      {org.team_name}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {org.website_url ? org.website_url : 'NA'}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {org.linkedin_url ? org.linkedin_url : 'NA'}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      <Chip
                        variant="outlined"
                        color={
                          org.status === 'Active'
                            ? 'success'
                            : org.status === 'Pending'
                            ? 'primary'
                            : 'primary'
                        }
                        size="small"
                        label={org.status ? org.status : 'Pending'}
                        className="subtag"
                        sx={{
                          color: org.status === 'Blocked' && 'red',
                          border: org.status === 'Blocked' && '1px solid red',
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {org?.created_at.split('T')[0]}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                      <FullScreenDialog OrgData={org} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            style={{ width: '95%' }}
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            count={reviewOrgData?.total_pages * reviewOrgData?.page_no}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
            className="scoreboard__navigation"
          />
        </>
        {selectedIds.length > 0 && (
          <Grid
            container
            sx={{
              background: 'white',
              padding: '10px',
              width: '100%',
              position: 'sticky',
              bottom: '10px',
              border: '2px solid #25507B',
            }}
          >
            <Grid item sm={6} xs={12}>
              <Select
                options={[
                  { id: 'Active', name: 'Active' },
                  { id: 'UnderReview', name: 'UnderReview' },
                  { id: 'Blocked', name: 'Blocked' },
                ]}
                onChange={(option) => setSelectedStatusToChange(option.id)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={
                  selectedStatusToChange ? selectedStatusToChange : 'Status'
                }
                value={selectedStatusToChange}
                isClearable
                menuPlacement="auto"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleBulkStatusChange}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default AdminReviewOrgs;
