import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const userStore = createReducer(
  {
    userProductsList: [],
    shippingAddress: {},
    cartItems: [],
    isAddressSavedError: null,
    isAddressSavedSuccess: null,
    isSavingAddress: null,
    success: false,
  },
  {
    [types.GET_USER_PRODUCT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingProductsList: true,
      });

      return newState;
    },
    [types.GET_USER_PRODUCT_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        userProductsList: action.data.items,
        isFetchingProductsList: false,
      });

      return newState;
    },
    [types.GET_USER_PRODUCT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingProductsList: false,
      });

      return newState;
    },

    [types.GET_SHIPPING_ADDRESS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingShippingAddress: true,
      });

      return newState;
    },
    [types.GET_SHIPPING_ADDRESS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        shippingAddress: action.data,
        isFetchingShippingAddress: false,
      });

      return newState;
    },
    [types.GET_SHIPPING_ADDRESS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingShippingAddress: false,
      });

      return newState;
    },

    [types.POST_SHIPPING_ADDRESS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingAddress: true,
        isAddressSavedSuccess: false,
        isAddressSavedError: false,
      });

      return newState;
    },
    [types.POST_SHIPPING_ADDRESS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingAddress: false,
        isAddressSavedSuccess: true,
        isAddressSavedError: false,
      });

      return newState;
    },
    [types.POST_SHIPPING_ADDRESS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingAddress: false,
        isAddressSavedSuccess: false,
        isAddressSavedError: true,
      });

      return newState;
    },

    [types.POST_UPDATE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCart: true,
      });

      return newState;
    },
    [types.POST_UPDATE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCart: false,
      });

      return newState;
    },
    [types.POST_UPDATE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCart: false,
      });

      return newState;
    },

    [types.GET_CART_LIST_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCartList: true,
      });

      return newState;
    },
    [types.GET_CART_LIST_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        cartItems: action.data,
        isFetchingCartList: false,
        success: false,
      });

      return newState;
    },
    [types.GET_CART_LIST_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCartList: false,
      });

      return newState;
    },

    [types.POST_PAYMENT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isInitiatingPayment: true,
      });

      return newState;
    },
    [types.POST_PAYMENT_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isInitiatingPayment: false,
      });

      return newState;
    },
    [types.POST_PAYMENT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isInitiatingPayment: false,
      });

      return newState;
    },

    [types.CHECK_PAYMENT_STATUS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCheckingPaymentStatus: true,
      });

      return newState;
    },
    [types.CHECK_PAYMENT_STATUS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isCheckingPaymentStatus: false,
        success: action.data?.data.success,
      });

      return newState;
    },
    [types.CHECK_PAYMENT_STATUS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCheckingPaymentStatus: false,
      });

      return newState;
    },
    [types.CANCEL_PAYMENT_STATUS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCancellingPayment: true,
      });

      return newState;
    },
    [types.CANCEL_PAYMENT_STATUS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCancellingPayment: false,
      });

      return newState;
    },
    [types.CANCEL_PAYMENT_STATUS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCancellingPayment: false,
      });

      return newState;
    },
  },
);

export { userStore as default };
