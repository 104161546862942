import { Button } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledChoiceEdit = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .choice-label': {
    background: theme.palette.secondary.background,
    borderRadius: '4px',
    color: theme.palette.secondary.main,
    lineHeight: 1,
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    marginRight: '10px',
  },

  '& .choice-input': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    '& .delete': {
      color: theme.palette.component.color,
    },

    '& .text-input-field': {
      width: '400px',
      margin: 0,
      marginRight: '10px',

      '@media (max-width: 600px)': {
        width: 'auto',
        flex: 1,
      },
    },
  },
}));

export const StyledOptionButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px',
  textTransform: 'none',
  width: '100%',
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.playColors.lightSky,
}));
