import * as types from './types';
import apiClient from '../api/apiclient.ts';

function getUserCollegesStart() {
  return {
    type: types.GET_USER_COLLEGES_START,
  };
}

function getUserCollegesSuccess({ data }) {
  return {
    type: types.GET_USER_COLLEGES_SUCCESS,
    data: data,
  };
}

function getUserCollegesFail() {
  return {
    type: types.GET_USER_COLLEGES_FAIL,
  };
}

export function getUserColleges() {
  return (dispatch) => {
    dispatch(getUserCollegesStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetStudentColleges()
      .then((resp) => {
        dispatch(getUserCollegesSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserCollegesFail());
      });
  };
}

function getCollegeRelatedDetailsStart() {
  return {
    type: types.GET_COLLEGE_RELATED_DETAILS_START,
  };
}

function getCollegeRelatedDetailsSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_RELATED_DETAILS_SUCCESS,
    data: data,
  };
}

function getCollegeRelatedDetailsFail() {
  return {
    type: types.GET_COLLEGE_RELATED_DETAILS_FAIL,
  };
}

export function getCollegeRelatedDetails(id) {
  return (dispatch) => {
    dispatch(getCollegeRelatedDetailsStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetCollegeRelatedDetails(id)
      .then((resp) => {
        dispatch(getCollegeRelatedDetailsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCollegeRelatedDetailsFail());
      });
  };
}

function getCollegeLeaderboardStart() {
  return {
    type: types.GET_COLLEGE_LEADERBOARD_START,
  };
}

function getCollegeLeaderboardSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_LEADERBOARD_SUCCESS,
    data: data,
  };
}

function getCollegeLeaderboardFail() {
  return {
    type: types.GET_COLLEGE_LEADERBOARD_FAIL,
  };
}

export function getCollegeLeaderboard(id) {
  return (dispatch) => {
    dispatch(getCollegeLeaderboardStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetCollegeLeaderboard(id)
      .then((resp) => {
        dispatch(getCollegeLeaderboardSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCollegeLeaderboardFail());
      });
  };
}

function getCollegeJobsStart() {
  return {
    type: types.GET_COLLEGE_JOBS_START,
  };
}

function getCollegeJobsSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_JOBS_SUCCESS,
    data: data,
  };
}

function getCollegeJobsFail() {
  return {
    type: types.GET_COLLEGE_JOBS_FAIL,
  };
}

export function getCollegeJobs(id) {
  return (dispatch) => {
    dispatch(getCollegeJobsStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetJobsForStudent(id)
      .then((resp) => {
        dispatch(getCollegeJobsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCollegeJobsFail());
      });
  };
}

function getCollegeStatsStart() {
  return {
    type: types.GET_COLLEGE_STATS_START,
  };
}

function getCollegeStatsSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_STATS_SUCCESS,
    data: data,
  };
}

function getCollegeStatsFail() {
  return {
    type: types.GET_COLLEGE_STATS_FAIL,
  };
}

export function getCollegeStats(id) {
  return (dispatch) => {
    dispatch(getCollegeStatsStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetStudentsStats(id, 'daily')
      .then((resp) => {
        dispatch(getCollegeStatsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCollegeStatsFail());
      });
  };
}

function uploadStudentVerificationStart() {
  return {
    type: types.UPLOAD_STUDENT_VERIFICATION_START,
  };
}

function uploadStudentVerificationSuccess({ data }) {
  return {
    type: types.UPLOAD_STUDENT_VERIFICATION_SUCCESS,
    data: data,
  };
}

function uploadStudentVerificationFail() {
  return {
    type: types.UPLOAD_STUDENT_VERIFICATION_FAIL,
  };
}

export function uploadStudentVerification(file) {
  return (dispatch) => {
    dispatch(uploadStudentVerificationStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiUploadStudentVerificationDocs(file)
      .then((resp) => {
        dispatch(uploadStudentVerificationSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(uploadStudentVerificationFail());
      });
  };
}

function postEducationDetailsStart() {
  return {
    type: types.POST_EDUCATION_DETAILS_START,
  };
}

function postEducationDetailsSuccess({ data }) {
  return {
    type: types.POST_EDUCATION_DETAILS_SUCCESS,
    data: data,
  };
}

function postEducationDetailsFail() {
  return {
    type: types.POST_EDUCATION_DETAILS_FAIL,
  };
}

export function postEducationDetails(data) {
  return (dispatch) => {
    dispatch(postEducationDetailsStart());

    apiClient
      .getUserApi()
      .userApiEducationDetails(data)
      .then((resp) => {
        dispatch(postEducationDetailsSuccess({ data: resp.data }));
        dispatch(getUserColleges());
      })
      .catch(() => {
        dispatch(postEducationDetailsFail());
      });
  };
}

function postCollegeDashboardMilestoneStart() {
  return {
    type: types.POST_COLLEGE_DASHBOARD_MILESTONE_START,
  };
}

function postCollegeDashboardMilestoneSuccess({ data }) {
  return {
    type: types.POST_COLLEGE_DASHBOARD_MILESTONE_SUCCESS,
    data: data,
  };
}

function postCollegeDashboardMilestoneFail() {
  return {
    type: types.POST_COLLEGE_DASHBOARD_MILESTONE_FAIL,
  };
}

export function postCollegeDashboardMilestone(data) {
  return (dispatch) => {
    dispatch(postCollegeDashboardMilestoneStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiCreateCollegeMilestoneDetails(data)
      .then((resp) => {
        dispatch(postCollegeDashboardMilestoneSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(postCollegeDashboardMilestoneFail());
      });
  };
}

function getCollegeDashboardMilestoneStart() {
  return {
    type: types.GET_COLLEGE_DASHBOARD_MILESTONE_START,
  };
}

function getCollegeDashboardMilestoneSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_DASHBOARD_MILESTONE_SUCCESS,
    data: data,
  };
}

function getCollegeDashboardMilestoneFail() {
  return {
    type: types.GET_COLLEGE_DASHBOARD_MILESTONE_FAIL,
  };
}

export function getCollegeDashboardMilestone(id) {
  return (dispatch) => {
    dispatch(getCollegeDashboardMilestoneStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiGetCollegeMilestoneDetails(id)
      .then((resp) => {
        dispatch(getCollegeDashboardMilestoneSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCollegeDashboardMilestoneFail());
      });
  };
}

function updateCollegeDashboardMilestoneStart() {
  return {
    type: types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_START,
  };
}

function updateCollegeDashboardMilestoneSuccess({ data }) {
  return {
    type: types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS,
    data: data,
  };
}

function updateCollegeDashboardMilestoneFail() {
  return {
    type: types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_FAIL,
  };
}

export function updateCollegeDashboardMilestone(id, data) {
  return (dispatch) => {
    dispatch(updateCollegeDashboardMilestoneStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiUpdateCollegeMilestoneDetails(id, data)
      .then((resp) => {
        dispatch(updateCollegeDashboardMilestoneSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(updateCollegeDashboardMilestoneFail());
      });
  };
}

function deleteCollegeDashboardMilestoneStart() {
  return {
    type: types.DELETE_COLLEGE_DASHBOARD_MILESTONE_START,
  };
}

function deleteCollegeDashboardMilestoneSuccess({ data }) {
  return {
    type: types.DELETE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS,
    data: data,
  };
}

function deleteCollegeDashboardMilestoneFail() {
  return {
    type: types.DELETE_COLLEGE_DASHBOARD_MILESTONE_FAIL,
  };
}

export function deleteCollegeDashboardMilestone(id, currentCollege) {
  return (dispatch) => {
    dispatch(deleteCollegeDashboardMilestoneStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiDeleteCollegeMilestoneDetail(id)
      .then((resp) => {
        dispatch(deleteCollegeDashboardMilestoneSuccess({ data: resp.data }));
        dispatch(getCollegeDashboardMilestone(currentCollege));
      })
      .catch(() => {
        dispatch(deleteCollegeDashboardMilestoneFail());
      });
  };
}

function uploadCollegeMilestoneImgStart() {
  return {
    type: types.UPLOAD_COLLEGE_MILESTONE_IMG_START,
  };
}

function uploadCollegeMilestoneImgSuccess({ data }) {
  return {
    type: types.UPLOAD_COLLEGE_MILESTONE_IMG_SUCCESS,
    data: data,
  };
}

function uploadCollegeMilestoneImgFail() {
  return {
    type: types.UPLOAD_COLLEGE_MILESTONE_IMG_FAIL,
  };
}

export function uploadCollegeMilestoneImg(file) {
  return (dispatch) => {
    dispatch(uploadCollegeMilestoneImgStart());

    apiClient
      .getStudentManagementApi()
      .studentManagementApiUploadMilestoneImage(file)
      .then((resp) => {
        dispatch(uploadCollegeMilestoneImgSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(uploadCollegeMilestoneImgFail());
      });
  };
}
