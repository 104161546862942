import React, { useState, useEffect } from 'react';
import './style.css';
import { Grid, Divider, TablePagination } from '@mui/material';
import TabHeader from './ContainerHeader';
import QuestionCard from './QuestionView';
import { useSelector } from 'react-redux';
import Loader from '../Loader/index';

function VotedHistoryPage(props) {
  const { user, tag, getVotedReportQuestion } = props;

  const votedReportQuestion = useSelector(
    (state) => state.question.votedReportQuestion,
  );

  const {
    current_page,
    page_size,
    total_pages,
    total_correct_votes,
    total_credits_earned,
    total_incorrect_votes,
    review_history_list,
  } = votedReportQuestion;

  useEffect(() => {
    getVotedReportQuestion(user?.selected_domain);
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(page_size ? page_size : 10);

  const handleChangePage = () => {};

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  if (!Object.keys(votedReportQuestion).length) {
    return (
      <div style={{ height: '50vh' }}>
        <Loader size={44} color="primary" />
      </div>
    );
  }

  return (
    <div>
      <TabHeader />
      <Divider style={{ margin: '10px 0px' }} />
      <Grid container alignItems="center" className="voting-history-summary">
        <p className="review-report-credit">
          Total Correct Votes :
          <strong className="review-report-strong">
            + {total_correct_votes}
          </strong>
        </p>
        <Divider orientation="vertical" flexItem />
        <p className="review-report-credit">
          Total Incorrect Votes :
          <strong className="review-report-strong">
            + {total_incorrect_votes}
          </strong>
        </p>
        <Divider orientation="vertical" flexItem />
        <p className="review-report-credit">
          Credits Earned :
          <strong className="review-report-strong">
            + {total_credits_earned}
          </strong>
        </p>
      </Grid>
      <Grid style={{ marginTop: '50px' }}>
        {votedReportQuestion?.review_history_list ? (
          review_history_list?.map((item, index) => (
            <QuestionCard
              question={item.question}
              index={index + 1}
              key={`${item?.id}-${index}`}
              tag={tag}
              tagList={tag.list}
              isVotingQuestion={true}
              reportDetails={item}
            />
          ))
        ) : (
          <></>
        )}
      </Grid>
      <TablePagination
        component="nav"
        page={current_page}
        rowsPerPage={rowsPerPage}
        count={page_size * total_pages}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ marginTop: '50px' }}
      />
    </div>
  );
}

export default VotedHistoryPage;
