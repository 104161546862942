import * as types from './types';
import apiClient from '../api/apiclient.ts';

function getEmailNotificationStart() {
  return {
    type: types.GET_EMAIL_NOTIFICATIONS_START,
  };
}

function getEmailNotificationSuccess({ data }) {
  return {
    type: types.GET_EMAIL_NOTIFICATIONS_SUCCESS,
    data: data,
  };
}

function getEmailNotificationFail() {
  return {
    type: types.GET_EMAIL_NOTIFICATIONS_FAIL,
  };
}

export function getEmailNotification() {
  return (dispatch) => {
    dispatch(getEmailNotificationStart());

    apiClient
      .getEmailNotificationAPI()
      .notificationApiGetEmailNotifications()
      .then((resp) => {
        dispatch(getEmailNotificationSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getEmailNotificationFail());
      });
  };
}
function postEmailNotificationStart() {
  return {
    type: types.POST_EMAIL_NOTIFICATIONS_START,
  };
}

function postEmailNotificationSuccess({ data }) {
  return {
    type: types.POST_EMAIL_NOTIFICATIONS_SUCCESS,
    data: data,
  };
}

function postEmailNotificationFail() {
  return {
    type: types.POST_EMAIL_NOTIFICATIONS_FAIL,
  };
}

export function postEmailNotification(body) {
  return (dispatch) => {
    dispatch(postEmailNotificationStart());

    apiClient
      .getEmailNotificationAPI()
      .notificationApiCreateEmailNotifications(body)
      .then((resp) => {
        dispatch(postEmailNotificationSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(postEmailNotificationFail());
      });
  };
}
function putEmailNotificationStart() {
  return {
    type: types.PUT_EMAIL_NOTIFICATIONS_START,
  };
}

function putEmailNotificationSuccess({ data }) {
  return {
    type: types.PUT_EMAIL_NOTIFICATIONS_SUCCESS,
    data: data,
  };
}

function putEmailNotificationFail() {
  return {
    type: types.PUT_EMAIL_NOTIFICATIONS_FAIL,
  };
}

export function putEmailNotification(id, body) {
  return (dispatch) => {
    dispatch(putEmailNotificationStart());

    apiClient
      .getEmailNotificationAPI()
      .notificationApiUpdateEmailNotifications(id, body)
      .then((resp) => {
        dispatch(putEmailNotificationSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putEmailNotificationFail());
      });
  };
}
function deleteEmailNotificationStart() {
  return {
    type: types.DELETE_EMAIL_NOTIFICATIONS_START,
  };
}

function deleteEmailNotificationSuccess({ data }) {
  return {
    type: types.DELETE_EMAIL_NOTIFICATIONS_SUCCESS,
    data: data,
  };
}

function deleteEmailNotificationFail() {
  return {
    type: types.DELETE_EMAIL_NOTIFICATIONS_FAIL,
  };
}

export function deleteEmailNotification(id) {
  return (dispatch) => {
    dispatch(deleteEmailNotificationStart());

    apiClient
      .getEmailNotificationAPI()
      .notificationApiDeleteEmailNotifications(id)
      .then((resp) => {
        dispatch(deleteEmailNotificationSuccess({ data: resp.data }));
        dispatch(getEmailNotification());
      })
      .catch(() => {
        dispatch(deleteEmailNotificationFail());
      });
  };
}
