import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const badges = createReducer(
  {
    badgeData: [],
    s3ImgUrl: '',
    badgeTypedata: [],
  },
  {
    [types.GET_BADGES_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingBadgeList: true,
        s3ImgUrl: '',
      });

      return newState;
    },
    [types.GET_BADGES_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        badgeData: action.data,
        isFetchingBadgeList: false,
      });

      return newState;
    },
    [types.GET_BADGES_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingBadgeList: false,
      });

      return newState;
    },
    [types.POST_BADGES_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingBadge: true,
      });

      return newState;
    },
    [types.POST_BADGES_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingBadge: false,
        isCreatingBadgeSuccess: true,
        isUpdatingBadgeSuccess: false,
      });

      return newState;
    },
    [types.POST_BADGES_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingBadge: false,
      });

      return newState;
    },

    [types.POST_BADGE_IMAGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingBadgeImg: true,
      });

      return newState;
    },
    [types.POST_BADGE_IMAGE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingBadgeImg: false,
        s3ImgUrl: action.data.s3_image_url,
      });

      return newState;
    },
    [types.POST_BADGE_IMAGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingBadgeImg: false,
      });

      return newState;
    },

    [types.UPDATE_BADGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingBadge: true,
      });

      return newState;
    },
    [types.UPDATE_BADGE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingBadge: false,
        isUpdatingBadgeSuccess: true,
        isCreatingBadgeSuccess: false,
      });

      return newState;
    },
    [types.UPDATE_BADGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingBadge: false,
      });

      return newState;
    },
    [types.DELETE_BADGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingBadge: true,
      });

      return newState;
    },
    [types.DELETE_BADGE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingBadge: false,
      });

      return newState;
    },
    [types.DELETE_BADGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingBadge: false,
      });

      return newState;
    },

    [types.GET_BADGE_TYPE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingBadgeType: true,
      });

      return newState;
    },
    [types.GET_BADGE_TYPE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingBadgeType: false,
        badgeTypedata: action.data,
      });

      return newState;
    },
    [types.GET_BADGE_TYPE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingBadgeType: false,
      });

      return newState;
    },
  },
);

export { badges as default };
