import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { StyledAppLoader } from './StyledComponent';

const Loader = ({ size, color, shouldShowLoadingText }) => {
  return (
    <StyledAppLoader className="app-loader">
      <CircularProgress size={size} color={color} />
      {shouldShowLoadingText && <p className="loader-text">Loading...</p>}
    </StyledAppLoader>
  );
};

export default Loader;
