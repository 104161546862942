import axios from 'axios';
import * as types from './types';
import config from './../config';
import apiClient from '../api/apiclient';

export function postTagStart() {
  return {
    type: types.POST_TAG_START,
  };
}

export function postTagSuccess() {
  return {
    type: types.POST_TAG_SUCCESS,
  };
}

export function postTagFail(data) {
  return {
    type: types.POST_TAG_FAIL,
    data,
  };
}

export function postTag(tag) {
  return (dispatch, getState) => {
    dispatch(postTagStart());

    const { auth } = getState();
    axios
      .post(`${config.API_URL}/tag`, tag, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(postTagSuccess());
      })
      .catch((ex) => {
        dispatch(postTagFail(ex.response.data));
      });
  };
}

export function updateTag(tag, tagId) {
  return (dispatch, getState) => {
    dispatch(postTagStart());
    const { auth } = getState();
    axios
      .put(`${config.API_URL}/tag/${tagId}`, tag, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(postTagSuccess());
      })
      .catch((ex) => {
        dispatch(postTagFail(ex.response.data));
      });
  };
}

export function getTagStart() {
  return {
    type: types.GET_TAG_START,
  };
}

export function getTagSuccess({ data }) {
  return {
    type: types.GET_TAG_SUCCESS,
    data: data,
  };
}

export function getTagFail() {
  return {
    type: types.GET_TAG_FAIL,
  };
}

export function getTag(approval_status = undefined, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();

    dispatch(getTagStart());
    apiClient
      .getTagApi()
      .tagApiGetTagList(
        undefined,
        approval_status,
        undefined,
        undefined,
        undefined,
      )
      .then((resp) => {
        if (callback) {
          callback(resp.data);
        } else {
          dispatch(
            getTagSuccess({
              data: {
                category_data: resp.data,
                selected_domain: user.selected_domain,
              },
            }),
          );
        }

        return Promise.resolve();
      })
      .catch(() => {
        dispatch(getTagFail());
      });
  };
}

export function getTagById(tagId, callback) {
  return () => {
    apiClient
      .getTagApi()
      .tagApiGetTagById(tagId)
      .then((res) => {
        callback?.(res.data);
      });
  };
}

export function getScoreByTagSuccess({ data }) {
  return {
    type: types.GET_SCORE_BY_TAG_SUCCESS,
    data: data,
  };
}

export function getScoreByTagFail() {
  return {
    type: types.GET_SCORE_BY_TAG_FAIL,
  };
}

export function getScoreByTag() {
  return (dispatch, getState) => {
    const { auth, user } = getState();

    axios
      .get(`${config.API_URL}/score/score_by_tag/${user.data.id}`, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(getScoreByTagSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getScoreByTagFail());
      });
  };
}

const putSaveStreakStart = () => {
  return {
    type: types.PUT_SAVE_STREAK_START,
  };
};

const putSaveStreakSuccess = ({ data }) => {
  return {
    type: types.PUT_SAVE_STREAK_SUCCESS,
    data,
  };
};

const putSaveStreakFail = () => {
  return {
    type: types.PUT_SAVE_STREAK_FAIL,
  };
};

export function putSaveStreak({ categoryId }) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(putSaveStreakStart());

    axios
      .put(
        `${config.API_URL}/tag/${categoryId}/save-my-streak`,
        {},
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((resp) => {
        dispatch(putSaveStreakSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putSaveStreakFail());
      });
  };
}

export function postSubtagStart() {
  return {
    type: types.POST_SUBTAG_START,
  };
}

export function postSubtagSuccess() {
  return {
    type: types.POST_SUBTAG_SUCCESS,
  };
}

export function postSubtagFail(data) {
  return {
    type: types.POST_SUBTAG_FAIL,
    data,
  };
}

export function postSubtag(subtag) {
  return (dispatch, getState) => {
    dispatch(postSubtagStart());

    const { auth } = getState();
    axios
      .post(`${config.API_URL}/sub_tag`, subtag, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(postSubtagSuccess());
      })
      .catch((ex) => {
        dispatch(postSubtagFail(ex.response.data));
      });
  };
}

export function updateSubtag(subtag, subtagId) {
  return (dispatch, getState) => {
    dispatch(postSubtagStart());

    const { auth } = getState();
    axios
      .put(`${config.API_URL}/sub_tag/${subtagId}`, subtag, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(postSubtagSuccess());
      })
      .catch((ex) => {
        dispatch(postSubtagFail(ex.response.data));
      });
  };
}

const removeTagFromDomainStart = () => {
  return {
    type: types.REMOVE_TAG_FROM_DOMAIN_START,
  };
};

const removeTagFromDomainSuccess = ({ data }) => {
  return {
    type: types.REMOVE_TAG_FROM_DOMAIN_SUCCESS,
    data,
  };
};

const removeTagFromDomainFail = () => {
  return {
    type: types.REMOVE_TAG_FROM_DOMAIN_FAIL,
  };
};

export function removeTagFromDomain({ tagId, domainId }, callback) {
  return (dispatch, getState) => {
    dispatch(removeTagFromDomainStart());

    const { auth } = getState();
    axios
      .delete(`${config.API_URL}/domain/${domainId}/tag/${tagId}/delink`, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(removeTagFromDomainSuccess({ data: resp.data }));
        if (callback) callback(resp.data);
      })
      .catch(() => {
        dispatch(removeTagFromDomainFail());
      });
  };
}

let tagAutocompleteCancelToken = null;
export function getTagAutocomplete(query, callback) {
  return () => {
    if (tagAutocompleteCancelToken) {
      tagAutocompleteCancelToken.cancel();
    }

    tagAutocompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getTagApi()
      .tagApiTagAutocomplete(undefined, undefined, query)
      .then((res) => {
        callback(res.data.data);
      });
  };
}

const addTagsToDomianStart = () => {
  return {
    type: types.ADD_TAG_TO_DOMAIN_START,
  };
};

const addTagsToDomianSuccess = ({ data }) => {
  return {
    type: types.ADD_TAG_TO_DOMAIN_SUCCESS,
    data,
  };
};

const addTagsToDomianFail = () => {
  return {
    type: types.ADD_TAG_TO_DOMAIN_FAIL,
  };
};

export function addTagsToDomian(domainId, data, callback) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(addTagsToDomianStart());

    axios
      .post(
        `${config.API_URL}/domain/${domainId}/add_tags`,
        {
          tag_ids: data.tagIds,
        },
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((res) => {
        dispatch(addTagsToDomianSuccess({ data: res.data }));
        if (callback) callback(res.data);
      })
      .catch(() => {
        dispatch(addTagsToDomianFail());
      });
  };
}
