import * as React from 'react';
import { maxTimeOptions } from '../../constants/maxTimeOptions';
import ReactSelect from 'react-select';
import { FormControl, FormLabel } from '@mui/material';

const TimeTooLessOption = ({ onChange, value }) => {
  const onTimeChange = (time) => {
    const timeChangeBody = {
      ...value,
      correct_values: [time?.value],
    };
    onChange(timeChangeBody);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit', paddingBottom: '8px' }}
      >
        Select appropriate time
      </FormLabel>
      <ReactSelect
        options={maxTimeOptions}
        value={maxTimeOptions.find(
          (c) => c.value === value.correct_values?.[0],
        )}
        getOptionLabel={(option) => option.label}
        isClearable
        onChange={onTimeChange}
      />
    </FormControl>
  );
};

export default TimeTooLessOption;
