import { styled } from '@mui/styles';
export const ParentContainer = styled('div')(({ theme }) => ({
  '& .play-answer': {
    marginTop: '25px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      padding: '0px',
    },
  },
  '& .play-answer__question p': {
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: 1.31,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  '& .play-answer__submit': {
    padding: '5px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',

    '& __hint': {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      width: '100vw',
      zIndex: 50,
      backgroundColor: theme.palette.background.main,
      gap: '5px',

      '& .play-answer__submit__skip, & .play-answer__submit__next, & .play-answer__submit__hint': {
        flexDirection: 'row-reverse',
        width: '100%',
        borderRadius: '5px',
        height: '45px',
      },
    },
  },

  '& .play-answer__solution': {
    padding: '30px 0',
  },

  '& .bottomicon': {
    display: 'flex',
    marginLeft: 'auto',
  },

  '& .questionwithHint': {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .hintIcon': {
    fill: 'rgba(255, 218, 28, 0.1)',
    strokeWidth: '1px',
    stroke: theme.palette.common.warning,
  },

  '& .question-hint': {
    backgroundColor: theme.palette.secondary.background,
    marginTop: '1em',
    padding: '1em',

    '& p:first-child': {
      color: theme.palette.secondary.main,
      fontWeight: 600,
      marginBottom: '0.5em',
    },

    '& p:last-child': {
      margin: '0',
    },
  },

  '& .solution-heading': {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .likeDislikeBtn': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      color: theme.palette.playColors.borderBlue,

      '& .likePressed': {
        color: `${theme.palette.common.success} !important`,
      },

      '& .dislikePressed': {
        color: `${theme.palette.common.error} !important`,
      },
    },
  },

  '& .public-question-solution-wrapper': {
    background: theme.palette.background.main,
    borderRadius: '4px',
    padding: '30px 10px 50px 10px',
    position: 'relative',
  },

  '& .public-question-blur-div': {
    position: 'absolute',
    filter: 'blur(2px)',
    backdropFilter: 'blur(5px)',
  },

  '& .public-question-btn-box': {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '10',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .solution-wrapper': {
    '& p': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  },

  '& .solutionText': {
    padding: '10px 10px',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.component.background}`,
  },

  '& .solution-content': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .solution-content p': {
    flex: '1',
  },

  '& .solution-input': {
    marginBottom: '20px',
  },

  '& .modifiedActive': {
    backgroundColor: `${theme.palette.playColors.lightGreen1} !important`,
  },

  '& .modifiedActiveIncorrect': {
    backgroundColor: `${theme.palette.playColors.lightRed} !important`,
  },

  '& .modifiedActiveSkipped': {
    backgroundColor: theme.palette.playColors.lightRed,
  },

  '& .modifiedActiveSkipped .option-text': {
    color: theme.palette.component.background,
  },

  '& .modifiedActiveSkipped .option-label': {
    backgroundColor: 'transparent !important',
  },

  '& .modifiedInactive': {
    backgroundColor: `${theme.palette.playColors.lightPink1} !important`,
  },

  '& .modifiedInactive .option-label': {
    backgroundColor: `${theme.palette.playColors.lightRed1} !important`,
  },

  '& .modifiedActiveIncorrect .option-label': {
    backgroundColor: `${theme.palette.playColors.lightRed1} !important`,
  },

  '& .modifiedActive .option-label': {
    backgroundColor: `${theme.palette.playColors.lightRed1} !important`,
  },

  '& .option': {
    display: ['-webkit-flex', '-moz-flex', '-ms-flex', '-o-flex', 'flex'],
    alignItems: 'center',
    margin: '20px 0px',
    '& .option-label': {
      background: theme.palette.primary.background,
      borderRadius: '4px',
      color: theme.palette.secondary.main,
      lineHeight: '1',
      padding: '10px',
      marginRight: '7px',

      '& .correct-answers': {
        backgroundColor: theme.palette.text.main,
        color: theme.palette.background.main,
      },
    },

    '& .option-text': {
      [theme.breakpoints.down('sm')]: {
        p: {
          fontSize: '14px !important',
        },
      },
    },
  },

  '& .option:nth-child(n + 2)': {
    marginTop: '5px',
  },

  '& .play-answer__options label': {
    color: `${theme.palette.component.color} !important`,
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },

  '& .play-answer .answer-text': {
    width: '60%',

    '& .answerBox': {
      height: 'fit-content',
      padding: '10px',
      border: `1px solid  ${theme.palette.text.main}`,
      color: theme.palette.component.background,
      fontWeight: 400,
      borderRadius: '5px',
      width: '60%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },

  '& .answerBox': {
    width: '100%',
    height: 'fit-content',
    padding: '10px',
    border: `1px solid ${theme.palette.text.main}`,
    borderRadius: '5px',
  },

  '& .answer-text-submitted-correct': {
    borderRadius: '8px',
    minHeight: '80px',
    border: `2px solid ${theme.palette.common.success}`,
    color: theme.palette.common.success,
    padding: '10px 14px',
  },

  '& .answer-text-submitted-incorrect': {
    borderRadius: '8px',
    border: `2px solid ${theme.palette.common.error}`,
    color: theme.palette.common.error,
    padding: '10px 14px',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  '& .answer-text-submitted-timeout': {
    borderRadius: '8px',
    border: `2px solid ${theme.palette.common.warning}`,
    color: theme.palette.common.warning,
    padding: '10px 14px',
    width: '60%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  '& .answer-text-submitted-skipped': {
    borderRadius: '8px',
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.main,
    padding: '10px 14px',
    width: '60%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  '& .play-answer__true': {
    color: theme.palette.common.success,
    backgroundColor: theme.palette.playColors.lightGreen1,
    border: `1px solid ${theme.palette.playColors.lightGreen2}`,
    padding: '15px 15px',
    fontSize: '16px',
    borderRadius: '4px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  '& .play-answer__true p': {
    marginBottom: '0px !important',
    fontSize: '16px !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  '& .play-category': {
    display: 'flex',
    flexWrap: 'wrap',
  },

  '& .play-category .play-category-card': {
    width: '33.3%',
    padding: '10px',
  },

  '& .play-category-card h3': {
    fontSize: '18px',
    color: theme.palette.secondary.main,
    fontWeight: 'normal',
    margin: '0',
    marginBottom: '10px',
  },

  '& .play-category-card p': {
    fontSize: '13px',
    color: theme.palette.component.color,
    fontWeight: 300,
    margin: '0',
    marginBottom: '10px',
  },

  '& .play-category-card .select-category-btn': {
    fontSize: '12px',
    color: theme.palette.common.success,
  },

  '& .play-answer__false': {
    color: theme.palette.common.error,
    backgroundColor: theme.palette.playColors.lightPink1,
    borderColor: theme.palette.playColors.lightPink2,
    padding: '15px 15px',
    fontSize: '16px',
    borderRadius: '4px',
    marginBottom: '30px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  '& .bg-category': {
    backgroundColor: theme.palette.common.error,
    height: '100px',
  },

  '& .PlayAnswer-root-86': {
    float: 'right',
  },

  '& .PlayAnswer-icon-87': {
    margin: '0px !important',
  },

  '& .MuiIcon-root-89, & .MuiIcon-root-95': {
    fontSize: '18px !important',
  },

  '& .PlayAnswer-note': {
    backgroundColor: theme.palette.playColors.lightYellow1,
    borderLeft: `6px solid ${theme.palette.common.warning}`,
    padding: '10px',
    color: theme.palette.component.title,
  },

  '& .PlayAnswer-note p': {
    color: theme.palette.component.title,
    lineHeight: '18px',
  },

  '& .question-text': {
    fontSize: '12px !important',
  },

  '& .prevent-selection': {
    userSelect: 'none',
  },

  '& .solution-text-hidden': {
    position: 'relative',
    padding: '12px',

    '& > p': {
      userSelect: 'none',
      padding: '1em',
      maxWidth: '45vw',
      overflowWrap: 'break-word',

      [theme.breakpoints.down('lg')]: {
        maxWidth: '75vw',
      },
    },

    '& div': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      minHeight: '100px',
      height: '100%',
      background: theme.palette.primary.background,
      borderRadius: '5px',
      backdropFilter: 'blur(4px)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& p': {
        margin: '0',
      },
    },
  },

  '& .active .option': {
    backgroundColor: `${theme.palette.playColors.lightGreen1} !important`,
  },

  '& .inactive .option': {
    backgroundColor: `${theme.palette.playColors.lightPink1} !important`,
  },

  '& .timedOut': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    margin: '1rem 0',

    '& div': {
      display: 'flex',
      gap: '1rem',

      '& span': {
        fontWeight: 'bold',
        color: 'black',
      },
    },

    '& p': {
      color: 'black',
      fontSize: '16px !important',
    },

    '& hr': {
      border: 'black',
      height: '2px',
      backgroundColor: 'black',
    },
  },

  '& .solution_rating': {
    borderRadius: '50%',
    display: 'flex',
    fontWeight: '500',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
  },

  '& .solution_rating:hover': {
    cursor: 'pointer',
  },
}));

export const StyledOption = styled('div')(({ theme }) => ({
  display: ['-webkit-flex', '-moz-flex', '-ms-flex', '-o-flex', 'flex'],
  alignItems: 'center',
  margin: '20px 0px',

  '& .option-label': {
    background: theme.palette.primary.background,
    borderRadius: '4px',
    color: theme.palette.secondary.main,
    lineHeight: '1',
    padding: '10px',
    marginRight: '7px',

    '& .correct-answers': {
      backgroundColor: theme.palette.common.success,
      color: theme.palette.common.success,
    },
  },

  '& .option-text': {
    [theme.breakpoints.down('sm')]: {
      p: {
        fontSize: '14px !important',
      },
    },
  },
  '& .option:nth-child(n + 2)': {
    marginTop: '5px',
  },
}));
