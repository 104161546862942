import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import LikeIcon from './Images/LikeIcon.svg';
import LikeColored from './Images/LikeColored.svg';
import DisLikeColored from './Images/Dislike.svg';
import DisLikeIcon from './Images/DislikeIcon.svg';
import './index.css';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { StyledIcon, StyledRateQuestionContainer } from './StyledComponent';

const Like_Dislike = (props) => {
  const [liked, setLiked] = useState(props.liked);
  const [disliked, setDisliked] = useState(props.disliked);
  const { likedCount, isPutLikeSuccess } = useSelector(
    (state) => state.question,
  );

  const changeLikeIcon = () => {
    setLiked(!liked);
    setDisliked(false);
  };

  const changeDislikeIcon = () => {
    setDisliked(!disliked);
    setLiked(false);
  };

  return (
    <StyledRateQuestionContainer className="rate-question-container">
      <StyledIcon className="icon">
        {liked ? (
          <IconButton
            onClick={() => {
              changeLikeIcon();
              props.putLike({
                questionId: props.id,
                body: {
                  is_liked: false,
                },
              });
            }}
            size="large"
          >
            <img src={LikeColored} />
            <Typography
              sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 500,
                paddingLeft: '5px',
              }}
            >
              {isPutLikeSuccess && likedCount?.total_likes}
            </Typography>
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              changeLikeIcon();
              props.putLike({
                questionId: props.id,
                body: {
                  is_liked: true,
                },
              });
            }}
            size="large"
          >
            <img src={LikeIcon} />
            <Typography
              sx={{
                color: '#7B7B7B66',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 500,
                paddingLeft: '5px',
              }}
            >
              {isPutLikeSuccess && likedCount?.total_likes}
            </Typography>
          </IconButton>
        )}
      </StyledIcon>

      <StyledIcon className="icon">
        {disliked ? (
          <IconButton
            onClick={() => {
              changeDislikeIcon();
              props.putDislike({
                questionId: props.id,
                body: {
                  is_disliked: false,
                },
              });
            }}
            size="large"
          >
            <img src={DisLikeColored} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              changeDislikeIcon();
              props.putDislike({
                questionId: props.id,
                body: {
                  is_disliked: true,
                },
              });
            }}
            size="large"
          >
            <img src={DisLikeIcon} />
          </IconButton>
        )}
      </StyledIcon>
    </StyledRateQuestionContainer>
  );
};
export default Like_Dislike;
