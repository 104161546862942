import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import DefaultProfile from './img/defaultProfile.png';
import EditIcon from '@mui/icons-material/BorderColorOutlined';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { months } from '../../constants/userProfile';
import { useDispatch } from 'react-redux';
import { postFollow, postUnfollow } from '../../actions/publicProfile';
import { useNavigate } from 'react-router-dom';

import {
  UCardContainer,
  UCardAvatarContainer,
  UCardAvatar,
  UCardContentContainer,
  UCardName,
  UCardAbout,
  UCardScore,
  UCardScoreText,
  UCardUsernameContainer,
  UCardUsername,
  UCardCopyIcon,
  UCardBtnStyle,
} from './UserProfileStyledComponent';
import config from '../../config';

const UserCard = ({ data, isSelf, followingUser }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [picture, setPicture] = useState('');
  const [copyBtnClicked, setClickCopyBtn] = useState(false);

  useEffect(() => {
    if (data?.user?.picture) {
      setPicture(data?.user?.picture);
    }
  }, [data, data?.user]);
  const memberSince = new Date(data?.user.created_at);

  return (
    <UCardContainer>
      <UCardAvatarContainer>
        <Box>
          {picture ? (
            <UCardAvatar alt="user-profile-picture" src={picture} />
          ) : (
            <UCardAvatar alt="default-profile" src={DefaultProfile} />
          )}
        </Box>
        <Box textAlign="center">
          <UCardScore color="primary">
            {data?.analytics?.overall?.score}
          </UCardScore>
          <UCardScoreText>Score</UCardScoreText>
        </Box>
      </UCardAvatarContainer>
      <UCardContentContainer>
        <UCardName color="primary">{`${data?.user?.first_name} ${data?.user?.last_name}`}</UCardName>
        <UCardUsernameContainer>
          <UCardUsername>{data?.user?.username || ''}</UCardUsername>
          <UCardCopyIcon
            color="text"
            onClick={() => {
              navigator.clipboard.writeText(
                encodeURI(
                  `${config.WEBSITE_URL}/profile/${data?.user?.username}` || '',
                ),
              );
              setClickCopyBtn(true);
            }}
            onMouseLeave={() => setClickCopyBtn(false)}
          >
            <Tooltip
              enterTouchDelay={50}
              title={copyBtnClicked ? 'Copied' : 'Copy'}
              placement="top"
              arrow
            >
              <FileCopyOutlinedIcon style={{ width: '14px' }} />
            </Tooltip>
          </UCardCopyIcon>
        </UCardUsernameContainer>
        <UCardAbout color="text">
          {data?.user?.about ||
            `Member of Abekus community since ${
              months[memberSince.getMonth()]
            } ${memberSince.getDate()}, ${memberSince.getFullYear()}`}
        </UCardAbout>
        <UCardBtnStyle
          variant="contained"
          color="primary"
          onClick={() => {
            if (isSelf) {
              navigate('/l/user/edit');
            } else {
              if (followingUser) {
                dispatch(postUnfollow(data?.user?.id));
              } else {
                dispatch(postFollow(data?.user?.id));
              }
            }
          }}
          startIcon={isSelf && <EditIcon />}
        >
          {isSelf ? 'Edit Profile' : followingUser ? 'Unfollow' : 'Follow'}
        </UCardBtnStyle>
      </UCardContentContainer>
    </UCardContainer>
  );
};

export default UserCard;
