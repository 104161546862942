import React from 'react';
import ReactSelect from 'react-select';
import { Avatar } from '@mui/material';
import './index.css';

export default function Tags({ placeholder, items, selectedTag, onChange }) {
  return (
    <div className="tag-select">
      <ReactSelect
        options={items}
        onChange={onChange}
        getOptionLabel={(option) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              alt={`${option.name}`}
              src={option.s3_url}
              style={{ height: 24, width: 24 }}
            />
            <p style={{ marginLeft: '20px' }}>{option.name}</p>
          </div>
        )}
        styles={{
          control: (baseStyle) => ({
            ...baseStyle,
            cursor: 'pointer',
          }),
        }}
        getOptionValue={(option) => option.name}
        value={items?.find((c) => c.id === selectedTag)}
        placeholder={placeholder}
      />
    </div>
  );
}
