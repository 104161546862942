import axios from 'axios';
import * as types from './types';
import config from './../config';
import apiClient from '../api/apiclient';
import {
  GET_COMPETITION_LIST_FAIL,
  GET_COMPETITION_LIST_SUCCESS,
} from './types';

function getCompetitionsStart() {
  return {
    type: types.GET_COMPETITION_START,
  };
}

function getCompetitionsSuccess({ data }) {
  return {
    type: types.GET_COMPETITION_SUCCESS,
    data: data,
  };
}

function getCompetitionsFail() {
  return {
    type: types.GET_COMPETITION_FAIL,
  };
}

export function getCompetitions(filters = {}, callback) {
  return (dispatch) => {
    dispatch(getCompetitionsStart());

    apiClient
      .getCompetitionApi()
      .competitionApiGetAllCompetitions(
        filters?.domain_id,
        filters?.page,
        filters?.page_size,
        filters?.search_query,
        filters?.status,
      )
      .then((resp) => {
        dispatch(getCompetitionsSuccess({ data: resp.data }));
        if (callback) callback(resp.data.data);
      })
      .catch(() => {
        dispatch(getCompetitionsFail());
      });
  };
}

//Change domain
const getCompetitionByIdStart = () => {
  return {
    type: types.GET_COMPETITION_BY_ID_START,
  };
};

const getCompetitionByIdSuccess = (data) => {
  return {
    type: types.GET_COMPETITION_BY_ID_SUCCESS,
    data,
  };
};

const getCompetitionByIdFail = () => {
  return {
    type: types.GET_COMPETITION_BY_ID_FAIL,
  };
};

export const getCompetitionById = (competitionId) => {
  return async (dispatch) => {
    dispatch(getCompetitionByIdStart());
    try {
      const response = await apiClient
        .getCompetitionApi()
        .competitionApiGetCompetitionById(competitionId);
      const competition = response.data || {};

      dispatch(getCompetitionByIdSuccess(competition));

      return Promise.resolve(competition);
    } catch (ex) {
      dispatch(getCompetitionByIdFail());
    }
  };
};

export function postCompetitionStart() {
  return {
    type: types.POST_COMPETITION_START,
  };
}

export function postCompetitionSuccess(data) {
  return {
    type: types.POST_COMPETITION_SUCCESS,
    data,
  };
}

export function postCompetitionFail() {
  return {
    type: types.POST_COMPETITION_FAIL,
  };
}

export function postCompetition(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(postCompetitionStart());

      apiClient
        .getCompetitionApi()
        .competitionApiUpsertCompetition(data)
        .then((resp) => {
          dispatch(postCompetitionSuccess());
          resolve(resp.data);
        })
        .catch((ex) => {
          dispatch(postCompetitionFail());
          reject(ex);
        });
    });
  };
}
export function getActiveJobsStart() {
  return {
    type: types.GET_ACTIVE_JOBS_START,
  };
}

export function getActiveJobsSuccess({ data }) {
  return {
    type: types.GET_ACTIVE_JOBS_SUCCESS,
    data,
  };
}

export function getActiveJobsFail() {
  return {
    type: types.GET_ACTIVE_JOBS_FAIL,
  };
}

export function getActiveJobs() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(getActiveJobsStart());

      apiClient
        .getJobApi()
        .jobApiGetAllActiveJobs()
        .then((resp) => {
          dispatch(getActiveJobsSuccess({ data: resp.data }));
          resolve(resp.data);
        })
        .catch((ex) => {
          dispatch(getActiveJobsFail());
          reject(ex);
        });
    });
  };
}

export function postRegistrationStart() {
  return {
    type: types.POST_REGISTRATION_START,
  };
}

export function postRegistrationSuccess(data) {
  return {
    type: types.POST_REGISTRATION_SUCCESS,
    payload: data,
  };
}

export function postRegistrationFail() {
  return {
    type: types.POST_REGISTRATION_FAIL,
  };
}

export function postRegistration(competitionId) {
  return (dispatch, getState) => {
    dispatch(postRegistrationStart());

    const { competitions } = getState();
    apiClient
      .getCompetitionApi()
      .competitionApiCompetitionRegistrations(competitionId)
      .then(() => {
        const credits_consumed = competitions.details.participation_credits;
        dispatch(postRegistrationSuccess({ credits_consumed }));
        dispatch(getStartStatistics(competitionId));
      })
      .catch(() => {
        dispatch(postRegistrationFail());
      });
  };
}

function getRecommendationsStart() {
  return {
    type: types.GET_RECOMMENDATIONS_START,
  };
}

function getRecommendationsSuccess({ data }) {
  return {
    type: types.GET_RECOMMENDATIONS_SUCCESS,
    data: data,
  };
}

function getRecommendationsFail() {
  return {
    type: types.GET_RECOMMENDATIONS_FAIL,
  };
}

export function getRecommendations() {
  return (dispatch) => {
    dispatch(getRecommendationsStart());

    apiClient
      .getCompetitionApi()
      .competitionApiGetAllCompetitions()
      .then((resp) => {
        dispatch(getRecommendationsSuccess({ data: resp.data.data }));
      })
      .catch(() => {
        dispatch(getRecommendationsFail());
      });
  };
}

export function deleteCompetitionStart() {
  return {
    type: types.DELETE_COMPETITION_START,
  };
}

export function deleteCompetitionSuccess({ data }) {
  return {
    type: types.DELETE_COMPETITION_SUCCESS,
    data,
  };
}

export function deleteCompetitionFail({ data }) {
  return {
    type: types.DELETE_COMPETITION_FAIL,
    data,
  };
}

export function deleteCompetition(
  competitionId,
  successCallback,
  failCallback,
) {
  return (dispatch) => {
    dispatch(deleteCompetitionStart());
    apiClient
      .getCompetitionApi()
      .competitionApiDeleteCompetition(competitionId)
      .then((res) => {
        dispatch(
          deleteCompetitionSuccess({
            data: { response: res.data, id: competitionId },
          }),
        );
        successCallback?.();
      })
      .catch((err) => {
        dispatch(
          deleteCompetitionFail({
            data: err,
          }),
        );
        failCallback?.();
      });
  };
}

export function getCertificatePathStart() {
  return {
    type: types.GET_CERTIFICATE_PATH_START,
  };
}

export function getCertificatePathSuccess(data) {
  return {
    type: types.GET_CERTIFICATE_PATH_SUCCESS,
    data,
  };
}

export function getCertificatePathFail() {
  return {
    type: types.GET_CERTIFICATE_PATH_FAIL,
  };
}

export function getCertificatePath(competitionId) {
  return async (dispatch) => {
    dispatch(getCertificatePathStart());
    apiClient
      .getCompetitionApi()
      .competitionApiCreateSelfCertificate(competitionId)
      .then((resp) => {
        dispatch(getCertificatePathSuccess(resp.data));
        const url = `${config.API_URL}/files/download-file-from-path?filepath=${resp.data.filename}`;
        window.open(url);
      })
      .catch(() => {
        dispatch(getCertificatePathFail());
      });
  };
}

function getCompetitionAnalyticsStart() {
  return {
    type: types.GET_COMPETITION_ANALYTICS_START,
  };
}

function getCompetitionAnalyticsSuccess({ data }) {
  return {
    type: types.GET_COMPETITION_ANALYTICS_SUCCESS,
    data: data,
  };
}

function getCompetitionAnalyticsFail() {
  return {
    type: types.GET_COMPETITION_ANALYTICS_FAIL,
  };
}

export function getCompetitionAnalytics(competitionId) {
  return (dispatch) => {
    dispatch(getCompetitionAnalyticsStart());
    apiClient
      .getCompetitionApi()
      .competitionApiGetDetailedCompetitionAnalyticsByUser(competitionId)

      .then((resp) => {
        dispatch(getCompetitionAnalyticsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCompetitionAnalyticsFail());
      });
  };
}

function getCompetitionQuestionsStart() {
  return {
    type: types.GET_COMPETITION_QUESTION_START,
  };
}

function getCompetitionQuestionsSuccess(data) {
  return {
    type: types.GET_COMPETITION_QUESTION_SUCCESS,
    data,
  };
}

function getCompetitionQuestionsFail(err) {
  return {
    type: types.GET_COMPETITION_QUESTION_FAIL,
    data: err,
  };
}

export function getCompetitionQuestions(exam_id, page = 1, page_size = 10) {
  return (dispatch) => {
    dispatch(getCompetitionQuestionsStart());
    apiClient
      .getQuestionApi()
      .questionApiGetAnsweredAttemptById(exam_id, page, page_size)
      .then((res) => {
        dispatch(getCompetitionQuestionsSuccess(res.data));
      })
      .catch(() => {
        dispatch(getCompetitionQuestionsFail('Failed to get Test'));
      });
  };
}

export function getCompetitionsSchedules(page_no, page_size) {
  return (dispatch) => {
    dispatch(getCompetitionsSchedulesStart());

    apiClient
      .getCompetitionApi()
      .competitionApiGetCompetitionSchedules('', '', '', page_no, page_size)
      .then((res) => {
        dispatch(getCompetitionsSchedulesSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getCompetitionsSchedulesFail(error));
        throw error;
      });
  };
}

export function getCompetitionsSchedulesStart() {
  return { type: types.GET_COMPETITION_SCHEDULE_START };
}

export function getCompetitionsSchedulesSuccess(data) {
  return { type: types.GET_COMPETITION_SCHEDULE_SUCCESS, data };
}

export function getCompetitionsSchedulesFail(err) {
  return { type: types.GET_COMPETITION_SCHEDULE_FAIL, data: err };
}

export const postCompetitionsSchedules = (data, competitionId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(postCompetitionsSchedulesStart());
      apiClient
        .getCompetitionApi()
        .competitionApiUpsertCompetitionSchedule(competitionId, data)
        .then((res) => {
          dispatch(postCompetitionsSchedulesSuccess(res.data));
          resolve(res.data);
        })
        .catch((ex) => {
          dispatch(postCompetitionsSchedulesFail(ex));
          reject();
        });
    });
};
export const postCompetitionsSchedulesStart = () => {
  return { type: types.POST_COMPETITION_SCHEDULE_START };
};
export const postCompetitionsSchedulesSuccess = (data) => {
  return { type: types.POST_COMPETITION_SCHEDULE_SUCCESS, data };
};
export const postCompetitionsSchedulesFail = (err) => {
  return { type: types.POST_COMPETITION_SCHEDULE_FAIL, data: err };
};

export const deleteCompetitionsSchedules = (scheduleId) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(deleteCompetitionsSchedulesStart());
      apiClient
        .getCompetitionApi()
        .competitionApiDeleteCompetitionSchedule(scheduleId)
        .then((res) => {
          dispatch(deleteCompetitionsSchedulesSuccess(res.data));
          resolve(res.data);
        })
        .catch((ex) => {
          dispatch(deleteCompetitionsSchedulesFail(ex));
          reject();
        });
    });
};
export const deleteCompetitionsSchedulesStart = () => {
  return { type: types.DELETE_COMPETITION_SCHEDULE_START };
};
export const deleteCompetitionsSchedulesSuccess = (data) => {
  return { type: types.DELETE_COMPETITION_SCHEDULE_SUCCESS, data };
};
export const deleteCompetitionsSchedulesFail = (err) => {
  return { type: types.DELETE_COMPETITION_SCHEDULE_FAIL, data: err };
};

function getStartStatisticsStart() {
  return {
    type: types.GET_START_STATISTICS_START,
  };
}

function getStartStatisticsSuccess({ data }) {
  return {
    type: types.GET_START_STATISTICS_SUCCESS,
    data: data,
  };
}

function getStartStatisticsFail() {
  return {
    type: types.GET_START_STATISTICS_FAIL,
  };
}

export function getStartStatistics(id) {
  return (dispatch) => {
    dispatch(getStartStatisticsStart());
    apiClient
      .getCompetitionApi()
      .competitionApiGetCompetitionStartStatistics(id)
      .then((res) => {
        dispatch(getStartStatisticsSuccess({ data: res.data }));
      })
      .catch(() => {
        dispatch(getStartStatisticsFail());
      });
  };
}

function getCompetitionDetailAnalyticsStart() {
  return {
    type: types.GET_COMPETITION_DETAIL_ANALYTICS_START,
  };
}

function getCompetitionDetailAnalyticsSuccess({ data }) {
  return {
    type: types.GET_COMPETITION_DETAIL_ANALYTICS_SUCCESS,
    data: data,
  };
}

function getCompetitionDetailAnalyticsFail() {
  return {
    type: types.GET_COMPETITION_DETAIL_ANALYTICS_FAIL,
  };
}

export function getCompetitionDetailAnalytics(id) {
  return (dispatch) => {
    dispatch(getCompetitionDetailAnalyticsStart());
    apiClient
      .getCompetitionApi()
      .competitionApiCompetitionAnalytics(id)
      .then((res) => {
        dispatch(getCompetitionDetailAnalyticsSuccess({ data: res.data }));
      })
      .catch(() => {
        dispatch(getCompetitionDetailAnalyticsFail());
      });
  };
}

function uploadCompetitionImgStart() {
  return {
    type: types.UPLOAD_COMPETITION_IMG_START,
  };
}

function uploadCompetitionImgSuccess(data) {
  return {
    type: types.UPLOAD_COMPETITION_IMG_SUCCESS,
    data,
  };
}
function uploadCompetitionHeaderSuccess(data) {
  return {
    type: types.UPLOAD_COMPETITION_HEADER_SUCCESS,
    data,
  };
}

function uploadCompetitionImgFail(err) {
  return {
    type: types.UPLOAD_COMPETITION_IMG_FAIL,
    data: err,
  };
}

export function uploadCompetitionImg(competitionName, data) {
  return (dispatch, getState) => {
    dispatch(uploadCompetitionImgStart());
    const { auth } = getState();
    axios
      .put(
        `${config.API_URL}/competition/${competitionName}/upload-img`,
        data,
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((res) => {
        if (res.data.image_url.includes('thumbname')) {
          dispatch(uploadCompetitionImgSuccess(res.data));
        } else {
          dispatch(uploadCompetitionHeaderSuccess(res.data));
        }
      })
      .catch(() => {
        dispatch(uploadCompetitionImgFail('Failed to get Test'));
      });
  };
}

export function getCompetitionListStart() {
  return {
    type: types.GET_COMPETITION_LIST_START,
  };
}

export function getCompetitionListSuccess({ data }) {
  return {
    type: GET_COMPETITION_LIST_SUCCESS,
    data,
  };
}

export function getCompetitionListFail({ data }) {
  return {
    type: GET_COMPETITION_LIST_FAIL,
    data,
  };
}

export function getCompetitionList({
  name = '',
  startDate = '',
  endDate = '',
  pageNumber = 1,
  pageSize = 10,
}) {
  return (dispatch) => {
    dispatch(getCompetitionListStart());
    apiClient
      .getCompetitionApi()
      .competitionApiGetCompetitionList(
        endDate,
        name,
        undefined,
        pageNumber,
        pageSize,
        startDate,
      )
      .then((res) => {
        dispatch(getCompetitionListSuccess({ data: res.data }));
      })
      .catch((error) => {
        dispatch(getCompetitionListFail(error));
      });
  };
}
