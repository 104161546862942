import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import QuestionCardComponent from '../QuestionCard/QuestionCard';
import {
  hintVisibility,
  solutionVisibility,
} from '../../constants/questioncard';
import { withRouter } from '../../routes';
import DiscussionPane from '../Discussion/DiscussionPane';

const QuestionCard = (props) => {
  useEffect(() => {
    const { getAnsweredQuestionById, params } = props;
    const { attemptId } = params;

    getAnsweredQuestionById({ id: attemptId });
  }, []);

  const getAttempt = () => {
    const { user } = props;
    const { attemptedQuestion } = user;

    return attemptedQuestion;
  };

  const attemptedQuestion = getAttempt();
  const questionId2 = props.user.attemptedQuestion.question?.id;

  if (!attemptedQuestion || !Object.keys(attemptedQuestion).length) {
    return null;
  }
  const questionId = props.location?.state?.questionId;
  const { putBookmark, tag, unlockSolution } = props;
  const { question } = attemptedQuestion;

  return (
    <div
      className="user-profile-edit-container-left"
      style={{ padding: '0px 20px' }}
    >
      <Paper className="user-wrapper">
        <QuestionCardComponent
          {...props}
          showHint={hintVisibility.history}
          showSolution={solutionVisibility.history}
          question={question}
          tag={tag}
          tagList={tag.list}
          isSolutionCollapse={false}
          isQuestionSelected={false}
          isQuestionPublished={true}
          putBookmark={putBookmark}
          questionId={questionId}
          unlockSolution={unlockSolution}
          showBookmarkLikeDisLike={true}
          timeTaken={
            attemptedQuestion.seconds_taken
              ? `${attemptedQuestion.seconds_taken} sec`
              : 'N/A'
          }
          attemptedQuestion={attemptedQuestion}
        />
      </Paper>
      {questionId2 && <DiscussionPane questionId={questionId2} />}
    </div>
  );
};

export default withRouter(QuestionCard);
