import * as React from 'react';
import { TextField, InputAdornment, MenuItem, Select } from '@mui/material/';
import { phoneCodes } from '../../constants/phoneCodes';

const PhoneNumberInput = ({
  label,
  margin = 'normal',
  disabled = false,
  value,
  onChange,
  variant,
  endIcon,
}) => {
  const phone_number_code =
    (value.toString() || '').split('-')?.[0] || phoneCodes[84][3];
  const number = (value.toString() || '').split('-')?.[1] || '';

  let pinCodesList = phoneCodes
    .map((code) => {
      return code[3];
    })
    .sort((a, b) => a - b);
  pinCodesList = [...new Set(pinCodesList)];

  return (
    <TextField
      variant={variant}
      label={label}
      disabled={disabled}
      onChange={(event) =>
        onChange(
          `${phone_number_code}-${event.target.value.replace(/[^0-9]/g, '')}`,
        )
      }
      value={number}
      margin={margin}
      className="text-input"
      fullWidth
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: '10.5px 14px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              label="Country Code"
              autoWidth
              disabled={disabled}
              onChange={(event) => onChange(`${event.target.value}-${number}`)}
              value={phone_number_code}
              disableUnderline
              defaultValue={'91'}
              sx={{
                '& fieldset': {
                  border: 'none',
                },
              }}
            >
              {pinCodesList.map((code) => (
                <MenuItem key={code} value={code}>{`+${code}`}</MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
        endAdornment: endIcon,
      }}
    />
  );
};

export default PhoneNumberInput;
