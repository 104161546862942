import { Box, Container } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledContainer = styled(Container)({
  display: 'flex !important',
  flexDirection: 'column',
});

export const StyledCategory = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .header': {
    marginBottom: '20px',
    fontSize: '22px',
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  '& .search-text-field': {
    backgroundColor: 'white',
    borderRadius: '7px',
    height: 'fit-content',
    '& input': {
      padding: '12.5px 14px',
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .list': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridGap: '10px',
  },
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  width: '340px',
  height: 'fit-content',
  borderRadius: '10px',
  backgroundColor: 'white',
  padding: '20px 15px',

  '& .header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',

    '& .image': {
      height: '50px',
      width: '50px',
      backgroundColor: theme.palette.background.main,
      borderRadius: '5px',
    },

    '& .details': {
      width: 'calc(100% - 50px)',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  '& .topicsList': {
    marginTop: '8px',

    '& .listCtn': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '5px',
      overflow: 'auto',
      maxHeight: '58px',

      '& .tag': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.background,
        borderRadius: '5px',
        fontWeight: '500',
        padding: '0px 5px',
      },
    },
  },

  '& .statsCtn': {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '22px',

    '& .questions': {
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.background,
      width: '30%',
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .avatar': {
        height: '40px',
        width: '100%',
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.background.main,
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingBottom: '7px',
        justifyContent: 'center',
      },
    },

    '& .score': {
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.background,
      width: '30%',
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .avatar': {
        height: '40px',
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    '& .badges': {
      padding: '10px',
      paddingTop: '3px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.background,
      width: '30%',
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .avatar': {
        height: '50px',
        width: '100%',
        borderRadius: '3px',
        backgroundPosition: 'center',
        backgroundSize: '80%',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },

  '& .otherDetails': {
    marginTop: '15px',

    '& .streak-spree': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '5px',
    },

    '& .Stat': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '5px',
      width: '90%',
      marginBottom: '8px',

      '& .progressCtn': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '2.5px',

        '& .current': {
          height: '25px',
          width: '25px',
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .target': {
          height: '25px',
          width: '25px',
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .currentSpree': {
          height: '25px',
          width: '25px',
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .targetSpree': {
          height: '25px',
          width: '25px',
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .progress': {
          width: 'calc(100% - 55px)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '4px',

          '& .dotFilled': {
            width: '100%',
            height: '7px',
            borderRadius: '30px',
            backgroundColor: theme.palette.common.success,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },

          '& .dot': {
            width: '100%',
            height: '7px',
            borderRadius: '30px',
            backgroundColor: theme.palette.primary.background,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },

        '& .progressSpree': {
          width: 'calc(100% - 55px)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '2px',

          '& .dotFilled': {
            width: '100%',
            height: '7px',
            borderRadius: '30px',
            backgroundColor: theme.palette.playColors.purple,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },

          '& .dot': {
            width: '100%',
            height: '7px',
            borderRadius: '30px',
            backgroundColor: theme.palette.secondary.color,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
    },
  },

  '& .triggerCtn': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '2px',
  },
}));

export const StyledQuestionLayout = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 0.9fr',
  height: '70vh',

  '& .left': {
    backgroundColor: theme.palette.background.main,
    padding: '15px',

    '& .header': {
      height: '29px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .d1': {
        height: '27px',
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& .s1, .s2': {
          height: '100%',
          width: '10%',
          backgroundColor: theme.palette.background.main,
        },

        '& .s2': {
          width: '30%',
          marginLeft: '20px',
        },
      },

      '& .d2': {
        height: '27px',
        width: '50%',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',

        '& .s3': {
          height: '100%',
          width: '20%',
          backgroundColor: theme.palette.background.main,
          marginLeft: '20px',
        },
      },
    },

    '& .subHeader': {
      width: '100%',
      height: '35px',
      backgroundColor: theme.palette.background.main,
      marginTop: '30px',
      borderRadius: '30px',
    },

    '& .main': {
      width: '100%',
      padding: '15px',

      '& .d1, .d2, .d3, .d4, .d5, .d6': {
        height: '27px',
        backgroundColor: theme.palette.background.main,
        marginTop: '13px',
      },

      '& .d1': {
        width: '30%',
      },

      '& .d2': {
        width: '45%',
      },

      '& .d3': {
        width: '60%',
      },

      '& .d4': {
        width: '20%',
      },

      '& .d5': {
        height: '31px',
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '7%',

        '& .s1, .s2': {
          height: '100%',
          width: '50%',
          backgroundColor: theme.palette.background.main,
        },
      },

      '& .d6': {
        height: '27px',
        width: '30%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '3%',

        '& .s1, .s2, .s3': {
          height: '100%',
          backgroundColor: theme.palette.background.main,
        },

        '& .s1': {
          width: '15%',
        },

        '& .s2': {
          width: '10%',
        },

        '& .s3': {
          width: '60%',
        },
      },
    },
  },

  '& .right': {
    backgroundColor: 'white',
    padding: '15px',
    paddingTop: '80px',

    '& div': {
      height: '27px',
      width: '100%',
      backgroundColor: theme.palette.background.main,
      marginBottom: '13px',
    },

    '& .diff': {
      width: '60%',
    },

    '& span': {
      display: 'block',
      height: '27px',
      width: '20%',
      backgroundColor: theme.palette.background.main,
      borderRadius: '20px',
    },
  },

  '& .headerM, .subHeaderM, .mainM': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',

    '& .right, .left': {
      display: 'none',
    },

    '& .headerM': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '8px',
      width: '100%',
      gap: '8px',

      '& .d1': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '20px',

        '& .s1, .s2, .s3, .s4': {
          height: '100%',
          backgroundColor: theme.palette.background.main,
        },

        '& .s2': {
          width: '17%',
        },

        '& .s3': {
          width: '26%',
        },

        '& .s4': {
          width: '30%',
        },
      },
    },

    '& .subHeaderM': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '8px',
      paddingBottom: '16px',
      width: '100%',

      '& .s1, .s2': {
        height: '20px',
        width: '30%',
        backgroundColor: theme.palette.background.main,
      },
    },

    '& .mainM': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '8px',
      paddingTop: '12px',
      width: '100%',
      gap: '8px',

      '& .d1': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '5px',
        width: '100%',

        '& .s1': {
          height: '20px',
          width: '89%',
          backgroundColor: theme.palette.background.main,
        },

        '& .s2': {
          height: '20px',
          width: '74%',
          backgroundColor: theme.palette.background.main,
        },
      },

      '& .d2': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '5px',
        width: '100%',

        '& div': {
          height: '20px',
          width: '40%',
          backgroundColor: theme.palette.background.main,
          marginTop: '15px',
        },
      },

      '& .d3': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '5px',
        width: '100%',
        marginTop: '35px',

        '& .s1': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '50%',
          height: '40px',
          gap: '5px',

          '& div': {
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.background.main,
          },
        },

        '& .s2': {
          height: '40px',
          width: '20%',
          backgroundColor: theme.palette.background.main,
        },
      },

      '& .d4': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '5px',
        width: '100%',
        marginTop: '35px',

        '& .s1': {
          height: '30px',
          width: '60%',
          backgroundColor: theme.palette.background.main,
        },

        '& .s2': {
          height: '30px',
          width: '20%',
          backgroundColor: theme.palette.background.main,
        },
      },
    },
  },
}));
