import React from 'react';
import { Box, Typography } from '@mui/material';
import CalenderYear from './CalenderYear';

const Activity = ({ overallYearStats, userStats, user }) => {
  return (
    <section>
      <Box className="" sx={{ marginBottom: '10px' }}>
        <Typography
          sx={{ fontSize: '1.4rem', fontWeight: '900', color: '#25507B' }}
        >
          Hello, {user ? user?.first_name : 'User'}
        </Typography>
        <Typography sx={{ fontSize: '14px', color: '#25507B' }}>
          Welcome back! Let&apos;s pick up where you left off and conquer
          today&apos;s practice goals together!
        </Typography>
      </Box>

      <Box>
        <Typography style={{ color: 'black', margin: '10px 0' }}>
          {userStats?.question_attempts_in_last_year || 0} Question practiced in
          the last year.
        </Typography>
        <CalenderYear overallYearStats={overallYearStats} />
      </Box>
    </section>
  );
};

export default Activity;
