import React from 'react';
import { Grid, Typography } from '@mui/material';
import ExclamationIcon from './images/exclamation.svg';
import QuestionDetails from './QuestionDetails';
import QuestionBox from './QuestionBox';

import AllReasons from '../../constants/voting';
import { ReasonTypeContainer, StyleQuestionContainer } from './styles';

const QuestionContainer = ({ data }) => {
  if (!data.id) return null;
  return (
    <StyleQuestionContainer width={'100%'}>
      <ReasonTypeContainer>
        <img src={ExclamationIcon} alt="icon" width={'20px'} />
        <Typography
          sx={{
            color: '#D60505',
            fontWeight: '600',
            fontSize: '16px',
          }}
        >
          {AllReasons[data?.full_report?.reason_type]?.title}
        </Typography>
      </ReasonTypeContainer>
      <Grid
        p="20px"
        my="15px"
        sx={{
          background: 'white',
          borderRadius: '5px',
          display: 'flex',
          gap: '20px',
          flexGrow: '1',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <QuestionBox
          data={data}
          reason={AllReasons[data?.full_report?.reason_type]}
        />
        <QuestionDetails data={data} />
      </Grid>
    </StyleQuestionContainer>
  );
};

export default QuestionContainer;
