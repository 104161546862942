import {
  DialogContent,
  Box,
  Typography,
  Divider,
  Button,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  TextField,
  ButtonGroup,
  IconButton,
  Grid,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { CallAttemptFieldHeading } from '../SalesStyledComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMailSentLog,
  getSalesLeadById,
  putSalesLead,
  sendSignupMail,
} from '../../../actions/salesDashboard';
import MessageBar from '../../MessageBar';
import moment from 'moment';

const EmailAttempts = ({ salesLead }) => {
  const dispatch = useDispatch();
  const { isMailSent, emailAttemptData, salesLeadById } = useSelector(
    (state) => state.SalesDashboard,
  );
  const [openMessageBar, setOpenMessageBar] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditing, setIsEditing] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch(getSalesLeadById(salesLead.id));
  }, [salesLead.id]);

  useEffect(() => {
    if (isMailSent) {
      setOpenMessageBar(true);
    }
  }, [isMailSent]);

  useEffect(() => {
    if (salesLeadById.email) {
      dispatch(
        getMailSentLog(
          salesLeadById?.email,
          page + 1,
          rowsPerPage,
          salesLeadById?.id,
        ),
      );
    }
  }, [page, rowsPerPage, salesLeadById.email]);

  const handleSendEmail = () => {
    dispatch(sendSignupMail(salesLeadById?.id, salesLeadById?.email));
  };

  const editableTableLead = () => {
    dispatch(
      putSalesLead(
        salesLeadById?.id,
        { email: email },
        'emailAttempsPage',
        undefined,
      ),
    );
    setIsEditing(false);
  };

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  return (
    <DialogContent>
      <Box
        display={'flex'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
      >
        <CallAttemptFieldHeading variant="h6">
          {' '}
          <Typography color="#25507B" variant="body">
            Name:&nbsp;
          </Typography>
          {salesLeadById?.name}
        </CallAttemptFieldHeading>

        {isEditing ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={'Field cannot be empty'}
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <ButtonGroup>
                  <IconButton
                    disabled={email ? false : true}
                    onClick={editableTableLead}
                    size="large"
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsEditing(false);
                    }}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CallAttemptFieldHeading
            variant="h6"
            onClick={() => {
              setIsEditing(true);
              setEmail(salesLeadById?.email);
            }}
          >
            <Typography color="#25507B" variant="body">
              Contact:&nbsp;
            </Typography>{' '}
            {salesLeadById?.email}
            <EditIcon fontSize="small" />
          </CallAttemptFieldHeading>
        )}
        <Button variant="contained" onClick={handleSendEmail}>
          Send Email
        </Button>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ overflow: 'auto', height: '60vh' }}>
        {emailAttemptData?.mail_list?.length === 0 ? (
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            There are no previous Email attempts, you send one now.
          </Typography>
        ) : (
          <Table style={{ backgroundColor: 'white' }}>
            <TableHead>
              <TableRow>
                <TableCell>Recipient</TableCell>
                <TableCell> Email Time</TableCell>
                <TableCell>Attchments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {emailAttemptData?.mail_list?.map((attempt) => (
                  <TableRow key={attempt.id}>
                    <TableCell style={{ cursor: 'pointer' }}>
                      <div className="sales__list__name">
                        {attempt?.recipient}
                      </div>
                    </TableCell>
                    <TableCell style={{ cursor: 'pointer' }}>
                      <div className="sales__list__name">
                        <Tooltip
                          title={
                            <Box sx={{ padding: '10px', color: 'white' }}>
                              <Typography variant="h6">
                                {moment
                                  .utc(attempt?.created_at)
                                  .local()
                                  .format('DD/MM/YYYY, hh:mm a')}
                              </Typography>
                            </Box>
                          }
                          placement="left"
                        >
                          {moment.utc(attempt?.created_at).fromNow()}
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell style={{ cursor: 'pointer' }}>
                      <div className="sales__list__name">
                        {attempt.attchment_url || 'NA'}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
          </Table>
        )}
      </Box>
      <TablePagination
        component="nav"
        page={page}
        rowsPerPage={rowsPerPage}
        count={emailAttemptData.total_pages * emailAttemptData.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
        className="scoreboard__navigation"
      />
      <MessageBar
        type="success"
        open={openMessageBar}
        onClose={() => setOpenMessageBar(false)}
        message={'Email has been Sent'}
      />
    </DialogContent>
  );
};

export default EmailAttempts;
