import {
  CLEAR_EMAILING_LIST_STATE,
  CREATE_EMAIL_TEMPLATE_FAIL,
  CREATE_EMAIL_TEMPLATE_START,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_FAIL,
  DELETE_EMAIL_TEMPLATE_START,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_BY_ID_FAIL,
  GET_EMAIL_TEMPLATE_BY_ID_START,
  GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
  GET_EMAIL_TEMPLATE_LIST_FAIL,
  GET_EMAIL_TEMPLATE_LIST_START,
  GET_EMAIL_TEMPLATE_LIST_SUCCESS,
  REMOVE_EMAILING_LIST_ATTACHMENT,
  SEND_EMAIL_FAIL,
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  UPDATE_EMAIL_TEMPLATE_START,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
} from './types';
import * as types from './types';
import apiClient from '../api/apiclient';

export const getEmailTemplateListStart = () => ({
  type: GET_EMAIL_TEMPLATE_LIST_START,
});

export const getEmailTemplateListSuccess = ({ data }) => ({
  type: GET_EMAIL_TEMPLATE_LIST_SUCCESS,
  data,
});

export const getEmailTemplateListFail = ({ data }) => ({
  type: GET_EMAIL_TEMPLATE_LIST_FAIL,
  data,
});

export const getEmailTemplateList = () => async (dispatch) => {
  dispatch(getEmailTemplateListStart());
  try {
    const res = await apiClient.getMailApi().mailApiGetAllTemplates();
    dispatch(
      getEmailTemplateListSuccess({
        data: res.data,
      }),
    );
  } catch (error) {
    dispatch(
      getEmailTemplateListFail({
        data: error.data,
      }),
    );
  }
};

export const getEmailTemplateByIdStart = () => ({
  type: GET_EMAIL_TEMPLATE_BY_ID_START,
});

export const getEmailTemplateByIdSuccess = ({ data }) => ({
  type: GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
  data,
});

export const getEmailTemplateByIdFail = ({ data }) => ({
  type: GET_EMAIL_TEMPLATE_BY_ID_FAIL,
  data,
});

export const getEmailTemplateById = (templateId) => async (dispatch) => {
  dispatch(getEmailTemplateByIdStart());
  try {
    const res = await apiClient.getMailApi().mailApiGetTemplateById(templateId);
    dispatch(
      getEmailTemplateByIdSuccess({
        data: res.data,
      }),
    );
  } catch (error) {
    dispatch(
      getEmailTemplateByIdFail({
        data: error.data,
      }),
    );
  }
};

export const postEmailAttachmentStart = () => ({
  type: types.POST_EMAIL_ATTACHMENT_START,
});

export const postEmailAttachmentSuccess = ({ data }) => ({
  type: types.POST_EMAIL_ATTACHMENT_SUCCESS,
  data,
});

export const postEmailAttachmentFail = ({ data }) => ({
  type: types.POST_EMAIL_ATTACHMENT_FAIL,
  data,
});

export const postEmailAttachment = (file, successCallback) => async (
  dispatch,
) => {
  dispatch(postEmailAttachmentStart());
  try {
    const res = await apiClient
      .getFilesApi()
      .filesApiUploadAttachmentToBucket(file);
    dispatch(postEmailAttachmentSuccess({ data: res.data }));
    successCallback?.();
  } catch (error) {
    dispatch(
      postEmailAttachmentFail({
        data: error,
      }),
    );
  }
};

export const removeAttachment = (attachmentType, index) => ({
  type: REMOVE_EMAILING_LIST_ATTACHMENT,
  data: {
    index,
    attachmentType,
  },
});

export const createEmailTemplateStart = () => ({
  type: CREATE_EMAIL_TEMPLATE_START,
});

export const createEmailTemplateSuccess = ({ data }) => ({
  type: CREATE_EMAIL_TEMPLATE_SUCCESS,
  data,
});

export const createEmailTemplateFail = ({ data }) => ({
  type: CREATE_EMAIL_TEMPLATE_FAIL,
  data,
});

export const createEmailTemplate = (
  { templateName, subject, templateBody, templateArgs, attachmentUrls },
  successCallback,
) => async (dispatch) => {
  dispatch(createEmailTemplateStart());
  try {
    const res = await apiClient.getMailApi().mailApiCreateTemplate({
      template_name: templateName,
      subject: subject,
      template_body: templateBody,
      template_args: templateArgs,
      attachment_urls: attachmentUrls,
    });
    dispatch(
      createEmailTemplateSuccess({
        data: { id: res.data.id, template_name: templateName },
      }),
    );
    successCallback?.(res.data.id);
  } catch (error) {
    dispatch(
      createEmailTemplateFail({
        data: error,
      }),
    );
  }
};

export const updateEmailTemplateStart = () => ({
  type: UPDATE_EMAIL_TEMPLATE_START,
});

export const updateEmailTemplateSuccess = ({ data }) => ({
  type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
  data,
});

export const updateEmailTemplateFail = ({ data }) => ({
  type: UPDATE_EMAIL_TEMPLATE_FAIL,
  data,
});

export const updateEmailTemplate = (
  {
    newTemplateName,
    subject,
    body,
    templateArguments,
    attachmentUrls,
    templateId,
  },
  successCallback,
) => async (dispatch) => {
  dispatch(updateEmailTemplateStart());

  try {
    const res = await apiClient.getMailApi().mailApiUpdateTemplate(templateId, {
      template_name: newTemplateName,
      subject: subject,
      template_body: body,
      template_args: templateArguments,
      attachment_urls: attachmentUrls,
    });
    dispatch(
      updateEmailTemplateSuccess({
        data: res.data,
      }),
    );
    successCallback?.(res.data.id);
  } catch (error) {
    dispatch(
      updateEmailTemplateFail({
        data: error,
      }),
    );
  }
};

export const deleteEmailTemplateStart = () => ({
  type: DELETE_EMAIL_TEMPLATE_START,
});

export const deleteEmailTemplateSuccess = ({ data }) => ({
  type: DELETE_EMAIL_TEMPLATE_SUCCESS,
  data,
});

export const deleteEmailTemplateFail = ({ data }) => ({
  type: DELETE_EMAIL_TEMPLATE_FAIL,
  data,
});

export const deleteEmailTemplate = (templateId, successCallback) => async (
  dispatch,
) => {
  dispatch(deleteEmailTemplateStart());

  try {
    await apiClient.getMailApi().mailApiDeleteTemplateById(templateId);
    dispatch(deleteEmailTemplateSuccess({ data: { templateId } }));
    successCallback?.();
  } catch (error) {
    dispatch(
      deleteEmailTemplateFail({
        data: error,
      }),
    );
  }
};

export const sendEmailStart = () => ({
  type: SEND_EMAIL_START,
});

export const sendEmailSuccess = ({ data }) => ({
  type: SEND_EMAIL_SUCCESS,
  data,
});

export const sendEmailFail = ({ data }) => ({
  type: SEND_EMAIL_FAIL,
  data,
});

export const sendEmail = (data, successCallback, failCallback) => async (
  dispatch,
) => {
  dispatch(sendEmailStart());
  try {
    const res = await apiClient.getMailApi().mailApiSendEmailApi({
      subject: data?.subject,
      custom_template: data?.body,
      email_list: data?.emailList,
      domain_id: data?.selectedDomain,
      competition_id: data?.competitionId,
      from_email: data?.fromEmail,
      new_user_only: data?.new_user_only,
      existing_users_only: data?.existing_users_only,
      scheduled_datetime: data?.schedule,
      exclude_hours: data?.excludeHours,
      password: data.password,
      attachment_s3_urls: data?.urls,
      template_args: data?.templateArguments,
    });
    dispatch(
      sendEmailSuccess({
        data: res.data,
      }),
    );
    successCallback?.();
  } catch (error) {
    dispatch(
      sendEmailFail({
        data: error,
      }),
    );
    if (error?.response?.data?.error === 'wrong_password') {
      failCallback?.('Wrong Password');
    } else {
      failCallback?.('Failed');
    }
  }
};

export const clearEmailingListState = () => ({
  type: CLEAR_EMAILING_LIST_STATE,
});

export const getScheduledEmailsStart = () => ({
  type: types.GET_SCHEDULED_EMAILS_LIST_START,
});

export const getScheduledEmailsSuccess = ({ data }) => ({
  type: types.GET_SCHEDULED_EMAILS_LIST_SUCCESS,
  data,
});

export const getScheduledEmailsFail = () => ({
  type: types.GET_SCHEDULED_EMAILS_LIST_FAIL,
});

export const getScheduledEmails = (
  pageNo,
  pageSize,
  sender,
  recipient,
  startDate,
  endDate,
  selectedTemplate,
) => async (dispatch) => {
  dispatch(getScheduledEmailsStart());
  try {
    const res = await apiClient
      .getMailApi()
      .mailApiGetScheduledMails(
        endDate,
        startDate,
        pageNo,
        pageSize,
        recipient,
        sender,
        selectedTemplate,
        undefined,
      );
    dispatch(
      getScheduledEmailsSuccess({
        data: res.data,
      }),
    );
  } catch (error) {
    dispatch(getScheduledEmailsFail());
  }
};
export const getEmailLogsStart = () => ({
  type: types.GET_EMAIL_LOGS_LIST_START,
});

export const getEmailLogsSuccess = ({ data }) => ({
  type: types.GET_EMAIL_LOGS_LIST_SUCCESS,
  data,
});

export const getEmailLogsFail = () => ({
  type: types.GET_EMAIL_LOGS_LIST_FAIL,
});

export const getEmailLogs = (
  pageNo,
  pageSize,
  sender,
  recepient,
  startDate,
  endDate,
  selectedTemplate,
) => async (dispatch) => {
  dispatch(getEmailLogsStart());
  try {
    const res = await apiClient
      .getMailApi()
      .mailApiGetMailsLogs(
        endDate,
        startDate,
        pageNo,
        pageSize,
        recepient,
        sender,
        selectedTemplate,
        undefined,
      );
    dispatch(
      getEmailLogsSuccess({
        data: res.data,
      }),
    );
  } catch (error) {
    dispatch(getEmailLogsFail());
  }
};

export const deleteScheduledEmailsStart = () => ({
  type: types.DELETE_SCHEDULED_EMAILS_LIST_START,
});

export const deleteScheduledEmailsSuccess = () => ({
  type: types.DELETE_SCHEDULED_EMAILS_LIST_SUCCESS,
});

export const deleteScheduledEmailsFail = () => ({
  type: types.DELETE_SCHEDULED_EMAILS_LIST_FAIL,
});

export const deleteScheduledEmails = (
  scheduledMailId,
  type,
  page,
  rowsPerPage,
) => async (dispatch) => {
  dispatch(deleteScheduledEmailsStart());
  try {
    await apiClient.getMailApi().mailApiDeleteScheduledMail(scheduledMailId);
    dispatch(deleteScheduledEmailsSuccess());
    if (type === 'scheduled') {
      dispatch(getScheduledEmails(page, rowsPerPage, '', '', '', '', ''));
    } else {
      dispatch(getEmailLogs(page, rowsPerPage, '', '', '', '', ''));
    }
  } catch (error) {
    dispatch(deleteScheduledEmailsFail());
  }
};
