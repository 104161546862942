import { TextField, Button, Grid, Container } from '@mui/material';
import AsyncCreatableSelect from 'react-select/async-creatable';
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { useEffect } from 'react';
import {
  getBadgeType,
  postBadge,
  updateBadge,
  uploadBadgeImage,
} from '../../actions/badges';
import MessageBar from '../MessageBar';
import ImagePicker from '../ImagePicker';
import Select from 'react-select';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FieldItem,
  FormWrapper,
  TagsForm,
  FormControl,
  FormHeading,
} from './StyledComponents';

const CreateNewBadgeDialog = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const { selectedBadge } = state || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      gem_reward: 0,
      credit_reward: 0,
    },
  });

  const {
    isCreatingBadge,
    s3ImgUrl,
    isuploadingBadgeImg,
    isCreatingBadgeSuccess,
    isUpdatingBadgeSuccess,
    badgeTypedata,
  } = useSelector((state) => state.badges);
  const [openMessageBar, setOpenMessageBar] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (params.badgeId) {
      reset({
        ...selectedBadge,
        credit_reward: selectedBadge.credit_reward ?? 0,
        gem_reward: selectedBadge.gem_reward ?? 0,
      });
    }
  }, [params.badgeId]);
  useEffect(() => {
    dispatch(getBadgeType());
  }, []);
  useEffect(() => {
    setOptions(
      badgeTypedata?.badge_types?.map((value) => ({
        value,
        label: value,
      })),
    );
  }, [badgeTypedata]);
  const handleCreateOption = async (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setValue('type', newOption.value);
    setOptions((prevOptions) => [...prevOptions, newOption]);
  };

  const onSubmitHandler = (data) => {
    data.image_url = params.badgeId
      ? s3ImgUrl
        ? s3ImgUrl
        : selectedBadge.image_url
      : s3ImgUrl;
    if (params.badgeId) {
      dispatch(updateBadge(data));
    } else {
      dispatch(postBadge(data));
    }
    setOpenMessageBar(true);
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };
  const handleMessageClose = () => {
    setOpenMessageBar(false);
  };
  const handleSelectChange = (selectedOption) => {
    setValue('type', selectedOption.value);
  };
  const handleSegmentSelectChange = (selectedOption) => {
    setValue('segment', selectedOption.value);
  };

  const defaultValueType = {
    value: params.badgeId ? selectedBadge?.type : watch('type'),
    label: params.badgeId ? selectedBadge?.type : watch('type'),
  };

  const defaultValueSegment = {
    value: params.badgeId ? selectedBadge?.segment : watch('segment'),
    label: params.badgeId ? selectedBadge?.segment : watch('segment'),
  };

  const onFileLoad = (file) => {
    dispatch(uploadBadgeImage(file));
  };
  const segmentOptions = [
    { value: 'Competition', label: 'Competition' },
    { value: 'PractiseProfile', label: 'PractiseProfile' },
    { value: 'AuthorProfile', label: 'AuthorProfile' },
    { value: 'Domain', label: 'Domain' },
    { value: 'Overall', label: 'Overall' },
  ];
  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.badgeId ? 'Create Badge' : 'Edit Badge'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FormHeading>Name</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('name', {
                        required: 'Please enter name',
                      })}
                      name="name"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      placeholder="Name"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Description</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('description', {
                        required: 'Please enter description',
                      })}
                      name="description"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      placeholder="Description"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Type</FormHeading>
                  <FieldItem>
                    <AsyncCreatableSelect
                      name="type"
                      defaultOptions={options}
                      options={options}
                      onChange={handleSelectChange}
                      onCreateOption={handleCreateOption}
                      placeholder="type"
                      defaultValue={defaultValueType}
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Segment</FormHeading>
                  <FieldItem>
                    <Select
                      options={segmentOptions}
                      name="segment"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={handleSegmentSelectChange}
                      defaultValue={defaultValueSegment}
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Milestone No</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('milestone_no', {
                        required: 'Please enter milestone no',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Only numeric value is allowed',
                        },
                      })}
                      name="milestone_no"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.milestone_no}
                      helperText={errors.milestone_no?.message}
                      placeholder="Milestone No"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Gem Reward</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('gem_reward', {
                        required: 'Please enter gem reward',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Only numeric value is allowed',
                        },
                      })}
                      name="gem_reward"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.gem_reward}
                      helperText={errors.gem_reward?.message}
                      placeholder="Gem reward"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Credit Reward</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('credit_reward', {
                        required: 'Please enter credit reward',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Only numeric value is allowed',
                        },
                      })}
                      name="credit_reward"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.credit_reward}
                      helperText={errors.credit_reward?.message}
                      placeholder="Credit reward"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImagePicker
                        onFileLoad={onFileLoad}
                        imageUrl={
                          params.badgeId ? selectedBadge.image_url : s3ImgUrl
                        }
                      />
                    </div>
                  </FieldItem>
                </FormControl>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isCreatingBadge || isuploadingBadgeImg}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isCreatingBadge || isuploadingBadgeImg ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
      <MessageBar
        type={
          isCreatingBadgeSuccess || isUpdatingBadgeSuccess ? 'success' : 'error'
        }
        open={openMessageBar}
        onClose={handleMessageClose}
        message={
          isCreatingBadgeSuccess || isUpdatingBadgeSuccess
            ? isUpdatingBadgeSuccess
              ? 'Badge Updated Successfully, Redirecting...'
              : 'Badge Created Successfully, Redirecting...'
            : 'Error Occured'
        }
      />
    </Container>
  );
};

export default CreateNewBadgeDialog;
