import React from 'react';
import {
  BuyBadge,
  GoalsBadge,
  Header,
  StyleCreditsContainer,
  StyledMocktestContainer,
  StyledMocktestMainContainer,
} from './DashboardStyledComponent';
import lightImg from './images/idea.png';
import question from './images/Question.png';
import mode from './images/Mode.png';
import timer from './images/timer.png';
import credits from './images/credits.png';
import { Hidden } from '@mui/material';
import { getDifficultyLabel } from '../../constants/examDifficulty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ConsumeCreditModal from '../ConsumeCreditModal';

function getDurationRange(totalQuestions) {
  return `${parseInt(totalQuestions * 0.5)} - ${parseInt(
    totalQuestions * 1.5,
  )}`;
}

const MockTest = ({ mocktestData, allTags }) => {
  const [mockTest, setMocktest] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleBuyTest = (data) => {
    setMocktest(data);
    setOpen(true);
  };

  const onBuyTest = (data) => {
    navigate('/l/mockTest?tab=allTest', {
      state: {
        dashboardData: data,
        from: '/l/dashbaord',
        is_unlocked: data?.is_unlocked,
      },
    });
  };

  return (
    <>
      <main>
        <Hidden smUp>
          <Header>MockTest</Header>
        </Hidden>
        <StyledMocktestMainContainer>
          {mocktestData ? (
            mocktestData.map((data, index) => {
              const tags = allTags
                ? allTags.filter((tag) => data?.tag_array?.includes(tag.id))
                : [];
              return (
                <StyledMocktestContainer key={index}>
                  <section className="" style={{ width: '12%' }}>
                    <img
                      src={data?.image_url || lightImg}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '100%',
                      }}
                    />
                  </section>
                  <section style={{ width: '73%' }}>
                    <Header style={{ height: '25%' }}>{data?.name}</Header>
                    <div style={{ height: '57%' }}>
                      <div
                        style={{
                          display: 'flex',
                          height: '100%',
                          flexWrap: 'wrap',
                          gap: '4px 5px',
                          marginTop: '5px',
                        }}
                      >
                        {tags?.slice(0, 3)?.map((tag, index) => (
                          <div key={index}>
                            <GoalsBadge
                              style={{
                                backgroundColor: '#25507B1A',
                                border: 'none',
                                fontSize: '10px',
                              }}
                            >
                              {tag?.name}
                            </GoalsBadge>
                          </div>
                        ))}
                        {tags?.length > 3 && (
                          <div>
                            <GoalsBadge
                              style={{
                                backgroundColor: '#25507B1A',
                                border: 'none',
                                marginRight: '2px',
                                fontSize: '10px',
                              }}
                            >
                              +{tags?.length - 3} more
                            </GoalsBadge>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '2px 5px',
                        fontSize: '12px',
                        height: '17%',
                        marginTop: '-10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={question}
                          style={{ width: '15px', height: '15px' }}
                          alt=""
                        />
                        <p style={{ fontSize: '10px' }}>
                          {data?.no_questions || 0}
                          <Hidden smDown> Question</Hidden>
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={timer}
                          style={{ width: '15px', height: '15px' }}
                          alt=""
                        />
                        <p style={{ fontSize: '10px' }}>
                          {getDurationRange(data?.no_questions) || 0} Minutes
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '2px',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={mode}
                          style={{ width: '15px', height: '15px' }}
                          alt=""
                        />
                        <p style={{ fontSize: '10px' }}>
                          {data?.exam_mode || 'Classic'}
                        </p>
                      </div>
                    </div>
                  </section>
                  <section style={{ width: '15%' }}>
                    <GoalsBadge
                      style={{
                        border: '1px solid red',
                        color: 'red',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        padding: '2px 18px',
                        fontSize: '10px',
                        borderRadius: '12.5px 10px 5px 12.5px',
                      }}
                    >
                      {getDifficultyLabel(data?.difficulty_level) || 'Balanced'}
                    </GoalsBadge>
                    <StyleCreditsContainer>
                      <img
                        src={credits}
                        style={{ width: '15px', height: '15px' }}
                        alt=""
                      />
                      <p> {data?.cost_in_credits || 100}</p>
                    </StyleCreditsContainer>
                    <BuyBadge
                      onClick={() => handleBuyTest(data)}
                      style={{ cursor: 'pointer' }}
                    >
                      {data?.is_unlocked ? 'Buy Test' : 'Unlock Now'}
                    </BuyBadge>
                  </section>
                </StyledMocktestContainer>
              );
            })
          ) : (
            <p>No data to show</p>
          )}
        </StyledMocktestMainContainer>
      </main>
      <ConsumeCreditModal
        open={open}
        onClose={() => setOpen(false)}
        title="Buy Mock Test"
        credits={mockTest?.cost_in_credits}
        message="Are you sure want to buy the mock test?"
        onYes={() => onBuyTest(mockTest)}
      />
    </>
  );
};

export default MockTest;
