import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import Component from './../components/MockTestCreate';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    mockTest: state.mockTest,
    user: state.user,
    tagList: state.tag.list,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
