import {
  Chip,
  IconButton,
  InputAdornment,
  Grid,
  Checkbox,
  Box,
  Hidden,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import RadioButton from '@mui/material/Radio';
import RadioButtonGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ReportIcon from '@mui/icons-material/Report';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import LikeDislike from './../../containers/Like_DislikeContainer';
import LockIcon from './images/lock-icon.svg';
import HintIcon from './images/hint-icon.svg';
import BookmarkQuestion from './BookmarkQuestion';
import { useState } from 'react';
import { useEffect } from 'react';
import { ParentContainer } from './PlayAnswerStyledComponent';
import author from './images/author.svg';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing(2),
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: '#c000',
    },
  },
});
const randomText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

const PlayAnswer = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(
    props.question?.attempt_details?.attempted_answer_option_no || [],
  );
  const [answerText, setAnswerText] = useState(
    props.question?.attempt_details?.attempted_answer_one_word || '',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newState, setNewState] = useState({});
  const [solutionratingValue, setSolutionratingValue] = useState(0);

  useEffect(() => {
    updateQuestionAttempt();
  }, []);

  useEffect(() => {
    setNewState({ selectedOptions: [], answerText: '', isSubmitting: false });

    if (Object.keys(newState)?.length) {
      setNewState(newState);
      updateQuestionAttempt();
    }
  }, [props.question?.data?.id]);

  const onRadioButtonChange = (event) => {
    setSelectedOptions([parseInt(event.target.value)]);
  };

  const onCheckboxClick = (event) => {
    const value = parseInt(event.target.value);
    if (selectedOptions?.includes(value)) {
      setSelectedOptions((options) => options?.filter((opt) => opt !== value));
    } else {
      setSelectedOptions((options) => [...(options || []), value]);
    }
  };

  const onTextFieldChange = (event) => {
    setAnswerText(event.target.value);
  };

  const updateQuestionAttempt = () => {
    const data = props.question?.data;
    const { attempt_details } = data;
    if (attempt_details) {
      if (data.question_type === 'OneWord') {
        setAnswerText(attempt_details.attempted_answer_one_word?.[0]);
      } else if (
        ['MultipleChoice', 'MultipleCorrect'].includes(data.question_type)
      ) {
        setSelectedOptions(attempt_details.attempted_answer_option_no);
      }
    }
  };

  const onSubmitClick = () => {
    const { question, onSubmit, isMockTest, mockTest } = props;
    // when user click on next button without any answer get next question
    if (
      isMockTest &&
      mockTest.data.exam_mode === 'flexible' &&
      !selectedOptions?.length &&
      !answerText
    )
      return props.onNext(true);

    let dataAnswer = {};
    if (
      ['MultipleChoice', 'MultipleCorrect'].includes(
        question.data.question_type,
      )
    ) {
      dataAnswer = {
        question_type: question.data.question_type,
        answer_option_no: selectedOptions,
        tag_id: question.data.tag_id,
      };
    } else if (question.data.question_type === 'OneWord') {
      dataAnswer = {
        question_type: 'OneWord',
        answer_one_word: answerText,
        tag_id: question.data.tag_id,
      };
    }
    setIsSubmitting(true);
    onSubmit(props.question.data.id, dataAnswer);
  };

  const {
    question,
    isAnswerSubmitted,
    isMockTest,
    mockTest,
    onNext,
    onSkip,
    isSolutionVisible,
    isHintVisible,
    onUnlockSolution,
    onReportQuestionClick,
    onHintClick,
    onEndTestClick,
  } = props;

  const parentQuestion = question.data?.parent_question;

  const data = question.data;
  const result = question.answerStatus;
  const isMockTestFlexibleMode =
    isMockTest && mockTest.data?.exam_mode === 'flexible';
  const unattendedQuestion = question.examAnalytics.questions?.find(
    (q) => q.status === 'unattempted',
  );

  const lastQuestion = question.examAnalytics?.questions?.at(-1) || null;

  let isLastQuestion =
    isMockTestFlexibleMode &&
    !unattendedQuestion &&
    data.id === lastQuestion?.id;

  const getRatingValueColor = (item) => {
    switch (item) {
      case 1:
        return '#D93551';
      case 2:
        return '#FA704E';
      case 3:
        return '#FDC50A';
      case 4:
        return '#34C177';
      case 5:
        return '#25A08B';
      default:
        return '#3572B01A';
    }
  };

  return (
    <ParentContainer>
      <div className="play-answer" key={question.id}>
        <div className="play-answer__question">
          {result && isAnswerSubmitted && !isMockTest && (
            <Box mb="15px">
              <Chip
                icon={
                  <Icon
                    style={{
                      color: result.timeout
                        ? '#EE9D00'
                        : result.question_skipped
                        ? '#21A9DE'
                        : result.is_attempt_correct
                        ? '#388E3C'
                        : '#D60505',
                      fontSize: 18,
                    }}
                  >
                    {result.timeout
                      ? 'av_timer'
                      : result.question_skipped
                      ? 'move_down'
                      : result.is_attempt_correct
                      ? 'check'
                      : 'clear'}
                  </Icon>
                }
                label={
                  result.timeout
                    ? 'Timed Out'
                    : result.question_skipped
                    ? 'Skipped'
                    : result.is_attempt_correct
                    ? 'Correct Answer'
                    : 'Incorrect Answer'
                }
                style={{
                  color: result.timeout
                    ? '#EE9D00'
                    : result.question_skipped
                    ? '#21A9DE'
                    : result.is_attempt_correct
                    ? '#388E3C'
                    : '#D60505',
                  backgroundColor: result.timeout
                    ? '#FDF5E5'
                    : result.question_skipped
                    ? '#F2FAFE'
                    : result.is_attempt_correct
                    ? '#EBF4EB'
                    : '#FBE6E6',
                  border: 'none',
                  fontWeight: 500,
                  margin: 5,
                  marginLeft: 0,
                }}
                variant="outlined"
              />
              <Chip
                label={
                  result.attempt_score > 0
                    ? `+${result.attempt_score}`
                    : result.attempt_score
                }
                style={{
                  color: result.timeout
                    ? '#EE9D00'
                    : result.question_skipped
                    ? '#21A9DE'
                    : result.is_attempt_correct
                    ? '#388E3C'
                    : '#D60505',
                  backgroundColor: result.timeout
                    ? '#FDF5E5'
                    : result.question_skipped
                    ? '#F2FAFE'
                    : result.is_attempt_correct
                    ? '#EBF4EB'
                    : '#FBE6E6',
                  border: 'none',
                  fontWeight: 500,
                  margin: 5,
                }}
                variant="outlined"
              />
            </Box>
          )}
          {parentQuestion && (
            <>
              <Typography
                variant="body2"
                gutterBottom
                color="primary"
                className={classnames({
                  'prevent-selection': !isAnswerSubmitted,
                })}
              >
                Comprehension
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: 15 }}
                className={classnames('question-text', {
                  'prevent-selection': !isAnswerSubmitted,
                })}
                color="black"
              >
                {ReactHtmlParser(parentQuestion.question_text)}
              </Typography>
            </>
          )}
          <Typography
            variant="body2"
            gutterBottom
            color="primary"
            className={classnames({
              'prevent-selection': !isAnswerSubmitted,
            })}
          >
            {data?.title}
          </Typography>
          {data?.title ? <Divider /> : null}
          {parentQuestion && (
            <Typography
              variant="h6"
              color="primary"
              style={{ fontSize: 16, marginTop: 10 }}
              className={classnames({
                'prevent-selection': !isAnswerSubmitted,
              })}
            >
              Question ({data.sub_question_number}/
              {data.parent_question.multipart_question_count})
            </Typography>
          )}

          {result?.timeout && parentQuestion?.question_type === 'MultiPart' && (
            <div className="timedOut">
              <div>
                <NotInterestedIcon htmlColor="red" />
                <span>Time-out Preview</span>
              </div>
              <p>
                This question is READ-ONLY and you can&apos;t attempt it as the
                timer has ended.
              </p>
              <Divider />
            </div>
          )}

          <div className="questionwithHint">
            <Typography
              variant="body2"
              className={classnames('question-text', {
                'prevent-selection': !isAnswerSubmitted,
              })}
              color="black"
            >
              {ReactHtmlParser(data.question_text)}
            </Typography>
            {data.is_hint_available && !isAnswerSubmitted && (
              <img
                src={HintIcon}
                disabled={isHintVisible}
                onClick={onHintClick}
                className="hintIcon"
              ></img>
            )}
          </div>
        </div>
        {['MultipleChoice', 'MultipleCorrect'].includes(data.question_type) ? (
          <Fragment>
            {result && isAnswerSubmitted && !isMockTest ? (
              <div className="play-answer__options">
                {data.options
                  .sort((a, b) => a.option_serial_no - b.option_serial_no)
                  .map((option, ind) => {
                    let optionLabel;
                    let optionClass;
                    if (
                      !result.is_attempt_correct &&
                      result.correct_options &&
                      selectedOptions?.includes(option.option_serial_no) &&
                      !result.correct_options.includes(option.option_serial_no)
                    ) {
                      optionLabel = <Icon style={{ fontSize: 18 }}>clear</Icon>;
                      optionClass = 'inactive modifiedInactive';
                    } else if (
                      result.correct_options?.includes(option.option_serial_no)
                    ) {
                      optionLabel = <Icon style={{ fontSize: 18 }}>check</Icon>;
                      optionClass = result.is_attempt_correct
                        ? 'active modifiedActive'
                        : 'active modifiedActiveIncorrect';
                    } else {
                      optionLabel = option.option_serial_no;
                    }

                    return (
                      <div
                        className={classnames('option', optionClass)}
                        key={ind}
                      >
                        <div className="option-label">{optionLabel}</div>
                        <div className="option-text">
                          {option.option_text?.split('\n').map((item, ind) => {
                            return (
                              <Typography
                                variant="body2"
                                className={classnames({
                                  'prevent-selection': !isAnswerSubmitted,
                                })}
                                key={ind}
                              >
                                {ReactHtmlParser(item)}
                              </Typography>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                {data.question_type === 'MultipleCorrect' && (
                  <Grid>
                    <Typography color="black">Correct Answers</Typography>
                    {data.options
                      .filter((opt) =>
                        result.correct_options.includes(opt.option_serial_no),
                      )
                      .map((option, ind) => {
                        let optionLabel = (
                          <Icon style={{ fontSize: 18 }}>check</Icon>
                        );
                        let optionClass = '';
                        return (
                          <div
                            className={classnames('option', optionClass)}
                            key={ind}
                          >
                            <div className="option-label correct-answers">
                              {optionLabel}
                            </div>
                            <div className="option-text">
                              {option.option_text
                                ?.split('\n')
                                .map((item, ind) => {
                                  return (
                                    <Typography
                                      variant="body2"
                                      className={classnames({
                                        'prevent-selection': !isAnswerSubmitted,
                                      })}
                                      key={ind}
                                    >
                                      {ReactHtmlParser(item)}
                                    </Typography>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </Grid>
                )}
              </div>
            ) : (
              <div className="play-answer__options">
                {data.question_type === 'MultipleChoice' ? (
                  <RadioButtonGroup
                    name="options"
                    disabled={isAnswerSubmitted}
                    value={selectedOptions ? selectedOptions[0] : []}
                    onChange={onRadioButtonChange}
                  >
                    {data.options.map((option) => (
                      <FormControlLabel
                        key={`${question.id}-${option.option_serial_no}`}
                        value={option.option_serial_no?.toString()}
                        label={option.option_text
                          .split('\n')
                          .map((item, ind) => {
                            return (
                              <Typography
                                variant="body2"
                                component="span"
                                className={classnames({
                                  'prevent-selection': !isAnswerSubmitted,
                                })}
                                key={ind}
                              >
                                {ReactHtmlParser(item)}
                              </Typography>
                            );
                          })}
                        control={
                          <RadioButton
                            color="primary"
                            disabled={isAnswerSubmitted}
                          />
                        }
                      />
                    ))}
                  </RadioButtonGroup>
                ) : (
                  <Grid display="flex" flexDirection="column">
                    {data.options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.option_serial_no}
                        label={option.option_text
                          .split('\n')
                          .map((item, ind) => {
                            return (
                              <Typography
                                variant="body2"
                                component="span"
                                className={classnames({
                                  'prevent-selection': !isAnswerSubmitted,
                                })}
                                key={ind}
                              >
                                {ReactHtmlParser(item)}
                              </Typography>
                            );
                          })}
                        control={
                          <Checkbox
                            checked={selectedOptions?.includes(
                              option.option_serial_no,
                            )}
                            onChange={(e) => onCheckboxClick(e)}
                          />
                        }
                      />
                    ))}
                  </Grid>
                )}
              </div>
            )}
          </Fragment>
        ) : null}
        {data.question_type === 'OneWord' ? (
          <div className="form-group answer-text">
            {!isAnswerSubmitted && (
              <TextField
                label="Enter your answer"
                value={answerText}
                disabled={isAnswerSubmitted}
                InputProps={{
                  startAdornment:
                    result && isAnswerSubmitted && !isMockTest ? (
                      <InputAdornment position="start">
                        <Icon style={{ fontSize: 18 }}>
                          {result.is_attempt_correct ? 'check' : 'clear'}
                        </Icon>
                      </InputAdornment>
                    ) : (
                      undefined
                    ),
                }}
                onChange={onTextFieldChange}
                margin="dense"
                variant="outlined"
                size="small"
                className="answer-text"
                fullWidth
              />
            )}
            {result && isAnswerSubmitted && !isMockTest ? (
              <div
                className={
                  result.timeout
                    ? 'answer-text-submitted-timeout'
                    : result.question_skipped
                    ? 'answer-text-submitted-skipped'
                    : result.is_attempt_correct
                    ? 'answer-text-submitted-correct'
                    : 'answer-text-submitted-incorrect'
                }
              >
                {answerText ? answerText : 'Question Skipped'}
              </div>
            ) : null}
            {result && isAnswerSubmitted && !isMockTest ? (
              <div>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: '500',
                    color: 'black',
                    marginTop: '15px',
                  }}
                  gutterBottom
                >
                  Correct Answer:
                </Typography>
                <div className="answerBox">{result.answer_one_word}</div>
              </div>
            ) : null}
          </div>
        ) : null}

        <Box flexGrow={1} />

        <div className="play-answer__submit">
          {isMockTest && isMockTestFlexibleMode ? (
            <Hidden mdUp>
              <Button
                color="success"
                variant="contained"
                className="play-answer__submit__skip"
                onClick={onEndTestClick}
              >
                Complete Test
              </Button>
            </Hidden>
          ) : (
            !isAnswerSubmitted && (
              <Button
                color="primary"
                variant="outlined"
                className="play-answer__submit__skip"
                onClick={onSkip}
              >
                Skip Question
              </Button>
            )
          )}
          {isAnswerSubmitted ? (
            <Button
              variant="contained"
              onClick={onNext}
              color="primary"
              className="play-answer__submit__next"
            >
              Next
            </Button>
          ) : (
            <Fragment>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={onSubmitClick}
                disabled={isSubmitting}
                className="play-answer__submit__next"
              >
                {isMockTestFlexibleMode
                  ? isLastQuestion
                    ? 'Submit Test'
                    : 'Next'
                  : 'Submit'}
              </Button>
            </Fragment>
          )}
        </div>
        {data.is_hint_available && !isAnswerSubmitted && isHintVisible && (
          <div className="question-hint">
            <p>Hint: </p>
            <p>{question.hint}</p>
          </div>
        )}
        {isAnswerSubmitted && !isMockTest ? (
          <div className="play-answer__solution solution">
            <div className="solution-heading">
              <Typography color="black" fontWeight={'400'} fontSize={'14px'}>
                Solution
              </Typography>
            </div>
            <div className="solution-wrapper">
              <Fragment>
                {data.is_solution_available ? (
                  <div>
                    {isSolutionVisible ? (
                      <div className="solutionText">
                        <Typography variant="body2" gutterBottom>
                          {ReactHtmlParser(data.solution_text)}
                        </Typography>
                        <Box
                          sx={{
                            mt: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#7B7B7B',
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            Rate Solution:
                          </Typography>
                          <Box display={'flex'} flexDirection="row">
                            {[1, 2, 3, 4, 5].map((item, index) => (
                              <Typography
                                key={index}
                                className="solution_rating"
                                style={{ marginLeft: '5px' }}
                                sx={{
                                  background:
                                    item <= solutionratingValue
                                      ? getRatingValueColor(solutionratingValue)
                                      : '#3572B01A',
                                  color:
                                    item <= solutionratingValue
                                      ? '#fff'
                                      : '#25507B',
                                  opacity:
                                    solutionratingValue !== 0
                                      ? item > solutionratingValue
                                        ? 0.5
                                        : 1
                                      : 1,
                                }}
                                onClick={() => {
                                  setSolutionratingValue(item);
                                  props.putSolutionRating({
                                    questionId: question?.data?.id,
                                    body: {
                                      solution_rating: item,
                                    },
                                  });
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </div>
                    ) : (
                      <div className="solution-text-hidden">
                        <Typography variant="body2" gutterBottom>
                          {randomText.slice(0, 100)}
                        </Typography>
                        <div>
                          <Typography
                            color="primary"
                            fontWeight={'600'}
                            fontSize={'16px'}
                          >
                            Unlock Solution
                          </Typography>
                          <IconButton
                            onClick={onUnlockSolution}
                            size="large"
                            color="primary"
                          >
                            <img src={LockIcon} alt="lock-icon" />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="solution-content">
                    <Typography variant="body2" gutterBottom>
                      No solution available
                    </Typography>
                  </div>
                )}
              </Fragment>
            </div>
          </div>
        ) : (
          undefined
        )}

        {isMockTest && (
          <Hidden mdUp>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                gap: '4px',
                marginTop: '16px',
              }}
            >
              <p>Author</p>
              <img src={author} style={{ width: '12px', height: '12px' }} />
              <p> {question?.data?.created_by_name}</p>
            </div>
          </Hidden>
        )}

        <div>
          <Divider />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {!parentQuestion && (
              <a
                href="#"
                className="play-answer-details-link"
                onClick={onReportQuestionClick}
              >
                <ReportIcon /> Report this Question
              </a>
            )}
            <div className="bottomicon">
              {!isMockTest && (
                <LikeDislike
                  id={question.data.id}
                  liked={false}
                  disliked={false}
                />
              )}
              <BookmarkQuestion
                id={question.data.id}
                putBookmark={props.putBookmark}
              />
            </div>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

PlayAnswer.defaultProps = {
  question: {
    data: [],
  },
  postAnswer: () => {},
  question_id: '',
  id: '',
};

PlayAnswer.propTypes = {
  question: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  postAnswer: PropTypes.func,
  question_id: PropTypes.string,
  id: PropTypes.string,
};
export default withStyles(styles)(PlayAnswer);
