import { styled } from '@mui/styles';
import { Box } from '@mui/material';

export const StyleResult = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0.5em 1em',
  marginTop: '10px',

  h6: {
    fontFamily: 'Roboto',
  },

  '& .result__header': {
    display: 'flex',
    padding: '0.5em 0',
    alignItems: 'center',

    '@media (max-width: 1000px)': {
      button: {
        padding: 0,
        paddingRight: '1em',
      },
    },
  },

  '& .result__categories': {
    margin: '1em 0',
    background: theme.palette.secondary.color,
    borderRadius: '5px',
    display: 'flex',
    padding: '1em',

    p: {
      fontSize: '1.1em',
      marginRight: '0.3em',
    },

    '> div': {
      display: 'flex',
      gap: '0.3em',

      div: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        borderRadius: '6px',
      },
    },
  },

  '& .result__statistics': {
    padding: '1em',
    boxShadow: `0px 4px 4px ${theme.palette.component.shadowColor}`,
    borderRadius: '5px',

    '@media (max-width: 1000px)': {
      padding: 0,
      paddingTop: '0.5em',
    },

    '& .result__statistics--header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0.5em',

      '@media (max-width: 1000px)': {
        paddingLeft: '1em',
      },
    },

    '& .result__statistics--content': {
      display: 'flex',

      '@media (max-width: 1000px)': {
        flexDirection: 'column',
      },

      '& > div': {
        flex: 1,
        gap: '1.5em',
        padding: '1.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '&:not(:first-child)': {
          borderLeft: `1px solid ${theme.palette.component.shadowColor}`,
        },

        '@media (max-width: 1000px)': {
          borderLeft: 'none',
          borderTop: `1px solid ${theme.palette.component.shadowColor}`,
        },

        '& > div:not(.pie-chart)': {
          flex: 1,

          p: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            span: {
              fontWeight: 'bolder',
              fontSize: '1.2em',

              '&.success': {
                color: theme.palette.playColors.lightGreen2,
              },

              '&.error': {
                color: theme.palette.common.error,
              },

              '&.warning': {
                color: theme.palette.common.warning,
              },

              '&.primary': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
  },
}));

export const StyledPieChart = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  aspectRatio: '1 / 1',
  position: 'relative',

  '&::after': {
    content: 'attr(data-value)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.5em',
    fontWeight: 'bolder',
    textAlign: 'center',
    width: '75%',
    height: '75%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
  },
}));
