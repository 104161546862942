import { styled } from '@mui/styles';

export const StyledLogin = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  '& .login-heading': {
    fontSize: '22px',
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.secondary.dark,
    marginTop: '6px',
  },

  '& .login-form': {
    flex: 1,
    marginBottom: '10px',
  },

  '& .login-extras': {
    marginBottom: '10px',
    textAlign: 'right',
  },

  '& .errors': {
    color: theme.palette.common.error,
    paddingLeft: '2px',
    marginTop: '4px',
  },

  '& .text-field': {
    marginBottom: '10px',
  },

  '& .text-input': {
    fontSize: '12px !important',
    color: `${theme.palette.component.color} !important`,
    width: '100%',
    backgroundColor: `${theme.palette.background.main} !important`,
    border: 'none !important',
  },

  '& .google-btn-container': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& .google-login-button': {
      marginTop: '10px',
      color: theme.palette.component.color,
      backgroundColor: theme.palette.background.main,
      width: 'fit-content',
      textAlign: 'center',
    },
  },

  '& .center': {
    textAlign: 'center',
  },
}));
