import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMatchingUsersState,
  getMatchingUsers,
} from '../../actions/userSearchQuerySuggestions';

const UserSearchField = ({ selectedUser, setSelectedUser }) => {
  const userQuerySuggestion =
    useSelector((state) => state.userSearchQuerySuggestions.list) || [];

  const options = useMemo(
    () =>
      userQuerySuggestion.map((user) => ({
        label: `${user?.first_name} ${user?.last_name}`,
        id: user.id,
      })),
    [userQuerySuggestion],
  );

  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchQuery !== '') {
      dispatch(getMatchingUsers(1, 10, searchQuery, '', ''));
    } else {
      dispatch(clearMatchingUsersState());
    }
  }, [dispatch, searchQuery]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField {...params} placeholder="Search an User" />
      )}
      options={options}
      size="small"
      inputValue={searchQuery}
      onInputChange={(_, newInputValue) => {
        setSearchQuery(newInputValue);
      }}
      value={selectedUser}
      onChange={(_, newValue) => {
        setSelectedUser(newValue);
      }}
    />
  );
};

export default UserSearchField;
