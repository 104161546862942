import { Box, Container } from '@mui/material';
import { styled } from '@mui/styles';

export const StyleDataManagement = styled(Container)(({ theme }) => ({
  '& .college__list__name': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .college__sub-tags': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .subtag': {
      margin: '2px 3px',
    },
  },

  '& .college__list__avatar': {
    marginRight: '20px',
  },

  '& .dialog-title-container': {
    display: 'flex',
    width: '100%',
    borderBottom: `0.2px solid ${theme.palette.secondary.main}`,

    '& .dialog-title': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 2,
      cursor: 'pointer',
      padding: '10px !important',

      '&::hover': {
        backgroundColor: 'white !important',
      },
      '&.active': {
        '&::hover': {
          backgroundColor: `${theme.palette.playColors.lightSky} !important`,
        },
      },
    },
  },

  '& .dialog-content': {
    minHeight: '150px',
  },

  '& .merge-entity-box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 0px',
    paddingLeft: '15px',
    cursor: 'pointer',
  },

  '& .dulticate-entities-container': {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    margin: '15px 0px',
  },

  '& .duplicate-entities-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .color-box': {
    width: '15px',
    height: '15px',
  },

  '& .duplicate-approval-status': {
    height: '22px !important',
    background: 'transparent !important',
    border: `1px solid ${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: '500',
  },
}));

export const QuickActionBarGrid = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: '10px',
  width: '100%',
  position: 'sticky',
  bottom: 0,
  border: `2px solid ${theme.palette.primary.main}`,
}));
