import * as types from './types';
import apiClient from '../api/apiclient';

export function getBadgesStart() {
  return {
    type: types.GET_BADGES_START,
  };
}
export function getBadgesSuccess({ data }) {
  return {
    type: types.GET_BADGES_SUCCESS,
    data: data,
  };
}

export function getBadgesFail() {
  return {
    type: types.GET_BADGES_FAIL,
  };
}
export function getBadges(searchTerm, page, pageSize) {
  return (dispatch) => {
    dispatch(getBadgesStart());
    apiClient
      .getBadgeApi()
      .badgeApiGetBadge(
        undefined,
        page,
        pageSize,
        searchTerm,
        undefined,
        undefined,
      )
      .then((resp) => {
        dispatch(getBadgesSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getBadgesFail());
        throw ex;
      });
  };
}
export function postBadgesStart() {
  return {
    type: types.POST_BADGES_START,
  };
}
export function postBadgesSuccess({ data }) {
  return {
    type: types.POST_BADGES_SUCCESS,
    data: data,
  };
}

export function postBadgesFail() {
  return {
    type: types.POST_BADGES_FAIL,
  };
}
export function postBadge(data) {
  return (dispatch) => {
    dispatch(postBadgesStart());
    apiClient
      .getBadgeApi()
      .badgeApiCreateNewBadge(data)
      .then((resp) => {
        dispatch(postBadgesSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postBadgesFail());
        throw ex;
      });
  };
}

export function postBadgeImageStart() {
  return {
    type: types.POST_BADGE_IMAGE_START,
  };
}

export function postBadgeImageSuccess({ data }) {
  return {
    type: types.POST_BADGE_IMAGE_SUCCESS,
    data: data,
  };
}

export function postBadgeImageFail() {
  return {
    type: types.POST_BADGE_IMAGE_FAIL,
  };
}

export function uploadBadgeImage(imageFile) {
  return (dispatch) => {
    dispatch(postBadgeImageStart());
    apiClient
      .getBadgeApi()
      .badgeApiUploadBadgeImage(imageFile)
      .then((resp) => {
        dispatch(postBadgeImageSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postBadgeImageFail());
        throw ex;
      });
  };
}

export function updateBadgeStart() {
  return {
    type: types.UPDATE_BADGE_START,
  };
}
export function updateBadgeSuccess({ data }) {
  return {
    type: types.UPDATE_BADGE_SUCCESS,
    data: data,
  };
}

export function updateBadgeFail() {
  return {
    type: types.UPDATE_BADGE_FAIL,
  };
}
export function updateBadge(data) {
  return (dispatch) => {
    dispatch(updateBadgeStart());
    apiClient
      .getBadgeApi()
      .badgeApiUpdateBadge(data)
      .then((resp) => {
        dispatch(updateBadgeSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(updateBadgeFail());
        throw ex;
      });
  };
}

export function deleteBadgeStart() {
  return {
    type: types.DELETE_BADGE_START,
  };
}
export function deleteBadgeSuccess({ data }) {
  return {
    type: types.DELETE_BADGE_SUCCESS,
    data: data,
  };
}

export function deleteBadgeFail() {
  return {
    type: types.DELETE_BADGE_FAIL,
  };
}
export function deleteBadge(id, searchTerm, page, pageSize) {
  return (dispatch) => {
    dispatch(deleteBadgeStart());
    apiClient
      .getBadgeApi()
      .badgeApiDeleteBadge(id)
      .then((resp) => {
        dispatch(deleteBadgeSuccess({ data: resp.data }));
        dispatch(getBadges(searchTerm, page, pageSize));
      })
      .catch((ex) => {
        dispatch(deleteBadgeFail());
        throw ex;
      });
  };
}

export function getBadgeTypeStart() {
  return {
    type: types.GET_BADGE_TYPE_START,
  };
}
export function getBadgeTypeSuccess({ data }) {
  return {
    type: types.GET_BADGE_TYPE_SUCCESS,
    data: data,
  };
}

export function getBadgeTypeFail() {
  return {
    type: types.GET_BADGE_TYPE_FAIL,
  };
}
export function getBadgeType() {
  return (dispatch) => {
    dispatch(getBadgeTypeStart());
    apiClient
      .getBadgeApi()
      .badgeApiGetBadgeType()
      .then((resp) => {
        dispatch(getBadgeTypeSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getBadgeTypeFail());
        throw ex;
      });
  };
}
