import { CssBaseline } from '@mui/material';
import React from 'react';

export default function NoSidebarLayout(props) {
  return (
    <div className="layout no_sidebar">
      <CssBaseline />
      <div className="layout__content">
        <div className="wrapper">{props.children}</div>
      </div>
    </div>
  );
}
