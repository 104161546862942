import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Grid,
  IconButton,
  Switch,
  InputAdornment,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector, useDispatch } from 'react-redux';
import { matchSorter } from 'match-sorter';

import AdminProductDetailsDialog from './createProductDialog';
import {
  getProductsList,
  updateProduct,
  deleteProduct,
} from '../../actions/storeAdmin';

import Gems from '../Gems';
import { NavLink } from 'react-router-dom';

import { SearchBox, Name, ProductImage } from './StoreAdminStyledComponent';

const StoreAdmin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductsList());
  }, [dispatch]);

  const { productsList } = useSelector((state) => state.storeAdmin);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [productID, setProductID] = useState('');

  const filteredList = useMemo(() => {
    if (searchTerm)
      return matchSorter(productsList, searchTerm, { keys: ['name'] });
    else return productsList;
  }, [productsList, searchTerm]);

  const selectedProduct = productsList?.find(
    (record) => record.id === productID,
  );

  const handleChange = (id, activeStatus) => {
    let data = {
      is_active: activeStatus,
    };
    dispatch(updateProduct(id, data));
  };

  const handleModalOpen = (id) => {
    setProductID(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteProduct = (id) => {
    dispatch(deleteProduct(id));
  };

  return (
    <div className="root">
      <Container>
        <Grid container>
          <Grid item xs={6}>
            <SearchBox
              margin="dense"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search here..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end" gap="20px">
              <NavLink to={'/l/storeAdmin/orders'}>
                <Button variant="outlined" type="button" color="primary">
                  Orders
                </Button>
              </NavLink>
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={() => handleModalOpen('')}
              >
                Add Product
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
          container
          style={{
            fontSize: '15px',
            color: '#25507B',
          }}
        >
          <Grid item style={{ width: '120px', paddingLeft: '10px' }}></Grid>
          <Grid item style={{ width: '350px', padding: '10px' }}>
            Name
          </Grid>
          <Grid item style={{ width: '130px', padding: '10px' }}>
            Type
          </Grid>
          <Grid item style={{ width: '200px', padding: '10px' }}>
            Price
          </Grid>

          <Grid item style={{ width: '100px', padding: '10px' }}>
            SKU
          </Grid>
          <Grid item style={{ width: '100px', padding: '10px' }}>
            Status
          </Grid>
          <Grid item style={{ padding: '10px' }}></Grid>
        </Grid>
      </Container>
      <Container>
        {filteredList.map((product) => (
          <Grid
            key={product.id}
            container
            style={{
              width: '1155px',
              background: '#fff',
              border: '1px solid #00000024',
              marginBottom: '10px',
              padding: '10px 0px 10px',
            }}
          >
            <Grid item style={{ width: '120px', paddingLeft: '10px' }}>
              <ProductImage
                alt={product.name}
                src={product.image_url && product.image_url[0]}
              />
            </Grid>
            <Grid item style={{ width: '350px', padding: '10px' }}>
              <Name>{product.name}</Name>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '12px',
                  letterSpacing: '0.4px',
                }}
              >
                {product.item_description}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                width: '130px',
                padding: '10px',
                borderLeft: '1px solid #00000024',
              }}
            >
              <Typography style={{ color: '#25507B', fontSize: '16px' }}>
                {' '}
                {product.is_reward ? 'Reward' : 'Shop'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                width: '200px',
                padding: '10px',
                borderLeft: '1px solid #00000024',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 600,
                  color: '#25507B',
                }}
              >
                {' '}
                {!product.is_reward && (
                  <span style={{ color: 'green' }}>
                    ₹ {product.gross_price_in_currency} /
                  </span>
                )}
                {product.gross_price_in_gems}{' '}
              </span>
              <Gems width="12px" />
            </Grid>

            <Grid
              item
              style={{
                width: '100px',
                padding: '10px',
                borderLeft: '1px solid #00000024',
              }}
            >
              <Typography
                style={{
                  fontSize: '20px',
                  color: '#25507B',
                  fontWeight: 600,
                }}
              >
                {product.sku_count}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                width: '100px',
                padding: '10px',
                borderLeft: '1px solid #00000024',
              }}
            >
              <Switch
                checked={product.is_active}
                onChange={(e) => handleChange(product.id, e.target.checked)}
                color="primary"
                id={product.id}
                size="small"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid
              item
              style={{ padding: '10px', borderLeft: '1px solid #00000024' }}
            >
              <Box display="flex" justifyContent="space-evenly">
                <IconButton
                  onClick={() => handleModalOpen(product.id)}
                  size="large"
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  disabled={product.is_active}
                  onClick={() => {
                    if (window.confirm('Do you want to Delete the item ?'))
                      handleDeleteProduct(product.id);
                  }}
                  size="large"
                >
                  <Tooltip title="To Delete, Item should be Inactive">
                    <DeleteIcon fontSize="medium" />
                  </Tooltip>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Container>

      {open ? (
        <AdminProductDetailsDialog
          open={open}
          handleClose={handleClose}
          productID={productID}
          product={selectedProduct}
        />
      ) : null}
    </div>
  );
};

export default StoreAdmin;
