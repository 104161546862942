import {
  Dialog,
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Background from './images/background.svg';
import LevelUpBackground from './images/level-up-background.svg';
import NameBackground from './images/name-background.svg';
import CreditsIcon from './images/credits-icon.svg';
import GemsIcon from './images/gems-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/styles';
import { claimRewards } from '../../actions/user';
import { CollectBadgeModalLevelImg } from './PlayStyledComponent';

const Loader = styled(CircularProgress)({
  color: 'white',
  width: '20px !important',
  height: '20px !important',
});

const TitleContainer = styled(Box)({
  backgroundPosition: 'center',
  height: '115px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

const BannerBackground = styled(Grid)({
  background: `no-repeat url(${Background})`,
  backgroundSize: 'cover',
  height: '400px',
  width: '100%',
  borderRadius: '10px',
});

const LevelBannerBackground = styled(Grid)({
  background: `no-repeat url(${LevelUpBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '400px',
  width: '100%',
  borderRadius: '10px',
  position: 'relative',
});

const RewardBoxContainerLevel = styled(Box)({
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
  mt: '40px',
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
});

const RewardBox = ({ icon_url, reward }) => {
  if (!reward) return null;
  return (
    <Grid
      width="60px"
      height="80px"
      p="2px"
      sx={{ background: 'linear-gradient(307deg, #EF5142 0%, #EB1B19 100%)' }}
    >
      <Grid
        sx={{
          background: '#550D05',
          aspectRatio: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        p="5px"
      >
        <img alt="icon" src={icon_url} width={'35px'} height={'35px'} />
      </Grid>
      <Typography
        color="white"
        fontSize={'14px'}
        fontWeight={'700'}
        textAlign={'center'}
      >
        +{reward}
      </Typography>
    </Grid>
  );
};

const CollectBadgeModal = ({
  open,
  badgeInfo,
  handleClose,
  categoryName,
  isLevelUp,
  nextLevel,
}) => {
  const dispatch = useDispatch();

  const [newLevel, setNewLevel] = useState(null);
  const levels = useSelector((state) => state.levels?.levelData?.levels) || [];

  if (isLevelUp) {
    setNewLevel(levels.find((lvl) => lvl.number === nextLevel));
  }

  const [collectedRewards, setCollectedRewards] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (badgeInfo) {
      if (!badgeInfo?.gem_reward && !badgeInfo?.credit_reward) {
        setCollectedRewards(true);
      }
    }
    if (newLevel) {
      if (!newLevel?.gem_reward && !newLevel?.credit_reward) {
        setCollectedRewards(true);
      }
    }
  }, [badgeInfo, newLevel]);

  if (!open) return null;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
          width: {
            xs: 'calc(100vw - 30px)',
            sm: '450px',
          },
          margin: {
            xs: '15px',
            sm: '32px',
          },
        },
      }}
    >
      <Grid
        p={{ xs: '10px', sm: '20px' }}
        display="flex"
        flexDirection="column"
        alignItems={'center'}
        gap="5px"
        position={'relative'}
        textAlign={'center'}
        className="collect-badge-modal"
      >
        {!isLevelUp && (
          <BannerBackground>
            <TitleContainer
              sx={{
                background: `no-repeat url(${NameBackground})`,
                paddingTop: { xs: '28px', sm: '25px' },
                backgroundSize: {
                  xs: '80%',
                },
              }}
            >
              <Typography color="#000" fontSize={'18px'} fontWeight={'700'}>
                {badgeInfo?.name}
              </Typography>
            </TitleContainer>
            <Box textAlign={'center'}>
              <img
                src={badgeInfo?.image_url}
                alt="badge-icon"
                width={150}
                height={150}
              />
            </Box>
            <Box
              display={'flex'}
              gap="40px"
              justifyContent={'center'}
              mt="20px"
            >
              <RewardBox
                icon_url={CreditsIcon}
                reward={badgeInfo?.credit_reward}
              />
              <RewardBox icon_url={GemsIcon} reward={badgeInfo?.gem_reward} />
            </Box>
          </BannerBackground>
        )}
        {isLevelUp && (
          <LevelBannerBackground>
            <Box textAlign={'center'} mt="40px">
              <CollectBadgeModalLevelImg
                src={newLevel?.image_url}
                alt="level-logo"
              />
            </Box>
            <RewardBoxContainerLevel>
              <RewardBox
                icon_url={CreditsIcon}
                reward={newLevel?.credit_reward}
              />
              <RewardBox icon_url={GemsIcon} reward={newLevel?.gem_reward} />
            </RewardBoxContainerLevel>
          </LevelBannerBackground>
        )}
        <Box mb="5px">
          <Typography color="primary" fontWeight={'500'} fontSize={'20px'}>
            {!isLevelUp
              ? "Congratulations! You've Earned a New Badge!"
              : 'Congratulations on Reaching a New Milestone'}
          </Typography>
          <Typography color="#212427" fontSize={'18px'}>
            {!isLevelUp
              ? 'Celebrate your achievements and unlock new levels of greatness on our platform.'
              : `You've Made Significant Progress in ${categoryName}. Keep Pushing and Aim Higher!`}
          </Typography>
        </Box>
        <Button
          startIcon={
            collectedRewards ? (
              <PlayCircleOutlineIcon />
            ) : loader ? (
              <Loader />
            ) : null
          }
          color="primary"
          variant="contained"
          onClick={() => {
            if (collectedRewards) handleClose();
            else {
              setLoader(true);
              setTimeout(() => {
                setCollectedRewards(true);
                dispatch(
                  claimRewards({
                    credits: badgeInfo?.credit_reward,
                    gems: badgeInfo?.gem_reward,
                  }),
                );
              }, 400);
            }
          }}
          sx={{
            background: !collectedRewards && '#FEC20C',
            '&:hover': {
              background: !collectedRewards && '#FEC20C',
            },
            marginBottom: { xs: '10px', sm: '0px' },
          }}
        >
          {collectedRewards ? 'Continue Practicing' : 'Claim Rewards'}
        </Button>
      </Grid>
    </Dialog>
  );
};

export default CollectBadgeModal;
