import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './../components/QuestionList/CreatedQuestionCard';

import {
  deleteQuestion,
  getQuestionById,
  getQuestionStats,
  putQuestion,
} from './../actions/question';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteQuestion,
      getQuestionById,
      getQuestionStats,
      putQuestion,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    question: state.question,
    tag: state.tag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
