import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const domain = createReducer(
  {
    isCreatingDomain: false,
    addTagsToDomainStart: false,
    addTagsToDomainSuccess: false,
    addTagsToDomainFail: false,
    domainSelectModalOpen: false,
  },
  {
    [types.OPEN_DOMAIN_SELECT_MODAL](state) {
      return { ...state, domainSelectModalOpen: true };
    },
    [types.CLOSE_DOMAIN_SELECT_MODAL](state) {
      return { ...state, domainSelectModalOpen: false };
    },
    [types.GET_DOMAIN_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingDomain: true,
        isUpdatingDomainSuccess: false,
        isUpdatingDomainFail: false,
      });

      return newState;
    },
    [types.GET_DOMAIN_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingDomain: false,
      });
      if (action.dataType === 'Exam') {
        newState.exams = action.data;
      } else {
        newState.list = action.data;
      }

      return newState;
    },
    [types.GET_DOMAIN_FAIL](state) {
      const newState = Object.assign({}, state, {
        list: [],
        isFetchingDomain: false,
      });

      return newState;
    },

    [types.CHANGE_DOMAIN_START](state) {
      const newState = Object.assign({}, state, {
        isUpdatingDomain: true,
        isUpdatingDomainSuccess: false,
        isUpdatingDomainFail: false,
      });

      return newState;
    },
    [types.CHANGE_DOMAIN_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        isUpdatingDomain: false,
        isUpdatingDomainSuccess: true,
      });

      return newState;
    },
    [types.CHANGE_DOMAIN_FAIL](state) {
      const newState = Object.assign({}, state, {
        isUpdatingDomain: false,
        isUpdatingDomainFail: true,
      });

      return newState;
    },

    [types.CREATE_NEW_DOMAIN_START](state) {
      const newState = Object.assign({}, state, {
        isCreatingDomain: true,
      });

      return newState;
    },
    [types.CREATE_NEW_DOMAIN_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isCreatingDomain: false,
        data: action.data,
      });

      return newState;
    },
    [types.CREATE_NEW_DOMAIN_FAIL](state) {
      const newState = Object.assign({}, state, {
        isCreatingDomain: false,
      });

      return newState;
    },
    [types.EDIT_DOMAIN_START](state) {
      const newState = Object.assign({}, state, {
        isEditingDomain: true,
      });

      return newState;
    },
    [types.EDIT_DOMAIN_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isEditingDomain: false,
        data: action.data,
      });

      return newState;
    },
    [types.EDIT_DOMAIN_FAIL](state) {
      const newState = Object.assign({}, state, {
        isEditingDomain: false,
      });

      return newState;
    },
    [types.ADD_TAG_TO_DOMAIN_START](state) {
      return {
        ...state,
        addTagsToDomainStart: true,
        addTagsToDomainSuccess: false,
        addTagsToDomainFail: false,
      };
    },
    [types.ADD_TAG_TO_DOMAIN_SUCCESS](state) {
      return {
        ...state,
        addTagsToDomainStart: false,
        addTagsToDomainSuccess: true,
        addTagsToDomainFail: false,
      };
    },
    [types.ADD_TAG_TO_DOMAIN_FAIL](state) {
      return {
        ...state,
        addTagsToDomainStart: false,
        addTagsToDomainSuccess: false,
        addTagsToDomainFail: true,
      };
    },
    [types.REMOVE_TAG_FROM_DOMAIN_START](state) {
      return {
        ...state,
        removeTagsFromDomainStart: true,
        removeTagsFromDomainSuccess: false,
        removeTagsFromDomainFail: false,
      };
    },
    [types.REMOVE_TAG_FROM_DOMAIN_SUCCESS](state) {
      return {
        ...state,
        removeTagsFromDomainStart: false,
        removeTagsFromDomainSuccess: true,
        removeTagsFromDomainFail: false,
      };
    },
    [types.REMOVE_TAG_FROM_DOMAIN_FAIL](state) {
      return {
        ...state,
        removeTagsFromDomainStart: false,
        removeTagsFromDomainSuccess: false,
        removeTagsFromDomainFail: true,
      };
    },
  },
);

export { domain as default };
