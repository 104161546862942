import * as React from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';
import RichTextEditor from '../RichTextEditor/index';

const IncorrectOptions = ({ options, onChange, value }) => {
  const [checkedValue, setCheckedValue] = React.useState();
  const [checkedIndex, setCheckedIndex] = React.useState();

  const onOptionTextChange = (e) => {
    const optionBody = {
      ...value,
      correct_values: [+checkedIndex],
      correct_text: e.html,
    };
    onChange(optionBody);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit' }}
      >
        Please select the correct option
      </FormLabel>
      <RadioGroup
        aria-label="option"
        name="option"
        onChange={(e) => setCheckedValue(e.target.value)}
      >
        {options.map((option) => (
          <div key={option.option_id}>
            <FormControlLabel
              value={option.option_text}
              control={
                <Radio
                  name={`${option.option_serial_no}`}
                  onChange={(e) => setCheckedIndex(e.target.name)}
                />
              }
              label={option.option_text}
            />
            {checkedValue === option.option_text && (
              <RichTextEditor
                onChange={onOptionTextChange}
                value={value.correct_text}
              />
            )}
          </div>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default IncorrectOptions;
