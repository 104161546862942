import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { StyledFormGroup } from './StyledComponent';

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
    flexDirection: 'coloumn',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default function Filters({
  handleRemoveFilter,
  handleChange,
  filterValues,
  type,
}) {
  const classes = useStyles();
  const filters = Object.keys(filterValues);

  const notSelected = (f) => {
    var temp = true;
    f.forEach((element) => {
      if (filterValues[element]) {
        temp = false;
      }
    });
    return temp;
  };

  const renderCheckboxFilter = () => {
    return (
      <FormControl className={classes.formControl}>
        <StyledFormGroup className={classes.checkboxes} row={false}>
          {filters.map((filter, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={filterValues[filter]}
                    onChange={handleChange(filter)}
                    value={filter}
                    color="primary"
                    size="small"
                  />
                }
                label={filter}
              />
            );
          })}
          {/* <FormControlLabel
            className={'optionCtn'}
            control={
              <div
                className={notSelected(filters) ? 'optionSelected' : 'option'}
                onClick={() => handleRemoveFilter()}
              >
                All
              </div>
            }
          />
          {filters.map((filter) => {
            return (
              <FormControlLabel
                className={'optionCtn'}
                control={
                  <div
                    className={
                      filterValues[filter] ? 'optionSelected' : 'option'
                    }
                    onClick={handleChange(filter)}
                  >
                    {filter}
                  </div>
                }
              />
            );
          })} */}
        </StyledFormGroup>
      </FormControl>
    );
  };

  const renderDropdownFilter = () => {
    //TODO : Fix No - 97
    return (
      <FormControl className={classes.formControl}>
        <StyledFormGroup className={classes.checkboxes} row={false}>
          <FormControlLabel
            className={'optionCtn'}
            control={
              <div
                className={notSelected(filters) ? 'optionSelected' : 'option'}
                onClick={() => handleRemoveFilter()}
              >
                All
              </div>
            }
          />
          {filters.map((filter, index) => {
            return (
              <FormControlLabel
                key={index}
                className={'optionCtn'}
                control={
                  <div
                    className={
                      filterValues[filter] ? 'optionSelected' : 'option'
                    }
                    onClick={handleChange(filter)}
                  >
                    {filter}
                  </div>
                }
              />
            );
          })}
        </StyledFormGroup>
      </FormControl>
    );
  };

  const filterVariant =
    type === 'checkboxes' ? renderCheckboxFilter() : renderDropdownFilter();

  return filterVariant;
}
