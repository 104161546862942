import React from 'react';
import { Box, Divider, Grid, Typography, Button } from '@mui/material';
import {
  AdminFeatureChip,
  DeleteBtn,
  Heading,
  LogsTitle,
  RecordCount,
  MoreDetailsBtn,
} from './StyledComponents';
import moment from 'moment';
import { Delete, Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { deleteScheduledEmails } from '../../actions/emailingList';
import { CustomDialogBox } from '../DialogBox';

const ScheduledAndLogs = ({ title, mailData, type, page, rowsPerPage }) => {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedIdOfMail, setSelectedIdOfMail] = React.useState('');
  const dataToBeMapped =
    type === 'scheduled' ? mailData?.scheduled_mails : mailData?.mail_logs;

  const handleDeleteScheduledMail = () => {
    dispatch(deleteScheduledEmails(selectedIdOfMail, type, page, rowsPerPage));
    setOpenDeleteDialog(false);
  };

  return (
    <Box padding={'10px'}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <LogsTitle>{title}</LogsTitle>
        <Box>
          <AdminFeatureChip label="Admin Feature" />
        </Box>
      </Box>
      {dataToBeMapped?.map((email, index) => (
        <Box mt={4} key={index}>
          <Grid container mb={2}>
            <Grid item xs={1} display={'flex'} justifyContent={'center'}>
              <RecordCount>
                {(mailData?.page_no - 1) * mailData?.page_size + (index + 1)}
              </RecordCount>
            </Grid>
            <Grid item xs={11}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography color={'#000'}>
                  <Heading style={{ fontWeight: 600 }}>Subject:</Heading>{' '}
                  {email?.subject}
                </Typography>
                <Typography color={'#000'}>
                  {' '}
                  <Heading>
                    {type === 'scheduled' ? 'Scheduled' : 'Sent On'}:
                  </Heading>{' '}
                  {moment
                    .utc(
                      type === 'scheduled'
                        ? email?.scheduled_datetime
                        : email?.created_at,
                    )
                    .add(5, 'hours')
                    .add(30, 'minutes')
                    .format('DD/MM/YYYY hh:mm a')}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mt={2}
              >
                <Box>
                  <Typography>
                    {' '}
                    <Heading>From Email: </Heading> {email?.sender || 'NA'}
                  </Typography>
                  <Typography>
                    {' '}
                    <Heading>Sending to: </Heading>
                    {email?.total_count ? `${email?.total_count} Users` : 'NA'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  {type === 'scheduled' && (
                    <DeleteBtn
                      onClick={() => {
                        setSelectedIdOfMail(email?.id);
                        setOpenDeleteDialog(true);
                      }}
                    >
                      <Delete /> Delete Mail
                    </DeleteBtn>
                  )}
                  <MoreDetailsBtn onClick={() => {}}>
                    <Add /> More Details
                  </MoreDetailsBtn>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
      {openDeleteDialog && (
        <CustomDialogBox
          actions={
            <>
              <DeleteBtn onClick={handleDeleteScheduledMail}>
                <Delete /> Delete Mail
              </DeleteBtn>
              <Button
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          title={'Are you sure you want to delete the scheduled mail?'}
          maxWidth="sm"
          overflowVisible={false}
        >
          Once deleted, the action cannot be reverted.
        </CustomDialogBox>
      )}
    </Box>
  );
};

export default ScheduledAndLogs;
