import * as types from './types';
import apiClient from '../api/apiclient.ts';

const getAdminUsersListStart = () => {
  return {
    type: types.GET_ADMIN_USERS_LIST_START,
  };
};

const getAdminUsersListSuccess = ({ data }) => {
  return {
    type: types.GET_ADMIN_USERS_LIST_SUCCESS,
    data,
  };
};

const getAdminUsersListFail = (data) => {
  return {
    type: types.GET_ADMIN_USERS_LIST_FAIL,
    data: data,
  };
};

export const getAdminUsersList = (searchInput, page, rowsPerPage, userType) => {
  return (dispatch) => {
    dispatch(getAdminUsersListStart());

    return apiClient
      .getGeneralApi()
      .generalApiGetAllUserList(
        searchInput,
        undefined,
        page,
        rowsPerPage,
        userType,
      )
      .then((resp) => {
        dispatch(getAdminUsersListSuccess({ data: resp.data }));
        return resp.data;
      })
      .catch(() => {
        dispatch(getAdminUsersListFail());
      });
  };
};

const getUserTokenStart = () => {
  return {
    type: types.GET_USER_TOKEN_START,
  };
};

const getUserTokenSuccess = ({ data }) => {
  return {
    type: types.GET_USER_TOKEN_SUCCESS,
    data,
  };
};

const getUserTokenFail = (data) => {
  return {
    type: types.GET_USER_TOKEN_FAIL,
    data: data,
  };
};

export const getUserToken = (email) => {
  return (dispatch) => {
    dispatch(getUserTokenStart());

    return apiClient
      .getTokenApi()
      .tokenApiGetTokenByUserDetails(email)
      .then((resp) => {
        dispatch(getUserTokenSuccess({ data: resp.data }));
        return resp.data;
      })
      .catch(() => {
        dispatch(getUserTokenFail());
      });
  };
};

const updateAdminUserStart = () => {
  return {
    type: types.UPDATE_ADMIN_USER_START,
  };
};

const updateAdminUserSuccess = () => {
  return {
    type: types.UPDATE_ADMIN_USER_SUCCESS,
  };
};

const updateAdminUserFail = (data) => {
  return {
    type: types.UPDATE_ADMIN_USER_FAIL,
    data: data,
  };
};

export const updateAdminUser = (userId, updateBody) => {
  return (dispatch) => {
    dispatch(updateAdminUserStart());

    return apiClient
      .getUserApi()
      .userApiAdminUserUpdate(userId, updateBody)
      .then((resp) => {
        dispatch(updateAdminUserSuccess({ data: resp.data }));
        return resp.data;
      })
      .catch(() => {
        dispatch(updateAdminUserFail());
      });
  };
};

const resetUserCompletelyStart = () => {
  return {
    type: types.RESET_USER_COMPLETELY_START,
  };
};

const resetUserCompletelySuccess = () => {
  return {
    type: types.RESET_USER_COMPLETELY_SUCCESS,
  };
};

const resetUserCompletelyFail = () => {
  return {
    type: types.RESET_USER_COMPLETELY_FAIL,
  };
};

export const resetUserCompletely = (userId) => {
  return (dispatch) => {
    dispatch(resetUserCompletelyStart());

    return apiClient
      .getUserApi()
      .userApiResetUserCompletely(userId)
      .then((resp) => {
        dispatch(resetUserCompletelySuccess({ data: resp.data }));
        return resp.data;
      })
      .catch(() => {
        dispatch(resetUserCompletelyFail());
      });
  };
};

const deleteUserCompletelyStart = () => {
  return {
    type: types.DELETE_USER_COMPLETELY_START,
  };
};

const deleteUserCompletelySuccess = () => {
  return {
    type: types.DELETE_USER_COMPLETELY_SUCCESS,
  };
};

const deleteUserCompletelyFail = () => {
  return {
    type: types.DELETE_USER_COMPLETELY_FAIL,
  };
};

export const deleteUserCompletely = (userId) => {
  return (dispatch) => {
    dispatch(deleteUserCompletelyStart());

    return apiClient
      .getUserApi()
      .userApiDeleteUserCompletely(userId)
      .then((resp) => {
        dispatch(deleteUserCompletelySuccess({ data: resp.data }));
        return resp.data;
      })
      .catch(() => {
        dispatch(deleteUserCompletelyFail());
      });
  };
};
