import * as React from 'react';
import { FormLabel, FormControl } from '@mui/material';
import RichTextEditor from '../RichTextEditor/index';

const IncorrectSolution = ({ question, onChange, title, value }) => {
  const handleChange = (e) => {
    onChange({
      ...value,
      correct_text: e.html,
    });
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit', paddingBottom: '8px' }}
      >
        {title}
      </FormLabel>
      <RichTextEditor
        value={value?.correct_text}
        onChange={handleChange}
        metaData={{
          tag_id: question.data.tag_id,
          question_id: question.data?.id,
        }}
      />
    </FormControl>
  );
};

export default IncorrectSolution;
