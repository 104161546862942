import {
  Button,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { useEffect } from 'react';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FieldItem,
  FormWrapper,
  TagsForm,
  FormControl,
} from '../Domains/StyledComponents';

import {
  getAdminCoupons,
  postAdminCoupons,
  putAdminCoupons,
} from '../../actions/adminCoupons';
import moment from 'moment';
import { getProductsList } from '../../actions/storeAdmin';

const CreateCoupon = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    adminCouponsData,
    isPostingAdminCoupons,
    isUpdatingAdminCoupons,
  } = useSelector((state) => state.AdminCoupons);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const selectedcoupon = adminCouponsData?.coupons?.find(
    (data) => data.id === params.couponId,
  );
  const { productsList = [] } = useSelector((state) => state.storeAdmin);

  useEffect(() => {
    dispatch(getAdminCoupons());
    dispatch(getProductsList());
  }, []);

  useEffect(() => {
    if (params.couponId) {
      reset({
        code: selectedcoupon?.code,
        coupon_type_use: selectedcoupon?.coupon_type_use,
        expire_on: moment(selectedcoupon?.expire_on).format('YYYY-MM-DD'),
        percent_discount: selectedcoupon?.percent_discount,
        item_id: selectedcoupon?.item_id,
        is_active: selectedcoupon?.is_active?.toString(),
      });
    }
  }, [params.couponId]);

  const onSubmitHandler = (data) => {
    if (params.couponId) {
      dispatch(putAdminCoupons(params.couponId, data));
    } else {
      dispatch(postAdminCoupons(data));
    }
    setTimeout(() => {
      navigate('/l/admin/coupon');
    }, 2000);
  };
  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.couponId ? 'Create Coupons' : 'Edit Coupons'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('code', {
                        required: 'Please enter the Code',
                      })}
                      name="code"
                      label={'Code'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.code}
                      helperText={errors.code?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>

                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('coupon_type_use', {
                        required: 'Please select a coupon use type',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Use Type"
                      error={!!errors.coupon_type_use}
                      defaultValue={
                        params.couponId
                          ? selectedcoupon?.coupon_type_use
                          : 'SingleUse'
                      }
                    >
                      <MenuItem value="SingleUse" key="SingleUse">
                        SingleUse
                      </MenuItem>
                      <MenuItem value="MultiUse" key="MultiUse">
                        MultiUse
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('is_active', {
                        required: 'Please select a coupon use type',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Is Active"
                      error={!!errors.is_active}
                      defaultValue={
                        params.couponId
                          ? selectedcoupon?.is_active?.toString()
                          : 'false'
                      }
                    >
                      <MenuItem value={'true'} key={'true'}>
                        True
                      </MenuItem>
                      <MenuItem value={'false'} key={'false'}>
                        False
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>

                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('expire_on', {
                        required: 'Please enter the expiry date',
                      })}
                      name="expire_on"
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.expire_on}
                      helperText={errors.expire_on?.message}
                      fullWidth
                      type="date"
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('percent_discount', {
                        required: 'Please enter percent discount',
                        valueAsNumber: true,
                      })}
                      name="percent_discount"
                      label={'Percent Discount'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.percent_discount}
                      helperText={errors.percent_discount?.message}
                      fullWidth
                      type="tel"
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('item_id', {
                        required: 'Please select a coupon use type',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Use Type"
                      error={!!errors.item_id}
                      defaultValue={
                        params.couponId
                          ? selectedcoupon?.item_id
                          : productsList[0]?.id
                      }
                    >
                      {productsList?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FieldItem>
                </FormControl>

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isPostingAdminCoupons || isUpdatingAdminCoupons}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isPostingAdminCoupons || isUpdatingAdminCoupons ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default CreateCoupon;
