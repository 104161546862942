import * as React from 'react';
import { Typography, Tooltip, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import { months, colors } from '../../constants/userProfile';

import {
  CalenderContainer,
  CalenderDaysContainer,
  CalenderDay,
  CalenderDatesContainer,
  CalenderDate,
  CalenderBadgeTooltip,
} from './UserProfileStyledComponent';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const getFirstDayOfTheMonth = (month, year) => {
  return new Date(year, month, 1).getDay();
};

const getLastDateOfTheMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getStyle = (count, theme) => {
  if (count === 0) {
    return {
      backgroundColor: colors[0],
      color: theme.palette.primary.main,
      fontWeight: '500',
    };
  } else if (count <= 20) {
    return {
      backgroundColor: colors[1],
      color: theme.palette.primary.main,
      fontWeight: '500',
    };
  } else if (count <= 40) {
    return {
      backgroundColor: colors[2],
      color: 'white',
      fontWeight: '500',
    };
  } else if (count <= 60) {
    return {
      backgroundColor: colors[3],
      color: 'white',
      fontWeight: '500',
    };
  } else if (count > 60) {
    return {
      backgroundColor: colors[4],
      color: 'white',
      fontWeight: '500',
    };
  }
};

const DailyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.text,
    fontSize: 11,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
}))(Tooltip);

const Calender = ({ month, year, activity }) => {
  const theme = useTheme();

  const dates = [...Array(getLastDateOfTheMonth(month, year)).keys()].map(
    (date) => ({
      date: date + 1,
      style: {
        color: theme.palette.primary.main,
      },
    }),
  );
  if (activity) {
    const stats = Object.keys(activity);
    stats.forEach((date) => {
      if (date === 'overall') return;
      const d = new Date(date).getDate();
      if (dates[d - 1]) {
        dates[d - 1].style = getStyle(activity[date].questions_seen, theme);
        dates[d - 1].question_seen = activity[date].questions_seen;
        dates[d - 1].accuracy = activity[date].accuracy;
        dates[d - 1].total_correct = activity[date].total_correct;
        dates[d - 1].avg_time_taken = activity[date].avg_time_taken;
      }
    });
  }

  const firstDay = getFirstDayOfTheMonth(month, year);

  return (
    <CalenderContainer>
      <CalenderDaysContainer>
        {days.map((_, i) => (
          <CalenderDay key={i} color="primary">
            {days[(i + firstDay) % 7]}
          </CalenderDay>
        ))}
      </CalenderDaysContainer>
      <CalenderDatesContainer>
        {dates.map((date) => (
          <DailyTooltip
            key={date.date}
            enterTouchDelay={50}
            placement="top"
            title={
              <CalenderBadgeTooltip>
                <Typography
                  color="primary"
                  variant="h6"
                >{`${months[month]} ${date.date}, ${year}`}</Typography>
                <Box style={{ display: 'flex', gap: '5px' }}>
                  <Typography>Accuracy:</Typography>
                  <Typography color="primary">
                    <b>{+date.accuracy?.toFixed(2) || 0}%</b>
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '5px' }}>
                  <Typography>Correct questions:</Typography>
                  <Typography color="primary">
                    <b>{date.total_correct || 0}</b>
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '5px' }}>
                  <Typography>Questions attempted: </Typography>
                  <Typography color="primary">
                    <b>{date.question_seen || 0}</b>
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '5px' }}>
                  <Typography>Average time taken: </Typography>
                  <Typography color="primary">
                    <b>{date.avg_time_taken || 0}s</b>
                  </Typography>
                </Box>
              </CalenderBadgeTooltip>
            }
          >
            <CalenderDate style={date?.style}>{date.date}</CalenderDate>
          </DailyTooltip>
        ))}
      </CalenderDatesContainer>
    </CalenderContainer>
  );
};

export default Calender;
