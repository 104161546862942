import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Material UI Imports
import {
  AppBar,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Other Components Imports
import Card from './../Card';
import Domain from './../Domains';
import Tags from './../Tags';
import Filters from './../Filters';
import BlockModal from './BlockModal';
import AcceptModal from './AcceptModal';
import MessageBar from '../MessageBar';
import helpers from './../../lib/helpers';
import ReviewQuestionCard from './ReviewQuestionCard';
import { getUnderReviewQuestions } from '../../actions/question';

//Third Party Libraries
import moment from 'moment';
import UserSearchField from './UserSearchField';
import ReactSelect from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ReviewQuestions(props) {
  const useStyles = makeStyles(() => ({
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    endContent: {
      boxShadow: 'none',
    },
    grow: {
      flexGrow: 1,
    },
    reviewQuestionContainer__header: {
      fontSize: '18px',
    },
    flexCentre: {
      display: 'flex',
      alignItems: 'center',
    },
    noContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: '20px',
    },
    reviewQuestionContainer: {
      padding: '10px 30px 10px 30px',
      overflow: 'auto',
    },
    dateRange: {
      display: 'flex',
      alignItems: 'center',
    },
    cardFilterWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      justifyContent: 'space-between',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const [questionStatus, setQuestionStatus] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [selectedTag, setSelectedTag] = useState('all');
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [sortOrder, setSortOrder] = useState('desc');
  const [questionType, setQuestionType] = useState('');
  const [selectedUser, setSelectedUser] = useState(() => ({
    label: '',
    id: '',
  }));
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [reviewFilters, setReviewFilters] = useState({
    Active: false,
    UnderReview: true,
    Blocked: false,
    withHint: false,
    withExam: false,
  });
  const [dateRange, setDateRange] = useState(() => ['', '']);
  const [isBlockDialogVisible, setIsBlockDialogVisible] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(['UnderReview']);
  const [selectedQuesIds, setSelectedQuesIds] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [currentPage, setCurrentPage] = useState(() => 1);
  const [acceptModalOpen, setAcceptModalOpen] = useState(() => false);
  const [filteredQ, setFilteredQ] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [actOnQuestion, setActOnQuestion] = useState(false);

  useEffect(() => {
    if (props.question.isPutReviewQuestionSuccess) {
      if (questionStatus === 'Active') {
        setIsStatusMessageVisible(true);
        setStatus('success');
        setMessage(
          `Congrats! ${selectedQuesIds.length} Question is accepted successfully.`,
        );
        var index;
        questionId?.map?.((ques) => {
          props.question.reviewQuestionsList.map((item) => {
            if (item.id === ques) {
              index = props.question.reviewQuestionsList.indexOf(item);
              props.question.reviewQuestionsList.splice(index, 1);
            }
          });
        });
        setSelectedQuesIds([]);
      } else {
        setIsStatusMessageVisible(true);
        setStatus('error');
        setMessage(`${selectedQuesIds.length} question(s) have been blocked.`);
        questionId?.map?.((ques) => {
          props.question.reviewQuestionsList.map((item) => {
            if (item.id === ques) {
              index = props.question.reviewQuestionsList.indexOf(item);
              props.question.reviewQuestionsList.splice(index, 1);
            }
          });
        });
        setSelectedQuesIds([]);
      }
    }
  }, [props.question.isPutReviewQuestionSuccess]);

  useEffect(() => {
    if (props.question.isPutReviewQuestionFail) {
      setIsStatusMessageVisible(true);
      setStatus('error');
      setMessage('Sorry! failed to submit review');
    }
  }, [props.question.isPutReviewQuestionFail]);

  useEffect(() => {
    setSelectedQuesIds([]);
    dispatch(
      getUnderReviewQuestions(
        currentPage,
        10,
        selectedDomain === 'all' ? undefined : selectedDomain,
        'date',
        sortOrder === 'all' ? undefined : sortOrder,
        selectedTag === 'all' ? undefined : selectedTag,
        appliedFilters.length > 0
          ? appliedFilters
          : ['Active', 'Blocked', 'UnderReview'],
        selectedUser?.id || '',
        1,
        dateRange[0].substring(0, 10),
        dateRange[1].substring(0, 10),
        questionType,
        reviewFilters?.['withHint'] ? true : null,
        reviewFilters?.['withExam'] ? true : null,
        (newData) => {
          setTotalPage(newData.total_pages);
          setFilteredQ((oldQuestion) => [...oldQuestion, ...newData.data]);
        },
      ),
    );
  }, [currentPage, trigger]);

  const setBlockDialogVisibility = (isBlockDialogVisible) => {
    setIsBlockDialogVisible(isBlockDialogVisible);
  };

  const getQuestionsIdOnClick = (ques, status) => {
    setQuestionId(ques);
    setQuestionStatus(status);
  };

  const postAccepted = (questionIds) => {
    let result = [];
    if (questionIds.length) {
      questionIds.map((ques) => {
        result.push({
          question_id: ques,
          question_status: 'Active',
        });
      });
    }
    if (result.length) {
      props.putBulkReviewQuestion({
        body: result,
      });
      getQuestionsIdOnClick(questionIds, 'Active');
      dispatchListUpdate();
      takeAction();
    }
  };
  const dispatchListUpdate = () => {
    setCurrentPage(currentPage);
    setFilteredQ(filteredQ);
    setTrigger((prevTrigger) => !prevTrigger);
  };
  const takeAction = () => {
    setActOnQuestion(true);
  };

  useEffect(() => {
    if (actOnQuestion) {
      const removeQuestion = () => {
        setFilteredQ(
          [...filteredQ].filter(
            (question) => !selectedQuesIds.includes(question.id),
          ),
        );
      };
      removeQuestion();
      setActOnQuestion(false);
    }
  }, [actOnQuestion]);

  const cardHeader = (
    <Box className={classes.cardFilterWrapper}>
      <div className={classes.reviewQuestionContainer__header}>
        Review Questions List
      </div>
      <div className={classes.flexCentre}>
        <BlockModal
          selectedQuestions={selectedQuesIds}
          putBulkReviewQuestion={props.putBulkReviewQuestion}
          getQuestionsIdOnClick={getQuestionsIdOnClick}
          open={isBlockDialogVisible}
          onClose={() => setBlockDialogVisibility(false)}
          takeAction={takeAction}
          props={props}
        />
        {acceptModalOpen && (
          <AcceptModal
            selectedQuesIds={selectedQuesIds}
            open={acceptModalOpen}
            onClose={() => setAcceptModalOpen(false)}
            setSelectedQuesIds={setSelectedQuesIds}
            postAccepted={postAccepted}
          />
        )}
      </div>
    </Box>
  );

  const SortOptions = [
    { value: 'desc', label: 'Newest first' },
    { value: 'asc', label: 'Oldest first' },
  ];

  const questionTypeOptions = [
    { value: 'OneWord', label: 'One Word' },
    { value: 'MultipleChoice', label: 'Multiple Choice' },
    { value: 'MultipleCorrect', label: 'Multiple Correct' },
    { value: 'MultiPart', label: 'Multiple Part' },
  ];

  const domains = useSelector((state) => state.domain.list);
  const category_by_domain = useSelector(
    (state) => state.tag.category_by_domain,
  );

  const { getCategoryList } = helpers;
  const category_list =
    selectedDomain && selectedDomain != 'all'
      ? category_by_domain[selectedDomain]
      : [];
  const filtertagList = getCategoryList(category_list, false);

  const handleFilterChange = (name) => (event) => {
    const reviewFilter = { ...reviewFilters };
    reviewFilter[name] = event.target.checked;

    const appliedFilters = Object.keys(reviewFilter).filter(
      (status) => reviewFilter[status],
    );
    setReviewFilters(reviewFilter);
    setAppliedFilters(appliedFilters);
  };

  const fetchFilteredQ = () => {
    setCurrentPage(1);
    setFilteredQ([]);
    setTrigger((prevTrigger) => !prevTrigger);
  };

  const handleTagChange = (tag) => {
    setSelectedTag(tag ? tag.id : '');
  };

  const handleDomainChange = (e) => {
    setSelectedTag('all');
    setSelectedDomain(e ? e.id : 'all');
  };

  const handleSortOrderChange = (time) => {
    setSortOrder(time ? time.value : '');
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionType(type ? type.value : '');
  };

  const cardFilters = (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} md={3}>
          <label htmlFor="">Filter by domain</label>
          <Box marginTop="10px">
            <Domain
              placeholder="Select domain"
              items={[{ id: 'all', name: 'All' }, ...domains]}
              selectedDomain={selectedDomain}
              onChange={handleDomainChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <label htmlFor="">Filter by category</label>
          <Box marginTop="10px">
            <Tags
              placeholder="Select category"
              items={[{ id: 'all', name: 'All' }, ...filtertagList]}
              selectedTag={selectedTag}
              onChange={handleTagChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <label htmlFor="">Filter by user</label>
          <Box marginTop="10px">
            <UserSearchField
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <label htmlFor="">Sort by</label>
          <Box marginTop="10px">
            <ReactSelect
              options={SortOptions}
              onChange={handleSortOrderChange}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={SortOptions.find((c) => c.value === sortOrder)}
              placeholder="Select sort order"
              isClearable
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <label htmlFor="">Question Type</label>
          <Box marginTop="10px">
            <ReactSelect
              options={questionTypeOptions}
              onChange={handleQuestionTypeChange}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={questionTypeOptions.find((c) => c.value === questionType)}
              placeholder="Select a question type"
              isClearable
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <label htmlFor="">Date Range</label>
          <Box marginTop="10px" className={classes.dateRange}>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  error: false,
                },
              }}
              value={moment(dateRange[0])}
              onChange={(value) =>
                setDateRange((prevState) => [
                  moment(value).toISOString(true),
                  prevState[1],
                ])
              }
            />
            &nbsp;&nbsp;to&nbsp;&nbsp;
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  error: false,
                },
              }}
              value={moment(dateRange[1])}
              onChange={(value) =>
                setDateRange((prevState) => [
                  prevState[0],
                  moment(value).toISOString(true),
                ])
              }
            />
            <Button onClick={() => setDateRange(['', ''])}>Clear</Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={9}>
          <Filters
            heading="Filter by status"
            type={'checkboxes'}
            handleChange={handleFilterChange}
            filterValues={reviewFilters}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ padding: '0' }}>
          <Button color="primary" variant="outlined" onClick={fetchFilteredQ}>
            Apply Filters
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ margin: '20px 0px' }} />
    </>
  );

  const handleSelectedQues = (id) => {
    setSelectedType('');
    setSelectedQuesIds([...selectedQuesIds, id]);
  };

  const arrayRemove = (arr, value) => {
    return arr.filter(function(ele) {
      return ele !== value;
    });
  };

  const handleDeselectedQues = async (id) => {
    let result = await arrayRemove(selectedQuesIds, id);
    setSelectedQuesIds(result);
  };

  const handleAccepted = async (questionIds = [], selectedType) => {
    setSelectedType(selectedType);
    setSelectedQuesIds(questionIds);
    setAcceptModalOpen(true);
  };

  const handleBlockForSingleQuestion = (questionId, selectedType) => {
    setSelectedQuesIds(questionId);
    setSelectedType(selectedType);
    setBlockDialogVisibility(true);
  };

  const getQuestionIdOnClick = (id, status) => {
    setQuestionId(id);
    setQuestionStatus(status);
  };

  const renderCardRow = (question, index) => {
    const tagList = [];
    const { tag } = props;
    const domains = Object.keys(tag.category_by_domain);
    domains.map((domain) => {
      tag.category_by_domain[domain].map((option) => {
        tagList.push(option);
      });
    });

    return (
      <ReviewQuestionCard
        getQuestionIdOnClick={getQuestionIdOnClick}
        canceled={canceled}
        handleSelectedQues={handleSelectedQues}
        handleDeselectedQues={handleDeselectedQues}
        handleBlockForSingleQuestion={handleBlockForSingleQuestion}
        question={question}
        index={index}
        props={props}
        currentPage={currentPage}
        tagList={tagList}
        selectedTag={selectedTag}
        selectedDomain={selectedDomain}
        appliedFilters={appliedFilters}
        selectedUser={selectedUser}
        selectedQuesIds={selectedQuesIds}
        handleAccepted={handleAccepted}
      />
    );
  };

  const handleCancelAppBar = () => {
    setCanceled(true);
    setSelectedQuesIds([]);
    setCanceled(false);
  };

  return (
    <Container
      className={classes.reviewQuestionContainer}
      maxWidth={false}
      id="scrollableContainer"
    >
      {cardHeader}
      <Card
        content={
          <Fragment>
            {cardFilters}
            {filteredQ?.length > 0 ? (
              <InfiniteScroll
                dataLength={filteredQ.length}
                next={() => {
                  setCurrentPage((page) => page + 1);
                }}
                hasMore={currentPage < totalPage + 1}
                loader={
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                }
                scrollThreshold={0.9}
                style={{ overflow: 'none' }}
                scrollableTarget="scrollableContainer"
              >
                {filteredQ.map(renderCardRow)}
              </InfiniteScroll>
            ) : (
              <p className={classes.noContent}>
                Hooray! No questions for review.
              </p>
            )}
          </Fragment>
        }
      />
      <MessageBar
        type={status}
        open={isStatusMessageVisible}
        onClose={() => {
          setIsStatusMessageVisible(false);
        }}
        message={message}
      />

      {selectedQuesIds.length > 0 && selectedType !== 'single' ? (
        <AppBar position="sticky" color="inherit" className={classes.appBar}>
          <Toolbar>
            <Typography>
              <strong>{selectedQuesIds.length}</strong>{' '}
              {selectedQuesIds.length > 1 ? 'questions are' : 'question is'}{' '}
              selected
            </Typography>
            <div className={classes.grow} />
            <Paper className={classes.endContent}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                direction="row-reverse"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<DoneIcon />}
                    onClick={() => {
                      handleAccepted(selectedQuesIds, '');
                    }}
                  >
                    Accept
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<ClearIcon />}
                    color="error"
                    onClick={() => setBlockDialogVisibility(true)}
                  >
                    Block
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={handleCancelAppBar}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Toolbar>
        </AppBar>
      ) : null}
    </Container>
  );
}
