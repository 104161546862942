import { styled } from '@mui/styles';
import {
  Box,
  Button,
  Chip,
  Container,
  Select,
  Typography,
} from '@mui/material';

export const EmailingListWrapper = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: 'white',
  borderRadius: '10px',
  paddingTop: '20px',
});

export const EmailingListHeader = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: 'black',
});

export const EmailingListPlaceholder = styled('div')({
  color: '#a2a2a2',
});

export const EmailingListSelects = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px 2%',
  justifyContent: 'space-between',
});

export const EmailingListSelect = styled(Select)({
  minWidth: '300px',
  borderRadius: '5px',
});

export const EmailingListInputWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export const EmailingListLabel = styled('label')({
  padding: '4px 0px',
});

export const EmailingListArguments = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '5px',
});

export const EmailingListAttachmentDisplay = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  wordBreak: 'break-word',
  boxShadow: '0 0 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '10px 10px',
});

export const EmailingListButtonCollection = styled(Box)({
  display: 'flex',
  gap: '5px',
  flexWrap: 'wrap',
  '@media screen and (max-width:830px)': {
    flexDirection: 'column',
  },
});

{
  /* Cannot use custom camel-cased attributes for dom elements 😭 */
}
export const EmailingListButton = styled(Button)(
  ({ customcolor, custommarginleft }) => ({
    color: customcolor,
    border: `1px solid ${customcolor}`,
    float: 'none',
    boxSizing: 'border-box',
    marginLeft: custommarginleft || 0,
    '@media screen and (max-width:830px)': {
      marginLeft: 0,
    },
  }),
);

export const EmailingListModalWrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '50px',
  backgroundColor: 'white',
  width: '400px',
  padding: '15px 10px',
  boxShadow: '0 0 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
});

// Preview modal styles

export const PreviewModalWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100vh',
  overflowY: 'scroll',
  backgroundColor: 'white',
  padding: '30px 30px',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

export const PreviewModalHeader = styled(Typography)({
  color: '#25507B',
  fontSize: '18px',
});

export const PreviewModalCloseButton = styled(Button)({
  position: 'absolute',
  top: '5px',
  right: '5px',
});

export const PreviewModalSubject = styled('span')({
  color: '#25507B',
  fontSize: '16px',
  fontWeight: 500,
});

export const PreviewModalButtonGroup = styled(Box)({
  display: 'flex',
});

export const SubtractEmailText = styled('span')({
  color: '#D60505',
  borderRadius: '3px',
  border: '1px solid #D60505',
  background: 'rgba(214, 5, 5, 0.05)',
  padding: '5px 10px',
  fontSize: '10px',
  fontWeight: 400,
});

export const ClearAllText = styled('span')({
  textAlign: 'end',
  color: '#D60505',
  fontSize: '14px',
});

export const ToggleBox = styled(Box)(({ theme }) => ({
  height: '45px',
  width: 'max-content',
  background: theme.palette.common.white,
  borderRadius: '25px',
  boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
  margin: 'auto',
}));
export const ToggleText = styled(Typography)({
  fontSize: '18px',
  margin: 'auto',
  padding: '0px 20px 0px 20px',
  borderRadius: '25px',
  ['&:hover']: {
    cursor: 'pointer',
  },
});
export const LogsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '24px',
  fontWeight: 600,
}));

export const AdminFeatureChip = styled(Chip)({
  background: '#8000800D',
  border: '1px solid #800080',
  color: '#800080',
  fontSize: '18px',
  fontWeight: 500,
});

export const RecordCount = styled(Typography)({
  width: '30px',
  height: '30px',
  background: '#3572B01A',
  color: '#25507B',
  borderRadius: '50%',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Heading = styled('span')({
  fontSize: '16px',
  color: '#25507B',
});
export const DeleteBtn = styled(Button)({
  border: '1px solid #D60505',
  color: '#D60505',
  textTransform: 'none',
  padding: '2px 10px',
});
export const MoreDetailsBtn = styled(Button)({
  border: '1px solid #25507B',
  color: '#25507B',
  textTransform: 'none',
  padding: '2px 10px',
});
