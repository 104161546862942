import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TablePagination,
  Tooltip,
  Typography,
  Avatar,
  adaptV4Theme,
} from '@mui/material';
import * as React from 'react';
import Select from 'react-select';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import Diamond from '../../images/Gemsnew.svg';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import Tick from './images/TickVector.svg';
import Filters from '../Filters';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import Card from './../Card';
import CategorySelector from './CategorySelector.jsx';
import EmptySlider from './EmptySlider';
import './index.scss';
import { withRouter } from '../../routes';
import List from './List';
import { useNavigate } from 'react-router-dom';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => (
  <WrappedComponent {...props} width="xs" />
);

const styles = {
  caption: {
    fontSize: '1rem',
  },
  selectRoot: {
    marginRight: '2px',
    fontSize: '1rem',
  },
  iconButtonRoot: {
    padding: 0,
  },
};
const QuestionList = (props) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [questionFilters, setQuestionFilters] = React.useState({
    Active: false,
    UnderReview: false,
    Blocked: false,
    Draft: false,
  });
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [isStatusMessageVisible, setIsStatusMessageVisible] = React.useState(
    false,
  );
  const [status, setStatus] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [selectedTag, setSelectedTag] = React.useState('all');
  const [showModal, setShowModal] = React.useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = React.useState(null);
  // const [deletedQuestionsId, setDeletedQuestionsId] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [openCategoryModal, setOpenCategoryModal] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const url = new URL(window.location.href);
    const query_string = url.search;
    const search_params = new URLSearchParams(query_string);
    const tag = search_params.get('tag');
    const status = search_params.get('status');
    const page = search_params.get('page');
    const pageSize = search_params.get('pageSize');
    const questionFilter = { ...questionFilters };
    var questionStatus = [];
    if (page) {
      setPage(page);
    }
    if (pageSize) {
      setRowsPerPage(pageSize);
    }
    if (tag) {
      setSelectedTag(tag);
    }
    if (status) {
      questionStatus = status.split(',');
      questionStatus.forEach((item) => {
        questionFilter[item] = true;
      });
    }
    setAppliedFilters(questionStatus);
    setQuestionFilters(questionFilter);
    getFilteredQuestionList(page, rowsPerPage, appliedFilters, selectedTag);
    setOpenCategoryModal(
      localStorage.getItem('openCategoryModal') === 'false' ? false : true,
    );
  }, []);

  React.useEffect(() => {
    if (selectedCategory?.id) props.getUserMilestone(selectedCategory?.id);
  }, [selectedCategory.id]);

  React.useEffect(() => {
    if (props.question?.isDeleteSuccess) {
      getFilteredQuestionList(1, rowsPerPage, appliedFilters, selectedTag);
      setIsStatusMessageVisible(true);
      setStatus('success');
      setMessage('Question is deleted successfully.');
    } else if (props.question?.isDeleteFail) {
      setIsStatusMessageVisible(true);
      setStatus('error');
      setMessage('Sorry! question is not deleted, please try again.');
    }
  }, [props.question?.isDeleteSuccess, props.question?.isDeleteFail]);

  const getFilteredQuestionList = (
    page,
    page_size,
    question_status,
    selectedTag,
  ) => {
    const { getQuestionList, user } = props;
    getQuestionList({
      page,
      page_size,
      question_status,
      tag_id: selectedTag,
      domain_id: user.selected_domain,
    });
  };

  const handleChangeSelectedCategory = (newCategory) => {
    if (newCategory) setSelectedCategory(newCategory);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    navigate({
      pathname: '/l/question',
      search: `?page=${page}&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(
      newPage + 1,
      rowsPerPage,
      appliedFilters,
      selectedTag,
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    navigate({
      pathname: '/l/question',
      search: `?page=1&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(1, rowsPerPage, appliedFilters, selectedTag);
  };

  const handleDeleteClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (showModal) {
      const { deleteQuestion } = props;
      deleteQuestion({ questionId: deleteQuestionId });
      setDeleteQuestionId([...deleteQuestionId, deleteQuestionId]);
      setShowModal(false);
    }
  };

  const handleDeleteAction = (questionId) => {
    setShowModal(true);
    setDeleteQuestionId(questionId);
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const handleRemoveFilter = () => {
    setQuestionFilters({
      Active: false,
      UnderReview: false,
      Blocked: false,
      Draft: false,
    });
    setAppliedFilters([]);
    navigate({
      pathname: '/l/question',
      search: `?page=1&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(1, rowsPerPage, [], selectedTag);
  };

  const handleFilterChange = (name) => () => {
    let questionFilters = {
      Active: false,
      UnderReview: false,
      Blocked: false,
      Draft: false,
    };
    questionFilters[name] = true;
    const appliedFilters = Object.keys(questionFilters).filter(
      (status) => questionFilters[status],
    );
    setQuestionFilters(questionFilters);
    setAppliedFilters(appliedFilters);
    navigate({
      pathname: '/l/question',
      search: `?page=1&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(1, rowsPerPage, appliedFilters, selectedTag);
  };

  const onClickAddQuestion = () => {
    setOpenCategoryModal(true);
  };

  const closeCategoryModal = () => {
    setOpenCategoryModal(false);
  };

  const { classes, width } = props;
  const isSmall = width == 'xs';
  const { question, tag, setCreateQuestionType } = props;

  let { questionList, questionPaginationInfo, isListLoading } = question;
  const cardFilters = (
    <div className="filter-section">
      <Filters
        heading="Filters"
        type={'slider'}
        handleChange={handleFilterChange}
        handleRemoveFilter={handleRemoveFilter}
        filterValues={questionFilters}
      />
    </div>
  );

  let cardContent;
  if (isListLoading) {
    cardContent = (
      <div className="no-content">
        <Loader size={44} color="primary" />
      </div>
    );
  } else if (!questionList.length && !appliedFilters.length) {
    cardContent = (
      <React.Fragment>
        <div className="no-content">
          {openCategoryModal ? (
            <CategorySelector
              tag={tag}
              domainList={props.domain.list}
              options={tag.list}
              setCreateQuestionType={setCreateQuestionType}
              userSelectedDomain={props.user.selected_domain}
              handleChangeSelectedCategory={handleChangeSelectedCategory}
              selectedCategory={selectedCategory}
            />
          ) : (
            <EmptySlider AddQuestion={onClickAddQuestion} />
          )}
        </div>
      </React.Fragment>
    );
  } else {
    cardContent = openCategoryModal ? (
      <CategorySelector
        tag={tag}
        domainList={props.domain.list}
        options={tag.list}
        setCreateQuestionType={setCreateQuestionType}
        userSelectedDomain={props.user.selected_domain}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        selectedCategory={selectedCategory}
      />
    ) : (
      <>
        {cardFilters}
        <List
          questionList={questionList}
          handleDeleteAction={handleDeleteAction}
          tag={props.tag}
        />
        <TablePagination
          component="nav"
          page={questionPaginationInfo.currentPage}
          rowsPerPage={rowsPerPage}
          count={
            questionPaginationInfo.pageSize * questionPaginationInfo.totalPages
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isSmall ? 'Rows' : 'Rows Per Page'}
          classes={{
            caption: classes?.caption,
            selectRoot: classes?.selectRoot,
          }}
          nextIconButtonProps={{ classes: { root: classes.iconButtonRoot } }}
          backIconButtonProps={{ classes: { root: classes.iconButtonRoot } }}
        />
        <MessageBar
          type={status}
          open={isStatusMessageVisible}
          onClose={handleMessageClose}
          message={message}
        />
      </>
    );
  }

  const TabSelector = () => {
    return (
      <div className="tabSelector">
        <div
          className={openCategoryModal ? 'tabSelected' : ''}
          onClick={onClickAddQuestion}
        >
          <Typography variant="h6">Create Question</Typography>
        </div>
        <div
          className={openCategoryModal ? '' : 'tabSelected'}
          onClick={closeCategoryModal}
        >
          <Typography variant="h6">Question List</Typography>
        </div>
      </div>
    );
  };

  function isMobileScreen() {
    const mediaQuery = window.matchMedia('(max-width: 767px)');

    if (mediaQuery.matches) {
      return true;
    } else {
      return false;
    }
  }

  const Milestone = () => {
    const milestone = question.milestone;
    const roadMap = milestone.milestone_mapping;
    const filteredTempRoadMap = roadMap.filter(
      (r) => r?.question_count <= milestone.question_created,
    );
    const filteredRoadMap = filteredTempRoadMap
      .concat(filteredTempRoadMap.slice(-1))
      .slice(-3, -1);
    const unachievedRoadMap = roadMap
      .filter((r) => r?.question_count > milestone.question_created)
      .slice(0, isMobileScreen() ? 2 : 5);
    const tempPoints = filteredRoadMap.concat(unachievedRoadMap);
    let occupied = 0;
    const displayPoints = tempPoints.map((d) => {
      let percentage =
        (d?.question_count /
          tempPoints[tempPoints.length - 1]?.question_count) *
        100;
      let finalPercentage = percentage - occupied;
      occupied = percentage;
      return { ...d, mL: finalPercentage };
    });
    const max = unachievedRoadMap.length;

    const defaultTheme = createTheme();
    const theme = createTheme(
      adaptV4Theme({
        overrides: {
          MuiTooltip: {
            tooltip: {
              color: 'rgba(0,0,0,0.7)',
              backgroundColor: 'white',
              maxWidth: '680px',
            },
          },
        },
      }),
    );
    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#25507B',
      }),
    };
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <ThemeProvider theme={theme}>
            <div className="milestoneContainer">
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item sm={3} xs={12}>
                  <Select
                    className="select"
                    styles={customStyles}
                    value={selectedCategory}
                    options={tag.list}
                    onChange={(e) => {
                      setSelectedCategory(e);
                    }}
                    getOptionLabel={(option) => (
                      <Grid
                        wrap="nowrap"
                        container
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Avatar
                            alt={`${option.name}`}
                            src={option.s3_url}
                            style={{
                              height: 24,
                              width: 24,
                            }}
                          />
                        </Grid>
                        <Grid item>{option.name}</Grid>
                      </Grid>
                    )}
                    getOptionValue={(option) => option.id}
                  />
                </Grid>
                <Grid item sm={9} xs={12}>
                  <div className="progressContainer">
                    {/* {roadMap[max-1].question_count} */}
                    <div className="milestoneHolder">
                      {displayPoints &&
                        displayPoints.map((d, index) => (
                          <Tooltip
                            key={index}
                            interactive={'true'}
                            enterTouchDelay={50}
                            title={
                              <div className="progressTooltipContainer">
                                <Grid
                                  container
                                  className="tooltipTopSection"
                                  spacing={3}
                                >
                                  <Grid item xs={6}>
                                    <span className="milestoneText">
                                      Milestone Target
                                    </span>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div className="progressQuestionCount">
                                      <span className="progressQuestionCountText1">
                                        {d.question_count}
                                      </span>{' '}
                                      <br />
                                      <span>Questions</span>
                                    </div>
                                  </Grid>
                                </Grid>
                                <hr />
                                <div className="tooltipBottomSection">
                                  <div className="tooltipBottomSectionText">
                                    Conquer and Earn Rewards
                                  </div>

                                  {d.credits === 0 && d.gems === 0 ? (
                                    <Grid container spacing={3}>
                                      <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <img
                                          src={d.img_url}
                                          height="45px"
                                          width="45px"
                                        />
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <img
                                          src={d.img_url}
                                          height="45px"
                                          width="45px"
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        {d.credits !== 0 && (
                                          <Grid
                                            container
                                            spacing={1}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Grid item>
                                              <img
                                                src={CreditIcon}
                                                width="20px"
                                                height="20px"
                                              />
                                            </Grid>
                                            <Grid item>
                                              <span className="tooltipGemsCount">
                                                {d.credits}
                                              </span>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {d.gems !== 0 && (
                                          <Grid
                                            container
                                            spacing={1}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Grid item>
                                              <img
                                                src={Diamond}
                                                width="20px"
                                                height="20px"
                                              />
                                            </Grid>
                                            <Grid item>
                                              <span className="tooltipGemsCount">
                                                {d.gems}
                                              </span>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                                </div>
                              </div>
                            }
                            arrow
                            placement="bottom-end"
                          >
                            <div
                              className="milestoneAchieved"
                              style={{
                                marginLeft: `calc(${d.mL}% - 20px)`,
                              }}
                            >
                              {d?.question_count >
                                milestone.question_created && (
                                <img
                                  src={Diamond}
                                  style={{ height: '12px' }}
                                  alt="diamond"
                                />
                              )}
                              {d?.question_count <=
                                milestone.question_created && (
                                <img
                                  src={Tick}
                                  style={{ width: '12px' }}
                                  alt="diamond"
                                />
                              )}
                            </div>
                          </Tooltip>
                        ))}
                    </div>
                    <div
                      className="progress"
                      style={{
                        width: `${(milestone.question_created /
                          unachievedRoadMap[max - 1]?.question_count) *
                          100}%`,
                      }}
                    >
                      <div className="currentStatus">
                        {milestone.question_created}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="labelContainer">
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className="label"
                >
                  Category Milestone Progress:
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className="count"
                >
                  {milestone.question_created}/{milestone.next_milestone}
                </Typography>
                <Typography variant="subtitle2" className="unit">
                  Questions
                </Typography>
              </div>
            </div>
          </ThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };

  return (
    <Container className="questionListContainer" maxWidth={false}>
      {question.isMilestoneFetched && <Milestone />}
      {!(!questionList.length && !appliedFilters.length) && <TabSelector />}
      <Card content={cardContent} />
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to remove this question?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteClick(question)}
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
QuestionList.defaultProps = {};

QuestionList.propTypes = {};

export default withWidth()(withStyles(styles)(withRouter(QuestionList)));
