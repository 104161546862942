import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Select2 from '@mui/material/Select';
import {
  ArrowDownward,
  ArrowUpward,
  Check as CheckIcon,
  Close as CloseIcon,
  RemoveRedEyeOutlined,
  SearchRounded as SearchIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  editReportedBug,
  getReportedBugsDetails,
} from '../../../actions/reportBug';
import docsImg from '../../../images/doc_image.png';
import useDebounce from '../../../lib/useDebounce';
import './index.css';
const ReportedBugs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [status, setStatus] = useState('Pending');
  const [searchUser, setSearchUser] = useState('');
  const [searchIssueArea, setSearchIssueArea] = useState(undefined);
  const [typeOfIssue, setTypeOfIssue] = useState(undefined);
  const [feedbackType, setFeedbackType] = useState(undefined);
  const [sortBy, setSortBy] = useState('date');
  const [sortOrderByTime, setSortOrderByTime] = useState('desc');
  const [sortOrderByUserName, setSortOrderByUserName] = useState('desc');
  const [sortOrderByIssueArea, setSortOrderByIssueArea] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditStatusOpen, setEditStatusOpen] = useState(false);
  const [editingFeedbackId, setEditingFeedbackId] = useState('');
  const [selectedRowID, setSelectedRowID] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const { feedbacks, isFetchingBugsList } = useSelector(
    (state) => state.reportedBugs,
  );

  const debouncedValue = useDebounce(searchUser, 1000);

  useEffect(() => {
    const sortOrder =
      sortBy === 'date'
        ? sortOrderByTime
        : sortBy === 'issue_area'
        ? sortOrderByIssueArea
        : sortBy === 'fullname'
        ? sortOrderByUserName
        : 'desc';

    dispatch(
      getReportedBugsDetails(
        page + 1,
        rowsPerPage,
        status,
        sortBy,
        sortOrder,
        typeOfIssue,
        searchIssueArea,
        debouncedValue,
        feedbackType,
        minDate,
        maxDate,
      ),
    );
  }, [
    status,
    sortBy,
    sortOrderByIssueArea,
    sortOrderByTime,
    sortOrderByUserName,
    page,
    rowsPerPage,
    typeOfIssue,
    searchIssueArea,
    debouncedValue,
    feedbackType,
    minDate,
    maxDate,
  ]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleSubmitFeedbackStatus = (data) => {
    const sortOrder =
      sortBy === 'date'
        ? sortOrderByTime
        : sortBy === 'issue_area'
        ? sortOrderByIssueArea
        : sortBy === 'fullname'
        ? sortOrderByUserName
        : 'desc';
    dispatch(
      editReportedBug(editingFeedbackId, data.approval_status, () => {
        dispatch(
          getReportedBugsDetails(
            page + 1,
            rowsPerPage,
            status,
            sortBy,
            sortOrder,
            typeOfIssue,
            searchIssueArea,
            searchUser,
            feedbackType,
            minDate,
            maxDate,
          ),
        );
      }),
    );
    setEditStatusOpen(false);
  };
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        marginTop: '1rem',
      }}
      // className="collegeListContainer"
    >
      <Grid xs={12}>
        <TextField
          fullWidth
          placeholder="Search username..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon htmlColor="#25507B" />
              </InputAdornment>
            ),
          }}
          value={searchUser}
          onChange={(e) => setSearchUser(e.target.value)}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={2} xs={5}>
          <InputLabel style={{ fontSize: '14px' }} htmlFor={'typeOfIssue'}>
            {'Status'}
          </InputLabel>
          <Select
            options={[
              { id: undefined, name: 'All' },
              { id: 'Pending', name: 'Pending' },
              { id: 'Closed', name: 'Closed' },
              { id: 'Rejected', name: 'Rejected' },
            ]}
            onChange={(option) => setStatus(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={status ? status : 'Category'}
            value={status}
          />
        </Grid>
        <Grid item md={2} xs={5}>
          <InputLabel style={{ fontSize: '14px' }} htmlFor={'typeOfIssue'}>
            {'Issue Area'}
          </InputLabel>
          <Select
            options={[
              { id: undefined, name: 'All' },
              { id: 'Practise', name: 'Practise' },
              { id: 'Competition', name: 'Competition' },
              { id: 'Leaderboard', name: 'Leaderboard' },
              { id: 'Contribute', name: 'Contribute' },
              { id: 'History', name: 'History' },
              { id: 'MockTest', name: 'Mock Test' },
              { id: 'ReviewReport', name: 'Review Report' },
              { id: 'Transaction', name: 'Transaction' },
              { id: 'Profile', name: 'Profile' },
              { id: 'Other', name: 'Other' },
            ]}
            onChange={(option) => setSearchIssueArea(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={
              searchIssueArea === ''
                ? 'All'
                : searchIssueArea !== undefined
                ? searchIssueArea
                : 'Issue Type'
            }
            value={status}
          />
        </Grid>
        <Grid item md={2} xs={5}>
          {
            <InputLabel style={{ fontSize: '14px' }} htmlFor={'typeOfIssue'}>
              {'Type Of Issue'}
            </InputLabel>
          }
          <Select
            id="typeOfIssue"
            options={[
              { id: undefined, name: 'All' },
              { id: 'BrokenFeature', name: 'Broken Feature' },
              { id: 'DifficultToUse', name: 'Difficult To Use' },
              { id: 'LogicalError', name: 'Logical Error' },
              { id: 'EdgeCase', name: 'Edge Case' },
              { id: 'Other', name: 'Other' },
            ]}
            onChange={(option) => setTypeOfIssue(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={typeOfIssue ? typeOfIssue : 'All'}
            value={status}
          />
        </Grid>
        <Grid item md={2} xs={5}>
          {
            <InputLabel style={{ fontSize: '14px' }} htmlFor={'feedbackType'}>
              {'Feedback Type'}
            </InputLabel>
          }
          <Select
            id="feedbackType"
            options={[
              { id: 'BugReport', name: 'Bug Report' },
              { id: 'FeatureRequest', name: 'Feature Request' },
              { id: 'Feedback', name: 'Feedback ' },
              { id: undefined, name: 'All' },
            ]}
            onChange={(option) => setFeedbackType(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={feedbackType ? feedbackType : 'All'}
            value={feedbackType}
          />
        </Grid>

        <Grid item md={2} xs={5} alignItems="flex-end">
          <InputLabel style={{ fontSize: '14px' }}>{'Min Date'}</InputLabel>
          <TextField
            fullWidth
            type="date"
            InputProps={{
              style: {
                height: '40px',
              },
            }}
            value={minDate}
            onChange={(e) => setMinDate(e.target.value)}
          />
        </Grid>
        <Grid item md={2} xs={5} alignItems="flex-end">
          <InputLabel style={{ fontSize: '14px' }}>{'Max Date'}</InputLabel>
          <TextField
            fullWidth
            type="date"
            InputProps={{
              style: {
                height: '40px',
              },
            }}
            value={maxDate}
            onChange={(e) => setMaxDate(e.target.value)}
          />
        </Grid>
      </Grid>

      {isFetchingBugsList ? (
        <></>
      ) : (
        <>
          <div className="table-container">
            <Table
              style={{
                // tableLayout: 'fixed',
                backgroundColor: 'white',
                maxWidth: '100%',

                overflow: 'auto',
              }}
            >
              <TableHead>
                {feedbacks?.feedback_reports?.length === 0 ? (
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    No Records Found
                  </Typography>
                ) : (
                  <TableRow style={{ minWidth: '40rem' }}>
                    <TableCell
                      style={{
                        padding: '4px',
                        paddingLeft: '10px',
                        minWidth: '8rem',
                      }}
                      onClick={() => {
                        setSortBy('fullname');
                        setSortOrderByUserName(
                          sortOrderByUserName === 'asc' ? 'desc' : 'asc',
                        );
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5 rem',
                        }}
                      >
                        User Name
                        {sortOrderByUserName === 'asc' ? (
                          <ArrowUpward style={{ height: '14px' }} />
                        ) : (
                          <ArrowDownward style={{ height: '14px' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: '4px',
                        width: 'auto',
                        minWidth: '8rem',
                      }}
                      onClick={() => {
                        setSortBy('issue_area');
                        setSortOrderByIssueArea(
                          sortOrderByIssueArea === 'asc' ? 'desc' : 'asc',
                        );
                      }}
                    >
                      {' '}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5 rem',
                        }}
                      >
                        Area Of Issue
                        {sortOrderByIssueArea === 'asc' ? (
                          <ArrowUpward style={{ height: '14px' }} />
                        ) : (
                          <ArrowDownward style={{ height: '14px' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      Type Of Issue
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      Feedback Type
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      Status
                    </TableCell>
                    <TableCell
                      style={{ padding: '4px', minWidth: '8rem' }}
                      onClick={() => {
                        setSortBy('date');
                        setSortOrderByTime(
                          sortOrderByTime === 'asc' ? 'desc' : 'asc',
                        );
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5 rem',
                        }}
                      >
                        Date
                        {sortOrderByTime === 'asc' ? (
                          <ArrowUpward style={{ height: '14px' }} />
                        ) : (
                          <ArrowDownward style={{ height: '14px' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>Check</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {feedbacks?.feedback_reports?.map((feedback, idx) => (
                  <TableRow
                    key={idx}
                    className="table-row-bugs"
                    onClick={() => setSelectedRowID(feedback.id)}
                  >
                    <TableCell
                      style={{ padding: '1rem 4px', paddingLeft: '10px' }}
                    >
                      {feedback?.user_detail?.first_name}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {feedback.area_of_issue}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {feedback.type_of_issue}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {feedback.feedback_type}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {isEditStatusOpen && feedback.id === selectedRowID ? (
                        <>
                          <FormControl style={{ width: '8rem', padding: 0 }}>
                            <Select2
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Exam"
                              error={!!errors.approval_status}
                              defaultValue={
                                feedback.status === null ||
                                feedback.status === 'Pending'
                                  ? 'Pending'
                                  : feedback.status
                              }
                            >
                              <MenuItem value="Closed" key="Closed">
                                Closed
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select2>
                          </FormControl>
                          <ButtonGroup style={{ textAlign: 'right' }}>
                            <IconButton
                              onClick={handleSubmit(handleSubmitFeedbackStatus)}
                              size="large"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditingFeedbackId('');
                                setEditStatusOpen(false);
                              }}
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </ButtonGroup>
                        </>
                      ) : (
                        <Chip
                          onClick={() => {
                            setEditingFeedbackId(feedback.id);
                            setEditStatusOpen(true);
                          }}
                          variant="outlined"
                          color="success"
                          size="small"
                          label={feedback.status ? feedback.status : 'Pending'}
                          className="subtag"
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }}>
                      {feedback?.created_at.split('T')[0]}
                    </TableCell>
                    <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                      <FullScreenDialog
                        isEditStatusOpen={isEditStatusOpen}
                        selectedRowID={selectedRowID}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        setEditStatusOpen={setEditStatusOpen}
                        setEditingFeedbackId={setEditingFeedbackId}
                        handleSubmitFeedbackStatus={handleSubmitFeedbackStatus}
                        feedback={feedback}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            style={{ width: '95%' }}
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            count={feedbacks?.total_pages * feedbacks?.page_size}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
            className="scoreboard__navigation"
          />
        </>
      )}
    </Container>
  );
};

export default ReportedBugs;

export function FullScreenDialog({
  feedback,
  handleSubmitFeedbackStatus,
  isEditStatusOpen,
  selectedRowID,
  register,
  errors,
  handleSubmit,
  setEditStatusOpen,
  setEditingFeedbackId,
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
    navigate(`/l/reportedBugs/${feedback?.id}`);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(`/l/reportedBugs`);
  };
  // eslint-disable-next-line no-unused-vars
  const { media_url_list, env, user_detail, user_id, ...rest } = feedback;
  const { last_name, first_name, email } = feedback.user_detail;
  const titles = {
    area_of_issue: 'Area of Issue',
    type_of_issue: 'Type of Issue',
    created_at: 'Created At',
    id: 'Report Id',
    message: 'Message',
    modified_on: 'Modified On',
    other_feedback: 'Other Feedback',
    overall_rating: 'Overall Rating',
    status: 'Status',
    last_name: 'Last Name',
    first_name: 'First Name',
    email: 'Email',
  };

  const UserObject = {
    Name: first_name + ' ' + last_name,
    Id: user_id,
    Email: email,
  };
  return (
    <div>
      <Button onClick={handleClickOpen}>
        <RemoveRedEyeOutlined />
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            close
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            {Object.keys(rest).map((key) => {
              return (
                <Box
                  key={key}
                  display={'flex'}
                  sx={{ gap: '1rem', alignItems: 'center' }}
                >
                  <Box width={'10rem'}>
                    <Typography variant="h6">{titles[key]}</Typography>
                  </Box>
                  {key === 'status' ? (
                    isEditStatusOpen && feedback?.id === selectedRowID ? (
                      <>
                        <FormControl style={{ width: '8rem', padding: 0 }}>
                          <Select2
                            {...register('approval_status', {
                              required: 'Please select a status',
                            })}
                            style={{ marginTop: '8px', marginBottom: '4px' }}
                            variant="outlined"
                            margin="dense"
                            displayEmpty
                            placeholder="Exam"
                            error={!!errors.approval_status}
                            defaultValue={
                              feedback?.status === null ||
                              feedback?.status === 'Pending'
                                ? 'Pending'
                                : feedback.status
                            }
                          >
                            <MenuItem value="Closed" key="Closed">
                              Closed
                            </MenuItem>
                            <MenuItem value="Pending" key="Pending">
                              Pending
                            </MenuItem>
                            <MenuItem value="Rejected" key="Rejected">
                              Rejected
                            </MenuItem>
                          </Select2>
                        </FormControl>
                        <ButtonGroup style={{ textAlign: 'right' }}>
                          <IconButton
                            onClick={handleSubmit(handleSubmitFeedbackStatus)}
                            size="large"
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEditingFeedbackId('');
                              setEditStatusOpen(false);
                            }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </ButtonGroup>
                      </>
                    ) : (
                      <Chip
                        onClick={() => {
                          setEditingFeedbackId(feedback?.id);
                          setEditStatusOpen(true);
                        }}
                        variant="outlined"
                        color="success"
                        size="small"
                        label={feedback.status ? feedback?.status : 'Pending'}
                        className="subtag"
                      />
                    )
                  ) : (
                    <Typography variant="body1">{rest[key]}</Typography>
                  )}
                </Box>
              );
            })}
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Typography variant="h4">User Details</Typography>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
              >
                <Avatar src={feedback.user_detail?.picture} />
                {Object.keys(UserObject).map((key) => {
                  return (
                    <Box
                      key={key}
                      display={'flex'}
                      sx={{ gap: '1rem', alignItems: 'center' }}
                    >
                      <Box width={'10rem'}>
                        <Typography variant="h6">{key}</Typography>
                      </Box>

                      <Typography variant="body1">{UserObject[key]}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Typography variant="h4">Environment</Typography>
              <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
                <Box width={'10rem'}>
                  <Typography variant="h6">Browser Name</Typography>
                </Box>

                <Typography variant="body1">
                  {feedback.env?.browser_name}
                </Typography>
              </Box>
              <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
                <Box width={'10rem'}>
                  <Typography variant="h6">Browser Version</Typography>
                </Box>

                <Typography variant="body1">
                  {feedback.env?.browser_ver || '-'}
                </Typography>
              </Box>
              <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
                <Box width={'10rem'}>
                  <Typography variant="h6">OS Name</Typography>
                </Box>

                <Typography variant="body1">{feedback.env?.os_name}</Typography>
              </Box>
              <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
                <Box width={'10rem'}>
                  <Typography variant="h6">OS Version</Typography>
                </Box>

                <Typography variant="body1">
                  {feedback.env?.os_ver || '-'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              {feedback.media_url_list?.map((item) => (
                <Box className="media-container" key={item}>
                  <ImageDialog link={item} />
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const ImageDialog = ({ link }) => {
  const [open, setOpen] = useState(false);
  const media = link.split('.');
  const extension = media[media.length - 1];
  const mediaType = ['jpeg', 'png', 'jpg'].includes(extension)
    ? 'image'
    : ['mp4', 'webm', 'mov'].includes(extension)
    ? 'video'
    : ['docx', 'rtf'].includes(extension)
    ? 'document'
    : extension;

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {mediaType === 'image' ? (
          <img
            className="image-bugs"
            src={link}
            alt={'bug_image'}
            loading="lazy"
          />
        ) : mediaType === 'video' ? (
          <video src={link} className="image-bugs"></video>
        ) : mediaType === 'document' ? (
          <img
            src={docsImg}
            alt={'bug_image'}
            className="image-bugs"
            loading="lazy"
          />
        ) : null}
      </Button>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <div
          className="container-dialog"
          style={{
            height: mediaType === 'document' ? 'fit-content' : '70vh',
            width: mediaType === 'video' ? '40vw' : 'auto',
          }}
        >
          <DialogContent>
            {mediaType === 'image' ? (
              <img src={link} />
            ) : mediaType === 'video' ? (
              <div className="video-container">
                <video
                  className="video"
                  controls
                  src={link}
                  width={'auto'}
                  height={'auto'}
                />
              </div>
            ) : (
              <Button variant="outlined" color="primary">
                <a href={link} download>
                  Download
                </a>
              </Button>
            )}
          </DialogContent>
          <DialogActions className="dialog-box-image-actions">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
