import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import Card from './../Card';
import Loader from '../Loader';
import SearchBox from '../SearchBox';
import './index.css';
import { withRouter } from '../../routes';

const getTagById = (list, id) => {
  const selectedtag = list?.find((item) => item.id === id);
  return selectedtag;
};

const SubTagsList = (props) => {
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    const { getTag } = props;
    getTag();
  }, []);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
  };

  const renderLoader = () => {
    return (
      <div className="no-content">
        <Loader size={44} color="primary" />
      </div>
    );
  };

  const renderTagsList = () => {
    const tagList = props.tag.allTags;
    let tagId = props.location.search;
    tagId = tagId.substring(1);
    const tagDetails = getTagById(tagList, tagId) || {};
    const subtagList = tagDetails.sub_tags;

    return (
      <div className="tagListContainer">
        <SearchBox value={searchInput} handleSearch={handleSearch} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Difficulty Score</TableCell>
              <TableCell align="right">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtagList.length > 0 ? (
              subtagList.map((subtag) => (
                <TableRow key={subtag.id}>
                  <TableCell>
                    <div className="tag__list__name">
                      <Avatar
                        alt={subtag.name}
                        src={subtag.s3_url}
                        className="tag__list__avatar"
                      />
                      {`${subtag.name}`}
                    </div>
                  </TableCell>
                  <TableCell>{subtag.difficulty_score}</TableCell>
                  <TableCell align="right" style={{ minWidth: '150px' }}>
                    <EditIcon
                      onClick={() => {
                        this.props.navigate({
                          pathname: `/l/editSubTag/${tagId}/${subtag.id}`,
                          search: `${subtag.id}`,
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <p className="no-tag">No Such tag found </p>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const cardHeader = (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <div className="tags-list__header">Sub-categories</div>
      <div className="create-tag-action">
        <NavLink to="/l/createSubtag">
          <Button color="primary" variant="outlined">
            Add Subcategory
          </Button>
        </NavLink>
      </div>
    </div>
  );

  const cardContent =
    props.tag.list.length > 0 ? renderTagsList() : renderLoader();

  return (
    <Container maxWidth={false}>
      <div className="tags-list">
        {cardHeader}
        <Card content={cardContent} />
      </div>
    </Container>
  );
};

export default withRouter(SubTagsList);
