import React, { useEffect, useState } from 'react';
import FormData from 'form-data';
import ReactSelect from 'react-select';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from './../Card';
import MessageBar from '../MessageBar';
import Loader from '../Loader';
import ImagePicker from './../ImagePicker';
import { withRouter } from '../../routes';
import { useLocation } from 'react-router-dom';
import { StyleTagsForm } from './StyledComponent';

const getTagById = (list = [], id) => {
  return list.find((item) => item.id === id);
};

const getDomainById = (list = [], id) => {
  return list.find((item) => item.id === id);
};

const CreateTag = (props) => {
  const [tagName, setTagName] = useState('');
  const [tagDescription, setTagDescription] = useState('');
  const [tagImage, setTagImage] = useState('');
  const [selectedDomain, setSelectedDomain] = useState({});
  const [acceptedQuestionCredits, setAcceptedQuestionCredits] = useState(0);
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [tagCreateStatus, setTagCreateStatus] = useState('');
  const [tagCreateMessage, setTagCreateMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [updatetagState, setUpdatetagState] = useState(false);
  const [approval_status, setApprovalStatus] = useState('Accepted');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const { getTag } = props;
    getTag();
    const { tag, params, domain } = props;
    const tagList = tag.allTags;
    const domainList = domain.list;
    const tagId = params.tagId;
    const tagDetails = getTagById(tagList, tagId) || {};
    const {
      name,
      description,
      s3_url,
      approval_status,
      accepted_question_credits,
    } = tagDetails;
    const domainId = tagDetails.domain_ids || [location.state?.domainId];
    const domainName = getDomainById(domainList, domainId[0]) || null;

    setAcceptedQuestionCredits(accepted_question_credits || 0);
    setTagName(name || '');
    setTagDescription(description || '');
    setTagImage(s3_url || '');
    setSelectedDomain(domainName);
    setApprovalStatus(approval_status || 'Accepted');
    if (tagId) {
      setUpdatetagState(true);
    }
  }, []);

  useEffect(() => {
    const { tag, params, domain } = props;
    const tagList = tag.allTags;
    const domainList = domain.list;
    const tagId = params.tagId;
    const tagDetails = getTagById(tagList, tagId) || {};
    const {
      name,
      description,
      s3_url,
      approval_status,
      accepted_question_credits,
    } = tagDetails;
    const domainId = tagDetails.domain_ids || [location.state?.domainId];
    const domainName = getDomainById(domainList, domainId[0]) || null;

    setTagName(name || '');
    setTagDescription(description);
    setTagImage(s3_url);
    setSelectedDomain(domainName);
    setApprovalStatus(approval_status);
    setAcceptedQuestionCredits(accepted_question_credits || 0);
    if (tagId) {
      setUpdatetagState(true);
    }
  }, [props.tag?.allTags?.length]);

  useEffect(() => {
    if (props.tag?.postTagSuccess) {
      setIsStatusMessageVisible(true);
      setTagCreateStatus('success');
      setTagCreateMessage('Congrats! Tag created successfully. Redirecting...');
      setLoading(false);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  }, [props.tag?.postTagSuccess]);

  useEffect(() => {
    const { tag } = props;
    if (props.tag?.postTagFail) {
      if (tag?.errorStatus?.status === 400) {
        setIsStatusMessageVisible(true);
        setTagCreateStatus('error');
        setTagCreateMessage('Tag name already exists');
        setLoading(false);
      } else {
        setIsStatusMessageVisible(true);
        setTagCreateStatus('error');
        setTagCreateMessage('Unable to create tag');
        setLoading(false);
      }
    }
  }, [props.tag?.postTagFail]);

  const validate = (values) => {
    const errors = {};

    if (values.tagName.trim() === '') {
      errors.tagName = 'Please enter the tag name';
    }
    if (values.tagDescription.trim() === '') {
      errors.tagDescription = 'Please enter the tag description';
    }

    if (!values.selectedDomain) {
      errors.selectedDomain = 'Please select domain';
    }

    if (!values.tagImage) {
      errors.tagImage = 'Please upload the tag icon';
    }

    if (values?.acceptedQuestionCredits <= 0) {
      errors.acceptedQuestionCredits = 'Please choose positive credits';
    }

    return errors;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { postTag, params, updateTag } = props;
    const values = {
      tagName,
      tagDescription,
      tagImage,
      selectedDomain: selectedDomain.id,
      approval_status,
      acceptedQuestionCredits,
    };
    const errors = validate(values);

    if (!Object.keys(errors).length) {
      let tagForm = new FormData();
      const tagId = params.tagId;
      tagForm.append('name', values.tagName);
      tagForm.append('description', values.tagDescription);
      tagForm.append('domain_id', values.selectedDomain);
      tagForm.append('approval_status', values.approval_status);
      tagForm.append(
        'accepted_question_credits',
        values.acceptedQuestionCredits,
      );
      if (tagId) {
        tagForm.append('id', tagId);
      }
      if (values.tagImage) {
        tagForm.append('tag_image', values.tagImage);
      }
      if (updatetagState === true) {
        setLoading(true);
        updateTag(tagForm, tagId);
      } else {
        setLoading(true);
        postTag(tagForm);
      }
    } else {
      setErrors(errors);
    }
  };

  const onFileLoad = (file) => {
    setTagImage(file);
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const handleDomainChange = (value) => {
    setSelectedDomain(value);
  };
  const handleApprovalStatusChange = (value) => {
    setApprovalStatus(value.value);
  };

  const { domain, navigate } = props;
  const domainList = domain.list;
  const options = [
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
  ];
  const cardContent = (
    <div className="position-center">
      <StyleTagsForm>
        <div className="tags-form">
          <form onSubmit={onSubmit}>
            <div className="form-control">
              <div className="field-item">
                <TextField
                  name="tagName"
                  label={'Name'}
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                  margin="dense"
                  variant="outlined"
                  className="text-input-field"
                />
                <p className="error">{errors.tagName}</p>
              </div>
            </div>
            <div className="form-control">
              <div className="field-item">
                <TextField
                  name="tagDescription"
                  label={'Description'}
                  value={tagDescription}
                  onChange={(e) => setTagDescription(e.target.value)}
                  margin="dense"
                  variant="outlined"
                  className="text-input-field"
                />
                <p className="error">{errors.tagDescription}</p>
              </div>
            </div>
            {!updatetagState && (
              <div className="form-control">
                <div className="field-item">
                  <ReactSelect
                    options={domainList}
                    name="selectedDomain"
                    onChange={handleDomainChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Domain"
                    value={selectedDomain}
                    isClearable
                  />
                  <p className="error">{errors.selectedDomain}</p>
                </div>
              </div>
            )}
            <div className="form-control">
              <div className="field-item">
                <ReactSelect
                  options={options}
                  name="approval_status"
                  onChange={handleApprovalStatusChange}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={'Approval status'}
                />
                <p className="error">{errors.approval_status}</p>
              </div>
            </div>
            <div className="form-control">
              <div className="field-item">
                <TextField
                  name="accepted_question_credits"
                  label={'Accepted Question Credits'}
                  value={acceptedQuestionCredits}
                  onChange={(e) => setAcceptedQuestionCredits(e.target.value)}
                  margin="dense"
                  variant="outlined"
                  className="text-input-field"
                  type="number"
                  style={{ zIndex: '0' }}
                />
                <p className="error">{errors.acceptedQuestionCredits}</p>
              </div>
            </div>
            <div className="form-control">
              <div className="field-item">
                <label htmlFor="">Icon</label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ImagePicker onFileLoad={onFileLoad} imageUrl={tagImage} />
                </div>

                <p className="error">{errors.tagImage}</p>
              </div>
            </div>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? <Loader size={24} color="inherit" /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </StyleTagsForm>
    </div>
  );

  return (
    <Container maxWidth={false}>
      <Card
        heading={!updatetagState ? 'Create Category' : 'Edit Category'}
        content={cardContent}
      />
      <MessageBar
        type={tagCreateStatus}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={tagCreateMessage}
      />
    </Container>
  );
};
export default withRouter(CreateTag);
