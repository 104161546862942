import React from 'react';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';
import {
  StyledCustomCard,
  StyledPiechartContainer,
  StyledPiechartInfoContainer,
} from './DashboardStyledComponent';

export default function PieCharts({ pieChartData }) {
  const total_correct_count = pieChartData?.total_correct_count || 0;
  const total_incorrect_count = pieChartData?.total_incorrect_count || 0;
  const total_timeout_count = pieChartData?.total_timeout_count || 0;
  const total_skipped_count = pieChartData?.total_skipped_count || 0;
  const total_question_count = pieChartData?.total_question_count || 0;

  const data = [
    { name: 'Correct', value: total_correct_count },
    { name: 'In Correct', value: total_incorrect_count },
    { name: 'Time Out', value: total_timeout_count },
    { name: 'Skipped', value: total_skipped_count },
  ];

  const COLORS = ['#388E3C', '#D60505', '#EE9D00', '#21A9DE'];

  return (
    <div>
      <StyledCustomCard
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <StyledPiechartContainer>
          <ResponsiveContainer
            width={'100%'}
            height={'100%'}
            style={{ margin: '0 auto' }}
          >
            <PieChart width={280} height={160} style={{ margin: '0 auto' }}>
              <Pie
                data={data}
                dataKey="value"
                cx={140}
                cy={100}
                innerRadius={50}
                outerRadius={70}
                label
                style={{ margin: '0 auto' }}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Label
                  position="center"
                  fill="#000"
                  fontSize={10}
                  fontWeight={900}
                >{`${total_question_count}\nQuestions`}</Label>
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </StyledPiechartContainer>
        <StyledPiechartInfoContainer>
          {data.map((entry, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  padding: '4px',
                  backgroundColor: COLORS[index],
                  fontSize: '10px',
                }}
              ></p>{' '}
              <p
                style={{
                  fontSize: '10px',
                }}
              >
                {entry.name}: {entry.value}
              </p>
            </div>
          ))}
        </StyledPiechartInfoContainer>
      </StyledCustomCard>
    </div>
  );
}
