import axios from 'axios';
import config from './../config';
import * as types from './types';
import apiClient from '../api/apiclient';

export function getOrderHistoryStart() {
  return {
    type: types.GET_ORDER_HISTORY_START,
  };
}

export function getOrderHistorySuccess({ data }) {
  return {
    type: types.GET_ORDER_HISTORY_SUCCESS,
    data: data,
  };
}

export function getOrderHistoryFail() {
  return {
    type: types.GET_ORDER_HISTORY_FAIL,
  };
}

export function getOrderHistoryList() {
  return (dispatch) => {
    dispatch(getOrderHistoryStart());
    apiClient
      .getPaymentApi()
      .paymentApiUserOrderHistory()
      .then((resp) => {
        dispatch(getOrderHistorySuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getOrderHistoryFail());
        throw ex;
      });
  };
}

export function getInvoiceFileNameStart() {
  return {
    type: types.GET_INVOICE_FILE_NAME_START,
  };
}

export function getInvoiceFileNameSuccess({ data }) {
  return {
    type: types.GET_INVOICE_FILE_NAME_SUCCESS,
    data: data,
  };
}

export function getInvoiceFileNameFail() {
  return {
    type: types.GET_INVOICE_FILE_NAME_FAIL,
  };
}

export function getInvoiceFileName(invoiceUrl) {
  return (dispatch) => {
    dispatch(getInvoiceFileNameStart());
    apiClient
      .getFilesApi()
      .filesApiDownloadFile(invoiceUrl)
      .then((resp) => {
        dispatch(getInvoiceFileNameSuccess({ data: resp.data }));
        dispatch(getInvoiceFileFromPath(resp.data.local_path.substring(5)));
      })
      .catch((ex) => {
        dispatch(getInvoiceFileNameFail());
        throw ex;
      });
  };
}

export function getInvoiceFileFromPathStart() {
  return {
    type: types.GET_INVOICE_FILE_FROM_PATH_START,
  };
}

export function getInvoiceFileFromPathSuccess({ data }) {
  return {
    type: types.GET_INVOICE_FILE_FROM_PATH_SUCCESS,
    data: data,
  };
}

export function getInvoiceFileFromPathFail() {
  return {
    type: types.GET_INVOICE_FILE_FROM_PATH_FAIL,
  };
}

export function getInvoiceFileFromPath(filePath) {
  return (dispatch, getState) => {
    dispatch(getInvoiceFileFromPathStart());
    const { auth } = getState();
    axios
      .get(
        `${config.API_URL}/files/download-file-from-path?filepath=${filePath}`,
        {
          headers: {
            token: auth.token,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((resp) => {
        dispatch(getInvoiceFileFromPathSuccess({ data: resp.data }));
        window.location.href = `${config.API_URL}/files/download-file-from-path?filepath=${filePath}`;
      })
      .catch((ex) => {
        dispatch(getInvoiceFileFromPathFail());
        throw ex;
      });
  };
}
