import React from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import { Cancel, RemoveRedEyeOutlined } from '@mui/icons-material';
import ReactHtmlParser from 'react-html-parser';
import TimerIcon from '@mui/icons-material/Timer';
import PlaceholderImg from './Images/Company.png';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { jobBulkStatusUpdate } from '../../actions/adminJobs';
import JobOpeningIcon from './Images/opening.svg';
import JobTypeIcon from './Images/jobType.svg';
import CTCIconPrimary from './Images/CTCIconPrimary.svg';
import ExperienceIcon from './Images/experience_icon.png';
import {
  CustomDivider,
  DescriptionContent,
  DescriptionTitle,
  IconTextContainer,
  JobInfoIcon,
  JobInfoText,
  LocationText,
} from './JobPreviewStyle';
import { expectedCTCFormatter, removeCommas } from '../../lib/helpers';
import { SALARY_FREQUENCY } from '../../constants/adminFeatures';

const JobPreview = ({ jobData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [selectedStatusToChange, setSelectedStatusToChange] = React.useState(
    '',
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const orgData = jobData?.org_details;
  const handleStatusChange = () => {
    const data = {
      status: selectedStatusToChange,
      job_ids: [jobData.id],
    };
    dispatch(jobBulkStatusUpdate(data));
  };
  const minSalary = expectedCTCFormatter(
    removeCommas(jobData?.minimum_salary || 0),
  );
  const maxSalary = expectedCTCFormatter(
    removeCommas(jobData?.maximum_salary || 0),
  );
  const salaryFrequency = SALARY_FREQUENCY.find(
    (val) => val.id === jobData?.salary_frequency,
  )?.label;

  const currency =
    typeof jobData?.currency === 'string'
      ? jobData?.currency
      : jobData?.currency?.label || '₹';

  const isInternship =
    (jobData?.job_type?.label || jobData?.job_type || '') === 'Internship';
  return (
    <div>
      <Button onClick={handleClickOpen}>
        <RemoveRedEyeOutlined />
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <Box display={'flex'} justifyContent={'flex-end'} onClick={handleClose}>
          <Cancel color="primary" />
        </Box>
        <DialogContent>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 0',
              padding: '0px 30px',
            }}
          >
            <Grid
              display={'flex'}
              flexDirection="column"
              flexWrap="nowrap"
              flex={'1 1 0'}
            >
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection={'column'}
                mb="10px"
              >
                <Grid>
                  <Grid
                    display={'flex'}
                    gap={'15px'}
                    mb={{ md: '30px', xs: '20px' }}
                  >
                    <Box>
                      <Box
                        component={'img'}
                        src={
                          orgData.logo_url ? orgData.logo_url : PlaceholderImg
                        }
                        width={{ md: '50px', xs: '40px' }}
                        height={{ md: '50px', xs: '40px' }}
                        style={{ borderRadius: '50%' }}
                      />
                    </Box>
                    <Grid>
                      <Typography
                        fontSize={{ md: '20px', xs: '16px' }}
                        fontWeight={'600'}
                        color={'#25507B'}
                      >
                        {jobData?.job_role_details?.name
                          ? jobData?.job_role_details?.name
                          : jobData?.title}
                      </Typography>

                      <Typography
                        fontSize={{ md: '14px', xs: '12px' }}
                        fontWeight={'500'}
                      >
                        {orgData?.company_name}
                      </Typography>
                      <Typography
                        fontSize={{ md: '12px', xs: '10px' }}
                        fontWeight={'500'}
                      >
                        {orgData?.team_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    gap={'10px'}
                    display={'flex'}
                    mb={{ md: '20px', xs: '10px' }}
                    flexWrap={'wrap'}
                  >
                    {jobData?.required_skills?.map((skill) => (
                      <Chip
                        key={skill.id}
                        label={skill.name}
                        color={'primary'}
                        sx={{
                          height: '28px',
                          backgroundColor: '#25507B',
                          '& > .MuiChip-label': {
                            padding: '5px 10px',
                          },
                        }}
                      />
                    ))}
                  </Box>
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      rowGap: '10px',
                    }}
                  >
                    <IconTextContainer>
                      <FmdGoodIcon
                        sx={{
                          color: '#7B7B7B',
                          fontSize: '20px',
                        }}
                      />
                      <LocationText>
                        {jobData?.work_flexibility !== 'Remote'
                          ? jobData?.job_location_details?.description
                          : 'Remote'}
                      </LocationText>
                    </IconTextContainer>
                    <IconTextContainer>
                      <JobInfoIcon src={ExperienceIcon} />
                      <JobInfoText>
                        {`${jobData?.minimum_experience?.value ??
                          jobData?.minimum_experience ??
                          0} - ${jobData?.maximum_experience?.value ??
                          jobData?.maximum_experience ??
                          0} Years`}
                      </JobInfoText>
                    </IconTextContainer>
                    <IconTextContainer>
                      <JobInfoIcon src={JobOpeningIcon} />
                      <JobInfoText>
                        {`${jobData?.no_of_positions ?? 1} Opening`}
                      </JobInfoText>
                    </IconTextContainer>
                    <IconTextContainer>
                      <JobInfoIcon src={JobTypeIcon} />
                      <JobInfoText>
                        {jobData?.job_type?.label ?? jobData?.job_type}
                      </JobInfoText>
                    </IconTextContainer>
                    {isInternship && (
                      <IconTextContainer>
                        <TimerIcon sx={{ fontSize: '20px' }} />
                        <JobInfoText>
                          {jobData?.job_duration?.value ??
                            jobData?.job_duration ??
                            0}
                          &nbsp;Month(s)
                        </JobInfoText>
                      </IconTextContainer>
                    )}
                    <IconTextContainer flex={'1 1 0'}>
                      <JobInfoIcon src={CTCIconPrimary} />
                      <JobInfoText>
                        {`${currency} ${minSalary} -
                        ${currency} ${maxSalary}`}
                        &nbsp;
                        <Typography
                          component={'span'}
                          fontSize={{ md: '12px', xs: '8px' }}
                          color={'#7B7B7B'}
                          fontWeight={'400'}
                        >
                          {salaryFrequency}
                        </Typography>
                      </JobInfoText>
                    </IconTextContainer>
                    {jobData?.created_at && (
                      <Box display="flex">
                        <Typography>Posted &nbsp;</Typography>
                        <Typography fontWeight="600">
                          {moment.utc(jobData?.created_at).fromNow()}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <CustomDivider
                sx={{
                  marginBottom: { md: '30px', xs: '20px' },
                }}
              />
              <Grid
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                gap={{ md: '30px', xs: '20px' }}
              >
                {jobData?.job_description && (
                  <Box>
                    <DescriptionTitle>Job Description</DescriptionTitle>
                    <DescriptionContent>
                      {ReactHtmlParser(jobData?.job_description || '')}
                    </DescriptionContent>
                  </Box>
                )}
                <Box>
                  <DescriptionTitle>Roles & Responsibilities</DescriptionTitle>
                  <DescriptionContent>
                    {ReactHtmlParser(jobData?.responsibilities || '')}
                  </DescriptionContent>
                </Box>
                {jobData?.minimum_qualification && (
                  <Box>
                    <DescriptionTitle>Minimum Qualifications</DescriptionTitle>
                    <DescriptionContent>
                      {ReactHtmlParser(jobData?.minimum_qualification || '')}
                    </DescriptionContent>
                  </Box>
                )}
                {jobData?.preferred_qualification && (
                  <Box>
                    <DescriptionTitle>
                      Preferred Qualifications
                    </DescriptionTitle>
                    <DescriptionContent>
                      {ReactHtmlParser(jobData?.preferred_qualification || '')}
                    </DescriptionContent>
                  </Box>
                )}
                {jobData?.additional_details && (
                  <Box>
                    <DescriptionTitle>Additional Details</DescriptionTitle>
                    <DescriptionContent>
                      {ReactHtmlParser(jobData?.additional_details || '')}
                    </DescriptionContent>
                  </Box>
                )}

                {orgData?.description && (
                  <Box>
                    <DescriptionTitle>About The Company</DescriptionTitle>
                    <DescriptionContent>
                      {ReactHtmlParser(orgData?.description || '')}
                    </DescriptionContent>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box
            display={'flex'}
            sx={{
              gap: '1rem',
              alignItems: 'center',
              background: '#25507B',
              padding: '10px',
              mt: 5,
            }}
          >
            <Box width={'10rem'}>
              <Typography variant="h6" sx={{ color: '#fff' }}>
                Change Status:{' '}
              </Typography>
            </Box>
            {isEditingStatus ? (
              <>
                <Box width={'10rem'}>
                  <Select
                    options={[
                      { id: 'Active', name: 'Active' },
                      { id: 'UnderReview', name: 'UnderReview' },
                      { id: 'Paused', name: 'Paused' },
                      { id: 'Draft', name: 'Draft' },
                      { id: 'Closed', name: 'Closed' },
                    ]}
                    onChange={(option) => setSelectedStatusToChange(option.id)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder={
                      selectedStatusToChange ? selectedStatusToChange : 'Status'
                    }
                    value={selectedStatusToChange}
                    menuPlacement="auto"
                  />
                </Box>
                <IconButton onClick={handleStatusChange}>
                  <DoneIcon
                    sx={{ color: '#fff' }}
                    disabled={selectedStatusToChange !== '' ? false : true}
                  />
                </IconButton>
                <IconButton onClick={() => setIsEditingStatus(false)}>
                  <ClearIcon sx={{ color: '#fff' }} />
                </IconButton>
              </>
            ) : (
              <Chip
                onClick={() => setIsEditingStatus(true)}
                variant="outlined"
                size="small"
                label={jobData.status ? jobData.status : 'UnderReview'}
                className="subtag"
                sx={{
                  color: '#fff',
                  border: '1px solid #fff',
                }}
              />
            )}
          </Box>
        </DialogContent>{' '}
      </Dialog>
    </div>
  );
};

export default JobPreview;
