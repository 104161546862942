import * as types from './types';
import apiClient from '../api/apiclient';

export function postProductStart() {
  return {
    type: types.POST_PRODUCT_START,
  };
}

export function postProductSuccess({ data }) {
  return {
    type: types.POST_PRODUCT_SUCCESS,
    data: data,
  };
}

export function postProductFail() {
  return {
    type: types.POST_PRODUCT_FAIL,
  };
}

export function createProduct(body) {
  return (dispatch) => {
    dispatch(postProductStart());
    apiClient
      .getPaymentApi()
      .paymentApiAddNewItem(body)
      .then((resp) => {
        dispatch(postProductSuccess({ data: resp.data }));
        dispatch(getProductsList());
      })
      .catch((ex) => {
        dispatch(postProductFail());
        throw ex;
      });
  };
}

export function getProductStart() {
  return {
    type: types.GET_PRODUCT_START,
  };
}

export function getProductSuccess({ data }) {
  return {
    type: types.GET_PRODUCT_SUCCESS,
    data: data,
  };
}

export function getProductFail() {
  return {
    type: types.GET_PRODUCT_FAIL,
  };
}

export function getProductsList() {
  return (dispatch) => {
    dispatch(getProductStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetAllItemsAdmin(undefined, undefined, undefined)
      .then((resp) => {
        dispatch(getProductSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getProductFail());
        throw ex;
      });
  };
}

export function deleteProductStart() {
  return {
    type: types.DELETE_PRODUCT_START,
  };
}

export function deleteProductSuccess() {
  return {
    type: types.DELETE_PRODUCT_SUCCESS,
  };
}

export function deleteProductFail() {
  return {
    type: types.DELETE_PRODUCT_FAIL,
  };
}
export function deleteProduct(id) {
  return (dispatch) => {
    dispatch(deleteProductStart());
    apiClient
      .getPaymentApi()
      .paymentApiDeleteItem(id)
      .then((resp) => {
        dispatch(deleteProductSuccess({ data: resp.data }));
        dispatch(getProductsList());
      })
      .catch((ex) => {
        dispatch(deleteProductFail());
        throw ex;
      });
  };
}

export function getProductByIdSuccess({ data }) {
  return {
    type: types.GET_PRODUCT_BY_ID_SUCCESS,
    data: data,
  };
}

export function getProductByIdFail() {
  return {
    type: types.GET_PRODUCT_BY_ID_FAIL,
  };
}

export function getProductById(id) {
  return (dispatch) => {
    dispatch(getProductStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetAllItemsAdmin(undefined, id, undefined)
      .then((resp) => {
        dispatch(getProductByIdSuccess({ data: resp.data.items?.[0] }));
      })
      .catch((ex) => {
        dispatch(getProductByIdFail());
        throw ex;
      });
  };
}

export function changeProductStatusStart() {
  return {
    type: types.CHANGE_PRODUCT_STATUS_START,
  };
}

export function changeProductStatusSuccess({ data }) {
  return {
    type: types.CHANGE_PRODUCT_STATUS_SUCCESS,
    data: data,
  };
}

export function changeProductStatusFail() {
  return {
    type: types.CHANGE_PRODUCT_STATUS_FAIL,
  };
}

export function updateProduct(id, body) {
  return (dispatch) => {
    dispatch(changeProductStatusStart());
    apiClient
      .getPaymentApi()
      .paymentApiUpdatePurchasableItems(id, body)
      .then((resp) => {
        dispatch(changeProductStatusSuccess({ data: resp.data }));
        dispatch(getProductById(id));
      })
      .catch((ex) => {
        dispatch(changeProductStatusFail());
        throw ex;
      });
  };
}

export function postProductImageStart() {
  return {
    type: types.POST_PRODUCT_IMAGE_START,
  };
}

export function postProductImageSuccess({ data }) {
  return {
    type: types.POST_PRODUCT_IMAGE_SUCCESS,
    data: data,
  };
}

export function postProductImageFail() {
  return {
    type: types.POST_PRODUCT_IMAGE_FAIL,
  };
}

export function postProductImage(file) {
  return (dispatch) => {
    dispatch(postProductImageStart());
    apiClient
      .getPaymentApi()
      .paymentApiUploadItemImage(file)
      .then((resp) => {
        dispatch(postProductImageSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postProductImageFail());
        throw ex;
      });
  };
}

export function getOrdersListStart() {
  return {
    type: types.GET_ORDERS_LIST_START,
  };
}

export function getOrdersListSuccess({ data }) {
  return {
    type: types.GET_ORDERS_LIST_SUCCESS,
    data: data,
  };
}

export function getOrdersListFail() {
  return {
    type: types.GET_ORDERS_LIST_FAIL,
  };
}

export function getOrdersList(page, pageSize, status, startDate, endDate) {
  return (dispatch) => {
    dispatch(getOrdersListStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetAllOrders(
        endDate,
        page,
        pageSize,
        undefined,
        status,
        startDate,
      )
      .then((resp) => {
        dispatch(getOrdersListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getOrdersListFail());
        throw ex;
      });
  };
}
export function getSingleOrderStart() {
  return {
    type: types.GET_SINGLE_ORDER_START,
  };
}

export function getSingleOrderSuccess({ data }) {
  return {
    type: types.GET_SINGLE_ORDER_SUCCESS,
    data: data,
  };
}

export function getSingleOrderFail() {
  return {
    type: types.GET_SINGLE_ORDER_FAIL,
  };
}

export function getSingleOrderDetails(id) {
  return (dispatch) => {
    dispatch(getSingleOrderStart());
    apiClient
      .getPaymentApi()
      .paymentApiAdminGetUserShippingDetails(id)
      .then((resp) => {
        dispatch(getSingleOrderSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getSingleOrderFail());
        throw ex;
      });
  };
}

export function UpdateAdminOrdersStart() {
  return {
    type: types.UPDATE_ADMIN_ORDERS_START,
  };
}

export function UpdateAdminOrdersSuccess({ data }) {
  return {
    type: types.UPDATE_ADMIN_ORDERS_SUCCESS,
    data: data,
  };
}

export function UpdateAdminOrdersFail() {
  return {
    type: types.UPDATE_ADMIN_ORDERS_FAIL,
  };
}

export function UpdateAdminOrders(id, body) {
  return (dispatch) => {
    dispatch(UpdateAdminOrdersStart());
    apiClient
      .getPaymentApi()
      .paymentApiUpdateTransactionDetails(id, body)
      .then((resp) => {
        dispatch(UpdateAdminOrdersSuccess({ data: resp.data }));
        dispatch(getOrdersList(1, 10, '', '', ''));
      })
      .catch((ex) => {
        dispatch(UpdateAdminOrdersFail());
        throw ex;
      });
  };
}
