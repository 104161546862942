import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export const CustomDialogBox = ({
  actions,
  open,
  onClose,
  title,
  children,
  maxWidth,
  overflowVisible,
}) => {
  return (
    <div>
      <Dialog
        scroll="body"
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        PaperProps={{ style: { overflowY: overflowVisible } }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
};

CustomDialogBox.defaultProps = {
  maxWidth: 'lg',
  overflowVisible: 'visible',
};

CustomDialogBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  children: PropTypes.element.isRequired,
  overflowVisible: PropTypes.string,
};
