import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from './../actions';

import Credits from './../components/Credits';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    credits: state.credits,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Credits);
