import React, { useState, useEffect, Fragment } from 'react';
import Container from '@mui/material/Container';
import TablePagination from '@mui/material/TablePagination';
import QuestionCard from '../QuestionCard/QuestionCard';
import Card from './../Card';
import Loader from '../Loader';
import Tags from './../Tags';
import MessageBar from '../MessageBar';
import helpers from './../../lib/helpers';
import './index.css';
import {
  hintVisibility,
  solutionVisibility,
} from '../../constants/questioncard';
import { withRouter } from '../../routes';

const cardHeader = (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
    <div className="reportedQuestionContainer__header">
      Reported Questions List
    </div>
  </div>
);

function ReportedQuestions(props) {
  const {
    question,
    tag,
    getReportedQuestions,
    putReportedQuestion,
    putQuestion,
    unlockSolution,
  } = props;
  const {
    reportedQuestionsPaginationInfo,
    isFetchingReportedQuestion,
    reportedQuestionsList,
    isPutReportedQuestionFail,
    isPutReportedQuestionSuccess,
  } = question;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(
    reportedQuestionsPaginationInfo.currentPage + 1,
  );
  const [isStatusMessageVisible, setStatusMessageVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const [selectedTag, setSelectedTag] = useState('all');

  useEffect(() => {
    const url = new URL(window.location.href);
    const query_string = url.search;
    const search_params = new URLSearchParams(query_string);
    const tag = search_params.get('tag');
    const page = search_params.get('page');
    const pageSize = search_params.get('pageSize');
    if (page) {
      setCurrentPage(page);
    }
    if (pageSize) {
      setRowsPerPage(pageSize);
    }
    if (tag) {
      setSelectedTag(tag);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getReportedQuestions({
      page: currentPage,
      page_size: rowsPerPage,
      tag_id: selectedTag === 'all' ? '' : selectedTag,
    });
  }, [currentPage, rowsPerPage, selectedTag, getReportedQuestions]);

  useEffect(() => {
    if (isPutReportedQuestionSuccess) {
      setStatusMessageVisible(true);
      setStatus('success');
      setMessage('Congrats! Question is reported successfully.');
      getReportedQuestions({
        page: currentPage,
        page_size: rowsPerPage,
        tag_id: selectedTag === 'all' ? '' : selectedTag,
      });
    } else if (isPutReportedQuestionFail) {
      setStatusMessageVisible(true);
      setStatus('error');
      setMessage('Sorry! failed to submit report question.');
    }
  }, [
    isPutReportedQuestionSuccess,
    isPutReportedQuestionFail,
    getReportedQuestions,
    currentPage,
    rowsPerPage,
    selectedTag,
  ]);

  const handleTagChange = (tag) => {
    const selectedTag = tag ? tag.id : null;
    setSelectedTag(selectedTag);
    setCurrentPage(1);
    props.navigate({
      pathname: '/l/reported',
      search: `?page=1&pageSize=${rowsPerPage}&tag=${selectedTag}`,
    });
  };

  const { getCategoryList } = helpers;
  const filtertagList = getCategoryList(tag.list, true);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(1);
    props.navigate({
      pathname: '/l/reported',
      search: `?page=1&pageSize=${+event.target.value}&tag=${selectedTag}`,
    });
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
    props.navigate({
      pathname: '/l/reported',
      search: `?page=${newPage + 1}&pageSize=${rowsPerPage}&tag=${selectedTag}`,
    });
  };

  const handleMessageClose = () => {
    setStatusMessageVisible(false);
  };

  const cardFilters = (
    <div className="filter-section">
      <div className="tags filter">
        <label htmlFor="">Filter by tag</label>
        <Tags
          placeholder="Select tag"
          items={[...filtertagList, { id: 'all', name: 'All' }]}
          selectedTag={selectedTag}
          onChange={handleTagChange}
        />
      </div>
    </div>
  );

  let cardContent;
  if (isFetchingReportedQuestion) {
    cardContent = (
      <div className="no-content">
        <Loader size={44} color="primary" />
      </div>
    );
  } else if (reportedQuestionsList && reportedQuestionsList.length) {
    cardContent = (
      <Fragment>
        {cardFilters}
        {reportedQuestionsList.map((record, index) => (
          <QuestionCard
            showHint={hintVisibility.reported}
            showSolution={solutionVisibility.reported}
            question={record}
            index={index + 1}
            key={`${record.id}-${index}`}
            tag={tag}
            tagList={tag.list}
            unlockSolution={unlockSolution}
            putQuestion={putQuestion}
            putReportedQuestion={putReportedQuestion}
          />
        ))}
      </Fragment>
    );
  } else {
    cardContent = (
      <Fragment>
        {cardFilters}
        <div className="no-content">
          <p>No Reported Question.</p>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="reportedQuestionContainer">
      <Container maxWidth={false}>
        {cardHeader}
        <Card content={cardContent} />
        <TablePagination
          component="nav"
          page={reportedQuestionsPaginationInfo.currentPage}
          rowsPerPage={rowsPerPage}
          count={
            reportedQuestionsPaginationInfo.pageSize *
            reportedQuestionsPaginationInfo.totalPages
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ marginTop: '50px' }}
        />

        <MessageBar
          type={status}
          open={isStatusMessageVisible}
          onClose={handleMessageClose}
          message={message}
        />
      </Container>
    </div>
  );
}

export default withRouter(ReportedQuestions);
