import * as types from './types';
import apiClient from '../api/apiclient';

export function getLevelsStart() {
  return {
    type: types.GET_LEVELS_START,
  };
}
export function getLevelsSuccess({ data }) {
  return {
    type: types.GET_LEVELS_SUCCESS,
    data: data,
  };
}

export function getLevelsFail() {
  return {
    type: types.GET_LEVELS_FAIL,
  };
}
export function getLevels() {
  return (dispatch) => {
    dispatch(getLevelsStart());

    apiClient
      .getLevelApi()
      .levelApiGetAllLevels()
      .then((resp) => {
        dispatch(getLevelsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getLevelsFail());
        throw ex;
      });
  };
}
export function postLevelsStart() {
  return {
    type: types.POST_LEVELS_START,
  };
}
export function postLevelsSuccess({ data }) {
  return {
    type: types.POST_LEVELS_SUCCESS,
    data: data,
  };
}

export function postLevelsFail() {
  return {
    type: types.POST_LEVELS_FAIL,
  };
}
export function postLevel(data) {
  return (dispatch) => {
    dispatch(postLevelsStart());

    apiClient
      .getLevelApi()
      .levelApiCreateLevel(data)
      .then((resp) => {
        dispatch(postLevelsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postLevelsFail());
        throw ex;
      });
  };
}

export function postLevelImageStart() {
  return {
    type: types.POST_LEVEL_IMAGE_START,
  };
}

export function postLevelImageSuccess({ data }) {
  return {
    type: types.POST_LEVEL_IMAGE_SUCCESS,
    data: data,
  };
}

export function postLevelImageFail() {
  return {
    type: types.POST_LEVEL_IMAGE_FAIL,
  };
}

export function uploadLevelImage(data) {
  return (dispatch) => {
    dispatch(postLevelImageStart());
    apiClient
      .getLevelApi()
      .levelApiUploadLevelImages(data)
      .then((resp) => {
        dispatch(postLevelImageSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postLevelImageFail());
        throw ex;
      });
  };
}

export function updateLevelStart() {
  return {
    type: types.UPDATE_LEVEL_START,
  };
}
export function updateLevelSuccess({ data }) {
  return {
    type: types.UPDATE_LEVEL_SUCCESS,
    data: data,
  };
}

export function updateLevelFail() {
  return {
    type: types.UPDATE_LEVEL_FAIL,
  };
}
export function updateLevel(data, id) {
  return (dispatch) => {
    dispatch(updateLevelStart());
    apiClient
      .getLevelApi()
      .levelApiUpdateLevel(id, data)
      .then((resp) => {
        dispatch(updateLevelSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(updateLevelFail());
        throw ex;
      });
  };
}

export function deleteLevelStart() {
  return {
    type: types.DELETE_LEVEL_START,
  };
}
export function deleteLevelSuccess({ data }) {
  return {
    type: types.DELETE_LEVEL_SUCCESS,
    data: data,
  };
}

export function deleteLevelFail() {
  return {
    type: types.DELETE_LEVEL_FAIL,
  };
}
export function deleteLevel(id) {
  return (dispatch) => {
    dispatch(deleteLevelStart());

    apiClient
      .getLevelApi()
      .levelApiDeleteLevel(id)
      .then((resp) => {
        dispatch(deleteLevelSuccess({ data: resp.data }));
        dispatch(getLevels());
      })
      .catch((ex) => {
        dispatch(deleteLevelFail());
        throw ex;
      });
  };
}
