import { styled } from '@mui/styles';

export const StyledAppLoader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& .loader-text': {
    marginTop: '15px',
  },
});
