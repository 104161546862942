import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Category from './../Category';
import PlayPractiseContainer from '../../containers/PlayPractiseContainer';
import ReviewQuestions from './../ReviewQuestions';
import ReportedQuestions from './../ReportedQuestions';
import UserProfile from '../../containers/UserProfileContainer';
import CompetitionCreate from '../../containers/CompetitionCreateContainer';
import QuestionList from '../../containers/QuestionListContainer';
import TagsList from '../TagsList';
import SubTagsList from '../SubTagsList';
import QuestionCreate from '../../containers/CreateQuestionContainer';
import TotalReferrals from './../../containers/TotalReferralContainer';
import CreateTag from '../../containers/CreateTagContainer';
import CreateSubtag from '../../containers/CreateSubtagContainer';
import QuestionDetailsContainer from '../../containers/QuestionDetailsContainer';
import Credits from '../../containers/CreditsContainer';
import ReviewReported from '../ReviewReported/ReviewReported';
import StoreAdmin from '../StoreAdmin';
import UserDetailsContainer from '../../containers/UserDetailsContainer';
import MockTest from '../../containers/MockTestContainer';
import MockTestCreate from '../../containers/MockTestCreateContainer';
import Sidebar from '../Sidebar/index';
import Appbar from './../Appbar';
import Loader from '../Loader';
import OrderHistory from '../OrderHistory/index';
import Store from '../Store';
import DataManagement from '../DataManagement';
import Cart from '../Store/Cart';
import OrdersStatus from '../StoreAdmin/OrdersStatus';
import ReportBug from '../ReportBug/ReportBug';
import AddDomains from '../Domains/AddDomains';
import CreateNewDomain from '../Domains/CreateNewDomain';
import ReportedBugs from '../ReportBug/ReportedBugs';
import { CompetitionSchedule } from '../Competition/CompetitionSchedule';
import CreateSchedule from '../Competition/CompetitionSchedule/CreateSchedule';
import CreateBadge from '../CreateBadge';
import CreateNewBadgeDialog from '../CreateBadge/CreateNewBadgeDialog';
import ReportFeature from '../ReportBug/ReportFeature';
import ReportFeedback from '../ReportBug/ReportFeedback';
import ContactUs from '../ReportBug';
import CommentReports from '../CommentReports/CommentReports';
import AdminFeatures from '../AdminFeatures';
import PlayCompetitionContainer from '../../containers/PlayCompetitionContainer';
import PlayMockTestContainer from '../../containers/PlayMockTestContainer';
import { withRouter } from '../../routes';
import Leaderboard from '../Leaderboard';
import CreateLevel from '../CreateLevel';
import CreateNewLevelDialog from '../CreateLevel/CreateNewLevelDialog';
import { getLevels } from '../../actions/levels';
import { useDispatch } from 'react-redux';
import Voting from '../Voting';
import AdminReviewOrgs from '../AdminReviewOrgs';
import AdminReviewJobs from '../AdminReviewJobs';
import CollegeDashboard from '../CollegeDashboard';
import Milestone from '../CollegeDashboard/Milestone';
import CreateNewMilestone from '../CollegeDashboard/Milestone/CreateNewMilestone';
import Dashboard from '../Dashboard';
import AdminCreateDegree from '../AdminDegree';
import CreateDegree from '../AdminDegree/CreateDegree';
import Specialization from '../AdminDegree/Specialization';
import CreateSpecialization from '../AdminDegree/CreateSpecialization';
import {
  StyledAppLoader,
  StyledLayout,
  StyledWrapper,
} from './StyledComponent';
import AdminEmailPreference from '../AdminEmailPreference';
import CreateEmailPreference from '../AdminEmailPreference/CreateEmailPreference';
import config from '../../config';
import AdminCoupon from '../AdminCoupon';
import CreateCoupon from '../AdminCoupon/CreateCoupon';
import SalesDashboard from '../SalesDashboard';
import AdminSales from '../SalesDashboard/AdminSales';
import AdminUserManagement from '../AdminUserManagement';
import AdminBulkEmail from '../EmailingList';

const Layout = (props) => {
  const [isOpen, setIsOpen] = useState(props.isMobile ? false : true);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const { auth, autoLogin } = props;
    if (!auth.loginSuccess) {
      autoLogin().then(() => {
        handleMount();
      });
    } else {
      handleMount();
    }
    if (localStorage.getItem('onboarding_done')) {
      navigate(localStorage.getItem('redirect_url'));
    }
  }, []);

  useEffect(() => {
    if (props.auth?.isLoggedOut) {
      if (
        window.location.hostname === 'abekus.com' ||
        window.location.hostname === 'abekus.ai'
      )
        window.location = `${config.WEBSITE_URL}`;
      else navigate('/login');
      localStorage.removeItem('redirect_url');
      localStorage.removeItem('projq__redirect_path');
    }
  }, [props.auth?.isLoggedOut]);

  useEffect(() => {
    if (props.domain?.isUpdatingDomainSuccess) {
      const { getUserAnalytics, getTag } = props;
      getUserAnalytics();
      getTag();
    }
  }, [props.domain?.isUpdatingDomainSuccess]);

  useEffect(() => {
    setIsOpen(isMobile ? false : true);
  }, [props.isMobile]);

  const handleMount = () => {
    const {
      user,
      getUserProfile,
      getDomain,
      initRememberCategoryId,
      getUserAnalytics,
      getTag,
      getCreditsCost,
    } = props;

    if (!user?.data?.id) {
      getCreditsCost();
      getUserProfile()
        .then(() => {
          getUserAnalytics();

          dispatch(getLevels());

          initRememberCategoryId();

          return getDomain();
        })
        .then(() => {
          getTag();
        })
        .catch(() => {});
    } else {
      getTag();
    }
  };

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const { auth, user, isMobile, changeDomain, domain, tag } = props;
  const isMetadataPromptOpen = !user?.data?.viewed_onboardings?.includes(
    'LEARNING_ONBOARDING',
  );
  const isSales = user?.data?.user_type === 'SalesExecutive';

  if (!auth.loginSuccess || !user.isUserFetchSuccess || !tag.list?.length) {
    return (
      <StyledAppLoader className="app-loader">
        <Loader size={44} color="primary" shouldShowLoadingText />
      </StyledAppLoader>
    );
  }

  if (isMetadataPromptOpen)
    return (
      <StyledLayout className="layout no_sidebar">
        <CssBaseline />
        <UserDetailsContainer />
      </StyledLayout>
    );

  return (
    <StyledLayout className="layout">
      <CssBaseline />
      <Appbar {...props} open={isOpen} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar
        handleDrawerClose={handleDrawerClose}
        open={isOpen}
        user={user}
        isMobile={isMobile}
        domains={domain.list || []}
        changeDomain={changeDomain}
      />
      <main
        className={classnames('layout__content', {
          'layout__content--shift': isOpen,
        })}
      >
        <StyledWrapper className="wrapper">
          <Routes>
            <Route
              path={`question/create`}
              element={<QuestionCreate {...props} />}
            />

            <Route
              path={`question/edit/:questionId`}
              element={<QuestionCreate {...props} />}
            />

            <Route path={`question`} element={<QuestionList {...props} />} />

            <Route
              path={`question/:questionId`}
              element={<QuestionDetailsContainer {...props} />}
            />
            <Route
              path={`underReview`}
              element={<ReviewQuestions {...props} />}
            ></Route>

            <Route
              path={`answer/:categoryId/play`}
              element={<PlayPractiseContainer {...props} />}
            />

            <Route
              path={`answer/:competitionId/competition`}
              element={<PlayCompetitionContainer {...props} />}
            />

            <Route
              path={`answer/:testId/mockTest`}
              element={<PlayMockTestContainer {...props} />}
            />

            <Route
              path={`practise`}
              element={
                <Category
                  {...props}
                  steps={[]}
                  step2={false}
                  handleDrawerClose={handleDrawerClose}
                  handleDrawerOpen={handleDrawerOpen}
                />
              }
            ></Route>

            <Route path={'leaderboard'} element={<Leaderboard />} />

            <Route
              path={`competition/create`}
              element={<CompetitionCreate {...props} />}
            />

            <Route path={`bulkEmail`} element={<AdminBulkEmail />} />
            <Route
              path={`orderHistory`}
              element={<OrderHistory {...props} />}
            />

            <Route path={`store`} element={<Store {...props} />}></Route>
            <Route path={`store/cart`} element={<Cart {...props} />} />

            <Route
              path={`storeAdmin/orders`}
              element={<OrdersStatus {...props} />}
            />
            <Route path={`storeAdmin`} element={<StoreAdmin {...props} />} />
            <Route
              path={`datamanagement`}
              element={<DataManagement {...props} />}
            />
            <Route path={`reportbug`} element={<ReportBug {...props} />} />
            <Route
              path={`reportfeature`}
              element={<ReportFeature {...props} />}
            />
            <Route
              path={`reportfeedback`}
              element={<ReportFeedback {...props} />}
            />
            <Route path={`contact-us`} element={<ContactUs {...props} />} />
            <Route
              path={`reportedBugs`}
              element={<ReportedBugs {...props} />}
            />
            <Route
              path={`reportedBugs/:id`}
              element={<ReportedBugs {...props} />}
            />

            <Route
              path={`competition/edit/:competitionId`}
              element={<CompetitionCreate {...props} />}
            />
            <Route
              path={`competition_schedule`}
              element={<CompetitionSchedule {...props} />}
            />
            <Route
              path={`competition_schedule/:id`}
              element={<CreateSchedule {...props} />}
            />

            <Route
              path={`categories/:domainId`}
              element={<TagsList {...props} />}
            ></Route>
            <Route
              path={`domainList`}
              element={<AddDomains {...props} />}
            ></Route>
            <Route
              path={`createNewDomain`}
              element={<CreateNewDomain {...props} />}
            />
            <Route
              path={`editDomain/:domainId`}
              element={<CreateNewDomain {...props} />}
            />
            <Route path={`referral`} element={<TotalReferrals {...props} />} />

            <Route path={`createTag`} element={<CreateTag {...props} />} />
            <Route path={`badgeAdmin`} element={<CreateBadge {...props} />} />
            <Route
              path={`createNewBadge`}
              element={<CreateNewBadgeDialog {...props} />}
            />
            <Route
              path={`editBadge/:badgeId`}
              element={<CreateNewBadgeDialog {...props} />}
            />

            <Route path={`level-admin`} element={<CreateLevel {...props} />} />
            <Route
              path={`create-new-level`}
              element={<CreateNewLevelDialog {...props} />}
            />
            <Route
              path={`edit-level/:level_id`}
              element={<CreateNewLevelDialog {...props} />}
            />

            <Route path={'dashboard'} element={<Dashboard {...props} />} />

            <Route
              path={`createSubtag`}
              element={<CreateSubtag {...props} />}
            />

            <Route
              path={`editSubTag/:tagId/:subTagId`}
              element={<CreateSubtag {...props} />}
            />

            <Route path={`editTag/:tagId`} element={<CreateTag {...props} />} />

            <Route
              path={`subTags/:tagId`}
              element={<SubTagsList {...props} />}
            ></Route>

            <Route
              path={`reported`}
              {...props}
              element={<ReportedQuestions {...props} />}
            ></Route>

            <Route
              path={`review-reported`}
              {...props}
              element={<ReviewReported {...props} />}
            ></Route>

            <Route
              path={`credits`}
              {...props}
              element={<Credits {...props} />}
            ></Route>

            <Route path={`user/*`} element={<UserProfile {...props} />} />

            <Route
              path={`mockTest/create`}
              element={<MockTestCreate {...props} />}
            />
            <Route
              path={`mockTest/edit/:mockTestId`}
              element={<MockTestCreate {...props} />}
            />
            <Route
              path={`adminFeatures`}
              element={<AdminFeatures {...props} />}
            />
            <Route
              path={`reviewOrgs`}
              element={<AdminReviewOrgs {...props} />}
            />
            <Route
              path={`reviewJobs`}
              element={<AdminReviewJobs {...props} />}
            />
            <Route
              path={`collegeDashboard`}
              element={<CollegeDashboard {...props} />}
              exact
            />

            <Route
              path={`college/milestone`}
              element={<Milestone {...props} />}
              exact
            />
            <Route
              path={`createNewMilestone`}
              element={<CreateNewMilestone {...props} />}
              exact
            />
            <Route
              path={`editNewMilestone/:collegeMilestoneId`}
              element={<CreateNewMilestone {...props} />}
              exact
            />

            <Route path={`mockTest`} element={<MockTest {...props} />} />
            <Route
              path={`admin/degrees`}
              element={<AdminCreateDegree {...props} />}
            />
            <Route
              path={`admin/create/degree`}
              element={<CreateDegree {...props} />}
            />
            <Route
              path={`admin/create/degree/:degreeId`}
              element={<CreateDegree {...props} />}
            />
            <Route
              path={`admin/specialization/:degreeId`}
              element={<Specialization {...props} />}
            />
            <Route
              path={`admin/create/specialization/:degreeId`}
              element={<CreateSpecialization {...props} />}
            />
            <Route
              path={`admin/create/specialization/:degreeId/:specializationId`}
              element={<CreateSpecialization {...props} />}
            />
            <Route
              path={`admin/email-preference`}
              element={<AdminEmailPreference {...props} />}
            />
            <Route
              path={`admin/create/email-preference`}
              element={<CreateEmailPreference {...props} />}
            />
            <Route
              path={`admin/create/email-preference/:notificationPreferenceId`}
              element={<CreateEmailPreference {...props} />}
            />

            <Route path={`comment-reports`} element={<CommentReports />} />

            <Route path="voting" element={<Voting />} />
            <Route path="admin/coupon" element={<AdminCoupon />} />
            <Route path="admin/create/coupon" element={<CreateCoupon />} />
            <Route
              path="admin/create/coupon/:couponId"
              element={<CreateCoupon />}
            />
            <Route path="sales/dashboard" element={<SalesDashboard />} />
            <Route path="admin/sales/dashboard" element={<AdminSales />} />
            <Route path="user-management" element={<AdminUserManagement />} />

            <Route
              path={'*'}
              element={
                <Navigate to={`${isSales ? 'sales/dashboard' : 'dashboard'}`} />
              }
            />
          </Routes>
        </StyledWrapper>
      </main>
    </StyledLayout>
  );
};

const withMediaQuery = (Component) => (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return <Component isMobile={isMobile} {...props} />;
};

export default withMediaQuery(withRouter(Layout));
