export default {
ENV: 'production',
API_URL: 'https://devapi.abekus.com',
WEBSITE_URL: 'https://abekus.com',
ROLLBAR_ACCESS_TOKEN: '53423c951b8b4fe6a15d9ff4599a1404',
RAZORPAY_KEY: '',
RAZORPAY_DESCRIPTION: 'Abekus Store Products',
RAZORPAY_NAME: 'WBTS Pvt. Ltd.',
RAZORPAY_CURRENCY: 'INR',
IS_TEST: false,
};

