import { Box, Container, DialogTitle, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const StyleSalesDashboard = styled(Container)(() => ({
  '& .sales__list__name': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const CallAttemptDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
export const CallAttemptFieldHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  display: 'flex',
  alignItems: 'center',
}));
export const SubmitBtn = styled(Box)(() => ({
  background: 'transparent',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: '10px',
  cursor: 'pointer',
  border: '2px solid #25507B',
  borderWidth: '2px',
}));
