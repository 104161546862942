import {
  styled,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

export const VotingBtn = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexBasis: '50%',
  cursor: 'pointer',
  height: '75%',
  flexDirection: 'column',
});

export const PageNavBtn = styled(Button)(({ selected }) => ({
  height: '28px',
  background: selected ? '#25507B' : 'white',
  color: selected ? 'white' : '#25507B',
  fontWeight: selected ? '600' : '400',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    background: selected ? '#25507B' : 'rgba(37, 80, 123, 0.04)',
    color: selected ? 'white' : '#25507B',
  },
}));

export const NoMoreQuesContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  textAlign: 'center',
  maxWidth: '650px',
});

export const PageNavContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  padding: '5px',
  background: 'white',
  borderRadius: '25px',
  width: 'fit-content',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
}));
export const RemainingVotesContainer = styled(Grid)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  background: 'white',
  padding: '10px 20px',
  width: 'fit-content',
  borderRadius: '5px',
  position: 'absolute',
  right: '20px',
});

export const ReasonTypeContainer = styled(Grid)({
  background: 'white',
  padding: '10px',
  borderRadius: '5px',
  border: '1px solid #D60505',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

export const VotingBtnContainer = styled(Grid)({
  background: 'white',
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  flexGrow: '1',
  height: '80px',
  borderRadius: '10px',
  padding: '5px',
});

export const NextBtn = styled(Box)(({ isSubmitted }) => ({
  background: 'transparent',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60px',
  borderRadius: '10px',
  cursor: 'pointer',
  border: '2px solid #25507B',
  borderWidth: isSubmitted ? '2px' : '0px',
}));

export const OptionContainer = styled(Grid)(({ reason }) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  marginBottom: '10px',
  background: reason.original_ans_bg_color,
  borderRadius: '5px',
  paddingLeft: '3px',
  padding: '5px',
}));

export const OptionSerialNumber = styled(Typography)({
  width: '25px',
  height: '25px',
  color: '#3572B0',
  background: '#D9EDF7',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2px',
});

export const TagContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  background: '#FFA5001A',
  borderRadius: '5px',
  padding: '5px 10px',
});

export const AnswerBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#FFA5001A',
  borderRadius: '5px',
  width: 'fit-content',
  minWidth: '30px',
  minHeight: '30px',
  padding: '5px',
});

export const MobileActionBtn = styled(Button)({
  flexBasis: '33%',
  fontSize: '20px',
  display: 'flex',
  flexDirection: 'column',
});

export const HistoryNavigationBtn = styled(IconButton)({
  background: '#25507B',
  borderRadius: '10px',
  width: '50px',
  height: '50px',

  '&:hover': {
    background: '#25507B',
  },
  '&.Mui-disabled': {
    background: '#7B7B7B80',

    '& svg': {
      color: '#7B7B7B80',
    },
  },
});

export const StyleQuestionContainer = styled(Grid)(({ theme }) => ({
  height: '37vh',
  [theme.breakpoints.down('sm')]: {
    height: '70vh',
  },
}));
