import React, { useEffect, useState } from 'react';
import {
  deleteJob,
  getJobReview,
  jobBulkStatusUpdate,
} from '../../actions/adminJobs';
import {
  Chip,
  Container,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  Checkbox,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  SearchRounded as SearchIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import '../ReportBug/ReportedBugs/index.css';
import JobPreview from './JobPreview';
import { useNavigate } from 'react-router-dom';
import { CustomDialogBox } from '../DialogBox';
import MessageBar from '../MessageBar';
import JobPremiumUpdateDialog from './JobPremiumUpdateDialog';
import moment from 'moment';

const AdminReviewJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviewJobsData } = useSelector((state) => state.adminJobs);
  const { data } = useSelector((state) => state.user);

  const [status, setStatus] = useState('UnderReview');
  const [searchUser, setSearchUser] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrderByTime, setSortOrderByTime] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedStatusToChange, setSelectedStatusToChange] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [openMessageBar, setOpenMessageBar] = useState('');
  const [openJobPremiumUpdateDialog, setOpenJobPremiumUpdateDialog] = useState(
    false,
  );
  const [rejectionReason, setRejectionReason] = useState('');

  useEffect(() => {
    dispatch(
      getJobReview(
        status,
        searchUser,
        page + 1,
        rowsPerPage,
        sortBy,
        sortOrderByTime,
      ),
    );
    setSelectedIds([]);
  }, [status, searchUser, page, rowsPerPage, sortBy, sortOrderByTime]);
  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };
  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id),
      );
    }
  };
  const handleBulkStatusChange = () => {
    const data = {
      status: selectedStatusToChange,
      job_ids: selectedIds,
      rejection_reason:
        selectedStatusToChange === 'Rejected' ? rejectionReason : '',
    };
    dispatch(
      jobBulkStatusUpdate(
        data,
        status,
        searchUser,
        sortBy,
        sortOrderByTime,
        page + 1,
        rowsPerPage,
      ),
    );
    setSelectedIds([]);
  };
  const handleJobDelete = (jobData) => {
    dispatch(
      deleteJob(
        jobData.org_id,
        jobData.id,
        status,
        searchUser,
        page + 1,
        rowsPerPage,
        sortBy,
        sortOrderByTime,
      ),
    ).then((res) => {
      setOpenMessageBar(res?.error);
    });
  };
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
        className="collegeListContainer"
      >
        <Button
          variant="outlined"
          sx={{ width: 'fit-content' }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <div className="scoreboard__filters__item">
              <Select
                options={[
                  { id: '', name: 'All' },
                  { id: 'Active', name: 'Active' },
                  { id: 'UnderReview', name: 'UnderReview' },
                  { id: 'Paused', name: 'Paused' },
                  { id: 'Draft', name: 'Draft' },
                  { id: 'Closed', name: 'Closed' },
                  { id: 'Rejected', name: 'Rejected' },
                  { id: 'Concluded', name: 'Concluded' },
                  { id: 'AccessExpired', name: 'AccessExpired' },
                ]}
                onChange={(option) => setStatus(option.id)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={status ? status : 'Status'}
                value={status}
                isClearable
              />
            </div>
          </Grid>

          <Grid item md={6} xs={12} alignItems="flex-end">
            <div className="scoreboard__filters__item">
              <TextField
                fullWidth
                placeholder="Search..."
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon htmlColor="#25507B" />
                    </InputAdornment>
                  ),
                }}
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <>
          <div className="table-container">
            <Table
              style={{
                backgroundColor: 'white',
                maxWidth: '100%',
                overflow: 'auto',
              }}
            >
              <TableHead>
                {reviewJobsData?.jobs?.length === 0 ? (
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    No Records Found
                  </Typography>
                ) : (
                  <TableRow>
                    {data?.user_type == 'Admin' && <TableCell></TableCell>}
                    <TableCell>Job Title</TableCell>
                    <TableCell
                      style={{
                        minWidth: '8rem',
                        textAlign: 'center',
                      }}
                    >
                      Positions
                    </TableCell>
                    <TableCell>Job Type</TableCell>
                    <TableCell sx={{ minWidth: '6rem', textAlign: 'center' }}>
                      Flexibility
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell
                      sx={{ minWidth: '8rem' }}
                      onClick={() => {
                        setSortBy('date');
                        setSortOrderByTime(
                          sortOrderByTime === 'asc' ? 'desc' : 'asc',
                        );
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5 rem',
                          justifyContent: 'center',
                        }}
                      >
                        Date
                        {sortOrderByTime === 'asc' ? (
                          <ArrowUpward style={{ height: '14px' }} />
                        ) : (
                          <ArrowDownward style={{ height: '14px' }} />
                        )}
                      </div>
                    </TableCell>
                    <TableCell>Posted By</TableCell>
                    {data?.user_type == 'Admin' && (
                      <TableCell>Premium</TableCell>
                    )}

                    <TableCell>Applicants</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {reviewJobsData?.jobs?.map((org, idx) => (
                  <TableRow key={idx}>
                    {data?.user_type == 'Admin' && (
                      <TableCell sx={{ display: 'flex' }}>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange(e, org.id)}
                          checked={selectedIds.includes(org.id)}
                        />
                        <IconButton
                          onClick={() => {
                            setOpenDeleteDialog(true);
                            setSelectedOrg(org);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell>
                      {org.job_role_details?.name || org.title}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {org.no_of_positions ? org.no_of_positions : 'NA'}
                    </TableCell>
                    <TableCell>{org.job_type ? org.job_type : 'NA'}</TableCell>
                    <TableCell sx={{ minWidth: '6rem', textAlign: 'center' }}>
                      {org.work_flexibility ? org.work_flexibility : 'NA'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        variant="outlined"
                        color={
                          org.status === 'Active'
                            ? 'success'
                            : org.status === 'Pending'
                            ? 'primary'
                            : 'primary'
                        }
                        size="small"
                        label={org.status ? org.status : 'Pending'}
                        className="subtag"
                        sx={{
                          color: org.status === 'Blocked' && 'red',
                          border: org.status === 'Blocked' && '1px solid red',
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Tooltip
                        title={
                          <Typography padding={'10px'} fontSize={'12px'}>
                            {moment(org?.created_at).format('DD/MM/YYYY')}
                          </Typography>
                        }
                        placement="left"
                      >
                        {moment.utc(org?.created_at).fromNow()}
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {`${org?.user_details?.first_name}
                      ${org?.user_details?.last_name}`}
                    </TableCell>

                    {data?.user_type == 'Admin' && (
                      <TableCell>
                        {' '}
                        <Chip
                          variant="outlined"
                          color={org?.is_premium ? 'success' : 'error'}
                          size="small"
                          label={org?.is_premium?.toString()}
                          className="subtag"
                          onClick={() => {
                            setOpenJobPremiumUpdateDialog(true);
                            setSelectedOrg(org);
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {org?.total_applicants}
                    </TableCell>
                    {data?.user_type == 'Admin' && (
                      <TableCell>
                        <JobPreview jobData={org} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            style={{ width: '95%' }}
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            count={reviewJobsData?.total_pages * reviewJobsData?.page_size}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
            className="scoreboard__navigation"
          />
        </>
        {selectedIds.length > 0 && (
          <Grid
            container
            sx={{
              background: 'white',
              padding: '10px',
              width: '100%',
              position: 'sticky',
              bottom: '10px',
              border: '2px solid #25507B',
            }}
          >
            <Grid item sm={6} xs={12}>
              <Select
                options={[
                  { id: 'Active', name: 'Active' },
                  { id: 'UnderReview', name: 'UnderReview' },
                  { id: 'Paused', name: 'Paused' },
                  { id: 'Draft', name: 'Draft' },
                  { id: 'Closed', name: 'Closed' },
                  { id: 'Rejected', name: 'Rejected' },
                  { id: 'Concluded', name: 'Concluded' },
                  { id: 'AccessExpired', name: 'AccessExpired' },
                ]}
                onChange={(option) => {
                  setSelectedStatusToChange(option.id);
                  setRejectionReason('');
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={
                  selectedStatusToChange ? selectedStatusToChange : 'Status'
                }
                value={selectedStatusToChange}
                isClearable
                menuPlacement="auto"
              />
              {selectedStatusToChange === 'Rejected' && (
                <>
                  <br />
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Rejection Reason"
                    onChange={(e) => setRejectionReason(e.target.value)}
                    value={rejectionReason}
                  />
                </>
              )}
            </Grid>
            <Grid item sm={6} xs={12} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="outlined"
                onClick={handleBulkStatusChange}
                disabled={
                  selectedStatusToChange === 'Rejected' &&
                  rejectionReason === ''
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
      {openDeleteDialog && (
        <CustomDialogBox
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  handleJobDelete(selectedOrg);
                  setOpenDeleteDialog(false);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          title={'Are you sure you want to delete the job?'}
          maxWidth="sm"
          overflowVisible={false}
        >
          Once deleted, the job cannot be reverted.
        </CustomDialogBox>
      )}
      <MessageBar
        type={'error'}
        open={openMessageBar?.length > 0}
        onClose={() => setOpenMessageBar('')}
        message={openMessageBar?.split('_').join(' ')}
      />
      {openJobPremiumUpdateDialog && (
        <JobPremiumUpdateDialog
          open={openJobPremiumUpdateDialog}
          onClose={() => setOpenJobPremiumUpdateDialog(false)}
          selectedOrg={selectedOrg}
          status={status}
          searchUser={searchUser}
          sortBy={sortBy}
          sortOrderByTime={sortOrderByTime}
          page={page + 1}
          rowsPerPage={rowsPerPage}
        />
      )}
      ;
    </>
  );
};

export default AdminReviewJobs;
