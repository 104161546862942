import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormLabel,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import EmailBlock from '../EmailBlock';

const SubtractEmailsDialog = ({
  handleOpen,
  handleClose,
  handleDataSubmit,
}) => {
  const [inputValue, setInputValue] = useState('');
  const {
    control,
    watch,
    formState: { errors },
  } = useForm({});

  const emailSubList = watch('email_list');
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={handleOpen}
      PaperProps={{
        style: {
          padding: '0',
          minWidth: '350px',
        },
      }}
    >
      <DialogTitle>Add Emails to Subtract</DialogTitle>
      <DialogContent>
        <Controller
          name="email_list"
          control={control}
          defaultValue={[]}
          rules={{
            required:
              'Please enter atleast one of domain , email or competitions',
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <FormLabel mb="8px">Emails</FormLabel>
              <EmailBlock
                inputValue={inputValue}
                setInputValue={setInputValue}
                tags={value}
                setTags={onChange}
              />
              <Typography style={{ color: 'red', fontSize: '14px' }}>
                {errors.email_list ? errors.email_list.message : ''}
              </Typography>
            </>
          )}
        />
      </DialogContent>
      <DialogActions style={{ padding: '20px' }}>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={() => handleDataSubmit(emailSubList)}
        >
          Submit
        </Button>
        <Button variant="contained" type="button" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubtractEmailsDialog;
