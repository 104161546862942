import { Dialog, IconButton, Select, MenuItem, Box } from '@mui/material';
import React from 'react';

import { CallAttemptDialogTitle } from './SalesStyledComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm } from 'react-hook-form';
import CallAttempts from './components/CallAttempts';
import EmailAttempts from './components/EmailAttempts';
import JobsPosted from './components/JobsPosted';

const CommunicationDetails = ({ open, handleClose, salesLead }) => {
  const { register, watch } = useForm();
  const contactType = watch('contact_type') || 'Call';

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <Box sx={{ overflow: 'hidden' }}>
        <CallAttemptDialogTitle>
          <IconButton
            display={'flex'}
            justifyContent={'flex-end'}
            onClick={handleClose}
          >
            <CancelIcon />
          </IconButton>
          <Select
            {...register('contact_type')}
            variant="outlined"
            defaultValue={'Call'}
          >
            <MenuItem value="Call" key="Call">
              Call Attempts
            </MenuItem>
            <MenuItem value="Email" key="Email">
              Email Attempts
            </MenuItem>
            <MenuItem value="jobs" key="jobs">
              Jobs Posted
            </MenuItem>
          </Select>
        </CallAttemptDialogTitle>

        {contactType === 'Call' ? (
          <CallAttempts salesLead={salesLead} />
        ) : contactType === 'Email' ? (
          <EmailAttempts salesLead={salesLead} />
        ) : (
          <JobsPosted salesLead={salesLead} />
        )}
      </Box>
    </Dialog>
  );
};

export default CommunicationDetails;
