import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useState } from 'react';
import { useEffect } from 'react';
import analyticsTimer from './images/analytics_timer.svg';
import analyticsSpeed from './images/analytics_speed.svg';
import acheivementBg from './images/background.png';
import Loader from '../Loader';
import QuestionCard from '../MockTest/QuestionCard';
import { StyledResult } from './ResultStyledComponent';

function Header({ onBack, name }) {
  return (
    <div className="result__header">
      <IconButton onClick={onBack} size="large">
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="h6">{name}</Typography>
      {/* <div className={`result__participant`}>
        <img src={participant} /> {participants && participants} Participants
      </div> */}
    </div>
  );
}

function Categories({ tagList }) {
  return (
    <div className="result__categories">
      <Typography variant="caption" component="p">
        Categories :
      </Typography>
      {tagList?.map((tag, index) => (
        <Chip key={index} label={tag} size="small" />
      ))}
    </div>
  );
}
function Statistics({ statistics, loading, isClassic }) {
  const gradientMap = {
    total_correct: '#6CE06A',
    total_incorrect: '#FF6B6B',
    skipped: '#FFC66B',
    timed_out: '#FF6B6B',
  };
  let gradient = 'conic-gradient(';
  Object.keys(gradientMap).forEach((key) => {
    if (statistics[key] > 0) {
      gradient += `${gradientMap[key]} 0,`;
      gradient += `${gradientMap[key]} ${(statistics[key] /
        statistics.total_attempted) *
        100}%,`;
    }
  });
  gradient = gradient.slice(0, -1) + ')';

  const renderContent = (img, content) => (
    <div>
      {img}
      <div>
        {content.map(({ label, value, color }) => (
          <Typography variant="caption" component="p" key={value}>
            {label}
            <span className={color}>{value}</span>
          </Typography>
        ))}
      </div>
    </div>
  );

  if (loading) return null;

  const analyticsContent = [
    {
      label: 'Av. Time Taken',
      value: `${Math.round(statistics.avg_time_taken * 100) / 100} secs`,
      color: 'primary',
    },
    {
      label: 'Total Time Taken',
      value: `${Math.round((statistics.total_time_taken / 60) * 100) / 100} ${
        statistics.total_time_taken / 60 === 1 ? 'min' : 'mins'
      }`,
      color: 'primary',
    },
  ];
  if (!isClassic)
    analyticsContent.unshift({
      label: 'Timed out',
      value: `${statistics.timed_out}/${statistics.total_attempted}`,
      color: 'error',
    });

  return (
    <div className="result__statistics">
      {/* <div className="result__statistics--header">{renderHeader()}</div> */}
      <div className="result__statistics--content">
        {renderContent(
          <div
            className="pie-chart"
            style={{
              width: 50,
              height: 50,
              background: gradient,
            }}
          />,
          [
            {
              label: 'Total Correct',
              value: `${statistics.total_correct}/${statistics.total_attempted}`,
              color: 'success',
            },
            {
              label: 'Total Incorrect',
              value: `${statistics.total_incorrect}/${statistics.total_attempted}`,
              color: 'error',
            },
            {
              label: 'Skipped Questions',
              value: `${statistics.skipped}/${statistics.total_attempted}`,
              color: 'warning',
            },
          ],
        )}
        {renderContent(
          <img
            src={analyticsTimer}
            width="50px"
            height="50px"
            alt="Analytics"
          />,
          analyticsContent,
        )}
        {renderContent(
          <img
            src={analyticsSpeed}
            width="50px"
            height="50px"
            alt="Analytics"
          />,
          [
            {
              label: 'Score',
              value: statistics.user_score?.toFixed(2),
              color: 'primary',
            },
            {
              label: 'Accuracy %',
              value: `${statistics.accuracy?.toFixed(2)}%`,
              color: 'primary',
            },
            {
              label: 'Total attempted',
              value: `${statistics.total_attempted}`,
              color: 'primary',
            },
          ],
        )}
      </div>
    </div>
  );
}

function QuestionList({
  questions,
  unlockSolution,
  loading,
  paginationInfo,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  if (loading) return <Loader />;

  return (
    <div className="result__questionlist">
      {questions.map(({ question, ...attempt }, index) => (
        <QuestionCard
          key={question.id}
          index={index + 1}
          question={question}
          attempt={attempt}
          unlockSolution={unlockSolution}
        />
      ))}
      <TablePagination
        component="nav"
        page={paginationInfo?.currentPage}
        rowsPerPage={rowsPerPage}
        count={paginationInfo?.pageSize * paginationInfo?.totalPages}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        style={{ marginTop: '50px' }}
      />
    </div>
  );
}

function Achievement({
  certificateDownloadHandler,
  isCertificateAvailable,
  examAnalytics,
  isloading,
}) {
  if (isloading) {
    return null;
  } else if (
    examAnalytics.credits_recieved === null ||
    examAnalytics.gems_received === null ||
    examAnalytics.score_rank === null
  ) {
    return null;
  }
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12 }}
      className="result__achievements"
    >
      <Grid xs={12} sm={12} md={8}>
        <Typography variant="h6">Achievements</Typography>
        <div className="result__panel">
          <div className="result__imgCtn">
            <img src={acheivementBg} alt={'achievement'} />
          </div>
          <div className="result__detailsCtn">
            <div className="c">
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: 'center',
                  color: '#25507B',
                  lineHeight: 1.2,
                }}
              >
                {examAnalytics.credits_recieved} <br /> Credits
              </Typography>
            </div>
            <div className="g">
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: 'center',
                  color: '#25507B',
                  lineHeight: 1.2,
                }}
              >
                {examAnalytics.gems_received} <br /> Gems
              </Typography>
            </div>
            <div className="r">
              <Typography
                variant="h6"
                style={{ textAlign: 'center', color: '#25507B' }}
              >
                {examAnalytics.score_rank}
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid xs={12} sm={12} md={4} className="result__gap">
        {isCertificateAvailable && (
          <Typography variant="h6">Certificate of achievement</Typography>
        )}
        {isCertificateAvailable && (
          <div className="result__certificate">
            <div className="fade">
              {isCertificateAvailable ? (
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={certificateDownloadHandler}
                >
                  <Typography color="white">Download Certificate</Typography>
                </Button>
              ) : (
                <Button variant="contained" type="button" color="primary">
                  <Typography color="white">
                    Certificate not Available
                  </Typography>
                </Button>
              )}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

function Result({
  getCompetitionQuestions,
  getCompetitionAnalytics,
  selectedCompetition,
  unlockSolution,
  competition,
  certificateDownloadHandler,
  isCertificateAvailable,
  categories,
  onBack,
}) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const {
  //   questions,
  //   isGettingMockTestQuestions,
  //   questionsPaginationInfo,
  // } = mockTest;
  const {
    analytics,
    isFetchingAnalytics,
    questions,
    isGettingCompetitionQuestions,
    questionsPaginationInfo,
  } = competition;

  const getStats = async () => {
    getCompetitionAnalytics(selectedCompetition.id);
  };

  useEffect(() => {
    getStats();
  }, [selectedCompetition.id]);

  useEffect(() => {
    getCompetitionQuestions(selectedCompetition.id, page, rowsPerPage, true);
  }, [selectedCompetition.id]);

  const handleChangePage = (_, newPage) => {
    const currentPage = newPage + 1;
    setPage(currentPage);
    getCompetitionQuestions(
      selectedCompetition.id,
      currentPage,
      rowsPerPage,
      true,
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    getCompetitionQuestions(selectedCompetition.id, page, newRowsPerPage, true);
  };

  return (
    <StyledResult>
      <Box position="relative" className="result">
        <Header
          onBack={onBack}
          name={selectedCompetition.name}
          participants={analytics.total_registered}
        />
        <Categories tagList={categories} />
        <Statistics
          isClassic={selectedCompetition.exam_mode === 'classic'}
          statistics={analytics}
          loading={isFetchingAnalytics}
        />
        <Achievement
          certificateDownloadHandler={certificateDownloadHandler}
          isCertificateAvailable={isCertificateAvailable}
          examAnalytics={analytics}
          loading={isFetchingAnalytics}
        />
        <QuestionList
          rowsPerPage={rowsPerPage}
          questions={questions}
          paginationInfo={questionsPaginationInfo}
          loading={isGettingCompetitionQuestions}
          unlockSolution={unlockSolution}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </StyledResult>
  );
}

export default Result;
