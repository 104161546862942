import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import ReactSelect from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import MessageBar from '../MessageBar';
import PhoneNumberVerify from './PhoneNumberVerify';
import UserProfileUpload from './UserProfileUpload';
import tickIcon from './img/email-tick.svg';
import BellIcon from '../Domains/images/bell.svg';
import { useLocation } from 'react-router-dom';
import { getTag } from '../../actions/tag';

import './index.scss';
import ImageCarousel from './ImageCarousel';
import DomainSelect from '../Domains/DomainSelect';
import { useDispatch } from 'react-redux';
import { onboardingDone } from '../../actions/user';

const CustomStepConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
    [theme.breakpoints.down('md')]: {
      left: 'calc(-70% + 15px)',
    },
  },
  active: {
    '& $line': {
      borderColor: '#25507B',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#25507B',
    },
  },
  line: {
    borderColor: '#D9D9D9',
    borderTopWidth: 15,
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles(() => ({
  activeContainerBackground: {
    backgroundColor: '#25507B',
  },
  inactiveContainerBackground: {
    backgroundColor: '#D9D9D9',
  },
  activeStepBackground: {
    backgroundColor: 'white',
  },
  inactiveStepBackground: {
    backgroundColor: '#25507B',
  },
  completedStepBackground: {
    backgroundColor: '#4CAF50',
  },
  activeTextColor: {
    color: '#25507B',
  },
  inactiveTextColor: {
    color: 'white',
  },
  completedColor: {
    color: 'white',
  },
  checkIcon: {
    fontSize: 18,
    marginTop: 1,
  },
}));

function getSteps() {
  return ['Personal', 'Domain', 'Picture'];
}

const checkIfPhoneNumberIsThere = (phoneNumber) =>
  phoneNumber &&
  phoneNumber?.split('-')?.length > 0 &&
  phoneNumber?.split('-')[1]?.length > 0;

const StepIcon = (props) => {
  const { active, completed } = props;
  const styles = useStyles();
  return (
    <div
      className={clsx(
        'step-container',
        active || completed
          ? styles.activeContainerBackground
          : styles.inactiveContainerBackground,
      )}
    >
      <div
        className={clsx(
          'step',
          active
            ? styles.activeStepBackground
            : completed
            ? styles.completedStepBackground
            : styles.inactiveStepBackground,
        )}
      >
        <span
          className={clsx(
            active
              ? styles.activeTextColor
              : completed
              ? styles.completedColor
              : styles.inactiveTextColor,
          )}
        >
          {completed ? <CheckIcon className={styles.checkIcon} /> : props.icon}
        </span>
      </div>
    </div>
  );
};

export default function UserDetails({ domain, allTags, user, ...actions }) {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      first_name: user.data?.first_name || '',
      last_name: user.data?.last_name || '',
      phone_number: user.data?.phone_number || '',
      current_status: null,
      goal: null,
      college_id: null,
      company_id: null,
      exam_name: null,
      selected_domain: domain?.find(
        (dom) => dom.id == state?.selectedQuestionDomain,
      ),
    },
  });
  const [page, setPage] = useState(0);
  const previousProps = useRef(user);
  const phoneNumberRef = useRef();
  const lastVerifiedPhoneNumberRef = useRef(
    user.data?.is_phone_verified ? user.data?.phone_number : undefined,
  );
  const [message, setMessage] = useState();
  const [domainChange, setDomainChange] = useState(null);

  const first_name = watch('first_name');
  const last_name = watch('last_name');
  const current_status = watch('current_status');
  const selected_domain = watch('selected_domain');

  const currentStatusOptions = [
    { label: 'College Student', value: 'Student' },
    {
      label: 'Working Professional',
      value: 'WorkingProfessional',
    },
    { label: 'Fresher', value: 'Fresher' },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  useEffect(() => {
    if (!allTags.length) {
      dispatch(getTag());
    }
  }, [allTags]);

  const handlePageChange = (data) => {
    let isAnyError = false;
    if (page === 1) {
      const namePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]{2,}$/;
      if (!namePattern.test(data.first_name)) {
        isAnyError = true;
        setError('first_name', {
          type: 'required',
          message: !data.first_name
            ? 'First name is required!'
            : 'Enter valid first name',
        });
      }
      if (!namePattern.test(data.last_name)) {
        isAnyError = true;
        setError('last_name', {
          type: 'required',
          message: !data.last_name
            ? 'Last name is required!'
            : 'Enter valid last name',
        });
      }
    }
    if (!isAnyError) setPage((page) => page + 1);
  };

  const onSubmit = (data) => {
    if (page !== 3) {
      handlePageChange(data);
      return;
    }

    if (user.OTPError) actions.setOTPError('');
    // custom validation
    if (
      !!phoneNumberRef.current?.phoneNumber &&
      checkIfPhoneNumberIsThere(phoneNumberRef.current?.phoneNumber)
    ) {
      if (
        ((lastVerifiedPhoneNumberRef.current &&
          lastVerifiedPhoneNumberRef.current !==
            phoneNumberRef.current.phoneNumber) ||
          (!!phoneNumberRef.current.phoneNumber &&
            !user.data.is_phone_verified)) &&
        phoneNumberRef.current.phoneNumber.startsWith('91')
      )
        return actions.setOTPError('Please verify your phone number');
    }
    // data sanitizing
    const metadata = {
      first_name: data.first_name,
      last_name: data.last_name,
      selected_domain: data.selected_domain.id,
      goal: data.goal?.value,
      current_status: data.current_status?.value,
      phone_number: data.phone_number?.split('-')[1]?.length
        ? data.phone_number
        : null,
    };
    if (data.goal?.value === 'Exam') {
      if (data.exam_name?.id) metadata.exam_id = data.exam_name.id;
      else metadata.exam_name = data.exam_name.value;
    }
    if (data.current_status?.value === 'Student') {
      if (data.college_id?.id) metadata.college_id = data.college_id.id;
      else metadata.college_name = data.college_id.value;
    } else if (data.current_status?.value === 'WorkingProfessional') {
      if (data.company_id?.id) metadata.company_id = data.company_id.id;
      else metadata.company_name = data.company_id.value;
    }

    // more validation
    if (metadata.company_name && metadata.company_name.length < 4)
      return setError('company_id', {
        type: 'min',
        message: "Company name can't be less than 4 characters",
      });
    if (metadata.college_name && metadata.college_name.length < 4)
      return setError('college_id', {
        type: 'min',
        message: "College name can't be less than 4 characters",
      });

    actions.sendMetadata(metadata);
  };

  useEffect(() => {
    if (user.error) {
      setMessage({
        type: 'error',
        content: user.error,
      });
    }
    if (
      !previousProps.current.isMetadataSendSuccess &&
      user.isMetadataSendSuccess
    ) {
      setMessage({
        type: 'success',
        content: 'Details updated successfully',
      });

      dispatch(onboardingDone({ name: 'LEARNING_ONBOARDING' }));
    }

    if (
      !previousProps.current.data.is_phone_verified &&
      user.data.is_phone_verified
    ) {
      setMessage({
        type: 'success',
        content: 'Phone number verified successfully',
      });
      lastVerifiedPhoneNumberRef.current = phoneNumberRef.current.phoneNumber;
    }

    if (!previousProps.current.isOTPSendSuccess && user.isOTPSendSuccess)
      setMessage({
        type: 'success',
        content: 'OTP sent successfully',
      });

    previousProps.current = user;
  }, [user]);

  useEffect(() => {
    if (user.selected_domain) {
      // check if user is in public page
      if (!window.location.pathname.includes('/l/')) actions.getTag();
    }
  }, [user.selected_domain]);

  useEffect(() => {
    if (user.selected_domain)
      setValue(
        'selected_domain',
        domain?.find((d) => d.id === user.selected_domain),
      );
  }, [domain, user.selected_domain]);
  useEffect(() => {
    setValue('selected_domain', domainChange);
  }, [domainChange]);

  const changedSelectedDomain = (data) => {
    setDomainChange(data);
  };
  const messageBar = (
    <MessageBar
      message={message?.content}
      type={message?.type}
      open={!!message}
      onClose={() => setMessage(undefined)}
    />
  );

  const getDisplayName = () => {
    if (page === 1) {
      return `Welcome ${user.data.first_name || 'to Abekus'}`;
    }
    return `Welcome ${first_name}`;
  };

  return (
    <>
      {messageBar}
      <div className="userdetails__container">
        <form onSubmit={(e) => e.preventDefault()}>
          {page === 0 ? (
            <ImageCarousel setPage={setPage} />
          ) : (
            <div>
              <Stepper
                activeStep={page - 1}
                alternativeLabel
                connector={<CustomStepConnector />}
              >
                {getSteps().map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {page === 1 ? (
                <div>
                  <div className="userdetails__heading">
                    <Typography variant="h4" color="primary">
                      {getDisplayName()}
                    </Typography>
                    <Typography variant="subtitle2" color="primary">
                      Please complete your profile, to help us give you a
                      personalized experience!
                    </Typography>
                  </div>
                  <div className="formitem__name">
                    <div className="form-control userdetails__formitem name-fields">
                      <label>First Name</label>
                      <TextField
                        variant="outlined"
                        value={first_name}
                        placeholder="Enter first name"
                        color="primary"
                        name="first_name"
                        onChange={(e) => {
                          clearErrors('first_name');
                          setValue('first_name', e.target.value.trimStart());
                        }}
                      />
                      <FormHelperText error={!!errors.first_name}>
                        {errors.first_name?.message}
                      </FormHelperText>
                    </div>
                    <div className="form-control userdetails__formitem name-fields">
                      <label>Last Name</label>
                      <TextField
                        variant="outlined"
                        value={last_name}
                        placeholder="Enter last name"
                        color="primary"
                        onChange={(e) => {
                          clearErrors('last_name');
                          setValue('last_name', e.target.value.trimStart());
                        }}
                      />
                      <FormHelperText error={!!errors.last_name}>
                        {errors.last_name?.message}
                      </FormHelperText>
                    </div>
                  </div>
                  <div className="form-control userdetails__formitem">
                    <label>Email Address</label>
                    <TextField
                      variant="outlined"
                      placeholder="Enter email address"
                      color="primary"
                      defaultValue={user?.data.email}
                      disabled
                      className="text-field-email"
                      InputProps={{
                        endAdornment: <img src={tickIcon} />,
                      }}
                    />
                  </div>
                  <div className="form-control userdetails__formitem">
                    <label>Phone No. (Optional) :</label>
                    <PhoneNumberVerify
                      {...actions}
                      phoneNumberRef={phoneNumberRef}
                      user={user}
                      setValue={setValue}
                    />
                    <FormHelperText error={!!errors.selected_domain}>
                      {errors.selected_domain?.message}
                    </FormHelperText>
                  </div>
                  <div className="form-control userdetails__formitem">
                    <label id="userdetails__status">Current Status :</label>
                    <Controller
                      control={control}
                      name="current_status"
                      defaultValue={current_status}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          placeholder="Select Current Status..."
                          value={current_status}
                          options={currentStatusOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          isClearable
                        />
                      )}
                    />
                  </div>
                  <div className="form-control userdetails__formitem single">
                    {current_status && current_status.value === 'Student' ? (
                      <>
                        <label id="userdetails__college">College :</label>
                        <div>
                          <Controller
                            control={control}
                            name="college_id"
                            defaultValue=""
                            rules={{
                              required: 'Please enter your college name',
                            }}
                            render={({ field }) => (
                              <>
                                <AsyncCreatableSelect
                                  {...field}
                                  cacheOptions
                                  isClearable
                                  noOptionsMessage={() =>
                                    'Search College to show options...'
                                  }
                                  htmlFor="userdetails__college"
                                  className="text-input-field"
                                  placeholder="Search College..."
                                  loadOptions={
                                    actions.sendCollegeAutocompleteQuery
                                  }
                                  getOptionLabel={(option) =>
                                    option.college_name
                                      ? option.college_name
                                      : `Add New:  "${option.value}"`
                                  }
                                  getOptionValue={(option) =>
                                    option.id ? option.id : option.value
                                  }
                                />
                              </>
                            )}
                          />
                          <FormHelperText error={!!errors.college_id}>
                            {errors.college_id?.message}
                          </FormHelperText>
                        </div>
                      </>
                    ) : current_status &&
                      current_status.value === 'WorkingProfessional' ? (
                      <>
                        <label id="userdetails__company">Company :</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="company_id"
                          rules={{
                            required: 'Please enter your company name',
                          }}
                          render={({ field }) => (
                            <div>
                              <AsyncCreatableSelect
                                {...field}
                                isClearable
                                cacheOptions
                                noOptionsMessage={() =>
                                  'Search Company to show options...'
                                }
                                placeholder="Search Company..."
                                htmlFor="userdetails__company"
                                className="text-input-field"
                                loadOptions={
                                  actions.sendCompanyAutocompleteQuery
                                }
                                getOptionLabel={(option) =>
                                  option.company_name
                                    ? option.company_name
                                    : `Add New:  "${option.value}"`
                                }
                                getOptionValue={(option) =>
                                  option.id ? option.id : option.value
                                }
                              />
                              <FormHelperText error={!!errors.company_id}>
                                {errors.company_id?.message}
                              </FormHelperText>
                            </div>
                          )}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              ) : page === 2 ? (
                <div>
                  <DomainSelect changedSelectedDomain={changedSelectedDomain} />
                </div>
              ) : (
                <div>
                  <div className="userdetails__heading">
                    <Typography variant="h4" color="primary">
                      {getDisplayName()}
                    </Typography>
                    <Typography variant="subtitle2" color="primary">
                      Please complete your profile, to help us give you a
                      personalized experience!
                    </Typography>
                  </div>
                  <UserProfileUpload />
                  <Box className="note">
                    <img src={BellIcon} alt="" />
                    &nbsp;&nbsp;
                    <Typography>
                      While uploading your profile picture, please ensure:
                      <br />
                      &nbsp;• Your image should be in JPEG/JPG/PNG format.
                      <br />
                      &nbsp;• Picture resolution should be between 200x200
                      pixels 4000x4000 pixels.
                      <br />
                    </Typography>
                  </Box>
                </div>
              )}
            </div>
          )}
          {(page === 1 || page === 2 || page === 3 || page === 4) && (
            <div className="form-control userdetails__formitem button">
              <Grid container className="button-group">
                <Grid item sm={6} xs={6}>
                  {(page === 2 || page === 3 || page === 4) && (
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        disabled={user.isMetadataSendInProgress}
                        endIcon={
                          user.isMetadataSendInProgress && (
                            <CircularProgress color="inherit" size={15} />
                          )
                        }
                        onClick={() => setPage(page - 1)}
                        sx={{ marginRight: '5px', borderRadius: '0px' }}
                      >
                        Previous
                      </Button>
                    </Box>
                  )}
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={
                        user.isMetadataSendInProgress ||
                        (page === 2 && !selected_domain)
                      }
                      endIcon={
                        user.isMetadataSendInProgress && (
                          <CircularProgress color="inherit" size={15} />
                        )
                      }
                      onClick={handleSubmit(onSubmit)}
                      sx={{ borderRadius: '0px' }}
                    >
                      {page === 3 ? 'Done' : 'Next'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
