import { styled } from '@mui/styles';

export const StyledAuth = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100vh',
  background: 'url(./img/background.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
  zIndex: 1,
  overflowY: 'auto',

  '& .auth__image-overlay': {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 2,
  },

  '& .auth__header': {
    textAlign: 'center',
    marginBottom: '40px',
  },

  '& .auth__container': {
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 900px)': {
      alignItems: 'flex-start',
    },
  },

  '& .auth__content-wrapper': {
    width: '100%',
    maxWidth: '83.33%',
    padding: '40px 20px',

    '@media (max-width: 900px)': {
      maxWidth: 'none',
    },
  },

  '& .auth__grids': {
    width: '100%',
    display: 'flex',
  },

  '& .auth__left': {
    flex: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },

  '& .auth__right': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .auth__content': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '420px',
    margin: '0 auto',
    padding: '0 40px',
  },

  '& .auth__details': {
    padding: '0 40px',

    '& .auth__details-item': {
      maxWidth: '420px',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '20px 0',
    },
  },

  '& .auth__icons': {
    fontSize: '32px !important',
    marginRight: '10px',
  },

  '& .icon-rose': {
    color: theme.palette.common.error,
  },

  '& .icon-primary': {
    color: theme.palette.playColors.purple,
  },

  '& .icon-info': {
    color: theme.palette.playColors.sky,
  },

  '& .auth__details-head': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .auth__details-head h3': {
    fontSize: '18px',
    color: theme.palette.common.black,
    margin: 0,
    marginBottom: '10px',
    fontWeight: 500,
    lineHeight: 1.5,
  },

  '& .auth__details-head p': {
    fontSize: '14px',
    color: theme.palette.component.color,
    margin: 0,
    lineHeight: 1.5,
    fontWeight: 300,
  },
}));
