import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Flag } from '@mui/icons-material';
import { Avatar, IconButton, Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NewCommentInput from './NewCommentInput';
import VoteIcon from '../../images/VoteIcon.svg';
import VoteActiveIcon from '../../images/VoteActiveIcon.svg';
import ReportModal from './ReportModal';
import { useDispatch, useSelector } from 'react-redux';
import { clearVoteACommentState, voteAComment } from '../../actions/discussion';

const CommentCard = ({
  commentId,
  username,
  picture,
  time,
  commentText,
  commentType,
  numberOfReplies,
  numberOfVotes,
  subComments,
  questionId,
  parentCommentId,
  topLevel,
  status,
  isDownvoted,
  isUpvoted,
}) => {
  const initialState = () => {
    if (isUpvoted) return 1;
    else if (isDownvoted) return -1;
    return 0;
  };
  const [voted, setVoted] = useState(initialState);
  const [previousVote, setPreviousVote] = useState(initialState);
  const [stagingVote, setStagingVote] = useState(() => null);
  const [
    numberOfSubcommentsToDisplay,
    setNumberOfSubcommentsToDisplay,
  ] = useState(() => 3);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const voteError = useSelector((state) => state.discussion.voteError);

  const dispatch = useDispatch();

  useEffect(() => {
    if (stagingVote !== null) {
      const voteType =
        stagingVote === 1 ? 'upvote' : stagingVote === -1 ? 'downvote' : null;
      dispatch(voteAComment(questionId, commentId, voteType));
    }
  }, [commentId, dispatch, questionId, stagingVote]);

  useEffect(() => {
    if (voteError !== '' && stagingVote !== null) {
      setStagingVote(null);
      setVoted(previousVote);
      dispatch(clearVoteACommentState());
    }
  }, [voteError, dispatch, stagingVote, previousVote]);

  const handleVote = (type) => {
    setPreviousVote(voted);
    if (type === voted) {
      setVoted(0);
      setStagingVote(0);
    } else {
      setVoted(type);
      setStagingVote(type);
    }
  };

  const voteButtonsMaker = (type) => (
    <button onClick={() => handleVote(type)} className="vote-buttons">
      <img
        src={voted === type ? VoteActiveIcon : VoteIcon}
        alt={'vote-icon'}
        className={type === 1 ? 'upvote-icon' : 'downvote-icon'}
      />
    </button>
  );

  const voteButtons = (
    <div className="votes-container">
      {voteButtonsMaker(1)}
      <span className="votes-count">{numberOfVotes + voted}</span>
      {voteButtonsMaker(-1)}
    </div>
  );

  const handleReport = () => {
    if (!reportModalOpen) setReportModalOpen(true);
  };

  const loadMoreComments = () => {
    setNumberOfSubcommentsToDisplay((oldValue) =>
      subComments?.length > numberOfSubcommentsToDisplay ? oldValue + 3 : 3,
    );
  };

  return (
    <div
      className={`comment-card-wrapper ${!topLevel && 'sub-comment-wrapper'}`}
    >
      {reportModalOpen && (
        <ReportModal
          {...{ reportModalOpen, setReportModalOpen, questionId, commentId }}
        />
      )}
      <section className="comment-main">
        <header className="comment-header">
          <div className="comment-user-details">
            <Avatar src={picture} />
            <div className="comment-user-sub-details">
              <div>{username}</div>
              <div>
                •
                {moment
                  .utc(time)
                  .utc()
                  .fromNow()}
              </div>
            </div>
            {parentCommentId && (
              <IconButton
                className="sub-comment-report-button"
                onClick={handleReport}
                size="large"
              >
                <Flag />
              </IconButton>
            )}
          </div>
          {!parentCommentId && (
            <div
              className={`comment-type ${commentType === 'General' &&
                'comment-type-blue'}`}
            >
              {commentType}
            </div>
          )}
        </header>
        <div className="comment-card-text">
          {status === 'Rejected' ? (
            <span
              style={{
                fontStyle: 'italic',
                color: 'grey',
              }}
            >
              <p>[This comment has been blocked]</p>
            </span>
          ) : (
            <Typography className="comment-text">{commentText}</Typography>
          )}
        </div>
      </section>
      {topLevel && (
        <>
          {voteButtons}
          <footer className="comment-buttons">
            <button>
              <ChatBubbleOutlineIcon />
              <span className="comment-buttons-label">
                {numberOfReplies} Replies
              </span>
            </button>
            <button onClick={handleReport}>
              <Flag />
              <span className="comment-buttons-label">Report</span>
            </button>
          </footer>
          {subComments
            ?.slice(0, numberOfSubcommentsToDisplay)
            ?.map((comment) => (
              <CommentCard {...comment} key={comment.commentId} />
            ))}
          {subComments && subComments?.length > 3 && (
            <div className="load-more-comments">
              <button onClick={loadMoreComments}>
                {subComments?.length > numberOfSubcommentsToDisplay
                  ? 'Load more comments...'
                  : 'Collapse Comments'}
              </button>
            </div>
          )}
          <NewCommentInput questionId={questionId} commentId={commentId} />
        </>
      )}
    </div>
  );
};

export default CommentCard;
