import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const storeAdmin = createReducer(
  {
    productsList: [],
    isProductDataSavedSuccess: null,
    isProductDataSavedFail: null,
    productImgUrl: [],
    ordersList: [],
    singleOrderData: [],
  },
  {
    [types.POST_PRODUCT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingProductData: true,
        isProductDataSavedSuccess: false,
        isProductDataSavedFail: false,
      });

      return newState;
    },
    [types.POST_PRODUCT_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingProductData: false,
        isProductDataSavedSuccess: true,
        isProductDataSavedFail: false,
      });

      return newState;
    },
    [types.POST_PRODUCT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSavingProductData: false,
        isProductDataSavedSuccess: false,
        isProductDataSavedFail: true,
      });
      return newState;
    },

    [types.GET_PRODUCT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingProductData: true,
      });

      return newState;
    },
    [types.GET_PRODUCT_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        productsList: action.data.items,
        isFetchingProductData: false,
        productImgUrl: [],
      });

      return newState;
    },
    [types.GET_PRODUCT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingProductData: false,
      });

      return newState;
    },

    [types.DELETE_PRODUCT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingProduct: true,
      });
      return newState;
    },

    [types.GET_PRODUCT_BY_ID_SUCCESS](state, action) {
      return {
        ...state,
        productsList: state.productsList.map((record) => {
          if (action.data && record.id === action.data.id) {
            return action.data;
          }

          return record;
        }),
        isFetchingProductData: false,
        productImgUrl: [],
      };
    },

    [types.GET_PRODUCT_BY_ID_FAIL](state) {
      return {
        ...state,
        isFetchingProductData: false,
      };
    },

    [types.DELETE_PRODUCT_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingProduct: false,
      });
      return newState;
    },

    [types.CHANGE_PRODUCT_STATUS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingStatus: true,
      });

      return newState;
    },

    [types.CHANGE_PRODUCT_STATUS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingStatus: false,
      });

      return newState;
    },
    [types.CHANGE_PRODUCT_STATUS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingStatus: false,
      });
      return newState;
    },
    [types.POST_PRODUCT_IMAGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImages: true,
      });
      return newState;
    },
    [types.POST_PRODUCT_IMAGE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImages: false,
        productImgUrl: action.data.s3_image_url,
      });
      return newState;
    },
    [types.POST_PRODUCT_IMAGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImages: false,
      });
      return newState;
    },

    [types.DELETE_PRODUCT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingProduct: false,
      });

      return newState;
    },
    [types.GET_ORDERS_LIST_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingData: true,
      });

      return newState;
    },
    [types.GET_ORDERS_LIST_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        ordersList: action.data,
        isFetchingData: false,
      });

      return newState;
    },
    [types.GET_ORDERS_LIST_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingData: false,
      });

      return newState;
    },

    [types.GET_SINGLE_ORDER_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingSingleData: true,
      });

      return newState;
    },
    [types.GET_SINGLE_ORDER_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        singleOrderData: action.data,
        isFetchingSingleData: false,
      });

      return newState;
    },
    [types.GET_SINGLE_ORDER_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingSingleData: false,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_ORDERS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdating: true,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_ORDERS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdating: false,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_ORDERS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdating: false,
      });

      return newState;
    },
  },
);
export { storeAdmin as default };
