import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

const DiscussionSnackBars = () => {
  const addNewComment = useSelector(
    (state) => state.discussion.addingNewCommentToQuestion,
  );

  const addNewCommentError = useSelector((state) => state.discussion.error);
  const voteError = useSelector((state) => state.discussion.voteError);

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(() => false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (addNewCommentError !== '') {
      setOpenErrorSnackbar(true);
      setMessage('Could not add your comment');
    } else {
      setOpenErrorSnackbar(false);
      setMessage('');
    }
  }, [addNewCommentError]);

  useEffect(() => {
    if (voteError !== '') {
      setOpenErrorSnackbar(true);
      setMessage('Could not Vote');
    }
  }, [voteError]);

  const handleSnackbarClose = () => {
    setMessage('');
    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar open={addNewComment} autoHideDuration={2000}>
        <Alert severity={'info'}> Adding New Comment... </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorSnackbar}
        message={message}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={'error'} onClose={handleSnackbarClose}>
          {' '}
          {message}{' '}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DiscussionSnackBars;
