import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import Slide1 from './img/slide1.svg';
import ResponsiveSlide2 from './img/responsiveSlide2.svg';
import Slide2 from './img/newSlide2.svg';
import Slide3 from './img/slide3.svg';
import LeftArrow from './img/left.svg';
import RightArrow from './img/right.svg';
import './ImageCarousel.css';

const ImageCarousel = ({ setPage }) => {
  const [slideNo, setSlideNo] = useState(1);
  const nextSlide = () => {
    if (slideNo < 3) {
      setSlideNo(slideNo + 1);
    } else {
      setSlideNo(3);
    }
  };

  const previousSlide = () => {
    if (slideNo > 1) {
      setSlideNo(slideNo - 1);
    } else {
      setSlideNo(1);
    }
  };
  return (
    <>
      <div className="img_carousel-onboarding">
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className={
              slideNo === 1
                ? 'imgContainer'
                : slideNo === 2
                ? 'imgContainer2'
                : 'imgContainer3'
            }
            style={{ textAlign: 'center' }}
          >
            {slideNo === 1 ? (
              <img className={'img_carousel_img1'} src={Slide1} />
            ) : slideNo === 2 ? (
              <img className={'img_carousel_img2'} src={Slide2} />
            ) : (
              <img className={'img_carousel_img3'} src={Slide3} />
            )}
            {slideNo === 2 && (
              <img
                className="img_carousel_img2_responsive"
                src={ResponsiveSlide2}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {slideNo === 1 ? (
              <span className="img-carousel__heading1">
                Free and Open Source study helper to improve skills
              </span>
            ) : slideNo === 2 ? (
              <span className="img-carousel__heading2">
                Participate in competitions and be rewarded.
              </span>
            ) : (
              <span className="img-carousel__heading3">
                Attempt Mock-Tests of various competitive exams.
              </span>
            )}
            <br />
            {slideNo === 1 ? (
              <span className="img-carousel__description1">
                The most natural way for people to become good at something is
                by doing it repeatedly. Abekus helps you in improving your
                skills by attempting more and more questions while discussing it
                with your peers.
              </span>
            ) : slideNo === 2 ? (
              <span className="img-carousel__description2">
                Great prizes await you in Abekus, a platform designed to make
                your rewards more accessible and fun. Turn your knowledge into
                Abekus Credits and Gems, and exchange them for gift cards,
                vouchers and gadgets.
              </span>
            ) : (
              <span className="img-carousel__description3">
                Competitive exams are like a rollercoaster. It&apos;s thrilling,
                exhilarating, and nerve-wracking. And while we can&apos;t make
                the test any easier, we can help you prepare with our
                comprehensive study system.
              </span>
            )}

            <div className="slidePositionHolder">
              <div
                className={slideNo === 1 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(1)}
              />
              <div
                className={slideNo === 2 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(2)}
              />
              <div
                className={slideNo === 3 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(3)}
              />
            </div>
            <div
              className="nextBtnContainer"
              onClick={() => (slideNo === 3 ? setPage(1) : nextSlide())}
            >
              <Button
                variant="contained"
                style={{
                  background: '#3572B0',
                  width: '150px',
                  height: '40px',
                  color: 'white',
                }}
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
        <div className="img-carousel__arrows">
          <img
            src={LeftArrow}
            alt=""
            className="img-carousel__arrowLeft"
            onClick={() => previousSlide()}
            style={{ opacity: slideNo === 1 && 0.1 }}
          />
          <img
            src={RightArrow}
            alt=""
            className="img-carousel__arrowRight"
            onClick={() => nextSlide()}
            style={{ opacity: slideNo === 3 && 0.1 }}
          />
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
