import React from 'react';

const EmptyImageURL =
  'https://s3.us-west-2.amazonaws.com/wikasta.projectq/app_img/voting_page_empty_state.png';

function EmptyReviewReport() {
  return (
    <div style={{ flex: 1 }}>
      <img
        src={EmptyImageURL}
        alt="No Questions to Review"
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default EmptyReviewReport;
