import {
  Box,
  Typography,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSalesExecutives } from '../../actions/salesDashboard';
import SearchBox from '../SearchBox';
import { useNavigate } from 'react-router-dom';

const AdminSales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { salesExecutiveList } = useSelector((state) => state.SalesDashboard);
  const [searchTerm, setSearchterm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAdminSalesExecutives(searchTerm, page + 1, rowsPerPage));
  }, [page, rowsPerPage, searchTerm]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleExecutiveSelection = (id) => {
    navigate(`/l/sales/dashboard?salesUserId=${id}`);
  };

  return (
    <Box>
      <Box padding={'20px'} width={'50%'}>
        <Box display="flex" justifyContent="flex-end">
          <SearchBox
            value={searchTerm}
            handleSearch={(e) => setSearchterm(e.target.value)}
          />
        </Box>
      </Box>
      <Box>
        {salesExecutiveList?.sales_executive_list?.length === 0 ? (
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            There are no sales executives.
          </Typography>
        ) : (
          <Box sx={{ overflow: 'auto', height: '60vh', padding: '20px' }}>
            <Table style={{ backgroundColor: 'white' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Exec Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Signup Count</TableCell>
                  <TableCell> Job Count</TableCell>
                  <TableCell>premium_job_count</TableCell>
                </TableRow>
              </TableHead>
              <>
                {salesExecutiveList?.sales_executive_list?.map((exec) => (
                  <TableBody key={exec.id}>
                    <TableRow onClick={() => handleExecutiveSelection(exec.id)}>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <div className="sales__list__name">
                          {exec?.first_name} {exec?.last_name}
                        </div>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <div className="sales__list__name">{exec?.email}</div>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <div className="sales__list__name">
                          {exec?.signup_count || 0}
                        </div>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <div className="sales__list__name">
                          {exec?.job_count || 0}
                        </div>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <div className="sales__list__name">
                          {exec?.premium_job_count || 0}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>
            </Table>
          </Box>
        )}
      </Box>
      <TablePagination
        component="nav"
        page={page}
        rowsPerPage={rowsPerPage}
        count={salesExecutiveList.total_pages * salesExecutiveList.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
        className="scoreboard__navigation"
      />
    </Box>
  );
};

export default AdminSales;
