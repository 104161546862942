import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import Invitation from './images/invitation.svg';
import LocationIcon from './images/Location.svg';
import MoneyIcon from './images/Money.svg';
import JobTypeIcon from './images/JobTypeIcon.svg';

const JobOpportunities = ({ styles }) => {
  return (
    <div
      className={styles.containerStyle}
      style={{ maxHeight: '450px', overflow: 'auto' }}
    >
      <span className={styles.contentLeft_Heading1}>Job opportunities</span>
      <Box
        mt={2}
        sx={{
          borderRadius: '5px',
          boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
          padding: '10px',
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} alignItems={'center'}>
            <img src={Invitation} alt={''} />
            <div>
              <span className={styles.companyName}>Google India</span>
              <br />
              <span className={styles.hotTopicsHeading}>UX/UI Designer</span>
            </div>
          </Box>
          <Box>
            <Button variant="contained" className={styles.invitationBoxBtn}>
              Apply
            </Button>
          </Box>
        </Box>
        <Grid container mt={2}>
          <Grid
            item
            xs={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <img src={LocationIcon} alt="" />
            <span style={{ fontSize: '10px' }}>Greater Noida</span>
          </Grid>
          <Grid
            item
            xs={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <img src={MoneyIcon} alt="" />
            <span style={{ fontSize: '10px' }}>₹15K-₹25K</span>
          </Grid>
          <Grid
            item
            xs={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <img src={JobTypeIcon} alt="" />
            <span style={{ fontSize: '10px' }}>Internship</span>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default JobOpportunities;
