import * as types from './types';

import apiClient from '../api/apiclient';

function getCreditsStart() {
  return {
    type: types.GET_CREDITS_START,
  };
}

function getCreditsSuccess({ data }) {
  return {
    type: types.GET_CREDITS_SUCCESS,
    data,
  };
}

function getCreditsFail() {
  return {
    type: types.GET_CREDITS_FAIL,
  };
}

export function getCredits({ page, page_size, type }) {
  return (dispatch) => {
    dispatch(getCreditsStart());
    apiClient
      .getCreditsApi()
      .creditsApiGetCreditConsumptionHistory(page, page_size, type)
      .then((resp) => {
        dispatch(getCreditsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCreditsFail());
      });
  };
}
function getCreditsCostStart() {
  return {
    type: types.GET_CREDITS_COST_START,
  };
}

function getCreditsCostSuccess({ data }) {
  return {
    type: types.GET_CREDITS_COST_SUCCESS,
    data,
  };
}

function getCreditsCostFail() {
  return {
    type: types.GET_CREDITS_COST_FAIL,
  };
}

export function getCreditsCost() {
  return (dispatch) => {
    dispatch(getCreditsCostStart());
    apiClient
      .getCreditsApi()
      .creditsApiGetCreditCost()
      .then((resp) => {
        dispatch(getCreditsCostSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCreditsCostFail());
      });
  };
}
