import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Box, Button, Grid } from '@mui/material';
import {
  getAdminSkillsDetails,
  getCollegeDetails,
  getCompanyDetails,
  getExamDetails,
  getJobRoleDetails,
  updateAdminSkillsDetails,
  updateCollegeDetails,
  updateCompanyDetails,
  updateExamDetails,
  updateJobRoleDetails,
} from '../../actions/dataManagement';
import SearchBox from '../SearchBox';
import AddCollegeDialog from './AddCollegeDialog';
import AddCompanyDialog from './AddCompanyDialog';
import AddExamDialog from './AddExamDialog';

import AddJobRoleDialog from './AddJobRoleDialog';
import { StyleDataManagement } from './StyledComponent';
import AdminSkillsList from './components/AdminSkillsList';
import AddAdminSkillsDialog from './AddAdminSkillsDialog';
import AdminCollegeList from './components/AdminCollegeList';
import AdminCompanyList from './components/AdminCompanyList';
import AdminExamsList from './components/AdminExamsList';
import AdminJobRoleList from './components/AdminJobRoleList';

const SelectStyles = {
  singleValue: (baseStyle) => ({
    ...baseStyle,
    height: '15px',
  }),
};

const DataManagement = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState({ id: 'Pending', name: 'Pending' });
  const [open, setOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [examOpen, setExamOpen] = useState(false);
  const [jobRoleOpen, setJobRoleOpen] = useState('');
  const [adminSkillOpen, setAdminSkillOpen] = useState('');
  const [collegeId, setCollegeId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [examId, setExamId] = useState('');
  const [jobRoleId, setJobRoleId] = useState('');
  const [adminSkillId, setAdminSkillId] = useState('');

  const [entityType, setEntityType] = useState({
    id: 'college',
    name: 'college',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(false);

  const [collegeName, setCollegeName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [examName, setExamName] = useState('');
  const [jobRoleName, setJobRoleName] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');

  const { collegeData, companyData, exams, jobRole, adminSkill } = useSelector(
    (state) => state.dataManagement,
  );

  const selectedCollege = collegeData.colleges?.find(
    (record) => record.college_id === collegeId,
  );

  const selectedCompany = companyData.company_data?.find(
    (record) => record.company_id === companyId,
  );

  const selectedExam = exams.all_external_exams?.find(
    (record) => record.id === examId,
  );
  const selectedJobRole = jobRole.all_job_roles?.find(
    (record) => record.job_role_id === jobRoleId,
  );
  const selectedAdminSkill = adminSkill.skills?.find(
    (record) => record.id === adminSkillId,
  );

  useEffect(() => {
    setIsEditing(false);
    setIsEditingStatus(false);
    if (entityType.id === 'company') {
      dispatch(
        getCompanyDetails(
          searchTerm,
          page + 1,
          rowsPerPage,
          status?.id || '',
          sortBy,
          sortOrder,
        ),
      );
    } else if (entityType.id === 'exams') {
      dispatch(
        getExamDetails(
          searchTerm,
          page + 1,
          rowsPerPage,
          status?.id || '',
          sortBy,
          sortOrder,
        ),
      );
    } else if (entityType.id === 'job') {
      dispatch(
        getJobRoleDetails(
          searchTerm,
          page + 1,
          rowsPerPage,
          status?.id || '',
          sortBy,
          sortOrder,
        ),
      );
    } else if (entityType.id === 'skills') {
      dispatch(
        getAdminSkillsDetails(
          searchTerm,
          page + 1,
          rowsPerPage,
          status?.id || '',
          sortBy,
          sortOrder,
        ),
      );
    } else {
      dispatch(
        getCollegeDetails(
          searchTerm,
          page + 1,
          rowsPerPage,
          status?.id || '',
          sortBy,
          sortOrder,
        ),
      );
    }
  }, [
    dispatch,
    searchTerm,
    page,
    rowsPerPage,
    status,
    entityType,
    sortBy,
    sortOrder,
  ]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleExamModalOpen = (id) => {
    setExamId(id);
    setExamOpen(true);
  };
  const handleCompanyModalOpen = (id) => {
    setCompanyId(id);
    setCompanyOpen(true);
  };
  const handleCollegeModalOpen = (id) => {
    setCollegeId(id);
    setOpen(true);
  };
  const handleJobModalOpen = (id) => {
    setJobRoleId(id);
    setJobRoleOpen(true);
  };
  const handleAdminSkillModalOpen = (id) => {
    setAdminSkillId(id);
    setAdminSkillOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCompanyOpen(false);
    setExamOpen(false);
    setJobRoleOpen(false);
    setAdminSkillOpen(false);
  };
  const onClickFunction = () => {
    setIsEditing(false);
    setIsEditingStatus(false);
    if (entityType.id === 'company') {
      handleCompanyModalOpen('');
    } else if (entityType.id === 'exams') {
      handleExamModalOpen('');
    } else if (entityType.id === 'job') {
      handleJobModalOpen('');
    } else if (entityType.id === 'skills') {
      handleAdminSkillModalOpen('');
    } else {
      handleCollegeModalOpen('');
    }
  };

  const editableTableCollege = (data) => {
    setIsEditingStatus(false);
    setIsEditing(false);
    data.college_name = collegeName;
    data.college_type = selectedCollege.college_type;
    data.district_name = selectedCollege.district_name;
    data.state_name = selectedCollege.state_name;
    data.university_name = selectedCollege.university_name;
    data.image_url = selectedCollege.image_url;
    dispatch(
      updateCollegeDetails(
        data,
        selectedCollege.college_id,
        status?.id || '',
        searchTerm,
        page + 1,
        rowsPerPage,
      ),
    );
  };

  const editableTableCompany = (data) => {
    setIsEditing(false);
    setIsEditingStatus(false);
    data.company_name = companyName;
    data.company_type = selectedCompany.company_type;
    data.corporate_identification_number =
      selectedCompany.corporate_identification_number;
    dispatch(
      updateCompanyDetails(
        data,
        selectedCompany.company_id,
        searchTerm,
        page + 1,
        rowsPerPage,
        status?.id || '',
      ),
    );
  };
  const editableTableExam = (data) => {
    setIsEditing(false);
    setIsEditingStatus(false);
    data.name = examName;
    data.next_date = selectedExam.next_date;
    dispatch(
      updateExamDetails(
        data,
        selectedExam.id,
        searchTerm,
        page + 1,
        rowsPerPage,
        status?.id || '',
      ),
    );
  };

  const editableTableAdminSkill = (data) => {
    const dataBody = {
      name: data.name,
      status: data.status,
    };
    dispatch(
      updateAdminSkillsDetails(
        dataBody,
        selectedAdminSkill?.id,
        searchTerm,
        page + 1,
        rowsPerPage,
        status?.id || '',
      ),
    );
  };

  const editableTableJobRole = (data) => {
    setIsEditing(false);
    setIsEditingStatus(false);
    data.name = jobRoleName;
    data.id = selectedJobRole.job_role_id;
    dispatch(
      updateJobRoleDetails(
        data,
        selectedJobRole.job_role_id,
        searchTerm,
        page + 1,
        rowsPerPage,
        status?.id || '',
      ),
    );
  };

  return (
    <StyleDataManagement className="collegeListContainer">
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <div className="scoreboard__filters__item">
            <Select
              options={[
                { id: '', name: 'All' },
                { id: 'Pending', name: 'Pending' },
                { id: 'Accepted', name: 'Accepted' },
              ]}
              onChange={(option) => setStatus(option)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={status ? status?.id || '' : 'Category'}
              value={status}
              isClearable
              styles={SelectStyles}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className="scoreboard__filters__item">
            <Select
              options={[
                { id: 'college', name: 'college' },
                { id: 'company', name: 'company' },
                { id: 'exams', name: 'exams' },
                { id: 'job', name: 'Job Role' },
                { id: 'skills', name: 'Admin Skills' },
              ]}
              onChange={(option) => setEntityType(option)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={'Entity Type'}
              value={entityType}
              styles={SelectStyles}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={onClickFunction}
            >
              Add Data
            </Button>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <SearchBox
              value={searchTerm}
              handleSearch={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      {(entityType.id === 'college' || entityType.id === '') && (
        <AdminCollegeList
          collegeData={collegeData}
          selectedCollege={selectedCollege}
          handleCollegeModalOpen={handleCollegeModalOpen}
          isEditingStatus={isEditingStatus}
          setIsEditingStatus={setIsEditingStatus}
          setCollegeId={setCollegeId}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          editableTableCollege={editableTableCollege}
          collegeName={collegeName}
          isEditing={isEditing}
          setCollegeName={setCollegeName}
          setIsEditing={setIsEditing}
          searchTerm={searchTerm}
          status={status?.id}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
        />
      )}
      {entityType.id === 'company' && (
        <AdminCompanyList
          companyData={companyData}
          selectedCompany={selectedCompany}
          handleCompanyModalOpen={handleCompanyModalOpen}
          isEditingStatus={isEditingStatus}
          setIsEditingStatus={setIsEditingStatus}
          setCompanyId={setCompanyId}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          editableTableCompany={editableTableCompany}
          companyName={companyName}
          isEditing={isEditing}
          setCompanyName={setCompanyName}
          setIsEditing={setIsEditing}
          searchTerm={searchTerm}
          status={status?.id}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
        />
      )}
      {entityType.id === 'exams' && (
        <AdminExamsList
          exams={exams}
          selectedExam={selectedExam}
          handleExamModalOpen={handleExamModalOpen}
          isEditingStatus={isEditingStatus}
          setIsEditingStatus={setIsEditingStatus}
          setExamId={setExamId}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          editableTableExam={editableTableExam}
          examName={examName}
          isEditing={isEditing}
          setExamName={setExamName}
          setIsEditing={setIsEditing}
          searchTerm={searchTerm}
          status={status?.id}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
        />
      )}
      {entityType.id === 'job' && (
        <AdminJobRoleList
          jobRole={jobRole}
          selectedJobRole={selectedJobRole}
          handleJobModalOpen={handleJobModalOpen}
          isEditingStatus={isEditingStatus}
          setIsEditingStatus={setIsEditingStatus}
          setJobRoleId={setJobRoleId}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          editableTableJobRole={editableTableJobRole}
          jobRoleName={jobRoleName}
          isEditing={isEditing}
          setJobRoleName={setJobRoleName}
          setIsEditing={setIsEditing}
          searchTerm={searchTerm}
          status={status?.id}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
        />
      )}
      {entityType.id === 'skills' && (
        <AdminSkillsList
          adminSkill={adminSkill}
          selectedAdminSkill={selectedAdminSkill}
          handleAdminSkillModalOpen={handleAdminSkillModalOpen}
          isEditingStatus={isEditingStatus}
          setIsEditingStatus={setIsEditingStatus}
          setAdminSkillId={setAdminSkillId}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          editableTableAdminSkill={editableTableAdminSkill}
          searchTerm={searchTerm}
          status={status?.id}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
        />
      )}

      {open && (
        <AddCollegeDialog
          open={open}
          handleClose={handleClose}
          collegeId={collegeId}
          college={selectedCollege}
          searchTerm={searchTerm}
          status={status?.id || ''}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onlyMerge={false}
        />
      )}
      {companyOpen && (
        <AddCompanyDialog
          open={companyOpen}
          handleClose={handleClose}
          companyId={companyId}
          company={selectedCompany}
          searchTerm={searchTerm}
          status={status?.id || ''}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onlyMerge={false}
        />
      )}
      {examOpen && (
        <AddExamDialog
          open={examOpen}
          handleClose={handleClose}
          examId={examId}
          exam={selectedExam}
          searchTerm={searchTerm}
          status={status?.id || ''}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onlyMerge={false}
        />
      )}
      {jobRoleOpen && (
        <AddJobRoleDialog
          open={jobRoleOpen}
          handleClose={handleClose}
          jobRoleId={jobRoleId}
          jobRole={selectedJobRole}
          searchTerm={searchTerm}
          status={status?.id || ''}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onlyMerge={false}
        />
      )}
      {adminSkillOpen && (
        <AddAdminSkillsDialog
          open={adminSkillOpen}
          handleClose={handleClose}
          adminSkillId={adminSkillId}
          adminSkill={selectedAdminSkill}
          searchTerm={searchTerm}
          status={status?.id || ''}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          onlyMerge={false}
        />
      )}
    </StyleDataManagement>
  );
};

export default DataManagement;
