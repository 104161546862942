import { Avatar, Button, Grid, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import helpers from '../../lib/helpers';
import qType1 from './images/Qtype1.svg';
import qType2 from './images/Qtype2.png';
import qType3 from './images/Qtype3.png';
import qmsq from './images/MSQ.png';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import { openDomainModal } from '../../actions/domain';
import CreditsIcon from '../../images/credits-icon.svg';

import './index.scss';

// illustration for the the domain

import aptitude from './images/illustrations/Aptitude and GK.svg';
import Civil from './images/illustrations/Civil Engineering.svg';
import Computer from './images/illustrations/Computer science.svg';
import Electrical from './images/illustrations/Electrical Engineering.svg';
import Electronics from './images/illustrations/Electronics and Communication.svg';
import Engineering from './images/illustrations/Engineering Mathematics.svg';
import MEchanical from './images/illustrations/Mechanical Engineering.svg';

// icons for domain

import Civil1 from './images/icons/Logo Civil Engineering.svg';
import Computer1 from './images/icons/Logo Computer Science 1.svg';
import Electrical1 from './images/icons/Logo Electrical Engineering.svg';
import Electronics1 from './images/icons/Logo Electronics and Communication.svg';
import aptitude1 from './images/icons/Logo General Knowledge.svg';
import Engineering1 from './images/icons/Logo Mathematics 1.svg';
import MEchanical1 from './images/icons/Logo Mechanical Engineering.svg';

const CategorySelector = ({
  tag,
  domainList = [],
  setCreateQuestionType,
  userSelectedDomain,
  selectedCategory,
  handleChangeSelectedCategory,
}) => {
  const { getCategoryList } = helpers;
  const [errorCategory, setErrorCategory] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const dispatch = useDispatch();

  const domainIllustration = {
    'b6af01fe-208e-4394-9437-b368eee2daed': {
      illustration: aptitude,
      icons: aptitude1,
    },
    '32ee9845-76a6-42f0-a07e-3c4934e32199': {
      illustration: Computer,
      icons: Computer1,
    },
    '49121f13-6d2a-4bc1-9ba0-d31b6173b572': {
      illustration: Electronics,
      icons: Electronics1,
    },
    'ba285d1b-9772-4fc7-b343-157b2afcb896': {
      illustration: MEchanical,
      icons: MEchanical1,
    },
    'd7c072fb-75ef-4545-9656-b9e7f089cf08': {
      illustration: Civil,
      icons: Civil1,
    },
    '9002b4b9-992c-442f-be7d-74633b0e6db5': {
      illustration: Electrical,
      icons: Electrical1,
    },
    '066c4432-030a-4860-a1cf-5ad681f35528': {
      illustration: Engineering,
      icons: Engineering1,
    },
  };

  useEffect(() => {
    const category_list =
      userSelectedDomain && userSelectedDomain != 'all'
        ? tag.category_by_domain[userSelectedDomain]
        : [];

    const catList = getCategoryList(category_list, false);
    setFilterOptions(catList);

    const categoryObject = JSON.parse(
      localStorage.getItem('category_by_domain'),
    );
    if (categoryObject && categoryObject[userSelectedDomain]) {
      handleChangeSelectedCategory(categoryObject[userSelectedDomain]);
      setValue('tag_id', categoryObject[userSelectedDomain]);
    } else {
      setValue('tag_id', catList[0]);
      handleChangeSelectedCategory(catList[0]);
    }
  }, [userSelectedDomain]);

  let navigate = useNavigate();

  const { control, handleSubmit, watch, setValue } = useForm({});
  const categorySelected = watch('tag_id');

  const onSubmit = (data) => {
    let state = {};
    state.tag = data.tag_id;
    if (data.tag_id.id) {
      state.tag_id = data.tag_id.id;
    } else {
      state.tag_name = data.tag_id.value;
    }
    navigate(`/l/question/create`, {
      state,
    });
  };

  var questionTypes = [
    {
      name: 'Multiple Choice Questions (MCQ)',
      img: qType1,
      value: 'MultipleChoice',
    },
    {
      name: 'Multiple Select Questions (MSQ)',
      img: qmsq,
      value: 'MultipleCorrect',
    },
    {
      name: 'One Word Question',
      img: qType2,
      value: 'OneWord',
    },
    {
      name: 'Comprehensive Questions',
      img: qType3,
      value: 'MultiPart',
    },
  ];

  const [questionType, setQuestionType] = useState('MultipleChoice');

  useEffect(() => {
    setCreateQuestionType({ data: questionType });
  }, [questionType]);

  const setCategoryInLocalStorage = (category) => {
    let categoryObject =
      JSON.parse(localStorage.getItem('category_by_domain')) || [];
    categoryObject = { ...categoryObject, [userSelectedDomain]: category };
    localStorage.setItem('category_by_domain', JSON.stringify(categoryObject));
  };

  return (
    <div className="CategorySelector">
      <img
        src={domainIllustration[userSelectedDomain]?.illustration}
        alt="Profile Image"
        className="image"
      />
      <div className="inpCtn">
        <Typography variant="h6" color="primary" mb="5px">
          Choose Domain
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          endIcon={<EditIcon color="primary" />}
          onClick={() => dispatch(openDomainModal())}
        >
          {
            <Grid wrap="nowrap" container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  alt={'domain-icon'}
                  src={domainIllustration[userSelectedDomain]?.icons}
                  style={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Grid>
              <Grid item>
                {
                  domainList.find((domain) => domain.id === userSelectedDomain)
                    ?.name
                }
              </Grid>
            </Grid>
          }
        </Button>
      </div>
      <div className="inpCtn">
        <Typography variant="h6" color="primary" mb="5px">
          Choose Category
        </Typography>
        <Controller
          control={control}
          name="tag_id"
          defaultValue={[]}
          rules={{
            required: 'Please select a category',
          }}
          render={({ field: { onChange } }) => (
            <CreatableSelect
              value={selectedCategory}
              className="select"
              options={filterOptions}
              blurInputOnSelect={true}
              onChange={(e) => {
                setCategoryInLocalStorage(e);
                handleChangeSelectedCategory(e);
                setErrorCategory(false);
                onChange(e);
              }}
              styles={{
                control: (baseStyles, { isFocused }) => {
                  return {
                    ...baseStyles,
                    cursor: 'pointer',

                    '& .css-1su2i3t-singleValue': {
                      width: isFocused ? '0px' : '100%',
                    },
                  };
                },
                singleValue: (baseStyles) => {
                  return {
                    ...baseStyles,
                    width: '100%',
                  };
                },
              }}
              getOptionLabel={(option) => (
                <Grid
                  display={'flex'}
                  justifyContent={'space-between'}
                  width="100%"
                >
                  <Grid wrap="nowrap" container alignItems="center" spacing={2}>
                    <Grid item>
                      <Avatar
                        alt={`${option.name}`}
                        src={option.s3_url}
                        style={{
                          height: 24,
                          width: 24,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {option.name ? option.name : `Create ${option.value}`}
                    </Grid>
                  </Grid>
                  <Box
                    mr="10px"
                    display={'flex'}
                    gap="5px"
                    alignItems={'center'}
                  >
                    <Avatar
                      alt={'credits-icon'}
                      src={CreditsIcon}
                      style={{
                        height: 22,
                        width: 22,
                      }}
                    />
                    <Typography color="primary">{`+${option.accepted_question_credits ||
                      10}`}</Typography>
                  </Box>
                </Grid>
              )}
              getOptionValue={(option) =>
                option.name ? option.name : option.value
              }
            />
          )}
        />
        {errorCategory && (
          <span style={{ color: 'red' }}>Please select a category</span>
        )}
      </div>
      <div className="inpCtn">
        <Typography variant="h6" color="primary" mb="5px">
          Question Type
        </Typography>
        <ReactSelect
          className="select"
          value={questionTypes.find((c) => c?.value === questionType)}
          options={questionTypes}
          onChange={(e) => setQuestionType(e.value)}
          menuPlacement={'top'}
          getOptionLabel={(questionType) => (
            <Grid wrap="nowrap" container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  alt={`${questionType.name}`}
                  src={questionType.img}
                  style={{
                    height: 24,
                    width: 24,
                    borderRadius: 0,
                  }}
                />
              </Grid>
              <Grid item>{questionType.name}</Grid>
            </Grid>
          )}
          getOptionValue={(questionType) => questionType.value}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              cursor: 'pointer',
            }),
          }}
        />
      </div>
      <Button
        variant="contained"
        sx={{ color: 'white', mt: '10px' }}
        onClick={(e) => {
          localStorage.setItem('openCategoryModal', true);
          if (!categorySelected) setErrorCategory(true);
          handleSubmit(onSubmit)(e);
        }}
      >
        Create Question
      </Button>
    </div>
  );
};

export default CategorySelector;
