import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  InputAdornment,
  DialogActions,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ImagesDialog from './viewImagesDialog';
import {
  createProduct,
  getProductsList,
  postProductImage,
  updateProduct,
} from '../../actions/storeAdmin';
import Gems from '../Gems';

import { ProductImg } from './StoreAdminStyledComponent';

const AdminProductDetailsDialog = ({
  open,
  handleClose,
  productID,
  product,
}) => {
  const inputRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { productImgUrl, isUploadingImages } = useSelector(
    (state) => state.storeAdmin,
  );
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const watchIsReward = watch('is_reward', false);
  const onProductDetailsSubmit = (data) => {
    const tempArr = [];
    if (productID) {
      if (!productImgUrl.length) {
        data.image_url = product.image_url;
      } else {
        tempArr.push(productImgUrl);
        data.image_url = tempArr;
      }
      delete data.created_at;
      delete data.created_by;
      delete data.gross_price_in_currency_after_disc;
      delete data.gross_price_in_gems_after_disc;
      delete data.id;
      delete data.modified_on;
    } else {
      if (!productImgUrl.length) {
        data.image_url = tempArr;
      } else {
        tempArr.push(productImgUrl);
        data.image_url = tempArr;
      }
    }
    try {
      if (!productID) {
        dispatch(createProduct(data));
        handleClose();
      } else {
        dispatch(updateProduct(productID, data));
        handleClose();
      }
    } catch (error) {}
  };

  const onCancelHandler = () => {
    dispatch(getProductsList());
    handleClose();
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onChangeFunction = () => {
    dispatch(postProductImage(inputRef.current.files[0]));
  };

  useEffect(() => {
    if (product) {
      reset(product);
    }
  }, [product]);

  return (
    <Dialog className="dialog" onClose={handleClose} open={open}>
      {productID ? (
        <DialogTitle>Edit the Product</DialogTitle>
      ) : (
        <DialogTitle>Add New Product</DialogTitle>
      )}
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          {productImgUrl?.length ? (
            <>
              {!isUploadingImages ? (
                <Grid
                  item
                  container
                  spacing={2}
                  justifyContent="center"
                  sm={4}
                  xs={12}
                >
                  <Grid item>
                    <ProductImg src={productImgUrl} alt="" width="100%" />
                  </Grid>
                  <Grid item>
                    <form>
                      <Button variant="text" component="label" type="button">
                        Upload Images
                        <input
                          id="file-input"
                          type="file"
                          hidden
                          ref={inputRef}
                          onChange={onChangeFunction}
                        />
                      </Button>
                    </form>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  direction="column"
                  alignItems="center"
                  container
                  spacing={2}
                  justifyContent="center"
                >
                  <Typography variant="h6">Uploading</Typography>{' '}
                </Grid>
              )}
            </>
          ) : (
            <>
              {isUploadingImages ? (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  direction="column"
                  alignItems="center"
                  container
                  spacing={2}
                  justifyContent="center"
                >
                  <Typography variant="h6">Uploading</Typography>{' '}
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  direction="column"
                  alignItems="center"
                  container
                  spacing={2}
                  justifyContent="center"
                >
                  {productID ? (
                    <Grid item>
                      <ProductImg src={product.image_url} alt="" width="100%" />
                    </Grid>
                  ) : (
                    <Grid container item justifyContent="center">
                      <AddBoxIcon fontSize="large" />
                    </Grid>
                  )}

                  <Grid item>
                    <form>
                      <Button variant="text" component="label" type="button">
                        Upload Images
                        <input
                          id="file-input"
                          type="file"
                          hidden
                          ref={inputRef}
                          onChange={onChangeFunction}
                        />
                      </Button>
                    </form>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={8}>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Name</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('name', {
                    required: 'Please enter product name',
                  })}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Description</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('item_description', {
                    required: 'Please enter the description',
                  })}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  rows="3"
                  multiline
                  placeholder="Description"
                  error={!!errors.item_description}
                  helperText={errors.item_description?.message}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Long Desc</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('long_description')}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  rows="3"
                  multiline
                  placeholder="Description"
                  error={!!errors.long_description}
                  helperText={errors.long_description?.message}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Reward</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControlLabel
                  {...register('is_reward')}
                  control={<Checkbox color="primary" checked={watchIsReward} />}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Price</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2}>
                  {!watchIsReward && (
                    <Grid item sm={5}>
                      <TextField
                        {...register('gross_price_in_currency', {
                          valueAsNumber: true,
                        })}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        error={!!errors.gross_price_in_currency}
                        helperText={errors.gross_price_in_currency?.message}
                        type="number"
                      />
                    </Grid>
                  )}
                  {!watchIsReward ? (
                    <Grid item sm={7}>
                      <TextField
                        {...register('gross_price_in_gems', {
                          valueAsNumber: true,
                        })}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Gems />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.gross_price_in_gems}
                        helperText={errors.gross_price_in_gems?.message}
                        type="number"
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <TextField
                        {...register('gross_price_in_gems', {
                          valueAsNumber: true,
                        })}
                        margin="dense"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Gems />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.gross_price_in_gems}
                        helperText={errors.gross_price_in_gems?.message}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Discount %</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2}>
                  {!watchIsReward && (
                    <Grid item sm={5}>
                      <TextField
                        {...register('discount_pct_for_currency', {
                          valueAsNumber: true,
                          max: 100,
                        })}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        error={!!errors.discount_pct_for_currency}
                        helperText={errors.discount_pct_for_currency?.message}
                        type="number"
                      />
                    </Grid>
                  )}
                  {!watchIsReward ? (
                    <Grid item sm={7}>
                      <TextField
                        {...register('discount_pct_for_gems', {
                          valueAsNumber: true,
                          max: 100,
                        })}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Gems />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.discount_pct_for_gems}
                        helperText={errors.discount_pct_for_gems?.message}
                        type="number"
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <TextField
                        {...register('discount_pct_for_gems', {
                          valueAsNumber: true,
                          max: 100,
                        })}
                        margin="dense"
                        type={'number'}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Gems />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.discount_pct_for_gems}
                        helperText={errors.discount_pct_for_gems?.message}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>SKU</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('sku_count', {
                    required: 'Please enter the SKU',
                    valueAsNumber: true,
                  })}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  error={!!errors.sku_count}
                  helperText={errors.sku_count?.message}
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Sku Size</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('sku_size', {
                    valueAsNumber: true,
                  })}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  error={!!errors.sku_size}
                  helperText={errors.sku_size?.message}
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Item type</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Select
                  {...register('item_type', {
                    required: 'Please enter Item Type',
                  })}
                  variant="outlined"
                  margin="dense"
                  error={!!errors.item_type}
                  defaultValue={productID ? product?.item_type : 'other'}
                  fullWidth
                >
                  <MenuItem value="giftcards" key="giftcards">
                    Gift card
                  </MenuItem>
                  <MenuItem value="credits" key="credits">
                    Credits
                  </MenuItem>
                  <MenuItem value="mocktests" key="mocktests">
                    Mock Tests
                  </MenuItem>
                  <MenuItem
                    value="post_premium_job_single"
                    key="post_premium_job_single"
                  >
                    Premium job single
                  </MenuItem>
                  <MenuItem
                    value="post_premium_job_subscription"
                    key="post_premium_job_subscription"
                  >
                    Premium job subscription
                  </MenuItem>
                  <MenuItem
                    value="single_job_analysis_credits"
                    key="single_job_analysis_credits"
                  >
                    Single job analysis credits
                  </MenuItem>
                  <MenuItem value="other" key="other">
                    Other
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container alignItems="center" mt={1}>
              <Grid item sm={3} xs={12}>
                <label>Available to</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Select
                  {...register('available_to', {
                    required: 'Please enter available to',
                  })}
                  variant="outlined"
                  margin="dense"
                  error={!!errors.available_to}
                  defaultValue={productID ? product?.available_to : 'Learners'}
                  fullWidth
                >
                  <MenuItem value="Employers" key="Employers">
                    Employers
                  </MenuItem>
                  <MenuItem value="Learners" key="Learners">
                    Learners
                  </MenuItem>
                  <MenuItem value="Applicants" key="Applicants">
                    Applicants
                  </MenuItem>
                  <MenuItem value="All" key="All">
                    All
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container alignItems="center" mt={1}>
              <Grid item sm={3} xs={12}>
                <label>Duration Type</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Select
                  {...register('duration_type', {
                    required: 'Please enter duration type',
                  })}
                  variant="outlined"
                  margin="dense"
                  error={!!errors.duration_type}
                  defaultValue={productID ? product?.duration_type : 'Days'}
                  fullWidth
                >
                  <MenuItem value="Days" key="Days">
                    Days
                  </MenuItem>
                  <MenuItem value="Weeks" key="Weeks">
                    Weeks
                  </MenuItem>
                  <MenuItem value="Months" key="Months">
                    Months
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={12}>
                <label>Duration Count</label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  {...register('duration_count', {
                    valueAsNumber: true,
                  })}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  error={!!errors.duration_count}
                  helperText={errors.duration_count?.message}
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" type="button" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button
                variant="text"
                type="button"
                color="primary"
                disabled={isUploadingImages}
                onClick={handleSubmit(onProductDetailsSubmit)}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
      {!!openDialog && (
        <ImagesDialog
          openDialog={openDialog}
          closeDialog={handleDialogClose}
          productImages={productImgUrl}
        />
      )}
    </Dialog>
  );
};

export default AdminProductDetailsDialog;
