import { styled } from '@mui/styles';
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Select,
  TextField,
  Typography,
  Chip,
} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { HourglassDisabled } from '@mui/icons-material';

export const ReportMainLoader = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NoMoreReports = styled('div')({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5rem',
});

export const NoMoreReportsIcon = styled(HourglassDisabled)({
  color: '#3572B0',
  fontSize: '15rem',
});

export const ReportsWrapper = styled(Container)({
  padding: '1% 1.5%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '80vh',
});

export const ReportsFilterWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: '2%',
  paddingBottom: '2%',
});

export const FilterWrapper = styled(Box)({
  width: '50%',
  gap: '2%',
  paddingBottom: '2%',
});

export const TypeFillter = styled(Select)({
  width: '60%',
  borderRadius: '10px',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#3572B0',
  color: 'white',
  '@media screen and (max-width:900px)': {
    width: '50%',
  },
});

export const SearchField = styled(TextField)({
  background: '#3572B0',
  borderRadius: '10px',
  '& > div': {
    color: '#fffffF',
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
});

export const BottomWrapper = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Chips = styled(Chip)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
  },
}));

export const ReportFilter = styled(Select)({
  width: '25%',
  borderRadius: '10px',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#3572B0',
  color: 'white',
  '@media screen and (max-width:900px)': {
    width: '50%',
  },
});

export const FlaggedCommentCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '2% 2%',
  marginTop: '10px',
  gap: '8px',
  borderRadius: '10px',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
});

export const QuestionWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  paddingBottom: '10px',
  borderBottom: '1px solid black',
  '@media (max-width:800px)': {
    flexDirection: 'column',
  },
});

export const QuestionTitle = styled('div')({
  alignSelf: 'flex-start',
  '> *': {
    wordBreak: 'break-word',
  },
});

export const ReportUserDetails = styled('div')({
  textAlign: 'center',
  '> *': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  '@media (max-width:800px)': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
});

export const CommentText = styled('div')({
  color: '#3572B0',
  padding: '10px 0',
  flexGrow: 1,
  fontSize: '1rem',
});
export const CommentDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  '@media (max-width:1000px)': {
    display: 'none',
  },
});

export const CommentLabelWrapper = styled('div')({
  flex: '1 1 0',
});

export const CommentLabel = styled('div')({
  textAlign: 'center',
});

export const CommentLabelValue = styled('div')({
  marginTop: '10px',
  height: '15px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ReportsTitle = styled(Typography)({
  color: '#3572B0',
  fontSize: '1rem',
  alignSelf: 'flex-start',
});

export const ReportAccordion = styled(Accordion)({
  width: '100%',
  marginTop: '8px',
  '&:before': {
    display: 'none',
    height: '0px',
    border: 'none',
    backgroundColor: 'white',
  },
});

export const ReportSummary = styled(AccordionSummary)({
  borderRadius: '10px',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
  border: 'none',
});

export const ReportDetailsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  gap: '5px',
});

export const ReportDetails = styled('div')({
  fontStyle: 'italic',
  wordBreak: 'break-word',
});

export const ReportType = styled('div')({
  marginLeft: 'auto',
  padding: '8px 8px',
  color: '#3572B0',
  borderRadius: '10px',
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
});

export const ReportUserAvatar = styled(Avatar)({
  width: '1.5em',
  height: '1.5em',
});

export const ReportGridContainer = styled(Grid)({
  '@media (max-width:1000px)': {
    display: 'none',
  },
});

export const ReportGridItem = styled(Grid)({
  padding: '2% 2%',
  display: 'flex',
  alignItems: 'center',
});

export const GridLabel = styled(ReportGridItem)({
  fontWeight: 500,
  color: '#3572B0',
});

export const MoreReportsViewer = styled(Typography)({
  alignSelf: 'flex-start',
  color: '#3572B0',
});

export const ReportCardAction = styled(CardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '5px',
  flex: 'auto',
});

// Mobile components
export const CommentDetailsConcise = styled('div')({
  display: 'none',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
  '@media (max-width:1000px)': {
    display: 'flex',
  },
});
