import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  IconButton,
  FormHelperText,
  Chip,
  ButtonGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AddBox as AddBoxIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCompanyDetails,
  updateCompanyDetails,
  uploadImage,
  mergeDuplicates,
  getCompanyDetails,
} from '../../actions/dataManagement';
import AsyncSelect from 'react-select/async';
import { StyleDataManagement } from './StyledComponent';

const useStyles = makeStyles({
  companyImg: {
    borderRadius: '5px',
  },
  infoIcon: {
    fontSize: '10px',
  },
});

const AddCompanyDialog = ({
  open,
  handleClose,
  companyId,
  company,
  searchTerm,
  status,
  page,
  rowsPerPage,
  onlyMerge,
  selectedCompanyList,
  setSelectedCompanyList,
  setSelectedIds,
}) => {
  const styles = useStyles();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [companyImg, setCompanyImg] = useState('');
  const [tab, setTab] = useState(0);
  const [mainEntity, setMainEntity] = useState({});
  const [duplicateEntities, setDuplicateEntities] = useState([company]);
  const [apiError, setApiError] = useState('');
  const [editingEntity, setEditingEntity] = useState({});

  const onChangeFunction = () => {
    dispatch(uploadImage(inputRef.current.files[0]));
  };

  const {
    imageUrl,
    isUploadingImage,
    isMergeDuplicateStart,
    isMergeDuplicateFail,
    isMergeDuplicateSuccess,
  } = useSelector((state) => state.dataManagement);

  useEffect(() => {
    if (company) {
      reset({
        company_name: company.company_name,
        company_type: company.company_type,
        corporate_identification_number:
          company.corporate_identification_number,
      });
      setCompanyImg(company.image_url);
    }
  }, [company]);

  useEffect(() => {
    if (
      isMergeDuplicateStart !== isMergeDuplicateFail &&
      isMergeDuplicateFail
    ) {
      setApiError('Something went wrong. Please try again!');
    }

    if (isMergeDuplicateStart) {
      setApiError('');
    }
  }, [isMergeDuplicateStart, isMergeDuplicateFail, isMergeDuplicateSuccess]);

  const onDetailsSubmit = (data) => {
    if (tab === 0) {
      data.default_color = 'null';
      data.image_url = companyImg ? companyImg : imageUrl;

      if (!companyId) {
        dispatch(
          addCompanyDetails(data, searchTerm, status, page, rowsPerPage),
        );
      } else {
        dispatch(
          updateCompanyDetails(
            data,
            companyId,
            searchTerm,
            page,
            rowsPerPage,
            status,
          ),
        );
      }
      handleClose();
    } else {
      let duplicate_ids;
      if (mainEntity)
        duplicate_ids = duplicateEntities
          .filter((entity) => entity.company_id !== mainEntity.company_id)
          .map((entity) => entity.company_id);
      else
        duplicate_ids = duplicateEntities
          .slice(1)
          .map((entity) => entity.company_id);

      if (duplicate_ids.length) {
        dispatch(
          mergeDuplicates(
            {
              main_entity_id:
                mainEntity.company_id || duplicateEntities[0].company_id,
              entity_type: 'company',
              duplicate_ids,
            },
            () => {
              dispatch(
                getCompanyDetails(searchTerm, page, rowsPerPage, status),
              );
              handleClose();
            },
          ),
        );
        setSelectedCompanyList([]);
        setSelectedIds([]);
      } else {
        handleClose();
      }
    }
  };

  const handleChange = () => {
    const data = {
      company_name: editingEntity.company_name,
      approval_status: editingEntity.approval_status || 'Pending',
    };
    dispatch(
      updateCompanyDetails(
        data,
        editingEntity.company_id,
        searchTerm,
        page,
        rowsPerPage,
        status,
        () => {
          setDuplicateEntities((entities) =>
            entities.map((entity) => {
              if (entity.company_id === editingEntity.company_id) {
                return {
                  ...entity,
                  ...data,
                };
              }
              return entity;
            }),
          );
          setEditingEntity({});
        },
      ),
    );
  };

  useEffect(() => {
    if (duplicateEntities.length && !mainEntity.company_id) {
      setMainEntity(duplicateEntities[0]);
    }
  }, [duplicateEntities?.[0]]);

  useEffect(() => {
    if (onlyMerge) {
      setTab(1);
      setDuplicateEntities(selectedCompanyList);
    }
  }, [onlyMerge]);

  return (
    <Dialog className="dialog" onClose={handleClose} open={open} fullWidth>
      <StyleDataManagement>
        <Grid className="dialog-title-container">
          {onlyMerge ? (
            <Button
              className={['dialog-title', tab === 1 && 'active']}
              color="primary"
              onClick={() => setTab(1)}
            >
              Merge Company
            </Button>
          ) : (
            <>
              {companyId ? (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Edit Company
                </Button>
              ) : (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Add New Company
                </Button>
              )}
              <Button
                className={['dialog-title', tab === 1 && 'active']}
                color="primary"
                onClick={() => setTab(1)}
              >
                Merge Company
              </Button>
            </>
          )}
        </Grid>

        <DialogContent className="dialog-content">
          {onlyMerge ? (
            <>
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the company you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getCompanyDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.company_data),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search companies...'}
                    getOptionLabel={(opt) => opt.company_name}
                    getOptionValue={(opt) => opt.company_id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.company_id === opt.company_id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate companies
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no companies to merge. Please select a
                        company!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity?.company_id}
                        style={{
                          background:
                            entity?.company_id === mainEntity?.company_id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity?.company_id === entity?.company_id ? (
                            <TextField
                              value={editingEntity.company_name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  company_name: value,
                                }));
                              }}
                              name="company_name"
                              placeholder="Enter the company name"
                              onClick={(e) => e.stopPropagation()}
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.company_name}
                            </Typography>
                          )}
                          {editingEntity?.company_id === entity?.company_id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Company"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity?.company_id === entity?.company_id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity?.company_id !== entity?.company_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) => ent.company_id !== entity.company_id,
                                ),
                              );
                              if (entity.company_id === mainEntity.company_id) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {tab === 0 && (
                <Grid container spacing={2} justifyContent="center">
                  {!!imageUrl?.length || companyImg?.length ? (
                    <Grid item container justifyContent="center" sm={4} xs={12}>
                      <Grid item>
                        <img
                          src={companyImg ? companyImg : imageUrl}
                          alt=""
                          width="100%"
                          className={styles.companyImg}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {isUploadingImage ? (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          direction="column"
                          alignItems="center"
                          container
                          spacing={2}
                          justifyContent="center"
                        >
                          <Typography variant="h6">Uploading</Typography>{' '}
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          direction="column"
                          alignItems="center"
                          container
                          spacing={2}
                          justifyContent="center"
                        >
                          <Grid container item justifyContent="center">
                            <AddBoxIcon fontSize="large" />
                          </Grid>

                          <Grid item>
                            <form>
                              <Button
                                variant="text"
                                component="label"
                                type="button"
                              >
                                Upload Images
                                <input
                                  id="file-input"
                                  type="file"
                                  hidden
                                  ref={inputRef}
                                  onChange={onChangeFunction}
                                />
                              </Button>
                            </form>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12} sm={8}>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Status</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <FormControl fullWidth>
                          <Select
                            {...register('approval_status', {
                              required: 'Please select a status',
                            })}
                            style={{ marginTop: '8px', marginBottom: '4px' }}
                            variant="outlined"
                            margin="dense"
                            displayEmpty
                            placeholder="Company"
                            error={!!errors.approval_status}
                            defaultValue={
                              companyId
                                ? company?.approval_status === null ||
                                  company?.approval_status === 'Pending'
                                  ? 'Pending'
                                  : 'Accepted'
                                : 'Accepted'
                            }
                          >
                            <MenuItem value="Accepted" key="Accepted">
                              Accepted
                            </MenuItem>
                            <MenuItem value="Pending" key="Pending">
                              Pending
                            </MenuItem>
                            <MenuItem value="Rejected" key="Rejected">
                              Rejected
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Name</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <TextField
                          {...register('company_name', {
                            required: 'Please enter the company_name',
                          })}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder="Company Name"
                          error={!!errors.company_name}
                          helperText={errors.company_name?.message}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label>Corporate Identification Number</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <TextField
                          {...register('corporate_identification_number')}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder="Corporate Identification"
                          error={!!errors.corporate_identification_number}
                          helperText={
                            errors.corporate_identification_number?.message
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the company you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getCompanyDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.company_data),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search companies...'}
                    getOptionLabel={(opt) => opt.company_name}
                    getOptionValue={(opt) => opt.company_id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.company_id === opt.company_id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate companies
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no companies to merge. Please select a
                        company!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity?.company_id}
                        style={{
                          background:
                            entity?.company_id === mainEntity?.company_id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity?.company_id === entity?.company_id ? (
                            <TextField
                              value={editingEntity.company_name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  company_name: value,
                                }));
                              }}
                              name="company_name"
                              placeholder="Enter the company name"
                              onClick={(e) => e.stopPropagation()}
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.company_name}
                            </Typography>
                          )}
                          {editingEntity?.company_id === entity?.company_id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Company"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity?.company_id === entity?.company_id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity?.company_id !== entity?.company_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) => ent.company_id !== entity.company_id,
                                ),
                              );
                              if (entity.company_id === mainEntity.company_id) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={handleSubmit(onDetailsSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </StyleDataManagement>
    </Dialog>
  );
};

export default AddCompanyDialog;
