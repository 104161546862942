import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
  IconButton,
  FormHelperText,
  Chip,
  ButtonGroup,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addJobRoleDetails,
  updateJobRoleDetails,
  mergeDuplicates,
  getJobRoleDetails,
} from '../../actions/dataManagement';
import AsyncSelect from 'react-select/async';
import { StyleDataManagement } from './StyledComponent';

const AddJobRoleDialog = ({
  open,
  handleClose,
  jobRoleId,
  jobRole,
  searchTerm,
  status,
  page,
  rowsPerPage,
  onlyMerge,
  selectedJobRoleList,
  setSelectedJobRoleList,
  setSelectedIds,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [tab, setTab] = useState(0);
  const [mainEntity, setMainEntity] = useState({});
  const [duplicateEntities, setDuplicateEntities] = useState([jobRole]);
  const [apiError, setApiError] = useState('');
  const [editingEntity, setEditingEntity] = useState({});

  const {
    isMergeDuplicateStart,
    isMergeDuplicateFail,
    isMergeDuplicateSuccess,
  } = useSelector((state) => state.dataManagement);

  useEffect(() => {
    if (
      isMergeDuplicateStart !== isMergeDuplicateFail &&
      isMergeDuplicateFail
    ) {
      setApiError('Something went wrong. Please try again!');
    }

    if (isMergeDuplicateStart) {
      setApiError('');
    }
  }, [isMergeDuplicateStart, isMergeDuplicateFail, isMergeDuplicateSuccess]);

  useEffect(() => {
    if (jobRole) {
      reset({
        name: jobRole.name,
      });
    }
  }, [jobRole]);

  const onDetailsSubmit = (data) => {
    if (tab === 0) {
      if (!jobRoleId) {
        dispatch(
          addJobRoleDetails(data, searchTerm, page, rowsPerPage, status),
        );
      } else {
        data.id = jobRoleId;
        dispatch(
          updateJobRoleDetails(
            data,
            jobRoleId,
            searchTerm,
            page,
            rowsPerPage,
            status,
          ),
        );
      }
      handleClose();
    } else {
      let duplicate_ids;
      if (mainEntity)
        duplicate_ids = duplicateEntities
          .filter((entity) => entity.job_role_id !== mainEntity.job_role_id)
          .map((entity) => entity.job_role_id);
      else
        duplicate_ids = duplicateEntities
          .slice(1)
          .map((entity) => entity.job_role_id);

      if (duplicate_ids.length) {
        dispatch(
          mergeDuplicates(
            {
              main_entity_id:
                mainEntity.job_role_id || duplicateEntities[0].job_role_id,
              entity_type: 'job_role',
              duplicate_ids,
            },
            () => {
              dispatch(
                getJobRoleDetails(searchTerm, page, rowsPerPage, status),
              );
              handleClose();
            },
          ),
        );
        setSelectedJobRoleList([]);
        setSelectedIds([]);
      } else {
        handleClose();
      }
    }
  };

  const handleChange = () => {
    const data = {
      name: editingEntity.name,
      approval_status: editingEntity.approval_status || 'Pending',
    };
    dispatch(
      updateJobRoleDetails(
        data,
        editingEntity.job_role_id,
        searchTerm,
        page,
        rowsPerPage,
        status,
        () => {
          setDuplicateEntities((entities) =>
            entities.map((entity) => {
              if (entity.job_role_id === editingEntity.job_role_id) {
                return {
                  ...entity,
                  ...data,
                };
              }
              return entity;
            }),
          );
          setEditingEntity({});
        },
      ),
    );
  };

  useEffect(() => {
    if (duplicateEntities.length && !mainEntity?.job_role_id) {
      setMainEntity(duplicateEntities[0]);
    }
  }, [duplicateEntities?.[0]]);

  useEffect(() => {
    if (onlyMerge) {
      setTab(1);
      setDuplicateEntities(selectedJobRoleList);
    }
  }, [onlyMerge]);

  const defaultStatusVal = jobRoleId
    ? jobRole?.approval_status === null ||
      jobRole?.approval_status === 'Pending'
      ? 'Pending'
      : 'Accepted'
    : 'Accepted';

  return (
    <Dialog className="dialog" onClose={handleClose} open={open} fullWidth>
      <StyleDataManagement>
        <Grid className="dialog-title-container">
          {onlyMerge ? (
            <Button
              className={['dialog-title', tab === 1 && 'active']}
              color="primary"
              onClick={() => setTab(1)}
            >
              Merge Job Role
            </Button>
          ) : (
            <>
              {jobRoleId ? (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Edit Job Role
                </Button>
              ) : (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Add New Job Role
                </Button>
              )}
              <Button
                className={['dialog-title', tab === 1 && 'active']}
                color="primary"
                onClick={() => setTab(1)}
              >
                Merge Job Role
              </Button>
            </>
          )}
        </Grid>
        <DialogContent className="dialog-content">
          {onlyMerge ? (
            <>
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the job role you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getJobRoleDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.all_job_roles),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search job roles...'}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.job_role_id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.job_role_id === opt.job_role_id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate job roles
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no job roles to merge. Please select a job
                        role!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity?.job_role_id}
                        style={{
                          background:
                            entity?.job_role_id === mainEntity?.job_role_id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity.job_role_id === entity.job_role_id ? (
                            <TextField
                              value={editingEntity.name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  name: value,
                                }));
                              }}
                              name="name"
                              placeholder="Enter job role"
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.name}
                            </Typography>
                          )}
                          {editingEntity.job_role_id === entity.job_role_id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Job Role"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity.job_role_id === entity.job_role_id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity.college_id !== entity.college_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) =>
                                    ent.job_role_id !== entity.job_role_id,
                                ),
                              );
                              if (
                                entity.job_role_id === mainEntity.job_role_id
                              ) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {tab === 0 && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={8}>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Status</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <FormControl fullWidth>
                          <Select
                            {...register('approval_status', {
                              required: 'Please select a status',
                            })}
                            style={{ marginTop: '8px', marginBottom: '4px' }}
                            variant="outlined"
                            margin="dense"
                            displayEmpty
                            placeholder="Job Role"
                            error={!!errors.approval_status}
                            defaultValue={defaultStatusVal}
                          >
                            <MenuItem value="Accepted" key="Accepted">
                              Accepted
                            </MenuItem>
                            <MenuItem value="Pending" key="Pending">
                              Pending
                            </MenuItem>
                            <MenuItem value="Rejected" key="Rejected">
                              Rejected
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Name</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <TextField
                          {...register('name', {
                            required: 'Please enter the name',
                          })}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                          error={!!errors.name}
                          helperText={errors.name?.message}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the job role you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getJobRoleDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.all_job_roles),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search job roles...'}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.job_role_id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.job_role_id === opt.job_role_id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate job roles
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no job roles to merge. Please select a job
                        role!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity.job_role_id}
                        style={{
                          background:
                            entity.job_role_id === mainEntity.job_role_id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity.job_role_id === entity.job_role_id ? (
                            <TextField
                              value={editingEntity.name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  name: value,
                                }));
                              }}
                              name="name"
                              placeholder="Enter job role"
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.name}
                            </Typography>
                          )}
                          {editingEntity.job_role_id === entity.job_role_id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Job Role"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity.job_role_id === entity.job_role_id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity.college_id !== entity.college_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) =>
                                    ent.job_role_id !== entity.job_role_id,
                                ),
                              );
                              if (
                                entity.job_role_id === mainEntity.job_role_id
                              ) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={handleSubmit(onDetailsSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </StyleDataManagement>
    </Dialog>
  );
};

export default AddJobRoleDialog;
