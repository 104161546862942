import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Play from '.';

let isEndTestClicked = false;

const Mocktest = (props) => {
  const playAnswerRef = useRef();
  const { search } = useLocation();
  const [questionAttemptTimer, setQuestionAttemptTimer] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);
  const [answer, setAnswer] = useState({});
  const [message, setMessage] = useState({});
  const [answerStatus, setAnswerStatus] = useState({});
  const [modalVisibility, setModalVisibility] = useState({
    hintModal: false,
    reportModal: false,
    timedoutModal: false,
    streakSaveModal: false,
    confirmEndModal: false,
    getMoreTimeModal: false,
    answerStatusModal: false,
    unlockSolutionModal: false,
    subcategorySelectorModal: false,
    collectBadgeModal: false,
  });

  const { testId } = useParams();
  const navigate = useNavigate();
  const {
    question,
    mockTest,
    getMockTestQuestion,
    getExamAnalytics,
    postAnswerSilently,
    getMockTestById,
    endMockTest,
    updateExamAnalytics,
    resetQuestions,
  } = props;

  const getNextQuestion = (mockTestSkipped = false) => {
    // clearing state while fetching a new question
    setIsHintVisible(false);
    setIsSolutionVisible(false);
    setIsAnswerSubmitted(false);

    const questionId = new URLSearchParams(search).get('question');
    if (questionId) {
      // for mock test flexible mode
      // if question id is there the question will be fetched on useEffect on first render
      // so we don't need to fetch it here
      // this is needed if we use this function on intial render too (which is currently the case)
      // if (!question.examAnalytics.questions) return;
      if (!question.examAnalytics?.questions?.length) return;

      const questionIndex = question.examAnalytics.questions?.findIndex(
        (q) => q.id === questionId,
      );
      if (
        questionIndex !== -1 &&
        questionIndex < (question.examAnalytics?.questions?.length || 0) - 1
      ) {
        if (mockTestSkipped)
          postAnswerSilently(
            questionId,
            {
              ...playAnswerRef.current?.getAnswer(),
              time_taken: questionAttemptTimer,
            },
            () => {
              updateExamAnalytics({
                id: question.data.id,
                status: 'skipped',
              });
            },
          );

        const nextQuestion =
          question.examAnalytics.questions[questionIndex + 1];
        // the fetching will happen at useEffect as the URL is changed
        navigate(
          `/l/answer/${mockTest.data.id}/mockTest?question=${nextQuestion.id}`,
          { replace: true },
        );
      } else if (
        questionIndex ===
        (question.examAnalytics?.questions?.length || 0) - 1
      ) {
        const unattendedQuestion = question.examAnalytics.questions?.find(
          (q) => q.status === 'unattempted',
        );
        if (unattendedQuestion)
          // the fetching will happen at useEffect as the URL is changed
          navigate(
            `/l/answer/${mockTest.data.id}/mockTest?question=${unattendedQuestion.id}`,
            { replace: true },
          );
        else setModalVisibility((old) => ({ ...old, confirmEndModal: true }));
      }
    } else getMockTestQuestion(testId);
  };

  const postAnswerWithAPI = (endTest = false, questionId) => {
    const ans = playAnswerRef.current?.getAnswer();
    // question change by question map - redirecting to next question
    // submit test - call end test api
    postAnswerSilently(
      question.data?.id,
      { ...ans, time_taken: questionAttemptTimer },
      endTest
        ? () => {
            isEndTestClicked = true;
            endMockTest(mockTest.data.id);
          }
        : () => {
            navigate(
              `/l/answer/${mockTest.data.id}/mockTest?question=${questionId}`,
              { replace: true },
            );
          },
    );
    updateExamAnalytics({
      id: question.data?.id,
      status: ans?.question_skipped ? 'skipped' : 'attempted',
    });
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      if (testId) {
        await getExamAnalytics(testId);
        getMockTestById(testId);
      }

      getNextQuestion();
    })();

    return () => {
      setIsAnswerSubmitted(false);
      setIsHintVisible(false);
      setIsSolutionVisible(false);
      setAnswer({});
      setModalVisibility({
        hintModal: false,
        reportModal: false,
        streakSaveModal: false,
        confirmEndModal: false,
        getMoreTimeModal: false,
        answerStatusModal: false,
        unlockSolutionModal: false,
        subcategorySelectorModal: false,
      });
      setAnswerStatus({});
      setMessage({});
      resetQuestions();
    };
  }, []);

  useEffect(() => {
    if (
      mockTest.data.exam_mode === 'flexible' &&
      question.examAnalytics?.questions?.length
    ) {
      const questionId = new URLSearchParams(search).get('question');
      if (!questionId) {
        const nextQuestion = question.examAnalytics.questions[0];
        navigate(
          `/l/answer/${mockTest.data.id}/mockTest?question=${nextQuestion.id}`,
          { replace: true },
        );
      }
    }
  }, [
    mockTest.data.exam_mode,
    question.examAnalytics,
    question.examAnalytics.questions,
  ]);

  useEffect(() => {
    if (question.isAnswerSuccess) {
      let answerStatus;
      if (question.answerStatus.question_skipped) {
        answerStatus = 'skipped';
      } else if (question.answerStatus.timeout) {
        answerStatus = 'timeout';
      } else if (question.answerStatus.is_attempt_correct) {
        answerStatus = 'attempted';
      } else if (!question.answerStatus.is_attempt_correct) {
        answerStatus = 'attempted';
      }
      updateExamAnalytics({
        id: question.data.id,
        status: answerStatus,
      });
    }
  }, [
    testId,
    question.data.id,
    question.answerStatus,
    question.isAnswerSuccess,
  ]);

  useEffect(() => {
    if (question.questionFetchFail) {
      if (question.error.includes('time')) endMockTest(mockTest.data.id);
    }
  }, [mockTest.data.id, question.questionFetchFail]);

  useEffect(() => {
    if (mockTest.endMockTestSuccess) {
      if (isEndTestClicked) {
        isEndTestClicked = false;
        navigate(
          {
            pathname: '/l/mockTest',
            search: '?tab=myTest',
          },
          {
            state: {
              mockTest: mockTest.data.id,
            },
          },
        );
        return;
      }

      setModalVisibility((old) => ({
        ...old,
        timedoutModal: true,
      }));
    }
  }, [mockTest.endMockTestSuccess]);

  useEffect(() => {
    // once answer is submitted, next question is fetched
    if (
      question.isAnswerSuccess &&
      (mockTest.data.exam_mode === 'flexible' || question.isSkipped)
    )
      getNextQuestion();
  }, [mockTest.data.exam_mode, question.isAnswerSuccess, question.isSkipped]);

  useEffect(() => {
    if (
      question.data.id &&
      !question.isAnswerSuccess &&
      !question.isAnswerFail
    ) {
      const interval = setInterval(() => {
        setQuestionAttemptTimer((t) => t + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
        setQuestionAttemptTimer(0);
      };
    }
  }, [question.data.id, question.isAnswerSuccess, question.isAnswerFail]);

  const onBack = () => {
    navigate(`/l/mockTest?tab=myTest`);
  };

  return (
    <Play
      {...props}
      getNextQuestion={getNextQuestion}
      timePeriod="mockTest"
      categoryId={testId}
      onBack={onBack}
      modalVisibility={modalVisibility}
      setModalVisibility={setModalVisibility}
      isAnswerSubmitted={isAnswerSubmitted}
      setIsAnswerSubmitted={setIsAnswerSubmitted}
      isHintVisible={isHintVisible}
      setIsHintVisible={setIsHintVisible}
      isSolutionVisible={isSolutionVisible}
      setIsSolutionVisible={setIsSolutionVisible}
      answer={answer}
      setAnswer={setAnswer}
      message={message}
      setMessage={setMessage}
      answerStatus={answerStatus}
      setAnswerStatus={setAnswerStatus}
      questionAttemptTimer={questionAttemptTimer}
      setQuestionAttemptTimer={setQuestionAttemptTimer}
      isEndTestClicked={isEndTestClicked}
      postAnswerWithAPI={postAnswerWithAPI}
      playAnswerRef={playAnswerRef}
    />
  );
};

export default Mocktest;
