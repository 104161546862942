import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Hidden,
  Tooltip,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import {
  PlaceholderContainer,
  StyleCalenderDate,
  StyledCalenderDateContainer,
  StyledCustomCard,
  StyledDataContainer,
  StyledMonthsContainer,
} from './DashboardStyledComponent';
import {
  ActivityColorRect,
  ActivityColorRectContainer,
  ActivitySubtitleText,
  CalenderBadgeTooltip,
} from '../UserProfile/UserProfileStyledComponent';
import { allMonths, allMonths1 } from '../../constants/years';
import { getStyle } from '../UserProfile/Calender';
import { colors } from '../../constants/userProfile';

const getDaysInYear = (year) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);
  const days = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    days.push({
      date: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
      dayOfWeek: date.getDay(),
    });
  }
  return days;
};

const DailyTooltip = withStyles((theme) => ({
  tooltip: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.text,
    fontSize: 11,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
}))(Tooltip);

const CalenderYear = ({ overallYearStats }) => {
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

  const memoizedDaysInYear = useMemo(() => getDaysInYear(currentYear), [
    currentYear,
  ]);

  useEffect(() => {
    const processData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 400));
      setIsLoading(false);
    };
    processData();
  }, []);

  const getColorForDate = (date) => {
    const dateString = `${date.year}-${String(date.month + 1).padStart(
      2,
      '0',
    )}-${String(date.date).padStart(2, '0')}`;
    if (
      overallYearStats &&
      overallYearStats.data &&
      overallYearStats.data[dateString]
    ) {
      const count =
        overallYearStats.data[dateString]?.overall?.questions_attempted;
      return getStyle(count, theme);
    }
    return {
      backgroundColor: colors[0],
      color: theme.palette.primary.main,
    };
  };

  return (
    <StyledCustomCard style={{ width: '100%' }}>
      <StyledDataContainer>
        <StyledMonthsContainer>
          <Hidden smDown>
            {allMonths.map((mon, ind) => (
              <Typography key={ind}>{mon}</Typography>
            ))}
          </Hidden>
          <Hidden smUp>
            {allMonths1.map((mon, ind) => (
              <Typography key={ind}>{mon}</Typography>
            ))}
          </Hidden>
        </StyledMonthsContainer>
        <StyledCalenderDateContainer>
          {isLoading ? (
            <PlaceholderContainer>
              <CircularProgress />
            </PlaceholderContainer>
          ) : (
            memoizedDaysInYear.map((date) => (
              <DailyTooltip
                key={`${date.year}-${date.month}-${date.date}`}
                enterTouchDelay={50}
                placement="top"
                title={
                  <CalenderBadgeTooltip>
                    <Typography color="primary" variant="h6">{`${
                      date.year
                    }-${date.month + 1}-${date.date}`}</Typography>
                    {overallYearStats?.data && (
                      <>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Typography>Accuracy:</Typography>
                          <Typography color="primary">
                            <b>
                              {overallYearStats?.data[
                                `${date.year}-${String(date.month + 1).padStart(
                                  2,
                                  '0',
                                )}-${String(date.date).padStart(2, '0')}`
                              ]?.overall?.accuracy?.toFixed(2) || 0}
                              %
                            </b>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Typography>Correct Answer:</Typography>
                          <Typography color="primary">
                            <b>
                              {overallYearStats?.data[
                                `${date.year}-${String(date.month + 1).padStart(
                                  2,
                                  '0',
                                )}-${String(date.date).padStart(2, '0')}`
                              ]?.overall?.correct_answers || 0}
                            </b>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Typography>Questions attempted: </Typography>
                          <Typography color="primary">
                            <b>
                              {overallYearStats?.data[
                                `${date.year}-${String(date.month + 1).padStart(
                                  2,
                                  '0',
                                )}-${String(date.date).padStart(2, '0')}`
                              ]?.overall?.questions_attempted || 0}
                            </b>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          <Typography>Average total time: </Typography>
                          <Typography color="primary">
                            <b>
                              {overallYearStats?.data[
                                `${date.year}-${String(date.month + 1).padStart(
                                  2,
                                  '0',
                                )}-${String(date.date).padStart(2, '0')}`
                              ]?.overall?.average_time_total || 0}
                              s
                            </b>
                          </Typography>
                        </Box>
                      </>
                    )}
                  </CalenderBadgeTooltip>
                }
              >
                <StyleCalenderDate
                  key={`${date.year}-${date.month}-${date.date}`}
                  style={{
                    ...getColorForDate(date),
                  }}
                ></StyleCalenderDate>
              </DailyTooltip>
            ))
          )}
        </StyledCalenderDateContainer>

        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '10px',
            padding: '4px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <ActivitySubtitleText color="text">Less</ActivitySubtitleText>
            <ActivityColorRectContainer>
              {[...Array(5).keys()].map((i, index) => (
                <ActivityColorRect
                  key={index}
                  style={{ background: colors[i], padding: '2px' }}
                />
              ))}
            </ActivityColorRectContainer>
            <ActivitySubtitleText color="text">More</ActivitySubtitleText>
          </div>
        </div>
      </StyledDataContainer>
    </StyledCustomCard>
  );
};

export default CalenderYear;
