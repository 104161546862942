import React from 'react';
import ReactSelect from 'react-select';
import { AppWrapper } from './StyledComponents';

export default function Domain({
  placeholder,
  items,
  selectedDomain,
  onChange,
}) {
  return (
    <AppWrapper>
      <ReactSelect
        options={items}
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={items.find((c) => c.id === selectedDomain)}
        placeholder={placeholder}
        isClearable
      />
    </AppWrapper>
  );
}
