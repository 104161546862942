import { styled } from '@mui/styles';

export const StyledAppHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  background: theme.palette.common.white,
  boxShadow: `1px 0 3px ${theme.palette.component.shadowColor2}`,
  transition: 'all 0.3s ease-in-out',
  alignItems: 'center',
  padding: '10px 0',
  zIndex: 1002,

  '& .app-header-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .brand': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .header-content': {
    flex: 1,
    display: 'flex',
  },
}));
