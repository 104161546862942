import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import './index.scss';
import UserProfileEditContainer from '../../containers/UserProfileEditContainer';
import YourQuestionsContainer from '../../containers/UserProfileQuestionsContainer';
import UserProfileInfoContainer from '../../containers/UserProfileInfoContainer';
import ChangePasswordContainer from '../../containers/ChangePasswordContainer';
import YourQuestionCardContainer from '../../containers/YourQuestionCardContainer';
import UserProfileReferralContainer from '../../containers/UserProfileReferralContainer';

const UserProfile = (props) => {
  return (
    <div className="user-profile">
      <div>
        <div className="user-profile-container">
          <div className="user-profile-container-left">
            <Routes>
              <Route
                path={``}
                element={<UserProfileInfoContainer {...props} />}
              />

              <Route
                path={`profile`}
                element={<UserProfileInfoContainer {...props} />}
              />

              <Route
                path={`edit`}
                element={<UserProfileEditContainer {...props} />}
              />

              <Route
                path={`questions`}
                element={<YourQuestionsContainer {...props} />}
              />

              <Route
                path={`questions/:attemptId`}
                element={<YourQuestionCardContainer {...props} />}
              />

              <Route
                path={`password`}
                element={<ChangePasswordContainer {...props} />}
              />

              <Route
                path={`referral`}
                element={<UserProfileReferralContainer {...props} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

UserProfile.defaultProps = {
  getUserProfile: () => {},
};

UserProfile.propTypes = {
  getUserProfile: PropTypes.func,
};
export default UserProfile;
