import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import Loader from './../Loader';
import BlankPage from './../BlankPage';

import './index.css';

const TotalReferrals = ({ totalReferrals, getTotalReferrals }) => {
  const page = 0;
  const rowsPerPage = 10;

  useEffect(() => {
    getTotalReferrals(page, rowsPerPage);
  }, [page, rowsPerPage, getTotalReferrals]);

  if (totalReferrals.isFetchingAllReferrals) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  if (
    !totalReferrals.isFetchingAllReferrals &&
    totalReferrals.list &&
    !Object.keys(totalReferrals.list).length
  ) {
    return (
      <>
        <BlankPage
          text="There are currently no referrals "
          icon={<RecentActorsIcon fontSize="large" className="blank-icon" />}
        />
      </>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" style={{ fontSize: '18px' }}>
          Referrals
        </Typography>
      </Box>

      <Paper className="referral-card">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Accepted</TableCell>
              <TableCell align="center">Referred</TableCell>
              <TableCell align="center">Pending</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(totalReferrals.list).map((element, index) => (
              <TableRow key={index} className="scoreboard__list__row">
                <TableCell align="center">{index + 1 + page * 10}. </TableCell>
                <TableCell align="left">
                  {Boolean(element) && element}
                </TableCell>
                <TableCell align="center">
                  {totalReferrals.list[element].email_id}
                </TableCell>
                <TableCell align="center">
                  {totalReferrals.list[element].total_active_users}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {totalReferrals.list[element].total_referred}
                </TableCell>
                <TableCell align="center">
                  {totalReferrals.list[element].total_registration_pending}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(totalReferrals.list).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Container>
  );
};

export default TotalReferrals;
