import createReducer from '../lib/createReducer';
import {
  ADD_COMMENT_TO_QUESTION_FAIL,
  ADD_COMMENT_TO_QUESTION_START,
  ADD_COMMENT_TO_QUESTION_SUCCESS,
  CLEAR_COMMENTS_FOR_QUESTION_STATE,
  CLEAR_VOTE_A_COMMENT_STATE,
  GET_COMMENTS_FOR_QUESTION_FAIL,
  GET_COMMENTS_FOR_QUESTION_START,
  GET_COMMENTS_FOR_QUESTION_SUCCESS,
  REPORT_COMMENT_CLEAR,
  REPORT_COMMENT_FAIL,
  REPORT_COMMENT_START,
  REPORT_COMMENT_SUCCESS,
  VOTE_A_COMMENT_FAIL,
  VOTE_A_COMMENT_START,
  VOTE_A_COMMENT_SUCCESS,
} from './../actions/types';

const initialState = {
  comments: [],
  error: '',
  fetchingCommentsForAQuestion: false,
  addingNewCommentToQuestion: false,
  votingAComment: false,
  voteError: '',
  reportingAComment: false,
  reportCommentError: '',
};
export const discussion = createReducer(initialState, {
  [GET_COMMENTS_FOR_QUESTION_START](state) {
    return {
      ...state,
      fetchingCommentsForAQuestion: true,
    };
  },
  [GET_COMMENTS_FOR_QUESTION_SUCCESS](state, action) {
    return {
      ...state,
      comments: action.data,
      fetchingCommentsForAQuestion: false,
    };
  },
  [GET_COMMENTS_FOR_QUESTION_FAIL](state, action) {
    return {
      ...state,
      fetchingCommentsForAQuestion: false,
      error: action.data,
    };
  },
  [CLEAR_COMMENTS_FOR_QUESTION_STATE]() {
    return { ...initialState };
  },

  [ADD_COMMENT_TO_QUESTION_START](state) {
    return {
      ...state,
      addingNewCommentToQuestion: true,
    };
  },

  [ADD_COMMENT_TO_QUESTION_SUCCESS](state, action) {
    const { user, ...newComment } = action.data;
    newComment.downvote = 0;
    newComment.upvote = 0;
    newComment.is_upvoted = false;
    newComment.is_downvoted = false;
    newComment.user_details = {
      first_name: user.first_name,
      id: user.id,
      last_name: user.last_name,
      picture: user.picture,
      username: user.username,
    };

    const { parent_comment_id } = newComment;
    const dummyComments = window.structuredClone
      ? window.structuredClone(state.comments)
      : JSON.parse(JSON.stringify(state.comments));

    if (!parent_comment_id) {
      dummyComments.unshift(newComment);
    } else {
      const ref = dummyComments.filter(
        (comment) => comment.id === parent_comment_id,
      )[0];
      if (ref.sub_comments) {
        ref.sub_comments.unshift(newComment);
      } else {
        ref.sub_comments = [newComment];
      }
    }

    return {
      ...state,
      comments: dummyComments,
      addingNewCommentToQuestion: false,
    };
  },

  [ADD_COMMENT_TO_QUESTION_FAIL](state, action) {
    return {
      ...state,
      addingNewCommentToQuestion: false,
      error: action.data,
    };
  },

  [VOTE_A_COMMENT_START](state) {
    return {
      ...state,
      votingAComment: true,
      voteError: '',
    };
  },
  [VOTE_A_COMMENT_SUCCESS](state) {
    return {
      ...state,
      votingAComment: false,
    };
  },
  [VOTE_A_COMMENT_FAIL](state, action) {
    return {
      ...state,
      votingAComment: false,
      voteError: action.data,
    };
  },
  [CLEAR_VOTE_A_COMMENT_STATE](state) {
    return {
      ...state,
      votingAComment: false,
      voteError: '',
    };
  },

  [REPORT_COMMENT_START](state) {
    return {
      ...state,
      reportingAComment: true,
    };
  },
  [REPORT_COMMENT_SUCCESS](state) {
    return {
      ...state,
      reportingAComment: false,
    };
  },
  [REPORT_COMMENT_FAIL](state, action) {
    return {
      ...state,
      reportingAComment: false,
      reportCommentError: action.data,
    };
  },
  [REPORT_COMMENT_CLEAR](state) {
    return {
      ...state,
      reportingAComment: false,
      reportCommentError: '',
    };
  },
});
