import React, { useEffect, useMemo, useState } from 'react';
import {
  LeaderboardBody,
  LeaderboardChip,
  LeaderboardChipsWrapper,
  LeaderboardChipsWrapperForCompetition,
  LeaderboardContainer,
  LeaderboardHeader,
  LeaderboardTemporalSelect,
  LeaderboardTemporalValue,
  LeaderboardTypeSelect,
  LeaderboardUsername,
  LeaderboardSelect,
  MoreCompetitionsButton,
} from './StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getFollowedUsersList } from '../../actions/publicProfile';
import CustomPagination from './CustomPagination';
import { AddCircle, Search } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { nanoid } from 'nanoid';
import {
  getAuthorLeaderboard,
  getLeaderboard,
} from '../../actions/leaderboard';
import {
  getCompetitionById,
  getCompetitionList,
} from '../../actions/competition';
import LeaderboardTable from './LeaderboardTable';
import SelectCompetitionsDialog from './SelectCompetitionsDialog';
import moment from 'moment';
import { Hidden, Select } from '@mui/material';

const Leaderboard = () => {
  const pageSize = useSelector(
    (state) => state.leaderboard.leaderboardData?.page_size ?? 10,
  );
  const totalPages = useSelector(
    (state) => state.leaderboard.leaderboardData?.total_pages,
  );

  const selectedDomain = useSelector((state) => state.user?.selected_domain);
  const competitionList = useSelector(
    (state) => state.competitions.competitionList,
  );

  const tagList = useSelector((state) => {
    const tags =
      state?.tag?.list?.map((tag) => ({ name: tag.name, id: tag.id })) || {};

    return (
      [
        {
          name: 'Select Category',
          id: '',
        },
        ...tags,
      ] || [
        {
          name: 'Select Category',
          id: '',
        },
      ]
    );
  });

  const isAdmin = useSelector(
    (state) => state.user?.data?.user_type === 'Admin',
  );

  const [leaderboardType, setLeaderboardType] = useState(() => 'domain');
  const [leaderboardSubType, setLeaderboardSubType] = useState(
    () => 'practice',
  );
  const [temporalValueForDomain, setTemporalValueForDomain] = useState(
    () => 'overall',
  );
  const [username, setUsername] = useState(() => '');
  const [selectedTag, setSelectedTag] = useState(() => '');
  const [selectedCompetition, setSelectedCompetition] = useState(() => '');
  const [selectedPage, setSelectedPage] = useState(() => 1);
  const [selectedPageSize, setSelectedPageSize] = useState(() => pageSize);
  const [sortingParameter, setSortingParameter] = useState(
    () => 'questions_authored',
  );
  const [sortingType, setSortingType] = useState(() => 'desc');
  const [competitionModalOpen, setCompetitionModalOpen] = useState(() => false);
  const [
    monthAndYearForCompetition,
    setMonthAndYearForCompetition,
  ] = useState(() => moment());
  const [goToRankTrigger, setGotoRankTrigger] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFollowedUsersList());
    const url = new URL(window.location.href);
    const query_string = url.search;
    const search_params = new URLSearchParams(query_string);
    const competition = search_params.get('competition');
    if (competition) {
      setLeaderboardType('competition');
      setSelectedCompetition(competition);
    }
  }, []);

  useEffect(() => {
    if (leaderboardType === 'competition') {
      dispatch(
        getCompetitionList({
          pageSize: 100,
          startDate: moment(monthAndYearForCompetition).format(
            'YYYY-MM-DDTHH:mm:ss',
          ),
        }),
      );
    }
  }, [leaderboardType, monthAndYearForCompetition]);

  useEffect(() => {
    if (competitionList?.competitions?.[0]?.id) {
      setSelectedCompetition((prevState) => {
        if (!prevState) {
          return competitionList?.competitions?.[0]?.id;
        } else {
          return prevState;
        }
      });
    }
  }, [competitionList]);

  useEffect(() => {
    if (selectedCompetition !== '') {
      dispatch(getCompetitionById(selectedCompetition));
    }
  }, [selectedCompetition]);

  useEffect(() => {
    if (!goToRankTrigger) {
      setSelectedPage(1);
    }
  }, [username]);

  useEffect(() => {
    if (leaderboardType === 'domain') {
      if (leaderboardSubType === 'practice') {
        dispatch(
          getLeaderboard({
            pageNumber: selectedPage,
            pageSize: selectedPageSize,
            timePeriod: temporalValueForDomain,
            tagId: selectedTag,
            userSearchQuery: username,
            domainId: selectedDomain,
            leaderboardType: 'practice',
          }),
        );
      } else {
        dispatch(
          getAuthorLeaderboard({
            pageNumber: selectedPage,
            pageSize: selectedPageSize,
            domainId: selectedDomain,
            leaderboardType: 'author',
            userSearchQuery: username,
            tagId: selectedTag,
            sortBy: sortingParameter,
            sortOrder: sortingType,
          }),
        );
      }
    } else if (
      leaderboardType === 'competition' &&
      selectedCompetition !== ''
    ) {
      dispatch(
        getLeaderboard({
          pageNumber: selectedPage,
          pageSize: selectedPageSize,
          competitionId: selectedCompetition,
          userSearchQuery: username,
          leaderboardType: 'competition',
        }),
      );
    }
  }, [
    leaderboardType,
    leaderboardSubType,
    selectedPage,
    selectedPageSize,
    temporalValueForDomain,
    selectedTag,
    username,
    selectedDomain,
    selectedCompetition,
    sortingParameter,
    sortingType,
  ]);
  useEffect(() => {
    setSelectedPage(1);
    setSelectedPageSize(10);
  }, [selectedTag]);

  const handleSorting = (category) => {
    if (category === 1) {
      setSortingType((currType) => (currType === 'desc' ? 'asc' : 'desc'));
      setSortingParameter('questions_authored');
    }
    if (category === 2) {
      setSortingType((currType) => (currType === 'desc' ? 'asc' : 'desc'));
      setSortingParameter('likes');
    }
    if (category === 3) {
      setSortingType((currType) => (currType === 'desc' ? 'asc' : 'desc'));
      setSortingParameter('questions_seen');
    }
    if (category === 4) {
      setSortingType((currType) => (currType === 'desc' ? 'asc' : 'desc'));
      setSortingParameter('bookmarks');
    }
  };

  const temporalEntries = useMemo(() => {
    if (leaderboardType === 'domain') {
      return [
        { value: 'monthly', label: 'Monthly' },
        { value: 'overall', label: 'All-Time' },
      ];
    }
  }, [leaderboardType]);

  const additionalDisplay = useMemo(() => {
    const selectedCompetitionIndex = competitionList?.competitions?.findIndex(
      (competition) => competition.id === selectedCompetition,
    );
    const additionalSelectedCompetition =
      competitionList?.competitions?.[selectedCompetitionIndex];

    return leaderboardType === 'domain' ? (
      <LeaderboardChipsWrapper>
        <Hidden smDown>
          <LeaderboardChip
            active={leaderboardSubType === 'practice' && 'true'}
            onClick={() => setLeaderboardSubType('practice')}
          >
            Practice
          </LeaderboardChip>
          {isAdmin && (
            <LeaderboardChip
              active={leaderboardSubType === 'author' && 'true'}
              onClick={() => setLeaderboardSubType('author')}
            >
              Author
            </LeaderboardChip>
          )}
        </Hidden>
        <Hidden smUp>
          <Select
            onChange={(event) => setLeaderboardSubType(event.target.value)}
            value={leaderboardSubType}
            displayEmpty
            size="small"
            style={{ height: '25px' }}
          >
            <MenuItem value={'practice'}>Practice</MenuItem>
            {isAdmin && <MenuItem value={'author'}>Author</MenuItem>}
          </Select>
        </Hidden>

        <LeaderboardSelect
          value={selectedTag}
          onChange={(event) => setSelectedTag(event.target.value)}
          displayEmpty
          size="small"
        >
          {tagList.map((tag) => (
            <MenuItem value={tag.id} key={tag.id}>
              {tag.name}
            </MenuItem>
          ))}
        </LeaderboardSelect>
      </LeaderboardChipsWrapper>
    ) : (
      <LeaderboardChipsWrapperForCompetition>
        {competitionList?.competitions?.slice(0, 2)?.map((competition) => (
          <LeaderboardChip
            key={competition.id}
            active={competition.id === selectedCompetition && 'true'}
            className={competition.id === selectedCompetition && 'active-chip'}
            onClick={() => setSelectedCompetition(competition.id)}
          >
            {competition.name}
          </LeaderboardChip>
        ))}
        {selectedCompetitionIndex > 1 && (
          <LeaderboardChip
            className="active-chip"
            active="true"
            onClick={() =>
              setSelectedCompetition(additionalSelectedCompetition.id)
            }
          >
            {additionalSelectedCompetition.name}
          </LeaderboardChip>
        )}
        <MoreCompetitionsButton
          onClick={() => setCompetitionModalOpen(true)}
          variant="outlined"
          startIcon={<AddCircle />}
          size="small"
        >
          <span className="button-text">More Competitions</span>
        </MoreCompetitionsButton>
      </LeaderboardChipsWrapperForCompetition>
    );
  }, [
    leaderboardType,
    competitionList,
    leaderboardSubType,
    selectedCompetition,
    selectedTag,
    sortingParameter,
    sortingType,
  ]);

  const handleGoToRankPage = (rank) => {
    setGotoRankTrigger(true);
    setUsername('');
    const targetPage = Math.ceil(rank / selectedPageSize);
    setSelectedPage(targetPage);
  };

  const temporalValueGenerator = () => {
    const temporalValue =
      leaderboardType === 'domain' && temporalValueForDomain;
    const setTemporalValue =
      leaderboardType === 'domain' && setTemporalValueForDomain;

    const handleClick = (entry) => {
      setTemporalValue(entry.value);
      if (leaderboardType === 'competition') {
        setSelectedCompetition('');
      }
    };

    return (
      <>
        {leaderboardType === 'domain' &&
        true === false && ( //Just added a condition for now to remove this feature temporarily.
            <LeaderboardTemporalSelect>
              {temporalEntries.map((entry) => (
                <LeaderboardTemporalValue
                  key={nanoid()}
                  onClick={() => handleClick(entry)}
                  active={temporalValue === entry.value}
                >
                  {entry.label}
                </LeaderboardTemporalValue>
              ))}
            </LeaderboardTemporalSelect>
          )}
      </>
    );
  };

  return (
    <LeaderboardContainer>
      <SelectCompetitionsDialog
        open={competitionModalOpen}
        setOpen={setCompetitionModalOpen}
        selectedCompetition={selectedCompetition}
        setSelectedCompetition={setSelectedCompetition}
        monthAndYearForCompetition={monthAndYearForCompetition}
        setMonthAndYearForCompetition={setMonthAndYearForCompetition}
      />
      <LeaderboardHeader>
        <LeaderboardTypeSelect
          size="small"
          value={leaderboardType}
          onChange={(event) => setLeaderboardType(event.target.value)}
        >
          <MenuItem value="domain">Domain Leaderboard</MenuItem>
          <MenuItem value="competition">Competition Leaderboard</MenuItem>
        </LeaderboardTypeSelect>

        {leaderboardSubType !== 'author' || leaderboardType === 'competition'
          ? temporalValueGenerator()
          : ''}

        <LeaderboardUsername
          placeholder="Search by Username"
          InputProps={{
            endAdornment: <Search />,
          }}
          value={username}
          onChange={(event) => {
            setSelectedPage(1);
            setUsername(event.target.value.trimStart());
          }}
          size="small"
        />
      </LeaderboardHeader>
      <LeaderboardBody>
        {additionalDisplay}
        <LeaderboardTable
          usernameSearchQuery={username}
          handleGoToRankPage={handleGoToRankPage}
          selectedTag={selectedTag}
          handleSorting={handleSorting}
        />
      </LeaderboardBody>
      <CustomPagination
        count={selectedPageSize * totalPages}
        page={selectedPage}
        rowsPerPage={selectedPageSize}
        rowsPerPageOption={[10, 25, 50, 100]}
        onRowsPerPageChange={(event) => {
          setSelectedPageSize(event.target.value);
        }}
        onPageChange={(page) => setSelectedPage(page)}
      />
    </LeaderboardContainer>
  );
};

export default Leaderboard;
