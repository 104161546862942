import React, { useEffect } from 'react';
import { Box, Chip, Modal, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import defaultMockTest from './images/defaultMockTest.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getMockTestStatistics } from '../../actions/mockTest';
import { StatsContainerMockTest } from './MocktestStartDialogStyledComponent';
import { StyledModalContainer } from '../Category/CategoryStyledComponent';

const useStyles = makeStyles(() => ({
  modalMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalDetails: {
    color: '#25507B',
    fontWeight: 500,
    textAlign: 'left',
    width: '100%',
  },
  modalTopics: {
    color: '#76767F',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },

  modalStats1: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '14px',
    textAlign: 'center',
    width: '100%',
    borderRadius: 2,
  },
}));

const MockTestResumeDialog = ({
  openModal,
  handleModalClose,
  mockTestDetails,
  onStartTest,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMockTestStatistics(mockTestDetails.id));
  }, []);
  const { mockTestStatistics } = useSelector((state) => state.mockTest);
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      className={styles.modalMain}
    >
      <StyledModalContainer>
        <Box className="header">
          <img
            src={mockTestDetails.image_url || defaultMockTest}
            className="image"
            alt={mockTestDetails.name}
          />
          <Box className="details">
            <Typography variant="subtitle1" className={styles.modalDetails}>
              {mockTestDetails.name}
            </Typography>
          </Box>
        </Box>
        <Box className="topicsList">
          <Typography variant="subtitle2" className={styles.modalTopics}>
            Topics Covered
          </Typography>
          <Box className="listCtn">
            {mockTestStatistics?.all_exam_tags?.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                size="small"
                className="tag"
              />
            ))}
          </Box>
          <StatsContainerMockTest>
            <Box className="questions-mockTest">
              <Typography
                variant="caption"
                align="center"
                style={{
                  color: '#25507B',
                  fontWeight: 500,
                  lineHeight: '16px',
                  fontSize: '16px',
                }}
              >
                Number of Questions
              </Typography>
              <Box className="avatar-mockTest">
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={styles.modalStats1}
                >
                  {mockTestStatistics?.total_questions_attempted} /{' '}
                  {mockTestStatistics?.total_questions}
                </Typography>
                &nbsp;
              </Box>
            </Box>
          </StatsContainerMockTest>

          <Box className="triggerCtn" style={{ marginTop: '15px' }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => onStartTest(mockTestDetails)}
              style={{
                backgroundColor: '#25507B',
                color: '#fff',
                width: '100%',
                height: '30px',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              {mockTestDetails.started_on ? 'Resume' : 'Start'} Mock Test
            </Button>
          </Box>
        </Box>
      </StyledModalContainer>
    </Modal>
  );
};

export default MockTestResumeDialog;
