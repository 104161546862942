import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import { styles } from './styles';
import { Box, Grid } from '@mui/material';
import SelectAllIcon from './img/selectAllIcon.svg';
import SelectedAllIcon from './img/selectedAllIcon.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubcategorySelector = (props) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (props.selectedCategory?.id) props.getStats(props.selectedCategory?.id);
  }, [props.selectedCategory]);

  useEffect(() => {
    if (!props.category.isFetchingStats && props.category.stats?.preferences) {
      setSelected(
        props.category.stats?.preferences
          .filter((record) => record.is_selected)
          .map((record) => record.id),
      );
    }
  }, [props.category.isFetchingStats]);

  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const toggleDeSelectAll = () => {
    setSelected([]);
  };

  const toggleSelectAll = () => {
    const { category } = props;
    setSelected(category.stats?.preferences?.map((record) => record.id));
  };

  const onSubmit = () => {
    props.handleDone(selected, props.selectedCategory.id);
    props.onClose();
  };

  const { visible, onClose, category, classes } = props;

  const subTopicLength = props.category?.stats?.preferences?.length ?? 0;

  return (
    <div>
      <Dialog
        open={visible}
        onClose={onClose}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
      >
        <div className={classes.content}>
          <div>
            <Typography variant="body1" className={classes.heading}>
              {category.stats?.tag_name} Sub-Topics:
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className={classes.heading2}
            >
              You can select multiple subtopics for focused practise with our
              platform.
            </Typography>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.allSubtopicsList}>
              <Grid
                container
                style={{ marginBottom: '20px' }}
                display={'flex'}
                alignItems={'center'}
              >
                <Grid item xs={6}>
                  <span className={classes.subTopicsHeading}>
                    Selected Subtopics{' '}
                    <span className={classes.selectedCounter}>
                      ({selected?.length}/{category.stats?.preferences?.length})
                    </span>
                  </span>
                </Grid>
                {subTopicLength === selected?.length ? (
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex' }}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <Box
                      onClick={toggleDeSelectAll}
                      className={classes.selectAllContainer}
                      sx={{
                        borderColor: '#3572B0 !important',
                        color: '#3572B0 !important',
                      }}
                    >
                      <img src={SelectedAllIcon} alt="SelectAllIcon" />
                      &nbsp;&nbsp;
                      <span>Deselect All</span>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex' }}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <Box
                      onClick={toggleSelectAll}
                      className={classes.selectAllContainer}
                      sx={{
                        borderColor: '#76767F',
                        color: '#76767F',
                      }}
                    >
                      <img src={SelectAllIcon} alt="SelectAllIcon" />
                      &nbsp;&nbsp;
                      <span>Select All</span>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <div className={classes.subCategoryContainer}>
                {category.stats?.preferences?.map((record, index) => (
                  <>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      className={
                        !selected.includes(record.id)
                          ? classes.subcategoryBox
                          : classes.selectedSubcategoryBox
                      }
                      key={record.id}
                      onClick={() => handleSelect(record.id)}
                    >
                      <span
                        className={
                          !selected.includes(record.id)
                            ? `${classes.serialNo} serialNo`
                            : `${classes.selectedSerialNo} selectedSerialNo`
                        }
                      >
                        {index + 1}
                      </span>{' '}
                      &nbsp;&nbsp;
                      <div>
                        <Typography
                          variant="body1"
                          className={
                            !selected.includes(record.id)
                              ? classes.categoryName
                              : classes.selectedCategoryName
                          }
                        >
                          {record.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.questionNumber}
                        >
                          Number of Question:&nbsp;{record.question_count}
                        </Typography>
                      </div>
                    </Box>
                  </>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid className={classes.btnContainer}>
          <Button
            onClick={onClose}
            variant="text"
            className={classes.discardBtn}
          >
            Discard
          </Button>
          <Button onClick={onSubmit} variant="text" className={classes.saveBtn}>
            Save
          </Button>
        </Grid>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(SubcategorySelector);
