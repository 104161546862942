import createReducer from '../lib/createReducer';
import * as types from './../actions/types';

export const mockTest = createReducer(
  {
    data: {},
    prerequisite: {},
    error: '',
    questions: [],
    questionsPaginationInfo: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 1,
    },
    mockTestStatistics: {},
    mockTestDataById: {},
    uploadedImage: null,
    isStartingMockTest: false,
    isFetchingMockTest: false,
    isCreatingMockTest: false,
    isPostingMockTest: false,
    isUploadingImage: false,
    isEndingMockTest: false,
    isFetchingMockTestPrerequisite: false,
    isEditingMockTest: false,
    isMockTestByIdFetched: false,
    isMockTestUpdatedSuccess: false,
  },
  {
    [types.CLEAR_MOCK_TEST_STATE]() {
      return {
        data: {},
        prerequisite: {},
        error: '',
        questions: [],
        questionsPaginationInfo: {
          currentPage: 0,
          pageSize: 0,
          totalPages: 1,
        },
        isStartingMockTest: false,
        isFetchingMockTest: false,
        isCreatingMockTest: false,
        isPostingMockTest: false,
        isFetchingMockTestPrerequisite: false,
      };
    },
    [types.GET_MOCK_TEST_START](state) {
      return {
        ...state,
        error: '',
        isFetchingMockTest: true,
        mockTestSuccessId: null,
      };
    },
    [types.GET_MOCK_TEST_SUCCESS](state, action) {
      return {
        ...state,
        data: action.data,
        isFetchingMockTest: false,
        isMockTestUpdatedSuccess: false,
      };
    },
    [types.GET_MOCK_TEST_FAIL](state) {
      return {
        ...state,
        isFetchingMockTest: false,
      };
    },
    [types.GET_MOCK_TEST_PREREQUISITE_START](state) {
      return {
        ...state,
        isFetchingMockTestPrerequisite: true,
      };
    },
    [types.GET_MOCK_TES_PREREQUISITT_SUCCESS](state, action) {
      return {
        ...state,
        prerequisite: action.data,
        isFetchingMockTestPrerequisite: false,
      };
    },
    [types.GET_MOCK_TEST_PREREQUISIT_FAIL](state) {
      return {
        ...state,
        isFetchingMockTestPrerequisite: false,
      };
    },
    [types.GET_MOCK_TEST_BY_ID_START](state) {
      return {
        ...state,
        error: '',
        isFetchingMockTest: true,
      };
    },
    [types.GET_MOCK_TEST_BY_ID_SUCCESS](state, action) {
      return {
        ...state,
        mockTestDataById: action.data,
        isFetchingMockTest: false,
        isMockTestByIdFetched: true,
        data: action.data,
      };
    },
    [types.GET_MOCK_TEST_BY_ID_FAIL](state) {
      return {
        ...state,
        isFetchingMockTest: false,
      };
    },
    [types.UPLOAD_IMAGE_START](state) {
      return {
        ...state,
        isUploadingImage: true,
        uploadImageSuccess: false,
        uploadImageFail: false,
      };
    },
    [types.UPLOAD_IMAGE_SUCCESS](state, action) {
      return {
        ...state,
        isUploadingImage: false,
        uploadImageSuccess: true,
        uploadImageFail: false,
        uploadedImage: action.data,
      };
    },
    [types.UPLOAD_IMAGE_FAIL](state) {
      return {
        ...state,
        isUploadingImage: false,
        uploadImageSuccess: false,
        uploadImageFail: true,
      };
    },
    [types.CREATE_MOCK_TEST_START](state) {
      return {
        ...state,
        error: '',
        isCreatingMockTest: true,
        isCreateMockTestSuccess: false,
        isCreatingMockTestFail: false,
      };
    },
    [types.CREATE_MOCK_TEST_SUCCESS](state) {
      return {
        ...state,
        isCreatingMockTest: false,
        isCreateMockTestSuccess: true,
        isCreatingMockTestFail: false,
      };
    },
    [types.CREATE_MOCK_TEST_FAIL](state, action) {
      return {
        ...state,
        isCreatingMockTest: false,
        isCreateMockTestSuccess: false,
        isCreatingMockTestFail: true,
        error: action.data,
      };
    },
    [types.POST_MOCK_TEST_START](state) {
      return {
        ...state,
        error: '',
        isPostingMockTest: true,
        isPostMockTestSuccess: false,
        postedMockTestId: null,
      };
    },
    [types.POST_MOCK_TEST_SUCCESS](state, action) {
      return {
        ...state,
        isPostingMockTest: false,
        isPostMockTestSuccess: true,
        postedMockTestId: action.data.id,
      };
    },
    [types.POST_MOCK_TEST_FAIL](state, action) {
      return {
        ...state,
        isPostingMockTest: false,
        isPostMockTestSuccess: false,
        error: action.data,
      };
    },
    [types.START_MOCK_TEST_START](state) {
      return {
        ...state,
        error: '',
        isStartingMockTest: true,
        isStartMockTestSuccess: false,
        mockTestSuccessId: null,
      };
    },
    [types.START_MOCK_TEST_SUCCESS](state, action) {
      return {
        ...state,
        isStartingMockTest: false,
        isStartMockTestSuccess: true,
        mockTestSuccessId: action.data,
      };
    },
    [types.START_MOCK_TEST_FAIL](state, action) {
      return {
        ...state,
        isStartingMockTest: false,
        error: action.data,
      };
    },
    [types.GET_MOCK_TEST_QUESTIONS_START](state) {
      return {
        ...state,
        questions: [],
        isGettingMockTestQuestions: true,
      };
    },
    [types.GET_MOCK_TEST_QUESTIONS_SUCCESS](state, action) {
      return {
        ...state,
        questions: action.data.all_attempts,
        questionsPaginationInfo: {
          currentPage: action.data.current_page - 1, // zero-based
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
        isGettingMockTestQuestions: false,
      };
    },
    [types.GET_MOCK_TEST_QUESTIONS_FAIL](state, action) {
      return {
        ...state,
        questions: [],
        error: action.data,
        isGettingMockTestQuestions: false,
      };
    },
    [types.UNLOCK_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.question_id === action.payload.data.question_id
            ? {
                ...question,
                is_solution_unlocked: true,
                question: {
                  ...question.question,
                  solution_text: action.payload.data.solution_text,
                },
              }
            : question,
        ),
      };
    },
    [types.END_MOCK_TEST_START](state) {
      return {
        ...state,
        isEndingMockTest: true,
      };
    },
    [types.END_MOCK_TEST_SUCCESS](state) {
      return {
        ...state,
        isEndingMockTest: false,
        endMockTestSuccess: true,
      };
    },
    [types.END_MOCK_TEST_FAIL](state) {
      return {
        ...state,
        isEndingMockTest: false,
        endMockTestSuccess: false,
      };
    },
    [types.DELETE_MOCK_TEST_START](state) {
      return {
        ...state,
        isDeletingMockTest: true,
        deleteMockTestSuccess: false,
        deleteMockTestFail: false,
      };
    },
    [types.DELETE_MOCK_TEST_SUCCESS](state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          templates: state.data.templates
            ? state.data.templates.filter(
                (template) => template.id !== action.data,
              )
            : [],
        },
        isDeletingMockTest: false,
        deleteMockTestSuccess: true,
        deleteMockTestFail: false,
      };
    },
    [types.DELETE_MOCK_TEST_FAIL](state) {
      return {
        ...state,
        isDeletingMockTest: false,
        deleteMockTestSuccess: false,
        deleteMockTestFail: true,
      };
    },
    [types.EDIT_MOCK_TEST_SUCCESS](state) {
      return {
        ...state,
        isEditingMockTest: false,
        isMockTestUpdatedSuccess: true,
      };
    },
    [types.EDIT_MOCK_TEST_FAIL](state) {
      return {
        ...state,
        isEditingMockTest: false,
        isMockTestUpdatedSuccess: false,
      };
    },
    [types.EDIT_MOCK_TEST_START](state) {
      return {
        ...state,
        isEditingMockTest: true,
        isMockTestUpdatedSuccess: false,
      };
    },

    [types.GET_MOCK_TEST_STATISTICS_START](state) {
      return {
        ...state,
        isGettingMockTestStatistics: true,
      };
    },
    [types.GET_MOCK_TEST_STATISTICS_SUCCESS](state, action) {
      return {
        ...state,
        mockTestStatistics: action.data,
        isGettingMockTestStatistics: false,
      };
    },
    [types.GET_MOCK_TEST_STATISTICS_FAIL](state) {
      return {
        ...state,
        isGettingMockTestStatistics: false,
      };
    },
  },
);
