import { TextField, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Button } from '../Button';
import RichTextEditor from '../RichTextEditor';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import CreateOptions from './CreateOptions';
import { QuestionValidation } from './Validations';
import DeleteIcon from './images/Delete.png';
import { AlternateAnswerSpace } from './QuestionBody';
import { StyledEditQuestion } from './StyledComponent';

const Question = ({
  index,
  metaData,
  questionArray,
  setQuestionArray,
  isActive,
  setActiveQuestion,
  DeleteQuestion,
}) => {
  const methods = useFormContext();

  const { clearErrors: resetErrors } = methods;
  const { setError } = useForm();
  const [questionType, setQuestionType] = useState(
    questionArray[index].question_type,
  );
  const [answer, setAnswer] = useState(questionArray[index].answer_one_word);
  const [how_to_answer, setHow_to_answer] = useState('');
  const [optionValues, setOptionValues] = useState([]);
  const [mcqAns, setMcqAns] = useState([1]);

  useEffect(() => {
    if (questionArray[index].question_text !== '') {
      setQuestionType(questionArray[index].question_type);
    }
  }, [questionArray]);

  useEffect(() => {
    if (Object.keys(questionArray[index])?.length) {
      setQuestionType(questionArray[index].question_type);
      setOptionValues(questionArray[index].options);
      setMcqAns(questionArray[index].answer_option_no);
    }
  }, []);

  useEffect(() => {
    setQuestionArray(() =>
      questionArray.map((q, i) => {
        if (i === index) {
          return { ...q, options: optionValues, answer_option_no: mcqAns };
        } else {
          return q;
        }
      }),
    );
  }, [optionValues, mcqAns]);

  useEffect(() => {
    setQuestionArray(() =>
      questionArray.map((q, i) => {
        if (i === index) {
          return { ...q, answer_one_word: answer };
        } else {
          return q;
        }
      }),
    );
  }, [answer]);

  useEffect(() => {
    setQuestionArray(() =>
      questionArray.map((q, i) => {
        if (i === index) {
          return { ...q, how_to_answer };
        } else {
          return q;
        }
      }),
    );
  }, [how_to_answer]);

  useEffect(() => {
    if (['MultipleCorrect', 'MultipleChoice'].includes(questionType)) {
      if (optionValues?.length) {
        setQuestionArray(() =>
          questionArray.map((q, i) => {
            if (i === index) {
              return {
                ...q,
                options: optionValues,
                answer_option_no: mcqAns,
                how_to_answer: null,
                answer_one_word: null,
                question_type: questionType,
              };
            } else {
              return q;
            }
          }),
        );
      } else {
        setQuestionArray(() =>
          questionArray.map((q, i) => {
            if (i === index) {
              return {
                ...q,
                how_to_answer: null,
                answer_one_word: null,
                question_type: questionType,
              };
            } else {
              return q;
            }
          }),
        );
      }
    } else {
      setQuestionArray(() =>
        questionArray.map((q, i) => {
          if (i === index) {
            return {
              ...q,
              options: null,
              answer_option_no: null,
              question_type: questionType,
            };
          } else {
            return q;
          }
        }),
      );
    }
  }, [questionType]);

  return (
    <>
      <div className="question">
        <div
          className="header"
          onClick={() => !isActive && setActiveQuestion(index)}
        >
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            <div className="dot">{isActive && <div className="filler" />}</div>
            Question {index + 1}
          </Typography>
          <div className="right_utils">
            <select
              value={questionType}
              onChange={(e) => {
                setQuestionType(e.target.value);
              }}
            >
              <option key={'OneWord'} value={'OneWord'}>
                OneWord
              </option>
              <option key={'MultipleChoice'} value={'MultipleChoice'}>
                MultipleChoice
              </option>
              <option key={'MultipleCorrect'} value={'MultipleCorrect'}>
                MultipleSelect
              </option>
            </select>
            <img src={DeleteIcon} onClick={() => DeleteQuestion(index)} />
          </div>
        </div>
        <div style={{ display: isActive ? 'block' : 'none' }}>
          <div className="form-group">
            <span>
              <Typography mb="5px" mt="5px" color="#000" fontWeight="500">
                Question Body Text
              </Typography>{' '}
            </span>
            <RichTextEditor
              value={questionArray[index].question_text}
              onChange={(e) =>
                setQuestionArray(() =>
                  questionArray.map((q, i) => {
                    if (i === index) {
                      return { ...q, question_text: e.target.value };
                    } else {
                      return q;
                    }
                  }),
                )
              }
              placeholder="Type your question"
            />
          </div>
          {questionType === 'OneWord' && (
            <div className="form-group answer-text">
              <span>
                <Typography mb="5px" mt="10px" color="#000" fontWeight="500">
                  Enter your one word answer
                </Typography>
              </span>
              <div>
                <TextField
                  value={
                    answer.length
                      ? answer?.[0]
                      : questionArray[index].answer_one_word
                  }
                  onChange={(e) =>
                    setAnswer([e.target.value, ...answer.slice(1)])
                  }
                  variant="outlined"
                  size="small"
                  className="text-input-field"
                  style={{ width: '100%' }}
                  type="text"
                />
              </div>
            </div>
          )}
          {questionType === 'OneWord' && (
            <div className="form-group answer-text">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  <Typography mb="5px" mt="10px" color="#000" fontWeight="500">
                    How to answer ?
                  </Typography>
                </span>
                <Tooltip
                  title={
                    <Typography fontSize={16}>
                      The format in which user should write the answer. Eg:
                      Without space, Without units, etc.
                    </Typography>
                  }
                  placement="right"
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <div>
                <TextField
                  value={
                    how_to_answer
                      ? how_to_answer
                      : questionArray[index].how_to_answer
                  }
                  onChange={(e) => setHow_to_answer(e.target.value)}
                  variant="outlined"
                  className="text-input-field"
                  size="small"
                  style={{ width: '100%' }}
                  type="text"
                  inputProps={{ maxLength: 255 }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  <Typography mb="5px" mt="10px" color="#000" fontWeight="500">
                    Alternate Answer
                  </Typography>
                </span>
                <Tooltip
                  title={
                    <Typography fontSize={16}>
                      Other acceptable formats of the one-word answer..
                    </Typography>
                  }
                  placement="right"
                >
                  <InfoIcon />
                </Tooltip>
              </div>
              <div>
                <AlternateAnswerSpace
                  value={answer}
                  onupdate={(value) => setAnswer(value)}
                />
              </div>
            </div>
          )}
          {['MultipleChoice', 'MultipleCorrect'].includes(questionType) && (
            <div className="form-group">
              <CreateOptions
                correctOptions={mcqAns}
                setCorrectOptions={setMcqAns}
                questionType={questionType}
                value={optionValues}
                onChange={setOptionValues}
                metaData={metaData}
                setError={setError}
                clearErrors={resetErrors}
              />
            </div>
          )}
          <div className="form-group">
            <span>
              <Typography mb="5px" mt="5px" color="#000" fontWeight="500">
                Solution
              </Typography>
            </span>
            <RichTextEditor
              value={questionArray[index].solution_text}
              onChange={(e) =>
                setQuestionArray(() =>
                  questionArray.map((q, i) => {
                    if (i === index) {
                      return { ...q, solution_text: e.target.value };
                    } else {
                      return q;
                    }
                  }),
                )
              }
              placeholder="Type your solution"
            />
          </div>
          <div className="form-group" style={{ marginBottom: '30px' }}>
            <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <Typography mb="5px" mt="5px" color="#000" fontWeight="500">
                Answer Hint <i>(optional)</i>
              </Typography>
              <Tooltip
                title={
                  <Typography fontSize={16}>
                    You will earn credits every time user uses your hint.
                  </Typography>
                }
                placement="right"
              >
                <img src={CreditIcon} height={15} />
              </Tooltip>
            </span>
            <TextField
              value={questionArray[index].question_hint}
              maxLength={255}
              onChange={(e) =>
                setQuestionArray(() =>
                  questionArray.map((q, i) => {
                    if (i === index) {
                      return { ...q, question_hint: e.target.value };
                    } else {
                      return q;
                    }
                  }),
                )
              }
              placeholder="Type the hint to the solution"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '30px',
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ComprehensiveQuestions = (props) => {
  const { value, onChange, defaultValue } = props;
  const [questionArray, setQuestionArray] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    value.map((v) => {
      delete v.exam_type;
      delete v.external_exam;
      delete v.exam_id;
    });
    if (value.length !== 0) {
      setQuestionArray(value);
    } else {
      setQuestionArray(defaultValue);
    }
  }, [value]);

  useEffect(() => {
    onChange(questionArray);
  }, [questionArray]);

  const tempVariable = {
    question_text: '',
    question_type: 'MultipleChoice',
    answer_option_no: [],
    answer_one_word: '',
    difficulty_level: 1.0,
    tag_id: '',
    sub_tag_array: [],
    options: [],
    solution_text: '',
  };

  const Append = () => {
    if (questionArray.length > 0) {
      for (let ind in questionArray) {
        const question = questionArray[ind];
        const error = QuestionValidation(question);
        if (error) {
          enqueueSnackbar(
            `Question ${parseInt(ind) + 1}: ${error.errorMessage}`,
            {
              variant: 'error',
            },
          );
          return;
        }
      }
    }
    var l = questionArray.length;
    setQuestionArray([...questionArray, tempVariable]);
    setActiveQuestion(l);
  };

  const DeleteQuestion = (index) => {
    const qs = questionArray;
    setQuestionArray(null);
    let temparr = [];
    qs.forEach((q, i) => {
      if (i !== index) {
        temparr.push(q);
      }
    });
    setQuestionArray(temparr);
  };

  return (
    <StyledEditQuestion>
      <div className="Comprehensive_question_style">
        {questionArray &&
          questionArray.map((q, i) => (
            <Question
              questionArray={questionArray}
              setQuestionArray={setQuestionArray}
              index={i}
              metaData={props.metaData}
              onChange={onChange}
              isActive={activeQuestion === i}
              setActiveQuestion={setActiveQuestion}
              DeleteQuestion={DeleteQuestion}
              key={i}
            />
          ))}
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => Append()}
        >
          Add Question
        </Button>
      </div>
    </StyledEditQuestion>
  );
};

export default ComprehensiveQuestions;
