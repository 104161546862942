import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

function TimedoutModal({ open, handleClose, examType }) {
  const btnText = examType === 'mockTest' ? 'View Result' : 'Go Back';

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Times Up!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The allocated time for this test is finished. Check the results page
          to analyse your performance
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          autoFocus
        >
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TimedoutModal;
