import { styled } from '@mui/styles';

export const StyledForm = styled('form')({
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  '& .btn-submit': {
    width: 'fit-content',
  },

  '& .schedules__navigation': {
    padding: '0',
  },

  '& .MuiTablePagination-spacer': {
    display: 'block',
  },
});
