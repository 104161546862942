import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Play from '.';

const getCategoryById = (list = [], id) => {
  return list.find((item) => item.id === id) || {};
};

const Practise = (props) => {
  const [questionAttemptTimer, setQuestionAttemptTimer] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);
  const [answer, setAnswer] = useState({});
  const [message, setMessage] = useState({});
  const [answerStatus, setAnswerStatus] = useState({});
  const [modalVisibility, setModalVisibility] = useState({
    hintModal: false,
    reportModal: false,
    timedoutModal: false,
    streakSaveModal: false,
    confirmEndModal: false,
    getMoreTimeModal: false,
    answerStatusModal: false,
    unlockSolutionModal: false,
    subcategorySelectorModal: false,
    collectBadgeModal: false,
  });

  const { categoryId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { question, getQuestion, getStreak, getStats, resetQuestions } = props;

  const getNextQuestion = () => {
    // clearing state while fetching a new question
    setIsHintVisible(false);
    setIsSolutionVisible(false);
    setIsAnswerSubmitted(false);

    if (state && state.page == 'publicquestion') {
      if (state.publicCategoryId)
        getQuestion(state.publicCategoryId, state.page);
      const stateCopy = { ...state };
      delete stateCopy.page;
      delete stateCopy.publicCategoryId;
      navigate('', { state: stateCopy });
    } else if (state && state.page === 'mockTest') {
      if (state.mocktestCategoryId) getQuestion(state.mocktestCategoryId);
    } else {
      getQuestion(categoryId);
    }
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);

      getStreak(categoryId);
      getStats(
        categoryId,
        () => {},
        (err) => {
          if (err.error === 'no_such_unlocked_tag') {
            navigate('/l/practise');
            return;
          }
        },
      );

      getNextQuestion();
    })();

    return () => {
      setIsAnswerSubmitted(false);
      setIsHintVisible(false);
      setIsSolutionVisible(false);
      setAnswer({});
      setModalVisibility({
        hintModal: false,
        reportModal: false,
        streakSaveModal: false,
        confirmEndModal: false,
        getMoreTimeModal: false,
        answerStatusModal: false,
        unlockSolutionModal: false,
        subcategorySelectorModal: false,
      });
      setAnswerStatus({});
      setMessage({});
      resetQuestions();
    };
  }, []);

  useEffect(() => {
    if (
      question.data.id &&
      !question.isAnswerSuccess &&
      !question.isAnswerFail
    ) {
      const interval = setInterval(() => {
        setQuestionAttemptTimer((t) => t + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
        setQuestionAttemptTimer(0);
      };
    }
  }, [question.data.id, question.isAnswerSuccess, question.isAnswerFail]);

  const onBack = () => {
    navigate(`/l/pratise`);
  };

  const handleSubcategoryChangeDone = (selectedSubcategory) => {
    setModalVisibility({
      ...modalVisibility,
      subcategorySelectorModal: false,
    });
    const { categoryList, setPreferences } = props;

    const selectedCategoryById = getCategoryById(categoryList, categoryId);

    const subCategories =
      selectedCategoryById &&
      selectedCategoryById.sub_tags &&
      selectedCategoryById.sub_tags.length &&
      selectedCategoryById.sub_tags.map((subTags) => {
        return {
          sub_tag_id: subTags.id,
          is_selected: selectedSubcategory.includes(subTags.id),
        };
      });

    setPreferences(categoryId, subCategories);
  };

  return (
    <Play
      {...props}
      getNextQuestion={getNextQuestion}
      timePeriod="play"
      categoryId={categoryId}
      onBack={onBack}
      handleSubcategoryChangeDone={handleSubcategoryChangeDone}
      modalVisibility={modalVisibility}
      setModalVisibility={setModalVisibility}
      isAnswerSubmitted={isAnswerSubmitted}
      setIsAnswerSubmitted={setIsAnswerSubmitted}
      isHintVisible={isHintVisible}
      setIsHintVisible={setIsHintVisible}
      isSolutionVisible={isSolutionVisible}
      setIsSolutionVisible={setIsSolutionVisible}
      answer={answer}
      setAnswer={setAnswer}
      message={message}
      setMessage={setMessage}
      answerStatus={answerStatus}
      setAnswerStatus={setAnswerStatus}
      questionAttemptTimer={questionAttemptTimer}
      setQuestionAttemptTimer={setQuestionAttemptTimer}
    />
  );
};

export default Practise;
