import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import './index.css';

const SearchBox = (props) => {
  return (
    <div className="search-box">
      <TextField
        className="search-box__input"
        placeholder="Search…"
        value={props.value}
        onChange={props.handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="search-box__icon" color="primary" />
            </InputAdornment>
          ),
          style: {
            height: '40px',
          },
        }}
      />
    </div>
  );
};

export default SearchBox;
