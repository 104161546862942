import { Box, Button, Hidden, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SegmentedProgressBar from '../PlayProgress/SegmentedProgressBar';
import { Link } from 'react-router-dom';
import author from './images/author.svg';
import {
  DifficultyRow,
  PlayAnswerDetailsChangeSubcategory,
  PlayAnswerDetailsChangeTypo,
  QuestionPreference,
} from '../PlayAnswerDetails/PlayAnswerDetailsStyledComponent';
import {
  NegativeScore,
  PositiveScore,
  QuestionDetail,
  QuestionDetailWithImg,
  StreakItemsMobile,
  StyledBottomButton,
  StyledCustomBadges,
  StyledDateAttempted,
  StyledWrapperContainer,
} from './PlayStyledComponent';
import moment from 'moment';
import { getQuestionTypeName } from '../PlayAnswerDetails';

function PlayDetailsMobile({
  question,
  examType,
  tag,
  competitionAnalytics,
  handleSubcategoryChange,
  preferences,
  competition,
}) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [showCategoryName, setShowCategoryName] = useState(false);
  const { data } = question;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const selectedCategoryId = data.tag_id ?? null;
  const selectedCategory = tag.allTags.find(
    (tag) => tag.id === selectedCategoryId,
  );

  let selectedSubcategories = [];

  if (selectedCategory && selectedCategory.sub_tags && data.sub_tag_array) {
    selectedSubcategories = selectedCategory.sub_tags.filter(
      (subTag) => data.sub_tag_array.indexOf(subTag.id) !== -1,
    );
  }

  const trimmedCategoryName = `${selectedCategory?.name?.slice(0, 18)}${
    selectedCategory?.name?.length > 18 ? '...' : ''
  }`;

  const getSelectedTopics = () => {
    const arr = preferences
      ?.filter((choice) => choice.is_selected)
      ?.map((choice, index) => (
        <QuestionPreference
          key={index}
          size="small"
          label={choice.name}
          color="success"
          variant="outlined"
        />
      ));
    if (arr?.length > 0) return arr;
    return preferences?.map((choice, index) => (
      <QuestionPreference
        key={index}
        size="small"
        label={choice.name}
        color="success"
        variant="outlined"
      />
    ));
  };

  return (
    <Hidden mdUp>
      <StreakItemsMobile>
        {examType !== 'competition' && (
          <div
            className="category-name-box"
            style={{
              opacity:
                showCategoryName && selectedCategory?.name?.length > 18 ? 1 : 0,
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              sx={{ fontWeight: '700' }}
            >
              {selectedCategory?.name}
            </Typography>
          </div>
        )}

        <div className="streak-item">
          {examType !== 'competition' && examType !== 'play' && (
            <Typography variant="body2" color="primary">
              Score {''} &nbsp;{' '}
              <PositiveScore>+{question.data.weightage}</PositiveScore>/
              <NegativeScore>
                {(question.data.negative_score || 0).toFixed(2)}
              </NegativeScore>
            </Typography>
          )}

          {examType !== 'competition' && examType !== 'play' && (
            <Typography
              variant="body2"
              color="primary"
              onMouseEnter={() => setShowCategoryName(true)}
              onMouseLeave={() => setShowCategoryName(false)}
            >
              Category{' '}
              <span style={{ fontWeight: '700' }}>{trimmedCategoryName}</span>
            </Typography>
          )}
        </div>

        {examType === 'competition' && (
          <div className="">
            <StyledDateAttempted>
              <StyledWrapperContainer>
                <Typography variant="body2" fontSize={12}>
                  End Date:
                </Typography>
                <Typography variant="body2" fontWeight={'600'} fontSize={12}>
                  {moment(competition.end_date).format('Do MMMM YYYY')}
                </Typography>
              </StyledWrapperContainer>

              <StyledWrapperContainer>
                <Typography variant="caption" fontSize={12}>
                  Attempted:
                </Typography>
                <Typography variant="body2" fontWeight={'600'} fontSize={12}>
                  {competitionAnalytics.total_attempted}/
                  {competitionAnalytics.total_competition_question_count}
                </Typography>
              </StyledWrapperContainer>
            </StyledDateAttempted>
          </div>
        )}

        {examType !== 'competition' && examType !== 'mockTest' && (
          <div className="streak-item">
            <div className="streak-spree streak">
              <Typography variant="body2" color="primary" fontSize={12}>
                Streak&nbsp;
              </Typography>
              {isMobile ? (
                <SegmentedProgressBar
                  strokeColor="#257B66"
                  trailColor="#E9ECEF"
                  value={(question.streakCount * 100) / question.streakTarget}
                  attempt={question.streakCount}
                  total={question.streakTarget}
                  width="120px"
                  isStreak={true}
                />
              ) : (
                <SegmentedProgressBar
                  strokeColor="#257B66"
                  trailColor="#E9ECEF"
                  value={(question.streakCount * 100) / question.streakTarget}
                  attempt={question.streakCount}
                  total={question.streakTarget}
                  width="140px"
                  isStreak={true}
                />
              )}
            </div>
            <div className="streak-spree spree">
              <Typography variant="body2" color="primary" fontSize={12}>
                Spree&nbsp;
              </Typography>
              {isMobile ? (
                <SegmentedProgressBar
                  strokeColor="#65257B"
                  trailColor="#E9ECEF"
                  value={
                    (question.streakDayCount * 100) / question.streakDayTarget
                  }
                  attempt={question.streakDayCount}
                  total={question.streakDayTarget}
                  width="120px"
                />
              ) : (
                <SegmentedProgressBar
                  strokeColor="#65257B"
                  trailColor="#E9ECEF"
                  value={
                    (question.streakDayCount * 100) / question.streakDayTarget
                  }
                  attempt={question.streakDayCount}
                  total={question.streakDayTarget}
                  width="140px"
                />
              )}
            </div>
          </div>
        )}

        {isDetailsVisible && !question?.error && (
          <Box display="flex" flexDirection="column" padding="10px 15px">
            {examType === 'competition' && (
              <>
                <QuestionDetail>
                  <Typography variant="caption">Author</Typography>
                  <Link
                    to={`/profile/${question.data.created_by_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <img src={author} alt="" width={14} height={14} />
                      {question.data.created_by_name}
                    </Typography>
                  </Link>
                </QuestionDetail>
                <QuestionDetail>
                  <Typography variant="caption">Category</Typography>
                  <Typography variant="body2" color="primary">
                    <StyledCustomBadges
                      style={{
                        backgroundColor: '#25507B1A',
                      }}
                    >
                      <img
                        src={selectedCategory?.s3_url}
                        alt="cat-icon"
                        width={14}
                        height={14}
                      />
                      {selectedCategory && selectedCategory?.name}
                    </StyledCustomBadges>
                  </Typography>
                </QuestionDetail>
                <QuestionDetail>
                  <Typography variant="caption">Sub Categories</Typography>
                  <Typography variant="body2" color="primary">
                    {selectedSubcategories.map((item, index) => (
                      <StyledCustomBadges
                        key={index}
                        style={{ backgroundColor: '#25507B1A' }}
                      >
                        {item.name}
                      </StyledCustomBadges>
                    ))}
                  </Typography>
                </QuestionDetail>
                <QuestionDetailWithImg>
                  <Typography variant="caption">Question Type</Typography>
                  <Typography variant="body2" color="primary">
                    {getQuestionTypeName(question?.data?.question_type)}
                  </Typography>
                </QuestionDetailWithImg>
              </>
            )}

            {examType === 'play' && (
              <>
                <QuestionDetail>
                  <Typography variant="caption">Author</Typography>
                  <Link
                    to={`/profile/${question.data.created_by_username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2px',
                      }}
                    >
                      <img src={author} alt="" width={14} height={14} />
                      {question.data.created_by_name}
                    </Typography>
                  </Link>
                </QuestionDetail>
                <QuestionDetailWithImg>
                  <Typography variant="caption">Question Type</Typography>
                  <Typography variant="body2" color="primary">
                    {getQuestionTypeName(question?.data?.question_type)}
                  </Typography>
                </QuestionDetailWithImg>
              </>
            )}

            {examType !== 'competition' && examType !== 'mockTest' && (
              <>
                <QuestionDetail>
                  <PlayAnswerDetailsChangeTypo variant="h6" gutterBottom>
                    <span style={{ color: 'black' }}>Selected Topics</span>
                    <a onClick={handleSubcategoryChange}>
                      <PlayAnswerDetailsChangeSubcategory />
                    </a>
                  </PlayAnswerDetailsChangeTypo>
                </QuestionDetail>
                <DifficultyRow>{getSelectedTopics()}</DifficultyRow>
              </>
            )}
          </Box>
        )}

        <StyledBottomButton
          style={{
            backgroundColor: '#3572B01A',
          }}
        >
          <Button
            endIcon={isDetailsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            size="small"
            variant="text"
            color="primary"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
            style={{ marginTop: '5px', fontSize: '10px', padding: '0px' }}
          >
            {isDetailsVisible ? 'View Less' : 'View More'}
          </Button>
        </StyledBottomButton>
      </StreakItemsMobile>
    </Hidden>
  );
}

export default PlayDetailsMobile;
