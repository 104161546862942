import { styled } from '@mui/styles';

export const StyledForgotPassForm = styled('form')({
  '& .login-link': {
    textAlign: 'center',
    paddingTop: '20px',
  },
  '& .otp-input-container': {
    display: 'flex',
    alignItems: 'flex-end',
  },
  '& .text-field': {
    '&.reset-password-container': {
      marginBottom: '10px !important',
    },
  },
});
