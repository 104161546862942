import { styled } from '@mui/styles';
import { Container, Grid, Paper } from '@mui/material';
import Box from '@mui/material/Box';

export const ContainerWrapper = styled(Container)({
  padding: 0,
});

export const StyledPaper = styled(Paper)({
  padding: '20px',
});

export const GridContainer = styled(Grid)({
  spacing: 6,
});

export const FeaturesHeading = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '22px',
  fontWeight: 600,
  lineHeight: 'normal',
}));

export const FeaturesGrid = styled(Grid)({
  '&:hover': {
    cursor: 'pointer',
  },
});

export const FeaturesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '15px',
  alignItems: 'center',
  gap: '15px',
  flexShrink: 0,
  borderRadius: '5px',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  marginTop: '15px',

  '& .firstpinicon': {
    display: 'none',
  },
  '&:hover': {
    '.firstpinicon': {
      display: 'inline',
    },
  },
}));

export const FeaturesName = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: 'normal',
}));

export const TextPinWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const PinSetter = styled(Box)({
  '&:hover': {
    '.firstpinicon': {
      display: 'none',
    },
    '.secondpinicon': {
      display: 'inline',
    },
  },
});

export const FirstPinIcon = styled('img')({
  display: 'none',
  width: '25px',
  '&:hover': {
    display: 'none',
  },
});

export const SecondPinIcon = styled('img')({
  display: 'inline',
  width: '23px',
});

export const ThirdPinIcon = styled('img')({
  width: '23px',
});
