import { styled } from '@mui/styles';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Icon,
  TablePagination,
  Typography,
} from '@mui/material';

// ------- Author Profile tab ----------
export const AnalyticsContainer = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
});

export const AnalyticsValue = styled(Typography)({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '20px',
});

export const AnalyticsName = styled(Typography)({
  fontSize: '12px',
});

export const AnalyticsBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
  background: theme.palette.common.background,
  width: '130px',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('450')]: {
    width: '110px',
  },
}));

export const TagBoxContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginTop: '20px',
  gap: '25px',
  [theme.breakpoints.down(450)]: {
    display: 'none',
  },
}));

export const TagBoxContainerSmall = styled(Grid)(({ theme }) => ({
  maxWidth: '95vw',
  marginTop: '10px',
  [theme.breakpoints.up(450)]: {
    display: 'none',
  },
}));

// --------Calender-------------

export const CalenderContainer = styled(Grid)({
  height: '100%',
  paddingRight: '10px',
  display: 'flex',
  gap: '10px',
});

export const CalenderDaysContainer = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  gap: '10px',
});

export const CalenderDay = styled(Typography)({
  height: '20px',
  textAlign: 'end',
  fontSize: '12px',
});

export const CalenderDatesContainer = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  gap: '10px',
});

export const CalenderDate = styled(Typography)({
  height: '20px',
  width: '20px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
});

export const CalenderBadgeTooltip = styled(Grid)({
  background: 'white',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
});

// ----------- PracticeTagBox --------------
export const PracticeContainer = styled(Grid)(({ theme }) => ({
  padding: '20px 10px',
  minWidth: '300px',
  maxWidth: '350px',
  boxShadow: `0px 0px 4px 0px ${theme.palette.component.shadowColor}`,
  borderRadius: '5px',
  [theme.breakpoints.down(450)]: {
    margin: '10px auto',
  },
}));

export const PracticeTopContainer = styled(Grid)({
  display: 'flex',
  padding: '0px 10px',
});

export const PracticeHeadingContainer = styled(Box)({
  marginLeft: '10px',
});

export const PracticeHeading = styled(Grid)({
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '95%',
  maxWidth: '220px',
});

export const PracticeLevel = styled(Chip)({
  height: '20px',
  marginBottom: '5px',
  fontWeight: '500',
  background: 'rgba(53, 114, 176, 0.10)', //no match
  border: 'none',
  padding: '5px',
});

export const PracticeAnalyticsContainer = styled(Grid)({
  display: 'flex',
  gap: '15px',
  padding: '0px 10px',
});

export const PracticeAnalyticsBox = styled(Grid)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
  background: theme.palette.common.background,
  width: '100%',
  height: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  textAlign: 'center',
  padding: '10px 0px',
}));

export const PracticeAnalyticsValue = styled(Typography)({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '15px',
});

export const PracticeAnalyticsName = styled(Typography)({
  fontSize: '12px',
  lineHeight: '15px',
});

export const PracticeStatisticsContainer = styled(Grid)({
  marginTop: '10px',
});

export const PracticeStatText = styled(Typography)({
  fontSize: '18px',
  fontWeight: '400',
  margin: '10px 0px',
});

export const PracticeBadgeContainer = styled(Grid)({
  margin: '10px 0 20px',
  padding: '0px 10px',
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  minHeight: '40px',
});

export const PracticeCompetitionTags = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.background,
  color: theme.palette.primary.main,
  borderRadius: '5px',
  padding: '3px',
  height: '25px',
  maxWidth: '100px',
  fontSize: '12px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const PracticeSubtitleTextContainer = styled(Box)({
  display: 'flex',
  gap: '15px',
});

export const PracticeSubtitleText = styled(Grid)({
  fontSize: '10px',
  display: 'flex',
  alignItems: 'center',
});

export const PracticeDoughnutContainer = styled(Grid)({
  display: 'flex',
  gap: '15px',
});

export const PracticeDoughnutActivityContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
});

export const PracticeDoughnutColorsBox = styled(Typography)({
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: 'white',
});

export const PracticeColorContainer = styled(Box)({
  display: 'flex',
  gap: '5px',
  flexWrap: 'wrap',
});

export const PracticeBadgeTooltip = styled(Grid)({
  width: '260px',
  height: '130px',
  background: 'white',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
});

export const PracticeTooltipHeading = styled(Typography)({
  fontWeight: '500',
  fontSize: '15px',
  textTransform: 'capitalize',
});

export const PracticeTooltipText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.common.lightCaption,
}));

export const PracticeTooltipSubtitle = styled(Typography)({
  fontSize: '10px',
});

export const PracticeBadgeCountContainer = styled(Box)({
  position: 'absolute',
  top: '0px',
  right: '0px',
  zIndex: '10',
  borderRadius: '50%',
  padding: '2px',
  background: 'white',
  width: '16px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PracticeBadgeCountText = styled(Grid)(({ theme }) => ({
  zIndex: '10',
  fontSize: '10px',
  fontWeight: '500',
  background: theme.palette.primary.main,
  borderRadius: '50%',
  color: 'white',
  minWidth: '14px',
  height: '14px',
  textAlign: 'center',
}));

export const PracticeExtraBadgeText = styled(Typography)({
  position: 'absolute',
  zIndex: '10',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '14px',
  fontWeight: '600',
});

export const PracticeExtraBadgeContainer = styled(Box)({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#d2d0d0c7', //no match
  zIndex: '5',
  position: 'absolute',
  top: '0px',
});

// --------- referral component -------------

export const SendIconImg = styled('img')(() => ({
  display: 'block',
  position: 'relative',
  top: '-45px',
  marginLeft: 'auto',
  paddingRight: '5px',
  cursor: 'pointer',
}));

export const ReferralEarned = styled(Grid)(() => ({
  alignItems: 'center',
}));

export const TotalCreditsEarned = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const TotalCreditsEarnedSmall = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const Hidden = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none !important',
  },
}));

export const Show = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'inline',
    padding: '0',
  },
}));

export const HeaderText = styled('span')(({ theme }) => ({
  paddingLeft: '20px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '10px',
  },
}));

export const CenteredDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
});

export const SmallText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    fontSize: '10px',
  },
}));

export const ReferralTableWrapper = styled(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: 'white',
  width: '100%',
}));

export const TableHeader = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '30px',
  marginBottom: '15px',
  background: '#3572B0',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '46px',
  },
}));

export const TableHeaderText = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',
  letterSpacing: '0.4px',
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
  },
}));

export const TableBodyWrapper = styled(Grid)(() => ({
  width: '100%',
  gap: '12px',
  backgroundColor: 'white',
  marginBottom: '10px',
}));

export const TableRow = styled(Grid)(() => ({
  borderRadius: '5px',
  background: '#FFF',
  width: '100%',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.40)',
  height: '50px',
  alignItems: 'center',
}));

export const TableRowItem = styled(Grid)(({ theme }) => ({
  padding: '.5rem',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export const TableRowItemText = styled(Typography)(({ theme }) => ({
  color: '#25507B',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '100%',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}));

export const Chips = styled(Chip)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
  },
}));

export const Tablepagination = styled(TablePagination)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},
}));
export const EarnedCreditsTableRowItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  padding: '.5rem',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    display: 'none !important',
  },
}));

export const ResponsiveTypographyGrid = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'inline',
    overflow: 'scroll',
  },
}));

// ---------- userActivity ---------
export const ActivityContainer = styled(Grid)(({ theme }) => ({
  padding: '10px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 15px',
    width: '100%',
  },
}));

export const ActivityActionBtnContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});

export const ActivityActionBtn = styled(Typography)(({ theme }) => ({
  margin: '0px 10px',
  fontSize: '15px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    fontSize: '17px',
  },
}));

export const ActivityActiveBtn = styled(Typography)({
  fontWeight: '600',
});

export const ActivityCalenderContainer = styled(Grid)(({ theme }) => ({
  margin: '10px 0px',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-around',
  },
}));

export const ActivityCalender = styled(Grid)(({ theme }) => ({
  minWidth: '230px',
  height: '210px',
  borderRight: '0.5px solid',
  borderColor: theme.palette.secondary.main,
}));

export const ActivityOverallActivity = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px 0px 4px',
});

export const ActivityImageContainer = styled(Box)({
  width: '30px',
  height: '30px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActivityImgText = styled(Typography)({
  fontSize: '10px',
  position: 'absolute',
  zIndex: 10,
  fontWeight: '500',
  color: 'black',
  background: '#E6E6E6', //no match
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActivityContainers = styled(Box)({
  margin: '4px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const ActivityText = styled(Typography)({
  fontSize: '12px',
  textAlign: 'end',
});

export const ActivityColorRectContainer = styled(Grid)({
  display: 'flex',
  gap: '6px',
});

export const ActivityColorShadeContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  marginLeft: '10px',
  marginTop: '5px',
});

export const ActivityColorRect = styled(Box)({
  width: '15px',
  height: '15px',
});

export const ActivitySubtitleTextContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
});

export const ActivitySubtitleText = styled(Typography)({
  fontSize: '10px',
});

// ------------- user Card ------------
export const UCardContainer = styled(Grid)({
  background: 'white',
  padding: '15px',
  display: 'flex',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
  borderRadius: '10px',
  minHeight: '220px',
  overflow: 'auto',
  flexGrow: 1,
  position: 'relative',
});

export const UCardAvatarContainer = styled(Grid)({
  maxWidth: '100px',
  width: '70px',
  textAlign: 'center',
});

export const UCardAvatar = styled(Avatar)({
  width: '60px',
  height: '60px',
});

export const UCardContentContainer = styled(Grid)({
  padding: '0px 15px',
});

export const UCardName = styled(Typography)({
  fontWeight: '600',
  fontSize: '24px',
});

export const UCardAbout = styled(Typography)({
  marginTop: '15px',
  marginBottom: '60px',
});

export const UCardScore = styled(Typography)({
  fontWeight: '600',
  marginTop: '4px',
  fontSize: '18px',
});

export const UCardScoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.lightCaption,
  fontSize: '12px',
  lineHeight: '8px',
}));

export const UCardUsernameContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '15px',
});

export const UCardUsername = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.lightCaption,
  fontSize: '12px',
}));

export const UCardCopyIcon = styled(Icon)({
  marginLeft: '5px',
  cursor: 'pointer',
});

export const UCardBtnStyle = styled(Button)({
  float: 'left',
  minWidth: '80px',
  position: 'absolute',
  bottom: '20px',
});

// ----------- userProfileInfo -------------
export const UPIWrapper = styled(Grid)(({ theme }) => ({
  padding: '0px 20px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: '0px 10px',
    maxWidth: '100vw',
  },
}));

export const UPITopContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const UPIUserCardContainer = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const UPIBadgesWrapper = styled('div')({
  marginTop: '10px',
  marginBottom: '5px',
});

export const UPIBadgesContainer = styled('div')({
  background: 'white',
  width: '100%',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  padding: '10px 15px',
});

export const UPIBadgeCountContainer = styled('div')({
  textAlign: 'center',
});

export const UPIBadgeCount = styled('div')({
  fontSize: '24px',
  fontWeight: '600',
  lineHeight: '25px',
});

export const UPISubtitleText = styled('div')(({ theme }) => ({
  color: theme.palette.common.lightCaption,
  fontSize: '12px',
}));

export const UPICalendar = styled(Grid)(({ theme }) => ({
  minWidth: '325px',
  width: '325px',
  height: '310px',
  background: 'white',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
  borderRadius: '10px',
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('450')]: {
    display: 'block',
    width: '100%',
  },
}));

export const UPIDomainSelectContainer = styled(Box)(({ theme }) => ({
  width: '250px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '15px',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '15px',
    width: '93%',
  },
}));

export const UPIBottomContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  width: '100%',
});

export const UPITabBtnsWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}));

export const UPITabBtnsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowY: 'clip',
  width: 'fit-content',
  [theme.breakpoints.down('450')]: {
    width: '100%',
  },
}));

export const UPITabBtn = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  padding: '10px 0',
  cursor: 'pointer',
  zIndex: 10,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('450')]: {
    fontSize: '16px',
    padding: '5px 0px',
    flexGrow: '1',
  },
}));

export const UPITabText = styled(Box)(({ theme }) => ({
  padding: '5px 30px',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('450')]: {
    fontSize: '16px',
    padding: '5px 10px',
  },
}));

export const UPITabActiveBtn = styled(Box)({
  background: 'white',
  fontWeight: '600',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
  marginTop: '2px',
});

export const UPITabInactiveBtn = styled(Box)({
  fontWeight: '400',
});

export const UPITab = styled(Grid)(({ theme }) => ({
  padding: '20px 25px',
  background: 'white',
  borderRadius: '10px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
  [theme.breakpoints.down('md')]: {
    padding: '20px 15px',
  },
}));

export const UPIBorderRight = styled('span')(({ theme }) => ({
  borderRight: '1px solid',
  borderColor: theme.palette.primary.main,
}));

export const UPIBorderLeft = styled('span')(({ theme }) => ({
  borderLeft: '1px solid',
  borderColor: theme.palette.primary.main,
}));
