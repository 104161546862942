import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../../config';

export default function NoCreditDialog({
  category = {},
  visible = false,
  onClose,
  credits,
}) {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="confirm-category-unlock"
      aria-describedby="Unlock category"
    >
      <DialogTitle id="alert-dialog-title">Insufficient Credits</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Oops! You are running low on Abekus credits. You need{' '}
          {category.category_unlock_credits
            ? Math.abs(credits - Math.abs(category.category_unlock_credits))
            : ''}{' '}
          more credits to unlock this category.{' '}
          <a
            href={`${config.WEBSITE_URL}/help-center/credits.html`}
            target="_blank"
            rel="noreferrer"
          >
            How do I earn Credits?
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
