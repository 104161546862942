import axios from 'axios';
import config from './../config';
import * as types from './types';
import apiClient from '../api/apiclient';

export function getCollegeStart() {
  return {
    type: types.GET_COLLEGE_START,
  };
}

export function getCollegeSuccess({ data }) {
  return {
    type: types.GET_COLLEGE_SUCCESS,
    data: data,
  };
}

export function getCollegeFail() {
  return {
    type: types.GET_COLLEGE_FAIL,
  };
}

export function getCollegeDetails(
  searchTerm,
  page,
  pageSize,
  status,
  sortBy = 'name',
  sortOrder = 'asc',
  callback,
) {
  return (dispatch, getState) => {
    if (!callback) dispatch(getCollegeStart());
    const { auth } = getState();

    axios
      .get(
        `${
          config.API_URL
        }/admin/college?college_name=${searchTerm}&page_no=${page}&page_size=${pageSize}&status=${
          status === undefined ? '' : status
        }&sort_by=${sortBy}&sort_order=${sortOrder}`,
        {
          headers: {
            'Content-Type': 'application/json',
            token: auth.token,
          },
        },
      )
      .then((resp) => {
        if (callback) callback(resp.data);
        else dispatch(getCollegeSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getCollegeFail());
        throw ex;
      });
  };
}

export function postCollegeStart() {
  return {
    type: types.POST_COLLEGE_START,
  };
}

export function postCollegeSuccess({ data }) {
  return {
    type: types.POST_COLLEGE_SUCCESS,
    data: data,
  };
}

export function postCollegeFail() {
  return {
    type: types.POST_COLLEGE_FAIL,
  };
}

export function addCollegeDetails(data, searchTerm, status, page, rowsPerPage) {
  return (dispatch, getState) => {
    dispatch(postCollegeStart());
    const { auth } = getState();
    axios
      .post(`${config.API_URL}/admin/college`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(postCollegeSuccess({ data: resp.data }));
        dispatch(getCollegeDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(postCollegeFail());
        throw ex;
      });
  };
}

export function updateCollegeStart() {
  return {
    type: types.UPDATE_COLLEGE_START,
  };
}

export function updateCollegeSuccess({ data }) {
  return {
    type: types.UPDATE_COLLEGE_SUCCESS,
    data: data,
  };
}

export function updateCollegeFail() {
  return {
    type: types.UPDATE_COLLEGE_FAIL,
  };
}

export function updateCollegeDetails(
  data,
  id,
  status,
  searchTerm,
  page,
  rowsPerPage,
  callback,
) {
  return (dispatch, getState) => {
    dispatch(updateCollegeStart());
    const { auth } = getState();
    axios
      .put(`${config.API_URL}/admin/college/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(updateCollegeSuccess({ data: resp.data }));
        if (callback) callback();
        dispatch(getCollegeDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(updateCollegeFail());
        throw ex;
      });
  };
}

export function deleteCollegeStart() {
  return {
    type: types.DELETE_COLLEGE_START,
  };
}

export function deleteCollegeSuccess({ data }) {
  return {
    type: types.DELETE_COLLEGE_SUCCESS,
    data: data,
  };
}

export function deleteCollegeFail() {
  return {
    type: types.DELETE_COLLEGE_FAIL,
  };
}

export function deleteCollege(
  deleteBody,
  status,
  searchTerm,
  page,
  rowsPerPage,
) {
  return (dispatch) => {
    dispatch(deleteCollegeStart());

    apiClient
      .getUserApi()
      .userApiDeleteCollege(deleteBody)
      .then((resp) => {
        dispatch(deleteCollegeSuccess({ data: resp.data }));
        dispatch(getCollegeDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(deleteCollegeFail());
        throw ex;
      });
  };
}

export function getCompanyStart() {
  return {
    type: types.GET_COMPANY_START,
  };
}

export function getCompanySuccess({ data }) {
  return {
    type: types.GET_COMPANY_SUCCESS,
    data: data,
  };
}

export function getCompanyFail() {
  return {
    type: types.GET_COMPANY_FAIL,
  };
}

export function getCompanyDetails(
  searchTerm,
  page,
  pageSize,
  status,
  sortBy = 'name',
  sortOrder = 'asc',
  callback,
) {
  return (dispatch, getState) => {
    if (!callback) dispatch(getCompanyStart());
    const { auth } = getState();
    axios
      .get(
        `${
          config.API_URL
        }/admin/company?company_name=${searchTerm}&page_no=${page}&page_size=${pageSize}&status=${
          status === undefined ? '' : status
        }&sort_by=${sortBy}&sort_order=${sortOrder}`,
        {
          headers: {
            'Content-Type': 'application/json',
            token: auth.token,
          },
        },
      )
      .then((resp) => {
        if (callback) callback(resp.data);
        else dispatch(getCompanySuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getCompanyFail());
        throw ex;
      });
  };
}

export function postCompanyStart() {
  return {
    type: types.POST_COMPANY_START,
  };
}

export function postCompanySuccess({ data }) {
  return {
    type: types.POST_COMPANY_SUCCESS,
    data: data,
  };
}

export function postCompanyFail() {
  return {
    type: types.POST_COMPANY_FAIL,
  };
}

export function addCompanyDetails(data, searchTerm, status, page, rowsPerPage) {
  return (dispatch, getState) => {
    dispatch(postCompanyStart());
    const { auth } = getState();
    axios
      .post(`${config.API_URL}/admin/company`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(postCompanySuccess({ data: resp.data }));
        dispatch(getCompanyDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(postCompanyFail());
        throw ex;
      });
  };
}

export function updateCompanyStart() {
  return {
    type: types.UPDATE_COMPANY_START,
  };
}

export function updateCompanySuccess({ data }) {
  return {
    type: types.UPDATE_COMPANY_SUCCESS,
    data: data,
  };
}

export function updateCompanyFail() {
  return {
    type: types.UPDATE_COMPANY_FAIL,
  };
}

export function updateCompanyDetails(
  data,
  id,
  searchTerm,
  page,
  rowsPerPage,
  status,
  callback,
) {
  return (dispatch, getState) => {
    dispatch(updateCompanyStart());
    const { auth } = getState();
    axios
      .put(`${config.API_URL}/admin/company/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(updateCompanySuccess({ data: resp.data }));
        if (callback) callback();
        dispatch(getCompanyDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(updateCompanyFail());
        throw ex;
      });
  };
}

export function deleteCompanyStart() {
  return {
    type: types.DELETE_COMPANY_START,
  };
}

export function deleteCompanySuccess({ data }) {
  return {
    type: types.DELETE_COMPANY_SUCCESS,
    data: data,
  };
}

export function deleteCompanyFail() {
  return {
    type: types.DELETE_COMPANY_FAIL,
  };
}

export function deleteCompany(
  deleteBody,
  searchTerm,
  page,
  rowsPerPage,
  status,
) {
  return (dispatch) => {
    dispatch(deleteCompanyStart());

    return apiClient
      .getUserApi()
      .userApiDeleteCompany(deleteBody)
      .then((resp) => {
        dispatch(getCompanyDetails(searchTerm, page, rowsPerPage, status));
        return dispatch(deleteCompanySuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(deleteCompanyFail());
        throw ex;
      });
  };
}

export function getExamStart() {
  return {
    type: types.GET_EXAM_START,
  };
}

export function getExamSuccess({ data }) {
  return {
    type: types.GET_EXAM_SUCCESS,
    data: data,
  };
}

export function getExamFail() {
  return {
    type: types.GET_EXAM_FAIL,
  };
}

let getExamsCancelToken = null;
export function getExamDetails(
  searchTerm,
  page,
  pageSize,
  status,
  sortBy = 'name',
  sortOrder = 'asc',
  callback,
) {
  return (dispatch) => {
    if (!callback) dispatch(getExamStart());
    if (getExamsCancelToken) {
      getExamsCancelToken.cancel();
    }

    getExamsCancelToken = axios.CancelToken.source();
    apiClient
      .getExamApi()
      .examApiGetExamDetail(
        undefined,
        page,
        pageSize,
        sortBy,
        sortOrder,
        status === '' ? undefined : status,
        searchTerm,
      )
      .then((resp) => {
        if (callback) callback(resp.data);
        else dispatch(getExamSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getExamFail());
        throw ex;
      });
  };
}

export function postExamStart() {
  return {
    type: types.POST_EXAM_START,
  };
}

export function postExamSuccess({ data }) {
  return {
    type: types.POST_EXAM_SUCCESS,
    data: data,
  };
}

export function postExamFail() {
  return {
    type: types.POST_EXAM_FAIL,
  };
}

export function addExamDetails(data, searchTerm, page, rowsPerPage, status) {
  return (dispatch) => {
    dispatch(postExamStart());
    apiClient
      .getExamApi()
      .examApiCreateExamDetail(data)
      .then((resp) => {
        dispatch(postExamSuccess({ data: resp.data }));
        dispatch(getExamDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(postExamFail());
        throw ex;
      });
  };
}

export function updateExamStart() {
  return {
    type: types.UPDATE_EXAM_START,
  };
}

export function updateExamSuccess({ data }) {
  return {
    type: types.UPDATE_EXAM_SUCCESS,
    data: data,
  };
}

export function updateExamFail() {
  return {
    type: types.UPDATE_EXAM_FAIL,
  };
}

export function updateExamDetails(
  data,
  id,
  searchTerm,
  page,
  rowsPerPage,
  status,
  callback,
) {
  return (dispatch) => {
    dispatch(updateExamStart());
    apiClient
      .getExamApi()
      .examApiUpdateExamDetail(id, data)
      .then((resp) => {
        dispatch(updateExamSuccess({ data: resp.data }));
        if (callback) callback();
        dispatch(getExamDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(updateExamFail());
        throw ex;
      });
  };
}

export function deleteExamStart() {
  return {
    type: types.DELETE_EXAM_START,
  };
}

export function deleteExamSuccess({ data }) {
  return {
    type: types.DELETE_EXAM_SUCCESS,
    data: data,
  };
}

export function deleteExamFail() {
  return {
    type: types.DELETE_EXAM_FAIL,
  };
}

export function deleteExam(deleteBody, searchTerm, page, rowsPerPage, status) {
  return (dispatch) => {
    dispatch(deleteExamStart());

    apiClient
      .getExamApi()
      .examApiDeleteExamDetail(deleteBody)
      .then((resp) => {
        dispatch(deleteExamSuccess({ data: resp.data }));
        dispatch(getExamDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(deleteExamFail());
        throw ex;
      });
  };
}

export function postImageStart() {
  return {
    type: types.POST_IMAGE_START,
  };
}

export function postImageSuccess({ data }) {
  return {
    type: types.POST_IMAGE_SUCESS,
    data: data,
  };
}

export function postImageFail() {
  return {
    type: types.POST_IMAGE_FAIL,
  };
}

export function uploadImage(data) {
  return (dispatch, getState) => {
    dispatch(postImageStart());
    const { auth } = getState();
    const bodyFormData = new FormData();
    bodyFormData.append('image_file', data);
    axios
      .post(`${config.API_URL}/admin/upload-misc-image`, bodyFormData, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(postImageSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postImageFail());
        throw ex;
      });
  };
}

export function getJobRoleStart() {
  return {
    type: types.GET_JOB_ROLE_START,
  };
}

export function getJobRoleSuccess({ data }) {
  return {
    type: types.GET_JOB_ROLE_SUCCESS,
    data: data,
  };
}

export function getJobRoleFail() {
  return {
    type: types.GET_JOB_ROLE_FAIL,
  };
}

let getJobRoleCancelToken = null;
export function getJobRoleDetails(
  searchTerm,
  page,
  pageSize,
  status,
  sortBy = 'name',
  sortOrder = 'asc',
  callback,
) {
  return (dispatch) => {
    if (!callback) dispatch(getJobRoleStart());
    if (getJobRoleCancelToken) {
      getJobRoleCancelToken.cancel();
    }

    getJobRoleCancelToken = axios.CancelToken.source();

    apiClient
      .getUserApi()
      .userApiGetJobRole(
        undefined,
        page,
        pageSize,
        sortBy,
        sortOrder,
        status,
        searchTerm,
      )
      .then((resp) => {
        if (callback) callback(resp.data);
        else dispatch(getJobRoleSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getJobRoleFail());
        throw ex;
      });
  };
}

export function postJobRoleStart() {
  return {
    type: types.POST_JOB_ROLE_START,
  };
}

export function postJobRoleSuccess({ data }) {
  return {
    type: types.POST_JOB_ROLE_SUCCESS,
    data: data,
  };
}

export function postJobRoleFail() {
  return {
    type: types.POST_JOB_ROLE_FAIL,
  };
}

export function addJobRoleDetails(data, searchTerm, page, rowsPerPage, status) {
  return (dispatch) => {
    dispatch(postJobRoleStart());
    apiClient
      .getUserApi()
      .userApiCreateJobRole(data)
      .then((resp) => {
        dispatch(postJobRoleSuccess({ data: resp.data }));
        dispatch(getJobRoleDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(postJobRoleFail());
        throw ex;
      });
  };
}

export function updateJobRoleStart() {
  return {
    type: types.UPDATE_JOB_ROLE_START,
  };
}

export function updateJobRoleSuccess({ data }) {
  return {
    type: types.UPDATE_JOB_ROLE_SUCCESS,
    data: data,
  };
}

export function updateJobRoleFail() {
  return {
    type: types.UPDATE_JOB_ROLE_FAIL,
  };
}

export function updateJobRoleDetails(
  data,
  id,
  searchTerm,
  page,
  rowsPerPage,
  status,
  callback,
) {
  return (dispatch) => {
    dispatch(updateJobRoleStart());
    apiClient
      .getUserApi()
      .userApiUpdateJobRole([data])
      .then((resp) => {
        dispatch(updateJobRoleSuccess({ data: resp.data }));
        if (callback) callback();
        dispatch(getJobRoleDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(updateJobRoleFail());
        throw ex;
      });
  };
}

export function deleteJobRoleStart() {
  return {
    type: types.DELETE_JOB_ROLE_START,
  };
}

export function deleteJobRoleSuccess({ data }) {
  return {
    type: types.DELETE_JOB_ROLE_SUCCESS,
    data: data,
  };
}

export function deleteJobRoleFail() {
  return {
    type: types.DELETE_JOB_ROLE_FAIL,
  };
}

export function deleteJobRole(
  deleteBody,
  searchTerm,
  page,
  rowsPerPage,
  status,
) {
  return (dispatch) => {
    dispatch(deleteJobRoleStart());

    return apiClient
      .getUserApi()
      .userApiDeleteJobRole(deleteBody)
      .then((resp) => {
        dispatch(getJobRoleDetails(searchTerm, page, rowsPerPage, status));
        return dispatch(deleteJobRoleSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(deleteJobRoleFail());
        throw ex;
      });
  };
}

const mergeDuplicatesStart = () => ({
  type: types.MERGE_DULTICATES_START,
});
const mergeDuplicatesSuccess = () => ({
  type: types.MERGE_DULTICATES_SUCCESS,
});
const mergeDuplicatesFail = () => ({
  type: types.MERGE_DULTICATES_FAIL,
});

export function mergeDuplicates(
  { main_entity_id, entity_type, duplicate_ids },
  callback,
) {
  return async (dispatch) => {
    dispatch(mergeDuplicatesStart());
    apiClient
      .getGeneralApi()
      .generalApiMergeSimilarOrDuplicateEnitites(
        { duplicate_ids: duplicate_ids },
        entity_type,
        main_entity_id,
      )
      .then(() => {
        dispatch(mergeDuplicatesSuccess());
        if (callback) callback();
      })
      .catch((err) => {
        dispatch(mergeDuplicatesFail());
        throw err;
      });
  };
}
export function getAdminSkillsStart() {
  return {
    type: types.GET_ADMIN_SKILLS_START,
  };
}

export function getAdminSkillsSuccess({ data }) {
  return {
    type: types.GET_ADMIN_SKILLS_SUCCESS,
    data: data,
  };
}

export function getAdminSkillsFail() {
  return {
    type: types.GET_ADMIN_SKILLS_FAIL,
  };
}

export function getAdminSkillsDetails(
  searchTerm,
  page,
  pageSize,
  status,
  sortBy = 'name',
  sortOrder = 'asc',
) {
  return (dispatch) => {
    dispatch(getAdminSkillsStart());
    apiClient
      .getJobApi()
      .jobApiAdminGetJobSkills(
        searchTerm,
        page,
        pageSize,
        sortBy,
        sortOrder,
        status === undefined ? '' : status,
        undefined,
      )
      .then((resp) => {
        dispatch(getAdminSkillsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getAdminSkillsFail());
        throw ex;
      });
  };
}

export function postAdminSkillsStart() {
  return {
    type: types.POST_ADMIN_SKILLS_START,
  };
}

export function postAdminSkillsSuccess({ data }) {
  return {
    type: types.POST_ADMIN_SKILLS_SUCCESS,
    data: data,
  };
}

export function postAdminSkillsFail() {
  return {
    type: types.POST_ADMIN_SKILLS_FAIL,
  };
}

export function addAdminSkillsDetails(
  body,
  searchTerm,
  page,
  rowsPerPage,
  status,
) {
  return (dispatch) => {
    dispatch(postAdminSkillsStart());
    apiClient
      .getJobApi()
      .jobApiCreateSkillBulk(body)
      .then((resp) => {
        dispatch(postAdminSkillsSuccess({ data: resp.data }));
        dispatch(getAdminSkillsDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(postAdminSkillsFail());
        throw ex;
      });
  };
}

export function updateAdminSkillsStart() {
  return {
    type: types.UPDATE_ADMIN_SKILLS_START,
  };
}

export function updateAdminSkillsSuccess({ data }) {
  return {
    type: types.UPDATE_ADMIN_SKILLS_SUCCESS,
    data: data,
  };
}

export function updateAdminSkillsFail() {
  return {
    type: types.UPDATE_ADMIN_SKILLS_FAIL,
  };
}

export function updateAdminSkillsDetails(
  body,
  id,
  searchTerm,
  page,
  rowsPerPage,
  status,
) {
  return (dispatch) => {
    dispatch(updateAdminSkillsStart());
    apiClient
      .getJobApi()
      .jobApiUpdateSkill(id, body)
      .then((resp) => {
        dispatch(updateAdminSkillsSuccess({ data: resp.data }));
        dispatch(getAdminSkillsDetails(searchTerm, page, rowsPerPage, status));
      })
      .catch((ex) => {
        dispatch(updateAdminSkillsFail());
        throw ex;
      });
  };
}
export function deleteAdminSkillsStart() {
  return {
    type: types.DELETE_ADMIN_SKILLS_START,
  };
}

export function deleteAdminSkillsSuccess({ data }) {
  return {
    type: types.DELETE_ADMIN_SKILLS_SUCCESS,
    data: data,
  };
}

export function deleteAdminSkillsFail() {
  return {
    type: types.DELETE_ADMIN_SKILLS_FAIL,
  };
}

export function deleteAdminSkills(
  deleteBody,
  searchTerm,
  page,
  rowsPerPage,
  status,
) {
  return (dispatch) => {
    dispatch(deleteAdminSkillsStart());
    return apiClient
      .getJobApi()
      .jobApiDeleteSkill(deleteBody)
      .then((resp) => {
        dispatch(getAdminSkillsDetails(searchTerm, page, rowsPerPage, status));
        return dispatch(deleteAdminSkillsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(deleteAdminSkillsFail());
        throw ex;
      });
  };
}
