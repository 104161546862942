import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getQuestionList,
  putQuestion,
  putReviewQuestion,
  deleteQuestion,
  postQuestion,
} from './../actions/question';
import Component from './../components/QuestionList';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getQuestionList,
      putQuestion,
      putReviewQuestion,
      deleteQuestion,
      postQuestion,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    question: state.question,
    tag: state.tag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
