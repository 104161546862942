import React from 'react';
import { Icon, Typography, Button } from '@mui/material';
import './index.scss';
import { useState } from 'react';

import pic1 from './images/pic1.svg';
import pic2 from './images/pic2.svg';
import pic3 from './images/pic3.svg';
import pic4 from './images/pic4.svg';
import pic5 from './images/pic5.svg';
import pic6 from './images/pic6.svg';

const EmptySlider = ({ AddQuestion }) => {
  const [slide, setSlide] = useState(1);

  const nextSlide = () => {
    if (slide < 4) {
      setSlide(slide + 1);
    } else {
      setSlide(4);
    }
  };

  const previousSlide = () => {
    if (slide > 1) {
      setSlide(slide - 1);
    } else {
      setSlide(1);
    }
  };

  const Slide = ({ index }) => {
    switch (index) {
      case 1:
        return (
          <>
            <div className="leftCtn">
              <img src={pic1} className="imgLeft" alt="empty image" />
            </div>
            <div className="rightCtn">
              <img src={pic2} className="imgRight" alt="empty image" />
              <div className="noteCtn">
                <Typography fontSize={'14px'} color="primary">
                  You can save the question as draft to edit and submit it
                  afterwards.
                </Typography>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'primary',
                  color: 'white',
                }}
                className="buttonStyle"
                onClick={() => nextSlide()}
              >
                Next
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="leftCtn">
              <img src={pic3} className="imgLeft" alt="empty image" />
            </div>
            <div className="rightCtn">
              <div className="textCtn">
                <Typography variant="h5" color="black">
                  Earn{' '}
                  <Typography
                    variant="h5"
                    color="secondary"
                    sx={{ display: 'inline-block' }}
                  >
                    Credits
                  </Typography>{' '}
                  and{' '}
                  <Typography
                    variant="h5"
                    color="secondary"
                    sx={{ display: 'inline-block' }}
                  >
                    Gems
                  </Typography>{' '}
                  for the accepted questions.
                </Typography>
              </div>
              <Button
                variant="contained"
                sx={{ backgroundColor: 'primary', color: 'white' }}
                className="buttonStyle"
                onClick={() => nextSlide()}
              >
                Next
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="leftCtn">
              <img src={pic4} className="imgLeft" alt="empty image" />
            </div>
            <div className="rightCtn">
              <div className="textCtn">
                <Typography variant="h5" color="black">
                  Rework on the rejected questions and resubmit them for review.
                </Typography>
              </div>
              <Button
                variant="contained"
                sx={{ backgroundColor: 'primary', color: 'white' }}
                className="buttonStyle"
                onClick={() => nextSlide()}
              >
                Next
              </Button>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="leftCtnFS">
              <img src={pic5} alt="empty image" />
            </div>
            <div className="rightCtnFS">
              <img src={pic6} alt="empty image" />
              <Button
                variant="contained"
                sx={{ backgroundColor: 'primary', color: 'white' }}
                onClick={() => AddQuestion()}
                className="buttonStyle"
              >
                Create Question
              </Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="EmptyImageSlider">
      <div className="sliderContainer">
        <div className="btnCtn">
          <div onClick={() => previousSlide()}>
            <Icon className={slide === 1 ? 'iconD' : 'icon'}>
              navigate_before
            </Icon>
          </div>
        </div>
        <div className="main">
          <Slide index={slide} />
        </div>
        <div className="btnCtn">
          <div onClick={() => nextSlide()}>
            <Icon className={slide === 4 ? 'iconD' : 'icon'}>
              navigate_next
            </Icon>
          </div>
        </div>
      </div>
      <div className="positionHolder">
        <div
          className={slide === 1 ? 'dotFIlled' : 'dot'}
          onClick={() => setSlide(1)}
        />
        <div
          className={slide === 2 ? 'dotFIlled' : 'dot'}
          onClick={() => setSlide(2)}
        />
        <div
          className={slide === 3 ? 'dotFIlled' : 'dot'}
          onClick={() => setSlide(3)}
        />
        <div
          className={slide === 4 ? 'dotFIlled' : 'dot'}
          onClick={() => setSlide(4)}
        />
      </div>
    </div>
  );
};

export default EmptySlider;
