import React from 'react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Branding from './../Branding';

import './index.scss';

const PublicAuthModal = ({ open = true, children, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="public-auth"
    >
      <Paper className="public-auth__content-wrapper">
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          className="btn-close"
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <div className="public-auth__header">
          <Branding />
        </div>
        {children}
      </Paper>
    </Modal>
  );
};

export default PublicAuthModal;
