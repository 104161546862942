import React, { Fragment } from 'react';
import { Button, Chip, Grid, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import StudentPlaceholder from './images/StudentPlaceholder.svg';
import { useState } from 'react';

const WeeklyTopper = ({ styles, leaderboardData }) => {
  const leaderboard = leaderboardData?.student_leaderboard;
  const { list } = useSelector((state) => state.domain);
  const [selectedDomain, setselectedDomain] = useState('');
  return (
    <div
      className={styles.containerStyle}
      style={{ maxHeight: '450px', overflow: 'auto', padding: 0 }}
    >
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 20px 5px 20px',
        }}
        spacing={2}
      >
        <Grid item md={4} sm={12}>
          <span className={styles.contentLeft_Heading1}>Weekly Topper</span>
        </Grid>
        <Grid item md={8} sm={12}>
          <Grid container>
            <Grid item xs={7}>
              <Select
                value={selectedDomain}
                onChange={(event) => setselectedDomain(event.target.value)}
                displayEmpty
                size="small"
                fullWidth
                sx={{
                  height: '25px',
                  borderRadius: '25px',
                  border: '1px solid #25507B',
                  color: '#25507B',
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem value={''} key={''}>
                  Choose Domain
                </MenuItem>
                {list.map((domain) => (
                  <MenuItem value={domain.id} key={domain.id}>
                    {domain.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" className={styles.invitationBoxBtn}>
                View All
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <hr style={{ border: '1px solid rgba(37, 80, 123, 0.1)' }} />
        <Grid
          container
          sx={{
            padding: '0px 20px',
          }}
        >
          <Grid item xs={2} className={styles.tableHeader}>
            Rank
          </Grid>
          <Grid item xs={6} className={styles.tableHeader}>
            Student
          </Grid>
          <Grid item xs={2} className={styles.tableHeader}>
            Score
          </Grid>
          <Grid item xs={2} className={styles.tableHeader}>
            Accuracy
          </Grid>
        </Grid>
        <hr style={{ border: '1px solid rgba(37, 80, 123, 0.1)' }} />
      </div>
      {leaderboard?.map((data, index) => (
        <Fragment key={index}>
          <div>
            <Grid
              container
              sx={{
                paddingLeft: '20px',
              }}
            >
              <Grid item xs={2} className={styles.tableRank}>
                {data?.rank}
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={data?.user?.picture || StudentPlaceholder}
                    alt="Profile Picture"
                    width={'50px'}
                    height={'50px'}
                    style={{ borderRadius: '50%' }}
                  />{' '}
                  &nbsp;&nbsp;
                  <div>
                    <span className={styles.studentName}>
                      {data?.user?.first_name} {data?.user?.last_name}
                    </span>
                    <Chip
                      className={styles.batch}
                      label={`Batch of ${data?.batch || 2023}`}
                      color="primary"
                      size="small"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} className={styles.tableRank}>
                {data?.score}
              </Grid>
              <Grid item xs={2} className={styles.tableRank}>
                {data?.percent_accuracy}%
              </Grid>
            </Grid>
          </div>
          <hr style={{ border: '1px solid rgba(37, 80, 123, 0.1)' }} />
        </Fragment>
      ))}
    </div>
  );
};

export default WeeklyTopper;
