import * as types from './types.js';
import apiClient from '../api/apiclient.ts';

function getAdminDegreeStart() {
  return {
    type: types.GET_ADMIN_DEGREE_START,
  };
}

function getAdminDegreeSuccess({ data }) {
  return {
    type: types.GET_ADMIN_DEGREE_SUCCESS,
    data: data,
  };
}

function getAdminDegreeFail() {
  return {
    type: types.GET_ADMIN_DEGREE_FAIL,
  };
}

export function getAdminDegree(searchInput, status, page, rowsPerPage) {
  return (dispatch) => {
    dispatch(getAdminDegreeStart());

    apiClient
      .getJobApi()
      .jobApiGetDegree(status, undefined, page, rowsPerPage, searchInput)
      .then((resp) => {
        dispatch(getAdminDegreeSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAdminDegreeFail());
      });
  };
}

export function postAdminDegreeStart() {
  return {
    type: types.POST_ADMIN_DEGREE_START,
  };
}
export function postAdminDegreeSuccess({ data }) {
  return {
    type: types.POST_ADMIN_DEGREE_SUCCESS,
    data: data,
  };
}

export function postAdminDegreeFail() {
  return {
    type: types.POST_ADMIN_DEGREE_FAIL,
  };
}
export function postAdminDegree(data) {
  return (dispatch) => {
    dispatch(postAdminDegreeStart());
    apiClient
      .getJobApi()
      .jobApiCreateDegree(data)
      .then((resp) => {
        dispatch(postAdminDegreeSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postAdminDegreeFail());
        throw ex;
      });
  };
}

export function updateAdminDegreeStart() {
  return {
    type: types.UPDATE_ADMIN_DEGREE_START,
  };
}
export function updateAdminDegreeSuccess({ data }) {
  return {
    type: types.UPDATE_ADMIN_DEGREE_SUCCESS,
    data: data,
  };
}

export function updateAdminDegreeFail() {
  return {
    type: types.UPDATE_ADMIN_DEGREE_FAIL,
  };
}
export function updateAdminDegree(data) {
  return (dispatch) => {
    dispatch(postAdminDegreeStart());
    apiClient
      .getJobApi()
      .jobApiUpdateDegree(data)
      .then((resp) => {
        dispatch(postAdminDegreeSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postAdminDegreeFail());
        throw ex;
      });
  };
}
export function deleteAdminDegreeStart() {
  return {
    type: types.DELETE_ADMIN_DEGREE_START,
  };
}
export function deleteAdminDegreeSuccess({ data }) {
  return {
    type: types.DELETE_ADMIN_DEGREE_SUCCESS,
    data: data,
  };
}

export function deleteAdminDegreeFail() {
  return {
    type: types.DELETE_ADMIN_DEGREE_FAIL,
  };
}
export function deleteAdminDegree(id) {
  return (dispatch) => {
    dispatch(deleteAdminDegreeStart());
    apiClient
      .getJobApi()
      .jobApiDeleteDegree(id)
      .then((resp) => {
        dispatch(deleteAdminDegreeSuccess({ data: resp.data }));
        dispatch(getAdminDegree(undefined));
      })
      .catch((ex) => {
        dispatch(deleteAdminDegreeFail());
        throw ex;
      });
  };
}

function getAdminSpecializationStart() {
  return {
    type: types.GET_ADMIN_SPECIALIZATION_START,
  };
}

function getAdminSpecializationSuccess({ data }) {
  return {
    type: types.GET_ADMIN_SPECIALIZATION_SUCCESS,
    data: data,
  };
}

function getAdminSpecializationFail() {
  return {
    type: types.GET_ADMIN_SPECIALIZATION_FAIL,
  };
}

export function getAdminSpecialization(
  searchInput,
  status,
  page,
  rowsPerPage,
  degreeId,
) {
  return (dispatch) => {
    dispatch(getAdminSpecializationStart());

    apiClient
      .getJobApi()
      .jobApiGetSpecialization(
        degreeId,
        status,
        page,
        rowsPerPage,
        undefined,
        searchInput,
      )
      .then((resp) => {
        dispatch(getAdminSpecializationSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAdminSpecializationFail());
      });
  };
}

export function postAdminSpecializationStart() {
  return {
    type: types.POST_ADMIN_SPECIALIZATION_START,
  };
}
export function postAdminSpecializationSuccess({ data }) {
  return {
    type: types.POST_ADMIN_SPECIALIZATION_SUCCESS,
    data: data,
  };
}

export function postAdminSpecializationFail() {
  return {
    type: types.POST_ADMIN_SPECIALIZATION_FAIL,
  };
}
export function postAdminSpecialization(data) {
  return (dispatch) => {
    dispatch(postAdminSpecializationStart());
    apiClient
      .getJobApi()
      .jobApiCreateSpecialization(data)
      .then((resp) => {
        dispatch(postAdminSpecializationSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postAdminSpecializationFail());
        throw ex;
      });
  };
}

export function updateAdminSpecializationStart() {
  return {
    type: types.UPDATE_ADMIN_SPECIALIZATION_START,
  };
}
export function updateAdminSpecializationSuccess({ data }) {
  return {
    type: types.UPDATE_ADMIN_SPECIALIZATION_SUCCESS,
    data: data,
  };
}

export function updateAdminSpecializationFail() {
  return {
    type: types.UPDATE_ADMIN_SPECIALIZATION_FAIL,
  };
}
export function updateAdminSpecialization(data) {
  return (dispatch) => {
    dispatch(postAdminSpecializationStart());
    apiClient
      .getJobApi()
      .jobApiUpdateSpecialization(data)
      .then((resp) => {
        dispatch(postAdminSpecializationSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postAdminSpecializationFail());
        throw ex;
      });
  };
}

export function deleteAdminSpecializationStart() {
  return {
    type: types.DELETE_ADMIN_SPECIALIZATION_START,
  };
}
export function deleteAdminSpecializationSuccess({ data }) {
  return {
    type: types.DELETE_ADMIN_SPECIALIZATION_SUCCESS,
    data: data,
  };
}

export function deleteAdminSpecializationFail() {
  return {
    type: types.DELETE_ADMIN_SPECIALIZATION_FAIL,
  };
}
export function deleteAdminSpecialization(id, degreeId) {
  return (dispatch) => {
    dispatch(deleteAdminSpecializationStart());
    apiClient
      .getJobApi()
      .jobApiDeleteSpecialization(id)
      .then((resp) => {
        dispatch(deleteAdminSpecializationSuccess({ data: resp.data }));
        dispatch(getAdminSpecialization(undefined, undefined, 1, 10, degreeId));
      })
      .catch((ex) => {
        dispatch(deleteAdminSpecializationFail());
        throw ex;
      });
  };
}
