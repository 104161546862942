import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import {
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  TablePagination,
  Typography,
  TableCell,
  Avatar,
  TextField,
  Checkbox,
} from '@mui/material';
import Select2 from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { StyleDataManagement } from '../StyledComponent';
import QuickActionBar from './QuickActionBar';
import { deleteCompany } from '../../../actions/dataManagement';
import AddCompanyDialog from '../AddCompanyDialog';
import MessageBar from '../../MessageBar';

const AdminCompanyList = ({
  companyData,
  selectedCompany,
  handleCompanyModalOpen,
  isEditingStatus,
  setIsEditingStatus,
  setCompanyId,
  page,
  rowsPerPage,
  onPageChangeHandler,
  handleChangeRowsPerPage,
  editableTableCompany,
  companyName,
  isEditing,
  setCompanyName,
  setIsEditing,
  searchTerm,
  status,
  setSortOrder,
  sortOrder,
  setSortBy,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = React.useState([]);
  const [showMergeCompanyDialog, setShowMergeCompanyDialog] = React.useState(
    false,
  );
  const [showDeleteError, setShowDeleteError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleCompanyMultiSelect = (event, company) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [
        ...prevSelectedIds,
        company?.company_id,
      ]);
      setSelectedCompanyList((prevSelected) => [...prevSelected, company]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter(
          (selectedId) => selectedId !== company?.company_id,
        ),
      );
      setSelectedCompanyList((prevSelected) =>
        prevSelected.filter(
          (selectedCompany) =>
            selectedCompany.company_id !== company?.company_id,
        ),
      );
    }
  };

  const handleActionDelete = (id, actionType) => {
    const deleteBody = actionType === 'bulk' ? selectedIds : [id];
    dispatch(
      deleteCompany({ ids: deleteBody }, searchTerm, page, rowsPerPage, status),
    ).then((res) => {
      if (res.data?.skipped?.length > 0) {
        setShowDeleteError(true);
        setErrorMessage(res.data?.skipped?.length);
      }
    });
    setSelectedIds([]);
    setSelectedCompanyList([]);
  };

  const handleSelectAll = () => {
    setSelectedIds(
      companyData?.company_data
        ?.filter((company) => company.approval_status !== 'Rejected')
        ?.map((obj) => obj.company_id),
    );
    setSelectedCompanyList(
      companyData?.company_data?.filter(
        (company) => company.approval_status !== 'Rejected',
      ),
    );
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectedCompanyList([]);
  };

  const handleActionMerge = () => {
    setShowMergeCompanyDialog(true);
  };

  return (
    <StyleDataManagement className="collegeListContainer">
      <Table style={{ backgroundColor: 'white' }}>
        <TableHead>
          {companyData.company_data?.length === 0 ? (
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              No Records Found
            </Typography>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Logo</TableCell>
              <TableCell>
                Company Name{' '}
                {sortOrder === 'asc' ? (
                  <ArrowUpward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('desc');
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('asc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          )}
        </TableHead>

        {companyData.company_data
          ?.filter((record) => record.approval_status !== 'Rejected')
          .map((company) => (
            <TableBody key={company.company_id}>
              <TableRow>
                <TableCell>
                  {' '}
                  <Checkbox
                    checked={selectedIds.includes(company.company_id)}
                    onChange={(e) => handleCompanyMultiSelect(e, company)}
                  />{' '}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  <div className="college__list__name">
                    <Avatar
                      alt={company.company_name}
                      src={company.image_url}
                      className="college__list__avatar"
                    />
                  </div>
                </TableCell>
                <TableCell style={{ width: '20rem' }}>
                  {isEditing &&
                  selectedCompany?.company_id === company?.company_id ? (
                    <FormControl
                      style={{ marginTop: '0.5rem', width: '12rem' }}
                    >
                      <TextField
                        placeholder={'Field cannot be empty'}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        autoFocus
                      />
                    </FormControl>
                  ) : (
                    <Typography
                      onClick={() => {
                        setCompanyId(company.company_id);
                        setIsEditingStatus(true);
                        setCompanyName(company.company_name);
                        setIsEditing(true);
                      }}
                    >
                      {company.company_name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ padding: '0', width: '15rem' }}
                >
                  {isEditingStatus &&
                  selectedCompany.company_id === company.company_id ? (
                    <>
                      <FormControl style={{ width: '8rem', padding: 0 }}>
                        <Select2
                          {...register('approval_status', {
                            required: 'Please select a status',
                          })}
                          style={{
                            marginTop: '8px',
                            marginBottom: '4px',
                          }}
                          variant="outlined"
                          margin="dense"
                          displayEmpty
                          placeholder="Company"
                          error={!!errors.approval_status}
                          defaultValue={
                            company.approval_status === null ||
                            company.approval_status === 'Pending'
                              ? 'Pending'
                              : 'Accepted'
                          }
                        >
                          <MenuItem value="Accepted" key="Accepted">
                            Accepted
                          </MenuItem>
                          <MenuItem value="Pending" key="Pending">
                            Pending
                          </MenuItem>
                          <MenuItem value="Rejected" key="Rejected">
                            Rejected
                          </MenuItem>
                        </Select2>
                      </FormControl>
                      <ButtonGroup style={{ textAlign: 'right' }}>
                        <IconButton
                          onClick={handleSubmit(editableTableCompany)}
                          disabled={companyName ? false : true}
                          size="large"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsEditingStatus(false);
                            setIsEditing(false);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </ButtonGroup>
                    </>
                  ) : (
                    <div className="college__sub-tags">
                      <Chip
                        onClick={() => {
                          setCompanyId(company.company_id);
                          setIsEditingStatus(true);
                          setCompanyName(company.company_name);
                          setIsEditing(true);
                        }}
                        variant="outlined"
                        color="success"
                        size="small"
                        label={
                          company.approval_status
                            ? company.approval_status
                            : 'Pending'
                        }
                        className="subtag"
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() => handleCompanyModalOpen(company.company_id)}
                    size="large"
                  >
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handleActionDelete(company.company_id, 'single')
                    }
                    size="large"
                  >
                    <Delete style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>

      {selectedIds.length > 0 ? (
        <QuickActionBar
          handleActionDelete={handleActionDelete}
          handleActionMerge={handleActionMerge}
          selectedIds={selectedIds}
          entityDataLength={companyData?.company_data?.length}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          page={page}
          rowsPerPage={rowsPerPage}
          paginationCount={companyData.total_pages * companyData.page_size}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={companyData.total_pages * companyData.page_size}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      )}

      {showMergeCompanyDialog && (
        <AddCompanyDialog
          open={showMergeCompanyDialog}
          handleClose={() => setShowMergeCompanyDialog(false)}
          companyId={''}
          company={''}
          searchTerm={searchTerm}
          status={status || ''}
          page={page}
          rowsPerPage={rowsPerPage}
          onlyMerge={true}
          selectedCompanyList={selectedCompanyList}
          setSelectedCompanyList={setSelectedCompanyList}
          setSelectedIds={setSelectedIds}
        />
      )}
      <MessageBar
        type="error"
        open={showDeleteError}
        onClose={() => setShowDeleteError(false)}
        message={`${errorMessage} record(s) could not be deleted`}
      />
    </StyleDataManagement>
  );
};

export default AdminCompanyList;
