import React, { useEffect, useRef, useState } from 'react';
import FormData from 'form-data';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ImagePicker from './../ImagePicker';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import { countries } from '../../constants/countries';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import './UserProfileEdit.css';
import PhoneNumberVerify from '../UserDetails/PhoneNumberVerify';
import profileEditSideImg from './img/profileEditSideImg.png';
import CalenderIcon from './img/calenderIcon.svg';
import { IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { withRouter } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { UploadUserProfileImage, putUserProfileData } from '../../actions/user';
import { useDispatch } from 'react-redux';

const DenseTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '10.5px 14px',
  },
});

const UserProfileEdit = (props) => {
  const phoneNumberRef = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [updateInfoStatus, setUpdateInfoStatus] = useState('');
  const [updateInfoMessage, setUpdateInfoMessage] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showCheckMessage, setShowCheckMessage] = useState(false);
  const [dob, setDob] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    gender: '',
    country: '',
    about: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (props.user.isUpdatingUserInfoSuccess) {
      setIsStatusMessageVisible(true);
      setUpdateInfoStatus('success');
      setUpdateInfoMessage(
        'Your profile information has been successfully updated',
      );

      setTimeout(() => {
        navigate('/l/user/profile');
      }, 3000);
    }
  }, [props.user.isUpdatingUserInfoSuccess]);
  useEffect(() => {
    if (props.user.isUpdatingUserInfoFail) {
      setIsStatusMessageVisible(true);
      setUpdateInfoStatus('error');
      setUpdateInfoMessage(
        'Sorry! Unable to save profile information, please try again later',
      );
    }
  }, [props.user.isUpdatingUserInfoFail]);
  useEffect(() => {
    if (props.user.isUpdatingUserInfoFail) {
      setIsStatusMessageVisible(true);
      setUpdateInfoStatus('success');
      setUpdateInfoMessage('Your phone number has been verified');
      setErrors((oldState) => {
        delete oldState['phone_number'];
        return oldState;
      });
    }
  }, [props.user.data.is_phone_verified]);

  useEffect(() => {
    if (imageFile !== null && typeof imageFile !== 'string') {
      dispatch(UploadUserProfileImage(imageFile));
    }
  }, [imageFile]);

  const onFileLoad = (file) => {
    setImageFile(file);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    }));
    if (name === 'username') {
      setErrors({ ...errors, username: '' });
      const usernameValidation = /^[a-zA-Z0-9._]{6,30}$/;
      if (usernameValidation.test(event.target.value)) {
        props.resetAvailability();
      } else {
        setErrors({ ...errors, username: 'The username is not valid!' });
      }
    }
  };

  const handleDateChange = (value) => {
    setDob(moment(value).toISOString(true));
  };

  const validate = (data) => {
    const errors = {};
    const { user } = props;

    const namePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{2,}$/;

    if (!data.first_name || !namePattern.test(data.first_name)) {
      errors['first_name'] = 'First Name is not valid';
    }

    if (!data.last_name || !namePattern.test(data.last_name)) {
      errors['last_name'] = 'Last Name is not valid';
    }

    if (!data.country) {
      errors['country'] = 'Please select a country';
    }

    if (
      data.phone_number?.split('-')?.[1]?.length !== 10 &&
      data.phone_number?.split('-')?.[1]?.length !== 0
    ) {
      errors['phone_number'] = 'Please enter a valid phone number';
    }

    const usernameValidation = /^[a-zA-Z0-9._]{6,30}$/;

    if (!usernameValidation.test(data.username)) {
      errors['username'] = 'The username is not valid!';
    }

    if (user.isUsernameAvailableSuccess && !user.isUsernameAvailable) {
      errors['username'] =
        'This username is not available. Please try something else.';
    }

    if (
      user.data.phone_number !== data.phone_number ||
      !user.data.is_phone_verified
    ) {
      if (user.data.phone_number && user.data.phone_number.startsWith('91'))
        errors['phone_number'] = 'Please verify your phone number';
    }

    return errors;
  };

  const onSubmit = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      gender,
      country,
      about,
    } = formData;

    const data = {
      first_name,
      last_name,
      username,
      email,
      dob: dob ? moment(dob).toISOString() : null,
      gender,
      country,
      about,
      phone_number: phoneNumberRef.current.phoneNumber,
    };

    const errors = validate(data);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
      let form = new FormData();
      form.append('first_name', data.first_name);
      form.append('last_name', data.last_name);
      form.append('username', data.username);
      form.append('email', data.email);
      form.append('dob', data.dob);
      form.append('gender', data.gender);
      form.append('country', data.country);
      form.append('about', data.about);
      form.append('phone_number', data.phone_number);

      if (
        props.user?.data?.username !== formData.username &&
        !isButtonClicked
      ) {
        setShowCheckMessage(true);
      } else {
        dispatch(
          putUserProfileData({
            data: form,
          }),
        );
      }
    }
  };

  const getUserInfo = () => {
    const { user } = props;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...user.data,
    }));
    setImageFile(user.data.picture);
    setDob(user.data.dob);
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const handleAvailabilityCheck = () => {
    const { getUsernameAvailability } = props;

    getUsernameAvailability(formData.username);
  };

  const { user } = props;

  const {
    isFetchingUsernameAvailability,
    isUsernameAvailableSuccess,
    isUsernameAvailable,
  } = user;

  const getAvailabilityIcon = () => {
    if (isFetchingUsernameAvailability) {
      return (
        <Button type="button">
          <CircularProgress size={24} />
        </Button>
      );
    }

    if (
      !isFetchingUsernameAvailability &&
      isUsernameAvailableSuccess &&
      isUsernameAvailable &&
      !errors.username
    ) {
      return <CheckCircleIcon color="secondary" />;
    } else if (
      !isFetchingUsernameAvailability &&
      isUsernameAvailableSuccess &&
      !isUsernameAvailable
    ) {
      return <CancelIcon color="error" />;
    }

    return (
      <Button
        type="button"
        onClick={() => {
          handleAvailabilityCheck();
          setIsButtonClicked(true);
          setShowCheckMessage(false);
        }}
        sx={{
          '&:hover': {
            background: 'white',
          },
        }}
      >
        <Typography variant="caption" color="primary" fontWeight={'500'}>
          Check
        </Typography>
      </Button>
    );
  };

  return (
    <div>
      <Button
        color="secondary"
        startIcon={<ChevronLeftIcon />}
        sx={{
          float: 'left',
          '& .MuiButton-startIcon': {
            mr: '0px',
          },
          '&:hover': {
            background: 'transparent',
          },
          ml: '15px',
          position: 'relative',
          top: '-35px',
        }}
        onClick={() => navigate('/l/user/profile')}
      >
        Back
      </Button>
      <Paper className="user-wrapper">
        <div>
          <Grid container className="form-control">
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-start">
                <ImagePicker onFileLoad={onFileLoad} imageUrl={imageFile} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className="profileEditSideImg">
              <Box display="flex" justifyContent="flex-end">
                <img
                  alt="profileEditSideImg"
                  src={profileEditSideImg}
                  width={'50%'}
                />
              </Box>
            </Grid>
          </Grid>
          <div>
            <Typography variant="h5" color="black" fontWeight={'600'}>
              About you
            </Typography>
            <div>
              <TextField
                fullWidth
                name="about"
                value={formData.about}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
                className="text-input-field"
                rows="3"
                multiline
                placeholder="Elevate your profile with a professional 100-word note about yourself..."
                inputProps={{ maxLength: 200 }}
              />
            </div>
          </div>
          <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography variant="h5" color="black" fontWeight={'600'}>
              General
            </Typography>
          </Box>

          <Grid container spacing={3} mb="12px">
            <Grid item sm={6} xs={12}>
              <label htmlFor="">First Name*</label>
              <DenseTextField
                name="first_name"
                fullWidth
                value={formData.first_name}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
              {errors.first_name ? (
                <p className="error">{errors.first_name}</p>
              ) : null}
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="">Last Name</label>
              <DenseTextField
                name="last_name"
                fullWidth
                value={formData.last_name}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
              />
              {errors.last_name ? (
                <p className="error">{errors.last_name}</p>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={3} mb="12px">
            <Grid item sm={6} xs={12}>
              <label htmlFor="">Email Address*</label>
              <DenseTextField
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                margin="dense"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="">Phone Number*</label>
              <div style={{ paddingTop: '8px' }}>
                <PhoneNumberVerify
                  {...props}
                  user={user}
                  error={errors.phone_number}
                  phoneNumberRef={phoneNumberRef}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb="12px">
            <Grid item sm={6} xs={12}>
              <div className="">
                <label htmlFor="">Username*</label>
                <div className="">
                  <DenseTextField
                    name="username"
                    fullWidth
                    value={formData.username}
                    onChange={handleChange}
                    margin="dense"
                    variant="outlined"
                    className="text-input-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getAvailabilityIcon()}
                        </InputAdornment>
                      ),
                    }}
                  />

                  {errors.username ? (
                    <p className="error">{errors.username}</p>
                  ) : null}
                  {showCheckMessage ? (
                    <p className="error">Check is required for new username</p>
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="">
                <Box mb="5px">
                  <label htmlFor="">Date of Birth</label>
                </Box>
                <div className="">
                  <DatePicker
                    value={
                      dob
                        ? moment(dob)
                        : moment(
                            new Date().setFullYear(
                              new Date().getFullYear() - 3,
                            ),
                          )
                    }
                    onChange={handleDateChange}
                    className="date-input-field"
                    format="DD/MM/YYYY"
                    placeholder="Date of Birth"
                    maxDate={moment(
                      new Date().setFullYear(new Date().getFullYear() - 1),
                    )}
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '10.5px 14px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton size="large">
                            <img alt="calender icon" src={CalenderIcon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.dob ? <p className="error">{errors.dob}</p> : null}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb="12px">
            <Grid item sm={6} xs={12}>
              <Box mb="5px">
                <label htmlFor="">Gender</label>
              </Box>
              <Select
                defaultValue={'male'}
                fullWidth
                variant="outlined"
                value={formData.gender}
                onChange={handleChange}
                style={{ height: '40px' }}
                inputProps={{
                  name: 'gender',
                }}
              >
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box mb="5px">
                <label htmlFor="">Country</label>
              </Box>
              <Select
                fullWidth
                variant="outlined"
                value={formData.country}
                onChange={handleChange}
                style={{ height: '40px' }}
                inputProps={{
                  name: 'country',
                }}
              >
                {countries.map((country) => (
                  <MenuItem value={country.code} key={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country ? (
                <p className="error">{errors.country}</p>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <Grid container className="form-action">
          <Grid item md={6} sm={12} xs={12} className="gridBottomLeft"></Grid>
          <Grid item md={6} sm={12} xs={12} className="gridBottomRight">
            <Box className="buttonContainerRight">
              <Button
                className="discardBtn"
                onClick={() => navigate('/l/user/profile')}
                disabled={user.isUpdatingUserInfo}
                style={{ marginRight: '20px' }}
              >
                Discard
              </Button>
              <Button
                className="submitBtn"
                color="primary"
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
                disabled={user.isUpdatingUserInfo}
              >
                {user.isUpdatingUserInfo ? (
                  <Loader size={20} color="secondary" />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <MessageBar
        type={updateInfoStatus}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={updateInfoMessage}
      />
    </div>
  );
};

UserProfileEdit.defaultProps = {};

UserProfileEdit.propTypes = {};

export default withRouter(UserProfileEdit);
