import axios from 'axios';
import * as types from './types';

import config from './../config';

function getTotalReferralsStart() {
  return {
    type: types.GET_TOTAL_REFERRALS_START,
  };
}

function getTotalReferralsSuccess({ data }) {
  return {
    type: types.GET_TOTAL_REFERRALS_SUCCESS,
    data,
  };
}

function getTotalReferralsFail() {
  return {
    type: types.GET_TOTAL_REFERRALS_FAIL,
  };
}

export function getTotalReferrals(page, pageSize) {
  return (dispatch, getState) => {
    dispatch(getTotalReferralsStart());
    const { auth } = getState();

    axios
      .get(
        `${config.API_URL}/user/referral_summary?page=${page}&&pageSize=${pageSize}`,
        {
          headers: {
            token: auth.token,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((resp) => {
        dispatch(getTotalReferralsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getTotalReferralsFail());
      });
  };
}
