import React, { useState } from 'react';
import CustomizedSteppers from './Stepper';
import {
  AddDetailsBadge,
  CreditWrapper,
  Header,
  StyleInputTitle,
  StyledActionModalContentContainer,
  StyledContainerLeft,
  StyledContainerRight,
  StyledImgContainer,
  StyledLeftContainer,
  StyledModalWrapper,
  StyledRightContainer,
  StyledStipperContainer,
  StyledTopContainer,
} from './DashboardStyledComponent';
import credits from './images/credits.png';
import character from './images/Character.svg';
import CustomCircularProgress from './CustomCircularProgress';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ActionModal from './ActionModal';
import dobImg from './images/DOB.svg';
import genderImg from './images/genderImg.svg';
import goalImg from './images/Goal.svg';
import aboutImg from './images/About.svg';
import educationImg from './images/Education.svg';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import {
  putUserProfileUpdate,
  sendCollegeAutocompleteQuery,
  sendDegreeAutocompleteQuery,
  sendExamAutocompleteQuery,
  sendSpecializationAutocompleteQuery,
} from '../../actions/user';
import { useDispatch } from 'react-redux';
import { Checkbox, TextField } from '@mui/material';
import Location from './Location';
import { gendersOptions, goalsOptions, steps } from '../../constants/dashboard';
import ReactSelect from 'react-select';

const TopContainer = ({ userProfileMilestone }) => {
  const dispatch = useDispatch();

  const [openDOB, setOpenDOB] = useState(false);
  const [openGender, setOpenGender] = useState(false);
  const [openEducation1, setOpenEducation1] = useState(false);
  const [openEducation2, setOpenEducation2] = useState(false);
  const [openGoal, setOpenGoal] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [about, setAbout] = useState('');
  const [goal, setGoal] = useState('');
  const [selectedExam, setSelectedExam] = useState(null);
  const [education, setEducation] = useState({
    collegeInfo: {},
    degreeInfo: {},
    specializationInfo: {},
    institute_location: {},
    start_date: '',
    end_date: '',
    is_currently_studying: false,
    grade: null,
  });

  const isObjectEmpty = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleEnterDOB = () => {
    const info = {
      user_data: {
        dob: dob,
      },
    };
    dispatch(putUserProfileUpdate(info));
    setOpenDOB(false);
  };

  const handleEnterGender = () => {
    const info = {
      user_data: {
        gender: gender,
      },
    };
    dispatch(putUserProfileUpdate(info));
    setOpenGender(false);
  };

  const handleEnterGoal = () => {
    let info = {};
    if (goal == 'Exam') {
      info = {
        practise_meta_data: {
          exam_id: selectedExam?.id,
          goal: goal,
          exam_name: selectedExam?.exam_name,
        },
      };
    } else {
      info = {
        practise_meta_data: {
          goal: goal,
        },
      };
    }
    dispatch(putUserProfileUpdate(info));
    setOpenGoal(false);
  };

  const handleEducation1 = () => {
    setOpenEducation1(false);
    setOpenEducation2(true);
  };

  const handleEducation2 = () => {
    const info = {
      educational_details: education,
    };

    if (info.educational_details.collegeInfo.id) {
      info.educational_details.college_id =
        info.educational_details.collegeInfo.id;
      delete info.educational_details.collegeInfo;
      delete info.educational_details.college_name;
    } else {
      info.educational_details.college_name =
        info.educational_details.collegeInfo.value;
      delete info.educational_details.collegeInfo;
      delete info.educational_details.college_id;
    }

    if (info.educational_details.degreeInfo.id) {
      info.educational_details.degree_id =
        info.educational_details.degreeInfo.id;
      delete info.educational_details.degreeInfo;
      delete info.educational_details.degree;
    } else {
      info.educational_details.degree =
        info.educational_details.degreeInfo.name;
      delete info.educational_details.collegeInfo;
      delete info.educational_details.degree_id;
    }

    if (info.educational_details.specializationInfo.id) {
      info.educational_details.specialization_id =
        info.educational_details.specializationInfo.id;
      delete info.educational_details.specializationInfo;
      delete info.educational_details.specialization;
    } else {
      info.educational_details.specialization =
        info.educational_details.specializationInfo.name;
      delete info.educational_details.specializationInfo;
      delete info.educational_details.specialization_id;
    }
    if (education.is_currently_studying) {
      delete education.end_date;
    }

    dispatch(putUserProfileUpdate(info));
    setOpenEducation2(false);
  };

  const handleEnterAbout = () => {
    const info = {
      user_data: {
        about: about,
      },
    };
    dispatch(putUserProfileUpdate(info));
    setOpenAbout(false);
  };

  return (
    <>
      <StyledTopContainer>
        <StyledStipperContainer>
          <Header style={{ fontSize: '0.9rem', marginLeft: '21px' }}>
            Complete your profile and earn extra credits
          </Header>
          <CustomizedSteppers
            steps={steps || []}
            activeStep={userProfileMilestone?.total_completed_fields}
            milestonesCompleted={
              userProfileMilestone?.profile_milestones_completed
            }
          />
        </StyledStipperContainer>

        {userProfileMilestone?.next_milestone === 'dob' && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledContainerLeft>
              <Header style={{ fontSize: '0.9rem', margin: '0', padding: '0' }}>
                Tell us when you were born.
              </Header>
              <p style={{ margin: '0' }}>
                Enter your Date of Birth for verification purpose.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '4px' }}>
                  <AddDetailsBadge
                    onClick={() => {
                      setDob('');
                      setOpenDOB(true);
                    }}
                  >
                    Enter DOB
                  </AddDetailsBadge>
                </div>
                <CreditWrapper>
                  <p>Earn: </p>
                  <img
                    src={credits}
                    style={{ width: '15px', height: '15px' }}
                    alt=""
                  />
                  <p> {userProfileMilestone?.next_milestone_credits} </p>
                </CreditWrapper>
              </div>
            </StyledContainerLeft>
            <StyledContainerRight>
              <div className="">
                <CustomCircularProgress
                  value={userProfileMilestone?.percent_completed}
                  size={60}
                  thickness={6}
                />
              </div>
              <StyledImgContainer>
                <img
                  src={character}
                  alt=""
                  style={{
                    marginTop: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
              </StyledImgContainer>
            </StyledContainerRight>
          </div>
        )}

        {userProfileMilestone?.next_milestone === 'gender' && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledContainerLeft>
              <Header style={{ fontSize: '0.9rem', margin: '0', padding: '0' }}>
                How you identify yourself?
              </Header>
              <p style={{ margin: '0' }}>
                Tell us your gender you identify yourself as.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '4px' }}>
                  <AddDetailsBadge
                    onClick={() => {
                      setOpenGender(true);
                    }}
                  >
                    Choose Gender
                  </AddDetailsBadge>
                </div>
                <CreditWrapper>
                  <p>Earn: </p>
                  <img
                    src={credits}
                    style={{ width: '15px', height: '15px' }}
                    alt=""
                  />
                  <p> {userProfileMilestone?.next_milestone_credits} </p>
                </CreditWrapper>
              </div>
            </StyledContainerLeft>
            <StyledContainerRight>
              <div className="">
                <CustomCircularProgress
                  value={userProfileMilestone.percent_completed}
                  size={60}
                  thickness={6}
                />
              </div>
              <StyledImgContainer>
                <img
                  src={character}
                  alt=""
                  style={{
                    marginTop: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
              </StyledImgContainer>
            </StyledContainerRight>
          </div>
        )}

        {userProfileMilestone?.next_milestone === 'education' && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledContainerLeft>
              <Header style={{ fontSize: '0.9rem', margin: '0', padding: '0' }}>
                Tell us about your education
              </Header>
              <p style={{ margin: '0' }}>
                Enter your education details to find your peers.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '4px' }}>
                  <AddDetailsBadge
                    onClick={() => {
                      setEducation({
                        college_name: '',
                        degree: '',
                        specialization: '',
                        institute_location: {},
                        start_date: '',
                        end_date: '',
                        is_currently_studying: false,
                        grade: '',
                      });
                      setOpenEducation1(true);
                    }}
                  >
                    Add Education Details
                  </AddDetailsBadge>
                </div>
                <CreditWrapper>
                  <p>Earn: </p>
                  <img
                    src={credits}
                    style={{ width: '15px', height: '15px' }}
                    alt=""
                  />
                  <p> {userProfileMilestone?.next_milestone_credits} </p>
                </CreditWrapper>
              </div>
            </StyledContainerLeft>
            <StyledContainerRight>
              <div className="">
                <CustomCircularProgress
                  value={userProfileMilestone?.percent_completed}
                  size={60}
                  thickness={6}
                />
              </div>
              <StyledImgContainer>
                <img
                  src={character}
                  alt=""
                  style={{
                    marginTop: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
              </StyledImgContainer>
            </StyledContainerRight>
          </div>
        )}

        {userProfileMilestone?.next_milestone === 'goal' && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledContainerLeft>
              <Header style={{ fontSize: '0.9rem', margin: '0', padding: '0' }}>
                What’s your Goal?
              </Header>
              <p style={{ margin: '0' }}>
                Tell us your goal on Abekus for personalised experience.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '4px' }}>
                  <AddDetailsBadge
                    onClick={() => {
                      setOpenGoal(true);
                    }}
                  >
                    Choose Goal
                  </AddDetailsBadge>
                </div>
                <CreditWrapper>
                  <p>Earn: </p>
                  <img
                    src={credits}
                    style={{ width: '15px', height: '15px' }}
                    alt=""
                  />
                  <p> {userProfileMilestone?.next_milestone_credits} </p>
                </CreditWrapper>
              </div>
            </StyledContainerLeft>
            <StyledContainerRight>
              <div className="">
                <CustomCircularProgress
                  value={userProfileMilestone?.percent_completed}
                  size={60}
                  thickness={6}
                />
              </div>
              <StyledImgContainer>
                <img
                  src={character}
                  alt=""
                  style={{
                    marginTop: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
              </StyledImgContainer>
            </StyledContainerRight>
          </div>
        )}

        {userProfileMilestone?.next_milestone === 'about' && (
          <div style={{ display: 'flex', gap: '4px' }}>
            <StyledContainerLeft>
              <Header style={{ fontSize: '0.9rem', margin: '0', padding: '0' }}>
                Tell everyone about yourself
              </Header>
              <p style={{ margin: '0' }}>
                Write 100 word professional note about yourself.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginTop: '4px' }}>
                  <AddDetailsBadge onClick={() => setOpenAbout(true)}>
                    Add About You
                  </AddDetailsBadge>
                </div>
                <CreditWrapper>
                  <p>Earn: </p>
                  <img
                    src={credits}
                    style={{ width: '15px', height: '15px' }}
                    alt=""
                  />
                  <p> {userProfileMilestone?.next_milestone_credits} </p>
                </CreditWrapper>
              </div>
            </StyledContainerLeft>
            <StyledContainerRight>
              <div className="">
                <CustomCircularProgress
                  value={userProfileMilestone?.percent_completed}
                  size={60}
                  thickness={6}
                />
              </div>
              <StyledImgContainer>
                <img
                  src={character}
                  alt=""
                  style={{
                    marginTop: '5px',
                    position: 'absolute',
                    zIndex: '1',
                  }}
                />
              </StyledImgContainer>
            </StyledContainerRight>
          </div>
        )}
      </StyledTopContainer>

      {/* DOB */}
      <StyledModalWrapper>
        <ActionModal
          open={openDOB}
          onClose={() => setOpenDOB(false)}
          title={'Tell us when you were born and earn'}
          content={
            <StyledActionModalContentContainer>
              <div style={{ width: '100%' }}>
                <img src={dobImg} style={{ margin: '0 auto' }} />
              </div>
              <div style={{ width: '100%' }}>
                <StyleInputTitle>Date of Birth</StyleInputTitle>
                <DatePicker
                  label="Enter Date (DD/MM/YYYY)"
                  format="DD/MM/YYYY"
                  onChange={(newValue) =>
                    setDob(moment(newValue).format('DD/MM/YYYY'))
                  }
                  sx={{ width: '100%' }}
                />
              </div>
            </StyledActionModalContentContainer>
          }
          onYes={handleEnterDOB}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={dob === ''}
        />
      </StyledModalWrapper>

      {/* Gender */}
      <StyledModalWrapper>
        <ActionModal
          open={openGender}
          onClose={() => setOpenGender(false)}
          title={'How you identify yourself? Save and earn'}
          content={
            <StyledActionModalContentContainer>
              <div style={{ width: '100%' }}>
                <img src={genderImg} style={{ margin: '0 auto' }} />
              </div>
              <div style={{ width: '100%' }}>
                <StyleInputTitle>Gender</StyleInputTitle>
                <ReactSelect
                  placeholder="Select Gender"
                  options={gendersOptions.map((gender) => ({
                    value: gender,
                    label: gender,
                  }))}
                  onChange={(e) => setGender(e.value)}
                  defaultValue={gender}
                />
              </div>
            </StyledActionModalContentContainer>
          }
          onYes={handleEnterGender}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={gender === ''}
        />
      </StyledModalWrapper>

      {/* Goals */}
      <StyledModalWrapper>
        <ActionModal
          open={openGoal}
          onClose={() => setOpenGoal(false)}
          title={'What’s your goal? Save and earn'}
          content={
            <StyledActionModalContentContainer>
              <StyledLeftContainer>
                <img
                  src={goalImg}
                  style={{ margin: '0 auto', width: '100%' }}
                />
              </StyledLeftContainer>
              <StyledRightContainer
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px 0',
                }}
              >
                <div>
                  <StyleInputTitle>Career Goal</StyleInputTitle>
                  <ReactSelect
                    placeholder="Select Goal"
                    options={goalsOptions.map((goal) => ({
                      value: goal,
                      label: goal.split(/(?=[A-Z])/).join(' '),
                    }))}
                    onChange={(e) => setGoal(e.value)}
                    defaultValue={goal}
                  />
                </div>
                {goal == 'Exam' && (
                  <div style={{ width: '100%' }}>
                    <StyleInputTitle>Target Exam</StyleInputTitle>
                    <AsyncCreatableSelect
                      isClearable
                      cacheOptions
                      noOptionsMessage={() => 'Search Exam to show options...'}
                      placeholder="Search Exam..."
                      loadOptions={(query, callback) =>
                        dispatch(sendExamAutocompleteQuery(query, callback))
                      }
                      getOptionLabel={(option) =>
                        option.exam_name ? option.exam_name : option.label
                      }
                      getOptionValue={(option) =>
                        option.id ? option.id : option.value
                      }
                      onChange={(e) => setSelectedExam(e)}
                    />
                  </div>
                )}
              </StyledRightContainer>
            </StyledActionModalContentContainer>
          }
          onYes={handleEnterGoal}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={
            goal === '' || (goal === 'Exam' && isObjectEmpty(selectedExam))
          }
        />
      </StyledModalWrapper>

      {/* Education1 */}
      <StyledModalWrapper>
        <ActionModal
          open={openEducation1}
          onClose={() => setOpenEducation1(false)}
          title={'Tell us about your education and earn'}
          titleRight={'(1/2)'}
          content={
            <StyledActionModalContentContainer>
              <StyledLeftContainer>
                <img src={educationImg} style={{ margin: '0 auto' }} />
              </StyledLeftContainer>
              <StyledRightContainer>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <StyleInputTitle>Institution Name</StyleInputTitle>
                  <AsyncCreatableSelect
                    value={education.collegeInfo}
                    onChange={(collegeInfo) => {
                      setEducation({
                        ...education,
                        collegeInfo: collegeInfo,
                      });
                    }}
                    cacheOptions
                    noOptionsMessage={() => 'Search College to show options...'}
                    className="text-input-field"
                    placeholder="Search Institute..."
                    loadOptions={(query, callback) =>
                      dispatch(sendCollegeAutocompleteQuery(query, callback))
                    }
                    getOptionLabel={(option) =>
                      option.college_name
                        ? option.college_name
                        : `Add New: "${option.value}"`
                    }
                    getOptionValue={(option) =>
                      option.id ? option.id : option.value
                    }
                  />
                </div>

                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <StyleInputTitle>Degree</StyleInputTitle>
                  <AsyncCreatableSelect
                    value={education.degreeInfo}
                    onChange={(degreeInfo) => {
                      setEducation({
                        ...education,
                        degreeInfo: degreeInfo,
                      });
                    }}
                    cacheOptions
                    noOptionsMessage={() => 'Search Degree to show options...'}
                    className="text-input-field"
                    placeholder="Search Degree..."
                    loadOptions={(query, callback) =>
                      dispatch(sendDegreeAutocompleteQuery(query, callback))
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : `Add New: "${option.value}"`
                    }
                    getOptionValue={(option) =>
                      option.id ? option.id : option.value
                    }
                  />
                </div>

                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <StyleInputTitle>Field of Study / Course</StyleInputTitle>
                  <AsyncCreatableSelect
                    value={education.specializationInfo}
                    onChange={(specializationInfo) => {
                      setEducation({
                        ...education,
                        specializationInfo: specializationInfo,
                      });
                    }}
                    cacheOptions
                    noOptionsMessage={() =>
                      'Search Specialization to show options...'
                    }
                    className="text-input-field"
                    placeholder="Search Specialization..."
                    loadOptions={(query, callback) =>
                      dispatch(
                        sendSpecializationAutocompleteQuery(
                          query,
                          callback,
                          education.degreeInfo.id,
                        ),
                      )
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : `Add New: "${option.value}"`
                    }
                    getOptionValue={(option) =>
                      option.id ? option.id : option.value
                    }
                  />
                </div>
              </StyledRightContainer>
            </StyledActionModalContentContainer>
          }
          onYes={handleEducation1}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={
            isObjectEmpty(education?.collegeInfo) ||
            isObjectEmpty(education?.degreeInfo) ||
            isObjectEmpty(education?.specializationInfo)
          }
        />
      </StyledModalWrapper>

      {/* Education2 */}
      <StyledModalWrapper>
        <ActionModal
          open={openEducation2}
          onClose={() => setOpenEducation2(false)}
          title={'Tell us about your education and earn'}
          titleRight={'(2/2)'}
          content={
            <StyledActionModalContentContainer>
              <StyledLeftContainer>
                <img src={educationImg} style={{ margin: '0 auto' }} />
              </StyledLeftContainer>
              <StyledRightContainer>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <StyleInputTitle>Institution Location</StyleInputTitle>
                  <Location
                    value={education?.institute_location}
                    onChange={(newLocation) =>
                      setEducation({
                        ...education,
                        institute_location: newLocation,
                      })
                    }
                  />
                </div>

                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <StyleInputTitle>Course Duration</StyleInputTitle>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        onChange={() =>
                          setEducation({
                            ...education,
                            is_currently_studying: !education?.is_currently_studying,
                          })
                        }
                      />{' '}
                      <p
                        style={{
                          margin: '0 0 0 4px',
                          padding: '0',
                        }}
                      >
                        I am currently studying here.
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      format="YYYY-MM-DD"
                      onChange={(newValue) =>
                        setEducation({
                          ...education,
                          start_date: moment(newValue).format('YYYY-MM-DD'),
                        })
                      }
                      sx={{ width: '100%' }}
                    />

                    <DatePicker
                      label="End Date"
                      format="YYYY-MM-DD"
                      disabled={education.is_currently_studying}
                      onChange={(newValue) =>
                        setEducation({
                          ...education,
                          end_date: moment(newValue).format('YYYY-MM-DD'),
                        })
                      }
                      sx={{ width: '100%', color: '#3572B0' }}
                    />
                  </div>
                </div>

                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <StyleInputTitle
                    style={{
                      color: '#25507B',
                      margin: '0 0 4px 0',
                      padding: '0',
                    }}
                  >
                    Grade
                  </StyleInputTitle>
                  <TextField
                    type="number"
                    onChange={(e) => {
                      setEducation({
                        ...education,
                        grade: parseFloat(e.target.value),
                      });
                    }}
                    fullWidth
                    size="small"
                    margin="dense"
                    variant="outlined"
                    placeholder="Enter Your Grade (Percentage/SGPA/CGPA)"
                  />
                </div>
              </StyledRightContainer>
            </StyledActionModalContentContainer>
          }
          onYes={handleEducation2}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={
            isObjectEmpty(education.institute_location) ||
            education.start_date === '' ||
            (education.end_date === '' && !education.is_currently_studying) ||
            education.grade === ''
          }
        />
      </StyledModalWrapper>

      {/* about */}
      <StyledModalWrapper>
        <ActionModal
          open={openAbout}
          onClose={() => setOpenAbout(false)}
          title={'Tell us about yourself and earn'}
          content={
            <StyledActionModalContentContainer>
              <StyledLeftContainer>
                <img
                  src={aboutImg}
                  style={{ margin: '0 auto', width: '100%' }}
                />
              </StyledLeftContainer>
              <StyledRightContainer>
                <StyleInputTitle>About You</StyleInputTitle>
                <textarea
                  style={{ width: '100%', height: '200px', padding: '4px' }}
                  placeholder="Write a 100 word professional note about yourself for the employers to know you better..."
                  onChange={(e) => setAbout(e.target.value)}
                />
              </StyledRightContainer>
            </StyledActionModalContentContainer>
          }
          endTask={true}
          onYes={handleEnterAbout}
          credits={userProfileMilestone?.next_milestone_credits || 0}
          actionButtonDisable={about === ''}
        />
      </StyledModalWrapper>
    </>
  );
};

export default TopContainer;
