import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postFeedback } from '../../actions/reportBug';
import reportSubmittedIcon from '../../images/reportSubmittedIcon.png';
import { CustomDialogBox } from '../DialogBox';
import './style.css';

const ReportFeedback = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [expValue, setExpValue] = useState('');
  const [showExpValErr, setShowExpValErr] = useState(false);

  const handleCloseDialog = () => {
    setOpenSuccessDialog(false);
    reset({
      message: '',
    });
    setExpValue('');
  };

  const handleFormSubmit = (data) => {
    data.overall_rating = expValue;
    data.feedback_type = 'Feedback';
    if (data.overall_rating === '') setShowExpValErr(true);
    else {
      dispatch(postFeedback(data));
      setOpenSuccessDialog(true);
    }
  };

  return (
    <Fragment>
      <Container>
        <Paper className="reportBug-card">
          <span className="heading-reportBug">
            Got a Feedback? Help us improve the platform!
          </span>
          <Grid container style={{ marginTop: '30px' }}>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <label>Message</label>
              <TextField
                {...register('message', {
                  required: 'Please enter Message',
                })}
                minRows={6}
                multiline
                fullWidth
                placeholder="Describe your issue, let's solve it together..."
                variant="outlined"
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            </Grid>

            <Grid container style={{ marginTop: '20px' }} spacing={1}>
              <Grid item xs={12}>
                <label>Rate your overall experience with Abekus</label>
                {showExpValErr && (
                  <Typography style={{ color: 'red' }}>
                    Please select a value
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                  <Typography
                    key={x}
                    onClick={() => {
                      setExpValue(x);
                      setShowExpValErr(false);
                    }}
                    className="experience-count"
                    style={{
                      backgroundColor: x <= expValue ? '#25507b' : '#3572B026',
                      color: x <= expValue ? '#fff' : '#25507b',
                      marginRight: '2%',
                    }}
                  >
                    {x}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '100px' }} spacing={1}>
              <Grid item sm={6} xs={12}>
                <Box display={'flex'} justifyContent="flex-start">
                  <Button
                    variant="outlined"
                    type="button"
                    className="report-actionBtn"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display={'flex'} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="report-actionBtn"
                    onClick={handleSubmit(handleFormSubmit)}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {openSuccessDialog ? (
          <CustomDialogBox
            open={openSuccessDialog}
            onClose={handleCloseDialog}
            actions={
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={handleCloseDialog}
                style={{ height: '40px', width: '150px' }}
              >
                Close
              </Button>
            }
            title={
              <Typography
                style={{
                  color: '#25507B',
                  fontSize: '34px',
                  fontWeight: 500,
                  lineHeight: '48px',
                }}
              >
                <img src={reportSubmittedIcon} /> Feedback Submitted
              </Typography>
            }
            maxWidth={'md'}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '24px',
                lineHeight: '29px',
                fontWeight: 400,
                paddingLeft: '40px',
                textAlign: 'justify',
              }}
            >
              Thanks for submitting your feedback! Your contribution helps make
              our platform a better experience for everyone. In case we need
              further clarification, our team will reach out to you.
            </Typography>
          </CustomDialogBox>
        ) : null}
      </Container>
    </Fragment>
  );
};

export default ReportFeedback;
