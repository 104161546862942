import React, { useEffect, Fragment, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton,
  TablePagination,
  TextField,
  Button,
  Collapse,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Gems from '../../images/Gemsnew.svg';
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import UpdateOrderDetailsModal from './UpdateOrderDetailsModal';
import { getOrdersList, getSingleOrderDetails } from '../../actions/storeAdmin';
import pdfPlaceholder from '../../images/pdfPlaceholder.png';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { CustomDialogBox } from '../DialogBox';
import { getInvoiceFileName } from '../../actions/orderHistory';
import { useNavigate } from 'react-router-dom';

import {
  PDFBtn,
  OrderStatusCardActions,
  Price,
  Quantity,
  Status,
  OrderStatusCollapseGrid,
  OrderStatusCollapseBtn,
  OrderStatusCardContent,
  OrderStatusCard,
  OrderStatusCardMedia,
  Heading,
} from './StoreAdminStyledComponent';

const statusOptions = [
  { id: '', name: 'All' },
  { id: 'incomplete', name: 'Incomplete' },
  { id: 'started', name: 'Started' },
  { id: 'succeeded', name: 'Succeeded' },
  { id: 'user_failure', name: 'User Failure' },
  { id: 'api_failure', name: 'Api Failure' },
];

const OrderStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({ rowsPerPage: 10, page: 0 });
  const [filters, setFilters] = useState({
    status: '',
    startDate: '',
    endDate: '',
  });
  const [transactionId, setTransactionId] = useState('');
  const [adminOrdersState, setAdminOrdersState] = useState({
    userMsg: '',
    internalMsg: '',
    overallStatus: '',
  });
  const [isCollapse, setIsCollapse] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    dispatch(
      getOrdersList(
        pagination.page + 1,
        pagination.rowsPerPage,
        filters.status,
        filters.startDate,
        filters.endDate,
      ),
    );
  }, [
    dispatch,
    pagination.page,
    pagination.rowsPerPage,
    filters.status,
    filters.startDate,
    filters.endDate,
  ]);

  const { ordersList, singleOrderData } = useSelector(
    (state) => state.storeAdmin,
  );
  const handleModalOpen = (tId, internalMsg, userMsg, status) => {
    setTransactionId(tId);
    setAdminOrdersState({
      userMsg: userMsg,
      internalMsg: internalMsg,
      overallStatus: status,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onPageChangeHandler = (e, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };
  const handleChangeRowsPerPage = (e) => {
    setPagination({ rowsPerPage: parseInt(e.target.value, 10), page: 0 });
  };

  const handleCollapseContainer = (id, trnId) => {
    dispatch(getSingleOrderDetails(trnId));
    setOrderId(id);
    setIsCollapse(true);
  };
  const handleInvoiceDownload = (invoiceUrl) => {
    if (!invoiceUrl) setOpenDialog(true);
    else dispatch(getInvoiceFileName(invoiceUrl));
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <Container>
      <Button
        onClick={() => navigate(-1)}
        sx={{
          float: 'left',
          '& .MuiButton-startIcon': {
            mr: '0px',
          },
        }}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Back
      </Button>
      <Heading container>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="h6">
              {' '}
              Store Orders({ordersList.all_orders?.length})
            </Typography>
          </Box>
        </Grid>
      </Heading>
      <Grid container spacing={2} mb="15px">
        <Grid item md={3} xs={12}>
          <div className="scoreboard__filters__item">
            <Select
              options={statusOptions}
              onChange={(option) =>
                setFilters({ ...filters, status: option.id })
              }
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={filters.status ? filters.status : 'Filter by Status'}
              value={filters.status}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            size="small"
            style={{ background: 'white' }}
            variant="outlined"
            id="date"
            label="Start Date"
            type="date"
            value={filters.startDate}
            onChange={(e) => {
              setError({
                ...error,
                startDate: '',
              });
              const val = e.target.value;
              if (
                !filters.endDate ||
                new Date(val) <= new Date(filters.endDate)
              )
                setFilters({ ...filters, startDate: val });
              else
                setError({
                  ...error,
                  startDate: 'Start date should be less than end date',
                });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error.startDate}
            helperText={error.startDate}
            sx={{
              '& .MuiFormHelperText-root': {
                background: '#f1f2f7',
                m: '0px',
              },
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            size="small"
            style={{ background: 'white' }}
            variant="outlined"
            id="date"
            label="End Date"
            type="date"
            value={filters.endDate}
            onChange={(e) => {
              setError({
                ...error,
                endDate: '',
              });
              const val = e.target.value;
              if (
                !filters.startDate ||
                new Date(val) >= new Date(filters.startDate)
              )
                setFilters({ ...filters, endDate: val });
              else
                setError({
                  ...error,
                  endDate: 'End date should be greater than start date',
                });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error.endDate}
            helperText={error.endDate}
            sx={{
              '& .MuiFormHelperText-root': {
                background: '#f1f2f7',
                m: '0px',
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        {ordersList.all_orders?.map((list) => (
          <Fragment key={list.id}>
            {list.cart?.map((cartItem) => (
              <Grid item xs={12} sm={12} lg={12} key={cartItem.id}>
                <OrderStatusCard>
                  <Grid container>
                    <Grid item lg={1} md={2} sm={12} xs={12}>
                      {/* <CardMedia
                        component="img"
                        className={classes.cardMedia}
                        image={
                          cartItem.item?.image_url &&
                          cartItem.item?.image_url[0]
                        }
                        alt={'Product Image'}
                      /> */}
                      <OrderStatusCardMedia>
                        <img
                          style={{ width: '100%', height: '100%' }}
                          src={
                            cartItem.item?.image_url &&
                            cartItem.item?.image_url[0]
                          }
                          alt={'Product Image'}
                        />
                      </OrderStatusCardMedia>
                    </Grid>
                    <Grid item lg={11} md={10} sm={12} xs={12}>
                      <OrderStatusCardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{ fontSize: '16px' }}
                            >
                              {cartItem.item?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: '12px',
                                color: '#3572B0',
                                textAlign: 'right',
                              }}
                            >
                              {list.invoice_no && (
                                <>Order No: {list.invoice_no}</>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box>
                          <Typography style={{ fontSize: '12px' }}>
                            {cartItem.item?.item_description}
                          </Typography>
                        </Box>
                        <Grid container style={{ marginTop: '15px' }}>
                          <Grid item xs={11}>
                            {list.internal_msg ? (
                              <Typography
                                style={{ color: '#DE1D1D', fontSize: '12px' }}
                              >
                                <strong>Internal Msg </strong>:{' '}
                                {list.internal_msg}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {list.user_msg ? (
                              <Typography
                                style={{ color: '#3572B0', fontSize: '12px' }}
                              >
                                <strong>User Msg</strong> :{list.user_msg}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() =>
                                handleModalOpen(
                                  cartItem.transaction_id,
                                  list.internal_msg,
                                  list.user_msg,
                                  list.overall_transaction_status,
                                )
                              }
                              size="large"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>

                        <OrderStatusCardActions>
                          <Grid
                            container
                            style={{
                              color: 'grey',
                              alignItems: 'end',
                            }}
                          >
                            <Grid item md={3} sm={12} xs={12}>
                              <Box flex={1}>
                                <Typography variant="subtitle2">
                                  <span style={{ color: 'grey' }}>
                                    Date:{' '}
                                    {moment(cartItem?.created_at).format(
                                      'YYYY-MM-DD',
                                    )}
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Quantity>
                                <Typography variant="subtitle2">
                                  <span style={{ color: 'grey' }}>
                                    Quantity: {cartItem.quantity}
                                  </span>
                                </Typography>
                              </Quantity>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Price>
                                <Typography variant="subtitle2">
                                  <span>
                                    Price:{' '}
                                    {cartItem.total_amount_in_currency &&
                                      '₹' +
                                        cartItem.total_amount_in_currency}{' '}
                                    {cartItem.total_amount_in_gems && (
                                      <>
                                        <img src={Gems} width="11px" />{' '}
                                        {cartItem.total_amount_in_gems}
                                      </>
                                    )}
                                  </span>
                                </Typography>
                              </Price>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Status>
                                <Typography variant="subtitle2">
                                  <span>
                                    Status:{' '}
                                    <span style={{ color: 'green' }}>
                                      {list.payment_status}
                                    </span>
                                  </span>
                                </Typography>
                              </Status>
                            </Grid>
                          </Grid>
                        </OrderStatusCardActions>
                      </OrderStatusCardContent>
                    </Grid>
                  </Grid>
                </OrderStatusCard>
                {orderId === cartItem.id ? (
                  <Collapse
                    in={isCollapse}
                    style={{
                      background: 'white',
                      marginTop: '4px',
                    }}
                  >
                    <OrderStatusCollapseGrid container>
                      <Grid item lg={9} md={8} sm={6} xs={12}>
                        <strong>Name</strong>: {singleOrderData.shipping?.name}
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box display={'flex'} justifyContent={'flex-start'}>
                          <strong>Email</strong>:{' '}
                          {singleOrderData.shipping?.email}
                        </Box>
                      </Grid>
                      {singleOrderData && (
                        <>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Typography
                              style={{ fontWeight: 600, fontSize: '12px' }}
                            >
                              Shipping Address :
                            </Typography>
                            Street: {singleOrderData.shipping?.address} <br />
                            State: {singleOrderData.shipping?.state} <br />
                            Phone number: {
                              singleOrderData.shipping?.phone_no
                            }{' '}
                            <br />
                            Zip code: {singleOrderData.shipping?.pincode} <br />
                            Country: {singleOrderData.shipping?.country}
                          </Grid>
                          <Grid
                            item
                            lg={5}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Typography
                              style={{ fontWeight: 600, fontSize: '12px' }}
                            >
                              Billing Address :
                            </Typography>
                            Street: {singleOrderData.billing?.address} <br />
                            State: {singleOrderData.billing?.state} <br />
                            Phone number: {
                              singleOrderData.billing?.phone_no
                            }{' '}
                            <br />
                            Zip code: {singleOrderData.billing?.pincode} <br />
                            Country: {singleOrderData.billing?.country}
                          </Grid>
                        </>
                      )}
                      {cartItem.total_amount_in_currency &&
                        list.payment_status === 'succeeded' && (
                          <Grid
                            item
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Box display={'flex'} justifyContent={'flex-start'}>
                              <strong>Invoice</strong>:
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'flex-start'}
                              style={{ position: 'relative' }}
                            >
                              <img
                                src={pdfPlaceholder}
                                style={{
                                  border: '1px solid #3572B0',
                                  borderRadius: '4px',
                                  position: 'relative',
                                }}
                              />
                              <PDFBtn
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleInvoiceDownload(list.invoice_url)
                                }
                              >
                                View
                              </PDFBtn>
                            </Box>
                          </Grid>
                        )}
                    </OrderStatusCollapseGrid>
                  </Collapse>
                ) : null}
                <OrderStatusCollapseBtn
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    if (isCollapse && orderId === cartItem.id)
                      setIsCollapse(false);
                    else handleCollapseContainer(cartItem.id, list.buyer_id);
                  }}
                  sx={{
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                >
                  Show more details{' '}
                  {isCollapse && orderId === cartItem.id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </OrderStatusCollapseBtn>
              </Grid>
            ))}
          </Fragment>
        ))}
      </Grid>
      <TablePagination
        component="nav"
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        count={ordersList.total_pages * ordersList.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
      />

      {open ? (
        <UpdateOrderDetailsModal
          open={open}
          handleClose={handleClose}
          transactionId={transactionId}
          internalMsg={adminOrdersState.internalMsg}
          userMsg={adminOrdersState.userMsg}
          overallStatus={adminOrdersState.overallStatus}
        />
      ) : null}
      <CustomDialogBox
        open={openDialog}
        onClose={handleDialogClose}
        actions={
          <Button
            variant="outlined"
            type="button"
            color="primary"
            onClick={handleDialogClose}
          >
            OK
          </Button>
        }
        title="Message"
        maxWidth={'sm'}
      >
        <Typography variant="h6">
          The Invoice will Generate in next 15 mins.
        </Typography>
      </CustomDialogBox>
    </Container>
  );
};

export default OrderStatus;
