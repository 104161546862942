export const QuestionValidation = (question) => {
  const {
    question_text,
    question_type,
    answer_one_word,
    solution_text,
    options,
    answer_option_no,
  } = question;
  const questionTextForCheck = question_text?.replace(/<[^>]+>/g, '');
  if (!questionTextForCheck || /^[\s\n]*$/.test(questionTextForCheck?.trim())) {
    return {
      errorMessage: 'Please provide a valid question',
    };
  }
  if (questionTextForCheck?.trim().length < 10) {
    return {
      errorMessage: 'Question must have minimum 10 characters',
    };
  }
  if (['MultipleChoice', 'MultipleCorrect'].includes(question_type)) {
    if (options?.length < 2) {
      return {
        errorMessage: 'Please provide atleast two options in multiple choice',
      };
    }

    if (answer_option_no === null || answer_option_no?.length === 0) {
      return {
        errorMessage: 'Please select one correct option',
      };
    }

    if (solution_text.length === 0 || solution_text === '<p><br></p>') {
      return {
        errorMessage: 'Please provide solution for question',
      };
    }
  } else {
    if (!answer_one_word || answer_one_word?.length === 0) {
      return {
        errorMessage: 'Please provide a valid answer for one word question',
      };
    }

    if (solution_text?.length === 0 || solution_text === '<p><br></p>') {
      return {
        errorMessage: 'Please provide solution for question',
      };
    }

    if (!BlankSpaceValidation(question?.solution_text)) {
      return {
        errorMessage: 'Please provide a valid solution for this question',
      };
    }

    if (!BlankSpaceValidation(question?.answer_one_word[0])) {
      return {
        errorMessage: 'Please provide a valid answer for this question',
      };
    }

    for (let i = 1; i < question?.answer_one_word?.length; i++) {
      if (!BlankSpaceValidation(question?.answer_one_word[i])) {
        return {
          errorMessage: `Please provide a valid alternate answer number ${i} for this question`,
        };
      }
    }

    if (question?.how_to_answer) {
      if (!BlankSpaceValidation(question?.how_to_answer)) {
        return {
          errorMessage: 'How to answer is invalid',
        };
      }
    }
  }
};

export const BlankSpaceValidation = (data) => {
  const textForCheck = data?.replace(/<[^>]+>/g, '');
  if (!textForCheck || /^[\s\n]*$/.test(textForCheck?.trim())) {
    return false;
  }

  return true;
};
