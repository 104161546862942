import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const orderHistory = createReducer(
  {
    orderHistoryList: {},
  },
  {
    [types.GET_ORDER_HISTORY_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingOrderHistoryDetails: true,
      });

      return newState;
    },
    [types.GET_ORDER_HISTORY_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        orderHistoryList: action.data,
        isFetchingOrderHistoryDetails: false,
      });

      return newState;
    },
    [types.GET_ORDER_HISTORY_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingOrderHistoryDetails: false,
      });

      return newState;
    },

    [types.GET_INVOICE_FILE_NAME_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileName: true,
      });

      return newState;
    },
    [types.GET_INVOICE_FILE_NAME_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileName: false,
      });

      return newState;
    },
    [types.GET_INVOICE_FILE_NAME_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileName: false,
      });

      return newState;
    },

    [types.GET_INVOICE_FILE_FROM_PATH_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileFromPath: true,
      });

      return newState;
    },
    [types.GET_INVOICE_FILE_FROM_PATH_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileFromPath: false,
      });

      return newState;
    },
    [types.GET_INVOICE_FILE_FROM_PATH_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingFileFromPath: false,
      });

      return newState;
    },
  },
);
export { orderHistory as default };
