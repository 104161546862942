import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Phone,
  Delete,
  LinkedIn,
  UploadFile,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  TableCell,
  Chip,
  CircularProgress,
  Select,
  MenuItem,
  Tooltip,
  Collapse,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactSelect from 'react-select';
import SearchBox from '../SearchBox';
import { StyleSalesDashboard } from './SalesStyledComponent';
import {
  deleteSalesLead,
  getNextSalesLead,
  getSalesLead,
  putSalesLead,
} from '../../actions/salesDashboard';
import AddSalesLead from './components/AddSalesLead';
import CommunicationDetails from './CommunicationDetails';
import MessageBar from '../MessageBar';
import {
  getLeadStatusChipColor,
  getSalesUserStatus,
  salesStatusFilter,
  salesStatusOptions,
} from '../../constants/salesDashbord';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CustomDialogBox } from '../DialogBox';
import UploadExcelFile from './components/UploadExcelFile';

const SalesDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramSalesUserId = location?.search?.split('=')[1];
  const { handleSubmit, register, watch, setValue } = useForm();
  const {
    salesLeadData,
    deleteSalesLeadErr,
    postSalesLeadErr,
    postedDataResponse,
  } = useSelector((state) => state.SalesDashboard);
  const isExistingLeads = Object.keys(postedDataResponse).length > 0;
  const leadPostedByData =
    postedDataResponse?.existing_leads?.by_email?.[0] ||
    postedDataResponse?.existing_leads?.by_linkedin_url?.[0] ||
    postedDataResponse?.existing_leads?.by_phone_number?.[0];
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [openAddLeadDialog, setOpenAddLeadDialog] = useState(false);
  const [salesLeadId, setSalesLeadId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [salesLead, setSalesLead] = useState({});
  const [openCallDetailsDialog, setOpenCallDetailsDialog] = useState(false);
  const [infiniteScrollLeadsData, setInfiniteScrollLeadsData] = useState([]);
  const [openMessageBar, setOpenMessageBar] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUploadExcelDialog, setOpenUploadExcelDialog] = useState(false);
  const [openExistingLeadDialog, setOpenExistingLeadDialog] = useState(false);
  const [filters, setFilters] = useState({
    status: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [error, setError] = useState({
    startDate: '',
    endDate: '',
  });
  const [isCollapse, setIsCollapse] = useState(false);
  const selectedLead = infiniteScrollLeadsData?.find(
    (record) => record.id === salesLeadId,
  );
  useEffect(() => {
    setIsEditing(false);
    if (searchTerm) {
      setPage(1);
      dispatch(
        getSalesLead(
          undefined,
          undefined,
          searchTerm,
          paramSalesUserId,
          filters,
        ),
      );
    } else {
      dispatch(getSalesLead(page, 10, searchTerm, paramSalesUserId, filters));
    }
  }, [
    dispatch,
    searchTerm,
    filters.endDate,
    filters.startDate,
    filters.status,
  ]);

  useEffect(() => {
    if (salesLeadData.page == 1) {
      setInfiniteScrollLeadsData(salesLeadData?.sales_leads);
    }
  }, [salesLeadData.sales_leads]);

  useEffect(() => {
    if (deleteSalesLeadErr?.length || postSalesLeadErr?.length) {
      setOpenMessageBar(true);
    }
  }, [deleteSalesLeadErr, postSalesLeadErr]);

  useEffect(() => {
    const data = postedDataResponse?.existing_leads;
    if (
      data?.by_email.length > 0 ||
      data?.by_linkedin_url.length > 0 ||
      data?.by_phone_number.length > 0
    ) {
      setOpenExistingLeadDialog(true);
    }
  }, [isExistingLeads]);

  const addLeadHandler = () => {
    setOpenAddLeadDialog(true);
    setSalesLead({});
    setSalesLeadId('');
  };

  const editableTableLead = (data) => {
    dispatch(putSalesLead(salesLeadId, data, '', undefined, filters, page));
    setIsEditing(false);
    setPage(1);
  };

  const fetchNextRecords = () => {
    setPage(page + 1);
    dispatch(
      getNextSalesLead(
        page + 1,
        10,
        searchTerm,
        paramSalesUserId,
        filters,
        (newData) => {
          setInfiniteScrollLeadsData((prev) =>
            prev.concat(newData.sales_leads),
          );
        },
      ),
    );
  };

  const handleExpandMore = (lead) => {
    setIsCollapse(true);
    setSalesLeadId(lead.id);
  };

  return (
    <StyleSalesDashboard
      id="scrollableContainer"
      sx={{
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2} mb={3}>
        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="flex-start" gap={'10px'}>
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={() =>
                navigate(
                  paramSalesUserId
                    ? '/l/admin/sales/dashboard'
                    : '/l/dashboard',
                )
              }
            >
              Back
            </Button>
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={() => navigate('/l/user-management')}
            >
              Users
            </Button>
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={() => navigate('/l/reviewJobs')}
            >
              Posted Jobs
            </Button>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => setOpenUploadExcelDialog(true)}
              color="primary"
            >
              <UploadFile />
            </IconButton>
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={addLeadHandler}
            >
              Add New Lead
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container mb={4} mt={4}>
        <Grid item border={'1px solid #000'} padding={'5px'}>
          <Typography fontWeight={500} fontSize={'16px'}>
            <strong style={{ color: '#000' }}>Total Leads Count:</strong>{' '}
            {salesLeadData?.total_leads_count || 0}
          </Typography>
        </Grid>
        <Grid item border={'1px solid #000'} padding={'5px'}>
          <Typography fontWeight={500} fontSize={'16px'}>
            <strong style={{ color: '#000' }}> Leads in last 2 hrs:</strong>{' '}
            {salesLeadData?.total_last_2_hours_leads || 0}
          </Typography>
        </Grid>
        <Grid item border={'1px solid #000'} padding={'5px'}>
          <Typography fontWeight={500} fontSize={'16px'}>
            <strong style={{ color: '#000' }}> Leads in last 24hrs:</strong>{' '}
            {salesLeadData?.total_last_day_leads || 0}
          </Typography>
        </Grid>
        <Grid item border={'1px solid #000'} padding={'5px'}>
          <Typography fontWeight={500} fontSize={'16px'}>
            <strong style={{ color: '#000' }}> Leads in last 7 days: </strong>
            {salesLeadData?.total_last_week_leads || 0}
          </Typography>
        </Grid>
        <Grid item border={'1px solid #000'} padding={'5px'}>
          <Typography fontWeight={500} fontSize={'16px'}>
            <strong style={{ color: '#000' }}> Leads in last 30 days: </strong>
            {salesLeadData?.total_this_month_leads || 0}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb="15px">
        <Grid item md={3} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <SearchBox
              value={searchTerm}
              handleSearch={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className="scoreboard__filters__item">
            <ReactSelect
              options={salesStatusFilter}
              onChange={(option) =>
                setFilters({ ...filters, status: option.id })
              }
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              placeholder={filters.status ? filters.status : 'Filter by Status'}
              value={filters.status}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            size="small"
            style={{ background: 'white' }}
            variant="outlined"
            id="date"
            label="Start Date"
            type="date"
            value={filters.startDate}
            onChange={(e) => {
              setError({
                ...error,
                startDate: '',
              });
              const val = e.target.value;
              if (!val) {
                setFilters({ ...filters, startDate: undefined });
              } else {
                if (
                  !filters.endDate ||
                  new Date(val) <= new Date(filters.endDate)
                )
                  setFilters({ ...filters, startDate: val });
                else
                  setError({
                    ...error,
                    startDate: 'Start date should be less than end date',
                  });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error.startDate}
            helperText={error.startDate}
            sx={{
              '& .MuiFormHelperText-root': {
                background: '#f1f2f7',
                m: '0px',
              },
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            size="small"
            style={{ background: 'white' }}
            variant="outlined"
            id="date"
            label="End Date"
            type="date"
            value={filters.endDate}
            onChange={(e) => {
              setError({
                ...error,
                endDate: '',
              });
              const val = e.target.value;
              if (!val) {
                setFilters({ ...filters, endDate: undefined });
              } else {
                if (
                  !filters.startDate ||
                  new Date(val) >= new Date(filters.startDate)
                )
                  setFilters({ ...filters, endDate: val });
                else
                  setError({
                    ...error,
                    endDate: 'End date should be greater than start date',
                  });
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error.endDate}
            helperText={error.endDate}
            sx={{
              '& .MuiFormHelperText-root': {
                background: '#f1f2f7',
                m: '0px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '50vh' }}>
        <InfiniteScroll
          dataLength={infiniteScrollLeadsData?.length || 0}
          next={() => {
            fetchNextRecords();
          }}
          hasMore={salesLeadData?.page < salesLeadData?.total_pages}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          scrollThreshold={0.9}
          style={{ overflow: 'none' }}
          scrollableTarget="scrollableContainer"
        >
          {salesLeadData?.sales_leads?.length === 0 ? (
            <Box>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                No Records Found
              </Typography>
            </Box>
          ) : (
            <Table style={{ backgroundColor: 'white' }}>
              <TableHead>
                <>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Added on</TableCell>
                    <TableCell>Lead Status</TableCell>
                    <TableCell>User Status</TableCell>
                    <TableCell align="right">Call</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </>
              </TableHead>
              <TableBody>
                {infiniteScrollLeadsData?.map((lead) => (
                  <>
                    <TableRow key={lead.id}>
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        sx={{ width: '100px' }}
                      >
                        <div className="sales__list__name">{lead?.name}</div>
                      </TableCell>
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        sx={{ width: '100px' }}
                      >
                        <div className="sales__list__name">
                          {lead?.company_name || 'NA'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {isEditing && selectedLead?.id === lead.id ? (
                          <FormControl
                            style={{ marginTop: '0.5rem', width: '12rem' }}
                          >
                            <TextField
                              {...register('email')}
                              value={watch('email')}
                              placeholder={'Field cannot be empty'}
                              autoFocus
                            />
                          </FormControl>
                        ) : (
                          <Typography
                            onClick={() => {
                              setSalesLeadId(lead.id);
                              setIsEditing(true);
                              setValue('email', lead.email);
                            }}
                          >
                            {lead?.email || 'NA'}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <div>{moment.utc(lead?.created_at).fromNow()}</div>
                      </TableCell>{' '}
                      <TableCell>
                        {isEditing && selectedLead?.id === lead.id ? (
                          <Grid
                            container
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <Grid item>
                              <Select
                                {...register('sales_status', {
                                  required: 'Please select a status',
                                })}
                                fullWidth
                                style={{
                                  marginTop: '8px',
                                  marginBottom: '4px',
                                }}
                                variant="outlined"
                                margin="dense"
                                displayEmpty
                                placeholder="Status"
                                defaultValue={
                                  salesLeadId
                                    ? selectedLead.sales_status ||
                                      'NoActionTaken'
                                    : 'NoActionTaken'
                                }
                              >
                                {salesStatusOptions.map((option) => (
                                  <MenuItem
                                    value={option.value}
                                    key={option.key}
                                  >
                                    {option.value
                                      ?.split(/(?=[A-Z])/)
                                      ?.join(' ')}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item>
                              <ButtonGroup style={{ textAlign: 'right' }}>
                                <IconButton
                                  onClick={handleSubmit(editableTableLead)}
                                  size="large"
                                >
                                  <CheckIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setIsEditing(false);
                                  }}
                                  size="large"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </ButtonGroup>
                            </Grid>
                          </Grid>
                        ) : (
                          <Chip
                            label={
                              lead.sales_status
                                ?.split(/(?=[A-Z])/)
                                ?.join(' ') || 'Null'
                            }
                            variant="contained"
                            sx={{
                              padding: '0px 15px',
                              background: getLeadStatusChipColor(
                                lead.sales_status,
                              ),
                              color: '#fff',
                            }}
                            onClick={() => {
                              setSalesLeadId(lead.id);
                              setIsEditing(true);
                              setValue('email', lead.email);
                            }}
                          />
                        )}
                      </TableCell>{' '}
                      <TableCell>
                        {' '}
                        <Chip
                          label={
                            getSalesUserStatus(lead?.sales_lead_status) ||
                            'null'
                          }
                          color="primary"
                          variant="outlined"
                          sx={{ padding: '0px 15px' }}
                        />
                      </TableCell>{' '}
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setOpenCallDetailsDialog(true);
                            setSalesLead(lead);
                            setSalesLeadId(lead.id);
                          }}
                          size="large"
                        >
                          <Phone color="primary" />
                        </IconButton>
                        {lead?.linkedin_url && (
                          <IconButton
                            onClick={() => {
                              window.open(lead.linkedin_url, '_blank');
                            }}
                            size="large"
                          >
                            <LinkedIn color="primary" />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => {
                            setOpenDeleteDialog(true);
                            setSalesLeadId(lead.id);
                          }}
                          size="large"
                        >
                          <Delete style={{ cursor: 'pointer' }} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpenAddLeadDialog(true);
                            setSalesLeadId(lead.id);
                            setSalesLead(lead);
                          }}
                          size="large"
                        >
                          <EditIcon style={{ cursor: 'pointer' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={8}>
                        {isCollapse && salesLeadId === lead.id ? (
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setIsCollapse(false)}
                          >
                            Show more details <KeyboardArrowUp />
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => handleExpandMore(lead)}
                          >
                            Show more details <KeyboardArrowDown />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{
                          background: isCollapse ? '#f2f2f2' : '',
                          border: 'none',
                        }}
                      >
                        {salesLeadId === lead.id ? (
                          <Collapse
                            in={isCollapse}
                            style={{
                              marginTop: '4px',
                            }}
                          >
                            <Box>
                              {' '}
                              <strong>Job Role:</strong> {lead.job_role_name}
                            </Box>
                            <br />
                            <Box>
                              {' '}
                              <strong>Lead Type:</strong>{' '}
                              {lead.sales_lead_type || 'NA'}
                            </Box>
                            <br />
                            <Box>
                              {' '}
                              <strong>Created At:</strong>{' '}
                              {moment
                                .utc(lead?.created_at)
                                .add(5, 'hours')
                                .add(30, 'minutes')
                                .format('DD/MM/YYYY hh:mm a')}
                            </Box>
                            <br />
                            <Box>
                              <strong> Last Call: </strong>
                              <Tooltip
                                title={
                                  <Box sx={{ padding: '10px', color: 'white' }}>
                                    <Typography variant="h6">
                                      {lead?.last_call
                                        ? moment
                                            .utc(lead?.last_call)
                                            .local()
                                            .format('DD/MM/YYYY, hh:mm a')
                                        : 'No calls'}
                                    </Typography>
                                  </Box>
                                }
                                placement="left"
                              >
                                {lead?.last_call
                                  ? moment.utc(lead?.last_call).fromNow()
                                  : 'No call logs'}
                              </Tooltip>
                            </Box>
                            <br />
                            <Box>
                              <strong>Comment:</strong> {lead?.comment}
                            </Box>
                          </Collapse>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          )}
        </InfiniteScroll>
      </Box>
      <AddSalesLead
        open={openAddLeadDialog}
        handleClose={() => setOpenAddLeadDialog(false)}
        salesLeadId={salesLeadId}
        selectedLead={salesLead}
        filters={filters}
        page={page}
      />
      {openCallDetailsDialog && (
        <CommunicationDetails
          open={openCallDetailsDialog}
          handleClose={() => setOpenCallDetailsDialog(false)}
          salesLead={salesLead}
        />
      )}
      {openUploadExcelDialog && (
        <UploadExcelFile
          open={openUploadExcelDialog}
          onClose={() => setOpenUploadExcelDialog(false)}
          filters={filters}
          page={page}
        />
      )}
      <MessageBar
        type="error"
        open={openMessageBar}
        onClose={() => setOpenMessageBar(false)}
        message={
          postSalesLeadErr?.split('_')?.join(' ') ||
          'This lead cannot be deleted as it contains the attempt details'
        }
      />
      {openDeleteDialog && (
        <CustomDialogBox
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteSalesLead(
                      salesLeadId,
                      paramSalesUserId,
                      filters,
                      page,
                    ),
                  );
                  setPage(1);
                  setOpenDeleteDialog(false);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          title={'Are you sure you want to delete the lead?'}
          maxWidth="sm"
          overflowVisible={false}
        >
          Once deleted, the lead cannot be reverted.
        </CustomDialogBox>
      )}
      <CustomDialogBox
        actions={
          <Button
            variant="contained"
            onClick={() => {
              setOpenExistingLeadDialog(false);
            }}
          >
            Close
          </Button>
        }
        open={openExistingLeadDialog}
        onClose={() => setOpenExistingLeadDialog(false)}
        title={'This Lead Already Exists'}
        maxWidth="sm"
        overflowVisible={false}
      >
        <Typography>
          {' '}
          <strong> Lead Posted By:</strong>{' '}
          {leadPostedByData?.sales_user?.first_name}{' '}
          {leadPostedByData?.sales_user?.last_name}{' '}
        </Typography>
        <Typography>
          <strong> Lead Email:</strong> {leadPostedByData?.email}
        </Typography>
        <Typography>
          {' '}
          <strong> Created At:</strong>{' '}
          {moment.utc(leadPostedByData?.created_at).fromNow()}
        </Typography>
        <Typography>
          {' '}
          <strong> Lead LinkedinUrl:</strong> {leadPostedByData?.linkedin_url}
        </Typography>
        <Typography>
          {' '}
          <strong> Lead Phone Number:</strong> {leadPostedByData?.phone_number}
        </Typography>
        <Typography>
          {' '}
          <strong> Lead Sales Status:</strong> {leadPostedByData?.sales_status}
        </Typography>
      </CustomDialogBox>
      ;
    </StyleSalesDashboard>
  );
};

export default SalesDashboard;
