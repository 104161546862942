import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import {
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  TablePagination,
  Typography,
  TableCell,
  Checkbox,
} from '@mui/material';
import Select2 from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { StyleDataManagement } from '../StyledComponent';
import QuickActionBar from './QuickActionBar';
import { deleteAdminSkills } from '../../../actions/dataManagement';

const AdminSkillsList = ({
  adminSkill,
  selectedAdminSkill,
  handleAdminSkillModalOpen,
  isEditingStatus,
  setIsEditingStatus,
  setAdminSkillId,
  page,
  rowsPerPage,
  onPageChangeHandler,
  handleChangeRowsPerPage,
  editableTableAdminSkill,
  searchTerm,
  status,
  setSortOrder,
  sortOrder,
  setSortBy,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = React.useState([]);

  const handleSkillMultiSelect = (event, skill) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, skill.id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== skill.id),
      );
    }
  };

  const handleActionDelete = (id, actionType) => {
    const deleteBody = actionType === 'bulk' ? selectedIds : [id];
    dispatch(
      deleteAdminSkills(
        { ids: deleteBody },
        searchTerm,
        page,
        rowsPerPage,
        status,
      ),
    );
    setSelectedIds([]);
  };

  const handleSelectAll = () => {
    setSelectedIds(
      adminSkill.skills
        ?.filter((skill) => skill.approval_status !== 'Rejected')
        ?.map((obj) => obj.id),
    );
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
  };
  const handleActionMerge = () => {};

  return (
    <StyleDataManagement className="collegeListContainer">
      <Table style={{ backgroundColor: 'white' }}>
        <TableHead>
          {adminSkill.skills?.length === 0 ? (
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              No Records Found
            </Typography>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                Skill Name{' '}
                {sortOrder === 'asc' ? (
                  <ArrowUpward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('desc');
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('asc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          )}
        </TableHead>

        {adminSkill.skills
          ?.filter((record) => record.approval_status !== 'Rejected')
          .map((skill) => (
            <TableBody key={skill.id}>
              <TableRow>
                <TableCell>
                  {' '}
                  <Checkbox
                    checked={selectedIds.includes(skill.id)}
                    onChange={(e) => handleSkillMultiSelect(e, skill)}
                  />{' '}
                </TableCell>
                <TableCell style={{ width: '20rem' }}>
                  <Typography>{skill.name}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ padding: '0', width: '15rem' }}
                >
                  {isEditingStatus && selectedAdminSkill?.id === skill?.id ? (
                    <>
                      <FormControl style={{ width: '8rem', padding: 0 }}>
                        <Select2
                          {...register('approval_status', {
                            required: 'Please select a status',
                          })}
                          style={{
                            marginTop: '8px',
                            marginBottom: '4px',
                          }}
                          variant="outlined"
                          margin="dense"
                          displayEmpty
                          placeholder="Company"
                          error={!!errors.approval_status}
                          defaultValue={
                            skill.approval_status === null ||
                            skill.approval_status === 'Pending'
                              ? 'Pending'
                              : 'Accepted'
                          }
                        >
                          <MenuItem value="Accepted" key="Accepted">
                            Accepted
                          </MenuItem>
                          <MenuItem value="Pending" key="Pending">
                            Pending
                          </MenuItem>
                          <MenuItem value="Rejected" key="Rejected">
                            Rejected
                          </MenuItem>
                        </Select2>
                      </FormControl>
                      <ButtonGroup style={{ textAlign: 'right' }}>
                        <IconButton
                          onClick={() =>
                            editableTableAdminSkill({
                              name: skill.name,
                              status: watch('approval_status'),
                            })
                          }
                          disabled={skill.name ? false : true}
                          size="large"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsEditingStatus(false);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </ButtonGroup>
                    </>
                  ) : (
                    <div className="college__sub-tags">
                      <Chip
                        onClick={() => {
                          setAdminSkillId(skill.id);
                          setIsEditingStatus(true);
                        }}
                        variant="outlined"
                        color="success"
                        size="small"
                        label={
                          skill.approval_status
                            ? skill.approval_status
                            : 'Pending'
                        }
                        className="subtag"
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() => handleAdminSkillModalOpen(skill.id)}
                    size="large"
                  >
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() => handleActionDelete(skill.id, 'single')}
                    size="large"
                  >
                    <Delete style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>
      {selectedIds.length > 0 ? (
        <QuickActionBar
          handleActionDelete={handleActionDelete}
          handleActionMerge={handleActionMerge}
          selectedIds={selectedIds}
          entityDataLength={adminSkill.skills?.length}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          page={page}
          rowsPerPage={rowsPerPage}
          paginationCount={adminSkill.total_pages * adminSkill.page_size}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={adminSkill.total_pages * adminSkill.page_size}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      )}
    </StyleDataManagement>
  );
};

export default AdminSkillsList;
