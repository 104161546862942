import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import CreditsIcon from '../Sidebar/img/credits-icon.svg';
export default function ConsumeCreditModal({
  title,
  credits,
  message,
  open,
  onClose,
  onYes,
  loading = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {credits && (
          <DialogContentText style={{ textAlign: 'center', fontSize: '1.3em' }}>
            {credits} <img src={CreditsIcon} width="15px" />
          </DialogContentText>
        )}
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          No
        </Button>
        <Button
          onClick={onYes}
          color="primary"
          autoFocus
          variant="contained"
          disabled={loading}
          endIcon={loading && <CircularProgress color="inherit" size={24} />}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
