import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCompetitionById, postCompetition } from '../actions/competition';

import Component from './../components/CompetitionCreate/index';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompetitionById,
      postCompetition,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    competition: state.competitions,
    domain: state.domain,
    tag: state.tag,
    category: state.category,
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
