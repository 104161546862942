import { styled } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  '& .optionCtn': {
    marginLeft: 0,
    marginRight: 0,
    '& .option, & .optionSelected': {
      margin: '0px !important',
      padding: '10px 10px !important',
      marginBottom: '-2px !important',
      color: theme.palette.primary.main,
      fontWeight: 400,
      borderBottom: `2px solid ${theme.palette.component.ToggleBoxBorder}`,
    },
    '& .optionSelected': {
      fontWeight: 600,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));
