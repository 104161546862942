import createReducer from '../lib/createReducer';
import {
  CLEAR_MATCHING_USERS_STATE,
  GET_MATCHING_USERS_FAIL,
  GET_MATCHING_USERS_START,
  GET_MATCHING_USERS_SUCCESS,
} from '../actions/types';

const initialState = {
  fetchingSuggestions: false,
  paginationInfo: null,
  list: null,
  error: null,
};

export const userSearchQuerySuggestions = createReducer(initialState, {
  [GET_MATCHING_USERS_START](state) {
    return {
      ...state,
      fetchingSuggestions: true,
    };
  },
  [GET_MATCHING_USERS_SUCCESS](state, action) {
    const { data } = action;
    return {
      ...state,
      fetchingSuggestions: false,
      paginationInfo: {
        pageNumber: data.page_no,
        pageSize: data.page_size,
        totalPages: data.total_pages,
      },
      list: data.user_details,
    };
  },
  [GET_MATCHING_USERS_FAIL](state, action) {
    return {
      ...state,
      fetchingSuggestions: false,
      error: action.data,
    };
  },
  [CLEAR_MATCHING_USERS_STATE]() {
    return initialState;
  },
});
