import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';

export const EmailingListSnackbarv2 = ({
  snackbarDetails,
  setSnackbarDetails,
}) => {
  const { severity, message, open } = snackbarDetails;

  const handleClose = () => {
    setSnackbarDetails({
      severity: 'success',
      open: false,
      message: '',
    });
  };

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}>
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const EmailingListSnackbars = () => {
  const updatingTemplate = useSelector(
    (state) => state.emailingList.updatingTemplate,
  );
  const deletingTemplate = useSelector(
    (state) => state.emailingList.deletingTemplate,
  );

  return (
    <>
      <Snackbar open={updatingTemplate}>
        <Alert severity="info">Updating Template</Alert>
      </Snackbar>
      <Snackbar open={deletingTemplate}>
        <Alert severity="info">Deleting Template</Alert>
      </Snackbar>
    </>
  );
};

export default EmailingListSnackbars;
