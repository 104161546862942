import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import Select from 'react-select';
import {
  AppWrapper,
  NoContent,
  StyledLoader,
  TagListName,
  NoTag,
  TagListHeader,
  TagButton,
} from '../Domains/StyledComponents';
import Card from '../Card';
import SearchBox from '../SearchBox';
import {
  deleteAdminDegree,
  getAdminDegree,
} from '../../actions/adminDegreeCreate';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { Grid, TablePagination } from '@mui/material';

const AdminCreateDegree = () => {
  const [searchInput, setSearchInput] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { degreeData, isFetchingAdminDegrees } = useSelector(
    (state) => state.adminDegreeCreate,
  );

  useEffect(() => {
    dispatch(getAdminDegree(searchInput, status, page + 1, rowsPerPage));
  }, [searchInput, status, page, rowsPerPage]);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
  };
  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const renderLoader = () => {
    return (
      <NoContent>
        <StyledLoader size={44} />
      </NoContent>
    );
  };

  const renderDegreeList = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <SearchBox value={searchInput} handleSearch={handleSearch} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Select
              options={[
                { id: undefined, name: 'All' },
                { id: 'Pending', name: 'Pending' },
                { id: 'Accepted', name: 'Accepted' },
                { id: 'Rejected', name: 'Rejected' },
              ]}
              onChange={(option) => setStatus(option.id)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={status ? status : 'Approval Status'}
              value={status}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Candidate Count</TableCell>
              <TableCell>Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          {isFetchingAdminDegrees ? (
            renderLoader()
          ) : (
            <TableBody>
              {degreeData?.all_degrees?.length > 0 ? (
                degreeData?.all_degrees?.map((degree, index) => (
                  <TableRow key={degree.degree_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      onClick={() => {
                        navigate({
                          pathname: `/l/admin/specialization/${degree.degree_id}`,
                          search: `${degree.degree_id}`,
                        });
                        localStorage.setItem(
                          'selectedAdminDegree',
                          degree.name,
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <TagListName>{`${degree.name}`}</TagListName>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        navigate({
                          pathname: `/l/admin/specialization/${degree.degree_id}`,
                          search: `${degree.degree_id}`,
                        });
                        localStorage.setItem(
                          'selectedAdminDegree',
                          degree.name,
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {degree.candidate_count}
                    </TableCell>
                    <TableCell>{degree.approval_status}</TableCell>
                    <TableCell
                      align="right"
                      sx={{ minWidth: { xs: '50px', sm: '150px' } }}
                    >
                      <EditIcon
                        onClick={() => {
                          navigate({
                            pathname: `/l/admin/create/degree/${degree.degree_id}`,
                            search: `${degree.degree_id}`,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ minWidth: { xs: '50px', sm: '150px' } }}
                    >
                      <Delete
                        onClick={() =>
                          dispatch(deleteAdminDegree(degree.degree_id))
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <NoTag>No Such Degree Found </NoTag>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={degreeData?.page_size * degreeData?.total_pages}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      </div>
    );
  };

  const cardHeader = (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <TagListHeader>Degrees</TagListHeader>
      <div>
        <NavLink to="/l/admin/create/degree">
          <TagButton variant="outlined">Add Degree</TagButton>
        </NavLink>
      </div>
    </div>
  );

  return (
    <Container maxWidth={false}>
      <AppWrapper>
        {cardHeader}
        <Card content={renderDegreeList()} />
      </AppWrapper>
    </Container>
  );
};

export default AdminCreateDegree;
