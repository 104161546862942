import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from './../actions';

import CreateTag from './../components/CreateTag';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    question: state.question,
    tag: state.tag,
    domain: state.domain,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTag);
