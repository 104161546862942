import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import ClearIcon from '@mui/icons-material/Clear';
import { Check } from '@mui/icons-material';

const ContentHider = ({ child }) => {
  const [seeMore, setSeeMore] = useState(() => false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSeeMore((oldValue) => !oldValue);
  };

  const divStyles = {
    height: seeMore ? 'auto' : '50px',
    overflowY: 'hidden',
    overflowX: 'auto',
  };

  const buttonStyles = {
    padding: '5px 2px',
    marginTop: '5px',
    float: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#3572B0',
  };

  return (
    <>
      <div style={divStyles}>{child}</div>
      <button onClick={handleClick} style={buttonStyles}>
        {seeMore ? 'See Less' : 'See More'}
      </button>
    </>
  );
};

const AcceptModal = ({
  open,
  onClose,
  selectedQuesIds,
  setSelectedQuesIds,
  postAccepted,
}) => {
  const reviewQuestionsList = useSelector(
    (state) => state.question.reviewQuestionsList,
  );
  const allTags = useSelector((state) => state.tag.allTags);

  const selectedQuestions = useMemo(
    () =>
      reviewQuestionsList
        ?.map((question, index) => ({
          index,
          questionId: question.id,
          questionText: question.question_text,
          tagId: question.tag_id,
        }))
        ?.filter(
          (question) =>
            selectedQuesIds.findIndex((id) => id === question.questionId) !==
            -1,
        ),
    [reviewQuestionsList, selectedQuesIds],
  );

  const newTagQuestions = useMemo(
    () =>
      selectedQuestions.filter(
        ({ tagId }) => allTags.findIndex((tag) => tag.id === tagId) === -1,
      ),
    [selectedQuestions, allTags, selectedQuesIds],
  );

  const [newTagQuestionsMasks, setNewTagQuestionsMasks] = useState(() =>
    newTagQuestions?.map(() => false),
  );

  const handleSwitchQuestion = (index, type) => {
    setNewTagQuestionsMasks((prevState) => [
      ...prevState.slice(0, index),
      type,
      ...prevState.slice(index + 1),
    ]);
  };

  const handleAccept = () => {
    const existingTagQuestions = selectedQuestions.filter(
      ({ tagId }) => allTags.findIndex((tag) => tag.id === tagId) !== -1,
    );
    const selectedNewTagQuestions = newTagQuestions.filter(
      (question, index) => newTagQuestionsMasks[index],
    );
    const questionIdsToPost = [
      ...existingTagQuestions,
      ...selectedNewTagQuestions,
    ].map((question) => question.questionId);
    setSelectedQuesIds(questionIdsToPost);
    postAccepted(questionIdsToPost);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
        sx={{
          overflow: 'hidden',
        }}
        onClose={onClose}
      >
        <DialogTitle id="responsive-dialog-title">
          Hold Up! Accepting the following questions will create new categories
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {newTagQuestions.map((question, index) => (
              <Box
                key={question.questionId}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                {question.index + 1}.{' '}
                <ContentHider child={ReactHtmlParser(question.questionText)} />
                <IconButton
                  sx={{
                    marginLeft: 'auto',
                  }}
                  onClick={() => handleSwitchQuestion(index, false)}
                >
                  <ClearIcon
                    sx={{
                      color: !newTagQuestionsMasks[index] ? 'red' : 'inherit',
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => handleSwitchQuestion(index, true)}>
                  <Check
                    sx={{
                      color: newTagQuestionsMasks[index] && 'green',
                    }}
                  />
                </IconButton>
              </Box>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button color="success" onClick={() => handleAccept()}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AcceptModal;
