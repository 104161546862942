import * as React from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import { CustomDialogBox } from '../DialogBox';
import makeStyles from '@mui/styles/makeStyles';
import AnswerIncorrectOption from './AnswerIncorrectOption';
import TimeTooLessOption from './TimeTooLessOption';
import IncorrectOptions from './IncorrectOptions';
import IncorrectQuestion from './IncorrectQuestion';
import IncorrectSolution from './IncorrectSolution';
import PlagiarisedOption from './PlagiarisedOption';
import CategoryOption from './CategoryOption';
import SubcategoryOption from './SubcategoryOption';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '36px',
    marginBottom: '5px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 600,
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '80%',
  },
  modalLabel: {
    marginBottom: '20px',
  },
  divLabel: {
    // overflow: 'auto'
  },
  actions: {
    marginTop: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  box: {
    background: '#F0F0F0',
    padding: theme.spacing(2),
    borderRadius: '8px',
  },
}));

const getReportedQuestionReason = (type) => {
  const reportQuestionReasons = [
    {
      label:
        type === 'OneWord'
          ? 'The Answer is wrong'
          : 'Different Option(s) is correct',
      value: 'answer_incorrect',
    },
    ...(type === 'MultipleChoice'
      ? [
          {
            label: 'No Option is correct',
            value: 'no_option_correct',
          },
          {
            label: 'The option text is incorrect',
            value: 'incorrect_options',
          },
        ]
      : []),
    {
      label: 'Grammatical/Formatting Issues in the question text',
      value: 'grammatical_issues',
    },
    {
      label: 'Grammatical/Formatting Issues in the solution text',
      value: 'grammatical_issues_solution',
    },
    {
      label: 'Question text is unclear/wrong',
      value: 'incorrect_question',
    },
    {
      label: 'Question Category suggestion',
      value: 'incorrect_category',
    },
    {
      label: 'Question Subcategory suggestion',
      value: 'incorrect_subcategory',
    },
    {
      label: 'Time Limit suggestion',
      value: 'time_too_less',
    },
    {
      label: 'Question is plagiarised',
      value: 'plagiarised',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  return reportQuestionReasons;
};

export default function ReportQuestionModal({
  open,
  onClose,
  onSubmitClick,
  question,
  selectedAnswer,
  categories,
}) {
  const classes = useStyles();

  const [selectedReason, setSelectedReason] = React.useState('');
  const [reasonBody, setReasonBody] = React.useState({});
  const [details, setDetails] = React.useState('');
  const { data, answerStatus } = question;
  const { allTags } = useSelector((state) => state.tag);

  React.useEffect(() => {
    switch (selectedReason) {
      case 'incorrect_question':
        handleReasonBodyChange({
          correct_text: question.data.question_text,
          reason_type: 'UnclearContent',
        });
        break;
      case 'grammatical_issues':
        handleReasonBodyChange({
          correct_text: question.data.question_text,
          reason_type: 'QuestionTextImprovement',
        });
        break;
      case 'grammatical_issues_solution':
        handleReasonBodyChange({
          correct_text: answerStatus?.solution_text ?? '',
          reason_type: 'SolutionTextImprovement',
        });
        break;
      case 'time_too_less':
        handleReasonBodyChange({
          correct_values: [question.data.max_time],
          reason_type: 'Timeout',
        });
        break;
      case 'incorrect_category':
        handleReasonBodyChange({
          correct_id: question.data.tag_id,
          reason_type: 'CategoryIncorrect',
        });
        break;
      case 'incorrect_subcategory':
        handleReasonBodyChange({
          correct_ids: question.data.sub_tag_array,
          reason_type: 'SubcategoryIncorrect',
        });
        break;
      case 'plagiarised':
        handleReasonBodyChange({
          correct_title: '',
          reason_type: 'Plagiarised',
        });
        break;
      case 'incorrect_options':
        handleReasonBodyChange({
          correct_text: '',
          reason_type: 'OptionTextIncorrect',
        });
        break;
      case 'no_option_correct':
        handleReasonBodyChange({
          correct_text: '',
          reason_type: 'NoAnswerCorrect',
        });
        break;
      default:
        break;
    }
  }, [selectedReason, question.data, answerStatus]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedReason(value);
  };

  const handleReasonBodyChange = (value) => {
    setReasonBody({
      ...value,
    });
  };

  const onSelectOtherOption = (e) => {
    if (selectedReason === 'other') {
      const otherReasonBody = {
        reason_type: 'Other',
        correct_text: '',
        details: e.target.value,
      };
      setReasonBody(otherReasonBody);
    } else {
      setDetails(e.target.value);
    }
  };

  const onSubmit = () => {
    onSubmitClick({ ...reasonBody, details });
  };

  const getCategoryById = (list = [], id) => {
    return list.find((item) => item.id === id) || {};
  };

  const selectedCategoryById = getCategoryById(allTags, data?.tag_id);
  const expandReportOption = (value) => {
    switch (value) {
      case 'answer_incorrect':
        return (
          <AnswerIncorrectOption
            questionData={question.data}
            selectedAnswer={selectedAnswer}
            onChange={handleReasonBodyChange}
            value={reasonBody}
          />
        );
      case 'time_too_less':
        return (
          <TimeTooLessOption
            onChange={handleReasonBodyChange}
            value={reasonBody}
          />
        );
      case 'incorrect_options':
        return (
          <IncorrectOptions
            options={question.data.options}
            value={reasonBody}
            onChange={handleReasonBodyChange}
          />
        );
      case 'incorrect_question':
        return (
          <IncorrectQuestion
            question={question}
            value={reasonBody}
            onChange={handleReasonBodyChange}
            title="Enter appropriate question"
          />
        );
      case 'plagiarised':
        return (
          <PlagiarisedOption
            onChange={handleReasonBodyChange}
            value={reasonBody}
          />
        );
      case 'grammatical_issues':
        return (
          <IncorrectQuestion
            value={reasonBody}
            question={question}
            onChange={handleReasonBodyChange}
            title="Enter grammatical issue"
          />
        );
      case 'grammatical_issues_solution':
        return (
          <IncorrectSolution
            value={reasonBody}
            question={question}
            onChange={handleReasonBodyChange}
            title="Enter grammatical issue"
          />
        );
      case 'incorrect_category':
        return (
          <CategoryOption
            onChange={handleReasonBodyChange}
            value={reasonBody}
            categories={categories}
          />
        );
      case 'incorrect_subcategory':
        return (
          <SubcategoryOption
            onChange={handleReasonBodyChange}
            value={reasonBody}
            subCategoryOptions={selectedCategoryById.sub_tags}
          />
        );

      case 'no_option_correct':
        return null;

      default:
        return null;
    }
  };

  const reasons = getReportedQuestionReason(data.question_type);

  return (
    <CustomDialogBox
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      maxWidth="sm"
      title="Select a reason"
      actions={
        <>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={!selectedReason}>
            Submit
          </Button>
        </>
      }
    >
      <div className={classes.divLabel}>
        <RadioGroup aria-label="option" name="option">
          {reasons.map((reason) => {
            return (
              <React.Fragment key={reason.value}>
                <FormControlLabel
                  value={reason.value}
                  control={
                    <Radio
                      onChange={handleChange}
                      checked={reason.value === selectedReason}
                    />
                  }
                  label={reason.label}
                />
                {reason.value === selectedReason && (
                  <Box className={classes.box}>
                    <div style={{ marginBottom: '16px' }}>
                      {expandReportOption(selectedReason)}
                    </div>
                    {selectedReason !== 'time_too_less' &&
                      selectedReason !== 'incorrect_category' &&
                      selectedReason !== 'incorrect_subcategory' && (
                        <FormControl fullWidth>
                          <FormLabel
                            component="legend"
                            style={{ color: 'black', fontSize: 'inherit' }}
                          >
                            Additional Comments (Optional)
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            size="small"
                            style={{
                              marginTop: '8px',
                              backgroundColor: 'white',
                            }}
                            onChange={onSelectOtherOption}
                            placeholder="Reasons for this change..."
                          />
                        </FormControl>
                      )}
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </RadioGroup>
      </div>
    </CustomDialogBox>
  );
}
