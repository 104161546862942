import { styled } from '@mui/styles';

export const StyledNewCommentInput = styled('div')(({ theme }) => ({
  '& .new-comment-input-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 0 2px ${theme.palette.component.shadowColor}`,
    borderRadius: '8px',
    padding: '2%',
    '&.new-comment-input-wrapper-sub': {
      boxShadow: 'none',
      padding: '2% 0',
    },
  },
  '& .new-comment-input-wrapper-sub': {
    boxShadow: 'none',
    padding: '2% 0',
  },

  '& .new-comment-text': {
    flex: '5 1 auto',
  },

  '& .new-comment-input-label': {
    fontSize: '12px',
    color: 'black',
    fontWeight: 500,
  },

  '& .new-comment-type': {
    flex: '2.5 1 0',
    minWidth: '250px',
  },

  '& .new-discussion-input > *': {
    color: theme.palette.secondary.main,
  },

  '& .new-input-send-icon': {
    width: '1.2rem',
    color: theme.palette.secondary.main,
  },
}));
