import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const collegeDashboard = createReducer(
  {
    userCollegeData: [],
    collegeRelatedData: [],
    leaderboardData: [],
    collegeJobData: [],
    collegeStatsData: [],
    docUrl: [],
    milestoneData: [],
    milestoneImgUrl: [],
  },
  {
    [types.GET_USER_COLLEGES_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        userCollegeData: [],
        isFetchingUserColleges: true,
      });

      return newState;
    },
    [types.GET_USER_COLLEGES_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        userCollegeData: action.data,
        isFetchingUserColleges: false,
      });

      return newState;
    },
    [types.GET_USER_COLLEGES_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        userCollegeData: [],
        isFetchingUserColleges: false,
      });

      return newState;
    },

    [types.GET_COLLEGE_RELATED_DETAILS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeRelatedData: [],
        isFetchingCollegeDetails: true,
      });

      return newState;
    },
    [types.GET_COLLEGE_RELATED_DETAILS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeRelatedData: action.data,
        isFetchingCollegeDetails: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_RELATED_DETAILS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeRelatedData: [],
        isFetchingCollegeDetails: false,
      });

      return newState;
    },

    [types.GET_COLLEGE_LEADERBOARD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        leaderboardData: [],
        isFetchingCollegeLeaderboard: true,
      });

      return newState;
    },
    [types.GET_COLLEGE_LEADERBOARD_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        leaderboardData: action.data,
        isFetchingCollegeLeaderboard: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_LEADERBOARD_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        leaderboardData: [],
        isFetchingCollegeLeaderboard: false,
      });

      return newState;
    },

    [types.GET_COLLEGE_JOBS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeJobData: [],
        isFetchingCollegeJobs: true,
      });

      return newState;
    },
    [types.GET_COLLEGE_JOBS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeJobData: action.data,
        isFetchingCollegeJobs: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_JOBS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeJobData: [],
        isFetchingCollegeJobs: false,
      });

      return newState;
    },

    [types.GET_COLLEGE_STATS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeStatsData: [],
        isFetchingCollegeStats: true,
      });

      return newState;
    },
    [types.GET_COLLEGE_STATS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeStatsData: action.data,
        isFetchingCollegeStats: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_STATS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeStatsData: [],
        isFetchingCollegeStats: false,
      });

      return newState;
    },

    [types.UPLOAD_STUDENT_VERIFICATION_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        docUrl: [],
        isUploadingStudentDoc: true,
      });

      return newState;
    },
    [types.UPLOAD_STUDENT_VERIFICATION_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        docUrl: action.data,
        isUploadingStudentDoc: false,
      });

      return newState;
    },
    [types.UPLOAD_STUDENT_VERIFICATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        docUrl: [],
        isUploadingStudentDoc: false,
      });

      return newState;
    },

    [types.POST_EDUCATION_DETAILS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEducationData: true,
      });

      return newState;
    },
    [types.POST_EDUCATION_DETAILS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEducationData: false,
      });

      return newState;
    },
    [types.POST_EDUCATION_DETAILS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEducationData: false,
      });

      return newState;
    },

    [types.POST_COLLEGE_DASHBOARD_MILESTONE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingMilestoneData: true,
      });

      return newState;
    },
    [types.POST_COLLEGE_DASHBOARD_MILESTONE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingMilestoneData: false,
        isPostingMilestoneDataSuccess: true,
      });

      return newState;
    },
    [types.POST_COLLEGE_DASHBOARD_MILESTONE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingMilestoneData: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_DASHBOARD_MILESTONE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        milestoneData: [],
        isFetchingMilestoneData: true,
        milestoneImgUrl: [],
      });

      return newState;
    },
    [types.GET_COLLEGE_DASHBOARD_MILESTONE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        milestoneData: action.data,
        isFetchingMilestoneData: false,
      });

      return newState;
    },
    [types.GET_COLLEGE_DASHBOARD_MILESTONE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        milestoneData: [],
        isFetchingMilestoneData: false,
      });

      return newState;
    },
    [types.DELETE_COLLEGE_DASHBOARD_MILESTONE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingMilestoneData: true,
      });

      return newState;
    },
    [types.DELETE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingMilestoneData: false,
      });

      return newState;
    },
    [types.DELETE_COLLEGE_DASHBOARD_MILESTONE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingMilestoneData: false,
      });

      return newState;
    },
    [types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEducationData: true,
      });

      return newState;
    },
    [types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEducationData: false,
        isUpdatingMilestoneDataSuccess: true,
      });

      return newState;
    },
    [types.UPDATE_COLLEGE_DASHBOARD_MILESTONE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEducationData: false,
      });

      return newState;
    },

    [types.UPLOAD_COLLEGE_MILESTONE_IMG_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingMilestoneImg: true,
      });

      return newState;
    },
    [types.UPLOAD_COLLEGE_MILESTONE_IMG_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingMilestoneImg: false,
        milestoneImgUrl: action.data?.url,
      });

      return newState;
    },
    [types.UPLOAD_COLLEGE_MILESTONE_IMG_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingMilestoneImg: false,
      });

      return newState;
    },
  },
);

export { collegeDashboard as default };
