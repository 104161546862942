import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getQuestionById,
  postQuestion,
  putQuestion,
  resetQuestionData,
  resetQuestionbyId,
} from '../../actions/question';
import helpers from '../../lib/helpers';
import Filters from '../Filters';
import Card from './../Card';
import FormProgress from './FormProgress';
import MetaData from './MetaData';
import PreviewForm from './PreviewForm';
import QuestionBody from './QuestionBody';
import { BlankSpaceValidation } from './Validations';
import List from '../QuestionList/List';
import { StyledEditQuestion } from './StyledComponent';

const CreateQuestion = (props) => {
  const [step, setStep] = React.useState(1);
  const [tabSelected, setTabSelected] = React.useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { question, tag, user, unlockSolution } = props;
  const { questionList, questionById } = question;

  //state of the questionList
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedTag, setSelectedTag] = React.useState('all');
  const [questionFilters, setquestionFilters] = React.useState({
    Active: false,
    UnderReview: false,
    Blocked: false,
    Draft: false,
  });
  const [showModal, setShowModal] = React.useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = React.useState(null);
  const [questionId, setQuestionId] = React.useState('');
  //sm = 600px
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('md'));

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const methods = useForm({
    defaultValues: {
      solution: {
        solution_text: '',
      },
    },
  });
  const { handleSubmit, reset, watch, setValue } = methods;
  const values = watch();

  const edit_qid = params.questionId;

  useEffect(() => {
    const qid = localStorage.getItem('new_question_id');
    if (qid) {
      setQuestionId(qid);
      dispatch(getQuestionById(qid));
    } else {
      if (!location.state?.tag_id && !location.state?.tag_name && !edit_qid) {
        navigate('/l/question');
      }
    }
    if (location.state?.tag_id) setValue('tag_id', location.state?.tag_id);

    if (location.state?.tag_name)
      setValue('tag_name', location.state?.tag_name);

    window.scrollTo(0, 0);
    const url = new URL(window.location.href);
    const query_string = url.search;
    const search_params = new URLSearchParams(query_string);
    const tag = search_params.get('tag');
    const status = search_params.get('status');
    const page = search_params.get('page');
    const pageSize = search_params.get('pageSize');

    var questionStatus = [];
    if (page) {
    }
    if (pageSize) {
      setRowsPerPage(pageSize);
    }
    if (tag) {
      setSelectedTag(tag);
    }
    if (status) {
      questionStatus = status.split(',');
      questionStatus.forEach((item) => {
        questionFilters[item] = true;
      });
    }
    getFilteredQuestionList(page, pageSize, questionStatus, tag);

    return () => {
      setQuestionId('');
      dispatch(resetQuestionbyId());
      dispatch(resetQuestionData());
      localStorage.removeItem('new_question_id');
    };
  }, []);

  useEffect(() => {
    if (edit_qid) {
      setQuestionId(edit_qid);
      dispatch(getQuestionById(edit_qid));
      setTabSelected(1);
    }
  }, [edit_qid]);

  const getFilteredQuestionList = (
    page,
    page_size,
    question_status,
    selectedTag,
  ) => {
    const { getQuestionList, user } = props;
    getQuestionList({
      page,
      page_size,
      question_status,
      tag_id: selectedTag,
      domain_id: user.selected_domain,
    });
  };

  const handleFilterChange = (name) => () => {
    let questionFilter = { ...questionFilters };
    if (questionFilter[name] === true) {
      questionFilter[name] = false;
    } else {
      questionFilter[name] = true;
    }
    const appliedFilters = Object.keys(questionFilter).filter(
      (status) => questionFilter[status],
    );
    setquestionFilters(questionFilter);

    navigate({
      pathname: '/l/question',
      search: `?page=1&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(1, rowsPerPage, appliedFilters, selectedTag);
  };

  const handleDeleteClick = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (showModal) {
      const { deleteQuestion } = props;
      await deleteQuestion({ questionId: deleteQuestionId });
      setShowModal(false);
      window.scrollTo(0, 0);
      const url = new URL(window.location.href);
      const query_string = url.search;
      const search_params = new URLSearchParams(query_string);
      const tag = search_params.get('tag');
      const status = search_params.get('status');
      const page = search_params.get('page');
      const pageSize = search_params.get('pageSize');
      var questionStatus = [];
      if (page) {
      }
      if (pageSize) {
        setRowsPerPage(pageSize);
      }
      if (tag) {
        setSelectedTag(tag);
      }
      if (status) {
        questionStatus = status.split(',');
        questionStatus.forEach((item) => {
          questionFilters[item] = true;
        });
      }
      getFilteredQuestionList(page, pageSize, questionStatus, tag);
    }
  };

  const handleRemoveFilter = () => {
    const questionFilter = {
      Active: false,
      UnderReview: false,
      Blocked: false,
      Draft: false,
    };
    const appliedFilters = [];
    setquestionFilters(questionFilter);

    navigate({
      pathname: '/l/question',
      search: `?page=1&pageSize=${rowsPerPage}&status=${appliedFilters.join(
        ',',
      )}&tag=${selectedTag}`,
    });
    getFilteredQuestionList(1, rowsPerPage, appliedFilters, selectedTag);
  };

  const handleDeleteAction = (questionId) => {
    setShowModal(true);
    setDeleteQuestionId(questionId);
  };

  const cardFilters = (
    <div className="filter-section">
      <Filters
        heading="Filters"
        type={'slider'}
        handleChange={handleFilterChange}
        handleRemoveFilter={handleRemoveFilter}
        filterValues={questionFilters}
      />
    </div>
  );

  const handleSubmitStatus = (status) => {
    switch (status) {
      case 'success':
        enqueueSnackbar('Congrats! Question is created successfully.', {
          variant: 'success',
        });
        break;
      case 'error':
        enqueueSnackbar('Sorry! Question is not created, please try again.', {
          variant: 'error',
        });
        break;

      default:
        break;
    }
    setTimeout(() => {
      navigate('/l/question/');
    }, 500);
  };

  const onNext = (data) => {
    if (
      question.createQuestionType != 'MultiPart' &&
      data.question_text === ''
    ) {
      enqueueSnackbar('Please provide valid question', {
        variant: 'error',
      });
      return;
    }
    if (
      question.createQuestionType != 'MultiPart' &&
      question.createQuestionType != 'OneWord' &&
      data.options.length < 2
    ) {
      enqueueSnackbar('Please enter minimum two options', {
        variant: 'error',
      });
      return;
    }
    if (
      question.createQuestionType != 'MultiPart' &&
      question.createQuestionType != 'OneWord' &&
      question.createQuestionType === 'MultipleChoice' &&
      data.answer_option_no.length === 0
    ) {
      enqueueSnackbar('Please select an option', {
        variant: 'error',
      });
      return;
    }
    if (
      question.createQuestionType != 'MultiPart' &&
      question.createQuestionType != 'OneWord' &&
      question.createQuestionType === 'MultipleCorrect' &&
      data.answer_option_no.length < 1
    ) {
      enqueueSnackbar('Please select at least one option', {
        variant: 'error',
      });
      return;
    }
    if (
      question.createQuestionType != 'MultiPart' &&
      data.solution.solution_text === ''
    ) {
      enqueueSnackbar('Please enter Solution', {
        variant: 'error',
      });
      return;
    }
    const questionTextForCheck = data.question_text?.replace(/<[^>]+>/g, '');
    if (!BlankSpaceValidation(data.question_text)) {
      enqueueSnackbar('Please provide a valid question', { variant: 'error' });
      return;
    }
    if (
      !Object.prototype.hasOwnProperty.call(data, 'sub_questions') &&
      !BlankSpaceValidation(data?.solution?.solution_text)
    ) {
      enqueueSnackbar('Please provide a valid solution for this question', {
        variant: 'error',
      });
      return;
    }

    for (let i = 0; i < data?.options?.length; i++) {
      let option = data?.options[i];
      if (!BlankSpaceValidation(option?.option_text)) {
        enqueueSnackbar('Option text cannot be empty', { variant: 'error' });
        return;
      }
    }

    if (data?.answer_one_word && data?.answer_one_word.length > 0) {
      if (!BlankSpaceValidation(data?.answer_one_word[0])) {
        enqueueSnackbar('Answer text cannot be empty', { variant: 'error' });
        return;
      }
    }

    if (data?.how_to_answer) {
      if (!BlankSpaceValidation(data?.how_to_answer)) {
        enqueueSnackbar('How to answer is invalid', { variant: 'error' });
        return;
      }
    }

    if (questionTextForCheck?.trim().length < 10) {
      enqueueSnackbar('Question must have minimum 10 characters', {
        variant: 'error',
      });
      return;
    }

    let metadata;
    if (data.question_type === 'OneWord') {
      metadata = {
        ...data,
        question_text: helpers.sanitizeHTML(data.question_text),
        question_type:
          question?.data?.question_type || question.createQuestionType,
        solution: {
          ...data.solution,
          solution_text:
            helpers.sanitizeHTML(data.solution.solution_text) || '',
        },
        question_source_url: data.question_source_url,
      };
      if (data.exam_id?.id) {
        metadata.exam_id = data.exam_id.id;
      } else {
        metadata.exam_name = data.exam_id?.value || undefined;
        metadata.exam_id = undefined;
      }
    } else {
      if (question.createQuestionType === 'MultiPart') {
        const subquestions = data?.sub_questions;
        if (subquestions.length < 2) {
          enqueueSnackbar('Sorry! Please add atleast two question', {
            variant: 'error',
          });
          return;
        }
        for (let i = 0; i < subquestions.length; i++) {
          const question = subquestions[i];
          if (
            ['MultipleChoice', 'MultipleCorrect'].includes(
              question?.question_type,
            ) &&
            (!question?.options || question?.options?.length < 2)
          ) {
            enqueueSnackbar(
              'Sorry! Please provide atleast two options in multiple choice question',
              {
                variant: 'error',
              },
            );
            return;
          }
          if (
            ['MultipleChoice', 'MultipleCorrect'].includes(
              question?.question_type,
            ) &&
            question?.answer_option_no?.length < 1
          ) {
            enqueueSnackbar(
              'Sorry! Please select atleast one option in multiple choice question',
              {
                variant: 'error',
              },
            );
            return;
          }
          if (
            question?.answer_one_word &&
            question?.answer_one_word?.length > 0
          ) {
            if (!BlankSpaceValidation(question?.answer_one_word[0])) {
              enqueueSnackbar('Answer text cannot be empty', {
                variant: 'error',
              });
              return;
            }
          }

          if (question?.how_to_answer) {
            if (!BlankSpaceValidation(question?.how_to_answer)) {
              enqueueSnackbar('How to answer is invalid', { variant: 'error' });
              return;
            }
          }
          const questionTextForCheck = question.question_text?.replace(
            /<[^>]+>/g,
            '',
          );
          if (questionTextForCheck?.trim()?.length < 10) {
            enqueueSnackbar('Question must have minimum 10 characters', {
              variant: 'error',
            });
            return;
          }
          if (question.solution_text === '') {
            enqueueSnackbar('Please enter Solution', {
              variant: 'error',
            });
            return;
          }
          if (!BlankSpaceValidation(question.question_text)) {
            enqueueSnackbar('Please provide a valid question', {
              variant: 'error',
            });
            return;
          }
        }
      }

      metadata = {
        ...data,
        // domain : props?.user?.selected_domain,
        question_text: helpers.sanitizeHTML(data.question_text),
        question_type:
          question?.data?.question_type || question.createQuestionType,
        solution: {
          ...data.solution,
          solution_text:
            helpers.sanitizeHTML(data.solution.solution_text) || '',
        },
        question_source_url: data.question_source_url,
      };

      if (
        ['MultipleChoice', 'MultipleCorrect'].includes(
          metadata.question_type,
        ) &&
        data.options.length < 2
      ) {
        enqueueSnackbar(
          'Sorry! Please give at least two options for Multiple Choice Questions.',
          {
            variant: 'error',
          },
        );
        return;
      }

      if (metadata.question_type === 'OneWord') {
        metadata = {
          ...metadata,
          answer_one_word: data?.answer_one_word,
          alternate_answer: undefined,
        };
      }
      if (data.exam_id?.id) {
        metadata.exam_id = data.exam_id.id;
      } else {
        metadata.exam_name = data.exam_id?.value || undefined;
        metadata.exam_id = undefined;
      }
    }

    metadata.tag = undefined;
    metadata.sub_tagid_array = undefined;
    if (data.tag?.id) {
      metadata.tag_id = data.tag.id;
    } else {
      metadata.tag_name = data.tag?.value || undefined;
      metadata.tag_id = undefined;
    }
    if (data.sub_tag_array?.length) {
      metadata.sub_tag_array = data.sub_tag_array
        .filter((tag) => tag.id && !tag.value)
        .map((tag) => tag.id);
      metadata.subtag_names = data.sub_tag_array
        ?.filter((tag) => tag.value)
        ?.map((tag) => tag.value);

      if (!metadata.subtag_names.length) {
        metadata.subtag_names = undefined;
      }
      if (!metadata.sub_tag_array.length) {
        metadata.sub_tag_array = undefined;
      }
    }

    delete data.alternate_answer;
    if (step === 3) {
      metadata.question_status = 'UnderReview';
      dispatch(putQuestion({ data: metadata, questionId }))
        .then(() => {
          handleSubmitStatus('success');
          localStorage.removeItem('new_question_id');
        })
        .catch(() => {
          handleSubmitStatus('error');
        });
    } else if (step == 1 && !questionId) {
      metadata.domain_id = props.user.selected_domain;
      if (location.state?.tag_id) metadata.tag_id = location.state?.tag_id;
      if (location.state?.tag_name)
        metadata.tag_name = location.state?.tag_name;
      dispatch(postQuestion(metadata)).then((res) => {
        setQuestionId(res.id);
        localStorage.setItem('new_question_id', res.id);
        setStep((step) => step + 1);
      });
    } else {
      metadata.question_status = 'Draft';
      dispatch(putQuestion({ data: metadata, questionId }))
        .then(() => {
          setStep(step + 1);
        })
        .catch(() => {
          handleSubmitStatus('error');
        });
    }
  };

  const onPrevious = () => {
    setStep(step - 1);
  };

  const getCategory = () => {
    return tag?.list;
  };

  const getSubCategory = () => {
    return values?.tag?.sub_tags;
  };

  React.useEffect(() => {
    let {
      question_type,
      answer_option_no,
      exam_type,
      max_time,
      options,
      question_text,
      solution,
      title,
      year,
      answer_one_word,
      tag_id,
      sub_tag_array,
    } = questionById || {};

    const questionData = {
      question_type,
      answer_option_no,
      exam_id: {
        label: exam_type,
        value: exam_type,
      },
      max_time,
      options,
      question_text,
      solution,
      title,
      year,
      answer_one_word,
      tag_id,
      sub_tagid_array: sub_tag_array,
    };

    if (questionById?.id) {
      reset(questionData);
    }
  }, [questionById]);

  const renderFormStep = (step_parameter) => {
    switch (step_parameter) {
      case 1:
        return (
          <>
            <QuestionBody question={question} user={user} />
            <Box flexGrow={1}></Box>
            <Box pt={4}>
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Box>
          </>
        );

      case 2:
        return (
          <>
            <MetaData categoryOptions={getCategory()} />
            <Box flexGrow={1}></Box>
            <Box pt={4}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onPrevious}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        );

      case 3:
        return (
          <>
            <PreviewForm
              unlockSolution={unlockSolution}
              category={getCategory()}
              subCategory={getSubCategory()}
              tag={tag}
              question_type={
                question?.data?.question_type || question?.createQuestionType
              }
            />
            <Box flexGrow={1}></Box>
            <Box pt={4}>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                style={{ marginTop: '50px', flexWrap: 'nowrap' }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onPrevious}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => navigate('/l/question')}
                    >
                      Save as draft
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  const cardContent =
    tabSelected === 1 ? (
      <form onSubmit={handleSubmit(onNext)}>
        <FormProvider {...methods}>
          <Grid container spacing={3}>
            {breakpoint && (
              <>
                <Grid item lg={3} md={3} sm={0} xs={0}>
                  <Box pb={7}>
                    <FormProgress step={step} />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                {renderFormStep(step)}
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    ) : (
      <>
        {cardFilters}
        <List
          questionList={questionList}
          handleDeleteAction={handleDeleteAction}
          tag={props.tag}
        />
      </>
    );

  const TabSelector = () => {
    return (
      <div className="tabSelector">
        <div
          className={tabSelected === 1 ? 'tabSelected' : ''}
          onClick={() => {
            localStorage.setItem('openCategoryModal', true);
            setTabSelected(1);
          }}
        >
          <Typography variant="h6">Create Question</Typography>
        </div>
        <div
          className={tabSelected === 1 ? '' : 'tabSelected'}
          onClick={() => {
            localStorage.setItem('openCategoryModal', false);
            setTabSelected(2);
          }}
        >
          <Typography variant="h6">Question List</Typography>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth={false}>
      <StyledEditQuestion>
        <div
          onClick={() => {
            navigate('/l/question');
          }}
          style={{
            display: 'flex',
            gap: '04px',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',
          }}
        >
          <ArrowBack fontSize="1rem" />
          <h3>Back</h3>
        </div>
        <TabSelector />
        <Card content={cardContent} />

        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to remove this question?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteClick()}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </StyledEditQuestion>
    </Container>
  );
};

export default CreateQuestion;
