import React, { useState, useRef } from 'react';
import { Button, Box } from '@mui/material';
import { useEffect } from 'react';
import { StyledImagePicker } from './StyledComponent';

const ImagePicker = ({ imageUrl, onFileLoad }) => {
  const [data, setData] = useState(imageUrl || '');
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setData(imageUrl);
    setLoading(false);
  }, [imageUrl]);

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        setLoading(true);
        setData('');
      };

      reader.onload = (event) => {
        setData(event.target.result);
        setLoading(false);
      };

      reader.readAsDataURL(files[0]);
      onFileLoad(files[0]);
    }
  };

  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      setLoading(true);
      setData('');
    };

    reader.onload = (event) => {
      setData(event.target.result);
      setLoading(false);
    };

    reader.readAsDataURL(file);
    onFileLoad(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      handleFile(droppedFile);
    }
  };

  const handlePreviewClick = () => {
    if (!data) {
      return;
    }

    setFullScreen(!fullScreen);
  };

  const backgroundData = data ? { backgroundImage: `url("${data}")` } : null;

  const previewClasses = [
    'preview',
    fullScreen ? 'preview--fullscreen' : '',
  ].join(' ');

  const fullScreenBackdropClass = fullScreen
    ? 'preview--fullscreen--backdrop'
    : '';

  return (
    <StyledImagePicker>
      <div
        className="image-picker"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <input
          id="car"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
        />

        <div
          className={fullScreenBackdropClass}
          onClick={() => setFullScreen(!fullScreen)}
        >
          <Box
            sx={{
              p: '5px',
              border: '2px solid rgba(53, 114, 176, 0.50)',
              borderRadius: '50%',
            }}
          >
            <Box
              className={previewClasses}
              sx={backgroundData}
              onClick={handlePreviewClick}
            >
              {!loading && !data && (
                <label htmlFor="car">Click to capture</label>
              )}
              {loading && <span>Loading...</span>}
            </Box>
          </Box>
        </div>
        {data && (
          <Button
            variant="contained"
            color="primary"
            className="clear-button"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            Change
          </Button>
        )}
      </div>
    </StyledImagePicker>
  );
};

export default ImagePicker;
