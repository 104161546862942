import createReducer from '../lib/createReducer';
import {
  CLEAR_COMMENT_REPORTS_STATE,
  GET_COMMENT_REPORTS_FAIL,
  GET_COMMENT_REPORTS_START,
  GET_COMMENT_REPORTS_SUCCESS,
  REVIEW_COMMENT_FAIL,
  REVIEW_COMMENT_START,
  REVIEW_COMMENT_SUCCESS,
  GET_ALL_COMMENT_START,
  GET_ALL_COMMENT_SUCCESS,
  GET_ALL_COMMENT_FAIL,
} from '../actions/types';

const initialState = {
  commentReports: [],
  allComments: [],
  isFetchingComments: false,
  commentFetchError: '',
  allCommentFetchError: '',
  currentPageNumber: '0',
  totalNumberOfPages: 0,
  reviewingAComment: false,
  error: '',
};

export const commentReports = createReducer(initialState, {
  [GET_COMMENT_REPORTS_START](state) {
    return { ...state, isFetchingComments: true };
  },
  [GET_COMMENT_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      isFetchingComments: false,
      commentReports: action.data?.reported_comments,
      currentPageNumber: action.data?.page_no,
      totalNumberOfPages: action.data?.total_pages,
    };
  },
  [GET_COMMENT_REPORTS_FAIL](state, action) {
    return {
      ...state,
      isFetchingComments: false,
      commentFetchError: action.data,
    };
  },

  [GET_ALL_COMMENT_START](state) {
    return { ...state, isFetchingComments: true };
  },
  [GET_ALL_COMMENT_SUCCESS](state, action) {
    return {
      ...state,
      isFetchingComments: false,
      allComments: action.data?.comments,
      currentPageNumber: action.data?.page,
      totalNumberOfPages: action.data?.total_pages,
    };
  },
  [GET_ALL_COMMENT_FAIL](state, action) {
    return {
      ...state,
      isFetchingComments: false,
      allCommentFetchError: action.data,
    };
  },

  [REVIEW_COMMENT_START](state) {
    return {
      ...state,
      reviewingAComment: true,
    };
  },

  [REVIEW_COMMENT_SUCCESS](state, action) {
    const { commentId } = action.data;

    const dummyComments = window.structuredClone
      ? window.structuredClone(state.commentReports)
      : JSON.parse(JSON.stringify(state.commentReports));

    const targetIndex = dummyComments?.findIndex(
      (comment) => comment?.comment_id === commentId,
    );

    targetIndex !== -1 && dummyComments.splice(targetIndex, 1);

    return {
      ...state,
      reviewingAComment: true,
      commentReports: dummyComments,
    };
  },

  [REVIEW_COMMENT_FAIL](state, action) {
    return { ...state, error: action.data };
  },

  [CLEAR_COMMENT_REPORTS_STATE]() {
    return { ...initialState };
  },
});
