import * as React from 'react';
import { Typography, Grid, Box, CircularProgress, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select, { components } from 'react-select';

import helpers from '../../lib/helpers';
import UserCard from './UserCard';
import UserActivity from './UserActivity';
import PractiseTab from './PractiseTab';
import CompetitionTab from './CompetitionTab';
import AuthorProfileTab from './AuthorProfileTab';
import { useDispatch } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  getFollowedUsersList,
  getUserPublicProfile,
} from '../../actions/publicProfile';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  UPIWrapper,
  UPITopContainer,
  UPIUserCardContainer,
  UPICalendar,
  UPIDomainSelectContainer,
  UPIBottomContainer,
  UPITabBtnsWrapper,
  UPITabBtnsContainer,
  UPITabBtn,
  UPITabText,
  UPITabActiveBtn,
  UPITabInactiveBtn,
  UPITab,
} from './UserProfileStyledComponent';

const selectStyles = (theme) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? theme.palette.primary.light
        : theme.palette.primary.main,
      color: 'white',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: 'white' }),
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'white',
    '&:hover': { color: 'white' },
  }),
  menu: (styles) => ({
    ...styles,
    background: theme.palette.primary.main,
    zIndex: 100,
  }),
});

const UserprofileInfo = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();

  const { user, publicProfile } = props;
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeDomain, setActiveDomain] = React.useState();
  const [followingUser, setFollowingUser] = React.useState(false);

  const isUserActivityFetching = publicProfile.isUserActivityFetching;

  const domainOptions = React.useMemo(
    () =>
      Object.values(publicProfile.profileData?.analytics?.domains || {}).map(
        (domain) => ({
          label: domain.domain_name,
          value: domain.domain_id,
          icon: domain.img_url,
        }),
      ),
    [publicProfile.profileData],
  );

  const { Option } = components;

  const domainIconOptions = (props) => (
    <Option {...props}>
      <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <img src={props.data.icon} style={{ width: '22px', height: '22px' }} />
        <Typography color="inherit">{props.data.label}</Typography>
      </Box>
    </Option>
  );

  const profileUsername = params.username;
  React.useEffect(() => {
    const domainId = helpers.getURLParameterByName('domain_id');
    if (domainId) {
      const domain = domainOptions.find((domain) => domain.value === domainId);
      if (domain) {
        setActiveDomain(domain);
      } else {
        setActiveDomain(domainOptions?.[0]);
      }
    } else {
      setActiveDomain(domainOptions?.[0]);
    }
  }, [domainOptions, profileUsername]);

  React.useEffect(() => {
    dispatch(getUserPublicProfile(profileUsername || user.data?.username));
    dispatch(getFollowedUsersList());
  }, [profileUsername]);

  React.useEffect(() => {
    const userId = publicProfile.followedUserList.find(
      (userId) => userId === publicProfile?.profileData?.user?.id,
    );
    if (userId) {
      setFollowingUser(true);
    } else {
      setFollowingUser(false);
    }
  }, [publicProfile, publicProfile?.followedUserList]);

  if (publicProfile.profileData?.success === false) {
    return (
      <Grid
        style={{
          background: 'white',
          padding: '20px',
          margin: '0px 20px',
        }}
      >
        <Typography
          color="primary"
          style={{
            fontWeight: '600',
            fontSize: '18px',
          }}
        >
          Something Failed please try again later
        </Typography>
      </Grid>
    );
  }
  const handleRedirect = () => {
    navigate(localStorage.getItem('mainUrl'));
  };
  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {!publicProfile.profileData?.user ? (
        <CircularProgress />
      ) : (
        <UPIWrapper>
          {location.key !== 'default' && (
            <Button
              sx={{
                '& .MuiButton-startIcon': {
                  mr: '0px',
                },
              }}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={handleRedirect}
            >
              Back
            </Button>
          )}
          <UPITopContainer>
            <UPIUserCardContainer>
              <UserCard
                data={publicProfile?.profileData}
                isSelf={user?.data?.id === publicProfile.profileData?.user?.id}
                followingUser={followingUser}
                {...props}
              />
              {/* <Grid className={classes.badgesWrapper}>
                <Typography color="primary" style={{ fontSize: '18px' }}>
                  Badges
                </Typography>
                <Grid className={classes.badgesContainer}>
                  <Box className={classes.badgeCountContainer}>
                    <Typography color="primary" className={classes.badgeCount}>
                      {publicProfile?.profileData?.analytics?.overall
                        ?.badge_count || 0}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                      Unlocked
                    </Typography>
                  </Box>
                  <Box></Box>
                </Grid>
              </Grid> */}
            </UPIUserCardContainer>
            <UPICalendar>
              <UserActivity
                activity={publicProfile?.user_activity}
                isUserActivityFetching={isUserActivityFetching}
                username={profileUsername || user.data?.username}
              />
            </UPICalendar>
          </UPITopContainer>
          <UPIBottomContainer>
            <UPITabBtnsWrapper>
              <UPITabBtnsContainer>
                {activeTab === 0 ? (
                  <UPITabActiveBtn>
                    <UPITabBtn>
                      <UPITabText
                        style={{
                          borderRight: activeTab === 2 && '1px solid',
                          borderColor: '#25507B',
                        }}
                        onClick={() => setActiveTab(0)}
                      >
                        Practise
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabActiveBtn>
                ) : (
                  <UPITabInactiveBtn>
                    <UPITabBtn>
                      <UPITabText
                        style={{
                          borderRight: activeTab === 2 && '1px solid',
                          borderColor: '#25507B',
                        }}
                        onClick={() => setActiveTab(0)}
                      >
                        Practise
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabInactiveBtn>
                )}

                {activeTab === 1 ? (
                  <UPITabActiveBtn>
                    <UPITabBtn>
                      <UPITabText onClick={() => setActiveTab(1)}>
                        Competition
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabActiveBtn>
                ) : (
                  <UPITabInactiveBtn>
                    <UPITabBtn>
                      <UPITabText onClick={() => setActiveTab(1)}>
                        Competition
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabInactiveBtn>
                )}

                {activeTab === 2 ? (
                  <UPITabActiveBtn>
                    <UPITabBtn>
                      <UPITabText
                        style={{
                          borderLeft: activeTab === 0 && '1px solid',
                          borderColor: '#25507B',
                        }}
                        onClick={() => setActiveTab(2)}
                      >
                        Author Profile
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabActiveBtn>
                ) : (
                  <UPITabInactiveBtn>
                    <UPITabBtn>
                      <UPITabText
                        style={{
                          borderLeft: activeTab === 0 && '1px solid',
                          borderColor: '#25507B',
                        }}
                        onClick={() => setActiveTab(2)}
                      >
                        Author Profile
                      </UPITabText>
                    </UPITabBtn>
                  </UPITabInactiveBtn>
                )}
              </UPITabBtnsContainer>
              <UPIDomainSelectContainer>
                <Select
                  options={domainOptions}
                  onChange={(currDomain) => {
                    navigate(
                      `${window.location.pathname}?domain_id=${currDomain.value}`,
                    );
                    setActiveDomain(currDomain);
                  }}
                  value={activeDomain}
                  styles={selectStyles(theme)}
                  getOptionLabel={(opt) => (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        background: 'transparent',
                      }}
                    >
                      <img
                        src={opt.icon}
                        style={{
                          width: '22px',
                          height: '22px',
                        }}
                      />
                      <Typography color="inherit">{opt.label}</Typography>
                    </Box>
                  )}
                  getOptionValue={(opt) => opt.value}
                  components={{ Option: domainIconOptions }}
                />
              </UPIDomainSelectContainer>
            </UPITabBtnsWrapper>
            <UPITab
              sx={{
                borderTopRightRadius: {
                  xs: activeTab === 2 ? '0px !important' : '10px',
                  sm: '10px !important',
                },
                borderTopLeftRadius:
                  activeTab === 0 ? '0px !important' : '10px',
              }}
            >
              {activeTab === 0 && (
                <PractiseTab
                  analytics={publicProfile?.profileData?.analytics}
                  activeDomain={activeDomain}
                  isSelf={
                    user?.data?.id === publicProfile.profileData?.user?.id
                  }
                />
              )}
              {activeTab === 1 && (
                <CompetitionTab
                  analytics={publicProfile?.profileData?.analytics}
                  isSelf={
                    user?.data?.id === publicProfile.profileData?.user?.id
                  }
                />
              )}
              {activeTab === 2 && (
                <AuthorProfileTab
                  creator_stats_refined={
                    publicProfile?.profileData?.creator_stats_refined
                  }
                  creator_stats={publicProfile?.profileData?.creator_stats}
                  isSelf={
                    user?.data?.id === publicProfile.profileData?.user?.id
                  }
                />
              )}
            </UPITab>
          </UPIBottomContainer>
        </UPIWrapper>
      )}
    </Grid>
  );
};

export default UserprofileInfo;
