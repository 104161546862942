import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const adminUserManagement = createReducer(
  {
    adminUsersList: {},
    fetchedUserToken: {},
  },
  {
    [types.GET_ADMIN_USERS_LIST_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingAdminUsersList: true,
      });

      return newState;
    },
    [types.GET_ADMIN_USERS_LIST_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        adminUsersList: action.data,
        isFetchingAdminUsersList: false,
      });

      return newState;
    },
    [types.GET_ADMIN_USERS_LIST_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingAdminUsersList: false,
      });

      return newState;
    },

    [types.GET_USER_TOKEN_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserToken: true,
      });

      return newState;
    },
    [types.GET_USER_TOKEN_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        fetchedUserToken: action.data?.token,
        isFetchingUserToken: false,
      });

      return newState;
    },
    [types.GET_USER_TOKEN_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserToken: false,
      });

      return newState;
    },
  },
);

export { adminUserManagement as default };
