import { Divider, Grid, styled, Typography, Box } from '@mui/material';

export const UploadImageIconContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #EE9D00',
  padding: '10px',
  borderRadius: '5px',
  width: '60px',
  height: '60px',
});

export const IconTextContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: '150px',
});

export const DescriptionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.main,
  marginBottom: '5px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}));
export const DescriptionContent = styled(Box)(({ theme }) => ({
  '& > *': {
    fontSize: '14px',
  },
  '*:not(:not(:last-child) ~ *)': {
    margin: '0px',
  },
  [theme.breakpoints.down('md')]: {
    '& > *': {
      fontSize: '12px',
    },
  },
}));

export const JobInfoIcon = styled('img')(({ theme }) => ({
  color: theme.palette.text.main,
  width: '22px',
  height: '20px',
  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '18px',
  },
}));

export const JobInfoText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  [theme.breakpoints.down('md')]: {
    fontStyle: '10px',
  },
}));

export const LocationText = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const CustomDivider = styled(Divider)({
  height: '1px',
  backgroundColor: '#7B7B7B33',
  borderBottomWidth: '0px !important',
});
