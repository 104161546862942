import {
  CLEAR_MATCHING_USERS_STATE,
  GET_MATCHING_USERS_FAIL,
  GET_MATCHING_USERS_START,
  GET_MATCHING_USERS_SUCCESS,
} from './types';
import apiClient from '../api/apiclient';

export const getMatchingUsersStart = () => ({
  type: GET_MATCHING_USERS_START,
});

export const getMatchingUsersSuccess = ({ data }) => ({
  type: GET_MATCHING_USERS_SUCCESS,
  data,
});

export const getMatchingUsersFail = ({ data }) => ({
  type: GET_MATCHING_USERS_FAIL,
  data,
});

export const getMatchingUsers = (
  pageNumber,
  pageSize,
  searchQuery,
  tagId,
  domainId,
) => async (dispatch) => {
  dispatch(getMatchingUsersStart());
  try {
    const res = await apiClient
      .getGeneralApi()
      .generalApiQuestionAuthorAutocomplete(
        domainId,
        pageNumber,
        pageSize,
        searchQuery,
        tagId,
      );
    dispatch(
      getMatchingUsersSuccess({
        data: res.data,
      }),
    );
  } catch (error) {
    dispatch(
      getMatchingUsersFail({
        data: error,
      }),
    );
  }
};

export const clearMatchingUsersState = () => ({
  type: CLEAR_MATCHING_USERS_STATE,
});
