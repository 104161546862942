import { Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import credit from './images/credits.png';
import {
  StyledActionModalTitle,
  StyledActionModalTitleImage,
  StyledTitleContainer,
  StyledTitleRight,
} from './DashboardStyledComponent';
const ActionModal = ({
  open,
  onClose,
  content,
  title,
  onYes,
  endTask,
  credits,
  titleRight,
  actionButtonDisable,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <StyledTitleContainer>
        <StyledActionModalTitle id="alert-dialog-title">
          <span>{title}</span>
          <StyledActionModalTitleImage src={credit} />
          <span> {credits} credits</span>
        </StyledActionModalTitle>
        {titleRight && <StyledTitleRight>{titleRight}</StyledTitleRight>}
      </StyledTitleContainer>
      <DialogContent>{content}</DialogContent>
      <DialogContent
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
        <Button
          onClick={onYes}
          color="primary"
          autoFocus
          variant="contained"
          disabled={actionButtonDisable}
        >
          Save & {endTask ? 'Finish' : 'Next'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ActionModal;
