import { styled } from '@mui/styles';

export const StyledReportModal = styled('div')(({ theme }) => ({
  '& .modal-wrapper, & .report-error-wrapper': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    width: 'min(100%, 500px)',
    padding: '2%',
    minHeight: 'min(100%, 400px)',
    letterSpacing: '0.8px',
    lineHeight: '1.2em',
    boxShadow: `0px 0px 5px ${theme.palette.component.shadowColor}`,
    borderRadius: '10px',
  },

  '& .report-loader': {
    alignSelf: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '& .modal-wrapper > *': {
    marginTop: '12px !important',
  },

  '& .report-modal-top-close-button': {
    border: 'none',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: '6%',
    right: '5%',
    cursor: 'pointer',
    padding: '0',
  },

  '& .report-modal-back-button': {
    float: 'none !important',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  '& .report-modal-back-button > img': {
    width: '1.5em',
  },

  '& .report-heading': {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    fontSize: '1.2em',
  },

  '& .about-report': {
    color: theme.palette.common.black,
  },

  '& .report-types': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px 8px',
  },

  '& .report-type': {
    padding: '1% 2%',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.text.main}`,
    borderRadius: '5px',
    color: theme.palette.text.main,
    fontWeight: 600,
    fontSize: '1em',
  },

  '& .active-report': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
  },

  '& .report-policy-details': {
    backgroundColor: theme.palette.primary.background,
    fontSize: '0.8em',
    padding: '2.5% 2%',
    borderRadius: '10px',
  },

  '& .report-modal-buttons': {
    background: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '25px',
    color: theme.palette.common.white,
    width: '18%',
    height: '8%',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },

  '& .report-modal-recepient': {
    display: 'block',
    background: theme.palette.playColors.lightRed1,
    borderRadius: '10px',
    color: theme.palette.text.main,
    padding: '2.5%',
    boxSizing: 'border-box',
  },

  '& .report-modal-recepient > input': {
    fontSize: '100px',
    background: theme.palette.common.error,
  },

  '& .report-modal-checked-label': {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('sm')]: {
    '& .modal-wrapper': {
      padding: '5%',
    },
  },
  '& .report-error-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.error,
    fontSize: '1.5rem',
  },
}));
