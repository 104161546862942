import { styled } from '@mui/styles';
import { Box, Button, Grid } from '@mui/material';

export const PlayArea = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  top: '-10px',

  '& .back-button': {
    '.MuiButton-startIcon': {
      marginRight: '0px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginRight: 'auto',
    marginLeft: '20px',
  },
});

export const StyledPlay = styled('div')(({ theme }) => ({
  padding: '0.5em 0',
  width: '100%',
  display: 'flex',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    gap: '0px',
  },

  '& .left': {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      flexBasis: '90%',
    },

    '.level-chip': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.component.ToggleBoxBorder,
    },
  },

  '& .right': {
    borderRadius: '10px',
    flexBasis: '30%',
    backgroundColor: theme.palette.background.main,
    width: '30%',
    minHeight: 'calc(100vh - 140px)',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      flexBasis: '0px',
      width: '0px',
      height: '0px',
    },
  },
}));

export const PositiveScore = styled('span')(({ theme }) => ({
  color: theme.palette.common.success,
  fontWeight: 600,
}));

export const NegativeScore = styled('span')(({ theme }) => ({
  color: theme.palette.common.error,
  fontWeight: 600,
}));

export const PlayAnswer = styled('div')({
  p: {
    margin: '0px',
  },
});

export const PlayError = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: '3em',
  textAlign: 'center',
  display: 'flex',
  gap: '1.5em',
  flexDirection: 'column',

  p: {
    fontSize: '1.1em',
  },

  button: {
    alignSelf: 'center',
  },
}));

export const StreakItems = styled('div')({
  marginBottom: '2em',
});

export const QuestionDetail = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2px',
});
export const QuestionDetailWithImg = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2px',
  '& img': {
    width: '14px',
    height: '14px',
  },
  '& p': {
    fontSize: '12px',
  },
});

export const StylePlayDetailsCard = styled('div')(({ theme }) => ({
  background: theme.palette.success.contrastText,
  boxShadow: `0px 4px 4px ${theme.palette.component.shadowColor2}`,
  margin: '0.5em 0',
  marginTop: '0',
  padding: '2em 0px 0px 0px',
  paddingTop: '1em',
  position: 'relative',
  borderRadius: '10px',
}));

export const StreakItemsMobile = styled('div')({
  position: 'relative',

  '& .category-name-box': {
    position: 'absolute',
    right: '10px',
    top: '-5px',
    transition: 'all 0.5s',
  },

  '& .streak-mob': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .streak-item': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2px 5px',

    '& .streak-spree': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .streak': {
      justifyContent: 'space-between',
    },
    '& .spree': {
      justifyContent: 'space-between',
    },

    '& > p': {
      minWidth: 'unset',
    },
  },

  button: {
    width: '100%',
    marginBottom: '10px',
    fontSize: '0.7em',
  },

  '& .mobile-details': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 5px',
  },
});

export const StreakItem = styled('div')(({ theme }) => ({
  display: 'flex',

  '& > p': {
    minWidth: '40%',
  },

  '&-value': {
    display: 'flex',

    p: {
      fontWeight: 'bold',
    },
  },

  '& > div': {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flex: '1',
    },
  },
}));

export const PlayDetailsMobileBottomContainer = styled('div')(({ theme }) => ({
  padding: '0.5em',
  backgroundColor: theme.palette.background.main,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '10px',
}));

export const CompetitionAllQuesAttempted = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '0.4px',
}));

export const CompetitionAllQuesAttemptedText = styled('p')(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.4px',
}));

export const CompetitionAllQuesAttemptedBtn = styled(Button)(({ theme }) => ({
  display: 'inline-flex',
  padding: '10px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '100px',
  background: theme.palette.primary.main,
  color: 'white',
  border: 'none',
  fontWeight: 600,
  marginTop: '20px',

  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.primary.main,
  },
}));

export const CompetitionStatisticsContainer = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  background: theme.palette.component.ToggleBoxBorder,
  padding: '30px',
  marginTop: '30px',
}));

export const CompetitionStatisticsFinalScore = styled('div')(({ theme }) => ({
  borderRadius: '5px',
  background: theme.palette.background.main,
  display: 'inline-flex',
  padding: '10px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '10px',
  width: '100%',
  height: '100%',
}));

export const CompetitionStatisticsFinalScoreText = styled('span')(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '100%',
  }),
);

export const CompetitionStatisticsFinalScoreNumber = styled('span')(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '100%',
  }),
);

export const CompetitionStatisticsSection2 = styled('div')({
  borderRadius: '5px',
  background: 'linear-gradient(157deg, #8263e5 0%, #406add 100%), #d9d9d9',
  padding: '25px',
  marginTop: '20px',
});

export const CompetitionAttemptmocktestCTA = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  padding: '5px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '100px',
  background: theme.palette.background.main,
  color: theme.palette.secondary.main,
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0.4px',
  border: 'none',
}));

export const CompetitionDonoughtChart = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '20px',
  borderRadius: '5px',
  backgroundColor: theme.palette.background.main,
  margin: 'auto',
}));

export const CompetitionMetricsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const CompetitionMetricsContainerColor = styled('span')({
  width: '10px',
  height: '10px',
});

export const CollectBadgeModalLevelImg = styled('img')(({ theme }) => ({
  width: '335px',
  height: '120px',
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    height: '100px',
  },
}));

export const StyleTopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '30px',
  marginBottom: '15px',
  gap: '5px',
  [theme.breakpoints.down('sm')]: {
    marginRight: '15px',
  },
}));

export const StyledCustomBadges = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  padding: '2px 5px',
  borderRadius: '10px',
  border: 'none',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));

export const StyledCustomGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '10px',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
}));

export const StyledInfoTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});
export const StyledDateAttempted = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 15px',
});
export const StyledBottomButton = styled('div')({
  padding: '0',
  display: 'flex',
  justifyContent: 'center',
});
export const StyledSuccessButton = styled(Button)(({ theme }) => ({
  fontSize: '10px',
  backgroundColor: theme.palette.common.success,
  padding: '4px 6px',
}));
export const StyledDiscussionPannelBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.main,
  borderRadius: '10px',
  padding: '20px',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
}));
export const StyledWrapperContainer = styled('div')({
  display: 'flex',
  gap: '2px',
});

export const StyledTimerContainer = styled('div')({
  display: 'flex',
  gap: '2px',
  justifyContent: 'space-between',
});

export const StyledTimerLeftContainer = styled('div')(({ theme }) => ({
  width: '67%',
  paddingLeft: '40px',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '93%',
    paddingLeft: '0px',
    margin: '5px auto',
  },
}));
