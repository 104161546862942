import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { ProgressBar, Step } from 'react-step-progress-bar';
import FireIconSVG from './img/fire-icon.svg';
import { StyledSegmentedProgressBar } from './SegmentProgressBarStyledComponent';

const FireIcon = ({ percent, total }) => {
  const n = total === 1 ? 1 : total >= 10 ? 10 : 5; // number of segments
  // possible n values = 1 | 5 | 10

  return (
    <img
      src={FireIconSVG}
      style={{
        position: 'absolute',
        left: `calc(100% / ${n} * ${(percent / 100) * n} - 5px)`,
        top: '-10px',
        fontSize: '12px',
      }}
    />
  );
};

function SegmentedProgressBar({
  strokeColor,
  value,
  total,
  attempt,
  trailColor,
  width,
  circleWidth,
  isStreak, // false for spree in practise page
}) {
  const [showPopup, setShowPopup] = useState(false);
  const steps = () => {
    const arr = [];
    const n = total === 1 ? 1 : total >= 10 ? 10 : 5; // number of segments
    // possible n values = 1 | 5 | 10
    for (let i = 0; i < n; i++) {
      arr.push(
        <Step transition="scale" key={i}>
          {({ accomplished }) => (
            <p
              className="step"
              style={{
                backgroundColor: accomplished ? strokeColor : trailColor,
                width: `100%`,
                left: `${100 / 2}%`,
              }}
            />
          )}
        </Step>,
      );
    }
    return arr;
  };
  return (
    <StyledSegmentedProgressBar
      style={{ width }}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      {showPopup && (
        <div className="popup">
          <Typography color="primary">
            Current {isStreak ? 'Streak' : 'Spree'} Position: <b>{attempt}</b>
          </Typography>
          <Typography color="primary">
            Target Position for the next Badge: <b>{total}</b>
          </Typography>
        </div>
      )}
      <Typography
        className="attempted-total"
        sx={{
          width: circleWidth,
          color: '#777777',
          borderColor: '#777777',
          margin: 0,
          zIndex: 5,
        }}
      >
        {attempt}
      </Typography>
      <div className="progress-bar-container">
        <ProgressBar
          percent={value}
          filledBackground="white"
          unfilledBackground="white"
          hasStepZero={false}
          height={5}
        >
          {steps()}
        </ProgressBar>
        <FireIcon percent={value} total={total} />
      </div>
      <Typography
        className="attempted-total"
        sx={{
          width: circleWidth,
          color: `${isStreak ? '#43C73F' : '#65257B'}`,
          borderColor: `${isStreak ? '#43C73F' : '#65257B'}`,
          margin: 0,
          zIndex: 5,
        }}
      >
        {total}
      </Typography>
    </StyledSegmentedProgressBar>
  );
}

export default SegmentedProgressBar;
