import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import Icon from '@mui/material/Icon';

import Branding from './../Branding';

import { StyledAuth } from './StyledComponent';

export default function Auth(props) {
  return (
    <StyledAuth>
      <div className="auth__image-overlay"></div>
      <Container style={{ display: 'flex' }} className="auth__container">
        <Paper className="auth__content-wrapper">
          <div className="auth__header">
            <Branding />
          </div>
          <div className="auth__grids">
            <div className="auth__left">
              <div className="auth__details">
                <div className="auth__details-item">
                  <Icon className="auth__icons icon-rose">timeline</Icon>
                  <div className="auth__details-head">
                    <h3>Beyond Code</h3>
                    <p>
                      Become a true programming master by enhancing your soft
                      skills and technical ability.
                    </p>
                  </div>
                </div>
                <div className="auth__details-item">
                  <Icon className="auth__icons icon-primary">code</Icon>
                  <div className="auth__details-head">
                    <h3>Sharpen your skills</h3>
                    <p>
                      Challenge yourself on questions, created by the community
                      to strengthen different skills. Master your current
                      language of choice, or expand your understanding of a new
                      one.
                    </p>
                  </div>
                </div>
                <div className="auth__details-item">
                  <Icon className="auth__icons icon-info">group</Icon>
                  <div className="auth__details-head">
                    <h3>Earn points and badges</h3>
                    <p>
                      Answers are scored based on the difficulty level. As you
                      complete difficult questions, you progress through the
                      ranks so we suggest you relevant challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="auth__right">
              <div className="auth__content">{props.children}</div>
            </div>
          </div>
        </Paper>
      </Container>
    </StyledAuth>
  );
}
