import React, { useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import ReportIcon from '../../images/ReportSubmittedIcon.svg';
import ReportCloseIcon from '../../images/ReportCloseIcon.png';
import ReportModalBackButton from '../../images/ReportModalBackButton.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearReportComment, reportComment } from '../../actions/discussion';
import { Error } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import { StyledReportModal } from './ReportModalStyledComponent';
import config from '../../config';

const ReportModal = ({
  reportModalOpen,
  setReportModalOpen,
  questionId,
  commentId,
}) => {
  const [submitReport, setSubmitReport] = useState(false);
  const [currentStep, setCurrentStep] = useState(() => 0);
  const [reportType, setReportType] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(() => false);
  const [reportMessage, setReportMessage] = useState(() => '');

  const reportingAComment = useSelector(
    (state) => state.discussion.reportingAComment,
  );
  const reportCommentError = useSelector(
    (state) => state.discussion.reportCommentError,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearReportComment());
    };
  }, [dispatch]);

  const reports = [
    ['Spam', 'Spam'],
    ['Copyright Infringement', 'CopyrightInfringement'],
    ['Harassment', 'Harassment'],
    ['Vulgarity', 'Vulgarity'],
    ['Other', 'Other'],
  ];

  const handleClose = () => {
    setReportModalOpen(false);
  };

  const handleReportTypeChange = (value) => {
    setReportType((oldValue) => (oldValue === value ? '' : value));
  };

  const handleNext = () => setCurrentStep((prevStep) => prevStep + 1);
  const handlePrev = () => setCurrentStep((prevStep) => prevStep - 1);

  const handleSubmit = () => {
    if (reportDetails === '' || reportType === '') {
      setOpenSnackBar(true);
      return;
    }
    setSubmitReport(true);
  };

  const messageSettingCallback = (message) => {
    setReportMessage(message);
  };

  useEffect(() => {
    if (submitReport) {
      dispatch(
        reportComment(
          questionId,
          commentId,
          reportType,
          reportDetails,
          messageSettingCallback,
        ),
      );
      setSubmitReport(false);
      handleNext();
    }
  }, [
    submitReport,
    questionId,
    commentId,
    reportType,
    reportDetails,
    dispatch,
  ]);

  const steps = [
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackBar(false)}
        className="empty-input-snackbar"
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity={'info'}>
          Please fill in all the fields!
        </Alert>
      </Snackbar>
      <h2 className="report-heading">Submit a Report</h2>
      <section id="report-modal-description" className="about-report">
        Thank you for reporting the message that violated Abekus rules. Your
        action has made the platform a better place for everyone. We appreciate
        your help in maintaining a safe and respectful community.
      </section>
      <div className="report-types">
        {reports.map((report, index) => (
          <div
            key={index}
            className={`${
              reportType === report[1] ? 'active-report' : ''
            } report-type`}
            onClick={() => handleReportTypeChange(report[1])}
          >
            {report[0]}
          </div>
        ))}
      </div>
      <TextField
        fullWidth
        value={reportDetails}
        onChange={({ target }) => setReportDetails(target.value)}
        variant="outlined"
        placeholder="Briefly explain your concern"
      />
      <div className="report-policy-details">
        Not sure if something is breaking the rules? Review{' '}
        <Link
          to={`${config.WEBSITE_URL}/privacy_policy.html`}
          className="policy-link"
        >
          Abekus discussion
        </Link>{' '}
        policy.
      </div>
      <button onClick={handleSubmit} className="report-modal-buttons">
        Submit
      </button>
    </>,
    reportCommentError === '' ? (
      <>
        <img src={ReportIcon} alt="report-icon" />
        <Typography variant="h6" component="h2">
          {reportMessage}
        </Typography>
        <Typography id="report-modal-description" sx={{ mt: 2 }}>
          Thanks again for reporting the message that violated Abekus rules.
          Your action has made the platform a better place for everyone. We
          appreciate your help in maintaining a safe and respectful community.
        </Typography>
        <button onClick={handleClose} className="report-modal-buttons">
          Close
        </button>
      </>
    ) : (
      <>
        <Error />
        <Typography variant="h6" component="h2">
          Could Not Submit report
        </Typography>
        <Typography id="report-modal-description" sx={{ mt: 2 }}>
          Your Report could not be submitted, please try again later!
        </Typography>
        <button onClick={handleClose} className="report-modal-buttons">
          Close
        </button>
      </>
    ),
    reportCommentError ? (
      <>
        <Error />
        <Typography variant="h6" component="h2">
          Could Not Submit Report
        </Typography>
        <button onClick={handleClose} className="report-modal-buttons">
          Close
        </button>
      </>
    ) : (
      <>
        <img src={ReportIcon} alt="report-icon" />
        <Typography variant="h6" component="h2">
          Report Submitted
        </Typography>
        <Typography id="report-modal-description" sx={{ mt: 2 }}>
          Thanks again for reporting the message that violated Abekus rules.
          Your action has made the platform a better place for everyone. We
          appreciate your help in maintaining a safe and respectful community.
        </Typography>
        <button onClick={handleClose} className="report-modal-buttons">
          Close
        </button>
      </>
    ),
  ];
  const maxSteps = steps.length;
  return (
    <Modal open={reportModalOpen}>
      <StyledReportModal>
        <div className="modal-wrapper">
          {submitReport || reportingAComment ? (
            <div className="report-loader">
              <CircularProgress />
            </div>
          ) : (
            <>
              <button
                onClick={handleClose}
                className={'report-modal-top-close-button'}
              >
                <img src={ReportCloseIcon} alt="report-close-icon" />
              </button>
              {0 < currentStep && currentStep < maxSteps - 1 && (
                <>
                  <section className="report-heading">
                    <button
                      onClick={handlePrev}
                      className="report-modal-back-button"
                    >
                      <img
                        src={ReportModalBackButton}
                        alt="report-back-button"
                      />
                    </button>
                    Submit a Report
                  </section>
                </>
              )}
              {steps[currentStep]}
            </>
          )}
        </div>
      </StyledReportModal>
    </Modal>
  );
};

export default ReportModal;
