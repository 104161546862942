import React, { useEffect, useState } from 'react';
import { Typography, Hidden, useMediaQuery, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';

import { PlayDetailsMobileBottomContainer } from './PlayStyledComponent';
import {
  AnalyticsProgressBars,
  PlayAnswerDetailsButton,
  PlayAnswerDetailsButton2,
  PlayAnswerDetailsChangeTypo,
  PlayAnswerDetailsNumberss,
  QuestionMapBoxContent,
  QuestionMapContent,
  QuestionMapContentContainer,
  QuestionMapContentItem,
  QuestionMapMobile,
  QuestionMapProgress,
  StyledBadge,
  StyledParagraph,
  StyledQuestionMapContentFooter,
  StyledQuestionMapContentFooterItem,
  StyledStatsDetailsContainer,
} from '../PlayAnswerDetails/PlayAnswerDetailsStyledComponent';
import { StyledCustomCard } from '../Dashboard/DashboardStyledComponent';

let oldQuestionMapOpenState = null;
let oldQuestionMapPageState = null;

const PlayDetailsMobileBottom = ({
  question,
  examType,
  mockTest,
  competitionAnalytics,
  postAnswerSilently,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [questionMapPage, setQuestionMapPage] = useState(0);
  const [isQuestionMapOpen, setIsQuestionMapOpen] = useState(!isMobile);
  const { data, examAnalytics } = question;

  useEffect(() => {
    if (oldQuestionMapOpenState !== null)
      setIsQuestionMapOpen(oldQuestionMapOpenState);
    if (oldQuestionMapPageState !== null)
      setQuestionMapPage(oldQuestionMapPageState);
  }, []);

  useEffect(() => {
    const currentQuestionIndex = examAnalytics.questions?.findIndex(
      (ques) => ques.id === data.id,
    );
    if (currentQuestionIndex !== -1) {
      const currentPage = Math.floor(currentQuestionIndex / 30);
      setQuestionMapPage(currentPage);
    }
  }, [data.id]);

  useEffect(() => {
    oldQuestionMapOpenState = isQuestionMapOpen;
    oldQuestionMapPageState = questionMapPage;
  }, [isQuestionMapOpen, questionMapPage]);

  const attemptedQuestionsPercent = (
    (examAnalytics.statistics?.attempted / examAnalytics.questions?.length) *
    100
  ).toFixed(2);
  const skippedQuestionPercent = (
    (examAnalytics.statistics?.skipped / examAnalytics.questions?.length) *
    100
  ).toFixed(2);
  const unattemptedQuestionPercent = (
    (examAnalytics.statistics?.unattempted / examAnalytics.questions?.length) *
    100
  ).toFixed(2);

  const totalAttendedQuestions =
    competitionAnalytics?.total_correct +
    competitionAnalytics?.total_incorrect +
    competitionAnalytics?.timed_out +
    competitionAnalytics?.skipped;

  if (examType === 'play') return null;

  return (
    <Hidden mdUp>
      <PlayDetailsMobileBottomContainer>
        {examType === 'competition' && (
          <StyledCustomCard>
            <AnalyticsProgressBars>
              <span
                className="green"
                style={{
                  width: `calc(${(competitionAnalytics.total_correct /
                    totalAttendedQuestions) *
                    100}% + 3px)`,
                }}
              />
              <span
                className="red"
                style={{
                  width: `calc(${(competitionAnalytics.total_incorrect /
                    totalAttendedQuestions) *
                    100}% + 3px)`,
                }}
              />
              <span
                className="yellow"
                style={{
                  width: `calc(${(competitionAnalytics.skipped /
                    totalAttendedQuestions) *
                    100}% + 3px)`,
                }}
              />
              <span
                className="purple"
                style={{
                  width: `calc(${(competitionAnalytics.timed_out /
                    totalAttendedQuestions) *
                    100}% + 3px)`,
                }}
              />
            </AnalyticsProgressBars>

            <StyledStatsDetailsContainer>
              <PlayAnswerDetailsNumberss
                style={{
                  backgroundColor: '#D4EDDA',
                  border: '1px solid #4caf50',
                  padding: '0.1em',
                }}
              >
                <p className="green">{competitionAnalytics.total_correct}</p>
                <Typography variant="caption" gutterBottom>
                  Correct
                </Typography>
              </PlayAnswerDetailsNumberss>

              <PlayAnswerDetailsNumberss
                style={{
                  backgroundColor: '#ebd6d6',
                  border: '1px solid #f44336',
                  padding: '0.1em',
                }}
              >
                <p className="red">{competitionAnalytics.total_incorrect}</p>
                <Typography variant="caption" gutterBottom>
                  Incorrect
                </Typography>
              </PlayAnswerDetailsNumberss>

              <PlayAnswerDetailsNumberss
                style={{
                  backgroundColor: '#EE9D0014',
                  border: '1px solid rgb(233, 196, 106)',
                  padding: '0.1em',
                }}
              >
                <p className="yellow">{competitionAnalytics.skipped}</p>
                <Typography variant="caption" gutterBottom>
                  Skipped
                </Typography>
              </PlayAnswerDetailsNumberss>

              <PlayAnswerDetailsNumberss
                style={{
                  backgroundColor: '#DAE5F0',
                  border: '1px solid #21A9DE',
                  padding: '0.1em',
                }}
              >
                <p className="purple">{competitionAnalytics.timed_out}</p>
                <Typography variant="caption" gutterBottom style={{}}>
                  Timeout
                </Typography>
              </PlayAnswerDetailsNumberss>

              <PlayAnswerDetailsNumberss
                style={{ border: '1px solid #25507B', padding: '0.1em' }}
              >
                <p className="blue">
                  {competitionAnalytics.accuracy?.toFixed(2)} %
                </p>
                <Typography variant="caption" gutterBottom>
                  Accuracy
                </Typography>
              </PlayAnswerDetailsNumberss>
            </StyledStatsDetailsContainer>
          </StyledCustomCard>
        )}

        {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
          <QuestionMapBoxContent width="100%">
            {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
              <Hidden smUp>
                <QuestionMapMobile>
                  <QuestionMapProgress>
                    {attemptedQuestionsPercent > 0 && (
                      <span
                        style={{ width: `${attemptedQuestionsPercent}%` }}
                        className="attempted"
                        data-value={examAnalytics.statistics?.attempted}
                      />
                    )}
                    {skippedQuestionPercent > 0 && (
                      <span
                        style={{ width: `${skippedQuestionPercent}%` }}
                        className="skipped"
                        data-value={examAnalytics.statistics?.skipped}
                      />
                    )}
                    {unattemptedQuestionPercent > 0 && (
                      <span
                        style={{ width: `${unattemptedQuestionPercent}%` }}
                        className="unattempted"
                        data-value={examAnalytics.statistics?.unattempted}
                      />
                    )}
                  </QuestionMapProgress>
                </QuestionMapMobile>
              </Hidden>
            )}
            {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
              <Hidden mdDown>
                <PlayAnswerDetailsChangeTypo variant="h6" gutterBottom>
                  Question Map
                </PlayAnswerDetailsChangeTypo>
              </Hidden>
            )}
            {examType === 'mockTest' &&
              mockTest.data.exam_mode === 'flexible' &&
              examAnalytics.questions && (
                <QuestionMapContent>
                  {isQuestionMapOpen ? (
                    <QuestionMapContentContainer>
                      <PlayAnswerDetailsButton
                        variant="text"
                        disabled={questionMapPage === 0}
                        onClick={() => setQuestionMapPage(questionMapPage - 1)}
                      >
                        <ChevronLeftIcon />
                      </PlayAnswerDetailsButton>

                      {examAnalytics?.questions
                        .slice(
                          questionMapPage * 30,
                          Math.min(
                            questionMapPage * 30 + 30,
                            examAnalytics.questions?.length,
                          ),
                        )
                        .map((ques) => (
                          <QuestionMapContentItem
                            onClick={() => postAnswerSilently(false, ques.id)}
                            key={ques.id}
                            className={classNames({
                              skipped: ques.status === 'skipped',
                              attempted:
                                ques.status === 'attempted' &&
                                ques.id !== data.id,
                              current: ques.id === data.id,
                            })}
                          >
                            {ques.custom_mocktest_question_num}
                          </QuestionMapContentItem>
                        ))}

                      <PlayAnswerDetailsButton
                        variant="text"
                        disabled={
                          questionMapPage * 30 + 30 >=
                          examAnalytics.questions?.length
                        }
                        onClick={() => setQuestionMapPage(questionMapPage + 1)}
                      >
                        <ChevronRightIcon />
                      </PlayAnswerDetailsButton>
                    </QuestionMapContentContainer>
                  ) : (
                    <QuestionMapContentContainer>
                      <PlayAnswerDetailsButton
                        variant="text"
                        disabled={questionMapPage === 0}
                        onClick={() => setQuestionMapPage(questionMapPage - 1)}
                      >
                        <ChevronLeftIcon />
                      </PlayAnswerDetailsButton>

                      {examAnalytics?.questions
                        .slice(
                          questionMapPage * 6,
                          Math.min(
                            questionMapPage * 6 + 6,
                            examAnalytics.questions?.length,
                          ),
                        )
                        .map((ques) => (
                          <QuestionMapContentItem
                            onClick={() => postAnswerSilently(false, ques.id)}
                            key={ques.id}
                            className={classNames({
                              skipped: ques.status === 'skipped',
                              attempted:
                                ques.status === 'attempted' &&
                                ques.id !== data.id,
                              current: ques.id === data.id,
                            })}
                          >
                            {ques.custom_mocktest_question_num}
                          </QuestionMapContentItem>
                        ))}

                      <PlayAnswerDetailsButton
                        variant="text"
                        disabled={
                          questionMapPage * 6 + 6 >=
                          examAnalytics.questions?.length
                        }
                        onClick={() => setQuestionMapPage(questionMapPage + 1)}
                      >
                        <ChevronRightIcon />
                      </PlayAnswerDetailsButton>
                    </QuestionMapContentContainer>
                  )}

                  <Divider />

                  <StyledQuestionMapContentFooter>
                    <StyledQuestionMapContentFooterItem>
                      <StyledBadge
                        badgeContent=" "
                        variant="dot"
                        className="attempted"
                      />
                      <StyledParagraph>
                        Attempted ({`${attemptedQuestionsPercent}%`})
                      </StyledParagraph>
                    </StyledQuestionMapContentFooterItem>

                    <StyledQuestionMapContentFooterItem>
                      <StyledBadge
                        badgeContent=" "
                        variant="dot"
                        className="skipped"
                      />
                      <StyledParagraph>
                        Skipped ({`${skippedQuestionPercent}%`})
                      </StyledParagraph>
                    </StyledQuestionMapContentFooterItem>

                    <StyledQuestionMapContentFooterItem>
                      <StyledBadge
                        badgeContent=" "
                        variant="dot"
                        className="unattempted"
                      />
                      <StyledParagraph>
                        Unsolved ({`${unattemptedQuestionPercent}%`})
                      </StyledParagraph>
                    </StyledQuestionMapContentFooterItem>

                    <StyledQuestionMapContentFooterItem>
                      <StyledBadge
                        badgeContent=" "
                        variant="dot"
                        className="current"
                      />
                      <StyledParagraph>Current</StyledParagraph>
                    </StyledQuestionMapContentFooterItem>
                  </StyledQuestionMapContentFooter>
                </QuestionMapContent>
              )}

            {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
              <Hidden smUp>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <PlayAnswerDetailsButton2
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={() => setIsQuestionMapOpen(!isQuestionMapOpen)}
                  >
                    {isQuestionMapOpen ? (
                      <>
                        Collapse View <ExpandLessIcon />
                      </>
                    ) : (
                      <>
                        Expand View <ExpandMoreIcon />
                      </>
                    )}
                  </PlayAnswerDetailsButton2>
                </div>
              </Hidden>
            )}
          </QuestionMapBoxContent>
        )}
      </PlayDetailsMobileBottomContainer>
    </Hidden>
  );
};

export default PlayDetailsMobileBottom;
