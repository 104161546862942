export const examTypes = [
  { value: 'JEE_MAIN', label: 'JEE MAIN' },
  { value: 'JEE_ADVANCE', label: 'JEE ADVANCE' },
  { value: 'GATE', label: 'GATE' },
  { value: 'BITSIT', label: 'BITSIT' },
  { value: 'AIIMS', label: 'AIIMS' },
  { value: 'AIPMT', label: 'AIPMT' },
  { value: 'AIEEE', label: 'AIEEE' },
  { value: 'CAT', label: 'CAT' },
  { value: 'IIT_JAM', label: 'IIT JAM' },
  { value: 'NDA', label: 'NDA' },
  { value: 'CDS', label: 'CDS' },
  { value: 'SSC', label: 'SSC' },
  { value: 'DRDO', label: 'DRDO' },
  { value: 'ISRO', label: 'ISRO' },
  { value: 'NEET', label: 'NEET' },
];

export const getExamLabel = (exam) => {
  for (let i = 0, l = examTypes.length; i < l; i += 1) {
    if (examTypes[i].value === exam) {
      return examTypes[i];
    }
  }

  return {};
};
