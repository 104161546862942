import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const auth = createReducer(
  {
    isLoggedOut: false,
    loginFail: false,
    loginSuccess: false,
    userSignupSuccess: false,
    userSignupFail: false,
    loginFailStatus: {},
  },
  {
    [types.LOGIN_START](state) {
      return { ...state, loginSuccess: false, loginFail: false };
    },
    [types.LOGIN_SUCCESS](state, action) {
      return {
        ...state,
        loginSuccess: true,
        isLoggedOut: false,
        token: action.token,
      };
    },
    [types.LOGIN_FAIL](state, action) {
      return { ...state, loginFail: true, loginFailStatus: action.data };
    },
    [types.USER_SIGNUP_START](state) {
      return { ...state, userSignupSuccess: false, userSignupFail: false };
    },
    [types.USER_SUCCESS](state, action) {
      return { ...state, userSignupSuccess: true, status: action.data };
    },
    [types.USER_FAIL](state) {
      return { ...state, userSignupFail: true };
    },
    [types.GET_USER_COUNTRY_SUCCESS](state, action) {
      return { ...state, country: action.country };
    },
    [types.GET_USER_COUNTRY_FAIL](state) {
      return { ...state };
    },
    [types.USER_LOGOUT](state) {
      return {
        ...state,
        loginSuccess: false,
        loginFail: false,
        isLoggedOut: true,
      };
    },
    [types.FORGOT_PASSWORD_START](state) {
      return { ...state, sendForgotPasswordStart: true };
    },
    [types.FORGOT_PASSWORD_SUCCESS](state) {
      return {
        ...state,
        sendForgotPasswordStart: false,
        sendForgotPasswordSuccess: true,
      };
    },

    [types.FORGOT_PASSWORD_FAIL](state) {
      return {
        ...state,
        sendForgotPasswordStart: false,
        sendForgotPasswordFail: true,
      };
    },
    [types.UPDATE_PASSWORD_START](state) {
      return { ...state, updatePasswordStart: true };
    },
    [types.UPDATE_PASSWORD_SUCCESS](state) {
      return {
        ...state,
        updatePasswordStart: false,
        updatePasswordSuccess: true,
      };
    },

    [types.UPDATE_PASSWORD_FAIL](state) {
      return {
        ...state,
        updatePasswordStart: false,
        updatePasswordFail: true,
      };
    },
  },
);

export { auth as default };
