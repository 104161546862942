import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
} from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useEffect } from 'react';
import PhoneNumberInput from '../Layout/PhoneNumberInput';
import OTPVerifyPrompt from '../OTPVerifyPrompt';
import tickIcon from './img/email-tick.svg';
// import PencilIcon from './img/pencilIcon.svg';
import PencilIcon from '@mui/icons-material/BorderColorOutlined';

const checkIfPhoneNumberIsThere = (phoneNumber) =>
  phoneNumber &&
  phoneNumber?.split('-')?.length > 0 &&
  phoneNumber?.split('-')[1]?.length > 0;

export default function PhoneNumberVerify({
  phoneNumberRef,
  error,
  user,
  setValue,
  ...actions
}) {
  const { data, isOTPVerifyInProgress, isOTPSendInProgress, OTPError } = user;
  const { phone_number, is_phone_verified } = data;

  const previousProps = useRef(user);
  const [phoneNumber, setPhoneNumber] = useState(phone_number || '');
  const [isOTPVerifyPromptOpen, setIsOTPVerifyPromptOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resendTimer, setResendTimer] = useState(-1);

  const sendOTP = () => {
    if (resendTimer > 0) setIsOTPVerifyPromptOpen(true);
    else actions.sendOTP(phoneNumber, 'User', data?.id);
  };

  useEffect(() => {
    if (!previousProps.current.isOTPSendSuccess && user.isOTPSendSuccess) {
      setIsOTPVerifyPromptOpen(true);
    }
    if (
      !previousProps.current.data.is_phone_verified &&
      user.data.is_phone_verified
    ) {
      setIsEditMode(false);
      setIsOTPVerifyPromptOpen(false);
    }

    if (!previousProps.current.isOTPSendSuccess && user.isOTPSendSuccess) {
      setResendTimer(60);
    }

    previousProps.current = user;
  }, [user]);

  useEffect(() => {
    if (setValue) setValue('phone_number', phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timeout = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [resendTimer]);

  useImperativeHandle(
    phoneNumberRef,
    () => ({
      phoneNumber,
    }),
    [phoneNumber],
  );

  return (
    <div className="userdetails__phonenumber__container">
      <div>
        <PhoneNumberInput
          disabled={isEditMode ? false : is_phone_verified}
          margin="dense"
          variant="outlined"
          className="text-input-field"
          value={phoneNumber}
          onChange={setPhoneNumber}
          endIcon={
            isEditMode ? undefined : is_phone_verified && <img src={tickIcon} />
          }
        />
        {!isOTPVerifyPromptOpen && (
          <FormHelperText error={!!OTPError || !!error}>
            {OTPError || error}
          </FormHelperText>
        )}
      </div>
      {isEditMode || !is_phone_verified ? (
        <>
          {((phoneNumber && phoneNumber.startsWith('91')) || !phoneNumber) && (
            <Button
              variant="contained"
              color="primary"
              onClick={sendOTP}
              disabled={
                !checkIfPhoneNumberIsThere(phoneNumber) || isOTPSendInProgress
              }
              endIcon={
                isOTPSendInProgress && (
                  <CircularProgress color="inherit" size={15} />
                )
              }
            >
              Send OTP
            </Button>
          )}
        </>
      ) : (
        <IconButton
          onClick={() => setIsEditMode(true)}
          size="large"
          color="primary"
        >
          <PencilIcon />
        </IconButton>
      )}

      <OTPVerifyPrompt
        timer={resendTimer}
        resendOTP={sendOTP}
        error={OTPError}
        open={isOTPVerifyPromptOpen}
        onClose={() => setIsOTPVerifyPromptOpen(false)}
        onVerify={actions.verifyOTP}
        loading={isOTPVerifyInProgress}
      />
    </div>
  );
}
