import {
  Box,
  DialogContent,
  Divider,
  Grid,
  TablePagination,
  Typography,
  Tooltip,
  Button,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CallAttemptFieldHeading } from '../SalesStyledComponent';
import AddCallAttempt from './AddCallAttempt';
import { getCallAttempt } from '../../../actions/salesDashboard';
import { useDispatch, useSelector } from 'react-redux';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import moment from 'moment';
import { Edit } from '@mui/icons-material';

const CallAttempts = ({ salesLead }) => {
  const { callAttemptData } = useSelector((state) => state.SalesDashboard);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showAddCallAttempt, setShowAddCallAttempt] = useState(false);
  const [selectedCallAttempt, setSelectedCallAttempt] = useState({});

  useEffect(() => {
    dispatch(
      getCallAttempt(
        page + 1,
        rowsPerPage,
        salesLead?.phone_number,
        salesLead?.id,
      ),
    );
  }, [page, rowsPerPage]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleEditCallAttemptData = (attempt) => {
    setShowAddCallAttempt(true);
    setSelectedCallAttempt(attempt);
  };

  return (
    <DialogContent>
      <Box display={'flex'} justifyContent={'space-around'}>
        <CallAttemptFieldHeading variant="h6">
          {' '}
          <Typography color="#25507B" variant="body">
            Name:&nbsp;
          </Typography>
          {salesLead?.name}
        </CallAttemptFieldHeading>

        <CallAttemptFieldHeading variant="h6">
          <Typography color="#25507B" variant="body">
            Contact:&nbsp;
          </Typography>{' '}
          {salesLead?.phone_number}
          {salesLead?.linkedin_url && (
            <IconButton
              onClick={() => window.open(salesLead?.linkedin_url, '_blank')}
            >
              <LinkedInIcon fontSize={'medium'} color="primary" />
            </IconButton>
          )}
        </CallAttemptFieldHeading>
        <Button
          variant="contained"
          onClick={() => {
            setShowAddCallAttempt(!showAddCallAttempt);
            setSelectedCallAttempt({});
          }}
        >
          {showAddCallAttempt ? 'Hide' : 'Show'} Add Attempt Form
        </Button>
      </Box>
      <br />
      <Box sx={{ overflow: 'auto', height: '60vh' }}>
        {showAddCallAttempt && (
          <AddCallAttempt
            salesLead={salesLead}
            selectedCallAttempt={selectedCallAttempt}
            setSelectedCallAttempt={setSelectedCallAttempt}
          />
        )}

        <Divider sx={{ mt: 2, mb: 2 }} />
        {callAttemptData?.call_attempts?.length === 0 ? (
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            There are no previous call attempts, you can add one now.
          </Typography>
        ) : (
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid
                item
                sm={1}
                xs={1}
                display={'flex'}
                justifyContent={'center'}
              ></Grid>
              <Grid
                item
                sm={3}
                xs={3}
                display={'flex'}
                justifyContent={'center'}
              >
                <CallAttemptFieldHeading variant="h6">
                  Call Status
                </CallAttemptFieldHeading>
              </Grid>
              <Grid
                item
                sm={3}
                xs={3}
                display={'flex'}
                justifyContent={'center'}
              >
                <CallAttemptFieldHeading variant="h6">
                  Call Time
                </CallAttemptFieldHeading>
              </Grid>
              <Grid item sm={5} xs={5}>
                <CallAttemptFieldHeading variant="h6">
                  Comment
                </CallAttemptFieldHeading>
              </Grid>
            </Grid>
            <Divider />
            {callAttemptData?.call_attempts?.map((attempt) => (
              <Box key={attempt.id}>
                <Grid
                  container
                  spacing={2}
                  mb={2}
                  mt={0}
                  color={'black'}
                  fontSize={'15px'}
                >
                  <Grid
                    item
                    sm={1}
                    xs={1}
                    display="flex"
                    justifyContent={'center'}
                  >
                    <IconButton
                      sx={{ borderRadius: '5px' }}
                      onClick={() => handleEditCallAttemptData(attempt)}
                    >
                      <Edit />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    sm={3}
                    xs={3}
                    display="flex"
                    justifyContent={'center'}
                  >
                    <Box>{attempt?.call_status}</Box>
                  </Grid>
                  <Grid item sm={3} xs={3} textAlign={'center'}>
                    <Tooltip
                      title={
                        <Box sx={{ padding: '10px', color: 'white' }}>
                          <Typography variant="h6">
                            {moment
                              .utc(attempt?.created_at)
                              .local()
                              .format('DD/MM/YYYY, hh:mm a')}
                          </Typography>
                        </Box>
                      }
                      placement="left"
                    >
                      {moment.utc(attempt?.created_at).fromNow()}
                    </Tooltip>
                  </Grid>
                  <Grid item sm={5} xs={5}>
                    {attempt?.comment}
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <TablePagination
        component="nav"
        page={page}
        rowsPerPage={rowsPerPage}
        count={callAttemptData.total_pages * callAttemptData.page_size}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows"
        className="scoreboard__navigation"
      />
    </DialogContent>
  );
};

export default CallAttempts;
