import PractiseIcon from '../components/Sidebar/img/practise-icon.svg';
import CompetitionsIcon from '../components/Sidebar/img/competitions-icon.svg';
import ContributeIcon from '../components/Sidebar/img/contribute-icon.svg';
import HistoryIcon from '../components/Sidebar/img/history-icon.svg';
import LeaderboardIcon from '../components/Sidebar/img/leaderboard-icon.svg';
import MockTestIcon from '../components/Sidebar/img/mock-test-icon.svg';
import StoreIcon from '../components/Sidebar/img/store-icon.svg';
import ReferralIcon from '../components/Sidebar/img/referral-icon.svg';
import TransactionsIcon from '../components/Sidebar/img/transactions-icon.svg';
import AdminIcon from '../components/Sidebar/img/adminFeatures.svg';
import CollegeDashboardIcon from '../components/Sidebar/img/collegeDashboard.svg';
import VoteReportedIcon from '../components/Sidebar/img/Voting.svg';
import JobsByAbekusIcon from '../components/Sidebar/img/JobsByAbekus.svg';
import DashboardIcon from '../components/Sidebar/img/dashboard.png';
import config from '../config';

export const getInitialOptions = (isAdmin, isSales) => {
  const commonOptions = [
    {
      id: 1,
      name: 'Dashboard',
      pinned: false,
      icon: DashboardIcon,
      url: 'l/dashboard',
    },
    {
      id: 3,
      name: 'Practise',
      pinned: false,
      icon: PractiseIcon,
      url: 'l/practise',
    },
    {
      id: 4,
      name: 'Leaderboard',
      pinned: false,
      icon: LeaderboardIcon,
      url: 'l/leaderboard',
    },
    {
      id: 5,
      name: 'Competitions',
      pinned: false,
      icon: CompetitionsIcon,
      url: 'competition',
    },
    {
      id: 6,
      name: 'Contribute',
      pinned: false,
      icon: ContributeIcon,
      url: 'l/question',
    },
    {
      id: 7,
      name: 'History',
      pinned: false,
      icon: HistoryIcon,
      url: 'l/user/questions',
    },
    {
      id: 8,
      name: 'Referrals',
      pinned: false,
      icon: ReferralIcon,
      url: 'l/user/referral',
    },
    {
      id: 9,
      name: 'Transactions',
      pinned: false,
      icon: TransactionsIcon,
      url: 'l/credits',
    },
    {
      id: 10,
      name: 'Store',
      pinned: false,
      icon: StoreIcon,
      url: 'l/store',
    },
    {
      id: 11,
      name: 'Mocktest',
      pinned: false,
      icon: MockTestIcon,
      url: 'l/mockTest',
    },
    {
      id: 12,
      name: 'Vote Reported',
      pinned: false,
      icon: VoteReportedIcon,
      url: 'l/voting',
    },
    {
      id: 14,
      name: 'Jobs by Abekus',
      pinned: false,
      icon: JobsByAbekusIcon,
      url: `${config.JOBS_URL}/login?token=${localStorage.getItem(
        'projq__auth__token',
      )}`,
    },
  ];

  if (isAdmin) {
    return [
      {
        id: 0,
        name: 'Admin Features',
        pinned: true,
        icon: AdminIcon,
        url: 'l/adminFeatures',
      },

      {
        id: 2,
        name: 'College Dashboard',
        pinned: false,
        icon: CollegeDashboardIcon,
        url: 'l/collegeDashboard',
      },
      {
        id: 13,
        name: 'Sales Dashboard',
        pinned: false,
        icon: CollegeDashboardIcon,
        url: 'l/admin/sales/dashboard',
      },
      ...commonOptions,
    ];
  } else if (isSales) {
    return [
      {
        id: 13,
        name: 'Sales Dashboard',
        pinned: false,
        icon: CollegeDashboardIcon,
        url: 'l/sales/dashboard',
      },
      ...commonOptions,
    ];
  } else {
    return commonOptions;
  }
};
