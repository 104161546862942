import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const CustomCircularProgress = ({ value, size, thickness }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        color="success"
        size={size}
        thickness={thickness}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <span
          style={{ color: 'black', fontSize: '1rem', fontWeight: '700' }}
        >{`${Math.round(value)}%`}</span>
      </Box>
    </Box>
  );
};

export default CustomCircularProgress;
