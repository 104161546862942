import { styled } from '@mui/styles';

import {
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Typography,
  FormControl,
  TextField,
  DialogTitle,
  DialogContent,
  Fab,
  Avatar,
  ButtonGroup,
} from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: '0.5rem',
  margin: '0.5rem',
  color: theme.palette.primary.main,
}));

export const StyledCardImg = styled('img')({
  width: '100%',
  borderRadius: '8px',
  objectPosition: 'cover',
  objectFit: 'contain',
});

export const CardImgContainer = styled(Grid)({
  overflow: 'hidden',
  paddingRight: '0.5rem',
});

export const QuantityInput = styled(TextField)({
  width: '4rem',
});

export const StyleFabIcon = styled(Fab)({
  background: '#D9EDF7', //no match
  boxShadow: 'none',
  border: 'none',
});

export const StyleFormLabel = styled(FormControl)({
  fontSize: '16px',
  color: '#204366', //no match
  fontWeight: '500',
  letterSpacing: '0.4px',
});

export const SideContainer = styled('div')(({ theme }) => ({
  fontWeight: '400',
  fontSize: '15px',
  color: theme.palette.component.color,
  lineHeight: '121.02%',
  letterSpacing: '0.4px',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  padding: '1rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}));

// -------- shipping details dialog ----------
export const Title = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ShippingDetailsLabel = styled(FormControl)(({ theme }) => ({
  display: 'inline-block',
  paddingTop: '20px',
  color: theme.palette.primary.main,
  lineHeight: '22px',
}));

export const LabelContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

export const InfoIcon = styled('div')({
  fontSize: '10px',
});

// -------------- store product details ----------------
export const ProductDetailsQuantityInput = styled(TextField)({
  width: '45px',
  margin: '0.3rem',
});

export const ProductDetailsFabIcon = styled(Typography)({
  background: '#D9EDF7', //no match
  boxShadow: 'none',
  border: 'none',
  margin: 'auto',
  width: '21px',
  height: '21px',
  borderRadius: '50%',
  textAlign: 'center',
  cursor: 'pointer',
});

export const DetailsDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.component.ToggleBoxBorder,
  fontSize: '11px',
  padding: '10px 10px 10px 10px',
  margin: '18px 10px 18px 0px',
  background: 'rgba(53, 114, 176, 0.11)', //no match
  textAlign: 'justify',
  overflow: 'auto',
  height: '123px',
  borderRadius: '5px',
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    margin: '10px 0px 10px 0px',
  },
}));

export const DetailsImgContainer = styled(Grid)({
  padding: '15px',
});
export const DetailsImg = styled(Avatar)(({ theme }) => ({
  height: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: '10rem',
  },
}));

export const ProductDetailsDialogContent = styled(DialogContent)(
  ({ theme }) => ({
    padding: '0px',
    '&:first-child': {
      padding: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        padding: '0px 18px 18px 18px',
      },
    },
  }),
);

// ----------- Terms And Conditions ------------
export const StylesTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: '30px 0px 0px 30px',
}));

export const StylesText = styled(Typography)(({ theme }) => ({
  // color: theme.palette.primary.main,
  margin: '15px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '17px',
  letterSpacing: '0.4px',
  textTransform: 'capitalize',
  color: theme.palette.component.ToggleBoxBorder,
  textAlign: 'justify',
}));

export const StylesButton = styled(Button)(({ theme }) => ({
  marginBottom: '30px',
  boxSizing: 'border-box',
  width: '129px',
  height: '30px',
  background: '#25507B',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '4px',
}));

export const StylesTextContainer = styled(DialogContent)({
  margin: '10px 30px 15px 30px',
  padding: '0px',
  background: 'rgba(53, 114, 176, 0.11)', //no match
  borderRadius: '5px',
  '&:first-child': {
    paddingTop: 0,
  },
});

export const StylesTagline = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  color: theme.palette.component.color,
  margin: '0px 0px 0px 30px',
}));

// ------------- store page style.js ------------
export const StylesHeaderImg = styled('img')({
  width: '100%',
});

export const StylesHeadingGridContainer = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
}));

export const StylesOrderHistoryText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  marginLeft: '7px',
  fontSize: '17px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));

export const StylesOrderHistoryIcon = styled('img')(({ theme }) => ({
  height: '26px',
  width: '26px',
  [theme.breakpoints.down('md')]: {
    height: '13px',
    width: '13px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

export const StylesCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  height: '153px',
  overflow: 'visible',
  [theme.breakpoints.down('md')]: { height: '335px' },
}));

export const StylesCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  borderRadius: '5px',
  padding: '10px',
  height: '153px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: { height: '173px', borderRadius: '5px' },
}));

export const StylesCardContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 10px 10px 0px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '10px',
    padding: '0px 10px 10px 0px',
  },
  '&:last-child': {
    paddingBottom: '10px',
  },
}));

export const StylesMultiLineEllipsis = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  [theme.breakpoints.down('md')]: {
    '-webkit-line-clamp': 4,
    lineHeight: '12px',
    fontSize: '10px',
    height: '48px',
    marginBottom: '10px',
  },
}));

export const StylesProductName = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '14px',
  marginTop: '10px',
  marginBottom: '10px',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    marginTop: 0,
    marginBottom: '5px',
  },
}));

export const StylesCartBtns = styled(ButtonGroup)(({ theme }) => ({
  width: '9rem',
  height: '30px',
  fontSize: '12px',
  letterSpacing: '0.4px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '10px',
    lineHeight: '10px',
  },
}));

export const StylesCartBtn = styled(Button)(({ theme }) => ({
  width: '9rem',
  height: '30px',
  fontSize: '12px',
  letterSpacing: '0.4px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '10px',
    lineHeight: '10px',
  },
}));

export const StylesDiscountGrid = styled(Grid)(({ theme }) => ({
  order: 2,
  [theme.breakpoints.down('md')]: { order: 1, height: '28px' },
}));

export const StylesCartBtnGrid = styled(Grid)(({ theme }) => ({
  order: 1,
  [theme.breakpoints.down('md')]: { order: 2, paddingTop: '10px' },
}));

export const StylesDiscountPercent = styled(Typography)(({ theme }) => ({
  color: '#DE1D1D', //no match
  fontSize: '16px',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '10px',
  },
}));

export const StylesUnderlinePrice = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: '#76767F66', //no match
  fontSize: '12px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'left',
    fontSize: '10px',
    lineHeight: '7px',
  },
}));

export const StylesDiscountedRate = styled('div')(({ theme }) => ({
  fontSize: '20px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '10px',
    marginTop: '6px',
  },
}));

export const StylesGemImage = styled('img')(({ theme }) => ({
  width: '16px',
  [theme.breakpoints.down('md')]: {
    width: '10px',
  },
}));
