import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Box, Chip, Grid, Icon, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';
import {
  AnswerBox,
  OptionContainer,
  OptionSerialNumber,
  TagContainer,
} from './styles';

const QuestionBox = ({ data, reason }) => {
  const reason_type = data?.full_report?.reason_type;
  const tags = useSelector((state) => state.tag).allTags;
  const tag = tags.find((t) => t.id === data?.tag_id);
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    if (data?.full_report?.reason_type === 'CategoryIncorrect') {
      setNewTag(tags.find((t) => t.id === data?.full_report?.correct_ids[0]));
    } else {
      setNewTag(tags.find((t) => t.id === data?.full_report?.tag_id));
    }
  }, [data]);

  if (!data.id || !reason) return null;
  return (
    <Grid
      sx={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Chip
          label={`Level ${data.difficulty_level}`}
          sx={{
            color: '#25507B',
            background: '#3572B01A',
          }}
        />
        <Chip
          label={
            <Typography display={'flex'} gap="5px" fontWeight={'500'}>
              <Typography color="#388E3C" fontWeight={'600'}>
                +2
              </Typography>
              /
              <Typography color="#D60505" fontWeight={'600'}>
                1
              </Typography>
              Points
            </Typography>
          }
          color="primary"
          variant="outlined"
          sx={{
            background: 'rgba(53, 114, 176, 0.05)',
          }}
        />
      </Grid>
      <Grid>
        <Typography color="black" fontSize={'22px'} fontWeight={'300'}>
          {ReactHtmlParser(data.question_text)}
        </Typography>
        <Grid>
          {data?.options
            ?.sort((a, b) => a.option_serial_no - b.option_serial_no)
            ?.map((option) => (
              <Grid key={option.option_id}>
                {data?.answer_option_no?.includes(option.option_serial_no) ? (
                  <OptionContainer reason={reason}>
                    <Icon>
                      <DoneIcon sx={{ color: reason.color, width: '20px' }} />
                    </Icon>
                    <Typography sx={{ color: reason.color }}>
                      {ReactHtmlParser(option.option_text)}
                    </Typography>
                  </OptionContainer>
                ) : (
                  <Grid
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <OptionSerialNumber fontSize={'15px'}>
                      {option.option_serial_no}
                    </OptionSerialNumber>
                    <Typography sx={{ color: '#7C7C7C' }}>
                      {ReactHtmlParser(option.option_text)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>
        {reason.original_title ? (
          <Grid mb="10px">
            <Typography
              sx={{
                fontSize: '16px',
                color: 'black',
                fontWeight: '500',
              }}
              mb="15px"
            >
              {reason.original_title}
            </Typography>
            {['SubcategoryIncorrect'].includes(reason_type) ? (
              <Grid
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {tag?.sub_tags
                  ?.filter((subTag) => data?.sub_tag_array?.includes(subTag.id))
                  ?.map((subTag) => (
                    <TagContainer key={subTag.id}>
                      <img
                        src={subTag.s3_url}
                        alt="icon"
                        width={'20px'}
                        height={'20px'}
                      />
                      <Typography color="black">{subTag.name}</Typography>
                    </TagContainer>
                  ))}
              </Grid>
            ) : null}
            {['CategoryIncorrect'].includes(reason_type) ? (
              <TagContainer>
                <img
                  src={tag.s3_url}
                  alt="icon"
                  width={'20px'}
                  height={'20px'}
                />
                <Typography color="black">{tag.name}</Typography>
              </TagContainer>
            ) : null}
            {['Timeout'].includes(reason_type) ? (
              <AnswerBox>
                <Typography fontWeight={'500'} color="black">
                  {data?.max_time}
                </Typography>
              </AnswerBox>
            ) : null}
            {['AnswerIncorrect', 'SolutionTextImprovement'].includes(
              reason_type,
            ) ? (
              <AnswerBox
                sx={{
                  padding: '5px 10px',
                }}
              >
                <Typography color="black">
                  {reason_type === 'SolutionTextImprovement'
                    ? ReactHtmlParser(data?.solution_text)
                    : ReactHtmlParser(data?.answer_one_word)}
                </Typography>
              </AnswerBox>
            ) : null}
          </Grid>
        ) : null}
        <Grid>
          <Typography
            sx={{
              fontSize: '16px',
              color: 'black',
              fontWeight: '500',
            }}
            mb="15px"
          >
            {reason.suggested_title}
          </Typography>

          {['Timeout'].includes(reason_type) ? (
            <AnswerBox
              sx={{
                background: '#9370DB26',
              }}
            >
              <Typography fontWeight={'500'} color="black">
                {data?.full_report?.correct_values[0]}
              </Typography>
            </AnswerBox>
          ) : null}
          {[
            'QuestionTextImprovement',
            'AnswerIncorrect',
            'SolutionTextImprovement',
            'UnclearContent',
            'Plagiarised',
          ].includes(reason_type) ? (
            <AnswerBox
              sx={{
                padding: '5px 10px',
              }}
            >
              {reason_type === 'Plagiarised' ? (
                <Typography
                  color={'black'}
                  onClick={() => window.open(data?.full_report?.correct_title)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Click Here to Visit
                </Typography>
              ) : (
                <Typography color="black">
                  {ReactHtmlParser(data?.full_report?.correct_text)}
                </Typography>
              )}
            </AnswerBox>
          ) : null}

          {reason_type === 'OptionTextIncorrect' ? (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                background: '#9370DB26',
                borderRadius: '5px',
                padding: '5px',
              }}
            >
              <OptionSerialNumber
                fontSize={'15px'}
                sx={{
                  background: 'transparent',
                }}
                color="black"
              >
                {data?.full_report?.correct_values &&
                  data?.full_report?.correct_values?.[0]}
              </OptionSerialNumber>
              <Typography color="black">
                {ReactHtmlParser(data?.full_report?.correct_text)}
              </Typography>
            </Grid>
          ) : null}
          {reason_type === 'OptionIncorrect' ? (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                background: '#9370DB26',
                borderRadius: '5px',
                padding: '5px',
              }}
            >
              <OptionSerialNumber
                fontSize={'15px'}
                sx={{
                  background: 'transparent',
                }}
                color="black"
              >
                {data?.full_report?.correct_values[0]}
              </OptionSerialNumber>
              <Typography color="black">
                {ReactHtmlParser(
                  data.options.find(
                    (opt) =>
                      opt.option_serial_no ===
                      data?.full_report?.correct_values[0],
                  )?.option_text,
                )}
              </Typography>
            </Grid>
          ) : null}
          {['SubcategoryIncorrect'].includes(reason_type) ? (
            <Grid
              sx={{
                display: 'flex',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {newTag?.sub_tags
                ?.filter((subTag) =>
                  data?.full_report?.correct_ids?.includes(subTag.id),
                )
                ?.map((subTag) => (
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      background: '#9370DB1A',
                      borderRadius: '5px',
                      padding: '5px 10px',
                    }}
                    key={subTag.id}
                  >
                    <img
                      src={subTag.s3_url}
                      alt="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Typography color="black">{subTag.name}</Typography>
                  </Grid>
                ))}
            </Grid>
          ) : null}
          {['CategoryIncorrect'].includes(reason_type) ? (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                background: '#9370DB1A',
                borderRadius: '5px',
                padding: '5px 10px',
              }}
            >
              <img
                src={newTag.s3_url}
                alt="icon"
                width={'20px'}
                height={'20px'}
              />
              <Typography color="black">{newTag.name}</Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Box flexGrow={1} />
      {data?.full_report?.details ? (
        <Grid mt="15px">
          <Typography
            sx={{
              color: '#7B7B7B',
              fontSize: '16px',
            }}
          >
            <i>Additional Comment</i>
          </Typography>
          <Typography color="black" fontSize={'14px'}>
            {data?.full_report?.details}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default QuestionBox;
