import React from 'react';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TablePagination,
  TextField,
} from '@mui/material';
import {
  EmailingListPlaceholder,
  ToggleBox,
  ToggleText,
} from './StyledComponents';
import {
  getEmailLogs,
  getEmailTemplateList,
  getScheduledEmails,
} from '../../actions/emailingList';
import { useDispatch, useSelector } from 'react-redux';
import EmailingList from './EmailingList';
import ScheduledAndLogs from './ScheduledAndLogs';
import { Controller, useForm } from 'react-hook-form';

const AdminBulkEmail = () => {
  const { control, watch, setValue } = useForm();
  const dispatch = useDispatch();
  const [type, setType] = React.useState('sendMail');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [senderEmail, setSenderEmail] = React.useState('');
  const [recepientEmail, setRecepientEmail] = React.useState('');
  const [subjectFilter, setSubjectFilter] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const { scheduledEmailsData, emailLogData } = useSelector(
    (state) => state.emailingList,
  );
  const selectedTemplate = watch('selectedTemplate');
  const templateList = useSelector((state) => state.emailingList.templates);

  React.useEffect(() => {
    if (type === 'scheduled') {
      dispatch(
        getScheduledEmails(
          page + 1,
          rowsPerPage,
          senderEmail,
          recepientEmail,
          startDate,
          endDate,
          selectedTemplate,
        ),
      );
      dispatch(getEmailTemplateList());
    } else if (type === 'logs') {
      dispatch(
        getEmailLogs(
          page + 1,
          rowsPerPage,
          senderEmail,
          recepientEmail,
          startDate,
          endDate,
          selectedTemplate,
        ),
      );
      dispatch(getEmailTemplateList());
    }
  }, [
    type,
    page,
    rowsPerPage,
    senderEmail,
    recepientEmail,
    startDate,
    endDate,
    selectedTemplate,
  ]);

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  return (
    <Box>
      <ToggleBox display="flex" justifyContent={'space-around'}>
        <ToggleText
          onClick={() => {
            setType('sendMail');
          }}
          style={{
            background: type === 'sendMail' && '#25507B',
            color: type === 'sendMail' ? 'white' : '#25507B',
          }}
        >
          Send Mail
        </ToggleText>
        <ToggleText
          onClick={() => {
            setType('scheduled');
            setSenderEmail('');
            setRecepientEmail('');
            setStartDate('');
            setEndDate('');
            setValue('selectedTemplate', '');
          }}
          style={{
            background: type === 'scheduled' && '#25507B',
            color: type === 'scheduled' ? 'white' : '#25507B',
          }}
        >
          Scheduled
        </ToggleText>
        <ToggleText
          onClick={() => {
            setType('logs');
            setSenderEmail('');
            setRecepientEmail('');
            setStartDate('');
            setEndDate('');
            setValue('selectedTemplate', '');
          }}
          style={{
            background: type === 'logs' && '#25507B',
            color: type === 'logs' ? 'white' : '#25507B',
          }}
        >
          Mail Logs
        </ToggleText>
      </ToggleBox>
      {type !== 'sendMail' && (
        <Box sx={{ padding: '20px 55px' }}>
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <TextField
                placeholder="Filter By Sender Email"
                fullWidth
                margin="dense"
                value={senderEmail}
                onChange={(e) => setSenderEmail(e.target.value)}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                placeholder="Filter By Recipient Email"
                margin="dense"
                value={recepientEmail}
                onChange={(e) => setRecepientEmail(e.target.value)}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                placeholder="Filter By Subject"
                fullWidth
                margin="dense"
                value={subjectFilter}
                onChange={(e) => setSubjectFilter(e.target.value)}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid
              item
              sm={4}
              xs={12}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
            >
              <Controller
                name={'selectedTemplate'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    fullWidth
                    value={value}
                    onChange={onChange}
                    defaultValue=""
                    size="small"
                    displayEmpty
                    sx={{ height: '42px', marginBottom: '5px' }}
                    margin="dense"
                    renderValue={
                      value !== ''
                        ? undefined
                        : () => (
                            <EmailingListPlaceholder>
                              Select a Template
                            </EmailingListPlaceholder>
                          )
                    }
                  >
                    <MenuItem value="">Select a Template</MenuItem>
                    {templateList.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.template_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                placeholder="Filter By Start Date"
                type="date"
                margin="dense"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                placeholder="Filter By End Date"
                type="date"
                margin="dense"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                inputProps={{
                  style: {
                    height: '10px',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {type === 'sendMail' ? (
        <Box mt={2}>
          <EmailingList />
        </Box>
      ) : (
        <Box>
          <Container>
            <Paper>
              <ScheduledAndLogs
                title={type === 'scheduled' ? 'Scheduled Emails' : 'Mail Logs'}
                mailData={
                  type === 'scheduled' ? scheduledEmailsData : emailLogData
                }
                type={type}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Paper>
            <TablePagination
              component="nav"
              page={page}
              rowsPerPage={rowsPerPage}
              count={
                type === 'scheduled'
                  ? scheduledEmailsData?.page_size *
                    scheduledEmailsData?.total_pages
                  : emailLogData?.page_size * emailLogData?.total_pages
              }
              onPageChange={onPageChangeHandler}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows"
              className="scoreboard__navigation"
            />
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default AdminBulkEmail;
