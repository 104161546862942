import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import Gems from '../../images/Gemsnew.svg';
import { useStyles } from './styles';
import Loader from './../Loader';
import { TableContainer } from '@mui/material';
import Tip from '../../images/Tip.svg';
import NoTransactionImg from '../../images/TransactionImg.png';
import CustomPagination from '../Leaderboard/CustomPagination';
import { StyleCredits } from './StyledComponent';
import config from '../../config';

const getCreditAction = (action) => {
  switch (action) {
    case 'TimeIncrease':
      return 'Question time extended';

    case 'CategoryUnlock':
      return 'Category unlocked';

    case 'CompetitionCreditsEarned':
      return 'Competition Reward';

    case 'CompetitionRegister':
      return 'Competition Register';

    case 'StreakSaved':
      return 'Streak saved';

    case 'QuestionAccepted':
      return 'Question contributed';

    case 'QuestionBlocked':
      return 'Active question blocked';

    case 'UserReferral':
      return 'Referral Bonus';

    case 'OnboardingCredits':
      return 'Onboarding Bonus';

    default:
      return action;
  }
};

const Credits = ({ getCredits, credits }) => {
  const { paginationInfo } = credits;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState('credits');
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState(
    paginationInfo.currentPage + 1,
  );
  const fetchCredits = () => {
    getCredits({
      page: currentPage,
      page_size: rowsPerPage,
      type: type,
    });
  };
  useEffect(() => {
    fetchCredits();
  }, [currentPage, rowsPerPage, type]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  if (credits.isFetchingCredits) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box
        display="flex"
        justifyContent={'space-around'}
        className={styles.toggleBox}
        mb={2}
      >
        <Typography
          onClick={() => setType('credits')}
          className={styles.toggleText}
          style={{
            background: type !== 'gems' && '#25507B',
            color: type !== 'gems' ? 'white' : 'black',
          }}
        >
          <img src={CreditIcon} width="15px" /> Credits
        </Typography>
        <Typography
          onClick={() => setType('gems')}
          className={styles.toggleText}
          style={{
            background: type === 'gems' && '#25507B',
            color: type === 'gems' ? 'white' : 'black',
          }}
        >
          <img src={Gems} width="15px" /> Gems
        </Typography>
      </Box>
      <Box className={styles.headerText} mt={2} mb={4}>
        Unlock a World of Possibilities and Exclusive Rewards with Abekus
        Credit.{' '}
        <Link
          href={`${config.WEBSITE_URL}/help-center/credits.html`}
          target="_blank"
          style={{ marginLeft: 7, color: '#3572B0', fontWeight: 400 }}
        >
          Learn More
        </Link>
      </Box>
      <StyleCredits>
        <Paper className="referral-card">
          <TableContainer>
            <Table size="small" stickyHeader={true}>
              <TableRow className={styles.tableHeader}>
                <TableCell
                  align="center"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    width: '10%',
                    margin: 'auto',
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    width: '35%',
                    margin: 'auto',
                  }}
                >
                  Action
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    margin: 'auto',
                    width: '10%',
                  }}
                >
                  Credit
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    margin: 'auto',
                    width: '8%',
                  }}
                >
                  Debit
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    margin: 'auto',
                    width: '17%',
                  }}
                >
                  Balance
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: 'white',
                    borderBottom: 'none',
                    margin: 'auto',
                    width: '20%',
                  }}
                  className={styles.dateCell}
                >
                  Date of Transaction
                </TableCell>
              </TableRow>
              {!credits.list ? (
                <>
                  <img
                    src={NoTransactionImg}
                    style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '50px',
                    }}
                  />
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ color: '#25507B', fontWeight: 600 }}
                  >
                    Oops! No Transaction Found
                  </Typography>
                  <Typography align="center" variant="subtitle1">
                    Participate in competitions, to earn Gems and redeem awesome
                    rewards from Store! Oops! No Transaction Found
                  </Typography>
                </>
              ) : (
                <TableBody>
                  {credits?.list?.map((item, index) => (
                    <TableRow key={index} className={styles.bodyRow}>
                      <TableCell
                        align="center"
                        style={{
                          borderBottom: 'none',
                          color: '#25507B',
                          width: '10%',
                          margin: 'auto',
                        }}
                      >
                        {(currentPage - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: 'none',
                          color: '#25507B',
                          width: '35%',
                          margin: 'auto',
                        }}
                      >
                        {getCreditAction(item.action_type)}
                        <p className={styles.responsiveDate}>
                          {' '}
                          {moment
                            .utc(item.created_at)
                            .utcOffset('+05:30')
                            .format('DD MMM YYYY, hh:mma')}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: 'none',
                          color: '#388E3C',
                          fontWeight: 600,
                          margin: 'auto',
                          width: '10%',
                        }}
                      >
                        {type === 'credits'
                          ? item.no_of_credits > 0 && item.no_of_credits
                          : item.no_of_gems > 0 && item.no_of_gems}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: 'none',
                          color: '#D60505',
                          fontWeight: 600,
                          margin: 'auto',
                          width: '8%',
                        }}
                      >
                        {type === 'credits'
                          ? item.no_of_credits < 0 && item.no_of_credits
                          : item.no_of_gems < 0 && item.no_of_gems}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: 'none',
                          color: '#3572B0',
                          fontWeight: 600,
                          width: '17%',
                          margin: 'auto',
                        }}
                      >
                        {type === 'credits'
                          ? item.balance_credits
                          : item.balance_gems}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          borderBottom: 'none',
                          color: '#7C7C7C',
                          margin: 'auto',
                          width: '20%',
                        }}
                        className={styles.dateCell}
                      >
                        {moment
                          .utc(item.created_at)
                          .utcOffset('+05:30')
                          .format('DD MMM YYYY, hh:mma')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {credits.list && (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <CustomPagination
                count={paginationInfo.pageSize * paginationInfo.totalPages}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOption={[10, 25, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </Box>
          )}
        </Paper>
      </StyleCredits>
      <Typography
        style={{
          background: '#3572B012',
          padding: '13px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
          borderRadius: '5px',
          marginTop: '15px',
          color: '#25507B',
        }}
      >
        <img src={Tip} width={'10px'} />
        &nbsp;&nbsp; Tip: Earn more credits by contributing questions, referring
        new users and by winning competitions!
      </Typography>
    </Container>
  );
};

export default Credits;
