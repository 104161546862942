import { styled } from '@mui/styles';

export const StyledWrapperContainer = styled('div')(({ theme }) => ({
  '& .tags-form': {
    padding: '40px 0',
    '& .form-control': {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: 0,
      alignItems: 'unset',
      padding: '10px 0',
      '& > label': {
        marginBottom: '10px',
      },
      '& .text-input-field': {
        width: '100%',
      },
    },
  },

  '& .subtag-select': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
  },
  '& .error': {
    color: theme.palette.common.error,
  },
}));
