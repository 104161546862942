import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

const EditUserDialog = ({ open, onClose, selectedUser, onDetailsSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <>
        <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography variant="h6" className={'dialog-title'} color="primary">
            Update Details
          </Typography>
        </Grid>
        <DialogContent className="dialog-content">
          <Box>
            <Grid mb={2}>
              <label>Banned Status</label>
              <Select
                {...register('is_banned', {
                  required: 'Please select ban status',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                error={!!errors.is_banned}
                helperText={!!errors.is_banned?.message}
                sx={{
                  height: '40px',
                }}
                defaultValue={selectedUser?.is_banned?.toString()}
              >
                <MenuItem value={'true'} key={'true'}>
                  True
                </MenuItem>
                <MenuItem value={'false'} key={'false'}>
                  False
                </MenuItem>
              </Select>
            </Grid>

            <Grid mb={2}>
              <label>User Type</label>
              <Select
                {...register('user_type', {
                  required: 'Please select user type',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                error={!!errors.user_type}
                helperText={!!errors.user_type?.message}
                sx={{
                  height: '40px',
                }}
                defaultValue={selectedUser?.user_type}
              >
                <MenuItem value={'Learner'} key={'Learner'}>
                  Learner
                </MenuItem>
                <MenuItem value={'Employer'} key={'Employer'}>
                  Employer
                </MenuItem>
                <MenuItem value={'SalesExecutive'} key={'SalesExecutive'}>
                  SalesExecutive
                </MenuItem>
                <MenuItem value={'Admin'} key={'Admin'}>
                  Admin
                </MenuItem>
                <MenuItem value={'TPO'} key={'TPO'}>
                  TPO
                </MenuItem>
              </Select>
            </Grid>
            <Grid mb={2}>
              <label>Is Active</label>
              <Select
                {...register('is_active', {
                  required: 'Please select active status',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                error={!!errors.is_active}
                helperText={!!errors.is_active?.message}
                sx={{
                  height: '40px',
                }}
                defaultValue={selectedUser?.is_active?.toString()}
              >
                <MenuItem value={'true'} key={'true'}>
                  True
                </MenuItem>
                <MenuItem value={'false'} key={'false'}>
                  False
                </MenuItem>
              </Select>
            </Grid>
            <Grid mb={2}>
              <label>credits</label>
              <TextField
                {...register('credits', {
                  required: 'Please enter the credits',
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                defaultValue={selectedUser?.credits || 0}
                error={!!errors.credits}
                helperText={errors.credits?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ marginTop: '20px' }}
          >
            <Button variant="outlined" type="button" onClick={onClose}>
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={handleSubmit(onDetailsSubmit)}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default EditUserDialog;
