import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const credits = createReducer(
  {
    list: [],
    costData: [],
    isFetchingCredits: false,
    isFetchingCreditsCost: false,
    paginationInfo: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 1,
    },
  },
  {
    [types.GET_CREDITS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCredits: true,
      });

      return newState;
    },
    [types.GET_CREDITS_SUCCESS](state, action) {
      return {
        ...state,
        isFetchingCredits: false,
        list: action.data.data,
        paginationInfo: {
          currentPage: action.data.current_page - 1, // zero-based
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
      };
    },
    [types.GET_CREDITS_FAIL](state) {
      return {
        ...state,
        isFetchingCredits: false,
      };
    },
    [types.GET_CREDITS_COST_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCreditsCost: true,
      });

      return newState;
    },
    [types.GET_CREDITS_COST_SUCCESS](state, action) {
      return {
        ...state,
        isFetchingCreditsCost: false,
        costData: action.data,
      };
    },
    [types.GET_CREDITS_COST_FAIL](state) {
      return {
        ...state,
        isFetchingCreditsCost: false,
      };
    },
  },
);
