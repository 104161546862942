import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import Gems from '../Gems';
import {
  getCartList,
  getShippingAddressList,
  initiatePayment,
  updateCart,
} from '../../actions/userStore';
import { useDispatch, useSelector } from 'react-redux';
import ShippingDetailsDialog from './ShippingDetailsDialog';
import { NavLink, useNavigate } from 'react-router-dom';
import { CustomDialogBox } from '../DialogBox';

import {
  StyledCard,
  Heading,
  StyledCardImg,
  CardImgContainer,
  StyleFormLabel,
  StyleFabIcon,
  QuantityInput,
} from './StoreStyledComponent';

const Cart = () => {
  // const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openGemsDialog, setOpenGemsDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openPaymentCompleteDialog, setOpenpaymentCompleteDialog] = useState(
    false,
  );

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleGemsDialogClose = () => {
    setOpenGemsDialog(false);
  };
  const handlePaymentDialogClose = () => {
    setOpenpaymentCompleteDialog(false);
    navigate('/l/orderHistory');
  };
  useEffect(() => {
    dispatch(getCartList());
    dispatch(getShippingAddressList());
  }, [dispatch]);

  const { cartItems, shippingAddress, success } = useSelector(
    (state) => state.userStore,
  );
  useEffect(() => {
    if (success) {
      setOpenpaymentCompleteDialog(true);
    }
  }, [success]);

  const { data } = useSelector((state) => state.user);

  const updateQuantityDecrease = (id, quantity, paymentMode) => {
    const arr = [];

    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevData = {
          item_id:
            cartItems.cart[i]?.item_id === id ? id : cartItems.cart[i]?.item_id,
          quantity:
            cartItems.cart[i]?.item_id === id
              ? quantity - 1
              : cartItems.cart[i]?.quantity,
          payment_mode:
            cartItems.cart[i]?.item_id === id
              ? paymentMode
              : cartItems.cart[i]?.total_amount_in_currency
              ? 'Currency'
              : 'Gems',
        };
        arr.push(prevData);
      }
      dispatch(updateCart(arr));
    } catch (err) {}
  };

  const updateQuantityIncrease = (id, quantity, paymentMode) => {
    const arr = [];

    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevData = {
          item_id:
            cartItems.cart[i]?.item_id === id ? id : cartItems.cart[i]?.item_id,
          quantity:
            cartItems.cart[i]?.item_id === id
              ? quantity + 1
              : cartItems.cart[i]?.quantity,
          payment_mode:
            cartItems.cart[i]?.item_id === id
              ? paymentMode
              : cartItems.cart[i]?.total_amount_in_currency
              ? 'Currency'
              : 'Gems',
        };
        arr.push(prevData);
      }
      dispatch(updateCart(arr));
    } catch (err) {}
  };

  const removeProductFromCart = (id) => {
    const filteredCartList =
      cartItems.cart && cartItems.cart?.filter((item) => item.item_id != id);
    let arr = [];
    filteredCartList.forEach((item) => {
      const data = {
        item_id: item.item_id,
        quantity: item.quantity,
        payment_mode: item.total_amount_in_currency ? 'Currency' : 'Gems',
      };
      arr.push(data);
    });
    try {
      dispatch(updateCart(arr));
    } catch (err) {}
  };

  const handlePayment = (e) => {
    e.preventDefault();
    const userDetails = {
      name: data.first_name,
      email: data.email,
      phoneNumber: data.phone_number,
    };
    const totalAmount = cartItems.cart
      ?.map((i) => i.total_amount_in_currency)
      .reduce((a, b) => a + b);
    const totalGems = cartItems.cart
      ?.map((i) => i.total_amount_in_gems)
      .reduce((a, b) => a + b);

    if (totalGems > data.gems) {
      setOpenGemsDialog(true);
      setAlertMessage("You Don't Have Enough Gems!");
    } else if (shippingAddress.billing) {
      dispatch(initiatePayment(totalAmount, userDetails));
    } else {
      setOpenDialog(true);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="flex-start">
        <Typography style={{ marginLeft: '0.5rem' }} variant="h6">
          Cart({cartItems.cart?.length})
        </Typography>
      </Box>
      {shippingAddress ? (
        <StyledCard>
          <Grid container>
            <Grid item xs={5}>
              <strong>Billing Address </strong> <br />
              {shippingAddress.billing ? (
                <Box mt="5px">
                  {shippingAddress.billing?.name},{' '}
                  {shippingAddress.billing?.pincode}
                  <Typography>{shippingAddress.billing?.address}</Typography>
                  <Typography>
                    {shippingAddress.billing?.state} ,{' '}
                    {shippingAddress.billing?.country}
                  </Typography>
                  <Typography>{shippingAddress.billing?.phone_no}</Typography>
                </Box>
              ) : (
                'No Billing address added.'
              )}
            </Grid>
            <Grid item xs={5}>
              <strong>Shipping Address </strong> <br />
              {shippingAddress.shipping ? (
                <Box mt="5px">
                  {shippingAddress.shipping?.name},{' '}
                  {shippingAddress.shipping?.pincode}
                  <Typography>{shippingAddress.shipping?.address}</Typography>
                  <Typography>
                    {shippingAddress.shipping?.state} ,{' '}
                    {shippingAddress.shipping?.country}
                  </Typography>
                  <Typography>{shippingAddress.shipping?.phone_no}</Typography>
                </Box>
              ) : (
                'No shipping address added.'
              )}
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleDialogOpen} size="large">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
            {!cartItems.cart?.length ? (
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent="center">
                  <Heading>You Don&apos;t have any items in Cart</Heading>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </StyledCard>
      ) : (
        <StyledCard>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={handleDialogOpen}
                >
                  Add Address
                </Button>
              </Box>
            </Grid>
          </Grid>
        </StyledCard>
      )}

      <Grid container>
        <Grid item lg={8} sm={12} xs={12}>
          {cartItems.cart &&
            cartItems.cart?.map((item) => (
              <StyledCard key={item.item_id}>
                <Grid container>
                  <CardImgContainer item md={3} sm={12} xs={12}>
                    <StyledCardImg
                      src={item.item?.image_url[0]}
                      alt="ProductImg"
                    />
                  </CardImgContainer>
                  <Grid
                    item
                    md={9}
                    xs={12}
                    sm={12}
                    style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                  >
                    <div>
                      <Typography variant="h5" color="primary">
                        {item.item?.name}
                      </Typography>
                      <Typography>{item.item?.item_description}</Typography>
                    </div>
                    <br />
                    <Grid container>
                      <Grid item md={4} sm={12} xs={12}>
                        <StyleFormLabel component="legend">
                          Total Amount
                        </StyleFormLabel>
                        <strong>
                          <Typography
                            variant="h5"
                            style={{ color: 'green', paddingBottom: '10px' }}
                          >
                            {item.total_amount_in_currency ? (
                              '₹' + item.total_amount_in_currency
                            ) : (
                              <Gems />
                            )}{' '}
                            {item.total_amount_in_gems &&
                              item.total_amount_in_gems}
                          </Typography>{' '}
                        </strong>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <FormControl component="fieldset">
                          <StyleFormLabel component="legend">
                            Quantity
                          </StyleFormLabel>
                          <Box flexDirection="row" display="flex">
                            <StyleFabIcon
                              style={{ marginRight: '0.5rem' }}
                              disabled={item.quantity <= 1 ? true : false}
                              size="small"
                              aria-label="minus"
                              onClick={() =>
                                updateQuantityDecrease(
                                  item.item_id,
                                  item.quantity,
                                  item.total_amount_in_currency
                                    ? 'Currency'
                                    : 'Gems',
                                )
                              }
                            >
                              <RemoveIcon />
                            </StyleFabIcon>
                            <div>
                              <QuantityInput
                                sx={{
                                  margin: 'auto',
                                  '& input': {
                                    padding: '10px',
                                    textAlign: 'center',
                                  },
                                }}
                                margin="dense"
                                variant="outlined"
                                type="text"
                                value={item.quantity}
                              />
                            </div>
                            <StyleFabIcon
                              style={{ marginLeft: '0.5rem' }}
                              disabled={item.quantity >= 10 ? true : false}
                              size="small"
                              aria-label="add"
                              onClick={() =>
                                updateQuantityIncrease(
                                  item.item_id,
                                  item.quantity,
                                  item.total_amount_in_currency
                                    ? 'Currency'
                                    : 'Gems',
                                )
                              }
                            >
                              <AddIcon />
                            </StyleFabIcon>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 'auto' }}
                      >
                        <br />
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            style={{ color: 'red', marginTop: '7px' }}
                            variant="text"
                            type="button"
                            onClick={() => removeProductFromCart(item.item_id)}
                          >
                            Remove
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledCard>
            ))}
        </Grid>
        {cartItems.cart?.length !== 0 && (
          <Grid item lg={4} sm={6} xs={12}>
            <StyledCard style={{ padding: '1rem' }}>
              <Typography variant="h6">Payment</Typography>
              <hr />
              <br />
              {cartItems.gross_total_in_gems && (
                <Grid container>
                  <Grid item sm={4} xs={6}>
                    Gems
                  </Grid>
                  <Grid item sm={8} xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <strong style={{ color: 'green', fontSize: '18px' }}>
                        <Gems />{' '}
                        {cartItems.cart
                          ?.map((i) => i.total_amount_in_gems)
                          .reduce((a, b) => a + b)}
                      </strong>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <br />
              {cartItems.gross_total_in_currency && (
                <Grid container>
                  <Grid item sm={4} xs={6}>
                    Amount
                  </Grid>
                  <Grid item sm={8} xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <strong style={{ color: 'green', fontSize: '18px' }}>
                        ₹{' '}
                        {cartItems.cart
                          ?.map((i) => i.total_amount_in_currency)
                          .reduce((a, b) => a + b)}
                      </strong>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <br />
              <hr />
              <Grid container>
                <Grid item sm={4} xs={6}>
                  Total
                </Grid>
                <Grid item sm={8} xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <strong style={{ color: 'green', fontSize: '19px' }}>
                      {' '}
                      {cartItems.gross_total_in_gems && (
                        <>
                          <Gems />
                          &nbsp;
                          {cartItems.cart
                            ?.map((i) => i.total_amount_in_gems)
                            .reduce((a, b) => a + b)}{' '}
                        </>
                      )}
                      {cartItems.gross_total_in_currency && (
                        <>
                          + ₹
                          {cartItems.cart
                            ?.map((i) => i.total_amount_in_currency)
                            .reduce((a, b) => a + b)}
                        </>
                      )}{' '}
                    </strong>
                  </Box>
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  onClick={handlePayment}
                >
                  Complete Checkout
                </Button>
              </Box>
            </StyledCard>
          </Grid>
        )}
      </Grid>
      {openDialog ? (
        <ShippingDetailsDialog
          openDialog={handleDialogOpen}
          closeDialog={handleDialogClose}
          address={shippingAddress}
        />
      ) : null}
      {openGemsDialog ? (
        <CustomDialogBox
          open={openGemsDialog}
          onClose={handleGemsDialogClose}
          actions={
            <Button
              variant="outlined"
              type="button"
              color="primary"
              onClick={handleGemsDialogClose}
            >
              OK
            </Button>
          }
          title="Message"
          maxWidth={'sm'}
        >
          <Typography variant="h6">{alertMessage}</Typography>
        </CustomDialogBox>
      ) : null}
      {openPaymentCompleteDialog ? (
        <CustomDialogBox
          open={openPaymentCompleteDialog}
          onClose={handlePaymentDialogClose}
          actions={
            <NavLink to="/l/store">
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={handleGemsDialogClose}
              >
                OK
              </Button>
            </NavLink>
          }
          title="Message"
          maxWidth={'sm'}
        >
          <Typography variant="h6">Your Order has been Placed!</Typography>
        </CustomDialogBox>
      ) : null}
    </Container>
  );
};

export default Cart;
