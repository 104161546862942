import axios from 'axios';
import * as types from './types';
import config from './../config';

export function getDashboardStart() {
  return {
    type: types.GET_DASHBOARD_START,
  };
}
export function getDashboardSuccess({ data }) {
  return {
    type: types.GET_DASHBOARD_SUCCESS,
    data: data,
  };
}

export function getDashboardFail() {
  return {
    type: types.GET_DASHBOARD_FAIL,
  };
}

export function getDashboard() {
  return (dispatch, getState) => {
    dispatch(getDashboardStart());
    const { auth } = getState();

    axios
      .get(`${config.API_URL}/dashboard`, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(getDashboardSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getDashboardFail());
      });
  };
}
