import axios from 'axios';
import * as types from './types';
import config from './../config';
import apiClient from '../api/apiclient';

const getUserPublicProfileStart = () => {
  return {
    type: types.GET_PUBLIC_PROFILE_START,
  };
};

const getUserPublicProfileSuccess = ({ data }) => {
  return {
    type: types.GET_PUBLIC_PROFILE_SUCCESS,
    data: data,
  };
};

const getUserPublicProfileFail = () => {
  return {
    type: types.GET_PUBLIC_PROFILE_FAIL,
  };
};

export const getUserPublicProfile = (publicUserId) => {
  return (dispatch) => {
    dispatch(getUserPublicProfileStart());

    apiClient
      .getUserApi()
      .userApiGetUserPublicProfile(publicUserId, undefined)
      .then((resp) => {
        dispatch(getUserPublicProfileSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserPublicProfileFail());
      });
  };
};

const getFollowedUsersListStart = () => {
  return {
    type: types.GET_FOLLOWED_USERS_LIST_START,
  };
};

const getFollowedUsersListSuccess = ({ data }) => {
  return {
    type: types.GET_FOLLOWED_USERS_LIST_SUCCESS,
    data: data,
  };
};

const getFollowedUsersListFail = () => {
  return {
    type: types.GET_FOLLOWED_USERS_LIST_FAIL,
  };
};

export const getFollowedUsersList = () => {
  return (dispatch, getState) => {
    dispatch(getFollowedUsersListStart());
    const { auth } = getState();

    axios
      .get(`${config.API_URL}/user/get_followed_users?follow_type=followed`, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(getFollowedUsersListSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getFollowedUsersListFail());
      });
  };
};

export function postUnfollowStart() {
  return {
    type: types.POST_UNFOLLOW_START,
  };
}

export function postUnfollowSuccess(data) {
  return {
    type: types.POST_UNFOLLOW_SUCCESS,
    data,
  };
}

export function postUnfollowFail() {
  return {
    type: types.POST_UNFOLLOW_FAIL,
  };
}

export function postUnfollow(userId) {
  return (dispatch, getState) => {
    dispatch(postUnfollowStart());

    const { auth } = getState();
    axios
      .post(
        `${config.API_URL}/user/unfollow/${userId}`,
        {},
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((resp) => {
        dispatch(postUnfollowSuccess(resp.data));
      })
      .catch(() => {
        dispatch(postUnfollowFail());
      });
  };
}

export function postFollowStart() {
  return {
    type: types.POST_FOLLOW_START,
  };
}

export function postFollowSuccess(data) {
  return {
    type: types.POST_FOLLOW_SUCCESS,
    data,
  };
}

export function postFollowFail() {
  return {
    type: types.POST_FOLLOW_FAIL,
  };
}

export function postFollow(userId) {
  return (dispatch, getState) => {
    dispatch(postFollowStart());

    const { auth } = getState();
    axios
      .post(
        `${config.API_URL}/user/follow/${userId}`,
        {},
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((resp) => {
        dispatch(postFollowSuccess(resp.data));
      })
      .catch(() => {
        dispatch(postFollowFail());
      });
  };
}

function getUserActivityStart() {
  return {
    type: types.GET_USER_ACTIVITY_START,
  };
}

function getUserActivitySuccess(data) {
  return {
    type: types.GET_USER_ACTIVITY_SUCCESS,
    payload: data,
  };
}

function getUserActivityFail(error) {
  return {
    type: types.GET_USER_ACTIVITY_FAIL,
    payload: error,
  };
}

export function getUserActivity({
  selectedMonth,
  summaryType = 'monthwise',
  selectedYear,
  username,
}) {
  return (dispatch) => {
    dispatch(getUserActivityStart());
    apiClient
      .getScoreApi()
      .scoreApiGetUserDailyActivity(
        username,
        selectedMonth,
        selectedYear,
        summaryType,
      )
      .then((res) => dispatch(getUserActivitySuccess(res.data)))
      .catch((err) => dispatch(getUserActivityFail(err)));
  };
}
