import createReducer from '../lib/createReducer';
import * as types from '../actions/types';

export const adminDegreeCreate = createReducer(
  {
    degreeData: [],
    specializationData: [],
  },
  {
    [types.GET_ADMIN_DEGREE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        degreeData: [],
        isFetchingAdminDegrees: true,
      });

      return newState;
    },
    [types.GET_ADMIN_DEGREE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        degreeData: action.data,
        isFetchingAdminDegrees: false,
      });

      return newState;
    },
    [types.GET_ADMIN_DEGREE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        degreeData: [],
        isFetchingAdminDegrees: false,
      });

      return newState;
    },

    [types.POST_ADMIN_DEGREE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminDegrees: true,
      });

      return newState;
    },
    [types.POST_ADMIN_DEGREE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminDegrees: false,
      });

      return newState;
    },
    [types.POST_ADMIN_DEGREE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminDegrees: false,
      });

      return newState;
    },

    [types.UPDATE_ADMIN_DEGREE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminDegrees: true,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_DEGREE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminDegrees: false,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_DEGREE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminDegrees: false,
      });

      return newState;
    },

    [types.GET_ADMIN_SPECIALIZATION_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        specializationData: [],
        isFetchingAdminSpecialization: true,
      });

      return newState;
    },
    [types.DELETE_ADMIN_DEGREE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminDegree: true,
      });

      return newState;
    },
    [types.DELETE_ADMIN_DEGREE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminDegree: false,
      });

      return newState;
    },
    [types.DELETE_ADMIN_DEGREE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminDegree: false,
      });

      return newState;
    },
    [types.GET_ADMIN_SPECIALIZATION_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        specializationData: action.data,
        isFetchingAdminSpecialization: false,
      });

      return newState;
    },
    [types.GET_ADMIN_SPECIALIZATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        specializationData: [],
        isFetchingAdminSpecialization: false,
      });

      return newState;
    },

    [types.POST_ADMIN_SPECIALIZATION_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminSpecialization: true,
      });

      return newState;
    },
    [types.POST_ADMIN_SPECIALIZATION_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminSpecialization: false,
      });

      return newState;
    },
    [types.POST_ADMIN_SPECIALIZATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingAdminSpecialization: false,
      });

      return newState;
    },

    [types.UPDATE_ADMIN_SPECIALIZATION_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSpecialization: true,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_SPECIALIZATION_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSpecialization: false,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_SPECIALIZATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSpecialization: false,
      });

      return newState;
    },
    [types.DELETE_ADMIN_SPECIALIZATION_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSpecialization: true,
      });

      return newState;
    },
    [types.DELETE_ADMIN_SPECIALIZATION_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSpecialization: false,
      });

      return newState;
    },
    [types.DELETE_ADMIN_SPECIALIZATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSpecialization: false,
      });

      return newState;
    },
  },
);

export { adminDegreeCreate as default };
