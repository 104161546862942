import React, { useEffect, useState } from 'react';
import ImagePicker from './../ImagePicker';
import ReactSelect from 'react-select';
import FormData from 'form-data';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Card from './../Card';
import MessageBar from '../MessageBar';
import Loader from '../Loader';

import './index.css';
import { withRouter } from '../../routes';
import { StyledWrapperContainer } from './StyledComponent';

const getTagById = (list = [], id) => {
  return list.find((item) => item.id === id);
};

const getSubTagById = (list = [], id) => {
  return list.find((item) => item.id === id);
};
const CreateSubTag = (props) => {
  const [subCategoryName, setSubCategoryName] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory_image, setSubcategoryImage] = useState(null);
  const [isStatusMessageVisible, setStatusMessageVisible] = useState(false);
  const [subCategoryCreateStatus, setSubCategoryCreateStatus] = useState('');
  const [subCategoryCreateMessage, setSubCategoryCreateMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [updateSubtagState, setUpdateSubtagState] = useState(false);
  const [approval_status, setApprovalStatus] = useState('Accepted');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { getTag, tag, params } = props;
    getTag();
    const tagList = tag.allTags;
    const tagId = params.tagId;
    const tagDetails = getTagById(tagList, tagId) || {};
    const subTagList = tagDetails.sub_tags || [];
    const subTagId = params.subTagId;
    const subtagDetails = getSubTagById(subTagList, subTagId) || {};
    const { name, s3_url, approval_status } = subtagDetails;
    setSubCategoryName(name || '');
    setCategory(tagDetails.id);
    setSubcategoryImage(s3_url);
    setUpdateSubtagState(!!subTagId);
    setApprovalStatus(approval_status || 'Accepted');
  }, []);

  useEffect(() => {
    const { tag, params } = props;
    const tagList = tag.allTags;
    const tagId = params.tagId;
    const tagDetails = getTagById(tagList, tagId) || [];
    const subTagList = tagDetails.sub_tags || [];
    const subTagId = params.subTagId;
    const subtagDetails = getSubTagById(subTagList, subTagId) || {};
    const { name, s3_url, approval_status } = subtagDetails;
    setSubCategoryName(name || '');
    setCategory(tagDetails.id);
    setSubcategoryImage(s3_url);
    setUpdateSubtagState(!!subTagId);
    setApprovalStatus(approval_status);
  }, [props.tag?.allTags?.length]);

  useEffect(() => {
    if (props.tag?.postSubtagSuccess) {
      setStatusMessageVisible(true);
      setSubCategoryCreateStatus('success');
      setSubCategoryCreateMessage(
        'Congrats! Sub-Category created successfully. Redirecting...',
      );
      setLoading(false);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  }, [props.tag?.postSubtagSuccess]);

  useEffect(() => {
    const { tag } = props;
    if (props.tag?.postSubtagFail) {
      if (tag?.subtagErrorStatus?.status === 400) {
        setStatusMessageVisible(true);
        setSubCategoryCreateStatus('error');
        setSubCategoryCreateMessage('Category name already exists');
        setLoading(false);
      } else {
        setStatusMessageVisible(true);
        setSubCategoryCreateStatus('error');
        setSubCategoryCreateMessage('Unable to create sub-category');
        setLoading(false);
      }
    }
  }, [props.tag?.postSubtagFail]);

  const validate = (values) => {
    const errors = {};

    if (values.subCategoryName.trim() === '') {
      errors.subCategoryName = 'Please enter the tag name';
    }
    if (values.category.trim() === '') {
      errors.tag = 'Please enter the tag description';
    }
    if (!values.subcategory_image) {
      errors.subcategory_image = 'Please upload the tag icon';
    }

    return errors;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { postSubtag, updateSubtag, params } = props;
    const values = {
      subCategoryName,
      category,
      subcategory_image,
      approval_status,
    };
    const errors = validate(values);

    if (!Object.keys(errors).length) {
      let tagForm = new FormData();
      tagForm.append('name', subCategoryName);
      tagForm.append('parent_tag_id', category);
      tagForm.append('approval_status', values.approval_status);

      if (subcategory_image) {
        tagForm.append('sub_tag_image', subcategory_image);
      }

      if (updateSubtagState === true) {
        setLoading(true);
        updateSubtag(tagForm, params.subTagId);
      } else {
        setLoading(true);
        postSubtag(tagForm);
      }
    } else {
      setErrors(errors);
    }
  };

  const onFileLoad = (file) => {
    setSubcategoryImage(file);
  };

  const handleMessageClose = () => {
    setStatusMessageVisible(false);
  };

  const handleChange = (tag) => {
    setCategory(tag ? tag.id : null);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleApprovalStatusChange = (value) => {
    setApprovalStatus(value.value);
  };

  const { tag, navigate } = props;
  const options = [
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
  ];
  const cardContent = (
    <div className="position-center">
      <div className="tags-form">
        <form onSubmit={onSubmit}>
          <div className="form-control">
            <div className="field-item">
              <label
                htmlFor=""
                style={{ marginBottom: '10px', display: 'block' }}
              >
                Select Category
              </label>
              <ReactSelect
                options={tag.list}
                onChange={handleChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={tag.list.find((c) => c.id === category)}
                isClearable
              />
              <p className="error">{errors.category}</p>
            </div>
          </div>
          <div className="form-control">
            <div>
              <label
                htmlFor=""
                style={{ marginBottom: '10px', display: 'block' }}
              >
                Sub-category Name
              </label>
              <TextField
                name="subCategoryName"
                value={subCategoryName}
                onChange={handleSubCategoryChange}
                variant="outlined"
                className="text-input-field"
                size="small"
              />
              <p className="error">{errors.subCategoryName}</p>
            </div>
          </div>
          <div className="form-control">
            <div className="field-item">
              <ReactSelect
                options={options}
                name="approval_status"
                onChange={handleApprovalStatusChange}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder={approval_status}
              />
              <p className="error">{errors.approval_status}</p>
            </div>
          </div>
          <div className="form-control">
            <div className="field-item">
              <label htmlFor="">Icon</label>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ImagePicker
                  onFileLoad={onFileLoad}
                  imageUrl={subcategory_image}
                />
              </div>

              <p className="error">{errors.subcategory_image}</p>
            </div>
          </div>

          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={loading}
                type="submit"
              >
                {loading ? <Loader size={24} color="inherit" /> : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );

  return (
    <StyledWrapperContainer>
      <Container maxWidth={false}>
        <Card
          heading={
            !updateSubtagState ? 'Create Sub-Category' : 'Edit Sub-Category'
          }
          content={cardContent}
        />
        <MessageBar
          type={subCategoryCreateStatus}
          open={isStatusMessageVisible}
          onClose={handleMessageClose}
          message={subCategoryCreateMessage}
        />
      </Container>
    </StyledWrapperContainer>
  );
};

export default withRouter(CreateSubTag);
