import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import BugReport from './Img/BugReport.svg';
import FeaturedRequest from './Img/FeaturedRequest.svg';
import Feedback from './Img/Feedback.svg';
import './style.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ContactUs = () => {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const isAdmin =
    auth &&
    auth.loginSuccess &&
    user &&
    user.data &&
    user.data?.user_type === 'Admin';
  return (
    <div className="contactUs-container">
      <div className="reportBug-card">
        <Grid container>
          <Grid item xs={9}>
            <span className="heading-reportBug">Contact Us</span>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                margin: '1rem',
                padding: '0.2rem',
              }}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              {isAdmin && (
                <Link to="/l/reportedBugs">
                  <Button variant="outlined" color="primary">
                    Check Bugs
                  </Button>
                </Link>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item md={4} sm={12} xs={12}>
            <Link to={'/l/reportbug'}>
              <div className="contact__reportBug">
                <img src={BugReport} alt="" />
                <span className="contact__reportBug-title">Bug Report</span>
              </div>
            </Link>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Link to={'/l/reportfeature'}>
              <div className="contact__reportBug">
                <img src={FeaturedRequest} alt="" />
                <span className="contact__reportBug-title">
                  Features Request
                </span>
              </div>
            </Link>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Link to={'/l/reportfeedback'}>
              <div className="contact__reportBug">
                <img src={Feedback} alt="" />
                <span className="contact__reportBug-title">Feedback</span>
              </div>
            </Link>
          </Grid>
        </Grid>
        <div style={{ marginTop: '40px' }}>
          <span className="contact__reportBug-text1">
            Thank you for using our platform! If you have any questions or
            feedback, we would love to hear from you. Please use the forms above
            to contact us and we will get back to you as soon as possible.{' '}
            <br /> <br />
            Alternatively, you can reach us through the email:{' '}
            <a
              href="mailto:hello@abekus.com"
              style={{ textDecoration: 'underline' }}
            >
              hello@abekus.com
            </a>
          </span>
        </div>
        <div style={{ marginTop: '30px' }}>
          <span className="contact__reportBug-text2">
            We are committed to providing you with the best experience possible,
            and your input is important to us. <br />
            Thank you for your support!
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
