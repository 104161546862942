import {
  FormHelperText,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import * as React from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import RichTextEditor from '../RichTextEditor';
import CreateOptions from './CreateOptions';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ComprehensiveQuestions from './ComprehensiveQuestions';
import { StyledEditQuestion } from './StyledComponent';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginBottom: 10,
    backgroundColor: 'white',
    border: '1px solid rgb(169, 169, 169)',
    padding: '10px 10px',
    borderRadius: '10px',
    zIndex: '100',
    color: '#25507b',
    fontSize: 12,
  },
}));

export const AlternateAnswerSpace = ({
  value,
  onupdate,
  clearErrors,
  setError,
}) => {
  const [text, setText] = React.useState('');

  const add = () => {
    clearErrors?.('alternate_answer');
    if (text.length === 0) {
      setError?.('alternate_answer', {
        message: 'Alternate answer cannot be empty',
      });
      return;
    }
    if (value.includes(text)) {
      setError?.('alternate_answer', {
        message: 'This alternate answer already exist',
      });
      return;
    }
    onupdate([...value, text]);
    setText('');
  };

  const deleteOpt = (idx) => {
    let newValue = value.filter((_, ind) => ind !== idx);
    onupdate(newValue);
  };

  return (
    <div className="alternate_answer">
      <TextField
        name="answer_one_word"
        variant="outlined"
        size="small"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="input"
        type="text"
      />

      <IconButton onClick={() => add()} color="primary">
        <AddIcon sx={{ width: '35px', height: '35px' }} />
      </IconButton>
      <div className="options_container">
        {value?.length > 1
          ? value?.slice(1)?.map((a, idx) => (
              <div key={idx}>
                {a}
                <Icon
                  onClick={() => deleteOpt(idx + 1)}
                  sx={{ fontSize: 18, cursor: 'pointer' }}
                >
                  close
                </Icon>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

const QuestionBody = ({ question, user }) => {
  const methods = useFormContext();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors: resetErrors,
  } = methods;
  const {
    setError,
    formState: { errors: formerror },
    clearErrors,
  } = useForm();
  const [questionType, setQuestionType] = React.useState(
    question.createQuestionType,
  );
  const [sub_question, setSub_questions] = React.useState(null);

  React.useEffect(() => {
    setQuestionType(
      question?.data?.question_type || question.createQuestionType,
    );
    setSub_questions(question?.data?.sub_questions ?? []);
  }, [question]);

  let heading = 'Multiple Choice Question (MCQ)';
  switch (questionType) {
    case 'MultipleChoice': {
      heading = 'Multiple Choice Question (MCQ)';
      break;
    }
    case 'MultipleCorrect': {
      heading = 'Multiple Select Question (MSQ)';
      break;
    }
    case 'OneWord': {
      heading = 'One Word';
      break;
    }
    case 'MultiPart': {
      heading = 'Comprehensive';
      break;
    }
  }

  return (
    <StyledEditQuestion>
      <Typography variant="h5" color="#3572B0" style={{ marginBottom: 10 }}>
        {heading}
      </Typography>

      <div className="form-group question">
        <label htmlFor="">
          <Typography mb="5px" color="#000" fontWeight="500">
            {questionType === 'MultiPart' ? 'Description' : 'Question'}
          </Typography>
        </label>
        <Controller
          control={control}
          name="question_text"
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              onChange={onChange}
              value={value}
              placeholder="Type your question"
              metaData={{
                question_id: question.questionById?.id,
                tag_id: question.questionById?.tag_id,
                domain_id: user?.selected_domain,
              }}
            />
          )}
        />
      </div>

      {/* Single Text Answer */}
      {questionType === 'OneWord' && (
        <div className="form-group answer-text">
          <label htmlFor="">
            <Typography mb="5px" color="#000" fontWeight="500">
              Enter your one word answer
            </Typography>
          </label>
          <div>
            <Controller
              control={control}
              name="answer_one_word"
              defaultValue=""
              rules={{
                required: 'Please enter your answer',
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="answer_one_word"
                  value={value[0] ?? question?.data?.answer_one_word?.[0]}
                  onChange={(e) =>
                    onChange([e.target.value, ...value.slice(1)])
                  }
                  variant="outlined"
                  size="small"
                  className="text-input-field"
                  style={{ width: '100%' }}
                  type="text"
                />
              )}
            />
            <FormHelperText error>
              {errors.answer_one_word?.message}
            </FormHelperText>
          </div>
        </div>
      )}

      {questionType === 'OneWord' && (
        <div className="form-group answer-text">
          <div style={{ display: 'flex' }}>
            <label htmlFor="">
              <Typography mb="5px" color="#000" fontWeight="500">
                Alternate Correct Answers{' '}
                <Typography color="#3572B0" sx={{ display: 'inline-flex' }}>
                  <i>(Optional)</i>
                </Typography>
              </Typography>
            </label>
            &nbsp;
            <LightTooltip
              title="Other acceptable formats of the one-word answer.."
              placement="right"
            >
              <InfoIcon sx={{ width: '18px' }} />
            </LightTooltip>
          </div>
          <div>
            <Controller
              control={control}
              name="answer_one_word"
              defaultValue={[]}
              render={() => (
                <AlternateAnswerSpace
                  value={watch('answer_one_word')}
                  onupdate={(value) => {
                    setValue('answer_one_word', value);
                  }}
                  clearErrors={clearErrors}
                  setError={setError}
                />
              )}
            />
          </div>
          <FormHelperText error>
            {formerror.alternate_answer?.message}
          </FormHelperText>
        </div>
      )}

      {questionType === 'OneWord' && (
        <div className="form-group answer-text">
          <div style={{ display: 'flex' }}>
            <label htmlFor="">
              <Typography mb="5px" color="#000" fontWeight="500">
                How to answer?
              </Typography>
            </label>
            <LightTooltip
              title=" The format in which user should write the answer. Eg: Without
            space, Without units, etc."
              placement="right"
            >
              <InfoIcon sx={{ width: '18px' }} />
            </LightTooltip>
          </div>
          <div>
            <Controller
              control={control}
              name="how_to_answer"
              defaultValue={
                question?.data?.how_to_answer
                  ? question?.data?.how_to_answer
                  : ''
              }
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="how_to_answer"
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  size="small"
                  className="text-input-field"
                  style={{ width: '100%' }}
                  type="text"
                />
              )}
            />
          </div>
        </div>
      )}

      {/* Multiple Choice - Choices */}
      {['MultipleChoice', 'MultipleCorrect'].includes(questionType) && (
        <>
          <Controller
            control={control}
            name="options"
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <>
                <CreateOptions
                  correctOptions={watch('answer_option_no')}
                  setCorrectOptions={(correctOptions) => {
                    setValue('answer_option_no', correctOptions);
                  }}
                  questionType={questionType}
                  value={value}
                  setError={setError}
                  onChange={onChange}
                  setValue={setValue}
                  clearErrors={resetErrors}
                  metaData={{
                    question_id: question.questionById?.id,
                    tag_id: question.questionById?.tag_id,
                    domain_id: user?.selected_domain,
                  }}
                />
              </>
            )}
          />
        </>
      )}

      {questionType === 'MultiPart' && sub_question && (
        <div className="form-group question">
          <label htmlFor="">
            <Typography mb="5px" color="#000" fontWeight="500">
              Questions
            </Typography>
          </label>
          <Controller
            control={control}
            name="sub_questions"
            defaultValue={[
              {
                question_text: '',
                question_type: 'MultipleChoice',
                answer_option_no: [],
                answer_one_word: [''],
                difficulty_level: 1.0,
                tag_id: '',
                sub_tag_array: [],
                options: [],
                solution_text: '',
              },
            ]}
            render={({ field: { onChange, value } }) => (
              <ComprehensiveQuestions
                value={sub_question}
                defaultValue={value}
                onChange={onChange}
                metaData={{
                  question_id: question.questionById?.id,
                  tag_id: question.questionById?.tag_id,
                  domain_id: user?.selected_domain,
                }}
              />
            )}
          />
          <FormHelperText error>{errors.sub_questions?.message}</FormHelperText>
        </div>
      )}

      {questionType !== 'MultiPart' && (
        <div className="form-group question">
          <label htmlFor="">
            <Typography mb="5px" color="#000" fontWeight="500">
              Solution
            </Typography>
          </label>
          <Controller
            control={control}
            name="solution.solution_text"
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <RichTextEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter detailed solution of the answer..."
                  metaData={{
                    question_id: question.questionById?.id,
                    tag_id: question.questionById?.tag_id,
                  }}
                />
              );
            }}
          />
        </div>
      )}
      {questionType !== 'MultiPart' &&
        question.isFetchingQuestionById !== true && (
          <div className="form-group question">
            <label htmlFor="" style={{ display: 'flex', gap: 5 }}>
              <Typography mb="5px" color="#000" fontWeight="500">
                Answer Hint
                <span className="optional-hint">(Optional)</span>
              </Typography>
              <LightTooltip
                title="You will earn credits every time user uses your hint."
                placement="right"
              >
                <InfoIcon sx={{ color: '#767676', width: '18px' }} />
              </LightTooltip>
            </label>
            <Controller
              control={control}
              name="question_hint"
              defaultValue={question?.data?.question_hint}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    onChange={onChange}
                    value={value}
                    placeholder="(Note: The Hint should not reveal the answer & You can give only one hint to the question)"
                    metaData={{
                      question_id: question.questionById?.id,
                      tag_id: question.questionById?.tag_id,
                    }}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: '30px',
                      },
                    }}
                  />
                );
              }}
            />
            <FormHelperText error>
              {errors.question_hint?.message}
            </FormHelperText>
          </div>
        )}
    </StyledEditQuestion>
  );
};

export default QuestionBody;
