import React, { useEffect, useState } from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,
  Box,
  Chip,
  Modal,
  Typography,
  Icon,
  Switch,
  Tooltip,
  TextField,
  InputAdornment,
} from '@mui/material';
import Loader from '../Loader';
import CategoryCard from '../CategoryCard';
import UnlockDialog from '../Dialog/UnlockDialog';
import NoCreditDialog from '../Dialog/NoCreditDialog';
import BlankPage from '../BlankPage';
import SubcategorySelector from '../../containers/SubcategorySelector';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../Button';
import SearchIcon from '@mui/icons-material/Search';

import badgeItem from './images/badge.svg';
import { changeDomain } from '../../actions/domain';
import { getTag } from '../../actions/tag';
import SegmentedProgressBar from '../PlayProgress/SegmentedProgressBar';
import InfoIcon from '../../images/infoIcon.svg';
import { withRouter } from '../../routes';
import { useNavigate } from 'react-router-dom';
import {
  StyledCategory,
  StyledContainer,
  StyledModalContainer,
  StyledQuestionLayout,
} from './CategoryStyledComponent';

const styles = {
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modalMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '999999',
  },
  modalDetails: {
    color: '#25507B',
    fontWeight: 500,
    textAlign: 'left',
    width: '100%',
  },
  modalSubs: {
    color: '#76767F',
    width: '100%',
    fontWeight: 400,
    lineHeight: 1.3,
    textAlign: 'left',
  },
  modalTopics: {
    color: '#76767F',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  lastText: {
    color: '#25507B',
    fontWeight: 500,
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    marginBottom: '10px',
  },
  modalIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    marginLeft: 5,
  },
  modalStats1: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 400,
    lineHeight: 1.3,
    fontSize: '16px',
    width: 'fit-content',
    padding: '0px 5px',
    borderRadius: 2,
  },
  modalStats2: {
    color: '#545459',
    background: '#fff',
    fontWeight: 200,
    lineHeight: 1.3,
    width: 'fit-content',
    borderRadius: 2,
  },
  modalStats3: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 400,
    lineHeight: 1.3,
    width: 'fit-content',
    padding: '1px 2px',
    margin: '0px 1px',
    borderRadius: 2,
  },
  modalStats31: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 500,
    lineHeight: 1.3,
    width: 'fit-content',
    padding: '0px 5px',
    borderRadius: 2,
  },
};
export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.text.main,
    opacity: 0.5,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      opacity: 1,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.text.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const Category = (props) => {
  const storedShowCorrectAnswerOnSkip = localStorage.getItem(
    'showCorrectAnswerOnSkip',
  );
  const storedPurchaseTimeWithoutPopup = localStorage.getItem(
    'purchaseTimeWithoutPopup',
  );
  const storedAutoReveal = localStorage.getItem('autoReveal');
  const storedAutoUnlockSolution = localStorage.getItem('autoUnlockSolution');

  const [showModal, setShowModal] = useState(false);
  const [selectedCategoryForPlay, setSelectedCategoryForPlay] = useState(null);
  const [
    showSubCategorySelectionModal,
    setShowSubCategorySelectionModal,
  ] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [
    subCategorySelectorVisibility,
    setSubCategorySelectorVisibility,
  ] = useState(false);
  const [
    isCategoryUnlockDialogVisible,
    setIsCategoryUnlockDialogVisible,
  ] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [
    insufficiantCreditDialogVisible,
    setInsufficiantCreditDialogVisible,
  ] = useState(false);
  const [showCorrectAnswerOnSkip, setShowCorrectAnswerOnSkip] = useState(
    storedShowCorrectAnswerOnSkip
      ? storedShowCorrectAnswerOnSkip === 'true'
      : true,
  );
  const [purchaseTimeWithoutPopup, setPurchaseTimeWithoutPopup] = useState(
    storedPurchaseTimeWithoutPopup
      ? storedPurchaseTimeWithoutPopup === 'true'
      : false,
  );
  const [autoReveal, setAutoReveal] = useState(
    storedAutoReveal ? storedAutoReveal === 'true' : true,
  );
  const [autoUnlockSolution, setAutoUnlockSolution] = useState(
    storedAutoUnlockSolution ? storedAutoUnlockSolution === 'true' : false,
  );
  const [searchCategoryText, setSearchCategoryText] = useState(
    localStorage.getItem('ai_search_term') || '',
  );
  const navigate = useNavigate();
  useEffect(() => {
    const { location } = props;
    if (location?.state) {
      checkPublicQuestionRedirect();
    }

    props.getTag();

    if (props.isMobile) props.handleDrawerClose();
    else props.handleDrawerOpen();

    if (location.state && location.state.from === '/mockTest') {
      handleStartQuiz(location.state.mockTestcategory);
    }
    const dashboardData = localStorage.getItem('dashboardPractice');
    if (dashboardData && dashboardData != '{}') {
      const practiceDate = JSON.parse(
        localStorage.getItem('dashboardPractice'),
      );
      handleShowModal(practiceDate);
      localStorage.removeItem('dashboardPractice');
    }
    localStorage.removeItem('ai_search_term');
  }, []);

  const checkPublicQuestionRedirect = () => {
    const { location, user } = props;
    // Public question category unlock & redirect

    (async () => {
      if (location.state) {
        const publicQuestionId = location.state.selectedQuestion;
        const publicCategoryId = location.state.selectedCategory;
        const publicSubCategoryId = location.state.selectedSubCategory;
        const publicQuestionDomain = location.state.selectedQuestionDomain;

        if (
          !publicQuestionId ||
          !publicCategoryId ||
          !publicSubCategoryId ||
          !publicQuestionDomain
        )
          return;

        if (publicQuestionDomain != user.selected_domain) {
          await props.changeDomain({ domain_id: publicQuestionDomain });
          await props.getTag();
        }
        const { category } = props;
        let matchedCategory = category.data.find((item) => {
          return item.id === publicCategoryId;
        });

        if (matchedCategory) {
          if (matchedCategory.is_unlocked) {
            const categoryProgress = getProgress(matchedCategory.id);
            if (categoryProgress && categoryProgress.startsWith('0/')) {
              setSelectedCategory(matchedCategory);
              setSelectedSubcategory(publicSubCategoryId);
              handleOkClick(matchedCategory.id);
            } else {
              setTimeout(() => {
                navigate(`/l/answer/${matchedCategory.id}/play`, {
                  state: {
                    page: 'publicquestion',
                    publicQuestionId,
                    showCorrectAnswerOnSkip: showCorrectAnswerOnSkip,
                    purchaseTimeWithoutPopup: purchaseTimeWithoutPopup,
                    autoReveal: autoReveal,
                    autoUnlockSolution: autoUnlockSolution,
                  },
                });
              });
            }
          } else {
            if (
              user.credits - Math.abs(matchedCategory.category_unlock_credits) <
              0
            ) {
              setSelectedCategory(matchedCategory);
              setInsufficiantCreditDialogVisible(true);
            } else {
              setSelectedCategory(matchedCategory);
              setIsCategoryUnlockDialogVisible(true);
            }
          }
        }
      }
    })();
  };

  const convertNumber = (number) => {
    return ('0000' + number).slice(-4);
  };

  const handleOkClick = (categoryId) => {
    const { location, setPreferences } = props;

    const subCategories =
      selectedCategory &&
      selectedCategory?.sub_tags &&
      selectedCategory?.sub_tags.length &&
      selectedCategory?.sub_tags.map((subTags) => {
        return {
          sub_tag_id: subTags.id,
          is_selected: selectedSubcategory.includes(subTags.id),
        };
      });

    setPreferences(categoryId, subCategories);

    if (location.state && location.state.selectedQuestion) {
      const publicQuestionId = location.state.selectedQuestion;
      setTimeout(() => {
        navigate(`/l/answer/${categoryId}/play`, {
          state: {
            page: 'publicquestion',
            publicCategoryId: categoryId,
            publicQuestionId,
            showCorrectAnswerOnSkip: showCorrectAnswerOnSkip,
            purchaseTimeWithoutPopup: purchaseTimeWithoutPopup,
            autoReveal: autoReveal,
            autoUnlockSolution: autoUnlockSolution,
          },
        });
      });
    } else {
      navigate(`/l/answer/${categoryId}/play`, {
        state: {
          showCorrectAnswerOnSkip: showCorrectAnswerOnSkip,
          purchaseTimeWithoutPopup: purchaseTimeWithoutPopup,
          autoReveal: autoReveal,
          autoUnlockSolution: autoUnlockSolution,
        },
      });
    }
  };

  const handleShowModal = (category) => {
    setShowModal(true);
    setSelectedCategoryForPlay(category);
    props.getStats(category?.id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowSubCategorySelector = () => {
    setShowModal(false);
    setShowSubCategorySelectionModal(true);
  };

  const handleSubcategoryChangeDone = (selectedSubcategory, categoryId) => {
    handleClose();

    const { setPreferences } = props;

    const subCategories =
      selectedCategoryForPlay &&
      selectedCategoryForPlay?.sub_tags &&
      selectedCategoryForPlay?.sub_tags.length &&
      selectedCategoryForPlay?.sub_tags.map((subTags) => {
        return {
          sub_tag_id: subTags.id,
          is_selected: selectedSubcategory.includes(subTags.id),
        };
      });

    setPreferences(categoryId, subCategories);
  };

  const handlePlay = (category) => {
    const categoryProgress = getProgress(category.id);

    if (categoryProgress?.startsWith('0/')) {
      setSelectedCategory(category);
      setShowModal(false);
      setSubCategorySelectorVisibility(true);
    } else {
      navigate(`/l/answer/${category.id}/play`, {
        state: {
          showCorrectAnswerOnSkip: showCorrectAnswerOnSkip,
          purchaseTimeWithoutPopup: purchaseTimeWithoutPopup,
          autoReveal: autoReveal,
          autoUnlockSolution: autoUnlockSolution,
        },
      });
    }
  };

  const handleStartQuiz = (category) => {
    const { user } = props;

    if (category.is_unlocked) {
      handleShowModal(category);
    } else {
      if (user.credits - Math.abs(category.category_unlock_credits) < 0) {
        setSelectedCategory(category);
        setInsufficiantCreditDialogVisible(true);
      } else {
        setSelectedCategory(category);
        setIsCategoryUnlockDialogVisible(true);
      }
    }
  };

  const handleUnlockCancel = () => {
    setSelectedCategory(undefined);
    setIsCategoryUnlockDialogVisible(false);
  };

  const handleUnlockConfirm = () => {
    const { location } = props;
    setIsCategoryUnlockDialogVisible(false);
    props.unlockCategory(selectedCategory.id);
    if (location.state?.from?.includes('mockTest')) {
      const mocktestCategoryId = location.state.mockTestcategory.id;
      navigate(`/l/answer/${selectedCategory.id}/play`, {
        state: {
          page: 'mockTest',
          mocktestCategoryId,
        },
        replace: true,
      });
    } else if (location.state) {
      const publicQuestionId = location.state.selectedQuestion;
      navigate(`/l/answer/${selectedCategory.id}/play`, {
        state: {
          page: 'publicquestion',
          publicQuestionId,
        },
        replace: true,
      });
    }
  };

  const handleInsufficuentCreditDialogClose = () => {
    setSelectedCategory(undefined);
    setInsufficiantCreditDialogVisible(false);
  };

  const handleClose = () => {
    if (selectedCategoryForPlay) {
      setSubCategorySelectorVisibility(false);
      setShowSubCategorySelectionModal(false);
    } else setSubCategorySelectorVisibility(false);
    setShowSubCategorySelectionModal(false);
    setShowModal(true);
  };

  const handleDone = (selectedSubcategory, categoryId) => {
    setSelectedSubcategory(selectedSubcategory);
    handleOkClick(categoryId);
  };

  const getProgress = (categoryId) => {
    const { user } = props;
    const { analytics } = user;

    if (!analytics.tags) return null;

    const { tags } = analytics;

    for (let i = 0, l = tags.length; i < l; i += 1) {
      if (tags[i].tag_id === categoryId) {
        return `${tags[i].total_category_questions -
          tags[i].unanswered_category_questions}/${
          tags[i].total_category_questions
        }`;
      }
    }

    return '0/0';
  };

  const correctAnswerOnSkipStatus = (e) => {
    const switchValue = e.target.checked;
    setShowCorrectAnswerOnSkip(switchValue);
    localStorage.setItem('showCorrectAnswerOnSkip', switchValue);
  };
  const purchaseTimeWithoutPopupStatus = (e) => {
    const switchValue = e.target.checked;
    setPurchaseTimeWithoutPopup(switchValue);
    localStorage.setItem('purchaseTimeWithoutPopup', switchValue);
  };
  const changeAutoRevealStatus = (e) => {
    const switchValue = e.target.checked;
    setAutoReveal(switchValue);
    localStorage.setItem('autoReveal', switchValue);
  };
  const changeUnlockSolStatus = (e) => {
    const switchValue = e.target.checked;
    setAutoUnlockSolution(switchValue);
    localStorage.setItem('autoUnlockSolution', switchValue);
  };

  const { category, user } = props;

  if (!category.data) {
    return <Loader size={44} color="primary" />;
  }
  const categoriesList = category.data
    .filter((item) =>
      item.name.toLowerCase().includes(searchCategoryText.toLowerCase()),
    )
    .map((item) => {
      return item.total_category_questions > 40 ? (
        <CategoryCard
          {...props}
          key={item.id}
          item={item}
          category={category}
          component="category"
          hasAction={true}
          handleStartQuiz={handleStartQuiz}
        />
      ) : null;
    })
    .filter((record) => !!record);

  return (
    <StyledContainer
      maxWidth={false}
      style={showModal ? { paddingTop: 0 } : {}}
    >
      <StyledCategory>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          width="100%"
          mb="15px"
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <header className="header">Choose Category</header>
          <TextField
            value={searchCategoryText}
            placeholder="Search"
            onChange={(e) => setSearchCategoryText(e.target.value)}
            className="search-text-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {categoriesList.length ? (
          !showModal ? (
            <>
              <div className="list">{categoriesList}</div>
            </>
          ) : category.isFetchingStats ? (
            <Loader size={44} color="primary" />
          ) : (
            <>
              <StyledQuestionLayout>
                <div className="left">
                  <div className="header">
                    <div className="d1">
                      <div className="s1"></div>
                      <div className="s2"></div>
                    </div>
                    <div className="d2">
                      <div className="s3"></div>
                    </div>
                  </div>
                  <div className="subHeader"></div>
                  <div className="main">
                    <div className="d1"></div>
                    <div className="d2"></div>
                    <div className="d3"></div>
                    <div className="d4"></div>
                    <div className="d4"></div>
                    <div className="d4"></div>
                    <div className="d4"></div>
                    <div className="d5">
                      <div className="s1"></div>
                      <div className="s2"></div>
                    </div>
                    <div className="d6">
                      <div className="s1"></div>
                      <div className="s2"></div>
                      <div className="s3"></div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="diff"></div>
                  <span></span>
                </div>
                <div className="headerM">
                  <div className="d1">
                    <div className="s1"></div>
                    <div className="s2"></div>
                  </div>
                  <div className="d1">
                    <div className="s3" />
                    <div className="s4" />
                  </div>
                </div>
                <div className="subHeaderM">
                  <div className="s1"></div>
                  <div className="s2"></div>
                </div>
                <div className="mainM">
                  <div className="d1">
                    <div className="s1" />
                    <div className="s2" />
                  </div>
                  <div className="d2">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                  <div className="d3">
                    <div className="s1">
                      <div />
                      <div />
                    </div>
                    <div className="s2" />
                  </div>
                  <div className="d4">
                    <div className="s1" />
                    <div className="s2" />
                  </div>
                </div>
              </StyledQuestionLayout>
              <Modal
                open={showModal}
                onClose={handleCloseModal}
                style={styles.modalMain}
              >
                <StyledModalContainer style={{ position: 'relative' }}>
                  <Box className="header">
                    <img
                      src={category.stats?.s3_url}
                      className="image"
                      alt={category.stats?.tag_name}
                    />
                    <Box className="details">
                      <Typography variant="h5" style={styles.modalDetails}>
                        {category.stats?.domain_name}
                      </Typography>
                      <Typography variant="subtitle1" style={styles.modalSubs}>
                        {category.stats?.tag_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="topicsList">
                    <Typography variant="Subtitle2" style={styles.modalTopics}>
                      Selected Topics
                      <a
                        onClick={handleShowSubCategorySelector}
                        style={styles.modalIcons}
                      >
                        <Icon style={{ fontSize: 15 }}>edit</Icon>
                      </a>
                    </Typography>
                    <Box className="listCtn">
                      {category.stats?.preferences
                        ?.sort((a, b) => {
                          return a.name.length - b.name.length;
                        })
                        ?.map(
                          (tag) =>
                            tag.is_selected && (
                              <Chip
                                key={tag.id}
                                label={tag.name}
                                size="small"
                                className="tag"
                              />
                            ),
                        )}
                    </Box>
                    <Box className="statsCtn">
                      <Box className="questions">
                        <Box className="avatar">
                          <Typography
                            variant="subtitle2"
                            align="center"
                            style={styles.modalStats1}
                          >
                            {
                              category.stats?.progress_obj
                                ?.current_tag_total_attempts
                            }
                          </Typography>
                          <Typography
                            variant="caption"
                            align="center"
                            style={styles.modalStats2}
                          >
                            /{''}
                            {
                              category.stats?.progress_obj
                                ?.current_tag_total_questions
                            }
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          align="center"
                          style={{
                            color: '#25507B',
                            fontWeight: 500,
                            lineHeight: 0.9,
                          }}
                        >
                          Number of Questions
                        </Typography>
                      </Box>
                      <Box className="score">
                        <Box className="avatar">
                          {convertNumber(
                            Math.round(
                              category?.stats.progress_obj?.current_tag_score ||
                                0,
                            ),
                          )
                            .split('')
                            .map((s, index) => (
                              <Typography
                                key={index}
                                variant="subtitle2"
                                align="center"
                                style={styles.modalStats3}
                              >
                                {s}
                              </Typography>
                            ))}
                        </Box>
                        <Typography
                          variant="caption"
                          align="center"
                          style={{
                            color: '#25507B',
                            fontWeight: 500,
                            lineHeight: 0.9,
                          }}
                        >
                          Category Score
                        </Typography>
                      </Box>
                      <Box className="badges">
                        <Box
                          className="avatar"
                          style={{ backgroundImage: `url(${badgeItem})` }}
                        >
                          <Typography
                            variant="subtitle2"
                            align="center"
                            style={styles.modalStats31}
                          >
                            {category.stats?.progress_obj?.total_badges}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          align="center"
                          style={{
                            color: '#25507B',
                            fontWeight: 500,
                            lineHeight: 0.9,
                          }}
                        >
                          Total
                          <br /> Badges
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="otherDetails">
                      <div className="streak-spree">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{
                            color: '#25507B',
                            fontWeight: 500,
                            fontSize: '12px',
                          }}
                        >
                          Streak&nbsp;
                        </Typography>
                        <SegmentedProgressBar
                          strokeColor="#257B66"
                          trailColor="#E9ECEF"
                          value={
                            (category.stats?.progress_obj
                              ?.current_streak_count *
                              100) /
                            category.stats?.progress_obj?.current_streak_target
                          }
                          attempt={
                            category.stats?.progress_obj?.current_streak_count
                          }
                          total={
                            category.stats?.progress_obj?.current_streak_target
                          }
                          width="140px"
                          isStreak={true}
                        />
                      </div>
                      <div className="streak-spree">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{
                            color: '#25507B',
                            fontWeight: 500,
                            fontSize: '12px',
                          }}
                        >
                          Spree&nbsp;
                        </Typography>
                        <SegmentedProgressBar
                          strokeColor="#65257B"
                          trailColor="#E9ECEF"
                          value={
                            (category.stats?.progress_obj
                              ?.current_day_streak_count *
                              100) /
                            category.stats?.progress_obj
                              ?.current_day_streak_target
                          }
                          attempt={
                            category.stats?.progress_obj
                              ?.current_day_streak_count
                          }
                          total={
                            category.stats?.progress_obj
                              ?.current_day_streak_target
                          }
                          width="140px"
                        />
                      </div>
                    </Box>
                    <Box className="topicsList">
                      <Typography variant="Subtitle2" style={styles.lastText}>
                        <Tooltip title="To reveal answer immediately after incorrect answer or timeout.">
                          <img src={InfoIcon} width="12px" />
                        </Tooltip>
                        &nbsp;&nbsp;Auto Reveal Answer &nbsp; &nbsp;&nbsp;
                        <div style={{ position: 'absolute', right: '20px' }}>
                          <AntSwitch
                            name="checkedC"
                            checked={autoReveal}
                            onChange={changeAutoRevealStatus}
                          />
                        </div>
                      </Typography>
                      <Typography variant="Subtitle2" style={styles.lastText}>
                        <Tooltip title="To unlock solution with automatic credits deduction, without a prompt.">
                          <img src={InfoIcon} width="12px" />
                        </Tooltip>
                        &nbsp;&nbsp;Unlock Solution Without Pop-Up &nbsp;
                        &nbsp;&nbsp;
                        <div style={{ position: 'absolute', right: '20px' }}>
                          <AntSwitch
                            name="checkedC"
                            checked={autoUnlockSolution}
                            onChange={changeUnlockSolStatus}
                          />
                        </div>
                      </Typography>
                      <Typography variant="Subtitle2" style={styles.lastText}>
                        <Tooltip title="To show the answer on skip question.">
                          <img src={InfoIcon} width="12px" />
                        </Tooltip>
                        &nbsp;&nbsp;Show Correct Answer on Skip &nbsp;
                        &nbsp;&nbsp;
                        <div style={{ position: 'absolute', right: '20px' }}>
                          <AntSwitch
                            name="checkedC"
                            checked={showCorrectAnswerOnSkip}
                            onChange={correctAnswerOnSkipStatus}
                          />
                        </div>
                      </Typography>
                      <Typography variant="Subtitle2" style={styles.lastText}>
                        <Tooltip title="To add the time with automatic credits deduction, without a prompt.">
                          <img src={InfoIcon} width="12px" />
                        </Tooltip>
                        &nbsp;&nbsp;Purchase Time Without Popup &nbsp;
                        &nbsp;&nbsp;
                        <div style={{ position: 'absolute', right: '20px' }}>
                          <AntSwitch
                            name="checkedC"
                            checked={purchaseTimeWithoutPopup}
                            onChange={purchaseTimeWithoutPopupStatus}
                          />
                        </div>
                      </Typography>
                    </Box>
                    <Box className="triggerCtn" style={{ marginTop: '15px' }}>
                      <Typography variant="caption">
                        Unlocked untill{' '}
                        {`${category.stats?.unlocked_until?.split(' ')[1]} ${
                          category.stats?.unlocked_until?.split(' ')[2]
                        } ${category.stats?.unlocked_until?.split(' ')[4]}`}
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handlePlay(selectedCategoryForPlay)}
                        style={{
                          backgroundColor: '#25507B',
                          color: '#fff',
                          width: '130px',
                        }}
                      >
                        <Typography variant="caption">Start</Typography>
                      </Button>
                    </Box>
                  </Box>
                </StyledModalContainer>
              </Modal>
            </>
          )
        ) : (
          <BlankPage
            text="There are currently no categories in this domain, but you can be the first one to contribute by creating questions."
            icon={<DomainIcon fontSize="large" className="blank-icon" />}
          />
        )}
      </StyledCategory>
      {subCategorySelectorVisibility ? (
        <SubcategorySelector
          visible={subCategorySelectorVisibility}
          selectedCategory={selectedCategory}
          handleDone={handleDone}
          onClose={handleClose}
          setShowModal={setShowModal}
        />
      ) : null}
      {showSubCategorySelectionModal ? (
        <SubcategorySelector
          visible={showSubCategorySelectionModal}
          selectedCategory={selectedCategoryForPlay}
          handleDone={handleSubcategoryChangeDone}
          onClose={handleClose}
          setShowModal={setShowModal}
        />
      ) : null}
      <UnlockDialog
        visible={isCategoryUnlockDialogVisible}
        category={selectedCategory}
        dialogTitleMessage={'Do you want to unlock this category?'}
        onConfirm={handleUnlockConfirm}
        onClose={handleUnlockCancel}
      />
      <NoCreditDialog
        visible={insufficiantCreditDialogVisible}
        category={selectedCategory}
        onClose={handleInsufficuentCreditDialogClose}
        credits={user.credits || 0}
      />
    </StyledContainer>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeDomain, getTag }, dispatch);
}

export default withStyles(styles)(
  connect(() => ({}), mapDispatchToProps)(withRouter(Category)),
);
