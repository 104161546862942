import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const tag = createReducer(
  {
    list: [],
    isFetchingTags: false,
    category_by_domain: {},
    allTags: [],
  },
  {
    [types.POST_TAG_START](state) {
      const newState = Object.assign({}, state, {
        postTagSuccess: false,
        postTagFail: false,
        postSubtagSuccess: false,
        postSubtagFail: false,
      });

      return newState;
    },
    [types.POST_TAG_SUCCESS](state) {
      return { ...state, postTagSuccess: true };
    },
    [types.POST_TAG_FAIL](state, action) {
      return { ...state, postTagFail: true, errorStatus: action.data };
    },
    [types.POST_SUBTAG_START](state) {
      const newState = Object.assign({}, state, {
        postSubtagSuccess: false,
        postSubtagFail: false,
      });

      return newState;
    },
    [types.POST_SUBTAG_SUCCESS](state) {
      return { ...state, postSubtagSuccess: true };
    },
    [types.POST_SUBTAG_FAIL](state, action) {
      return { ...state, postSubtagFail: true, subtagErrorStatus: action.data };
    },
    [types.GET_TAG_START](state) {
      return {
        ...state,
        isFetchingTags: true,
        postTagSuccess: false,
        postTagFail: false,
        postSubtagSuccess: false,
        postSubtagFail: false,
      };
    },
    [types.GET_TAG_SUCCESS](state, action) {
      let category_by_domain = {};
      for (var i = 0, l = action.data.category_data.length; i < l; i++) {
        let category = action.data.category_data[i];
        for (var j = 0, k = category.domain_ids.length; j < k; j++) {
          let domain = category.domain_ids[j];
          if (!category_by_domain[domain]) {
            category_by_domain[domain] = [];
          }
          category_by_domain[domain].push(category);
        }
      }

      const firstDomain = Object.keys(category_by_domain)?.[0];

      const newState = Object.assign({}, state, {
        isTagCreated: true,
        list: category_by_domain[action.data.selected_domain || firstDomain],
        allTags: action.data?.category_data,
        category_by_domain: category_by_domain,
        isFetchingTags: false,
      });

      return newState;
    },
    [types.GET_TAG_FAIL](state) {
      const newState = Object.assign({}, state, {
        isTagCreated: false,
        isFetchingTags: false,
      });

      return newState;
    },
    [types.GET_SCORE_BY_TAG_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isScoreByTag: true,
        list: action.data,
      });

      return newState;
    },
    [types.GET_SCORE_BY_TAG_FAIL](state) {
      const newState = Object.assign({}, state, {
        isScoreByTag: false,
      });

      return newState;
    },
  },
);

export { tag as default };
