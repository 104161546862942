import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Icon,
  Divider,
  Hidden,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageCarousel from './imageCarousel';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAlreadyVotedQuestion,
  getNewVotingQuestion,
  getReportedQuestions,
  postVotingQuestionResponse,
} from '../../actions/voting';
import NoMoreQuesLeftIcon from './images/no-ques-left-image.png';
import NoReportedQues from './images/NoReportedQues.svg';
import NoHistoryData from './images/NoHistoryData.svg';
import NoVotingData from './images/NoVotingData.svg';
import { useNavigate } from 'react-router-dom';
import CreditsIcon from '../Sidebar/img/credits-icon.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  VotingBtn,
  PageNavBtn,
  NoMoreQuesContainer,
  PageNavContainer,
  RemainingVotesContainer,
  VotingBtnContainer,
  NextBtn,
  MobileActionBtn,
  HistoryNavigationBtn,
} from './styles';
import QuestionContainer from './QuestionContainer';
import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
import RecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';

const Voting = () => {
  const [page, setPage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { votingResponse, error, history, question } = useSelector(
    (state) => state.voting,
  );
  const {
    costData: { QuestionImprove: QuestionImproveCredits },
  } = useSelector((state) => state.credits);
  const [historyPage, setHistoryPage] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [historyInd, setHistoryInd] = useState(0);
  const [data, setData] = useState({});

  const getNewQuestion = () => {
    dispatch(getNewVotingQuestion());
    setIsSubmitted(false);
  };

  const getVotedQuestion = (historyPage) => {
    dispatch(getAlreadyVotedQuestion(historyPage));
  };

  const handleResponseClick = (reportId, reviewType) => {
    dispatch(postVotingQuestionResponse(reportId, reviewType));
    setIsSubmitted(true);
  };

  useEffect(() => {
    const page_no = parseInt(localStorage.getItem('voting_page'));
    setPage(page_no || 0);

    if (page === 0) getVotedQuestion(1);
  }, []);

  useEffect(() => {
    if (page === 1) getNewQuestion();
    else if (page === 2) getVotedQuestion(historyPage);
    else if (page === 3) dispatch(getReportedQuestions(1));

    localStorage.setItem('voting_page', page);
  }, [page, historyPage]);

  useEffect(() => {
    if (history?.review_history_list?.length > 0 && page === 0) setPage(1);
    if (page === 1)
      setData({
        ...question.data,
        remaining_reviews: question.remaining_reviews,
      });
    else if (page === 2 || page === 3) {
      setData({
        ...history.review_history_list[historyInd],
        creator_details:
          history.review_history_list[historyInd]?.reports?.[0]
            ?.creator_details,
        reporter_details:
          history.review_history_list[historyInd]?.reports?.[0]
            ?.reporter_details,
        voted_correct: data.reporting_resolved ? true : undefined,
        pct_agreed:
          history?.review_history_list?.[historyInd]?.reports?.[0]?.pct_agreed,
        full_report: history.review_history_list[historyInd]?.reports?.[0],
        review_type:
          history.review_history_list[historyInd]?.reports?.[0]?.reviews?.[0]
            ?.review_type,
      });
    }
  }, [question, historyInd, history]);

  const getActionBtnBgColor = (type) => {
    return page === 1
      ? isSubmitted
        ? votingResponse?.review_type === type
          ? type === 'Agree'
            ? '#9370DB1A'
            : '#FFA5001A'
          : '#7B7B7B1A'
        : 'white'
      : page === 2 || page === 3
      ? !data.reporting_resolved
        ? data.review_type === type
          ? type === 'Agree'
            ? '#9370DB1A'
            : '#FFA5001A'
          : '#7B7B7B1A'
        : data.review_type === type
        ? (type === 'Agree' && data.pct_agreed > 50) ||
          (type === 'Disagree' && data.pct_agreed < 50)
          ? '#388E3C1A'
          : '#D605051A'
        : '#7B7B7B1A'
      : 'white';
  };

  const getActionBtnHoverBgColor = (type) => {
    return page === 1
      ? isSubmitted
        ? votingResponse?.review_type === type
          ? type === 'Agree'
            ? '#9370DB1A'
            : '#FFA5001A'
          : '#7B7B7B1A'
        : type === 'Agree'
        ? '#9370DB1A'
        : '#FFA5001A'
      : page === 2 || page === 3
      ? !data.reporting_resolved
        ? data.review_type === type
          ? type === 'Agree'
            ? '#9370DB1A'
            : '#FFA5001A'
          : '#7B7B7B1A'
        : data.review_type === type
        ? (type === 'Agree' && data.pct_agreed > 50) ||
          (type === 'Disagree' && data.pct_agreed < 50)
          ? '#388E3C1A'
          : '#D605051A'
        : '#7B7B7B1A'
      : 'white';
  };

  const getActionBtnColor = (type) => {
    return page === 1
      ? isSubmitted
        ? votingResponse?.review_type === type
          ? type === 'Agree'
            ? '#9370DB'
            : '#FFA500'
          : '#7B7B7B'
        : type === 'Agree'
        ? '#9370DB'
        : '#FFA500'
      : page === 2 || page === 3
      ? !data.reporting_resolved
        ? data.review_type === type
          ? type === 'Agree'
            ? '#9370DB'
            : '#FFA500'
          : '#7B7B7B'
        : data.review_type === type
        ? (type === 'Agree' && data.pct_agreed > 50) ||
          (type === 'Disagree' && data.pct_agreed < 50)
          ? '#388E3C'
          : '#D60505'
        : '#7B7B7B'
      : '#9370DB';
  };
  return (
    <>
      <Hidden smUp>
        <div style={{ margin: '0', display: 'flex', flex: 'end' }}>
          <Button
            color="secondary"
            className="back-button"
            startIcon={<ChevronLeftIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </Hidden>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        {page !== 0 ? (
          <Grid
            mb="10px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              alignItems: 'center',
              boxShadow: 'box-shadow: 0px 0px 4px 0px #00000040',
              width: '100%',
              padding: '4px',
            }}
          >
            {(page === 2 || page === 3) && (
              <Grid
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  position: 'absolute',
                  left: '20px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontWeight={'600'}
                  fontSize="16px"
                  color={'black'}
                  mr="5px"
                >
                  Voting Status:
                </Typography>

                <Icon
                  sx={{
                    color: !data.reporting_resolved ? '#F44336' : '#388E3C',
                    width: '14px',
                    mr: '3px',
                  }}
                >
                  {!data.reporting_resolved ? (
                    <RecordIcon sx={{ width: '14px' }} />
                  ) : (
                    <CheckCircleIcon sx={{ width: '14px' }} />
                  )}
                </Icon>
                <Typography
                  fontWeight={'600'}
                  fontSize="16px"
                  sx={{
                    color: !data.reporting_resolved ? '#F44336' : '#388E3C',
                  }}
                >
                  {!data.reporting_resolved ? 'LIVE' : 'FINISHED'}
                </Typography>
              </Grid>
            )}
            <PageNavContainer>
              <PageNavBtn selected={page === 1} onClick={() => setPage(1)}>
                Vote
              </PageNavBtn>
              <PageNavBtn selected={page === 2} onClick={() => setPage(2)}>
                History
              </PageNavBtn>
              <PageNavBtn selected={page === 3} onClick={() => setPage(3)}>
                Reported
              </PageNavBtn>
            </PageNavContainer>
            {page === 1 && (
              <RemainingVotesContainer
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                >
                  Votes Remaining
                </Typography>
                <Typography
                  sx={{
                    padding: '5px',
                    fontWeight: '500',
                    fontSize: '16px',
                    background: '#3572B01A',
                    borderRadius: '3px',
                  }}
                  color="primary"
                >
                  {data.remaining_reviews ?? 0}
                </Typography>
              </RemainingVotesContainer>
            )}
          </Grid>
        ) : null}
        {page !== 0 && error?.error === 'no_more_questions' ? (
          <NoMoreQuesContainer>
            <Box
              component={'img'}
              src={NoMoreQuesLeftIcon}
              mb="10px"
              width={{ sm: '60%', xs: '100%' }}
            />
            <Typography
              fontWeight={'600'}
              fontSize={'28px'}
              color="primary"
              lineHeight={'20px'}
            >
              {"Looks Like You've Voted on All Questions!"}
            </Typography>
            <Typography color="black" fontSize={'18px'}>
              Keep practicing and unlock more questions to vote. The more you
              attempt question the more you get to vote. Your opinions shape the
              future!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('practise')}
            >
              Go to Practice
            </Button>
          </NoMoreQuesContainer>
        ) : null}

        {page === 0 && !history?.review_history_list?.length ? (
          <ImageCarousel setPage={setPage} />
        ) : !error?.error ? (
          (page === 2 || page == 3) &&
          history?.review_history_list?.length === 0 ? (
            <NoMoreQuesContainer>
              <Box
                component={'img'}
                src={
                  page === 1
                    ? NoVotingData
                    : page === 2
                    ? NoHistoryData
                    : NoReportedQues
                }
                mb="10px"
                width={{ sm: '60%', xs: '100%' }}
              />
              <Typography
                fontWeight={'600'}
                fontSize={'28px'}
                color="primary"
                lineHeight={'20px'}
              >
                {page === 1
                  ? 'No Questions Available at the Moment!'
                  : page == 2
                  ? 'No Voted Questions Found'
                  : 'Enjoying Abekus? Your Experience Matters!'}
              </Typography>
              <Typography color="black" fontSize={'18px'}>
                {page === 1
                  ? 'It appears you haven&apos;t come across any reported questions yet. Keep practicing and help make Abekus a hassle-free platform for all!'
                  : page == 2
                  ? 'You Haven&apso;t Voted on Any Questions Yet or There Are No Reported Questions Available'
                  : '  Your Feedback Counts! If You Haven&apos;t Reported Any Questions Yet, Help Us Maintain a Great Experience by Reporting Any Issues You Find.'}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('practise')}
              >
                Go to Practice
              </Button>
            </NoMoreQuesContainer>
          ) : Object.keys(data).length > 0 ? (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                mb: { xs: '80px', md: '0px' },
              }}
              p={{ xs: '0px 10px', md: '0px 20px' }}
              width={'100%'}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
                width={'100%'}
              >
                <QuestionContainer data={data} />

                {/* Mobile Action Buttons */}
                <Grid
                  sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                    position: 'fixed',
                    bottom: '0px',
                    left: '0px',
                    height: '50px',
                    background: 'white',
                    width: '100%',
                    alignItems: 'center',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <MobileActionBtn
                    onClick={() =>
                      handleResponseClick(data?.full_report?.id, 'Agree')
                    }
                  >
                    {(isSubmitted || page === 2 || page === 3) &&
                    (data.pct_agreed || votingResponse?.pct_agreed) ? (
                      <Typography
                        fontSize={'25px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        sx={{
                          transform: 'translateY(7px)',
                          color: getActionBtnColor('Agree'),
                        }}
                      >
                        {`${Math.round(
                          data.pct_agreed || votingResponse?.pct_agreed,
                        )}%`}
                      </Typography>
                    ) : null}
                    <Typography
                      fontSize={'22px'}
                      fontWeight="500"
                      sx={{
                        color: getActionBtnColor('Agree'),
                        transition: 'all 300ms',
                        transform:
                          isSubmitted || page === 2 || page === 3
                            ? 'scale(0.5) '
                            : '',
                      }}
                    >
                      AGREE
                    </Typography>
                  </MobileActionBtn>
                  <Divider orientation="vertical" />
                  <MobileActionBtn
                    onClick={() =>
                      handleResponseClick(data?.full_report?.id, 'Disagree')
                    }
                  >
                    {(isSubmitted || page === 2 || page === 3) &&
                    (data.pct_agreed || votingResponse?.pct_agreed) ? (
                      <Typography
                        fontSize={'25px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        sx={{
                          transform: 'translateY(7px)',
                          color: getActionBtnColor('Disagree'),
                        }}
                      >
                        {`${Math.round(
                          100 - (data.pct_agreed || votingResponse?.pct_agreed),
                        )}%`}
                      </Typography>
                    ) : null}
                    <Typography
                      fontSize={'22px'}
                      fontWeight="500"
                      sx={{
                        color: getActionBtnColor('Disagree'),
                        transition: 'all 300ms',
                        transform:
                          isSubmitted || page === 2 || page === 3
                            ? 'scale(0.5) '
                            : '',
                      }}
                    >
                      DISAGREE
                    </Typography>
                  </MobileActionBtn>
                  <Divider orientation="vertical" />
                  {page === 1 && (
                    <MobileActionBtn onClick={() => getNewQuestion()}>
                      {isSubmitted ? 'NEXT' : 'SKIP'}
                    </MobileActionBtn>
                  )}
                  {(page === 2 || page === 3) && (
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        gap: '10px',
                        margin: 'auto',
                      }}
                    >
                      <HistoryNavigationBtn
                        onClick={() => {
                          if (
                            historyInd ===
                            history.review_history_list?.length - 1
                          ) {
                            if (history.current_page < history.total_pages) {
                              setHistoryPage((curr) => curr + 1);
                              setHistoryInd(0);
                            }
                          } else {
                            setHistoryInd((ind) => ind + 1);
                          }
                        }}
                        disabled={
                          historyInd ===
                            history.review_history_list?.length - 1 &&
                          history.current_page === history.total_pages
                        }
                      >
                        <RightIcon
                          sx={{
                            color: '#25507B',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        />
                      </HistoryNavigationBtn>
                      <HistoryNavigationBtn
                        disabled={
                          historyInd === 0 && history.current_page === 1
                        }
                        onClick={() => {
                          if (historyInd === 0) {
                            if (history.current_page > 1) {
                              setHistoryPage((curr) => curr - 1);
                              setHistoryInd(0);
                            }
                          } else {
                            setHistoryInd((ind) => ind - 1);
                          }
                        }}
                      >
                        <LeftIcon
                          sx={{
                            color: '#25507B',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        />
                      </HistoryNavigationBtn>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                sx={{
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid lightgrey',
                  zIndex: { sm: 0, xs: -1 },
                  width: '100%',
                  background: { sm: 'white', xs: 'transparent' },
                  margin: 'auto',
                }}
              >
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                  mb="5px"
                >
                  <Typography
                    sx={{
                      color:
                        page === 1
                          ? '#D60505'
                          : !data.reporting_resolved
                          ? '#25507B'
                          : data.voted_correct
                          ? '#388E3C'
                          : '#D60505',
                    }}
                  >
                    <i>
                      {page === 1
                        ? "Note: Once you've voted, It can't be changed."
                        : !data.reporting_resolved
                        ? page === 2
                          ? 'Thanks for voting! Keep your eye on the result.'
                          : 'Voting on your report is live. Please check back later for results.'
                        : data.voted_correct
                        ? `Congratulations! Correct Vote. You Won ${data.credits_earned} Credits.`
                        : 'Oops! Your Vote was incorrect. Vote correctly to win credits.'}
                    </i>
                  </Typography>
                  {!data.reporting_resolved && (
                    <Typography
                      color="black"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      Earn
                      <img src={CreditsIcon} alt="icon" width="15px" />
                      <Typography color={'primary'} fontSize={'500'}>
                        {QuestionImproveCredits} credits
                      </Typography>
                      for every correct vote.
                    </Typography>
                  )}
                </Grid>
                <Grid
                  sx={{
                    display: { md: 'flex', sm: 'none', xs: 'none' },
                    gap: '20px',
                    alignItems: 'center',
                  }}
                >
                  <VotingBtnContainer>
                    <VotingBtn
                      sx={{
                        backgroundColor: getActionBtnBgColor('Agree'),

                        '&:hover': {
                          background: getActionBtnHoverBgColor('Agree'),
                        },
                      }}
                      onClick={() => {
                        if (!isSubmitted && page === 1)
                          handleResponseClick(data?.full_report?.id, 'Agree');
                      }}
                    >
                      {(isSubmitted || page === 2 || page === 3) &&
                      (data.pct_agreed || votingResponse?.pct_agreed) ? (
                        <Typography
                          fontSize={'25px'}
                          fontWeight={'500'}
                          lineHeight={'20px'}
                          sx={{
                            transform: 'translateY(7px)',
                            color: getActionBtnColor('Agree'),
                          }}
                        >
                          {`${Math.round(
                            data.pct_agreed || votingResponse?.pct_agreed,
                          )}%`}
                        </Typography>
                      ) : null}
                      <Typography
                        fontSize={'22px'}
                        fontWeight="500"
                        sx={{
                          color: getActionBtnColor('Agree'),
                          transition: 'all 300ms',
                          transform:
                            isSubmitted || page === 2 || page === 3
                              ? 'scale(0.5) '
                              : '',
                        }}
                      >
                        AGREE
                      </Typography>
                    </VotingBtn>
                    <VotingBtn
                      sx={{
                        backgroundColor: getActionBtnBgColor('Disagree'),
                        '&:hover': {
                          background: getActionBtnHoverBgColor('Disagree'),
                        },
                      }}
                      onClick={() => {
                        if (!isSubmitted && page === 1)
                          handleResponseClick(
                            data?.full_report?.id,
                            'Disagree',
                          );
                      }}
                    >
                      {(isSubmitted || page === 2 || page === 3) &&
                      (data.pct_agreed || votingResponse?.pct_agreed) ? (
                        <Typography
                          fontSize={'25px'}
                          fontWeight={'500'}
                          lineHeight={'20px'}
                          sx={{
                            transform: 'translateY(7px)',
                            color: getActionBtnColor('Disagree'),
                          }}
                        >
                          {`${Math.round(
                            100 -
                              (data.pct_agreed || votingResponse?.pct_agreed),
                          )}%`}
                        </Typography>
                      ) : null}
                      <Typography
                        fontSize={'22px'}
                        fontWeight="500"
                        sx={{
                          color: getActionBtnColor('Disagree'),
                          transition: 'all 300ms',
                          transform:
                            isSubmitted || page === 2 || page === 3
                              ? 'scale(0.5) '
                              : '',
                        }}
                      >
                        DISAGREE
                      </Typography>
                    </VotingBtn>
                  </VotingBtnContainer>
                  {page === 1 && (
                    <NextBtn
                      isSubmitted={isSubmitted}
                      onClick={() => getNewQuestion()}
                    >
                      <Typography
                        sx={{
                          transform: 'rotate(-90deg)',
                          fontWeight: '500',
                          fontSize: '18px',
                        }}
                        color="primary"
                      >
                        {isSubmitted ? 'NEXT' : 'SKIP'}
                      </Typography>
                    </NextBtn>
                  )}
                  {(page === 2 || page === 3) && (
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <HistoryNavigationBtn
                        onClick={() => {
                          if (
                            historyInd ===
                            history.review_history_list?.length - 1
                          ) {
                            if (history.current_page < history.total_pages) {
                              setHistoryPage((curr) => curr + 1);
                              setHistoryInd(0);
                            }
                          } else {
                            setHistoryInd((ind) => ind + 1);
                          }
                        }}
                        disabled={
                          historyInd ===
                            history.review_history_list?.length - 1 &&
                          history.current_page === history.total_pages
                        }
                      >
                        <RightIcon
                          sx={{
                            color: '#25507B',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        />
                      </HistoryNavigationBtn>
                      <HistoryNavigationBtn
                        disabled={
                          historyInd === 0 && history.current_page === 1
                        }
                        onClick={() => {
                          if (historyInd === 0) {
                            if (history.current_page > 1) {
                              setHistoryPage((curr) => curr - 1);
                              setHistoryInd(0);
                            }
                          } else {
                            setHistoryInd((ind) => ind - 1);
                          }
                        }}
                      >
                        <LeftIcon
                          sx={{
                            color: '#25507B',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        />
                      </HistoryNavigationBtn>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Grid>
          )
        ) : null}
      </Grid>
    </>
  );
};

export default Voting;
