import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const SalesDashboard = createReducer(
  {
    salesLeadData: {},
    salesLeadById: {},
    postSalesLeadErr: '',
    deleteSalesLeadErr: '',
    callAttemptData: {},
    emailAttemptData: {},
    salesExecutiveList: {},
    postedJobsList: {},
    postedDataResponse: {},
  },
  {
    [types.GET_SALES_LEAD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadData: [],
        isFetchingSalesLead: true,
        postSalesLeadErr: '',
        isPostingSalesLeadSuccess: false,
        isUpdatingSalesLeadSuccess: false,
      });

      return newState;
    },
    [types.GET_SALES_LEAD_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadData: action.data,
        isFetchingSalesLead: false,
      });

      return newState;
    },
    [types.GET_SALES_LEAD_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadData: [],
        isFetchingSalesLead: false,
      });

      return newState;
    },

    [types.GET_SALES_LEAD_BY_ID_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadById: [],
        isFetchingSalesLeadById: true,
      });

      return newState;
    },
    [types.GET_SALES_LEAD_BY_ID_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadById: action.data,
        isFetchingSalesLeadById: false,
      });

      return newState;
    },
    [types.GET_SALES_LEAD_BY_ID_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        salesLeadById: [],
        isFetchingSalesLeadById: false,
      });

      return newState;
    },

    [types.POST_SALES_LEAD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLead: true,
        postSalesLeadErr: '',
      });

      return newState;
    },
    [types.POST_SALES_LEAD_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLead: false,
        isPostingSalesLeadSuccess: true,
      });

      return newState;
    },
    [types.POST_SALES_LEAD_FAIL](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLead: false,
        postSalesLeadErr: action.data?.error,
      });

      return newState;
    },

    [types.PUT_SALES_LEAD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingSalesLead: true,
      });

      return newState;
    },
    [types.PUT_SALES_LEAD_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingSalesLead: false,
        isUpdatingSalesLeadSuccess: true,
      });

      return newState;
    },
    [types.PUT_SALES_LEAD_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingSalesLead: false,
      });

      return newState;
    },

    [types.DELETE_SALES_LEAD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingSalesLead: true,
        deleteSalesLeadErr: '',
      });

      return newState;
    },
    [types.DELETE_SALES_LEAD_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingSalesLead: false,
      });

      return newState;
    },
    [types.DELETE_SALES_LEAD_FAIL](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingSalesLead: false,
        deleteSalesLeadErr: action.data.error,
      });

      return newState;
    },

    [types.GET_CALL_ATTEMPT_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        callAttemptData: [],
        isFetchingCallAttemptDetails: true,
      });

      return newState;
    },
    [types.GET_CALL_ATTEMPT_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        callAttemptData: action.data,
        isFetchingCallAttemptDetails: false,
      });

      return newState;
    },
    [types.GET_CALL_ATTEMPT_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        callAttemptData: [],
        isFetchingCallAttemptDetails: false,
      });

      return newState;
    },

    [types.SEND_SIGNUP_MAIL_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSendingSignupMail: true,
        isMailSent: false,
      });

      return newState;
    },
    [types.SEND_SIGNUP_MAIL_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSendingSignupMail: false,
        isMailSent: true,
      });

      return newState;
    },
    [types.SEND_SIGNUP_MAIL_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSendingSignupMail: false,
        isMailSent: false,
      });

      return newState;
    },
    [types.POST_SALES_LEAD_BULK_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLeadBulk: true,
        isSalesLeadPosted: false,
        postedDataResponse: {},
      });

      return newState;
    },
    [types.POST_SALES_LEAD_BULK_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLeadBulk: false,
        isSalesLeadPosted: true,
        postedDataResponse: action.data,
      });

      return newState;
    },
    [types.POST_SALES_LEAD_BULK_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingSalesLeadBulk: false,
        isSalesLeadPosted: false,
        postedDataResponse: {},
      });

      return newState;
    },

    [types.GET_MAIL_SENT_LOG_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingMailList: true,
        isMailSent: false,
      });

      return newState;
    },
    [types.GET_MAIL_SENT_LOG_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingMailList: false,
        emailAttemptData: action.data,
      });

      return newState;
    },
    [types.GET_MAIL_SENT_LOG_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingMailList: false,
      });

      return newState;
    },

    [types.GET_ADMIN_SALES_EXECUTIVES_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingSalesExec: true,
      });

      return newState;
    },
    [types.GET_ADMIN_SALES_EXECUTIVES_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingSalesExec: false,
        salesExecutiveList: action.data,
      });

      return newState;
    },
    [types.GET_ADMIN_SALES_EXECUTIVES_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingSalesExec: false,
      });

      return newState;
    },

    [types.GET_POSTED_JOB_BY_THE_USER_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingPostedJobByUser: true,
      });

      return newState;
    },
    [types.GET_POSTED_JOB_BY_THE_USER_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingPostedJobByUser: false,
        postedJobsList: action.data,
      });

      return newState;
    },
    [types.GET_POSTED_JOB_BY_THE_USER_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isGettingPostedJobByUser: false,
      });

      return newState;
    },
  },
);

export { SalesDashboard as default };
