import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BlockReasons } from '../Button';

export default function BlockModal({
  selectedQuestions,
  getQuestionsIdOnClick,
  putBulkReviewQuestion,
  open,
  onClose,
  props,
  takeAction,
}) {
  const [selectedReasons, setSelectedReasons] = React.useState('');

  const handleClose = () => {
    onClose();
    setSelectedReasons('');
  };

  const onBlockReasonSubmit = async () => {
    takeAction();
    let result = [];
    const questions = selectedQuestions;
    if (questions.length) {
      await questions.map(async (ques) => {
        result.push({
          question_id: ques,
          question_status: 'Blocked',
          rejection_reason: selectedReasons,
        });
      });
    }
    if (result.length && selectedReasons) {
      putBulkReviewQuestion({
        body: result,
      });
      await getQuestionsIdOnClick(questions, 'Blocked');
    }
    setSelectedReasons('');
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title">
          Block Selected Questions
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <BlockReasons
              selectedReasons={selectedReasons}
              setSelectedReasons={setSelectedReasons}
              question={props.question}
              getReviewTemplate={props.getReviewTemplate}
              postReviewTemplate={props.postReviewTemplate}
              deleteReviewTemplate={props.deleteReviewTemplate}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="outlined"
            disabled={selectedReasons.length === 0}
            autoFocus
            onClick={onBlockReasonSubmit}
            style={{ color: selectedReasons.length === 0 ? 'grey' : '#f35f54' }}
          >
            Block
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
