import config from './../config';
import * as types from './types';
import apiClient from '../api/apiclient';

export function getUserProductStart() {
  return {
    type: types.GET_USER_PRODUCT_START,
  };
}

export function getUserProductSuccess({ data }) {
  return {
    type: types.GET_USER_PRODUCT_SUCCESS,
    data: data,
  };
}

export function getUserProductFail() {
  return {
    type: types.GET_USER_PRODUCT_FAIL,
  };
}

export function getUserProductsList() {
  return (dispatch) => {
    dispatch(getUserProductStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetAllItemsPublic('Learners', undefined, undefined)
      .then((resp) => {
        dispatch(getUserProductSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getUserProductFail());
        throw ex;
      });
  };
}

export function getShippingAddressStart() {
  return {
    type: types.GET_SHIPPING_ADDRESS_START,
  };
}

export function getShippingAddressSuccess({ data }) {
  return {
    type: types.GET_SHIPPING_ADDRESS_SUCCESS,
    data: data,
  };
}

export function getShippingAddressFail() {
  return {
    type: types.GET_SHIPPING_ADDRESS_FAIL,
  };
}

export function getShippingAddressList() {
  return (dispatch) => {
    dispatch(getShippingAddressStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetUserAddressDetails()
      .then((resp) => {
        dispatch(getShippingAddressSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getShippingAddressFail());
        throw ex;
      });
  };
}

export function postShippingAddressStart() {
  return {
    type: types.POST_SHIPPING_ADDRESS_START,
  };
}

export function postShippingAddressSuccess({ data }) {
  return {
    type: types.POST_SHIPPING_ADDRESS_SUCCESS,
    data: data,
  };
}

export function postShippingAddressFail() {
  return {
    type: types.POST_SHIPPING_ADDRESS_FAIL,
  };
}

export function createShippingAddress(body) {
  return (dispatch) => {
    dispatch(postShippingAddressStart());
    apiClient
      .getPaymentApi()
      .paymentApiUpsertUserAddressDetails(body)
      .then((resp) => {
        dispatch(postShippingAddressSuccess({ data: resp.data }));
        dispatch(getShippingAddressList());
      })
      .catch((ex) => {
        dispatch(postShippingAddressFail());
        throw ex;
      });
  };
}

export function postUpdateCartStart() {
  return {
    type: types.POST_UPDATE_START,
  };
}

export function postUpdateCartSuccess({ data }) {
  return {
    type: types.POST_UPDATE_SUCCESS,
    data: data,
  };
}

export function postUpdateCartFail() {
  return {
    type: types.POST_UPDATE_FAIL,
  };
}

export function updateCart(body) {
  return (dispatch) => {
    dispatch(postUpdateCartStart());
    apiClient
      .getPaymentApi()
      .paymentApiUpdateCartItems(body)
      .then((resp) => {
        dispatch(postUpdateCartSuccess({ data: resp.data }));
        dispatch(getCartList());
      })
      .catch((ex) => {
        dispatch(postUpdateCartFail());
        throw ex;
      });
  };
}

export function getCartListStart() {
  return {
    type: types.GET_CART_LIST_START,
  };
}

export function getCartListSuccess({ data }) {
  return {
    type: types.GET_CART_LIST_SUCCESS,
    data: data,
  };
}

export function getCartListFail() {
  return {
    type: types.GET_CART_LIST_FAIL,
  };
}

export function getCartList() {
  return (dispatch) => {
    dispatch(getCartListStart());
    apiClient
      .getPaymentApi()
      .paymentApiGetCart()
      .then((resp) => {
        dispatch(getCartListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getCartListFail());
        throw ex;
      });
  };
}

export function postPaymentStart() {
  return {
    type: types.POST_PAYMENT_START,
  };
}

export function postPaymentSuccess({ data }) {
  return {
    type: types.POST_PAYMENT_SUCCESS,
    data: data,
  };
}

export function postPaymentFail() {
  return {
    type: types.POST_PAYMENT_FAIL,
  };
}

export function initiatePayment(amount, userDetails) {
  return (dispatch) => {
    dispatch(postPaymentStart());
    apiClient
      .getPaymentApi()
      .paymentApiMakePayment({
        partial_payment: true,
        is_test: config.IS_TEST,
      })
      .then((resp) => {
        dispatch(postPaymentSuccess({ data: resp.data }));
        const options = {
          key: config.RAZORPAY_KEY,
          amount: amount,
          currency: config.RAZORPAY_CURRENCY,
          name: config.RAZORPAY_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          image: '',
          order_id: resp.data?.razorpay_order_id,
          handler: function(response) {
            dispatch(
              checkFinalPaymentStatus(
                resp.data?.transaction_id,
                response.razorpay_payment_id,
                response.razorpay_signature,
              ),
            );
          },
          modal: {
            ondismiss: function() {
              dispatch(cancelFinalPaymentStatus(resp.data?.transaction_id));
            },
          },
          prefill: {
            name: userDetails.name,
            email: userDetails.email,
            contact: userDetails.phoneNumber,
          },
          theme: {
            color: '#3399cc',
          },
        };
        if (!resp.data?.is_complete) {
          var rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', function() {});
          rzp1.open();
        } else {
          dispatch(getCartList());
          dispatch(checkPaymentStatusSuccess({ data: resp.data }));
        }
      })
      .catch((ex) => {
        dispatch(postPaymentFail());
        dispatch(getCartList());
        throw ex;
      });
  };
}

export function checkPaymentStatusStart() {
  return {
    type: types.CHECK_PAYMENT_STATUS_START,
  };
}

export function checkPaymentStatusSuccess(data) {
  return {
    type: types.CHECK_PAYMENT_STATUS_SUCCESS,
    data: data,
  };
}

export function checkPaymentStatusFail() {
  return {
    type: types.CHECK_PAYMENT_STATUS_FAIL,
  };
}

export function checkFinalPaymentStatus(transactionId, paymentId, signature) {
  return (dispatch) => {
    dispatch(checkPaymentStatusStart());
    apiClient
      .getPaymentApi()
      .paymentApiCompleteTransaction({
        transaction_id: transactionId,
        razorpay_payment_id: paymentId,
        razorpay_signature: signature,
      })
      .then((resp) => {
        dispatch(getCartList());
        dispatch(checkPaymentStatusSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(checkPaymentStatusFail());
        dispatch(getCartList());
        throw ex;
      });
  };
}

export function cancelPaymentStatusStart() {
  return {
    type: types.CANCEL_PAYMENT_STATUS_START,
  };
}

export function cancelPaymentStatusSuccess(data) {
  return {
    type: types.CANCEL_PAYMENT_STATUS_SUCCESS,
    data: data,
  };
}

export function cancelPaymentStatusFail() {
  return {
    type: types.CANCEL_PAYMENT_STATUS_FAIL,
  };
}

export function cancelFinalPaymentStatus(transactionId) {
  return (dispatch) => {
    dispatch(cancelPaymentStatusStart());
    apiClient
      .getPaymentApi()
      .paymentApiCancelTransaction({
        transaction_id: transactionId,
      })
      .then((resp) => {
        dispatch(getCartList());
        dispatch(cancelPaymentStatusSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(cancelPaymentStatusFail());
        dispatch(getCartList());
        throw ex;
      });
  };
}
