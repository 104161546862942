import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const publicProfile = createReducer(
  {
    profileData: {},
    followedUserList: [],
    isFollowDataFetchFail: false,
    isFollowDataFetchSuccess: false,
    user_activity: {},
    isUserActivityFetching: false,
  },
  {
    [types.GET_PUBLIC_PROFILE_START](state) {
      return {
        ...state,
        profileData: {},
        isDataLoading: true,
        isDataFetchSuccess: false,
        isDataFetchFail: false,
      };
    },
    [types.GET_PUBLIC_PROFILE_SUCCESS](state, action) {
      return {
        ...state,
        profileData: action.data,
        isDataLoading: false,
        isDataFetchSuccess: true,
      };
    },
    [types.GET_PUBLIC_PROFILE_FAIL](state) {
      return {
        ...state,
        isDataLoading: false,
        isDataFetchFail: true,
      };
    },
    [types.POST_FOLLOW_START](state) {
      return {
        ...state,
        isFollowDataFetchSuccess: false,
        isFollowDataFetchFail: false,
      };
    },
    [types.POST_FOLLOW_SUCCESS](state, action) {
      return {
        ...state,
        followedUserList: action.data.updated_followed_list || [],
        isFollowDataFetchSuccess: true,
      };
    },
    [types.POST_FOLLOW_FAIL](state) {
      return {
        ...state,
        isFollowDataFetchFail: true,
      };
    },
    [types.POST_UNFOLLOW_START](state) {
      return {
        ...state,
        isUnFollowDataFetchSuccess: false,
        isUnFollowDataFetchFail: false,
      };
    },
    [types.POST_UNFOLLOW_SUCCESS](state, action) {
      return {
        ...state,
        followedUserList: action.data.updated_followed_list || [],
        isUnFollowDataFetchSuccess: true,
      };
    },
    [types.POST_UNFOLLOW_FAIL](state) {
      return {
        ...state,
        isUnFollowDataFetchFail: true,
      };
    },
    [types.GET_FOLLOWED_USERS_LIST_START](state) {
      return {
        ...state,
      };
    },
    [types.GET_FOLLOWED_USERS_LIST_SUCCESS](state, action) {
      return {
        ...state,
        followedUserList: action.data.followed_users || [],
      };
    },
    [types.GET_FOLLOWED_USERS_LIST_FAIL](state) {
      return {
        ...state,
      };
    },
    [types.GET_USER_ACTIVITY_SUCCESS](state, action) {
      return {
        ...state,
        user_activity: action.payload,
        isUserActivityFetching: false,
      };
    },
    [types.GET_USER_ACTIVITY_START](state) {
      return {
        ...state,
        isUserActivityFetching: true,
      };
    },
    [types.GET_USER_ACTIVITY_FAIL](state) {
      return {
        ...state,
        isUserActivityFetching: false,
      };
    },
  },
);

export { publicProfile as default };
