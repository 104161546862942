import React, { useEffect, useState } from 'react';
import {
  Alert,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { addCommentToQuestion } from '../../actions/discussion';
import Snackbar from '@mui/material/Snackbar';
import NewCommentSendIcon from '../../images/NewCommentSend.svg';
import { StyledNewCommentInput } from './NewCommentStyledComponent';

const NewCommentInput = ({
  questionId,
  commentId: parentCommentId = null,
  topLevelComment = false,
}) => {
  const [openSnackBar, setOpenSnackBar] = useState(() => false);
  const [comment, setComment] = useState(() => '');
  const [commentType, setCommentType] = useState('General');
  const [addComment, setAddComment] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (addComment) {
      dispatch(
        addCommentToQuestion(comment, commentType, questionId, parentCommentId),
      );
      setComment('');
      setCommentType('General');
      setAddComment(false);
    }
  }, [addComment, comment, commentType, questionId, parentCommentId, dispatch]);

  const handleChange = (mode, value) => {
    mode === 0 ? setComment(value) : setCommentType(value);
  };

  const handleSubmit = () => {
    if (comment === '') {
      setOpenSnackBar(true);
      return;
    }
    setAddComment(true);
  };

  return (
    <StyledNewCommentInput>
      <section
        className={`new-comment-input-wrapper ${parentCommentId &&
          'new-comment-input-wrapper-sub'}`}
      >
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          className="empty-input-snackbar"
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert severity="info" onClose={() => setOpenSnackBar(false)}>
            Please Enter a Comment
          </Alert>
        </Snackbar>
        <FormControl className="new-comment-text">
          <label
            htmlFor="new-comment-text-field"
            className="new-comment-input-label"
          >
            Leave a Comment
          </label>
          <TextField
            value={comment}
            id="new-comment-text-field"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSubmit} size="large">
                  <img
                    src={NewCommentSendIcon}
                    alt={'send'}
                    className="new-input-send-icon"
                  />
                </IconButton>
              ),
            }}
            placeholder="Start typing here..."
            onChange={({ target }) => handleChange(0, target.value.trimStart())}
          />
        </FormControl>
        {topLevelComment && (
          <FormControl className="new-comment-type">
            <label
              htmlFor="new-comment-text-field"
              className="new-comment-input-label"
            >
              Comment Type
            </label>
            <Select
              value={commentType}
              variant="outlined"
              className="new-discussion-input"
              onChange={({ target }) => handleChange(1, target.value)}
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="AlternateSolution">Alternate Solution</MenuItem>
            </Select>
          </FormControl>
        )}
      </section>
    </StyledNewCommentInput>
  );
};

export default NewCommentInput;
