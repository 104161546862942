import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const category = createReducer(
  {
    data: [],
    c_id: [],
    isFetchingStats: true,
  },
  {
    [types.GET_TAG_SUCCESS](state, action) {
      let category_by_domain = {};
      for (var i = 0, l = action.data.category_data.length; i < l; i++) {
        let category = action.data.category_data[i];
        for (var j = 0, k = category.domain_ids.length; j < k; j++) {
          let domain = category.domain_ids[j];
          if (!(domain in category_by_domain)) {
            category_by_domain[domain] = [];
          }
          category_by_domain[domain].push(category);
        }
      }
      const newState = Object.assign({}, state, {
        isCategory: true,
        data: category_by_domain[action.data.selected_domain],
      });

      return newState;
    },
    [types.GET_TAG_FAIL](state) {
      const newState = Object.assign({}, state, {
        isCategory: false,
      });

      return newState;
    },
    [types.UNLOCK_CATEGORY_SUCCESS](state, action) {
      const newTagList = state.data.map((tag) => {
        if (tag.id === action.payload.tagId) {
          tag.is_unlocked = true;
          return tag;
        }
        return tag;
      });

      return {
        ...state,
        data: newTagList,
      };
    },
    [types.SET_PREFERENCES_START](state) {
      const newState = Object.assign({}, state, {
        preferencesSaveSuccess: false,
        preferencesSaveFail: false,
      });

      return newState;
    },
    [types.SET_PREFERENCES_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        preferencesSaveSuccess: true,
      });

      return newState;
    },
    [types.SET_PREFERENCES_FAIL](state) {
      const newState = Object.assign({}, state, {
        preferencesSaveFail: true,
      });

      return newState;
    },
    [types.GET_PREFERENCES_START](state) {
      return {
        ...state,
        isFetchingPreferences: true,
      };
    },
    [types.GET_PREFERENCES_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingPreferences: false,
        preferences: action.data.data,
      });

      return newState;
    },
    [types.GET_PREFERENCES_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingPreferences: false,
        preferences: [],
      });

      return newState;
    },
    [types.GET_STATS_START](state) {
      return {
        ...state,
        isFetchingStats: true,
      };
    },
    [types.GET_STATS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingStats: false,
        stats: action.data,
      });

      return newState;
    },
    [types.GET_STATS_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingStats: false,
        stats: [],
      });

      return newState;
    },
    [types.REMEMBER_CATEGORY_ID](state, action) {
      const newState = Object.assign({}, state, {
        c_id: action.data,
      });

      return newState;
    },
  },
);

export { category as default };
