import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import UserProfileInfo from '../components/UserProfile/UserProfileInfo';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user,
    publicProfile: state.publicProfile,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileInfo);
