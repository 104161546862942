import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const DetailsContainerDialog = ({ open, handleClose, userData }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        display={'flex'}
        alignItems="center"
        justifyContent={'space-between'}
      >
        User Details
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>{' '}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            {Object.entries(userData).map(([key, value], index) => (
              <TableRow key={index}>
                <TableCell>
                  <strong>
                    {key
                      .split('_')
                      .join(' ')
                      .toUpperCase()}
                  </strong>
                </TableCell>
                <TableCell>{value || 'NA'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsContainerDialog;
