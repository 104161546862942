import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function UnlockDialog({
  category = {},
  visible = false,
  dialogTitleMessage,
  onConfirm,
  onClose,
}) {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="confirm-category-unlock"
      aria-describedby="Unlock category"
    >
      <DialogTitle id="alert-dialog-title">
        {/* Do you want to unlock this category? */}
        {dialogTitleMessage}
      </DialogTitle>
      <DialogContent>
        {/* ToDo: We need to make this optional - should not be shown to competitions */}
        {/* <DialogContentText id="alert-dialog-description">
          You are about to unlock the <strong>{category.description}</strong>{' '}
          category.
        </DialogContentText> */}
        <DialogContentText id="alert-dialog-description">
          This action will consume {Math.abs(category.category_unlock_credits)}{' '}
          credits. Are you sure you want to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
