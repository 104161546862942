import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import {
  LeaderboardDatePicker,
  LeaderboardDialog,
  LeaderboardDialogActions,
  LeaderboardDialogCompetitionList,
  LeaderboardDialogContent,
  LeaderboardDialogTitle,
} from './StyledComponents';
import LeaderboardDialogIcon from '../../images/LeaderboardDialogIcon.svg';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { KeyboardArrowDown } from '@mui/icons-material';
import moment from 'moment/moment';

const SelectCompetitionsDialog = ({
  open,
  setOpen,
  selectedCompetition,
  setSelectedCompetition,
  monthAndYearForCompetition,
  setMonthAndYearForCompetition,
}) => {
  const competitionsList = useSelector(
    (state) => state.competitions.competitionList.competitions,
  );

  const [pickedCompetition, setPickedCompetition] = useState(
    () => selectedCompetition,
  );

  const [competitionQuery, setCompetitionQuery] = useState(() => '');

  const filteredCompetitions = useMemo(
    () =>
      competitionsList?.filter((competition) =>
        competition?.name
          ?.toLowerCase()
          .includes(competitionQuery.toLowerCase()),
      ),
    [competitionQuery, competitionsList],
  );

  useEffect(() => {
    setPickedCompetition(selectedCompetition);
  }, [selectedCompetition]);

  const handleClose = () => {
    setOpen(false);
  };
  const onApply = () => {
    setSelectedCompetition(pickedCompetition);
    handleClose();
  };

  return (
    <LeaderboardDialog open={open} onClose={handleClose}>
      <LeaderboardDialogTitle>
        <img src={LeaderboardDialogIcon} alt="leaderboard-icon" />
        <Box>
          <Typography
            sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
            }}
          >
            Select Competition
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: 300,
            }}
          >
            Choose Your Competitions to Get a Glimpse of the Leaderboard and See
            Where You Stand
          </Typography>
        </Box>
      </LeaderboardDialogTitle>
      <LeaderboardDialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              size="small"
              placeholder="Search a Competition"
              fullWidth
              value={competitionQuery}
              onChange={(event) => {
                setCompetitionQuery(event.target.value);
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <LeaderboardDatePicker
              views={['year', 'month']}
              slotProps={{ textField: { size: 'small' } }}
              slots={{ openPickerIcon: KeyboardArrowDown }}
              value={monthAndYearForCompetition}
              onChange={(value) => setMonthAndYearForCompetition(moment(value))}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          All Competitions
        </Box>
        <LeaderboardDialogCompetitionList>
          <RadioGroup
            value={pickedCompetition}
            onChange={(event) => setPickedCompetition(event.target.value)}
            sx={{
              color: 'black',
              overflow: 'auto',
            }}
          >
            {filteredCompetitions?.map((competition) => (
              <FormControlLabel
                key={competition.id}
                control={<Radio />}
                value={competition.id}
                label={competition.name}
              />
            ))}
          </RadioGroup>
        </LeaderboardDialogCompetitionList>
      </LeaderboardDialogContent>
      <LeaderboardDialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          className="leaderboard-button"
        >
          Close
        </Button>
        <Button
          onClick={onApply}
          variant="contained"
          className="leaderboard-button"
        >
          Apply
        </Button>
      </LeaderboardDialogActions>
    </LeaderboardDialog>
  );
};

export default SelectCompetitionsDialog;
