import { styled } from '@mui/styles';

export const StyledBlankContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  h6: {
    textAlign: 'center',
    maxWidth: '600px',
  },

  '& .blank-icon': {
    fontSize: '72px',
  },
});
