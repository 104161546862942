import * as types from './types';
import apiClient from '../api/apiclient.ts';

function getSalesLeadStart() {
  return {
    type: types.GET_SALES_LEAD_START,
  };
}

function getSalesLeadSuccess({ data }) {
  return {
    type: types.GET_SALES_LEAD_SUCCESS,
    data: data,
  };
}

function getSalesLeadFail() {
  return {
    type: types.GET_SALES_LEAD_FAIL,
  };
}

export function getSalesLead(page, pageSize, query, salesUserId, filters) {
  return (dispatch) => {
    dispatch(getSalesLeadStart());

    apiClient
      .getSalesAPI()
      .salesApiGetSalesLeadList(
        filters?.endDate,
        filters?.startDate,
        page,
        pageSize,
        query,
        undefined,
        salesUserId,
        filters?.status,
      )
      .then((resp) => {
        dispatch(getSalesLeadSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getSalesLeadFail());
      });
  };
}

function getSalesLeadByIdStart() {
  return {
    type: types.GET_SALES_LEAD_BY_ID_START,
  };
}

function getSalesLeadByIdSuccess({ data }) {
  return {
    type: types.GET_SALES_LEAD_BY_ID_SUCCESS,
    data: data,
  };
}

function getSalesLeadByIdFail() {
  return {
    type: types.GET_SALES_LEAD_BY_ID_FAIL,
  };
}

export function getSalesLeadById(id) {
  return (dispatch) => {
    dispatch(getSalesLeadByIdStart());

    apiClient
      .getSalesAPI()
      .salesApiGetSalesLeadById(id)
      .then((resp) => {
        dispatch(getSalesLeadByIdSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getSalesLeadByIdFail());
      });
  };
}
export function getNextSalesLead(
  page,
  pageSize,
  query,
  paramSalesUserId,
  filters,
  callback,
) {
  return (dispatch) => {
    dispatch(getSalesLeadStart());

    apiClient
      .getSalesAPI()
      .salesApiGetSalesLeadList(
        filters?.endDate,
        filters?.startDate,
        page,
        pageSize,
        query,
        undefined,
        paramSalesUserId,
        filters?.status,
      )
      .then((resp) => {
        dispatch(getSalesLeadSuccess({ data: resp.data }));
        callback(resp.data);
      })
      .catch(() => {
        dispatch(getSalesLeadFail());
      });
  };
}

function postSalesLeadStart() {
  return {
    type: types.POST_SALES_LEAD_START,
  };
}

function postSalesLeadSuccess({ data }) {
  return {
    type: types.POST_SALES_LEAD_SUCCESS,
    data: data,
  };
}

function postSalesLeadFail({ err }) {
  return {
    type: types.POST_SALES_LEAD_FAIL,
    data: err,
  };
}

export function postSalesLead(body, currentSalesUserId, filters, page) {
  return (dispatch) => {
    dispatch(postSalesLeadStart());

    apiClient
      .getSalesAPI()
      .salesApiCreateSalesLead(body)
      .then((resp) => {
        dispatch(postSalesLeadSuccess({ data: resp.data }));
        dispatch(
          getSalesLead(
            page,
            10,
            '',
            currentSalesUserId ? currentSalesUserId : '',
            filters,
          ),
        );
      })
      .catch((err) => {
        dispatch(postSalesLeadFail({ err: err.response.data }));
      });
  };
}

function postSalesLeadBulkStart() {
  return {
    type: types.POST_SALES_LEAD_BULK_START,
  };
}

function postSalesLeadBulkSuccess({ data }) {
  return {
    type: types.POST_SALES_LEAD_BULK_SUCCESS,
    data: data,
  };
}

function postSalesLeadBulkFail() {
  return {
    type: types.POST_SALES_LEAD_BULK_FAIL,
  };
}

export function postSalesLeadBulk(body, currentSalesUserId, filters, page) {
  return (dispatch) => {
    dispatch(postSalesLeadBulkStart());

    apiClient
      .getSalesAPI()
      .salesApiCreateBulkSalesLead(body)
      .then((resp) => {
        dispatch(postSalesLeadBulkSuccess({ data: resp.data }));
        dispatch(
          getSalesLead(
            page,
            10,
            '',
            currentSalesUserId ? currentSalesUserId : '',
            filters,
          ),
        );
      })
      .catch(() => {
        dispatch(postSalesLeadBulkFail());
      });
  };
}

function putSalesLeadStart() {
  return {
    type: types.PUT_SALES_LEAD_START,
  };
}

function putSalesLeadSuccess({ data }) {
  return {
    type: types.PUT_SALES_LEAD_SUCCESS,
    data: data,
  };
}

function putSalesLeadFail() {
  return {
    type: types.PUT_SALES_LEAD_FAIL,
  };
}

export function putSalesLead(
  leadId,
  body,
  whereFrom,
  currentSalesUserId,
  filters,
  page,
) {
  return (dispatch) => {
    dispatch(putSalesLeadStart());

    apiClient
      .getSalesAPI()
      .salesApiUpdateSalesLeadById(leadId, body)
      .then((resp) => {
        dispatch(putSalesLeadSuccess({ data: resp.data }));
        if (whereFrom === 'emailAttempsPage') {
          dispatch(getSalesLeadById(leadId));
        } else {
          dispatch(
            getSalesLead(
              page,
              10,
              '',
              currentSalesUserId ? currentSalesUserId : '',
              filters,
            ),
          );
        }
      })
      .catch(() => {
        dispatch(putSalesLeadFail());
      });
  };
}

function deleteSalesLeadStart() {
  return {
    type: types.DELETE_SALES_LEAD_START,
  };
}

function deleteSalesLeadSuccess({ data }) {
  return {
    type: types.DELETE_SALES_LEAD_SUCCESS,
    data: data,
  };
}

function deleteSalesLeadFail({ data }) {
  return {
    type: types.DELETE_SALES_LEAD_FAIL,
    data: data,
  };
}

export function deleteSalesLead(id, currentSalesUserId, filters, page) {
  return (dispatch) => {
    dispatch(deleteSalesLeadStart());

    apiClient
      .getSalesAPI()
      .salesApiDeleteSalesLeadById(id)
      .then((resp) => {
        dispatch(deleteSalesLeadSuccess({ data: resp.data }));
        dispatch(getSalesLead(page, 10, '', currentSalesUserId, filters));
      })
      .catch((err) => {
        dispatch(deleteSalesLeadFail({ data: err.response.data }));
      });
  };
}
function getCallAttemptStart() {
  return {
    type: types.GET_CALL_ATTEMPT_START,
  };
}

function getCallAttemptSuccess({ data }) {
  return {
    type: types.GET_CALL_ATTEMPT_SUCCESS,
    data: data,
  };
}

function getCallAttemptFail() {
  return {
    type: types.GET_CALL_ATTEMPT_FAIL,
  };
}

export function getCallAttempt(page, rowsPerPage, phoneNumber, salesLeadId) {
  return (dispatch) => {
    dispatch(getCallAttemptStart());

    apiClient
      .getSalesAPI()
      .salesApiGetCallAttemptList(page, rowsPerPage, phoneNumber, salesLeadId)
      .then((resp) => {
        dispatch(getCallAttemptSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getCallAttemptFail());
      });
  };
}

function postCallAttemptStart() {
  return {
    type: types.POST_CALL_ATTEMPT_START,
  };
}

function postCallAttemptSuccess({ data }) {
  return {
    type: types.POST_CALL_ATTEMPT_SUCCESS,
    data: data,
  };
}

function postCallAttemptFail({ err }) {
  return {
    type: types.POST_CALL_ATTEMPT_FAIL,
    data: err,
  };
}

export function postCallAttempt(body, id, phoneNumber) {
  return (dispatch) => {
    dispatch(postCallAttemptStart());

    apiClient
      .getSalesAPI()
      .salesApiCreateCallAttempt(id, body)
      .then((resp) => {
        dispatch(postCallAttemptSuccess({ data: resp.data }));
        dispatch(getCallAttempt(1, 10, phoneNumber, id));
      })
      .catch((err) => {
        dispatch(postCallAttemptFail({ err: err.response.data }));
      });
  };
}
function updateCallAttemptStart() {
  return {
    type: types.UPDATE_CALL_ATTEMPT_START,
  };
}

function updateCallAttemptSuccess({ data }) {
  return {
    type: types.UPDATE_CALL_ATTEMPT_SUCCESS,
    data: data,
  };
}

function updateCallAttemptFail() {
  return {
    type: types.UPDATE_CALL_ATTEMPT_FAIL,
  };
}

export function updateCallAttempt(
  body,
  callAttemptId,
  salesLeadId,
  phoneNumber,
) {
  return (dispatch) => {
    dispatch(updateCallAttemptStart());

    apiClient
      .getSalesAPI()
      .salesApiUpdateCallAttempt(callAttemptId, salesLeadId, body)
      .then((resp) => {
        dispatch(updateCallAttemptSuccess({ data: resp.data }));
        dispatch(getCallAttempt(1, 10, phoneNumber, salesLeadId));
      })
      .catch(() => {
        dispatch(updateCallAttemptFail());
      });
  };
}

function sendSignupMailStart() {
  return {
    type: types.SEND_SIGNUP_MAIL_START,
  };
}

function sendSignupMailSuccess({ data }) {
  return {
    type: types.SEND_SIGNUP_MAIL_SUCCESS,
    data: data,
  };
}

function sendSignupMailFail() {
  return {
    type: types.SEND_SIGNUP_MAIL_FAIL,
  };
}

export function sendSignupMail(id, email) {
  return (dispatch) => {
    dispatch(sendSignupMailStart());

    apiClient
      .getSalesAPI()
      .salesApiEmailToSalesLead(id)
      .then((resp) => {
        dispatch(sendSignupMailSuccess({ data: resp.data }));
        dispatch(getMailSentLog(email, 1, 10, id));
      })
      .catch(() => {
        dispatch(sendSignupMailFail());
      });
  };
}

function getMailSentLogStart() {
  return {
    type: types.GET_MAIL_SENT_LOG_START,
  };
}

function getMailSentLogSuccess({ data }) {
  return {
    type: types.GET_MAIL_SENT_LOG_SUCCESS,
    data: data,
  };
}

function getMailSentLogFail() {
  return {
    type: types.GET_MAIL_SENT_LOG_FAIL,
  };
}

export function getMailSentLog(email, page, pageSize, id) {
  return (dispatch) => {
    dispatch(getMailSentLogStart());

    apiClient
      .getSalesAPI()
      .salesApiGetSalesMailSentList(email, page, pageSize, id)
      .then((resp) => {
        dispatch(getMailSentLogSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getMailSentLogFail());
      });
  };
}

function getAdminSalesExecutivesStart() {
  return {
    type: types.GET_ADMIN_SALES_EXECUTIVES_START,
  };
}

function getAdminSalesExecutivesSuccess({ data }) {
  return {
    type: types.GET_ADMIN_SALES_EXECUTIVES_SUCCESS,
    data: data,
  };
}

function getAdminSalesExecutivesFail() {
  return {
    type: types.GET_ADMIN_SALES_EXECUTIVES_FAIL,
  };
}

export function getAdminSalesExecutives(name, page, pageSize) {
  return (dispatch) => {
    dispatch(getAdminSalesExecutivesStart());
    apiClient
      .getSalesAPI()
      .salesApiGetSalesExcutiveList(name, page, pageSize)
      .then((resp) => {
        dispatch(getAdminSalesExecutivesSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAdminSalesExecutivesFail());
      });
  };
}

function getPostedJobsByTheUserStart() {
  return {
    type: types.GET_POSTED_JOB_BY_THE_USER_START,
  };
}

function getPostedJobsByTheUserSuccess({ data }) {
  return {
    type: types.GET_POSTED_JOB_BY_THE_USER_SUCCESS,
    data: data,
  };
}

function getPostedJobsByTheUserFail() {
  return {
    type: types.GET_POSTED_JOB_BY_THE_USER_FAIL,
  };
}

export function getPostedJobsByTheUser(page, pageSize, id) {
  return (dispatch) => {
    dispatch(getPostedJobsByTheUserStart());

    apiClient
      .getSalesAPI()
      .salesApiGetSalesLeadJobDetailsList(id, page, pageSize)
      .then((resp) => {
        dispatch(getPostedJobsByTheUserSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getPostedJobsByTheUserFail());
      });
  };
}
