import React, { useEffect, useState } from 'react';
import { Container, Grid, MenuItem, Select } from '@mui/material';
import { useStyles } from './style';
import HotTopics from './HotTopics';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCollegeDashboardMilestone,
  getCollegeJobs,
  getCollegeLeaderboard,
  getCollegeRelatedDetails,
  getCollegeStats,
  getUserColleges,
} from '../../actions/collegeDashboard';
import CollegePlaceholder from './images/CollegePlaceholder.svg';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WeeklyTopper from './WeeklyTopper';
import JobOpportunities from './JobOpportunities';
import CollegeStats from './CollegeStats';
import LiveCompetitions from './LiveCompetitions';
import TrendingMockTests from './TrendingMockTests';
import MilestoneBar from './MilestoneBar';
import AddCollegeDialog from './AddCollegeDialog';

const CollegeDashboard = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [currentCollege, setCurrentCollege] = useState('');
  const [openAddCollegeDialog, setOpenAddCollegeDialog] = useState(false);
  const {
    userCollegeData,
    leaderboardData,
    collegeRelatedData,
    collegeJobData,
    collegeStatsData,
    milestoneData,
  } = useSelector((state) => state.collegeDashboard);

  useEffect(() => {
    dispatch(getUserColleges());
  }, []);

  useEffect(() => {
    if (userCollegeData?.college_list) {
      setCurrentCollege(userCollegeData?.college_list?.[0]?.college_id);
    }
  }, [userCollegeData]);

  useEffect(() => {
    if (currentCollege) dispatch(getCollegeRelatedDetails(currentCollege));
  }, [dispatch, currentCollege]);

  useEffect(() => {
    if (currentCollege) dispatch(getCollegeLeaderboard(currentCollege));
  }, [dispatch, currentCollege]);

  useEffect(() => {
    if (currentCollege) dispatch(getCollegeJobs(currentCollege));
  }, [dispatch, currentCollege]);

  useEffect(() => {
    if (currentCollege) dispatch(getCollegeStats(currentCollege));
  }, [dispatch, currentCollege]);

  useEffect(() => {
    if (currentCollege) {
      dispatch(getCollegeDashboardMilestone(currentCollege));
    }
  }, [dispatch, currentCollege]);

  const options = userCollegeData?.college_list;
  const handleAddCollegeDialogOpen = () => {
    setOpenAddCollegeDialog(true);
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          <Select
            fullWidth
            value={currentCollege}
            onChange={(e) => setCurrentCollege(e.target.value)}
            IconComponent={KeyboardArrowDownIcon}
            renderValue={(selectedValue) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={selectedValue.image_url || CollegePlaceholder}
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                {
                  options?.find((option) => option.college_id === selectedValue)
                    ?.college_name
                }
              </div>
            )}
            sx={{
              borderRadius: '25px',
              border: '1px solid #25507B',
              background: '#fff',
              height: '40px',
            }}
          >
            {options?.map((option) => (
              <MenuItem key={option.college_id} value={option.college_id}>
                <img
                  src={option.image_url || CollegePlaceholder}
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                {option.college_name}
              </MenuItem>
            ))}
            <>
              <Grid
                onClick={handleAddCollegeDialogOpen}
                display={'flex'}
                alignItems={'center'}
                sx={{
                  borderTop: '1px solid #25507B',
                  margin: '0px 15px',
                  paddingTop: '5px',
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                <AddCircleIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    color: '#25507B ',
                  }}
                />
                Add New College/University
              </Grid>
            </>
          </Select>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <MilestoneBar milestoneData={milestoneData} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item md={4} sm={12} xs={12}>
          <div className={styles.contentLeft}>
            <HotTopics
              headline={'Hot Topics'}
              styles={styles}
              collegeRelatedData={collegeRelatedData}
            />
            <LiveCompetitions headline={'Live Competitions'} styles={styles} />
            <TrendingMockTests
              headline={'Trending Mocktests'}
              styles={styles}
              collegeRelatedData={collegeRelatedData}
            />
          </div>
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <CollegeStats
            styles={styles}
            collegeStatsData={collegeStatsData}
            collegeRelatedData={collegeRelatedData}
            milestoneData={milestoneData}
          />
          <Grid container style={{ marginTop: '20px' }} spacing={2}>
            <Grid item md={7} sm={12} xs={12}>
              <WeeklyTopper styles={styles} leaderboardData={leaderboardData} />
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <JobOpportunities
                styles={styles}
                collegeJobData={collegeJobData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openAddCollegeDialog ? (
        <AddCollegeDialog
          open={openAddCollegeDialog}
          onClose={() => setOpenAddCollegeDialog(false)}
        />
      ) : null}
    </Container>
  );
};

export default CollegeDashboard;
