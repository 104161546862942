import * as AuthActions from './auth';
import * as QuestionActions from './question';
import * as tagActions from './tag';
import * as categoryActions from './category';
import * as userActions from './user';
import * as leaderboardActions from './leaderboard';
import * as notificationsActions from './notifications';
import * as totalReferralsActions from './totalReferrals';
import * as publicProfileActions from './publicProfile';
import * as layoutActions from './layout';
import * as domainActions from './domain';
import * as competitionActions from './competition';
import * as creditsActions from './credits';
import * as emailingListActions from './emailingList';
import * as orderHistoryActions from './orderHistory';
import * as userStoreActions from './userStore';
import * as storeAdminActions from './storeAdmin';
import * as dataManagementActions from './dataManagement';
import * as mockTestActions from './mockTest';
import * as reportBug from './reportBug';
import * as badges from './badges';
import * as dashboard from './dashboard';
import * as discussionActions from './discussion';
import * as adminJobsActions from './adminJobs';
import * as collegeDashboardActions from './collegeDashboard';
import * as AdminDegreeCreate from './adminDegreeCreate';
import * as EmailNotifications from './emailNotifications';
import * as AdminCouponsActions from './adminCoupons';
import * as SalesDashboard from './salesDashboard';
import * as AdminUserManagement from './adminUserManagement';

export default Object.assign(
  {},
  AuthActions,
  QuestionActions,
  tagActions,
  categoryActions,
  userActions,
  leaderboardActions,
  notificationsActions,
  totalReferralsActions,
  publicProfileActions,
  layoutActions,
  domainActions,
  competitionActions,
  creditsActions,
  emailingListActions,
  orderHistoryActions,
  userStoreActions,
  storeAdminActions,
  dataManagementActions,
  mockTestActions,
  reportBug,
  badges,
  discussionActions,
  adminJobsActions,
  collegeDashboardActions,
  dashboard,
  AdminDegreeCreate,
  EmailNotifications,
  AdminCouponsActions,
  SalesDashboard,
  AdminUserManagement,
);
