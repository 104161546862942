import { Button, Container, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import { useEffect } from 'react';
import MessageBar from '../../MessageBar';
import ImagePicker from '../../ImagePicker';
import {
  postCollegeDashboardMilestone,
  updateCollegeDashboardMilestone,
  uploadCollegeMilestoneImg,
} from '../../../actions/collegeDashboard';

const CreateNewMilestone = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const { selectedMilestone } = state || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      gems: 0,
      credits: 0,
    },
  });
  const {
    isPostingMilestoneDataSuccess,
    isUpdatingMilestoneDataSuccess,
    milestoneImgUrl,
    isUploadingMilestoneImg,
  } = useSelector((state) => state.collegeDashboard);
  const [openMessageBar, setOpenMessageBar] = useState(false);
  useEffect(() => {
    if (params.collegeMilestoneId) {
      reset({
        student_count: selectedMilestone.student_count,
        credits: selectedMilestone.credits ?? 0,
        gems: selectedMilestone.gems ?? 0,
      });
    }
  }, [params.collegeMilestoneId]);

  const onSubmitHandler = (data) => {
    data.image_url = params.collegeMilestoneId
      ? milestoneImgUrl?.length === 0
        ? selectedMilestone.image_url
        : milestoneImgUrl
      : milestoneImgUrl;
    if (params.collegeMilestoneId) {
      dispatch(
        updateCollegeDashboardMilestone(params.collegeMilestoneId, data),
      );
    } else {
      dispatch(postCollegeDashboardMilestone(data));
    }
    setOpenMessageBar(true);

    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };
  const handleMessageClose = () => {
    setOpenMessageBar(false);
  };

  const onFileLoad = (file) => {
    dispatch(uploadCollegeMilestoneImg(file));
  };

  return (
    <Container maxWidth={false}>
      <section className="card">
        <header className="card-heading">
          {!params.collegeMilestoneId ? 'Create Badge' : 'Edit Badge'}
        </header>
        <div className="card-content">
          <div className="position-center">
            <div className="tags-form">
              <form>
                <div className="form-control">
                  <label htmlFor="">Student count</label>
                  <div className="field-item">
                    <TextField
                      {...register('student_count', {
                        required: 'Please enter milestone no',
                        valueAsNumber: true,
                        min: {
                          value: 1,
                          message: 'Student count cannot be less than 1',
                        },
                      })}
                      name="student_count"
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.student_count}
                      helperText={errors.student_count?.message}
                      placeholder="Student count"
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-control">
                  <label htmlFor="">Gems</label>
                  <div className="field-item">
                    <TextField
                      {...register('gems', {
                        required: 'Please enter gem reward',
                        valueAsNumber: true,
                      })}
                      name="gems"
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.gems}
                      helperText={errors.gems?.message}
                      placeholder="Gem reward"
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-control">
                  <label htmlFor="">Credit</label>
                  <div className="field-item">
                    <TextField
                      {...register('credits', {
                        required: 'Please enter credit reward',
                        valueAsNumber: true,
                      })}
                      name="credits"
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.credits}
                      helperText={errors.credits?.message}
                      placeholder="Credit reward"
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-control">
                  <div className="field-item">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImagePicker
                        onFileLoad={onFileLoad}
                        imageUrl={
                          params.collegeMilestoneId
                            ? selectedMilestone.image_url
                            : milestoneImgUrl
                        }
                      />
                    </div>
                  </div>
                </div>

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isUploadingMilestoneImg}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isUploadingMilestoneImg ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </section>
      <MessageBar
        type={
          isPostingMilestoneDataSuccess || isUpdatingMilestoneDataSuccess
            ? 'success'
            : 'error'
        }
        open={openMessageBar}
        onClose={handleMessageClose}
        message={
          isPostingMilestoneDataSuccess || isUpdatingMilestoneDataSuccess
            ? isUpdatingMilestoneDataSuccess
              ? 'Badge Updated Successfully, Redirecting...'
              : 'Badge Created Successfully, Redirecting...'
            : 'Error Occured'
        }
      />
    </Container>
  );
};

export default CreateNewMilestone;
