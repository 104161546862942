import React, { useEffect, useState } from 'react';
import NotificationsOutlinedIcon from '../../images/notifications.svg';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import moment from 'moment';
import './index.css';
import { withRouter } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => (
  <WrappedComponent {...props} width="xs" />
);

const Notification = (props) => {
  const [isNewNotification, setIsNewNotification] = useState(
    !!props.notifications?.data?.new_notifications,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [page_size, setPageSize] = useState(10);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [notificationsInterval, setNotificationsInterval] = useState('');
  const { credits } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.notifications.data.new_notifications && isNewNotification) {
      setIsNewNotification(true);
    } else {
      setIsNewNotification(false);
    }
  }, [props.notifications.data.new_notifications, isNewNotification]);

  useEffect(() => {
    props.getNewNotifications();
    setNotificationsInterval(
      setInterval(
        () => props.getNewNotifications(),
        120000, //2 mins
      ),
    );
    props.getNotifications({
      page: 1,
      page_size: 100,
      body: { viewed: false },
    });
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    window.removeEventListener('resize', updateWindowDimensions);
    clearInterval(notificationsInterval);
  }, [notificationsInterval]);
  useEffect(() => {
    setUnreadNotificationsCount(props.notifications?.data?.new_notifications);
  }, [props.notifications.data.new_notifications]);

  useEffect(() => {
    props.getNewNotifications();
    props.getNotifications({
      page: 1,
      page_size: 100,
      body: { viewed: false },
    });
  }, [credits]);

  const updateWindowDimensions = () => {
    handleClose();
  };
  const getTotalNoOfNotificationsonBellIconClick = () => {
    setPageSize(10);
  };

  const showNextTenNotifications = () => {
    setPageSize(page_size + 10);
  };

  const handleClick = (event) => {
    props.getNotifications({
      page: 1,
      page_size: 100,
      body: { viewed: true },
    });
    getTotalNoOfNotificationsonBellIconClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsNewNotification(false);
  };

  const openQuestionTab = (notification, eachQuestion) => {
    if (notification.notification_type === 'QuestionReviewed') {
      navigate(`/l/question/${notification.entity_id}`, {
        state: {
          question: eachQuestion,
          tag: props.tag,
        },
      });
    }
    handleClose();
  };

  const clearBadge = () => {
    setUnreadNotificationsCount(0);
  };

  const openPlayArea = (notification) => {
    let arr = props.location.pathname.split('/');
    const { tag, changeDomain } = props;
    const domains = Object.keys(tag.category_by_domain);
    domains.map((domain) => {
      tag.category_by_domain[domain].map((option) => {
        if (notification.entity_id && option.id === notification.entity_id) {
          if (option.domain_ids[0] !== props.user.selected_domain) {
            changeDomain({
              domain_id: option.domain_ids[0],
            });
          }
        }
      });
    });

    if (arr.length === 4) {
      navigate(`${props.match.path}/answer/${notification.entity_id}/play`);
      window.location.reload();
    } else {
      navigate(`${props.match.path}/answer/${notification.entity_id}/play`);
    }

    handleClose();
  };

  const showNotifications = (notification) => {
    const { question } = props;

    const eachQuestion = question.questionList.find(
      (item) => item.id === notification.entity_id,
    );

    return (
      <div key={notification.id}>
        <a
          href
          key={notification.id}
          onClick={() => openQuestionTab(notification, eachQuestion)}
          className={
            notification.is_viewed ? 'dropdown-item' : 'notification_notViewed'
          }
        >
          <div className="each-notification-container">
            <div className="notification-icon">
              {notification.notification_type === 'QuestionReviewed' ? (
                notification.text.includes('Accepted') ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <HighlightOffIcon color="error" />
                )
              ) : notification.notification_type === 'BadgeRecieved' ? (
                <StarsOutlinedIcon color="success" />
              ) : (
                <OfflineBoltOutlinedIcon color="#3572B0" />
              )}
            </div>
            <div className="play-icon-text">
              <p>{notification.text}</p>
              <div className="date-time-container">
                <Typography variant="caption" className="date-container">
                  {moment.utc(notification.created_at).fromNow()}
                </Typography>
              </div>
            </div>
            <div className="date-time-play-container">
              <div className="play-icon">
                {notification.notification_type === 'RankImproved' &&
                notification.entity_id ? (
                  <Button
                    size="small"
                    color="success"
                    onClick={() => openPlayArea(notification)}
                  >
                    <PlayCircleOutlineIcon fontSize="medium" color="success" />
                    <span className="play-text">Play</span>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </a>
        <Typography className="space-outside"></Typography>
      </div>
    );
  };

  const { notifications } = props;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <Badge className="appbar__content__data__badge">
          <img
            style={{ position: 'relative' }}
            src={NotificationsOutlinedIcon}
            width={'17px'}
          />
          <p
            style={{
              position: 'absolute',
              top: '-6px',
              left: '6px',
              fontSize: '9px',
              color: 'black',
              fontWeight: 'bolder',
              height: '11px',
              width: '19px',
              backgroundColor: 'white',
              borderRadius: '1px',
              border: '0.5px solid #76767F99',
            }}
          >
            {unreadNotificationsCount
              ? unreadNotificationsCount > 99
                ? '99+'
                : unreadNotificationsCount
              : '0'}
          </p>
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={{ onEntered: clearBadge }}
        disablePortal={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: 'notification_popover' }}
      >
        <Typography className="notifications" variant="subtitle1">
          Notifications
        </Typography>
        <Typography className="space-outside"></Typography>
        {notifications.data.notifications.length > 0 ? (
          <div className="notification-container">
            {notifications.data.notifications.length <= 10
              ? notifications.data.notifications.map((notification) => {
                  return showNotifications(notification);
                })
              : notifications.data.notifications
                  .slice(0, page_size)
                  .map((notification) => {
                    return showNotifications(notification);
                  })}
            {notifications.data.notifications.length > page_size ? (
              <span className="button">
                <Button
                  color="primary"
                  variant="text"
                  onClick={showNextTenNotifications}
                >
                  <span className="load_more">See more</span>
                </Button>
              </span>
            ) : null}
          </div>
        ) : (
          <Typography className="notification_item">
            No new notifications
          </Typography>
        )}
      </Popover>
    </>
  );
};

export default withWidth()(withRouter(Notification));
