import { Box } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewer = ({ file, style }) => {
  const [numOfPages, setNumOfPages] = useState(0);
  function onDocumentLoadSuccess({ numPages }) {
    setNumOfPages(numPages);
  }

  return (
    <Box style={style} width="100%" display="flex" justifyContent="center">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numOfPages), (el, index) => (
          <>
            <Page
              key={index + 1}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
            />
            <hr />
          </>
        ))}
      </Document>
    </Box>
  );
};

export default PdfViewer;
