import createReducer from '../lib/createReducer';
import {
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_START,
  GET_LEADERBOARD_SUCCESS,
} from '../actions/types';

const initialState = {
  leaderboardData: [],
  rankList: [],
  currentUserDetails: {},
  paginationInfo: {
    currentPage: 1,
    pageSize: 10,
    totalPages: null,
  },
  leaderboardType: null,
  fetchingLeaderboard: false,
  error: null,
};

export const leaderboard = createReducer(initialState, {
  [GET_LEADERBOARD_START](state) {
    return {
      ...state,
      fetchingLeaderboard: true,
    };
  },
  [GET_LEADERBOARD_SUCCESS](state, action) {
    const {
      rank_list,
      current_page,
      page_size,
      total_pages,
      curr_user_score_details,
      leaderboardType,
    } = action.data;

    return {
      ...state,
      fetchingLeaderboard: false,
      leaderboardData: action.data,
      rankList: rank_list,
      paginationInfo: {
        currentPage: current_page,
        pageSize: page_size,
        totalPages: total_pages,
      },
      currentUserDetails: curr_user_score_details,
      leaderboardType,
    };
  },
  [GET_LEADERBOARD_FAIL](state, action) {
    return {
      ...initialState,
      fetchingLeaderboard: action.data.code === 'ERR_CANCELED' ? true : false,
      error: action.data,
    };
  },
});
