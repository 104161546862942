import { Button, Container, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { useEffect } from 'react';
import { postLevel, updateLevel, uploadLevelImage } from '../../actions/levels';
import MessageBar from '../MessageBar';
import ImagePicker from '../ImagePicker';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FormWrapper,
  TagsForm,
  FormControl,
  FieldItem,
  FormHeading,
} from '../CreateBadge/StyledComponents';
const CreateNewLevelDialog = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  const { selectedLevel } = state || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      gem_reward: 0,
      credit_reward: 0,
    },
  });

  const {
    isCreatingLevel,
    s3ImgUrl,
    isuploadingLevelImg,
    isCreatingLevelSuccess,
    isUpdatingLevelSuccess,
  } = useSelector((state) => state.levels);
  const [openMessageBar, setOpenMessageBar] = useState(false);

  useEffect(() => {
    if (params.level_id) {
      reset({
        ...selectedLevel,
        credit_reward: selectedLevel.credit_reward ?? 0,
        gem_reward: selectedLevel.gem_reward ?? 0,
      });
    }
  }, [params.level_id]);

  const onSubmitHandler = (data) => {
    data.image_url = params.level_id
      ? s3ImgUrl
        ? s3ImgUrl
        : selectedLevel.image_url
      : s3ImgUrl;
    if (!data.image_url) data.image_url = undefined;
    if (params.level_id) {
      data.created_at = undefined;
      data.id = undefined;
      data.modified_on = undefined;
      dispatch(updateLevel(data, params.level_id));
    } else {
      dispatch(postLevel(data));
    }
    setOpenMessageBar(true);
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };
  const handleMessageClose = () => {
    setOpenMessageBar(false);
  };

  const onFileLoad = (file) => {
    dispatch(uploadLevelImage(file));
  };

  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.level_id ? 'Create Level' : 'Edit Level'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FormHeading>Name</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('name', {
                        required: 'Please enter name',
                      })}
                      name="name"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      placeholder="Name"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Level number</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('number', {
                        required: 'Please enter level number',
                      })}
                      name="number"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.number}
                      helperText={errors.number?.message}
                      placeholder="Level number"
                      type="number"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Gem Reward</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('gem_reward', {
                        required: 'Please enter gem reward',
                      })}
                      name="gem_reward"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.gem_reward}
                      helperText={errors.gem_reward?.message}
                      placeholder="Gem reward"
                      type="number"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FormHeading>Credit Reward</FormHeading>
                  <FieldItem>
                    <TextField
                      {...register('credit_reward', {
                        required: 'Please enter credit reward',
                      })}
                      name="credit_reward"
                      margin="dense"
                      variant="outlined"
                      error={!!errors.credit_reward}
                      helperText={errors.credit_reward?.message}
                      placeholder="Credit reward"
                      type="number"
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImagePicker
                        onFileLoad={onFileLoad}
                        imageUrl={
                          params.level_id ? selectedLevel.image_url : s3ImgUrl
                        }
                      />
                    </div>
                  </FieldItem>
                </FormControl>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isCreatingLevel || isuploadingLevelImg}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isCreatingLevel || isuploadingLevelImg ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
      <MessageBar
        type={
          isCreatingLevelSuccess || isUpdatingLevelSuccess ? 'success' : 'error'
        }
        open={openMessageBar}
        onClose={handleMessageClose}
        message={
          isCreatingLevelSuccess || isUpdatingLevelSuccess
            ? isUpdatingLevelSuccess
              ? 'Level Updated Successfully, Redirecting...'
              : 'Level Created Successfully, Redirecting...'
            : 'Error Occured'
        }
      />
    </Container>
  );
};

export default CreateNewLevelDialog;
