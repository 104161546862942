import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  InputField: {
    '&.MuiOutlinedInput': {
      height: '20px',
    },
  },
});

const autocompleteService = { current: null };

export default function Location({
  value,
  onChange,
  disabled,
  containerStyles = {},
  borderStyles = {},
  className,
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{
        width: { sm: '100%', xs: 'auto' },
        '& .MuiInputBase-root': containerStyles,
        '& .MuiOutlinedInput-notchedOutline': borderStyles,
      }}
      className={className}
      getOptionLabel={(option) =>
        (typeof option === 'string' ? option : option.description) || ''
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled || false}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          className={classes.InputField}
          placeholder="Enter your location"
          sx={{ borderTopRightRadius: 0 }}
          disabled={disabled || false}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option?.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
