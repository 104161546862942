import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Divider, Typography } from '@mui/material';
import OTPInput from 'react-otp-input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff,
} from '@mui/icons-material/';

import Loader from '../Loader';
import MessageBar from '../MessageBar';

import { withRouter } from '../../routes';
import { StyledForgotPassForm } from './StyledComponent';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectEmail, setIsCorrectEmail] = useState(true);
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [resendOtpTime, setResendOtpTime] = useState(0);
  const [step, setStep] = useState(1);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if (resendOtpTime > 0) {
        setResendOtpTime(resendOtpTime - 1);
      }
    }, 1000);
  }, []);

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const validate = (credentials) => {
    const errors = {};
    setErrors(errors);

    if (!credentials.email) {
      errors.email = 'Please enter email';
    } else if (
      !credentials.email.match(/^([a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,4}$)/i)
    ) {
      errors.email = 'Please enter a valid email';
    }
    return errors;
  };

  const sendOtp = (email) => {
    const data = { email };
    const { sendPasswordResetOtp } = props;

    const errors = validate(data);
    if (!Object.keys(errors).length) {
      (async () => {
        try {
          const response = await sendPasswordResetOtp(data);

          const resp = response.data;
          if (
            response.status === 200 &&
            (!resp?.success || resp.success !== false)
          ) {
            setStep(2);
            setResendOtpTime(60);
            setStatus('success');
          } else {
            setStatus('error');
            setErrors({
              errors: {
                email: 'Please try again after a while!',
              },
            });
          }
        } catch (err) {}
      })();
    } else {
      // Save validation errors in state
      setErrors(errors);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const credentials = {
      email,
    };

    if (step === 1) {
      sendOtp(email);
    } else {
      const errors = validate(credentials);
      if (!Object.keys(errors).length) {
        (async () => {
          setIsLoading(true);
          try {
            const resp = await props.setNewPassword({
              email,
              otp,
              new_password: newPassword,
            });
            if (resp.data?.message) {
              setMessage(resp.data?.message);
              props.navigate('/login');
            }
          } catch (err) {}
          setIsLoading(false);
        })();
      } else {
        // Save validation errors in state
        setErrors(errors);
        setIsCorrectEmail(false);
      }
    }
  };

  const { sendForgotPasswordStart, location } = props;

  return (
    <div className="login">
      <div className="login-heading">Recover Account</div>
      <Typography color="primary">
        To recover you account, please enter your details.
      </Typography>
      <Divider
        style={{ height: '3px', background: 'white', marginTop: '10px' }}
      />
      <div className="login-form">
        <p className="errors">{!isCorrectEmail ? errors.email : ''}</p>
        <StyledForgotPassForm onSubmit={onSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="text-field">
                <label>Email Address</label>
                <TextField
                  variant="outlined"
                  name="email"
                  type="email"
                  margin="dense"
                  className="text-input"
                  placeholder="Your e-mail address"
                  value={email}
                  onChange={handleChange}
                />
                <br />
              </div>
              {errors.email && <p className="error"> {errors.email}</p>}

              {step === 2 && (
                <>
                  <div className="text-field reset-password-conatiner">
                    <label>
                      <Typography color="primary" mb="5px">
                        Enter OTP (4 alphabets)
                      </Typography>
                    </label>
                    <div className="otp-input-container">
                      <OTPInput
                        value={otp}
                        onChange={(val) => setOtp(val)}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                        placeholder="XXXX"
                        inputStyle={{
                          border: '1px solid #25507B3D',
                          borderRadius: '8px',
                          width: '44px',
                          height: '44px',
                          fontSize: '18px',
                          color: 'black',
                          fontWeight: '400',
                          caretColor: 'black',
                          margin: '5px 5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        focusStyle={{
                          border: '1px solid #CFD3DB',
                          outline: 'none',
                        }}
                      />
                      <Button
                        variant="text"
                        size="small"
                        sx={{
                          fontSize: '12px !important',
                          '&:hover': {
                            background: 'white',
                            color: 'blue',
                          },
                          '&:disabled': {
                            color: 'gray',
                          },
                        }}
                        disabled={resendOtpTime > 0}
                        onClick={() => sendOtp(email)}
                      >
                        <Typography fontSize="12px">
                          {resendOtpTime > 0
                            ? `Resend Otp (${resendOtpTime})`
                            : 'Resend Otp'}
                        </Typography>
                      </Button>
                    </div>
                  </div>
                  {errors.otp && <p className="error"> {errors.otp}</p>}
                  <div className="text-field">
                    <label>Password</label>
                    <TextField
                      variant="outlined"
                      type={isPasswordVisible ? 'text' : 'password'}
                      placeholder="Enter your new password"
                      name="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                      margin="dense"
                      className="text-input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visiility"
                              onClick={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                              size="large"
                            >
                              {isPasswordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />
                  </div>
                  {errors.password && (
                    <p className="error"> {errors.password}</p>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              {' '}
              <Button
                variant="contained"
                className="btn-primary"
                fullWidth={true}
                type="submit"
                disabled={isLoading}
                color="primary"
              >
                {sendForgotPasswordStart ? (
                  <Loader size={24} color="inherit" />
                ) : step === 1 ? (
                  'Send OTP'
                ) : (
                  'Reset Password'
                )}
              </Button>
            </Grid>
            <Divider
              style={{
                marginTop: '10px',
                background: 'white',
                height: '3px',
              }}
            />
            <Grid item>
              <div className="login-link">
                <NavLink
                  to={{
                    pathname: `/login`,
                    search: location.search,
                  }}
                  state={{
                    background:
                      location && location.state && location.state.background,
                  }}
                >
                  Login to your account
                </NavLink>
              </div>
            </Grid>
          </Grid>
        </StyledForgotPassForm>
      </div>
      <MessageBar
        type={status}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={message}
      />
    </div>
  );
};

export default withRouter(ForgotPassword);
