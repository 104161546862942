import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Box } from '@mui/material';
import Close from '@mui/icons-material/Close';

const ImagesDialog = ({ openDialog, closeDialog, productImages }) => {
  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      PaperProps={{
        style: {
          padding: '0',
          minWidth: '50%',
          fontWeight: 'bold',
        },
      }}
    >
      <Grid container>
        <Grid item sm={6}>
          <DialogTitle>Product Images</DialogTitle>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-end">
            <DialogTitle>
              <Close onClick={closeDialog} />
            </DialogTitle>
          </Box>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container>
          {productImages.map((images, index) => (
            <Grid item sm={4} key={index}>
              <img src={images} width="100%" />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImagesDialog;
