import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import helpers from './../../lib/helpers';
import Loader from '../Loader';
import Tags from './../Tags';
import { Box, Grid } from '@mui/material';
import QuestionCard from '../MockTest/QuestionCard';
import HistoryEmptyState from './img/HistoryEmptyState.svg';
// import QuestionCard from '../QuestionCard/QuestionCard';

export default function YourQuestions({
  getAnsweredQuestions,
  getTag,
  tag,
  user,
  unlockSolution,
}) {
  const { questions: list, questionPaginationInfo, isFetchingQuestions } = user;
  const { getCategoryList } = helpers;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(
    questionPaginationInfo.currentPage + 1,
  );
  const [selectedTag, setSelectedTag] = useState('all');

  const [state, setState] = React.useState({
    checked: false,
  });

  useEffect(() => {
    getTag();
  }, [currentPage, rowsPerPage, selectedTag]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAnsweredQuestions({
      page: currentPage,
      page_size: rowsPerPage,
      tag_id: selectedTag === 'all' ? '' : selectedTag,
      bookmark: state.checked === true ? 'bookmarks' : '',
      domain_id: user.selected_domain,
    });
  }, [currentPage, rowsPerPage, selectedTag, state.checked]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleTagChange = (tag) => {
    setSelectedTag(tag ? tag.id : null);
  };

  const handleCheckbox = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  return (
    <div className="user-questions" style={{ padding: '0px 20px' }}>
      <Paper className="user-questions__card">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="tags filter">
                <label htmlFor="">Filter by Category</label>
                <Tags
                  placeholder="Select Category"
                  items={[
                    ...getCategoryList(tag.list, true),
                    { id: 'all', name: 'All' },
                  ]}
                  selectedTag={selectedTag}
                  onChange={handleTagChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="bookmark-check">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checked}
                      onChange={handleCheckbox('checked')}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="Bookmarked"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {isFetchingQuestions ? (
          <div className="questions-loader">
            <Loader size={44} color="primary" />
          </div>
        ) : (
          <div>
            {list.map(({ question, ...attempt }, index) => (
              <QuestionCard
                key={index}
                index={(currentPage - 1) * rowsPerPage + (index + 1)}
                question={question}
                attempt={attempt}
                unlockSolution={unlockSolution}
              />
              // <QuestionCard
              //   key={index}
              //   index={(currentPage - 1) * rowsPerPage + (index + 1)}
              //   question={question}
              //   attempt={attempt}
              //   unlockSolution={unlockSolution}
              // />
            ))}
          </div>
        )}
        {!isFetchingQuestions && list.length === 0 && (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <img
              src={HistoryEmptyState}
              alt=""
              height={'fit-content'}
              className="emptyState-img"
            />
            <span className="emptyStateHeading-one">
              You have not attempted any questions.
            </span>
            <br />
            <span className="emptyStateHeading-two">
              Start Attempting Questions and Learn More Every Day!
            </span>
          </Box>
        )}
        <TablePagination
          component="nav"
          page={questionPaginationInfo.currentPage}
          rowsPerPage={rowsPerPage}
          count={
            questionPaginationInfo.pageSize * questionPaginationInfo.totalPages
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ marginTop: '50px' }}
        />
      </Paper>
    </div>
  );
}
