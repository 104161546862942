// import axios from 'axios';
import * as types from './types';

// import config from './../config';

export function setHeaderText(text) {
  return {
    type: types.SAVE_HEADER_TEXT,
    text,
  };
}
