import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  putBookmark,
  getAnsweredQuestionById,
  postReportQuestion,
  unlockSolution,
} from './../actions/question';
import Component from './../components/UserProfile/QuestionCard';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      putBookmark,
      getAnsweredQuestionById,
      postReportQuestion,
      unlockSolution,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    tag: state.tag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
