import React from 'react';
import { AccordionDetails, Button, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import momemt from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Check, Clear } from '@mui/icons-material';
import {
  CommentDetails,
  CommentDetailsConcise,
  CommentLabel,
  CommentLabelValue,
  CommentLabelWrapper,
  CommentText,
  FlaggedCommentCard,
  GridLabel,
  MoreReportsViewer,
  QuestionTitle,
  QuestionWrapper,
  ReportAccordion,
  ReportCardAction,
  ReportDetails,
  ReportDetailsWrapper,
  ReportGridContainer,
  ReportGridItem,
  ReportsTitle,
  ReportSummary,
  ReportType,
  ReportUserAvatar,
  ReportUserDetails,
  Chips,
  BottomWrapper,
} from './StyledComponents';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const TextTruncator = ({ text }) => {
  const [windowSize, setWindowSize] = useState(() => window.innerWidth);
  const truncateTo = useMemo(() => {
    if (windowSize < 500) {
      return 20;
    } else if (windowSize < 1000) {
      return 40;
    } else if (windowSize < 1400) {
      return 80;
    } else {
      return 150;
    }
  }, [windowSize]);
  const [seeMore, setSeeMore] = useState(() => false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clickerStyles = {
    color: '#3572B0',
    padding: '5px 2px',
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSeeMore((oldValue) => !oldValue);
  };

  return (
    <div>
      {seeMore || text.length <= truncateTo
        ? text
        : `${text.slice(0, truncateTo)}... `}
      {text.length > truncateTo && (
        <div onClick={handleClick} style={clickerStyles}>
          {seeMore ? 'See less' : 'See more'}
        </div>
      )}
    </div>
  );
};

const ContentHider = ({ child }) => {
  const [seeMore, setSeeMore] = useState(() => false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSeeMore((oldValue) => !oldValue);
  };

  const divStyles = {
    height: seeMore ? 'auto' : '50px',
    overflow: 'hidden',
  };

  const buttonStyles = {
    padding: '5px 2px',
    marginTop: '5px',
    float: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#3572B0',
  };

  return (
    <>
      <div style={divStyles}>{child}</div>
      <button onClick={handleClick} style={buttonStyles}>
        {seeMore ? 'See Less' : 'See More'}
      </button>
    </>
  );
};

const CommentReportCard = ({
  questionAuthorUsername,
  questionAuthorPicture,
  commentId,
  commentStatus,
  commentAuthorUsername,
  commentAuthorPicture,
  commentCreatedOn,
  commentText,
  questionText,
  reports,
  upvotes,
  downvotes,
  takeAction,
  type,
  index,
}) => {
  const formatTime = (reportTime) =>
    momemt
      .utc(reportTime)
      .local()
      .format('D-MM-YYYY [@] hh:mm A');

  const formatTimeConcise = (reportTime) =>
    momemt
      .utc(reportTime)
      .local()
      .format('D-MM-YYYY');

  const parsedQuestionText = ReactHtmlParser(questionText);

  const [visibleReportsCount, setVisibleReportsCount] = useState(() => 3);

  const loadMoreReports = () => {
    if (visibleReportsCount >= reports.length) return;
    setVisibleReportsCount((oldValue) => oldValue + 3);
  };

  const handleActionButtonClick = (type) => {
    takeAction(type, commentId);
  };

  return (
    <FlaggedCommentCard>
      <QuestionWrapper>
        <QuestionTitle>
          Question:{index + 1}
          <ContentHider child={parsedQuestionText} />
        </QuestionTitle>
        <Divider flexItem />
        <ReportUserDetails>
          Author
          <Link to={`../../profile/${questionAuthorUsername}`}>
            <ReportUserAvatar src={questionAuthorPicture} />
            {questionAuthorUsername}
          </Link>
        </ReportUserDetails>
      </QuestionWrapper>
      <div>
        <div>
          Comment: <CommentText>{commentText}</CommentText>
        </div>
        <CommentDetails>
          <CommentLabelWrapper>
            <CommentLabel>By</CommentLabel>
            <CommentLabelValue>
              <ReportUserDetails>
                <Link to={`../../profile/${commentAuthorUsername}`}>
                  <ReportUserAvatar src={commentAuthorPicture} />
                  {commentAuthorUsername}
                </Link>
              </ReportUserDetails>
            </CommentLabelValue>
          </CommentLabelWrapper>
          <Divider orientation={'vertical'} flexItem />
          <Divider flexItem />
          <CommentLabelWrapper>
            <CommentLabel>Created on</CommentLabel>
            <CommentLabelValue>
              {formatTime(commentCreatedOn)}
            </CommentLabelValue>
          </CommentLabelWrapper>
          <Divider orientation={'vertical'} flexItem />
          <Divider flexItem />
          <CommentLabelWrapper>
            <CommentLabel>Votes</CommentLabel>
            <CommentLabelValue>
              +{upvotes}/-{downvotes}
            </CommentLabelValue>
          </CommentLabelWrapper>
        </CommentDetails>
        <CommentDetailsConcise>
          By{' '}
          <ReportUserDetails>
            <Link to={`../../profile/${commentAuthorUsername}`}>
              <ReportUserAvatar src={commentAuthorPicture} />
              {commentAuthorUsername}
            </Link>
          </ReportUserDetails>{' '}
          on {formatTimeConcise(commentCreatedOn)} (+{upvotes}/-{downvotes}{' '}
          Votes)
        </CommentDetailsConcise>
      </div>
      {type === 'reported' && (
        <>
          <ReportsTitle>Reports</ReportsTitle>
          {reports.slice(0, visibleReportsCount).map((report) => (
            <div key={report.id}>
              {report ? (
                <ReportAccordion>
                  <ReportSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ReportDetailsWrapper>
                      <ReportDetails>
                        <TextTruncator text={report.details} />
                      </ReportDetails>
                      <ReportType>{report.type}</ReportType>
                    </ReportDetailsWrapper>
                  </ReportSummary>
                  <AccordionDetails>
                    <ReportGridContainer container>
                      <GridLabel item xs={4} lg={2}>
                        Reported by
                      </GridLabel>
                      <ReportGridItem item xs={8} lg={10}>
                        <ReportUserDetails>
                          <Link to={`../../profile/${report.authorUsername}`}>
                            <ReportUserAvatar src={report.authorPicture} />
                            {report.authorUsername}
                          </Link>
                        </ReportUserDetails>
                      </ReportGridItem>
                      <GridLabel item xs={4} lg={2}>
                        Reported at
                      </GridLabel>
                      <ReportGridItem item xs={8} lg={10}>
                        {formatTime(report.createdAt)}
                      </ReportGridItem>
                    </ReportGridContainer>
                    <CommentDetailsConcise>
                      By{' '}
                      <ReportUserDetails>
                        <Link to={`../../profile/${report.authorUsername}`}>
                          <ReportUserAvatar src={report.authorPicture} />
                          {report.authorUsername}
                        </Link>
                      </ReportUserDetails>{' '}
                      on {formatTimeConcise(report.createdAt)}
                    </CommentDetailsConcise>
                  </AccordionDetails>
                </ReportAccordion>
              ) : (
                <></>
              )}
            </div>
          ))}
        </>
      )}
      {visibleReportsCount < reports.length && (
        <MoreReportsViewer onClick={loadMoreReports}>
          Load more reports...
        </MoreReportsViewer>
      )}
      <BottomWrapper>
        {type === 'all' &&
          (commentStatus === 'Accepted' ? (
            <Chips
              color="success"
              size="medium"
              variant="filled"
              label="Accepted"
            />
          ) : (
            <Chips
              color="error"
              size="medium"
              variant="filled"
              label="Rejected"
            />
          ))}
        <ReportCardAction>
          {type === 'reported' && (
            <Button
              endIcon={<Check />}
              onClick={() => handleActionButtonClick(1)}
            >
              Ignore
            </Button>
          )}
          {commentStatus === 'Rejected' ? (
            <Button
              endIcon={<Clear />}
              onClick={() => handleActionButtonClick(1)}
            >
              UnBlock
            </Button>
          ) : (
            <Button
              endIcon={<Clear />}
              onClick={() => handleActionButtonClick(2)}
            >
              Block
            </Button>
          )}
        </ReportCardAction>
      </BottomWrapper>
    </FlaggedCommentCard>
  );
};

export default CommentReportCard;
