import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LeaderboardTableBody,
  LeaderboardTableCell,
  LeaderboardTableRow,
  LeaderboardTableWrapper,
  RankCard,
  RankDisplay,
  LeaderboardAvatar,
  LeaderboardUserDetails,
  LeaderboardCategoryLevel,
  FollowButton,
  LeaderboardDisplayUsername,
  LeaderboardRewardsWrapper,
  LeaderboardRewardsValue,
  EmptyLeaderboard,
  EmptyLeaderboardImage,
  JumpToRankButton,
  HeadingWithSorting,
  SortingIcon,
} from './StyledComponents';
import { nanoid } from 'nanoid';
import FirstRank from '../../images/1stRank.svg';
import SecondRank from '../../images/2ndRank.svg';
import ThirdRank from '../../images/3rdRank.svg';
import { postFollow, postUnfollow } from '../../actions/publicProfile';
import { Tooltip } from '@mui/material';
import LeaderboardCoin from '../../images/LeaderboardCoin.svg';
import LeaderboardGem from '../../images/LeaderboardGem.svg';
import LeaderboardCertificate from '../../images/LeaderboardCertificate.svg';
import CircularProgress from '@mui/material/CircularProgress';
import EmptyLeaderboardIcon from '../../images/EmptyLeaderboard.svg';
import arrowdown from './svg/arrowdown.svg';
import arrowup from './svg/arrowup.svg';

const LeaderboardTable = ({
  usernameSearchQuery,
  handleGoToRankPage,
  selectedTag,
  handleSorting,
}) => {
  const [sortedField, setSortedField] = useState(() => '1');
  const [icon, setIcon] = useState(() => 'desc');
  const rankList = useSelector((state) => state.leaderboard.rankList);
  const currentUserId = useSelector((state) => state.user?.data?.id) || '';
  const leaderboardType = useSelector(
    (state) => state.leaderboard.leaderboardType,
  );
  const followedUserList = useSelector(
    (state) => state.publicProfile.followedUserList,
  );
  const rewardsList = useSelector(
    (state) => state.competitions?.details?.rewards_list,
  );
  const totalCoa = useSelector(
    (state) => state.competitions?.details?.total_coa,
  );
  const fetchingLeaderboard = useSelector(
    (state) => state.leaderboard.fetchingLeaderboard,
  );
  const paginationInfo = useSelector(
    (state) => state.leaderboard.paginationInfo,
  );
  const currentUserDetails = useSelector(
    (state) => state.leaderboard.currentUserDetails,
  );

  const rewards = useMemo(() => {
    let userRewards = [];
    rewardsList?.forEach((reward) => {
      const length = reward.rank_to - reward.rank_from + 1;
      const { gems, credits } = reward;
      userRewards = [
        ...userRewards,
        ...Array.from({ length }, () => ({
          gems,
          credits,
        })),
      ];
    });
    return userRewards;
  }, [rewardsList]);

  const columnSizes = useMemo(() => {
    if (leaderboardType === 'practice') {
      // name, country, score, accuracy, category level, follow button
      return [5, 2, 2, 2, 3, 3.5];
    } else if (leaderboardType === 'author') {
      // name, country, questions created, likes, views, bookmark, follow button
      return [5, 2, 3, 1, 2, 3, 3.5];
    } else {
      // name, country, score, accuracy, rewards, follow button
      return [6, 2, 2, 2, 5.5, 3.5];
    }
  }, [leaderboardType]);

  const sizeAdder = useCallback(
    (entries) =>
      entries.map((entry, index) => ({
        size: columnSizes[index],
        label: entry,
      })),
    [columnSizes],
  );

  const handleIcon = (currIndex) => {
    if (currIndex === 1) {
      setSortedField('1');
      setIcon((currType) => (currType === 'desc' ? 'asc' : 'desc'));
    }
    if (currIndex === 2) {
      setSortedField('2');
      setIcon((currType) => (currType === 'desc' ? 'asc' : 'desc'));
    }
    if (currIndex === 3) {
      setSortedField('3');
      setIcon((currType) => (currType === 'desc' ? 'asc' : 'desc'));
    }
    if (currIndex === 4) {
      setSortedField('4');
      setIcon((currType) => (currType === 'desc' ? 'asc' : 'desc'));
    }
  };

  const headers = useMemo(() => {
    const labels =
      leaderboardType === 'practice'
        ? [
            'Name',
            'Country',
            'Score',
            'Accuracy',
            selectedTag !== '' ? 'Category Level' : 'Domain Level',
            '',
          ]
        : leaderboardType === 'author'
        ? [
            'Name',
            'Country',
            <HeadingWithSorting
              key={1}
              onClick={() => {
                handleSorting(1);
                handleIcon(1);
              }}
            >
              Question Created
              <SortingIcon>
                {sortedField === '1' ? (
                  <img src={icon === 'asc' ? arrowup : arrowdown} width={10} />
                ) : (
                  <>
                    <img src={arrowup} height={10} width={10} />
                    <img src={arrowdown} width={10} />
                  </>
                )}
              </SortingIcon>
            </HeadingWithSorting>,
            <HeadingWithSorting
              key={2}
              onClick={() => {
                handleSorting(2);
                handleIcon(2);
              }}
            >
              Likes
              <SortingIcon>
                {sortedField === '2' ? (
                  <img src={icon === 'asc' ? arrowup : arrowdown} width={10} />
                ) : (
                  <>
                    <img src={arrowup} height={10} width={10} />
                    <img src={arrowdown} width={10} />
                  </>
                )}
              </SortingIcon>
            </HeadingWithSorting>,
            <HeadingWithSorting
              key={3}
              onClick={() => {
                handleSorting(3);
                handleIcon(3);
              }}
            >
              Views
              <SortingIcon>
                {sortedField === '3' ? (
                  <img src={icon === 'asc' ? arrowup : arrowdown} width={10} />
                ) : (
                  <>
                    <img src={arrowup} height={10} width={10} />
                    <img src={arrowdown} width={10} />
                  </>
                )}
              </SortingIcon>
            </HeadingWithSorting>,
            <HeadingWithSorting
              key={4}
              onClick={() => {
                handleSorting(4);
                handleIcon(4);
              }}
            >
              Bookmark
              <SortingIcon>
                {sortedField === '4' ? (
                  <img src={icon === 'asc' ? arrowup : arrowdown} width={10} />
                ) : (
                  <>
                    <img src={arrowup} height={10} width={10} />
                    <img src={arrowdown} width={10} />
                  </>
                )}
              </SortingIcon>
            </HeadingWithSorting>,
            '',
          ]
        : ['Name', 'Country', 'Score', 'Accuracy', 'Rewards', ''];
    return sizeAdder(labels);
  }, [leaderboardType, sizeAdder, selectedTag, sortedField, icon]);

  const dispatch = useDispatch();

  const handleFollowClick = (following, id) => {
    if (following) {
      dispatch(postUnfollow(id));
    } else {
      dispatch(postFollow(id));
    }
  };
  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  const rewardLister = ({ gems, credits }, certificate) => (
    <LeaderboardRewardsWrapper>
      <img src={LeaderboardCoin} alt="coin" />
      <LeaderboardRewardsValue>{credits}</LeaderboardRewardsValue>
      <img src={LeaderboardGem} alt="gem" />
      <LeaderboardRewardsValue>{gems}</LeaderboardRewardsValue>
      {certificate && <img src={LeaderboardCertificate} alt="certificate" />}
    </LeaderboardRewardsWrapper>
  );

  const rowGenerator = (keys) => {
    const { rank, entries, sticky, header, rankText, currentUser } = keys;
    return (
      <LeaderboardTableRow
        key={nanoid()}
        sticky={sticky || header ? 'true' : 'false'}
        emptyrewards={
          leaderboardType === 'competition' && rewards.length === 0 && 'true'
        }
        topstick={header ? 'true' : 'false'}
      >
        <LeaderboardTableCell size={1}>{rank}</LeaderboardTableCell>
        <RankCard
          size={20}
          header={header ? 'true' : 'false'}
          rank={rankText}
          sticky={sticky ? 'true' : 'false'}
          currentuser={currentUser ? 'true' : 'false'}
          emptyrewards={
            leaderboardType === 'competition' && rewards.length === 0 && 'true'
          }
        >
          {entries.map((entry) => (
            <LeaderboardTableCell key={nanoid()} size={entry.size}>
              {entry.label}
            </LeaderboardTableCell>
          ))}
        </RankCard>
      </LeaderboardTableRow>
    );
  };

  const entries = () => {
    const { currentPage, pageSize } = paginationInfo;

    const valueMapper = (rankEntry, index, sticky) => {
      const {
        rank: rankInResponse,
        user: {
          user_id: userId,
          country,
          first_name: firstName,
          last_name: lastName,
          picture,
          username,
        },
        score,
        percent_accuracy: accuracy,
        user_level: categoryLevel,
        total_bookmarks: bookmarks,
        total_questions_authored: questionsCreated,
        total_likes: likes,
        total_views: views,
      } = rankEntry;
      const rank =
        rankInResponse ||
        (usernameSearchQuery === ''
          ? pageSize * (currentPage - 1) + index + 1
          : index + 1);
      const userRank =
        (leaderboardType === 'author' && usernameSearchQuery !== '') ||
        rank > 3 ? (
          <RankDisplay>{rank}</RankDisplay>
        ) : rank === 1 ? (
          <img src={FirstRank} alt="first-rank" />
        ) : rank === 2 ? (
          <img src={SecondRank} alt="second-rank" />
        ) : (
          rank === 3 && <img src={ThirdRank} alt="third-rank" />
        );

      const name = (
        <Tooltip title={`${firstName} ${lastName}`}>
          <LeaderboardUserDetails
            to={`../../profile/${username}`}
            rank={(rank <= 3).toString()}
          >
            <LeaderboardAvatar src={picture} />
            <LeaderboardDisplayUsername>{`${firstName} ${lastName}`}</LeaderboardDisplayUsername>
          </LeaderboardUserDetails>
        </Tooltip>
      );

      const roundedScore = roundToTwo(score);
      const roundedAccuracy = `${roundToTwo(accuracy)}%`;
      const categoryLevelComponent = (
        <LeaderboardCategoryLevel type={rank <= 3 ? 'one' : 'two'}>
          {categoryLevel || 1}
        </LeaderboardCategoryLevel>
      );

      const rewardsComponent = rewards?.[index]
        ? rewardLister(rewards?.[index], index + 1 <= totalCoa)
        : '';

      const following = followedUserList?.indexOf(userId) !== -1;
      const followButton =
        userId === currentUserId ? (
          handleGoToRankPage &&
          sticky && (
            <JumpToRankButton
              onClick={() => handleGoToRankPage(rank)}
              variant="contained"
            >
              Go To Your Rank
            </JumpToRankButton>
          )
        ) : (
          <FollowButton
            following={following.toString()}
            type={rank <= 3 ? 'one' : 'two'}
            onClick={() => handleFollowClick(following, userId)}
            variant={following ? 'outlined' : 'contained'}
          >
            {following ? 'Unfollow' : 'Follow'}
          </FollowButton>
        );

      const currentEntries =
        leaderboardType === 'practice'
          ? [
              name,
              country,
              roundedScore,
              roundedAccuracy,
              categoryLevelComponent,
              followButton,
            ]
          : leaderboardType === 'author'
          ? [
              name,
              country,
              questionsCreated,
              likes,
              views,
              bookmarks,
              followButton,
            ]
          : [
              name,
              country,
              roundedScore,
              roundedAccuracy,
              rewardsComponent,
              followButton,
            ];

      const entriesWithSizes = sizeAdder(currentEntries);
      return rowGenerator({
        rank: userRank,
        entries: entriesWithSizes,
        header: false,
        rankText: rank,
        currentUser: currentUserId === userId,
        sticky,
      });
    };

    const rows = rankList.map((rankEntry, index) =>
      valueMapper(rankEntry, index, false),
    );
    const currentUserComponent =
      rankList?.slice(-1)?.[0].rank < currentUserDetails?.rank &&
      valueMapper(currentUserDetails, 0, true);
    const rankColumnHeader =
      leaderboardType === 'author' && usernameSearchQuery !== ''
        ? 'S No.'
        : 'Rank';
    return (
      <>
        {rowGenerator({
          rank: rankColumnHeader,
          entries: headers,
          header: true,
        })}
        {rows}
        {currentUserComponent}
      </>
    );
  };

  if (fetchingLeaderboard) {
    return (
      <LeaderboardTableWrapper>
        <CircularProgress
          sx={{
            margin: 'auto',
          }}
        />
      </LeaderboardTableWrapper>
    );
  }

  if (rankList?.length === 0) {
    return (
      <EmptyLeaderboard>
        <EmptyLeaderboardImage
          src={EmptyLeaderboardIcon}
          alt={'empty-leaderboard'}
        />
        <div>No Ranks to display</div>
      </EmptyLeaderboard>
    );
  }

  return (
    <LeaderboardTableWrapper>
      <LeaderboardTableBody>{entries()}</LeaderboardTableBody>
    </LeaderboardTableWrapper>
  );
};

export default LeaderboardTable;
