import axios from 'axios';
import * as types from './types';

import config from './../config';
import apiClient from '../api/apiclient';

const getNewVotingQuestionStart = () => ({
  type: types.GET_VOTING_QUESTION_START,
});

const getNewVotingQuestionSuccess = (data) => ({
  type: types.GET_VOTING_QUESTION_SUCCESS,
  payload: data,
});
const getNewVotingQuestionFail = (data) => ({
  type: types.GET_VOTING_QUESTION_FAIL,
  payload: data,
});

export const getNewVotingQuestion = () => {
  return (dispatch, getState) => {
    const { user, auth } = getState();
    const { selected_domain } = user;
    dispatch(getNewVotingQuestionStart());
    axios
      .get(
        `${config.API_URL}/domain/${selected_domain}/review/get_review_report`,
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((res) => {
        dispatch(getNewVotingQuestionSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getNewVotingQuestionFail(err.response.data));
      });
  };
};

const postVotingQuestionResponseSuccess = (data) => ({
  type: types.POST_VOTING_QUESTION_RESPONSE_SUCCESS,
  payload: data,
});

const postVotingQuestionResponseFail = (data) => ({
  type: types.POST_VOTING_QUESTION_RESPONSE_ERROR,
  payload: data,
});

export function postVotingQuestionResponse(report_id, review_type) {
  return (dispatch) => {
    apiClient
      .getReviewApi()
      .reviewApiReviewReportedQuestion({
        report_id: report_id,
        review_type: review_type,
      })
      .then((res) => {
        dispatch(
          postVotingQuestionResponseSuccess({ ...res.data, review_type }),
        );
      })
      .catch((err) => {
        dispatch(postVotingQuestionResponseFail(err.response.data));
      });
  };
}

const getAlreadyVotedQuestionSuccess = (data) => ({
  type: types.GET_HISTORY_VOTED_QUESTION_SUCCESS,
  payload: data,
});

export const getAlreadyVotedQuestion = (page = 1) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { selected_domain } = user;
    apiClient
      .getReviewApi()
      .reviewApiGetSelfReviewingHistory(
        selected_domain,
        page,
        undefined,
        undefined,
      )
      .then((res) => {
        dispatch(getAlreadyVotedQuestionSuccess(res.data));
      });
  };
};

const getReportedQuestionSuccess = (data) => ({
  type: types.GET_VOTING_REPORTED_QUESTION_SUCCESS,
  payload: data,
});

export const getReportedQuestions = (page = 1) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { selected_domain } = user;
    apiClient
      .getReviewApi()
      .reviewApiGetReportedQuestion(
        selected_domain,
        page,
        undefined,
        undefined,
        undefined,
      )
      .then((res) => {
        dispatch(getReportedQuestionSuccess(res.data));
      });
  };
};
