import {
  Button,
  Chip,
  Container,
  Typography,
  Grid,
  useMediaQuery,
  Hidden,
} from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import PlayAnswer from '../PlayAnswer';
import PlayAnswerStatusModal from '../PlayAnswer/PlayAnswerStatusModal';
import PlayAnswerDetails from '../PlayAnswerDetails';
import GetMoreTime from '../PlayProgress/GetMoreTimeDialog';
import SubcategorySelector from '../../containers/SubcategorySelector';
import Timer from '../Timer';
import ConsumeCreditModal from '../ConsumeCreditModal';
import ReportQuestionModal from './ReportQuestionModal';
import PlayDetailsMobile from './PlayDetailsMobile';
import ConfirmEndModal from './ConfirmEndModal';
import helpers from '../../lib/helpers';
import TimedoutModal from './TimedoutModal';
import { getErrorText } from './constants';
import PlayDetailsMobileBottom from './PlayDetailsMobileBottom';
import CollectBadgeModal from './CollectBadgeModal.jsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DiscussionPane from '../Discussion/DiscussionPane';
import {
  NegativeScore,
  PositiveScore,
  PlayArea,
  PlayError,
  StyleTopContainer,
  StyledPlay,
  StylePlayDetailsCard,
  StyledCustomBadges,
  StyledCustomGrid,
  StyledInfoTop,
  StyledSuccessButton,
  StyledDiscussionPannelBox,
  StyledTimerContainer,
  StyledTimerLeftContainer,
} from './PlayStyledComponent.jsx';
export const moreTimeCredits = 5;
export const unlockSolutionCredit = 5;
export const unlockHintCredit = 5;

const getScoreByCategoryId = (list = [], id) => {
  const category = list.find((item) => item.tag_id === id);
  return category ? category.score : 0;
};

function NoQuestions({
  examType,
  errorMessage,
  onSubCategoryChange,
  mockTest,
}) {
  const navigate = useNavigate();
  const path =
    examType === 'mockTest'
      ? {
          pathname: '/l/mockTest',
          search: '?tab=myTest',
          state: { mockTest },
        }
      : '/competition';

  if (examType === 'mockTest' || examType === 'competition')
    return (
      <PlayError>
        <Typography variant="body2" gutterBottom>
          {getErrorText(errorMessage)}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate(path)}
        >
          {examType === 'mockTest' ? 'View Result' : 'Back'}
        </Button>
      </PlayError>
    );

  return (
    <PlayError>
      <Typography variant="body2" gutterBottom>
        You do not have any more questions left in this category. Please choose
        another category
      </Typography>
      <Button
        variant="outlined"
        onClick={onSubCategoryChange}
        color="primary"
        type="button"
      >
        Change Subcategory
      </Button>
    </PlayError>
  );
}

function Play(props) {
  const timerRef = useRef();
  const MockTestTimerRef = useRef();

  const [quickOptionsCollapsed, setQuickOptionsCollapsed] = useState(true);
  // For inactivity measure
  const [isAttempting, setIsAttempting] = useState(() => false);
  const tabSwitchTime = useRef(null);
  const [userInactivityData, setUserInactivityData] = useState(() => []);
  const leavingTime = useRef(null);
  const { search, state } = useLocation();

  const [showCorrectAnswerOnSkip, setShowCorrectAnswerOnSkip] = useState(
    state?.showCorrectAnswerOnSkip,
  );
  const [purchaseTimeWithoutPopup, setPurchaseTimeWithoutPopup] = useState(
    state?.purchaseTimeWithoutPopup,
  );
  const [autoReveal, setAutoReveal] = useState(state?.autoReveal);
  const [autoUnlockSolution, setAutoUnlockSolution] = useState(
    state?.autoUnlockSolution,
  );
  const [isLevelUp, setIsLevelUp] = useState(false);

  const navigate = useNavigate();
  const {
    category,
    user,
    question,
    categoryList,
    tag,
    competition,
    mockTest,
    competitionAnalytics,
    getMockTestQuestionById,
    endMockTest,
    costData,
    getNextQuestion,
    timePeriod,
    categoryId,
    onBack,
    handleSubcategoryChangeDone,
    modalVisibility,
    setModalVisibility,
    isAnswerSubmitted,
    setIsAnswerSubmitted,
    isHintVisible,
    setIsHintVisible,
    isSolutionVisible,
    setIsSolutionVisible,
    answer,
    setAnswer,
    message,
    setMessage,
    answerStatus,
    setAnswerStatus,
    questionAttemptTimer,
    playAnswerRef,
    postAnswerWithAPI = () => {},
  } = props;

  const selectedCategoryId = question?.data.tag_id ?? null;
  const selectedCategory = tag.allTags.find(
    (tag) => tag.id === selectedCategoryId,
  );
  const isMobile = useMediaQuery('(max-width: 600px)');

  const isLaptop = useMediaQuery('(min-width: 900px)');
  const { analytics } = user;
  const [categoryScore, setCategoryScore] = useState(
    analytics.tags && question
      ? getScoreByCategoryId(analytics.tags, categoryId)
      : 0,
  );

  const [prevStreakCount, setPrevStreakCount] = useState(
    question?.answerStatus?.progress_obj?.current_streak_count ?? 0,
  );
  const [prevStreakTarget, setPrevStreakTarget] = useState(
    question?.answerStatus?.progress_obj?.current_streak_target ?? 5,
  );

  useEffect(() => {
    const onFocus = () => {
      if (tabSwitchTime.current && leavingTime.current) {
        const inactiveTime = Date.now() / 1000 - tabSwitchTime.current;
        setUserInactivityData((oldData) => [
          ...oldData,
          {
            tab_switch_seconds: inactiveTime,
            time_elapsed_seconds: leavingTime.current,
          },
        ]);
      }
    };

    const onBlur = () => {
      tabSwitchTime.current = Date.now() / 1000;
      leavingTime.current = timerRef.current?.state.duration;
    };

    if (isAttempting) {
      setUserInactivityData([]);
      leavingTime.current = -1;
      tabSwitchTime.current = Date.now() / 1000;
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
    }
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [isAttempting, question]);

  useEffect(() => {
    if (MockTestTimerRef.current) MockTestTimerRef.current.startTimer();
  }, [question.data?.id, mockTest.data?.id, MockTestTimerRef.current]);

  useEffect(() => {
    const questionId = new URLSearchParams(search).get('question');
    if (questionId) {
      setIsAnswerSubmitted(false);
      getMockTestQuestionById(categoryId, questionId);
    }
    // search should be there to fetch question when URL changes
  }, [search]);

  useEffect(() => {
    if (timerRef.current) {
      timerRef.current.startTimer();
      setIsAttempting(true);
    }
  }, [question.data.id]);

  useEffect(() => {
    if (question.isAnswerSuccess) {
      setCategoryScore(question?.categoryScore);
    }
    if (question?.answerStatus?.progress_obj) {
      if (
        !question?.answerStatus?.progress_obj?.is_streak_save_possible ||
        question?.answerStatus?.is_attempt_correct
      ) {
        setPrevStreakCount(
          question?.answerStatus?.progress_obj?.current_streak_count,
        );
        setPrevStreakTarget(
          question?.answerStatus?.progress_obj?.current_streak_target,
        );
      }
    }
  }, [question.data.id, question.answerStatus, question.isAnswerSuccess]);

  useEffect(() => {
    if (
      (question.isAnswerSuccess || question.isAnswerFail) &&
      timerRef.current
    ) {
      timerRef.current.stopTimer();
      setIsAttempting(false);
    }
  }, [question.isAnswerSuccess, question.isAnswerFail]);

  useEffect(() => {
    if (question.data.success === false)
      navigate(`/l/user/questions/${question?.data?.attempt_id}`);
  }, [question.data.success]);

  useEffect(() => {
    if (question.isAnswerSuccess) {
      if (timePeriod === 'mockTest') {
        setIsAnswerSubmitted(true);
        return;
      }
      const newAnswerStatus = {};
      if (question.isSkipped) {
        setIsAnswerSubmitted(true);
      } else if (question.isTimeout) {
        newAnswerStatus.type = 'timeOver';
        newAnswerStatus.message = 'Oops! Timed Out.';
      } else if (question.answerStatus.is_attempt_correct) {
        setIsAnswerSubmitted(true);
        newAnswerStatus.type = 'success';
        newAnswerStatus.message = 'Correct answer';
        // return;
      } else if (!question.answerStatus.is_attempt_correct) {
        newAnswerStatus.type = 'error';
        newAnswerStatus.message = 'Oops! Incorrect Answer.';
      }
      setAnswerStatus(newAnswerStatus);
      if (autoReveal) {
        if (question.answerStatus?.progress_obj?.is_streak_save_possible) {
          setModalVisibility((old) => ({
            ...old,
            answerStatusModal: true,
          }));
        } else {
          setIsAnswerSubmitted(true);
        }
      } else if (
        !autoReveal &&
        !showCorrectAnswerOnSkip &&
        question.isSkipped &&
        timePeriod !== 'mockTest'
      ) {
        return;
      } else {
        if (!question.isSkipped) {
          setModalVisibility((old) => ({
            ...old,
            answerStatusModal: true,
          }));
        }
      }
    } else if (question.isAnswerFail) {
      setAnswerStatus({
        type: 'error',
        message: 'Unable to submit answer',
      });
      if (
        question.error?.includes('time') ||
        question.error?.includes('no more attempts remaining')
      ) {
        if (timePeriod === 'mockTest') endMockTest(mockTest.data.id);
        else
          setModalVisibility((old) => ({
            ...old,
            timedoutModal: true,
          }));
      }

      setModalVisibility((old) => ({
        ...old,
        answerStatusModal: true,
      }));
      setIsAnswerSubmitted(false);
    }
  }, [
    timePeriod,
    question.answerStatus,
    question.isAnswerFail,
    question.isAnswerSuccess,
  ]);

  useEffect(() => {
    const newMessage = {};
    if (question.isPostReportQuestionSuccess) {
      newMessage.type = 'success';
      newMessage.content = 'Question has been reported successfully';
    } else if (question.isPostReportQuestionFail) {
      newMessage.type = 'error';
      newMessage.content =
        'Sorry! Unable to report question, please try again later';
    } else {
      return;
    }
    setMessage(newMessage);
    setModalVisibility((old) => ({
      ...old,
      reportModal: question.isPostReportQuestionFail,
    }));
  }, [question.isPostReportQuestionSuccess, question.isPostReportQuestionFail]);

  useEffect(() => {
    if (question.questionFetchFail) {
      let error_msg = null;
      if (question.error === 'no_more_attempts_remaining') {
        error_msg = 'No more questions left!';
      }
      setMessage({
        type: 'error',
        content: error_msg || 'Question Fetching failed',
      });
    }
  }, [question.questionFetchFail]);

  useEffect(() => {
    if (question.isUnlockQuestionSuccess) setIsSolutionVisible(true);
    else if (question.isUnlockQuestionFail)
      setMessage({
        type: 'error',
        content: 'Sorry! Unable to unlock solution, please try again later',
      });

    setModalVisibility((old) => ({
      ...old,
      unlockSolutionModal: false,
    }));
  }, [question.isUnlockQuestionSuccess, question.isUnlockQuestionFail]);

  useEffect(() => {
    if (
      question.answerStatus?.progress_obj?.badge_win_by_this_attempt?.length
    ) {
      setIsLevelUp(false);
      setModalVisibility((modalVisibility) => ({
        ...modalVisibility,
        collectBadgeModal: false,
      }));
    }
    if (
      question.answerStatus?.progress_obj?.tag_level?.new_level &&
      question.answerStatus?.progress_obj?.tag_level?.new_level !==
        question.answerStatus?.progress_obj?.tag_level?.old_level
    ) {
      setIsLevelUp(true);
      setModalVisibility((modalVisibility) => ({
        ...modalVisibility,
        collectBadgeModal: true,
      }));
    }
  }, [
    question.answerStatus,
    question.answerStatus?.progress_obj,
    question.answerStatus?.progress_obj?.badge_win_by_this_attempt,
  ]);

  useEffect(() => {
    if (question.isGettingHintSuccess) setIsHintVisible(true);
    else if (question.isGettingHintFail)
      setMessage({
        type: 'error',
        content: 'Sorry! Unable to get hint, please try again later',
      });

    setModalVisibility((old) => ({
      ...old,
      hintModal: false,
    }));
  }, [question.isGettingHintSuccess, question.isGettingHintFail]);

  useEffect(() => {
    if (
      category.preferencesSaveSuccess &&
      question.isAnswerSuccess &&
      timePeriod !== 'mockTest'
    )
      getNextQuestion();
  }, [timePeriod, categoryId, category.preferencesSaveSuccess]);

  useEffect(() => {
    if (question.isSkipped && !showCorrectAnswerOnSkip) {
      if (question.answerStatus?.progress_obj?.is_streak_save_possible) {
        setModalVisibility((old) => ({
          ...old,
          answerStatusModal: true,
        }));
      } else {
        getNextQuestion();
      }
    }
  }, [question.isSkipped]);

  const handleSubmit = (qid, ans) => {
    if (!question.isTimeOver && !question.isSubmittingAnswer) {
      setAnswer(ans);
      if (timePeriod === 'competition') {
        props.postAnswer(qid, {
          ...ans,
          time_taken: questionAttemptTimer,
          activity_data: userInactivityData,
        });
        return;
      }
      props.postAnswer(qid, { ...ans, time_taken: questionAttemptTimer });
    }
  };

  const handleSkip = () => {
    const { question, postAnswer } = props;
    if (question.isSubmittingAnswer) return;
    let dataAnswer = {
      question_type: question.data?.question_type,
      time_taken: questionAttemptTimer,
    };

    if (question.data.question_type === 'MultipleChoice') {
      dataAnswer = {
        ...dataAnswer,
        answer_option_no: null,
        question_skipped: true,
      };
    } else if (question.data.question_type === 'OneWord') {
      dataAnswer = {
        ...dataAnswer,
        answer_one_word: '',
        question_skipped: true,
      };
    }

    if (question.isTimeOver) {
      dataAnswer.timeout = true;
      dataAnswer.question_skipped = false;
    }

    if (timePeriod === 'competition') {
      postAnswer(question.data.id, {
        ...dataAnswer,
        activity_data: userInactivityData,
      });
      return;
    }

    postAnswer(question.data.id, dataAnswer);
  };

  useEffect(() => {
    if (
      question.isTimeOver &&
      !(question.isAnswerSuccess || question.isSubmittingAnswer)
    )
      handleSkip();
  }, [question.isTimeOver]);

  const isTimerShown =
    timePeriod === 'mockTest' ? mockTest.data.exam_mode === 'classic' : true;
  const isCompleted =
    timePeriod === 'mockTest'
      ? mockTest.data.is_completed
      : timePeriod === 'competition'
      ? helpers.isTimeGone(competition.end_date)
      : !question.data.id;
  const remTime = helpers.getRemainingTime(
    mockTest.data.started_on,
    mockTest.data.total_duration,
  );
  const remHrs = helpers.getHoursRemaining(remTime);
  const remMins = helpers.getMinutesRemaining(remTime);
  const timeRemaining =
    timePeriod === 'mockTest' && (remHrs > 0 || remMins > 0)
      ? `${remHrs > 0 ? remHrs + ' hours and' : ''} ${remMins} mins`
      : null;

  if (question.isQuestionFetching || question.isFetchingQuestionById)
    return <Loader size={44} color="primary" />;

  if (isCompleted)
    return (
      <>
        <NoQuestions
          mockTest={mockTest.data.id}
          examType={timePeriod}
          errorMessage={question.error}
          onSubCategoryChange={() =>
            setModalVisibility({
              ...modalVisibility,
              subcategorySelectorModal: true,
            })
          }
        />
        <SubcategorySelector
          visible={modalVisibility.subcategorySelectorModal}
          selectedCategory={categoryList?.find(
            (record) => record.id === categoryId,
          )}
          handleDone={handleSubcategoryChangeDone}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              subcategorySelectorModal: false,
            })
          }
        />
      </>
    );

  return (
    <PlayArea mb="40px">
      <StyleTopContainer>
        <Button
          color="secondary"
          className="back-button"
          startIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>

        {timePeriod === 'mockTest' && (
          <Hidden mdDown>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setModalVisibility({
                  ...modalVisibility,
                  confirmEndModal: true,
                })
              }
              sx={{ fontSize: '12px', backgroundColor: '#4caf50' }}
            >
              Complete Test
            </Button>
          </Hidden>
        )}

        {timePeriod === 'mockTest' && (
          <Hidden mdUp>
            <Typography>{mockTest?.data?.name || 'Mocktest Name'}</Typography>
          </Hidden>
        )}

        {timePeriod === 'mockTest' && mockTest?.data?.exam_mode === 'classic' && (
          <Hidden mdUp>
            <StyledSuccessButton
              variant="contained"
              color="primary"
              onClick={() =>
                setModalVisibility({
                  ...modalVisibility,
                  confirmEndModal: true,
                })
              }
            >
              Complete Test
            </StyledSuccessButton>
          </Hidden>
        )}

        {timePeriod !== 'mockTest' && timePeriod !== 'competition' && (
          <Hidden mdUp>
            <div className="">
              <StyledCustomBadges>
                <img
                  src={selectedCategory?.s3_url}
                  alt="cat-icon"
                  width={16}
                  height={16}
                />
                {selectedCategory && selectedCategory?.name}
              </StyledCustomBadges>
            </div>
          </Hidden>
        )}

        {timePeriod === 'competition' && (
          <Hidden mdUp>
            <Typography>{competition?.name || 'Competition Name'}</Typography>
          </Hidden>
        )}
      </StyleTopContainer>

      <StyledTimerContainer>
        <StyledTimerLeftContainer>
          {timePeriod === 'mockTest' &&
          mockTest?.data?.exam_mode === 'flexible' ? (
            <Timer
              lineProgressBar
              duration={helpers.getRemainingTime(
                mockTest.data.started_on,
                mockTest.data.total_duration,
              )}
              preferencesChange={props?.category?.preferencesSaveSuccess}
              ref={MockTestTimerRef}
              timeOver={() =>
                setModalVisibility({
                  ...modalVisibility,
                  timedoutModal: true,
                })
              }
              purchasedTime={0}
              maxTime={mockTest?.data?.total_duration}
            />
          ) : (
            <Timer
              lineProgressBar
              duration={
                Math.ceil(
                  question?.data?.max_time !== null
                    ? question?.data?.max_time - question?.data?.start_time
                    : question?.data?.parent_question?.max_time -
                        question?.data?.parent_question?.start_time ?? 0,
                ) || 0
              }
              preferencesChange={props?.category?.preferencesSaveSuccess}
              ref={timerRef}
              timeOver={props.setTimeOut}
              maxTime={
                question?.data?.max_time !== null
                  ? question?.data?.max_time
                  : question?.data?.parent_question?.max_time
              }
              purchasedTime={question.purchasedTime}
              isGetMoreTimePossible={
                isAnswerSubmitted ? false : timePeriod !== 'mockTest'
              }
              handleGetMoreTime={() =>
                !isAnswerSubmitted &&
                (purchaseTimeWithoutPopup
                  ? props.getMoreTime({
                      action_type: 'TimeIncrease',
                      question_id:
                        question?.data?.parent_question_id ||
                        question?.data?.id,
                      no_of_credits: costData?.TimeIncrease,
                    })
                  : setModalVisibility({
                      ...modalVisibility,
                      getMoreTimeModal: true,
                    }))
              }
            />
          )}
        </StyledTimerLeftContainer>
      </StyledTimerContainer>

      <Container maxWidth={false} style={{ padding: '0 10px' }}>
        {timePeriod === 'mockTest' || !isMobile ? null : (
          <StylePlayDetailsCard>
            <PlayDetailsMobile
              tag={tag}
              examType={timePeriod}
              question={question}
              preferences={category.stats?.preferences}
              competitionAnalytics={competitionAnalytics}
              categoryScore={categoryScore}
              handleSubcategoryChange={() =>
                setModalVisibility({
                  ...modalVisibility,
                  subcategorySelectorModal: !modalVisibility.subcategorySelectorModal,
                })
              }
              competition={competition}
            />
          </StylePlayDetailsCard>
        )}

        <StyledPlay>
          <div className="left">
            <StyledCustomGrid p="20px">
              <Hidden mdUp>
                {timePeriod === 'mockTest' && (
                  <StyledInfoTop>
                    <div className="">
                      <StyledCustomBadges
                        style={{
                          backgroundColor: '#25507B1A',
                        }}
                      >
                        <img
                          src={selectedCategory?.s3_url}
                          alt="cat-icon"
                          width={16}
                          height={16}
                        />
                        {selectedCategory && selectedCategory?.name}
                      </StyledCustomBadges>
                    </div>

                    <div className="">
                      <StyledCustomBadges
                        style={{
                          backgroundColor: '#25507B1A',
                          border: '1px solid gray',
                        }}
                      >
                        <PositiveScore>
                          +{question.data.weightage}
                        </PositiveScore>
                        /
                        <NegativeScore>
                          {(question.data.negative_score || 0).toFixed(2)}
                        </NegativeScore>{' '}
                        Points
                      </StyledCustomBadges>
                    </div>
                  </StyledInfoTop>
                )}
              </Hidden>

              <Hidden mdUp>
                {timePeriod !== 'mockTest' && (
                  <StyledInfoTop>
                    <div className="">
                      <StyledCustomBadges
                        style={{
                          backgroundColor: '#25507B1A',
                          padding: '2px 5px',
                          fontSize: '10px',
                        }}
                      >
                        {`Level ${question?.data?.difficulty_level}`}
                      </StyledCustomBadges>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '2px',
                        alignItems: 'center',
                      }}
                    >
                      <div className="">
                        <StyledCustomBadges
                          style={{
                            border: '1px solid #3572B0',
                            fontSize: '10px',
                            padding: '2px',
                          }}
                        >
                          {timePeriod === 'competition'
                            ? 'Total Score: '
                            : 'Category Score: '}
                          {timePeriod === 'competition'
                            ? `${competitionAnalytics?.user_score || 0}`
                            : `${categoryScore || 0}`}
                        </StyledCustomBadges>
                      </div>
                      <div className="">
                        <StyledCustomBadges
                          style={{
                            backgroundColor: '#25507B1A',
                            border: '1px solid gray',
                            fontSize: '10px',
                          }}
                        >
                          <PositiveScore>
                            +{question.data.weightage}
                          </PositiveScore>
                          /
                          <NegativeScore>
                            {(question.data.negative_score || 0).toFixed(2)}
                          </NegativeScore>{' '}
                          Points
                        </StyledCustomBadges>
                      </div>
                    </div>
                  </StyledInfoTop>
                )}
              </Hidden>

              {isTimerShown && !question?.error && (
                <Grid display="flex" justifyContent="space-between" gap="10px">
                  <Chip
                    label={`Level ${question?.data?.difficulty_level}`}
                    className="level-chip"
                    sx={{ display: !isLaptop ? 'none' : 'flex' }}
                  />
                  <Chip
                    label={
                      <Typography display={'flex'} gap="5px" fontWeight={'500'}>
                        <Typography color="#388E3C" fontWeight={'600'}>
                          +{question?.data?.weightage}
                        </Typography>
                        /
                        <Typography color="#D60505" fontWeight={'600'}>
                          {question?.data?.negative_score}
                        </Typography>
                        Points
                      </Typography>
                    }
                    color="primary"
                    variant="outlined"
                    sx={{
                      display: !isLaptop ? 'none' : 'flex',
                      background: 'rgba(53, 114, 176, 0.05)',
                    }}
                  />
                </Grid>
              )}
              {question?.error ? (
                <div>
                  <Typography variant="h6" color="primary">
                    {getErrorText(question?.error)}
                  </Typography>
                </div>
              ) : (
                <PlayAnswer
                  {...props}
                  ref={playAnswerRef}
                  categoryId={categoryId}
                  isAnswerSubmitted={isAnswerSubmitted}
                  isMockTest={timePeriod === 'mockTest'}
                  isSolutionVisible={isSolutionVisible}
                  isHintVisible={isHintVisible}
                  onHintClick={() =>
                    setModalVisibility({
                      ...modalVisibility,
                      hintModal: true,
                    })
                  }
                  onEndTestClick={() =>
                    setModalVisibility({
                      ...modalVisibility,
                      confirmEndModal: true,
                    })
                  }
                  onSubmit={handleSubmit}
                  onNext={getNextQuestion}
                  onSkip={handleSkip}
                  result={question.answerStatus}
                  onUnlockSolution={() =>
                    autoUnlockSolution
                      ? props.unlockSolution(question.data.id)
                      : setModalVisibility({
                          ...modalVisibility,
                          unlockSolutionModal: true,
                        })
                  }
                  onReportQuestionClick={() =>
                    setModalVisibility({
                      ...modalVisibility,
                      reportModal: true,
                    })
                  }
                />
              )}
            </StyledCustomGrid>
            {timePeriod === 'play' && isAnswerSubmitted && (
              <StyledDiscussionPannelBox mt="15px">
                <DiscussionPane
                  questionId={question.data.id}
                  viewLoadState
                  isDisabled={!isAnswerSubmitted}
                />
              </StyledDiscussionPannelBox>
            )}
          </div>
          {/* <div className="play--right">
           
          </div> */}
          <PlayAnswerDetails
            categories={categoryList}
            question={question}
            tag={tag}
            preferences={category.stats?.preferences}
            examType={timePeriod}
            mockTest={mockTest}
            competition={competition}
            competitionAnalytics={competitionAnalytics}
            postAnswerSilently={postAnswerWithAPI}
            onEndTestClick={() =>
              setModalVisibility({
                ...modalVisibility,
                confirmEndModal: true,
              })
            }
            categoryScore={categoryScore}
            handleSubcategoryVisibilityChange={() =>
              setModalVisibility({
                ...modalVisibility,
                subcategorySelectorModal: !modalVisibility.subcategorySelectorModal,
              })
            }
            setShowCorrectAnswerOnSkip={setShowCorrectAnswerOnSkip}
            setPurchaseTimeWithoutPopup={setPurchaseTimeWithoutPopup}
            setAutoReveal={setAutoReveal}
            setAutoUnlockSolution={setAutoUnlockSolution}
            showCorrectAnswerOnSkip={showCorrectAnswerOnSkip}
            purchaseTimeWithoutPopup={purchaseTimeWithoutPopup}
            autoReveal={autoReveal}
            autoUnlockSolution={autoUnlockSolution}
            openEndTestModal={() =>
              setModalVisibility({
                ...modalVisibility,
                confirmEndModal: true,
              })
            }
            competitionScore={competitionAnalytics.user_score}
            setQuickOptionsCollapsed={setQuickOptionsCollapsed}
            quickOptionsCollapsed={quickOptionsCollapsed}
          />
        </StyledPlay>
        <div className="play--mobile-bottom">
          <PlayDetailsMobileBottom
            categories={categoryList}
            question={question}
            tag={tag}
            preferences={category.stats?.preferences}
            examType={timePeriod}
            mockTest={mockTest}
            competitionAnalytics={competitionAnalytics}
            postAnswerSilently={postAnswerWithAPI}
            onEndTestClick={() =>
              setModalVisibility({
                ...modalVisibility,
                confirmEndModal: true,
              })
            }
            handleSubcategoryVisibilityChange={() =>
              setModalVisibility({
                ...modalVisibility,
                subcategorySelectorModal: !modalVisibility.subcategorySelectorModal,
              })
            }
          />
        </div>
        {/* Report question modal */}
        <ReportQuestionModal
          isAnswerStatusModalVisible={modalVisibility.answerStatusModal}
          selectedAnswer={answer}
          question={question}
          categories={categoryList}
          selectedCategoryId={categoryId}
          key={question.data.id}
          open={modalVisibility.reportModal}
          onClose={() =>
            setModalVisibility((currModalVisibility) => ({
              ...currModalVisibility,
              reportModal: false,
            }))
          }
          onSubmitClick={(reason) =>
            props.postReportQuestion(question.data.id, reason)
          }
        />

        {/* Answer status modals */}
        <PlayAnswerStatusModal
          {...props}
          question={question}
          type={answerStatus.type}
          open={modalVisibility.answerStatusModal}
          message={answerStatus.message}
          result={question.answerStatus}
          onYes={() => {
            setIsAnswerSubmitted(true);
            setModalVisibility({
              ...modalVisibility,
              answerStatusModal: false,
            });
          }}
          onClose={() => {
            setIsAnswerSubmitted(true);
            setModalVisibility({
              ...modalVisibility,
              answerStatusModal: false,
            });
          }}
          onSaveStreak={() => {
            setModalVisibility({
              ...modalVisibility,
              streakSaveModal: true,
            });
          }}
          prevStreakCount={prevStreakCount}
          prevStreakTarget={prevStreakTarget}
          onNext={() => {
            setIsAnswerSubmitted(true);
            setModalVisibility({
              ...modalVisibility,
              answerStatusModal: false,
            });
            getNextQuestion();
          }}
          autoReveal={autoReveal}
          setAutoReveal={setAutoReveal}
        />

        {/* Answer status toast */}
        <MessageBar
          type={message.type}
          open={message.type && message.content}
          message={message.content}
          onClose={() => setMessage({})}
        />

        {/* Streak save Modal */}
        <ConsumeCreditModal
          open={modalVisibility.streakSaveModal}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              streakSaveModal: false,
            })
          }
          title="Are you sure?"
          message="You are about to consume credits. Continue?"
          onYes={() => {
            setIsAnswerSubmitted(true);
            setModalVisibility({
              ...modalVisibility,
              streakSaveModal: false,
              answerStatusModal: false,
            });
            props.putSaveStreak({ categoryId });
          }}
        />

        {/* Unlock solution Modal */}
        <ConsumeCreditModal
          open={modalVisibility.unlockSolutionModal}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              unlockSolutionModal: false,
            })
          }
          title="Unlock Solution"
          credits={costData?.BuySolution}
          message="Ready to unlock the solution?"
          onYes={() => props.unlockSolution(question.data.id)}
        />

        {/* Unlock hint Modal */}
        <ConsumeCreditModal
          open={modalVisibility.hintModal}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              hintModal: false,
            })
          }
          title="Unlock Hint"
          credits={costData?.BuyHint}
          message="Ready to unlock the hint?"
          onYes={() => props.getHint(question.data.id)}
        />

        <ConfirmEndModal
          attemptedQuestions={question.examAnalytics?.statistics?.attempted}
          totalQuestions={question.examAnalytics?.questions?.length}
          timeLeft={timeRemaining}
          open={modalVisibility.confirmEndModal}
          handleClose={() =>
            setModalVisibility({
              ...modalVisibility,
              confirmEndModal: false,
            })
          }
          handleConfirm={() => postAnswerWithAPI(true)}
        />

        <TimedoutModal
          open={modalVisibility.timedoutModal}
          examType={timePeriod}
          handleClose={
            timePeriod === 'mockTest'
              ? () =>
                  navigate(
                    {
                      pathname: '/l/mockTest',
                      search: '?tab=myTest',
                    },
                    {
                      state: {
                        mockTest: mockTest.data.id,
                      },
                    },
                  )
              : onBack
          }
        />
        <CollectBadgeModal
          open={modalVisibility.collectBadgeModal}
          handleClose={() => {
            setModalVisibility((modalVisibility) => ({
              ...modalVisibility,
              collectBadgeModal: false,
            }));
          }}
          badgeInfo={
            question.answerStatus?.progress_obj?.badge_win_by_this_attempt?.[0]
          }
          streakCount={
            question.answerStatus?.progress_obj?.badge_win_by_this_attempt?.[0]
              ?.type === 'Streak'
              ? question.streakCount
              : question.streakDayCount
          }
          streakTarget={
            question.answerStatus?.progress_obj?.badge_win_by_this_attempt?.[0]
              ?.type === 'Streak'
              ? question.streakTarget
              : question.streakDayTarget
          }
          categoryName={
            categoryList?.find((record) => record.id === categoryId)?.name
          }
          isLevelUp={isLevelUp}
          nextLevel={question.answerStatus?.progress_obj?.tag_level?.new_level}
        />

        <GetMoreTime
          visible={modalVisibility.getMoreTimeModal}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              getMoreTimeModal: false,
            })
          }
          credits={costData?.TimeIncrease}
          onConfirm={() => {
            setModalVisibility({
              ...modalVisibility,
              getMoreTimeModal: false,
            });
            props.getMoreTime({
              action_type: 'TimeIncrease',
              question_id:
                question?.data?.parent_question_id || question?.data?.id,
              no_of_credits: costData?.TimeIncrease,
            });
          }}
        />
        <SubcategorySelector
          visible={modalVisibility.subcategorySelectorModal}
          selectedCategory={categoryList?.find(
            (record) => record.id === categoryId,
          )}
          handleDone={handleSubcategoryChangeDone}
          onClose={() =>
            setModalVisibility({
              ...modalVisibility,
              subcategorySelectorModal: false,
            })
          }
        />
      </Container>
    </PlayArea>
  );
}

export default Play;
