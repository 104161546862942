import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import ProfileIcon from './imgs/profile-icon.svg';
import ChangePasswordIcon from './imgs/change-password-icon.svg';
import LogoutIcon from './imgs/logout-icon.svg';
import {
  StyledAvatar,
  StyledMenu,
  StyledMenuItem,
  IconImage,
} from './StyledComponents';

export default function ProfileDropDown({ image, doLogout }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onProfileClick = () => {
    localStorage.setItem('mainUrl', window.location.pathname);
    navigate('/l/user/profile');
    handleClose();
  };

  const onChangePassword = () => {
    navigate('/l/user/password');
    handleClose();
  };

  const onLogoutClick = () => {
    doLogout();
  };

  const open = Boolean(anchorEl);

  const getIcon = (iconName) => <IconImage src={iconName} />;

  return (
    <Fragment>
      <StyledAvatar src={image} onClick={handleClick} />
      <StyledMenu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <StyledMenuItem value="profile" onClick={onProfileClick}>
          {/* <PersonOutlinedIcon
            className="dropdown-popover__menu__icon"
            color="primary"
          /> */}
          {getIcon(ProfileIcon)}
          Profile
        </StyledMenuItem>
        <StyledMenuItem
          value="changePassword"
          onClick={onChangePassword}
          className="dropdown-popover__menu"
        >
          {/* <LockOutlinedIcon
            className="dropdown-popover__menu__icon"
            color="primary"
          /> */}
          {getIcon(ChangePasswordIcon)}
          Change Password
        </StyledMenuItem>

        <StyledMenuItem
          value="logout"
          onClick={onLogoutClick}
          className="dropdown-popover__menu"
        >
          {/* <ExitToAppIcon
            className="dropdown-popover__menu__icon"
            color="primary"
          /> */}
          {getIcon(LogoutIcon)}
          Logout
        </StyledMenuItem>
      </StyledMenu>
    </Fragment>
  );
}
