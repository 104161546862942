import * as React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const RuleBox = ({ heading, points }) => (
  <Paper
    style={{
      background: 'rgba(53, 114, 176, 0.07)',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      padding: '15px',
      marginTop: '20px',
    }}
  >
    <Box display="flex" alignItems="center">
      <NotificationsIcon color="primary" />
      <Typography color="primary" style={{ marginLeft: '5px' }}>
        {heading}
      </Typography>
    </Box>
    <ul style={{ marginTop: '5px', marginLeft: '30px' }}>
      {points.map((point, idx) => (
        <li style={{ color: '#25507B' }} key={idx}>
          <Typography color="primary">{point}</Typography>
        </li>
      ))}
    </ul>
  </Paper>
);

export default RuleBox;
