import axios from 'axios';
import * as types from './types';

import { doLogout } from './auth';

import config from './../config';
import apiClient from '../api/apiclient.ts';

const getUserProfileStart = () => {
  return {
    type: types.GET_USER_PROFILE_START,
  };
};

const getUserProfileSuccess = ({ data }) => {
  return {
    type: types.GET_USER_PROFILE_SUCCESS,
    data: data,
  };
};

const getUserProfileFail = () => {
  return {
    type: types.GET_USER_PROFILE_FAIL,
  };
};

export const getUserProfile = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(getUserProfileStart());

      apiClient
        .getUserApi()
        .userApiGetUserSelf()
        .then((resp) => {
          dispatch(getUserProfileSuccess({ data: resp.data }));
          resolve(resp.data);
        })
        .catch((err) => {
          dispatch(getUserProfileFail());
          if (err.response.data.error === 'wrong_token') {
            dispatch(doLogout());
            reject();
          }
        });
    });
  };
};

const getUserAnalyticsStart = () => {
  return {
    type: types.GET_USER_ANALYTICS_START,
  };
};

const getUserAnalyticsSuccess = ({ data }) => {
  return {
    type: types.GET_USER_ANALYTICS_SUCCESS,
    data: data,
  };
};

const getUserAnalyticsFail = () => {
  return {
    type: types.GET_USER_ANALYTICS_FAIL,
  };
};

export const getUserAnalytics = () => {
  return (dispatch, getState) => {
    dispatch(getUserAnalyticsStart());
    const { user } = getState();
    apiClient
      .getUserApi()
      .userApiGetUserAnalytics(user.selected_domain)
      .then((resp) => {
        dispatch(getUserAnalyticsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserAnalyticsFail());
      });
  };
};

const putUserProfileStart = () => {
  return {
    type: types.PUT_USER_PROFILE_START,
  };
};

const putUserProfileSuccess = (data) => {
  return {
    type: types.PUT_USER_PROFILE_SUCCESS,
    data,
  };
};

const putUserProfileFail = () => {
  return {
    type: types.PUT_USER_PROFILE_FAIL,
  };
};

export const putUserProfile = ({ data }) => {
  return (dispatch, getState) => {
    dispatch(putUserProfileStart());

    const { auth } = getState();
    axios
      .put(`${config.API_URL}/user`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => {
        dispatch(putUserProfileSuccess(resp.data));
      })
      .catch(() => {
        dispatch(putUserProfileFail());
      });
  };
};

const resetPasswordStart = () => {
  return {
    type: types.RESET_PASSWORD_START,
  };
};

const resetPasswordSuccess = (data) => {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    data,
  };
};

const resetPasswordFail = () => {
  return {
    type: types.RESET_PASSWORD_FAIL,
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(resetPasswordStart());
    apiClient
      .getUserApi()
      .userApiChangeUserPassword(data)
      .then((resp) => {
        dispatch(resetPasswordSuccess(resp.data));
      })
      .catch(() => {
        dispatch(resetPasswordFail());
      });
  };
};

const getUserReferralsStart = () => {
  return {
    type: types.GET_USER_REFERRALS_START,
  };
};

const getUserReferralsSuccess = ({ data }) => {
  return {
    type: types.GET_USER_REFERRALS_SUCCESS,
    data: data,
  };
};

const getUserReferralsFail = () => {
  return {
    type: types.GET_USER_REFERRALS_FAIL,
  };
};

export const getUserReferrals = ({ page, page_size }) => {
  return (dispatch, getState) => {
    dispatch(getUserReferralsStart());
    const { auth } = getState();
    axios
      .get(`${config.API_URL}/user/referrals`, {
        params: { page, page_size },
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(getUserReferralsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserReferralsFail());
      });
  };
};

export function getDailyStatisticsStart() {
  return {
    type: types.GET_DAILY_STATISTICS_START,
  };
}

export function getDailyStatisticsSuccess({ data }) {
  return {
    type: types.GET_DAILY_STATISTICS_SUCCESS,
    data: data,
  };
}

export function getDailyStatisticsFail() {
  return {
    type: types.GET_DAILY_STATISTICS_FAIL,
  };
}

export function getDailyStatistics(data) {
  return (dispatch, getState) => {
    dispatch(getDailyStatisticsStart());

    const { auth } = getState();

    axios
      .post(`${config.API_URL}/score/daily_statistics`, data, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(getDailyStatisticsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getDailyStatisticsFail());
      });
  };
}

function getAllUsersStart() {
  return {
    type: types.GET_ALL_USERS_START,
  };
}

function getAllUsersSuccess({ data }) {
  return {
    type: types.GET_ALL_USERS_SUCCESS,
    data: data,
  };
}

function getAllUsersFail() {
  return {
    type: types.GET_ALL_USERS_FAIL,
  };
}

export function getAllUsers() {
  return (dispatch, getState) => {
    dispatch(getAllUsersStart());
    const { auth } = getState();
    axios
      .get(`${config.API_URL}/user`, {
        headers: { token: auth.token },
      })
      .then((resp) => {
        dispatch(getAllUsersSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAllUsersFail());
        dispatch(doLogout());
      });
  };
}

export function resetAvailability() {
  return {
    type: types.RESET_USERNAME_AVAILABILITY,
  };
}

function getUsernameAvailabilityStart() {
  return {
    type: types.GET_USERNAME_AVAILABILITY_START,
  };
}

function getUsernameAvailabilitySuccess({ data }) {
  return {
    type: types.GET_USERNAME_AVAILABILITY_SUCCESS,
    data: data,
  };
}

function getUsernameAvailabilityFail() {
  return {
    type: types.GET_USERNAME_AVAILABILITY_FAIL,
  };
}

export function getUsernameAvailability(username) {
  return (dispatch, getState) => {
    dispatch(getUsernameAvailabilityStart());
    const { auth } = getState();
    axios
      .get(
        `${config.API_URL}/user/username/${encodeURIComponent(
          username,
        )}/is_available`,
        {
          headers: { token: auth.token },
        },
      )
      .then((resp) => {
        dispatch(getUsernameAvailabilitySuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUsernameAvailabilityFail());
      });
  };
}

function createReferralsStart() {
  return {
    type: types.CREATE_REFERRALS_START,
  };
}

function createReferralsSuccess({ data, invited }) {
  return {
    type: types.CREATE_REFERRALS_SUCCESS,
    data,
    invited,
  };
}

function createReferralsFail() {
  return {
    type: types.CREATE_REFERRALS_FAIL,
  };
}

export function createReferrals(data, domainId) {
  return (dispatch, getState) => {
    dispatch(createReferralsStart());
    const { auth } = getState();

    axios
      .post(`${config.API_URL}/user/refer-by-emails`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
        params: {
          domain_id: domainId,
        },
      })
      .then((resp) => {
        dispatch(
          createReferralsSuccess({ data: resp.data, invited: data.length }),
        );
      })
      .catch(() => {
        dispatch(createReferralsFail());
      });
  };
}

export function resetReferrals() {
  return {
    type: types.RESET_REFERRALS,
  };
}

function sendOTPStart() {
  return {
    type: types.SEND_OTP_START,
  };
}

function sendOTPSuccess(data) {
  return {
    type: types.SEND_OTP_SUCCESS,
    data,
  };
}

function sendOTPFail(data) {
  return {
    type: types.SEND_OTP_FAIL,
    data,
  };
}

export function sendOTP(phone_no, entity_type, entity_id) {
  return (dispatch) => {
    dispatch(sendOTPStart());
    apiClient
      .getSmsApi()
      .smsApiGenerateSms({ phone_no, entity_id, entity_type })
      .then(() => {
        dispatch(sendOTPSuccess({ phone_no }));
      })
      .catch((ex) => {
        if (ex.response.data.error === 'number_already_exists_choose_another')
          dispatch(sendOTPFail('Number used by another user'));
        else if (ex.response.data.error === 'retry_limit_exceeded_for_today')
          dispatch(sendOTPFail('OTP send limit exceeded for today'));
        else if (
          ex.response.data.error === 'phone_no_already_verified_and_unchanged'
        )
          dispatch(sendOTPFail('Phone number already verified'));
        else dispatch(sendOTPFail('Failed to send OTP'));
      });
  };
}

function verifyOTPStart() {
  return {
    type: types.VERIFY_OTP_START,
  };
}

function verifyOTPSuccess() {
  return {
    type: types.VERIFY_OTP_SUCCESS,
  };
}

function verifyOTPFail(data) {
  return {
    type: types.VERIFY_OTP_FAIL,
    data,
  };
}

export function verifyOTP(otp, entity_type, entity_id) {
  return (dispatch) => {
    dispatch(verifyOTPStart());
    apiClient
      .getSmsApi()
      .smsApiValidateOtp({ otp, entity_type, entity_id })
      .then(() => {
        dispatch(verifyOTPSuccess());
      })
      .catch(() => {
        dispatch(verifyOTPFail('Invalid OTP'));
      });
  };
}

export function setOTPError(data) {
  return {
    type: types.SEND_OTP_FAIL,
    data,
  };
}

function sendMetadataStart() {
  return {
    type: types.SEND_METADATA_START,
  };
}

function sendMetadataSuccess(data) {
  return {
    type: types.SEND_METADATA_SUCCESS,
    data,
  };
}

function sendMetadataFail(data) {
  return {
    type: types.SEND_METADATA_FAIL,
    data,
  };
}

export function sendMetadata(data) {
  return (dispatch, getState) => {
    dispatch(sendMetadataStart());
    const { auth } = getState();

    axios
      .put(`${config.API_URL}/user/practise-metadata`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        dispatch(sendMetadataSuccess(res.data));
        localStorage.setItem('onboarding_done', true);
      })
      .catch(() => {
        dispatch(sendMetadataFail('Failed to Save'));
      });
  };
}

let collegeAutocompleteCancelToken = null;

export function sendCollegeAutocompleteQuery(query, callback) {
  return (dispatch, getState) => {
    const { auth } = getState();

    if (collegeAutocompleteCancelToken) {
      collegeAutocompleteCancelToken.cancel();
    }

    collegeAutocompleteCancelToken = axios.CancelToken.source();
    axios
      .get(
        `${config.API_URL}/user/college-autocomplete?query=${query}&page=1&page_size=20`,
        {
          cancelToken: collegeAutocompleteCancelToken.token,
          headers: {
            token: auth.token,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        callback(res.data.data);
      })
      .catch((ex) => {
        if (axios.isCancel(ex)) {
        } else {
        }
      });
  };
}

let degreeAutocompleteCancelToken = null;

export function sendDegreeAutocompleteQuery(query, callback) {
  return () => {
    if (degreeAutocompleteCancelToken) {
      degreeAutocompleteCancelToken.cancel();
    }

    degreeAutocompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getJobApi()
      .jobApiDegreeAutoComplete(undefined, 1, 20, query)
      .then((resp) => {
        callback(resp.data.data);
      })
      .catch(() => {});
  };
}

let specializationAutocompleteCancelToken = null;

export function sendSpecializationAutocompleteQuery(query, callback, id) {
  return () => {
    if (specializationAutocompleteCancelToken) {
      specializationAutocompleteCancelToken.cancel();
    }

    specializationAutocompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getJobApi()
      .jobApiSpecializationAutoComplete(id, 1, 20, query)
      .then((resp) => {
        callback(resp.data.data);
      })
      .catch(() => {});
  };
}

let companyAutoCompleteCancelToken = null;

export function sendCompanyAutocompleteQuery(query, callback) {
  return () => {
    if (companyAutoCompleteCancelToken !== null) {
      companyAutoCompleteCancelToken.cancel();
    }

    companyAutoCompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getUserApi()
      .userApiCompanyAutocomplete(1, 20, query)
      .then((res) => {
        callback(res.data.data);
      })
      .catch((ex) => {
        if (axios.isCancel(ex)) {
        } else {
        }
      });
  };
}

let examAutocompleteCancelToken = null;

export function sendExamAutocompleteQuery(query, callback) {
  return () => {
    // if already a request is in progress, cancel it
    if (examAutocompleteCancelToken) {
      examAutocompleteCancelToken.cancel();
    }

    examAutocompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getExamApi()
      .examApiExamAutoComplete(undefined, undefined, query)
      .then((res) => {
        callback(res.data.data);
      })
      .catch((ex) => {
        if (axios.isCancel(ex)) {
        } else {
        }
      });
  };
}

let jobRoleAutocompleteCancelToken = null;

export function sendJobRoleAutocompleteQuery(query, callback) {
  return () => {
    // if already a request is in progress, cancel it
    if (jobRoleAutocompleteCancelToken) {
      jobRoleAutocompleteCancelToken.cancel();
    }

    jobRoleAutocompleteCancelToken = axios.CancelToken.source();
    apiClient
      .getUserApi()
      .userApiJobRoleAutoComplete(undefined, undefined, query)
      .then((res) => {
        callback(res.data.data);
      })
      .catch((ex) => {
        if (axios.isCancel(ex)) {
        } else {
        }
      });
  };
}

function UploadResumeStart() {
  return {
    type: types.UPLOAD_RESUME_START,
  };
}

function UploadResumeSuccess(data) {
  return {
    type: types.UPLOAD_RESUME_SUCCESS,
    data,
  };
}

function UploadResumeFail(error) {
  return {
    type: types.UPLOAD_RESUME_FAIL,
    payload: error,
  };
}

export function uploadResume(data) {
  return (dispatch) => {
    dispatch(UploadResumeStart());
    apiClient
      .getFilesApi()
      .filesApiUploadResume(data)
      .then((res) => dispatch(UploadResumeSuccess(res.data)))
      .catch((err) => dispatch(UploadResumeFail(err)));
  };
}

function getResumeDetailsStart() {
  return {
    type: types.GET_RESUME_DETAILS_START,
  };
}

function getResumeDetailsSuccess(data) {
  return {
    type: types.GET_RESUME_DETAILS_SUCCESS,
    data,
  };
}

function getResumeDetailsFail(error) {
  return {
    type: types.GET_RESUME_DETAILS_FAIL,
    payload: error,
  };
}

export function getResumeDetails() {
  return (dispatch) => {
    dispatch(getResumeDetailsStart());
    apiClient
      .getUserApi()
      .userApiResumeDetails()
      .then((res) => dispatch(getResumeDetailsSuccess(res.data)))
      .catch((err) => dispatch(getResumeDetailsFail(err)));
  };
}

export function claimRewards({ credits = 0, gems = 0 }) {
  return (dispatch) => {
    dispatch({
      type: types.CLAIM_REWARDS,
      payload: {
        credits,
        gems,
      },
    });
  };
}

function UploadUserProfileImageStart() {
  return {
    type: types.UPLOAD_USER_PROFILE_PICTURE_START,
  };
}

function UploadUserProfileImageSuccess(data) {
  return {
    type: types.UPLOAD_USER_PROFILE_PICTURE_SUCCESS,
    data,
  };
}

function UploadUserProfileImageFail() {
  return {
    type: types.UPLOAD_USER_PROFILE_PICTURE_FAIL,
  };
}

export function UploadUserProfileImage(file) {
  return (dispatch) => {
    dispatch(UploadUserProfileImageStart());
    apiClient
      .getUserApi()
      .userApiUploadUserProfilePicture(file)
      .then((res) => {
        dispatch(UploadUserProfileImageSuccess(res.data));
        dispatch(
          putUserProfileData({ data: { picture: res?.data?.image_url } }),
        );
      })
      .catch(() => dispatch(UploadUserProfileImageFail()));
  };
}

function putUserProfileDataStart() {
  return {
    type: types.PUT_USER_PROFILE_DATA_START,
  };
}

function putUserProfileDataSuccess(data) {
  return {
    type: types.PUT_USER_PROFILE_DATA_SUCCESS,
    data,
  };
}

function putUserProfileDataFail() {
  return {
    type: types.PUT_USER_PROFILE_DATA_FAIL,
  };
}

export function putUserProfileData({ data }) {
  return (dispatch) => {
    dispatch(putUserProfileDataStart());
    apiClient
      .getUserApi()
      .userApiUpdateUserProfile(data)
      .then((res) => {
        dispatch(putUserProfileDataSuccess(res.data));
      })
      .catch(() => dispatch(putUserProfileDataFail()));
  };
}

const getUserLoginStreakStart = () => {
  return {
    type: types.GET_USER_LOGIN__STREAK_START,
  };
};

const getUserLoginStreakSuccess = ({ data }) => {
  return {
    type: types.GET_USER_LOGIN__STREAK_SUCCESS,
    data: data,
  };
};

const getUserLoginStreakFail = () => {
  return {
    type: types.GET_USER_LOGIN__STREAK_FAIL,
  };
};

export const getUserLoginStreak = () => {
  return (dispatch) => {
    dispatch(getUserLoginStreakStart());
    apiClient
      .getUserApi()
      .userApiGetUserLoginStreakDetails()
      .then((resp) => {
        dispatch(getUserLoginStreakSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserLoginStreakFail());
      });
  };
};

const getUserProfileMilestoneStart = () => {
  return {
    type: types.GET_USER_PROFILE_MILESTONE_START,
  };
};

const getUserProfileMilestoneSuccess = ({ data }) => {
  return {
    type: types.GET_USER_PROFILE_MILESTONE_SUCCESS,
    data: data,
  };
};

const getUserProfileMilestoneFail = () => {
  return {
    type: types.GET_USER_PROFILE_MILESTONE_FAIL,
  };
};

export const getUserProfileMilestone = () => {
  return (dispatch, getState) => {
    dispatch(getUserProfileMilestoneStart());
    const { auth } = getState();

    axios
      .get(`${config.API_URL}/users/profile-milestone`, {
        headers: {
          token: auth.token,
        },
      })
      .then((resp) => {
        dispatch(getUserProfileMilestoneSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserProfileMilestoneFail());
      });
  };
};

const putUserProfileUpdateStart = () => {
  return {
    type: types.PUT_USER_PROFILE_UPDATE_START,
  };
};

const putUserProfileUpdateSuccess = () => {
  return {
    type: types.PUT_USER_PROFILE_UPDATE_SUCCESS,
  };
};

const putUserProfileUpdateFail = (data) => {
  return {
    type: types.PUT_USER_PROFILE_UPDATE_FAIL,
    data: data,
  };
};

export const putUserProfileUpdate = (data) => {
  return (dispatch, getState) => {
    dispatch(putUserProfileUpdateStart());
    const { auth } = getState();

    axios
      .put(`${config.API_URL}/dashboard/profile-update`, data, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(putUserProfileUpdateSuccess());
        dispatch(getUserProfileMilestone());
        dispatch(getUserProfile());
      })
      .catch(() => {
        dispatch(putUserProfileUpdateFail());
      });
  };
};
const onboardingDoneStart = () => {
  return {
    type: types.ONBOARDING_DONE_START,
  };
};

const onboardingDoneSuccess = () => {
  return {
    type: types.ONBOARDING_DONE_SUCCESS,
  };
};

const onboardingDoneFail = (data) => {
  return {
    type: types.ONBOARDING_DONE_FAIL,
    data: data,
  };
};

export const onboardingDone = (data) => {
  return (dispatch) => {
    dispatch(onboardingDoneStart());

    apiClient
      .getUserApi()
      .userApiOnboardingDone(data)
      .then(() => {
        dispatch(onboardingDoneSuccess());
        window.location.reload();
      })
      .catch(() => {
        dispatch(onboardingDoneFail());
      });
  };
};

const getManagementAPIPendingStart = () => {
  return {
    type: types.GET_MANAGEMENT_API_PENDING_START,
  };
};

const getManagementAPIPendingSuccess = (data) => {
  return {
    type: types.GET_MANAGEMENT_API_PENDING_SUCCESS,
    data,
  };
};

const getManagementAPIPendingFail = (data) => {
  return {
    type: types.GET_MANAGEMENT_API_PENDING_FAIL,
    data: data,
  };
};

export const getManagementAPIPending = () => {
  return (dispatch) => {
    dispatch(getManagementAPIPendingStart());

    apiClient
      .getManagementAPI()
      .managementApiGetPendingEntitiesCounts()
      .then((resp) => {
        dispatch(getManagementAPIPendingSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getManagementAPIPendingFail());
      });
  };
};
