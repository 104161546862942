import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postFeedback, uploadFeedbackImage } from '../../actions/reportBug';
import reportSubmittedIcon from '../../images/reportSubmittedIcon.png';
import { CustomDialogBox } from '../DialogBox';
import './style.css';

const ReportFeature = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const allowedMediaTypes = [
    'jpg',
    'png',
    'jpeg',
    'mov',
    'mp4',
    'webm',
    'docx',
    'rtf',
  ];
  const [mediaTypeError, setMediaTypeError] = useState(false);
  const { feedbackImageUrl, isUploadingImage } = useSelector(
    (state) => state.reportBug,
  );

  useEffect(() => {
    setMediaArray([...mediaArray, feedbackImageUrl]);
  }, [feedbackImageUrl]);

  const handleCloseDialog = () => {
    setOpenSuccessDialog(false);
    reset({
      message: '',
    });
    setMediaArray([[]]);
  };

  const handleFormSubmit = (data) => {
    mediaArray.shift();
    data.media_url_list = mediaArray;
    data.feedback_type = 'FeatureRequest';
    dispatch(postFeedback(data));
    setOpenSuccessDialog(true);
  };
  const onChangeFunction = () => {
    setMediaTypeError(false);
    const media = imageRef.current?.files[0]?.name?.split('.');

    if (media) {
      const mediaType = media[media?.length - 1];
      if (!allowedMediaTypes.includes(mediaType)) {
        setMediaTypeError(true);
        return;
      }
    }
    dispatch(uploadFeedbackImage(imageRef.current.files[0]));
  };
  const removeInputFields = (data) => {
    const index = mediaArray.indexOf(data);
    const list = [...mediaArray];
    list.splice(index, 1);
    setMediaArray(list);
  };

  return (
    <Fragment>
      <Container>
        <Paper className="reportBug-card">
          <span className="heading-reportBug">
            Got new features suggestion? Help us implement it!
          </span>
          <Grid container style={{ marginTop: '30px' }}>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <label>Message</label>
              <TextField
                {...register('message', {
                  required: 'Please enter Message',
                })}
                minRows={6}
                multiline
                fullWidth
                placeholder="Describe your issue, let's solve it together..."
                variant="outlined"
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            </Grid>

            <Grid item sm={10} xs={12} style={{ margin: 'auto' }}>
              <label>
                Upload supporting images or videos <i>(optional)</i>{' '}
              </label>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Button
                variant="outlined"
                color="primary"
                component="label"
                type="button"
                fullWidth
                style={{ height: '40px' }}
                disabled={isUploadingImage}
              >
                {isUploadingImage ? 'Uploading...' : 'Upload'}
                <input
                  id="file-input"
                  type="file"
                  hidden
                  ref={imageRef}
                  onChange={onChangeFunction}
                />
              </Button>
            </Grid>
            {mediaTypeError && (
              <Typography variant="body" color="error">
                Only these formats are allowed
                {allowedMediaTypes.map((type, index) => (
                  <span key={type}>
                    {' ' +
                      type +
                      (index === allowedMediaTypes.length - 1 ? '.' : ' ,')}
                  </span>
                ))}
              </Typography>
            )}
            <Grid container spacing={3} style={{ margin: '20px 0px 30px 0px' }}>
              {mediaArray.slice(1)?.map((data, index) => (
                <Grid item sm={3} xs={12} key={index}>
                  <Fragment>
                    <Chip
                      style={{ width: '100%' }}
                      label={data?.split('_')[1]}
                      variant="outlined"
                      onDelete={() => removeInputFields(data)}
                      color="primary"
                    />
                  </Fragment>
                </Grid>
              ))}
            </Grid>

            <Grid container style={{ marginTop: '100px' }} spacing={1}>
              <Grid item sm={6} xs={12}>
                <Box display={'flex'} justifyContent="flex-start">
                  <Button
                    variant="outlined"
                    type="button"
                    className="report-actionBtn"
                    onClick={() => navigate(-1)}
                    disabled={isUploadingImage}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display={'flex'} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="report-actionBtn"
                    onClick={handleSubmit(handleFormSubmit)}
                    disabled={isUploadingImage}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {openSuccessDialog ? (
          <CustomDialogBox
            open={openSuccessDialog}
            onClose={handleCloseDialog}
            actions={
              <Button
                variant="contained"
                type="button"
                color="primary"
                onClick={handleCloseDialog}
                style={{ height: '40px', width: '150px' }}
              >
                Close
              </Button>
            }
            title={
              <Typography
                style={{
                  color: '#25507B',
                  fontSize: '34px',
                  fontWeight: 500,
                  lineHeight: '48px',
                }}
              >
                <img src={reportSubmittedIcon} /> Suggestion Submitted
              </Typography>
            }
            maxWidth={'md'}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '24px',
                lineHeight: '29px',
                fontWeight: 400,
                paddingLeft: '40px',
                textAlign: 'justify',
              }}
            >
              Thanks for submitting your suggestion! Your contribution helps
              make our platform a better experience for everyone. In case we
              need further clarification, our team will reach out to you.
            </Typography>
          </CustomDialogBox>
        ) : null}
      </Container>
    </Fragment>
  );
};

export default ReportFeature;
