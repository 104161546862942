import React, { useEffect } from 'react';
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import '../ReportBug/ReportedBugs/index.css';
import {
  deleteEmailNotification,
  getEmailNotification,
} from '../../actions/emailNotifications';
import { NavLink, useNavigate } from 'react-router-dom';
const AdminEmailPreference = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailNotificationsPreferencesData } = useSelector(
    (state) => state.EmailNotifications,
  );

  useEffect(() => {
    dispatch(getEmailNotification());
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteEmailNotification(id));
  };
  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem',
        }}
        className="collegeListContainer"
      >
        {' '}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Email Preferences</Typography>
          <div>
            <NavLink to="/l/admin/create/email-preference">
              <Button variant="outlined">Add Preference</Button>
            </NavLink>
          </div>
        </div>
        <>
          <div className="table-container">
            <Table
              style={{
                backgroundColor: 'white',
                maxWidth: '100%',
                overflow: 'auto',
              }}
            >
              <TableHead>
                {emailNotificationsPreferencesData?.notifications?.length ===
                0 ? (
                  <Typography variant="h6" style={{ textAlign: 'center' }}>
                    No Records Found
                  </Typography>
                ) : (
                  <TableRow style={{ minWidth: '40rem' }}>
                    <TableCell> Title</TableCell>

                    <TableCell style={{ padding: '4px', minWidth: '6rem' }}>
                      Name
                    </TableCell>
                    <TableCell style={{ padding: '4px', minWidth: '8rem' }}>
                      User Type
                    </TableCell>

                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {emailNotificationsPreferencesData?.notifications?.map(
                  (data, idx) => (
                    <TableRow key={idx} className="table-row-bugs">
                      <TableCell
                        style={{ padding: '1rem 4px', paddingLeft: '10px' }}
                      >
                        {data.title}
                      </TableCell>
                      <TableCell style={{ padding: '1rem 4px' }}>
                        {data.name}
                      </TableCell>
                      <TableCell style={{ padding: '1rem 4px' }}>
                        {data.user_type}
                      </TableCell>

                      <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                        <Edit
                          onClick={() => {
                            navigate({
                              pathname: `/l/admin/create/email-preference/${data.id}`,
                              search: `${data.id}`,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: '1rem 4px' }} width={'2rem'}>
                        <Delete onClick={() => handleDelete(data.id)} />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </div>
        </>
      </Container>
    </>
  );
};

export default AdminEmailPreference;
