import * as React from 'react';
import cx from 'classnames';
import { Button as MuiButton, darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.secondary.contrastText,

    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.contrastText,

    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
      opacity: 0.7,
    },

    '&:active': {
      opacity: 0.7,
    },
  },
}));

/**
 * Common button UI component, which is an extension of Mui Button
 * Refer: https://material-ui.com/components/buttons for usage and props
 * @component
 *
 * @param {Object} props - Material UI button props.
 * @param {Object} props.children - Text or any valid ReactNode.
 *
 * @example
 * return <Button variant="contained">Submit</Button>
 */
const Button = ({ children, color, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiButton
      {...rest}
      color={color}
      className={cx({
        [classes.success]: color === 'success',
        [classes.error]: color === 'error',
      })}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
