import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Accordion,
  AccordionActions,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import Bookmark from '@mui/icons-material/Bookmark';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Alert, AlertTitle } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';
import { getExamLabel } from '../../constants/examTypes';
import { reportReasons } from '../../constants/questioncard';
import helpers from '../../lib/helpers';
import { Button, SplitButton } from '../Button';
import ConsumeCreditModal from '../ConsumeCreditModal';
import LikeDislike from './../../containers/Like_DislikeContainer';
import './index.css';
import './style.scss';
import { withRouter } from '../../routes';
import ClearIcon from '@mui/icons-material/Clear';
import { nanoid } from 'nanoid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSelector } from 'react-redux';
import { ParentContainer } from '../PlayAnswer/PlayAnswerStyledComponent';

const QuestionCard = (props) => {
  const [setActive, setSetActive] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);
  const [isUnlockSolutionModalOpen, setIsUnlockSolutionModalOpen] = useState(
    false,
  );
  const [isBookmarked, setIsBookmarked] = useState(
    props?.attemptedQuestion?.bookmark || false,
  );
  const [subQuestion, setSubQuestion] = useState(0);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const { page, page_size, tag_id, getUnderReviewQuestions } = props;
    if (setActive) {
      getUnderReviewQuestions({
        page: page,
        page_size: page_size,
        tag_id: tag_id,
        question_status: 'Blocked',
        user_id: props.user_id,
      });
    }
  }, [props.page, props.page_size, props.tag_id]);

  const onBlockReasonSubmit = (status, reason) => {
    const question = { ...props.question };
    question.question_status = status;

    if (reason) {
      question.rejection_reason = reason;
    }
    if (question.user_data) {
      delete question.user_data;
    }
    props.putReportedQuestion({
      body: question,
      questionId: question?.id,
    });
    props.getQuestionIdOnClick(question?.id, 'blocked');
  };

  const getStatusChipColor = (question) => {
    if (question.question_status === 'Active') {
      return 'primary';
    }
    return 'default';
  };

  const changeIcon = () => {
    setIsBookmarked(!isBookmarked);
  };

  const renderReviewQuestionButtons = () => {
    const {
      question,
      onAccept,
      onBlock,
      isSolutionCollapse,
      handleDeleteAction,
      putBookmark,
      questionId,
      showBookmarkLikeDisLike,
      isQuestionSelected,
      attemptedQuestion,
    } = props;
    return (
      <AccordionActions style={{ padding: '10px 16px' }}>
        {showBookmarkLikeDisLike ? (
          <div style={{ width: '100%' }}>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <LikeDislike
                  id={question?.id}
                  liked={attemptedQuestion.is_liked}
                  disliked={attemptedQuestion.is_disliked}
                />
              </Grid>
              <Grid item>
                <Box display={'flex'} alignItems="center">
                  {!isBookmarked ? (
                    <BookmarkBorder
                      color="primary"
                      fontSize="42"
                      onClick={() => {
                        changeIcon();
                        putBookmark({
                          questionId: questionId,
                          body: { bookmark_status: true },
                        });
                      }}
                    />
                  ) : (
                    <Bookmark
                      color="primary"
                      onClick={() => {
                        changeIcon();
                        putBookmark({
                          questionId: questionId,
                          body: { bookmark_status: false },
                        });
                      }}
                    />
                  )}
                  <Typography variant="body2" color="primary">
                    Bookmark
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            {isSolutionCollapse && (
              <Grid container>
                <NavLink
                  to={`/l/question/${question?.id}`}
                  state={{
                    question: question,
                    tag: props.tag,
                  }}
                >
                  <Button
                    aria-label="view details"
                    color="primary"
                    variant="outlined"
                  >
                    View details
                  </Button>
                </NavLink>
              </Grid>
            )}
            <Grid container spacing={2} justifyContent="flex-end">
              {isQuestionSelected ? (
                <>
                  <Grid item style={{ paddingTop: '0' }}>
                    <NavLink to={`/l/question/edit/${question?.id}`}>
                      <Button
                        aria-label="edit"
                        color="primary"
                        variant="contained"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </NavLink>
                  </Grid>
                  {(question?.question_status === 'Blocked' ||
                    question?.question_status === 'UnderReview') && (
                    <>
                      <Grid item style={{ paddingTop: '0' }}>
                        <Button
                          aria-label="edit"
                          color="success"
                          variant="contained"
                          startIcon={<DoneIcon />}
                          onClick={() => onAccept(question?.id, 'single')}
                        >
                          Accept
                        </Button>
                      </Grid>
                      <Grid item style={{ paddingTop: '0' }}>
                        <Button
                          aria-label="block"
                          variant="contained"
                          startIcon={<ClearIcon />}
                          color="error"
                          onClick={() => onBlock(question?.id, 'single')}
                        >
                          Block
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {question?.question_status === 'Blocked' && (
                    <Grid item>
                      <NavLink to={`/l/question/edit/${question?.id}`}>
                        <Button
                          aria-label="edit"
                          color="primary"
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </NavLink>
                    </Grid>
                  )}
                  {question?.question_status === 'Draft' && (
                    <>
                      <Grid item>
                        <NavLink to={`/l/question/edit/${question?.id}`}>
                          <Button
                            aria-label="edit"
                            color="primary"
                            variant="contained"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </NavLink>
                      </Grid>
                      <Grid item>
                        <Button
                          aria-label="delete"
                          color="primary"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          style={{
                            backgroundColor: '#d50000',
                            border: '#d50000',
                          }}
                          onClick={() => handleDeleteAction(question?.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </div>
        )}
      </AccordionActions>
    );
  };

  const renderReportedQuestionReason = () => {
    const { question } = props;
    const { report_details } = question;
    const reason_names = Object.keys(report_details);
    const reasons = Object.values(report_details).map((item, key) => {
      return (
        `\n` +
        (key + 1) +
        `.` +
        reportReasons[reason_names[key]] +
        ' by ' +
        item.all_reporter_names.join(', ')
      );
    });

    return (
      <div className="reason-message">
        <Typography>{`Reported for reason: ${
          reasons && reasons.length ? reasons : 'Unknown'
        }`}</Typography>
      </div>
    );
  };

  const renderBlockedReason = (question) => {
    const { rejection_reason } = question;

    return (
      <>
        <Alert severity="error">
          <AlertTitle>
            This question has been blocked due to the following reasons:
          </AlertTitle>
          <strong>
            {rejection_reason ? rejection_reason : 'Unspecified reason'}
          </strong>
        </Alert>
      </>
    );
  };

  const renderReportedQuestionButtons = () => {
    const { question } = props;
    return (
      <div>
        {renderReportedQuestionReason()}
        <AccordionActions
          style={{ padding: '0 0', justifyContent: 'flex-start', gap: '10px' }}
        >
          <NavLink to={`/l/question/edit/${question?.id}`}>
            <Button
              aria-label="edit"
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </NavLink>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              props.putReportedQuestion({
                questionId: question?.id,
                body: {
                  ...question,
                  question_status: 'Active',
                  rejection_reason: '',
                },
              })
            }
          >
            Undo Report
          </Button>
          <SplitButton
            options={[
              'Quality of question not upto the mark',
              'Duplicate question',
              'Wrong Question',
              'Grammar or formatting issues',
              'Other',
            ]}
            onClick={(reason) => onBlockReasonSubmit('Blocked', reason)}
            type="danger"
          />
        </AccordionActions>
      </div>
    );
  };
  const changeBlockedToActive = async () => {
    const { question, putReviewQuestion } = props;

    putReviewQuestion({
      body: { ...question, question_status: 'Active' },
      questionId: question?.id,
    });
    setSetActive(true);
  };

  const handleCheckChange = () => {
    const newChecked = !isStagedToReview;
    if (newChecked === true) {
      props.handleSelectedQues(props.question?.id);
    } else {
      props.handleDeselectedQues(props.question?.id);
    }
    return newChecked;
  };

  const renderSolution = (question) => {
    if (!question?.solution_text)
      return (
        <div className="question-details-soluton">
          <Typography variant="subtitle2">Solution</Typography>
          <Typography variant="body2" gutterBottom color="default">
            No solution available
          </Typography>
        </div>
      );

    let is_solution_unlocked = true;
    if (props.attemptedQuestion)
      is_solution_unlocked = props.attemptedQuestion.is_solution_unlocked;

    return (
      <div className="question-details-soluton">
        <Typography variant="subtitle2">Solution</Typography>
        {is_solution_unlocked ? (
          <Typography variant="body2" gutterBottom>
            {ReactHtmlParser(question?.solution_text)}
          </Typography>
        ) : (
          <div className="solution-text-hidden">
            <Typography variant="body2" gutterBottom>
              {helpers.getRandomString(100)}
            </Typography>
            <div>
              <Typography variant="h6" component="p" color="primary">
                Unlock Solution
              </Typography>
              <IconButton
                onClick={() => setIsUnlockSolutionModalOpen(true)}
                size="large"
              >
                <LockOpenIcon />
              </IconButton>
            </div>
          </div>
        )}
        {question?.how_to_answer && (
          <>
            <Typography variant="subtitle2">How To Answer</Typography>
            <Typography variant="body2" gutterBottom>
              {question?.how_to_answer}
            </Typography>
          </>
        )}
      </div>
    );
  };

  const renderHint = (question, showHint) => {
    if (!question?.is_hint_available) return null;

    return (
      <div className="question-hint" style={{ marginTop: 30 }}>
        <p>Hint: </p>
        {showHint && question?.question_hint ? (
          <p>{ReactHtmlParser(question?.question_hint)}</p>
        ) : (
          <p>No hint available</p>
        )}
      </div>
    );
  };

  const renderQuestionInfo = (record) => {
    return (
      <>
        <Grid
          item
          container
          spacing={2}
          justifyContent="space-between"
          key={record.label}
        >
          <Grid item>
            <Typography variant="body2">{record.label}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {record.value}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const getCategory = () => {
    const { question, tag } = props;

    if (question?.tag) {
      if (question.tag.name) return question.tag;
      return { ...question.tag, name: question.tag.value };
    }

    return tag.allTags.find((t) => t.id === question?.tag_id) || {};
  };

  const getSubCategories = () => {
    let category = getCategory();
    const { question } = props;

    return category?.sub_tags?.filter((subTag) =>
      question.sub_tag_array.includes(subTag.id),
    );
  };

  const renderVotingBox = (reportId, reason) => {
    const { handleVoteQuestion } = props;
    const { reportDetails } = props;

    return (
      <>
        <section className="review-reason-box">
          <div>
            {reason ? (
              <h4 className="review-report-heading">
                Reason: <strong>{reason}</strong>
              </h4>
            ) : (
              <></>
            )}
            <p className="review-report-credit">
              Correct Vote Credits{' '}
              <strong className="review-report-strong">
                +{reportDetails?.credits_earned}
              </strong>
            </p>
            <h4 className="review-report-heading">Vote</h4>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              style={{
                background:
                  reportDetails?.review_type == 'Agree'
                    ? 'rgba(95, 183, 98, 0.19)'
                    : '#FFFFFF',
                border: '1px solid #5FB762',
                borderRadius: '4px',
                width: ' 109px',
                height: '22px',
                padding: '0.9rem',
                marginRight: '10px',
                color: '#5FB762',
              }}
              onClick={() => handleVoteQuestion(reportId, 'Agree')}
              disabled={reportDetails?.review_type ? true : false}
            >
              Agree
            </Button>
            <Button
              style={{
                background:
                  reportDetails?.review_type == 'Disagree'
                    ? 'rgba(224, 106, 106, 0.3)'
                    : '#FFFFFF',
                border: '1px solid #E06A6A',
                borderRadius: '4px',
                width: ' 109px',
                height: '22px',
                padding: '0.9rem',
                marginRight: '10px',
                color: '#E06A6A',
              }}
              onClick={() => handleVoteQuestion(reportId, 'Disagree')}
              disabled={reportDetails?.review_type ? true : false}
            >
              Disagree
            </Button>
            {reportDetails?.review_type ? (
              <p className="review-report-credit">
                Voted{' '}
                {moment(reportDetails?.created_at)
                  .startOf('day')
                  .fromNow()}
              </p>
            ) : (
              <a
                style={{
                  marginRight: '10px',
                  color: '#3572B0',
                  fontWeight: 400,
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => handleVoteQuestion(reportId, 'Skip')}
                disabled={reportDetails?.review_type ? true : false}
              >
                <p>Skip</p>
              </a>
            )}
          </div>
        </section>
      </>
    );
  };

  const {
    question,
    statistics,
    index,
    isQuestionSelected,
    isSolutionCollapse,
    isQuestionPublished,
    isVotingQuestion,
    unlockSolution,
    costData,
    isStagedToReview,
  } = props;

  let buttonsDom;
  if (question && question?.report_details && !isVotingQuestion) {
    buttonsDom = renderReportedQuestionButtons();
  } else if (question && question?.question_status) {
    buttonsDom = renderReviewQuestionButtons();
  }

  const examLabel = getExamLabel(question?.exam_type).label;

  let {
    question_type,
    score,
    difficulty_level,
    year,
    modified_on,
    created_at,
    max_time,
    total_likes,
    total_dislikes,
    user_data,
    full_report,
    sub_questions,
    question_source_url,
  } = question || {};

  const publishedQuestionInfo = [
    {
      label: 'Created By',
      value: question?.user_data
        ? user_data?.first_name + ' ' + user_data?.last_name
        : undefined,
    },
    {
      label: 'Created On',
      value: moment(question?.created_at).format('DD MMM YYYY'),
    },
    {
      label: 'Maximum Time',
      value: question?.max_time ? `${question?.max_time} sec` : 'No Limit',
    },
    {
      label: 'Exam',
      value: examLabel ? (
        <>
          {examLabel} {!!year && `(${year})`}
        </>
      ) : (
        undefined
      ),
    },
    {
      label: 'Question Attempts',
      value: question?.question_attempts ? question?.question_attempts : 0,
    },
    {
      label: 'Reporting Frequency',
      value:
        question?.report_details &&
        Object.keys(question?.report_details).length,
    },
    {
      label: 'Time Taken',
      value: props.timeTaken,
    },
  ];

  const copyToClipboard = (text) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  const QuestionWithCopy = ({ question_source_url }) => {
    const handleCopy = () => {
      copyToClipboard(question_source_url);
    };

    return (
      question_source_url && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>
              {question_source_url.length > 10
                ? question_source_url.substring(0, 20) + '...'
                : question_source_url}
            </Typography>
            <IconButton
              onClick={handleCopy}
              size="small"
              style={{ padding: 0 }}
            >
              <FileCopyIcon />
            </IconButton>
          </div>
        </>
      )
    );
  };

  const metaInfo = [
    {
      label: 'Score',
      value: score,
    },
    {
      label: 'Difficulty Level',
      value: difficulty_level,
    },
    {
      label: 'Question Type',
      value: question_type,
    },
    {
      label: 'Created By',
      value: question?.user_data
        ? user_data?.last_name
          ? user_data?.first_name + ' ' + user_data?.last_name
          : user_data?.first_name
        : undefined,
    },
    {
      label: 'Exam',
      value: examLabel ? (
        <>
          {examLabel} {!!year && `(${year})`}
        </>
      ) : (
        undefined
      ),
    },
    {
      label: 'Last Updated',
      value:
        modified_on || created_at ? (
          <>
            {moment(modified_on ? modified_on : created_at).format(
              'Do MMM YYYY',
            )}
          </>
        ) : (
          undefined
        ),
    },
    user.data?.user_type === 'Admin' &&
      question_source_url?.length > 0 &&
      !isQuestionSelected && {
        label: 'Reference',
        value: (
          <QuestionWithCopy
            question_source_url={question_source_url ? question_source_url : ''}
          />
        ),
      },
    {
      label: 'Max Time',
      value: max_time ? (
        <>{max_time ? `${max_time} sec` : 'No Limit'}</>
      ) : (
        undefined
      ),
    },
    {
      label: 'Likes',
      value: !isNaN(parseInt(total_likes, 10)) ? total_likes : undefined,
    },
    {
      label: 'Dislikes',
      value: !isNaN(parseInt(total_dislikes, 10)) ? total_dislikes : undefined,
    },
  ];
  const showQuestionStats = props.location?.state?.showQuestionStats;

  return (
    <ParentContainer>
      <Accordion className="question-list-item" expanded>
        <Grid container spacing={2}>
          <Grid
            item
            lg={props.hideStats ? 12 : 8}
            md={12}
            sm={12}
            xs={12}
            style={{
              flexGrow: 1,
              display: 'flex',
              padding: '20px 0px 0px 20px',
            }}
          >
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {question?.question_type === 'MultiPart' && (
                <Typography variant="subtitle1" color="primary">
                  Comprehension
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'flex-start',
                }}
              >
                {!isQuestionSelected ? null : (
                  <Checkbox
                    checked={isStagedToReview}
                    onChange={handleCheckChange}
                    color="primary"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: 8,
                      marginTop: '0.1rem',
                    }}
                  />
                )}
                {index && (
                  <Typography variant="subtitle1" style={{ lineHeight: '1.5' }}>
                    {index} .&nbsp;&nbsp;
                  </Typography>
                )}
              </div>
              <div className="question-details-text">
                <Box mb={2}>
                  {question?.question_status === 'Blocked' &&
                    renderBlockedReason(question)}
                </Box>
                {question?.title && (
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    gutterBottom
                    style={{ marginTop: '-16px', marginBottom: '30px' }}
                  >
                    {question?.title
                      ? ReactHtmlParser(question?.title)
                      : 'No question body available'}
                  </Typography>
                )}
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ flex: 1, lineHeight: '1.5' }}
                  gutterBottom
                >
                  {question?.question_text
                    ? ReactHtmlParser(question?.question_text)
                    : 'No question body available'}
                </Typography>
                {question?.question_type === 'MultiPart' && (
                  <>
                    <Typography variant="subtitle1" color="primary">
                      Question {subQuestion + 1}
                    </Typography>
                    <QuestionCard
                      getQuestionStats={props.getQuestionStats}
                      question={sub_questions && sub_questions[subQuestion]}
                      statistics={question?.statistics}
                      isStatsFetched={question?.isStatsFetched}
                      tag={props.tag}
                      tagList={props.tagList}
                      isSolutionCollapse={false}
                      isQuestionSelected={false}
                      isQuestionPublished={false}
                      showHint={props.showHint}
                      showSolution={props.showSolution}
                      unlockSolution={props.unlockSolution}
                      hideStats={true}
                    />
                    <div className="SubQuestionNavigator">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setSubQuestion(subQuestion - 1)}
                        disabled={subQuestion === 0}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSubQuestion(subQuestion + 1)}
                        disabled={
                          subQuestion === question?.sub_questions?.length - 1
                        }
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
                {['MultipleChoice', 'MultipleCorrect'].includes(
                  question?.question_type,
                ) ? (
                  <div>
                    {question?.options.map((option, index) => {
                      let optionLabel;
                      let optionClass;

                      if (
                        !props?.attemptedQuestion?.is_attempt_correct &&
                        props?.attemptedQuestion?.attempted_answer_option_no &&
                        option.option_serial_no ===
                          props?.attemptedQuestion?.attempted_answer_option_no.includes(
                            option.option_serial_no,
                          )
                      ) {
                        optionLabel = (
                          <Icon style={{ fontSize: 18 }}>clear</Icon>
                        );
                        optionClass = 'inactive modifiedInactive';
                      } else if (
                        question?.answer_option_no.includes(
                          option?.option_serial_no,
                        )
                      ) {
                        optionLabel = (
                          <Icon style={{ fontSize: 18 }}>check</Icon>
                        );
                        optionClass = 'active modifiedActive';
                      } else {
                        optionLabel = option.option_serial_no;
                      }

                      return (
                        <div
                          className={classnames('option', optionClass)}
                          key={index}
                        >
                          <div className="option-label">{optionLabel}</div>
                          <div className="option-text">
                            {ReactHtmlParser(option.option_text)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {question?.question_type === 'OneWord' ? (
                  <div>
                    <div className="option active">
                      <div className="option-label">
                        <Icon style={{ fontSize: 18 }}>check</Icon>
                      </div>
                      <div className="option-text">
                        {' '}
                        <Typography variant="body2" gutterBottom>
                          {question?.answer_one_word?.[0]}
                        </Typography>
                      </div>
                    </div>

                    {question?.answer_one_word?.length > 1 && (
                      <div>
                        <Typography variant="subtitle2">
                          Alternate Answers
                        </Typography>
                        {question.answer_one_word
                          ?.slice(1)
                          ?.map((alternateAnswer) => (
                            <Typography
                              variant="body2"
                              gutterBottom
                              key={nanoid()}
                            >
                              {alternateAnswer}
                            </Typography>
                          ))}
                      </div>
                    )}
                  </div>
                ) : null}

                {isSolutionCollapse ? (
                  <>
                    <Button
                      onClick={() => setIsSolutionVisible(!isSolutionVisible)}
                    >
                      {isSolutionVisible ? 'Hide' : 'View'} Solution
                    </Button>
                    {isSolutionVisible && renderSolution(question)}
                  </>
                ) : (
                  question?.question_type !== 'MultiPart' &&
                  renderSolution(question)
                )}
                {renderHint(question, props.showHint)}
                <ConsumeCreditModal
                  open={isUnlockSolutionModalOpen}
                  onClose={() => setIsUnlockSolutionModalOpen(false)}
                  title="Unlock Solution"
                  credits={costData?.BuySolution}
                  message="Ready to unlock the solution?"
                  onYes={() => {
                    setIsUnlockSolutionModalOpen(false);
                    unlockSolution(question?.id);
                  }}
                />
              </div>
            </div>
            {!props.hideStats && (
              <Divider
                orientation="vertical"
                className="question-card-divider"
              />
            )}
          </Grid>

          {!props.hideStats && (
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <div>
                <Chip
                  variant="outlined"
                  color="success"
                  size="small"
                  label={
                    getCategory()?.name?.length > 0
                      ? getCategory().name
                      : 'No Category'
                  }
                  style={{ marginBottom: '5px' }}
                />

                {question?.question_status &&
                question?.question_status === 'Blocked' ? (
                  <Tooltip
                    title="Click on chip to change question status to Active"
                    arrow
                  >
                    <Chip
                      onClick={() => {
                        changeBlockedToActive();
                      }}
                      variant="outlined"
                      color={getStatusChipColor(question)}
                      size="small"
                      label={question?.question_status}
                      style={
                        question?.question_status === 'Blocked'
                          ? {
                              color: 'red',
                              borderColor: 'red',
                              marginLeft: '8px',
                              marginBottom: '5px',
                            }
                          : { marginLeft: '8px', marginBottom: '5px' }
                      }
                    />
                  </Tooltip>
                ) : (
                  question?.question_status && (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={question?.question_status}
                      color={getStatusChipColor(question)}
                      style={
                        question?.question_status === 'UnderReview'
                          ? {
                              color: 'rgb(240,210,45)',
                              borderColor: 'rgb(240,210,45)',
                              marginLeft: '8px',
                              marginBottom: '5px',
                            }
                          : { marginLeft: '8px', marginBottom: '5px' }
                      }
                    />
                  )
                )}
              </div>

              {isQuestionPublished
                ? publishedQuestionInfo.map((record) => {
                    if (!record.value) return;
                    return renderQuestionInfo(record);
                  })
                : metaInfo.map((record) => {
                    if (!record.value) return;
                    return renderQuestionInfo(record);
                  })}

              <div className="question-details-info">
                <div className="difficulty" style={{ flexWrap: 'wrap' }}>
                  {getSubCategories()?.map((item) => {
                    return (
                      <Chip
                        key={item.id}
                        size="small"
                        label={item.name}
                        className="question-tag"
                      />
                    );
                  })}
                </div>
              </div>
              {user.data?.user_type === 'Admin' &&
                question_source_url?.length > 0 && (
                  <div className="question-hint">
                    <p>Reference : </p>
                    <p>{question_source_url}</p>
                  </div>
                )}
              {showQuestionStats && (
                <>
                  <div className="questionStatistics">
                    <div className="stat">
                      <div className="key">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ width: 'fit-content' }}
                        >
                          Attempted
                        </Typography>
                      </div>
                      <div className="val">
                        <div
                          className="progress"
                          style={{
                            backgroundColor: '#3572B0',
                            width: '100%',
                          }}
                        >
                          {statistics?.total_attempted
                            ? statistics?.total_attempted
                            : '0'}
                        </div>
                      </div>
                    </div>
                    <div className="stat">
                      <div className="key">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ width: 'fit-content' }}
                        >
                          Correct
                        </Typography>
                      </div>
                      <div className="val">
                        <div
                          className="progress"
                          style={{
                            backgroundColor: '#388E3C',
                            width: `${
                              (statistics?.total_correct /
                                statistics?.total_attempted) *
                                100 ===
                              0
                                ? '5'
                                : (statistics?.total_correct /
                                    statistics?.total_attempted) *
                                  100
                            }%`,
                          }}
                        >
                          {statistics?.total_correct
                            ? statistics?.total_correct
                            : '0'}
                        </div>
                      </div>
                    </div>
                    <div className="stat">
                      <div className="key">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ width: 'fit-content' }}
                        >
                          Incorrect
                        </Typography>
                      </div>
                      <div className="val">
                        <div
                          className="progress"
                          style={{
                            backgroundColor: '#D60505',
                            width: `${
                              (statistics?.total_incorrect /
                                statistics?.total_attempted) *
                                100 ===
                              0
                                ? '5'
                                : (statistics?.total_incorrect /
                                    statistics?.total_attempted) *
                                  100
                            }%`,
                          }}
                        >
                          {statistics?.total_incorrect
                            ? statistics?.total_incorrect
                            : '0'}
                        </div>
                      </div>
                    </div>
                    <div className="stat">
                      <div className="key">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ width: 'fit-content' }}
                        >
                          Timed Out
                        </Typography>
                      </div>
                      <div className="val">
                        <div
                          className="progress"
                          style={{
                            backgroundColor: '#EE9D00',
                            width: `${
                              (statistics?.total_timeout /
                                statistics?.total_attempted) *
                                100 ===
                              0
                                ? '5'
                                : (statistics?.total_timeout /
                                    statistics?.total_attempted) *
                                  100
                            }%`,
                          }}
                        >
                          {statistics?.total_timeout
                            ? statistics?.total_timeout
                            : '0'}
                        </div>
                      </div>
                    </div>
                    <div className="stat">
                      <div className="key">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          style={{ width: 'fit-content' }}
                        >
                          Skipped
                        </Typography>
                      </div>
                      <div className="val">
                        <div
                          className="progress"
                          style={{
                            backgroundColor: '#21A9DE',
                            width: `${
                              (statistics?.total_skipped /
                                statistics?.total_attempted) *
                                100 ===
                              0
                                ? '5'
                                : (statistics?.total_skipped /
                                    statistics?.total_attempted) *
                                  100
                            }%`,
                          }}
                        >
                          {statistics?.total_skipped
                            ? statistics?.total_skipped
                            : '0'}
                        </div>
                      </div>
                    </div>
                    <div className="qualityStat">
                      <div>
                        <div className="time">
                          {statistics?.avg_time_taken
                            ? statistics?.avg_time_taken
                            : 0}
                        </div>
                        Avg. Time
                      </div>
                      <div>
                        <div className="acc">
                          <CircularProgress
                            value={
                              statistics?.accuracy ? statistics?.accuracy : 0
                            }
                            size={60}
                            thickness={6}
                            color="primary"
                            variant="determinate"
                          />
                        </div>
                        Avg. Accuracy
                      </div>
                      <div>
                        <div className="diff">
                          {question?.difficulty_level
                            ? question?.difficulty_level
                            : 0}
                        </div>
                        Difficulty Level
                      </div>
                    </div>
                  </div>
                  <div className="reviewStats">
                    <div>
                      <Icon style={{ color: '#388E3C' }}>thumb_up</Icon>
                      {statistics?.likes ? statistics?.likes : '0'}
                    </div>
                    <div>
                      <Icon style={{ color: '#EE9D00' }}>thumb_down</Icon>
                      {statistics?.dislikes ? statistics?.dislikes : '0'}
                    </div>
                    <div>
                      <Icon style={{ color: '#3572B0' }}>bookmark</Icon>
                      {statistics?.bookmarks ? statistics?.bookmarks : '0'}
                    </div>
                    <div>
                      <Icon style={{ color: '#D60505' }}>report</Icon>
                      {statistics?.total_reports
                        ? statistics?.total_reports
                        : '0'}
                    </div>
                  </div>
                </>
              )}
            </Grid>
          )}
        </Grid>
        {isVotingQuestion ? (
          full_report ? (
            full_report?.map((item) => (
              <>
                <div>
                  <p>Details : {item.details}</p>
                </div>
                {renderVotingBox(item.id, item.reason_type)}
              </>
            ))
          ) : (
            renderVotingBox()
          )
        ) : (
          <></>
        )}
        {!props.hideStats && buttonsDom}
      </Accordion>
    </ParentContainer>
  );
};
export default withRouter(QuestionCard);

QuestionCard.defaultProps = {
  putQuestion: () => {},
};

QuestionCard.propTypes = {
  putQuestion: PropTypes.func,
};
