import React, { useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  addAdminSkillsDetails,
  updateAdminSkillsDetails,
} from '../../actions/dataManagement';
import { StyleDataManagement } from './StyledComponent';

const AddAdminSkillsDialog = ({
  open,
  handleClose,
  adminSkillId,
  adminSkill,
  searchTerm,
  status,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (adminSkill) {
      reset({
        name: adminSkill.name,
      });
    }
  }, [adminSkill]);

  const onDetailsSubmit = (data) => {
    if (!adminSkillId) {
      let skillArr = data.name?.split(',');
      const finalDataBody = {
        names: skillArr,
        status: data.approval_status,
      };
      dispatch(
        addAdminSkillsDetails(
          finalDataBody,
          searchTerm,
          page,
          rowsPerPage,
          status,
        ),
      );
    } else {
      const finalDataBody = {
        name: data.name,
        status: data.approval_status,
      };
      dispatch(
        updateAdminSkillsDetails(
          finalDataBody,
          adminSkillId,
          searchTerm,
          page,
          rowsPerPage,
          status,
        ),
      );
    }
    handleClose();
  };

  const defaultStatusVal = adminSkillId
    ? adminSkill?.approval_status === null ||
      adminSkill?.approval_status === 'Pending'
      ? 'Pending'
      : adminSkill?.approval_status === 'Rejected'
      ? 'Rejected'
      : 'Accepted'
    : 'Accepted';

  return (
    <Dialog className="dialog" onClose={handleClose} open={open} fullWidth>
      <StyleDataManagement>
        <Grid className="dialog-title-container">
          {adminSkillId ? (
            <Button className={'dialog-title'} color="primary">
              Edit Skill
            </Button>
          ) : (
            <Button className={'dialog-title'} color="primary">
              Add New Skill
            </Button>
          )}
        </Grid>
        <DialogContent className="dialog-content">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Grid container alignItems="center">
                <Grid item sm={3} xs={6}>
                  <label> Status</label>
                </Grid>
                <Grid item sm={9} xs={6}>
                  <FormControl fullWidth>
                    <Select
                      {...register('approval_status', {
                        required: 'Please select a status',
                      })}
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Job Role"
                      error={!!errors.approval_status}
                      defaultValue={defaultStatusVal}
                    >
                      <MenuItem value="Accepted" key="Accepted">
                        Accepted
                      </MenuItem>
                      <MenuItem value="Pending" key="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem value="Rejected" key="Rejected">
                        Rejected
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item sm={3} xs={6}>
                  <label> Name</label>
                </Grid>
                <Grid item sm={9} xs={6}>
                  <TextField
                    {...register('name', {
                      required: 'Please enter the name',
                    })}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={handleSubmit(onDetailsSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </StyleDataManagement>
    </Dialog>
  );
};

export default AddAdminSkillsDialog;
