import React from 'react';

import { StyledCard } from './StyledComponent';

export default function Card({ heading, content, footerContent }) {
  return (
    <StyledCard className="card">
      {heading ? <header className="card-heading">{heading}</header> : null}
      <div className="card-content">{content}</div>
      <div className="card-footer">{footerContent}</div>
    </StyledCard>
  );
}
