import { styled } from '@mui/styles';
import { Avatar } from '@mui/material';

export const StyledCard = styled('section')(({ theme }) => ({
  marginBottom: '20px',
  background: theme.palette.common.white,
  borderRadius: '4px',
}));

export const CardHeading = styled('header')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  background: theme.palette.common.white,
  textTransform: 'uppercase',
  padding: '15px',
  borderBottom: `1px solid ${theme.palette.secondary.color}`,
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
}));

export const CardContent = styled('div')({
  padding: '15px',
});

export const FormWrapper = styled('div')({
  width: '62%',
  margin: '0 auto',

  '@media (max-width: 600px)': {
    width: '100%',
  },
});

export const TagsForm = styled('div')({
  padding: '40px 0',
});

export const FormHeading = styled('label')(({ theme }) => ({
  display: 'block',
  marginBottom: '10px',
  color: theme.palette.component.color,
  fontWeight: '500',
}));

export const FormControl = styled('div')(({ theme }) => ({
  display: 'block',
  marginBottom: '20px',
  alignItems: 'center',
  color: theme.palette.component.color,
}));

export const FieldItem = styled('div')({
  flex: '1',
});

export const TagListHeader = styled('div')({
  fontSize: '18px',
  flex: '1',
});

export const TagAction = styled('div')({
  margin: '0',
  textAlign: 'right',
});

export const NoTag = styled('p')({
  marginTop: '10px',
});

export const TagListName = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TagListAvatar = styled(Avatar)({
  marginRight: '20px',
});
