import { styled } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';

export const StyleCardContainer = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '40px',
});
export const StyleCard = styled(Card)(({ theme }) => ({
  width: '350px',
  minWidth: '300px',
  boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor2}`,
  borderRadius: '10px',
}));

export const StyleMargin = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyleTypography = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StylePaper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const StyleDetails = styled('div')(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyleCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
}));

export const StyleMedia = styled(CardMedia)(({ theme }) => ({
  width: theme.typography.pxToRem(100),
  height: theme.typography.pxToRem(100),
  borderRadius: '50%',
  [theme.breakpoints.down('md')]: {
    width: theme.typography.pxToRem(100),
    height: theme.typography.pxToRem(100),
    margin: theme.spacing(1),
  },
}));

export const StyleCategoryChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  marginRight: 10,
  marginBottom: 10,
  color: theme.palette.secondary.dark,
  background: theme.palette.secondary.background,
  fontWeight: 500,

  [theme.breakpoints.down('md')]: {
    marginRight: 4,
    marginBottom: 4,
  },
}));

export const StyleCategoryContainer = styled(Box)({
  height: '68px',
  overflow: 'hidden',
});

export const StyleActions = styled('div')({});

export const StyleCompetitionName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: theme.palette.component.background,
  height: '38px',
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    fontWeight: 500,
  },
}));

export const StyleCompetitionDate = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '9px',
  letteSpacing: '0.4px',
  color: theme.palette.component.color,
}));

export const StyleShortDescription = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: theme.palette.component.color,
  overflow: 'hidden',
  height: '55px',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
}));

export const StyleRegisterBtn = styled(Box)(({ theme }) => ({
  height: '40px',
  width: '80%',
  background: theme.palette.primary.main,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '4px',
  color: 'white',
  marginTop: '40px',
  marginBottom: '10px',
}));

export const StyleToggleBox = styled(Box)(({ theme }) => ({
  height: '45px',
  width: '250px',
  background: 'white',
  borderRadius: '25px',
  boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
  margin: 'auto',
}));

export const StyleToggleText = styled(Typography)({
  fontSize: '18px',
  margin: 'auto',
  padding: '0px 20px 0px 20px',
  borderRadius: '25px',
  ['&:hover']: {
    cursor: 'pointer',
  },
});

export const StyleRegisteredBadge = styled(Typography)(({ theme }) => ({
  width: 'fit-content',
  marginLeft: 'auto',
  background: theme.palette.playColors.lightGreen1,
  border: `1px solid ${theme.palette.common.success}`,
  borderRadius: '25px 0px 10px 25px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '14px',
  color: theme.palette.common.success,
  padding: '8px 13px 8px 14px',
}));

export const StyleParticipatedBadge = styled(Typography)(({ theme }) => ({
  background: theme.palette.playColors.lightGreen1,
  border: `1px solid #{theme.palette.secondary.main}`,
  borderRadius: '25px 0px 10px 25px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '14px',
  color: theme.palette.secondary.main,
  padding: '8px 13px 8px 14px',
}));
