import axios from 'axios';
import * as types from './types';
import config from './../config';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../constants';

import apiClient from '../api/apiclient.ts';

export function autoLogin(preventLogout = false) {
  // Fetch token from local storage
  const isAdminMode = localStorage.getItem('isAdminMode');
  const secondaryToken = localStorage.getItem('secondaryToken');
  const token = JSON.parse(isAdminMode)
    ? secondaryToken
    : localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);

  if (token) {
    return (dispatch) => {
      dispatch(postLoginSuccess({ token }));
      return Promise.resolve();
    };
  } else {
    return (dispatch) => {
      if (!preventLogout) {
        dispatch(doLogout());
        return Promise.reject();
      }
    };
  }
}

export function postLoginStart() {
  return {
    type: types.LOGIN_START,
  };
}

export function postLoginSuccess({ token }) {
  // Save token in local storage
  const isAdminMode = localStorage.getItem('isAdminMode');
  isAdminMode
    ? localStorage.setItem('secondaryToken', token)
    : localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
  apiClient.setApiKey(token);

  return {
    type: types.LOGIN_SUCCESS,
    token,
  };
}

export function postLoginFail(data) {
  return {
    type: types.LOGIN_FAIL,
    data,
  };
}

export function postLogin(credentials) {
  return (dispatch) => {
    dispatch(postLoginStart());

    const params = Object.assign({}, credentials);
    apiClient
      .getTokenApi()
      .tokenApiGetToken(params)
      .then((resp) => {
        dispatch(postLoginSuccess({ token: resp.data.token }));

        return Promise.resolve();
      })
      .catch((ex) => {
        dispatch(postLoginFail(ex.response.data));
      });
  };
}

const forgotPasswordStart = () => {
  return {
    type: types.FORGOT_PASSWORD_START,
  };
};

const forgotPasswordSuccess = () => {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
  };
};

const forgotPasswordFail = () => {
  return {
    type: types.FORGOT_PASSWORD_FAIL,
  };
};

export function forgotPassword(email) {
  return (dispatch) => {
    dispatch(forgotPasswordStart());
    axios
      .get(`${config.API_URL}/user/send-link/?email=${email}`, {
        headers: {
          token: '',
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(forgotPasswordSuccess());
      })
      .catch(() => {
        dispatch(forgotPasswordFail());
      });
  };
}

const updatePasswordStart = () => {
  return {
    type: types.UPDATE_PASSWORD_START,
  };
};

const updatePasswordSuccess = () => {
  return {
    type: types.UPDATE_PASSWORD_SUCCESS,
  };
};

const updatePasswordFail = () => {
  return {
    type: types.UPDATE_PASSWORD_FAIL,
  };
};

export function updatePassword({ body }) {
  return (dispatch) => {
    dispatch(updatePasswordStart());
    const params = Object.assign({}, body);
    apiClient
      .getUserApi()
      .userApiSetNewPassword(params)
      .then(() => {
        dispatch(updatePasswordSuccess());
      })
      .catch(() => {
        dispatch(updatePasswordFail());
      });
  };
}

const postSignUpStart = () => {
  return {
    type: types.USER_SIGNUP_START,
  };
};

const postUserSuccess = ({ data }) => {
  return {
    type: types.USER_SUCCESS,
    data,
  };
};

const postUserFail = () => {
  return {
    type: types.USER_FAIL,
  };
};

export function postUser(credentials) {
  return (dispatch) => {
    dispatch(postSignUpStart());
    const data = Object.assign({}, credentials);
    apiClient
      .getUserApi()
      .userApiCreateUser(data)
      .then((response) => {
        dispatch(postUserSuccess({ data: response.data }));
        dispatch(
          postLogin({
            email: credentials.email,
            password: credentials.password,
          }),
        );
      })
      .catch(() => {
        dispatch(postUserFail());
      });
  };
}

export function sendEmailVerifyOtp(data) {
  return async () => {
    try {
      const resp = await axios.put(
        `${config.API_URL}/users/send-email-verify-otp`,
        data,
      );
      return resp;
    } catch (err) {
      return err;
    }
  };
}

export function verifyEmailOtp(data) {
  return async () => {
    try {
      const resp = await axios.put(
        `${config.API_URL}/users/email-verify-otp`,
        data,
      );
      return resp.data;
    } catch (err) {
      return err;
    }
  };
}

export function sendPasswordResetOtp(data) {
  return async () => {
    try {
      const resp = await axios.put(
        `${config.API_URL}/users/send-password-reset-otp`,
        data,
      );
      return resp;
    } catch (err) {
      return err;
    }
  };
}

export function setNewPassword(data) {
  return async () => {
    try {
      const resp = await axios.put(
        `${config.API_URL}/users/reset-password`,
        data,
      );
      return resp;
    } catch (err) {
      return err;
    }
  };
}

const getUserCountrySuccess = (country) => {
  return {
    type: types.GET_USER_COUNTRY_SUCCESS,
    country,
  };
};

const getUserCountryFail = () => {
  return {
    type: types.GET_USER_COUNTRY_FAIL,
  };
};

export function getUserCountry() {
  return (dispatch) => {
    fetch('https://extreme-ip-lookup.com/json/')
      .then((res) => res.json())
      .then((response) => {
        dispatch(getUserCountrySuccess(response.country));
      })
      .catch(() => {
        dispatch(getUserCountryFail());
      });
  };
}

export function doLogout() {
  // Clear token in local storage
  localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);

  return {
    type: types.USER_LOGOUT,
  };
}
