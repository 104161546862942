import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Switch,
  TextField,
} from '@mui/material';
import { MobileDateTimePicker as DateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
  getCompetitionById,
  getCompetitions,
  postCompetitionsSchedules,
} from '../../../actions/competition';
import { StyledForm } from './CompetitionScheduleStyledComponent';

const CreateSchedule = () => {
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [checkActive, setCheckActive] = useState(false);
  const [competition, setCompetition] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id: scheduleId } = useParams();
  const {
    schedules: { scheduled_competitions },
  } = useSelector((state) => state.competitionsSchedules);
  const {
    isPostingCompetitionsScheduleSuccess,
    isPostingCompetitionsScheduleFail,
  } = useSelector((state) => state.competitionsSchedules);
  const recurrenceDays = watch('recurrence_days');
  const validUntil = watch('valid_until');
  const isActive = watch('is_active');
  const maxLiveCount = watch('max_live_count');
  const competitionName = watch('competition_name');
  const nextCompetitionSerialNo = watch('next_competition_serial_no');

  useEffect(() => {
    if (scheduleId !== 'create') {
      const scheduleCompetition = scheduled_competitions?.find(
        (competition) => competition.id === scheduleId,
      );

      if (scheduleCompetition) {
        setValue('competition_id', scheduleCompetition.competition_id);
        setValue('recurrence_days', scheduleCompetition.recurrence_days);
        setValue(
          'valid_until',
          moment().format(scheduleCompetition.valid_until),
        );
        setValue('is_active', scheduleCompetition.is_active);
        setValue('max_live_count', scheduleCompetition.max_live_count);
        setValue('competition_name', scheduleCompetition.competition_name);
        setValue(
          'next_competition_serial_no',
          scheduleCompetition.next_competition_serial_no,
        );
        setCheckActive(scheduleCompetition.is_active);

        dispatch(getCompetitionById(scheduleCompetition.competition_id))
          .then((res) => setCompetition(res))
          .catch(() => {});
        setCompetition(scheduleCompetition.competition_id);
      }
    }
  }, [scheduled_competitions]);

  useEffect(() => {
    if (isPostingCompetitionsScheduleSuccess) {
      enqueueSnackbar('Competitions scheduled', { variant: 'success' });
      setTimeout(() => {
        navigate('/l/competition_schedule');
        setLoading(false);
      }, 3000);
    } else if (isPostingCompetitionsScheduleFail) {
      if (scheduleId !== 'create')
        enqueueSnackbar('Sorry! Unable to update competition schedule', {
          variant: 'error',
        });
      else
        enqueueSnackbar('Sorry! Unable to create competition schedule', {
          variant: 'error',
        });
      setLoading(false);
    }
  }, [isPostingCompetitionsScheduleSuccess, isPostingCompetitionsScheduleFail]);

  const handleSubmitData = () => {
    const data = {
      competition_id: competition?.id,
      recurrence_days: parseInt(recurrenceDays),
      valid_until: validUntil ? validUntil.split('T')[0] : null,
      is_active: isActive,
      max_live_count: parseInt(maxLiveCount),
      competition_name: competitionName,
      next_competition_serial_no: parseInt(nextCompetitionSerialNo),
    };
    dispatch(postCompetitionsSchedules(data, competition?.id));
  };
  return (
    <Container>
      <Box display="flex" py={2}>
        <Box flex="1" fontWeight="fontWeightRegular" fontSize={21}>
          Create Competition Schedule
        </Box>
      </Box>

      <Card elevation={0} square>
        <StyledForm action="" onSubmit={handleSubmit(handleSubmitData)}>
          <FormControl fullWidth margin="dense">
            <label htmlFor="" style={{ marginBottom: '0.8rem' }}>
              Competition
            </label>

            <AsyncSelect
              isDisabled={scheduleId !== 'create'}
              cacheOptions
              isClearable
              noOptionsMessage={() => 'Search Competition  to show options...'}
              placeholder={
                competition ? competition?.name : 'Search Competition ...'
              }
              loadOptions={(query, callback) => {
                dispatch(getCompetitions({ search_query: query }, callback));
              }}
              onChange={(option) => {
                setValue('competition_name', option.name);
                setCompetition(option);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <label htmlFor="">Recurrence Days</label>
            <TextField
              {...register('recurrence_days', {
                required: 'Please enter the recurrence days ',
                min: { value: 0, message: 'The value cannot be negative' },
              })}
              margin="dense"
              variant="outlined"
              type="number"
              error={!!errors.recurrence_days}
              helperText={errors.recurrence_days?.message}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <label>Valid Until</label>
            <Controller
              name="valid_until"
              control={control}
              defaultValue={null}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  margin="dense"
                  id="date-picker-dialog"
                  format="MM/DD/YYYY HH:mm"
                  value={value ? moment(value) : null}
                  showTodayButton
                  onChange={(date) => onChange(moment(date).toISOString(true))}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant="outlined"
                  clearable
                  // error={!!errors.reg_start_date}
                  placeholder="Select the Date and Time"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <label htmlFor="">Active</label>
            <Switch
              {...register('is_active')}
              checked={checkActive}
              onClick={() => {
                setCheckActive(!checkActive);
              }}
              margin="dense"
              variant="outlined"
              error={!!errors.is_active}
              helperText={errors.is_active?.message}
            />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <label htmlFor="">Max Live Count</label>
            <TextField
              {...register('max_live_count', {
                required: 'Please enter the max live count',
                min: { value: 0, message: 'The value cannot be negative' },
              })}
              margin="dense"
              variant="outlined"
              type="number"
              error={!!errors.max_live_count}
              helperText={errors.max_live_count?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <label htmlFor="">Competition Name </label>
            <TextField
              {...register('competition_name', {
                required: 'Please enter the competition name',
              })}
              margin="dense"
              variant="outlined"
              error={!!errors.competition_name}
              helperText={errors.competition_name?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <label htmlFor="">Next Competition Serial No</label>
            <TextField
              {...register('next_competition_serial_no', {
                min: { value: 0, message: 'The value cannot be negative' },
              })}
              margin="dense"
              variant="outlined"
              type="number"
              error={!!errors.next_competition_serial_no}
              helperText={errors.next_competition_serial_no?.message}
            />
          </FormControl>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: 'fitContent' }}
          >
            {loading ? <CircularProgress /> : 'Submit'}
          </Button>
        </StyledForm>
      </Card>
    </Container>
  );
};

export default CreateSchedule;
