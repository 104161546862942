import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const reportBug = createReducer(
  {
    feedbackImageUrl: '',
  },
  {
    [types.POST_FEEDBACK_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingFeedback: true,
      });

      return newState;
    },
    [types.POST_FEEDBACK_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingFeedback: false,
      });

      return newState;
    },
    [types.POST_FEEDBACK_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingFeedback: false,
      });

      return newState;
    },
    [types.UPLOAD_FEEDBACK_IMAGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: true,
      });

      return newState;
    },
    [types.UPLOAD_FEEDBACK_IMAGE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: false,
        feedbackImageUrl: action.data.s3_image_url,
      });

      return newState;
    },
    [types.UPLOAD_FEEDBACK_IMAGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: false,
      });

      return newState;
    },
  },
);

export const reportedBugs = createReducer(
  {
    feedbacks: [],
  },
  {
    [types.GET_REPORTED_BUGS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingBugsList: true,
      });

      return newState;
    },
    [types.GET_REPORTED_BUGS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        feedbacks: action.data,
        isFetchingBugsList: false,
      });

      return newState;
    },
    [types.GET_REPORTED_QUESTION_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingBugsList: false,
      });

      return newState;
    },
    [types.EDIT_REPORTED_BUG_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isEditBugStatus: true,
      });

      return newState;
    },
    [types.EDIT_REPORTED_BUG_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isEditBugStatus: false,
      });

      return newState;
    },
    [types.EDIT_REPORTED_BUG_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isEditBugStatus: false,
      });

      return newState;
    },
  },
);
