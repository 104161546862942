import { styled } from '@mui/styles';

export const StyledSegmentedProgressBar = styled('div')({
  maxWidth: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  margin: 0,

  '& .popup': {
    position: 'absolute',
    top: '-63px',
    minWidth: '170px',
    border: '1px solid rgb(169, 169, 169)',
    padding: '5px 10px 10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    zIndex: 100,

    p: {
      fontSize: '12px',
      lineHeight: '15px',
      marginTop: '5px',
    },
  },

  '& > svg': {
    height: '7px',
    borderRadius: '3.5px',
  },

  '&  .attempted-total': {
    border: '2px solid',
    borderRadius: '100%',
    width: '18px',
    height: '18px',
    padding: '5px',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    userSelect: 'none',
    fontWeight: 600,
  },

  '&  .progress-bar-container': {
    width: '100%',
    position: 'relative',

    '&  .RSPBprogressBar': {
      display: 'flex',

      '&  .RSPBstep': {
        width: '100%',
        marginRight: '1px',
      },

      '&  .step': {
        height: '5px',
        borderRadius: '15px',
        fontSize: '0px',
        position: 'relative',
      },

      '&  .RSPBprogression': {
        width: '0% !important',
      },
    },
  },
});
