import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import Component from './../components/SubcategorySelector';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    preferences: state.category.preferences,
    category: state.category,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
