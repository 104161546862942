import * as React from 'react';
import { Paper, Container, Icon } from '@mui/material';

import QuestionCard from '../QuestionCard/QuestionCard';
import './index.scss';
import {
  hintVisibility,
  solutionVisibility,
} from '../../constants/questioncard';
import { withRouter } from '../../routes';
import DiscussionPane from '../Discussion/DiscussionPane';
import { useDispatch } from 'react-redux';
import { resetQuestionData, resetQuestionbyId } from '../../actions/question';

const CreatedQuestionCard = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const { params, getQuestionById, getQuestionStats } = props;
    const questionId = params.questionId;

    getQuestionById(questionId);
    getQuestionStats(questionId);

    return () => {
      dispatch(resetQuestionData());
      dispatch(resetQuestionbyId());
    };
  }, []);

  const onBack = () => {
    localStorage.setItem('openCategoryModal', false);
    props.navigate(`/l/question`);
  };

  const handleDeleteClick = (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to remove this question?')) {
      const { deleteQuestion } = props;
      deleteQuestion({ questionId: id });
      props.navigate('/l/question');
    }
  };

  const { question, tag, unlockSolution } = props;
  const { params } = props;
  const questionId = params.questionId;
  return (
    <Container maxWidth={false}>
      <div
        onClick={onBack}
        style={{
          width: 'fit-content',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px 0px',
          fontSize: 17,
          color: '#25507B',
          cursor: 'pointer',
        }}
      >
        <Icon style={{ fontSize: 20, marginRight: '-5px' }}>
          arrow_back_ios
        </Icon>
        Back
      </div>
      <Paper className="user-wrapper" style={{ paddingBottom: '20px' }}>
        <div className="question-details">
          <div className="question-details-text">
            <QuestionCard
              getQuestionStats={props.getQuestionStats}
              putQuestion={props.putQuestion}
              question={question?.questionById}
              statistics={question.statistics}
              isStatsFetched={question.isStatsFetched}
              tag={tag}
              tagList={tag.list}
              isSolutionCollapse={false}
              isQuestionSelected={false}
              isQuestionPublished={false}
              showHint={hintVisibility.contribute}
              showSolution={solutionVisibility.contribute}
              unlockSolution={unlockSolution}
              handleDeleteAction={handleDeleteClick}
              history={props.history}
            />
          </div>
        </div>
      </Paper>
      <DiscussionPane questionId={questionId} />
    </Container>
  );
};

export default withRouter(CreatedQuestionCard);
