import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const levels = createReducer(
  {
    levelData: [],
    s3ImgUrl: '',
  },
  {
    [types.GET_LEVELS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingLevelList: true,
        s3ImgUrl: '',
      });

      return newState;
    },
    [types.GET_LEVELS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        levelData: action.data,
        isFetchingLevelList: false,
      });

      return newState;
    },
    [types.GET_LEVELS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingLevelList: false,
      });

      return newState;
    },
    [types.POST_LEVELS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingLevel: true,
      });

      return newState;
    },
    [types.POST_LEVELS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingLevel: false,
        isCreatingLevelSuccess: true,
        isUpdatingLevelSuccess: false,
      });

      return newState;
    },
    [types.POST_LEVELS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCreatingLevel: false,
      });

      return newState;
    },

    [types.POST_LEVEL_IMAGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingLevelImg: true,
      });

      return newState;
    },
    [types.POST_LEVEL_IMAGE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingLevelImg: false,
        s3ImgUrl: action.data.s3_image_url,
      });

      return newState;
    },
    [types.POST_LEVEL_IMAGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isuploadingLevelImg: false,
      });

      return newState;
    },

    [types.UPDATE_LEVEL_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingLevel: true,
      });

      return newState;
    },
    [types.UPDATE_LEVEL_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingLevel: false,
        isUpdatingLevelSuccess: true,
        isCreatingLevelSuccess: false,
      });

      return newState;
    },
    [types.UPDATE_LEVEL_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isupdatingLevel: false,
      });

      return newState;
    },
    [types.DELETE_LEVEL_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingLevel: true,
      });

      return newState;
    },
    [types.DELETE_LEVEL_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingLevel: false,
      });

      return newState;
    },
    [types.DELETE_LEVEL_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isdeletingLevel: false,
      });

      return newState;
    },
  },
);

export { levels as default };
