export const difficulties = [
  { value: 0, label: 'Balanced' },
  { value: 1, label: 'Easy' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'Hard' },
];

export function getDifficultyLabel(value) {
  return difficulties.find((difficulty) => difficulty.value === value)?.label;
}
