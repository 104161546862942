import { Box, Grid, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Firstpin from './img/FirstPin.svg';
import SecondPin from './img/SecondPin.svg';
import ThirdPin from './img/ThirdPin.svg';
import {
  ContainerWrapper,
  StyledPaper,
  FeaturesHeading,
  FeaturesBox,
  TextPinWrapper,
  FeaturesName,
  PinSetter,
  FirstPinIcon,
  SecondPinIcon,
} from './StyledComponents';
import { featureArray } from '../../constants/adminFeatures';
import { useDispatch, useSelector } from 'react-redux';
import { getManagementAPIPending } from '../../actions/user';

const AdminFeatures = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pinnedFeatures, setPinnedFeatures] = useState([]);
  const { pendingEntries } = useSelector((state) => state.user);
  const pendingData = pendingEntries?.data || {};

  useEffect(() => {
    dispatch(getManagementAPIPending());
  }, []);

  const getPendingCount = (id) => {
    switch (id) {
      case 'data-management':
        return `(${pendingData.college +
          pendingData.company +
          pendingData.job_role +
          pendingData.skills})`;
      case 'admin-degrees':
        return `(${pendingData.degree + pendingData.specialization})`;
      case 'review-jobs':
        return `(${pendingData.job})`;
      case 'review-orgs':
        return `(${pendingData.organization})`;
      case 'review':
        return `(${pendingData.question})`;
      case 'domains':
        return `(${pendingData.sub_tag + pendingData.tag})`;
      case 'check-bugs':
        return `(${pendingData.user_feedback})`;
      default:
        return '';
    }
  };

  const tooltipData = (item) => {
    return item.id === 'domains'
      ? `Tag:${pendingData.tag}, Sub Tag:${pendingData.sub_tag}`
      : item.id === 'data-management'
      ? `College:${pendingData.college}, Company:${pendingData.company}, Job Role:${pendingData.job_role}, Skills:${pendingData.skills}`
      : item.id === 'admin-degrees'
      ? `Degree:${pendingData.degree}, Specialization:${pendingData.specialization}`
      : getPendingCount(item.id);
  };

  useEffect(() => {
    const pinnedFeaturesFromStorage =
      localStorage.getItem('pinnedFeatures') || '';
    if (pinnedFeaturesFromStorage) {
      setPinnedFeatures(JSON.parse(pinnedFeaturesFromStorage));
    }
  }, []);

  const handlePinFeature = (featureName) => {
    if (!pinnedFeatures.includes(featureName)) {
      const updatedPinnedFeatures = [...pinnedFeatures, featureName];
      setPinnedFeatures(updatedPinnedFeatures);
      localStorage.setItem(
        'pinnedFeatures',
        JSON.stringify(updatedPinnedFeatures),
      );
    }
  };

  const handleUnpinFeature = (featureName) => {
    const updatedPinnedFeatures = pinnedFeatures.filter(
      (item) => item !== featureName,
    );
    setPinnedFeatures(updatedPinnedFeatures);
    localStorage.setItem(
      'pinnedFeatures',
      JSON.stringify(updatedPinnedFeatures),
    );
  };

  return (
    <ContainerWrapper>
      {pinnedFeatures.length > 0 && (
        <StyledPaper>
          <FeaturesHeading>Pinned Features</FeaturesHeading>
          <Grid container spacing={3}>
            {pinnedFeatures.map((pinnedFeature, index) => (
              <Grid
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={`pinned-${index}`}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              >
                <FeaturesBox>
                  <img
                    src={
                      featureArray.find((item) => item.name === pinnedFeature)
                        ?.icon
                    }
                    alt=""
                  />
                  <TextPinWrapper>
                    <FeaturesName
                      onClick={() =>
                        navigate(
                          `${featureArray.find(
                            (item) => item.name === pinnedFeature,
                          )?.link || '/'}`,
                        )
                      }
                    >
                      {pinnedFeature}{' '}
                      {getPendingCount(
                        featureArray.find((item) => item.name === pinnedFeature)
                          ?.id,
                      )}
                    </FeaturesName>
                  </TextPinWrapper>
                </FeaturesBox>
              </Grid>
            ))}
          </Grid>
        </StyledPaper>
      )}
      <StyledPaper>
        <FeaturesHeading>Admin Features</FeaturesHeading>
        <Grid container spacing={3}>
          {featureArray.map((item, index) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={index}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              <Tooltip
                title={
                  <Box
                    sx={{ padding: '10px', fontSize: '14px', fontWeight: 600 }}
                  >
                    {tooltipData(item)}
                  </Box>
                }
              >
                <FeaturesBox>
                  <img src={item.icon} alt="" />
                  <TextPinWrapper>
                    <FeaturesName onClick={() => navigate(`${item.link}`)}>
                      {item.name} {getPendingCount(item.id)}
                    </FeaturesName>
                    {pinnedFeatures.includes(item.name) ? (
                      <img
                        src={ThirdPin}
                        width={23}
                        onClick={() => handleUnpinFeature(item.name)}
                      />
                    ) : (
                      <PinSetter>
                        <FirstPinIcon
                          className="firstpinicon"
                          src={Firstpin}
                          width={23}
                        />
                        <SecondPinIcon
                          className="secondpinicon"
                          src={SecondPin}
                          onClick={() => handlePinFeature(item.name)}
                        />
                      </PinSetter>
                    )}
                  </TextPinWrapper>
                </FeaturesBox>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </StyledPaper>
    </ContainerWrapper>
  );
};

export default AdminFeatures;
