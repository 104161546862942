import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './../components/CompetitionDetails';
import ActionCreators from './../actions';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    competition: state.competitions,
    user: state.user,
    notifications: state.notifications,
    question: state.question,
    auth: state.auth,
    statisticsData: state.competitions.statisticsData,
    adminAnalyticsData: state.competitions.adminAnalyticsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
