import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OtpInput from 'react-otp-input';
import {
  Visibility as VisibilityIcon,
  VisibilityOff,
} from '@mui/icons-material/';

import MessageBar from '../MessageBar';

import helpers, { getHostAlias } from '../../lib/helpers';
import Config from './../../config';

import Google from './img/google.png';
import './index.css';
import { Divider } from '@mui/material';
import { withRouter } from '../../routes';

const SignUp = (props) => {
  const referralCode = helpers.getURLParameterByName('ref');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [signupStatus, setSignupStatus] = useState('');
  const [signupMessage, setSignupMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [resendOtpTime, setResendOtpTime] = useState(0);
  const [intervalId, setIntervalId] = useState('');
  const { location } = props;
  const hostUrl = window.location.host;

  useEffect(() => {
    props.getUserCountry();
    if (props.auth?.loginSuccess) {
      navigate('/competition');
    }
    if (location.state && location.state.from && location.state.from.pathname) {
      localStorage.setItem('redirect_url', location.state.from.pathname);
    }
  }, []);
  useEffect(() => {
    if (step === 2) startTimer();
  }, [resendOtpTime]);

  useEffect(() => {
    if (props.auth?.loginSuccess) {
      let from = { pathname: '/l' };
      const savedRedirect = localStorage.getItem('projq__redirect_path');

      if (savedRedirect) {
        from.pathname = savedRedirect;
        localStorage.removeItem('projq__redirect_path');
      } else if (window.location.state && window.location.state.from) {
        from = window.location.state.from;
      }
      navigate(from);
    }
  }, [props.auth?.loginSuccess]);

  useEffect(() => {
    if (props.auth?.userSignupSuccess) {
      if (
        !props.auth?.status.Success &&
        props.auth?.status.error === 'user_already_exists'
      ) {
        setIsStatusMessageVisible(true);
        setSignupStatus('warning');
        setSignupMessage(
          'This email is already registered. Please login instead!',
        );
      } else if (
        !props.auth?.status.Success &&
        props.auth?.status.error === 'inactive_user_exists'
      ) {
        setIsStatusMessageVisible(true);
        setSignupStatus('warning');
        setSignupMessage(
          'This email is already registered. Please activate your account by using the link sent via email.',
        );
      } else {
        setIsStatusMessageVisible(true);
        setSignupStatus('warning');
        setSignupMessage(
          'Congrats! You have been registered successfully. We have sent you a link to verify your email.',
        );
      }
    } else if (!props.auth?.userSignupFail && props.auth?.userSignupFail) {
      setIsStatusMessageVisible(true);
      setSignupStatus('error');
      setSignupMessage(
        'Sorry! We are not able to accept registrations at this time. Please try again in sometime.',
      );
    }
  }, [props.auth?.userSignupSuccess, props.auth?.userSignupFail]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const validate = (credentials) => {
    const errors = {};

    if (credentials?.password?.length === 0) {
      errors.password = 'Please enter a password';
    }
    if (credentials?.password?.length < 6) {
      errors.password = 'Password should contain more than 6 characters.';
    }
    if (!credentials?.email) {
      errors.email = 'Please enter email';
    } else if (
      !credentials.email.match(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      )
    ) {
      errors.email = 'Email is invalid';
    }

    if (credentials?.otp && credentials?.otp?.length !== 4) {
      errors.otp = 'Please enter a valid OTP';
    }

    return errors;
  };

  const startTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const newIntervalId = setInterval(() => {
      if (resendOtpTime > 0) {
        setResendOtpTime(resendOtpTime - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const sendOtpToEmail = (email) => {
    const data = { email };
    const { sendEmailVerifyOtp } = props;

    const errors = validate(data);
    if (!Object.keys(errors).length) {
      (async () => {
        try {
          const response = await sendEmailVerifyOtp(data);
          if (response.status === 200) {
            setStep(2);
            setResendOtpTime(60);
          } else {
            setErrors({ email: 'Please try again with different email' });
          }
        } catch (err) {}
      })();
    } else {
      // Save validation errors in state
      setErrors(errors);
    }
  };

  const verifyOtp = (email, otp) => {
    const data = { email, otp };
    const { verifyEmailOtp } = props;
    const errors = validate(data);

    if (!Object.keys(errors).length) {
      (async () => {
        try {
          const resp = await verifyEmailOtp(data);
          if (resp.is_email_verified) setStep(3);
          else {
            setErrors({ otp: "OTP doesn't match. Please try again" });
          }
        } catch (err) {}
      })();
    } else {
      // Save validation errors in state
      setErrors(errors);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { postUser, location } = props;

    if (step === 1) {
      sendOtpToEmail(email);
    } else if (step === 2) {
      verifyOtp(email, otp);
    } else {
      const credentials = {
        email,
        password,
        email_verification_otp: otp,
        timezone,
        referred_by_hash: referralCode,
        redirectTo: encodeURIComponent(location.state?.from?.pathname),
      };

      const errors = validate(credentials);

      if (!Object.keys(errors).length) {
        postUser(credentials);
      } else {
        // Save validation errors in state
        setErrors(errors);
      }
    }
  };

  const handleGoogleClick = () => {
    const { location } = props;
    if (location.state && location.state.from && location.state.from.pathname) {
      localStorage.setItem(
        'projq__redirect_path',
        location.state.from.pathname,
      );
    }
    if (location.state?.background) {
      window.location.href = `${
        Config.API_URL
      }/google_signup?host=${getHostAlias(
        hostUrl,
      )}&referral_hash=${referralCode}&redirectTo=${
        location.state.background.pathname
      }`;
    } else {
      window.location.href = `${
        Config.API_URL
      }/google_signup?host=${getHostAlias(
        hostUrl,
      )}&referral_hash=${referralCode}`;
    }
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="signup">
      <div className="signup-heading">Create an account</div>
      <Typography color="primary">
        To signup, please enter your details.
      </Typography>
      <Divider
        style={{ background: 'white', height: '3px', margin: '10px 0px' }}
      />
      <div className="signup-form">
        <form onSubmit={onSubmit} noValidate>
          <div className="text-field">
            <label>Email Address</label>
            <br />
            <TextField
              name="email"
              variant="outlined"
              type="email"
              value={email}
              onChange={handleChange}
              margin="dense"
              className="text-input"
              placeholder="Enter your email address"
            />
          </div>
          {errors.email && <p className="error"> {errors.email}</p>}

          {step === 2 && (
            <>
              <div className="text-field">
                <label>
                  <Typography color="primary" mb="5px">
                    Enter OTP (4 alphabets)
                  </Typography>
                </label>
                <div className="otp-input-container">
                  <OtpInput
                    value={otp}
                    onChange={(val) => setOtp(val)}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus={true}
                    placeholder="XXXX"
                    inputStyle={{
                      border: '1px solid #25507B3D',
                      borderRadius: '8px',
                      width: '44px',
                      height: '44px',
                      fontSize: '18px',
                      color: 'black',
                      fontWeight: '400',
                      caretColor: 'black',
                      margin: '5px 5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    focusStyle={{
                      border: '1px solid #CFD3DB',
                      outline: 'none',
                    }}
                  />
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      fontSize: '12px !important',
                      '&:hover': {
                        background: 'white',
                        color: 'blue',
                      },
                      '&:disabled': {
                        color: 'gray',
                      },
                    }}
                    disabled={resendOtpTime > 0}
                    onClick={() => sendOtpToEmail(email)}
                  >
                    <Typography fontSize="12px">
                      {resendOtpTime > 0
                        ? `Resend Otp (${resendOtpTime})`
                        : 'Resend Otp'}
                    </Typography>
                  </Button>
                </div>
              </div>
              <p className="error"> {errors.otp}</p>
            </>
          )}

          {step === 3 && (
            <>
              <div className="text-field">
                <label>Set your password</label>
                <TextField
                  type={isPasswordVisible ? 'text' : 'password'}
                  variant="outlined"
                  name="password"
                  onChange={handleChangePassword}
                  value={password}
                  margin="dense"
                  className="text-input"
                  placeholder="Set your unique password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visiility"
                          onClick={togglePassword}
                          size="large"
                        >
                          {isPasswordVisible ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
              </div>
              <p className="error"> {errors.password}</p>
            </>
          )}

          <Button
            variant="contained"
            className="btn-primary"
            fullWidth={true}
            type="submit"
            color="primary"
          >
            {step === 1 ? 'Send OTP' : step === 2 ? 'Verify OTP' : 'Signup'}
          </Button>
        </form>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 0, marginTop: 0 }}>
        <Typography variant="subtitle1" color="primary">
          OR
        </Typography>
      </div>

      <div className="google-btn-conatiner">
        <Button
          variant="outlined"
          className="google-login-button"
          fullWidth={true}
          type="button"
          onClick={handleGoogleClick}
        >
          <img alt="google_icon" src={Google} height={20} />
          Signup with Google
        </Button>
      </div>
      <Divider
        style={{ background: 'white', height: '3px', margin: '10px 0px' }}
      />
      <div className="signup-extras">
        Have an account?&nbsp;
        <NavLink
          to={{
            pathname: `/login`,
            search: props.location.search,
          }}
          state={{
            background:
              props.location &&
              props.location.state &&
              props.location.state.background,
            from:
              props.location &&
              props.location.state &&
              props.location.state.from,
          }}
        >
          Login
        </NavLink>
      </div>
      <MessageBar
        type={signupStatus}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={signupMessage}
      />
    </div>
  );
};

SignUp.defaultProps = {
  postUser: () => {},
  sendEmailVerifyOtp: () => {},
  verifyEmailOtp: () => {},
};

SignUp.propTypes = {
  postUser: PropTypes.func,
  sendEmailVerifyOtp: PropTypes.func,
  verifyEmailOtp: PropTypes.func,
};

export default withRouter(SignUp);
