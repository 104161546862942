import { styled } from '@mui/styles';

export const QuestionCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '@media (max-width: 1000px)': {
    justifyContent: 'space-between',
  },

  h6: {
    marginRight: '0.5em',
  },

  '&.extra': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3em',

    p: {
      display: 'flex',
      alignItems: 'center',

      span: {
        '&.Correct': {
          backgroundColor: theme.palette.common.success,
        },

        '&.Incorrect': {
          backgroundColor: theme.palette.common.error,
        },

        '&.Skipped': {
          backgroundColor: theme.palette.secondary.background,
        },

        '&.Timeout': {
          backgroundColor: theme.palette.common.warning,
        },
      },

      svg: {
        margin: '0 0.3em',
        fontSize: '1.3em',
      },

      '&:last-child': {
        borderLeft: `1px solid ${theme.palette.text.main}`,
      },
    },
  },
}));

export const ButtonContainerMultipart = styled('div')({
  width: '100%',
  padding: '10px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const QuestionCardSolution = styled('div')(({ theme }) => ({
  boxShadow: `0px 4px 4px ${theme.palette.component.shadowColor}`,
  borderRadius: '3px',
  marginTop: '1em',
  padding: '0.1em 1em',

  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    h6: {
      fontWeight: 'bolder',
    },
  },
}));
