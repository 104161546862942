import * as types from './types';
import apiClient from '../api/apiclient';

function getMockTestPrerequisiteStart() {
  return {
    type: types.GET_MOCK_TEST_PREREQUISITE_START,
  };
}

function getMockTestPrerequisiteSuccess(data) {
  return {
    type: types.GET_MOCK_TES_PREREQUISITT_SUCCESS,
    data,
  };
}

function getMockTestPrerequisiteFail() {
  return {
    type: types.GET_MOCK_TEST_PREREQUISIT_FAIL,
  };
}

export function getMockTestPrerequisite(id) {
  return (dispatch) => {
    dispatch(getMockTestPrerequisiteStart());

    apiClient
      .getMockTestApi()
      .mocktestApiGetMocktestPrerequisiteStatus(id)
      .then((res) => {
        dispatch(getMockTestPrerequisiteSuccess(res.data));
      })
      .catch(() => {
        dispatch(getMockTestPrerequisiteFail());
      });
  };
}

function getMockTestStart() {
  return {
    type: types.GET_MOCK_TEST_START,
  };
}

function getMockTestSuccess(data) {
  return {
    type: types.GET_MOCK_TEST_SUCCESS,
    data,
  };
}

function getMockTestFail() {
  return {
    type: types.GET_MOCK_TEST_FAIL,
  };
}

export function getMockTest(
  { domain_id, examType, name, page, pageSize, selectedTag },
  callback,
) {
  return (dispatch) => {
    dispatch(getMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetUserDomainMockExams(
        domain_id,
        examType,
        name,
        page,
        pageSize,
        selectedTag,
      )
      .then((res) => {
        dispatch(getMockTestSuccess(res.data));
        if (callback) callback(res.data.mocktest);
      })
      .catch(() => {
        dispatch(getMockTestFail());
      });
  };
}

function uploadImageStart() {
  return {
    type: types.UPLOAD_IMAGE_START,
  };
}

function uploadImageSuccess(data) {
  return {
    type: types.UPLOAD_IMAGE_SUCCESS,
    data,
  };
}

function uploadImageFail() {
  return {
    type: types.UPLOAD_IMAGE_FAIL,
  };
}

export function uploadImage(file) {
  return (dispatch) => {
    dispatch(uploadImageStart());

    apiClient
      .getExamApi()
      .examApiUploadExamImage(file)
      .then((res) => {
        dispatch(uploadImageSuccess(res.data));
      })
      .catch(() => {
        dispatch(uploadImageFail());
      });
  };
}

function createMockTestStart() {
  return {
    type: types.CREATE_MOCK_TEST_START,
  };
}

function createMockTestSuccess(data) {
  return {
    type: types.CREATE_MOCK_TEST_SUCCESS,
    data,
  };
}

function createMockTestFail(err) {
  return {
    type: types.CREATE_MOCK_TEST_FAIL,
    data: err,
  };
}

export function createMockTest(mockTest) {
  return (dispatch) => {
    dispatch(createMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiCreateMocktest(mockTest)
      .then((res) => {
        dispatch(createMockTestSuccess(res.data));
      })
      .catch((err) => {
        let errText = "Couldn't create mock test";
        if (err.response?.data?.error)
          errText = err.response.data.error.replaceAll('_', ' ');

        errText = errText.charAt(0).toUpperCase() + errText.slice(1);
        dispatch(createMockTestFail(errText));
      });
  };
}

function postMockTestStart() {
  return {
    type: types.POST_MOCK_TEST_START,
  };
}

function postMockTestSuccess(data) {
  return {
    type: types.POST_MOCK_TEST_SUCCESS,
    data,
  };
}

function postMockTestFail(err) {
  return {
    type: types.POST_MOCK_TEST_FAIL,
    data: err,
  };
}

export function postMockTest(exam) {
  return (dispatch) => {
    dispatch(postMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiCreateMocktest(exam)
      .then((res) => {
        dispatch(postMockTestSuccess(res.data));
      })
      .catch((err) => {
        let errText = "Couldn't buy mock test";
        if (err.response?.data?.error)
          errText = err.response.data.error.replaceAll('_', ' ');

        errText = errText.charAt(0).toUpperCase() + errText.slice(1);
        dispatch(postMockTestFail(errText));
      });
  };
}

function startMockTestStart() {
  return {
    type: types.START_MOCK_TEST_START,
  };
}

function startMockTestSuccess(data) {
  return {
    type: types.START_MOCK_TEST_SUCCESS,
    data,
  };
}

function startMockTestFail(err) {
  return {
    type: types.START_MOCK_TEST_FAIL,
    data: err,
  };
}

export function startMockTest(id) {
  return (dispatch) => {
    dispatch(startMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiStartExam(id)
      .then(() => {
        dispatch(startMockTestSuccess(id));
      })
      .catch(() => {
        dispatch(startMockTestFail('Failed to Start Test'));
      });
  };
}

function getMockTestByIdStart() {
  return {
    type: types.GET_MOCK_TEST_BY_ID_START,
  };
}

function getMockTestByIdSuccess(data) {
  return {
    type: types.GET_MOCK_TEST_BY_ID_SUCCESS,
    data,
  };
}

function getMockTestByIdFail(err) {
  return {
    type: types.GET_MOCK_TEST_BY_ID_FAIL,
    data: err,
  };
}

export function getMockTestById(id) {
  return (dispatch) => {
    dispatch(getMockTestByIdStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetMocktestById(id)
      .then((res) => {
        dispatch(getMockTestByIdSuccess(res.data.mocktest));
      })
      .catch(() => {
        dispatch(getMockTestByIdFail('Failed to get Test'));
      });
  };
}

function getMockTestQuestionsStart() {
  return {
    type: types.GET_MOCK_TEST_QUESTIONS_START,
  };
}

function getMockTestQuestionsSuccess(data) {
  return {
    type: types.GET_MOCK_TEST_QUESTIONS_SUCCESS,
    data,
  };
}

function getMockTestQuestionsFail(err) {
  return {
    type: types.GET_MOCK_TEST_QUESTIONS_FAIL,
    data: err,
  };
}

export function getMockTestQuestions(exam_id, page = 1, page_size = 10) {
  return (dispatch) => {
    dispatch(getMockTestQuestionsStart());
    apiClient
      .getQuestionApi()
      .questionApiGetAnsweredQuestionList(
        undefined,
        undefined,
        exam_id,
        undefined,
        page,
        page_size,
        undefined,
        undefined,
      )
      .then((res) => {
        dispatch(getMockTestQuestionsSuccess(res.data));
      })
      .catch(() => {
        dispatch(getMockTestQuestionsFail('Failed to get Test'));
      });
  };
}

function endMockTestStart() {
  return {
    type: types.END_MOCK_TEST_START,
  };
}

function endMockTestSuccess() {
  return {
    type: types.END_MOCK_TEST_SUCCESS,
  };
}

function endMockTestFail(error) {
  return {
    type: types.END_MOCK_TEST_FAIL,
    data: error,
  };
}

export function endMockTest(id) {
  return (dispatch) => {
    dispatch(endMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiCloseExam(id)
      .then(() => {
        dispatch(endMockTestSuccess());
      })
      .catch(() => {
        dispatch(endMockTestFail('Failed to end mock test'));
      });
  };
}

function deleteMockTestStart() {
  return {
    type: types.DELETE_MOCK_TEST_START,
  };
}

function deleteMockTestSuccess(data) {
  return {
    type: types.DELETE_MOCK_TEST_SUCCESS,
    data,
  };
}

function deleteMockTestFail(error) {
  return {
    type: types.DELETE_MOCK_TEST_FAIL,
    data: error,
  };
}

export function deleteMockTest(
  id,
  domain_id,
  examType,
  searchText,
  page,
  pageSize,
  tag,
) {
  return (dispatch) => {
    dispatch(deleteMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiDeleteExam(id)
      .then(() => {
        dispatch(deleteMockTestSuccess(id));
        dispatch(
          getMockTest(
            {
              domain_id,
              examType,
              searchText,
              page,
              pageSize,
              selectedTag: tag,
            },
            () => {},
          ),
        );
      })
      .catch(() => {
        dispatch(deleteMockTestFail('Failed to delete mock test'));
      });
  };
}

export function clearMockTest() {
  return {
    type: types.CLEAR_MOCK_TEST_STATE,
  };
}

function editMockTestStart() {
  return {
    type: types.EDIT_MOCK_TEST_START,
  };
}

function editMockTestSuccess(data) {
  return {
    type: types.EDIT_MOCK_TEST_SUCCESS,
    data,
  };
}

function editMockTestFail(err) {
  return {
    type: types.EDIT_MOCK_TEST_FAIL,
    data: err,
  };
}

export function editMockTest(id, data) {
  return (dispatch) => {
    dispatch(editMockTestStart());
    apiClient
      .getMockTestApi()
      .mocktestApiUpdateMocktestDetail(id, data)
      .then((res) => {
        dispatch(editMockTestSuccess(res.data));
      })
      .catch(() => {
        dispatch(editMockTestFail('Failed to Start Test'));
      });
  };
}

function getMockTestStatisticsStart() {
  return {
    type: types.GET_MOCK_TEST_STATISTICS_START,
  };
}

function getMockTestStatisticsSuccess(data) {
  return {
    type: types.GET_MOCK_TEST_STATISTICS_SUCCESS,
    data,
  };
}

function getMockTestStatisticsFail(err) {
  return {
    type: types.GET_MOCK_TEST_STATISTICS_FAIL,
    data: err,
  };
}

export function getMockTestStatistics(id) {
  return (dispatch) => {
    dispatch(getMockTestStatisticsStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetMocktestStartStatistics(id)
      .then((res) => {
        dispatch(getMockTestStatisticsSuccess(res.data));
      })
      .catch(() => {
        dispatch(getMockTestStatisticsFail('Failed to get Test'));
      });
  };
}
