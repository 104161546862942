import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../actions';
import Component from '../components/Play/Competition';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user,
    question: state.question,
    category: state.category,
    categoryList: state.category.data,
    tag: state.tag,
    mockTest: state.mockTest,
    preferences: state.category.preferences,
    competition: state.competitions.details,
    competitionAnalytics: state.competitions.analytics,
    costData: state.credits.costData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
