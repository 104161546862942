import * as types from './types';
import apiClient from '../api/apiclient';

export function postFeedbackStart() {
  return {
    type: types.POST_FEEDBACK_START,
  };
}

export function postFeedbackSuccess({ data }) {
  return {
    type: types.POST_FEEDBACK_SUCCESS,
    data: data,
  };
}

export function postFeedbackFail() {
  return {
    type: types.POST_FEEDBACK_FAIL,
  };
}

export function postFeedback(body) {
  return (dispatch) => {
    dispatch(postFeedbackStart());
    apiClient
      .getGeneralApi()
      .generalApiCreateFeedbackReport(body)
      .then((resp) => {
        dispatch(postFeedbackSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(postFeedbackFail());
        throw ex;
      });
  };
}

export function uploadFeedbackImageStart() {
  return {
    type: types.UPLOAD_FEEDBACK_IMAGE_START,
  };
}

export function uploadFeedbackImageSuccess({ data }) {
  return {
    type: types.UPLOAD_FEEDBACK_IMAGE_SUCCESS,
    data: data,
  };
}

export function uploadFeedbackImageFail() {
  return {
    type: types.UPLOAD_FEEDBACK_IMAGE_FAIL,
  };
}

export function uploadFeedbackImage(file) {
  return (dispatch) => {
    dispatch(uploadFeedbackImageStart());
    apiClient
      .getGeneralApi()
      .generalApiUploadFeedbackMedia(file)
      .then((resp) => {
        dispatch(uploadFeedbackImageSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(uploadFeedbackImageFail());
        throw ex;
      });
  };
}

export function getReportedBugsStart() {
  return {
    type: types.GET_REPORTED_BUGS_START,
  };
}
export function getReportedBugsSuccess({ data }) {
  return {
    type: types.GET_REPORTED_BUGS_SUCCESS,
    data: data,
  };
}
export function getReportedBugsFaiL() {
  return {
    type: types.GET_REPORTED_BUGS_FAIL,
  };
}

export function getReportedBugsDetails(
  page,
  pageSize,
  status,
  sortBy,
  sortOrder,
  typeError,
  searchIssueArea,
  searchUser,
  feedbackType,
  minDate,
  maxDate,
) {
  return (dispatch) => {
    dispatch(getReportedBugsStart());
    apiClient
      .getGeneralApi()
      .generalApiGetFeedbackReports(
        searchIssueArea,
        maxDate,
        minDate,
        feedbackType,
        typeError,
        searchUser,
        page,
        pageSize,
        sortBy,
        sortOrder,
        status,
        undefined,
      )
      .then((resp) => {
        dispatch(getReportedBugsSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        dispatch(getReportedBugsFaiL());
        throw ex;
      });
  };
}

export function editReportedBugsStart() {
  return {
    type: types.EDIT_REPORTED_BUG_START,
  };
}
export function editReportedBugsSuccess() {
  return {
    type: types.EDIT_REPORTED_BUG_SUCCESS,
  };
}
export function editReportedBugsFaiL() {
  return {
    type: types.EDIT_REPORTED_BUG_FAIL,
  };
}

export function editReportedBug(feedbackId, status, callback) {
  return (dispatch) => {
    dispatch(editReportedBugsStart());
    apiClient
      .getGeneralApi()
      .generalApiUpdateUserFeedbackStatus(feedbackId, status)
      .then(() => {
        dispatch(editReportedBugsSuccess());
        callback?.();
      })
      .catch((ex) => {
        dispatch(editReportedBugsFaiL());
        throw ex;
      });
  };
}
