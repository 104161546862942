import React, { forwardRef, useState } from 'react';
import { DragAndDropLabel, DragAndDropUploadIcon } from './StyledComponents';
import { Box } from '@mui/material';

// Drag and drop based interface for uploading file(s)
const DragAndDrop = forwardRef(({ setFiles, multiple }, ref) => {
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    ref.current.files = event?.dataTransfer?.files;
    setFiles?.(ref.current.files);
  };

  const handleChange = (event) => {
    setFiles?.(event.target.files);
  };

  return (
    <Box>
      <DragAndDropLabel
        beingdragged={dragActive.toString()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <DragAndDropUploadIcon />
        Drop your file(s) here
        <input
          ref={ref}
          type={'file'}
          style={{
            display: 'none',
          }}
          onChange={handleChange}
          multiple={multiple}
        />
      </DragAndDropLabel>
    </Box>
  );
});

export default DragAndDrop;
