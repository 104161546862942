import React, { useState } from 'react';
import ReactSelect from 'react-select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { StyledDomainPrompt } from './StyledComponent';

const DomainPrompt = ({ handleSubmit, domains, open }) => {
  const [selectedDomain, setDomain] = useState('');
  const [errors, setErrors] = useState({});

  const handleDomainChange = (domain) => {
    setDomain(domain);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedDomain) {
      handleSubmit(selectedDomain);
    } else {
      setErrors({ ...errors, selectedDomain: 'Please select domain' });
    }
  };

  return (
    <StyledDomainPrompt
      open={open}
      onClose={() => null}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="domain-prompt"
    >
      <div className="domain-prompt__container">
        <Paper>
          <div className="domain-prompt__container__content">
            <Typography variant="h6">Pick a domain of your choice!</Typography>
            <Typography variant="caption" className="caption">
              Selecting a domain is mandatory, but you can switch your selected
              domain from the sidebar anytime in the future.
            </Typography>
            <div className="domain-prompt__container__content__input">
              <ReactSelect
                options={domains}
                name="selectedDomain"
                onChange={handleDomainChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder="Select a domain"
                value={selectedDomain}
                isClearable
              />
              <p className="error">{errors.selectedDomain}</p>
            </div>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={onSubmit}
            >
              submit
            </Button>
          </div>
        </Paper>
      </div>
    </StyledDomainPrompt>
  );
};

export default DomainPrompt;
