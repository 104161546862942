import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import './index.css';
import { withRouter } from '../../routes';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [errors, setErrors] = useState({});
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [hash, setHash] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (props.auth.updatePasswordSuccess) {
      setIsStatusMessageVisible(true);
      setStatus('success');
      setMessage('Password updated successfully');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [props.auth?.updatePasswordSuccess]);

  useEffect(() => {
    if (props.auth.updatePasswordFail) {
      setIsStatusMessageVisible(true);
      setStatus('success');
      setMessage('Password could not be updated. Try again!');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [props.auth?.updatePasswordFail]);

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };
  useEffect(() => {
    var hash = document.URL.split('?password_hash=');
    setHash(hash[1]);
  }, []);

  const validate = (credentials) => {
    const errors = {};

    if (!credentials.newPassword) {
      errors.newPassword = 'Please enter new password';
    } else if (!credentials.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (credentials.newPassword !== credentials.confirmPassword) {
      errors.match = "Passwords don't match";
    }

    return errors;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const credentials = {
      newPassword: event.target.newPassword.value,
      confirmPassword: event.target.confirmPassword.value,
    };

    const errors = validate(credentials);

    if (!Object.keys(errors).length) {
      setIsLoading(true);
      setErrors({});
      props.updatePassword({
        body: {
          password_hash: hash,
          password: credentials.newPassword,
        },
      });
    } else {
      setErrors(errors);
    }
  };
  const handleChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const { updatePasswordStart } = props;

  return (
    <div className="change-password-container">
      <div className="header">Update Password</div>
      <form onSubmit={onSubmit}>
        <p className="errors">{errors.match}</p>
        <div>
          <TextField
            name="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            onChange={handleChange}
            margin="normal"
            className="text-input"
          />
          <br />
          <p className="errors">{errors.newPassword}</p>
        </div>
        <div className="text-field">
          <TextField
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            onChange={handleConfirmPasswordChange}
            value={confirmPassword}
            margin="normal"
            className="text-input"
          />
          <br />
          <p className="errors">{errors.confirmPassword}</p>
        </div>
        <Button
          variant="contained"
          fullWidth={true}
          type="submit"
          disabled={isLoading}
          color="primary"
        >
          {updatePasswordStart ? (
            <Loader size={24} color="inherit" />
          ) : (
            'Change Password'
          )}
        </Button>
      </form>
      <MessageBar
        type={status}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={message}
      />
    </div>
  );
};

export default withRouter(UpdatePassword);
