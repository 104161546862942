import {
  Avatar,
  FormHelperText,
  Grid,
  Typography,
  TextField,
  Tooltip,
  tooltipClasses,
  styled,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { sendExamAutocompleteQuery } from '../../actions/user';
import { getTagById } from '../../actions/tag';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useLocation } from 'react-router-dom';
import { StyledEditQuestion } from './StyledComponent';

const maxTimeOptions = [
  {
    value: 60,
    label: '60 Seconds',
  },
  {
    value: 120,
    label: '2 Minutes',
  },
  {
    value: 180,
    label: '3 Minutes',
  },
  {
    value: 300,
    label: '5 Minutes',
  },
  {
    value: 600,
    label: '10 Minutes',
  },
];

const yearOptions = [];

for (let i = 1980; i <= +moment().format('YYYY'); i += 1) {
  yearOptions.push({
    label: i,
    value: i,
  });
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginBottom: 10,
    backgroundColor: 'white',
    border: '1px solid rgb(169, 169, 169)',
    padding: '10px 10px',
    borderRadius: '10px',
    zIndex: '100',
    color: '#25507b',
    fontSize: 12,
  },
}));

const MetaData = (props) => {
  const methods = useFormContext();
  const { categoryOptions = [] } = props;
  const [subCategoryOptions, setSubCategoryOptions] = React.useState([]);
  const dispatch = useDispatch();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const examId = watch('exam_id');
  const categoryId = watch('tag_id');
  const categoryName = watch('tag_name');
  const category = watch('tag');
  const sub_tagid_array = watch('sub_tagid_array');
  const location = useLocation();
  React.useEffect(() => {
    if (
      (category?.id && category?.id === categoryId) ||
      (category?.value && category?.value === categoryName)
    )
      return;
    if (categoryId) {
      dispatch(
        getTagById(categoryId, (res) => {
          setValue('tag', res);
          const selectedSubTags = res.sub_tags.filter((subTag) =>
            sub_tagid_array?.includes(subTag.id),
          );
          setValue('sub_tag_array', selectedSubTags);
        }),
      );
    } else if (categoryName) {
      setValue('tag', {
        name: categoryName,
        value: categoryName,
      });
      setValue('sub_tag_array', []);
    }
  }, [categoryId, categoryName]);

  React.useEffect(() => {
    // This will have only accepted subtags
    const selectedCategory = categoryOptions.find(
      (cat) => category?.id === cat.id,
    );
    const subCategories = selectedCategory?.sub_tags || [];
    setSubCategoryOptions(subCategories);
  }, [category]);

  return (
    <StyledEditQuestion>
      {/* Subcategory */}
      <div className="form-group tags">
        <label htmlFor="">Select Category</label>
        <Controller
          control={control}
          name="tag"
          rules={{
            required: 'Please select a category',
          }}
          defaultValue={location.state?.tag}
          render={({ field: { onChange, value } }) => (
            <CreatableSelect
              value={value}
              options={categoryOptions}
              blurInputOnSelect={true}
              onChange={(e) => {
                onChange(e);
                setValue('tag_id', e?.id);
                setValue('sub_tag_array', []);
                setSubCategoryOptions([]);
              }}
              getOptionLabel={(option) => (
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Avatar
                      alt={`${option.name}`}
                      src={option.s3_url}
                      style={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    {option.name ? option.name : `Create ${option.value}`}
                  </Grid>
                </Grid>
              )}
              getOptionValue={(option) =>
                option.name ? option.name : option.value
              }
              styles={{
                control: (baseStyles, { isFocused }) => {
                  return {
                    ...baseStyles,
                    cursor: 'pointer',

                    '& .css-1su2i3t-singleValue': {
                      width: isFocused ? '0px' : '100%',
                    },
                  };
                },
                singleValue: (baseStyles) => {
                  return {
                    ...baseStyles,
                    width: '100%',
                  };
                },
              }}
            />
          )}
        />
        <FormHelperText error>{errors.tag?.message}</FormHelperText>
      </div>
      <div className="form-group tags">
        <label htmlFor="">Select Subtopic</label>
        <Controller
          control={control}
          name="sub_tag_array"
          defaultValue={[]}
          rules={{
            required: 'Please select at least one sub-category',
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <CreatableSelect
                options={subCategoryOptions}
                onChange={(evt) => onChange(evt)}
                value={value || []}
                getOptionLabel={(option) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'intial',
                        height: '24px',
                      }}
                    >
                      <Avatar
                        alt={`${option.name}`}
                        src={option.s3_url}
                        style={{
                          mr: '5px',
                          mixBlendMode: 'multiply',
                          height: 24,
                          width: 24,
                        }}
                      />
                      <Typography color="primary" style={{ marginLeft: '5px' }}>
                        {option.name ? option.name : `Create ${option.value}`}
                      </Typography>
                    </div>
                  );
                }}
                getOptionValue={(option) =>
                  option.name ? option.name : option.value
                }
                isClearable={false}
                isMulti
              />
            );
          }}
        />
        <FormHelperText error>{errors.sub_tag_array?.message}</FormHelperText>
      </div>

      <div className="form-group tags">
        <label htmlFor="">
          Exam in which this question appeared (Optional)
        </label>
        <Controller
          control={control}
          name="exam_id"
          defaultValue=""
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <>
              <AsyncCreatableSelect
                {...field}
                isClearable
                cacheOptions
                noOptionsMessage={() => 'Search Exam to show options...'}
                placeholder="Search Exam..."
                loadOptions={(query, callback) =>
                  dispatch(sendExamAutocompleteQuery(query, callback))
                }
                getOptionLabel={(option) =>
                  option.exam_name ? option.exam_name : option.label
                }
                getOptionValue={(option) =>
                  option.id ? option.id : option.value
                }
              />
            </>
          )}
        />
      </div>

      {examId && (
        <div className="form-group tags">
          <label htmlFor="">Year of exam</label>
          <Controller
            control={control}
            name="year"
            defaultValue={null}
            rules={{
              required: 'Please select the year',
            }}
            render={({ field: { onChange, value } }) => (
              <ReactSelect
                options={yearOptions}
                onChange={(val) => onChange(val?.value)}
                value={yearOptions.find((year) => year.value === value)}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isClearable
                isSearchable={false}
              />
            )}
          />
          <FormHelperText error>{errors.year?.message}</FormHelperText>
        </div>
      )}

      {examId && (
        <div className="form-group tags">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label htmlFor="">Question Reference URL</label>
            <LightTooltip
              title="Give the reference URL of the question"
              placement="right"
            >
              <InfoIcon
                sx={{ width: '18px', paddingBottom: '6px', marginLeft: '10px' }}
              />
            </LightTooltip>
          </div>
          <Controller
            control={control}
            name="question_source_url"
            rules={{
              required: 'Please enter reference URL',
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                type="url"
                onChange={(e) => onChange(e.target.value)}
                value={value}
                fullWidth
                size="small"
                placeholder="Enter question reference URL"
              />
            )}
          />
          <FormHelperText error>
            {errors.question_source_url?.message}
          </FormHelperText>
        </div>
      )}

      <div className="form-group tags">
        <label htmlFor="">Max Time</label>
        <Controller
          control={control}
          name="max_time"
          rules={{
            required: 'Please select max time',
          }}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              options={maxTimeOptions}
              onChange={(val) => onChange(val?.value)}
              value={maxTimeOptions.find((time) => time.value === value)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              // isClearable
              isSearchable={false}
            />
          )}
        />
        <FormHelperText error>{errors.max_time?.message}</FormHelperText>
      </div>
    </StyledEditQuestion>
  );
};

export default MetaData;
