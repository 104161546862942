import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import Component from './../components/Appbar';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    question: state.question,
    tag: state.tag,
    category: state.category,
    user: state.user,
    notifications: state.notifications,
    layout: state.layout,
    domain: state.domain,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
