import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Checkbox,
  Chip,
  Accordion,
  AccordionActions,
  Grid,
  Icon,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import { Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import { Button, SplitButton } from '../Button';
import { getExamLabel } from '../../constants/examTypes';
import DeleteIcon from '@mui/icons-material/Delete';
import LikeDislike from './../../containers/Like_DislikeContainer';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import Bookmark from '@mui/icons-material/Bookmark';
import './style.css';
import { reportReasons } from '../../constants/questioncard';

const getTagById = (list = [], id) => {
  return list.find((tag) => tag.id === id) || {};
};
const QuestionCard = (props) => {
  const [setActive, setSetActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(
    props.attemptedQuestion?.bookmark,
  );

  useEffect(() => {
    if (props.canceled === true) setChecked(false);
  }, [props.canceled]);

  useEffect(() => {
    const { page, page_size, tag_id, getUnderReviewQuestions } = props;
    if (setActive) {
      getUnderReviewQuestions({
        page: page,
        page_size: page_size,
        tag_id: tag_id,
        question_status: 'Blocked',
        user_id: props.user_id,
      });
    }
  }, []);

  const onBlockReasonSubmit = (status, reason) => {
    const question = { ...props.question };
    question.question_status = status;

    if (reason) {
      question.rejection_reason = reason;
    }

    if (question.user_data) {
      delete question.user_data;
    }

    props.putReportedQuestion({ body: question, questionId: question.id });
    props.getQuestionIdOnClick(question.id, 'blocked');
  };

  const getStatusChipColor = (question) => {
    if (question.question_status === 'Active') {
      return 'primary';
    }
    return 'default';
  };

  const changeIcon = () => {
    setIsBookmarked(!isBookmarked);
  };

  const renderReviewQuestionButtons = () => {
    const {
      question,
      onAccept,
      isSolutionCollapse,
      handleDeleteAction,
      putBookmark,
      questionId,
      showBookmarkLikeDisLike,
      isQuestionSelected,
      attemptedQuestion,
    } = props;

    return (
      <AccordionActions style={{ padding: '10px 16px' }}>
        {showBookmarkLikeDisLike ? (
          <div style={{ width: '100%' }}>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <LikeDislike
                  id={question.id}
                  liked={attemptedQuestion.is_liked}
                  disliked={attemptedQuestion.is_disliked}
                />
              </Grid>
              <Grid item>
                <Box display={'flex'} alignItems="center">
                  {!isBookmarked ? (
                    <BookmarkBorder
                      color="primary"
                      fontSize="42"
                      onClick={() => {
                        changeIcon();
                        putBookmark({
                          questionId: questionId,
                          body: { bookmark_status: true },
                        });
                      }}
                    />
                  ) : (
                    <Bookmark
                      color="primary"
                      onClick={() => {
                        changeIcon();
                        putBookmark({
                          questionId: questionId,
                          body: { bookmark_status: false },
                        });
                      }}
                    />
                  )}
                  <Typography variant="body2" color="primary">
                    Bookmark
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            {isSolutionCollapse && (
              <Grid container>
                <NavLink
                  to={`/l/question/${question.id}`}
                  state={{
                    question: question,
                    tag: props.tag,
                  }}
                >
                  <Button
                    aria-label="view details"
                    color="primary"
                    variant="outlined"
                  >
                    View details
                  </Button>
                </NavLink>
              </Grid>
            )}
            <Grid container spacing={2} justifyContent="flex-end">
              {isQuestionSelected ? (
                <>
                  <Grid item>
                    <NavLink to={`/l/question/edit/${question.id}`}>
                      <Button
                        aria-label="edit"
                        color="primary"
                        variant="contained"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </NavLink>
                  </Grid>
                  {(question.question_status === 'Blocked' ||
                    question.question_status === 'UnderReview') && (
                    <Grid item>
                      <Button
                        aria-label="edit"
                        color="success"
                        variant="contained"
                        startIcon={<DoneIcon />}
                        onClick={() => onAccept(question.id, 'single')}
                      >
                        Accept
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {question.question_status === 'Blocked' && (
                    <Grid item>
                      <NavLink to={`/l/question/edit/${question.id}`}>
                        <Button
                          aria-label="edit"
                          color="primary"
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </NavLink>
                    </Grid>
                  )}
                  {question.question_status === 'Draft' && (
                    <>
                      <Grid item>
                        <NavLink to={`/l/question/edit/${question.id}`}>
                          <Button
                            aria-label="edit"
                            color="primary"
                            variant="contained"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                        </NavLink>
                      </Grid>
                      <Grid item>
                        <Button
                          aria-label="delete"
                          color="primary"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          style={{
                            backgroundColor: '#d50000',
                            border: '#d50000',
                          }}
                          onClick={() => handleDeleteAction(question.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </div>
        )}
      </AccordionActions>
    );
  };

  const renderReportedQuestionReason = () => {
    const { question } = props;
    const { report_details } = question;
    const reason_names = Object.keys(report_details);
    const reasons = Object.values(report_details).map((item, key) => {
      return (
        `\n` +
        (key + 1) +
        `.` +
        reportReasons[reason_names[key]] +
        ' by ' +
        item.all_reporter_names.join(', ')
      );
    });

    return (
      <div className="reason-message">
        <Typography>{`Reported for reason: ${reasons}`}</Typography>
      </div>
    );
  };

  const renderBlockedReason = (question) => {
    const { rejection_reason } = question;

    return (
      <>
        <Alert severity="error">
          <AlertTitle>
            This question has been blocked due to the following reasons:
          </AlertTitle>
          <strong>{!!rejection_reason && rejection_reason}</strong>
        </Alert>
      </>
    );
  };

  const renderReportedQuestionButtons = () => {
    const { question } = props;
    return (
      <div>
        {renderReportedQuestionReason()}
        <AccordionActions
          style={{ padding: '0 0 24px 24px', justifyContent: 'flex-start' }}
        >
          <NavLink to={`/l/question/edit/${question.id}`}>
            <Button
              aria-label="edit"
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </NavLink>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              props.putReportedQuestion({
                questionId: question.id,
                body: {
                  ...question,
                  question_status: 'Active',
                  rejection_reason: '',
                },
              })
            }
            style={{ marginRight: 10 }}
          >
            Undo Report
          </Button>
          <SplitButton
            options={[
              'Quality of question not upto the mark',
              'Duplicate question',
              'Wrong Question',
              'Grammar or formatting issues',
              'Other',
            ]}
            onClick={(reason) => onBlockReasonSubmit('Blocked', reason)}
            type="danger"
          />
        </AccordionActions>
      </div>
    );
  };
  const changeBlockedToActive = async () => {
    const { question, putReviewQuestion } = props;

    putReviewQuestion({
      body: { ...question, question_status: 'Active' },
      questionId: question.id,
    });
    setSetActive(true);
  };

  const handleCheckChange = async () => {
    await setChecked(!checked);
    if (checked === true) {
      props.handleSelectedQues(props.question.id);
    } else {
      props.handleDeselectedQues(props.question.id);
    }
  };

  const renderSolution = (question, reportSuggested) => {
    return (
      <>
        <div className="question-details-soluton">
          <Typography variant="subtitle2">Solution</Typography>
          {question.solution_text ? (
            <>
              <Typography
                variant="body2"
                gutterBottom
                className={reportSuggested?.correctSolutionText && 'wrong-text'}
              >
                {ReactHtmlParser(question.solution_text)}
              </Typography>
              {reportSuggested?.correctSolutionText && (
                <Typography variant="body2" gutterBottom color="default">
                  {ReactHtmlParser(reportSuggested?.correctSolutionText)}
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="body2" gutterBottom color="default">
              No solution available
            </Typography>
          )}
        </div>
      </>
    );
  };

  const renderQuestionInfo = (record) => {
    return (
      <>
        <Grid
          item
          container
          spacing={2}
          justifyContent="space-between"
          key={record.label}
        >
          <Grid item>
            <Typography variant="body2">{record.label}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {record.value}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderVotingBox = (reportId, reason, suggestedReport) => {
    const { handleVoteQuestion } = props;
    const { reportDetails } = props;

    return (
      <>
        <section className="review-reason-box">
          <div>
            {reason ? (
              <div>
                <h4 className="review-report-heading">
                  Reason:{' '}
                  <strong>{reason?.split(/(?=[A-Z])/).join(' ')}</strong>
                </h4>
                {suggestedReport?.plagiarisedURL && (
                  <p>
                    Souce Link :{' '}
                    <a href={suggestedReport?.plagiarisedURL}>
                      {suggestedReport?.plagiarisedURL}
                    </a>
                  </p>
                )}
                {suggestedReport?.categoryCorrect && (
                  <p>Correct Category : {suggestedReport?.categoryCorrect}</p>
                )}
                {suggestedReport?.subCategoryCorrect && (
                  <p>
                    Correct Sub categories :{' '}
                    {suggestedReport?.subCategoryCorrect}
                  </p>
                )}
                {suggestedReport?.correctTimeout && (
                  <p>Suggested Timeout : {suggestedReport?.correctTimeout}</p>
                )}
                {suggestedReport?.suggestedUnclear && (
                  <p>{ReactHtmlParser(suggestedReport?.suggestedUnclear)}</p>
                )}
                {suggestedReport?.suggestedAnswer && (
                  <p>Suggested answer : {suggestedReport?.suggestedAnswer}</p>
                )}
              </div>
            ) : (
              <></>
            )}
            <p className="review-report-credit">
              Correct Vote Credits{' '}
              <strong className="review-report-strong"> + 5</strong>
            </p>
            <h4 className="review-report-heading">Vote</h4>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              style={{
                background:
                  reportDetails?.review_type == 'Agree'
                    ? 'rgba(95, 183, 98, 0.19)'
                    : '#FFFFFF',
                border: '1px solid #5FB762',
                borderRadius: '4px',
                width: ' 109px',
                height: '22px',
                padding: '0.9rem',
                marginRight: '10px',
                color: '#5FB762',
              }}
              onClick={() => handleVoteQuestion(reportId, 'Agree')}
              disabled={reportDetails?.review_type ? true : false}
            >
              Agree
            </Button>
            <Button
              style={{
                background:
                  reportDetails?.review_type == 'Disagree'
                    ? 'rgba(224, 106, 106, 0.3)'
                    : '#FFFFFF',
                border: '1px solid #E06A6A',
                borderRadius: '4px',
                width: ' 109px',
                height: '22px',
                padding: '0.9rem',
                marginRight: '10px',
                color: '#E06A6A',
              }}
              onClick={() => handleVoteQuestion(reportId, 'Disagree')}
              disabled={reportDetails?.review_type ? true : false}
            >
              Disagree
            </Button>
            {reportDetails?.review_type ? (
              <p className="review-report-credit">
                Voted{' '}
                {moment(reportDetails?.created_at)
                  .startOf('day')
                  .fromNow()}
              </p>
            ) : (
              <a
                style={{
                  marginRight: '10px',
                  color: '#3572B0',
                  fontWeight: 400,
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => handleVoteQuestion(reportId, 'Skip')}
                disabled={reportDetails?.review_type ? true : false}
              >
                <p>Skip</p>
              </a>
            )}
          </div>
        </section>
      </>
    );
  };

  const {
    question,
    index,
    tagList,
    isQuestionSelected,
    isSolutionCollapse,
    isQuestionPublished,
    isVotingQuestion,
  } = props;

  const selectedCategoryId = question.tag_id ?? null;

  const selectedCategory = tagList?.find((tag) => {
    return tag.id === selectedCategoryId;
  });

  let selectedSubcategories = [];

  if (selectedCategory && selectedCategory.sub_tags) {
    selectedSubcategories = selectedCategory.sub_tags.filter(
      (subTag) => question.sub_tag_array.indexOf(subTag.id) !== -1,
    );
  }

  let buttonsDom;
  if (question && question.report_details && !isVotingQuestion) {
    buttonsDom = renderReportedQuestionButtons();
  } else if (question && question?.question_status) {
    buttonsDom = renderReviewQuestionButtons();
  }

  const examLabel = getExamLabel(question.exam_type).label;

  let {
    question_type,
    score,
    difficulty_level,
    year,
    modified_on,
    created_at,
    max_time,
    total_likes,
    total_dislikes,
    user_data,
    full_report,
  } = question || {};

  let reportSuggested = {};

  if (full_report?.length) {
    switch (full_report[0].reason_type) {
      case 'OptionTextIncorrect':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          correctOptionText: full_report[0].correct_text,
          optionNumber: full_report[0].correct_values?.[0],
        };
        break;
      case 'QuestionTextImprovement':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          correctQuestion: full_report[0].correct_text,
        };
        break;
      case 'OptionIncorrect':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          correctOptionNumber: full_report[0].correct_values?.[0],
        };
        break;
      case 'SolutionTextImprovement':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          correctSolutionText: full_report[0].correct_text,
        };
        break;
      case 'Plagiarised':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          plagiarisedURL: full_report[0].correct_title,
        };
        break;
      case 'Timeout':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          correctTimeout: full_report[0].correct_values?.[0],
        };
        break;
      case 'UnclearContent':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          suggestedUnclear: full_report[0]?.correct_text,
        };
        break;
      case 'NoAnswerCorrect':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          suggestedNoAnswer: full_report[0]?.correct_text,
        };
        break;
      case 'AnswerIncorrect':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          suggestedAnswer: full_report[0]?.correct_text,
        };
        break;
      case 'Other':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          suggestedOther: full_report[0]?.correct_text,
        };
        break;
      case 'Duplicate':
        reportSuggested = {
          report_type: full_report[0].reason_type,
          suggestedDuplicate: full_report[0]?.correct_text,
        };
        break;
      case 'CategoryIncorrect': {
        let findCategory = tagList?.find((tag) => {
          return tag.id === full_report[0].correct_ids[0];
        });
        reportSuggested = {
          report_type: full_report[0].reason_type,
          categoryCorrect: findCategory?.name,
        };
        break;
      }
      case 'SubcategoryIncorrect': {
        let selectedSubcategories = [];
        let getCategory = tagList?.find((tag) => {
          return tag.id === full_report[0].tag_id;
        });
        if (getCategory && getCategory.sub_tags) {
          selectedSubcategories = getCategory.sub_tags.filter(
            (subTag) => full_report[0].correct_ids.indexOf(subTag.id) !== -1,
          );
        }
        reportSuggested = {
          report_type: full_report[0].reason_type,
          subCategoryCorrect: selectedSubcategories
            .map((item) => item.name)
            .join(' , '),
        };
        break;
      }
      default:
        reportSuggested = {};
    }
  }

  const publishedQuestionInfo = [
    {
      label: 'Created By',
      value: question?.user_data
        ? user_data?.first_name + ' ' + user_data?.last_name
        : undefined,
    },
    {
      label: 'Created On',
      value: moment(question?.created_at).format('DD MMM YYYY'),
    },
    {
      label: 'Maximum Time',
      value: question.max_time ? `${question.max_time} sec` : 'No Limit',
    },
    {
      label: 'Exam',
      value: examLabel ? (
        <>
          {examLabel} {!!year && `(${year})`}
        </>
      ) : (
        undefined
      ),
    },
    {
      label: 'Question Attempts',
      value: question.question_attempts ? question.question_attempts : 0,
    },
    {
      label: 'Reporting Frequency',
      value:
        question?.report_details && Object.keys(question.report_details).length,
    },
    {
      label: 'Time Taken',
      value: props.timeTaken,
    },
  ];

  const metaInfo = [
    {
      label: 'Score',
      value: score,
    },
    {
      label: 'Difficulty Level',
      value: difficulty_level,
    },
    {
      label: 'Question Type',
      value: question_type,
    },
    {
      label: 'Exam',
      value: examLabel ? (
        <>
          {examLabel} {!!year && `(${year})`}
        </>
      ) : (
        undefined
      ),
    },
    {
      label: 'Last Updated',
      value:
        modified_on || created_at ? (
          <>
            {moment(modified_on ? modified_on : created_at).format(
              'Do MMM YYYY',
            )}
          </>
        ) : (
          undefined
        ),
    },
    {
      label: 'Max Time',
      value: max_time ? (
        <>{max_time ? `${max_time} sec` : 'No Limit'}</>
      ) : (
        undefined
      ),
    },
    {
      label: 'Likes',
      value: !isNaN(parseInt(total_likes, 10)) ? total_likes : undefined,
    },
    {
      label: 'Dislikes',
      value: !isNaN(parseInt(total_dislikes, 10)) ? total_dislikes : undefined,
    },
  ];

  return (
    <>
      <Accordion className="question-list-item" expanded>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item lg={8} xs={12} style={{ flexGrow: 1 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'flex-start',
              }}
            >
              {!isQuestionSelected ? null : (
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  color="primary"
                  size="small"
                  style={{
                    padding: 0,
                    marginRight: 8,
                    marginTop: '0.1rem',
                  }}
                />
              )}
              {index && (
                <Typography variant="subtitle1" style={{ lineHeight: '1.5' }}>
                  {index} .&nbsp;&nbsp;
                </Typography>
              )}
              {question.title ? (
                <Typography
                  variant="subtitle1"
                  style={{ flex: 1, lineHeight: '1.5' }}
                >
                  {question.title}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ flex: 1, lineHeight: '1.5' }}
                  gutterBottom
                >
                  {question.question_text
                    ? ReactHtmlParser(question.question_text)
                    : 'No question body available'}
                </Typography>
              )}
            </div>
            <div className="question-details-text">
              <Box mb={2}>
                {question.question_status === 'Blocked' &&
                  renderBlockedReason(question)}
              </Box>
              {question.title && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    gutterBottom
                    className={reportSuggested?.correctQuestion && 'wrong-text'}
                    style={{ marginTop: '-16px', marginBottom: '30px' }}
                  >
                    {question.question_text
                      ? ReactHtmlParser(question.question_text)
                      : 'No question body available'}
                  </Typography>
                  {reportSuggested?.correctQuestion && (
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      gutterBottom
                      style={{ marginTop: '-16px', marginBottom: '30px' }}
                    >
                      {ReactHtmlParser(reportSuggested?.correctQuestion)}
                    </Typography>
                  )}
                </>
              )}
              {['MultipleChoice', 'MultipleCorrect'].includes(
                question.question_type,
              ) ? (
                <div>
                  {question.options.map((option, index) => {
                    let optionLabel;
                    let optionClass;

                    if (
                      !props?.attemptedQuestion?.is_attempt_correct &&
                      props?.attemptedQuestion?.attempted_answer_option_no &&
                      props?.attemptedQuestion?.attempted_answer_option_no.includes(
                        option.option_serial_no,
                      )
                    ) {
                      optionLabel = <Icon style={{ fontSize: 18 }}>clear</Icon>;
                      optionClass = 'inactive';
                    } else if (
                      question?.answer_option_no.includes(
                        option?.option_serial_no,
                      )
                    ) {
                      optionLabel = (
                        <Icon style={{ fontSize: 18 }}>
                          {reportSuggested?.correctOptionNumber
                            ? 'clear'
                            : 'check'}
                        </Icon>
                      );
                      optionClass = reportSuggested?.correctOptionNumber
                        ? 'inactive'
                        : 'active';
                    } else {
                      optionLabel = option.option_serial_no;
                    }

                    if (reportSuggested?.correctOptionNumber === index + 1) {
                      optionLabel = <Icon style={{ fontSize: 18 }}>check</Icon>;
                      optionClass = 'active';
                    }

                    return (
                      <div
                        className={classnames('option', optionClass)}
                        key={index}
                      >
                        <div className="option-label">{optionLabel}</div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            className={
                              reportSuggested?.optionNumber == index + 1
                                ? `option-text wrong-text`
                                : `option-text`
                            }
                          >
                            {ReactHtmlParser(option.option_text)}
                          </div>
                          {reportSuggested?.correctOptionText &&
                            reportSuggested?.optionNumber == index + 1 && (
                              <div className="option-text">
                                {ReactHtmlParser(
                                  reportSuggested?.correctOptionText,
                                )}
                              </div>
                            )}
                        </div>
                        {reportSuggested?.correctOptionNumber == index + 1 && (
                          <div className="suggested-changes">
                            <p>Suggested Changes</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {question.question_type === 'OneWord' ? (
                <div>
                  <div className="option active">
                    <div className="option-label">
                      <Icon style={{ fontSize: 18 }}>check</Icon>
                    </div>
                    <div className="option-text">
                      {' '}
                      <Typography variant="body2" gutterBottom>
                        {question.answer_one_word}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : null}

              {isSolutionCollapse ? (
                <>
                  <Button
                    onClick={() => setIsSolutionVisible(!isSolutionVisible)}
                  >
                    {isSolutionVisible ? 'Hide' : 'View'} Solution
                  </Button>
                  {isSolutionVisible &&
                    renderSolution(question, reportSuggested)}
                </>
              ) : (
                renderSolution(question, reportSuggested)
              )}
            </div>
          </Grid>

          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Divider orientation="vertical" />
          </Grid>

          <Grid item lg={4} xs={12}>
            <div>
              {question?.tag_array?.map((item, index) => (
                <Chip
                  variant="outlined"
                  color="success"
                  size="small"
                  label={getTagById(tagList, item).name}
                  style={{ marginBottom: '5px' }}
                  key={index}
                />
              ))}
              {question?.question_status &&
              question?.question_status === 'Blocked' ? (
                <Tooltip
                  title="Click on chip to change question status to Active"
                  arrow
                >
                  <Chip
                    onClick={() => {
                      changeBlockedToActive();
                    }}
                    variant="outlined"
                    color={getStatusChipColor(question)}
                    size="small"
                    label={question.question_status}
                    style={
                      question.question_status === 'Blocked'
                        ? {
                            color: 'red',
                            borderColor: 'red',
                            marginLeft: '8px',
                            marginBottom: '5px',
                          }
                        : { marginLeft: '8px', marginBottom: '5px' }
                    }
                  />
                </Tooltip>
              ) : (
                question?.question_status && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={question.question_status}
                    color={getStatusChipColor(question)}
                    style={
                      question.question_status === 'UnderReview'
                        ? {
                            color: 'rgb(240,210,45)',
                            borderColor: 'rgb(240,210,45)',
                            marginLeft: '8px',
                            marginBottom: '5px',
                          }
                        : { marginLeft: '8px', marginBottom: '5px' }
                    }
                  />
                )
              )}
            </div>

            {isQuestionPublished
              ? publishedQuestionInfo.map((record) => {
                  if (!record.value) return;
                  return renderQuestionInfo(record);
                })
              : metaInfo.map((record) => {
                  if (!record.value) return;
                  return renderQuestionInfo(record);
                })}

            <div className="question-details-info">
              <div className="difficulty" style={{ flexWrap: 'wrap' }}>
                {selectedSubcategories.map((item, index) => {
                  return (
                    <Chip
                      size="small"
                      label={item.name}
                      className="question-tag"
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
        {isVotingQuestion ? (
          full_report ? (
            full_report?.map((item) =>
              renderVotingBox(item.id, item.reason_type, reportSuggested),
            )
          ) : (
            renderVotingBox(reportSuggested)
          )
        ) : (
          <></>
        )}
        {buttonsDom}
      </Accordion>
    </>
  );
};
export default QuestionCard;

QuestionCard.defaultProps = {
  putQuestion: () => {},
};

QuestionCard.propTypes = {
  putQuestion: PropTypes.func,
};
