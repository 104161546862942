import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { excelFieldmapping } from '../../../constants/salesDashbord';
import { postSalesLeadBulk } from '../../../actions/salesDashboard';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const UploadExcelFile = ({ open, onClose, filters, page }) => {
  const [data, setData] = React.useState(null);
  const searchParams = useSearchParams()[0];
  const dispatch = useDispatch();
  const currentSalesUserId = searchParams.get('salesUserId');
  const transformArray = (data, map) => {
    return data.map((item) => {
      const newObj = {};
      for (const [newKey, originalKey] of Object.entries(map)) {
        newObj[newKey] = item[originalKey];
      }
      return newObj;
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setData(sheetData);
    };

    reader.readAsBinaryString(file);
  };

  const handleAddLeadsInBulk = () => {
    const transformedArray = transformArray(data, excelFieldmapping);
    const updatedArray = transformedArray?.map((obj) => {
      const newObj = {
        ...obj,
        phone_number: obj.phone_number.toString(),
      };
      Object.keys(newObj).forEach((key) => {
        if (newObj[key] === 0) {
          delete newObj[key];
        }
      });

      return newObj;
    });

    dispatch(
      postSalesLeadBulk(updatedArray, currentSalesUserId, filters, page),
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {' '}
      <DialogTitle>Upload Your Excel File</DialogTitle>
      <DialogContent>
        <Box>
          <input type="file" onChange={handleFileUpload} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleAddLeadsInBulk}>
          Submit
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadExcelFile;
