import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import Component from './../components/TotalReferrals';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    totalReferrals: state.totalReferrals,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
