import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Avatar,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import {
  deleteCollegeDashboardMilestone,
  getCollegeDashboardMilestone,
} from '../../../actions/collegeDashboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getUserColleges } from '../../../actions/collegeDashboard';
import CollegePlaceholder from '../images/CollegePlaceholder.svg';

export const Milestone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentCollege, setCurrentCollege] = useState('');
  const { userCollegeData } = useSelector((state) => state.collegeDashboard);
  useEffect(() => {
    dispatch(getUserColleges());
  }, []);

  useEffect(() => {
    if (userCollegeData.college_list) {
      setCurrentCollege(userCollegeData?.college_list?.[0].college_id);
    }
  }, [userCollegeData]);

  const options = userCollegeData?.college_list;

  const { milestoneData } = useSelector((state) => state.collegeDashboard);
  useEffect(() => {
    if (currentCollege) {
      dispatch(getCollegeDashboardMilestone(currentCollege));
    }
  }, [dispatch, currentCollege]);

  const handleDeleteBadge = (id) => {
    dispatch(deleteCollegeDashboardMilestone(id, currentCollege));
  };

  const handleEditBadge = (id) => {
    const selectedMilestone = milestoneData?.all_milestone_details?.find(
      (milestone) => milestone.id === id,
    );
    navigate(
      {
        pathname: `/l/editNewMilestone/${id}`,
        search: `${id}`,
      },
      {
        state: { selectedMilestone },
      },
    );
  };
  return (
    <Container maxWidth={false}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <div className="tags-list__header">College Dashboard Milestone</div>
        <div className="create-tag-action">
          <NavLink to="/l/createNewMilestone">
            <Button color="primary" variant="outlined">
              Add Milestone
            </Button>
          </NavLink>
        </div>
      </div>
      <Select
        value={currentCollege}
        onChange={(e) => setCurrentCollege(e.target.value)}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selectedValue) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={selectedValue.image_url || CollegePlaceholder}
              style={{
                width: '30px',
                height: '30px',
                marginRight: '8px',
              }}
            />
            {
              options?.find((option) => option.college_id === selectedValue)
                ?.college_name
            }
          </div>
        )}
        sx={{
          borderRadius: '25px',
          border: '1px solid #25507B',
          background: '#fff',
          height: '50px',
          marginBottom: '10px',
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.college_id} value={option.college_id}>
            <img
              src={option.image_url || CollegePlaceholder}
              alt={option.college_name}
              style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            {option.college_name}
          </MenuItem>
        ))}
      </Select>
      <div className="tagListContainer">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Student Count</TableCell>
              <TableCell>Gems</TableCell>
              <TableCell>Credits</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {milestoneData?.all_milestone_details?.map((data) => (
              <TableRow key={data.id}>
                <TableCell style={{ cursor: 'pointer' }}>
                  <div className="tag__list__name">
                    <Avatar
                      src={data.image_url}
                      className="tag__list__avatar"
                    />
                  </div>
                </TableCell>

                <TableCell style={{ cursor: 'pointer' }}>
                  {data.student_count}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  {data.gems ?? 0}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  {data.credits ?? 0}
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    onClick={() => handleEditBadge(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Delete
                    onClick={() => handleDeleteBadge(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!milestoneData?.all_milestone_details && (
              <p className="no-tag">No Such tag found </p>
            )}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

export default Milestone;
