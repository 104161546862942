import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from './../actions';

import CreateSubtag from './../components/CreateSubtag';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    question: state.question,
    tag: state.tag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubtag);
