import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const dashboard = createReducer(
  {
    dashboardData: {},
    s3ImgUrl: '',
    dashboardTypedata: [],
  },
  {
    [types.GET_DASHBOARD_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingDashboardList: true,
        s3ImgUrl: '',
      });

      return newState;
    },
    [types.GET_DASHBOARD_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        dashboardData: action.data,
        isFetchingDashboardList: false,
      });

      return newState;
    },
    [types.GET_DASHBOARD_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingDashboardList: false,
      });

      return newState;
    },
  },
);

export { dashboard as default };
