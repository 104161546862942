import axios from 'axios';
import * as types from './types';
import config from './../config';
import apiClient from '../api/apiclient';

function getDomainStart() {
  return {
    type: types.GET_DOMAIN_START,
  };
}

function getDomainSuccess({ data, type }) {
  return {
    type: types.GET_DOMAIN_SUCCESS,
    data: data,
    dataType: type,
  };
}

function getDomainFail() {
  return {
    type: types.GET_DOMAIN_FAIL,
  };
}

export const getDomain = (type, status, page_size, name, callback) => {
  return (dispatch) => {
    dispatch(getDomainStart());

    apiClient
      .getDomainApi()
      .domainApiGetDomain(name, undefined, page_size, status, type)
      .then((resp) => {
        if (!callback) dispatch(getDomainSuccess({ data: resp.data, type }));
        // To store the response in local state only. (Should not update the redux state)
        else callback(resp.data);

        return Promise.resolve();
      })
      .catch(() => {
        dispatch(getDomainFail());
      });
  };
};

//Change domain
const changeDomainStart = () => {
  return {
    type: types.CHANGE_DOMAIN_START,
  };
};

const changeDomainSuccess = (domainId) => {
  return {
    type: types.CHANGE_DOMAIN_SUCCESS,
    domainId,
  };
};

const changeDomainFail = () => {
  return {
    type: types.CHANGE_DOMAIN_FAIL,
  };
};

export const changeDomain = (data, callback) => {
  return async (dispatch, getState) => {
    dispatch(changeDomainStart());

    const { auth } = getState();

    await axios
      .put(`${config.API_URL}/user/select_domain`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        dispatch(changeDomainSuccess(data.domain_id));
        callback?.();
      })
      .catch(() => {
        dispatch(changeDomainFail());
      });
  };
};
const createNewDomainStart = () => {
  return {
    type: types.CREATE_NEW_DOMAIN_START,
  };
};

const createNewDomainSuccess = (data) => {
  return {
    type: types.CREATE_NEW_DOMAIN_SUCCESS,
    data: data,
  };
};

const createNewDomainFail = () => {
  return {
    type: types.CREATE_NEW_DOMAIN_FAIL,
  };
};

export const createNewDomain = (data) => {
  return async (dispatch, getState) => {
    dispatch(createNewDomainStart());

    const { auth } = getState();

    await axios
      .post(`${config.API_URL}/domain`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(createNewDomainSuccess(resp.data));
      })
      .catch(() => {
        dispatch(createNewDomainFail());
      });
  };
};
const editDomainStart = () => {
  return {
    type: types.EDIT_DOMAIN_START,
  };
};

const editDomainSuccess = (data) => {
  return {
    type: types.EDIT_DOMAIN_SUCCESS,
    data: data,
  };
};

const editDomainFail = () => {
  return {
    type: types.EDIT_DOMAIN_FAIL,
  };
};

export const editDomain = (data, id) => {
  return async (dispatch, getState) => {
    dispatch(editDomainStart());

    const { auth } = getState();

    await axios
      .put(`${config.API_URL}/domain/${id}`, data, {
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(editDomainSuccess(resp.data));
      })
      .catch(() => {
        dispatch(editDomainFail());
      });
  };
};

export const openDomainModal = () => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_DOMAIN_SELECT_MODAL,
    });
  };
};
export const closeDomainModal = () => {
  return (dispatch) => {
    dispatch({
      type: types.CLOSE_DOMAIN_SELECT_MODAL,
    });
  };
};
