export const solutionVisibility = {
  play: false,
  result: true,
  history: false,
  contribute: true,
  reported: true,
  review: true,
  voting: true,
};

export const hintVisibility = {
  play: false,
  result: true,
  history: false,
  contribute: true,
  reported: true,
  review: true,
  voting: true,
};
export const reportReasons = {
  OptionTextIncorrect: 'Option Text Incorrect',
  QuestionTextImprovement: 'Question Text Improvement',
  OptionIncorrect: 'Option Incorrect',
  SolutionTextImprovement: 'Solution Text Improvement',
  Plagiarised: 'Plagiarised',
  CategoryIncorrect: 'Category Incorrect',
  SubcategoryIncorrect: 'Subcategory Incorrect',
  Timeout: 'Timeout',
  UnclearContent: 'Unclear Content',
  NoAnswerCorrect: 'NoAnswer Correct',
  AnswerIncorrect: 'Answer Incorrect',
  Duplicate: 'Duplicate',
};
