import React, { useRef, useState } from 'react';
import { Link as RedirectLink } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Grid,
  Typography,
  Hidden,
  Divider,
  IconButton,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProfileIcon from './images/profile-icon.svg';

import './index.css';

import { getExamLabel } from '../../constants/examTypes';

import qmcq from '../QuestionList/images/Qtype1.svg';
import qmsq from '../QuestionList/images/MSQ.png';
import qoneword from '../QuestionList/images/Qtype2.png';
import qcomprehensive from '../QuestionList/images/Qtype3.png';

import { Button, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import SegmentedProgressBar from '../PlayProgress/SegmentedProgressBar';
import { useEffect } from 'react';
import { AntSwitch } from '../Category';
import {
  AnalyticsProgressBars,
  CategoryScoreChip,
  DetailRow,
  DetailRowAnswer,
  DifficultyBlock,
  DifficultyLevel,
  PlayAnswerDetailsButton,
  PlayAnswerDetailsChange,
  PlayAnswerDetailsChangeSubcategory,
  PlayAnswerDetailsChangeTyp,
  PlayAnswerDetailsContainer,
  PlayAnswerDetailsNumberss,
  QuestionMapContent,
  QuestionMapContentContainer,
  QuestionMapContentItem,
  QuestionMapContentItemAttempted,
  QuestionMapContentItemSkipped,
  QuestionMapContentItemUnattempted,
  QuestionMapHeader,
  QuestionMapMobile,
  QuestionMapProgress,
  QuestionPreference,
  QuickOptions,
  QuickOptionsCollapsed,
  QuickSettingRow,
  QuickSettingsContainer,
  SelectedSubtopics,
  SettingIcon,
  SettingIconRotated,
  StyledBadge,
  StyledParagraph,
  StyledQuestionMapContentFooter,
  StyledQuestionMapContentFooterItem,
  StyledStatsDetailsContainer,
} from './PlayAnswerDetailsStyledComponent';
import { Header } from '../Dashboard/DashboardStyledComponent';

let oldQuestionMapOpenState = null;
let oldQuestionMapPageState = null;

export const getQuestionTypeName = (questionType) => {
  let name = '';
  let src = '';
  switch (questionType) {
    case 'MultipleChoice': {
      name = 'Multiple Choice Question (MCQ)';
      src = qmcq;
      break;
    }
    case 'MultipleCorrect': {
      name = 'Multiple Select Question (MSQ)';
      src = qmsq;
      break;
    }
    case 'OneWord': {
      name = 'One Word';
      src = qoneword;
      break;
    }
    case 'MultiPart': {
      name = 'Comprehensive';
      src = qcomprehensive;
      break;
    }
  }

  return (
    <>
      <img src={src} alt="icon" />
      {name}
    </>
  );
};

function PlayAnswerDetails({
  question,
  tag,
  examType,
  mockTest,
  competition,
  preferences,
  competitionAnalytics,
  postAnswerSilently,
  handleSubcategoryVisibilityChange,
  onEndTestClick,
  categoryScore,
  setShowCorrectAnswerOnSkip,
  setPurchaseTimeWithoutPopup,
  setAutoReveal,
  setAutoUnlockSolution,
  showCorrectAnswerOnSkip,
  purchaseTimeWithoutPopup,
  autoReveal,
  autoUnlockSolution,
  competitionScore,
  setQuickOptionsCollapsed,
  quickOptionsCollapsed,
}) {
  const timerRef = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [questionMapPage, setQuestionMapPage] = useState(0);
  const [isQuestionMapOpen, setIsQuestionMapOpen] = useState(!isMobile);
  const { data, examAnalytics } = question || {};

  useEffect(() => {
    if (oldQuestionMapOpenState !== null)
      setIsQuestionMapOpen(oldQuestionMapOpenState);
    if (oldQuestionMapPageState !== null)
      setQuestionMapPage(oldQuestionMapPageState);
  }, []);

  useEffect(() => {
    if (timerRef.current) timerRef.current.startTimer();
  }, [question.data?.id, mockTest.data?.id, timerRef.current]);

  useEffect(() => {
    const currentQuestionIndex = examAnalytics.questions?.findIndex(
      (ques) => ques?.id === data?.id,
    );
    if (currentQuestionIndex !== -1) {
      const currentPage = Math.floor(currentQuestionIndex / 30);
      setQuestionMapPage(currentPage);
    }
  }, [data?.id]);

  useEffect(() => {
    oldQuestionMapOpenState = isQuestionMapOpen;
    oldQuestionMapPageState = questionMapPage;
  }, [isQuestionMapOpen, questionMapPage]);

  const selectedCategoryId = data?.tag_id ?? null;
  const selectedCategory = tag.allTags.find(
    (tag) => tag?.id === selectedCategoryId,
  );

  const handleSubcategoryChange = (event) => {
    event.preventDefault();
    handleSubcategoryVisibilityChange(true);
  };

  let selectedSubcategories = [];

  if (selectedCategory && selectedCategory.sub_tags && data?.sub_tag_array) {
    selectedSubcategories = selectedCategory.sub_tags.filter(
      (subTag) => data?.sub_tag_array.indexOf(subTag.id) !== -1,
    );
  }

  const getSelectedTopics = () => {
    const arr = preferences
      ?.filter((choice) => choice.is_selected)
      ?.map((choice, index) => (
        <QuestionPreference
          key={index}
          size="small"
          label={choice.name}
          color="primary"
          variant="outlined"
        />
      ));
    if (arr?.length > 0) return arr;
    return preferences?.map((choice, index) => (
      <QuestionPreference
        key={index}
        size="small"
        label={choice.name}
        color="primary"
        variant="outlined"
      />
    ));
  };

  const {
    streakCount,
    streakTarget,

    streakDayTarget,
    streakDayCount,
  } = question;

  const attemptedQuestionsPercent = (
    (examAnalytics.statistics?.attempted / examAnalytics.questions?.length) *
    100
  ).toFixed(2);
  const skippedQuestionPercent = (
    (examAnalytics.statistics?.skipped / examAnalytics.questions?.length) *
    100
  ).toFixed(2);
  const unattemptedQuestionPercent = (
    (examAnalytics.statistics?.unattempted / examAnalytics.questions?.length) *
    100
  ).toFixed(2);

  const examLabel = getExamLabel(data.exam_type).label;
  const totalAttendedQuestions =
    competitionAnalytics?.total_correct +
    competitionAnalytics?.total_incorrect +
    competitionAnalytics?.timed_out +
    competitionAnalytics?.skipped;

  const correctAnswerOnSkipStatus = (e) => {
    const switchValue = e.target.checked;
    setShowCorrectAnswerOnSkip(switchValue);
    localStorage.setItem('showCorrectAnswerOnSkip', switchValue);
  };
  const purchaseTimeWithoutPopupStatus = (e) => {
    const switchValue = e.target.checked;
    setPurchaseTimeWithoutPopup(switchValue);
    localStorage.setItem('purchaseTimeWithoutPopup', switchValue);
  };
  const changeAutoRevealStatus = (e) => {
    const switchValue = e.target.checked;
    setAutoReveal(switchValue);
    localStorage.setItem('autoReveal', switchValue);
  };
  const changeUnlockSolStatus = (e) => {
    const switchValue = e.target.checked;
    setAutoUnlockSolution(switchValue);
    localStorage.setItem('autoUnlockSolution', switchValue);
  };

  const heading =
    examType === 'mockTest'
      ? mockTest?.data?.name
      : examType === 'competition'
      ? competition?.name
      : '';

  return (
    <Hidden mdDown>
      <PlayAnswerDetailsContainer>
        {examType !== 'mockTest' ? (
          <CategoryScoreChip
            label={`${
              examType === 'competition'
                ? `Total Score: ${competitionScore}`
                : `Category Score: ${categoryScore}`
            }`}
          />
        ) : (
          ''
        )}

        {(examType === 'competition' || examType === 'mockTest') && (
          <Typography
            color="primary"
            fontSize={'16px'}
            fontWeight={'400'}
            marginTop={2}
          >
            {heading}
          </Typography>
        )}

        {!question?.error && (
          <Hidden mdDown>
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="column"
                bgcolor="background.paper"
              >
                {examType !== 'mockTest' && examType !== 'competition' && (
                  <Hidden mdDown>
                    <Box my="10px">
                      <DetailRow>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight="500"
                        >
                          Streak
                        </Typography>
                        <SegmentedProgressBar
                          strokeColor="#43C73F"
                          trailColor="#E9ECEF"
                          value={(streakCount * 100) / streakTarget}
                          attempt={question.streakCount}
                          total={question.streakTarget}
                          isStreak={true}
                          width={'140px'}
                        />
                      </DetailRow>
                      <DetailRow>
                        <Typography
                          variant="body1"
                          color="primary"
                          fontWeight="500"
                        >
                          Spree
                        </Typography>
                        <SegmentedProgressBar
                          strokeColor="#65257B"
                          trailColor="#E9ECEF"
                          value={(streakDayCount * 100) / streakDayTarget}
                          attempt={question.streakDayCount}
                          total={question.streakDayTarget}
                          width={'140px'}
                        />
                      </DetailRow>
                    </Box>
                  </Hidden>
                )}
                <DetailRow>
                  <DifficultyBlock>
                    <Typography variant="body2" gutterBottom fontWeight="500">
                      Author
                    </Typography>
                    <RedirectLink
                      to={`/profile/${data.created_by_username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DetailRowAnswer
                        variant="body2"
                        gutterBottom
                        color="primary"
                      >
                        <img src={ProfileIcon} alt="profile-icon" />
                        {data.created_by_name}
                      </DetailRowAnswer>
                    </RedirectLink>
                  </DifficultyBlock>
                </DetailRow>
                {!!examLabel && (
                  <DetailRow>
                    <DifficultyBlock>
                      <Typography variant="body2" gutterBottom>
                        Exam
                      </Typography>
                    </DifficultyBlock>
                    <DifficultyLevel>
                      <Typography variant="body2" gutterBottom>
                        {examLabel} {!!question.year && `(${question.year})`}
                      </Typography>
                    </DifficultyLevel>
                  </DetailRow>
                )}
                <>
                  {examType === 'competition' && (
                    <DetailRow>
                      <DifficultyBlock>
                        <Typography
                          variant="body2"
                          gutterBottom
                          fontWeight="500"
                        >
                          Attempted Question
                        </Typography>
                        <DetailRowAnswer color="primary">
                          {competitionAnalytics.total_attempted}/
                          {
                            competitionAnalytics.total_competition_question_count
                          }
                        </DetailRowAnswer>
                      </DifficultyBlock>
                    </DetailRow>
                  )}
                  <DetailRow>
                    <DifficultyBlock>
                      <Typography variant="body2" gutterBottom fontWeight="500">
                        Category
                      </Typography>
                      <DetailRowAnswer
                        variant="body2"
                        gutterBottom
                        color="primary"
                      >
                        <img
                          src={selectedCategory?.s3_url}
                          alt="cat-icon"
                          width={22}
                          height={22}
                        />
                        {selectedCategory && selectedCategory.name}
                      </DetailRowAnswer>
                    </DifficultyBlock>
                  </DetailRow>
                  <DetailRow>
                    <DifficultyBlock>
                      <Typography variant="body2" gutterBottom fontWeight="500">
                        Subtopic
                      </Typography>
                      <Box display={'flex'} flexWrap={'wrap'} gap="5px">
                        {selectedSubcategories.slice(0, 5).map((item, i) => {
                          return (
                            <QuestionPreference
                              key={i}
                              size="small"
                              label={item.name}
                              color="primary"
                              variant="outlined"
                            />
                          );
                        })}
                        {selectedSubcategories?.length > 5 && (
                          <QuestionPreference
                            size="small"
                            label={`+${selectedSubcategories?.length - 5} more`}
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </Box>
                    </DifficultyBlock>
                  </DetailRow>
                  <DetailRow>
                    <DifficultyBlock>
                      <Typography
                        variant="body2"
                        gutterBottom
                        fontWeight={'500'}
                      >
                        Question Type
                      </Typography>
                      <DetailRowAnswer
                        variant="body2"
                        gutterBottom
                        color="primary"
                      >
                        {getQuestionTypeName(question?.data?.question_type)}
                      </DetailRowAnswer>
                    </DifficultyBlock>
                  </DetailRow>

                  {examType === 'mockTest' && (
                    <DetailRow>
                      <DifficultyBlock>
                        <Typography
                          variant="body2"
                          gutterBottom
                          fontWeight={'500'}
                        >
                          Mocktest Mode
                        </Typography>
                        <DetailRowAnswer
                          variant="body2"
                          gutterBottom
                          color="primary"
                        >
                          {mockTest?.data?.exam_mode}
                        </DetailRowAnswer>
                      </DifficultyBlock>
                    </DetailRow>
                  )}
                  {examType === 'competition' && (
                    <DetailRow>
                      <DifficultyBlock>
                        <Typography
                          variant="body2"
                          gutterBottom
                          fontWeight={'500'}
                        >
                          End Date
                        </Typography>
                        <DetailRowAnswer
                          variant="body2"
                          gutterBottom
                          color="primary"
                        >
                          {moment(competition.end_date).format('Do MMMM YYYY')}
                        </DetailRowAnswer>
                      </DifficultyBlock>
                    </DetailRow>
                  )}
                </>
                {examType !== 'competition' && examType !== 'mockTest' ? (
                  <Grid mt="15px">
                    <PlayAnswerDetailsChange variant="h6" gutterBottom>
                      Selected Subtopics
                      <a href="" onClick={handleSubcategoryChange}>
                        <PlayAnswerDetailsChangeSubcategory color="primary" />
                      </a>
                    </PlayAnswerDetailsChange>
                    <SelectedSubtopics>{getSelectedTopics()}</SelectedSubtopics>
                  </Grid>
                ) : null}
              </Box>
            </Box>
          </Hidden>
        )}
        <Box>
          <QuestionMapHeader>
            {examType === 'competition' && (
              <div style={{ width: '100%' }}>
                <Header>Live Stats</Header>

                <AnalyticsProgressBars>
                  <span
                    className="green"
                    style={{
                      width: `calc(${(competitionAnalytics.total_correct /
                        totalAttendedQuestions) *
                        100}% + 3px)`,
                    }}
                  />
                  <span
                    className="red"
                    style={{
                      width: `calc(${(competitionAnalytics.total_incorrect /
                        totalAttendedQuestions) *
                        100}% + 3px)`,
                    }}
                  />
                  <span
                    className="yellow"
                    style={{
                      width: `calc(${(competitionAnalytics.skipped /
                        totalAttendedQuestions) *
                        100}% + 3px)`,
                    }}
                  />
                  <span
                    className="purple"
                    style={{
                      width: `calc(${(competitionAnalytics.timed_out /
                        totalAttendedQuestions) *
                        100}% + 3px)`,
                    }}
                  />
                </AnalyticsProgressBars>

                <StyledStatsDetailsContainer>
                  <PlayAnswerDetailsNumberss
                    style={{
                      backgroundColor: '#D4EDDA',
                      border: '1px solid #4caf50',
                    }}
                  >
                    <p className="green">
                      {competitionAnalytics.total_correct}
                    </p>
                    <Typography variant="caption" gutterBottom>
                      Correct
                    </Typography>
                  </PlayAnswerDetailsNumberss>

                  <PlayAnswerDetailsNumberss
                    style={{
                      backgroundColor: '#ebd6d6',
                      border: '1px solid #f44336',
                    }}
                  >
                    <p className="red">
                      {competitionAnalytics.total_incorrect}
                    </p>
                    <Typography variant="caption" gutterBottom>
                      Incorrect
                    </Typography>
                  </PlayAnswerDetailsNumberss>

                  <PlayAnswerDetailsNumberss
                    style={{
                      backgroundColor: '#DAE5F0',
                      border: '1px solid #21A9DE',
                    }}
                  >
                    <p className="purple">{competitionAnalytics.timed_out}</p>
                    <Typography variant="caption" gutterBottom style={{}}>
                      Timeout
                    </Typography>
                  </PlayAnswerDetailsNumberss>
                </StyledStatsDetailsContainer>

                <StyledStatsDetailsContainer>
                  <div style={{ width: '32%' }}>
                    <PlayAnswerDetailsNumberss
                      style={{
                        backgroundColor: '#EE9D0014',
                        border: '1px solid rgb(233, 196, 106)',
                      }}
                    >
                      <p className="yellow">{competitionAnalytics.skipped}</p>
                      <Typography variant="caption" gutterBottom>
                        Skipped
                      </Typography>
                    </PlayAnswerDetailsNumberss>
                  </div>

                  <PlayAnswerDetailsNumberss
                    style={{ border: '1px solid #25507B' }}
                  >
                    <p className="blue">
                      {competitionAnalytics.accuracy?.toFixed(2)} %
                    </p>
                    <Typography variant="caption" gutterBottom>
                      Accuracy
                    </Typography>
                  </PlayAnswerDetailsNumberss>
                </StyledStatsDetailsContainer>
              </div>
            )}
            {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
              <Hidden smUp>
                <QuestionMapMobile>
                  <QuestionMapProgress>
                    {attemptedQuestionsPercent > 0 && (
                      <QuestionMapContentItemAttempted
                        style={{ width: `${attemptedQuestionsPercent}%` }}
                        data-value={examAnalytics.statistics?.attempted}
                      />
                    )}
                    {skippedQuestionPercent > 0 && (
                      <QuestionMapContentItemSkipped
                        style={{ width: `${skippedQuestionPercent}%` }}
                        data-value={examAnalytics.statistics?.skipped}
                      />
                    )}
                    {unattemptedQuestionPercent > 0 && (
                      <QuestionMapContentItemUnattempted
                        style={{ width: `${unattemptedQuestionPercent}%` }}
                        data-value={examAnalytics.statistics?.unattempted}
                      />
                    )}
                  </QuestionMapProgress>
                  <PlayAnswerDetailsButton
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={() => setIsQuestionMapOpen(!isQuestionMapOpen)}
                  >
                    Question Map
                    {isQuestionMapOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </PlayAnswerDetailsButton>
                </QuestionMapMobile>
              </Hidden>
            )}
          </QuestionMapHeader>
          {examType === 'mockTest' && mockTest.data.exam_mode === 'flexible' && (
            <Hidden mdDown>
              <PlayAnswerDetailsChangeTyp variant="h6" gutterBottom>
                Question Map
              </PlayAnswerDetailsChangeTyp>
            </Hidden>
          )}
          {examType === 'mockTest' &&
            mockTest.data.exam_mode === 'flexible' &&
            examAnalytics.questions &&
            isQuestionMapOpen && (
              <QuestionMapContent>
                <QuestionMapContentContainer>
                  <PlayAnswerDetailsButton
                    disabled={questionMapPage === 0}
                    onClick={() => setQuestionMapPage(questionMapPage - 1)}
                  >
                    <ChevronLeftIcon />
                  </PlayAnswerDetailsButton>

                  {examAnalytics?.questions
                    .slice(
                      questionMapPage * 30,
                      Math.min(
                        questionMapPage * 30 + 30,
                        examAnalytics.questions?.length,
                      ),
                    )
                    .map((ques) => (
                      <QuestionMapContentItem
                        onClick={() => postAnswerSilently(false, ques.id)}
                        key={ques.id}
                        className={classNames({
                          skipped: ques.status === 'skipped',
                          attempted:
                            ques.status === 'attempted' && ques.id !== data.id,
                          current: ques.id === data.id,
                        })}
                      >
                        <span>{ques.custom_mocktest_question_num}</span>
                      </QuestionMapContentItem>
                    ))}

                  <PlayAnswerDetailsButton
                    disabled={
                      questionMapPage * 30 + 30 >=
                      examAnalytics.questions?.length
                    }
                    onClick={() => setQuestionMapPage(questionMapPage + 1)}
                  >
                    <ChevronRightIcon />
                  </PlayAnswerDetailsButton>
                </QuestionMapContentContainer>

                <Divider />

                <StyledQuestionMapContentFooter>
                  <StyledQuestionMapContentFooterItem>
                    <StyledBadge
                      badgeContent=" "
                      variant="dot"
                      className="attempted"
                    />
                    <StyledParagraph>
                      Attempted ({`${attemptedQuestionsPercent}%`})
                    </StyledParagraph>
                  </StyledQuestionMapContentFooterItem>

                  <StyledQuestionMapContentFooterItem>
                    <StyledBadge
                      badgeContent=" "
                      variant="dot"
                      className="skipped"
                    />
                    <StyledParagraph>
                      Skipped ({`${skippedQuestionPercent}%`})
                    </StyledParagraph>
                  </StyledQuestionMapContentFooterItem>

                  <StyledQuestionMapContentFooterItem>
                    <StyledBadge
                      badgeContent=" "
                      variant="dot"
                      className="unattempted"
                    />
                    <StyledParagraph>
                      Unsolved ({`${unattemptedQuestionPercent}%`})
                    </StyledParagraph>
                  </StyledQuestionMapContentFooterItem>

                  <StyledQuestionMapContentFooterItem>
                    <StyledBadge
                      badgeContent=" "
                      variant="dot"
                      className="current"
                    />
                    <StyledParagraph>Current</StyledParagraph>
                  </StyledQuestionMapContentFooterItem>
                </StyledQuestionMapContentFooter>
              </QuestionMapContent>
            )}

          {examType === 'mockTest' && (
            <Hidden smUp>
              <div className="end-btn">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onEndTestClick}
                >
                  Submit Test
                </Button>
              </div>
            </Hidden>
          )}
        </Box>
        {examType !== 'mockTest' ? (
          <Grid>
            <Divider />
            {quickOptionsCollapsed ? (
              <QuickOptions>
                <QuickOptionsCollapsed>
                  <QuickSettingRow>
                    <Typography color="primary" fontWeight="500">
                      Auto Reveal Answer
                    </Typography>
                    <AntSwitch
                      checked={autoReveal}
                      onChange={changeAutoRevealStatus}
                    />
                  </QuickSettingRow>
                  <QuickSettingRow>
                    <Typography color="primary" fontWeight="500">
                      Unlock Solution Without Pop-Up
                    </Typography>

                    <AntSwitch
                      checked={autoUnlockSolution}
                      onChange={changeUnlockSolStatus}
                    />
                  </QuickSettingRow>
                  <QuickSettingRow>
                    <Typography color="primary" fontWeight="500">
                      Show Correct Answer on Skip
                    </Typography>
                    <AntSwitch
                      checked={showCorrectAnswerOnSkip}
                      onChange={correctAnswerOnSkipStatus}
                    />
                  </QuickSettingRow>
                  <QuickSettingRow>
                    <Typography color="primary" fontWeight="500">
                      Purchase Time Without Popup
                    </Typography>
                    <AntSwitch
                      checked={purchaseTimeWithoutPopup}
                      onChange={purchaseTimeWithoutPopupStatus}
                    />
                  </QuickSettingRow>
                </QuickOptionsCollapsed>
              </QuickOptions>
            ) : (
              <QuickOptions>
                <QuickSettingRow>
                  <Typography color="primary" fontWeight="500">
                    Auto Reveal Answer
                  </Typography>
                  <AntSwitch
                    checked={autoReveal}
                    onChange={changeAutoRevealStatus}
                  />
                </QuickSettingRow>
                <QuickSettingRow>
                  <Typography color="primary" fontWeight="500">
                    Unlock Solution Without Pop-Up
                  </Typography>

                  <AntSwitch
                    checked={autoUnlockSolution}
                    onChange={changeUnlockSolStatus}
                  />
                </QuickSettingRow>
                <QuickSettingRow>
                  <Typography color="primary" fontWeight="500">
                    Show Correct Answer on Skip
                  </Typography>
                  <AntSwitch
                    checked={showCorrectAnswerOnSkip}
                    onChange={correctAnswerOnSkipStatus}
                  />
                </QuickSettingRow>
                <QuickSettingRow>
                  <Typography color="primary" fontWeight="500">
                    Purchase Time Without Popup
                  </Typography>
                  <AntSwitch
                    checked={purchaseTimeWithoutPopup}
                    onChange={purchaseTimeWithoutPopupStatus}
                  />
                </QuickSettingRow>
              </QuickOptions>
            )}
            <QuickSettingsContainer>
              <Typography fontWeight={'500'} color="black" fontSize={'16px'}>
                Quick Settings
              </Typography>
              <IconButton
                color="primary"
                onClick={() => setQuickOptionsCollapsed((curr) => !curr)}
              >
                {quickOptionsCollapsed ? (
                  <SettingIcon>
                    <SettingIconRotated />
                  </SettingIcon>
                ) : (
                  <SettingIcon />
                )}
              </IconButton>
            </QuickSettingsContainer>
            <Divider />
          </Grid>
        ) : null}
      </PlayAnswerDetailsContainer>
    </Hidden>
  );
}

export default PlayAnswerDetails;
