import {
  Box,
  Button,
  InputBase,
  Typography,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import MessageBar from '../MessageBar';
import ConsumeCreditModal from '../ConsumeCreditModal';
import Result from './Result';
import PlusIcon from './images/plus.svg';
import MockTestStartDialog from './MockTestStartDialog';
import MockTestResumeDialog from './MockTestResumeDialog';
import { MockTestContainer } from './StyledComponents';
import AllTestTab from './components/AllTestTab';
import './index.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyTestTab from './components/MyTestTab';

function Filter({
  show,
  searchText,
  onSearch,
  selectedTag,
  onTagChange,
  tagList,
}) {
  if (!show) return null;

  return (
    <Box display="flex" className="mocktest__header">
      <InputBase
        endAdornment={<SearchIcon />}
        placeholder="Search here..."
        className="mocktest__header--search"
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
      />
      <ReactSelect
        placeholder="Select Category"
        options={[{ name: 'All', id: null }, ...tagList]}
        onChange={(e) => onTagChange(e)}
        getOptionLabel={(tag) => tag.name}
        getOptionValue={(tag) => tag.id}
        value={selectedTag}
        className="mocktest__header--select"
      />
    </Box>
  );
}

function MockTest(props) {
  const {
    mockTest,
    user,
    clearMockTest,
    getMockTest,
    postMockTest,
    startMockTest,
    deleteMockTest,
    getMockTestPrerequisite,
    tagList,
  } = props;
  const { search, state } = useLocation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedMockTestId, setSelectedMockTestId] = useState();
  const [confirmBuyMockTest, setConfirmBuyMockTest] = useState({
    mockTest: null,
  });
  const [confirmDeleteMockTest, setConfirmDeleteMockTest] = useState({
    mockTest: null,
  });
  const [message, setMessage] = useState();
  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(search).get('tab') || 'allTest',
  );
  const [page, setPage] = useState(1);

  const [openStartModal, setOpenStartModal] = useState(false);
  const [mockTestDetails, setMockTestDetails] = useState([]);
  const [openResumeTestModal, setOpenResumeTestModal] = useState(false);
  const [mocktestList, setMocktestList] = useState([]);

  useEffect(() => {
    if (mockTest.isCreateMockTestSuccess)
      setMessage({
        type: 'success',
        content: 'Mock Test Created Successfully',
      });
  }, [mockTest.isCreateMockTestSuccess]);

  useEffect(() => {
    if (mockTest.deleteMockTestSuccess) {
      setMessage({
        type: 'success',
        content: 'Mock Test Deleted Successfully',
      });
    } else if (mockTest.deleteMockTestFail) {
      setMessage({
        type: 'error',
        content: "Couldn't delete mock test",
      });
    }
    setConfirmDeleteMockTest({ mockTest: null });
  }, [mockTest.deleteMockTestSuccess, mockTest.deleteMockTestFail]);

  useEffect(() => {
    if (mockTest.isPostMockTestSuccess && mockTest.postedMockTestId) {
      setMessage({
        type: 'success',
        content: 'Mock Test Purchased Successfully',
      });
      setCurrentTab('myTest');
      getMockTest(
        {
          domain_id: user.data.selected_domain,
          examType: currentTab === 'allTest' ? 'template' : 'user_exam',
          name: searchText,
          page: 1,
          pageSize: 10,
          selectedTag: selectedTag?.id,
        },
        (newData) => {
          setMocktestList(newData);
        },
      );
      setConfirmBuyMockTest(() => ({
        mockTest: { id: mockTest.postedMockTestId },
        open: false,
        highlight: true,
      }));
      setTimeout(() => {
        setConfirmBuyMockTest({ mockTest: null });
      }, 2500);
    }
  }, [mockTest.isPostMockTestSuccess, mockTest.postedMockTestId]);

  useEffect(() => {
    if (mockTest.error)
      setMessage({
        type: 'error',
        content: mockTest.error,
      });
  }, [mockTest.error]);

  useEffect(() => {
    // when user clicks on start test
    // after posting the mock test to backend redirect to play page
    if (!mockTest.isFetchingMockTest && mockTest.mockTestSuccessId)
      navigate(`/l/answer/${mockTest.mockTestSuccessId}/mockTest`);
  }, [mockTest.mockTestSuccessId, mockTest.isFetchingMockTest]);

  useEffect(() => {
    setPage(1);
    const { selected_domain } = user.data;
    if (selected_domain)
      getMockTest(
        {
          domain_id: selected_domain,
          examType: currentTab === 'allTest' ? 'template' : 'user_exam',
          name: searchText,
          page: page,
          pageSize: 10,
          selectedTag: selectedTag?.id,
        },
        (newData) => {
          setMocktestList(newData);
        },
      );
    // clear state on unmount
    return clearMockTest;
  }, [user.data, currentTab, searchText, selectedTag]);
  useEffect(() => {
    // set the current tab on url (to persist state)
    navigate('/l/mockTest?tab=' + currentTab, { replace: true });
    setSelectedMockTestId(undefined);
    setPage(1);
    setMocktestList([]);
  }, [currentTab]);

  useEffect(() => {
    if (state?.mockTest) {
      setSelectedMockTestId(state.mockTest);
    }
  }, [state]);
  useEffect(() => {
    setSelectedTag([]);
  }, [user.data]);

  useEffect(() => {
    if (state?.dashboardData && state?.from === '/l/dashbaord') {
      if (state?.is_unlocked) {
        setConfirmBuyMockTest({ open: false, mockTest: state?.dashboardData });
        const test = {
          ...state?.dashboardData,
          parent_mocktest_id: state?.dashboardData?.id,
        };
        delete test.is_template;
        delete test.id;
        delete test.is_unlocked;
        delete test.total_duration;
        delete test.is_completed;
        delete test.started_on;
        delete test.is_template_active;

        postMockTest(test);
      }
    }
  }, []);

  const onStartTest = (test) => {
    if (test.is_completed) {
      setSelectedMockTestId(test.id);
    } else if (test.started_on) navigate(`/l/answer/${test.id}/mockTest`);
    else startMockTest(test.id);
  };

  const onBuyTest = () => {
    const test = {
      ...confirmBuyMockTest.mockTest,
      parent_mocktest_id: confirmBuyMockTest.mockTest.id,
    };
    delete test.is_template;
    delete test.id;
    delete test.is_unlocked;
    delete test.total_duration;
    delete test.is_completed;
    delete test.started_on;
    delete test.is_template_active;

    postMockTest(test);
  };

  const onDeleteTest = () => {
    const { selected_domain } = user.data;
    deleteMockTest(
      confirmDeleteMockTest.mockTest.id,
      selected_domain,
      currentTab === 'allTest' ? 'template' : 'user_exam',
      searchText,
      page,
      10,
      selectedTag.id,
    );
  };

  const onBack = () => {
    navigate('/l/mockTest?tab=myTest', { replace: true });
    setSelectedMockTestId(undefined);
    setMocktestList([]);
  };

  const handleStartModal = (test) => {
    setOpenStartModal(true);
    setMockTestDetails(test);
  };
  const handleResumeModal = (test) => {
    setOpenResumeTestModal(true);
    setMockTestDetails(test);
  };
  const handleNextPage = () => {
    const { selected_domain } = user.data;
    if (selected_domain) {
      getMockTest(
        {
          domain_id: selected_domain,
          examType: currentTab === 'allTest' ? 'template' : 'user_exam',
          name: searchText,
          page: page,
          pageSize: 10,
          selectedTag: selectedTag?.id,
        },
        (newData) => {
          setMocktestList((list) => list?.concat(newData));
        },
      );
    }
  };

  useEffect(() => {
    if (page > 1) {
      handleNextPage();
    }
  }, [page]);

  return (
    <MockTestContainer
      style={{ marginTop: '20px', overflow: 'auto' }}
      id="scrollableContainer"
    >
      {user.data?.user_type === 'Admin' && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: '25px', mb: { xs: '10px', md: 0 } }}
          onClick={() => navigate('/l/mockTest/create')}
        >
          <img src={PlusIcon} alt="" /> &nbsp;Create
        </Button>
      )}
      <MessageBar
        message={message?.content}
        type={message?.type}
        open={!!message}
        onClose={() => setMessage(undefined)}
      />
      <Box
        display="flex"
        justifyContent={'space-around'}
        className={'toggleBox_tab'}
        mb={2}
      >
        <Typography
          variant="subtitle"
          onClick={() => {
            setCurrentTab('allTest');
            setPage(1);
          }}
          className={'toggleText_tab'}
          style={{
            background: currentTab !== 'myTest' && '#25507B',
            color: currentTab !== 'myTest' ? 'white' : 'black',
          }}
        >
          Test Store
        </Typography>
        <Typography
          variant="subtitle"
          onClick={() => {
            setCurrentTab('myTest');
            setPage(1);
          }}
          className={'toggleText_tab'}
          style={{
            background: currentTab === 'myTest' && '#25507B',
            color: currentTab === 'myTest' ? 'white' : 'black',
          }}
        >
          My Tests
        </Typography>
      </Box>

      <Filter
        show={!selectedMockTestId}
        searchText={searchText}
        onSearch={(txt) => setSearchText(txt)}
        tagList={tagList}
        selectedTag={selectedTag}
        onTagChange={setSelectedTag}
      />

      <>
        {currentTab === 'allTest' && (
          <InfiniteScroll
            dataLength={mocktestList?.length}
            next={() => setPage(page + 1)}
            hasMore={mockTest.data?.page_no < mockTest.data?.total_pages}
            loader={
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            }
            scrollThreshold={0.9}
            style={{ overflow: 'none' }}
            scrollableTarget="scrollableContainer"
          >
            <AllTestTab
              show={currentTab === 'allTest'}
              isAdmin={user.data?.user_type === 'Admin'}
              templates={mocktestList}
              paginationInfo={mockTest.data}
              prerequisite={mockTest?.prerequisite}
              getMockTestPrerequisite={getMockTestPrerequisite}
              tagList={tagList}
              onBuy={(test) =>
                setConfirmBuyMockTest({ open: true, mockTest: test })
              }
              onDelete={(test) =>
                setConfirmDeleteMockTest({ open: true, mockTest: test })
              }
            />
          </InfiniteScroll>
        )}

        {selectedMockTestId && (
          <Result
            {...props}
            selectedMockTest={mockTest.data.mocktest?.find(
              (t) => t.id === selectedMockTestId,
            )}
            onBack={onBack}
          />
        )}
        {currentTab === 'myTest' && (
          <InfiniteScroll
            dataLength={mocktestList?.length}
            next={() => setPage((page) => page + 1)}
            hasMore={mockTest.data?.page_no < mockTest.data?.total_pages}
            loader={
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            }
            scrollThreshold={0.9}
            style={{ overflow: 'none' }}
            scrollableTarget="scrollableContainer"
          >
            <MyTestTab
              show={currentTab === 'myTest' && !selectedMockTestId}
              highlightedTest={
                confirmBuyMockTest.highlight
                  ? confirmBuyMockTest.mockTest
                  : null
              }
              templates={mocktestList}
              tagList={tagList}
              onStartTest={onStartTest}
              handleStartModal={handleStartModal}
              handleResumeModal={handleResumeModal}
            />
          </InfiniteScroll>
        )}
      </>

      <ConsumeCreditModal
        open={confirmBuyMockTest.open}
        loading={mockTest.isPostingMockTest}
        onClose={() => setConfirmBuyMockTest({ mockTest: null })}
        title="Buy Mock Test"
        credits={confirmBuyMockTest.mockTest?.cost_in_credits}
        message="Are you sure want to buy the mock test?"
        onYes={onBuyTest}
      />
      <ConsumeCreditModal
        open={confirmDeleteMockTest.open}
        loading={mockTest.isDeletingMockTest}
        onClose={() => setConfirmDeleteMockTest({ mockTest: null })}
        title="Delete Mock Test"
        message="Are you sure want to delete the mock test?"
        onYes={onDeleteTest}
      />
      {openStartModal ? (
        <MockTestStartDialog
          openModal={openStartModal}
          handleModalClose={() => setOpenStartModal(false)}
          mockTestDetails={mockTestDetails}
          onStartTest={onStartTest}
          tagList={tagList}
        />
      ) : null}
      {openResumeTestModal ? (
        <MockTestResumeDialog
          openModal={openResumeTestModal}
          handleModalClose={() => setOpenResumeTestModal(false)}
          mockTestDetails={mockTestDetails}
          onStartTest={onStartTest}
        />
      ) : null}
    </MockTestContainer>
  );
}

export default MockTest;
