import React, { useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import RightToggleIcon from './images/RightToggleIcon.svg';
import Invitation from './images/invitation.svg';
import StudentCount from './images/studentCount.svg';
import PracticeVector from './images/practiceVector.svg';

const HotTopics = ({ styles, headline, collegeRelatedData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const hotTopics = collegeRelatedData?.trending_topics?.global_trend;
  const currentItem = hotTopics?.[currentIndex];
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % hotTopics?.length);
  };
  return (
    <>
      <span className={styles.contentLeft_Heading1}>{headline}</span>
      <div
        style={{
          borderRadius: '10px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
          margin: '15px 0px 20px 0px',
          position: 'relative',
        }}
      >
        <div style={{ padding: '20px' }}>
          <Box display={'flex'} alignItems={'center'}>
            <img
              src={currentItem?.s3_url || Invitation}
              alt={''}
              width={'50px'}
            />{' '}
            &nbsp;&nbsp;&nbsp;
            <Box>
              <span className={styles.hotTopicsHeading}>
                {currentItem?.name}
              </span>
              <Box className={styles.tagContainer}>
                <Chip
                  label={currentItem?.domain_name}
                  size="small"
                  className={styles.tag}
                />{' '}
              </Box>
            </Box>
          </Box>
          <div>
            <span className={styles.hotTopicsDescription}>
              {currentItem?.description}
            </span>
          </div>

          <Box className={styles.hotTopicsStudentCount}>
            <img src={StudentCount} alt="Students Playing" />
            &nbsp;&nbsp; 25 Students Playing
          </Box>

          <a className={styles.practiceBtn} href="/l/practice">
            {' '}
            <img src={PracticeVector} alt={'practice Vector'} />
            &nbsp;&nbsp; Practice
          </a>
        </div>
        <Box
          textAlign="right"
          sx={{ position: 'absolute', top: '45%', right: '-20px' }}
        >
          <IconButton onClick={handleNextClick}>
            <img src={RightToggleIcon} width={'20px'} />
          </IconButton>
        </Box>
      </div>
    </>
  );
};

export default HotTopics;
