import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from '../components/Competition/index';
import ActionCreators from './../actions';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    competitions: state.competitions,
    user: state.user,
    auth: state.auth,
    notifications: state.notifications,
    question: state.question,
    domain: state.domain,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
