import React from 'react';
import classnames from 'classnames';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Checkbox from '@mui/material/Checkbox';
import { InputBase } from '@mui/material';
import './index.css';

export default function SplitButton({ options, onClick, type }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [arrayReasons, setArrayReasons] = React.useState(
    options.map(() => {
      return { checked: false };
    }),
  );
  const [optionReasons, setOptionReasons] = React.useState(options);

  function handleClick() {
    const blockArray = optionReasons.filter((option, index) => {
      if (arrayReasons[index].checked === true) {
        return option;
      }
    });
    onClick(blockArray);
    setOpen(false);
  }

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
  }

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  return (
    <Grid container style={{ width: 'auto' }}>
      <Grid item xs={12} align="center">
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          aria-label="split button"
          className={classnames({ 'action-danger': type === 'danger' })}
        >
          <Button
            onClick={handleClick}
            className={classnames({ 'action-danger': type === 'danger' })}
            style={{ borderColor: 'red', color: 'white' }}
          >
            Block
          </Button>
          <Button
            size="small"
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ borderColor: '#f35f54', color: '#f35f54' }}
            className={classnames({ 'action-danger': type === 'danger' })}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          style={{
            zIndex: 100,
          }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                zIndex: '100',
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <div className="options-menu" key={index}>
                        <Checkbox
                          checked={arrayReasons[index].checked}
                          onChange={() => {
                            const check = arrayReasons[index].checked;
                            const arrayOptions = [...arrayReasons];
                            arrayOptions[index].checked = !check;
                            setArrayReasons(arrayOptions);
                          }}
                          color="primary"
                        />
                        <MenuItem
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                          style={{
                            maxWidth: '400px',
                            minWidth: '210px',
                            width: '350px',
                          }}
                        >
                          <InputBase
                            value={optionReasons[index]}
                            className="options-textField"
                            onChange={(event) => {
                              const reason = event.target.value;
                              const optionsArray = [...optionReasons];
                              optionsArray[index] = reason;
                              setOptionReasons(optionsArray);
                            }}
                            fullWidth={true}
                            multiline={true}
                            style={{ zIndex: 1000 }}
                          />
                        </MenuItem>
                      </div>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
