import { styled } from '@mui/styles';
import { Upload } from '@mui/icons-material';

{
  /* Cannot use custom camel-cased attributes for dom elements 😭 */
}
export const DragAndDropLabel = styled('label')(({ beingdragged }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  border: '2px dashed #bfbeba',
  borderRadius: '10px',
  width: '100%',
  height: '200px',
  backgroundColor: beingdragged === 'true' ? 'lightgray' : 'transparent',
  boxSizing: 'border-box',
  cursor: 'pointer',
  fontSize: '1.2rem',
}));

export const DragAndDropUploadIcon = styled(Upload)({
  fontSize: '4rem',
});
