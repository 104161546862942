import { combineReducers } from 'redux';
import * as authReducer from './auth';
import * as questionReducer from './question';
import * as tagReducer from './tag';
import * as categoryReducer from './category';
import * as userReducer from './user';
import * as leaderboardReducer from './leaderboard';
import * as notificationsReducer from './notifications';
import * as totalReferralsReducer from './totalReferrals';
import * as publicProfileReducer from './publicProfile';
import * as layoutReducer from './layout';
import * as domainReducer from './domain';
import * as competitionReducer from './competition';
import * as creditsReducer from './credits';
import * as emailingListReducer from './emailingList';
import * as userStoreReducer from './userStore';
import * as storeAdminReducer from './storeAdmin';
import * as dataManagementReducer from './dataManagement';
import * as orderHistoryReducer from './orderHistory';
import * as mockTestReducer from './mockTest';
import * as reportBug from './reportBug';
import * as badges from './badges';
import * as dashboard from './dashboard';
import * as discussion from './discussion';
import * as commentReportsReducer from './commentReports';
import * as userQueryReducer from './userSearchQuerySuggestions';
import * as levels from './levels';
import * as votingReducer from './voting';
import * as adminJobsReducer from './adminJobs';
import * as collegeDashboard from './collegeDashboard';
import * as adminDegreeCreate from './adminDegreeCreate';
import * as EmailNotifications from './emailNotifications';
import * as AdminCouponsReducer from './adminCoupons';
import * as SalesDashboard from './salesDashboard';
import * as AdminUserManagement from './adminUserManagement';

const appReducer = combineReducers(
  Object.assign(
    {},
    authReducer,
    questionReducer,
    tagReducer,
    categoryReducer,
    userReducer,
    leaderboardReducer,
    notificationsReducer,
    totalReferralsReducer,
    publicProfileReducer,
    layoutReducer,
    competitionReducer,
    domainReducer,
    creditsReducer,
    emailingListReducer,
    userStoreReducer,
    storeAdminReducer,
    dataManagementReducer,
    orderHistoryReducer,
    mockTestReducer,
    reportBug,
    badges,
    levels,
    discussion,
    commentReportsReducer,
    userQueryReducer,
    votingReducer,
    adminJobsReducer,
    collegeDashboard,
    dashboard,
    adminDegreeCreate,
    EmailNotifications,
    AdminCouponsReducer,
    SalesDashboard,
    AdminUserManagement,
  ),
);

const reducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default reducer;
