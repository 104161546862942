import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
  IconButton,
  Chip,
  FormHelperText,
  ButtonGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AddBox as AddBoxIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addExamDetails,
  updateExamDetails,
  uploadImage,
  getExamDetails,
  mergeDuplicates,
} from '../../actions/dataManagement';
import AsyncSelect from 'react-select/async';
import { StyleDataManagement } from './StyledComponent';

const useStyles = makeStyles({
  companyImg: {
    borderRadius: '5px',
  },
  infoIcon: {
    fontSize: '10px',
  },
});

const AddExamDialog = ({
  open,
  handleClose,
  examId,
  exam,
  searchTerm,
  status,
  page,
  rowsPerPage,
  onlyMerge,
  selectedExamList,
  setSelectedExamList,
  setSelectedIds,
}) => {
  const styles = useStyles();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [examImg, setExamImg] = useState('');
  const [tab, setTab] = useState(0);
  const [mainEntity, setMainEntity] = useState({});
  const [duplicateEntities, setDuplicateEntities] = useState([exam]);
  const [apiError, setApiError] = useState('');
  const [editingEntity, setEditingEntity] = useState({});

  const onChangeFunction = () => {
    dispatch(uploadImage(inputRef.current.files[0]));
  };

  const {
    imageUrl,
    isUploadingImage,
    isMergeDuplicateStart,
    isMergeDuplicateFail,
    isMergeDuplicateSuccess,
  } = useSelector((state) => state.dataManagement);

  useEffect(() => {
    if (exam) {
      reset({
        name: exam.name,
        next_date: exam.next_date,
      });
      setExamImg(exam.image_url);
    }
  }, [exam]);

  useEffect(() => {
    if (
      isMergeDuplicateStart !== isMergeDuplicateFail &&
      isMergeDuplicateFail
    ) {
      setApiError('Something went wrong. Please try again!');
    }

    if (isMergeDuplicateStart) {
      setApiError('');
    }
  }, [isMergeDuplicateStart, isMergeDuplicateFail, isMergeDuplicateSuccess]);

  const onDetailsSubmit = (data) => {
    if (tab === 0) {
      data.image_url = examImg ? examImg : imageUrl;

      if (!examId) {
        dispatch(addExamDetails(data, searchTerm, page, rowsPerPage, status));
      } else {
        dispatch(
          updateExamDetails(
            data,
            examId,
            searchTerm,
            page,
            rowsPerPage,
            status,
          ),
        );
      }
      handleClose();
    } else {
      let duplicate_ids;
      if (mainEntity)
        duplicate_ids = duplicateEntities
          .filter((entity) => entity.id !== mainEntity.id)
          .map((entity) => entity.id);
      else
        duplicate_ids = duplicateEntities.slice(1).map((entity) => entity.id);

      if (duplicate_ids.length) {
        dispatch(
          mergeDuplicates(
            {
              main_entity_id: mainEntity.id || duplicateEntities[0].id,
              entity_type: 'exam',
              duplicate_ids,
            },
            () => {
              dispatch(getExamDetails(searchTerm, page, rowsPerPage, status));
              handleClose();
            },
          ),
        );
        setSelectedExamList([]);
        setSelectedIds([]);
      } else {
        handleClose();
      }
    }
  };

  const handleChange = () => {
    const data = {
      name: editingEntity.name,
      approval_status: editingEntity.approval_status || 'Pending',
    };
    dispatch(
      updateExamDetails(
        data,
        editingEntity.id,
        searchTerm,
        page,
        rowsPerPage,
        status,
        () => {
          setDuplicateEntities((entities) =>
            entities.map((entity) => {
              if (entity.id === editingEntity.id) {
                return {
                  ...entity,
                  ...data,
                };
              }
              return entity;
            }),
          );
          setEditingEntity({});
        },
      ),
    );
  };

  useEffect(() => {
    if (duplicateEntities.length && !mainEntity.id) {
      setMainEntity(duplicateEntities[0]);
    }
  }, [duplicateEntities?.[0]]);

  useEffect(() => {
    if (onlyMerge) {
      setTab(1);
      setDuplicateEntities(selectedExamList);
    }
  }, [onlyMerge]);

  return (
    <Dialog className="dialog" onClose={handleClose} open={open} fullWidth>
      <StyleDataManagement>
        <Grid className="dialog-title-container">
          {onlyMerge ? (
            <Button
              className={['dialog-title', tab === 1 && 'active']}
              color="primary"
              onClick={() => setTab(1)}
            >
              Merge Exam
            </Button>
          ) : (
            <>
              {examId ? (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Edit Exam
                </Button>
              ) : (
                <Button
                  className={['dialog-title', tab === 0 && 'active']}
                  color="primary"
                  onClick={() => setTab(0)}
                >
                  Add New Exam
                </Button>
              )}
              <Button
                className={['dialog-title', tab === 1 && 'active']}
                color="primary"
                onClick={() => setTab(1)}
              >
                Merge Exam
              </Button>
            </>
          )}
        </Grid>
        <DialogContent className="dialog-content">
          {onlyMerge ? (
            <>
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the exam you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getExamDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.all_external_exams),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search exmas...'}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.id === opt.id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate Exams
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no exams to merge. Please select a exam!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity.id}
                        style={{
                          background:
                            entity.id === mainEntity.id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity.id === entity.id ? (
                            <TextField
                              value={editingEntity.name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  name: value,
                                }));
                              }}
                              name="name"
                              placeholder="Enter the exam name"
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.name}
                            </Typography>
                          )}
                          {editingEntity.id === entity.id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Exam"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity.id === entity.id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity.college_id !== entity.college_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) => ent.id !== entity.id,
                                ),
                              );
                              if (entity.id === mainEntity.id) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {tab === 0 && (
                <Grid container spacing={2} justifyContent="center">
                  {!!imageUrl?.length || examImg?.length ? (
                    <Grid item container justifyContent="center" sm={4} xs={12}>
                      <Grid item>
                        <img
                          src={examImg ? examImg : imageUrl}
                          alt=""
                          width="100%"
                          className={styles.companyImg}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {isUploadingImage ? (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          direction="column"
                          alignItems="center"
                          container
                          spacing={2}
                          justifyContent="center"
                        >
                          <Typography variant="h6">Uploading</Typography>{' '}
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          direction="column"
                          alignItems="center"
                          container
                          spacing={2}
                          justifyContent="center"
                        >
                          <Grid container item justifyContent="center">
                            <AddBoxIcon fontSize="large" />
                          </Grid>

                          <Grid item>
                            <form>
                              <Button
                                variant="text"
                                component="label"
                                type="button"
                              >
                                Upload Images
                                <input
                                  id="file-input"
                                  type="file"
                                  hidden
                                  ref={inputRef}
                                  onChange={onChangeFunction}
                                />
                              </Button>
                            </form>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12} sm={8}>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Status</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <FormControl fullWidth>
                          <Select
                            {...register('approval_status', {
                              required: 'Please select a status',
                            })}
                            style={{ marginTop: '8px', marginBottom: '4px' }}
                            variant="outlined"
                            margin="dense"
                            displayEmpty
                            placeholder="Exam"
                            error={!!errors.approval_status}
                            defaultValue={
                              examId
                                ? exam?.approval_status === null ||
                                  exam?.approval_status === 'Pending'
                                  ? 'Pending'
                                  : 'Accepted'
                                : 'Accepted'
                            }
                          >
                            <MenuItem value="Accepted" key="Accepted">
                              Accepted
                            </MenuItem>
                            <MenuItem value="Pending" key="Pending">
                              Pending
                            </MenuItem>
                            <MenuItem value="Rejected" key="Rejected">
                              Rejected
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label> Name</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <TextField
                          {...register('name', {
                            required: 'Please enter the name',
                          })}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                          error={!!errors.name}
                          helperText={errors.name?.message}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item sm={3} xs={6}>
                        <label>Next Date</label>
                      </Grid>
                      <Grid item sm={9} xs={6}>
                        <TextField
                          {...register('next_date')}
                          margin="dense"
                          type="date"
                          variant="outlined"
                          fullWidth
                          placeholder="Next Date"
                          error={!!errors.next_date}
                          helperText={errors.next_date?.message}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 1 && (
                <Grid>
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginBottom: '10px' }}
                  >
                    Search the exam you want to merge with
                  </Typography>
                  <AsyncSelect
                    isClearable
                    loadOptions={(inp, callback) => {
                      dispatch(
                        getExamDetails(inp, 1, 10, '', (resp) =>
                          callback(resp.all_external_exams),
                        ),
                      );
                    }}
                    noOptionsMessage={() => 'Search exmas...'}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    onChange={(opt) => {
                      if (
                        !duplicateEntities.find(
                          (entity) => entity.id === opt.id,
                        )
                      )
                        setDuplicateEntities((entities) => [...entities, opt]);
                    }}
                    value={null}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        overflow: 'auto',
                        maxHeight: `${130 + duplicateEntities.length * 50}px`,
                      }),
                    }}
                  />
                  <Divider />
                  <Grid className="dulticate-entities-container">
                    <Grid className="duplicate-entities-header">
                      <Typography
                        color="primary"
                        variant="h6"
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        Duplicate Exams
                      </Typography>
                      <Grid className="d-flex" style={{ gap: '10px' }}>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(145, 224, 136, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Main entity
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex"
                          style={{ gap: '5px', alignItems: 'center' }}
                        >
                          <Box
                            className="color-box"
                            style={{ background: 'rgba(149, 184, 227, 0.49)' }}
                          />
                          <Typography variant="subtitle1">
                            Duplicate entity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    {!duplicateEntities.length && (
                      <Typography color="primary">
                        There are no exams to merge. Please select a exam!
                      </Typography>
                    )}
                    {duplicateEntities.map((entity) => (
                      <Box
                        key={entity.id}
                        style={{
                          background:
                            entity.id === mainEntity.id
                              ? '#91e0887d'
                              : '#95b8e37d',
                        }}
                        className="merge-entity-box"
                        onClick={() => setMainEntity(entity)}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                        >
                          {editingEntity.id === entity.id ? (
                            <TextField
                              value={editingEntity.name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  name: value,
                                }));
                              }}
                              name="name"
                              placeholder="Enter the exam name"
                            />
                          ) : (
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{ fontSize: '18px' }}
                            >
                              {entity.name}
                            </Typography>
                          )}
                          {editingEntity.id === entity.id ? (
                            <Select
                              {...register('approval_status', {
                                required: 'Please select a status',
                              })}
                              style={{ marginTop: '8px', marginBottom: '4px' }}
                              variant="outlined"
                              margin="dense"
                              displayEmpty
                              placeholder="Exam"
                              error={!!errors.approval_status}
                              defaultValue={
                                entity?.approval_status || 'Pending'
                              }
                              onChange={(e) =>
                                setEditingEntity((curr) => ({
                                  ...curr,
                                  approval_status: e.target.value,
                                }))
                              }
                            >
                              <MenuItem value="Accepted" key="Accepted">
                                Accepted
                              </MenuItem>
                              <MenuItem value="Pending" key="Pending">
                                Pending
                              </MenuItem>
                              <MenuItem value="Rejected" key="Rejected">
                                Rejected
                              </MenuItem>
                            </Select>
                          ) : (
                            <Chip
                              style={{ cursor: 'pointer' }}
                              label={entity?.approval_status || 'Pending'}
                              className="duplicate-approval-status"
                            />
                          )}
                          {editingEntity.id === entity.id && (
                            <ButtonGroup style={{ textAlign: 'right' }}>
                              <IconButton
                                onClick={() => handleChange()}
                                size="large"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => setEditingEntity({})}
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </ButtonGroup>
                          )}
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          {editingEntity.college_id !== entity.college_id && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingEntity(entity);
                              }}
                              color="primary"
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicateEntities(
                                duplicateEntities.filter(
                                  (ent) => ent.id !== entity.id,
                                ),
                              );
                              if (entity.id === mainEntity.id) {
                                setMainEntity({});
                              }
                            }}
                            color="primary"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={handleSubmit(onDetailsSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </StyleDataManagement>
    </Dialog>
  );
};

export default AddExamDialog;
