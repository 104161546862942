import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPassword } from './../actions/user';
import Component from './../components/UserProfile/ChangePasswordCard';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
