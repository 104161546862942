import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommentReports,
  reviewComment,
  getAllComment,
} from '../../actions/commentReports';
import CommentReportCard from './CommentReportCard';
import {
  NoMoreReports,
  NoMoreReportsIcon,
  ReportFilter,
  ReportMainLoader,
  ReportsFilterWrapper,
  ReportsWrapper,
  FilterWrapper,
  TypeFillter,
  SearchField,
} from './StyledComponents';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert, Box, CircularProgress, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterList, Sort } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';

const ReviewSnackbar = ({ snackbarDetails, setSnackbarDetails }) => {
  const handleClose = () => {
    setSnackbarDetails({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  return (
    <Snackbar
      onClose={handleClose}
      open={snackbarDetails.open}
      autoHideDuration={4000}
    >
      <Alert onClose={handleClose} severity={snackbarDetails.severity}>
        {snackbarDetails.message}
      </Alert>
    </Snackbar>
  );
};

const CommentReports = () => {
  const totalNumberOfPages = useSelector(
    (state) => state.commentReports.totalNumberOfPages,
  );

  const currentPageNumber = useSelector(
    (state) => state.commentReports.currentPageNumber,
  );

  const [currentPage, setCurrentPage] = useState(() => 1);
  const [comments, setComments] = useState(() => []);
  const [firstLoad, setFirstLoad] = useState(() => true);
  const [typeFilter, setTypeFilter] = useState(() => 'All');
  const [commentType, setCommentType] = useState(() => 'General');
  const [sortFilter, setSortFilter] = useState(() => 'desc');
  const [actOnComment, setActOnComment] = useState(() => false);
  const [selectedCategory, setSelectedCategory] = useState(() => 'reported');
  const [nameQuery, setNameQuery] = useState('');
  const [actionDetails, setActionDetails] = useState(() => ({
    type: '',
    commentId: '',
  }));
  const [snackbarDetails, setSnackbarDetails] = useState(() => ({
    open: false,
    severity: 'success',
    message: '',
  }));

  const dispatch = useDispatch();

  const commentsProcessor = useCallback((rawComments) => {
    const mapperFunction = (rawComment) => {
      const {
        comment: commentText,
        comment_id: commentId,
        comment_created_on: commentCreatedOn,
        comment_status: commentStatus,
        question_details: {
          question_id: questionId,
          question_text: questionText,
          question_author: {
            id: questionAuthorId,
            username: questionAuthorUsername,
            picture: questionAuthorPicture,
          },
        },
        reported_comment_author: {
          id: commentAuthorId,
          username: commentAuthorUsername,
          picture: commentAuthorPicture,
        } = {},
        total_downvotes: downvotes,
        total_upvotes: upvotes,
        reports = [],
      } = rawComment;

      const processedReports = reports.map((report) => ({
        id: report.id,
        createdAt: report.created_at,
        authorUsername: report.report_author.username,
        authorPicture: report.report_author.picture,
        status: report.status,
        authorId: report.user_id,
        type: report.report_type,
        details: report.details,
      }));

      return {
        questionId,
        questionText,
        questionAuthorId,
        questionAuthorUsername,
        questionAuthorPicture,
        commentId,
        commentText,
        commentStatus,
        commentAuthorId,
        commentAuthorUsername,
        commentAuthorPicture,
        commentCreatedOn,
        reports: processedReports,
        upvotes,
        downvotes,
      };
    };

    return rawComments.map(mapperFunction);
  }, []);

  useEffect(() => {
    if (selectedCategory === 'reported') {
      dispatch(
        getCommentReports(
          currentPage,
          10,
          sortFilter,
          'date',
          typeFilter === 'All' ? '' : typeFilter,
          (newData) => {
            setComments((oldFlaggedComments) => [
              ...oldFlaggedComments,
              ...commentsProcessor(newData),
            ]);
            setFirstLoad(false);
          },
          () => {
            setFirstLoad(false);
            setSnackbarDetails({
              open: true,
              severity: 'error',
              message: `Could not retrieve reports`,
            });
          },
        ),
      );
    } else {
      dispatch(
        getAllComment(
          // typeFilter === 'All' ? '' : typeFilter,
          commentType,
          '',
          '',
          'date',
          sortFilter,
          nameQuery,
          currentPage,
          10,
          (newData) => {
            setComments((oldAllComments) => [
              ...oldAllComments,
              ...commentsProcessor(newData),
            ]);
            setFirstLoad(false);
          },
          () => {
            setFirstLoad(false);
            setSnackbarDetails({
              open: true,
              severity: 'error',
              message: `Could not retrieve comment`,
            });
          },
        ),
      );
    }
  }, [
    selectedCategory,
    currentPage,
    dispatch,
    commentsProcessor,
    sortFilter,
    typeFilter,
    nameQuery,
    commentType,
  ]);

  useEffect(() => {
    if (actOnComment) {
      const { type, commentId } = actionDetails;

      const removeComment = () => {
        const targetIndex = comments?.findIndex(
          (flaggedComment) => flaggedComment.commentId === commentId,
        );
        targetIndex !== -1 &&
          setComments((oldValues) => [
            ...oldValues.slice(0, targetIndex),

            ...oldValues.slice(targetIndex + 1),
          ]);
      };

      const changeStatus = () => {
        setComments((oldValues) => {
          return oldValues.map((comment) => {
            if (comment.commentId === commentId) {
              return {
                ...comment,
                commentStatus: status,
              };
            } else {
              return comment;
            }
          });
        });
      };

      const status = type === 1 ? 'Accepted' : 'Rejected';

      const successCallback = () => {
        selectedCategory === 'reported' ? removeComment() : changeStatus();
        setSnackbarDetails({
          open: true,
          severity: 'success',
          message: `Comment ${status}`,
        });
      };

      const failCallback = () => {
        setSnackbarDetails({
          open: true,
          severity: 'error',
          message: 'Could Not Review Comment',
        });
      };

      dispatch(reviewComment(commentId, status, successCallback, failCallback));

      setActOnComment(false);
    }
  }, [actOnComment, actionDetails, dispatch, comments]);

  const handleReportFilterChanges = (value, type) => {
    setCurrentPage(1);
    setComments([]);
    setFirstLoad(true);
    switch (type) {
      case 1:
        setTypeFilter(value);
        return;
      case 2:
        setSortFilter(value);
        return;
      default:
        return;
    }
  };

  const takeAction = (type, commentId) => {
    setActionDetails({
      type,
      commentId,
    });
    setActOnComment(true);
  };

  if (firstLoad) {
    return (
      <ReportMainLoader>
        <CircularProgress />
        Loading Reports...
      </ReportMainLoader>
    );
  }

  return (
    <ReportsWrapper
      id="scrollableContainer"
      sx={{
        marginTop: '10px',
        paddingTop: '10px',
        overflow: 'auto',
      }}
    >
      <ReviewSnackbar
        snackbarDetails={snackbarDetails}
        setSnackbarDetails={setSnackbarDetails}
      />
      <Box display={'flex'}>
        <FilterWrapper>
          <TypeFillter
            value={selectedCategory}
            onChange={({ target }) => {
              setSelectedCategory(target.value);
              handleReportFilterChanges();
            }}
          >
            <MenuItem value={'reported'}>Reported Comments</MenuItem>
            <MenuItem value={'all'}>All Comments</MenuItem>
          </TypeFillter>
        </FilterWrapper>
        <ReportsFilterWrapper>
          {selectedCategory === 'reported' ? (
            <ReportFilter
              IconComponent={FilterList}
              value={typeFilter}
              onChange={({ target }) =>
                handleReportFilterChanges(target.value, 1)
              }
            >
              <MenuItem value={'Harassment'}>Harassment</MenuItem>
              <MenuItem value={'Spam'}>Spam</MenuItem>
              <MenuItem value={'CopyrightInfringement'}>
                Copyright Infringement
              </MenuItem>
              <MenuItem value={'Vulgarity'}>Vulgarity</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
              <MenuItem value={'All'}>All</MenuItem>
            </ReportFilter>
          ) : (
            <>
              <SearchField
                value={nameQuery}
                variant="outlined"
                placeholder="Search by username"
                onChange={({ target }) => {
                  setNameQuery(target.value);
                  setComments([]);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              ></SearchField>
              <ReportFilter
                IconComponent={FilterList}
                value={commentType}
                onChange={({ target }) => {
                  setCommentType(target.value);
                  setComments([]);
                }}
              >
                <MenuItem value={'General'}>General</MenuItem>
                <MenuItem value={'AlternateSolution'}>
                  Alternate Solution
                </MenuItem>
              </ReportFilter>
            </>
          )}
          <ReportFilter
            IconComponent={Sort}
            value={sortFilter}
            onChange={({ target }) =>
              handleReportFilterChanges(target.value, 2)
            }
          >
            <MenuItem value={'asc'}>Date-Ascending</MenuItem>
            <MenuItem value={'desc'}>Date-Descending</MenuItem>
          </ReportFilter>
        </ReportsFilterWrapper>
      </Box>

      {comments.length === 0 ? (
        <NoMoreReports>
          <NoMoreReportsIcon size="large" />
          <div>No more reports...</div>
        </NoMoreReports>
      ) : (
        <InfiniteScroll
          dataLength={comments.length}
          next={() => {
            setCurrentPage((page) => page + 1);
          }}
          hasMore={currentPageNumber < totalNumberOfPages}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          scrollThreshold={0.9}
          style={{ overflow: 'none' }}
          scrollableTarget="scrollableContainer"
        >
          {comments.map((Comment, index) => (
            <CommentReportCard
              {...Comment}
              takeAction={takeAction}
              type={selectedCategory}
              key={Comment.commentId}
              index={index}
            />
          ))}
        </InfiniteScroll>
      )}
    </ReportsWrapper>
  );
};

export default CommentReports;
