/*
 * Extensions for Quill.js Editor
 * https://github.com/quilljs/awesome-quill
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import ImageUploader from 'quill-image-uploader';
import ReactHtmlParser from 'react-html-parser';
import { uploadImage } from '../../actions/question';
import InfoIcon from './icons/info.png';

import './index.css';
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large', 'huge'];

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Size, true);

Quill.register(Font, true);

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/imageUploader', ImageUploader);
const addInfoIcon = (container) => {
  const existing = container.querySelector('.info-icon');
  if (existing) return;
  const icon = document.createElement('img');
  icon.src = InfoIcon;
  icon.width = '20';
  icon.height = '20';
  icon.setAttribute('class', 'info-icon');
  icon.title = 'For more information visit katex.org';

  icon.addEventListener('click', () => {
    window.open('https://katex.org');
  });

  container.insertBefore(icon, container.firstChild);
};
const RichTextEditor = (props) => {
  const quillRef = React.useRef();
  const quillContainerRef = React.useRef();
  const [iconAdded, setIconAdded] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const modules = {
    toolbar: {
      container: [
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'code-block', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'formula'],
        ['clean'],
      ],
    },
    blotFormatter: {},
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'script',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
    'imageBlot',
    'formula',
    'clean',
  ];

  const tooltips = {
    bold: 'Bold (ctrl+B)',
    italic: 'Italic (ctrl+I)',
    underline: 'Underline (ctrl+U)',
    strike: 'Strike',
    'code-block': 'Code Block',
    blockquote: 'Blockquote',
    list: 'List',
    script: 'Script',
    indent: 'Indent',
    link: 'Link',
    image: 'Image',
    formula: 'Formula',
    clean: 'Clean',
  };

  React.useEffect(() => {
    const showTooltip = (tool) => {
      const toolClass = tool.className.replace('ql-', '');
      if (tooltips[toolClass]) {
        let title = tooltips[toolClass];
        const value = tool.getAttribute('value');
        if (value) {
          // special cases for indent
          if (value === '-1') title = 'Decrease ' + title;
          else if (value === '+1') title = 'Increase ' + title;
          // default case
          else title = value + title;

          title = title.charAt(0).toUpperCase() + title.slice(1);
        }
        tool.setAttribute('title', title);
      }
    };

    // get all tools in the toolbar and add tooltip
    const toolbarElement = quillContainerRef.current.querySelector(
      '.ql-toolbar',
    );
    if (toolbarElement) {
      const allTools = toolbarElement.querySelectorAll('button');
      for (const tool of allTools) {
        showTooltip(tool);
      }
    }
  }, []);

  React.useEffect(() => {
    const container = quillContainerRef.current.querySelector('.ql-tooltip');
    if (container && iconAdded === false) {
      addInfoIcon(container);
      setIconAdded(true);
    }
    setIsFocused(props.onBoxFocus ? props.onBoxFocus : isFocused);
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      quillRef.current.focus();
    }
  }, [isFocused]);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    if (getTextFromHTML(quillRef.current.value)) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  };

  const onClickDiv = () => {
    setIsFocused(true);
  };

  const getTextFromHTML = (html) => {
    let text = '';
    const options = {
      transform: (node) => {
        if (node.type === 'text') {
          text += node.data;
        }
      },
    };

    ReactHtmlParser(html, options);

    return text;
  };

  return (
    <div ref={quillContainerRef} onClick={onClickDiv}>
      {isFocused ? (
        <ReactQuill
          onFocus={onFocus}
          onBlur={onBlur}
          ref={quillRef}
          theme="snow"
          modules={modules}
          placeholder={props.placeholder}
          className="RichEditor-root"
          formats={formats}
          value={props.value}
          onChange={(raw) => {
            props.onChange({
              raw,
              html: raw,
              target: {
                name: props.name,
                value: raw,
              },
            });
          }}
        />
      ) : (
        <div
          style={{
            borderRadius: '5px',
            border: '1px solid rgba(123, 123, 123, 0.50)',
            alignItems: 'center',
            padding: '10px 10px',
          }}
        >
          {' '}
          {getTextFromHTML(props.value) === ''
            ? props.placeholder
            : ReactHtmlParser(props.value)}{' '}
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadImage,
    },
    dispatch,
  );
}

export default connect(() => ({}), mapDispatchToProps)(RichTextEditor);
