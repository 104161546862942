import React from 'react';
import {
  Header,
  StyledCompetetionCard,
  StyledCompetetionContainer,
  StyledCustomCard,
} from './DashboardStyledComponent';
import competetionImg from './images/competetion.png';
import register from './images/Register.png';
import { Typography, useMediaQuery } from '@mui/material';
import award from './images/award.png';
import { useNavigate } from 'react-router-dom';

const Competetion = ({ competetionData }) => {
  const navigate = useNavigate();
  const handleRegister = (alias) => {
    navigate(`/competition/${alias}`);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <main style={{ margin: '15px 0' }}>
      <Header>Participate in Competitions</Header>
      {competetionData ? (
        <StyledCompetetionContainer>
          {competetionData.map((competetion, index) => (
            <StyledCompetetionCard key={index}>
              <div className="">
                <img
                  src={competetion?.image_url || competetionImg}
                  style={{
                    maxWidth: '80px',
                    height: '80px',
                    borderRadius: '100%',
                  }}
                  alt=""
                />
              </div>

              <div className="" style={{ paddingRight: '25px' }}>
                <Typography sx={{ color: '#3572B0' }}>
                  Computer Science
                </Typography>
                <Header style={{ padding: '0' }}>
                  {competetion?.name || ''}
                </Header>
                <span>
                  {new Date(
                    competetion?.reg_start_date || new Date(),
                  ).toLocaleDateString()}{' '}
                  -{' '}
                  {new Date(
                    competetion?.reg_end_date || new Date(),
                  ).toLocaleDateString()}
                </span>
              </div>

              <div
                style={{
                  backgroundColor: '#25507B',
                  padding: '5px',
                  borderRadius: '0px 10px 10px 0px',
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  height: '100%',
                  color: 'white',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRegister(competetion?.alias)}
                >
                  <img src={register} style={{ width: '100%' }} />
                </div>
              </div>
            </StyledCompetetionCard>
          ))}
          {competetionData?.length == 1 && !isMobile && (
            <StyledCustomCard
              style={{
                background: 'linear-gradient(90deg, #E6DCFE 0%, #FDE1EB 100%)',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div>
                <img src={award} width={90} height={90} alt="" />
              </div>
              <div
                style={{
                  color: 'black',
                  fontWeight: '700',
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                }}
              >
                <span>🏆 Participate & Prosper!</span>
                <span>🌟 Unlock Rewards & Opportunities</span>
                <span>🎉 Win Prizes</span>
                <span>👩‍💼 Land Internships</span>
                <span>💼 Explore Job Opportunities</span>
              </div>
            </StyledCustomCard>
          )}
        </StyledCompetetionContainer>
      ) : null}
    </main>
  );
};

export default Competetion;
