import { styled } from '@mui/styles';

export const StyledEditQuestion = styled('div')(({ theme }) => ({
  '& .forCustomToolbar': {
    padding: '0px !important',
  },

  '& .forCustomToolbar1': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start !important',
    padding: '0px !important',

    '& .ql-toolbar': {
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
    },

    '& .ql-container': {
      borderBottomLeftRadius: '5px !important',
      borderBottomRightRadius: '5px !important',
    },

    '& label': {
      fontSize: '10rem',
    },
  },

  '& .form-group': {
    '& .ql-toolbar': {
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
    },

    '& .ql-container': {
      borderBottomLeftRadius: '5px !important',
      borderBottomRightRadius: '5px !important',
    },

    '& label': {
      fontSize: '0.9rem',
      fontWeight: 500,
      color: theme.palette.component.color,
    },

    '& .optional-hint': {
      fontStyle: 'italic',
      color: theme.palette.secondary.main,
      fontWeight: 'normal',
    },
  },

  '& .forListItem': {
    border: `1px solid ${theme.palette.component.border}`,
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },

  '& .correctAnswer': {
    backgroundColor: theme.palette.common.error,
  },

  '& .edit-question': {
    padding: '20px 0',

    '@media (max-width: 600px)': {
      padding: '0',
    },

    '& .form-group > label': {
      display: 'block',
      marginBottom: '7px',
      fontSize: '14px',

      '@media (max-width: 600px)': {
        marginBottom: '3px',
      },
    },

    '& .question': {
      marginBottom: '30px',

      '@media (max-width: 600px)': {
        marginBottom: '12px',
      },
    },

    '& .answer-type': {
      marginBottom: '30px',

      '@media (max-width: 600px)': {
        marginBottom: '12px',
      },
    },

    '& .answer-type-choices': {
      display: ['-webkit-flex', '-moz-flex', '-ms-flex', '-o-flex', 'flex'],
    },

    '& .answer-type-choices input[type="radio"]': {
      marginTop: '1px',
      marginRight: '7px',
    },

    '& .answer-type-choices label': {
      display: ['-webkit-flex', '-moz-flex', '-ms-flex', '-o-flex', 'flex'],
      marginRight: '22px',
    },

    '& .answer-type-radio': {
      display: ['-webkit-flex', '-moz-flex', '-ms-flex', '-o-flex', 'flex'],
      flex: 1,
    },

    '& .answer-text': {
      marginBottom: '30px',

      '@media (max-width: 600px)': {
        marginBottom: '12px',
      },
    },

    '& .error': {
      color: theme.palette.common.error,
      marginTop: '5px',
    },
  },

  '& .edit-question .tags': {
    marginBottom: '30px',

    '@media (max-width: 600px)': {
      marginBottom: '12px',
    },
  },

  '& .tags .text-input-field': {
    width: '200px',
  },

  '& .question-explanation .text-input-field': {
    width: '100%',
  },

  '& .text-input-field .MuiSelect-select': {
    color: theme.palette.component.color,
    padding: '6px 12px',
    fontSize: '14px',
  },

  '& .choice-label': {
    background: theme.palette.primary.background,
    borderRadius: '4px',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    width: '30px',
  },

  '& .Comprehensive_question_style': {
    width: '100%',

    '& .question': {
      width: '100%',
      borderRadius: '5px',
      border: `1px solid ${theme.palette.playColors.borderBlue}`,
      margin: '10px 0px',
      padding: '10px',
    },

    '& .header': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .dot': {
        height: '17px',
        width: '17px',
        margin: '0px',
        padding: '0px',
        borderRadius: '20px',
        border: `2px solid ${theme.palette.component.border}`,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& .filler': {
        height: '10px',
        width: '10px',
        borderRadius: '20px',
        backgroundColor: theme.palette.secondary.main,
      },

      '& .right_utils': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',

        '& select': {
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
          color: theme.palette.secondary.main,
          fontWeight: 500,
          padding: '2.4px 0px',
          paddingLeft: '10px',
        },
      },
    },

    '& .form-group': {
      margin: '0px 0px 7px 25px',
    },
  },

  '& .tabSelector': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    marginbottom: '-3px',

    '& div': {
      cursor: 'pointer',
    },

    '& h6': {
      fontSize: '18px',
      color: theme.palette.secondary.dark,
      margin: '7px 20px',
    },

    '& .tabSelected': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
  },

  '& .alternate_answer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',

    '& button': {
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '5px',
      padding: '0px',
    },

    '& .input': {
      width: '130px',
    },

    '& .options_container': {
      flexGrow: 1,
      height: '40px',
      overflow: 'auto',
      flexWrap: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '5px',

      '& div': {
        border: `1px solid ${theme.palette.component.border}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        gap: '2px',
        height: '100%',
        width: 'fit-content',
        padding: '0px 5px',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
      },
    },
  },
}));
