// Auth
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_SIGNUP_START = 'USER_SIGNUP_START';
export const USER_FAIL = 'USER_FAIL';
export const USER_SUCCESS = 'USER_SUCCESS';
export const GET_USER_COUNTRY_SUCCESS = 'GET_USER_COUNTRY_SUCCESS';
export const GET_USER_COUNTRY_FAIL = 'GET_USER_COUNTRY_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

// Question
export const CREATE_QUESTION_TYPE = 'CREATE_QUESTION_TYPE';
export const RESET_QUESTION_BY_ID = 'RESET_QUESTION_BY_ID';
export const RESET_QUESTION_DATA = 'RESET_QUESTION_DATA';

export const POST_QUESTION_START = 'POST_QUESTION_START';
export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS';
export const POST_QUESTION_FAIL = 'POST_QUESTION_FAIL';

export const GET_QUESTION_LIST_START = 'GET_QUESTION_LIST_START';
export const GET_QUESTION_LIST_SUCCESS = 'GET_QUESTION_LIST_SUCCESS';
export const GET_QUESTION_LIST_FAIL = 'GET_QUESTION_LIST_FAIL';

export const GET_QUESTION_START = 'GET_QUESTION_START';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAIL = 'GET_QUESTION_FAIL';

export const GET_QUESTION_STATS_START = 'GET_QUESTION_STATS_START';
export const GET_QUESTION_STATS_SUCCESS = 'GET_QUESTION_STATS_SUCCESS';
export const GET_QUESTION_STATS_FAIL = 'GET_QUESTION_STATS_FAIL';

export const GET_QUESTION_BY_ID_START = 'GET_QUESTION_BY_ID_START';
export const GET_QUESTION_BY_ID_SUCCESS = 'GET_QUESTION_BY_ID_SUCCESS';
export const GET_QUESTION_BY_ID_FAIL = 'GET_QUESTION_BY_ID_FAIL';

export const GET_UNANSWERED_QUESTION_BY_ID_START =
  'GET_UNANSWERED_QUESTION_BY_ID_START';
export const GET_UNANSWERED_QUESTION_BY_ID_SUCCESS =
  'GET_UNANSWERED_QUESTION_BY_ID_SUCCESS';
export const GET_UNANSWERED_QUESTION_BY_ID_FAIL =
  'GET_UNANSWERED_QUESTION_BY_ID_FAIL';

export const RESET_QUESTIONS = 'RESET_QUESTIONS';

// Tag
export const POST_TAG_START = 'POST_TAG_START';
export const POST_TAG_SUCCESS = 'POST_TAG_SUCCESS';
export const POST_TAG_FAIL = 'POST_TAG_FAIL';
export const POST_SUBTAG_START = 'POST_SUBTAG_START';
export const POST_SUBTAG_SUCCESS = 'POST_SUBTAG_SUCCESS';
export const POST_SUBTAG_FAIL = 'POST_SUBTAG_FAIL';
export const PUT_SAVE_STREAK_START = 'PUT_SAVE_STREAK_START';
export const PUT_SAVE_STREAK_SUCCESS = 'PUT_SAVE_STREAK_SUCCESS';
export const PUT_SAVE_STREAK_FAIL = 'PUT_SAVE_STREAK_FAIL';
export const UNLOCK_CATEGORY_START = 'UNLOCK_CATEGORY_START';
export const UNLOCK_CATEGORY_SUCCESS = 'UNLOCK_CATEGORY_SUCCESS';

export const REMOVE_TAG_FROM_DOMAIN_SUCCESS = 'REMOVE_TAG_FROM_DOMAIN_SUCCESS';
export const REMOVE_TAG_FROM_DOMAIN_FAIL = 'REMOVE_TAG_FROM_DOMAIN_FAIL';
export const REMOVE_TAG_FROM_DOMAIN_START = 'REMOVE_TAG_FROM_DOMAIN_START';
export const ADD_TAG_TO_DOMAIN_SUCCESS = 'ADD_TAG_TO_DOMAIN_SUCCESS';
export const ADD_TAG_TO_DOMAIN_FAIL = 'ADD_TAG_TO_DOMAIN_FAIL';
export const ADD_TAG_TO_DOMAIN_START = 'ADD_TAG_TO_DOMAIN_START';

export const GET_TAG_START = 'GET_TAG_START';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAIL = 'GET_TAG_FAIL';

// Answer
export const POST_ANSWER_START = 'POST_ANSWER_START';
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAIL = 'POST_ANSWER_FAIL';

// Streak
export const GET_STREAK_START = 'GET_STREAK_START';
export const GET_STREAK_SUCCESS = 'GET_STREAK_SUCCESS';
export const GET_STREAK_FAIL = 'GET_STREAK_FAIL';

export const GET_MORE_TIME_START = 'GET_MORE_TIME_START';
export const GET_MORE_TIME_SUCCESS = 'GET_MORE_TIME_SUCCESS';
export const GET_MORE_TIME_FAIL = 'GET_MORE_TIME_FAIL';
export const RESET_PURCHASE_TIME = 'RESET_PURCHASE_TIME';

//category
export const SET_PREFERENCES_START = 'SET_PREFERENCES_START';
export const SET_PREFERENCES_SUCCESS = 'SET_PREFERENCES_SUCCESS';
export const SET_PREFERENCES_FAIL = 'SET_PREFERENCES_FAIL';
export const GET_PREFERENCES_START = 'GET_PREFERENCES_START';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_FAIL = 'GET_PREFERENCES_FAIL';
export const GET_STATS_START = 'GET_STATS_START';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAIL = 'GET_STATS_FAIL';
export const REMEMBER_CATEGORY_ID = 'REMEMBER_CATEGORY_ID';

//user
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const GET_DOMAIN_START = 'GET_DOMAIN_START';
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_FAIL = 'GET_DOMAIN_FAIL';
export const GET_USER_LOGIN__STREAK_START = 'GET_USER_LOGIN__STREAK_START';
export const GET_USER_LOGIN__STREAK_SUCCESS = 'GET_USER_LOGIN__STREAK_SUCCESS';
export const GET_USER_LOGIN__STREAK_FAIL = 'GET_USER_LOGIN__STREAK_FAIL';
export const GET_USER_PROFILE_MILESTONE_START =
  'GET_USER_PROFILE_MILESTONE_START';
export const GET_USER_PROFILE_MILESTONE_SUCCESS =
  'GET_USER_PROFILE_MILESTONE_SUCCESS';
export const GET_USER_PROFILE_MILESTONE_FAIL =
  'GET_USER_PROFILE_MILESTONE_FAIL';

export const PUT_USER_PROFILE_UPDATE_START = 'PUT_USER_PROFILE_UPDATE_START';
export const PUT_USER_PROFILE_UPDATE_SUCCESS =
  'PUT_USER_PROFILE_UPDATE_SUCCESS';
export const PUT_USER_PROFILE_UPDATE_FAIL = 'PUT_USER_PROFILE_UPDATE_FAIL';

export const CHANGE_DOMAIN_START = 'CHANGE_DOMAIN_START';
export const CHANGE_DOMAIN_SUCCESS = 'CHANGE_DOMAIN_SUCCESS';
export const CHANGE_DOMAIN_FAIL = 'CHANGE_DOMAIN_FAIL';

export const GET_PUBLIC_PROFILE_START = 'GET_PUBLIC_PROFILE_START';
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAIL = 'GET_PUBLIC_PROFILE_FAIL';
export const GET_FOLLOWED_USERS_LIST_START = 'GET_FOLLOWED_USERS_LIST_START';
export const GET_FOLLOWED_USERS_LIST_SUCCESS =
  'GET_FOLLOWED_USERS_LIST_SUCCESS';
export const GET_FOLLOWED_USERS_LIST_FAIL = 'GET_FOLLOWED_USERS_LIST_FAIL';
export const POST_FOLLOW_START = 'POST_FOLLOW_START';
export const POST_FOLLOW_SUCCESS = 'POST_FOLLOW_SUCCESS';
export const POST_FOLLOW_FAIL = 'POST_FOLLOW_FAIL';
export const POST_UNFOLLOW_START = 'POST_UNFOLLOW_START';
export const POST_UNFOLLOW_SUCCESS = 'POST_UNFOLLOW_SUCCESS';
export const POST_UNFOLLOW_FAIL = 'POST_UNFOLLOW_FAIL';

export const PUT_USER_PROFILE_START = 'PUT_USER_PROFILE_START';
export const PUT_USER_PROFILE_SUCCESS = 'PUT_USER_PROFILE_SUCCESS';
export const PUT_USER_PROFILE_FAIL = 'PUT_USER_PROFILE_FAIL';

export const SEND_OTP_START = 'SEND_OTP_START';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL';

export const VERIFY_OTP_START = 'VERIFY_OTP_START';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const SEND_EMAIL_OTP_START = 'SEND_EMAIL_OTP_START';
export const SEND_EMAIL_OTP_SUCCESS = 'SEND_EMAIL_OTP_SUCCESS';
export const SEND_EMAIL_OTP_FAIL = 'SEND_EMAIL_OTP_FAIL';

export const VERIFY_EMAIL_OTP_START = 'VERIFY_EMAIL_OTP_START';
export const VERIFY_EMAIL_OTP_SUCCESS = 'VERIFY_EMAIL_OTP_SUCCESS';
export const VERIFY_EMAIL_OTP_FAIL = 'VERIFY_EMAIL_OTP_FAIL';

export const SEND_METADATA_START = 'SEND_METADATA_START';
export const SEND_METADATA_SUCCESS = 'SEND_METADATA_SUCCESS';
export const SEND_METADATA_FAIL = 'SEND_METADATA_FAIL';

export const UPLOAD_RESUME_START = 'UPLOAD_RESUME_START';
export const UPLOAD_RESUME_SUCCESS = 'UPLOAD_RESUME_SUCCESS';
export const UPLOAD_RESUME_FAIL = 'UPLOAD_RESUME_FAIL';
export const DELETE_RESUME = 'DELETE_RESUME';

export const GET_RESUME_DETAILS_START = 'GET_RESUME_DETAILS_START';
export const GET_RESUME_DETAILS_SUCCESS = 'GET_RESUME_DETAILS_SUCCESS';
export const GET_RESUME_DETAILS_FAIL = 'GET_RESUME_DETAILS_FAIL';

export const GET_USER_ACTIVITY_START = 'GET_USER_ACTIVITY_START';
export const GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS';
export const GET_USER_ACTIVITY_FAIL = 'GET_USER_ACTIVITY_FAIL';

//UNDER REVIEW QUESTIONS
export const GET_UNDER_REVIEW_QUESTION_START =
  'GET_UNDER_REVIEW_QUESTION_START';
export const GET_UNDER_REVIEW_QUESTION_SUCCESS =
  'GET_UNDER_REVIEW_QUESTION_SUCCESS';
export const GET_UNDER_REVIEW_QUESTION_FAIL = 'GET_UNDER_REVIEW_QUESTION_FAIL';

//REPORTED QUESTIONS
export const GET_REPORTED_QUESTION_START = 'GET_REPORTED_QUESTION_START';
export const GET_REPORTED_QUESTION_SUCCESS = 'GET_REPORTED_QUESTION_SUCCESS';
export const GET_REPORTED_QUESTION_FAIL = 'GET_REPORTED_QUESTION_FAIL';

//put question
export const PUT_QUESTION_START = 'PUT_QUESTION_START';
export const PUT_QUESTION_SUCCESS = 'PUT_QUESTION_SUCCESS';
export const PUT_QUESTION_FAIL = 'PUT_QUESTION_FAIL';

//put question
export const CHANGE_AUTOREVEAL_STATUS_ON = 'CHANGE_AUTOREVEAL_STATUS_ON';
export const CHANGE_AUTOREVEAL_STATUS_OFF = 'CHANGE_AUTOREVEAL_STATUS_OFF';

//put question
export const CHANGE_UNLOCKSOL_STATUS_ON = 'CHANGE_UNLOCKSOL_STATUS_ON';
export const CHANGE_UNLOCKSOL_STATUS_OFF = 'CHANGE_UNLOCKSOLL_STATUS_OFF';

export const CORRECT_ANSWER_ON_SKIP_ON = 'CORRECT_ANSWER_ON_SKIP_ON';
export const CORRECT_ANSWER_ON_SKIP_OFF = 'CORRECT_ANSWER_ON_SKIP_OFF';

export const PURCHASE_TIME_WITH_POPUP_STATUS_ON =
  'PURCHASE_TIME_WITH_POPUP_STATUS_ON';
export const PURCHASE_TIME_WITH_POPUP_STATUS_OFF =
  'PURCHASE_TIME_WITH_POPUP_STATUS_OFF';

//Like or dislike solution
export const PUT_SOLUTION_RATE_START = 'PUT_SOLUTION_RATE_START';
export const PUT_SOLUTION_RATE_SUCCESS = 'PUT_SOLUTION_RATE_SUCCESS';
export const PUT_SOLUTION_RATE_FAIL = 'PUT_SOLUTION_RATE_FAIL';

//GET Question Milestone
export const GET_USER_MILESTONE_START = 'GET_USER_MILESTONE_START';
export const GET_USER_MILESTONE_SUCCESS = 'GET_USER_MILESTONE_SUCCESS';
export const GET_USER_MILESTONE_FAIL = 'GET_USER_MILESTONE_FAIL';

export const PUT_REVIEW_QUESTION_START = 'PUT_REVIEW_QUESTION_START';
export const PUT_REVIEW_QUESTION_SUCCESS = 'PUT_REVIEW_QUESTION_SUCCESS';
export const PUT_REVIEW_QUESTION_FAIL = 'PUT_REVIEW_QUESTION_FAIL';

export const PUT_REPORTED_QUESTION_START = 'PUT_REPORTED_QUESTION_START';
export const PUT_REPORTED_QUESTION_SUCCESS = 'PUT_REPORTED_QUESTION_SUCCESS';
export const PUT_REPORTED_QUESTION_FAIL = 'PUT_REPORTED_QUESTION_FAIL';

// Delete question
export const DELETE_QUESTION_START = 'DELETE_QUESTION_START';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

// unlock question
export const UNLOCK_QUESTION_START = 'UNLOCK_QUESTION_START';
export const UNLOCK_QUESTION_SUCCESS = 'UNLOCK_QUESTION_SUCCESS';
export const UNLOCK_QUESTION_FAIL = 'UNLOCK_QUESTION_FAIL';

// get question hint
export const GET_QUESTION_HINT_START = 'GET_QUESTION_HINT_START';
export const GET_QUESTION_HINT_SUCCESS = 'GET_QUESTION_HINT_SUCCESS';
export const GET_QUESTION_HINT_FAIL = 'GET_QUESTION_HINT_FAIL';

// get exam analytics
export const GET_EXAM_ANALYTICS_START = 'GET_EXAM_ANALYTICS_START';
export const GET_EXAM_ANALYTICS_SUCCESS = 'GET_QUESTION_ANALYTICS_SUCCESS';
export const GET_EXAM_ANALYTICS_FAIL = 'GET_EXAM_ANALYTICS_FAIL';

export const SET_EXAM_ANALYTICS = 'SET_EXAM_ANALYTICS';

//GET SCORE BY TAG
export const GET_SCORE_BY_TAG_START = 'GET_SCORE_BY_TAG_START';
export const GET_SCORE_BY_TAG_SUCCESS = 'GET_SCORE_BY_TAG_SUCCESS';
export const GET_SCORE_BY_TAG_FAIL = 'GET_SCORE_BY_TAG_FAIL';

//GET ANSWERED QUESTIONS
export const GET_ANSWERED_QUESTION_START = 'GET_ANSWERED_QUESTION_START';
export const GET_ANSWERED_QUESTION_SUCCESS = 'GET_ANSWERED_QUESTION_SUCCESS';
export const GET_ANSWERED_QUESTION_FAIL = 'GET_ANSWERED_QUESTION_FAIL';
export const GET_ANSWERED_QUESTION_BY_ID_START =
  'GET_ANSWERED_QUESTION_BY_ID_START';
export const GET_ANSWERED_QUESTION_BY_ID_SUCCESS =
  'GET_ANSWERED_QUESTION_BY_ID_SUCCESS';
export const GET_ANSWERED_QUESTION_BY_ID_FAIL =
  'GET_ANSWERED_QUESTION_BY_ID_FAIL';

export const SAVE_HEADER_TEXT = 'SAVE_HEADER_TEXT';

// Leaderboard
export const GET_LEADERBOARD_START = 'GET_LEADERBOARD_START';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAIL = 'GET_LEADERBOARD_FAIL';

//all users
export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

// user Analytics
export const GET_USER_ANALYTICS_START = 'GET_USER_ANALYTICS_START';
export const GET_USER_ANALYTICS_SUCCESS = 'GET_USER_ANALYTICS_SUCCESS';
export const GET_USER_ANALYTICS_FAIL = 'GET_USER_ANALYTICS_FAIL';

export const GET_USERNAME_AVAILABILITY_START =
  'GET_USERNAME_AVAILABILITY_START';
export const GET_USERNAME_AVAILABILITY_SUCCESS =
  'GET_USERNAME_AVAILABILITY_SUCCESS';
export const GET_USERNAME_AVAILABILITY_FAIL = 'GET_USERNAME_AVAILABILITY_FAIL';
export const RESET_USERNAME_AVAILABILITY = 'RESET_USERNAME_AVAILABILITY';

export const GET_USER_REFERRALS_START = 'GET_USER_REFERRALS_START';
export const GET_USER_REFERRALS_SUCCESS = 'GET_USER_REFERRALS_SUCCESS';
export const GET_USER_REFERRALS_FAIL = 'GET_USER_REFERRALS_FAIL';

export const SET_TIME_OUT = 'SET_TIME_OUT';

export const POST_REPORT_QUESTION_START = 'POST_REPORT_QUESTION_START';
export const POST_REPORT_QUESTION_SUCCESS = 'POST_REPORT_QUESTION_SUCCESS';
export const POST_REPORT_QUESTION_FAIL = 'POST_REPORT_QUESTION_FAIL';

export const POST_NEW_SOLUTION_START = 'POST_NEW_SOLUTION_START';
export const POST_NEW_SOLUTION_SUCCESS = 'POST_NEW_SOLUTION_SUCCESS';
export const POST_NEW_SOLUTION_FAIL = 'POST_NEW_SOLUTION_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

//Notifications
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NEW_NOTIFICATIONS_START = 'GET_NEW_NOTIFICATIONS_START';
export const GET_NEW_NOTIFICATIONS_SUCCESS = 'GET_NEW_NOTIFICATIONS_SUCCESS';
export const GET_NEW_NOTIFICATIONS_FAIL = 'GET_NEW_NOTIFICATIONS_FAIL';

//Bookmark
export const PUT_BOOKMARK_START = 'PUT_BOOKMARK_START';
export const PUT_BOOKMARK_SUCCESS = 'PUT_BOOKMARK_SUCCESS';
export const PUT_BOOKMARK_FAIL = 'PUT_BOOKMARK_FAIL';

//Like and Dislike Question
export const PUT_LIKE_START = 'PUT_LIKE_START';
export const PUT_LIKE_SUCCESS = 'PUT_LIKE_SUCCESS';
export const PUT_LIKE_FAIL = 'PUT_LIKE_FAIL';

export const PUT_DISLIKE_START = 'PUT_DISLIKE_START';
export const PUT_DISLIKE_SUCCESS = 'PUT_DISLIKE_SUCCESS';
export const PUT_DISLIKE_FAIL = 'PUT_DISLIKE_FAIL';

// Solution Rating
export const PUT_SOLUTION_RATING_START = 'PUT_SOLUTION_RATING_START';
export const PUT_SOLUTION_RATING_SUCCESS = 'PUT_SOLUTION_RATING_SUCCESS';
export const PUT_SOLUTION_RATING_FAIL = 'PUT_SOLUTION_RATING_FAIL';

//Total Referrals for Admin
export const GET_TOTAL_REFERRALS_START = 'GET_TOTAL_REFERRALS_START';
export const GET_TOTAL_REFERRALS_SUCCESS = 'GET_TOTAL_REFERRALS_SUCCESS';
export const GET_TOTAL_REFERRALS_FAIL = 'GET_TOTAL_REFERRALS_FAIL';
export const CREATE_REFERRALS_START = 'CREATE_REFERRALS_START';
export const CREATE_REFERRALS_SUCCESS = 'CREATE_REFERRALS_SUCCESS';
export const CREATE_REFERRALS_FAIL = 'CREATE_REFERRALS_FAIL';
export const RESET_REFERRALS = 'RESET_REFERRALS';

//Get Daily Statistics
export const GET_DAILY_STATISTICS_START = 'GET_DAILY_STATISTICS_START';
export const GET_DAILY_STATISTICS_SUCCESS = 'GET_DAILY_STATISTICS_SUCCESS';
export const GET_DAILY_STATISTICS_FAIL = 'GET_DAILY_STATISTICS_FAIL';

// Competitions
export const GET_COMPETITION_START = 'GET_COMPETITION_START';
export const GET_COMPETITION_SUCCESS = 'GET_COMPETITION_SUCCESS';
export const GET_COMPETITION_FAIL = 'GET_COMPETITION_FAIL';

export const GET_COMPETITION_QUESTION_START = 'GET_COMPETITION_QUESTION_START';
export const GET_COMPETITION_QUESTION_SUCCESS =
  'GET_COMPETITION_QUESTION_SUCCESS';
export const GET_COMPETITION_QUESTION_FAIL = 'GET_COMPETITION_QUESTION_FAIL';

export const GET_COMPETITION_BY_ID_START = 'GET_COMPETITION_BY_ID_START';
export const GET_COMPETITION_BY_ID_SUCCESS = 'GET_COMPETITION_BY_ID_SUCCESS';
export const GET_COMPETITION_BY_ID_FAIL = 'GET_COMPETITION_BY_ID_FAIL';

export const POST_COMPETITION_START = 'POST_COMPETITION_START';
export const POST_COMPETITION_SUCCESS = 'POST_COMPETITION_SUCCESS';
export const POST_COMPETITION_FAIL = 'POST_COMPETITION_FAIL';
export const GET_ACTIVE_JOBS_START = 'GET_ACTIVE_JOBS_START';
export const GET_ACTIVE_JOBS_SUCCESS = 'GET_ACTIVE_JOBS_SUCCESS';
export const GET_ACTIVE_JOBS_FAIL = 'GET_ACTIVE_JOBS_FAIL';

export const UPDATE_COMPETITION_START = 'UPDATE_COMPETITION_START';
export const UPDATE_COMPETITION_SUCCESS = 'UPDATE_COMPETITION_SUCCESS';
export const UPDATE_COMPETITION_FAIL = 'UPDATE_COMPETITION_FAIL';

export const DELETE_COMPETITION_START = 'DELETE_COMPETITION_START';
export const DELETE_COMPETITION_SUCCESS = 'DELETE_COMPETITION_SUCCESS';
export const DELETE_COMPETITION_FAIL = 'DELETE_COMPETITION_FAIL';

export const GET_COMPETITION_LIST_START = 'GET_COMPETITION_LIST_START';
export const GET_COMPETITION_LIST_SUCCESS = 'GET_COMPETITION_LIST_SUCCESS';
export const GET_COMPETITION_LIST_FAIL = 'GET_COMPETITION_LIST_FAIL';

export const POST_REGISTRATION_START = 'POST_REGISTRATION_START';
export const POST_REGISTRATION_SUCCESS = 'POST_REGISTRATION_SUCCESS';
export const POST_REGISTRATION_FAIL = 'POST_REGISTRATION_FAIL';

export const GET_RECOMMENDATIONS_START = 'GET_RECOMMENDATIONS_START';
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_FAIL = 'GET_RECOMMENDATIONS_FAIL';

export const GET_COMPETITION_SCHEDULE_START = 'GET_COMPETITION_SCHEDULE_START';
export const GET_COMPETITION_SCHEDULE_SUCCESS =
  'GET_COMPETITION_SCHEDULE_SUCCESS';
export const GET_COMPETITION_SCHEDULE_FAIL = 'POST_COMPETITION_SCHEDULE_FAIL';

export const POST_COMPETITION_SCHEDULE_START =
  'POST_COMPETITION_SCHEDULE_START';
export const POST_COMPETITION_SCHEDULE_SUCCESS =
  'POST_COMPETITION_SCHEDULE_SUCCESS';
export const POST_COMPETITION_SCHEDULE_FAIL = 'POST_COMPETITION_SCHEDULE_FAIL';

export const DELETE_COMPETITION_SCHEDULE_START =
  'DELETE_COMPETITION_SCHEDULE_START';
export const DELETE_COMPETITION_SCHEDULE_SUCCESS =
  'DELETE_COMPETITION_SCHEDULE_SUCCESS';
export const DELETE_COMPETITION_SCHEDULE_FAIL =
  'DELETE_COMPETITION_SCHEDULE_FAIL';

export const GET_CREDITS_START = 'GET_CREDITS_START';
export const GET_CREDITS_SUCCESS = 'GET_CREDITS_SUCCESS';
export const GET_CREDITS_FAIL = 'GET_CREDITS_FAIL';

export const GET_CERTIFICATE_PATH_START = 'GET_CERTIFICATE_PATH_START';
export const GET_CERTIFICATE_PATH_SUCCESS = 'GET_CERTIFICATE_PATH_SUCCESS';
export const GET_CERTIFICATE_PATH_FAIL = 'GET_CERTIFICATE_PATH_FAIL';

export const GET_COMPETITION_ANALYTICS_START =
  'GET_COMPETITION_ANALYTICS_START';
export const GET_COMPETITION_ANALYTICS_SUCCESS =
  'GET_COMPETITION_ANALYTICS_SUCCESS';
export const GET_COMPETITION_ANALYTICS_FAIL = 'GET_COMPETITION_ANALYTICS_FAIL';

export const UPLOAD_COMPETITION_IMG_START = 'UPLOAD_COMPETITION_IMG_START';
export const UPLOAD_COMPETITION_IMG_SUCCESS = 'UPLOAD_COMPETITION_IMG_SUCCESS';
export const UPLOAD_COMPETITION_IMG_FAIL = 'UPLOAD_COMPETITION_IMG_FAIL';
export const UPLOAD_COMPETITION_HEADER_SUCCESS =
  'UPLOAD_COMPETITION_HEADER_SUCCESS';

// Bulk Email

export const GET_EMAIL_TEMPLATE_LIST_START = 'GET_EMAIL_TEMPLATE_LIST_START';
export const GET_EMAIL_TEMPLATE_LIST_SUCCESS =
  'GET_EMAIL_TEMPLATE_LIST_SUCCESS';
export const GET_EMAIL_TEMPLATE_LIST_FAIL = 'GET_EMAIL_TEMPLATE_LIST_FAIL';

export const GET_EMAIL_TEMPLATE_BY_ID_START = 'GET_EMAIL_TEMPLATE_BY_ID_START';
export const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS =
  'GET_EMAIL_TEMPLATE_BY_ID_SUCCESS';
export const GET_EMAIL_TEMPLATE_BY_ID_FAIL = 'GET_EMAIL_TEMPLATE_BY_ID_FAIL';

export const CREATE_EMAIL_TEMPLATE_START = 'CREATE_EMAIL_TEMPLATE_START';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_FAIL = 'CREATE_EMAIL_TEMPLATE_FAIL';

export const UPDATE_EMAIL_TEMPLATE_START = 'UPDATE_EMAIL_TEMPLATE_START';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAIL = 'UPDATE_EMAIL_TEMPLATE_FAIL';

export const DELETE_EMAIL_TEMPLATE_START = 'DELETE_EMAIL_TEMPLATE_START';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_FAIL = 'DELETE_EMAIL_TEMPLATE_FAIL';

export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

export const POST_EMAIL_ATTACHMENT_START = 'POST_EMAIL_ATTACHMENT_START';
export const POST_EMAIL_ATTACHMENT_SUCCESS = 'POST_EMAIL_ATTACHMENT_SUCCESS';
export const POST_EMAIL_ATTACHMENT_FAIL = 'POST_EMAIL_ATTACHMENT_FAIL';

export const GET_EMAIL_LOGS_LIST_START = 'GET_EMAIL_LOGS_LIST_START';
export const GET_EMAIL_LOGS_LIST_SUCCESS = 'GET_EMAIL_LOGS_LIST_SUCCESS';
export const GET_EMAIL_LOGS_LIST_FAIL = 'GET_EMAIL_LOGS_LIST_FAIL';

export const DELETE_SCHEDULED_EMAILS_LIST_START =
  'DELETE_SCHEDULED_EMAILS_LIST_START';
export const DELETE_SCHEDULED_EMAILS_LIST_SUCCESS =
  'DELETE_SCHEDULED_EMAILS_LIST_SUCCESS';
export const DELETE_SCHEDULED_EMAILS_LIST_FAIL =
  'DELETE_SCHEDULED_EMAILS_LIST_FAIL';

export const GET_SCHEDULED_EMAILS_LIST_START =
  'GET_SCHEDULED_EMAILS_LIST_START';
export const GET_SCHEDULED_EMAILS_LIST_SUCCESS =
  'GET_SCHEDULED_EMAILS_LIST_SUCCESS';
export const GET_SCHEDULED_EMAILS_LIST_FAIL = 'GET_SCHEDULED_EMAILS_LIST_FAIL';

export const REMOVE_EMAILING_LIST_ATTACHMENT =
  'REMOVE_EMAILING_LIST_ATTACHMENT';

export const CLEAR_EMAILING_LIST_STATE = 'CLEAR_EMAILING_LIST_STATE';

// Order History
export const GET_ORDER_HISTORY_START = 'GET_ORDER_HISTORY_START';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAIL = 'GET_ORDER_HISTORY_FAIL';

export const GET_INVOICE_FILE_NAME_START = 'GET_INVOICE_NAME_START';
export const GET_INVOICE_FILE_NAME_SUCCESS = 'GET_INVOICE_NAME_SUCCESS';
export const GET_INVOICE_FILE_NAME_FAIL = 'GET_INVOICE_NAME_FAIL';

export const GET_INVOICE_FILE_FROM_PATH_START =
  'GET_INVOICE_FILE_FROM_PATH_START';
export const GET_INVOICE_FILE_FROM_PATH_SUCCESS =
  'GET_INVOICE_FILE_FROM_PATH_SUCCESS';
export const GET_INVOICE_FILE_FROM_PATH_FAIL =
  'GET_INVOICE_FILE_FROM_PATH_FAIL';

// Templates
export const GET_REVIEW_TEMPLATE_SUCCESS = 'GET_REVIEW_TEMPLATE_SUCCESS';
export const GET_REVIEW_TEMPLATE_FAIL = 'GET_REVIEW_TEMPLATE_FAIL';
export const GET_REVIEW_TEMPLATE_START = 'GET_REVIEW_TEMPLATE_START';
export const POST_REVIEW_TEMPLATE_SUCCESS = 'POST_REVIEW_TEMPLATE_SUCCESS';
export const POST_REVIEW_TEMPLATE_FAIL = 'POST_REVIEW_TEMPLATE_FAIL';
export const POST_REVIEW_TEMPLATE_START = 'POST_REVIEW_TEMPLATE_START';
export const DELETE_REVIEW_TEMPLATE_START = 'DELETE_REVIEW_TEMPLATE_START';
export const DELETE_REVIEW_TEMPLATE_SUCCESS = 'DELETE_REVIEW_TEMPLATE_SUCCESS';
export const DELETE_REVIEW_TEMPLATE_FAIL = 'DELETE_REVIEW_TEMPLATE_FAIL';

// User Store
export const GET_USER_PRODUCT_START = 'GET_USER_PRODUCT_START';
export const GET_USER_PRODUCT_SUCCESS = 'GET_USER_PRODUCT_SUCCESS';
export const GET_USER_PRODUCT_FAIL = 'GET_USER_PRODUCT_FAIL';

export const POST_SHIPPING_ADDRESS_START = 'POST_SHIPPING_ADDRESS_START';
export const POST_SHIPPING_ADDRESS_SUCCESS = 'POST_SHIPPING_ADDRESS_SUCCESS';
export const POST_SHIPPING_ADDRESS_FAIL = 'POST_SHIPPING_ADDRESS_FAIL';
export const GET_SHIPPING_ADDRESS_START = 'GET_SHIPPING_ADDRESS_START';
export const GET_SHIPPING_ADDRESS_SUCCESS = 'GET_SHIPPING_ADDRESS_SUCCESS';
export const GET_SHIPPING_ADDRESS_FAIL = 'GET_SHIPPING_ADDRESS_FAIL';

export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_UPDATE_START = 'POST_UPDATE_START';
export const GET_CART_LIST_START = 'GET_CART_LIST_START';
export const GET_CART_LIST_SUCCESS = 'GET_CART_LIST_SUCCESS';
export const GET_CART_LIST_FAIL = 'GET_CART_LIST_FAIL';

export const POST_PAYMENT_START = 'POST_PAYMENT_START';
export const POST_PAYMENT_SUCCESS = 'POST_PAYMENT_SUCCESS';
export const POST_PAYMENT_FAIL = 'POST_PAYMENT_FAIL';
export const CHECK_PAYMENT_STATUS_START = 'CHECK_PAYMENT_STATUS_START';
export const CHECK_PAYMENT_STATUS_SUCCESS = 'CHECK_PAYMENT_STATUS_SUCCESS';
export const CHECK_PAYMENT_STATUS_FAIL = 'CHECK_PAYMENT_STATUS_FAIL';

export const CANCEL_PAYMENT_STATUS_START = 'CANCEL_PAYMENT_STATUS_START';
export const CANCEL_PAYMENT_STATUS_SUCCESS = 'CANCEL_PAYMENT_STATUS_SUCCESS';
export const CANCEL_PAYMENT_STATUS_FAIL = 'CANCEL_PAYMENT_STATUS_FAIL';

// Admin Store
export const POST_PRODUCT_START = 'POST_PRODUCT_START';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAIL = 'POST_PRODUCT_FAIL';
export const GET_PRODUCT_START = 'GET_PRODUCT_START';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAIL = 'GET_PRODUCT_BY_ID_FAIL';
export const POST_PRODUCT_IMAGE_START = 'POST_PRODUCT_IMAGE_START';
export const POST_PRODUCT_IMAGE_SUCCESS = 'POST_PRODUCT_IMAGE_SUCCESS';
export const POST_PRODUCT_IMAGE_FAIL = 'POST_PRODUCT_IMAGE_FAIL';

export const CHANGE_PRODUCT_STATUS_START = 'CHANGE_PRODUCT_STATUS_START';
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS';
export const CHANGE_PRODUCT_STATUS_FAIL = 'CHANGE_PRODUCT_STATUS_FAIL';

export const RESET_TEMPLATE = 'RESET_TEMPLATE';

// Admin Orders List
export const GET_ORDERS_LIST_START = 'GET_ORDERS_LIST_START';
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS';
export const GET_ORDERS_LIST_FAIL = 'GET_ORDERS_LIST_FAIL';

export const GET_SINGLE_ORDER_FAIL = 'GET_SINGLE_ORDER_FAIL';
export const GET_SINGLE_ORDER_START = 'GET_SINGLE_ORDER_START';
export const GET_SINGLE_ORDER_SUCCESS = 'GET_SINGLE_ORDER_SUCCESS';

export const UPDATE_ADMIN_ORDERS_START = 'UPDATE_ADMIN_ORDERS_START';
export const UPDATE_ADMIN_ORDERS_SUCCESS = 'UPDATE_ADMIN_ORDERS_SUCCESS';
export const UPDATE_ADMIN_ORDERS_FAIL = 'UPDATE_ADMIN_ORDERS_FAIL';

// College Data Management
export const MERGE_DULTICATES_START = 'MERGE_DULTICATES_START';
export const MERGE_DULTICATES_SUCCESS = 'MERGE_DULTICATES_SUCCESS';
export const MERGE_DULTICATES_FAIL = 'MERGE_DULTICATES_FAIL';

export const GET_COLLEGE_START = 'GET_COLLEGE_START';
export const GET_COLLEGE_SUCCESS = 'GET_COLLEGE_SUCCESS';
export const GET_COLLEGE_FAIL = 'GET_COLLEGE_FAIL';

export const POST_COLLEGE_START = 'POST_COLLEGE_START';
export const POST_COLLEGE_SUCCESS = 'POST_COLLEGE_SUCCESS';
export const POST_COLLEGE_FAIL = 'POST_COLLEGE_FAIL';

export const UPDATE_COLLEGE_START = 'UPDATE_COLLEGE_START';
export const UPDATE_COLLEGE_SUCCESS = 'UPDATE_COLLEGE_SUCCESS';
export const UPDATE_COLLEGE_FAIL = 'UPDATE_COLLEGE_FAIL';

export const DELETE_COLLEGE_START = 'DELETE_COLLEGE_START';
export const DELETE_COLLEGE_SUCCESS = 'DELETE_COLLEGE_SUCCESS';
export const DELETE_COLLEGE_FAIL = 'DELETE_COLLEGE_FAIL';

// company Data Management
export const GET_COMPANY_START = 'GET_COMPANY_START';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const POST_COMPANY_START = 'POST_COMPANY_START';
export const POST_COMPANY_SUCCESS = 'POST_COMPANY_SUCCESS';
export const POST_COMPANY_FAIL = 'POST_COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

// Exam Data Management
export const GET_EXAM_START = 'GET_EXAM_START';
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS';
export const GET_EXAM_FAIL = 'GET_EXAM_FAIL';

export const POST_EXAM_START = 'POST_EXAM_START';
export const POST_EXAM_SUCCESS = 'POST_EXAM_SUCCESS';
export const POST_EXAM_FAIL = 'POST_EXAM_FAIL';

export const UPDATE_EXAM_START = 'UPDATE_EXAM_START';
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAM_SUCCESS';
export const UPDATE_EXAM_FAIL = 'UPDATE_COMPANY_FAIL';

export const DELETE_EXAM_START = 'DELETE_EXAM_START';
export const DELETE_EXAM_SUCCESS = 'DELETE_EXAM_SUCCESS';
export const DELETE_EXAM_FAIL = 'DELETE_EXAM_FAIL';

export const POST_IMAGE_START = 'POST_IMAGE_START';
export const POST_IMAGE_SUCESS = 'POST_IMAGE_SUCESS';
export const POST_IMAGE_FAIL = 'POST_IMAGE_FAIL';

// Job Role Data Management
export const GET_JOB_ROLE_START = 'GET_JOB_ROLE_START';
export const GET_JOB_ROLE_SUCCESS = 'GET_JOB_ROLE_SUCCESS';
export const GET_JOB_ROLE_FAIL = 'GET_JOB_ROLE_FAIL';

export const POST_JOB_ROLE_START = 'POST_JOB_ROLE_START';
export const POST_JOB_ROLE_SUCCESS = 'POST_JOB_ROLE_SUCCESS';
export const POST_JOB_ROLE_FAIL = 'POST_JOB_ROLE_FAIL';

export const UPDATE_JOB_ROLE_START = 'UPDATE_JOB_ROLE_START';
export const UPDATE_JOB_ROLE_SUCCESS = 'UPDATE_JOB_ROLE_SUCCESS';
export const UPDATE_JOB_ROLE_FAIL = 'UPDATE_JOB_ROLE_FAIL';

export const DELETE_JOB_ROLE_START = 'DELETE_JOB_ROLE_START';
export const DELETE_JOB_ROLE_SUCCESS = 'DELETE_JOB_ROLE_SUCCESS';
export const DELETE_JOB_ROLE_FAIL = 'DELETE_JOB_ROLE_FAIL';

// Skills Data Management
export const GET_ADMIN_SKILLS_START = 'GET_ADMIN_SKILLS_START';
export const GET_ADMIN_SKILLS_SUCCESS = 'GET_ADMIN_SKILLS_SUCCESS';
export const GET_ADMIN_SKILLS_FAIL = 'GET_ADMIN_SKILLS_FAIL';

export const POST_ADMIN_SKILLS_START = 'POST_ADMIN_SKILLS_START';
export const POST_ADMIN_SKILLS_SUCCESS = 'POST_ADMIN_SKILLS_SUCCESS';
export const POST_ADMIN_SKILLS_FAIL = 'POST_ADMIN_SKILLS_FAIL';

export const UPDATE_ADMIN_SKILLS_START = 'UPDATE_ADMIN_SKILLS_START';
export const UPDATE_ADMIN_SKILLS_SUCCESS = 'UPDATE_ADMIN_SKILLS_SUCCESS';
export const UPDATE_ADMIN_SKILLS_FAIL = 'UPDATE_ADMIN_SKILLS_FAIL';

export const DELETE_ADMIN_SKILLS_START = 'DELETE_ADMIN_SKILLS_START';
export const DELETE_ADMIN_SKILLS_SUCCESS = 'DELETE_ADMIN_SKILLS_SUCCESS';
export const DELETE_ADMIN_SKILLS_FAIL = 'DELETE_ADMIN_SKILLS_FAIL';

//Feedback API
export const POST_FEEDBACK_START = 'POST_FEEDBACK_START';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_FAIL = 'POST_FEEDBACK_FAIL';

export const UPLOAD_FEEDBACK_IMAGE_START = 'UPLOAD_FEEDBACK_IMAGE_START';
export const UPLOAD_FEEDBACK_IMAGE_SUCCESS = 'UPLOAD_FEEDBACK_IMAGE_SUCCESS';
export const UPLOAD_FEEDBACK_IMAGE_FAIL = 'UPLOAD_FEEDBACK_IMAGE_FAIL';
// Repoted Bugs

export const GET_REPORTED_BUGS_START = 'GET_REPORTED_BUGS_START';
export const GET_REPORTED_BUGS_SUCCESS = 'GET_REPORTED_BUGS_SUCCESS';
export const GET_REPORTED_BUGS_FAIL = 'GET_REPORTED_BUGS_FAIL';

export const EDIT_REPORTED_BUG_START = 'EDIT_REPORTED_BUG_START';
export const EDIT_REPORTED_BUG_SUCCESS = 'EDIT_REPORTED_BUG_SUCCESS';
export const EDIT_REPORTED_BUG_FAIL = 'EDIT_REPORTED_BUG_FAIL';

//Report Review (Voting)
export const GET_VOTING_REPORT_QUESTION_SUCCESS =
  'GET_VOTING_REPORT_QUESTION_SUCCESS';
export const GET_VOTING_REPORT_QUESTION_FAIL =
  'GET_VOTING_REPORT_QUESTION_FAIL';
export const GET_VOTED_REPORT_QUESTION_SUCCESS =
  'GET_VOTED_REPORT_QUESTION_SUCCESS';
export const GET_VOTED_REPORT_QUESTION_FAIL = 'GET_VOTED_REPORT_QUESTION_FAIL';

export const POST_VOTING_REPORT_QUESTION_SUCCESS =
  'POST_VOTING_REPORT_QUESTION_SUCCESS';
export const POST_VOTING_REPORT_QUESTION_FAIL =
  'POST_VOTING_REPORT_QUESTION_FAIL';

export const GET_PUBLIC_QUESTION_SUCCESS = 'GET_PUBLIC_QUESTION_SUCCESS';
export const GET_PUBLIC_QUESTION_FAIL = 'GET_PUBLIC_QUESTION_FAIL';

// MockTest
export const GET_MOCK_TEST_PREREQUISITE_START =
  'GET_MOCK_TEST_PREREQUISIT_START';
export const GET_MOCK_TES_PREREQUISITT_SUCCESS =
  'GET_MOCK_TEST_PREREQUISIT_SUCCESS';
export const GET_MOCK_TEST_PREREQUISIT_FAIL = 'GET_MOCK_TEST_PREREQUISIT_FAIL';

export const GET_MOCK_TEST_START = 'GET_MOCK_TEST_START';
export const GET_MOCK_TEST_SUCCESS = 'GET_MOCK_TEST_SUCCESS';
export const GET_MOCK_TEST_FAIL = 'GET_MOCK_TEST_FAIL';

export const GET_MOCK_TEST_BY_ID_START = 'GET_MOCK_TEST_BY_ID_STARD';
export const GET_MOCK_TEST_BY_ID_SUCCESS = 'GET_MOCK_TEST_BY_ID_SUCCESS';
export const GET_MOCK_TEST_BY_ID_FAIL = 'GET_MOCK_TEST_BY_ID_FAIL';

export const POST_MOCK_TEST_START = 'POST_MOCK_TEST_START';
export const POST_MOCK_TEST_SUCCESS = 'POST_MOCK_TEST_SUCCESS';
export const POST_MOCK_TEST_FAIL = 'POST_MOCK_TEST_FAIL';

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export const CREATE_MOCK_TEST_START = 'CREATE_MOCK_TEST_START';
export const CREATE_MOCK_TEST_SUCCESS = 'CREATE_MOCK_TEST_SUCCESS';
export const CREATE_MOCK_TEST_FAIL = 'CREATE_MOCK_TEST_FAIL';

export const START_MOCK_TEST_START = 'START_MOCK_TEST_START';
export const START_MOCK_TEST_SUCCESS = 'START_MOCK_TEST_SUCCESS';
export const START_MOCK_TEST_FAIL = 'START_MOCK_TEST_FAIL';

export const GET_MOCK_TEST_QUESTIONS_START = 'GET_MOCK_TEST_QUESTIONS_START';
export const GET_MOCK_TEST_QUESTIONS_SUCCESS =
  'GET_MOCK_TEST_QUESTIONS_SUCCESS';
export const GET_MOCK_TEST_QUESTIONS_FAIL = 'GET_MOCK_TEST_QUESTIONS_FAIL';

export const END_MOCK_TEST_START = 'END_MOCK_TEST_START';
export const END_MOCK_TEST_SUCCESS = 'END_MOCK_TEST_SUCCESS';
export const END_MOCK_TEST_FAIL = 'END_MOCK_TEST_FAIL';

export const DELETE_MOCK_TEST_START = 'DELETE_MOCK_TEST_START';
export const DELETE_MOCK_TEST_SUCCESS = '_ELETEMOCK_TEST_SUCCESS';
export const DELETE_MOCK_TEST_FAIL = '_ELETETEST_FAIL';

export const CLEAR_MOCK_TEST_STATE = 'CLEAR_MOCK_TEST_STATE';

export const EDIT_MOCK_TEST_START = 'EDIT_MOCK_TEST_START';
export const EDIT_MOCK_TEST_SUCCESS = 'EDIT_MOCK_TEST_SUCCESS';
export const EDIT_MOCK_TEST_FAIL = 'EDIT_MOCK_TEST_FAIL';
// CREATE NEW DOMAIN
export const CREATE_NEW_DOMAIN_START = 'CREATE_NEW_DOMAIN_START';
export const CREATE_NEW_DOMAIN_SUCCESS = 'CREATE_NEW_DOMAIN_SUCCESS';
export const CREATE_NEW_DOMAIN_FAIL = 'CREATE_NEW_DOMAIN_FAIL';
export const EDIT_DOMAIN_START = 'EDIT_DOMAIN_START';
export const EDIT_DOMAIN_SUCCESS = 'EDIT_DOMAIN_SUCCESS';
export const EDIT_DOMAIN_FAIL = 'EDIT_DOMAIN_FAIL';

// GET CREDITS COST
export const GET_CREDITS_COST_START = 'GET_CREDITS_COST_START';
export const GET_CREDITS_COST_SUCCESS = 'GET_CREDITS_COST_SUCCESS';
export const GET_CREDITS_COST_FAIL = 'GET_CREDITS_COST_FAIL';
//ADMIN BADGES
export const GET_BADGES_START = 'GET_BADGES_START';
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAIL = 'GET_BADGES_FAIL';

export const POST_BADGES_START = 'POST_BADGES_START';
export const POST_BADGES_SUCCESS = 'POST_BADGES_SUCCESS';
export const POST_BADGES_FAIL = 'POST_BADGES_FAIL';

export const POST_BADGE_IMAGE_START = 'POST_BADGE_IMAGE_START';
export const POST_BADGE_IMAGE_SUCCESS = 'POST_BADGE_IMAGE_SUCCESS';
export const POST_BADGE_IMAGE_FAIL = 'POST_BADGE_IMAGE_FAIL';

export const UPDATE_BADGE_START = 'POST_BADGE_IMAGE_FAIL';
export const UPDATE_BADGE_SUCCESS = 'UPDATE_BADGE_SUCCESS';
export const UPDATE_BADGE_FAIL = 'UPDATE_BADGE_FAIL';

export const DELETE_BADGE_START = 'DELETE_BADGE_START';
export const DELETE_BADGE_SUCCESS = 'DELETE_BADGE_SUCCESS';
export const DELETE_BADGE_FAIL = 'DELETE_BADGE_FAIL';

export const GET_BADGE_TYPE_START = 'GET_BADGE_TYPE_START';
export const GET_BADGE_TYPE_SUCCESS = 'GET_BADGE_TYPE_SUCCESS';
export const GET_BADGE_TYPE_FAIL = 'GET_BADGE_TYPE_FAIL';

//ADMIN LEVELS
export const GET_LEVELS_START = 'GET_LEVELS_START';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL = 'GET_LEVELS_FAIL';

export const POST_LEVELS_START = 'POST_LEVELS_START';
export const POST_LEVELS_SUCCESS = 'POST_LEVELS_SUCCESS';
export const POST_LEVELS_FAIL = 'POST_LEVELS_FAIL';

export const POST_LEVEL_IMAGE_START = 'POST_LEVEL_IMAGE_START';
export const POST_LEVEL_IMAGE_SUCCESS = 'POST_LEVEL_IMAGE_SUCCESS';
export const POST_LEVEL_IMAGE_FAIL = 'POST_LEVEL_IMAGE_FAIL';

export const UPDATE_LEVEL_START = 'POST_LEVEL_IMAGE_FAIL';
export const UPDATE_LEVEL_SUCCESS = 'UPDATE_LEVEL_SUCCESS';
export const UPDATE_LEVEL_FAIL = 'UPDATE_LEVEL_FAIL';

export const DELETE_LEVEL_START = 'DELETE_LEVEL_START';
export const DELETE_LEVEL_SUCCESS = 'DELETE_LEVEL_SUCCESS';
export const DELETE_LEVEL_FAIL = 'DELETE_LEVEL_FAIL';

// Competition Start Statistics
export const GET_START_STATISTICS_START = 'GET_START_STATISTICS_START';
export const GET_START_STATISTICS_SUCCESS = 'GET_START_STATISTICS_SUCCESS';
export const GET_START_STATISTICS_FAIL = 'GET_START_STATISTICS_FAIL';

// Competition Detail Admin Analytics
export const GET_COMPETITION_DETAIL_ANALYTICS_START =
  'GET_COMPETITION_DETAIL_ANALYTICS_START';
export const GET_COMPETITION_DETAIL_ANALYTICS_SUCCESS =
  'GET_COMPETITION_DETAIL_ANALYTICS_SUCCESS';
export const GET_COMPETITION_DETAIL_ANALYTICS_FAIL =
  'GET_COMPETITION_DETAIL_ANALYTICS_FAIL';
// Mock Start Statistics
export const GET_MOCK_TEST_STATISTICS_START = 'GET_MOCK_TEST_STATISTICS_START';
export const GET_MOCK_TEST_STATISTICS_SUCCESS =
  'GET_MOCK_TEST_STATISTICS_SUCCESS';
export const GET_MOCK_TEST_STATISTICS_FAIL = 'GET_MOCK_TEST_STATISTICS_FAIL';

export const OPEN_DOMAIN_SELECT_MODAL = 'OPEN_DOMAIN_SELECT_MODAL';
export const CLOSE_DOMAIN_SELECT_MODAL = 'CLOSE_DOMAIN_SELECT_MODAL';

// Discussions
export const GET_RECENT_ATTEMPT_DISCUSSIONS_START =
  'GET_RECENT_ATTEMPT_DISCUSSIONS_START';

export const GET_RECENT_ATTEMPT_DISCUSSIONS_SUCCESS =
  'GET_RECENT_ATTEMPT_DISCUSSIONS_SUCCESS';

export const GET_RECENT_ATTEMPT_DISCUSSIONS_FAIL =
  'GET_RECENT_ATTEMPT_DISCUSSIONS_FAIL';

export const CLEAR_RECENT_ATTEMPT_DISCUSSIONS_STATE =
  'CLEAR_RECENT_ATTEMPT_DISCUSSIONS_STATE';

export const GET_HOT_TOPICS_DISCUSSIONS_START =
  'GET_HOT_TOPICS_DISCUSSIONS_START';

export const GET_HOT_TOPICS_DISCUSSIONS_SUCCESS =
  'GET_HOT_TOPICS_DISCUSSIONS_SUCCESS';

export const GET_HOT_TOPICS_DISCUSSIONS_FAIL =
  'GET_HOT_TOPICS_DISCUSSIONS_FAIL';

export const CLEAR_HOT_TOPICS_DISCUSSIONS_STATE =
  'CLEAR_HOT_TOPICS_DISCUSSIONS_STATE';

export const GET_COMMENTS_FOR_QUESTION_START =
  'GET_COMMENTS_FOR_QUESTION_START';

export const GET_COMMENTS_FOR_QUESTION_SUCCESS =
  'GET_COMMENTS_FOR_QUESTION_SUCCESS';

export const GET_COMMENTS_FOR_QUESTION_FAIL = 'GET_COMMENTS_FOR_QUESTION_FAIL';

export const CLEAR_COMMENTS_FOR_QUESTION_STATE =
  'CLEAR_COMMENTS_FOR_QUESTION_STATE';

export const VOTE_A_COMMENT_START = 'VOTE_A_COMMENT_START';

export const VOTE_A_COMMENT_SUCCESS = 'VOTE_A_COMMENT_SUCCESS';

export const VOTE_A_COMMENT_FAIL = 'VOTE_A_COMMENT_FAIL';

export const CLEAR_VOTE_A_COMMENT_STATE = 'CLEAR_VOTE_A_COMMENT_STATE';

export const REPORT_COMMENT_START = 'REPORT_COMMENT_START';

export const REPORT_COMMENT_SUCCESS = 'REPORT_COMMENT_SUCCESS';

export const REPORT_COMMENT_FAIL = 'REPORT_COMMENT_FAIL';

export const REPORT_COMMENT_CLEAR = 'REPORT_COMMENT_CLEAR';

export const ADD_COMMENT_TO_QUESTION_START = 'ADD_COMMENT_TO_QUESTION_START';

export const ADD_COMMENT_TO_QUESTION_SUCCESS =
  'ADD_COMMENT_TO_QUESTION_SUCCESS';

export const ADD_COMMENT_TO_QUESTION_FAIL = 'ADD_COMMENT_TO_QUESTION_FAIL';

export const ADD_NEW_DISCUSSION_COMMENT_START = 'ADD_NEW_DISCUSSION_START';

export const ADD_NEW_DISCUSSION_COMMENT_SUCCESS = 'ADD_NEW_DISCUSSION_SUCCESS';

export const ADD_NEW_DISCUSSION_COMMENT_FAIL = 'ADD_NEW_DISCUSSION_FAIL';

// Comment reports
export const GET_COMMENT_REPORTS_START = 'GET_COMMENT_REPORTS_START';

export const GET_COMMENT_REPORTS_SUCCESS = 'GET_COMMENT_REPORTS_SUCCESS';

export const GET_COMMENT_REPORTS_FAIL = 'GET_COMMENT_REPORTS_FAIL';

export const CLEAR_COMMENT_REPORTS_STATE = 'CLEAR_COMMENT_REPORTS_STATE';

export const REVIEW_COMMENT_START = 'REVIEW_COMMENTS_REPORT_START';

export const REVIEW_COMMENT_SUCCESS = 'REVIEW_COMMENT_SUCCESS';

export const REVIEW_COMMENT_FAIL = 'REVIEW_COMMENT_FAIL';

export const GET_ALL_COMMENT_START = 'GET_ALL_COMMENT_START';

export const GET_ALL_COMMENT_SUCCESS = 'GET_ALL_COMMENT_SUCCESS';

export const GET_ALL_COMMENT_FAIL = 'GET_ALL_COMMENT_FAIL';

export const CLEAR_ALL_COMMENT_STATE = 'CLEAR_ALL_COMMENT_STATE';

// User Search Query
export const GET_MATCHING_USERS_START = 'GET_MATCHING_USERS_START';
export const GET_MATCHING_USERS_SUCCESS = 'GET_MATCHING_USERS_SUCCESS';
export const GET_MATCHING_USERS_FAIL = 'GET_MATCHING_USERS_FAIL';
export const CLEAR_MATCHING_USERS_STATE = 'CLEAR_MATCHING_USERS_STATE';

export const CLAIM_REWARDS = 'CLAIM_REWARDS';

// Voting
export const GET_VOTING_QUESTION_START = 'GET_VOTING_QUESTION_START';
export const GET_VOTING_QUESTION_SUCCESS = 'GET_VOTING_QUESTION_SUCCESS';
export const GET_VOTING_QUESTION_FAIL = 'GET_VOTING_QUESTION_FAIL';

export const POST_VOTING_QUESTION_RESPONSE_SUCCESS =
  'POST_VOTING_QUESTION_RESPONSE_SUCCESS';
export const POST_VOTING_QUESTION_RESPONSE_ERROR =
  'POST_VOTING_QUESTION_RESPONSE_ERROR';

export const GET_HISTORY_VOTED_QUESTION_SUCCESS =
  'GET_HISTORY_VOTED_QUESTION_SUCCESS';

export const GET_VOTING_REPORTED_QUESTION_SUCCESS =
  'GET_VOTING_REPORTED_QUESTION_SUCCESS';

// Admin Job Review
export const GET_JOB_REVIEW_START = 'GET_JOB_REVIEW_START';
export const GET_JOB_REVIEW_SUCCESS = 'GET_JOB_REVIEW_SUCCESS';
export const GET_JOB_REVIEW_FAIL = 'GET_JOB_REVIEW_FAIL';

// Admin Org Review
export const GET_ORG_REVIEW_START = 'GET_ORG_REVIEW_START';
export const GET_ORG_REVIEW_SUCCESS = 'GET_ORG_REVIEW_SUCCESS';
export const GET_ORG_REVIEW_FAIL = 'GET_ORG_REVIEW_FAIL';

// Bulk Org Status Update
export const ORG_BULK_STATUS_UPDATE_START = 'ORG_BULK_STATUS_UPDATE_START';
export const ORG_BULK_STATUS_UPDATE_SUCCESS = 'ORG_BULK_STATUS_UPDATE_SUCCESS';
export const ORG_BULK_STATUS_UPDATE_FAIL = 'ORG_BULK_STATUS_UPDATE_FAIL';

// Job Premium Update
export const JOBS_PREMIUM_UPDATE_START = 'JOBS_PREMIUM_UPDATE_START';
export const JOBS_PREMIUM_UPDATE_SUCCESS = 'JOBS_PREMIUM_UPDATE_SUCCESS';
export const JOBS_PREMIUM_UPDATE_FAIL = 'JOBS_PREMIUM_UPDATE_FAIL';

// Bulk Job Status Update
export const JOBS_BULK_STATUS_UPDATE_START = 'JOBS_BULK_STATUS_UPDATE_START';
export const JOBS_BULK_STATUS_UPDATE_SUCCESS =
  'JOBS_BULK_STATUS_UPDATE_SUCCESS';
export const JOBS_BULK_STATUS_UPDATE_FAIL = 'JOBS_BULK_STATUS_UPDATE_FAIL';

// Delete Jobs
export const DELETE_JOBS_START = 'DELETE_JOBS_START';
export const DELETE_JOBS_SUCCESS = 'DELETE_JOBS_SUCCESS';
export const DELETE_JOBS_FAIL = 'DELETE_JOBS_FAIL';

// GET STUDENT COLLEGES
export const GET_USER_COLLEGES_START = 'GET_USER_COLLEGES_START';
export const GET_USER_COLLEGES_SUCCESS = 'GET_USER_COLLEGES_SUCCESS';
export const GET_USER_COLLEGES_FAIL = 'GET_USER_COLLEGES_FAIL';

// GET COLLEGE RELATED DETAILS
export const GET_COLLEGE_RELATED_DETAILS_START =
  'GET_COLLEGE_RELATED_DETAILS_START';
export const GET_COLLEGE_RELATED_DETAILS_SUCCESS =
  'GET_COLLEGE_RELATED_DETAILS_SUCCESS';
export const GET_COLLEGE_RELATED_DETAILS_FAIL =
  'GET_COLLEGE_RELATED_DETAILS_FAIL';

// GET COLLEGE RELATED DETAILS
export const GET_COLLEGE_LEADERBOARD_START = 'GET_COLLEGE_LEADERBOARD_START';
export const GET_COLLEGE_LEADERBOARD_SUCCESS =
  'GET_COLLEGE_LEADERBOARD_SUCCESS';
export const GET_COLLEGE_LEADERBOARD_FAIL = 'GET_COLLEGE_LEADERBOARD_FAIL';

// GET COLLEGE RELATED DETAILS
export const GET_COLLEGE_JOBS_START = 'GET_COLLEGE_JOBS_START';
export const GET_COLLEGE_JOBS_SUCCESS = 'GET_COLLEGE_JOBS_SUCCESS';
export const GET_COLLEGE_JOBS_FAIL = 'GET_COLLEGE_JOBS_FAIL';

// GET COLLEGE RELATED DETAILS
export const GET_COLLEGE_STATS_START = 'GET_COLLEGE_STATS_START';
export const GET_COLLEGE_STATS_SUCCESS = 'GET_COLLEGE_STATS_SUCCESS';
export const GET_COLLEGE_STATS_FAIL = 'GET_COLLEGE_STATS_FAIL';

// POST STUDENT VERIFICATION DOC
export const UPLOAD_STUDENT_VERIFICATION_START =
  'UPLOAD_STUDENT_VERIFICATION_START';
export const UPLOAD_STUDENT_VERIFICATION_SUCCESS =
  'UPLOAD_STUDENT_VERIFICATION_SUCCESS';
export const UPLOAD_STUDENT_VERIFICATION_FAIL =
  'UPLOAD_STUDENT_VERIFICATION_FAIL';

// POST EDUCATION DETAILS
export const POST_EDUCATION_DETAILS_START = 'POST_EDUCATION_DETAILS_START';
export const POST_EDUCATION_DETAILS_SUCCESS = 'POST_EDUCATION_DETAILS_SUCCESS';
export const POST_EDUCATION_DETAILS_FAIL = 'POST_EDUCATION_DETAILS_FAIL';

// POST COLLEGE DASHBOARD MILESTONE
export const POST_COLLEGE_DASHBOARD_MILESTONE_START =
  'POST_COLLEGE_DASHBOARD_MILESTONE_START';
export const POST_COLLEGE_DASHBOARD_MILESTONE_SUCCESS =
  'POST_COLLEGE_DASHBOARD_MILESTONE_SUCCESS';
export const POST_COLLEGE_DASHBOARD_MILESTONE_FAIL =
  'POST_COLLEGE_DASHBOARD_MILESTONE_FAIL';

// GET COLLEGE DASHBOARD MILESTONE
export const GET_COLLEGE_DASHBOARD_MILESTONE_START =
  'GET_COLLEGE_DASHBOARD_MILESTONE_START';
export const GET_COLLEGE_DASHBOARD_MILESTONE_SUCCESS =
  'GET_COLLEGE_DASHBOARD_MILESTONE_SUCCESS';
export const GET_COLLEGE_DASHBOARD_MILESTONE_FAIL =
  'GET_COLLEGE_DASHBOARD_MILESTONE_FAIL';

// DELETE COLLEGE DASHBOARD MILESTONE
export const DELETE_COLLEGE_DASHBOARD_MILESTONE_START =
  'DELETE_COLLEGE_DASHBOARD_MILESTONE_START';
export const DELETE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS =
  'DELETE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS';
export const DELETE_COLLEGE_DASHBOARD_MILESTONE_FAIL =
  'DELETE_COLLEGE_DASHBOARD_MILESTONE_FAIL';

// UPDATE COLLEGE DASHBOARD MILESTONE
export const UPDATE_COLLEGE_DASHBOARD_MILESTONE_START =
  'UPDATE_COLLEGE_DASHBOARD_MILESTONE_START';
export const UPDATE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS =
  'UPDATE_COLLEGE_DASHBOARD_MILESTONE_SUCCESS';
export const UPDATE_COLLEGE_DASHBOARD_MILESTONE_FAIL =
  'UPDATE_COLLEGE_DASHBOARD_MILESTONE_FAIL';

// UPDATE COLLEGE DASHBOARD MILESTONE
export const UPLOAD_COLLEGE_MILESTONE_IMG_START =
  'UPLOAD_COLLEGE_MILESTONE_IMG_START';
export const UPLOAD_COLLEGE_MILESTONE_IMG_SUCCESS =
  'UPLOAD_COLLEGE_MILESTONE_IMG_SUCCESS';
export const UPLOAD_COLLEGE_MILESTONE_IMG_FAIL =
  'UPLOAD_COLLEGE_MILESTONE_IMG_FAIL';

// GET DASHBOARD
export const GET_DASHBOARD_START = 'GET_DASHBOARD_START';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';

// ADMIN DEGREE
export const GET_ADMIN_DEGREE_START = 'GET_ADMIN_DEGREE_START';
export const GET_ADMIN_DEGREE_SUCCESS = 'GET_ADMIN_DEGREE_SUCCESS';
export const GET_ADMIN_DEGREE_FAIL = 'GET_ADMIN_DEGREE_FAIL';

export const POST_ADMIN_DEGREE_START = 'POST_ADMIN_DEGREE_START';
export const POST_ADMIN_DEGREE_SUCCESS = 'POST_ADMIN_DEGREE_SUCCESS';
export const POST_ADMIN_DEGREE_FAIL = 'POST_ADMIN_DEGREE_FAIL';

export const UPDATE_ADMIN_DEGREE_START = 'UPDATE_ADMIN_DEGREE_START';
export const UPDATE_ADMIN_DEGREE_SUCCESS = 'UPDATE_ADMIN_DEGREE_SUCCESS';
export const UPDATE_ADMIN_DEGREE_FAIL = 'UPDATE_ADMIN_DEGREE_FAIL';

export const DELETE_ADMIN_DEGREE_START = 'DELETE_ADMIN_DEGREE_START';
export const DELETE_ADMIN_DEGREE_SUCCESS = 'DELETE_ADMIN_DEGREE_SUCCESS';
export const DELETE_ADMIN_DEGREE_FAIL = 'DELETE_ADMIN_DEGREE_FAIL';

// ADMIN SPECIALIZATION
export const GET_ADMIN_SPECIALIZATION_START = 'GET_ADMIN_SPECIALIZATION_START';
export const GET_ADMIN_SPECIALIZATION_SUCCESS =
  'GET_ADMIN_SPECIALIZATION_SUCCESS';
export const GET_ADMIN_SPECIALIZATION_FAIL = 'GET_ADMIN_SPECIALIZATION_FAIL';

export const POST_ADMIN_SPECIALIZATION_START =
  'POST_ADMIN_SPECIALIZATION_START';
export const POST_ADMIN_SPECIALIZATION_SUCCESS =
  'POST_ADMIN_SPECIALIZATION_SUCCESS';
export const POST_ADMIN_SPECIALIZATION_FAIL = 'POST_ADMIN_SPECIALIZATION_FAIL';

export const UPDATE_ADMIN_SPECIALIZATION_START =
  'UPDATE_ADMIN_SPECIALIZATION_START';
export const UPDATE_ADMIN_SPECIALIZATION_SUCCESS =
  'UPDATE_ADMIN_SPECIALIZATION_SUCCESS';
export const UPDATE_ADMIN_SPECIALIZATION_FAIL =
  'UPDATE_ADMIN_SPECIALIZATION_FAIL';

export const DELETE_ADMIN_SPECIALIZATION_START =
  'DELETE_ADMIN_SPECIALIZATION_START';
export const DELETE_ADMIN_SPECIALIZATION_SUCCESS =
  'DELETE_ADMIN_SPECIALIZATION_SUCCESS';
export const DELETE_ADMIN_SPECIALIZATION_FAIL =
  'DELETE_ADMIN_SPECIALIZATION_FAIL';

// USER PROFILE PICTURE
export const UPLOAD_USER_PROFILE_PICTURE_START =
  'UPLOAD_USER_PROFILE_PICTURE_START';
export const UPLOAD_USER_PROFILE_PICTURE_SUCCESS =
  'UPLOAD_USER_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_USER_PROFILE_PICTURE_FAIL =
  'UPLOAD_USER_PROFILE_PICTURE_FAIL';

// USER PROFILE DATA
export const PUT_USER_PROFILE_DATA_START = 'PUT_USER_PROFILE_DATA_START_START';
export const PUT_USER_PROFILE_DATA_SUCCESS =
  'PUT_USER_PROFILE_DATA_START_SUCCESS';
export const PUT_USER_PROFILE_DATA_FAIL = 'PUT_USER_PROFILE_DATA_START_FAIL';

// EMAIL NOTIFICATIONS
export const GET_EMAIL_NOTIFICATIONS_START = 'GET_EMAIL_NOTIFICATIONS_START';
export const GET_EMAIL_NOTIFICATIONS_SUCCESS =
  'GET_EMAIL_NOTIFICATIONS_SUCCESS';
export const GET_EMAIL_NOTIFICATIONS_FAIL = 'GET_EMAIL_NOTIFICATIONS_FAIL';

export const POST_EMAIL_NOTIFICATIONS_START = 'POST_EMAIL_NOTIFICATIONS_START';
export const POST_EMAIL_NOTIFICATIONS_SUCCESS =
  'POST_EMAIL_NOTIFICATIONS_SUCCESS';
export const POST_EMAIL_NOTIFICATIONS_FAIL = 'POST_EMAIL_NOTIFICATIONS_FAIL';

export const PUT_EMAIL_NOTIFICATIONS_START = 'PUT_EMAIL_NOTIFICATIONS_START';
export const PUT_EMAIL_NOTIFICATIONS_SUCCESS =
  'PUT_EMAIL_NOTIFICATIONS_SUCCESS';
export const PUT_EMAIL_NOTIFICATIONS_FAIL = 'PUT_EMAIL_NOTIFICATIONS_FAIL';

export const DELETE_EMAIL_NOTIFICATIONS_START =
  'DELETE_EMAIL_NOTIFICATIONS_START';
export const DELETE_EMAIL_NOTIFICATIONS_SUCCESS =
  'DELETE_EMAIL_NOTIFICATIONS_SUCCESS';
export const DELETE_EMAIL_NOTIFICATIONS_FAIL =
  'DELETE_EMAIL_NOTIFICATIONS_FAIL';

// Admin Coupons
export const GET_ADMIN_COUPONS_START = 'GET_ADMIN_COUPONS_START';
export const GET_ADMIN_COUPONS_SUCCESS = 'GET_ADMIN_COUPONS_SUCCESS';
export const GET_ADMIN_COUPONS_FAIL = 'GET_ADMIN_COUPONS_FAIL';

export const POST_ADMIN_COUPONS_START = 'POST_ADMIN_COUPONS_START';
export const POST_ADMIN_COUPONS_SUCCESS = 'POST_ADMIN_COUPONS_SUCCESS';
export const POST_ADMIN_COUPONS_FAIL = 'POST_ADMIN_COUPONS_FAIL';

export const PUT_ADMIN_COUPONS_START = 'PUT_ADMIN_COUPONS_START';
export const PUT_ADMIN_COUPONS_SUCCESS = 'PUT_ADMIN_COUPONS_SUCCESS';
export const PUT_ADMIN_COUPONS_FAIL = 'PUT_ADMIN_COUPONS_FAIL';

export const DELETE_ADMIN_COUPONS_START = 'DELETE_ADMIN_COUPONS_START';
export const DELETE_ADMIN_COUPONS_SUCCESS = 'DELETE_ADMIN_COUPONS_SUCCESS';
export const DELETE_ADMIN_COUPONS_FAIL = 'DELETE_ADMIN_COUPONS_FAIL';

// Onboarding Done
export const ONBOARDING_DONE_START = 'ONBOARDING_DONE_START';
export const ONBOARDING_DONE_SUCCESS = 'ONBOARDING_DONE_SUCCESS';
export const ONBOARDING_DONE_FAIL = 'ONBOARDING_DONE_FAIL';

// Managment Pending API
export const GET_MANAGEMENT_API_PENDING_START =
  'GET_MANAGEMENT_API_PENDING_START';
export const GET_MANAGEMENT_API_PENDING_SUCCESS =
  'GET_MANAGEMENT_API_PENDING_SUCCESS';
export const GET_MANAGEMENT_API_PENDING_FAIL =
  'GET_MANAGEMENT_API_PENDING_FAIL';

// Sales API
export const GET_SALES_LEAD_START = 'GET_SALES_LEAD_START';
export const GET_SALES_LEAD_SUCCESS = 'GET_SALES_LEAD_SUCCESS';
export const GET_SALES_LEAD_FAIL = 'GET_SALES_LEAD_FAIL';

export const POST_SALES_LEAD_START = 'POST_SALES_LEAD_START';
export const POST_SALES_LEAD_SUCCESS = 'POST_SALES_LEAD_SUCCESS';
export const POST_SALES_LEAD_FAIL = 'POST_SALES_LEAD_FAIL';

export const PUT_SALES_LEAD_START = 'PUT_SALES_LEAD_START';
export const PUT_SALES_LEAD_SUCCESS = 'PUT_SALES_LEAD_SUCCESS';
export const PUT_SALES_LEAD_FAIL = 'PUT_SALES_LEAD_FAIL';

export const DELETE_SALES_LEAD_START = 'DELETE_SALES_LEAD_START';
export const DELETE_SALES_LEAD_SUCCESS = 'DELETE_SALES_LEAD_SUCCESS';
export const DELETE_SALES_LEAD_FAIL = 'DELETE_SALES_LEAD_FAIL';

export const GET_CALL_ATTEMPT_START = 'GET_CALL_ATTEMPT_START';
export const GET_CALL_ATTEMPT_SUCCESS = 'GET_CALL_ATTEMPT_SUCCESS';
export const GET_CALL_ATTEMPT_FAIL = 'GET_CALL_ATTEMPT_FAIL';

export const POST_CALL_ATTEMPT_START = 'POST_CALL_ATTEMPT_START';
export const POST_CALL_ATTEMPT_SUCCESS = 'POST_CALL_ATTEMPT_SUCCESS';
export const POST_CALL_ATTEMPT_FAIL = 'POST_CALL_ATTEMPT_FAIL';

export const SEND_SIGNUP_MAIL_START = 'SEND_SIGNUP_MAIL_START';
export const SEND_SIGNUP_MAIL_SUCCESS = 'SEND_SIGNUP_MAIL_SUCCESS';
export const SEND_SIGNUP_MAIL_FAIL = 'SEND_SIGNUP_MAIL_FAIL';

export const GET_MAIL_SENT_LOG_START = 'GET_MAIL_SENT_LOG_START';
export const GET_MAIL_SENT_LOG_SUCCESS = 'GET_MAIL_SENT_LOG_SUCCESS';
export const GET_MAIL_SENT_LOG_FAIL = 'GET_MAIL_SENT_LOG_FAIl';

export const GET_ADMIN_SALES_EXECUTIVES_START =
  'GET_ADMIN_SALES_EXECUTIVES_START';
export const GET_ADMIN_SALES_EXECUTIVES_SUCCESS =
  'GET_ADMIN_SALES_EXECUTIVES_SUCCESS';
export const GET_ADMIN_SALES_EXECUTIVES_FAIL =
  'GET_ADMIN_SALES_EXECUTIVES_FAIL';

export const GET_SALES_LEAD_BY_ID_START = 'GET_SALES_LEAD_BY_ID_START';
export const GET_SALES_LEAD_BY_ID_SUCCESS = 'GET_SALES_LEAD_BY_ID_SUCCESS';
export const GET_SALES_LEAD_BY_ID_FAIL = 'GET_SALES_LEAD_BY_ID_FAIL';

export const GET_POSTED_JOB_BY_THE_USER_START =
  'GET_POSTED_JOB_BY_THE_USER_START';
export const GET_POSTED_JOB_BY_THE_USER_SUCCESS =
  'GET_POSTED_JOB_BY_THE_USER_SUCCESS';
export const GET_POSTED_JOB_BY_THE_USER_FAIL =
  'GET_POSTED_JOB_BY_THE_USER_FAIL';

export const UPDATE_CALL_ATTEMPT_START = 'UPDATE_CALL_ATTEMPT_START';
export const UPDATE_CALL_ATTEMPT_SUCCESS = 'UPDATE_CALL_ATTEMPT_SUCCESS';
export const UPDATE_CALL_ATTEMPT_FAIL = 'UPDATE_CALL_ATTEMPT_FAIL';

export const POST_SALES_LEAD_BULK_START = 'POST_SALES_LEAD_BULK_START';
export const POST_SALES_LEAD_BULK_SUCCESS = 'POST_SALES_LEAD_BULK_SUCCESS';
export const POST_SALES_LEAD_BULK_FAIL = 'POST_SALES_LEAD_BULK_FAIL';

// User Management API
export const GET_ADMIN_USERS_LIST_START = 'GET_ADMIN_USERS_LIST_START';
export const GET_ADMIN_USERS_LIST_SUCCESS = 'GET_ADMIN_USERS_LIST_SUCCESS';
export const GET_ADMIN_USERS_LIST_FAIL = 'GET_ADMIN_USERS_LIST_FAIL';

export const GET_USER_TOKEN_START = 'GET_USER_TOKEN_START';
export const GET_USER_TOKEN_SUCCESS = 'GET_USER_TOKEN_SUCCESS';
export const GET_USER_TOKEN_FAIL = 'GET_USER_TOKEN_FAIL';

export const UPDATE_ADMIN_USER_START = 'UPDATE_ADMIN_USER_START';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_ADMIN_USER_FAIL = 'UPDATE_ADMIN_USER_FAIL';

export const RESET_USER_COMPLETELY_START = 'RESET_USER_COMPLETELY_START';
export const RESET_USER_COMPLETELY_SUCCESS = 'RESET_USER_COMPLETELY_SUCCESS';
export const RESET_USER_COMPLETELY_FAIL = 'RESET_USER_COMPLETELY_FAIL';

export const DELETE_USER_COMPLETELY_START = 'DELETE_USER_COMPLETELY_START';
export const DELETE_USER_COMPLETELY_SUCCESS = 'DELETE_USER_COMPLETELY_SUCCESS';
export const DELETE_USER_COMPLETELY_FAIL = 'DELETE_USER_COMPLETELY_FAIL';
