import * as React from 'react';
import ReactSelect from 'react-select';
import { FormControl, FormLabel, Avatar } from '@mui/material';

const SubcategoryOption = ({ subCategoryOptions = [], onChange, value }) => {
  const onSubCategoryChange = (evt) => {
    const subCategoryBody = {
      ...value,
      correct_ids: evt?.map((e) => e.id),
    };
    onChange(subCategoryBody);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit', paddingBottom: '8px' }}
      >
        Select appropriate subcategories
      </FormLabel>
      <ReactSelect
        options={subCategoryOptions}
        value={subCategoryOptions.filter((sub_cat) =>
          value.correct_ids?.includes(sub_cat.id),
        )}
        onChange={onSubCategoryChange}
        getOptionLabel={(option) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'intial',
              height: '24px',
            }}
          >
            <Avatar
              alt={`${option.name}`}
              src={option.s3_url}
              style={{
                height: 24,
                width: 24,
              }}
            />
            &nbsp; &nbsp;
            {option.name}
          </div>
        )}
        getOptionValue={(option) => option.id}
        isClearable
        isMulti
      />
    </FormControl>
  );
};

export default SubcategoryOption;
