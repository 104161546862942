import { Grid, Typography, Box, Chip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import qmcq from '../QuestionList/images/Qtype1.svg';
import qmsq from '../QuestionList/images/MSQ.png';
import qoneword from '../QuestionList/images/Qtype2.png';
import qcomprehensive from '../QuestionList/images/Qtype3.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ProfileIcon from './images/profile.svg';
import ClockIcon from './images/clock.svg';
import moment from 'moment';

const Detail = ({ icon, title, value, clickHandler }) => (
  <Grid>
    <Typography mb="5px" fontSize={'500'}>
      {title}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: clickHandler ? 'pointer' : 'default',
      }}
      onClick={() => clickHandler?.()}
    >
      {icon}
      <Typography color="primary">{value}</Typography>
    </Box>
  </Grid>
);

const QuestionDetails = ({ data }) => {
  const navigate = useNavigate();
  const tags = useSelector((state) => state.tag).allTags || [];
  const tag = tags.find((t) => t.id === data?.tag_id);

  const questionType = (type) => {
    if (type === 'MultipleChoice')
      return {
        title: 'Multiple Choice Question (MCQ)',
        icon: qmcq,
      };
    if (type === 'MultipleCorrect')
      return {
        title: 'Multiple Select Question (MSQ)',
        icon: qmsq,
      };
    if (type === 'OneWord')
      return {
        title: 'One Word',
        icon: qoneword,
      };
    if (type === 'MultiPart')
      return {
        title: 'Comprehensive',
        icon: qcomprehensive,
      };
    return {};
  };

  if (!data.id) return null;
  return (
    <Grid
      sx={{
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        gap: '15px',
        padding: '0px 20px',
        borderLeft: '1px solid #25507B4D',
        height: 'fit-content',
        minWidth: '240px',
      }}
    >
      <Detail
        icon={<img src={ProfileIcon} alt="icon" width="20px" height="20px" />}
        title="Author"
        value={`${data?.creator_details?.first_name} ${data?.creator_details?.last_name}`}
        clickHandler={() =>
          navigate(`/profile/${data?.creator_details.username}`)
        }
      />
      <Detail
        icon={<img src={tag?.s3_url} alt="icon" width="20px" height="20px" />}
        title="Category"
        value={tag?.name}
      />
      <Detail
        title="Subtopic"
        value={
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
            }}
          >
            {data?.sub_tag_array?.map((subTag) => (
              <Chip
                key={subTag}
                label={tag.sub_tags.find((stag) => subTag === stag.id)?.name}
                sx={{
                  color: '#25507B',
                  background: '#3572B01A',
                  height: '25px',
                }}
              />
            ))}
          </Grid>
        }
      />
      <Detail
        icon={
          <img
            src={questionType(data?.question_type).icon}
            alt="icon"
            width="20px"
            height="20px"
          />
        }
        title="Question Type"
        value={questionType(data?.question_type).title}
      />
      <Detail
        icon={<img src={ClockIcon} alt="icon" width="20px" height="20px" />}
        title="Attempt Time"
        value={data?.max_time}
      />
      <Detail
        icon={<img src={ProfileIcon} alt="icon" width="20px" height="20px" />}
        title="Reported By"
        value={`${data?.reporter_details?.first_name} ${data?.reporter_details?.last_name}`}
        clickHandler={() =>
          navigate(`/profile/${data?.reporter_details.username}`)
        }
      />
      <Detail
        icon={
          <CalendarTodayIcon
            color="primary"
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        }
        title="Reported On"
        value={moment(data?.full_report?.created_at).format('DD-MM-YYYY')}
      />
      <p style={{ paddingBottom: '20px' }}></p>
    </Grid>
  );
};

export default QuestionDetails;
