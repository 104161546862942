import React, { useState, useEffect } from 'react';
import ImagePicker from '../ImagePicker';
import { useDispatch, useSelector } from 'react-redux';
import { UploadUserProfileImage } from '../../actions/user';

export default function UserProfileUpload() {
  const [imageFile, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const imageUrl = useSelector((state) => state.user.data?.picture);

  useEffect(() => {
    if (imageFile !== null) {
      let form = new FormData();
      form.append('picture', imageFile);
      dispatch(UploadUserProfileImage(imageFile));
    }
  }, [imageFile]);

  return (
    <div>
      <div className="form-control userdetails__formitem">
        <label htmlFor="">Please Upload Profile Picture</label>
        <div className="userdetails__pictureupload">
          <ImagePicker
            onFileLoad={(file) => setImageFile(file)}
            imageUrl={imageUrl}
          />
        </div>
      </div>
    </div>
  );
}
