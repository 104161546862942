import { styled } from '@mui/styles';
import {
  Avatar,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import LeaderboardLevelType1 from '../../images/LeaderboardLevelType1.svg';
import LeaderboardLevelType2 from '../../images/LeaderboardLevelType2.svg';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const LeaderboardContainer = styled(Container)(({ theme }) => ({
  width: 'calc(100vw - 240px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  color: 'black',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

export const LeaderboardHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
});

export const LeaderboardTypeSelect = styled(Select)({
  backgroundColor: 'white',
  borderRadius: '10px',
});

export const LeaderboardTemporalSelect = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '25px',
  backgroundColor: 'white',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '18px',
  padding: '5px',
});

export const LeaderboardTemporalValue = styled(Box)(({ active }) => {
  let commonStyles = {
    borderRadius: '15px',
    padding: '5px 10px',
    cursor: 'pointer',
    flex: '1 0 auto',
    textAlign: 'center',
  };

  if (active) {
    commonStyles = {
      ...commonStyles,
      ...{
        background: '#25507B',
        color: 'white',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      },
    };
  }

  return commonStyles;
});

export const LeaderboardUsername = styled(TextField)({
  borderRadius: '25px',
  border: '1px solid #25507B',
  background: 'white',
  '& fieldset': { border: 'none' },
});

export const LeaderboardBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '10px 5px',
  borderRadius: '10px',
  flexGrow: 1,
  overflow: 'hidden',
});

export const EmptyLeaderboard = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flexGrow: 1,
  fontSize: '1.2rem',
});
export const EmptyLeaderboardImage = styled('img')({
  width: '50%',
});

export const HeadingWithSorting = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const SortingIcon = styled(Box)({
  marginLeft: '5px',
  display: 'flex',
  flexDirection: 'column',
});

export const LeaderboardChipsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const LeaderboardChipsWrapperForCompetition = styled(
  LeaderboardChipsWrapper,
)({
  '@media screen and (max-width:1000px)': {
    '&>*:nth-child(n)': {
      display: 'none',
    },
    '&>.active-chip': {
      display: 'flex',
    },
    '& > :last-child': {
      display: 'flex',
    },
  },
});

export const LeaderboardChip = styled(Box)(({ active }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 25px',
  gap: '10px',
  border: '1px solid rgba(0, 0, 0, 0.20)',
  backgroundColor: active === 'true' ? '#25507B' : 'transparent',
  color: active === 'true' ? 'white' : 'black',
  boxShadow:
    active === 'true' ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset' : 'none',
  borderRadius: '15px',
  cursor: 'pointer',
  marginRight: '4px',
}));

export const LeaderboardSelect = styled(Select)({
  marginLeft: 'auto',
  wordBreak: 'break-word',
  height: '25px',
});

export const SortingSelect = styled(Select)({
  marginLeft: 'auto',
  wordBreak: 'break-word',
  height: '25px',
});

export const MoreCompetitionsButton = styled(Button)({
  marginLeft: 'auto',
  '@media screen and (max-width:1000px)': {
    padding: '5px 8px',
    minWidth: 0,
    '&>.button-text': {
      display: 'none',
    },
    '&>.MuiButton-startIcon': {
      margin: 0,
    },
  },
});

export const LeaderboardTableWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: 'white',
  flexGrow: 1,
  overflowY: 'scroll',
  padding: '0 8px',
});

export const LeaderboardTableBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const LeaderboardTableRow = styled(Box)(
  ({ sticky, topstick, emptyrewards }) => {
    let commonStyles = {
      width: '100%',
      display: 'flex',
      gap: '10px',
      minWidth: '1000px',
      '@media screen and (max-width:800px)': {
        minWidth: '700px',
      },
    };

    if (emptyrewards === 'true') {
      commonStyles = {
        ...commonStyles,
        '@media screen and (max-width:800px)': {
          minWidth: '500px',
        },
      };
    }

    if (sticky === 'true') {
      commonStyles = {
        ...commonStyles,
        position: 'sticky',
        bottom: '0px',
        zIndex: 2,
        backgroundColor: 'white',
        borderTop: '1px solid rgba(53, 114, 176, 0.20)',
        paddingTop: '10px',
      };
    }
    if (topstick === 'true') {
      commonStyles = {
        ...commonStyles,
        top: topstick === 'true' ? '0px' : 'none',
        borderTop: 'none',
      };
    }
    return commonStyles;
  },
);

export const LeaderboardTableCell = styled(Box)(({ size, customAlign }) => ({
  flex: `${size} 0 0`,
  display: 'flex',
  padding: '8px 2px',
  gap: '5px',
  justifyContent: customAlign || 'center',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const RankCard = styled(LeaderboardTableCell)(
  ({ rank, header, sticky, currentuser, emptyrewards }) => {
    let commonStyles = {
      borderRadius: '5px',
      alignItems: 'center',
      padding: '5px 5px',
      '& > :first-child': {
        justifyContent: 'flex-start',
      },
      textOverflow: 'ellipsis',
      '@media screen and (max-width:800px)': {
        '& > :nth-child(2)': {
          display: 'none',
        },
      },
    };
    if (emptyrewards === 'true') {
      commonStyles = {
        ...commonStyles,
        '& > :nth-child(5)': {
          display: 'none',
        },
      };
    }
    if (rank === 1 || rank === 2 || rank === 3) {
      commonStyles = {
        ...commonStyles,
        borderRadius: ' 6px',
        background:
          currentuser === 'true'
            ? 'linear-gradient(38deg, rgba(93,93,68,1) 0%, rgba(12,12,39,1) 6%, rgba(12,12,39,1) 89%, rgba(93,93,68,1) 100%)'
            : 'linear-gradient(264deg, rgba(63,2,2,1) 13%, rgba(98,15,10,1) 35%, rgba(111,13,4,1) 58%, rgba(111,18,9,1) 67%, rgba(68,5,10,1) 91%)',
        color: 'white',
        fontWeight: 600,
      };
    }
    if (header === 'false') {
      commonStyles = {
        ...commonStyles,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.40)',
      };
    }
    if (sticky === 'true') {
      commonStyles = {
        ...commonStyles,
        backgroundColor: 'rgba(53, 114, 176, 0.10)',
      };
    } else if (currentuser === 'true') {
      commonStyles = {
        ...commonStyles,
        backgroundColor: 'rgba(53, 114, 176, 0.10)',
      };
    }
    return commonStyles;
  },
);

export const RankDisplay = styled(Box)({
  width: '35px',
  height: '35px',
  borderRadius: '50%',
  backgroundColor: 'rgba(53, 114, 176, 0.10)',
  color: '#3572B0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LeaderboardUserDetails = styled(NavLink)(({ rank }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  fontWeight: rank === 'true' ? '600' : '400',
  color: rank === 'true' ? 'white' : '#25507B',
}));

export const LeaderboardDisplayUsername = styled(Typography)({
  maxWidth: '150px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const LeaderboardAvatar = styled(Avatar)({
  width: '30px',
  height: '30px',
});

export const LeaderboardCategoryLevel = styled('div')(({ type }) => ({
  backgroundImage: `url(${
    type === 'one' ? LeaderboardLevelType1 : LeaderboardLevelType2
  })`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  padding: '10px 10px',
  color: type === 'one' ? 'black' : 'white',
}));

export const JumpToRankButton = styled(Button)({
  fontSize: '0.7rem',
  borderRadius: '100px',
  backgroundColor: '#3572B0',
});

export const FollowButton = styled(Button)(({ type, following }) => {
  let customStyles = {};
  if (type === 'one') {
    if (following === 'true') {
      customStyles = {
        border: '1px solid white',
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(161,154,154,0.46)',
        },
      };
    } else {
      customStyles = {
        backgroundColor: 'white',
        color: '#C1272D',
        '&:hover': {
          backgroundColor: 'rgba(161,154,154,0.46)',
        },
      };
    }
  }
  return {
    fontSize: '0.7rem',
    borderRadius: '100px',
    ...customStyles,
  };
});

export const LeaderboardRewardsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: 'white',
});

export const LeaderboardRewardsValue = styled('span')({
  backgroundColor: '#25507B',
  borderRadius: '10px',
  padding: '3px 10px',
});

export const LeaderboardDialog = styled(Dialog)({
  color: 'black',
});

export const LeaderboardDialogTitle = styled(DialogTitle)({
  display: 'flex',
  gap: '10px',
  alignItems: 'flex-start',
  color: 'black',
});

export const LeaderboardDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  color: 'black',
  overflow: 'hidden',
});

export const LeaderboardFormLabel = styled(FormLabel)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // height: '100px'
});

export const LeaderboardDatePicker = styled(DatePicker)({
  border: 'none',
  width: '100%',
});

export const LeaderboardDialogCompetitionList = styled(Box)({
  overflow: 'auto',
  height: '85vh',
});

export const LeaderboardDialogActions = styled(DialogActions)({
  display: 'flex',
  gap: '8px',
  '&>*': {
    flexGrow: 1,
  },
});

export const LeaderboardPaginationWrapper = styled(Box)({
  color: 'black',
  fontWeight: 400,
  fontSize: '0.875rem',
  marginTop: 'auto',
  alignSelf: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '5px',
  '@media screen and (max-width:800px)': {
    wordSpacing: '-2px',
  },
});

export const LeaderboardPaginationRowView = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
