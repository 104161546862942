import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { nanoid } from 'nanoid';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CreditsIcon from '../Sidebar/img/credits-icon.svg';

import {
  Hidden,
  HeaderText,
  SmallText,
  TableHeaderText,
  TableRowItem,
  TableBodyWrapper,
  TableRowItemText,
  EarnedCreditsTableRowItem,
  ReferralTableWrapper,
  TableHeader,
  TableRow,
  ResponsiveTypographyGrid,
  Chips,
  Tablepagination,
} from './UserProfileStyledComponent';

export default function ReferralCardTable({
  referralList,
  currentPage,
  rowsPerPage,
  page,
  count,
  onPageChange,
  onRowsPerPageChange,
}) {
  const TruncatedText = ({ text, maxLength, credit }) => {
    const truncatedText =
      text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

    return (
      <Grid container>
        <Grid item>
          <SmallText>{truncatedText}</SmallText>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '2px',
            paddingLeft: '2px',
          }}
        >
          <img src={CreditsIcon} height="14px" width="12px" alt="" />
          &nbsp; <SmallText>{credit ? credit : 0}</SmallText>
        </Grid>
      </Grid>
    );
  };

  return (
    <ReferralTableWrapper>
      <TableHeader container>
        <Grid item md={1} sm={2} xs={2} textAlign={'center'}>
          <TableHeaderText>
            <HeaderText>S.No.</HeaderText>
          </TableHeaderText>
        </Grid>
        <Grid item md={3} sm={4} xs={5}>
          <TableHeaderText>Email Address</TableHeaderText>
        </Grid>
        <Grid item md={3}>
          <Hidden>
            <TableHeaderText paddingLeft={1}>Name</TableHeaderText>
          </Hidden>
        </Grid>
        <Grid item md={2} sm={3} xs={3}>
          <TableHeaderText>Referral Status</TableHeaderText>
        </Grid>
        <Grid item md={2} sm={3} xs={2}>
          <TableHeaderText>Profile Status</TableHeaderText>
        </Grid>
        <Grid item md={1}>
          <TableHeaderText>
            <Hidden>Earned Credits</Hidden>
          </TableHeaderText>
        </Grid>
      </TableHeader>
      <TableBodyWrapper container>
        {referralList.map((row, index) => (
          <TableRow container key={nanoid()}>
            <TableRowItem item md={1} sm={2} xs={2}>
              <SmallText>
                <TableRowItemText
                  style={{
                    fontWeight: '900',
                  }}
                  textAlign={'center'}
                >
                  {index + 1 + (currentPage - 1) * rowsPerPage}
                </TableRowItemText>
              </SmallText>
            </TableRowItem>
            <EarnedCreditsTableRowItem item md={3}>
              <TableRowItemText>{row.email}</TableRowItemText>
            </EarnedCreditsTableRowItem>

            <EarnedCreditsTableRowItem item md={3}>
              <TableRowItemText>
                {row.first_name && <>{`${row.first_name} ${row.last_name}`}</>}
              </TableRowItemText>
            </EarnedCreditsTableRowItem>

            <ResponsiveTypographyGrid item md={3} sm={4} xs={5}>
              <Box>
                <TableRowItemText noWrap>
                  <SmallText>{row.email}</SmallText>
                </TableRowItemText>
              </Box>
              <Box>
                {row.first_name && (
                  <TruncatedText
                    text={`${row.first_name}  ${row.last_name}`}
                    maxLength={50}
                    credit={row.credit}
                  />
                )}
              </Box>
            </ResponsiveTypographyGrid>

            <TableRowItem
              item
              md={2}
              sm={3}
              xs={3}
              style={{ color: '#D60505', fontWeight: 600 }}
            >
              {row.is_registration_complete ? (
                <Chips
                  style={{ color: '#25507B', border: '1px solid ##25507B' }}
                  size="small"
                  label="Registered"
                  variant="outlined"
                />
              ) : (
                <Tooltip
                  title="The user needs to activate the account by clicking on the activation link received on email."
                  arrow
                >
                  <Chips
                    style={{
                      color: '#EE9D00',
                      border: '1px solid #EE9D00',
                    }}
                    size="small"
                    variant="outlined"
                    label="Pending"
                  />
                </Tooltip>
              )}
            </TableRowItem>
            <TableRowItem
              item
              md={2}
              sm={3}
              xs={2}
              style={{ color: '#3572B0', fontWeight: 600 }}
            >
              {row.is_active ? (
                <Chips
                  color="success"
                  size="small"
                  variant="outlined"
                  label="Active"
                />
              ) : (
                <Tooltip
                  title="The user is marked active when the overall question attempts are > 5 (updates hourly)."
                  arrow
                >
                  <Chips
                    style={{
                      color: '#76767F',
                      border: '1px solid #76767F',
                    }}
                    size="small"
                    label="Inactive"
                    variant="outlined"
                  />
                </Tooltip>
              )}
            </TableRowItem>
            <EarnedCreditsTableRowItem item md={1}>
              <img
                src={CreditsIcon}
                style={{ marginRight: '10px' }}
                height="17px"
                width="17px"
                alt=""
              />
              &nbsp;
              <TableRowItemText style={{ textAlign: 'left' }}>
                {row.credits ? row.credits : 0}
              </TableRowItemText>
            </EarnedCreditsTableRowItem>
          </TableRow>
        ))}
      </TableBodyWrapper>
      <Tablepagination
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </ReferralTableWrapper>
  );
}
