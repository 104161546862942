import React, { useEffect } from 'react';
import Activity from './Activity';
import Practice from './Practice';
import Piecharts from './Piechart';
import {
  MainContainer,
  StyledParentGrid,
  StyledParentGridTwo,
  StyledParentGridThree,
  DashboardWrapper,
} from './DashboardStyledComponent';
import MockTest from './MockTest';
import LeaderBoard from './LeaderBoard';
import MockTestInfo from './MockTestInfo';
import Tips from './Tips';
import Competetion from './Competetion';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../../actions/dashboard';
import { getLeaderboard } from '../../actions/leaderboard';
import TopContainer from './TopContainer';
import {
  getUserLoginStreak,
  getUserProfileMilestone,
} from '../../actions/user';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { allTags } = useSelector((state) => state.tag);
  const { dashboardData } = useSelector((state) => state.dashboard);
  const { data, userLoginStreakData } = useSelector((state) => state.user);
  const { leaderboardData } = useSelector((state) => state.leaderboard);
  const selectedDomain = useSelector((state) => state.user?.selected_domain);
  const { userProfileMilestone } = useSelector((state) => state?.user);

  useEffect(() => {
    dispatch(getDashboard());
    dispatch(getUserLoginStreak());
    dispatch(getUserProfileMilestone());
    dispatch(
      getLeaderboard({
        pageNumber: 1,
        pageSize: 5,
        domainId: selectedDomain,
      }),
    );
  }, []);

  return (
    <DashboardWrapper>
      {userProfileMilestone && userProfileMilestone?.percent_completed < 100 && (
        <div
          style={{
            margin: '0 5px 10px 5px',
            padding: '0 5px',
            width: '100%',
          }}
        >
          <TopContainer userProfileMilestone={userProfileMilestone || {}} />
        </div>
      )}

      <div
        style={{
          width: '100%',
          padding: '0 4px',
        }}
      >
        <MainContainer>
          <StyledParentGrid>
            <div>
              <Activity
                overallYearStats={dashboardData?.overall_year_stats || {}}
                userStats={dashboardData?.user_stats || {}}
                user={data || {}}
              />
              <Practice practiceData={dashboardData?.recommended_tags || []} />
            </div>
            <StyledParentGridThree>
              <Tips
                userBadges={dashboardData?.user_badges?.badges || []}
                badgeCount={dashboardData?.user_badges?.total_badge_count}
              />
              <Piecharts pieChartData={dashboardData?.user_stats || {}} />
            </StyledParentGridThree>
          </StyledParentGrid>

          <StyledParentGrid>
            <div>
              <StyledParentGridTwo>
                <div>
                  <MockTest
                    mocktestData={dashboardData?.recommended_mock_tests || []}
                    allTags={allTags || []}
                  />
                </div>
                <div>
                  <MockTestInfo userLoginStreakData={userLoginStreakData} />
                </div>
              </StyledParentGridTwo>
            </div>

            <div>
              <LeaderBoard leaderboardData={leaderboardData || []} />
            </div>
          </StyledParentGrid>

          {dashboardData?.live_competition ? (
            <Competetion competetionData={dashboardData?.live_competition} />
          ) : null}
        </MainContainer>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
