import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Card from './../Card';
import Loader from '../Loader';
import SearchBox from '../SearchBox';
import './index.css';
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { withRouter } from '../../routes';

const getDomainById = (list = [], id) => {
  return list.find((item) => item.id === id);
};

const TagsList = (props) => {
  const [tagsList, setTagsList] = useState(
    Object.entries(props.tag.category_by_domain)?.find(
      (arr) => arr[0] === props.location.search?.substring(1),
    )[1],
  );
  const [searchInput, setSearchInput] = useState('');
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [categoriesToAdd, setCategoriesToAdd] = useState([]);
  const [showRemoveLoader, setShowRemoveLoader] = useState(false);
  const [removeTagId, setRemoveTagId] = useState('');
  const [showAddTagsLoader, setShowAddTagsLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!props.tag.isFetchingTags)
      setTagsList(
        Object.entries(props.tag.category_by_domain)?.find(
          (arr) => arr[0] === props.location.search?.substring(1),
        )[1],
      );
    setCategoriesToAdd([]);
    setAddCategoryModalOpen(false);
    setShowAddTagsLoader(false);
    setShowRemoveLoader(false);
    setRemoveTagId('');
  }, [props.tag.isFetchingTags]);

  useEffect(() => {
    const { getTag } = props;
    getTag();
  }, []);
  useEffect(() => {
    filterList();
  }, [searchInput]);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
  };

  const handleRemoveTagFromDomain = (tagId) => {
    setShowRemoveLoader(true);
    setRemoveTagId(true);
    const domainId = props.location.search?.substring(1);
    props.removeTagFromDomain({ tagId, domainId }, props.getTag);
  };

  const handleAddTagsToDomain = () => {
    setShowAddTagsLoader(true);
    const domainId = props.location.search?.substring(1);
    const tagIds = categoriesToAdd.map((cat) => cat.id);
    props.addTagsToDomian(domainId, { tagIds }, props.getTag);
  };

  const handleAddCategoryModalOpen = () => {
    setAddCategoryModalOpen(true);
  };

  const handleAddCategoryModalClose = () => {
    setAddCategoryModalOpen(false);
  };

  const renderLoader = () => {
    return (
      <div className="no-content">
        <Loader size={44} color="primary" />
      </div>
    );
  };

  const filterList = () => {
    const filteredList = Object.entries(props.tag.category_by_domain)
      ?.find((arr) => arr[0] === props.location.search?.substring(1))[1]
      ?.filter(
        (tag) =>
          tag.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          tag.description?.toLowerCase().includes(searchInput.toLowerCase()),
      );
    setTagsList(filteredList);
  };

  const renderTagsList = () => {
    return (
      <div className="tagListContainer">
        <SearchBox value={searchInput} handleSearch={handleSearch} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Sub-Category</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tagsList?.length > 0 ? (
              tagsList.map((tag) => (
                <TableRow key={tag.id}>
                  <TableCell
                    onClick={() => {
                      navigate({
                        pathname: `/l/subTags/${tag.id}`,
                        search: `${tag.id}`,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="tag__list__name">
                      <Avatar
                        alt={tag.name}
                        src={tag.s3_url}
                        className="tag__list__avatar"
                      />
                      {`${tag.name}`}
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate({
                        pathname: `/l/subTags/${tag.id}`,
                        search: `${tag.id}`,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {tag.description}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      navigate({
                        pathname: `/l/subTags/${tag.id}`,
                        search: `${tag.id}`,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="tag__sub-tags">
                      {tag.sub_tags && tag.sub_tags.length
                        ? tag.sub_tags.map((item, index) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              color="success"
                              size="small"
                              label={item.name}
                              className="subtag"
                            />
                          ))
                        : '-'}
                    </div>
                  </TableCell>
                  <TableCell align="right" style={{ minWidth: '150px' }}>
                    <EditIcon
                      onClick={() => {
                        navigate({
                          pathname: `/l/editTag/${tag.id}`,
                          search: `${tag.id}`,
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                  <TableCell align="right" style={{ minWidth: '70px' }}>
                    {removeTagId === tag.id && showRemoveLoader ? (
                      <CircularProgress
                        style={{ width: '30px', height: '30px' }}
                      />
                    ) : (
                      <DeleteIcon
                        onClick={() => handleRemoveTagFromDomain(tag.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <p className="no-tag">No Such tag found </p>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const params = useParams();
  const cardHeader = (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <div className="tags-list__header">Categories</div>
      <div className="create-tag-action">
        <NavLink to="/l/createTag" state={{ domainId: params.domainId }}>
          <Button color="primary" variant="outlined">
            Create Category
          </Button>
        </NavLink>
        <NavLink to="/l/createSubtag">
          <Button color="primary" variant="outlined">
            Add Subcategory
          </Button>
        </NavLink>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleAddCategoryModalOpen}
        >
          Add Category
        </Button>
      </div>
    </div>
  );

  const cardContent =
    props.tag.list.length > 0 ? renderTagsList() : renderLoader();

  const domainId = props.location.search?.substring(1);
  const domainName = getDomainById(props.domain.list, domainId)?.name;
  return (
    <Container maxWidth={false}>
      <Dialog open={addCategoryModalOpen} onClose={handleAddCategoryModalClose}>
        <Grid
          style={{
            width: '500px',
            padding: '20px',
          }}
        >
          <Typography
            color="primary"
            variant="h5"
            style={{
              textAlign: 'center',
              fontWeight: '600',
              marginBottom: '15px',
            }}
          >
            Add categories to {domainName}
          </Typography>
          <Grid>
            <Box style={{ marginBottom: '20px' }}>
              <AsyncSelect
                noOptionsMessage={() => 'Search categories to show options...'}
                placeholder="Search categories"
                loadOptions={(input, callback) => {
                  props.getTagAutocomplete(input, callback);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(opt) =>
                  setCategoriesToAdd([...categoriesToAdd, opt])
                }
                value={null}
              />
            </Box>
            <Divider />
            <Grid
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'column',
                margin: '15px 0px',
              }}
            >
              <Typography
                color="primary"
                variant="h6"
                style={{
                  fontWeight: '600',
                }}
              >
                Categories
              </Typography>
              {!categoriesToAdd.length && (
                <Typography color="primary">
                  There are no categories to add. Please select a category!
                </Typography>
              )}
              {categoriesToAdd.map((category) => (
                <Box
                  key={category.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#95b8e37d',
                    paddingLeft: '15px',
                  }}
                >
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ fontSize: '18px' }}
                  >
                    {category.name}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setCategoriesToAdd(
                        categoriesToAdd.filter((cat) => cat.id !== category.id),
                      );
                    }}
                    color="primary"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Grid>
            {props.domain.addTagsToDomainFail && (
              <FormHelperText error>
                There is some error in adding the categories. Please try again
              </FormHelperText>
            )}
            <Button
              variant="contained"
              onClick={handleAddTagsToDomain}
              color="primary"
              fullWidth
            >
              {showAddTagsLoader ? <CircularProgress color="white" /> : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <div className="tags-list">
        {cardHeader}
        <Card content={cardContent} />
      </div>
    </Container>
  );
};

export default withRouter(TagsList);
