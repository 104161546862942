import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { ImageDialog } from './ImageDialog';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { orgBulkStatusUpdate } from '../../actions/adminJobs';
import { useDispatch } from 'react-redux';

export const FullScreenDialog = ({ OrgData }) => {
  const [open, setOpen] = React.useState(false);
  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [selectedStatusToChange, setSelectedStatusToChange] = React.useState(
    '',
  );
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const userDetails = OrgData.owner_details[0];
  const handleStatusChange = () => {
    const data = {
      status: selectedStatusToChange,
      org_ids: [OrgData.id],
    };
    dispatch(orgBulkStatusUpdate(data));
  };
  return (
    <div>
      <Button onClick={handleClickOpen}>
        <RemoveRedEyeOutlined />
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogContent>
          <Avatar src={OrgData.logo_url} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
              <Box width={'10rem'}>
                <Typography variant="h6">Name</Typography>
              </Box>
              <Typography variant="body1">{OrgData.team_name}</Typography>
            </Box>
            <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
              <Box width={'10rem'}>
                <Typography variant="h6">Description</Typography>
              </Box>
              <Typography variant="body1">{OrgData.description}</Typography>
            </Box>
            <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
              <Box width={'10rem'}>
                <Typography variant="h6">Address</Typography>
              </Box>
              <Typography variant="body1">{OrgData.address}</Typography>
            </Box>
            <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
              <Box width={'10rem'}>
                <Typography variant="h6">Status</Typography>
              </Box>
              {isEditingStatus ? (
                <>
                  <Box width={'10rem'}>
                    <Select
                      options={[
                        { id: 'Active', name: 'Active' },
                        { id: 'UnderReview', name: 'UnderReview' },
                        { id: 'Blocked', name: 'Blocked' },
                      ]}
                      onChange={(option) =>
                        setSelectedStatusToChange(option.id)
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder={
                        selectedStatusToChange
                          ? selectedStatusToChange
                          : 'Status'
                      }
                      value={selectedStatusToChange}
                      menuPlacement="auto"
                    />
                  </Box>
                  <IconButton onClick={handleStatusChange}>
                    <DoneIcon />
                  </IconButton>
                  <IconButton onClick={() => setIsEditingStatus(false)}>
                    <ClearIcon />
                  </IconButton>
                </>
              ) : (
                <Chip
                  onClick={() => setIsEditingStatus(true)}
                  variant="outlined"
                  color={
                    OrgData.status === 'Active'
                      ? 'success'
                      : OrgData.status === 'Pending'
                      ? 'primary'
                      : 'primary'
                  }
                  size="small"
                  label={OrgData.status ? OrgData.status : 'Pending'}
                  className="subtag"
                  sx={{
                    color: OrgData.status === 'Blocked' && 'red',
                    border: OrgData.status === 'Blocked' && '1px solid red',
                  }}
                />
              )}
            </Box>
            <Box display={'flex'} sx={{ gap: '1rem', alignItems: 'center' }}>
              {OrgData.linkedin_url && (
                <a
                  href={`https://${OrgData.linkedin_url}/`}
                  target="_blank"
                  className="subtag"
                  rel="noreferrer"
                >
                  {' '}
                  {OrgData.linkedin_url}
                </a>
              )}
              {OrgData.website_url && (
                <a
                  href={`https://${OrgData.website_url}/`}
                  target="_blank"
                  className="subtag"
                  rel="noreferrer"
                >
                  {' '}
                  {OrgData.website_url}
                </a>
              )}
            </Box>

            <Divider />
            <Typography variant="h4">User Details</Typography>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Box width="10rem">
                <Typography variant="h6">Name</Typography>
              </Box>
              <Typography variant="body1">
                {userDetails?.first_name} {userDetails?.last_name},{' '}
                {userDetails?.designation} ({userDetails?.role})
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Box width="10rem">
                <Typography variant="h6">Contact</Typography>
              </Box>
              <Typography variant="body1">
                {userDetails?.email}, &nbsp;&nbsp; {userDetails?.phone_number}
              </Typography>
            </Box>
            <Divider />
            {OrgData?.verification_doc_url ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.5rem',
                }}
              >
                <Box className="media-container">
                  <ImageDialog link={OrgData?.verification_doc_url} />
                </Box>
              </Box>
            ) : (
              'No Document Available'
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
