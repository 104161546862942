import React from 'react';
import './style.css';

function ContainerHeader() {
  return (
    <div>
      <section className="voting-header">
        <div>
          <p
            className="review-report-heading"
            style={{ color: 'rgba(118, 118, 127, 0.86)', display: 'flex' }}
          >
            <span style={{ fontSize: '50px', color: '#6CE06A' }}>&#8226;</span>{' '}
            Suggested Change
          </p>
        </div>
        <div>
          <p
            className="review-report-heading"
            style={{ color: 'rgba(118, 118, 127, 0.86)', display: 'flex' }}
          >
            <span style={{ fontSize: '50px', color: '#E06A6A' }}>&#8226;</span>{' '}
            Original version
          </p>
        </div>
      </section>
    </div>
  );
}

export default ContainerHeader;
