import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import PractiseTagBox from './PractiseTagBox';

import {
  TagBoxContainerSmall,
  TagBoxContainer,
  AnalyticsBox,
  AnalyticsName,
  AnalyticsValue,
  AnalyticsContainer,
} from './UserProfileStyledComponent';

const PractiseTab = ({ analytics, activeDomain, isSelf }) => {
  const [currentIndex, setCurrentIndex] = React.useState(1);

  const timeoutPercent =
    (analytics?.overall?.total_timeout_count * 100) /
    analytics?.overall?.total_question_count;
  const skippedPercent =
    (analytics?.overall?.total_skipped_count * 100) /
    analytics?.overall?.total_question_count;
  return (
    <Grid>
      <AnalyticsContainer>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {analytics?.overall?.total_question_count || 0}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Total Questions</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {`${analytics?.overall?.percent_accuracy || 0}%`}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Accuracy</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {`${Math.round((timeoutPercent + Number.EPSILON) * 100) / 100 ||
              0}%`}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Timeout Percent</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {`${Math.round((skippedPercent + Number.EPSILON) * 100) / 100 ||
              0}%`}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Skipped Percent</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {analytics?.overall?.badge_count}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Total Badges</AnalyticsName>
        </AnalyticsBox>
      </AnalyticsContainer>
      {!analytics?.tags?.filter((tag) => tag.domain_id === activeDomain?.value)
        ?.length && (
        <Typography color="primary" className="no-activity-text">
          No activity in this section!
        </Typography>
      )}
      <TagBoxContainer>
        {analytics?.tags
          ?.filter((tag) => tag.domain_id === activeDomain?.value)
          .map((tag) => {
            return (
              <PractiseTagBox key={tag.tag_id} data={tag} isSelf={isSelf} />
            );
          })}
      </TagBoxContainer>
      <TagBoxContainerSmall>
        <SwipeableViews onChangeIndex={(index) => setCurrentIndex(index + 1)}>
          {analytics?.tags
            ?.filter((tag) => tag.domain_id === activeDomain?.value)
            .map((tag) => (
              <Box style={{ margin: '0px 5px' }} key={tag.tag_id}>
                <PractiseTagBox data={tag} isSelf={isSelf} />
              </Box>
            ))}
        </SwipeableViews>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            justifyContent: 'center',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            {currentIndex}
          </Typography>
          <Typography color="primary" fontSize="14px">
            /
            {
              analytics?.tags?.filter(
                (tag) => tag.domain_id === activeDomain?.value,
              )?.length
            }
          </Typography>
        </Box>
      </TagBoxContainerSmall>
    </Grid>
  );
};

export default PractiseTab;
