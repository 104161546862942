import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import Select from 'react-select';
import {
  AppWrapper,
  NoContent,
  StyledLoader,
  TagListName,
  NoTag,
  TagListHeader,
  TagButton,
} from '../Domains/StyledComponents';
import Card from '../Card';
import SearchBox from '../SearchBox';
import {
  deleteAdminSpecialization,
  getAdminSpecialization,
} from '../../actions/adminDegreeCreate';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, Delete } from '@mui/icons-material';
import { Grid, IconButton, TablePagination } from '@mui/material';

const Specialization = () => {
  const [searchInput, setSearchInput] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { specializationData, isFetchingAdminSpecialization } = useSelector(
    (state) => state.adminDegreeCreate,
  );
  const selectedAdminDegree = localStorage.getItem('selectedAdminDegree');

  useEffect(() => {
    dispatch(
      getAdminSpecialization(
        searchInput,
        status,
        page + 1,
        rowsPerPage,
        params?.degreeId,
      ),
    );
  }, [searchInput, status, page, rowsPerPage, params.degreeId]);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
  };
  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const renderLoader = () => {
    return (
      <NoContent>
        <StyledLoader size={44} />
      </NoContent>
    );
  };

  const renderDegreeList = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <SearchBox value={searchInput} handleSearch={handleSearch} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Select
              options={[
                { id: undefined, name: 'All' },
                { id: 'Pending', name: 'Pending' },
                { id: 'Accepted', name: 'Accepted' },
                { id: 'Rejected', name: 'Rejected' },
              ]}
              onChange={(option) => setStatus(option.id)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={status ? status : 'Approval Status'}
              value={status}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Candidate Count</TableCell>
              <TableCell>Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          {isFetchingAdminSpecialization ? (
            renderLoader()
          ) : (
            <TableBody>
              {specializationData?.all_specializations?.filter(
                (items) => items?.degree_id === params?.degreeId,
              )?.length > 0 ? (
                specializationData?.all_specializations
                  ?.filter((items) => items?.degree_id === params?.degreeId)
                  .map((specialization, index) => (
                    <TableRow key={specialization.specialization_id}>
                      <TableCell>
                        {specializationData.page_size *
                          (specializationData.current_page - 1) +
                          index +
                          1}
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <TagListName>{`${specialization.name}`}</TagListName>
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        {specialization.candidate_count}
                      </TableCell>
                      <TableCell>{specialization.approval_status}</TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: { xs: '50px', sm: '150px' } }}
                      >
                        <EditIcon
                          onClick={() => {
                            navigate({
                              pathname: `/l/admin/create/specialization/${params.degreeId}/${specialization.specialization_id}`,
                              search: `${specialization.specialization_id}`,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: { xs: '50px', sm: '150px' } }}
                      >
                        <Delete
                          onClick={() =>
                            dispatch(
                              deleteAdminSpecialization(
                                specialization.specialization_id,
                                params.degreeId,
                              ),
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <NoTag>No Such Specializations Found </NoTag>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={
            specializationData?.page_size * specializationData?.total_pages
          }
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      </div>
    );
  };

  const cardHeader = (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
        <br />
        <TagListHeader>Specializations</TagListHeader>
        <div>
          <NavLink to={`/l/admin/create/specialization/${params.degreeId}`}>
            <TagButton variant="outlined">Add Specialization</TagButton>
          </NavLink>
        </div>
      </div>
      <TagListHeader>Degree: {selectedAdminDegree} </TagListHeader>
    </>
  );

  return (
    <Container maxWidth={false}>
      <AppWrapper>
        {cardHeader}
        <Card content={renderDegreeList()} />
      </AppWrapper>
    </Container>
  );
};

export default Specialization;
