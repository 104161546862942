import * as types from './types';
import apiClient from '../api/apiclient.ts';

function getAdminCouponsStart() {
  return {
    type: types.GET_ADMIN_COUPONS_START,
  };
}

function getAdminCouponsSuccess({ data }) {
  return {
    type: types.GET_ADMIN_COUPONS_SUCCESS,
    data: data,
  };
}

function getAdminCouponsFail() {
  return {
    type: types.GET_ADMIN_COUPONS_FAIL,
  };
}

export function getAdminCoupons() {
  return (dispatch) => {
    dispatch(getAdminCouponsStart());

    apiClient
      .getCouponsAPI()
      .couponApiGetCouponList()
      .then((resp) => {
        dispatch(getAdminCouponsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAdminCouponsFail());
      });
  };
}
function postAdminCouponsStart() {
  return {
    type: types.POST_ADMIN_COUPONS_START,
  };
}

function postAdminCouponsSuccess({ data }) {
  return {
    type: types.POST_ADMIN_COUPONS_SUCCESS,
    data: data,
  };
}

function postAdminCouponsFail() {
  return {
    type: types.POST_ADMIN_COUPONS_FAIL,
  };
}

export function postAdminCoupons(body) {
  return (dispatch) => {
    dispatch(postAdminCouponsStart());

    apiClient
      .getCouponsAPI()
      .couponApiCreateCoupon(body)
      .then((resp) => {
        dispatch(postAdminCouponsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(postAdminCouponsFail());
      });
  };
}
function putAdminCouponsStart() {
  return {
    type: types.PUT_ADMIN_COUPONS_START,
  };
}

function putAdminCouponsSuccess({ data }) {
  return {
    type: types.PUT_ADMIN_COUPONS_SUCCESS,
    data: data,
  };
}

function putAdminCouponsFail() {
  return {
    type: types.PUT_ADMIN_COUPONS_FAIL,
  };
}

export function putAdminCoupons(id, body) {
  return (dispatch) => {
    dispatch(putAdminCouponsStart());

    apiClient
      .getCouponsAPI()
      .couponApiUpdateCouponById(id, body)
      .then((resp) => {
        dispatch(putAdminCouponsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putAdminCouponsFail());
      });
  };
}
function deleteAdminCouponsStart() {
  return {
    type: types.DELETE_ADMIN_COUPONS_START,
  };
}

function deleteAdminCouponsSuccess({ data }) {
  return {
    type: types.DELETE_ADMIN_COUPONS_SUCCESS,
    data: data,
  };
}

function deleteAdminCouponsFail() {
  return {
    type: types.DELETE_ADMIN_COUPONS_FAIL,
  };
}

export function deleteAdminCoupons(id) {
  return (dispatch) => {
    dispatch(deleteAdminCouponsStart());

    apiClient
      .getCouponsAPI()
      .couponApiDeleteCouponById(id)
      .then((resp) => {
        dispatch(deleteAdminCouponsSuccess({ data: resp.data }));
        dispatch(getAdminCoupons());
      })
      .catch(() => {
        dispatch(deleteAdminCouponsFail());
      });
  };
}
