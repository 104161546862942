import {
  Button,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { useEffect } from 'react';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FieldItem,
  FormWrapper,
  TagsForm,
  FormControl,
} from '../Domains/StyledComponents';
import {
  getAdminDegree,
  postAdminDegree,
  updateAdminDegree,
} from '../../actions/adminDegreeCreate';

const CreateDegree = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isCreatingAdminDegree,
    degreeData,
    isUpdatingAdminDegree,
  } = useSelector((state) => state.adminDegreeCreate);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const selectedDegree = degreeData?.all_degrees?.find(
    (degree) => degree.degree_id === params.degreeId,
  );
  useEffect(() => {
    dispatch(getAdminDegree());
  }, []);

  useEffect(() => {
    if (params.degreeId) {
      reset({
        name: selectedDegree.name,
        approval_status: selectedDegree.approval_status,
      });
    }
  }, [params.degreeId]);

  const onSubmitHandler = (data) => {
    const nameValues = data.name.split(',');
    const newArray = nameValues.map((value) => ({
      name: value.trim(),
      approval_status: data.approval_status,
    }));
    if (params.degreeId) {
      data.id = params.degreeId;
      dispatch(updateAdminDegree(data));
    } else {
      dispatch(postAdminDegree(newArray));
    }
    setTimeout(() => {
      navigate('/l/admin/degrees');
    }, 2000);
  };
  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.degreeId ? 'Create Degree' : 'Edit Degree'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('name', {
                        required: 'Please enter Degree name',
                      })}
                      name="name"
                      label={'Name'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('approval_status', {
                        required: 'Please select a status',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Approval Status"
                      error={!!errors.approval_status}
                      defaultValue={
                        params.degreeId
                          ? selectedDegree?.approval_status
                          : 'Accepted'
                      }
                    >
                      <MenuItem value="Accepted" key="Accepted">
                        Accepted
                      </MenuItem>
                      <MenuItem value="Pending" key="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem value="Rejected" key="Rejected">
                        Rejected
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isCreatingAdminDegree || isUpdatingAdminDegree}
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isCreatingAdminDegree || isUpdatingAdminDegree ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default CreateDegree;
