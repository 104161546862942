import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useState } from 'react';

export const ImageDialog = ({ link }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <img
          className="image-bugs"
          src={link}
          alt={'bug_image'}
          loading="lazy"
        />
      </Button>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <div
          className="container-dialog"
          style={{
            height: '70vh',
            width: 'auto',
          }}
        >
          <DialogContent>
            <img src={link} />
            <Button variant="outlined" color="primary">
              <a href={link} download>
                Download
              </a>
            </Button>
          </DialogContent>
          <DialogActions className="dialog-box-image-actions">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
