import { styled } from '@mui/styles';

export const StyleTagsForm = styled('div')(({ theme }) => ({
  '& .tags-form': {
    padding: '40px 0',
  },
  '& .form-control': {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 0,
    alignItems: 'unset',
    padding: '10px 0',
  },
  '& .form-control > label': {
    marginBottom: '10px',
  },
  '& .error': {
    color: theme.palette.common.error,
  },
}));
