import * as React from 'react';
import ReactSelect from 'react-select';
import { FormControl, FormLabel, Avatar } from '@mui/material';

const CategoryOption = ({ categories, onChange, value }) => {
  const onCategoryChange = (e) => {
    const categoryBody = {
      ...value,
      correct_id: e?.id,
    };
    onChange(categoryBody);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit', paddingBottom: '8px' }}
      >
        Select appropriate category
      </FormLabel>
      <ReactSelect
        value={categories?.find((c) => c.id === value?.correct_id)}
        options={categories}
        onChange={onCategoryChange}
        getOptionLabel={(option) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Avatar
              alt={`${option?.name}`}
              src={option?.s3_url}
              style={{
                height: 24,
                width: 24,
              }}
            />
            &nbsp; &nbsp;
            {option?.name}
          </div>
        )}
        getOptionValue={(option) => option?.id}
        isClearable
      />
    </FormControl>
  );
};

export default CategoryOption;
