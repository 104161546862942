import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const StatsContainerMockTest = styled(Box)(({ theme }) => ({
  marginTop: '15px',

  '& .questions-mockTest': {
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.secondary.color,
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .avatar-mockTest': {
      height: '40px',
      width: '30%',
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.common.white,
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));
