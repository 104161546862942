import * as React from 'react';
import { Typography, Box, Divider, Avatar, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ClockIcon from '@mui/icons-material/Schedule';
import ParticipantsIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';

import BarChart from './BarChart';
import DoughnutChart from './DoughnutChart';
import EmptyBadgeIcon from './img/empty_badge_icom.png';

import {
  PracticeContainer,
  PracticeTopContainer,
  PracticeHeadingContainer,
  PracticeHeading,
  PracticeLevel,
  PracticeAnalyticsContainer,
  PracticeAnalyticsBox,
  PracticeAnalyticsValue,
  PracticeAnalyticsName,
  PracticeStatisticsContainer,
  PracticeStatText,
  PracticeBadgeContainer,
  PracticeCompetitionTags,
  PracticeSubtitleTextContainer,
  PracticeSubtitleText,
  PracticeDoughnutContainer,
  PracticeDoughnutActivityContainer,
  PracticeDoughnutColorsBox,
  PracticeColorContainer,
  PracticeBadgeTooltip,
  PracticeTooltipHeading,
  PracticeTooltipText,
  PracticeTooltipSubtitle,
  PracticeBadgeCountContainer,
  PracticeBadgeCountText,
  PracticeExtraBadgeText,
  PracticeExtraBadgeContainer,
} from './UserProfileStyledComponent';
import moment from 'moment';

const BadgeTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  },
}))(Tooltip);

const PractiseTagBox = ({
  data,
  isCompetition = false,
  isAuthorProfile = false,
  isSelf,
}) => {
  const BarData = {
    attempted: data?.total_question_count,
    correct: data?.total_correct_count,
    incorrect: data?.total_incorrect_count,
    'timed out': data?.total_timeout_count,
    skipped: data?.total_skipped_count,
  };
  const BarBackgroundColors = [
    '#3572B0',
    '#388E3C',
    '#D60505',
    '#EE9D00',
    '#21A9DE',
  ];

  const score = data?.score ?? data?.value;
  const rank = data?.score_rank ?? data?.rank;
  const accuracy = data?.percent_accuracy;

  // Author Profile Data
  const doughnutData = {};
  let doughnutColors;

  if (isAuthorProfile) {
    Object.keys(data).forEach((lvl) => {
      if (isNaN(lvl)) return;
      doughnutData[lvl] = parseInt(data[lvl]?.total_questions_created);
    });

    const dataLen = Object.keys(doughnutData).length;
    doughnutColors = [
      '#82C341',
      '#4C9DE1',
      '#F5A623',
      '#E75C58',
      'rgba(123, 123, 123, 0.60)',
      '#A77DC2',
      '#9F8170',
      '#989898',
      '#010101',
    ].slice(0, dataLen);
  }

  return (
    <PracticeContainer>
      <PracticeTopContainer>
        <Box>
          <img
            src={data?.s3_url || data?.image_url}
            alt="tag-logo"
            width="60px"
            height="60px"
          />
        </Box>
        <PracticeHeadingContainer>
          {!isCompetition && !isAuthorProfile && (
            <PracticeLevel
              label={`Level ${data?.user_tag_level || 0}`}
              color="primary"
              variant="outlined"
            />
          )}
          <PracticeHeading color="primary">
            {data.tag_name || data.name}
          </PracticeHeading>
          {isCompetition && (
            <PracticeSubtitleTextContainer>
              <Box style={{ display: 'flex', gap: '2px' }}>
                <ClockIcon style={{ width: '15px' }} color="primary" />
                <PracticeSubtitleText>Avg. Time</PracticeSubtitleText>
                <PracticeSubtitleText color="primary">
                  {`${data?.avg_time_taken_total}s`}
                </PracticeSubtitleText>
              </Box>
              <Box style={{ display: 'flex', gap: '2px' }}>
                <ParticipantsIcon style={{ width: '15px' }} color="primary" />
                <PracticeSubtitleText>Total Participants</PracticeSubtitleText>
                <PracticeSubtitleText color="primary">
                  {data.total_participants}
                </PracticeSubtitleText>
              </Box>
            </PracticeSubtitleTextContainer>
          )}
        </PracticeHeadingContainer>
      </PracticeTopContainer>
      {!isCompetition ? (
        <PracticeBadgeContainer>
          {Object.values(data?.badge_obj || {}).length === 0 && (
            <Box display="flex" gap="10px" alignItems="center">
              <img src={EmptyBadgeIcon} alt="icon" width={30} height={30} />
              {isSelf ? (
                <Box>
                  <Typography fontSize={'12px'} color="black" fontWeight={500}>
                    Level up Your Skills:{' '}
                  </Typography>
                  <Typography fontSize={'12px'} color="black" fontWeight={500}>
                    Earn Badges through Practice and Dedication!
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography fontSize={'12px'} color="black" fontWeight={500}>
                    No badges achieved in this category yet.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {Object.values(data?.badge_obj || {})
            .filter((badgeInfo) => badgeInfo?.image_url)
            .slice(0, 5)
            .map((badgeInfo) => (
              <BadgeTooltip
                key={badgeInfo.badge_id}
                enterTouchDelay={50}
                placement="top"
                title={
                  <PracticeBadgeTooltip>
                    <Avatar
                      src={badgeInfo?.image_url}
                      width="90px"
                      height="90px"
                      style={{
                        width: '90px',
                        height: '90px',
                      }}
                    />
                    <Box>
                      <PracticeTooltipHeading color="primary">
                        {badgeInfo.badge_name?.split('_').join(' ')}
                      </PracticeTooltipHeading>
                      <PracticeTooltipText>
                        {badgeInfo.description}
                      </PracticeTooltipText>
                      <Typography
                        color="primary"
                        style={{ fontSize: '12px', fontWeight: '500' }}
                      >
                        {`Owned Badges: ${badgeInfo.count}`}
                      </Typography>
                      <PracticeTooltipSubtitle color="text">
                        Last Received:{' '}
                        {badgeInfo?.last_received_date
                          ? moment(badgeInfo?.last_received_date).format(
                              'DD/MM/YYYY',
                            )
                          : 'NA'}
                      </PracticeTooltipSubtitle>
                    </Box>
                  </PracticeBadgeTooltip>
                }
              >
                <Box style={{ position: 'relative' }}>
                  <PracticeBadgeCountContainer>
                    <PracticeBadgeCountText>
                      {badgeInfo.count}
                    </PracticeBadgeCountText>
                  </PracticeBadgeCountContainer>
                  <Avatar
                    src={badgeInfo?.image_url}
                    width="30px"
                    height="30px"
                  />
                </Box>
              </BadgeTooltip>
            ))}
          {Object.values(data?.badge_obj || {}).filter(
            (badgeInfo) => badgeInfo?.image_url,
          )?.length > 5 && (
            <Box style={{ position: 'relative' }}>
              <Avatar
                src={
                  Object.values(data?.badge_obj || {}).filter(
                    (badgeInfo) => badgeInfo?.image_url,
                  )?.[5]?.image_url
                }
                width="30px"
                height="30px"
              />
              <PracticeExtraBadgeContainer />
              <PracticeExtraBadgeText color="primary">{`+${Object.values(
                data?.badge_obj || {},
              ).filter((badgeInfo) => badgeInfo?.image_url)?.length -
                5}`}</PracticeExtraBadgeText>
            </Box>
          )}
        </PracticeBadgeContainer>
      ) : (
        <PracticeBadgeContainer style={{ gap: '5px', height: '55px' }}>
          {data.category_list.slice(0, 5).map((category) => (
            <Tooltip
              title={category.name}
              placement="top"
              arrow
              key={category.id}
            >
              <PracticeCompetitionTags label={category.name} />
            </Tooltip>
          ))}
          {data.category_list.length - 5 > 0 && (
            <PracticeCompetitionTags
              label={`+${data.category_list.length - 5}`}
              style={{ width: '50px' }}
            />
          )}
        </PracticeBadgeContainer>
      )}
      {!isAuthorProfile && (
        <PracticeAnalyticsContainer>
          <PracticeAnalyticsBox>
            <PracticeAnalyticsValue color={'primary'}>
              {score}
            </PracticeAnalyticsValue>
            <PracticeAnalyticsName variant="caption">
              Score
            </PracticeAnalyticsName>
          </PracticeAnalyticsBox>
          <PracticeAnalyticsBox>
            <PracticeAnalyticsValue color={'primary'}>
              {rank}
            </PracticeAnalyticsValue>
            <PracticeAnalyticsName variant="caption">
              Rank
            </PracticeAnalyticsName>
          </PracticeAnalyticsBox>
          <PracticeAnalyticsBox>
            <PracticeAnalyticsValue color={'primary'}>
              {accuracy}
            </PracticeAnalyticsValue>
            <PracticeAnalyticsName variant="caption">
              Accuracy
            </PracticeAnalyticsName>
          </PracticeAnalyticsBox>
        </PracticeAnalyticsContainer>
      )}
      <PracticeStatisticsContainer
        style={{ padding: !isAuthorProfile && '0px 10px' }}
      >
        <PracticeStatText color="primary">Statistics</PracticeStatText>
        {!isAuthorProfile ? (
          <BarChart data={BarData} backgroundColors={BarBackgroundColors} />
        ) : (
          <PracticeDoughnutContainer>
            <Box>
              <Box width={'180px'}>
                <DoughnutChart
                  data={doughnutData}
                  colors={doughnutColors}
                  type="userprofile"
                />
              </Box>
              <PracticeSubtitleText mt="5px">
                Difficulty Level
              </PracticeSubtitleText>
              <PracticeColorContainer>
                {Object.keys(doughnutData).map((lvl, idx) => (
                  <PracticeDoughnutColorsBox
                    key={lvl}
                    style={{ backgroundColor: doughnutColors[idx] }}
                  >
                    {lvl}
                  </PracticeDoughnutColorsBox>
                ))}
              </PracticeColorContainer>
            </Box>
            <Divider orientation="vertical" flexItem />
            <PracticeDoughnutActivityContainer>
              <PracticeAnalyticsBox>
                <PracticeAnalyticsValue color={'primary'}>
                  {data?.tag_stats.total_questions_created}
                </PracticeAnalyticsValue>
                <PracticeAnalyticsName>Ques. Created</PracticeAnalyticsName>
              </PracticeAnalyticsBox>
              <PracticeAnalyticsBox>
                <PracticeAnalyticsValue color={'primary'}>
                  {data?.tag_stats.total_bookmarks}
                </PracticeAnalyticsValue>
                <PracticeAnalyticsName>Bookmarks</PracticeAnalyticsName>
              </PracticeAnalyticsBox>
              <PracticeAnalyticsBox>
                <PracticeAnalyticsValue color={'primary'}>
                  {data?.tag_stats.total_likes}
                </PracticeAnalyticsValue>
                <PracticeAnalyticsName>Likes</PracticeAnalyticsName>
              </PracticeAnalyticsBox>
              <PracticeAnalyticsBox>
                <PracticeAnalyticsValue color={'primary'}>
                  {data?.tag_stats.complete_attempt_avg_time}
                </PracticeAnalyticsValue>
                <PracticeAnalyticsName>Avg. Time</PracticeAnalyticsName>
              </PracticeAnalyticsBox>
            </PracticeDoughnutActivityContainer>
          </PracticeDoughnutContainer>
        )}
      </PracticeStatisticsContainer>
    </PracticeContainer>
  );
};

export default PractiseTagBox;
