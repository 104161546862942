import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import {
  Grid,
  Paper,
  Box,
  MenuList,
  InputBase,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './index.css';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.2,
  },
  InputBase: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    border: '2px solid #f1f1f1',
    paddingLeft: '10px',
    paddingTop: '15px',
    fontSize: '16px',
    height: '450px',
    borderRadius: '4px',
  },
  Paper: {
    padding: '25px 15px',
    paddingTop: '15px',
    border: '2px solid #f1f1f1',
    height: '450px',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingRight: '4px',
    paddingLeft: '4px',
  },
  List: {
    width: '700px',
    marginBottom: '5px',
    padding: 0,
  },
  ListItem: {
    padding: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  deleteForeverIcon: {
    position: 'absolute',
    right: '4px',
    color: '#cccccc',
  },
  addTemplate: {
    padding: theme.spacing(1),
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
  },
  addInput: {
    borderRadius: '4px',
    backgroundColor: 'white',
    fontSize: '15px',
    width: '100%',
    padding: theme.spacing(1),
    minHeight: '40px',
    marginBottom: theme.spacing(1),
  },
  addBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: 0,
  },
  cancelButton: {
    textTransform: 'none',
  },
  saveButton: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  subPaper: {
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
    '&::-webkit-scrollbar': { width: 0 },
  },
  listItemIcon: {
    position: 'relative',
    alignItems: 'center',
    height: '15px',
    lineHeight: 1.2,
    minWidth: 0,
  },
  appsIcon: {
    padding: '0 8px',
    color: '#cccccc',
    minWidth: 0,
    lineHeight: 1.2,
  },
  add: {
    cursor: 'pointer',
  },
  agreeButton: {
    color: theme.palette.common.error,
  },
  label: {
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
  },
  templateHeader: {
    fontSize: '18px',
  },
  searchTemplateInput: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: theme.spacing(1),
  },
}));

export default function BlockReasons({
  selectedReasons,
  setSelectedReasons,
  question,
  getReviewTemplate,
  postReviewTemplate,
  deleteReviewTemplate,
}) {
  const classes = useStyles();
  const [display, setDisplay] = React.useState('none');
  const [description, setDescription] = React.useState('');
  const [hover, setHover] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [optionReasons, setOptionReasons] = React.useState([]);
  const [templateSelected, setTemplateSelected] = React.useState('');
  const [searchTemplateText, setSearchTemplateText] = React.useState('');

  React.useEffect(() => {
    getReviewTemplate();
  }, []);

  React.useEffect(() => {
    if (
      question.isPostNewTemplateSuccess === true ||
      question.isDeleteNewTemplateSuccess === true
    ) {
      getReviewTemplate();
    }
    if (question.isGotTemplates === true) {
      setOptionReasons(question.templates);
    }
  }, [
    question.isPostNewTemplateSuccess,
    question.isDeleteNewTemplateSuccess,
    question.isGotTemplates,
  ]);

  React.useEffect(() => {
    handleSearchTemplatesInput(searchTemplateText);
  }, [searchTemplateText]);

  const handleMenuItemClick = (event, id) => {
    let reasonText = selectedReasons;
    let text = [];
    text = optionReasons.filter((option) => {
      if (id === option.id) {
        return option;
      }
    });
    if (!reasonText.length) {
      reasonText += text[0].description;
    } else {
      reasonText += '\n' + text[0].description;
    }
    setSelectedReasons(reasonText);
  };

  const addTemplate = () => {
    setDisplay('block');
  };

  const saveTemplate = () => {
    setDisplay('none');
    const saveText = description.trim();
    const data = {
      name: saveText,
      description: saveText,
    };
    if (saveText.length) postReviewTemplate(data);
    setDescription('');
  };

  const cancelTemplate = () => {
    setDisplay('none');
    setDescription('');
  };

  const deleteTemplate = (e, id) => {
    deleteReviewTemplate(id);
    handleClose(e);
  };

  const onTextChange = (event) => {
    setDescription(event.target.value);
  };

  const handleMouseEnter = (id) => {
    setHover({
      [id]: true,
    });
  };

  const handleMouseLeave = (id) => {
    setHover({
      [id]: false,
    });
  };

  const handleClickOpen = (e, id) => {
    e.stopPropagation();
    setOpen(true);
    setTemplateSelected(id);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleSearchTemplatesInput = (text) => {
    const txt = text.toLowerCase();
    const filteredTemplates = question.templates?.filter((template) => {
      return (template.name || '').toLowerCase().includes(txt);
    });

    setOptionReasons(filteredTemplates);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <InputBase
          className={classes.InputBase}
          placeholder="Please Type Your Reasons"
          value={selectedReasons}
          onChange={(event) => {
            const reason = event.target.value;
            setSelectedReasons(reason);
          }}
          multiline={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.Paper} elevation={0} id="addTemplate">
          <Box className={classes.box}>
            <Typography className={classes.templateHeader}>
              Choose Templates
            </Typography>
            <AddBoxIcon
              onClick={addTemplate}
              color="primary"
              className={classes.add}
            />
          </Box>

          <Box className={classes.searchTemplateInput}>
            <TextField
              onChange={(e) => setSearchTemplateText(e.target.value || '')}
              className={classes.searchTemplateInput}
              variant="outlined"
              placeholder="Search"
              value={searchTemplateText}
            />
          </Box>

          <Paper
            elevation={0}
            className={classes.subPaper}
            style={{ maxHeight: display === 'block' ? '200px' : '320px' }}
          >
            <MenuList>
              {optionReasons &&
                optionReasons.map((option) => (
                  <Box className="options-menu" key={option.id}>
                    <List dense="dense" className={classes.List}>
                      <ListItem
                        button
                        className={classes.ListItem}
                        onMouseEnter={() => handleMouseEnter(option.id)}
                        onMouseLeave={() => handleMouseLeave(option.id)}
                        onClick={(event) =>
                          handleMenuItemClick(event, option.id)
                        }
                      >
                        <ListItemIcon className={classes.appsIcon}>
                          <AppsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={option.description}
                          classes={{
                            primary: classes.listItemText,
                          }}
                          primaryTypographyProps={{
                            style: { whiteSpace: 'pre-wrap' },
                          }}
                        />
                        <ListItemIcon
                          onClick={(e) => handleClickOpen(e, option.id)}
                          className={classes.listItemIcon}
                          style={{
                            visibility: hover[option.id] ? 'visible' : 'hidden',
                          }}
                        >
                          <DeleteForeverIcon
                            className={classes.deleteForeverIcon}
                          />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Box>
                ))}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Do you want to continue deleting this template?'}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={(e) => handleClose(e)}
                    autoFocus
                    className={classes.disagreeButton}
                  >
                    No
                  </Button>
                  <Button
                    onClick={(e) => {
                      deleteTemplate(e, templateSelected);
                      setTemplateSelected('');
                    }}
                    className={classes.agreeButton}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </MenuList>
          </Paper>
          <Box
            className={classes.addTemplate}
            style={{ display: `${display}` }}
          >
            <Typography className={classes.label} variant="body1">
              Please enter template description
            </Typography>
            <InputBase
              className={classes.addInput}
              multiline="true"
              onChange={onTextChange}
              value={description}
              rows={2}
            />
            <Box className={classes.addBox}>
              <Button onClick={cancelTemplate} className={classes.cancelButton}>
                Cancel
              </Button>
              <Button onClick={saveTemplate} className={classes.saveButton}>
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
