import React from 'react';

import Logo from './img/logo.png';
import { StyleBrand } from './StyledComponent';

export default function Branding() {
  return (
    <StyleBrand>
      <img src={Logo} alt="Abekus" width={120} />
    </StyleBrand>
  );
}
