import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { StyledBlankContainer } from './StyledComponent';

const BlankPage = ({ text, icon, action }) => {
  return (
    <StyledBlankContainer>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography variant="subtitle1">{text}</Typography>
        </Grid>

        <Grid item>{!!action && action}</Grid>
      </Grid>
    </StyledBlankContainer>
  );
};

export default BlankPage;
