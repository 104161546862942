import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Line } from 'rc-progress';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import helpers from '../../lib/helpers';
import './index.scss';
import { useDispatch } from 'react-redux';
import { resetPurchaseTime } from '../../actions/question';

const Timer = (props, ref) => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(
    props.duration + props.purchasedTime,
  );
  const [totalTime, setTotalTime] = useState(
    props.maxTime + props.purchasedTime,
  );
  const timer = useRef();

  useEffect(() => {
    const { purchasedTime } = props;
    setDuration(duration + purchasedTime);
    setTotalTime(totalTime + purchasedTime);
  }, [props.purchasedTime]);

  useEffect(() => {
    dispatch(resetPurchaseTime());
  }, [totalTime]);

  const startTimer = () => {
    stopTimer();
    resetTimer();

    timer.current = setInterval(tick, 1000);
  };

  const tick = () => {
    if (timer.current && duration) {
      setDuration((prevState) => {
        const newState = Math.max(prevState - 1, 0);
        if (newState <= 0) {
          setDuration(0);
          stopTimer();
          props.timeOver();
        }

        return newState;
      });
    }
  };

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  useEffect(() => {
    ref.current = {
      startTimer,
      stopTimer,
      state: {
        duration,
      },
    };
  }, [duration]);

  const resetTimer = () => {
    setDuration(props.duration + props.purchasedTime);
  };

  const { handleGetMoreTime } = props;

  const daysout = helpers.getDaysRemaining(duration);
  const hoursout = helpers.getHoursRemaining(duration);
  const minutesout = helpers.getMinutesRemaining(duration);
  const secondsout = helpers.getSecondsRemaining(duration);
  let first, second;
  if (daysout > 0) {
    first = daysout + 'd';
    if (hoursout > 0) {
      second = hoursout + 'h';
    } else if (minutesout > 0) {
      second = minutesout + 'm';
    } else {
      second = secondsout + 's';
    }
  } else if (hoursout > 0) {
    first = hoursout + 'h';
    if (minutesout > 0) {
      second = minutesout + 'm';
    } else {
      second = secondsout + 's';
    }
  } else if (minutesout > 0) {
    first = minutesout + 'm';
    second = secondsout + 's';
  } else {
    first = '0m';
    second = secondsout + 's';
  }

  const percentCompleted = (duration / totalTime) * 100;

  return (
    <Box display="flex" alignItems="center" className="timer-box" flex={1}>
      {props.lineProgressBar && (
        <Line
          className="timer-line-progress-bar"
          percent={percentCompleted}
          strokeColor={percentCompleted > 25 ? '#4EB035' : '#f00'}
          trailColor="#D9D9D9BF"
        />
      )}
      <Typography variant="h4" sx={{ background: 'transparent' }}>
        <span>{first}</span>:<span>{second}</span>
      </Typography>
      {props.isGetMoreTimePossible && (
        <IconButton onClick={handleGetMoreTime} color="inherit" size="large">
          <AddCircleIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  );
};

export default forwardRef(Timer);
