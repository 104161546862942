import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Play from '.';
import AllCompetitionAttempted from './AllCompetitionAttempted';

const Competition = (props) => {
  const [questionAttemptTimer, setQuestionAttemptTimer] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isHintVisible, setIsHintVisible] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);
  const [answer, setAnswer] = useState({});
  const [message, setMessage] = useState({});
  const [answerStatus, setAnswerStatus] = useState({});
  const [modalVisibility, setModalVisibility] = useState({
    hintModal: false,
    reportModal: false,
    timedoutModal: false,
    streakSaveModal: false,
    confirmEndModal: false,
    getMoreTimeModal: false,
    answerStatusModal: false,
    unlockSolutionModal: false,
    subcategorySelectorModal: false,
    collectBadgeModal: false,
  });

  const { competitionId } = useParams();
  const navigate = useNavigate();
  const {
    question,
    getCompetitionById,
    getCompetitionQuestion,
    getCompetitionAnalytics,
    getStreak,
    resetQuestions,
  } = props;

  const getNextQuestion = () => {
    // clearing state while fetching a new question
    setIsHintVisible(false);
    setIsSolutionVisible(false);
    setIsAnswerSubmitted(false);

    getCompetitionQuestion(competitionId);
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);

      getCompetitionById(competitionId);
      getCompetitionAnalytics(competitionId);
      getStreak(competitionId);

      getNextQuestion();
    })();

    return () => {
      setIsAnswerSubmitted(false);
      setIsHintVisible(false);
      setIsSolutionVisible(false);
      setAnswer({});
      setModalVisibility({
        hintModal: false,
        reportModal: false,
        streakSaveModal: false,
        confirmEndModal: false,
        getMoreTimeModal: false,
        answerStatusModal: false,
        unlockSolutionModal: false,
        subcategorySelectorModal: false,
      });
      setAnswerStatus({});
      setMessage({});
      resetQuestions();
    };
  }, []);

  useEffect(() => {
    if (question.isAnswerSuccess) {
      getCompetitionAnalytics(competitionId);
    }
  }, [
    competitionId,
    question.data.id,
    question.answerStatus,
    question.isAnswerSuccess,
  ]);

  useEffect(() => {
    if (
      question.data.id &&
      !question.isAnswerSuccess &&
      !question.isAnswerFail
    ) {
      const interval = setInterval(() => {
        setQuestionAttemptTimer((t) => t + 1);
      }, 1000);
      return () => {
        clearInterval(interval);
        setQuestionAttemptTimer(0);
      };
    }
  }, [question.data.id, question.isAnswerSuccess, question.isAnswerFail]);

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      {question?.error === 'no_more_attempts_remaining' ? (
        <AllCompetitionAttempted {...props} />
      ) : (
        <Play
          {...props}
          getNextQuestion={getNextQuestion}
          timePeriod="competition"
          categoryId={competitionId}
          onBack={onBack}
          modalVisibility={modalVisibility}
          setModalVisibility={setModalVisibility}
          isAnswerSubmitted={isAnswerSubmitted}
          setIsAnswerSubmitted={setIsAnswerSubmitted}
          isHintVisible={isHintVisible}
          setIsHintVisible={setIsHintVisible}
          isSolutionVisible={isSolutionVisible}
          setIsSolutionVisible={setIsSolutionVisible}
          answer={answer}
          setAnswer={setAnswer}
          message={message}
          setMessage={setMessage}
          answerStatus={answerStatus}
          setAnswerStatus={setAnswerStatus}
          questionAttemptTimer={questionAttemptTimer}
          setQuestionAttemptTimer={setQuestionAttemptTimer}
        />
      )}
    </>
  );
};

export default Competition;
