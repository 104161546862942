import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Collapse,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  getInvoiceFileName,
  getOrderHistoryList,
} from '../../actions/orderHistory';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Gems from '../../images/Gemsnew.svg';
import { Fragment } from 'react';
import { matchSorter } from 'match-sorter';
import { useMemo } from 'react';
import { getSingleOrderDetails } from '../../actions/storeAdmin';
import pdfPlaceholder from '../../images/pdfPlaceholder.png';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { CustomDialogBox } from '../DialogBox';
const useStyles = makeStyles((theme) => ({
  heading: {
    color: '#25507B',
    lineHeight: '80%',
    fontWeight: '700',
  },
  header_Img: {
    width: '100%',
  },
  heading_grid_container: {
    color: '#25507B',
  },
  card: {
    display: 'flex',
    overflow: 'visible',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
  cardMedia: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 10px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
    '&:last-child': {
      paddingBottom: 1,
    },
  },
  cardActions: {
    display: 'flex',
    padding: '0px 0px 10px 0px',
  },
  collapseBtn: {
    background: 'white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: '#25507B',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    height: '30px',
    borderRadius: '0px 0px 5px 5px',
    marginTop: '3px',
  },
  collapseGrid: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.4px',
    color: '#76767F',
    padding: '10px',
  },
  pdfBtn: {
    position: 'absolute',
    top: '30px',
    left: '75px',
    height: '20px',
    fontSize: '14px',
    width: '80px',
    lineHeight: '10px',
  },
  status: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const OrderHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderHistoryList());
  }, [dispatch]);
  const [isCollapse, setIsCollapse] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const { orderHistoryList } = useSelector((state) => state.orderHistory);
  const { data } = useSelector((state) => state.user);
  const { singleOrderData } = useSelector((state) => state.storeAdmin);

  const sortedList = useMemo(() => {
    return matchSorter(Array.from(orderHistoryList), '', {
      keys: ['created_at'],
    }).reverse();
  }, [orderHistoryList]);
  const handleCollapseContainer = (id, trnId) => {
    dispatch(getSingleOrderDetails(trnId));
    setOrderId(id);
    setIsCollapse(true);
  };

  const handleInvoiceDownload = (invoiceUrl) => {
    if (!invoiceUrl) setOpenDialog(true);
    else dispatch(getInvoiceFileName(invoiceUrl));
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <Container>
      <Grid container className={classes.heading}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="h6">
              {' '}
              Order History({orderHistoryList.length})
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        {Array.from(sortedList).map((list) => (
          <Fragment key={list.id}>
            {list.cart?.map((cartItem) => (
              <Grid item xs={12} sm={12} lg={12} key={cartItem.id}>
                <Card className={classes.card}>
                  <Grid container>
                    <Grid item lg={1} md={2} sm={12} xs={12}>
                      <CardMedia
                        component="img"
                        className={classes.cardMedia}
                        image={
                          cartItem.item?.image_url &&
                          cartItem.item?.image_url[0]
                        }
                        alt={'Product Image'}
                      />
                    </Grid>
                    <Grid item lg={11} md={10} sm={12} xs={12}>
                      <CardContent className={classes.cardContent}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{ fontSize: '16px' }}
                            >
                              {cartItem.item?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: '12px',
                                color: '#3572B0',
                                textAlign: 'right',
                              }}
                            >
                              {list.invoice_no && (
                                <>Order No: {list.invoice_no}</>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box>
                          <Typography style={{ fontSize: '12px' }}>
                            {cartItem.item?.item_description}
                          </Typography>
                        </Box>
                        <Grid container style={{ marginTop: '15px' }}>
                          <Grid item xs={11}>
                            {list.user_msg ? (
                              <Typography
                                style={{ color: '#3572B0', fontSize: '12px' }}
                              >
                                <strong>User Msg</strong> :{list.user_msg}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                        <CardActions className={classes.cardActions}>
                          <Grid
                            container
                            style={{
                              color: 'grey',
                              alignItems: 'end',
                            }}
                          >
                            <Grid item md={3} sm={12} xs={12}>
                              <Box flex={1}>
                                <Typography variant="subtitle2">
                                  <span style={{ color: 'grey' }}>
                                    Date:{' '}
                                    {moment(cartItem.created_at).format(
                                      'YYYY-MM-DD',
                                    )}
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Box className={classes.quantity}>
                                <Typography variant="subtitle2">
                                  <span style={{ color: 'grey' }}>
                                    Quantity: {cartItem.quantity}
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Box className={classes.price}>
                                <Typography variant="subtitle2">
                                  <span>
                                    Price:{' '}
                                    {cartItem.total_amount_in_currency &&
                                      '₹' +
                                        cartItem.total_amount_in_currency}{' '}
                                    {cartItem.total_amount_in_gems && (
                                      <>
                                        <img src={Gems} width="11px" />{' '}
                                        {cartItem.total_amount_in_gems}
                                      </>
                                    )}
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}>
                              <Box className={classes.status}>
                                <Typography variant="subtitle2">
                                  <span>
                                    Status:{' '}
                                    <span style={{ color: 'green' }}>
                                      {list.payment_status}
                                    </span>
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
                {orderId === cartItem.id ? (
                  <Collapse
                    in={isCollapse}
                    style={{
                      background: 'white',
                      marginTop: '4px',
                    }}
                  >
                    <Grid container className={classes.collapseGrid}>
                      <Grid item lg={9} md={8} sm={6} xs={12}>
                        <strong>Name</strong>:{' '}
                        {singleOrderData.addresses?.shipping?.name}
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box display={'flex'} justifyContent={'flex-start'}>
                          <strong>Email</strong>: {data.email}
                        </Box>
                      </Grid>
                      {singleOrderData.addresses && (
                        <>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Typography
                              style={{ fontWeight: 600, fontSize: '12px' }}
                            >
                              Shipping Address :
                            </Typography>
                            Street:{' '}
                            {singleOrderData.addresses?.shipping?.address}{' '}
                            <br />
                            State: {
                              singleOrderData.addresses?.shipping?.state
                            }{' '}
                            <br />
                            Phone number:{' '}
                            {singleOrderData.addresses?.shipping?.phone_no}{' '}
                            <br />
                            Zip code:{' '}
                            {singleOrderData.addresses?.shipping?.pincode}{' '}
                            <br />
                            Country:{' '}
                            {singleOrderData.addresses?.shipping?.country}
                          </Grid>
                          <Grid
                            item
                            lg={5}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Typography
                              style={{ fontWeight: 600, fontSize: '12px' }}
                            >
                              Billing Address :
                            </Typography>
                            Street:{' '}
                            {singleOrderData.addresses?.billing?.address} <br />
                            State: {
                              singleOrderData.addresses?.billing?.state
                            }{' '}
                            <br />
                            Phone number:{' '}
                            {singleOrderData.addresses?.billing?.phone_no}{' '}
                            <br />
                            Zip code:{' '}
                            {singleOrderData.addresses?.billing?.pincode} <br />
                            Country:{' '}
                            {singleOrderData.addresses?.billing?.country}
                          </Grid>
                        </>
                      )}
                      {cartItem.total_amount_in_currency &&
                        list.payment_status === 'succeeded' && (
                          <Grid
                            item
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                            style={{ marginTop: '5px' }}
                          >
                            <Box display={'flex'} justifyContent={'flex-start'}>
                              <strong>Invoice</strong>:
                            </Box>

                            <Box
                              display={'flex'}
                              justifyContent={'flex-start'}
                              style={{ position: 'relative' }}
                            >
                              <img
                                src={pdfPlaceholder}
                                style={{
                                  border: '1px solid #3572B0',
                                  borderRadius: '4px',
                                  position: 'relative',
                                }}
                              />
                              <Button
                                className={classes.pdfBtn}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleInvoiceDownload(list.invoice_url)
                                }
                              >
                                View
                              </Button>
                            </Box>
                          </Grid>
                        )}
                    </Grid>
                  </Collapse>
                ) : null}

                {isCollapse && orderId === cartItem.id ? (
                  <Button
                    variant="contained"
                    className={classes.collapseBtn}
                    fullWidth
                    onClick={() => setIsCollapse(false)}
                  >
                    Show more details <ExpandLessIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.collapseBtn}
                    fullWidth
                    onClick={() =>
                      handleCollapseContainer(
                        cartItem.id,
                        cartItem.transaction_id,
                      )
                    }
                  >
                    Show more details <ExpandMoreIcon />
                  </Button>
                )}
              </Grid>
            ))}
          </Fragment>
        ))}
      </Grid>
      <CustomDialogBox
        open={openDialog}
        onClose={handleDialogClose}
        actions={
          <Button
            variant="outlined"
            type="button"
            color="primary"
            onClick={handleDialogClose}
          >
            OK
          </Button>
        }
        title="Message"
        maxWidth={'sm'}
      >
        <Typography variant="h6">
          The Invoice will Generate in next 15 mins.
        </Typography>
      </CustomDialogBox>
    </Container>
  );
};

export default OrderHistory;
