import { styled } from '@mui/styles';

export const StyledResult = styled('div')(({ theme }) => ({
  '& .result': {
    backgroundColor: theme.palette.common.white,
    padding: '0.5em 1em',

    h6: {
      fontFamily: 'Roboto',
    },

    '& .result__header': {
      display: 'flex',
      padding: '0.5em 0',
      alignItems: 'center',

      '@media (max-width: 1000px)': {
        button: {
          padding: 0,
          paddingRight: '1em',
        },
      },
    },

    '& .result__participant': {
      position: 'absolute',
      top: '1.5em',
      right: 0,
      padding: '0 1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      fontSize: '1.02rem',
      gap: 10,
      img: {
        height: '25px',
      },
      '@media (max-width:900px)': {
        position: 'static',
        padding: '0px',
        display: 'none',
      },
    },

    '& .result__achievements': {
      minHeight: '100px',
      margin: '12px 0px',
    },

    '& .result__panel': {
      margin: '10px 0px',
      minHeight: '120px',
      borderRadius: '8px',
      border: `1.2px solid ${theme.palette.primary.background}`,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      '@media (max-width: 600px)': {
        flexDirection: 'column-reverse',
      },
    },

    '& .result__imgCtn': {
      width: '45%',
      height: '120px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      img: {
        width: '100%',
        marginBottom: '-3px',
      },
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },

    '& .result__detailsCtn': {
      width: '55%',
      height: '120px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '4px',
      '& .c': {
        backgroundImage: 'url(./images/credits.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '96%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '& .g': {
        backgroundImage: 'url(./images/gems.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '96%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '& .r': {
        backgroundImage: 'url(./images/rank.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '96%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '@media (max-width: 600px)': {
        width: '100%',
      },
    },

    '& .result__gap': {
      paddingLeft: '10px',
      '@media (max-width: 950px)': {
        paddingLeft: '0px',
      },
    },

    '& .result__certificate': {
      margin: '10px 0px',
      height: '122px',
      width: '100%',
      borderRadius: '8px',
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: 'url("./images/certificateMode.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      '& .fade': {
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(5px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },

    '& .result__categories': {
      margin: '1em 0',
      background: theme.palette.primary.background,
      borderRadius: '5px',
      display: 'flex',
      padding: '1em',
      p: {
        fontSize: '1.1em',
        marginRight: '0.3em',
      },
      gap: '0.3em',
      flexWrap: 'wrap',
      div: {
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        borderRadius: '6px',
      },
    },

    '& .result__statistics': {
      padding: '1em',
      boxShadow: `0px 4px 4px ${theme.palette.component.shadowColor}`,
      borderRadius: '5px',

      '@media (max-width: 1000px)': {
        padding: '0',
        paddingTop: '0.5em',
      },

      '& .result__statistics--header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.5em',

        '@media (max-width: 1000px)': {
          paddingLeft: '1em',
        },
      },

      '& .result__statistics--content': {
        display: 'flex',
        '@media (max-width: 1000px)': {
          flexDirection: 'column',
        },

        '& > div': {
          flex: '1',
          gap: '1.5em',
          padding: '1.5em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          '&:not(:first-child)': {
            borderLeft: `1px solid ${theme.palette.component.shadowColor}`,
          },

          '@media (max-width: 1000px)': {
            borderLeft: 'none',
            borderTop: `1px solid ${theme.palette.component.shadowColor}`,
          },

          '& > div:not(.pie-chart)': {
            flex: '1',

            p: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',

              span: {
                fontWeight: 'bolder',
                fontSize: '1.2em',

                '&.success': {
                  color: theme.palette.playColors.lightGreen2,
                },

                '&.error': {
                  color: theme.palette.common.error,
                },

                '&.warning': {
                  color: theme.palette.common.warning,
                },

                '&.primary': {
                  color: theme.palette.secondary.main,
                },
              },
            },
          },
        },
      },

      '& .pie-chart': {
        borderRadius: '50%',
        aspectRatio: '1 / 1',
        position: 'relative',

        '& .pie-chart::after': {
          content: 'attr(data-value)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '1.5em',
          fontWeight: 'bolder',
          textAlign: 'center',
          width: '75%',
          height: '75%',
          backgroundColor: theme.palette.common.white,
          borderRadius: '50%',
        },
      },
    },
  },
}));

export const StyledResumeUploadHeading = styled('div')({
  fontSize: '28px',
  fontWeight: '500',

  '@media screen and (max-width:600px)': {
    fontSize: '18px',
  },
});
