import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from 'react-select';
import {
  ArrowBack,
  CopyAll,
  Delete,
  Edit,
  Launch,
  RestartAlt,
  SortByAlphaOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  AppWrapper,
  NoContent,
  StyledLoader,
  TagListName,
  NoTag,
  TagListHeader,
} from '../Domains/StyledComponents';
import Card from '../Card';
import SearchBox from '../SearchBox';
import {
  deleteUserCompletely,
  getAdminUsersList,
  getUserToken,
  resetUserCompletely,
  updateAdminUser,
} from '../../actions/adminUserManagement';
import config from '../../config';
import DetailsContainerDialog from './DetailsContainerDialog';
import { CustomDialogBox } from '../DialogBox';
import EditUserDialog from './EditUserDialog';

const AdminUserManagement = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(undefined);
  const [userType, setUserType] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const { adminUsersList, isFetchingAdminUsersList } = useSelector(
    (state) => state.adminUserManagement,
  );
  const [filteredRecords, setFilteredRecords] = useState(
    adminUsersList?.user_detail_list,
  );
  const [isAscending, setIsAscending] = useState(true);
  const [openActionWarningDialog, setOpenActionWarningDialog] = useState(false);
  const [openEditUserDataDialog, setOpenEditUserDataDialog] = useState(false);
  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    dispatch(
      getAdminUsersList(searchInput, page + 1, rowsPerPage, userType),
    ).then((data) => {
      setFilteredRecords(data?.user_detail_list);
    });
  }, [searchInput, page, rowsPerPage, userType]);

  const handleSearch = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
  };
  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleLoginAsAdmin = (email, type) => {
    dispatch(getUserToken(email)).then((data) => {
      if (type === 'jobs') {
        window.location.replace(
          `${config.JOBS_URL}/login?secondaryToken=${data.token}&isAdminMode=true`,
        );
      } else {
        localStorage.setItem('secondaryToken', data?.token);
        localStorage.setItem('isAdminMode', true);
        window.location.replace('/l');
      }
    });
  };

  const handleCopyClipboard = (email, type) => {
    dispatch(getUserToken(email)).then((data) => {
      if (type === 'jobs') {
        navigator.clipboard.writeText(
          `${config.JOBS_URL}/login?secondaryToken=${data.token}&isAdminMode=true`,
        );
      } else {
        navigator.clipboard.writeText(
          `${config.WEBSITE_URL}/login?token=${data.token}`,
        );
      }
    });
  };

  const handleSort = () => {
    const sortedRecords = filteredRecords.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return isAscending ? dateA - dateB : dateB - dateA;
    });
    setFilteredRecords(sortedRecords);
    setIsAscending(!isAscending);
  };

  const handleResetUserCompletely = (userId) => {
    dispatch(resetUserCompletely(userId)).then(() => {
      dispatch(
        getAdminUsersList(searchInput, page + 1, rowsPerPage, userType),
      ).then((data) => {
        setFilteredRecords(data?.user_detail_list);
        setOpenActionWarningDialog(false);
      });
    });
  };

  const handleDeleteUserCompletely = (userId) => {
    dispatch(deleteUserCompletely(userId)).then(() => {
      dispatch(
        getAdminUsersList(searchInput, page + 1, rowsPerPage, userType),
      ).then((data) => {
        setFilteredRecords(data?.user_detail_list);
        setOpenActionWarningDialog(false);
      });
    });
  };

  const onEditDetailsSubmit = (data) => {
    dispatch(updateAdminUser(selectedUserData?.id, data)).then(() => {
      dispatch(
        getAdminUsersList(searchInput, page + 1, rowsPerPage, userType),
      ).then((data) => {
        setFilteredRecords(data?.user_detail_list);
        setOpenEditUserDataDialog(false);
      });
    });
  };

  const renderLoader = () => {
    return (
      <NoContent>
        <StyledLoader size={44} />
      </NoContent>
    );
  };

  const renderDegreeList = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <SearchBox value={searchInput} handleSearch={handleSearch} />
          </Grid>
          <Grid item sm={5} xs={12}>
            <Select
              options={[
                { id: undefined, name: 'All' },
                { id: 'Learner', name: 'Learner' },
                { id: 'Employer', name: 'Employer' },
                { id: 'SalesExecutive', name: 'SalesExecutive' },
                { id: 'Admin', name: 'Admin' },
                { id: 'TPO', name: 'TPO' },
              ]}
              onChange={(option) => setUserType(option.id)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={userType ? userType : 'User Type'}
              value={userType}
            />
          </Grid>
          <Grid item sm={1} xs={12}>
            <IconButton
              sx={{
                background: '#25507B20',
                transform: isAscending ? '' : 'scaleX(-1)',
              }}
              onClick={handleSort}
            >
              <SortByAlphaOutlined fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell></TableCell>
              {data?.user_type == 'Admin' && (
                <TableCell align="center"> Login as Admin</TableCell>
              )}
            </TableRow>
          </TableHead>
          {isFetchingAdminUsersList ? (
            renderLoader()
          ) : (
            <TableBody>
              {filteredRecords?.length > 0 ? (
                filteredRecords?.map((list) => (
                  <TableRow key={list.id}>
                    <TableCell>
                      <TagListName>
                        <IconButton
                          onClick={() => {
                            setOpenDetailsDialog(true);
                            setSelectedUserData(list);
                          }}
                        >
                          <Launch fontSize="small" />
                        </IconButton>{' '}
                        {`${list.name}`}
                      </TagListName>
                    </TableCell>
                    <TableCell>{list.email}</TableCell>
                    <TableCell>{list.phone_no || 'NA'}</TableCell>
                    <TableCell>
                      {moment.utc(list?.created_at).fromNow() || 'NA'}
                    </TableCell>
                    <TableCell>{list.user_type}</TableCell>
                    <TableCell>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography padding={'5px'}>
                            Delete User Permanently
                          </Typography>
                        }
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedUserData(list);
                            setOpenActionWarningDialog(true);
                            setActionType('delete');
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography padding={'5px'}>
                            Reset User Details
                          </Typography>
                        }
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedUserData(list);
                            setOpenActionWarningDialog(true);
                            setActionType('reset');
                          }}
                        >
                          <RestartAlt />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography padding={'5px'}>
                            Edit User Details
                          </Typography>
                        }
                      >
                        <IconButton
                          onClick={() => {
                            setOpenEditUserDataDialog(true);
                            setSelectedUserData(list);
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {data?.user_type == 'Admin' && (
                      <TableCell
                        align="right"
                        sx={{
                          minWidth: { xs: '50px', sm: '150px' },
                          display: 'flex',
                        }}
                      >
                        {list.user_type !== 'Employer' && (
                          <>
                            <IconButton
                              onClick={() =>
                                handleCopyClipboard(list.email, 'learning')
                              }
                            >
                              <CopyAll />
                            </IconButton>
                            <Button
                              variant="contained"
                              color={
                                list?.viewed_onboardings?.length
                                  ? 'primary'
                                  : 'error'
                              }
                              onClick={() =>
                                handleLoginAsAdmin(list.email, 'learning')
                              }
                            >
                              Learning
                            </Button>
                          </>
                        )}

                        <IconButton
                          onClick={() =>
                            handleCopyClipboard(list.email, 'jobs')
                          }
                        >
                          <CopyAll />
                        </IconButton>
                        <Button
                          variant="contained"
                          color={
                            list?.viewed_onboardings?.length
                              ? 'primary'
                              : 'error'
                          }
                          onClick={() => handleLoginAsAdmin(list.email, 'jobs')}
                        >
                          Jobs
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <NoTag>No Such list Found </NoTag>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={adminUsersList?.page_size * adminUsersList?.total_pages}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      </div>
    );
  };

  const cardHeader = (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
    >
      <IconButton
        variant="outlined"
        sx={{ width: 'fit-content' }}
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </IconButton>
      <TagListHeader>Users</TagListHeader>
    </div>
  );

  return (
    <Container maxWidth={false}>
      <AppWrapper>
        {cardHeader}
        <Card content={renderDegreeList()} />
      </AppWrapper>
      <DetailsContainerDialog
        open={openDetailsDialog}
        handleClose={() => setOpenDetailsDialog(false)}
        userData={selectedUserData}
      />
      <CustomDialogBox
        actions={
          <>
            <Button
              variant="contained"
              onClick={() => {
                actionType === 'reset'
                  ? handleResetUserCompletely(selectedUserData?.id)
                  : handleDeleteUserCompletely(selectedUserData?.id);
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenActionWarningDialog(false);
              }}
            >
              Cancel
            </Button>
          </>
        }
        open={openActionWarningDialog}
        onClose={() => setOpenActionWarningDialog(false)}
        title={`Are you sure you want to ${
          actionType === 'reset' ? 'reset' : 'delete'
        } the user data?`}
        maxWidth="sm"
        overflowVisible={false}
      >
        {actionType === 'reset'
          ? 'Once the user data is removed, then it cannot be reverted.'
          : ' Once deleted, the user data cannot be reverted.'}
      </CustomDialogBox>
      <EditUserDialog
        open={openEditUserDataDialog}
        onClose={() => setOpenEditUserDataDialog(false)}
        selectedUser={selectedUserData}
        onDetailsSubmit={onEditDetailsSubmit}
      />
    </Container>
  );
};

export default AdminUserManagement;
