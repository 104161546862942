import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormHelperText,
  Hidden,
  Typography,
} from '@mui/material';
import OtpInput from 'react-otp-input';
import otpillustration from './images/otpillustration.svg';
import './index.scss';
import { useSelector } from 'react-redux';

export default function OTPVerifyPrompt({
  timer,
  resendOTP,
  open,
  error,
  onClose,
  onVerify,
  loading,
}) {
  const [otp, setOtp] = useState();
  const { data } = useSelector((state) => state.user);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>OTP Verification</DialogTitle>
      <div className="otpverify__content">
        <div className="otpverify__content-left">
          <OtpInput
            isInputNum
            value={otp}
            numInputs={6}
            shouldAutoFocus
            onChange={setOtp}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: '40%',
              height: 40,
              margin: 5,
              fontSize: 20,
              border: '1px solid #bbb',
              borderRadius: 7,
            }}
          />
          <FormHelperText error={!!error}>{error}</FormHelperText>
          <div className="otpverify__content-left--action">
            {timer > 0 ? (
              <Typography>Resend OTP in {timer}s</Typography>
            ) : (
              <div>
                <Typography>Didn&apos;t receive OTP? </Typography>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={resendOTP}
                >
                  Resend OTP
                </Button>
              </div>
            )}
            <Button
              onClick={() => {
                onVerify(parseInt(otp, 10), 'User', data.id);
                setOtp(undefined);
              }}
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading}
              endIcon={
                loading && <CircularProgress color="inherit" size={15} />
              }
            >
              Verify
            </Button>
          </div>
        </div>
        <Hidden smDown>
          <img
            className="otpverify__illustration"
            src={otpillustration}
            alt="Verify OTP"
          />
        </Hidden>
      </div>
    </Dialog>
  );
}
