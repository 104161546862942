import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Box, Button, Typography, Input } from '@mui/material';

import StoreProductDetailsDialog from './StoreProductDetailsDialog';
import TermsAndConditionsDialog from './TermsAndConditionDialog';
import {
  getUserProductsList,
  getCartList,
  updateCart,
} from '../../actions/userStore';
// import { useStyles } from './StorePageStyles';
import Banner from '../../images/banner.png';
import { NavLink } from 'react-router-dom';
import Gems from '../../images/Gemsnew.svg';
import Gem from '../../images/greyGem.svg';
import Sale from '../../images/Sale.png';
import OrderHistory from '../../images/OrderHistory.svg';
import Cart from '../../images/Cart.svg';
import CartIcon from '../../images/VectorcartIcon.svg';
import InfoIcon from '../../images/infoIcon.svg';

import {
  StylesHeaderImg,
  StylesHeadingGridContainer,
  StylesOrderHistoryText,
  StylesOrderHistoryIcon,
  StylesCard,
  StylesCardMedia,
  StylesCardContent,
  StylesMultiLineEllipsis,
  StylesProductName,
  StylesCartBtn,
  StylesCartBtns,
  StylesDiscountGrid,
  StylesCartBtnGrid,
  StylesDiscountPercent,
  StylesUnderlinePrice,
  StylesDiscountedRate,
  StylesGemImage,
} from './StoreStyledComponent';

const Store = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [dialogProductId, setDialogProductId] = useState('');
  const [enoughGems, setEnoughGems] = useState(true);
  const [productId, setProductId] = useState('');
  const [openTerms, setOpenTerms] = useState(false);
  const [text, setText] = useState('');

  const handleProductBuy = (id) => {
    setOpen(true);
    setDialogProductId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseTermsDialog = () => {
    setOpenTerms(false);
  };

  React.useEffect(() => {
    dispatch(getUserProductsList());
    dispatch(getCartList());
  }, [dispatch]);

  const { userProductsList = [], cartItems } = useSelector(
    (state) => state.userStore,
  );
  const { data } = useSelector((state) => state.user);

  const updateQuantityIncrease = (id) => {
    const arr = [];

    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevData = {
          item_id:
            cartItems.cart[i]?.item_id === id ? id : cartItems.cart[i]?.item_id,
          quantity:
            cartItems.cart[i]?.item_id === id
              ? cartItems.cart[i]?.quantity + 1
              : cartItems.cart[i]?.quantity,
          payment_mode:
            cartItems.cart[i]?.item_id === id &&
            cartItems.cart[i]?.total_amount_in_currency
              ? 'Currency'
              : 'Gems',
        };
        arr.push(prevData);
      }
      dispatch(updateCart(arr));
    } catch (err) {}
  };

  const updateQuantityDecrease = (id) => {
    const arr = [];

    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevData = {
          item_id:
            cartItems.cart[i]?.item_id === id ? id : cartItems.cart[i]?.item_id,
          quantity:
            cartItems.cart[i]?.item_id === id
              ? cartItems.cart[i]?.quantity - 1
              : cartItems.cart[i]?.quantity,
          payment_mode:
            cartItems.cart[i]?.item_id === id &&
            cartItems.cart[i]?.total_amount_in_currency
              ? 'Currency'
              : 'Gems',
        };
        arr.push(prevData);
      }
      dispatch(updateCart(arr));
    } catch (err) {}
  };

  const addGemsItemToCart = (id, gemPrice, discountPrice) => {
    setProductId(id);
    const itemArr = [];
    const currItem = {
      item_id: id,
      quantity: 1,
      payment_mode: 'Gems',
    };
    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevDataItem = {
          item_id: cartItems.cart[i]?.item_id,
          quantity: cartItems.cart[i]?.quantity,
          payment_mode: cartItems.cart[i]?.total_amount_in_currency
            ? 'Currency'
            : 'Gems',
        };
        itemArr.push(prevDataItem);
      }
      itemArr.push(currItem);
      if (discountPrice === 0) {
        if (gemPrice > data.gems) {
          setEnoughGems(false);
        } else {
          dispatch(updateCart(itemArr));
        }
      } else if (discountPrice > data.gems) {
        setEnoughGems(false);
      } else {
        dispatch(updateCart(itemArr));
      }
    } catch (err) {}
  };
  return (
    <Container>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <StylesHeaderImg src={Banner} alt="header_img" />
        </Grid>
        <Grid item container style={{ marginBottom: '16px' }}>
          <Grid item lg={6} md={6} sm={4} xs={5} style={{ marginTop: '32px' }}>
            <Box display="flex" justifyContent="flex-start">
              <StylesHeadingGridContainer style={{ fontWeight: 600 }}>
                {' '}
                Abekus Shop{' '}
              </StylesHeadingGridContainer>
              &nbsp;
              <img
                src={InfoIcon}
                onClick={() => {
                  setOpenTerms(true);
                  setText('shop');
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={5} md={4} sm={6} xs={4} style={{ marginTop: '32px' }}>
            <NavLink to={`/l/orderHistory`}>
              <Box display="flex" justifyContent="flex-end">
                <StylesOrderHistoryIcon src={OrderHistory} />
                <StylesOrderHistoryText>Order History </StylesOrderHistoryText>
              </Box>
            </NavLink>
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={3} style={{ marginTop: '32px' }}>
            <NavLink to={cartItems.cart?.length ? `/l/store/cart` : `/l/store`}>
              <Box display="flex" justifyContent="flex-end">
                <StylesOrderHistoryIcon src={Cart} />
                <StylesOrderHistoryText>
                  Cart{' '}
                  {cartItems.cart?.length
                    ? `(${cartItems.cart?.length})`
                    : `(0)`}
                </StylesOrderHistoryText>
              </Box>
            </NavLink>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          {userProductsList?.map((product) => (
            <Fragment key={product.id}>
              {!product.is_reward && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={12}
                  lg={6}
                  style={{ position: 'relative' }}
                >
                  {product.discount_pct_for_currency !== 0 && (
                    <Box display="flex" justifyContent="flex-end">
                      <Grid item xs={4} style={{ position: 'absolute' }}>
                        <img
                          src={Sale}
                          width="65px"
                          style={{ marginTop: '-6px', marginRight: '6px' }}
                        />
                      </Grid>
                    </Box>
                  )}
                  <StylesCard>
                    <Grid container>
                      <Grid item md={3} sm={12} xs={12}>
                        {/* <StylesCardMedia
                          component="img"
                          image={product.image_url && product.image_url[0]}
                          alt={product.name}
                        /> */}
                        <StylesCardMedia>
                          <img
                            style={{ width: '100%', height: '100%' }}
                            src={product.image_url && product.image_url[0]}
                            alt={product.name}
                          />
                        </StylesCardMedia>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'end',
                        }}
                      >
                        <StylesCardContent>
                          <Grid container>
                            <Grid item xs>
                              <StylesProductName variant="h6">
                                {product.name}
                              </StylesProductName>
                            </Grid>
                          </Grid>
                          <Box flex={1}>
                            <StylesMultiLineEllipsis>
                              {product.item_description}
                            </StylesMultiLineEllipsis>
                          </Box>
                          <Grid container>
                            {cartItems.cart?.find(
                              (items) => items.item?.id === product.id,
                            ) ? (
                              <StylesCartBtnGrid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'end',
                                }}
                              >
                                <Box display="flex" justifyContent="flex-start">
                                  <StylesCartBtns
                                    size="small"
                                    aria-label="small outlined button group"
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        updateQuantityDecrease(product.id)
                                      }
                                    >
                                      -
                                    </Button>
                                    <Grid>
                                      <Input
                                        style={{
                                          background: '#76767F33',
                                          paddingLeft: '5px',
                                          paddingRight: '5px',
                                          border: 'none',
                                        }}
                                        value={
                                          cartItems.cart?.find(
                                            (items) =>
                                              items.item?.id === product.id,
                                          ).quantity
                                        }
                                        disableUnderline
                                        disabled
                                        inputProps={{
                                          style: { textAlign: 'center' },
                                        }}
                                      />
                                    </Grid>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        updateQuantityIncrease(product.id)
                                      }
                                    >
                                      +
                                    </Button>
                                  </StylesCartBtns>
                                </Box>
                              </StylesCartBtnGrid>
                            ) : (
                              <StylesCartBtnGrid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'end',
                                }}
                              >
                                <Box display="flex" justifyContent="flex-start">
                                  <StylesCartBtn
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handleProductBuy(product.id);
                                    }}
                                  >
                                    <img
                                      src={CartIcon}
                                      width="15px"
                                      style={{ color: 'white' }}
                                    />{' '}
                                    &nbsp;&nbsp; Add To Cart
                                  </StylesCartBtn>
                                </Box>
                              </StylesCartBtnGrid>
                            )}
                            <StylesDiscountGrid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                              }}
                            >
                              {product.discount_pct_for_currency !== 0 && (
                                <>
                                  <Box display="flex" justifyContent="flex-end">
                                    <StylesDiscountPercent variant="h6">
                                      <span>
                                        {product.discount_pct_for_currency}% OFF
                                      </span>
                                    </StylesDiscountPercent>
                                  </Box>
                                  <Box display="flex" justifyContent="flex-end">
                                    <StylesUnderlinePrice variant="subtitle2">
                                      <span>
                                        ₹{product.gross_price_in_currency}
                                      </span>
                                      &nbsp;/&nbsp;{product.gross_price_in_gems}{' '}
                                      <img src={Gem} width="10px" />
                                    </StylesUnderlinePrice>
                                  </Box>
                                </>
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                {product.discount_pct_for_currency === 0 ? (
                                  <StylesDiscountedRate variant="subtitle2">
                                    <span style={{ color: '#5FB762' }}>
                                      ₹{product.gross_price_in_currency}
                                    </span>
                                    &nbsp;/&nbsp;{product.gross_price_in_gems}{' '}
                                    <StylesGemImage src={Gems} />
                                  </StylesDiscountedRate>
                                ) : (
                                  <StylesDiscountedRate variant="subtitle2">
                                    <span style={{ color: '#5FB762' }}>
                                      ₹
                                      {
                                        product.gross_price_in_currency_after_disc
                                      }
                                    </span>
                                    &nbsp;/&nbsp;
                                    {
                                      product.gross_price_in_gems_after_disc
                                    }{' '}
                                    <StylesGemImage src={Gems} />
                                  </StylesDiscountedRate>
                                )}
                              </Box>
                            </StylesDiscountGrid>
                          </Grid>
                        </StylesCardContent>
                      </Grid>
                    </Grid>
                  </StylesCard>
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
        <br />
        <Grid item spacing={2} container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <StylesHeadingGridContainer variant="h6">
                {' '}
                Abekus Rewards
              </StylesHeadingGridContainer>
              &nbsp;
              <img
                src={InfoIcon}
                onClick={() => {
                  setOpenTerms(true);
                  setText('rewards');
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          {userProductsList?.map((product) => (
            <Fragment key={product.id}>
              {product.is_reward && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={12}
                  lg={6}
                  style={{ position: 'relative' }}
                >
                  {product.discount_pct_for_gems !== 0 && (
                    <Box display="flex" justifyContent="flex-end">
                      <Grid item xs={4} style={{ position: 'absolute' }}>
                        <img
                          src={Sale}
                          width="65px"
                          style={{ marginTop: '-6px', marginRight: '6px' }}
                        />
                      </Grid>
                    </Box>
                  )}
                  <StylesCard>
                    <Grid container>
                      <Grid item md={3} sm={12} xs={12}>
                        <StylesCardMedia>
                          <img
                            style={{ width: '100%', height: '100%' }}
                            src={product.image_url && product.image_url[0]}
                            alt={product.name}
                          />
                        </StylesCardMedia>
                      </Grid>
                      <Grid
                        item
                        md={9}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'end',
                        }}
                      >
                        <StylesCardContent>
                          <Grid container>
                            <Grid item xs>
                              <StylesProductName variant="h6">
                                {product.name}
                              </StylesProductName>
                            </Grid>
                          </Grid>
                          <Box flex={1}>
                            <StylesMultiLineEllipsis>
                              {product.item_description}
                            </StylesMultiLineEllipsis>
                          </Box>
                          <Grid container>
                            {cartItems.cart?.find(
                              (items) => items.item?.id === product.id,
                            ) ? (
                              <StylesCartBtnGrid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'end',
                                }}
                              >
                                <Box display="flex" justifyContent="flex-start">
                                  <StylesCartBtns
                                    size="small"
                                    aria-label="small outlined button group"
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        updateQuantityDecrease(product.id)
                                      }
                                    >
                                      -
                                    </Button>
                                    <Grid>
                                      <Input
                                        align="center"
                                        style={{
                                          background: '#76767F33',
                                          paddingLeft: '5px',
                                          paddingRight: '5px',
                                          border: 'none',
                                        }}
                                        value={
                                          cartItems.cart?.find(
                                            (items) =>
                                              items.item?.id === product.id,
                                          ).quantity
                                        }
                                        disableUnderline
                                        disabled
                                        inputProps={{
                                          style: { textAlign: 'center' },
                                        }}
                                      />
                                    </Grid>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        updateQuantityIncrease(product.id)
                                      }
                                    >
                                      +
                                    </Button>
                                  </StylesCartBtns>
                                </Box>
                              </StylesCartBtnGrid>
                            ) : (
                              <StylesCartBtnGrid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'end',
                                }}
                              >
                                {!enoughGems && productId === product.id && (
                                  <>
                                    {(product.gross_price_in_gems > data.gems ||
                                      product.gross_price_in_gems_after_disc >
                                        data.gems) && (
                                      <Typography
                                        style={{
                                          color: 'red',
                                        }}
                                      >
                                        Not Enough Gems
                                      </Typography>
                                    )}
                                  </>
                                )}

                                <Box display="flex" justifyContent="flex-start">
                                  <StylesCartBtn
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      addGemsItemToCart(
                                        product.id,
                                        product.gross_price_in_gems,
                                        product.gross_price_in_gems_after_disc,
                                      );
                                    }}
                                  >
                                    <img
                                      src={CartIcon}
                                      width="15px"
                                      style={{ color: 'white' }}
                                    />{' '}
                                    &nbsp;&nbsp; Add To Cart
                                  </StylesCartBtn>
                                </Box>
                              </StylesCartBtnGrid>
                            )}
                            <StylesDiscountGrid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                              }}
                            >
                              {product.discount_pct_for_gems !== 0 && (
                                <>
                                  <Box display="flex" justifyContent="flex-end">
                                    <StylesDiscountPercent variant="h6">
                                      <span>
                                        {product.discount_pct_for_gems}% OFF
                                      </span>
                                    </StylesDiscountPercent>
                                  </Box>
                                  <Box display="flex" justifyContent="flex-end">
                                    <StylesUnderlinePrice variant="subtitle2">
                                      {product.gross_price_in_gems}{' '}
                                      <img src={Gem} width="10px" />
                                    </StylesUnderlinePrice>
                                  </Box>
                                </>
                              )}
                              <Box display="flex" justifyContent="flex-end">
                                {product.discount_pct_for_gems === 0 ? (
                                  <StylesDiscountedRate variant="subtitle2">
                                    {product.gross_price_in_gems}{' '}
                                    <StylesGemImage src={Gems} />
                                  </StylesDiscountedRate>
                                ) : (
                                  <StylesDiscountedRate variant="subtitle2">
                                    {product.gross_price_in_gems_after_disc}{' '}
                                    <StylesGemImage src={Gems} />
                                  </StylesDiscountedRate>
                                )}
                              </Box>
                            </StylesDiscountGrid>
                          </Grid>
                        </StylesCardContent>
                      </Grid>
                    </Grid>
                  </StylesCard>
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
      <StoreProductDetailsDialog
        open={open}
        handleClick={handleClose}
        handleModalSubmit=""
        dialogProductId={dialogProductId}
      />
      <TermsAndConditionsDialog
        openDialog={openTerms}
        closeDialog={handleCloseTermsDialog}
        text={text}
      />
    </Container>
  );
};

export default Store;
