import * as React from 'react';
import { Grid, Box, Divider, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { getUserActivity } from '../../actions/publicProfile';
import BarChart from './BarChart';
import ClockIcon from './img/clock_icon.svg';
import QuestionIcon from './img/question_icon.svg';
import Calender from './Calender';

import {
  ActivityContainer,
  ActivityActionBtnContainer,
  ActivityActionBtn,
  ActivityActiveBtn,
  ActivityCalenderContainer,
  ActivityCalender,
  ActivityOverallActivity,
  ActivityImageContainer,
  ActivityImgText,
  ActivityContainers,
  ActivityText,
  ActivityColorRectContainer,
  ActivityColorShadeContainer,
  ActivityColorRect,
  ActivitySubtitleTextContainer,
  ActivitySubtitleText,
} from './UserProfileStyledComponent';
import { colors, months } from '../../constants/userProfile';

const selectStyles = (theme) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderColor: theme.palette.primary.main,
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? theme.palette.primary.main : 'white',
      color: isSelected ? 'white' : theme.palette.primary.main,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: 'white',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: theme.palette.primary.main }),
  singleValue: (styles) => ({
    ...styles,
    color: theme.palette.primary.main,
    fontWeight: '500',
  }),
  valueContainer: (styles) => ({ ...styles, justifyContent: 'center' }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: theme.palette.primary.main,
    '&:hover': { color: theme.palette.primary.main },
  }),
  menu: (styles) => ({ ...styles, zIndex: 100 }),
});

const numberOfYearsToShow = 10;
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const years = [...Array(numberOfYearsToShow).keys()].map((i) => ({
  label: currentYear - i,
  value: currentYear - i,
}));

const UserActivity = ({ activity, isUserActivityFetching, username }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = React.useState(years[0]);

  let monthIdx = 0;
  let renderMonths = months.slice(0, currentMonth + 1).map((month) => {
    monthIdx += 1;
    return {
      label: `${month} (${currentYear})`,
      value: monthIdx,
      year: currentYear,
    };
  });

  renderMonths = months
    .slice(currentMonth + 1)
    .map((month) => {
      monthIdx += 1;
      return {
        label: `${month} (${currentYear - 1})`,
        value: monthIdx,
        year: currentYear - 1,
      };
    })
    .concat(renderMonths);
  renderMonths.reverse();

  const [selectedMonth, setSelectedMonth] = React.useState(
    renderMonths.find((month) => month.value === new Date().getMonth() + 1),
  );
  const [summaryType, setSummaryType] = React.useState('datewise'); // datewise and monthwise

  React.useEffect(() => {
    let data = {
      summaryType,
      selectedYear: selectedYear?.value || years[0].value,
      username: encodeURIComponent(username),
    };
    if (summaryType === 'datewise') {
      data.selectedMonth = selectedMonth?.value || new Date().getMonth() + 1;
      data.selectedYear = selectedMonth?.year;
    }
    dispatch(getUserActivity(data));
  }, [selectedYear, selectedMonth, summaryType]);

  // For monthwise summaryType
  const activityData = activity?.activity;
  let ActivityMonths;
  if (activityData) {
    ActivityMonths = Object.keys(activityData);
  }
  const BarData = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };
  ActivityMonths?.forEach((month) => {
    if (month === 'overall') return;
    BarData[month] = activityData[month].questions_seen;
  });
  const barBackgroundColors = Object.values(BarData).map((val) => {
    if (val === 0) return colors[0];
    if (val <= 10) return colors[1];
    if (val <= 50) return colors[2];
    if (val <= 100) return colors[3];
    return colors[4];
  });

  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {isUserActivityFetching ? (
        <CircularProgress />
      ) : (
        <ActivityContainer>
          <ActivityActionBtnContainer>
            <ActivityActionBtn
              color={summaryType === 'datewise' ? 'primary' : '#7B7B7B'}
              onClick={() => setSummaryType('datewise')}
            >
              {summaryType === 'datewise' ? (
                <ActivityActiveBtn>Daily</ActivityActiveBtn>
              ) : (
                'Daily'
              )}
            </ActivityActionBtn>

            <Divider orientation="vertical" flexItem />

            <ActivityActionBtn
              color={summaryType === 'monthwise' ? 'primary' : '#7B7B7B'}
              onClick={() => setSummaryType('monthwise')}
            >
              {summaryType === 'datewise' ? (
                <ActivityActiveBtn>Yearly</ActivityActiveBtn>
              ) : (
                'Yearly'
              )}
            </ActivityActionBtn>
          </ActivityActionBtnContainer>

          <ActivityCalenderContainer>
            <ActivityCalender>
              {summaryType === 'datewise' ? (
                <Calender
                  month={selectedMonth.value - 1}
                  year={selectedYear.value}
                  activity={activityData}
                />
              ) : (
                <BarChart
                  data={BarData}
                  backgroundColors={barBackgroundColors}
                  isHorizontal={false}
                  xticks={{
                    autoSkip: false,
                  }}
                  yDisplay={false}
                  maintainAspectRatio={false}
                />
              )}
            </ActivityCalender>
            <ActivityOverallActivity>
              <ActivityContainers>
                <ActivityText color="primary">Avg. Time</ActivityText>
                <ActivityImageContainer>
                  <img
                    src={ClockIcon}
                    alt="clock-icon"
                    width={'30px'}
                    height={'30px'}
                  />
                  <ActivityImgText>{`${Math.floor(
                    activityData?.overall?.avg_time_taken,
                  )}s`}</ActivityImgText>
                </ActivityImageContainer>
              </ActivityContainers>
              <ActivityContainers>
                <ActivityText color="primary">Avg. Accuracy</ActivityText>
                <ActivityImageContainer>
                  <Box
                    style={{
                      borderRadius: '50%',
                      border: '2.5px solid #F1CE55',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                    }}
                  ></Box>
                  <CircularProgress
                    value={activityData?.overall?.accuracy}
                    variant="determinate"
                    size={'30px'}
                  />
                  <ActivityImgText>{`${Math.floor(
                    activityData?.overall?.accuracy,
                  )}%`}</ActivityImgText>
                </ActivityImageContainer>
              </ActivityContainers>
              <ActivityContainers>
                <ActivityText color="primary">Total Questions</ActivityText>
                <ActivityImageContainer>
                  <img
                    src={QuestionIcon}
                    alt="total-ques-icon"
                    width={'30px'}
                    height={'30px'}
                  />
                  <ActivityImgText>{`${Math.floor(
                    activityData?.overall?.questions_seen,
                  )}`}</ActivityImgText>
                </ActivityImageContainer>
              </ActivityContainers>
            </ActivityOverallActivity>
          </ActivityCalenderContainer>
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ActivityColorShadeContainer>
              <ActivityColorRectContainer>
                {[...Array(5).keys()].map((i, index) => (
                  <ActivityColorRect
                    key={index}
                    style={{ background: colors[i] }}
                  />
                ))}
              </ActivityColorRectContainer>
              <ActivitySubtitleTextContainer>
                <ActivitySubtitleText color="text">Less</ActivitySubtitleText>
                <ActivitySubtitleText color="text">More</ActivitySubtitleText>
              </ActivitySubtitleTextContainer>
            </ActivityColorShadeContainer>
            <Grid
              style={{ width: summaryType === 'monthwise' ? '100px' : '130px' }}
            >
              <Select
                options={summaryType === 'monthwise' ? years : renderMonths}
                onChange={(e) =>
                  summaryType === 'monthwise'
                    ? setSelectedYear(e)
                    : setSelectedMonth(e)
                }
                isSearchable={false}
                value={
                  summaryType === 'monthwise' ? selectedYear : selectedMonth
                }
                styles={selectStyles(theme)}
              />
            </Grid>
          </Grid>
        </ActivityContainer>
      )}
    </Grid>
  );
};

export default UserActivity;
