import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import Slide1 from './images/Slide1.png';
import Slide2 from './images/Slide2.png';
import Slide3 from './images/Slide3.png';
import LeftArrow from './images/left.svg';
import RightArrow from './images/right.svg';
import './imageCarousel.css';

const ImageCarousel = ({ setPage }) => {
  const [slideNo, setSlideNo] = useState(1);
  const nextSlide = () => {
    if (slideNo < 3) {
      setSlideNo(slideNo + 1);
    } else {
      setSlideNo(3);
    }
  };

  const previousSlide = () => {
    if (slideNo > 1) {
      setSlideNo(slideNo - 1);
    } else {
      setSlideNo(1);
    }
  };
  return (
    <>
      <div className="img_carousel-voting">
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className={'imgContainer'}
            style={{ textAlign: 'center' }}
          >
            {slideNo === 1 ? (
              <img className={'img_carousel_img'} src={Slide1} />
            ) : slideNo === 2 ? (
              <img className={'img_carousel_img'} src={Slide2} />
            ) : (
              <img className={'img_carousel_img'} src={Slide3} />
            )}
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt="30px">
            {slideNo === 1 ? (
              <span className="img-carousel__heading">
                Help Us Make the Platform Better and Earn Rewards!
              </span>
            ) : slideNo === 2 ? (
              <span className="img-carousel__heading">
                Have a Say in Improving Question Accuracy and Get Rewarded!
              </span>
            ) : (
              <span className="img-carousel__heading">
                Make a Difference and Earn Rewards by Voting!
              </span>
            )}
            {slideNo === 1 ? (
              <span className="img-carousel__description">
                Report to us if you find any incorrect or confusing questions
                and suggest improvements. Your opinion matters, and we&apos;ll
                reward you for it!
              </span>
            ) : slideNo === 2 ? (
              <span className="img-carousel__description">
                Vote on proposed changes to questions to ensure they&apos;re
                accurate. Your vote matters, and we&apos;ll give you rewards for
                participating!
              </span>
            ) : (
              <span className="img-carousel__description">
                Help us improve the quality of questions by voting on proposed
                changes. When your vote matches the majority, we&apos;ll reward
                you as a token of appreciation!
              </span>
            )}

            <div className="slidePositionHolder">
              <div
                className={slideNo === 1 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(1)}
              />
              <div
                className={slideNo === 2 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(2)}
              />
              <div
                className={slideNo === 3 ? 'dot' : 'dotDisabled'}
                onClick={() => setSlideNo(3)}
              />
            </div>
            <div
              className="nextBtnContainer"
              onClick={() => (slideNo === 3 ? setPage(1) : nextSlide())}
            >
              <Button
                variant="contained"
                style={{
                  background: '#3572B0',
                  width: '150px',
                  height: '40px',
                  color: 'white',
                }}
              >
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
        <div className="img-carousel__arrows">
          <img
            src={LeftArrow}
            alt=""
            className="img-carousel__arrowLeft"
            onClick={() => previousSlide()}
            style={{ opacity: slideNo === 1 && 0.1 }}
          />
          <img
            src={RightArrow}
            alt=""
            className="img-carousel__arrowRight"
            onClick={() => nextSlide()}
            style={{ opacity: slideNo === 3 && 0.1 }}
          />
        </div>
      </div>
    </>
  );
};

export default ImageCarousel;
