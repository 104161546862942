import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import './index.css';
import { useEffect } from 'react';

const ImagePickerBlue = ({ imageUrl, onFileLoad }) => {
  const [data, setData] = useState(imageUrl || '');
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  useEffect(() => {
    setData(imageUrl);
    setLoading(false);
  }, [imageUrl]);
  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onloadstart = () => setLoading(true);

      reader.onload = (event) => {
        setData(event.target.result);
        setLoading(false);
      };

      reader.readAsDataURL(files[0]);
      onFileLoad(files[0]);
    }
  };

  const handleClearClick = () => {
    setData(null);
    setFullScreen(false);
    fileInputRef.current.value = '';
  };

  const handlePreviewClick = () => {
    if (!data) {
      return;
    }

    setFullScreen(!fullScreen);
  };

  const backgroundData = data ? { backgroundImage: `url("${data}")` } : null;

  const previewClasses = [
    'preview',
    fullScreen ? 'preview--fullscreen' : '',
  ].join(' ');

  return (
    <div className="image-picker">
      <input
        id="car2"
        type="file"
        accept="image/*"
        capture="camera"
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      <div
        className={previewClasses}
        style={backgroundData}
        onClick={handlePreviewClick}
      >
        {!loading && !data && <label htmlFor="car2">Click to capture</label>}

        {loading && <span>Loading...</span>}
      </div>
      {data && (
        <Button
          variant="contained"
          color="primary"
          className="clear-button"
          onClick={handleClearClick}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default ImagePickerBlue;
