import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLevel, getLevels } from '../../actions/levels';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import {
  TagListName,
  TagListHeader,
  TagListAvatar,
  TagAction,
  NoTag,
} from '../CreateBadge/StyledComponents';

export const CreateLevel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { levelData } = useSelector((state) => state.levels);
  useEffect(() => {
    dispatch(getLevels());
  }, []);

  const handleDeleteLevel = (id) => {
    dispatch(deleteLevel(id));
  };

  const handleEditLevel = (id) => {
    const selectedLevel = levelData.levels?.find((level) => level.id === id);
    navigate(
      {
        pathname: `/l/edit-level/${id}`,
        search: `${id}`,
      },
      {
        state: { selectedLevel },
      },
    );
  };
  return (
    <Container maxWidth={false}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <TagListHeader>Levels</TagListHeader>
        <TagAction>
          <NavLink to="/l/create-new-level">
            <Button color="primary" variant="outlined">
              Add Levels
            </Button>
          </NavLink>
        </TagAction>
      </div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Level Number</TableCell>
              <TableCell>Gem Reward</TableCell>
              <TableCell>Credit Reward</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {levelData.levels?.map((data) => (
              <TableRow key={data.id}>
                <TableCell style={{ cursor: 'pointer' }}>
                  <TagListName>
                    <TagListAvatar alt={data.name} src={data.image_url} />
                    {data.name}
                  </TagListName>
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.number}
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.gem_reward ?? 0}
                </TableCell>
                <TableCell style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {data.credit_reward ?? 0}
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    onClick={() => handleEditLevel(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Delete
                    onClick={() => handleDeleteLevel(data.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {!levelData.levels && <NoTag>No Such tag found </NoTag>}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

export default CreateLevel;
