import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(2),
  },
  formControl: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  halfGrid: {
    flex: 1,
  },
  label: {},
  inputBase: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(4),
  },

  select: {
    marginTop: theme.spacing(1),
  },
  category_select_wrapper: {
    display: 'flex',
  },
  select_category: {
    marginTop: theme.spacing(1),
    flex: 2,
  },
  select_category_percentage: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 4,
    marginLeft: -12,
  },
  previewImage: {
    width: 64,
    height: 64,
  },
  previewHeaderImage: {
    height: 64,
  },
}));
