import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from 'react-infinite-scroll-component';

import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FileCopy from '@mui/icons-material/FileCopy';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AptitudeandGk from './images/aptitude_and_gk.svg';
import CivilEngineering from './images/civil_engineering.svg';
import ComputerScience from './images/computer_science.svg';
import ElectricalEngineering from './images/electrical_engineering.svg';
import ElectronicsEngineering from './images/electronics_engineering.svg';
import EnginneringMathematics from './images/engineering_mathematics.svg';
import MechanicalEngineering from './images/mechanical_engineering.svg';
import NoCompetitions from './images/NoCompetitions.svg';
import HiringEvent from './images/hiringEvent.svg';

import { styles } from './styles';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import {
  StyleActions,
  StyleCard,
  StyleCardContainer,
  StyleCardContent,
  StyleCategoryChip,
  StyleCategoryContainer,
  StyleCompetitionDate,
  StyleCompetitionName,
  StyleDetails,
  StyleMedia,
  StyleParticipatedBadge,
  StyleRegisteredBadge,
  StyleShortDescription,
  StyleToggleBox,
  StyleToggleText,
} from './StyledComponent';

const renderRegistrationTime = (competitionDetails) => {
  if (
    competitionDetails.is_registered &&
    moment().isBetween(
      competitionDetails.start_date,
      competitionDetails.end_date,
    )
  ) {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button variant="contained" color="primary" style={{ width: '100%' }}>
            Contest
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{
              color:
                moment(competitionDetails.end_date).diff(moment(), 'days') <= 2
                  ? '#D60505'
                  : '#76767F',
              marginBottom: '30px',
            }}
          >
            Finishes {moment(competitionDetails.end_date).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (
    competitionDetails.is_registered &&
    moment().isBefore(competitionDetails.start_date)
  ) {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%' }}
            disabled
          >
            Contest
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{
              color:
                moment(competitionDetails.start_date).diff(moment(), 'days') <=
                2
                  ? '#D60505'
                  : '#76767F',
              marginBottom: '30px',
            }}
          >
            starts in {moment(competitionDetails.start_date).fromNow(true)}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (
    competitionDetails.is_registered &&
    moment().isAfter(competitionDetails.end_date)
  ) {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 10, width: '100%', background: '#25507B1A' }}
          >
            View Results
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{ color: '#D60505', marginBottom: '30px' }}
          >
            Ended {moment(competitionDetails.end_date).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (moment().isAfter(competitionDetails.end_date)) {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 10, width: '100%' }}
          >
            View Details
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{ color: '#D60505', marginBottom: '30px' }}
          >
            Ended {moment(competitionDetails.end_date).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (
    !competitionDetails.is_registered &&
    moment().isBetween(
      competitionDetails.reg_start_date,
      competitionDetails.reg_end_date,
    )
  ) {
    return (
      <>
        <Grid container spacing={2} item>
          <Grid item alignItems="center" justifyContent="center" container>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
            >
              Register
            </Button>
          </Grid>
          <Grid item alignItems="center" justifyContent="center" container>
            <Typography
              variant="subtitle2"
              display="block"
              style={{
                color:
                  moment(competitionDetails.reg_end_date).diff(
                    moment(),
                    'days',
                  ) <= 2
                    ? '#D60505'
                    : '#76767F',
                marginBottom: '30px',
              }}
            >
              Registration : &nbsp;
              {moment(competitionDetails.reg_end_date).diff(
                moment(),
                'days',
              )}{' '}
              Days Left
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  } else if (moment().isBefore(competitionDetails.reg_start_date)) {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%' }}
            disabled
          >
            Register
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{
              color:
                moment(competitionDetails.reg_end_date).diff(
                  moment(),
                  'days',
                ) <= 2
                  ? '#D60505'
                  : '#76767F',
              marginBottom: '30px',
            }}
          >
            Registration will start in{' '}
            {moment(competitionDetails.reg_start_date).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2} item>
        <Grid item alignItems="center" justifyContent="center" container>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%' }}
            disabled
          >
            Register
          </Button>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" container>
          <Typography
            variant="subtitle2"
            display="block"
            style={{ color: '#D60505', marginBottom: '30px' }}
          >
            Registrations : Closed
          </Typography>
        </Grid>
      </Grid>
    );
  }
};
const getDomainIconName = (name) => {
  switch (name) {
    case 'Computer Science':
      return ComputerScience;
    case 'Electronics and Communication':
      return ElectronicsEngineering;
    case 'Mechanical Engineering':
      return MechanicalEngineering;
    case 'Civil Engineering':
      return CivilEngineering;
    case 'Electrical Engineering':
      return ElectricalEngineering;
    case 'Aptitude and GK':
      return AptitudeandGk;
    case 'Engineering Mathematics':
      return EnginneringMathematics;
    default:
      return ComputerScience;
  }
};

const SnackbarForCompetition = ({ snackbarProps, setSnackbarProps }) => {
  const { open, severity, message } = snackbarProps;

  const handleSnackbarReset = () => {
    setSnackbarProps({
      message: '',
      open: false,
      severity: 'success',
    });
  };

  return (
    <Snackbar open={open} onClose={handleSnackbarReset} autoHideDuration={5000}>
      <Alert onClose={handleSnackbarReset} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const AlertDialogForCompetition = ({
  alertDialogProps,
  setAlertDialogProps,
  onYes,
}) => {
  const { open, title, content } = alertDialogProps;

  const handleDeleteDialogClose = () => {
    setAlertDialogProps({
      open: false,
      title: '',
      content: '',
    });
  };

  const handleAgree = () => {
    handleDeleteDialogClose();
    onYes();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>No</Button>
          <Button onClick={handleAgree} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Competitions = (props) => {
  const navigate = useNavigate();
  const { competitions, classes, user, auth, domain } = props;
  const [status, setStatus] = useState('ongoing');
  const [page, setPage] = useState(1);
  const [competitionsList, setCompetitionsList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [snackbarProps, setSnackBarProps] = useState(() => ({
    message: '',
    open: false,
    severity: 'success',
  }));
  const [alertDialogProps, setAlertDialogProps] = useState(() => ({
    open: false,
    title: '',
    content: '',
  }));
  const [idToDelete, setIdToDelete] = useState(() => null);

  const location = useLocation();
  const statusRef = useRef('ongoing');
  const userLoggedInRef = useRef(auth.isLoggedOut);

  const handleCompetitionClick = (id) => {
    navigate(`/competition/${id}`);
  };

  const handleEditClick = (id) => {
    navigate(`/l/competition/edit/${id}`);
  };

  const handleDuplicateClick = (id) => {
    navigate(`/l/competition/create?duplicate=true&base_competition=${id}`);
  };

  const handleDeleteClick = (id, numberOfRegistrations) => {
    // Proxy and preprocessing
    if (numberOfRegistrations > 0) {
      setSnackBarProps({
        severity: 'error',
        message: 'Could not delete a competition with active registrations',
        open: true,
      });
      return;
    }
    setIdToDelete(id);
    setAlertDialogProps({
      open: true,
      title: 'Delete this Competition?',
      content:
        'Are you sure you want to delete this competition? This action is irreversible.',
    });
  };

  const handleDeleteCompetition = () => {
    // Success callback
    const targetIndex = competitionsList.findIndex(
      (competition) => competition.id === idToDelete,
    );
    targetIndex !== -1 &&
      setCompetitionsList((oldCompetition) => [
        ...oldCompetition.slice(0, targetIndex),
        ...oldCompetition.slice(targetIndex + 1),
      ]);
    targetIndex !== -1 &&
      setSnackBarProps({
        message: 'Deleted Successfully',
        severity: 'success',
        open: true,
      });
  };

  const deleteCompetition = () => {
    // Dispatching the action
    props.deleteCompetition(
      idToDelete,
      () => {
        handleDeleteCompetition();
      },
      () => {
        setSnackBarProps({
          message: 'Could not delete',
          severity: 'error',
          open: true,
        });
      },
    );
  };

  const handlePaginationChange = () => {
    if (status === statusRef.current) {
      props.getCompetitions(
        {
          domain_id: user?.selected_domain || '',
          page,
          status,
        },
        (newData) => {
          setCompetitionsList((list) => list.concat(newData));
          setFirstLoad(false);
        },
      );
    }
  };

  const handleStatusChange = () => {
    if (status !== statusRef.current) {
      props.getCompetitions(
        {
          page,
          status,
        },
        (newData) => {
          setCompetitionsList(newData);
          statusRef.current = status;
          setFirstLoad(false);
        },
      );
    }
  };

  useEffect(() => {
    handlePaginationChange();
  }, [page]);

  useEffect(() => {
    setPage(1);
    setFirstLoad(true);
    handleStatusChange();
  }, [status]);
  useEffect(() => {
    if (location.state) {
      if (location.state.isOngoing) {
        setStatus('ongoing');
      } else {
        setStatus('finished');
      }
    } else {
      setStatus('ongoing');
    }
  }, []);
  useEffect(() => {
    if (props.auth.isLoggedOut !== userLoggedInRef.current) {
      window.location.reload();
    }
  }, [props.auth.isLoggedOut]);

  const { paginationInfo } = competitions;

  const isAdmin =
    auth &&
    auth.loginSuccess &&
    user &&
    user.data &&
    user.data?.user_type === 'Admin';

  useEffect(() => {
    if (!isAdmin) {
      // Filter out competitions which have not started
      setCompetitionsList(
        competitionsList.filter((record) =>
          moment(record.reg_start_date).isBefore(moment()),
        ),
      );
    }
  }, [isAdmin]);

  return (
    <div
      style={{
        marginTop: '10px',
        paddingTop: '10px',
        overflow: 'auto',
        height: !user?.data?.id ? 'calc(100vh - 120px)' : 'auto',
      }}
      id="scrollableContainer"
    >
      <SnackbarForCompetition
        snackbarProps={snackbarProps}
        setSnackbarProps={setSnackBarProps}
      />
      <AlertDialogForCompetition
        alertDialogProps={alertDialogProps}
        setAlertDialogProps={setAlertDialogProps}
        onYes={deleteCompetition}
      />
      {isAdmin && (
        <Box
          display={'flex'}
          justifyContent="flex-end"
          sx={{ gap: '1rem', padding: '10px' }}
          mb={3}
        >
          <Grid item>
            <Link to="/l/competition_schedule">
              <Button variant="outlined" color="primary">
                Schedule Competition{' '}
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <NavLink to="/l/competition/create">
              <Button variant="outlined" color="primary">
                Add Competition{' '}
              </Button>
            </NavLink>
          </Grid>
        </Box>
      )}

      <StyleToggleBox display="flex" mb={3} justifyContent={'center'}>
        <StyleToggleText
          onClick={() => {
            setStatus('ongoing');
            setPage(1);
          }}
          style={{
            background: status === 'ongoing' && '#25507B',
            color: status === 'ongoing' ? 'white' : 'black',
          }}
        >
          On Going
        </StyleToggleText>
        <StyleToggleText
          onClick={() => {
            setStatus('finished');
            setPage(1);
          }}
          style={{
            background: status === 'finished' && '#25507B',
            color: status === 'finished' ? 'white' : 'black',
          }}
        >
          Finished
        </StyleToggleText>
      </StyleToggleBox>

      {!firstLoad ? (
        <>
          {competitionsList?.length ? (
            <List>
              <InfiniteScroll
                dataLength={competitionsList.length} //This is important field to render the next data
                next={() => setPage((page) => page + 1)}
                hasMore={
                  paginationInfo?.currentPage < paginationInfo?.totalPages
                }
                loader={
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                }
                scrollThreshold={0.9}
                style={{ overflow: 'none' }}
                scrollableTarget="scrollableContainer"
              >
                <StyleCardContainer spacing={3}>
                  {competitionsList.map((competition) => (
                    <Grid
                      // item
                      // lg={4}
                      // md={4}
                      // sm={6}
                      // xs={12}
                      key={competition.id}
                      id={competition.id}
                    >
                      <ListItem
                        disableGutters
                        button
                        onClick={() =>
                          handleCompetitionClick(competition.alias)
                        }
                        dense
                        classes={{ root: classes.listItemRoot }}
                      >
                        <StyleCard variant="outlined">
                          <CardActionArea>
                            <StyleDetails>
                              <StyleCardContent>
                                <Grid container spacing={3}>
                                  <Grid item xs={5}>
                                    <StyleMedia
                                      image={getDomainIconName(
                                        domain.list?.find(
                                          (item) =>
                                            item.id === competition.domain_id,
                                        )?.name,
                                      )}
                                      title="Contemplative Reptile"
                                    />
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <StyleActions>
                                        {competition.is_registered &&
                                          moment().isBefore(
                                            competition.end_date,
                                          ) && (
                                            <StyleRegisteredBadge>
                                              Registered
                                            </StyleRegisteredBadge>
                                          )}
                                        {competition.is_registered &&
                                          moment().isAfter(
                                            competition.end_date,
                                          ) && (
                                            <StyleParticipatedBadge>
                                              Participated
                                            </StyleParticipatedBadge>
                                          )}
                                        {[
                                          'FresherJob',
                                          'Internship',
                                          'ExperiencedJob',
                                        ].includes(
                                          competition.competition_type,
                                        ) && (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'flex-end',
                                              marginTop: '10px',
                                            }}
                                          >
                                            <img
                                              src={HiringEvent}
                                              alt="hiring event"
                                            />
                                          </div>
                                        )}
                                        <Box>
                                          {isAdmin && (
                                            <IconButton
                                              aria-label="delete competition"
                                              className={classes.deleteButton}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleDeleteClick(
                                                  competition.id,
                                                  competition.total_registrations,
                                                );
                                              }}
                                              size="large"
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          )}

                                          {isAdmin && (
                                            <IconButton
                                              aria-label="edit competition"
                                              className={classes.editButton}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleEditClick(competition.id);
                                              }}
                                              size="large"
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          )}
                                          {isAdmin && (
                                            <IconButton
                                              aria-label="duplicate competition"
                                              className={
                                                classes.duplicateButton
                                              }
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleDuplicateClick(
                                                  competition.id,
                                                );
                                              }}
                                              size="large"
                                            >
                                              <FileCopy />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </StyleActions>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <StyleCompetitionDate variant="caption">
                                  {moment(competition.start_date).format(
                                    'Do MMMM YYYY',
                                  )}{' '}
                                  -{' '}
                                  {moment(competition.end_date).format(
                                    'Do MMMM YYYY',
                                  )}
                                </StyleCompetitionDate>
                                <StyleCompetitionName variant="h5" gutterBottom>
                                  {competition.name || ''}
                                  &nbsp;&nbsp;
                                </StyleCompetitionName>
                                <StyleShortDescription
                                  variant="body2"
                                  gutterBottom
                                >
                                  {ReactHtmlParser(
                                    competition.short_description || '',
                                  )}
                                </StyleShortDescription>
                                <StyleCategoryContainer>
                                  {competition.category_names &&
                                    competition.category_names.length &&
                                    competition.category_names
                                      .slice(0, 3)
                                      .map((category) => (
                                        <StyleCategoryChip
                                          key={category}
                                          size="small"
                                          label={category}
                                        />
                                      ))}
                                  {competition.category_names &&
                                    competition.category_names.length &&
                                    competition.category_names.length > 3 && (
                                      <StyleCategoryChip
                                        size="small"
                                        label={`+${competition.category_names
                                          .length - 3}`}
                                      />
                                    )}
                                </StyleCategoryContainer>
                                <Box display={'flex'} justifyContent={'center'}>
                                  {renderRegistrationTime(competition)}
                                </Box>
                              </StyleCardContent>
                            </StyleDetails>
                          </CardActionArea>
                        </StyleCard>
                      </ListItem>
                    </Grid>
                  ))}
                </StyleCardContainer>
              </InfiniteScroll>
            </List>
          ) : (
            <>
              <img
                alt="No competition available"
                src={NoCompetitions}
                style={{
                  width: '460px',
                  margin: 'auto',
                  display: 'block',
                  marginTop: '50px',
                  marginBottom: '50px',
                }}
              />
              <Typography
                align="center"
                variant="h4"
                style={{ color: '#25507B', fontWeight: 600 }}
              >
                Stay tuned for upcoming competitions!
              </Typography>
              <Typography
                align="center"
                variant="subtitle1"
                style={{ color: '#25507BDB' }}
              >
                No live competitions at the moment, but be ready for the next
                challenge!
              </Typography>
            </>
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" mt="20px">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default withStyles(styles)(Competitions);
