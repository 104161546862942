import axios from 'axios';
import * as types from './types';
import config from './../config';
import apiClient from '../api/apiclient';

export function setTimeOut() {
  return {
    type: types.SET_TIME_OUT,
  };
}

export function resetQuestions() {
  return {
    type: types.RESET_QUESTIONS,
  };
}

export function resetQuestionbyId() {
  return {
    type: types.RESET_QUESTION_BY_ID,
  };
}

export function resetQuestionData() {
  return {
    type: types.RESET_QUESTION_DATA,
  };
}

export function setCreateQuestionType({ data }) {
  return {
    type: types.CREATE_QUESTION_TYPE,
    data,
  };
}

export function postQuestionSuccess({ data }) {
  return {
    type: types.POST_QUESTION_SUCCESS,
    data,
  };
}

export function postQuestionFail() {
  return {
    type: types.POST_QUESTION_FAIL,
  };
}

export function getQuestionStart() {
  return {
    type: types.GET_QUESTION_START,
  };
}

export function getQuestionSuccess({ data }) {
  return {
    type: types.GET_QUESTION_SUCCESS,
    data: data,
  };
}

export function getQuestionFail(data) {
  return {
    type: types.GET_QUESTION_FAIL,
    data,
  };
}

export function getUserMilestoneStart() {
  return {
    type: types.GET_USER_MILESTONE_START,
  };
}

export function getUserMilestoneSuccess({ data }) {
  return {
    type: types.GET_USER_MILESTONE_SUCCESS,
    data: data,
  };
}

export function getUserMilestoneFail() {
  return {
    type: types.GET_USER_MILESTONE_FAIL,
  };
}

export function getQuestionStatsStart() {
  return {
    type: types.GET_QUESTION_STATS_START,
  };
}

export function getQuestionStatsSuccess({ data }) {
  return {
    type: types.GET_QUESTION_STATS_SUCCESS,
    data: data,
  };
}

export function getQuestionStatsFail(data) {
  return {
    type: types.GET_QUESTION_STATS_FAIL,
    data,
  };
}

export function getUserMilestone(id) {
  return (dispatch) => {
    dispatch(getUserMilestoneStart());

    apiClient
      .getQuestionApi()
      .questionApiQuestionCreateMilestoneByTag(id)
      .then((resp) => {
        dispatch(getUserMilestoneSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUserMilestoneFail());
      });
  };
}

export function postQuestion(payload) {
  return async (dispatch) => {
    try {
      const res = await apiClient
        .getQuestionApi()
        .questionApiCreateQuestion(payload);

      dispatch(
        postQuestionSuccess({
          data: { ...res.data, domain: payload.domain },
        }),
      );

      return { ...res.data, domain: payload.domain };
    } catch (ex) {
      dispatch(postQuestionFail());
    }
  };
}

export function getQuestionStats(questionId) {
  return (dispatch) => {
    dispatch(getQuestionStatsStart());
    apiClient
      .getQuestionApi()
      .questionApiGetQuestionStatistics(questionId)
      .then((resp) => {
        let payloadData = Array.isArray(resp.data) ? resp?.data[0] : resp.data;
        dispatch(getQuestionStatsSuccess({ data: payloadData }));
      })
      .catch((ex) => {
        const error = ex.response?.data?.error || 'Failed to fetch question';

        dispatch(getQuestionStatsFail(error));
      });
  };
}

export function getQuestion(categoryId) {
  return (dispatch) => {
    dispatch(getQuestionStart());

    apiClient
      .getQuestionApi()
      .questionApiGetUnansweredQuestionByTagCompetition(
        categoryId,
        undefined,
        undefined,
        undefined,
      )
      .then((resp) => {
        let payloadData = Array.isArray(resp.data) ? resp?.data[0] : resp.data;
        dispatch(getQuestionSuccess({ data: payloadData }));
      })
      .catch((ex) => {
        const error = ex.response?.data?.error || 'Failed to fetch question';

        dispatch(getQuestionFail(error));
      });
  };
}

export function getMockTestQuestion(id) {
  return (dispatch) => {
    dispatch(getQuestionStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetUnansweredQuestionByExam(id)
      .then((resp) => {
        dispatch(getQuestionSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        const error = ex.response?.data?.error || 'Failed to fetch question';

        dispatch(getQuestionFail(error));
      });
  };
}

export function getCompetitionQuestion(competitionId) {
  return (dispatch) => {
    dispatch(getQuestionStart());

    apiClient
      .getCompetitionApi()
      .competitionApiCompetitionNextQuestion(competitionId)
      .then((resp) => {
        dispatch(getQuestionSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        const error = ex.response?.data?.error || 'Failed to fetch question';

        dispatch(getQuestionFail(error));
      });
  };
}

export function changeAutoRevealOn() {
  return {
    type: types.CHANGE_AUTOREVEAL_STATUS_ON,
  };
}

export function changeAutoRevealOff() {
  return {
    type: types.CHANGE_AUTOREVEAL_STATUS_OFF,
  };
}

export function changeAutoRevealStatus() {
  return (dispatch, getState) => {
    const { question } = getState();
    if (!question.autoReveal) dispatch(changeAutoRevealOn());
    else dispatch(changeAutoRevealOff());
  };
}

export function changeUnlockSolOn() {
  return {
    type: types.CHANGE_UNLOCKSOL_STATUS_ON,
  };
}

export function changeUnlockSolOff() {
  return {
    type: types.CHANGE_UNLOCKSOL_STATUS_OFF,
  };
}

export function changeUnlockSolStatus() {
  return (dispatch, getState) => {
    const { question } = getState();
    if (!question.autoUnlockSolution) dispatch(changeUnlockSolOn());
    else dispatch(changeUnlockSolOff());
  };
}

export function CorrectAnswerOnSkipOn() {
  return {
    type: types.CORRECT_ANSWER_ON_SKIP_ON,
  };
}

export function CorrectAnswerOnSkipOff() {
  return {
    type: types.CORRECT_ANSWER_ON_SKIP_OFF,
  };
}

export function CorrectAnswerOnSkipStatus() {
  return (dispatch, getState) => {
    const { question } = getState();
    if (!question.showCorrectAnswerOnSkip) dispatch(CorrectAnswerOnSkipOn());
    else dispatch(CorrectAnswerOnSkipOff());
  };
}

export function purchaseTimeWithoutPopupStatusOn() {
  return {
    type: types.PURCHASE_TIME_WITH_POPUP_STATUS_ON,
  };
}

export function purchaseTimeWithoutPopupStatusOff() {
  return {
    type: types.PURCHASE_TIME_WITH_POPUP_STATUS_OFF,
  };
}

export function purchaseTimeWithoutPopupStatus() {
  return (dispatch, getState) => {
    const { question } = getState();
    if (!question.purchaseTimeWithoutPopup)
      dispatch(purchaseTimeWithoutPopupStatusOn());
    else dispatch(purchaseTimeWithoutPopupStatusOff());
  };
}

export function postAnswerStart() {
  return {
    type: types.POST_ANSWER_START,
  };
}

export function postAnswerSuccess({ data }) {
  return {
    type: types.POST_ANSWER_SUCCESS,
    data: data,
  };
}

export function postAnswerFail(data) {
  return {
    type: types.POST_ANSWER_FAIL,
    data,
  };
}

export function postAnswerSilently(question_id, values, callback) {
  return () => {
    apiClient
      .getQuestionApi()
      .questionApiAttemptQuestion(question_id, values)
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        callback?.();
      })
      .catch(() => {});
  };
}

export function postAnswer(question_id, values) {
  return (dispatch) => {
    dispatch(postAnswerStart());
    apiClient
      .getQuestionApi()
      .questionApiAttemptQuestion(question_id, values)
      .then((resp) => {
        dispatch(
          postAnswerSuccess({
            data: resp.data,
          }),
        );
      })
      .catch((ex) => {
        let errText = "Can't post answer";
        if (ex.response?.data?.error)
          errText = ex.response.data.error.replaceAll('_', ' ');

        dispatch(postAnswerFail(errText));
      });
  };
}

export function getStreakStart() {
  return {
    type: types.GET_STREAK_START,
  };
}

export function getStreakSuccess({ data }) {
  return {
    type: types.GET_STREAK_SUCCESS,
    data: data,
  };
}

export function getStreakFail() {
  return {
    type: types.GET_STREAK_FAIL,
  };
}

export function getStreak(categoryId) {
  return (dispatch) => {
    apiClient
      .getTagApi()
      .tagApiStreakProgress(categoryId)
      .then((resp) => {
        dispatch(getStreakSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getStreakFail());
      });
  };
}

export function getReportedQuestionsStart() {
  return {
    type: types.GET_REPORTED_QUESTION_START,
  };
}

export function getReportedQuestionsSuccess({ data }) {
  return {
    type: types.GET_REPORTED_QUESTION_SUCCESS,
    data: data,
  };
}

export function getReportedQuestionsFail() {
  return {
    type: types.GET_REPORTED_QUESTION_FAIL,
  };
}

export function getReportedQuestions({ page, page_size, tag_id }) {
  return (dispatch, getState) => {
    dispatch(getReportedQuestionsStart());
    const { auth } = getState();
    axios
      .get(`${config.API_URL}/question/reported?tag_id=${tag_id}`, {
        params: { page, page_size },
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(getReportedQuestionsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getReportedQuestionsFail());
      });
  };
}

export function getUnderReviewQuestionsStart() {
  return {
    type: types.GET_UNDER_REVIEW_QUESTION_START,
  };
}

export function getUnderReviewQuestionsSuccess({ data }) {
  return {
    type: types.GET_UNDER_REVIEW_QUESTION_SUCCESS,
    data: data,
  };
}

export function getUnderReviewQuestionsFail() {
  return {
    type: types.GET_UNDER_REVIEW_QUESTION_FAIL,
  };
}

export function getUnderReviewQuestions(
  page,
  pageSize,
  domainId,
  sortBy,
  sortOrder,
  tagId,
  question_status = [],
  userId,
  duplicate,
  minDate,
  maxDate,
  questionType,
  withHint,
  withExam,
  successCallback,
) {
  return async (dispatch) => {
    try {
      dispatch(getUnderReviewQuestionsStart());
      const resp = await apiClient
        .getReviewApi()
        .reviewApiGetQuestionUnderreviewList(
          domainId,
          duplicate,
          maxDate,
          minDate,
          page,
          pageSize,
          Array.isArray(question_status)
            ? question_status.join(',')
            : question_status,
          questionType,
          sortBy,
          sortOrder,
          tagId,
          userId,
          withExam,
          withHint,
        );
      dispatch(getUnderReviewQuestionsSuccess({ data: resp.data }));
      successCallback(resp.data);
    } catch (err) {
      dispatch(getUnderReviewQuestionsFail());
    }
  };
}

export function putQuestionStart() {
  return {
    type: types.PUT_QUESTION_START,
  };
}

export function putQuestionSuccess() {
  return {
    type: types.PUT_QUESTION_SUCCESS,
  };
}

export function putQuestionFail() {
  return {
    type: types.PUT_QUESTION_FAIL,
  };
}

export function putQuestion({ data, questionId }) {
  return async (dispatch) => {
    dispatch(putQuestionStart());

    try {
      await apiClient
        .getQuestionApi()
        .questionApiUpdateQuestion(questionId, data);
      dispatch(putQuestionSuccess());
    } catch (ex) {
      dispatch(putQuestionFail());

      throw ex;
    }
  };
}

export function putSolutionRateStart() {
  return {
    type: types.PUT_SOLUTION_RATE_START,
  };
}

export function putSolutionRateSuccess() {
  return {
    type: types.PUT_SOLUTION_RATE_SUCCESS,
  };
}

export function putSolutionRateFail() {
  return {
    type: types.PUT_SOLUTION_RATE_FAIL,
  };
}

export function putRate({ data, questionId }) {
  return async (dispatch) => {
    dispatch(putSolutionRateStart());
    try {
      await apiClient
        .getQuestionApi()
        .questionApiSolutionLikeDislike(questionId, data);
      dispatch(putSolutionRateSuccess());
    } catch (ex) {
      dispatch(putSolutionRateFail());

      throw ex;
    }
  };
}

export function putReviewQuestionStart() {
  return {
    type: types.PUT_REVIEW_QUESTION_START,
  };
}

export function putReviewQuestionSuccess() {
  return {
    type: types.PUT_REVIEW_QUESTION_SUCCESS,
  };
}

export function putReviewQuestionFail() {
  return {
    type: types.PUT_REVIEW_QUESTION_FAIL,
  };
}

export function putReviewQuestion({ questionId, body }) {
  return (dispatch) => {
    dispatch(putReviewQuestionStart());
    apiClient
      .getQuestionApi()
      .questionApiUpdateUnderReviewDetails(questionId, body)
      .then(() => {
        dispatch(putReviewQuestionSuccess());
      })
      .catch(() => {
        dispatch(putReviewQuestionFail());
      });
  };
}

export function putBulkReviewQuestion({ body }) {
  return (dispatch, getState) => {
    dispatch(putReviewQuestionStart());

    const { auth } = getState();
    axios
      .put(`${config.API_URL}/question/bulk_review_status`, body, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(putReviewQuestionSuccess());
      })
      .catch(() => {
        dispatch(putReviewQuestionFail());
      });
  };
}

//get Answered Questions
export function getAnsweredQuestionsStart() {
  return {
    type: types.GET_ANSWERED_QUESTION_START,
  };
}

export function getAnsweredQuestionsSuccess({ data }) {
  return {
    type: types.GET_ANSWERED_QUESTION_SUCCESS,
    data: data,
  };
}

export function getAnsweredQuestionsFail() {
  return {
    type: types.GET_ANSWERED_QUESTION_FAIL,
  };
}

export function getAnsweredQuestions({
  page,
  page_size,
  tag_id,
  bookmark,
  domain_id,
}) {
  return (dispatch) => {
    dispatch(getAnsweredQuestionsStart());
    apiClient
      .getQuestionApi()
      .questionApiGetAnsweredQuestionList(
        undefined,
        domain_id,
        undefined,
        bookmark,
        page,
        page_size,
        undefined,
        tag_id,
      )
      .then((resp) => {
        dispatch(getAnsweredQuestionsSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAnsweredQuestionsFail());
      });
  };
}

//get Answered Questions
export function getAnsweredQuestionByIdStart() {
  return {
    type: types.GET_ANSWERED_QUESTION_BY_ID_START,
  };
}

export function getAnsweredQuestionByIdSuccess({ data }) {
  return {
    type: types.GET_ANSWERED_QUESTION_BY_ID_SUCCESS,
    data: data,
  };
}

export function getAnsweredQuestionByIdFail() {
  return {
    type: types.GET_ANSWERED_QUESTION_BY_ID_FAIL,
  };
}

export function getAnsweredQuestionById({ id }) {
  return (dispatch) => {
    dispatch(getAnsweredQuestionByIdStart());
    apiClient
      .getQuestionApi()
      .questionApiGetAnsweredAttemptById(id)
      .then((resp) => {
        dispatch(getAnsweredQuestionByIdSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getAnsweredQuestionByIdFail());
      });
  };
}

export function getQuestionListStart() {
  return {
    type: types.GET_QUESTION_LIST_START,
  };
}

export function getQuestionListSuccess({ data }) {
  return {
    type: types.GET_QUESTION_LIST_SUCCESS,
    data: data,
  };
}

export function getQuestionListFail() {
  return {
    type: types.GET_QUESTION_LIST_FAIL,
  };
}

export function getQuestionList({ page, page_size, question_status, tag_id }) {
  if (tag_id == 'all') {
    tag_id = '';
  }
  return (dispatch, getState) => {
    dispatch(getQuestionListStart());
    const { auth } = getState();
    axios
      .get(`${config.API_URL}/user/created_questions`, {
        params: {
          page,
          page_size,
          question_status: question_status.join(','),
          // tag_id: tag_id,
          // domain_id: domain_id,
        },
        headers: {
          token: auth.token,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(getQuestionListSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getQuestionListFail());
      });
  };
}

// Get Question By Id
export function getQuestionByIdStart() {
  return {
    type: types.GET_QUESTION_BY_ID_START,
  };
}

export function getQuestionByIdSuccess({ data }) {
  return {
    type: types.GET_QUESTION_BY_ID_SUCCESS,
    data: data,
  };
}

export function getQuestionByIdFail() {
  return {
    type: types.GET_QUESTION_BY_ID_FAIL,
  };
}

export function getQuestionById(questionId) {
  return (dispatch) => {
    dispatch(getQuestionByIdStart());
    apiClient
      .getQuestionApi()
      .questionApiGetQuestionById(questionId, undefined)
      .then((resp) => {
        dispatch(getQuestionByIdSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getQuestionByIdFail());
      });
  };
}

export function getMockTestQuestionById(examId, questionId) {
  return (dispatch) => {
    dispatch(getQuestionByIdStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetUnansweredQuestionByExam(examId, questionId)
      .then((resp) => {
        dispatch(getQuestionByIdSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getQuestionByIdFail());
      });
  };
}

//Get Unasnwered question by Id
export function getUnansweredQuestionByIdStart() {
  return {
    type: types.GET_UNANSWERED_QUESTION_BY_ID_START,
  };
}

export function getUnansweredQuestionByIdSuccess({ data }) {
  return {
    type: types.GET_UNANSWERED_QUESTION_BY_ID_SUCCESS,
    data: data,
  };
}

export function getUnansweredQuestionByIdFail() {
  return {
    type: types.GET_UNANSWERED_QUESTION_BY_ID_FAIL,
  };
}

export function getUnansweredQuestionById(slug) {
  return (dispatch) => {
    dispatch(getUnansweredQuestionByIdStart());
    apiClient
      .getQuestionApi()
      .questionApiGetUnansweredQuestionByQuestionId(undefined, slug)
      .then((resp) => {
        dispatch(getUnansweredQuestionByIdSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getUnansweredQuestionByIdFail());
      });
  };
}

export function getPublicQuestionData(slug) {
  return (dispatch) => {
    apiClient
      .getQuestionApi()
      .questionApiGetPublicQuestionByGroup(slug)
      .then((resp) => {
        dispatch({
          type: types.GET_PUBLIC_QUESTION_SUCCESS,
          payload: resp.data,
        });
      })
      .catch(() => {
        dispatch({
          type: types.GET_PUBLIC_QUESTION_FAIL,
        });
      });
  };
}

// Delete Question
export function deleteQuestionStart() {
  return {
    type: types.DELETE_QUESTION_START,
  };
}

export function deleteQuestionSuccess() {
  return {
    type: types.DELETE_QUESTION_SUCCESS,
  };
}

export function deleteQuestionFail() {
  return {
    type: types.DELETE_QUESTION_FAIL,
  };
}

export function deleteQuestion({ questionId }) {
  return (dispatch) => {
    dispatch(deleteQuestionStart());
    apiClient
      .getQuestionApi()
      .questionApiDeleteQuestion(questionId)
      .then(() => {
        dispatch(deleteQuestionSuccess());
      })
      .catch(() => {
        dispatch(deleteQuestionFail());
      });
  };
}

export function postReportQuestionStart() {
  return {
    type: types.POST_REPORT_QUESTION_START,
  };
}

export function postReportQuestionSuccess({ data }) {
  return {
    type: types.POST_REPORT_QUESTION_SUCCESS,
    data: data,
  };
}

export function postReportQuestionFail() {
  return {
    type: types.POST_REPORT_QUESTION_FAIL,
  };
}

export function postReportQuestion(question_id, reason) {
  return (dispatch) => {
    dispatch(postReportQuestionStart());
    apiClient
      .getQuestionApi()
      .questionApiReportQuestion(question_id, reason)
      .then(() => {
        dispatch(postReportQuestionSuccess({}));
      })
      .catch(() => {
        dispatch(postReportQuestionFail());
      });
  };
}

export function postNewSolutionStart() {
  return {
    type: types.POST_NEW_SOLUTION_START,
  };
}

export function postNewSolutionSuccess({ data }) {
  return {
    type: types.POST_NEW_SOLUTION_SUCCESS,
    data: data,
  };
}

export function postNewSolutionFail() {
  return {
    type: types.POST_NEW_SOLUTION_FAIL,
  };
}

export function postNewSolution(question_id) {
  return (dispatch) => {
    dispatch(postNewSolutionStart());

    apiClient
      .getQuestionApi()
      .questionApiGetSolution(question_id)
      .then(() => {
        dispatch(postNewSolutionSuccess({}));
      })
      .catch(() => {
        dispatch(postNewSolutionFail());
      });
  };
}

export function putReportedQuestionStart() {
  return {
    type: types.PUT_REPORTED_QUESTION_START,
  };
}

export function putReportedQuestionSuccess() {
  return {
    type: types.PUT_REPORTED_QUESTION_SUCCESS,
  };
}

export function putReportedQuestionFail() {
  return {
    type: types.PUT_REPORTED_QUESTION_FAIL,
  };
}

export function putReportedQuestion({ questionId, body }) {
  return (dispatch, getState) => {
    dispatch(putReportedQuestionStart());
    const params = Object.assign({}, body);

    const { auth } = getState();
    axios
      .put(`${config.API_URL}/question/${questionId}/reported/action`, params, {
        headers: {
          token: auth.token,
        },
      })
      .then(() => {
        dispatch(putReportedQuestionSuccess());
      })
      .catch(() => {
        dispatch(putReportedQuestionFail());
      });
  };
}

//PUT Bookmark

export function putBookmarkStart() {
  return {
    type: types.PUT_BOOKMARK_START,
  };
}

export function putBookmarkSuccess({ data }) {
  return {
    type: types.PUT_BOOKMARK_SUCCESS,
    data: data.success,
  };
}

export function putBookmarkFail() {
  return {
    type: types.PUT_BOOKMARK_FAIL,
  };
}

export function putBookmark({ questionId, body }) {
  return (dispatch) => {
    dispatch(putBookmarkStart());

    apiClient
      .getQuestionApi()
      .questionApiBookmarkQuestion(questionId, body)
      .then((resp) => {
        dispatch(putBookmarkSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putBookmarkFail());
      });
  };
}
// PUT Solution Rating
export function putSolutionRatingStart() {
  return {
    type: types.PUT_SOLUTION_RATING_START,
  };
}

export function putSolutionRatingSuccess({ data }) {
  return {
    type: types.PUT_SOLUTION_RATING_SUCCESS,
    data: data,
  };
}

export function putSolutionRatingFail() {
  return {
    type: types.PUT_SOLUTION_RATING_FAIL,
  };
}

export function putSolutionRating({ questionId, body }) {
  return (dispatch) => {
    dispatch(putSolutionRatingStart());
    apiClient
      .getQuestionApi()
      .questionApiSolutionRating(questionId, body)
      .then((resp) => {
        dispatch(putSolutionRatingSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putSolutionRatingFail());
      });
  };
}

//PUT Like and Dislike

export function putLikeStart() {
  return {
    type: types.PUT_LIKE_START,
  };
}

export function putLikeSuccess({ data }) {
  return {
    type: types.PUT_LIKE_SUCCESS,
    data: data,
  };
}

export function putLikeFail() {
  return {
    type: types.PUT_LIKE_FAIL,
  };
}

export function putLike({ questionId, body }) {
  return (dispatch) => {
    dispatch(putLikeStart());
    apiClient
      .getQuestionApi()
      .questionApiQuestionLike(questionId, body)
      .then((resp) => {
        dispatch(putLikeSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putLikeFail());
      });
  };
}

export function putDislikeStart() {
  return {
    type: types.PUT_DISLIKE_START,
  };
}

export function putDislikeSuccess({ data }) {
  return {
    type: types.PUT_DISLIKE_SUCCESS,
    data: data,
  };
}

export function putDislikeFail() {
  return {
    type: types.PUT_DISLIKE_FAIL,
  };
}

export function putDislike({ questionId, body }) {
  return (dispatch) => {
    dispatch(putDislikeStart());
    apiClient
      .getQuestionApi()
      .questionApiQuestionLike(questionId, body)
      .then((resp) => {
        dispatch(putDislikeSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(putDislikeFail());
      });
  };
}

export function getMoreTimeStart() {
  return {
    type: types.GET_MORE_TIME_START,
  };
}

export function getMoreTimeSuccess({ data }) {
  return {
    type: types.GET_MORE_TIME_SUCCESS,
    data: data,
  };
}

export function getMoreTimeFail() {
  return {
    type: types.GET_MORE_TIME_FAIL,
  };
}

export function getMoreTime(data) {
  return (dispatch) => {
    apiClient
      .getCreditsApi()
      .creditsApiUseCredits(data)
      .then((resp) => {
        dispatch(getMoreTimeSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getMoreTimeFail());
      });
  };
}

export function resetPurchaseTime() {
  return {
    type: types.RESET_PURCHASE_TIME,
  };
}

export function uploadImage(formData, compress = true) {
  return async () => {
    const res = await apiClient
      .getFilesApi()
      .filesApiUploadImage(formData, compress);
    return Promise.resolve(res.data);
  };
}

export function getReviewTemplateStart() {
  return {
    type: types.GET_REVIEW_TEMPLATE_START,
  };
}

export function getReviewTemplateSuccess({ data }) {
  return {
    type: types.GET_REVIEW_TEMPLATE_SUCCESS,
    data: data,
  };
}

export function getReviewTemplateFail() {
  return {
    type: types.GET_REVIEW_TEMPLATE_FAIL,
  };
}

export function getReviewTemplate() {
  return (dispatch) => {
    dispatch(getReviewTemplateStart());
    apiClient
      .getReviewApi()
      .reviewApiReviewTemplateApi()
      .then((resp) => {
        dispatch(getReviewTemplateSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(getReviewTemplateFail());
      });
  };
}

export function postReviewTemplateStart() {
  return {
    type: types.POST_REVIEW_TEMPLATE_START,
  };
}

export function postReviewTemplateSuccess({ data }) {
  return {
    type: types.POST_REVIEW_TEMPLATE_SUCCESS,
    data: data.success,
  };
}

export function postReviewTemplateFail() {
  return {
    type: types.POST_REVIEW_TEMPLATE_FAIL,
  };
}

export function postReviewTemplate(body) {
  return (dispatch) => {
    dispatch(postReviewTemplateStart());
    apiClient
      .getReviewApi()
      .reviewApiCreateReviewTemplateApi(body)
      .then((resp) => {
        dispatch(postReviewTemplateSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(postReviewTemplateFail());
      });
  };
}

export function deleteReviewTemplateStart() {
  return {
    type: types.DELETE_REVIEW_TEMPLATE_START,
  };
}

export function deleteReviewTemplateSuccess({ data }) {
  return {
    type: types.DELETE_REVIEW_TEMPLATE_SUCCESS,
    data: data.success,
  };
}

export function deleteReviewTemplateFail() {
  return {
    type: types.DELETE_REVIEW_TEMPLATE_FAIL,
  };
}

export function deleteReviewTemplate(id) {
  return (dispatch) => {
    dispatch(deleteReviewTemplateStart());
    apiClient
      .getReviewApi()
      .reviewApiDeleteReviewTemplateApi(id)
      .then((resp) => {
        dispatch(deleteReviewTemplateSuccess({ data: resp.data }));
      })
      .catch(() => {
        dispatch(deleteReviewTemplateFail());
      });
  };
}

export function getVotingReportQuestion(selected_domain) {
  return (dispatch, getState) => {
    const { auth } = getState();

    axios
      .get(
        `${config.API_URL}/domain/${selected_domain}/review/get_review_report`,
        {
          headers: {
            token: auth.token,
          },
        },
      )
      .then((res) => {
        dispatch({
          type: types.GET_VOTING_REPORT_QUESTION_SUCCESS,
          data: res.data?.data,
        });
      })
      .catch(() => {
        dispatch({
          type: types.GET_VOTING_REPORT_QUESTION_FAIL,
        });
      });
  };
}

function unlockQuestionStart() {
  return {
    type: types.UNLOCK_QUESTION_START,
  };
}

function unlockQuestionSuccess(data, unlockQuestionCredits) {
  return {
    type: types.UNLOCK_QUESTION_SUCCESS,
    payload: { data, unlockQuestionCredits },
  };
}

function unlockQuestionFail() {
  return {
    type: types.UNLOCK_QUESTION_FAIL,
  };
}

export function unlockSolution(question_id) {
  return (dispatch, getState) => {
    dispatch(unlockQuestionStart());
    const { credits } = getState();
    apiClient
      .getQuestionApi()
      .questionApiGetSolution(question_id)
      .then((resp) => {
        dispatch(
          unlockQuestionSuccess(resp.data, credits.costData?.BuySolution),
        );
      })
      .catch(() => {
        dispatch(unlockQuestionFail());
      });
  };
}

function getExamAnalyticsStart() {
  return {
    type: types.GET_EXAM_ANALYTICS_START,
  };
}

function getExamAnalyticsSuccess(data) {
  return {
    type: types.GET_EXAM_ANALYTICS_SUCCESS,
    data: data,
  };
}

function getExamAnalyticsFail() {
  return {
    type: types.GET_EXAM_ANALYTICS_FAIL,
  };
}

export function updateExamAnalytics(data) {
  return {
    type: types.SET_EXAM_ANALYTICS,
    data: data,
  };
}

export function getVotedReportQuestion(domain_id) {
  return (dispatch) => {
    apiClient
      .getReviewApi()
      .reviewApiGetSelfReviewingHistory(
        domain_id,
        undefined,
        undefined,
        undefined,
      )
      .then((res) => {
        dispatch({
          type: types.GET_VOTED_REPORT_QUESTION_SUCCESS,
          data: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: types.GET_VOTED_REPORT_QUESTION_FAIL,
        });
      });
  };
}

export function postVotingReportQuestion(report_id, review_type) {
  return (dispatch) => {
    apiClient
      .getReviewApi()
      .reviewApiReviewReportedQuestion({
        report_id: report_id,
        review_type: review_type,
      })
      .then(() => {
        dispatch({
          type: types.POST_VOTING_REPORT_QUESTION_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: types.POST_VOTING_REPORT_QUESTION_FAIL,
        });
      });
  };
}

export function getExamAnalytics(id) {
  return (dispatch) => {
    dispatch(getExamAnalyticsStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetOngoingAnalytics(id)
      .then((resp) => {
        dispatch(getExamAnalyticsSuccess(resp.data));
      })
      .catch(() => {
        dispatch(getExamAnalyticsFail());
      });
  };
}

export function getExamResultAnalytics(id) {
  return (dispatch) => {
    dispatch(getExamAnalyticsStart());
    apiClient
      .getMockTestApi()
      .mocktestApiGetMocktestAnalytics(id)
      .then((resp) => {
        dispatch(getExamAnalyticsSuccess(resp.data));
      })
      .catch(() => {
        dispatch(getExamAnalyticsFail());
      });
  };
}

function getHintStart() {
  return {
    type: types.GET_QUESTION_HINT_START,
  };
}

function getHintSuccess(data, buyHintCredits) {
  return {
    type: types.GET_QUESTION_HINT_SUCCESS,
    payload: { data, buyHintCredits },
  };
}

function getHintFail() {
  return {
    type: types.GET_QUESTION_HINT_FAIL,
  };
}

export function getHint(question_id) {
  return (dispatch, getState) => {
    dispatch(getHintStart());
    const { credits } = getState();
    apiClient
      .getQuestionApi()
      .questionApiGetQuestionHint(question_id)
      .then((resp) => {
        dispatch(getHintSuccess(resp.data, credits.costData?.BuyHint));
      })
      .catch(() => {
        dispatch(getHintFail());
      });
  };
}
