import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const user = createReducer(
  {
    userLoginStreakData: {},
    allUsers: [],
    isFetchingAllUsers: false,
    data: {},
    analytics: {},
    questions: [],
    bookmark: false,
    bookmarkedQuestions: [],
    isFetchingBookmarkedQuestions: false,
    questionPaginationInfo: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 1,
    },
    attemptedQuestion: {},
    referrals: [],
    referralPaginationInfo: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 1,
    },
    error: '',
    current_competition: {},
    isOTPSendInProgress: false,
    isOTPVerifyInProgress: false,
    OTPError: '',
    isMetadataSendInProgress: false,
    isMetadataSendSuccess: false,
    resume_url: '',
    isUpdatingPasswordSuccess: false,
    userProfilePicture: [],
    userProfileMilestone: {},
  },
  {
    [types.GET_ALL_USERS_START](state) {
      const newState = Object.assign({}, state, {
        allUsers: {},
        isFetchingAllUsers: true,
      });

      return newState;
    },
    [types.GET_ALL_USERS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        allUsers: action.data,
        isFetchingAllUsers: false,
      });

      return newState;
    },
    [types.GET_ALL_USERS_FAIL](state) {
      const newState = Object.assign({}, state, {
        allUsers: {},
        isFetchingAllUsers: false,
      });

      return newState;
    },
    [types.GET_USER_ANALYTICS_START](state) {
      const newState = Object.assign({}, state, {
        analytics: {},
        isFetchingUserAnalytics: true,
        isAnalyticsFetchSuccess: false,
      });

      return newState;
    },
    [types.GET_USER_ANALYTICS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        analytics: action.data,
        isFetchingUserAnalytics: false,
        isAnalyticsFetchSuccess: true,
      });

      return newState;
    },
    [types.GET_USER_ANALYTICS_FAIL](state) {
      const newState = Object.assign({}, state, {
        analytics: {},
        isFetchingUserAnalytics: false,
      });

      return newState;
    },
    [types.PUT_SAVE_STREAK_START](state) {
      return {
        ...state,
        isUpdatingStreak: true,
      };
    },

    [types.PUT_SAVE_STREAK_SUCCESS](state, action) {
      return {
        ...state,
        credits: action.data.data.new_credits,
        isUpdatingStreak: false,
      };
    },
    [types.PUT_SAVE_STREAK_FAIL](state) {
      return {
        ...state,
        isUpdatingStreak: false,
      };
    },
    [types.UNLOCK_CATEGORY_SUCCESS](state, action) {
      return {
        ...state,
        credits: action.payload?.remaining_credits,
      };
    },
    [types.GET_USER_PROFILE_START](state) {
      const newState = Object.assign({}, state, {
        data: {},
        isFetchingUserInfo: true,
        isUserFetchSuccess: false,
      });

      return newState;
    },

    [types.GET_USER_PROFILE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        data: action.data,
        credits: action.data.credits,
        gems: action.data.gems,
        isFetchingUserInfo: false,
        isUserFetchSuccess: true,
        selected_domain: action.data.selected_domain,
      });

      return newState;
    },
    [types.GET_USER_PROFILE_FAIL](state) {
      const newState = Object.assign({}, state, {
        data: {},
        isFetchingUserInfo: false,
      });

      return newState;
    },
    [types.PUT_USER_PROFILE_START](state) {
      return {
        ...state,
        isUpdatingUserInfo: true,
        isUpdatingUserInfoSuccess: false,
        isUpdatingUserInfoFail: false,
      };
    },

    [types.PUT_USER_PROFILE_SUCCESS](state, action) {
      return {
        ...state,
        data: action.data,
        isUpdatingUserInfo: false,
        isUpdatingUserInfoSuccess: true,
      };
    },
    [types.GET_USER_ACTIVITY_START](state) {
      return {
        ...state,
        isUserActivityFetching: true,
        isUpdatingUserInfoSuccess: false,
      };
    },
    [types.PUT_USER_PROFILE_FAIL](state) {
      return {
        ...state,
        isUpdatingUserInfo: false,
        isUpdatingUserInfoFail: true,
      };
    },
    [types.RESET_PASSWORD_START](state) {
      return {
        ...state,
        isUpdatingPassword: true,
        isUpdatingPasswordSuccess: false,
        isUpdatingPasswordFail: false,
      };
    },

    [types.RESET_PASSWORD_SUCCESS](state, action) {
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatingPasswordSuccess: true,
        error: action.data.error,
      };
    },
    [types.RESET_PASSWORD_FAIL](state) {
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatingPasswordFail: true,
      };
    },
    [types.GET_PUBLIC_PROFILE_SUCCESS](state) {
      return {
        ...state,
        isUpdatingPasswordSuccess: false,
      };
    },
    [types.POST_ANSWER_SUCCESS](state, action) {
      if (!action.data) return { ...state };

      const attemptScore = action.data.attempt_score;
      const selected_domain = state.selected_domain;
      const currentScore = state.analytics.domains[selected_domain]?.score || 0;

      return {
        ...state,
        analytics: {
          ...state.analytics,
          overall: {
            ...state.analytics.overall,
            score: action.data.progress_obj.overall_score,
          },
          domains: {
            ...state.analytics.domains,
            [selected_domain]: {
              ...state.analytics.domains[selected_domain],
              score: currentScore + attemptScore,
            },
          },
        },
        current_competition: {
          ...state.current_competition,
          overall_score: action.data.progress_obj.competition_overall_score,
        },
      };
    },
    [types.GET_ANSWERED_QUESTION_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingQuestions: true,
      });

      return newState;
    },
    [types.GET_ANSWERED_QUESTION_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingQuestions: false,
        questions: action.data.all_attempts,
        questionPaginationInfo: {
          currentPage: action.data.current_page - 1, // zero-based
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
      });

      return newState;
    },
    [types.GET_ANSWERED_QUESTION_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingQuestions: false,
      });

      return newState;
    },
    [types.UNLOCK_QUESTION_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        credits: state.credits - action.payload.unlockQuestionCredits,
        questions: state.questions.map((attempt) =>
          attempt.question.id === action.payload?.data.question_id
            ? {
                ...attempt,
                is_solution_unlocked: true,
                question: {
                  ...attempt.question,
                  solution_text: action.payload?.data.solution_text,
                },
              }
            : attempt,
        ),
        attemptedQuestion: state.attemptedQuestion
          ? {
              ...state.attemptedQuestion,
              is_solution_unlocked: true,
              question: {
                ...state.attemptedQuestion.question,
                solution_text: action.payload?.data.solution_text,
              },
            }
          : {},
      });

      return newState;
    },
    [types.GET_ANSWERED_QUESTION_BY_ID_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingAttemptedQuestion: true,
        attemptedQuestion: {},
      });

      return newState;
    },
    [types.GET_ANSWERED_QUESTION_BY_ID_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingAttemptedQuestion: false,
        attemptedQuestion: action.data,
      });

      return newState;
    },
    [types.GET_ANSWERED_QUESTION_BY_ID_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingAttemptedQuestion: false,
      });

      return newState;
    },

    [types.PUT_BOOKMARK_START](state) {
      const newState = Object.assign({}, state, {
        bookmark: false,
      });

      return newState;
    },
    [types.PUT_BOOKMARK_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        bookmark: true,
      });

      return newState;
    },
    [types.PUT_BOOKMARK_FAIL](state) {
      const newState = Object.assign({}, state, {
        bookmark: false,
      });

      return newState;
    },
    [types.GET_USER_REFERRALS_START](state) {
      const newState = Object.assign({}, state, {
        referrals: [],
        isFetchingUserReferrals: true,
        isReferralsFetchSuccess: false,
      });

      return newState;
    },
    [types.GET_USER_REFERRALS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        referrals: action.data,
        isFetchingUserReferrals: false,
        isReferralsFetchSuccess: true,
        referralPaginationInfo: {
          currentPage: action.data.current_page - 1, // zero-based
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
      });

      return newState;
    },
    [types.GET_USER_REFERRALS_FAIL](state) {
      const newState = Object.assign({}, state, {
        referrals: [],
        isFetchingUserReferrals: false,
      });

      return newState;
    },
    [types.GET_DAILY_STATISTICS_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingDailyStatistics: true,
        statistics: {},
      });

      return newState;
    },
    [types.GET_DAILY_STATISTICS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingDailyStatistics: false,
        statistics: action.data.data,
      });

      return newState;
    },
    [types.GET_DAILY_STATISTICS_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingDailyStatistics: false,
      });

      return newState;
    },
    [types.GET_USERNAME_AVAILABILITY_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingUsernameAvailability: true,
        isUsernameAvailableSuccess: false,
        isUsernameAvailableFail: false,
        isUsernameAvailable: false,
      });

      return newState;
    },
    [types.GET_USERNAME_AVAILABILITY_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingUsernameAvailability: false,
        isUsernameAvailableSuccess: true,
        isUsernameAvailable: action.data.is_available,
      });

      return newState;
    },
    [types.GET_USERNAME_AVAILABILITY_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingUsernameAvailability: false,
        isUsernameAvailableFail: true,
      });

      return newState;
    },
    [types.RESET_USERNAME_AVAILABILITY](state) {
      const newState = Object.assign({}, state, {
        isFetchingUsernameAvailability: false,
        isUsernameAvailableFail: false,
        isUsernameAvailableSuccess: false,
        isUsernameAvailable: false,
      });

      return newState;
    },

    [types.CHANGE_DOMAIN_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        data: {
          ...state.data,
          selected_domain: action.domainId,
        },
        selected_domain: action.domainId,
      });

      return newState;
    },
    [types.GET_MORE_TIME_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        credits: action.data.remaining_credits,
      });

      return newState;
    },
    [types.GET_QUESTION_HINT_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        credits: state.credits - action.payload.buyHintCredits,
      });

      return newState;
    },
    [types.CREATE_MOCK_TEST_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        credits: state.credits - 100,
      });

      return newState;
    },
    [types.POST_MOCK_TEST_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        credits: state.credits - action.data.cost_in_credits,
      });

      return newState;
    },
    [types.CREATE_REFERRALS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isSubmittingReferrals: true,
        createReferralSuccess: false,
        createReferralFail: false,
      });

      return newState;
    },
    [types.CREATE_REFERRALS_SUCCESS](state, action) {
      return {
        ...state,
        isSubmittingReferrals: false,
        createReferralSuccess: true,
        existingUsers: action.data?.existing_users.length,
        totalInvitedUsers: action.invited,
      };
    },
    [types.CREATE_REFERRALS_FAIL](state) {
      return {
        ...state,
        isSubmittingReferrals: false,
        createReferralFail: true,
      };
    },
    [types.RESET_REFERRALS](state) {
      return {
        ...state,
        isSubmittingReferrals: false,
        createReferralFail: false,
        createReferralSuccess: false,
        existingUsers: null,
        totalInvitedUsers: null,
      };
    },
    [types.SEND_OTP_START](state) {
      return {
        ...state,
        OTPError: '',
        isOTPSendSuccess: false,
        isOTPSendInProgress: true,
      };
    },
    [types.SEND_OTP_SUCCESS](state, action) {
      return {
        ...state,
        OTPError: '',
        otpSentNumber: action.data,
        isOTPSendInProgress: false,
        isOTPSendSuccess: true,
      };
    },
    [types.SEND_OTP_FAIL](state, action) {
      return {
        ...state,
        isOTPSendInProgress: false,
        isOTPSendSuccess: false,
        OTPError: action.data,
      };
    },
    [types.VERIFY_OTP_START](state) {
      return {
        ...state,
        OTPError: '',
        isOTPVerifyInProgress: true,
        data: {
          ...state.data,
          is_phone_verified: false,
        },
      };
    },
    [types.VERIFY_OTP_SUCCESS](state) {
      return {
        ...state,
        OTPError: '',
        isOTPVerifyInProgress: false,
        data: {
          ...state.data,
          phone_number: state.otpSentNumber.phone_no,
          is_phone_verified: true,
        },
      };
    },
    [types.VERIFY_OTP_FAIL](state, action) {
      return {
        ...state,
        isOTPVerifyInProgress: false,
        data: {
          ...state.data,
          is_phone_verified: false,
        },
        OTPError: action.data,
      };
    },
    [types.SEND_METADATA_START](state) {
      return {
        ...state,
        error: '',
        isMetadataSendInProgress: true,
      };
    },
    [types.SEND_METADATA_SUCCESS](state) {
      return {
        ...state,
        error: '',
        isMetadataSendInProgress: false,
        isMetadataSendSuccess: true,
      };
    },
    [types.SEND_METADATA_FAIL](state, action) {
      return {
        ...state,
        isMetadataSendInProgress: false,
        isMetadataSendSuccess: false,
        error: action.data,
      };
    },
    [types.UPLOAD_RESUME_START](state) {
      return {
        ...state,
        isResumeUploadingInProgress: true,
        isResumeUploadingSuccess: true,
      };
    },
    [types.UPLOAD_RESUME_SUCCESS](state, action) {
      return {
        ...state,
        isResumeUploadingInProgress: false,
        isResumeUploadingSuccess: true,
        resume_url: action.data,
      };
    },
    [types.UPLOAD_RESUME_FAIL](state, action) {
      return {
        ...state,
        isResumeUploadingInProgress: false,
        isResumeUploadingSuccess: false,
        error: action.error,
      };
    },
    [types.DELETE_RESUME](state) {
      return {
        ...state,
        resume_url: '',
      };
    },
    [types.GET_RESUME_DETAILS_START](state) {
      return {
        ...state,
        isResumeFetchingInProgress: true,
        isResumeFetchingSuccess: true,
      };
    },
    [types.GET_RESUME_DETAILS_SUCCESS](state, action) {
      return {
        ...state,
        isResumeFetchingInProgress: false,
        isResumeFetchingSuccess: true,
        resume_url: action.data?.candidate_meta_details?.resume_url || '',
      };
    },
    [types.GET_RESUME_DETAILS_FAIL](state, action) {
      return {
        ...state,
        isResumeFetchingInProgress: false,
        isResumeFetchingSuccess: false,
        error: action.error,
      };
    },
    [types.POST_REGISTRATION_SUCCESS](state, action) {
      return {
        ...state,
        credits: state.credits - action.payload.credits_consumed,
      };
    },
    [types.CLAIM_REWARDS](state, action) {
      return {
        ...state,
        credits: state.credits + action.payload.credits,
        gems: state.gems + action.payload.gems,
      };
    },
    [types.UPLOAD_USER_PROFILE_PICTURE_START](state) {
      return {
        ...state,
        isUploadingProfileImage: true,
      };
    },
    [types.UPLOAD_USER_PROFILE_PICTURE_SUCCESS](state, action) {
      return {
        ...state,
        isUploadingProfileImage: false,
        userProfilePicture: action.data,
      };
    },
    [types.UPLOAD_USER_PROFILE_PICTURE_FAIL](state, action) {
      return {
        ...state,
        isUploadingProfileImage: false,
        error: action.error,
      };
    },
    [types.PUT_USER_PROFILE_DATA_START](state) {
      return {
        ...state,
        isUpdatingData: true,
      };
    },
    [types.PUT_USER_PROFILE_DATA_SUCCESS](state) {
      return {
        ...state,
        isUpdatingData: false,
      };
    },
    [types.PUT_USER_PROFILE_DATA_FAIL](state) {
      return {
        ...state,
        isUpdatingData: false,
      };
    },

    [types.GET_USER_LOGIN__STREAK_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserLoginStreakList: true,
      });

      return newState;
    },
    [types.GET_USER_LOGIN__STREAK_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        userLoginStreakData: action.data,
        isFetchingUserLoginStreakList: false,
      });

      return newState;
    },
    [types.GET_USER_LOGIN__STREAK_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserLoginStreakList: false,
      });

      return newState;
    },
    [types.GET_USER_PROFILE_MILESTONE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserProfileMilestoneList: true,
        s3ImgUrl: '',
      });

      return newState;
    },
    [types.GET_USER_PROFILE_MILESTONE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        userProfileMilestone: action.data,
        isFetchingUserProfileMilestoneList: false,
      });

      return newState;
    },
    [types.GET_USER_PROFILE_MILESTONE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingUserProfileMilestoneList: false,
      });

      return newState;
    },

    [types.PUT_USER_PROFILE_UPDATE_START](state) {
      return {
        ...state,
        isUpdatingUserProfile: true,
      };
    },

    [types.PUT_USER_PROFILE_UPDATE_SUCCESS](state) {
      return {
        ...state,
        isUpdatingUserProfile: false,
      };
    },
    [types.PUT_SAVE_STREAK_FAIL](state) {
      return {
        ...state,
        isUpdatingUserProfile: false,
      };
    },
    [types.GET_MANAGEMENT_API_PENDING_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingPendingEntries: true,
      });

      return newState;
    },
    [types.GET_MANAGEMENT_API_PENDING_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        pendingEntries: action.data,
        isFetchingPendingEntries: false,
      });

      return newState;
    },
    [types.GET_MANAGEMENT_API_PENDING_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingPendingEntries: false,
      });

      return newState;
    },
  },
);

export { user as default };
