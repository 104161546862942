import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DomainChangeDialog({
  visible = false,
  dialogTitleMessage,
  onConfirm,
  onClose,
}) {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="domain-change-dialog"
      aria-describedby="Domain Change Confirmation"
    >
      <DialogTitle id="alert-dialog-title">
        {/* Do you really want to change the Domain? */}
        {dialogTitleMessage}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Selected Categories do not match the selected domain. If you change
          the domain, all categories will be removed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
