import {
  CLEAR_COMMENT_REPORTS_STATE,
  GET_COMMENT_REPORTS_FAIL,
  GET_COMMENT_REPORTS_START,
  GET_COMMENT_REPORTS_SUCCESS,
  GET_ALL_COMMENT_FAIL,
  GET_ALL_COMMENT_START,
  GET_ALL_COMMENT_SUCCESS,
  CLEAR_ALL_COMMENT_STATE,
  REVIEW_COMMENT_FAIL,
  REVIEW_COMMENT_START,
  REVIEW_COMMENT_SUCCESS,
} from './types';
import axios from 'axios';
import config from '../config';
import apiClient from '../api/apiclient';

export const getCommentReportsStart = () => {
  return {
    type: GET_COMMENT_REPORTS_START,
  };
};
export const getCommentReportsSuccess = ({ data }) => {
  return {
    type: GET_COMMENT_REPORTS_SUCCESS,
    data,
  };
};

export const getCommentReportsFail = ({ data }) => {
  return {
    type: GET_COMMENT_REPORTS_FAIL,
    data,
  };
};

export const getAllCommentStart = () => {
  return {
    type: GET_ALL_COMMENT_START,
  };
};

export const getAllCommentSuccess = ({ data }) => {
  return {
    type: GET_ALL_COMMENT_SUCCESS,
    data,
  };
};

export const getAllCommentFail = ({ data }) => {
  return {
    type: GET_ALL_COMMENT_FAIL,
    data,
  };
};

export const getCommentReports = (
  pageNumber,
  pageSize,
  sortOrder,
  sortBy,
  reportType,
  successCallback,
  failCallback,
) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const params = {
      page: pageNumber,
      page_size: pageSize,
      sort_order: sortOrder,
      sort_by: sortBy,
      report_type: reportType,
    };
    try {
      dispatch(getCommentReportsStart());
      const response = await axios.get(
        `${config.API_URL}/admin/comment/unresolved-reports`,
        {
          headers: {
            token: auth.token,
          },
          params: params,
        },
      );
      dispatch(
        getCommentReportsSuccess({
          data: response.data,
        }),
      );
      successCallback(response.data.reported_comments);
    } catch (err) {
      dispatch(
        getCommentReportsFail({
          data: err,
        }),
      );
      failCallback?.();
    }
  };
};

export const getAllComment = (
  commentType,
  startDate,
  endDate,
  sortBy,
  sortOrder,
  nameQuery,
  pageNumber,
  pageSize,
  successCallback,
  failCallback,
) => {
  return async (dispatch) => {
    try {
      dispatch(getAllCommentStart());
      const response = await apiClient
        .getDiscussionApi()
        .discussionApiGetAllComments(
          commentType,
          endDate,
          nameQuery,
          pageNumber,
          pageSize,
          sortBy,
          sortOrder,
          startDate,
        );
      dispatch(
        getAllCommentSuccess({
          data: response.data,
        }),
      );
      successCallback(response.data.all_comments);
    } catch (err) {
      dispatch(
        getAllCommentFail({
          data: err,
        }),
      );
      failCallback?.();
    }
  };
};

export const reviewCommentStart = () => {
  return {
    type: REVIEW_COMMENT_START,
  };
};

export const reviewCommentSuccess = ({ data }) => {
  return {
    type: REVIEW_COMMENT_SUCCESS,
    data,
  };
};

export const reviewCommentFail = ({ data }) => {
  return {
    type: REVIEW_COMMENT_FAIL,
    data,
  };
};

export const reviewComment = (
  commentId,
  status,
  successCallback,
  failCallback,
) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    try {
      dispatch(reviewCommentStart());
      const response = await axios.put(
        `${config.API_URL}/comment/${commentId}/review-report`,
        { status },
        {
          headers: {
            token: auth.token,
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch(
        reviewCommentSuccess({
          data: { response: response.data, commentId },
        }),
      );
      successCallback?.();
    } catch (err) {
      dispatch(
        getCommentReportsFail({
          data: err,
        }),
      );
      failCallback?.();
    }
  };
};

export const clearCommentReportsState = () => {
  return {
    type: CLEAR_COMMENT_REPORTS_STATE,
  };
};

export const clearAllCommentState = () => {
  return {
    type: CLEAR_ALL_COMMENT_STATE,
  };
};
