import React from 'react';
import { Box, Button, Chip, Typography, Grid } from '@mui/material';
import Icon from '@mui/material/Icon';
import { getDifficultyLabel } from '../../constants/examDifficulty';
import defaultMockTest from './images/defaultMockTest.svg';
import CreditsIcon from '../Sidebar/img/credits-icon.svg';
import { StyleColorHeader } from './StyledComponents';

function MockTestCard({
  title,
  isHighlighted,
  imageUrl,
  tags,
  difficulty,
  questionsText,
  questionsValue,
  timeText,
  timeValue,
  mode,
  credits,
  btnText,
  onClick,
  extra,
  key,
}) {
  const actionButton = (
    <Button
      sx={{
        backgroundColor: btnText === 'Unlock Now' ? '#AFB4BE' : 'primary',
        height: '35px',
        borderRadius: '25px 0px 0px 25px',
        position: 'relative',
        right: '-21px',
        width: '180px',
        marginLeft: 'auto',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 600,
      }}
      variant={btnText === 'View Result' ? 'outlined' : 'contained'}
      size="small"
      onClick={onClick}
    >
      {btnText === 'Unlock Now' && (
        <Icon style={{ fontSize: '1.2rem', marginBottom: '3px' }}>lock</Icon>
      )}
      {btnText}
    </Button>
  );

  return (
    <Box
      key={key}
      className={`mocktest__item${isHighlighted ? ' highlighted' : ''}`}
    >
      <div className="mocktest__item--main">
        {/* <div className="mocktest__item--timestamp">{timestamp}</div> */}
        <div className="mocktest__item--header">
          <Grid
            container
            sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}
          >
            <Grid item xs={6}>
              <img
                src={imageUrl || defaultMockTest}
                alt="Gate Electronics Mock Test"
                className="logo"
              />
            </Grid>
            <Grid item>
              {credits && (
                <Typography variant="h5" className="mocktest_credits">
                  <img src={CreditsIcon} width="20px" />
                  &nbsp;{credits}
                </Typography>
              )}
            </Grid>
            <div
              className={`difficulty-badge ${getDifficultyLabel(difficulty) ||
                difficulty}`}
            >
              {getDifficultyLabel(difficulty) || difficulty}
            </div>
          </Grid>

          <Grid container>
            <Grid item xs>
              <StyleColorHeader variant="h5">{title}</StyleColorHeader>
              <p className="small__title">{title}</p>
              <div style={{ height: '70px' }}>
                {tags?.slice(0, 3)?.map((tag) => (
                  <Chip
                    key={tag.id}
                    label={tag.name}
                    size="small"
                    style={{
                      color: '#25507B',
                      background: '#25507B1A',
                      marginRight: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ))}
                {tags?.length > 3 && (
                  <Typography variant="caption" color="primary">
                    +{tags?.length - 3} more
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mocktest__item--content">
          <div>
            <Typography variant="caption">{questionsText}</Typography>
            <Typography color="primary" className="mocktest__item--number">
              {questionsValue}
            </Typography>
          </div>
          <div className="vertical-line"></div>
          <div>
            <Typography variant="caption">{timeText}</Typography>
            <Typography color="primary" className="mocktest__item--number">
              {timeValue} mins
            </Typography>
          </div>
          <div className="vertical-line"></div>
          <div>
            <Typography variant="caption">Mode</Typography>
            <Typography color="primary" className="mocktest__item--number">
              {mode}
            </Typography>
          </div>
        </div>
        <div className="mocktest__item--extra">
          {extra && <div>{extra}</div>}
          {actionButton}
        </div>
      </div>
    </Box>
  );
}

export default MockTestCard;
