import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from './../actions';
import UserProfile from './../components/UserProfile';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
    category: state.category,
    tag: state.tag,
    question: state.question,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
