import { Box, Dialog, DialogActions, Grid, IconButton } from '@mui/material';
import * as React from 'react';
import Close from '@mui/icons-material/Close';

import {
  StylesTitle,
  StylesTagline,
  StylesTextContainer,
  StylesText,
  StylesButton,
} from './StoreStyledComponent';

const TermsAndConditionDialog = ({ closeDialog, openDialog, text }) => {
  return (
    <Dialog
      className="dialog"
      onClose={closeDialog}
      aria-labelledby="simple-dialog-title"
      open={openDialog}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: '14px',
        },
      }}
    >
      <Grid container>
        <Grid item sm={9} xs={7}>
          <StylesTitle>Terms and Conditions</StylesTitle>
          <StylesTagline>
            {' '}
            Read these terms and conditions carefully for better assistance.
          </StylesTagline>
        </Grid>
        <Grid item sm={3} xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={closeDialog} size="large">
              <Close fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <StylesTextContainer>
          {text === 'shop' ? (
            <StylesText>
              • Credits and other digital assets will be immediately updated as
              soon as the transaction is completed.
              <br />
              • Prices and discounts may vary from time to time, depending on
              the offers and schemes.
              <br />
              • There is no provision of return once the transaction is made.
              <br />
              • You can download your invoice from Order History page.
              <br />
              • There will be no invoice for transactions with GEMS, you can
              check your transactions page for ledger of credits and GEMS
              <br />• In case of any issues with the order, please drop us an
              email at <a href="mailto:hello@abekus.com">hello@abekus.com</a>,
              and our team will respond back within 2 working days.
              <br />
            </StylesText>
          ) : (
            <StylesText>
              • Rewards can only be redeemed through GEMS, and cannot be
              purchased with money.
              <br />
              • Once redeemed, you will receive your reward coupons at your
              registered email within 3 days.
              <br />
              • If the reward is a tangible item, it will take 7-10 working days
              to reach your address.
              <br />
              • Please make sure you enter your correct address and contact
              number to ensure you receive the reward without any trouble.
              <br />
              • The coupons sent on email often has an expiry date. The exact
              expiry is provided on the coupon by the given product/companies.
              Please make sure you redeem the coupon before expiry date.
              <br />
              • If the coupon is expired, they cannot be redeemed again. <br />•
              Once redeemed, the reward cannot be cancelled or exchanged.
            </StylesText>
          )}
        </StylesTextContainer>
      </Box>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <StylesButton
                variant="contained"
                type="button"
                color="primary"
                onClick={closeDialog}
              >
                OK
              </StylesButton>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionDialog;
