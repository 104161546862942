import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff,
} from '@mui/icons-material/';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import './index.scss';
import { withRouter } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const ChangePasswordCard = (props) => {
  const [current_password, setCurrentPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirm_new_password, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isStatusMessageVisible, setIsStatusMessageVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.user?.isUpdatingPasswordSuccess) setMessageBar();
  }, [props.user?.error]);

  const setMessageBar = () => {
    const { user } = props;
    if (user.error === 'incorrect_current_password') {
      setIsStatusMessageVisible(true);
      setStatus('error');
      setMessage('Sorry! failed to update password');
      setErrors({ current_password: 'Current password is invalid' });
    } else {
      setIsStatusMessageVisible(true);
      setStatus('success');
      setMessage('Password updated successfully');
      setTimeout(() => {
        navigate('/l/user');
      }, 3000);
    }
  };

  const validate = (data) => {
    const errors = {};

    if (!data.current_password) {
      errors['current_password'] = 'Please enter current password';
    }
    if (data.new_password?.length < 6) {
      errors['new_password'] =
        'Password should contain more than 6 characters.';
    }
    if (!data.new_password) {
      errors['new_password'] = 'Please enter new password';
    }

    if (!data.confirm_new_password) {
      errors['confirm_new_password'] = 'Please enter confirm new password';
    } else if (data.new_password !== data.confirm_new_password) {
      errors['confirm_new_password'] =
        'New password and confirmed password are not same';
    }

    return errors;
  };

  const onSavePassword = (event) => {
    event.preventDefault();

    const data = {
      current_password,
      new_password,
      confirm_new_password,
    };

    const errors = validate(data);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
      const resetData = {
        current_password: data.current_password,
        new_password: data.new_password,
      };
      props.resetPassword(resetData);
    }
  };

  const handleMessageClose = () => {
    setIsStatusMessageVisible(false);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const { user } = props;

  return (
    <div className="user-profile-edit-container-left">
      <Paper className="user-wrapper">
        <h1>Change Password</h1>
        <div className="user-profile-edit_form">
          <div className="user-profile-edit_form_left">
            <div className="form-control">
              <label htmlFor="">Current Password</label>
              <div className="field-item">
                <TextField
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={current_password}
                  onChange={(e) =>
                    setCurrentPassword(e.target.value.replace(/\s/g, ''))
                  }
                  margin="normal"
                  variant="outlined"
                  className="text-input-field"
                  name="current_password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visiility"
                          onClick={togglePassword}
                          size="large"
                        >
                          {isPasswordVisible ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p className="error">{errors.current_password}</p>
              </div>
            </div>
            <div className="form-control">
              <label htmlFor="">New Password</label>
              <div className="field-item">
                <TextField
                  type="password"
                  value={new_password}
                  onChange={(e) =>
                    setNewPassword(e.target.value.replace(/\s/g, ''))
                  }
                  margin="normal"
                  variant="outlined"
                  className="text-input-field"
                  name="new_password"
                />
                <p className="error">{errors.new_password}</p>
              </div>
            </div>
            <div className="form-control">
              <label htmlFor="">Confirm New Password</label>
              <div className="field-item">
                <TextField
                  type="password"
                  value={confirm_new_password}
                  onChange={(e) =>
                    setConfirmNewPassword(e.target.value.replace(/\s/g, ''))
                  }
                  margin="normal"
                  variant="outlined"
                  className="text-input-field"
                  name="confirm_new_password"
                />
                <p className="error">{errors.confirm_new_password}</p>
              </div>
            </div>
          </div>
          <div className="form-action">
            <Button
              variant="contained"
              className="btn btn-primary"
              onClick={onSavePassword}
            >
              {user.isUpdatingPassword ? (
                <Loader size={20} color="secondary" />
              ) : (
                'Save'
              )}
            </Button>
          </div>
        </div>
      </Paper>
      <MessageBar
        type={status}
        open={isStatusMessageVisible}
        onClose={handleMessageClose}
        message={message}
      />
    </div>
  );
};

export default withRouter(ChangePasswordCard);
