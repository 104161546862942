import React, { useEffect } from 'react';
import './style.css';
import { Box, Paper, Tabs, Tab, Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import TabHeader from './ContainerHeader';
import QuestionCard from './QuestionView';
import VotedHistoryPage from './VotedHistory';
import { useSelector } from 'react-redux';
import EmptyReviewReport from './EmptyReviewReport';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReviewReported = (props) => {
  const {
    user,
    tag,
    getVotingReportQuestion,
    getVotedReportQuestion,
    postVotingReportQuestion,
  } = props;

  const [value, setValue] = React.useState(0);

  const votingReportQuestion = useSelector(
    (state) => state.question.votingReportQuestion,
  );
  const isVotingReportQuestionGetFail = useSelector(
    (state) => state.question.isVotingReportQuestionGetFail,
  );

  useEffect(() => {
    getVotingReportQuestion(user?.selected_domain);
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleVoteQuestion = (report_id, review_type) => {
    postVotingReportQuestion(report_id, review_type);
    getVotingReportQuestion(user.selected_domain);
  };

  if (isVotingReportQuestionGetFail) return <EmptyReviewReport />;

  return (
    <div>
      <section>
        <section>
          <Paper style={{ flexGrow: 1 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Voting" {...a11yProps(0)} />
              <Tab label="Voting History" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <TabHeader />
              <Divider style={{ marginTop: '10px' }} />
              <QuestionCard
                question={votingReportQuestion}
                index={null}
                key={`${votingReportQuestion?.id}-${1}`}
                tag={tag}
                tagList={tag.list}
                isVotingQuestion={true}
                handleVoteQuestion={handleVoteQuestion}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <VotedHistoryPage
                user={user}
                tag={tag}
                getVotedReportQuestion={getVotedReportQuestion}
              />
            </TabPanel>
          </Paper>
        </section>
      </section>
    </div>
  );
};

export default ReviewReported;
