import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import {
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  TablePagination,
  Typography,
  TableCell,
  Avatar,
  TextField,
  Checkbox,
} from '@mui/material';
import Select2 from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { StyleDataManagement } from '../StyledComponent';
import QuickActionBar from './QuickActionBar';
import { deleteCollege } from '../../../actions/dataManagement';
import AddCollegeDialog from '../AddCollegeDialog';

const AdminCollegeList = ({
  collegeData,
  selectedCollege,
  handleCollegeModalOpen,
  isEditingStatus,
  setIsEditingStatus,
  setCollegeId,
  page,
  rowsPerPage,
  onPageChangeHandler,
  handleChangeRowsPerPage,
  editableTableCollege,
  collegeName,
  isEditing,
  setCollegeName,
  setIsEditing,
  searchTerm,
  status,
  setSortOrder,
  sortOrder,
  setSortBy,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [selectedCollegeList, setSelectedCollegeList] = React.useState([]);
  const [showMergeCollegeDialog, setShowMergeCollegeDialog] = React.useState(
    false,
  );

  const handleCollegeMultiSelect = (event, college) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [
        ...prevSelectedIds,
        college?.college_id,
      ]);
      setSelectedCollegeList((prevSelected) => [...prevSelected, college]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter(
          (selectedId) => selectedId !== college?.college_id,
        ),
      );
      setSelectedCollegeList((prevSelected) =>
        prevSelected.filter(
          (selectedCollege) =>
            selectedCollege.college_id !== college?.college_id,
        ),
      );
    }
  };

  const handleActionDelete = (id, actionType) => {
    const deleteBody = actionType === 'bulk' ? selectedIds : [id];
    dispatch(
      deleteCollege({ ids: deleteBody }, status, searchTerm, page, rowsPerPage),
    );
    setSelectedIds([]);
    setSelectedCollegeList([]);
  };

  const handleSelectAll = () => {
    setSelectedIds(
      collegeData?.colleges
        ?.filter((college) => college.approval_status !== 'Rejected')
        ?.map((obj) => obj.college_id),
    );
    setSelectedCollegeList(
      collegeData?.colleges?.filter(
        (college) => college.approval_status !== 'Rejected',
      ),
    );
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectedCollegeList([]);
  };

  const handleActionMerge = () => {
    setShowMergeCollegeDialog(true);
  };

  return (
    <StyleDataManagement className="collegeListContainer">
      <Table style={{ backgroundColor: 'white' }}>
        <TableHead>
          {collegeData.colleges?.length === 0 ? (
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              No Records Found
            </Typography>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Logo</TableCell>
              <TableCell>
                College Name{' '}
                {sortOrder === 'asc' ? (
                  <ArrowUpward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('desc');
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('asc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          )}
        </TableHead>

        {collegeData.colleges
          ?.filter((record) => record.approval_status !== 'Rejected')
          .map((college) => (
            <TableBody key={college.college_id}>
              <TableRow>
                <TableCell>
                  {' '}
                  <Checkbox
                    checked={selectedIds.includes(college.college_id)}
                    onChange={(e) => handleCollegeMultiSelect(e, college)}
                  />{' '}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }}>
                  <div className="college__list__name">
                    <Avatar
                      alt={college.college_name}
                      src={college.image_url}
                      className="college__list__avatar"
                    />
                  </div>
                </TableCell>
                <TableCell style={{ width: '20rem' }}>
                  {isEditing &&
                  selectedCollege.college_id === college.college_id ? (
                    <>
                      <FormControl
                        style={{ marginTop: '0.5rem', width: '12rem' }}
                      >
                        <TextField
                          placeholder={'Field cannot be empty'}
                          value={collegeName}
                          onChange={(e) => setCollegeName(e.target.value)}
                          autoFocus
                        />
                      </FormControl>
                    </>
                  ) : (
                    <Typography
                      onClick={() => {
                        setCollegeId(college.college_id);
                        setIsEditingStatus(true);
                        setCollegeName(college.college_name);
                        setIsEditing(true);
                      }}
                    >
                      {college.college_name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ padding: '0', width: '15rem' }}
                >
                  {isEditingStatus &&
                  selectedCollege.college_id === college.college_id ? (
                    <>
                      <FormControl style={{ width: '8rem', padding: 0 }}>
                        <Select2
                          {...register('approval_status', {
                            required: 'Please select a status',
                          })}
                          style={{
                            marginTop: '8px',
                            marginBottom: '4px',
                          }}
                          variant="outlined"
                          margin="dense"
                          displayEmpty
                          placeholder="College"
                          error={!!errors.approval_status}
                          defaultValue={
                            college.approval_status === null ||
                            college.approval_status === 'Pending'
                              ? 'Pending'
                              : 'Accepted'
                          }
                        >
                          <MenuItem value="Accepted" key="Accepted">
                            Accepted
                          </MenuItem>
                          <MenuItem value="Pending" key="Pending">
                            Pending
                          </MenuItem>
                          <MenuItem value="Rejected" key="Rejected">
                            Rejected
                          </MenuItem>
                        </Select2>
                      </FormControl>
                      <ButtonGroup style={{ textAlign: 'right' }}>
                        <IconButton
                          disabled={collegeName ? false : true}
                          onClick={handleSubmit(editableTableCollege)}
                          size="large"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsEditingStatus(false);
                            setIsEditing(false);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </ButtonGroup>
                    </>
                  ) : (
                    <div className="college__sub-tags">
                      <Chip
                        onClick={() => {
                          setCollegeId(college.college_id);
                          setIsEditingStatus(true);
                          setCollegeName(college.college_name);
                          setIsEditing(true);
                        }}
                        variant="outlined"
                        color="success"
                        size="small"
                        label={
                          college.approval_status
                            ? college.approval_status
                            : 'Pending'
                        }
                        className="subtag"
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() => handleCollegeModalOpen(college.college_id)}
                    size="large"
                  >
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handleActionDelete(college.college_id, 'single')
                    }
                    size="large"
                  >
                    <Delete style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>

      {selectedIds.length > 0 ? (
        <QuickActionBar
          handleActionDelete={handleActionDelete}
          handleActionMerge={handleActionMerge}
          selectedIds={selectedIds}
          entityDataLength={collegeData?.colleges?.length}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          page={page}
          rowsPerPage={rowsPerPage}
          paginationCount={collegeData.total_pages * collegeData.page_size}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={collegeData.total_pages * collegeData.page_size}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      )}

      {showMergeCollegeDialog && (
        <AddCollegeDialog
          open={showMergeCollegeDialog}
          handleClose={() => setShowMergeCollegeDialog(false)}
          collegeId={''}
          college={''}
          searchTerm={searchTerm}
          status={status || ''}
          page={page}
          rowsPerPage={rowsPerPage}
          onlyMerge={true}
          selectedCollegeList={selectedCollegeList}
          setSelectedCollegeList={setSelectedCollegeList}
          setSelectedIds={setSelectedIds}
        />
      )}
    </StyleDataManagement>
  );
};

export default AdminCollegeList;
