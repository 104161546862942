import * as React from 'react';
import { FormLabel, FormControl } from '@mui/material';
import RichTextEditor from '../RichTextEditor/index';

const IncorrectQuestion = ({ question, onChange, title, value }) => {
  const onQuestionBodyChange = (e) => {
    const questionBodyChange = {
      ...value,
      correct_text: e.html,
    };
    onChange(questionBodyChange);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit', paddingBottom: '8px' }}
      >
        {title}
      </FormLabel>
      <RichTextEditor
        value={value?.correct_text}
        onChange={onQuestionBodyChange}
        metaData={{
          tag_id: question.data?.tag_id,
          question_id: question.data?.id,
        }}
      />
    </FormControl>
  );
};

export default IncorrectQuestion;
