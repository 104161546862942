import { Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { Icon } from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
const UnlockProgressModal = ({
  showModal,
  handleClose,
  tags,
  prerequisite,
  navigate,
}) => {
  return (
    <Modal open={showModal} onClose={handleClose} className="progressModal">
      <div className="MajorCtn">
        <div className="header">
          <Button
            variant="contained"
            size="small"
            style={{ color: '#fff', backgroundColor: '#C4C4C7' }}
            disabled
            onClick={() => {}}
          >
            <Icon style={{ fontSize: '1.2rem', marginBottom: '3px' }}>
              lock
            </Icon>{' '}
            Locked
          </Button>
          <Icon
            style={{ color: '#3572B080', cursor: 'pointer' }}
            onClick={() => {
              handleClose();
            }}
          >
            <CancelTwoToneIcon />
          </Icon>
        </div>
        <div className="main">
          <Typography
            variant="h6"
            style={{ color: 'black', fontWeight: '700', opacity: '0.73' }}
          >
            You are doing great. Complete these tasks to unlock the mock test.
          </Typography>
          {tags?.map((t) => (
            <div className="indiProgress" key={t.id}>
              <div className="progressView">
                <Typography variant="subtitle2" style={{ color: '#3572B0' }}>
                  Attempt {prerequisite?.[t.id]?.remaining_question_attempts}{' '}
                  questions of {t.name}.
                </Typography>
                <div className="progressBar">
                  <div
                    className="progressIndicator"
                    style={{
                      backgroundColor:
                        prerequisite?.[t.id]?.remaining_question_attempts === 0
                          ? '#5FB762'
                          : '#3572B0',
                      marginLeft: `calc(${(prerequisite?.[t.id]
                        ?.attempted_question_count /
                        (prerequisite?.[t.id]?.remaining_question_attempts +
                          prerequisite?.[t.id]?.attempted_question_count)) *
                        100}% - 5px)`,
                    }}
                  >
                    {prerequisite?.[t.id]?.remaining_question_attempts === 0 ? (
                      <Icon>check</Icon>
                    ) : (
                      prerequisite?.[t.id]?.attempted_question_count
                    )}
                  </div>
                  <div className="mainCtn">
                    <div
                      style={{
                        height: '100%',
                        width: `${(prerequisite?.[t.id]
                          ?.attempted_question_count /
                          (prerequisite?.[t.id]?.remaining_question_attempts +
                            prerequisite?.[t.id]?.attempted_question_count)) *
                          100}%`,
                        backgroundColor:
                          prerequisite?.[t.id]?.remaining_question_attempts ===
                          0
                            ? '#5FB762'
                            : '#25507B',
                      }}
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                size="small"
                style={{
                  color: '#fff',
                  backgroundColor:
                    prerequisite?.[t.id]?.remaining_question_attempts === 0
                      ? '#C4C4C7'
                      : '#25507B',
                  width: '120px',
                  marginBottom: '-25px',
                }}
                disabled={
                  prerequisite?.[t.id]?.remaining_question_attempts === 0
                }
                onClick={() => {
                  navigate(`/l/practise`, {
                    state: { from: '/mockTest', mockTestcategory: t },
                  });
                }}
              >
                {prerequisite?.[t.id]?.remaining_question_attempts === 0
                  ? 'Done'
                  : 'Proceed'}
              </Button>
            </div>
          ))}
        </div>
        <div className="BtnCtn">
          <Button
            variant="contained"
            size="small"
            style={{
              color: '#fff',
              backgroundColor: '#25507B',
              width: '120px',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UnlockProgressModal;
