import { styled } from '@mui/styles';
import { Badge, Box, Button, Chip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/CreateOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
export const PlayAnswerDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  borderRadius: '10px',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  width: '30%',
  [theme.breakpoints.down('md')]: {
    flexBasis: '0px',
    width: '0px',
    height: '0px',
  },
}));

export const CategoryScoreChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  backgroundColor: theme.palette.primary.background,
  borderRadius: '5px',
  fontWeight: 600,
  fontSize: '14px',
  minHeight: '35px',
}));

export const PlayAnswerDetailsEndButton = styled('div')({
  marginTop: '2em',
  marginBottom: '1.5em',
  display: 'flex',
  justifyContent: 'center',
});

export const DetailRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: '10px',
  fontSize: '14px',
});

export const DetailRowAnswer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

export const DifficultyRow = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '5px',
});

export const QuestionTag = styled('div')({
  marginBottom: '5px !important',
});

export const DifficultyBlock = styled('div')(({ theme }) => ({
  flex: 1,
  color: theme.palette.component.color,
}));

export const DifficultyLevel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  color: theme.palette.secondary.main,
  justifyContent: 'flex-end',
}));

export const PlayAnswerDetailsLink = styled('div')({
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
});

export const PlayAnswerDetailsChangeSubcategory = styled(EditIcon)({
  fontSize: '20px !important',
  marginLeft: '10px',
  position: 'relative',
  top: '4px',
});

export const PlayAnswerDetailsChange = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
export const PlayAnswerDetailsChangeTypo = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
export const PlayAnswerDetailsChangeTyp = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const PlayAnswerDetailsChangeLink = styled('a')({
  outline: 'none',
});

export const SelectedSubtopics = styled('div')({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
});

export const QuestionPreference = styled(Chip)(({ theme }) => ({
  height: '21px !important',
  fontSize: '12px !important',
  border: 'none',
  color: theme.palette.secondary.dark,
  backgroundColor: 'rgba(37, 80, 123, 0.1)',
  fontWeight: 500,
  letterSpacing: '0.4px',
}));

export const QuestionMapHeader = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  marginTop: '20px',
});

export const QuestionMapContentItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px',
  background: 'rgba(37, 80, 123, 0.07)',
  borderRadius: '15px',
  cursor: 'pointer',
  '&.attempted': {
    background: theme.palette.common.success,
    color: theme.palette.background.main,
  },
  '&.current': {
    color: theme.palette.background.main,
    background: theme.palette.secondary.dark,
  },
  '&.skipped': {
    background: '#21A9DE',
    color: theme.palette.background.main,
  },
  '&.timedOut': {
    background: theme.palette.common.warning,
    color: theme.palette.background.main,
  },
}));

export const PlayAnswerDetailsButton = styled('button')(({ theme }) => ({
  fontSize: '0.8em !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px',
  borderRadius: '15px',
  border: `1px solid ${theme.palette.primary.main}`,
  cursor: 'pointer',
  padding: '0',
}));

export const PlayAnswerDetailsButton2 = styled(Button)({
  fontSize: '0.8em !important',
});

export const PlayAnswerDetailsNumber = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  textAlign: 'center',
  padding: '0.5em 1.2em',
  borderRadius: '3px',
}));

export const PlayAnswerDetailsNumberBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  textAlign: 'center',
  padding: '0.5em 1.2em',
  borderRadius: '3px',
}));

export const PlayAnswerDetailsNumberContent = styled('div')({
  backgroundColor: 'transparent',
});

export const PlayAnswerDetailsNumberSpan = styled('span')(({ theme }) => ({
  color: theme.palette.component.color,
  display: 'block',
  fontSize: '0.8em',
}));

export const PlayAnswerDetailsNumberParagraph = styled('p')({
  padding: '0',
  fontSize: '1.2em',
  fontWeight: 600,
  margin: '0',
});

export const PlayAnswerDetailsNumberParagraphGreen = styled('p')(
  ({ theme }) => ({
    color: theme.palette.common.success,
    background: 'transparent',
  }),
);

export const PlayAnswerDetailsNumberParagraphRed = styled('p')(({ theme }) => ({
  color: theme.palette.common.error,
  background: 'transparent',
}));

export const PlayAnswerDetailsNumberParagraphPurple = styled('p')(
  ({ theme }) => ({
    color: theme.palette.playColors.lightPurple,
    background: 'transparent',
  }),
);

export const PlayAnswerDetailsNumberParagraphYellow = styled('p')(
  ({ theme }) => ({
    color: theme.palette.common.warning,
    background: 'transparent',
  }),
);

export const PlayAnswerDetailsNumberParagraphBlue = styled('p')(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    background: 'transparent',
  }),
);

export const AnalyticsProgress = styled('div')({
  display: 'flex',
  width: '100%',
});

export const AnalyticsProgressMargin = styled('div')({
  margin: '1em 0',
});

export const AnalyticsProgressBar = styled('span')({
  height: '10px',
  boxSizing: 'border-box',
});

export const AnalyticsProgressBarLeft = styled('span')({
  borderTopLeftRadius: '15px',
  borderBottomLeftRadius: '15px',
});

export const AnalyticsProgressBarRight = styled('span')({
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
});

export const AnalyticsProgressBarGreen = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.success,
  zIndex: 4,
}));

export const AnalyticsProgressBarRed = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.error,
  transform: 'translateX(-3px)',
  zIndex: 3,
}));

export const AnalyticsProgressBarPurple = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.playColors.lightPurple,
  transform: 'translateX(-6px)',
  zIndex: 2,
}));

export const AnalyticsProgressBarYellow = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.common.warning,
  transform: 'translateX(-9px)',
}));

export const QuestionMapBoxContent = styled(Box)(({ theme }) => ({
  padding: '5px',
  borderRadius: '10px',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
  width: '100%',
}));
export const QuestionMapContent = styled('div')({
  marginTop: '0.5em',
});

export const QuestionMapContentNav = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const QuestionMapContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: '5px 2px',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '5px 1px',
  },
}));

export const QuestionMapContentFooter = styled('div')({
  marginTop: '1em',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '0.1em',
});

export const QuestionMapContentFooterItem = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

export const QuestionMapContentFooterItemSpan = styled(Badge)({
  marginLeft: '0.5em',
});

export const QuestionMapContentFooterItemParagraph = styled('p')({
  margin: '0',
  marginLeft: '0.6em',
  fontSize: '0.75em',
});

export const QuestionMapContentItemAttempted = styled('div')(({ theme }) => ({
  background: theme.palette.playColors.lightGreen2,
}));

export const QuestionMapContentItemCurrent = styled('div')(({ theme }) => ({
  color: theme.palette.background.main,
  background: theme.palette.primary.main,
}));

export const QuestionMapContentItemSkipped = styled('div')(({ theme }) => ({
  background: theme.palette.playColors.lightPink2,
}));

export const QuestionMapContentItemUnattempted = styled('div')(({ theme }) => ({
  background: theme.palette.playColors.borderBlue,
}));

export const QuestionMapContentItemTimeout = styled('div')(({ theme }) => ({
  background: theme.palette.playColors.lightYellow2,
}));

export const QuestionMapMobile = styled('div')({
  flex: 1,
  display: 'flex',
});

export const QuestionMapProgress = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  paddingRight: '10%',
});

export const QuestionMapProgressSpan = styled('span')({
  height: '1em',
  borderRadius: '42px',
  position: 'relative',
});

export const QuestionMapProgressSpanAfter = styled('span')(({ theme }) => ({
  content: 'attr(data-value)',
  position: 'absolute',
  top: '-10%',
  right: '0',
  height: '120%',
  aspectRatio: '1 / 1',
  fontSize: '0.8em',
  textAlign: 'center',
  borderRadius: '50%',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
  zIndex: 2,
  color: theme.palette.secondary.main,
  fontWeight: 500,
  backgroundColor: theme.palette.common.white,
}));

export const QuestionMapProgressSpanAttempted = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.playColors.lightGreen2,
}));

export const QuestionMapProgressSpanTimeout = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.playColors.lightYellow2,
}));

export const QuestionMapProgressSpanSkipped = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.playColors.lightPink2,
}));

export const QuestionMapProgressSpanUnattempted = styled('span')(
  ({ theme }) => ({
    backgroundColor: theme.palette.playColors.borderBlue,
  }),
);

export const QuickSettingsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 10px',
});

export const QuickSettingRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
});

export const QuickOptions = styled('div')({
  padding: '10px 10px 0px',
  height: 'fit-content',
});

export const QuickOptionsCollapsed = styled('div')({
  height: '0px',
  padding: '0px',
  overflow: 'hidden',
  zIndex: '-1',
});

export const SettingIcon = styled(SettingsIcon)({
  transition: 'transform 500ms',
});

export const SettingIconRotated = styled('div')({
  transform: 'rotate(45deg)',
});

export const PlayAnswerDetails = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '0 !important',
    border: 'none !important',
  },
}));

export const StyledQuestionMapContentFooter = styled('div')(({ theme }) => ({
  padding: '10px 5px',
  marginTop: '0.2em',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '0.2rem',
  [theme.breakpoints.down('sm')]: {
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(4, 1fr)',
    padding: '6px',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '0px',
    gap: '0.3rem',
  },
}));

export const StyledQuestionMapContentFooterItem = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  marginRight: '0.5em',
  '&.attempted .MuiBadge-dot': {
    backgroundColor: theme.palette.common.success,
  },
  '&.current .MuiBadge-dot': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.skipped .MuiBadge-dot': {
    backgroundColor: '#21A9DE',
  },
  '&.unattempted .MuiBadge-dot': {
    backgroundColor: theme.palette.playColors.borderBlue,
  },
}));

export const StyledParagraph = styled('p')(({ theme }) => ({
  margin: '0',
  marginLeft: '0.6em',
  fontSize: '0.75em',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0.2em',
  },
}));

export const PlayAnswerDetailsNumberss = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  textAlign: 'center',
  padding: '0.3em 0.9em',
  borderRadius: '3px',
  flex: 1,
  flexGrow: 'grow',

  '& > div': {
    backgroundColor: 'transparent',
  },

  '& span': {
    color: theme.palette.component.color,
    display: 'block',
    fontSize: '0.8em',
  },

  '& p': {
    padding: '0',
    fontSize: '1em',
    fontWeight: 600,
    margin: '0',
  },

  '& p.green': {
    color: theme.palette.common.success,
    background: 'transparent',
  },

  '& p.red': {
    color: theme.palette.common.error,
    background: 'transparent',
  },

  '& p.purple': {
    color: theme.palette.playColors.sky,
    background: 'transparent',
  },

  '& p.yellow': {
    color: theme.palette.common.warning,
    background: 'transparent',
  },

  '& p.blue': {
    color: theme.palette.secondary.main,
    background: 'transparent',
  },
}));
export const PlayAnswerDetailsNumberssBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  textAlign: 'center',
  padding: '0.3em 0.9em',
  borderRadius: '3px',

  '& > div': {
    backgroundColor: 'transparent',
  },

  '& span': {
    color: theme.palette.component.color,
    display: 'block',
    fontSize: '0.8em',
  },

  '& p': {
    padding: '0',
    fontSize: '1em',
    fontWeight: 600,
    margin: '0',
  },

  '& p.green': {
    color: theme.palette.common.success,
    background: 'transparent',
  },

  '& p.red': {
    color: theme.palette.common.error,
    background: 'transparent',
  },

  '& p.purple': {
    color: theme.palette.playColors.lightSky,
    background: 'transparent',
  },

  '& p.yellow': {
    color: theme.palette.common.warning,
    background: 'transparent',
  },

  '& p.blue': {
    color: theme.palette.secondary.main,
    background: 'transparent',
  },
}));

export const AnalyticsProgressBars = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: '1em 0',

  '& span': {
    height: '10px',
    boxSizing: 'border-box',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
  },

  '& span:first-child': {
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
  },

  '& .green': {
    backgroundColor: theme.palette.common.success,
    zIndex: 4,
  },

  '& .red': {
    backgroundColor: theme.palette.common.error,
    transform: 'translateX(-3px)',
    zIndex: 3,
  },
  '& .yellow': {
    backgroundColor: theme.palette.common.warning,
    transform: 'translateX(-6px)',
    zIndex: 2,
  },
  '& .purple': {
    backgroundColor: theme.palette.playColors.sky,
    transform: 'translateX(-9px)',
  },
}));

export const StyledStatsDetailsContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '8px',
});
