import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  hintVisibility,
  solutionVisibility,
} from '../../constants/questioncard';
import QuestionCard from '../QuestionCard/QuestionCard';
import { StyledEditQuestion } from './StyledComponent';

const PreviewForm = (props) => {
  const methods = useFormContext();
  const { getValues } = methods;
  const { tag, question_type } = props;
  let question = {
    ...getValues(),
    question_type,
  };
  question.solution_text = question?.solution.solution_text;
  question.is_solution_available =
    question?.solution?.solution_text?.length > 0;
  question.tag_id = question?.tag?.id;
  question.tag_name = question?.tag?.value;
  question.sub_tag_array = question.sub_tag_array.map((subTag) => {
    if (!subTag.id) {
      if (question.tag?.sub_tags?.length)
        question.tag.sub_tags.push({ id: subTag.value, name: subTag.value });
      else question.tag.sub_tags = [{ id: subTag.value, name: subTag.value }];
      subTag.id = subTag.value;
    }
    return subTag.id;
  });

  return (
    <StyledEditQuestion>
      <QuestionCard
        showSolution={solutionVisibility.contribute}
        showHint={hintVisibility.contribute}
        question={question}
        tag={tag}
        tagList={tag.list}
        isSolutionCollapse={false}
        isQuestionSelected={false}
        isQuestionPublished={false}
      />
    </StyledEditQuestion>
  );
};

export default PreviewForm;
