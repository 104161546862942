import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

function ConfirmEndModal({
  open,
  attemptedQuestions,
  totalQuestions,
  timeLeft,
  handleClose,
  handleConfirm,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Submit Test Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {timeLeft === null || attemptedQuestions === totalQuestions
            ? `Are you sure you want to submit this Mock Test?`
            : `You have attempted ${attemptedQuestions} out of ${totalQuestions} and there are ${timeLeft} left. Are you sure you want to submit the test?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmEndModal;
