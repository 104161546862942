import * as React from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';

const PlagiarisedOption = ({ onChange, value }) => {
  const onTextChange = (e) => {
    const plagrarisedBody = {
      ...value,
      correct_title: e.target.value,
    };
    onChange(plagrarisedBody);
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        component="legend"
        style={{ color: 'black', fontSize: 'inherit' }}
      >
        Enter source url
      </FormLabel>
      <TextField
        margin="normal"
        variant="outlined"
        size="small"
        onChange={onTextChange}
        value={value.correct_title}
        style={{ marginTop: '8px', backgroundColor: 'white' }}
      />
    </FormControl>
  );
};

export default PlagiarisedOption;
