import { Hidden, Typography } from '@mui/material';
import React from 'react';
import {
  StyledCustomCard,
  StyledMocktestInfoContainer,
} from './DashboardStyledComponent';
import fire from './images/fire.svg';
import box from './images/CreditBox.png';
import store from './images/store.png';
import { Link } from 'react-router-dom';
import active from './images/active.svg';
import unActive from './images/unActive.svg';

const MockTestInfo = ({ userLoginStreakData }) => {
  const getLast7DaysArray = () => {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - i);
      const dayIndex = currentDate.getDay();
      return dayNames[dayIndex];
    });

    const reversedDays = last7Days.map(
      (day, index, array) => array[array.length - 1 - index],
    );

    return reversedDays;
  };

  const getLastActive7DaysArray = (dateArray) => {
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - i);
      return currentDate.toISOString().split('T')[0];
    });

    const daysArray = dateArray
      .filter((date) => last7Days.includes(date))
      .map((date) => {
        const dayIndex = new Date(date).getDay();
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex];
      });

    return daysArray;
  };

  const logInDates = userLoginStreakData?.consecutive_logins || [];
  const activeDays = getLastActive7DaysArray(logInDates);
  const allDays = getLast7DaysArray();
  const daysStreak = userLoginStreakData?.days_streak || 0;
  const weekStreak = userLoginStreakData?.week_streak || 0;

  return (
    <main>
      <Hidden lgDown>
        <div style={{ padding: '5px' }}>
          <StyledCustomCard style={{ backgroundColor: '#25507B1A' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <div className="" style={{}}>
                <img
                  src={box}
                  style={{ width: '80px', height: '80px', margin: '0 auto' }}
                />
              </div>
              <div className="" style={{}}>
                <Typography sx={{ fontSize: '13px', marginBottom: '15px' }}>
                  Low on credits or gems? Get more to continue practicing and
                  improve your skills.
                </Typography>

                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#25507B',
                    fontWeight: '600',
                  }}
                >
                  Get credits, gems or redeem rewards at Abekus store.
                </Typography>
              </div>
            </div>
            <Link to="/l/store">
              <div className="" style={{ padding: '0 5px' }}>
                <p
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '25px',
                    padding: '4px',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={store}
                    width={15}
                    height={15}
                    style={{ marginTop: '2px' }}
                  />
                  <span> Visit Abekus Store</span>
                </p>
              </div>
            </Link>
          </StyledCustomCard>
        </div>
      </Hidden>
      <StyledMocktestInfoContainer>
        <StyledCustomCard
          style={{
            backgroundColor: '#FFF6E5',
            textAlign: 'center',
            height: '175px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Typography
            sx={{ fontSize: '14px', fontWeight: '900', color: '#333333' }}
          >
            Today&apos;s Motivation
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#333333',
              fontStyle: 'italic',
              textAlign: 'center',
            }}
          >
            “It&apos;s not whether you get knocked down, it&apos;s whether you
            get up.”
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '700',
              color: '#333333',
              fontStyle: 'italic',
            }}
          >
            - A.A. Mine
          </Typography>
        </StyledCustomCard>
        <StyledCustomCard
          style={{
            backgroundColor: '#FFF6E5',
            textAlign: 'center',
            height: '175px',
          }}
        >
          <div>
            <img src={fire} width={50} alt="" />
            <br />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              }}
            >
              {allDays.map((day, index) => (
                <span key={index}>{day.slice(0, 1)}</span>
              ))}
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                gap: '2px',
              }}
            >
              {allDays.map((day, index) =>
                activeDays.includes(day) ? (
                  <span key={index}>
                    <img
                      src={active}
                      alt=""
                      style={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  </span>
                ) : (
                  <span key={index}>
                    <img
                      src={unActive}
                      alt=""
                      style={{ width: '16px', height: '16px' }}
                    />
                  </span>
                ),
              )}
            </div>
          </div>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '600',
              color: '#333333',
              margin: '3px 0',
            }}
          >
            {daysStreak} day streak!
          </Typography>
          {weekStreak == 0 ? (
            <Typography style={{ fontSize: '12px' }}>
              Daily logins unlock badges! 🏆 #StayActive
            </Typography>
          ) : (
            <Typography style={{ fontSize: '13px' }}>
              {weekStreak} weeks strong! Keep it up!
            </Typography>
          )}
        </StyledCustomCard>
      </StyledMocktestInfoContainer>
    </main>
  );
};

export default MockTestInfo;
