import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import {
  difficulties,
  getDifficultyLabel,
} from '../../constants/examDifficulty';
import './index.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import MessageBar from '../MessageBar';
import ChooseSubcategoryDialog from './ChooseSubcategoryDialog';

function MockTestCreate(props) {
  const {
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const params = useParams();
  const [message, setMessage] = useState();
  const [subTagDialogOpen, setSubTagDialogOpen] = useState(false);
  const {
    user,
    tagList,
    mockTest,
    createMockTest,
    uploadImage,
    editMockTest,
    getMockTestById,
  } = props;
  const editParentTag = watch('edit_parent_tag');
  const tagMap = watch('tag_map');
  const tagArray = watch('tag_array');
  const tagWeightages = watch('tag_weightages');
  const tagPrerequisites = watch('tag_prerequisites');
  const tagInput = watch('tag');
  const weightageInput = watch('weightage');
  const prerequisiteInput = watch('prerequisite');
  const image = watch('image');
  useEffect(() => {
    if (mockTest.isCreateMockTestSuccess) {
      navigate('/l/mockTest');
    }
    if (mockTest.isMockTestUpdatedSuccess) {
      navigate('/l/mockTest');
    }
  }, [mockTest.isCreateMockTestSuccess, mockTest.isMockTestUpdatedSuccess]);

  useEffect(() => {
    if (mockTest.isCreatingMockTestFail) {
      setMessage({
        type: 'error',
        content: mockTest.error,
      });
    }
  }, [mockTest.isCreatingMockTestFail, mockTest.error]);

  useEffect(() => {
    if (mockTest.uploadImageSuccess)
      setValue('image', {
        ...image,
        image_url: mockTest.uploadedImage,
      });
  }, [mockTest.uploadImageSuccess]);

  useEffect(() => {
    if (params.mockTestId) getMockTestById(params.mockTestId);
  }, []);

  useEffect(() => {
    if (params.mockTestId && mockTest.isMockTestByIdFetched) {
      setValue('name', mockTest.mockTestDataById.name);
      setValue('cost_in_credits', mockTest.mockTestDataById.cost_in_credits);
      setValue('is_template', mockTest.mockTestDataById.is_template);
      setValue('tag_weightages', mockTest.mockTestDataById.tag_weightages);
      setValue(
        'tag_prerequisites',
        Object.values(mockTest.mockTestDataById.prerequisite),
      );
      setValue('exam_mode', mockTest.mockTestDataById.exam_mode);
      setValue('no_questions', mockTest.mockTestDataById.no_questions);
      setValue('difficulty_level', mockTest.mockTestDataById.difficulty_level);
      setValue(
        'tag_array',
        tagList.filter((tag) =>
          mockTest.mockTestDataById.tag_array.some((tId) => tag.id === tId),
        ),
      );
    }
  }, [mockTest.isMockTestByIdFetched, params.mockTestId]);
  const addNewTag = () => {
    if (tagInput && weightageInput) {
      const newTagArray = tagArray ? [...tagArray, tagInput] : [tagInput];
      const newTagWeightages = tagWeightages
        ? [...tagWeightages, parseInt(weightageInput)]
        : [parseInt(weightageInput)];
      const newTagPrerequisites = tagPrerequisites
        ? [...tagPrerequisites, parseInt(prerequisiteInput)]
        : [parseInt(prerequisiteInput)];
      setValue('tag_array', newTagArray);
      setValue('tag_weightages', newTagWeightages);
      setValue('tag_prerequisites', newTagPrerequisites);
      setValue('tag', '');
      setValue('weightage', '');
      setValue('prerequisite', '');
      clearErrors('tag_array');
    }
  };

  const removeTag = (tagIndex) => {
    const newTagArray = tagArray.filter((_, index) => index !== tagIndex);
    const newTagWeightages = tagWeightages.filter(
      (_, index) => index !== tagIndex,
    );
    const newTagPrerequisites = tagPrerequisites.filter(
      (_, index) => index !== tagIndex,
    );
    setValue('tag_array', newTagArray);
    setValue('tag_weightages', newTagWeightages);
    setValue('tag_prerequisites', newTagPrerequisites);
  };

  const onSubmit = (data) => {
    if (!Array.isArray(tagArray) || tagArray.length === 0)
      return setError(
        'tag_array',
        { message: 'Please add atleast one Category' },
        {
          shouldFocus: true,
        },
      );

    const subcategoryWeights = {};
    if (tagMap) {
      tagArray.forEach((tag) => {
        subcategoryWeights[tag.id] = Object.fromEntries(tagMap.get(tag.id));
      });
    }

    data = {
      ...data,
      domain_id: user.data.selected_domain,
      difficulty_level: data.difficulty_level.value,
      no_questions: data.no_questions.value,
      is_template: Boolean(data.is_template),
      cost_in_credits: parseInt(data.cost_in_credits),
      tag_weightages: tagWeightages,
      prerequisite: Object.fromEntries(
        tagPrerequisites
          .map((prerequisite, index) => {
            return [tagArray[index].id, prerequisite];
          })
          .filter((prerequisite) => !!prerequisite[1]),
      ),
      tag_array: tagArray.map((tag) => tag.id),
      subcategory_weights: subcategoryWeights || {},
      image_url: image?.image_url?.url,
    };
    delete data.image;
    delete data.tag;
    delete data.weightage;
    delete data.tag_prerequisites;
    delete data.tag_map;
    delete data.edit_parent_tag;

    if (params.mockTestId) {
      editMockTest(params.mockTestId, data);
    } else {
      createMockTest(data);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
      setValue('image', {
        name: file.name,
      });
    }
  };

  const onClose = () => {
    navigate(-1);
  };

  const editSubCategory = (parentTag) => {
    setSubTagDialogOpen(true);
    setValue('edit_parent_tag', parentTag);
  };

  return (
    <div className="createtest__container">
      <div className="createtest__header">
        <MessageBar
          message={message?.content}
          type={message?.type}
          open={!!message}
          onClose={() => setMessage(undefined)}
        />
        <Typography variant="h5" color="primary">
          {params.mockTestId ? 'Update Test' : 'Create Test'}
        </Typography>
        <IconButton onClick={onClose} color="primary" size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="createtest__content">
        <div className="row">
          <div>
            <label htmlFor="">Name of the test</label>
            <div className="field-item">
              <TextField
                {...register('name', { required: 'Name is required' })}
                fullWidth
                placeholder="Test Title"
                size="small"
                margin="dense"
                variant="outlined"
                className="text-input-field"
              />
              <FormHelperText error={!!errors.name}>
                {errors.name?.message}
              </FormHelperText>
            </div>
          </div>

          {user.data?.user_type === 'Admin' && (
            <>
              <div
                style={{
                  flex: 0,
                }}
              >
                <label htmlFor="">Template</label>
                <div className="field-item">
                  <Checkbox
                    {...register('is_template')}
                    color="primary"
                    defaultChecked
                  />
                </div>
              </div>
              <div>
                <label htmlFor="">Cost (in Credits)</label>
                <div className="field-item">
                  <TextField
                    {...register('cost_in_credits', {
                      min: {
                        value: 0,
                        message: 'Cost cannot be negative',
                      },
                    })}
                    defaultValue={50}
                    type="number"
                    placeholder="Cost in Credit"
                    size="small"
                    margin="dense"
                    variant="outlined"
                    className="text-input-field"
                    inputProps={{ min: 0 }}
                  />

                  <FormHelperText error={!!errors.cost_in_credits}>
                    {errors.cost_in_credits?.message}
                  </FormHelperText>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div>
            <label htmlFor="">Total Questions</label>
            <div className="field-item">
              <Controller
                name="no_questions"
                rules={{ required: 'Total questions is required' }}
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      placeholder={
                        params.mockTestId
                          ? watch('no_questions')
                          : 'No. of Questions'
                      }
                      className="text-input-field"
                      options={new Array(30).fill(0).map((_, i) => ({
                        value: (i + 1) * 10,
                        label: (i + 1) * 10,
                      }))}
                    />
                    <FormHelperText error={!!errors.no_questions}>
                      {errors.no_questions?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </div>
          </div>

          <div>
            <label htmlFor="">Difficulty Level</label>
            <div className="field-item">
              <Controller
                name="difficulty_level"
                control={control}
                rules={{ required: 'Difficulty level is required' }}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      className="text-input-field"
                      placeholder={
                        params.mockTestId
                          ? getDifficultyLabel(watch('difficulty_level'))
                          : 'Difficulty Level'
                      }
                      options={difficulties}
                    />
                    <FormHelperText error={!!errors.difficulty_level}>
                      {errors.difficulty_level?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div>
            <label htmlFor="">List of Categories</label>
            <div className="field-item">
              <Controller
                name="tag"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      options={tagList.filter(
                        (tag) => !tagArray?.includes(tag),
                      )}
                      getOptionLabel={(tag) => tag.name}
                      getOptionValue={(tag) => tag.id}
                      className="text-input-field"
                      placeholder="List of Categories"
                    />

                    <FormHelperText error={!!errors.tag_array}>
                      {errors.tag_array?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </div>
          </div>
          <div className="category__weightage">
            <div>
              <label htmlFor="">Weightage</label>
              <div className="field-item">
                <TextField
                  {...register('weightage')}
                  fullWidth
                  type="number"
                  placeholder="Weightage %"
                  size="small"
                  margin="dense"
                  variant="outlined"
                  className="text-input-field"
                  inputProps={{ min: 0 }}
                />
              </div>
            </div>
          </div>
          <div className="category__prerequisite">
            <div>
              <label htmlFor="">Prerequisite (optional)</label>
              <div className="field-item">
                <TextField
                  {...register('prerequisite', {
                    required: false,
                    min: {
                      value: 0,
                      message: "Number of prequisites can't be zero",
                    },
                  })}
                  fullWidth
                  type="number"
                  placeholder="Prerequisite"
                  size="small"
                  margin="dense"
                  variant="outlined"
                  className="text-input-field"
                />
              </div>
            </div>
          </div>

          <Button variant="contained" onClick={addNewTag}>
            Add
          </Button>

          {tagInput && (
            <Button
              variant="contained"
              onClick={() => setSubTagDialogOpen(true)}
            >
              Add subcategories
            </Button>
          )}

          {(tagInput || editParentTag) && (
            <ChooseSubcategoryDialog
              {...{
                tagInput,
                tagMap,
                editParentTag,
                subTagDialogOpen,
                setSubTagDialogOpen,
                setValueParent: setValue,
              }}
            />
          )}
        </div>
        {tagArray?.map((tag, index) => (
          <div className="createtest__tag" key={tag.id}>
            <p>
              {tag.name}{' '}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  editSubCategory(tag);
                }}
                href=""
              >
                (edit subcategories)
              </a>
            </p>
            <p>{tagWeightages[index]}%</p>
            <p>{tagPrerequisites[index] || ''}</p>
            <IconButton
              onClick={() => removeTag(index)}
              size="small"
              color="primary"
            >
              <CloseIcon />
            </IconButton>
          </div>
        ))}
        <div className="row">
          <div>
            <label htmlFor="">Upload Test Image</label>
            <div className="field-item">
              <input
                accept="image/*"
                id="mocktest_create"
                type="file"
                onChange={handleImageChange}
                hidden
              />
              <label htmlFor="mocktest_create">
                <Button
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  disabled={mockTest.isUploadingImage}
                  endIcon={
                    mockTest.isUploadingImage && (
                      <CircularProgress color="inherit" size={24} />
                    )
                  }
                >
                  Select Image
                </Button>
              </label>
              {image && image.name}
            </div>
          </div>
          <div>
            <label htmlFor="">Mode</label>
            <div className="field-item">
              <Controller
                name="exam_mode"
                control={control}
                defaultValue="flexible"
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="classic"
                      control={<Radio color="primary" />}
                      label="Classic"
                    />
                    <FormControlLabel
                      value="flexible"
                      control={<Radio color="primary" />}
                      label="Flexible"
                    />
                  </RadioGroup>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="createtest__footer">
        <Button
          variant="contained"
          color="primary"
          disabled={mockTest.isCreatingMockTest || mockTest.isUploadingImage}
          endIcon={
            mockTest.isCreatingMockTest && (
              <CircularProgress color="inherit" size={24} />
            )
          }
          onClick={handleSubmit(onSubmit)}
        >
          {params.mockTestId ? 'Update Test' : 'Create Test'}
        </Button>
      </div>
    </div>
  );
}

export default MockTestCreate;
