import React, { useEffect } from 'react';
import { useState } from 'react';
import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import {
  StyledContainer,
  CancelBtn,
  ApplyBtn,
  ContainerGrid,
  DomainGrid,
  DomainName,
  EmptySearch,
  Heading,
  SearchField,
  StyledImage,
  StyledIconButton,
  ToggleBox,
  ContainerWrapper,
  ToggleTextSubject,
  ToggleTextExam,
} from './StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getDomain, changeDomain } from '../../actions/domain';
import SearchIcon from './images/search.svg';
import TickIcon from './images/tick.svg';
import CancelIcon from '@mui/icons-material/Cancel';

const DomainSelect = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('Subject');
  const [changeDomainId, setChangeDomainId] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { list: DomainList = [], exams: ExistingExams = [] } = useSelector(
    (state) => state.domain,
  );
  const [exams, setExams] = useState(ExistingExams);
  const [list, setList] = useState(DomainList);
  const { data = [], selected_domain: userCurrentDomain } = useSelector(
    (state) => state.user,
  );
  const selected_domain = list.find(
    (domain) => domain.id === data.selected_domain,
  );
  useEffect(() => {
    dispatch(
      getDomain(type, 'Accepted', 9, searchTerm, (data) =>
        type === 'Subject' ? setList(data) : setExams(data),
      ),
    );
  }, [type, searchTerm]);

  useEffect(() => {
    if (userCurrentDomain) setChangeDomainId(userCurrentDomain);
  }, [userCurrentDomain]);

  const handleDomainSelect = (id) => {
    setChangeDomainId(id);
    props.changedSelectedDomain(list.find((domain) => domain.id === id));
  };
  const handleChangeDomainSubmit = () => {
    setLoading(true);
    dispatch(
      changeDomain({ domain_id: changeDomainId }, () => {
        props.handleClose();
        window.location.reload();
      }),
    );
  };
  useEffect(() => {
    props.changedSelectedDomain(
      list.find((domain) => domain.id === changeDomainId),
    );
  }, [changeDomainId]);
  return (
    <ContainerWrapper>
      {props.handleClose && (
        <StyledIconButton onClick={() => props.handleClose()} size="large">
          <CancelIcon />
        </StyledIconButton>
      )}
      <Paper>
        <StyledContainer>
          <ToggleBox>
            <ToggleTextSubject type={type} onClick={() => setType('Subject')}>
              Subjects
            </ToggleTextSubject>
            <ToggleTextExam type={type} onClick={() => setType('Exam')}>
              Exams
            </ToggleTextExam>
          </ToggleBox>

          <Heading>Choose the domain of your interest</Heading>
          <Box md={2} textAlign={'center'}>
            <SearchField
              endAdornment={<img src={SearchIcon} />}
              placeholder="Search domains here..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <Box style={{ marginBottom: '10px' }}>
            <ContainerGrid container spacing={1}>
              {(type === 'Subject' ? list : exams)?.length === 0 ? (
                <EmptySearch>No Search Found</EmptySearch>
              ) : (
                (type === 'Subject' ? list : exams)?.map((domain) => (
                  <Grid
                    item
                    md={4}
                    sm={12}
                    xs={12}
                    key={domain.id}
                    height="fit-content"
                  >
                    <DomainGrid
                      onClick={() => handleDomainSelect(domain.id)}
                      changeDomainId={changeDomainId}
                      domain={domain}
                    >
                      <img
                        src={
                          !(
                            selected_domain?.id === domain?.id ||
                            changeDomainId === domain?.id
                          )
                            ? domain.img_url_blue
                            : domain.img_url
                        }
                        width={'25px'}
                        alt=""
                      />{' '}
                      &nbsp;&nbsp;
                      <DomainName
                        changeDomainId={changeDomainId}
                        domain={domain}
                      >
                        {domain.name}
                        {changeDomainId
                          ? changeDomainId === domain?.id && (
                              <StyledImage src={TickIcon} alt="" />
                            )
                          : selected_domain?.id === domain?.id && (
                              <StyledImage src={TickIcon} alt="" />
                            )}
                      </DomainName>
                    </DomainGrid>
                  </Grid>
                ))
              )}
            </ContainerGrid>
          </Box>
          {props.applyBtn && (
            <Box display={'flex'} justifyContent={'flex-end'} mb={'20px'}>
              <ApplyBtn
                variant="contained"
                onClick={handleChangeDomainSubmit}
                disabled={(type === 'Subject' ? list : exams)?.length === 0}
              >
                {loading ? <CircularProgress /> : 'Apply'}
              </ApplyBtn>{' '}
              &nbsp;&nbsp;
              <CancelBtn onClick={() => props.handleClose()}>Cancel</CancelBtn>
            </Box>
          )}
        </StyledContainer>
      </Paper>
    </ContainerWrapper>
  );
};

export default DomainSelect;
