import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  contentLeft: {
    background: theme.palette.common.white,
    boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor}`,
    borderRadius: '10px',
    padding: '20px',
  },
  contentLeft_Heading1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '12px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.dark,
  },
  hotTopicsHeading: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    color: theme.palette.common.black,
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '5px',
    overflow: 'auto',
    maxHeight: '58px',
    width: '90%',
  },
  tag: {
    background: theme.palette.playColors.lightSky,
    borderRadius: '15px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    color: theme.palette.secondary.dark,
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  hotTopicsDescription: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.component.color,
    marginTop: '10px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hotTopicsStudentCount: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.component.color,
    background: theme.palette.playColors.lightSky,
    borderRadius: '15px',
    padding: '5px 15px',
    marginTop: '15px',
    width: 'fit-content',
  },
  TopicsGems: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '17px',
    color: theme.palette.secondary.dark,
    background: theme.palette.playColors.lightSky,
    borderRadius: '15px',
    padding: '5px 15px',
    marginTop: '15px',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: '5px 8px',
    },
  },
  practiceBtn: {
    textTransform: 'none',
    marginTop: '10px',
    marginBottom: '5px',
    background: theme.palette.secondary.dark,
    borderRadius: ' 5px',
    color: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  levelBadge: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.error}`,
    borderRadius: '12.5px 10px 5px 12.5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: theme.palette.common.error,
    padding: '3px 20px',
    width: 'fit-content',
  },
  containerStyle: {
    borderRadius: '10px',
    padding: '20px',
    background: theme.palette.common.white,
    boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor}`,
  },
  activityText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.common.black,
  },
  activityTimeText: {
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '12px',
    color: theme.palette.component.shadowColor2,
    textAlign: 'right',
  },
  invitationBoxBtn: {
    height: '25px',
    borderRadius: '25px',
    textTransform: 'none',
    fontSize: '10px',
    lineHeight: '10px',
  },
  tableHeader: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '9px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.component.color,
    padding: '5px 0px',
  },
  tableRank: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.common.black,
  },
  studentName: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '100%',
    letterSpacing: '0.4px',
    color: theme.palette.common.black,
    display: 'block',
  },
  batch: {
    background: theme.palette.playColors.lightSky,
    color: theme.palette.secondary.dark,
    marginTop: '5px',
  },
  noOfStudents: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '13px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.dark,
    display: 'block',
    marginTop: '5px',
  },
  companyName: {
    color: theme.palette.secondary.dark,
    fontSize: '10px',
    fontWeight: 400,
  },
  collegeStats__enrollIndex: {
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    padding: '0px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '-2px',
  },
  collegeStats__enrollIndexLast: {
    background: theme.palette.common.white,
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 500,
  },
  stats_chart: {
    borderRight: `1px solid ${theme.palette.playColors.lightSky}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  partitionRule: {
    color: theme.palette.playColors.lightSky,
  },
}));

export { useStyles };
