import {
  Button,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { useEffect } from 'react';
import {
  StyledCard,
  CardHeading,
  CardContent,
  FieldItem,
  FormWrapper,
  TagsForm,
  FormControl,
} from '../Domains/StyledComponents';

import {
  getEmailNotification,
  postEmailNotification,
  putEmailNotification,
} from '../../actions/emailNotifications';

const CreateEmailPreference = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    emailNotificationsPreferencesData,
    isPostingEmailNotificationsPreferences,
    isUpdatingEmailNotificationsPreferences,
  } = useSelector((state) => state.EmailNotifications);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const selectedPreference = emailNotificationsPreferencesData?.notifications?.find(
    (data) => data.id === params.notificationPreferenceId,
  );

  useEffect(() => {
    dispatch(getEmailNotification());
  }, []);

  useEffect(() => {
    if (params.notificationPreferenceId) {
      reset({
        name: selectedPreference?.name,
        title: selectedPreference?.title,
        user_type: selectedPreference?.user_type,
      });
    }
  }, [params.notificationPreferenceId]);

  const onSubmitHandler = (data) => {
    if (params.notificationPreferenceId) {
      dispatch(putEmailNotification(params.notificationPreferenceId, data));
    } else {
      dispatch(postEmailNotification(data));
    }
    setTimeout(() => {
      navigate('/l/admin/email-preference');
    }, 2000);
  };
  return (
    <Container maxWidth={false}>
      <StyledCard>
        <CardHeading>
          {!params.notificationPreferenceId
            ? 'Create Preference'
            : 'Edit Preference'}
        </CardHeading>
        <CardContent>
          <FormWrapper>
            <TagsForm>
              <form>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('title', {
                        required: 'Please enter Title',
                      })}
                      name="title"
                      label={'Title'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.title}
                      helperText={errors.title?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <TextField
                      {...register('name', {
                        required: 'Please enter name',
                      })}
                      name="name"
                      label={'Name'}
                      margin="dense"
                      variant="outlined"
                      className="text-input-field"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  </FieldItem>
                </FormControl>
                <FormControl>
                  <FieldItem>
                    <Select
                      {...register('user_type', {
                        required: 'Please select a user type',
                      })}
                      fullWidth
                      style={{ marginTop: '8px', marginBottom: '4px' }}
                      variant="outlined"
                      margin="dense"
                      displayEmpty
                      placeholder="Approval Status"
                      error={!!errors.user_type}
                      defaultValue={
                        params.notificationPreferenceId
                          ? selectedPreference?.user_type
                          : 'Employers'
                      }
                    >
                      <MenuItem value="Employers" key="Employers">
                        Employers
                      </MenuItem>
                      <MenuItem value="Learners" key="Learners">
                        Learners
                      </MenuItem>
                      <MenuItem value="Applicants" key="Applicants">
                        Applicants
                      </MenuItem>
                      <MenuItem value="All" key="All">
                        All
                      </MenuItem>
                    </Select>
                  </FieldItem>
                </FormControl>

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={
                        isPostingEmailNotificationsPreferences ||
                        isUpdatingEmailNotificationsPreferences
                      }
                      type="submit"
                      onClick={handleSubmit(onSubmitHandler)}
                    >
                      {isPostingEmailNotificationsPreferences ||
                      isUpdatingEmailNotificationsPreferences ? (
                        <Loader size={24} color="inherit" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TagsForm>
          </FormWrapper>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default CreateEmailPreference;
