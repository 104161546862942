import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const AdminCoupons = createReducer(
  {
    adminCouponsData: [],
  },
  {
    [types.GET_ADMIN_COUPONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        adminCouponsData: [],
        isFetchingAdminCoupons: true,
      });

      return newState;
    },
    [types.GET_ADMIN_COUPONS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        adminCouponsData: action.data,
        isFetchingAdminCoupons: false,
      });

      return newState;
    },
    [types.GET_ADMIN_COUPONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        adminCouponsData: [],
        isAdminCoupons: false,
      });

      return newState;
    },

    [types.POST_ADMIN_COUPONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingAdminCoupons: true,
      });

      return newState;
    },
    [types.POST_ADMIN_COUPONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingAdminCoupons: false,
      });

      return newState;
    },
    [types.POST_ADMIN_COUPONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingAdminCoupons: false,
      });

      return newState;
    },

    [types.PUT_ADMIN_COUPONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminCoupons: true,
      });

      return newState;
    },
    [types.PUT_ADMIN_COUPONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminCoupons: false,
      });

      return newState;
    },
    [types.PUT_ADMIN_COUPONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminCoupons: false,
      });

      return newState;
    },

    [types.DELETE_ADMIN_COUPONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminCoupons: true,
      });

      return newState;
    },
    [types.DELETE_ADMIN_COUPONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminCoupons: false,
      });

      return newState;
    },
    [types.DELETE_ADMIN_COUPONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminCoupons: false,
      });

      return newState;
    },
  },
);

export { AdminCoupons as default };
