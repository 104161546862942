import React, { useEffect } from 'react';
import {
  Route,
  useLocation,
  useNavigate,
  useParams,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LoginContainer from './../containers/LoginContainer';
import SignUpContainer from './../containers/SignUpContainer';
import ForgotPasswordContainer from './../containers/ForgotPasswordContainer';
import UpdatePasswordContainer from './../containers/UpdatePasswordContainer';
import LayoutContainer from './../containers/LayoutContainer';
import Competition from '../containers/CompetitionContainer';
import CompetitionDetails from '../containers/CompetitionDetailsContainer';

import Auth from './../components/Auth';
import PublicAuthModal from './../components/PublicAuthModal';
import LayoutPublic from '../components/Layout/LayoutPublic';

import { autoLogin } from './../actions/auth';
import { getResumeDetails } from '../actions/user';
import UserProfileInfoContainer from '../containers/UserProfileInfoContainer';
import DomainSelectModal from '../components/Domains/DomainSelectModal';
import { closeDomainModal } from '../actions/domain';

const addGoogleAnalyticsScript = () => {
  const script = document.createElement('script');
  const body = document.querySelector('body');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-J8ZDYR9T3R';
  script.async = true;
  body.appendChild(script);
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());

  gtag('config', 'G-J8ZDYR9T3R');
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}

const AllRoutes = (props) => {
  const dispatch = useDispatch();
  const { location, navigate } = props;
  let background = location.state && location.state.background;
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const domain = useSelector((state) => state.domain);

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'production') {
    addGoogleAnalyticsScript();
  }

  useEffect(() => {
    if (!auth.loginSuccess) {
      dispatch(autoLogin(true));
    }
  }, [auth.loginSuccess, dispatch]);

  useEffect(() => {
    if (auth.loginSuccess && user.isUserFetchSuccess) {
      dispatch(getResumeDetails());
    }
  }, [
    auth.loginSuccess,
    user.selected_domain,
    dispatch,
    user.isUserFetchSuccess,
  ]);

  const handleClose = () => {
    navigate(background.pathname);
  };

  useEffect(() => {
    const pinnedOptionUrl =
      JSON.parse(localStorage.getItem('pinnedOptionUrl')) || '';
    const userInfo = user?.data;
    const isAdmin = userInfo?.user_type === 'Admin';

    if (isAdmin && location.pathname === '/l') {
      const defaultURL = isAdmin ? '/l/adminFeatures' : '/l/dashboard';
      const mainURL =
        pinnedOptionUrl === '' ? defaultURL : '/' + pinnedOptionUrl;
      navigate(mainURL);
    }
  }, [user?.data, location.pathname, navigate]);

  return (
    <>
      <DomainSelectModal
        open={domain.domainSelectModalOpen}
        onClose={() => dispatch(closeDomainModal())}
      />
      <Routes location={background || location}>
        <Route
          path={`/login`}
          element={
            <Auth>
              <LoginContainer {...props} />
            </Auth>
          }
        />
        <Route
          path={`/signup`}
          element={
            <Auth>
              <SignUpContainer {...props} />
            </Auth>
          }
        />
        <Route
          path={`/password/reset`}
          element={
            <Auth>
              <ForgotPasswordContainer {...props} />
            </Auth>
          }
        />
        <Route
          path={`/forget-password`}
          element={
            <Auth>
              <UpdatePasswordContainer {...props} />
            </Auth>
          }
        />
        <Route path="/l/*" element={<LayoutContainer {...props} />} />
        <Route
          path={`/profile/:username`}
          element={
            <LayoutPublic {...props}>
              <UserProfileInfoContainer />
            </LayoutPublic>
          }
        />
        <Route
          path={`/competition`}
          element={
            <LayoutPublic {...props}>
              <Competition />
            </LayoutPublic>
          }
        />

        <Route
          path={`/competition/:competitionId`}
          element={
            <LayoutPublic {...props}>
              <CompetitionDetails />
            </LayoutPublic>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/l" />}></Route>
      </Routes>
      <Routes>
        {background && (
          <Route
            path={`/signup`}
            element={
              <PublicAuthModal open={true} handleClose={handleClose}>
                <SignUpContainer {...props} />
              </PublicAuthModal>
            }
          />
        )}
        {background && (
          <Route
            path={`/login`}
            element={
              <PublicAuthModal open={true} handleClose={handleClose}>
                <LoginContainer {...props} />
              </PublicAuthModal>
            }
          ></Route>
        )}
        {background && (
          <Route
            path={`/password/reset`}
            element={
              <PublicAuthModal open={true} handleClose={handleClose}>
                <ForgotPasswordContainer {...props} />
              </PublicAuthModal>
            }
          ></Route>
        )}
      </Routes>
    </>
  );
};

export default withRouter(AllRoutes);
