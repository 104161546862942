import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Stack, StepConnector, stepConnectorClasses } from '@mui/material';

const ColorlibConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#3572B0',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#3572B0',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
  },
});

const GreenStepLabel = styled(StepLabel)(({ completed }) => ({
  '& .MuiStepIcon-root': {
    color: completed ? '#3572B0' : '#C0C0C0',
    zIndex: '1',
  },
}));

export default function CustomizedStepper({
  steps,
  activeStep,
  milestonesCompleted,
}) {
  const reorderSteps = (steps, completedSteps) => {
    if (completedSteps?.length > 0) {
      const remainingSteps = steps.filter(
        (step) => !completedSteps?.includes(step),
      );
      return [...completedSteps, ...remainingSteps];
    }
    return steps;
  };
  return (
    <Stack sx={{ width: '100%', marginTop: '5px' }} spacing={10}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {reorderSteps(steps, milestonesCompleted).map((label, index) => (
          <Step key={label}>
            <GreenStepLabel completed={index <= activeStep - 1}>
              <span style={{ fontSize: '13px' }}>{label.toUpperCase()}</span>
            </GreenStepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
