import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const dataManagement = createReducer(
  {
    collegeData: [],
    companyData: [],
    exams: [],
    jobRole: [],
    imageUrl: '',
    adminSkill: [],
  },
  {
    [types.GET_COLLEGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCollegeList: true,
      });

      return newState;
    },
    [types.GET_COLLEGE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        collegeData: action.data,
        isFetchingCollegeList: false,
        isMergeDuplicateSuccess: false,
        imageUrl: '',
      });

      return newState;
    },
    [types.GET_COLLEGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCollegeList: false,
      });

      return newState;
    },

    [types.POST_COLLEGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCollege: true,
      });

      return newState;
    },
    [types.POST_COLLEGE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCollege: false,
      });

      return newState;
    },
    [types.POST_COLLEGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCollege: false,
      });

      return newState;
    },

    [types.UPDATE_COLLEGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCollege: true,
      });

      return newState;
    },
    [types.UPDATE_COLLEGE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCollege: false,
      });

      return newState;
    },
    [types.UPDATE_COLLEGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCollege: false,
      });

      return newState;
    },

    [types.GET_COMPANY_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCompanyList: true,
      });

      return newState;
    },
    [types.GET_COMPANY_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        companyData: action.data,
        isFetchingCompanyList: false,
        isMergeDuplicateSuccess: false,
        imageUrl: '',
      });

      return newState;
    },
    [types.GET_COMPANY_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCompanyList: false,
      });

      return newState;
    },

    [types.POST_COMPANY_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCompany: true,
      });

      return newState;
    },
    [types.POST_COMPANY_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCompany: false,
      });

      return newState;
    },
    [types.POST_COMPANY_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingCompany: false,
      });

      return newState;
    },

    [types.UPDATE_COMPANY_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCompany: true,
      });

      return newState;
    },
    [types.UPDATE_COMPANY_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCompany: false,
      });

      return newState;
    },
    [types.UPDATE_COMPANY_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingCompany: false,
      });

      return newState;
    },

    [types.GET_EXAM_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingExamList: true,
      });

      return newState;
    },
    [types.GET_EXAM_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        exams: action.data,
        isFetchingExamList: false,
        isMergeDuplicateSuccess: false,
        imageUrl: '',
      });

      return newState;
    },
    [types.GET_EXAM_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingExamList: false,
      });

      return newState;
    },

    [types.POST_EXAM_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingExam: true,
      });

      return newState;
    },
    [types.POST_EXAM_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingExam: false,
      });

      return newState;
    },
    [types.POST_EXAM_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingExam: false,
      });

      return newState;
    },

    [types.UPDATE_EXAM_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingExam: true,
      });

      return newState;
    },
    [types.UPDATE_EXAM_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingExam: false,
      });

      return newState;
    },
    [types.UPDATE_EXAM_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingExam: false,
      });

      return newState;
    },

    [types.POST_IMAGE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: true,
      });

      return newState;
    },
    [types.POST_IMAGE_SUCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: false,
        imageUrl: action.data,
      });

      return newState;
    },
    [types.POST_IMAGE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUploadingImage: false,
      });

      return newState;
    },

    [types.GET_JOB_ROLE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingJobRoleList: true,
      });

      return newState;
    },
    [types.GET_JOB_ROLE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        jobRole: action.data,
        isFetchingJobRoleList: false,
        isMergeDuplicateSuccess: false,
        imageUrl: '',
      });

      return newState;
    },
    [types.GET_JOB_ROLE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingJobRoleList: false,
      });

      return newState;
    },

    [types.POST_JOB_ROLE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingJobRole: true,
      });

      return newState;
    },
    [types.POST_JOB_ROLE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingJobRole: false,
      });

      return newState;
    },
    [types.POST_JOB_ROLE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingJobRole: false,
      });

      return newState;
    },

    [types.UPDATE_JOB_ROLE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingJobRole: true,
      });

      return newState;
    },
    [types.UPDATE_JOB_ROLE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingJobRole: false,
      });

      return newState;
    },
    [types.UPDATE_JOB_ROLE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingJobRole: false,
      });

      return newState;
    },

    [types.DELETE_JOB_ROLE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJobRole: true,
      });

      return newState;
    },
    [types.DELETE_JOB_ROLE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJobRole: false,
      });

      return newState;
    },
    [types.DELETE_JOB_ROLE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJobRole: false,
      });

      return newState;
    },
    [types.MERGE_DULTICATES_START](state) {
      return {
        ...state,
        isMergeDuplicateStart: true,
        isMergeDuplicateSuccess: false,
        isMergeDuplicateFail: false,
      };
    },
    [types.MERGE_DULTICATES_SUCCESS](state) {
      return {
        ...state,
        isMergeDuplicateStart: false,
        isMergeDuplicateSuccess: true,
        isMergeDuplicateFail: false,
      };
    },
    [types.MERGE_DULTICATES_FAIL](state) {
      return {
        ...state,
        isMergeDuplicateStart: false,
        isMergeDuplicateSuccess: false,
        isMergeDuplicateFail: true,
      };
    },
    [types.GET_ADMIN_SKILLS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingAdminSkillsList: true,
      });

      return newState;
    },
    [types.GET_ADMIN_SKILLS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        adminSkill: action.data,
        isFetchingAdminSkillsList: false,
      });

      return newState;
    },
    [types.GET_ADMIN_SKILLS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingAdminSkillsList: false,
      });

      return newState;
    },

    [types.POST_ADMIN_SKILLS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingAdminSkill: true,
      });

      return newState;
    },
    [types.POST_ADMIN_SKILLS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingAdminSkill: false,
      });

      return newState;
    },
    [types.POST_ADMIN_SKILLS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isAddingAdminSkill: false,
        isAddingAdminSkillFail: true,
      });

      return newState;
    },

    [types.UPDATE_ADMIN_SKILLS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSkill: true,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_SKILLS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSkill: false,
      });

      return newState;
    },
    [types.UPDATE_ADMIN_SKILLS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingAdminSkill: false,
        isUpdatingAdminSkillFail: true,
      });

      return newState;
    },

    [types.DELETE_ADMIN_SKILLS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSkill: true,
      });

      return newState;
    },
    [types.DELETE_ADMIN_SKILLS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSkill: false,
      });

      return newState;
    },
    [types.DELETE_ADMIN_SKILLS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingAdminSkill: false,
      });

      return newState;
    },
  },
);

export { dataManagement as default };
