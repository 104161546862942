import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCompetitionsSchedules,
  getCompetitionsSchedules,
} from '../../../actions/competition';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';

export const CompetitionSchedule = () => {
  const dispatch = useDispatch();
  const { isCompetitionsScheduleFecthing, schedules } = useSelector(
    (state) => state.competitionsSchedules,
  );
  const { scheduled_competitions } = schedules;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getCompetitionsSchedules(page + 1, rowsPerPage));
  }, [page, rowsPerPage]);

  const [isDeletingSchedule, setIsDeletingSchedule] = useState(false);
  const [currentSelectedSchedule, setCurrentSelectedSchedule] = useState(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleConfirmDeleteDialog = (scheduleId) => {
    setCurrentSelectedSchedule(scheduleId);
    setDeleteConfirmDialogOpen(true);
  };

  const onPageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleConfirmDeleteSuccess = () => {
    setIsDeletingSchedule(true);
    dispatch(deleteCompetitionsSchedules(currentSelectedSchedule))
      .then(() => {
        enqueueSnackbar('Schedule deleted successfully', {
          variant: 'success',
        });

        dispatch(getCompetitionsSchedules(1, 100));
      })
      .catch(() => {
        enqueueSnackbar('Sorry! Unable to Delete schedule', {
          variant: 'error',
        });
      });
    setCurrentSelectedSchedule(null);
    setDeleteConfirmDialogOpen(false);
    setIsDeletingSchedule(false);
  };
  const handleConfirmDeleteCancel = () => {
    setCurrentSelectedSchedule(null);
    setDeleteConfirmDialogOpen(false);
  };

  return (
    <Container maxWidth="xl">
      <Box display="flex" py={2}>
        <Box flex="1" fontWeight="fontWeightRegular" fontSize={21}>
          <Link to={'/l/competition_schedule/create'}>
            <Button variant="outlined" color="primary">
              Create Schedule
            </Button>
          </Link>
        </Box>
      </Box>
      <Card elevation={0} square>
        {isCompetitionsScheduleFecthing ? (
          '...Loading...'
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Competition Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Live Count</TableCell>
                <TableCell>Recurrence Days</TableCell>
                <TableCell>Valid Until</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduled_competitions &&
                scheduled_competitions.map((schedule, index) => (
                  <TableRow key={index}>
                    <TableCell>{schedule?.competition_name}</TableCell>
                    <TableCell>
                      {schedule?.is_active ? (
                        <DotIcon htmlColor="lightgreen" />
                      ) : (
                        <DotIcon htmlColor="red" />
                      )}
                    </TableCell>
                    <TableCell>{schedule?.max_live_count}</TableCell>
                    <TableCell>{schedule?.recurrence_days}</TableCell>
                    <TableCell>
                      {schedule?.valid_until?.split('T')[0]}
                    </TableCell>
                    <TableCell>
                      <Link to={`/l/competition_schedule/${schedule?.id}`}>
                        <EditIcon />
                      </Link>
                    </TableCell>
                    <TableCell>
                      {currentSelectedSchedule === schedule?.id ? (
                        isDeletingSchedule ? (
                          <CircularProgress color="red" />
                        ) : (
                          <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            color="error"
                            onClick={() =>
                              handleConfirmDeleteDialog(schedule?.id)
                            }
                          />
                        )
                      ) : (
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          color="error"
                          onClick={() =>
                            handleConfirmDeleteDialog(schedule?.id)
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        {!isCompetitionsScheduleFecthing && (
          <TablePagination
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            count={schedules?.total_pages * schedules?.page_size}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
            style={{ padding: '0' }}
          />
        )}
      </Card>

      {deleteConfirmDialogOpen && (
        <Dialog
          open={deleteConfirmDialogOpen}
          onClose={() => setDeleteConfirmDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Confirm Delete Schedule'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this schedule ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteCancel}>Cancel</Button>
            <Button
              onClick={handleConfirmDeleteSuccess}
              style={{ color: 'red' }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};
