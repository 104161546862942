import React, { useState } from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from '@mui/material';

import ShippingDetailsDialog from './ShippingDetailsDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserProductsList,
  getCartList,
  getShippingAddressList,
  updateCart,
} from '../../actions/userStore';
import { useNavigate } from 'react-router-dom';
import Gems from '../Gems';
import CrossIcon from '../../images/cross.svg';

import {
  StyleFormLabel,
  ProductDetailsQuantityInput,
  ProductDetailsFabIcon,
  DetailsDescription,
  DetailsImgContainer,
  DetailsImg,
  ProductDetailsDialogContent,
} from './StoreStyledComponent';

const StoreProductDetailsDialog = ({ open, handleClick, dialogProductId }) => {
  // const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quantityCount, setQuantityCount] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [paymentMode, setPaymentMode] = useState('Currency');
  const [enoughGems, setEnoughGems] = useState(true);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const increaseCount = () => {
    setQuantityCount(Math.min(quantityCount + 1, 10));
  };

  const decreaseCount = () => {
    setQuantityCount(Math.max(quantityCount - 1, 1));
  };

  React.useEffect(() => {
    dispatch(getUserProductsList());
    dispatch(getShippingAddressList());
    dispatch(getCartList());
  }, [dispatch]);

  React.useEffect(() => {
    setQuantityCount(1);
  }, [open]);

  const { userProductsList, shippingAddress, cartItems } = useSelector(
    (state) => state.userStore,
  );
  const { data } = useSelector((state) => state.user);

  const product =
    userProductsList?.find((product) => product.id === dialogProductId) || {};

  const handleAddToCart = (str) => {
    const arr = [];
    const dataItem = {
      item_id: product.id,
      quantity: quantityCount,
      payment_mode: paymentMode,
    };
    try {
      for (let i = 0; i < cartItems.cart?.length; i++) {
        const prevData = {
          item_id: cartItems.cart[i]?.item_id,
          quantity: cartItems.cart[i]?.quantity,
          payment_mode: cartItems.cart[i]?.total_amount_in_currency
            ? 'Currency'
            : 'Gems',
        };
        arr.push(prevData);
      }
      arr.push(dataItem);

      if (product.gross_price_in_gems_after_disc === 0) {
        if (
          paymentMode === 'Gems' &&
          product.gross_price_in_gems * quantityCount > data.gems
        ) {
          setEnoughGems(false);
        } else {
          dispatch(updateCart(arr));
          handleClick();
        }
      } else if (
        paymentMode === 'Gems' &&
        product.gross_price_in_gems_after_disc * quantityCount > data.gems
      ) {
        setEnoughGems(false);
      } else {
        if (str === 'checkout') {
          dispatch(updateCart(arr));
          navigate('/l/store/cart');
        } else {
          dispatch(updateCart(arr));
          handleClick();
        }
      }
    } catch (err) {}
  };

  return (
    <Dialog
      onClose={handleClick}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          padding: '0',
          width: '100%',
          maxWidth: '1051px',
          color: '#25507B',
        },
      }}
    >
      <Grid container>
        <DetailsImgContainer item xs={12} sm={6} md={4}>
          <DetailsImg
            alt={product.name}
            src={product.image_url && product.image_url[0]}
            variant="square"
          />
        </DetailsImgContainer>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClick} size="large">
              <img
                src={CrossIcon}
                alt="crossIcon"
                width={'18px'}
                height={'18px'}
              />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <ProductDetailsDialogContent>
              <Typography
                variant="h5"
                style={{ fontSize: '22px', fontWeight: 600 }}
              >
                {' '}
                {product.name}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: '12px',
                  color: '#76767F',
                  textAlign: 'justify',
                }}
              >
                {' '}
                {product.item_description}
              </Typography>
              {product.long_description ? (
                <DetailsDescription>
                  {product.long_description ? (
                    <ul>
                      {product.long_description
                        ?.split('.')
                        .filter((s) => s)
                        .map((str) => (
                          <li key={Math.random()}>{str}</li>
                        ))}
                    </ul>
                  ) : (
                    ''
                  )}
                </DetailsDescription>
              ) : (
                <Typography style={{ height: '123px' }}></Typography>
              )}
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <StyleFormLabel component="legend">Pay With</StyleFormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="Gems"
                      value={paymentMode}
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      {!product.is_reward && (
                        <FormControlLabel
                          value="Currency"
                          control={<Radio color="primary" />}
                          label={
                            <span
                              style={{ fontSize: '15px', color: '#5FB762' }}
                            >{`₹ ${product.gross_price_in_currency_after_disc}`}</span>
                          }
                        />
                      )}

                      <FormControlLabel
                        style={{ marginRight: '2px' }}
                        value="Gems"
                        control={<Radio color="primary" />}
                        label={
                          <div
                            style={{
                              fontSize: '15px',
                              color: '#3572B0',
                              fontWeight: 600,
                            }}
                          >
                            {`${product.gross_price_in_gems_after_disc}`}{' '}
                            <Gems width={'12px'} />
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  {!enoughGems && (
                    <Typography
                      style={{
                        color: 'red',
                        paddingBottom: '15px',
                        fontWeight: 600,
                      }}
                    >
                      Not Enough Gems
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    <StyleFormLabel component="legend">Quantity</StyleFormLabel>
                    <Box
                      flexDirection="row"
                      display="flex"
                      style={{ marginTop: '5px' }}
                    >
                      <ProductDetailsFabIcon onClick={decreaseCount}>
                        -
                      </ProductDetailsFabIcon>
                      <div>
                        <ProductDetailsQuantityInput
                          variant="outlined"
                          type="text"
                          value={quantityCount}
                          InputProps={{
                            style: { height: '22px' },
                          }}
                        />
                      </div>
                      <ProductDetailsFabIcon onClick={increaseCount}>
                        +
                      </ProductDetailsFabIcon>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </ProductDetailsDialogContent>
          </Box>
          <DialogActions style={{ marginTop: 'auto' }}>
            <Button
              variant="text"
              type="button"
              onClick={() => handleAddToCart('addToCart')}
            >
              Add to Cart
            </Button>
            {shippingAddress ? (
              <>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  onClick={() => handleAddToCart('checkout')}
                >
                  Checkout({quantityCount})
                </Button>
              </>
            ) : (
              <Button
                variant="text"
                type="button"
                color="primary"
                onClick={handleDialogOpen}
              >
                Checkout({quantityCount})
              </Button>
            )}
          </DialogActions>
        </Grid>
      </Grid>
      {openDialog ? (
        <ShippingDetailsDialog
          openDialog={handleDialogOpen}
          closeDialog={handleDialogClose}
        />
      ) : null}
    </Dialog>
  );
};

export default StoreProductDetailsDialog;
