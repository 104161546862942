import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  CardActions,
  Box,
  Card,
  CardMedia,
  Typography,
  Button,
  LinearProgress,
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import NextLevelBackground from './images/next-level-background.svg';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CreditIcon from '../../images/credits-icon.svg';
import GemsIcon from '../../images/gems-icon.svg';

import classnames from 'classnames';

import './index.scss';

const useStyles = makeStyles((theme) => ({
  space: {
    marginBottom: '10',
  },
  card: {
    width: '100%',
    boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
    maxWidth: '360px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '20px',
  },
  media: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  mediaImage: {
    height: '70px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px 15px',
    gap: '5px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    cursor: 'pointer',
  },
  competitionChip: {
    position: 'absolute',
    top: 3,
    right: -6,
    height: 16,
    background: theme.palette.common.warning,
  },
  chipText: {
    fontSize: 10,
    color: theme.palette.common.white,
  },
  nextBadgeIcon: {
    position: 'absolute',
    right: '0px',
    top: '-10px',
    cursor: 'pointer',
    background: `no-repeat url(${NextLevelBackground})`,
    fontWeight: '500',
    color: 'white',
    height: '25px',
    width: '18px',
    textAlign: 'center',
  },
}));

const RewardTypography = styled(Typography)({
  borderRadius: '10px',
  background: '#25507B',
  width: '40px',
  textAlign: 'center',
  fontWeight: '500',
});

const NextBadgePopup = ({ credit_reward, gem_reward, img_url }) => (
  <Grid
    p="10px"
    sx={{
      background: 'white',
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
      borderRadius: '10px',
    }}
    width={'170px'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    gap="10px"
  >
    <img src={img_url} alt="icon" width={'140px'} height="50px" />
    <Typography fontSize={'14px'} color="black" fontWeight={'600'}>
      Level Up Ahead!
      <br />
      Rewards Await!
    </Typography>
    <Box display={'flex'} gap="10px">
      {credit_reward && (
        <Box display={'flex'} gap="5px">
          <img src={CreditIcon} alt="credit-icon" width={20} height={20} />
          <RewardTypography>{credit_reward}</RewardTypography>
        </Box>
      )}
      {gem_reward && (
        <Box display={'flex'} gap="5px">
          <img src={GemsIcon} alt="gems-icon" width={20} height={20} />
          <RewardTypography>{gem_reward}</RewardTypography>
        </Box>
      )}
    </Box>
  </Grid>
);

const CategoryCard = (props) => {
  const classes = useStyles();
  const levels = useSelector((state) => state.levels?.levelData?.levels) || [];
  const { item, handleStartQuiz } = props;

  const unlockedUntil = moment(item.unlocked_until).fromNow();

  const levelImg = levels.find(
    (level) => level.number === (item.user_level_details.curr_level ?? 1),
  )?.image_url;

  const nextLevel = levels.find(
    (level) => level.number == (item.user_level_details.next_level ?? 2),
  );

  return (
    <div
      className={classnames('category-card', {
        'category-card__locked': !item.is_unlocked,
      })}
      key={Boolean(item) && item.id}
    >
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardMedia className={classes.media}>
            <img
              className={classes.mediaImage}
              src={item.s3_url ? item.s3_url : undefined}
              alt={`${item.name}`}
            />
            {levelImg && (
              <img src={levelImg} alt="level-icon" width={85} height={30} />
            )}
          </CardMedia>
          <Grid className={classes.content}>
            <Typography fontSize="16px" fontWeight={'600'} color="black">
              {item.name}
            </Typography>
            <Typography fontSize={'12px'} mb="3px">
              {item.sub_tags?.length} Subtopics
            </Typography>
            <Typography fontSize={'14px'}>{`${item.description?.slice(0, 70)}${
              item.description?.length > 70 ? '...' : ''
            }`}</Typography>
            <Typography fontSize={'12px'}>Progress</Typography>
            <Box position={'relative'}>
              <LinearProgress
                variant="determinate"
                value={
                  ((item.user_level_details?.curr_score ?? 0) * 100) /
                    item.user_level_details?.next_score_cutoff ?? 1
                }
                sx={{
                  '& .MuiLinearProgress-bar1Determinate': {
                    backgroundColor: '#2FC494',
                  },
                  backgroundColor: '#D9D9D9',
                  height: '6px',
                  borderRadius: '10px',
                  mr: '10px',
                }}
              />
              <Tooltip
                enterTouchDelay={50}
                title={
                  <NextBadgePopup
                    img_url={nextLevel?.image_url}
                    credit_reward={nextLevel?.credit_reward}
                    gem_reward={nextLevel?.gem_reward}
                  />
                }
              >
                <Typography className={classes.nextBadgeIcon}>
                  {item.user_level_details?.next_level ?? 2}
                </Typography>
              </Tooltip>
            </Box>
            <Typography color="primary" fontSize="12px">
              {item.user_level_details.curr_score ? (
                <>
                  <b>{item.user_level_details?.curr_score}</b>&nbsp;of&nbsp;
                  <b>{item.user_level_details?.next_score_cutoff}</b>&nbsp;Score
                </>
              ) : (
                <b>Start Now!</b>
              )}
            </Typography>
            <Grid display={'flex'} justifyContent={'space-between'} mt="10px">
              <Chip
                label={
                  item.is_unlocked
                    ? item.unlocked_until != null
                      ? `${unlockedUntil.slice(
                          unlockedUntil.search('in') + 3,
                        )} left`
                      : '3 Months left'
                    : 'Unlock for 3 Month'
                }
                sx={{
                  background: '#F1F3F4',
                  borderRadius: '5px',
                  color: '#767F8E',
                }}
                avatar={<TimerOutlinedIcon />}
              />
              {item.is_unlocked && (
                <Box>
                  <Typography fontSize={'12px'} lineHeight={'15px'}>
                    Next Level Up
                  </Typography>
                  <Typography
                    fontSize={'12px'}
                    lineHeight={'15px'}
                    color="primary"
                  >
                    {item.user_level_details?.next_score_cutoff ?? 1} Score
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
        <CardActions
          className={classes.cardActions}
          onClick={() => handleStartQuiz(item)}
        >
          {item.is_unlocked ? (
            <Button color="primary" variant="contained" sx={{ flexGrow: 1 }}>
              <PlayCircleOutlineIcon style={{ marginRight: '5px' }} />
              Start Quiz
            </Button>
          ) : (
            <Button
              className="walkthrough_target_start-quiz"
              sx={{
                flexGrow: 1,
                background: '#DCDCDD',
                color: 'primary',
                '&:hover': {
                  background: '#DCDCDD',
                },
              }}
            >
              <LockIcon style={{ marginRight: '5px' }} />
              Unlock
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default CategoryCard;
