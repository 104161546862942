import { styled } from '@mui/styles';

export const StyledImagePicker = styled('div')(({ theme }) => ({
  '& .image-picker': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    gap: '25px',
  },

  '& .image-picker & input': {
    width: '1px',
    height: '1px',
    opacity: '0',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: '-1',
  },

  '& .image-picker & label': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '10px',
    color: theme.palette.component.color,
  },

  '& .image-picker & .preview': {
    position: 'relative',
    width: '90px',
    height: '90px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: `1px solid ${theme.palette.component.shadowColor2}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  '& .image-picker & .preview--fullscreen--backdrop': {
    position: 'fixed',
    top: '0px',
    left: '0px',
    height: '100vh',
    width: '100vw',
    zIndex: '10000',
    backgroundColor: theme.palette.component.shadowColor2,
  },

  '& .image-picker & .preview--fullscreen': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '350px',
    height: '350px',
    backgroundSize: 'contain',
    border: `1px solid ${theme.palette.component.shadowColor2}`,
    backgroundColor: theme.palette.component.shadowColor2,
    zIndex: '10',
    cursor: 'pointer',
  },

  '& .clear-button': {
    textAlign: 'center',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '20px',
  },
}));
