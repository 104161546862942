import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      body1: {
        fontSize: 14,
      },
    },
    components: {
      MuiPaper: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiTablePagination: {
        actions: {
          display: 'flex',
          flexDirection: 'row',
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
    },
    palette: {
      common: {
        black: '#000',
        white: '#fff',
        lightCaption: 'rgba(152, 152, 152, 0.7)',
        text: 'rgba(0, 0, 0, 0.87)',
        success: '#4caf50',
        warning: 'rgb(233, 196, 106)',
        error: '#f44336',
        info: '#00bcd4',
        background: '#F8F8F8',
      },
      primary: {
        main: '#25507B',
        dark: '#25507B',
        background: '#DAE5F0',
      },
      secondary: {
        main: '#3572B0',
        dark: '#25507B',
        background: '#DAE5F0',
        button: '#F1F2F7',
        color: '#eff2f7',
      },
      error: {
        main: red.A400,
      },
      success: {
        main: '#4caf50',
        contrastText: '#ffffff',
      },
      background: {
        main: '#fff',
      },
      text: {
        main: '#7B7B7B',
      },
      component: {
        color: '#767676',
        details: 'rgba(152, 152, 152, 0.7)',
        title: 'rgba(0, 0, 0, 0.7)',
        border: 'rgba(0, 0, 0, 0.1)',
        background: '#000000',
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowColor2: 'rgba(0, 0, 0, 0.5)',
        ToggleBoxBorder: 'rgba(37, 80, 123, 0.4)',
      },
      playColors: {
        purple: '#65257B',
        sky: '#21A9DE',
        lightSky: '#21A9DE14',
        lightPurple: '#a178af',
        lightRed: '#f0f0f0',
        lightRed1: 'rgba(213, 0, 0, 0)',
        lightPink1: '#FBE6E6',
        lightPink2: '#e6cbe3',
        lightYellow1: '#FFFFCC',
        lightYellow2: '#eecc8c',
        lightGreen1: '#D4EDDA',
        lightGreen2: '#9fdd9d',
        borderBlue: '#76767f40',
        gold: '#b5b338',
        cyan: '#38a9b5',
        orange: '#b55e38',
        blue: '#3864b5',
        pink: '#b538ad',
      },
      mui: {
        primary: '#3f51b5',
      },
    },
  }),
);

export default theme;
