import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = ({
  data,
  backgroundColors,
  isHorizontal = true,
  xticks,
  yDisplay = true,
  maintainAspectRatio = true,
}) => {
  const options = {
    scales: {
      x: {
        grid: { display: false },
        ticks: xticks,
      },
      y: {
        grid: { display: false },
        display: yDisplay,
      },
    },
    indexAxis: isHorizontal ? 'y' : 'x',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: maintainAspectRatio,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const BarData = {
    labels: Object.keys(data).map(
      (name) => name.charAt(0).toUpperCase() + name.slice(1),
    ),
    datasets: [
      {
        data: Object.values(data),
        borderColor: backgroundColors,
        backgroundColor: backgroundColors,
      },
    ],
  };
  return <Bar options={options} data={BarData} />;
};

export default BarChart;
