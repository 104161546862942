import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  toggleBox: {
    height: '45px',
    width: '250px',
    background: theme.palette.common.white,
    borderRadius: '25px',
    boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
    margin: 'auto',
  },
  toggleText: {
    fontSize: '18px',
    margin: 'auto',
    padding: '0px 20px 0px 20px',
    borderRadius: '25px',
    ['&:hover']: {
      cursor: 'pointer',
    },
  },
  tableHeader: {
    marginBottom: '15px',
    background: theme.palette.secondary.main,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  bodyRow: {
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${theme.palette.component.border}`,
    borderRadius: '5px',
    marginBottom: '10px',
    height: '50px',
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
    },
  },
  dateCell: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  responsiveDate: {
    fontSize: '10px',
    color: theme.palette.text.main,
    fontWeight: 400,
    lineHeight: '8px',
    letterSpacing: '0.4px',
    display: 'none',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  headerText: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
    },
  },
}));

export { useStyles };
