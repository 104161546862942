import { Configuration } from './client/index';
import Config from '../config';

import {
  JobApiApi,
  CompetitionApiApi,
  GeneralApiApi,
  DiscussionApiApi,
  MailApiApi,
  UserApiApi,
  LevelApiApi,
  OrganizationApiApi,
  QuestionApiApi,
  StudentManagementApiApi,
  SmsApiApi,
  MocktestApiApi,
  ExamApiApi,
  BadgeApiApi,
  CreditsApiApi,
  FilesApiApi,
  PaymentApiApi,
  TagApiApi,
  ReviewApiApi,
  TokenApiApi,
  DomainApiApi,
  ScoreApiApi,
  NotificationApiApi,
  CouponApiApi,
  ManagementApiApi,
  SalesApiApi,
} from './client/api';

class ApiClient {
  public apiKey?: string;

  getAPIConfiguration = () => {
    const config = new Configuration({
      basePath: Config.API_URL,
      apiKey: this.apiKey,
      accessToken: this.apiKey,
    });

    config.isJsonMime = () => false;

    return config;
  };

  public setApiKey = (value: string) => {
    this.apiKey = value;
  };

  public getJobApi = () => {
    const api = new JobApiApi(this.getAPIConfiguration());

    return api;
  };

  public getCompetitionApi = () => {
    const api = new CompetitionApiApi(this.getAPIConfiguration());

    return api;
  };

  public getGeneralApi = () => {
    const api = new GeneralApiApi(this.getAPIConfiguration());

    return api;
  };

  public getDiscussionApi = () => {
    const api = new DiscussionApiApi(this.getAPIConfiguration());

    return api;
  };

  public getMailApi = () => {
    const api = new MailApiApi(this.getAPIConfiguration());

    return api;
  };

  public getUserApi = () => {
    const api = new UserApiApi(this.getAPIConfiguration());

    return api;
  };

  public getLevelApi = () => {
    const api = new LevelApiApi(this.getAPIConfiguration());

    return api;
  };

  public getOrganisationAPI = () => {
    const api = new OrganizationApiApi(this.getAPIConfiguration());

    return api;
  };

  public getQuestionApi = () => {
    const api = new QuestionApiApi(this.getAPIConfiguration());

    return api;
  };
  public getStudentManagementApi = () => {
    const api = new StudentManagementApiApi(this.getAPIConfiguration());

    return api;
  };

  public getSmsApi = () => {
    const api = new SmsApiApi(this.getAPIConfiguration());

    return api;
  };

  public getMockTestApi = () => {
    const api = new MocktestApiApi(this.getAPIConfiguration());

    return api;
  };

  public getExamApi = () => {
    const api = new ExamApiApi(this.getAPIConfiguration());

    return api;
  };
  public getBadgeApi = () => {
    const api = new BadgeApiApi(this.getAPIConfiguration());

    return api;
  };
  public getCreditsApi = () => {
    const api = new CreditsApiApi(this.getAPIConfiguration());

    return api;
  };
  public getFilesApi = () => {
    const api = new FilesApiApi(this.getAPIConfiguration());

    return api;
  };
  public getPaymentApi = () => {
    const api = new PaymentApiApi(this.getAPIConfiguration());

    return api;
  };
  public getTagApi = () => {
    const api = new TagApiApi(this.getAPIConfiguration());

    return api;
  };
  public getReviewApi = () => {
    const api = new ReviewApiApi(this.getAPIConfiguration());

    return api;
  };
  public getTokenApi = () => {
    const api = new TokenApiApi(this.getAPIConfiguration());

    return api;
  };
  public getDomainApi = () => {
    const api = new DomainApiApi(this.getAPIConfiguration());

    return api;
  };
  public getScoreApi = () => {
    const api = new ScoreApiApi(this.getAPIConfiguration());

    return api;
  };

  public getEmailNotificationAPI = () => {
    const api = new NotificationApiApi(this.getAPIConfiguration());

    return api;
  };

  public getCouponsAPI = () => {
    const api = new CouponApiApi(this.getAPIConfiguration());

    return api;
  };

  public getManagementAPI = () => {
    const api = new ManagementApiApi(this.getAPIConfiguration());

    return api;
  };

  public getSalesAPI = () => {
    const api = new SalesApiApi(this.getAPIConfiguration());

    return api;
  };
}

const apiClient = new ApiClient();

export default apiClient;
