import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const EmailNotifications = createReducer(
  {
    emailNotificationsPreferencesData: [],
  },
  {
    [types.GET_EMAIL_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        emailNotificationsPreferencesData: [],
        isFetchingEmailNotificationsPreferences: true,
      });

      return newState;
    },
    [types.GET_EMAIL_NOTIFICATIONS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        emailNotificationsPreferencesData: action.data,
        isFetchingEmailNotificationsPreferences: false,
      });

      return newState;
    },
    [types.GET_EMAIL_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        emailNotificationsPreferencesData: [],
        isEmailNotificationsPreferences: false,
      });

      return newState;
    },

    [types.POST_EMAIL_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEmailNotificationsPreferences: true,
      });

      return newState;
    },
    [types.POST_EMAIL_NOTIFICATIONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEmailNotificationsPreferences: false,
      });

      return newState;
    },
    [types.POST_EMAIL_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingEmailNotificationsPreferences: false,
      });

      return newState;
    },

    [types.PUT_EMAIL_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEmailNotificationsPreferences: true,
      });

      return newState;
    },
    [types.PUT_EMAIL_NOTIFICATIONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEmailNotificationsPreferences: false,
      });

      return newState;
    },
    [types.PUT_EMAIL_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingEmailNotificationsPreferences: false,
      });

      return newState;
    },

    [types.DELETE_EMAIL_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingEmailNotificationsPreferences: true,
      });

      return newState;
    },
    [types.DELETE_EMAIL_NOTIFICATIONS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingEmailNotificationsPreferences: false,
      });

      return newState;
    },
    [types.DELETE_EMAIL_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingEmailNotificationsPreferences: false,
      });

      return newState;
    },
  },
);

export { EmailNotifications as default };
