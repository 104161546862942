import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const adminJobs = createReducer(
  {
    reviewJobsData: {},
    reviewOrgData: {},
  },
  {
    [types.GET_JOB_REVIEW_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewJobsData: {},
        isFetchingReviewJobs: true,
      });

      return newState;
    },
    [types.GET_JOB_REVIEW_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewJobsData: action.data,
        isFetchingReviewJobs: false,
      });

      return newState;
    },
    [types.GET_JOB_REVIEW_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewJobsData: {},
        isFetchingReviewJobs: false,
      });

      return newState;
    },
    [types.GET_ORG_REVIEW_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewOrgData: {},
        isFetchingReviewOrgs: true,
      });

      return newState;
    },
    [types.GET_ORG_REVIEW_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewOrgData: action.data,
        isFetchingReviewOrgs: false,
      });

      return newState;
    },
    [types.GET_ORG_REVIEW_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        reviewOrgData: {},
        isFetchingReviewOrgs: false,
      });

      return newState;
    },
    [types.ORG_BULK_STATUS_UPDATE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkOrgStatus: true,
      });

      return newState;
    },
    [types.ORG_BULK_STATUS_UPDATE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkOrgStatus: false,
      });

      return newState;
    },
    [types.ORG_BULK_STATUS_UPDATE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkOrgStatus: false,
      });

      return newState;
    },

    [types.JOBS_BULK_STATUS_UPDATE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkJobStatus: true,
      });

      return newState;
    },
    [types.JOBS_BULK_STATUS_UPDATE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkJobStatus: false,
      });

      return newState;
    },
    [types.JOBS_BULK_STATUS_UPDATE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isUpdatingBulkJobStatus: false,
      });

      return newState;
    },
    [types.DELETE_JOBS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJob: true,
      });

      return newState;
    },
    [types.DELETE_JOBS_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJob: false,
      });

      return newState;
    },
    [types.DELETE_JOBS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeletingJob: false,
      });

      return newState;
    },
  },
);

export { adminJobs as default };
