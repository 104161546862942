import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const layout = createReducer(
  {
    headerTitle: '',
  },
  {
    [types.SAVE_HEADER_TEXT](state, action) {
      return {
        ...state,
        headerTitle: action.text,
      };
    },
  },
);
