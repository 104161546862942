import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleIconOutlined from '@mui/icons-material/CircleOutlined';
import CrossIcon from '@mui/icons-material/Cancel';
import {
  StartDialogButton,
  StartDialogDetailsCard,
  StartDialogDetailsCardHeading,
  StartDialogDetailsCardText,
  StartDialogHeading,
  StartDialogLeftGrid,
  StartDialogPagination,
  StartDialogRightGrid,
  StartDialogRightGridBottom,
  StartDialogRightGridBottomChip,
  StartDialogRightGridBottomName,
  StartDialogRightGridItem,
  StartDialogRightGridNumber,
  StartDialogRightGridText,
  StartDialogSubHeading,
} from './StyledComponents';
import StartMockTestImg from './images/StartMockTestImg.svg';
import {
  carouselCardData1,
  carouselCardData2,
  carouselCardDataMobile,
} from '../../constants/mockTestStartInstructions';
import NextIconForPagination from '../../images/NextIconForPagination.svg';
import BackIconForPagination from '../../images/BackIconForPagination.svg';

const MockTestStartDialog = ({
  openModal,
  handleModalClose,
  mockTestDetails,
  onStartTest,
  tagList,
}) => {
  const [CardData, setCardData] = useState(carouselCardData1);
  const [slide, setSlide] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tags = tagList?.filter((tag) =>
    mockTestDetails.tag_array?.includes(tag.id),
  );
  useEffect(() => {
    if (isMobile) {
      setCardData(carouselCardDataMobile);
    } else {
      setCardData(carouselCardData1);
    }
  }, [isMobile]);

  return (
    <Dialog fullWidth maxWidth="lg" open={openModal} onClose={handleModalClose}>
      <DialogTitle>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          onClick={handleModalClose}
        >
          <CrossIcon style={{ color: 'rgba(53, 114, 176, 0.50)' }} />
        </Box>
        <StartDialogHeading>
          Mock Test Rules: Keep these points in mind for better performance.{' '}
        </StartDialogHeading>

        <StartDialogSubHeading>
          Here are few simple rules to keep in mind while attempting the
          mock-tests, follow these instructions and test your preparation.
        </StartDialogSubHeading>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <StartDialogLeftGrid
            item
            md={8}
            sm={12}
            xs={12}
            display={'flex'}
            justifyContent={'center'}
          >
            <img src={StartMockTestImg} width={'85%'} />
          </StartDialogLeftGrid>
          <StartDialogRightGrid item md={4} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StartDialogRightGridItem>
                  <StartDialogRightGridNumber>
                    {mockTestDetails?.no_questions}
                  </StartDialogRightGridNumber>
                  <StartDialogRightGridText>
                    Total Questions
                  </StartDialogRightGridText>
                </StartDialogRightGridItem>
              </Grid>
              <Grid item xs={6}>
                <StartDialogRightGridItem>
                  <StartDialogRightGridNumber>
                    {mockTestDetails?.total_duration / 60} mins
                  </StartDialogRightGridNumber>
                  <StartDialogRightGridText>
                    Total Time
                  </StartDialogRightGridText>
                </StartDialogRightGridItem>
              </Grid>
            </Grid>
            <StartDialogRightGridItem>
              <StartDialogRightGridNumber>
                {mockTestDetails?.exam_mode}
              </StartDialogRightGridNumber>
              <StartDialogRightGridText>Mode</StartDialogRightGridText>
            </StartDialogRightGridItem>
            <Grid container>
              <Grid item xs={12}>
                <StartDialogRightGridBottom>
                  <StartDialogRightGridText>
                    Categories & Subtopics Covered
                  </StartDialogRightGridText>
                  {tags?.map((tag, index) => (
                    <>
                      <StartDialogRightGridBottomName key={index}>
                        {tag.name}
                      </StartDialogRightGridBottomName>
                      <Grid container>
                        {Object.keys(mockTestDetails?.subcategory_weights)
                          .length > 0 ? (
                          tag.sub_tags
                            ?.filter((item) =>
                              Object.keys(
                                mockTestDetails.subcategory_weights[tag.id],
                              ).includes(item.id),
                            )
                            ?.map((item) => (
                              <Grid xs key={item.id}>
                                <StartDialogRightGridBottomChip
                                  variant="outlined"
                                  label={item.name}
                                  color="primary"
                                  size="small"
                                />
                              </Grid>
                            ))
                        ) : (
                          <StartDialogRightGridBottomChip
                            variant="outlined"
                            label="All Subcategories"
                            color="primary"
                            size="small"
                          />
                        )}
                      </Grid>
                    </>
                  ))}
                </StartDialogRightGridBottom>
              </Grid>
            </Grid>
          </StartDialogRightGrid>
        </Grid>
        <Grid container spacing={3}>
          {isMobile
            ? CardData.filter((_, index) => index === slide - 1).map((item) => (
                <Grid item sm={4} xs={12} key={item.id}>
                  <StartDialogDetailsCard>
                    <img src={item.image} />
                    <StartDialogDetailsCardHeading>
                      {item.title}
                    </StartDialogDetailsCardHeading>
                    <StartDialogDetailsCardText>
                      {item.description}
                    </StartDialogDetailsCardText>
                  </StartDialogDetailsCard>
                </Grid>
              ))
            : CardData.map((item) => (
                <Grid item sm={4} xs={12} key={item.id}>
                  <StartDialogDetailsCard>
                    <img src={item.image} />
                    <StartDialogDetailsCardHeading>
                      {item.title}
                    </StartDialogDetailsCardHeading>
                    <StartDialogDetailsCardText>
                      {item.description}
                    </StartDialogDetailsCardText>
                  </StartDialogDetailsCard>
                </Grid>
              ))}
        </Grid>
        <StartDialogPagination>
          <IconButton
            onClick={() => {
              !isMobile && setCardData(carouselCardData1);
              setSlide(Math.max(slide - 1, 1));
            }}
          >
            <img src={BackIconForPagination} width="20px" />
          </IconButton>
          &nbsp;&nbsp;
          {!isMobile ? (
            <>
              {slide === 1 && (
                <>
                  <CircleIcon color="primary" fontSize="8px" />
                  <CircleIconOutlined color="primary" fontSize="8px" />
                </>
              )}

              {slide === 2 && (
                <>
                  <CircleIconOutlined color="primary" fontSize="8px" />
                  <CircleIcon color="primary" fontSize="8px" />
                </>
              )}
            </>
          ) : (
            <StartDialogDetailsCardHeading>
              {slide}/6
            </StartDialogDetailsCardHeading>
          )}
          &nbsp;&nbsp;
          <IconButton
            onClick={() => {
              !isMobile && setCardData(carouselCardData2);
              setSlide(Math.min(slide + 1, isMobile ? 6 : 2));
            }}
          >
            <img src={NextIconForPagination} width="20px" />
          </IconButton>
        </StartDialogPagination>
        <StartDialogButton
          onClick={() => onStartTest(mockTestDetails)}
          variant="contained"
        >
          Start Test
        </StartDialogButton>
      </DialogContent>
    </Dialog>
  );
};

export default MockTestStartDialog;
