import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';

export default function ChooseSubcategoryDialog({
  tagInput,
  tagMap,
  editParentTag,
  subTagDialogOpen,
  setSubTagDialogOpen,
  setValueParent,
}) {
  const parentTag = editParentTag || tagInput;

  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const tagMapped = tagMap || new Map();

  const subTagInput = watch('sub_tag');
  const subTagWeightageInput = watch('sub_tag_weightage');

  const addSubTag = () => {
    const newTagMap = new Map(tagMapped);
    const newSubTagMap = new Map(newTagMap.get(parentTag.id));
    newSubTagMap.set(subTagInput.id, parseInt(subTagWeightageInput));
    newTagMap.set(parentTag.id, newSubTagMap);
    setValueParent('tag_map', newTagMap);
  };

  const closeSubTagDialog = () => {
    setSubTagDialogOpen(false);

    setValue('sub_tag', '');
    setValue('sub_tag_weightage', '');
    setValueParent('edit_parent_tag', null);
  };

  const removeSubTag = (subTagId) => {
    const newTagMap = new Map(tagMapped);
    const newSubTagMap = new Map(newTagMap.get(parentTag.id));

    newSubTagMap.delete(subTagId);
    newTagMap.set(parentTag.id, newSubTagMap);

    setValueParent('tag_map', newTagMap);
  };

  return (
    <Dialog
      open={subTagDialogOpen}
      onClose={closeSubTagDialog}
      className="form-dialog"
      fullWidth
    >
      <center>
        <DialogTitle>Add Subcategories for {parentTag.name}</DialogTitle>
      </center>

      <DialogContent>
        <div className="createtest__subcategory_dialog__content">
          <div className="createtest__subcategory_dialog__createsubcategory">
            <div className="createtest__subcategory_dialog__subcategory_input">
              <Controller
                name="sub_tag"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field }) => (
                  <>
                    <ReactSelect
                      {...field}
                      options={parentTag.sub_tags}
                      getOptionLabel={(subTag) => subTag.name}
                      getOptionValue={(subTag) => subTag.id}
                      placeholder="List of Subcategories"
                    />
                    {errors && (
                      <p style={{ color: 'red' }}>{errors.sub_tag?.message}</p>
                    )}
                  </>
                )}
              />
            </div>

            <TextField
              {...register('sub_tag_weightage', {
                min: {
                  value: 1,
                  message: 'The value should not 0 or less',
                },
              })}
              fullWidth
              margin="dense"
              type="number"
              placeholder="Weightage %"
              variant="outlined"
              style={{ marginTop: -1, marginRight: 10 }}
              error={!!errors.sub_tag_weightage}
              helperText={errors.sub_tag_weightage?.message}
            />

            <Button
              onClick={handleSubmit(addSubTag)}
              variant="contained"
              style={{ marginTop: -1, height: 'max-content' }}
            >
              Add
            </Button>
          </div>

          {tagMapped &&
            tagMapped.get(parentTag.id) &&
            Array.from(tagMapped.get(parentTag.id)).map(
              ([subTagId, weightage]) => {
                const subTag = parentTag.sub_tags.find(
                  (subTag) => subTag.id === subTagId,
                );

                return (
                  <div className="createtest__tag" key={subTag?.id}>
                    <p>{subTag?.name}</p>
                    <p>{weightage}%</p>
                    <IconButton
                      onClick={() => removeSubTag(subTagId)}
                      size="small"
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>

                    <br />
                  </div>
                );
              },
            )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={closeSubTagDialog} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
