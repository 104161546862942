export const maxTimeOptions = [
  {
    value: 30,
    label: '30 Seconds',
  },
  {
    value: 60,
    label: '60 Seconds',
  },
  {
    value: 120,
    label: '2 Minutes',
  },
  {
    value: 300,
    label: '5 Minutes',
  },
];
