import { styled } from '@mui/styles';

export const StyledStartCompetetionDialog = styled('div')(({ theme }) => ({
  '& .statsCtn-competition': {
    marginTop: 15,
    '& .questions-competition': {
      padding: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.background,
      width: '100%',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .avatar-competition': {
        height: 40,
        width: '30%',
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '& .score-competition': {
      marginTop: 10,
      padding: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.background,
      width: '100%',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .avatar-competition': {
        height: 40,
        width: '30%',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
}));
