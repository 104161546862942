import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAnsweredQuestions } from './../actions/question';
import {
  getUserReferrals,
  createReferrals,
  resetReferrals,
} from './../actions/user';
import Component from './../components/UserProfile/ReferralCard';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAnsweredQuestions,
      getUserReferrals,
      createReferrals,
      resetReferrals,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    domain: state.domain,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
