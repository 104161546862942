import {
  Button,
  Chip,
  Hidden,
  IconButton,
  TablePagination,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useState } from 'react';
import { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import analyticsTimer from './images/analytics_timer.svg';
import analyticsSpeed from './images/analytics_speed.svg';
import { getDifficultyLabel } from '../../constants/examDifficulty';
import Loader from '../Loader';
import QuestionCard from './QuestionCard';
import helpers from '../../lib/helpers';
import { StyleResult, StyledPieChart } from './ResultStyledComponent';

function Header({ onBack, name, difficulty }) {
  return (
    <div className="result__header">
      <IconButton onClick={onBack} size="large">
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="h6">{name}</Typography>
      <div className={`difficulty-badge ${getDifficultyLabel(difficulty)}`}>
        {getDifficultyLabel(difficulty)}
      </div>
    </div>
  );
}

function Categories({ tagList, tags }) {
  return (
    <div className="result__categories">
      <Typography variant="caption" component="p">
        Categories :
      </Typography>
      <div>
        {tagList
          .filter((tag) => tags.includes(tag.id))
          .map((tag) => (
            <Chip key={tag.id} label={tag.name} size="small" />
          ))}
      </div>
    </div>
  );
}
function Statistics({ statistics, loading, isClassic }) {
  const [open, setOpen] = useState(true);

  const gradientMap = {
    total_correct: '#6CE06A',
    total_incorrect: '#FF6B6B',
    skipped: '#FFC66B',
    timeout: '#FFFFFF',
  };
  let gradient = 'conic-gradient(';
  Object.keys(gradientMap).forEach((key) => {
    if (statistics[key] > 0) {
      gradient += `${gradientMap[key]} 0,`;
      gradient += `${gradientMap[key]} ${(statistics[key] /
        statistics.no_questions) *
        100}%,`;
    }
  });
  gradient = gradient.slice(0, -1) + ')';

  const renderHeader = () => (
    <>
      <Typography variant="h6">Statistics</Typography>
      <Hidden smUp>
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={() => setOpen(!open)}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Hidden>
    </>
  );

  const renderContent = (img, content) =>
    open ? (
      <div>
        {img}
        <div>
          {content.map(({ label, value, color }, index) => (
            <Typography variant="caption" component="p" key={index}>
              {label}
              <span className={color}>{value}</span>
            </Typography>
          ))}
        </div>
      </div>
    ) : null;

  if (loading) return null;

  const analyticsContent = [
    {
      label: 'Av. Time Taken',
      value: `${helpers.getMinutesRemaining(statistics.avg_duration)} mins`,
      color: 'primary',
    },
    {
      label: 'Total Time Taken',
      value: `${helpers.getMinutesRemaining(statistics.total_duration)} mins`,
      color: 'primary',
    },
  ];
  if (isClassic)
    analyticsContent.unshift({
      label: 'Timed out',
      value: `${statistics.timeout}/${statistics.no_questions}`,
      color: 'error',
    });

  return (
    <div className="result__statistics">
      <div className="result__statistics--header">{renderHeader()}</div>
      <div className="result__statistics--content">
        {renderContent(
          <div className="">
            <StyledPieChart
              style={{
                width: 50,
                height: 50,
                background: gradient,
              }}
            />
          </div>,
          [
            {
              label: 'Total Correct',
              value: `${statistics.total_correct}/${statistics.no_questions}`,
              color: 'success',
            },
            {
              label: 'Total Incorrect',
              value: `${statistics.total_incorrect}/${statistics.no_questions}`,
              color: 'error',
            },
            {
              label: 'Skipped Questions',
              value: `${statistics.skipped}/${statistics.no_questions}`,
              color: 'warning',
            },
          ],
        )}
        {renderContent(
          <img
            src={analyticsTimer}
            width="50px"
            height="50px"
            alt="Analytics"
          />,
          analyticsContent,
        )}
        {renderContent(
          <img
            src={analyticsSpeed}
            width="50px"
            height="50px"
            alt="Analytics"
          />,
          [
            {
              label: 'Score',
              value: `${statistics.current_score?.toFixed(2)}/${
                statistics.maximum_score
              }`,
              color: 'primary',
            },
            {
              label: 'Accuracy %',
              value: `${statistics.accuracy?.toFixed(2)}%`,
              color: 'primary',
            },
            {
              label: 'Total Unattempted',
              value: `${statistics.unattempted}/${statistics.no_questions}`,
              color: 'primary',
            },
          ],
        )}
      </div>
    </div>
  );
}

function QuestionList({
  questions,
  unlockSolution,
  loading,
  paginationInfo,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  if (loading) return <Loader />;

  return (
    <div className="result__questionlist">
      {questions.map(({ question, ...attempt }, index) => (
        <QuestionCard
          key={question.id}
          index={index + 1}
          question={question}
          attempt={attempt}
          unlockSolution={unlockSolution}
        />
      ))}
      <TablePagination
        component="nav"
        page={paginationInfo.currentPage}
        rowsPerPage={rowsPerPage}
        count={paginationInfo.pageSize * paginationInfo.totalPages}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        style={{ marginTop: '50px' }}
      />
    </div>
  );
}

function Result({
  mockTest,
  selectedMockTest,
  question,
  tagList,
  unlockSolution,
  getMockTestQuestions,
  getExamResultAnalytics,
  onBack,
}) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    questions,
    isGettingMockTestQuestions,
    questionsPaginationInfo,
  } = mockTest;
  const { examAnalytics, isGettingExamAnalytics } = question;

  useEffect(() => {
    if (selectedMockTest?.id) {
      getMockTestQuestions(selectedMockTest.id, page, rowsPerPage, true);
      getExamResultAnalytics(selectedMockTest.id);
    }
  }, [selectedMockTest]);

  const handleChangePage = (_, newPage) => {
    const currentPage = newPage + 1;
    setPage(currentPage);
    getMockTestQuestions(selectedMockTest.id, currentPage, rowsPerPage, true);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    getMockTestQuestions(selectedMockTest.id, page, newRowsPerPage, true);
  };

  return (
    <StyleResult position="relative">
      <Header
        onBack={onBack}
        name={selectedMockTest.name}
        difficulty={selectedMockTest.difficulty_level}
      />
      <Categories tagList={tagList} tags={selectedMockTest.tag_array} />
      <Statistics
        isClassic={selectedMockTest.exam_mode === 'classic'}
        statistics={examAnalytics}
        loading={isGettingExamAnalytics}
      />
      <QuestionList
        rowsPerPage={rowsPerPage}
        questions={questions}
        paginationInfo={questionsPaginationInfo}
        loading={isGettingMockTestQuestions}
        unlockSolution={unlockSolution}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </StyleResult>
  );
}

export default Result;
