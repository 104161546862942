import { styled } from '@mui/styles';

export const StyleCredits = styled('div')(({ theme }) => ({
  '& .referral-card': {
    padding: '20px',
    margin: 'auto',
    height: 'fit-content',
  },

  '& .difficulty': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },

  '& .difficulty-block': {
    color: theme.palette.component.color,
    alignItems: 'center',
  },

  '& .difficulty-level': {
    color: theme.palette.secondary.main,
  },

  '& .referral-container': {
    padding: '10px',
  },

  '& .title-text': {
    padding: '10px',
  },
}));
