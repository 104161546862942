import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import PractiseTagBox from './PractiseTagBox';

import {
  TagBoxContainerSmall,
  TagBoxContainer,
  AnalyticsBox,
  AnalyticsName,
  AnalyticsValue,
  AnalyticsContainer,
} from './UserProfileStyledComponent';

const AuthorProfileTab = ({ creator_stats_refined, creator_stats, isSelf }) => {
  const [currentIndex, setCurrentIndex] = React.useState(1);

  return (
    <Grid>
      <AnalyticsContainer>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {creator_stats?.total_questions_created}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Questions Created</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {creator_stats?.total_bookmarks}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Bookmarks</AnalyticsName>
        </AnalyticsBox>
        <AnalyticsBox>
          <AnalyticsValue color={'primary'}>
            {creator_stats?.total_likes}
          </AnalyticsValue>
          <AnalyticsName variant="caption">Likes</AnalyticsName>
        </AnalyticsBox>
      </AnalyticsContainer>
      {!Object.values(creator_stats_refined || {}).length && (
        <Typography color="primary" className="no-activity-text">
          No activity in this section!
        </Typography>
      )}
      <TagBoxContainer>
        {Object.values(creator_stats_refined || {}).map((stat, idx) => (
          <PractiseTagBox
            key={idx}
            data={stat}
            isAuthorProfile={true}
            isSelf={isSelf}
          />
        ))}
      </TagBoxContainer>
      <TagBoxContainerSmall>
        <SwipeableViews onChangeIndex={(index) => setCurrentIndex(index + 1)}>
          {Object.values(creator_stats_refined || {}).map((stat, idx) => (
            <Box style={{ margin: '0px 5px' }} key={idx}>
              <PractiseTagBox
                data={stat}
                isAuthorProfile={true}
                isSelf={isSelf}
              />
            </Box>
          ))}
        </SwipeableViews>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            justifyContent: 'center',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            {currentIndex}
          </Typography>
          <Typography color="primary" fontSize="14px">
            /{Object.values(creator_stats_refined || {})?.length}
          </Typography>
        </Box>
      </TagBoxContainerSmall>
    </Grid>
  );
};

export default AuthorProfileTab;
