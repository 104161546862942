import { Box, Typography, Chip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import helpers from '../../../lib/helpers';
import MockTestCard from '../MockTestCard';
import InprogressIcon from '../images/inprogress-icon.svg';
import '../index.scss';
import MyTestEmptyState from '../images/AllTestEmptyState.svg';

function EmptyMockTestMyTests() {
  return (
    <Box
      className="mocktest__empty"
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <img
        src={MyTestEmptyState}
        alt="MyTestEmptyState"
        className="mocktest__emptyImg2"
      />
      <span className="emptyState__heading1">
        Unlock Your Potential with Mock Tests
      </span>
      <span className="emptyState__heading2">
        No purchased tests matching your search criteria. Browse our selection
        and find the perfect one for you.
      </span>
    </Box>
  );
}
const MyTestTab = ({
  show,
  templates,
  highlightedTest,
  onStartTest,
  tagList,
  handleStartModal,
  handleResumeModal,
}) => {
  if (!show) return null;

  return (
    <>
      <div className="mocktest__items">
        {templates?.length === 0 && <EmptyMockTestMyTests />}
        {templates?.map((test, index) => (
          <MockTestCard
            key={`${test.id}_${index}`}
            title={test.name}
            imageUrl={test.image_url}
            isHighlighted={highlightedTest?.id === test.id}
            timestamp={moment.utc(test.created_at).fromNow()}
            tags={tagList.filter((tag) => test.tag_array?.includes(tag.id))}
            difficulty={test.difficulty_level}
            questionsText={
              test.is_completed || test.started_on
                ? 'Questions Attempted'
                : 'Questions'
            }
            questionsValue={
              test.is_completed || test.started_on
                ? `${test.attempted_questions}/${test.no_questions}`
                : test.no_questions
            }
            timeText={
              test.is_completed
                ? 'Time Taken'
                : test.started_on
                ? 'Time Left'
                : 'Duration'
            }
            timeValue={
              test.is_completed
                ? helpers.getMinutesRemaining(
                    helpers.getTimeDifference(
                      test.started_on,
                      test.completed_on,
                    ),
                  )
                : test.started_on
                ? helpers.getMinutesRemaining(
                    helpers.getRemainingTime(
                      test.started_on,
                      test.total_duration,
                    ),
                  )
                : parseInt(test.total_duration / 60)
            }
            mode={test.exam_mode}
            btnText={
              test.is_completed
                ? 'View Result'
                : test.started_on
                ? 'Resume Test'
                : 'Start Test'
            }
            onClick={
              test.is_completed
                ? () => onStartTest(test)
                : test.started_on
                ? () => handleResumeModal(test)
                : () => handleStartModal(test)
            }
            extra={
              !test.is_completed &&
              test.started_on && (
                <Chip
                  label={
                    <Box display={'flex'} alignItems={'center'} gap="7px">
                      <img src={InprogressIcon} alt="icon" />
                      <Typography color="primary">In Progress</Typography>
                    </Box>
                  }
                  sx={{
                    background: '#3572B01A',
                  }}
                />
              )
            }
          />
        ))}
      </div>
    </>
  );
};

export default MyTestTab;
