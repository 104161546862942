import React from 'react';
import { useNavigate } from 'react-router-dom';
import QuestionCard from '../QuestionCard/QuestionCard';
import {
  hintVisibility,
  solutionVisibility,
} from '../../constants/questioncard';

export default function ReviewQuestionCard({
  getQuestionIdOnClick,
  canceled,
  handleSelectedQues,
  handleDeselectedQues,
  handleBlockForSingleQuestion,
  question,
  index,
  props,
  currentPage,
  tagList,
  selectedTag,
  selectedDomain,
  appliedFilters,
  selectedUser,
  selectedQuesIds,
  handleAccepted,
}) {
  const navigate = useNavigate();

  const handleDeleteClick = (id) => {
    if (window.confirm('Are you sure you want to remove this question?')) {
      const { deleteQuestion } = props;
      deleteQuestion({ questionId: id });
      navigate('/l/question');
    }
  };

  return (
    <>
      <QuestionCard
        showHint={hintVisibility.review}
        showSolution={solutionVisibility.review}
        handleDeleteAction={handleDeleteClick}
        isQuestionSelected={true}
        getQuestionIdOnClick={getQuestionIdOnClick}
        question={question}
        index={index + 1}
        key={`${question.id}-${index}`}
        tag={props.tag}
        tagList={tagList}
        putQuestion={props.putQuestion}
        putReviewQuestion={props.putReviewQuestion}
        postReportQuestion={props.postReportQuestion}
        getUnderReviewQuestions={props.getUnderReviewQuestions}
        unlockSolution={props.unlockSolution}
        page={currentPage}
        page_size={20}
        tag_id={selectedTag === 'all' ? '' : selectedTag}
        domain_id={selectedDomain === 'all' ? '' : selectedDomain}
        question_status={appliedFilters}
        userId={selectedUser?.id}
        isStagedToReview={
          selectedQuesIds.findIndex((id) => id === question?.id) !== -1
        }
        handleSelectedQues={handleSelectedQues}
        handleDeselectedQues={handleDeselectedQues}
        canceled={canceled}
        onAccept={(questionId, selectedType) =>
          handleAccepted([questionId], selectedType)
        }
        onBlock={(questionId, selectedType) => {
          handleBlockForSingleQuestion([questionId], selectedType);
        }}
      />
    </>
  );
}
