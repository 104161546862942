import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Select,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Close from '@mui/icons-material/Close';
import { UpdateAdminOrders } from '../../actions/storeAdmin';
import { useDispatch } from 'react-redux';

const UpdateOrderDetailsModal = ({
  open,
  handleClose,
  transactionId,
  internalMsg,
  userMsg,
  overallStatus,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const onProductDetailsSubmit = (data) => {
    dispatch(UpdateAdminOrders(transactionId, data));
    handleClose();
  };
  const dialogDataArr = {
    internal_msg: internalMsg,
    user_msg: userMsg,
    overall_transaction_status: overallStatus,
  };
  React.useEffect(() => {
    reset(dialogDataArr);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: '0',
          minWidth: '50%',
        },
      }}
    >
      <Grid container>
        <Grid item sm={6} xs={9}>
          <DialogTitle>Update Status</DialogTitle>
        </Grid>
        <Grid item sm={6} xs={3}>
          <Box display="flex" justifyContent="flex-end">
            <DialogTitle>
              <IconButton size="large">
                <Close onClick={handleClose} />
              </IconButton>
            </DialogTitle>
          </Box>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12}>
              <label>Status</label>
            </Grid>
            <Grid item sm={9} xs={12}>
              <Select
                {...register('overall_transaction_status', {
                  required: 'This field is required',
                })}
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                fullWidth
                placeholder="Status"
                defaultValue={overallStatus ? overallStatus : ''}
                error={!!errors.overall_transaction_status}
                helperText={errors.overall_transaction_status?.message}
              >
                <MenuItem value="shipping_pending" key="shipping_pending">
                  Shipping Pending
                </MenuItem>
                <MenuItem value="done" key="done">
                  Done
                </MenuItem>
                <MenuItem value="payment_pending" key="payment_pending">
                  Payment Pending
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12}>
              <label>Internal msg</label>
            </Grid>
            <Grid item sm={9} xs={12}>
              <TextField
                {...register('internal_msg')}
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Internal Msg"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={12}>
              <label>User msg</label>
            </Grid>
            <Grid item sm={9} xs={12}>
              <TextField
                {...register('user_msg')}
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="User Msg"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="text"
                type="button"
                color="primary"
                onClick={handleSubmit(onProductDetailsSubmit)}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOrderDetailsModal;
