import React, { useRef, useState, useEffect, Fragment } from 'react';
// import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import Loader from '../Loader';
import MessageBar from '../MessageBar';
import config from './../../config';
import CreditsIcon from '../Sidebar/img/credits-icon.svg';
import Invitation from './img/chatsendInvitation.svg';
import QuestionPractise from './img/QuestionPractise.svg';
import RegisteredUser from './img/registeredUser.svg';
import Line from './img/line.svg';
import Line2 from './img/line2.svg';
import Facebook from './img/facebook.svg';
import LinkedIn from './img/linkedIn.svg';
import Twitter from './img/twitter.svg';
import Whatsapp from './img/whatsapp.svg';
import SendIcon from './img/sendIcon.svg';
import EmailBlock from '../EmailBlock';
import ReferralCardTable from './ReferralCardTable';

import {
  SendIconImg,
  ReferralEarned,
  TotalCreditsEarned,
  TotalCreditsEarnedSmall,
} from './UserProfileStyledComponent';

export default function ReferralCard({
  user,
  getUserReferrals,
  createReferrals,
  resetReferrals,
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const [messageStatusVisibility, setMessageStatusVisibility] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const textInputRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    referrals,
    referralPaginationInfo,
    isSubmittingReferrals,
    createReferralSuccess,
    createReferralFail,
    existingUsers,
    totalInvitedUsers,
  } = user;
  const referralList = referrals.data;
  const [currentPage, setCurrentPage] = useState(
    referralPaginationInfo.currentPage + 1,
  );

  const fetchReferralsList = () => {
    getUserReferrals({
      page: currentPage,
      page_size: rowsPerPage,
    });
  };

  useEffect(() => {
    fetchReferralsList();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    if (!isSubmittingReferrals && createReferralSuccess) {
      setMessageStatusVisibility(true);
      setMessage(
        !existingUsers || existingUsers.length == 0
          ? 'Congratulations! Your invitations have been sent.'
          : `Successfully invited ${totalInvitedUsers} users. ${existingUsers} users already exist`,
      );
      setStatus('success');
      resetReferrals();

      fetchReferralsList();
    } else if (!isSubmittingReferrals && createReferralFail) {
      setMessageStatusVisibility(true);
      setMessage('Failed to invite friends');
      setStatus('error');
      resetReferrals();
    }

    setTimeout(() => {
      setMessageStatusVisibility(false);
    }, 3000);
  }, [
    isSubmittingReferrals,
    createReferralSuccess,
    createReferralFail,
    existingUsers,
  ]);

  const referralURL = `${config.WEBSITE_URL}?ref=${user.data.referral_hash}`;

  const copyToClipboard = (e) => {
    e.preventDefault();
    textInputRef.current.select();

    navigator.clipboard.writeText(referralURL);

    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 3000);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleSubmit = (data, domainId) => {
    createReferrals(data, domainId);
  };

  const isValidEmail = (tag) => {
    return tag.match(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );
  };

  const pushTag = (tag) => {
    if (!tag) return;

    if (isValidEmail(tag)) {
      setTags([...tags, { value: tag, isValid: true }]);
    } else {
      setTags([...tags, { value: tag, isValid: false }]);
    }
  };

  const processSubmission = () => {
    const data = tags
      .filter((tag) => tag.isValid)
      .map((tag) => {
        if (tag.isValid)
          return {
            email: tag.value,
            first_name: '',
            last_name: '',
          };
      });
    if (data.length) handleSubmit(data, '');

    // Reset component state
    setTags([]);
    setInputValue('');
  };

  const handleSend = () => {
    if (inputValue) {
      pushTag(inputValue);

      setInputValue('');
    } else {
      processSubmission();
    }
  };

  return (
    <div
      className="user-profile-edit-container-left"
      style={{ marginTop: '10px', padding: '0px 20px' }}
    >
      <div className="referral-rule-card">
        <Grid container>
          <Grid item className="referral-rule-text">
            {' '}
            <img src={CreditsIcon} alt="" style={{ marginRight: '10px' }} />1
            Referral = 125 Credits
          </Grid>
        </Grid>
      </div>
      <div className="user-wrapper referral">
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <span className="referral-heading-text">Referral</span>
          </Grid>
        </Grid>

        <Typography variant="body1" glutterBottom style={{ color: '#777777' }}>
          Invite your friends to Abekus. If they sign up and practise 5
          questions, you and your friend will get 25 credits each for free!
        </Typography>
        <Grid
          container
          className="referral-process"
          justifyContent="center"
          style={{ marginTop: '40px' }}
        >
          <Grid
            item
            md={2}
            sm={4}
            xs={4}
            className="referral-process-single-container"
          >
            <div className="referral-process-img">
              <img src={Invitation} alt="" width="60px" height={'60px'} />
            </div>
            <span className="referral-process-text1">Send Invitation</span>
            <span className="referral-process-text2">
              Send your referral link to friends and tell them how cool Abekus
              is!
            </span>
          </Grid>
          <Grid item md={1} sm={4} xs={4} className="line">
            <img src={Line} alt="" />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={4}
            className="referral-process-single-container"
          >
            <div className="referral-process-img">
              <img src={RegisteredUser} alt="" width="60px" height={'60px'} />
            </div>
            <span className="referral-process-text1">Registration</span>
            <span className="referral-process-text2">
              Let them register to our services using your referral link.
            </span>
          </Grid>
          <Grid item md={1} sm={4} xs={4} className="extra-line">
            <img src={Line2} alt="" />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={4}
            className="referral-process-single-container"
          >
            <div className="referral-process-img">
              <img src={QuestionPractise} alt="" width="60px" height={'60px'} />
            </div>
            <span className="referral-process-text1">Practise</span>
            <span className="referral-process-text2">
              Encourage them to practise 5 questions of any domain or exam.
            </span>
          </Grid>
          <Grid item md={1} sm={4} xs={4} className="line">
            <img src={Line} alt="" />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={4}
            className="referral-process-single-container"
          >
            <div className="referral-process-img">
              <img src={CreditsIcon} alt="" width="60px" height={'60px'} />
            </div>
            <span className="referral-process-text1">125 Free Credits!</span>
            <span className="referral-process-text2">
              You and your friend gets 25 and 100 free credits each!
            </span>
          </Grid>
        </Grid>
        <div className="invite-friends" style={{ marginTop: '50px' }}>
          <Typography variant="subtitle" className="referral-link-text1">
            Invite your friends
          </Typography>
          <Typography variant="subtitle" className="referral-link-text2">
            Insert your friends’ email address and send them invitation to join
            Abekus!
          </Typography>
          <Grid item xs>
            <Box pt={1}>
              <EmailBlock
                inputValue={inputValue}
                setInputValue={setInputValue}
                tags={tags}
                setTags={setTags}
              />
            </Box>
            <SendIconImg src={SendIcon} alt="sendIcon" onClick={handleSend} />
          </Grid>
        </div>
        <div className="referral-link">
          <Typography variant="subtitle" className="referral-link-text1">
            Share Referral Link
          </Typography>
          <Typography variant="subtitle" className="referral-link-text2">
            You can also share your referral link by copying and sending it or
            sharing it on your social media.
          </Typography>
          <Grid container style={{ marginTop: '15px' }}>
            <Grid item md={8} xs={12} className="referral-link-input-container">
              <input
                type="text"
                value={referralURL}
                className="referral-text-input-field"
                ref={textInputRef}
                disabled
              />
              {document.queryCommandSupported('copy') ? (
                <Fragment>
                  {copySuccess ? (
                    copySuccess
                  ) : (
                    <a
                      className="referral-link-copy"
                      style={{
                        cursor: 'pointer',
                        textAlign: 'right',
                        width: '100px',
                      }}
                      onClick={copyToClipboard}
                    >
                      <Typography variant="body1" color="primary">
                        Copy Link
                      </Typography>
                    </a>
                  )}
                </Fragment>
              ) : null}
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid container className="referral-share-icon-container">
                <Grid item className="referral-share-icon">
                  <FacebookShareButton url={referralURL}>
                    <img src={Facebook} alt="" width={'30px'} height="30px" />
                  </FacebookShareButton>
                </Grid>
                <Grid item className="referral-share-icon">
                  <TwitterShareButton url={referralURL}>
                    <img src={Twitter} alt="" width={'30px'} height="30px" />
                  </TwitterShareButton>
                </Grid>
                <Grid item className="referral-share-icon">
                  <WhatsappShareButton url={referralURL}>
                    <img src={Whatsapp} alt="" width={'30px'} height="30px" />
                  </WhatsappShareButton>
                </Grid>
                <Grid item className="referral-share-icon">
                  <LinkedinShareButton url={referralURL}>
                    <img src={LinkedIn} alt="" width={'30px'} height="30px" />
                  </LinkedinShareButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {referralList ? (
        <Box className="user-wrapper referral tablesmall">
          <Grid container>
            <Grid item md={9} xs={7}>
              <Typography variant="subtitle" className="referral-table-heading">
                My Referrals
              </Typography>
            </Grid>
            <TotalCreditsEarnedSmall item md={3} xs={5}>
              <Typography variant="subtitle" className="credits-earned-text">
                Earned :&nbsp;
                <img src={CreditsIcon} height="15px" width="15px" />
                &nbsp;{referrals.total_credits_earned}
              </Typography>
            </TotalCreditsEarnedSmall>
          </Grid>
          <br />
          {referrals.total_user_referrals > 0 ? (
            <>
              <ReferralEarned container>
                <Grid item md={9}>
                  <Typography variant="body2" glutterBottom>
                    Congratulations! {referrals.total_active_users} users out of{' '}
                    {referrals.total_user_referrals} of your referrals are now
                    active and successful on our platform has been activated.
                  </Typography>
                </Grid>
                <TotalCreditsEarned item md={3}>
                  <Typography
                    variant="subtitle"
                    className="credits-earned-text"
                  >
                    Earned :&nbsp;
                    <img src={CreditsIcon} height="15px" width="15px" />
                    &nbsp;{referrals.total_credits_earned}
                  </Typography>
                </TotalCreditsEarned>
              </ReferralEarned>
              <ReferralCardTable
                referralList={referralList}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                page={referralPaginationInfo.currentPage}
                count={
                  referralPaginationInfo.pageSize *
                  referralPaginationInfo.totalPages
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography gutterBottom variant="body1">
              You have no successful referrals yet.
            </Typography>
          )}
        </Box>
      ) : (
        <Paper className="user-wrapper referral-on-load">
          <Loader size={44} color="primary" />
        </Paper>
      )}
      <MessageBar
        type={status}
        open={messageStatusVisibility}
        onClose={() => setMessageStatusVisibility(false)}
        message={message}
      />
    </div>
  );
}
