import React from 'react';
import { Box, Chip, Modal, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StyledModalContainer } from '../Category/CategoryStyledComponent';
import { StyledStartCompetetionDialog } from './CompetitionStartDialogStyledComponent';

const useStyles = makeStyles(() => ({
  modalMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalDetails: {
    color: '#25507B',
    fontWeight: 500,
    textAlign: 'left',
    width: '100%',
  },
  modalSubs: {
    color: '#76767F',
    width: '100%',
    fontWeight: 400,
    lineHeight: 1.3,
    textAlign: 'left',
  },
  modalTopics: {
    color: '#76767F',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },

  modalStats1: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '14px',
    textAlign: 'center',
    width: '100%',
    borderRadius: 2,
  },
  modalStats2: {
    color: '#545459',
    background: '#fff',
    fontWeight: 200,
    width: '100%',
    fontSize: '10px',
    lineHeight: '12px',
    borderRadius: 2,
    textAlign: 'left',
  },
  modalStats3: {
    color: '#25507B',
    background: '#fff',
    fontWeight: 400,
    lineHeight: 1.3,
    width: 'fit-content',
    padding: '1px 2px',
    margin: '0px 1px',
    borderRadius: 2,
  },
}));

const CompetitionStartDialog = ({
  openModal,
  handleModalClose,
  competitionDetails,
  handlePlay,
  startStatistics,
}) => {
  const styles = useStyles();
  const convertNumber = (number) => {
    return ('0000' + number).slice(-4) || '0000';
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      className={styles.modalMain}
    >
      <StyledModalContainer>
        <Box className="header">
          <img
            src={competitionDetails.image_url}
            className="image"
            alt={competitionDetails.name}
          />
          <Box className="details">
            <Typography variant="subtitle1" className={styles.modalDetails}>
              {competitionDetails.name}
            </Typography>
          </Box>
        </Box>
        <Box className="topicsList">
          <Typography variant="subtitle2" className={styles.modalTopics}>
            Topics Covered
          </Typography>
          <Box className="listCtn">
            {competitionDetails.category_names?.map((category, index) => (
              <Chip label={category} size="small" className="tag" key={index} />
            ))}
          </Box>
          <StyledStartCompetetionDialog>
            <Box className="statsCtn-competition">
              <Box className="questions-competition">
                <Typography
                  variant="caption"
                  align="center"
                  style={{
                    color: '#25507B',
                    fontWeight: 500,
                    lineHeight: '16px',
                    fontSize: '16px',
                  }}
                >
                  Number of Questions
                </Typography>
                <Box className="avatar-competition">
                  <Typography
                    variant="subtitle2"
                    align="center"
                    className={styles.modalStats1}
                  >
                    {startStatistics?.questions_attempted}/{' '}
                    {startStatistics?.total_question_count}
                  </Typography>
                  &nbsp;
                </Box>
              </Box>
              <Box className="score-competition">
                <Typography
                  variant="caption"
                  align="center"
                  style={{
                    color: '#25507B',
                    fontWeight: 500,
                    lineHeight: '16px',
                    fontSize: '16px',
                  }}
                >
                  Competition Score
                </Typography>
                <Box className="avatar-competition">
                  {convertNumber(Math.round(startStatistics?.user_score || 0))
                    .split('')
                    .map((s, index) => (
                      <Typography
                        key={index}
                        variant="subtitle2"
                        align="center"
                        className={styles.modalStats3}
                      >
                        {s}
                      </Typography>
                    ))}
                </Box>
              </Box>
            </Box>
          </StyledStartCompetetionDialog>

          <Box className="triggerCtn" style={{ marginTop: '15px' }}>
            <Button
              variant="contained"
              size="small"
              onClick={handlePlay}
              style={{
                backgroundColor: '#25507B',
                color: '#fff',
                width: '100%',
                height: '30px',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              Start Competition
            </Button>
          </Box>
        </Box>
      </StyledModalContainer>
    </Modal>
  );
};

export default CompetitionStartDialog;
