import React from 'react';
import { Grid, TextField, Typography, Select, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  postCallAttempt,
  updateCallAttempt,
} from '../../../actions/salesDashboard';
import { SubmitBtn } from '../SalesStyledComponent';
import { callStatusOptions } from '../../../constants/salesDashbord';

const AddCallAttempt = ({
  salesLead,
  selectedCallAttempt,
  setSelectedCallAttempt,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const isCallAttemptSelected = Object.keys(selectedCallAttempt).length > 0;

  React.useEffect(() => {
    if (isCallAttemptSelected) {
      reset({
        comment: selectedCallAttempt?.comment,
        call_status: selectedCallAttempt?.call_status,
      });
    }
  }, [selectedCallAttempt]);

  const onDetailsSubmit = (data) => {
    const callAttemptBody = {
      comment: data.comment,
      call_status: data.call_status,
      phone_number: salesLead?.phone_number,
    };
    if (isCallAttemptSelected) {
      dispatch(
        updateCallAttempt(
          callAttemptBody,
          selectedCallAttempt?.id,
          salesLead?.id,
          salesLead?.phone_number,
        ),
      );
      setSelectedCallAttempt({});
    } else {
      dispatch(
        postCallAttempt(
          callAttemptBody,
          salesLead?.id,
          salesLead?.phone_number,
        ),
      );
    }

    reset({ comment: '', call_status: 'AgreedToSignup' });
  };

  return (
    <div>
      {' '}
      <Grid container alignItems="center" spacing={3}>
        <Grid item sm={11} xs={11}>
          <Select
            {...register('call_status', {
              required: 'Please enter your call status',
            })}
            variant="outlined"
            fullWidth
            placeholder="Call Status"
            error={!!errors.call_status}
            helperText={errors.call_status?.message}
            defaultValue={'AgreedToSignup'}
          >
            {callStatusOptions?.map((option) => (
              <MenuItem value={option.value} key={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
          <TextField
            {...register('comment', {
              required: 'Please enter your comment',
            })}
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder="Comment"
            error={!!errors.comment}
            helperText={errors.comment?.message}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <SubmitBtn
            variant="contained"
            type="button"
            color="primary"
            onClick={handleSubmit(onDetailsSubmit)}
            fullWidth
            sx={{ height: '100%', display: 'flex' }}
          >
            <Typography
              sx={{
                transform: 'rotate(-90deg)',
                fontWeight: '500',
                fontSize: '18px',
              }}
              color="primary"
            >
              {isCallAttemptSelected ? 'UPDATE' : 'SAVE'}
            </Typography>
          </SubmitBtn>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddCallAttempt;
