import { Button, Chip, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const StartDialogHeading = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  fontSize: '24px',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: 'normal',
  },
}));
export const StartDialogSubHeading = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: 'normal',
  },
}));
export const StartDialogDetailsCard = styled('div')({
  borderRadius: '10px',
  background:
    'linear-gradient(154deg, rgba(53, 114, 176, 0.10) 0%, rgba(53, 114, 176, 0.10) 100%)',
  padding: '30px 20px',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: '180px',
  marginTop: '25px',
});

export const StartDialogDetailsCardHeading = styled('p')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.primary.main,
  fontWeight: 500,
}));
export const StartDialogDetailsCardText = styled('span')(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.common.black,
  fontWeight: 400,
}));
export const StartDialogPagination = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StartDialogButton = styled(Button)({
  height: '30px',
  fontSize: '14px',
  fontWeight: 700,
  padding: '7px 30px',
});

export const StartDialogLeftGrid = styled(Grid)(({ theme }) => ({
  order: 1,
  [theme.breakpoints.down('sm')]: {
    order: 2,
  },
}));

export const StartDialogRightGrid = styled(Grid)(({ theme }) => ({
  order: 2,
  [theme.breakpoints.down('sm')]: {
    order: 1,
  },
}));

export const StartDialogRightGridItem = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '10px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.component.border}`,
  background: theme.palette.secondary.color,
  marginBottom: '15px',
}));
export const StartDialogRightGridNumber = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '24px',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));
export const StartDialogRightGridText = styled('span')(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: '14px',
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));
export const StartDialogRightGridBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '10px',
  flexDirection: 'column',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.component.border}`,
  background: theme.palette.secondary.color,
  maxHeight: '220px',
  overflow: 'auto',
}));
export const StartDialogRightGridBottomName = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '10px',
}));
export const StartDialogRightGridBottomChip = styled(Chip)(({ theme }) => ({
  width: 'fit-content',
  padding: '7px 10px',
  borderRadius: '25px',
  background: theme.palette.secondary.color,
  border: 'none',
  color: theme.palette.primary.main,
  fontSize: '12px',
  fontWeight: 500,
  marginTop: '5px',
}));

export const MockTestContainer = styled(Container)(({ theme }) => ({
  '& .mocktest__header': {
    margin: '1em 0',
    gap: '1em',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },

    '& .mocktest__header--search': {
      flex: 5,
      padding: '0 1em',
      background: theme.palette.background.main,
      border: `1px solid ${theme.palette.component.border}`,
      borderRadius: '4px',
    },

    '& .mocktest__header--select': {
      flex: 2,
    },
  },

  '& .mocktest__empty': {
    width: '100%',
    minHeight: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .mocktest__items': {
    margin: '2em 0',
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    gap: '2em',

    '&::before': {
      content: '""',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'background 0.5s',
      display: 'none',
      zIndex: -1,
    },

    '&:has(.highlighted)': {
      '&::before': {
        display: 'block',
        backgroundColor: theme.palette.component.shadowColor2,
        zIndex: 100,
      },
    },
  },

  '& .mocktest__item': {
    flex: 1,
    flexBasis: '30%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '32%',
    marginBottom: '1em',

    '@media (max-width: 1300px)': {
      flexBasis: '40%',
      maxWidth: '50%',
    },

    '@media (max-width: 1000px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },

    '&.highlighted': {
      backgroundColor: theme.palette.background.main,
      zIndex: 101,
      borderRadius: '4px',

      '& .mocktest__item--main': {
        border: 'none',
      },
    },

    '& .mocktest__item--main': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.background.main,
      border: `1px solid ${theme.palette.component.border}`,
      borderRadius: '10px 5px 10px 10px',
      padding: '1em 1.5em',
      position: 'relative',
    },

    '& .mocktest__item--timestamp': {
      position: 'absolute',
      top: '0.5em',
      right: '0.5em',
      fontSize: '0.8em',
      color: theme.palette.component.color,
    },

    '& .mocktest__item--header': {
      h5: {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 500,
        color: theme.palette.primary.dark,

        '@media (max-width: 1000px)': {
          paddingRight: '3em',
        },
      },

      '& .logo': {
        width: '64px',
        height: '64px',
        objectFit: 'cover',
      },
      '& .mocktest_credits': {
        color: theme.palette.common.success,
        fontWeight: 500,
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
      },
    },

    '& .mocktest__item--content': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.5em',
      background: theme.palette.secondary.color,
      borderRadius: '5px',
      padding: '15px',

      div: {
        color: theme.palette.text.main,
      },
    },

    '& .mocktest__item--extra': {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      h5: {
        color: theme.palette.common.success,
        fontWeight: 500,
        fontSize: '24px',
      },

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
      },
    },
  },
  '& .answer-status--badge': {
    padding: '0.1em 1em',
    color: theme.palette.component.shadowColor2,
    position: 'relative',
    paddingRight: '4em',

    '@media (max-width: 1000px)': {
      paddingRight: '1em',
    },

    '&.Correct': {
      backgroundColor: theme.palette.playColors.lightGreen1,
    },

    '&.Incorrect': {
      backgroundColor: theme.palette.common.error,
    },

    '&.Skipped': {
      backgroundColor: theme.palette.playColors.lightSky,
    },

    '&.Timeout': {
      backgroundColor: theme.palette.playColors.lightYellow2,
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 0.7em 1.6em 0',
      borderColor: `transparent ${theme.palette.background.main} transparent transparent`,
    },
  },

  '& .difficulty-badge': {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '8px 15px',
    height: '26px',
    '--bg-color': theme.palette.common.warning,
    border: '1px solid var(--bg-color)',
    borderRadius: '12.5px 5px 12.5px 12.5px',
    color: 'var(--bg-color)',
    fontWeight: 500,

    '&.Difficult, &.Modes, &.Hard': {
      '--bg-color': theme.palette.common.error,
    },

    '&.Medium': {
      '--bg-color': theme.palette.common.warning,
    },

    '&.Easy': {
      '--bg-color': theme.palette.common.success,
    },
  },

  '& .small__title': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.text.main,
  },

  '& .vertical-line': {
    height: '40px',
    border: `1px solid ${theme.palette.component.border}`,
    display: 'flex',
    alignItems: 'center',
  },

  '& .toggleBox_tab': {
    height: '45px',
    width: '250px',
    background: 'white',
    borderRadius: '25px',
    boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  '& .toggleText_tab': {
    fontSize: '18px',
    padding: '7px 15px',
    borderRadius: '25px',
    cursor: 'pointer',
  },

  '& .mocktest__emptyImg': {
    width: '30%',

    '@media screen and (max-width: 850px)': {
      width: '50%',
    },
  },

  '& .mocktest__emptyImg2': {
    width: '40%',

    '@media screen and (max-width: 500px)': {
      width: '75%',
    },
  },

  '& .emptyState__heading1': {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: '30px',

    '@media screen and (max-width: 450px)': {
      fontSize: '14px',
    },
  },

  '& .emptyState__heading2': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.common.black,
    marginTop: '15px',

    '@media screen and (max-width: 450px)': {
      fontSize: '10px',
      marginTop: '1px',
    },
  },
}));

export const StyleColorHeader = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.secondary.main,
}));
