import React from 'react';
import DomainSelect from './DomainSelect';
import { StyledDialog } from './StyledComponents';
import { useSelector } from 'react-redux';

const DomainSelectModal = ({ open, onClose }) => {
  const { data } = useSelector((state) => state.user);

  return (
    <StyledDialog
      open={open}
      onClose={data?.selected_domain ? onClose : () => {}}
      maxWidth="md"
    >
      <DomainSelect
        applyBtn={true}
        changedSelectedDomain={() => {}}
        handleClose={data?.selected_domain ? onClose : () => {}}
      />
    </StyledDialog>
  );
};

export default DomainSelectModal;
