import React from 'react';
import { Box, Button, Grid, TablePagination, Typography } from '@mui/material';
import { QuickActionBarGrid } from '../StyledComponent';

const QuickActionBar = ({
  handleActionDelete,
  handleActionMerge,
  selectedIds,
  entityDataLength,
  handleSelectAll,
  handleDeselectAll,
  page,
  rowsPerPage,
  paginationCount,
  onPageChangeHandler,
  handleChangeRowsPerPage,
}) => {
  return (
    <QuickActionBarGrid>
      <Box mb={2}>
        <Typography sx={{ fontSize: '16px', color: '#000' }}>
          <strong>{selectedIds?.length}</strong> selected from{' '}
          <strong>{entityDataLength}</strong>
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <Button
            color="error"
            variant="outlined"
            onClick={() => handleActionDelete('', 'bulk')}
            sx={{ width: '100%' }}
          >
            Delete
          </Button>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleActionMerge}
            sx={{ width: '100%' }}
          >
            Merge
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Box
            padding={'10px'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Button variant="contained" onClick={handleSelectAll}>
              Select All
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeselectAll}
            >
              DeSelect All
            </Button>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TablePagination
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            count={paginationCount}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
            className="scoreboard__navigation"
          />
        </Grid>
      </Grid>
    </QuickActionBarGrid>
  );
};

export default QuickActionBar;
