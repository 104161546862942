export const styles = (theme) => ({
  competitionDetailsWrapper: {
    padding: theme.spacing(2),
    position: 'relative',
    marginBottom: theme.spacing(2),
    maxWidth: '100vw',
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
    },
  },
  competitionDetails: {
    flex: 1,
    color: 'black',
  },
  competitionDetailsHeader: {},
  bgImage: {
    width: '100%',
  },
  back: {
    width: '100px',
    position: 'relative',
    cursor: 'pointer',
    top: '-50px',
    left: '10px',
    [theme.breakpoints.down('md')]: {
      top: '-35px',
      width: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '-25px',
      width: '60px',
    },
  },
  competitionDetailsImage: {
    width: '100%',
  },
  extraContent: {
    maxWidth: 400,
    width: 300,
    padding: theme.spacing(2),
  },
  card: {
    flex: 1,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContent: {
    padding: theme.spacing(1),
    flex: 1,
  },
  media: {
    width: 70,
    height: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaImage: {
    width: 55,
    height: 55,
  },
  categoryChip: {
    borderRadius: 4,
    marginRight: 10,
    marginBottom: 10,
  },
  longText: {
    whiteSpace: 'pre-line',
  },
  table: {
    width: '100%',
  },
  avatar: {
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },

  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  competitionName: {
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  referralLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    border: 0,
    padding: '0',
  },
  referralLinkInput: {
    marginRight: '10px',
    flex: 1,
    color: '#576366 !important',
    border: 0,
    fontSize: '14px',
    outline: 'none',
    minWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  rewardsText: {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '13px',
    letterSpacing: '0.4px',
    color: theme.palette.common.black,
    padding: '15px',
  },
  rankIcon: {
    position: 'absolute',
    right: 0,
    top: '40px',
    [theme.breakpoints.down('md')]: {
      width: '41px',
      height: '57px',
      top: '58px',
    },
  },
  rankTag: {
    marginTop: '-10px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-15px',
      width: '43px',
    },
  },
  rewardsBottomText_1: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.primary.main,
    padding: '25px 0px 20px 10px',
  },
  rewardsBottomText_2: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.4px',
    color: theme.palette.component.color,
    padding: '0px 10px 35px 10px',
  },
  cardRank_1: {
    height: '40px',
    boxShadow: `0px 2px 4px ${theme.palette.component.shadowColor}`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    bottomRight: '50px',
    position: 'relative',
    background: 'linear-gradient(180deg, #25507B 0.03%, #3572B0 100%)',
    color: 'white',
    width: '263px',
    [theme.breakpoints.down('md')]: {
      width: '340px',
      height: '58px',
    },
  },
  cardRank_2: {
    height: '40px',
    boxShadow: `0px 2px 4px ${theme.palette.component.shadowColor}`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    bottomRight: '50px',
    position: 'relative',
    background: 'white',
    color: theme.palette.primary.main,
    width: '231px',
    [theme.breakpoints.down('md')]: {
      width: '285px',
      height: '58px',
    },
  },
  cardRank_3: {
    height: '40px',
    boxShadow: `0px 2px 4px ${theme.palette.component.shadowColor}`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    bottomRight: '50px',
    position: 'relative',
    background: 'white',
    color: theme.palette.primary.main,
    width: '199px',
    [theme.breakpoints.down('md')]: {
      width: '245px',
      height: '58px',
    },
  },
  cardRank_all: {
    height: '40px',
    boxShadow: `0px 2px 4px ${theme.palette.component.shadowColor}`,
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    bottomRight: '50px',
    position: 'relative',
    background: 'white',
    color: theme.palette.primary.main,
    width: '150px',
    [theme.breakpoints.down('md')]: {
      width: '217px',
      height: '58px',
    },
  },
  adminAnalyticsData: {
    backgroundColor: theme.palette.primary.background,
    padding: '10px 25px',
    boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor2}`,
    borderRadius: '5px',
    marginBottom: '15px',
    color: theme.palette.secondary.main,
    fontSize: '15px',
    lineHeight: '29px',
    fontWeight: 400,
  },
});
