import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, IconButton } from '@mui/material';
import RightToggleIcon from './images/RightToggleIcon.svg';
import Invitation from './images/invitation.svg';
import Gems from './images/Gems.svg';
import { getCompetitions } from '../../actions/competition';
import { useDispatch, useSelector } from 'react-redux';
import HtmlParser from 'react-html-parser';
import moment from 'moment';

const LiveCompetitions = ({ styles, headline }) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.competitions);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    dispatch(getCompetitions({ status: 'ongoing' }));
  }, []);
  const currentItem = list?.[currentIndex];
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % list?.length);
  };
  return (
    <>
      <span className={styles.contentLeft_Heading1}>{headline}</span>
      <div
        style={{
          borderRadius: '10px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
          margin: '15px 0px 20px 0px',
          position: 'relative',
        }}
      >
        <div style={{ padding: '20px' }}>
          <>
            <Box display={'flex'} alignItems={'center'}>
              <img
                src={currentItem?.image_url || Invitation}
                alt={''}
                width={'50px'}
              />{' '}
              &nbsp;&nbsp;&nbsp;
              <Box>
                <span className={styles.hotTopicsHeading}>
                  {currentItem?.name}
                </span>
                <Box className={styles.tagContainer}>
                  {currentItem?.category_names?.map((category, index) => (
                    <Chip
                      key={index}
                      label={category}
                      size="small"
                      className={styles.tag}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <div>
              <span className={styles.hotTopicsDescription}>
                {HtmlParser(currentItem?.short_description)}
              </span>
            </div>
            {currentItem?.rewards_list.length > 0 && (
              <Grid container>
                <Grid item xs={6}>
                  <Box className={styles.TopicsGems}>
                    <img src={Gems} alt="Students Playing" />
                    &nbsp;&nbsp; Win 800 Gems
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={styles.TopicsGems}>
                    <img src={Gems} alt="Students Playing" />
                    &nbsp;&nbsp; Win 800 Gems
                  </Box>
                </Grid>
              </Grid>
            )}

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>
                {moment(currentItem?.reg_start_date).format('Do MMMM YYYY')} -
                {moment(currentItem?.reg_end_date).format('Do MMMM YYYY')}
              </span>
            </div>

            <a className={styles.practiceBtn}>Register for Free</a>

            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  color: '#3572B0',
                  fontStyle: 'italic',
                  fontWeight: 500,
                }}
              >
                Be an Early Bird and Enroll for Free!
              </span>
            </div>
          </>
        </div>
        <Box
          textAlign="right"
          sx={{ position: 'absolute', top: '50%', right: '-20px' }}
        >
          <IconButton onClick={handleNextClick}>
            <img src={RightToggleIcon} width={'20px'} />
          </IconButton>
        </Box>
      </div>
    </>
  );
};

export default LiveCompetitions;
