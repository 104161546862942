import { styled } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';
import IconButton from '@mui/material/IconButton';

export const AppbarWrapper = styled(AppBar)(({ open, theme }) => ({
  display: 'flex !important',
  flexDirection: 'row !important',
  alignItems: 'center',
  padding: '0px 10px 0px 0px',
  overflow: 'auto',
  transition: open
    ? 'margin 0.3s ease-out, width 0.3s ease-out !important'
    : 'margin 0.3s ease, width 0.3s ease !important',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 2px 4px 0 rgba($color: #000000, $alpha: 0.15) !important',
  marginleft: open ? '240px' : '72px',
  width: open ? 'calc(100% - 72px) !important' : 'calc(100% - 72px) !important',

  '@media (max-width: 600px)': {
    width: '100% !important',
    marginLeft: open ? '0' : '',
    transition: open ? 'null' : '',
  },
}));

export const AppbarContent = styled('div')({
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const AppbarNotification = styled('div')({
  padding: '0',
  '@media (max-width: 460px)': {
    padding: '0px',
  },
});

export const AppbarContentData = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.component.color,
  fontSize: '32px',
  padding: '0px 10px',
  '@media (max-width: 600px)': {
    padding: '0 5px',
  },
  '@media (max-width: 460px)': {
    padding: '0px 8px',
  },
}));

export const Anchor = styled('a')({
  cursor: 'pointer',
});

export const ContentDescription = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: '5px',
  '@media (max-width: 460px)': {
    padding: '2px',
  },
});

export const ContentPara = styled('p')(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '123.02%',
  color: theme.palette.component.details,
  textAlign: 'center',
  margin: '0',
}));

export const ContentHeading = styled('h1')(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '123.02%',
  textAlign: 'center',
  letterSpacing: '0.4px',
  color: theme.palette.component.title,
  margin: '0',
}));

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.component.color,
  marginTop: '5px',

  '&.credit-popover': {
    '&.tooltip': {
      width: '300px !important',
      boxShadow: `1px 2px 4px 0px ${theme.palette.component.border}`,
      border: `1px solid ${theme.palette.component.border}`,
      padding: '10px',
    },
  },
}));

const slamKeyframes = keyframes({
  '0%': {
    transform: 'scale(0.2)',
  },
  '50%': {
    transform: 'scale(1.2)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

export const AnimatedHeading = styled('h1')(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '123.02%',
  textAlign: 'center',
  letterSpacing: '0.4px',
  color: theme.palette.component.title,
  margin: '0',
  animation: (props) =>
    props.animationStart && `${slamKeyframes} 1.5s ease-in-out forwards`,
}));

const spin = keyframes({
  '0%': {
    transform: 'rotateY(0deg) scale(1)',
  },
  '50%': {
    transform: 'rotateY(180deg) scale(1.5)',
  },
  '100%': {
    transform: 'rotateY(360deg) scale(1)',
  },
});

export const AnimatedIcon = styled('img')({
  fontSize: '32px !important',
  animation: (props) => props.animationStart && `${spin} 1.5s linear forwards`,
});

export const MenuButton = styled(IconButton)(({ open, theme }) => ({
  marginRight: '16px',
  color: theme.palette.component.color,
  display: open ? 'none' : 'block',
}));

export const StyledAvatar = styled(Avatar)({
  cursor: 'pointer',
});

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '200px !important',
    boxShadow: `1px 2px 4px 0px ${theme.palette.component.border}`,
    border: `1px solid ${theme.palette.component.border}`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const IconImage = styled('img')({
  width: '20px',
  marginRight: '10px',
  fontSize: '22px',
});
