import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const notifications = createReducer(
  {
    data: {
      notifications: {},
    },

    new_notifications: 0,
  },
  {
    [types.GET_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        isNotificationFetched: false,
      });

      return newState;
    },
    [types.GET_NOTIFICATIONS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isNotificationFetched: true,
        data: action.data,
      });

      return newState;
    },
    [types.GET_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        isNotificationFetched: false,
      });

      return newState;
    },
    [types.GET_NEW_NOTIFICATIONS_START](state) {
      const newState = Object.assign({}, state, {
        isNewNotificationFetched: false,
      });

      return newState;
    },
    [types.GET_NEW_NOTIFICATIONS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isNewNotificationFetched: true,
        new_notifications: action.data,
      });

      return newState;
    },
    [types.GET_NEW_NOTIFICATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        isNewNotificationFetched: false,
      });

      return newState;
    },
  },
);

export { notifications as default };
