export const styles = (theme) => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '40px',
  },
  card: {
    width: '350px',
    minWidth: '300px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  Typography: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Paper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  details: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
    flex: 1,
  },
  media: {
    width: theme.typography.pxToRem(100),
    height: theme.typography.pxToRem(100),
    borderRadius: '50%',

    [theme.breakpoints.down('md')]: {
      width: theme.typography.pxToRem(100),
      height: theme.typography.pxToRem(100),
      margin: theme.spacing(1),
    },
  },
  categoryChip: {
    borderRadius: 4,
    marginRight: 10,
    marginBottom: 10,
    color: '#25507B',
    background: 'rgba(37, 80, 123, 0.1)',
    fontWeight: 500,

    [theme.breakpoints.down('md')]: {
      marginRight: 4,
      marginBottom: 4,
    },
  },
  categoryContainer: {
    height: '68px',
    overflow: 'hidden',
  },
  actions: {},
  competitionName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    height: '38px',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  competitionDate: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '9px',
    letteSpacing: '0.4px',
    color: '#76767F',
  },
  shortDescription: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#76767F',
    overflow: 'hidden',
    height: '55px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  registerBtn: {
    height: '40px',
    width: '80%',
    background: '#25507B',
    border: '1px solid #3572B0',
    borderRadius: '4px',
    color: 'white',
    marginTop: '40px',
    marginBottom: '10px',
  },
  toggleBox: {
    height: '45px',
    width: '250px',
    background: 'white',
    borderRadius: '25px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    margin: 'auto',
  },
  toggleText: {
    fontSize: '18px',
    margin: 'auto',
    padding: '0px 20px 0px 20px',
    borderRadius: '25px',
    ['&:hover']: {
      cursor: 'pointer',
    },
  },
  registeredBadge: {
    width: 'fit-content',
    marginLeft: 'auto',
    background: '#F6FBF6',
    border: '1px solid #4CAF50',
    borderRadius: '25px 0px 10px 25px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: ' #4CAF50',
    padding: '8px 13px 8px 14px',
  },
  participatedBadge: {
    background: '#F6FBF6',
    border: '1px solid #3572B0',
    borderRadius: '25px 0px 10px 25px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: ' #3572B0',
    padding: '8px 13px 8px 14px',
  },
});
