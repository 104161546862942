import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Appbar from './../../containers/AppbarContainer';
import Sidebar from '../Sidebar/index';
import Header from './../HeaderPublic';
import Loader from './../Loader';
import DomainPrompt from './DomainPrompt';

import { autoLogin } from '../../actions/auth';
import { getUserProfile, getUserAnalytics } from '../../actions/user';
import { getDomain, changeDomain } from '../../actions/domain';

import './index.scss';
import NoSidebarLayout from './NoSidebarLayout';
import { CssBaseline } from '@mui/material';
import UserDetailsContainer from '../../containers/UserDetailsContainer';
import {
  StyledAppLoader,
  StyledLayout,
  StyledWrapper,
} from './StyledComponent';

const LayoutPublic = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isLoading, setLoading] = useState(true);
  const auth = useSelector((state) => state.auth);
  const domain = useSelector((state) => state.domain);
  const user = useSelector((state) => state.user);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isSidebarExpanded, setSidebarState] = useState(
    isMobile ? false : true,
  );

  const userInfo = user.data;
  const isLoggedIn = userInfo && userInfo.id;

  const handleMount = () => {
    if (!isLoggedIn) {
      Promise.resolve()
        .then(() => {
          return dispatch(getUserProfile());
        })
        .then(() => {
          dispatch(getUserAnalytics());
          dispatch(getDomain());

          setLoading(false);
        })
        .catch(() => {
          // Nothing to handle
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Attempt auto-login, if not already logged in
    if (!auth.loginSuccess) {
      dispatch(autoLogin())
        .then(() => {
          handleMount();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      handleMount();
    }
  }, [auth.loginSuccess]);

  useEffect(() => {
    if (isMobile) {
      setSidebarState(false);
    } else {
      setSidebarState(true);
    }
  }, [isMobile]);

  if (isLoading) {
    return (
      <StyledAppLoader className="app-loader">
        <Loader size={44} color="primary" shouldShowLoadingText />
      </StyledAppLoader>
    );
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { navigate, params, location });
    }
    return child;
  });
  const isMetadataPromptOpen =
    Object.keys(user.data).length === 0
      ? false
      : !user?.data?.viewed_onboardings?.includes('LEARNING_ONBOARDING');

  if (isMetadataPromptOpen)
    return (
      <NoSidebarLayout
        open={true}
        handleDrawerOpen={() => setSidebarState(true)}
        addSteps={(steps) => this.addSteps(steps)}
        changeStep2={() => null}
        step1={false}
      >
        <CssBaseline />
        <UserDetailsContainer />
      </NoSidebarLayout>
    );

  return (
    <StyledLayout
      className={classnames('layout', { 'layout--only-header': !isLoggedIn })}
    >
      {isLoggedIn ? (
        <Fragment>
          <Appbar
            open={isSidebarExpanded}
            handleDrawerOpen={() => setSidebarState(true)}
            addSteps={(steps) => this.addSteps(steps)}
            changeStep2={() => null}
            step1={false}
          />
          <Sidebar
            user={user}
            open={isSidebarExpanded}
            handleDrawerClose={() => setSidebarState(false)}
            domains={domain.list || []}
            changeDomain={(data) => dispatch(changeDomain(data))}
            addSteps={() => null}
            changeStep1={() => null}
          />
        </Fragment>
      ) : (
        <Header />
      )}
      <main
        className={classnames('layout__content', {
          'layout__content--shift': isSidebarExpanded,
        })}
      >
        <StyledWrapper className="wrapper">{childrenWithProps}</StyledWrapper>
      </main>

      {isLoggedIn && !user.selected_domain ? (
        <DomainPrompt
          open={true}
          domains={domain.list || []}
          handleSubmit={(domain) => {
            dispatch(
              changeDomain({
                domain_id: domain.id,
              }),
            );
          }}
        />
      ) : null}
    </StyledLayout>
  );
};

export default LayoutPublic;
