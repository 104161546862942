import React from 'react';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import Bookmark from '@mui/icons-material/Bookmark';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { styled } from '@mui/styles';

export const StyledBookmarkIcon = styled(IconButton)({
  fontSize: '42px',
});

const BookmarkQuestion = (props) => {
  const [change, setChange] = useState(false);

  const changeIcon = () => {
    setChange(!change);
  };
  const { putBookmark, id, className } = props;
  return (
    <div>
      {!change ? (
        <StyledBookmarkIcon
          aria-label="close"
          className={className}
          onClick={() => {
            changeIcon();
            putBookmark({
              questionId: id,
              body: { bookmark_status: true },
            });
          }}
          size="large"
        >
          <BookmarkBorder color="grey" />
        </StyledBookmarkIcon>
      ) : (
        <StyledBookmarkIcon
          aria-label="close"
          className={className}
          onClick={() => {
            changeIcon();
            putBookmark({
              questionId: id,
              body: { bookmark_status: false },
            });
          }}
          size="large"
        >
          <Bookmark color="primary" />
        </StyledBookmarkIcon>
      )}
    </div>
  );
};
export default BookmarkQuestion;
