import { styled } from '@mui/styles';

import {
  Box,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Typography,
  TextField,
} from '@mui/material';

// --------create product dialogue ----------------
export const ProductImg = styled('img')({
  borderRadius: '5px',
});
export const InfoIcon = styled('img')({
  borderRadius: '5px',
});

// ---------index.jsx-----------
export const SearchBox = styled(TextField)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.primary.main,
}));
export const Name = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 500,
  color: theme.palette.primary.main,
}));
export const ProductImage = styled('img')({
  width: '100px',
  overflow: 'hidden',
  height: '122px',
});

// ----------order status--------------
export const Heading = styled(Grid)(({ theme }) => ({
  lineHeight: '80%',
  fontWeight: '700',
  color: theme.palette.primary.main,
}));
export const Heading_grid_container = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
export const OrderStatusCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  overflow: 'visible',
  boxShadow: `0px 1px 4px ${theme.palette.component.shadowColor}`,
}));
export const OrderStatusCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '5px',
  padding: '10px',
  [theme.breakpoints.down('md')]: {
    height: '200px',
  },
}));
export const OrderStatusCardContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 10px 10px 0px',
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
  '&:last-child': {
    paddingBottom: 1,
  },
}));
export const OrderStatusCollapseBtn = styled(Button)(({ theme }) => ({
  background: 'white',
  boxShadow: `0px 2px 4px ${theme.palette.component.shadowColor}`,
  color: theme.palette.primary.main,
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  height: '30px',
  borderRadius: '0px 0px 5px 5px',
  marginTop: '3px',
}));
export const OrderStatusCollapseGrid = styled(Grid)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '12px',
  letterSpacing: '0.4px',
  color: theme.palette.component.color,
  padding: '10px',
}));
export const Status = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));
export const Quantity = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));
export const Price = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));
export const OrderStatusCardActions = styled(CardActions)({
  display: 'flex',
  padding: '0px 0px 10px 0px',
});
export const PDFBtn = styled(Button)({
  position: 'absolute',
  top: '30px',
  left: '75px',
  height: '20px',
  fontSize: '14px',
  width: '80px',
  lineHeight: '10px',
});
export const Header_Img = styled('div')({
  width: '100%',
});
