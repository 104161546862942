import {
  Box,
  Container,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

export const BottomBarContainer = styled(Container)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  background: 'linear-gradient(180deg, #25507B -1.39%, #3572B0 98.61%)',
  padding: '5px',
  boxShadow: '0px -2px 10px 0px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  zIndex: '1',
});

export const BottomBarGrid = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px 2px',
});

export const BottomBarItem = styled(NavLink)({
  color: 'white',
  padding: '2px',
  margin: '0',
  '& img': {
    width: '16px',
    height: '18px',
    margin: '0 auto',
  },
  '&:hover': {
    color: '#F1CE55',
    borderTop: '3px solid #F1CE55',
  },
});

export const BottomBarText = styled(Typography)({
  fontSize: '10px',
  textAlign: 'center',
});

export const TextwithPin = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const FeedBack = styled(ListItem)(() => ({
  margin: '5px 0px',
}));

export const FeedbackButton = styled(ListItemButton)(() => ({
  borderRadius: '4px',
  background: ' rgba(37, 80, 123, 0.50)',
}));

export const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 72,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerPaper: {
    background: 'linear-gradient(176.02deg, #25507B 0%, #3572B0 100%)',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  popover: {
    zIndex: '1600 !important',
    marginTop: 5,
  },
}));
