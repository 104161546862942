import React from 'react';
import { Grid } from '@mui/material';
import AllQuesAttempted from '../../images/allQuesAttempted.svg';
import DoughnutChart from '../UserProfile/DoughnutChart';
import BackIcon from '../../images/backIcon.svg';
import { useNavigate } from 'react-router-dom';
import {
  CompetitionAllQuesAttempted,
  CompetitionAllQuesAttemptedBtn,
  CompetitionAllQuesAttemptedText,
  CompetitionAttemptmocktestCTA,
  CompetitionDonoughtChart,
  CompetitionMetricsContainer,
  CompetitionMetricsContainerColor,
  CompetitionStatisticsContainer,
  CompetitionStatisticsFinalScore,
  CompetitionStatisticsFinalScoreNumber,
  CompetitionStatisticsFinalScoreText,
  CompetitionStatisticsSection2,
} from './PlayStyledComponent';

const AllCompetitionAttempted = ({ competitionAnalytics }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: 'white',
        borderRadius: '20px',
        padding: '20px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={BackIcon} alt="" /> &nbsp;&nbsp;{' '}
        <span
          style={{ fontSize: '22px', color: '#3572B0', fontWeight: 400 }}
          onClick={() => navigate(-1)}
        >
          Back
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <CompetitionAllQuesAttempted>
          All Questions Attempted! <br />
          Keep an Eye on the Leaderboard for Final Results.
        </CompetitionAllQuesAttempted>
        <CompetitionAllQuesAttemptedText>
          Congratulations on completing all the questions in the competition.
          Stay tuned to see where you stand among the top performers on the
          leaderboard.
        </CompetitionAllQuesAttemptedText>
        <img src={AllQuesAttempted} alt="" />
        <CompetitionAllQuesAttemptedBtn
          onClick={() => navigate('/l/leaderboard')}
        >
          Go to Leaderboard
        </CompetitionAllQuesAttemptedBtn>
        <CompetitionStatisticsContainer>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={3}>
                  <CompetitionStatisticsFinalScore>
                    <CompetitionStatisticsFinalScoreText>
                      Your Final Score
                    </CompetitionStatisticsFinalScoreText>
                    <CompetitionStatisticsFinalScoreNumber>
                      {competitionAnalytics.user_score}
                    </CompetitionStatisticsFinalScoreNumber>
                  </CompetitionStatisticsFinalScore>
                </Grid>
                <Grid item xs={3}>
                  <CompetitionStatisticsFinalScore>
                    <CompetitionStatisticsFinalScoreText>
                      Total Time Taken
                    </CompetitionStatisticsFinalScoreText>
                    <CompetitionStatisticsFinalScoreNumber>
                      {competitionAnalytics.total_time_taken?.toFixed(2)}
                    </CompetitionStatisticsFinalScoreNumber>
                  </CompetitionStatisticsFinalScore>
                </Grid>
                <Grid item xs={3}>
                  <CompetitionStatisticsFinalScore>
                    <CompetitionStatisticsFinalScoreText>
                      Accuracy
                    </CompetitionStatisticsFinalScoreText>
                    <CompetitionStatisticsFinalScoreNumber>
                      {competitionAnalytics.accuracy?.toFixed(2)}
                    </CompetitionStatisticsFinalScoreNumber>
                  </CompetitionStatisticsFinalScore>
                </Grid>
                <Grid item xs={3}>
                  <CompetitionStatisticsFinalScore>
                    <CompetitionStatisticsFinalScoreText>
                      Avg. Attempt Time
                    </CompetitionStatisticsFinalScoreText>
                    <CompetitionStatisticsFinalScore>
                      {competitionAnalytics.avg_time_taken?.toFixed(2)}
                    </CompetitionStatisticsFinalScore>
                  </CompetitionStatisticsFinalScore>
                </Grid>
              </Grid>
              <hr />
              <CompetitionStatisticsSection2>
                <span
                  style={{
                    color: '#FFF',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                >
                  Unleash Your Full Potential! Rise to New Heights in the Next
                  Competition.
                </span>
                <Grid container style={{ marginTop: '10px' }}>
                  <Grid item sm={8} xs={12}>
                    <span
                      style={{
                        color: '#FFF',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      Master Your Skills with Mock Tests and Prepare to Achieve
                      Your Best Performance in the Upcoming Challenge.
                    </span>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <CompetitionAttemptmocktestCTA
                      onClick={() => navigate('/l/mockTest')}
                    >
                      Attempt Mock Tests
                    </CompetitionAttemptmocktestCTA>
                  </Grid>
                </Grid>
              </CompetitionStatisticsSection2>
            </Grid>
            <Grid item sm={4} xs={12}>
              <CompetitionDonoughtChart>
                <div
                  style={{
                    width: '50%',
                    margin: 'auto',
                    paddingBottom: '10px',
                  }}
                >
                  <DoughnutChart
                    data={[
                      competitionAnalytics.total_correct,
                      competitionAnalytics.total_incorrect,
                      competitionAnalytics.timed_out,
                      competitionAnalytics.skipped,
                    ]}
                    colors={['#388E3C', '#D60505', '#EE9D00', '#21A9DE']}
                    type="playCompetition"
                  />
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <CompetitionMetricsContainer>
                      <CompetitionMetricsContainerColor
                        style={{ background: '#388e3c' }}
                      ></CompetitionMetricsContainerColor>
                      &nbsp;
                      <span
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          fontSize: '10px',
                        }}
                      >
                        Correct
                      </span>
                    </CompetitionMetricsContainer>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <CompetitionMetricsContainer>
                      <CompetitionMetricsContainerColor
                        style={{ background: '#D60505' }}
                      ></CompetitionMetricsContainerColor>
                      &nbsp;
                      <span
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          fontSize: '10px',
                        }}
                      >
                        Incorrect
                      </span>
                    </CompetitionMetricsContainer>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <CompetitionMetricsContainer>
                      <CompetitionMetricsContainerColor
                        style={{ background: '#EE9D00' }}
                      ></CompetitionMetricsContainerColor>
                      &nbsp;
                      <span
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          fontSize: '10px',
                        }}
                      >
                        Timed Out
                      </span>
                    </CompetitionMetricsContainer>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <CompetitionMetricsContainer>
                      <CompetitionMetricsContainerColor
                        style={{ background: '#21A9DE' }}
                      ></CompetitionMetricsContainerColor>
                      &nbsp;
                      <span
                        style={{
                          color: 'black',
                          fontWeight: '500',
                          fontSize: '10px',
                        }}
                      >
                        Skipped
                      </span>
                    </CompetitionMetricsContainer>
                  </Grid>
                </Grid>
              </CompetitionDonoughtChart>
            </Grid>
          </Grid>
        </CompetitionStatisticsContainer>
      </div>
    </div>
  );
};

export default AllCompetitionAttempted;
