import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import {
  ButtonGroup,
  FormControl,
  IconButton,
  MenuItem,
  TablePagination,
  Typography,
  TableCell,
  TextField,
  Checkbox,
} from '@mui/material';
import Select2 from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { StyleDataManagement } from '../StyledComponent';
import QuickActionBar from './QuickActionBar';
import { deleteJobRole } from '../../../actions/dataManagement';
import AddJobRoleDialog from '../AddJobRoleDialog';
import MessageBar from '../../MessageBar';

const AdminJobRoleList = ({
  jobRole,
  selectedJobRole,
  handleJobModalOpen,
  isEditingStatus,
  setIsEditingStatus,
  setJobRoleId,
  page,
  rowsPerPage,
  onPageChangeHandler,
  handleChangeRowsPerPage,
  editableTableJobRole,
  jobRoleName,
  isEditing,
  setJobRoleName,
  setIsEditing,
  searchTerm,
  status,
  setSortOrder,
  sortOrder,
  setSortBy,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [selectedJobRoleList, setSelectedJobRoleList] = React.useState([]);
  const [showMergeJobRoleDialog, setShowMergeJobRoleDialog] = React.useState(
    false,
  );
  const [showDeleteError, setShowDeleteError] = React.useState(false);

  const handleJobRoleMultiSelect = (event, job) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [
        ...prevSelectedIds,
        job.job_role_id,
      ]);
      setSelectedJobRoleList((prevSelected) => [...prevSelected, job]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== job.job_role_id),
      );
      setSelectedJobRoleList((prevSelected) =>
        prevSelected.filter(
          (selectedRole) => selectedRole.job_role_id !== job?.job_role_id,
        ),
      );
    }
  };

  const handleActionDelete = (id, actionType) => {
    const deleteBody = actionType === 'bulk' ? selectedIds : [id];
    dispatch(
      deleteJobRole({ ids: deleteBody }, searchTerm, page, rowsPerPage, status),
    ).catch(() => {
      setShowDeleteError(true);
    });
    setSelectedIds([]);
    setSelectedJobRoleList([]);
  };

  const handleSelectAll = () => {
    setSelectedIds(
      jobRole.all_job_roles
        ?.filter((job) => job.approval_status !== 'Rejected')
        ?.map((obj) => obj.job_role_id),
    );
    setSelectedJobRoleList(
      jobRole?.all_job_roles?.filter(
        (job) => job.approval_status !== 'Rejected',
      ),
    );
  };

  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectedJobRoleList([]);
  };

  const handleActionMerge = () => {
    setShowMergeJobRoleDialog(true);
  };

  return (
    <StyleDataManagement className="collegeListContainer">
      <Table style={{ backgroundColor: 'white' }}>
        <TableHead>
          {jobRole.length === 0 ? (
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              No Records Found
            </Typography>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                Job Role{' '}
                {sortOrder === 'asc' ? (
                  <ArrowUpward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('desc');
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ height: '14px' }}
                    onClick={() => {
                      setSortBy('name');
                      setSortOrder('asc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          )}
        </TableHead>

        {jobRole.all_job_roles
          ?.filter((record) => record.approval_status !== 'Rejected')
          .map((job) => (
            <TableBody key={job.job_role_id}>
              <TableRow>
                <TableCell>
                  {' '}
                  <Checkbox
                    checked={selectedIds.includes(job.job_role_id)}
                    onChange={(e) => handleJobRoleMultiSelect(e, job)}
                  />{' '}
                </TableCell>
                <TableCell style={{ width: '20rem' }}>
                  {isEditing &&
                  selectedJobRole?.job_role_id === job?.job_role_id ? (
                    <>
                      <FormControl
                        style={{ marginTop: '0.5rem', width: '12rem' }}
                      >
                        <TextField
                          placeholder={'Field Cannot be empty'}
                          value={jobRoleName}
                          onChange={(e) => setJobRoleName(e.target.value)}
                          autoFocus
                        />
                      </FormControl>
                    </>
                  ) : (
                    <Typography
                      onClick={() => {
                        setJobRoleId(job.job_role_id);
                        setIsEditingStatus(true);
                        setJobRoleName(job.name);
                        setIsEditing(true);
                      }}
                    >
                      {job.name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ padding: '0', width: '15rem' }}
                >
                  {isEditingStatus &&
                  selectedJobRole.job_role_id === job.job_role_id ? (
                    <>
                      <FormControl style={{ width: '8rem', padding: 0 }}>
                        <Select2
                          {...register('approval_status', {
                            required: 'Please select a status',
                          })}
                          style={{
                            marginTop: '8px',
                            marginBottom: '4px',
                          }}
                          variant="outlined"
                          margin="dense"
                          displayEmpty
                          placeholder="College"
                          error={!!errors.approval_status}
                          defaultValue={
                            job.approval_status === null ||
                            job.approval_status === 'Pending'
                              ? 'Pending'
                              : 'Accepted'
                          }
                        >
                          <MenuItem value="Accepted" key="Accepted">
                            Accepted
                          </MenuItem>
                          <MenuItem value="Pending" key="Pending">
                            Pending
                          </MenuItem>
                          <MenuItem value="Rejected" key="Rejected">
                            Rejected
                          </MenuItem>
                        </Select2>
                      </FormControl>
                      <ButtonGroup style={{ textAlign: 'right' }}>
                        <IconButton
                          onClick={handleSubmit(editableTableJobRole)}
                          disabled={jobRoleName ? false : true}
                          size="large"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsEditingStatus(false);
                            setIsEditing(false);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </ButtonGroup>
                    </>
                  ) : (
                    <div className="college__sub-tags">
                      <Chip
                        onClick={() => {
                          setJobRoleId(job.job_role_id);
                          setIsEditingStatus(true);
                          setJobRoleName(job.name);
                          setIsEditing(true);
                        }}
                        variant="outlined"
                        color="success"
                        size="small"
                        label={
                          job.approval_status ? job.approval_status : 'Pending'
                        }
                        className="subtag"
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() => handleJobModalOpen(job.job_role_id)}
                    size="large"
                  >
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: {
                      xs: '50px',
                      sm: '150px',
                    },
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handleActionDelete(job.job_role_id, 'single')
                    }
                    size="large"
                  >
                    <Delete style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>

      {selectedIds.length > 0 ? (
        <QuickActionBar
          handleActionDelete={handleActionDelete}
          handleActionMerge={handleActionMerge}
          selectedIds={selectedIds}
          entityDataLength={jobRole?.all_job_roles?.length}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          page={page}
          rowsPerPage={rowsPerPage}
          paginationCount={jobRole.total_pages * jobRole.page_size}
          onPageChangeHandler={onPageChangeHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          count={jobRole.total_pages * jobRole.page_size}
          onPageChange={onPageChangeHandler}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows"
          className="scoreboard__navigation"
        />
      )}
      {showMergeJobRoleDialog && (
        <AddJobRoleDialog
          open={showMergeJobRoleDialog}
          handleClose={() => setShowMergeJobRoleDialog(false)}
          jobId={''}
          job={''}
          searchTerm={searchTerm}
          status={status || ''}
          page={page}
          rowsPerPage={rowsPerPage}
          onlyMerge={true}
          selectedJobRoleList={selectedJobRoleList}
          setSelectedJobRoleList={setSelectedJobRoleList}
          setSelectedIds={setSelectedIds}
        />
      )}
      <MessageBar
        type="error"
        open={showDeleteError}
        onClose={() => setShowDeleteError(false)}
        message={`internal server error`}
      />
    </StyleDataManagement>
  );
};

export default AdminJobRoleList;
