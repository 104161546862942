import { styled } from '@mui/styles';
import {
  Box,
  Dialog,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Typography,
  Avatar,
} from '@mui/material';
import Loader from '../Loader';

export const AppWrapper = styled('div')({
  marginTop: '10px',
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.component.background,
    opacity: '0.6',
  },
}));

export const ContainerWrapper = styled(Container)({
  margin: 'auto',
  width: '100%',
  padding: '0px',
  position: 'relative',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  right: '0px',
  color: theme.palette.mui.primary,
}));

export const StyledContainer = styled(Container)({
  paddingTop: '15px',
});

export const ToggleBox = styled(Box)(({ theme }) => ({
  height: '45px',
  width: '250px',
  background: theme.palette.common.white,
  borderRadius: '25px',
  boxShadow: `0px 0px 4px ${theme.palette.component.shadowColor}`,
  margin: 'auto',
  border: `1px solid ${theme.palette.component.toggleBoxBorder}`,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '0px 5px',
  },
  display: 'flex',
  justifyContent: 'space-around',
}));

export const ToggleTextSubject = styled(Typography)(({ type, theme }) => ({
  fontSize: '18px',
  margin: 'auto',
  padding: '0px 20px 0px 20px',
  borderRadius: '25px',
  ['&:hover']: {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    padding: '4px',
    width: '100%',
    textAlign: 'center',
  },
  background: type === 'Subject' && theme.palette.primary.main,
  color:
    type === 'Subject'
      ? theme.palette.common.white
      : theme.palette.primary.main,
}));
export const ToggleTextExam = styled(Typography)(({ type, theme }) => ({
  fontSize: '18px',
  margin: 'auto',
  padding: '0px 20px 0px 20px',
  borderRadius: '25px',
  ['&:hover']: {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    padding: '4px',
    width: '100%',
    textAlign: 'center',
  },
  background: type !== 'Subject' && theme.palette.primary.main,
  color:
    type !== 'Subject'
      ? theme.palette.common.white
      : theme.palette.primary.main,
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '30px',
  lineHeight: '30px',
  textAlign: 'center',
  letterSpacing: '0.4px',
  color: theme.palette.primary.main,
  marginTop: '10px',
  marginBottom: '15px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    marginTop: '7px',
    marginBottom: '7px',
  },
}));

export const SearchField = styled(InputBase)(({ theme }) => ({
  padding: '0 1em',
  background: theme.palette.success.contrastText,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '7px',
  height: '50px',
  width: '60%',
  marginBottom: '12px',
  color: 'theme.palette.primary.main',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  '& .text-input-field .MuiSelect-select': {
    paddingLeft: '0 !important',
  },
}));

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  height: '300px',
  overflow: 'auto',
  padding: '8px',
  [theme.breakpoints.down('md')]: {
    height: '280px',
  },
}));

export const EmptySearch = styled(Grid)({
  width: '100%',
  fontSize: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DomainGrid = styled(Button)(
  ({ changeDomainId, domain, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '10px 15px',
    marginBottom: '10px',
    width: '100%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
    background:
      changeDomainId === domain?.id
        ? theme.palette.secondary.main
        : theme.palette.secondary.button,
    boxShadow: `0px 0px 2px ${theme.palette.component.shadowColor2}`,
    height: '50px',
    paddingRight: '15px',
    cursor: 'pointer',
    '&:hover': {
      background:
        changeDomainId === domain?.id
          ? theme.palette.secondary.main
          : theme.palette.secondary.button,
    },
  }),
);

export const DomainName = styled(Typography)(
  ({ changeDomainId, domain, theme }) => ({
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'none',
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      textAlign: 'start',
    },
    color:
      changeDomainId === domain?.id
        ? theme.palette.common.white
        : theme.palette.primary.main,
  }),
);

export const StyledImage = styled('img')({
  position: 'absolute',
  top: '-8px',
  right: '-8px',
});

export const ApplyBtn = styled(Button)(({ theme }) => ({
  width: '150px',
  height: '50px',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '5px',
  fontWeight: 500,
  fontSize: '24px',
  textAlign: 'center',
  textTransform: 'none',
  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
    width: '100px',
    fontSize: '18px',
  },
  color: theme.palette.common.white,
}));

export const CancelBtn = styled(Button)(({ theme }) => ({
  width: '150px',
  height: '50px',
  border: 'none',
  borderRadius: '5px',
  background: theme.palette.component.color,
  fontWeight: 500,
  fontSize: '24px',
  textAlign: 'center',
  color: theme.palette.common.white,
  textTransform: 'none',
  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.secondary.main,
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
    width: '100px',
    fontSize: '18px',
  },
}));

export const TagListName = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TagListAvatar = styled(Avatar)({
  marginRight: '20px',
});

export const NoTag = styled('p')({
  marginTop: '10px',
});

export const TagListHeader = styled('div')({
  fontSize: '18px',
  flex: '1',
});

export const TagButton = styled(Button)(({ theme }) => ({
  marginLeft: '20px',
  color: theme.palette.mui.primary,
}));

export const NoContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingTop: '20px',
});

export const StyledLoader = styled(Loader)(({ theme }) => ({
  color: theme.palette.mui.primary,
}));

export const StyledCard = styled('div')(({ theme }) => ({
  marginBottom: '20px',
  background: theme.palette.common.white,
  borderRadius: '4px',
}));

export const CardHeading = styled('header')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  background: theme.palette.common.white,
  textTransform: 'uppercase',
  padding: '15px',
  borderBottom: `1px solid ${theme.palette.secondary.color}`,
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
}));

export const CardContent = styled('div')({
  padding: '15px',
});

export const FormWrapper = styled('div')({
  width: '62%',
  margin: '0 auto',

  '@media (max-width: 600px)': {
    width: '100%',
  },
});

export const TagsForm = styled('div')({
  padding: '40px 0',
});

export const FormControl = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'center',
});

export const FieldItem = styled('div')({
  flex: '1',
});
