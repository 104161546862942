import createReducer from './../lib/createReducer';
import * as types from './../actions/types';

export const competitions = createReducer(
  {
    list: [],
    details: {},
    analytics: {},
    isGettingCompetitionQuestions: true,
    questions: [],
    statisticsData: {},
    isFetchingCompetitionDetailAnalytics: false,
    adminAnalyticsData: {},
    isDeletingCompetition: false,
    deleteError: '',
    scoreboardList: [],
    competitionImgUrl: [],
    competitionHeaderUrl: [],
    isFetchingCompetitionList: false,
    competitionList: {},
  },
  {
    [types.GET_START_STATISTICS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        statisticsData: {},
        isFetchingStatistics: true,
      });

      return newState;
    },
    [types.GET_START_STATISTICS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        statisticsData: action.data,
        isFetchingStatistics: false,
      });

      return newState;
    },
    [types.GET_START_STATISTICS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        statisticsData: {},
        isFetchingStatistics: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_DETAIL_ANALYTICS_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCompetitionDetailAnalytics: true,
      });

      return newState;
    },
    [types.GET_COMPETITION_DETAIL_ANALYTICS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        adminAnalyticsData: action.data,
        isFetchingCompetitionDetailAnalytics: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_DETAIL_ANALYTICS_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isFetchingCompetitionDetailAnalytics: false,
      });

      return newState;
    },

    [types.GET_COMPETITION_QUESTION_START](state) {
      return {
        ...state,
        questions: [],
        isGettingCompetitionQuestions: true,
      };
    },
    [types.GET_COMPETITION_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        questions: action.data.all_attempts,
        questionsPaginationInfo: {
          currentPage: action.data.current_page - 1, // zero-based
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
        isGettingCompetitionQuestions: false,
      };
    },
    [types.GET_COMPETITION_QUESTION_FAIL](state, action) {
      return {
        ...state,
        questions: [],
        error: action.data,
        isGettingCompetitionQuestions: false,
      };
    },
    [types.UNLOCK_QUESTION_SUCCESS](state, action) {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.question_id === action.payload.data.question_id
            ? {
                ...question,
                is_solution_unlocked: true,
                question: {
                  ...question.question,
                  solution_text: action.payload.data.solution_text,
                },
              }
            : question,
        ),
      };
    },
    [types.GET_COMPETITION_START](state) {
      const newState = Object.assign({}, state, {
        list: [],
        details: {},
        isFetchingCompetition: true,
        uploadCompetitionImgSuccess: false,
        uploadCompetitionImgFail: false,
        uploadCompetitionHeaderSuccess: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        list: action.data.data,
        paginationInfo: {
          currentPage: action.data.current_page,
          pageSize: action.data.page_size,
          totalPages: action.data.total_pages,
        },
        isFetchingCompetition: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_FAIL](state) {
      const newState = Object.assign({}, state, {
        list: [],
        isFetchingCompetition: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_BY_ID_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingCompetitionById: true,
      });

      return newState;
    },
    [types.GET_COMPETITION_BY_ID_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        details: action.data,
        isFetchingCompetitionById: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_BY_ID_FAIL](state) {
      const newState = Object.assign({}, state, {
        details: {},
        isFetchingCompetitionById: false,
      });

      return newState;
    },
    [types.POST_COMPETITION_START](state) {
      const newState = Object.assign({}, state, {
        isSubmittingCompetition: true,
        postCompetitionSuccess: false,
        postCompetitionFail: false,
      });

      return newState;
    },
    [types.POST_COMPETITION_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        isSubmittingCompetition: false,
        postCompetitionSuccess: true,
      });

      return newState;
    },
    [types.POST_COMPETITION_FAIL](state) {
      const newState = Object.assign({}, state, {
        isSubmittingCompetition: false,
        postCompetitionFail: true,
      });

      return newState;
    },
    [types.POST_REGISTRATION_START](state) {
      const newState = Object.assign({}, state, {
        isRegistering: true,
        postRegistrationSuccess: false,
        postRegistrationFail: false,
      });

      return newState;
    },
    [types.POST_REGISTRATION_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        isRegistering: false,
        postRegistrationSuccess: true,
        details: {
          ...state.details,
          is_registered: true,
        },
      });

      return newState;
    },
    [types.POST_REGISTRATION_FAIL](state) {
      const newState = Object.assign({}, state, {
        isRegistering: false,
        postRegistrationFail: true,
      });

      return newState;
    },
    [types.GET_RECOMMENDATIONS_START](state) {
      const newState = Object.assign({}, state, {
        recommendationList: [],
        isFetchingRecommendations: true,
      });

      return newState;
    },
    [types.GET_RECOMMENDATIONS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        recommendationList: action.data,
        isFetchingRecommendations: false,
      });

      return newState;
    },
    [types.GET_RECOMMENDATIONS_FAIL](state) {
      const newState = Object.assign({}, state, {
        recommendationList: [],
        isFetchingRecommendations: false,
      });

      return newState;
    },
    [types.GET_LEADERBOARD_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        scoreboardList: action.data.rank_list || [],
        isFetchingScoreboard: false,
      });

      return newState;
    },
    [types.DELETE_COMPETITION_START](state) {
      return {
        ...state,
        isDeletingCompetition: true,
        deleteError: '',
      };
    },
    [types.DELETE_COMPETITION_SUCCESS](state, action) {
      const competitions = [...state.list];
      const { id: idToDelete } = action.data;
      const targetIndex = competitions.findIndex(
        (competition) => competition.id === idToDelete,
      );

      targetIndex !== -1 && competitions.splice(targetIndex, 1);

      return {
        ...state,
        list: competitions,
        isDeletingCompetition: false,
      };
    },
    [types.DELETE_COMPETITION_FAIL](state, action) {
      return {
        ...state,
        isDeletingCompetition: false,
        deleteError: action.data,
      };
    },
    [types.GET_COMPETITION_ANALYTICS_START](state) {
      const newState = Object.assign({}, state, {
        isFetchingAnalytics: true,
      });

      return newState;
    },
    [types.GET_COMPETITION_ANALYTICS_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isFetchingAnalytics: false,
        analytics: action.data,
      });

      return newState;
    },
    [types.GET_COMPETITION_ANALYTICS_FAIL](state) {
      const newState = Object.assign({}, state, {
        isFetchingAnalytics: false,
      });

      return newState;
    },
    [types.UPLOAD_COMPETITION_IMG_START](state) {
      const newState = Object.assign({}, state, {
        isUploadingCompetitionImg: true,
        isUploadingCompetitionHeader: true,
      });

      return newState;
    },
    [types.UPLOAD_COMPETITION_IMG_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isUploadingCompetitionImg: false,
        uploadCompetitionImgSuccess: true,
        competitionImgUrl: action.data.image_url,
      });

      return newState;
    },
    [types.UPLOAD_COMPETITION_HEADER_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        isUploadingCompetitionHeader: false,
        uploadCompetitionHeaderSuccess: true,
        competitionHeaderUrl: action.data.image_url,
        isUploadingCompetitionImg: false,
      });

      return newState;
    },
    [types.UPLOAD_COMPETITION_IMG_FAIL](state) {
      const newState = Object.assign({}, state, {
        isUploadingCompetitionImg: false,
        uploadCompetitionImgFail: true,
      });

      return newState;
    },
    [types.GET_COMPETITION_LIST_START](state) {
      return {
        ...state,
        isFetchingCompetitionList: true,
      };
    },
    [types.GET_COMPETITION_LIST_SUCCESS](state, action) {
      return {
        ...state,
        isFetchingCompetitionList: false,
        competitionList: action.data,
      };
    },
    [types.GET_COMPETITION_LIST_FAIL](state, action) {
      return {
        ...state,
        isFetchingCompetitionList: false,
        error: action.data,
      };
    },
  },
);
export const competitionsSchedules = createReducer(
  {
    isCompetitionsScheduleFecthing: true,
    isPostingCompetitionsSchedule: true,
    isDeleteCompetitionsSchedule: true,
    schedules: [],
  },
  {
    [types.GET_COMPETITION_SCHEDULE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isCompetitionsScheduleFecthing: true,
        isPostingCompetitionsScheduleSuccess: false,
        isPostingCompetitionsScheduleFail: false,
      });

      return newState;
    },
    [types.GET_COMPETITION_SCHEDULE_SUCCESS](state, action) {
      const newState = Object.assign({}, state, {
        ...state,
        isCompetitionsScheduleFecthing: false,
        schedules: action.data,
      });

      return newState;
    },
    [types.GET_COMPETITION_SCHEDULE_FAIL](state, action) {
      return {
        ...state,
        isCompetitionsScheduleFecthing: false,
        error: action.data,
      };
    },

    [types.POST_COMPETITION_SCHEDULE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingCompetitionsSchedule: true,
        isPostingCompetitionsScheduleSuccess: false,
        isPostingCompetitionsScheduleFail: false,
      });
      return newState;
    },
    [types.POST_COMPETITION_SCHEDULE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingCompetitionsSchedule: false,
        isPostingCompetitionsScheduleSuccess: true,
      });

      return newState;
    },
    [types.POST_COMPETITION_SCHEDULE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isPostingCompetitionsSchedule: false,
        isPostingCompetitionsScheduleFail: true,
      });
      return newState;
    },
    [types.DELETE_COMPETITION_SCHEDULE_START](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeleteCompetitionsSchedule: true,
      });
      return newState;
    },
    [types.DELETE_COMPETITION_SCHEDULE_SUCCESS](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeleteCompetitionsSchedule: false,
      });

      return newState;
    },
    [types.DELETE_COMPETITION_SCHEDULE_FAIL](state) {
      const newState = Object.assign({}, state, {
        ...state,
        isDeleteCompetitionsSchedule: false,
      });
      return newState;
    },
  },
);
export { competitions as default };
