import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAnsweredQuestions } from './../actions/question';
import { getTag } from './../actions/tag';
import { unlockSolution } from './../actions/question';
import Component from './../components/UserProfile/YourQuestions';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAnsweredQuestions,
      getTag,
      unlockSolution,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    tag: state.tag,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
