/* tslint:disable */
/* eslint-disable */
/**
 * Abekus Open API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AcceptReportResponse
 */
export interface AcceptReportResponse {
  /**
   *
   * @type {Question}
   * @memberof AcceptReportResponse
   */
  data?: Question;
  /**
   *
   * @type {boolean}
   * @memberof AcceptReportResponse
   */
  resolved?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AcceptReportResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface ActiveJobBody
 */
export interface ActiveJobBody {
  /**
   *
   * @type {Array<string>}
   * @memberof ActiveJobBody
   */
  job_mode?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  min_experience?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ActiveJobBody
   */
  skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  salary_max?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  posted_since?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ActiveJobBody
   */
  job_role_ids?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ActiveJobBody
   */
  job_type?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  salary_min?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  company_size?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  sort_by?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ActiveJobBody
   */
  location_ids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  bookmarked?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  page_size?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobBody
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof ActiveJobBody
   */
  max_experience?: number;
}
/**
 *
 * @export
 * @interface ActiveJobSub
 */
export interface ActiveJobSub {
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobSub
   */
  total_applicants?: number;
  /**
   *
   * @type {boolean}
   * @memberof ActiveJobSub
   */
  is_bookmarked?: boolean;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  work_flexibility?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  company_logo_url?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobSub
   */
  minimum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  org_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  competition_url?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  company_size?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof ActiveJobSub
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {number}
   * @memberof ActiveJobSub
   */
  application_credits?: number;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  additional_details?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  company_name?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobSub
   */
  no_of_positions?: number;
  /**
   *
   * @type {Location}
   * @memberof ActiveJobSub
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof ActiveJobSub
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof ActiveJobSub
   */
  salary_frequency?: string;
  /**
   *
   * @type {number}
   * @memberof ActiveJobSub
   */
  maximum_salary?: number;
}
/**
 *
 * @export
 * @interface ActiveJobsResponse
 */
export interface ActiveJobsResponse {
  /**
   *
   * @type {number}
   * @memberof ActiveJobsResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<ActiveJobSub>}
   * @memberof ActiveJobsResponse
   */
  jobs?: Array<ActiveJobSub>;
  /**
   *
   * @type {number}
   * @memberof ActiveJobsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ActiveJobsResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface AddApplicantMessageBody
 */
export interface AddApplicantMessageBody {
  /**
   *
   * @type {string}
   * @memberof AddApplicantMessageBody
   */
  applicant_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMessageBody
   */
  attachment_url?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMessageBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface AddApplicantMsgResponse
 */
export interface AddApplicantMsgResponse {
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  applicant_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddApplicantMsgResponse
   */
  is_chat_seen?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  attachment_url?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  from_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof AddApplicantMsgResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AddItemToCartBody
 */
export interface AddItemToCartBody {
  /**
   *
   * @type {string}
   * @memberof AddItemToCartBody
   */
  item_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddItemToCartBody
   */
  item_payment_mode?: string;
  /**
   *
   * @type {number}
   * @memberof AddItemToCartBody
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface AddItemToCartResponse
 */
export interface AddItemToCartResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddItemToCartResponse
   */
  coupon_valid?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddItemToCartResponse
   */
  cart_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddItemToCartResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddItemToCartResponse
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddItemToCartResponse
   */
  coupon_failure_reason?: string;
}
/**
 *
 * @export
 * @interface AddJobItemToCartBody
 */
export interface AddJobItemToCartBody {
  /**
   *
   * @type {string}
   * @memberof AddJobItemToCartBody
   */
  item_id?: string;
  /**
   *
   * @type {string}
   * @memberof AddJobItemToCartBody
   */
  job_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddJobItemToCartBody
   */
  item_payment_mode?: string;
  /**
   *
   * @type {number}
   * @memberof AddJobItemToCartBody
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface AddNewStoreItemBody
 */
export interface AddNewStoreItemBody {
  /**
   *
   * @type {boolean}
   * @memberof AddNewStoreItemBody
   */
  is_reward?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  duration_type?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AddNewStoreItemBody
   */
  image_url?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  available_to?: string;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  discount_pct_for_gems?: number | null;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  duration_count?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof AddNewStoreItemBody
   */
  shipping_required?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  item_description?: string;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  gross_price_in_gems?: number | null;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  long_description?: string;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  sku_count?: number;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  item_type?: string;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  gross_price_in_currency?: number | null;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  sku_size?: number | null;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof AddNewStoreItemBody
   */
  discount_pct_for_currency?: number | null;
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemBody
   */
  currency?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddNewStoreItemBody
   */
  is_active?: boolean;
}
/**
 *
 * @export
 * @interface AddNewStoreItemResponse
 */
export interface AddNewStoreItemResponse {
  /**
   *
   * @type {string}
   * @memberof AddNewStoreItemResponse
   */
  item_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddNewStoreItemResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface AddUserSkillBody
 */
export interface AddUserSkillBody {
  /**
   *
   * @type {string}
   * @memberof AddUserSkillBody
   */
  skill_id: string;
  /**
   *
   * @type {number}
   * @memberof AddUserSkillBody
   */
  rating: number;
}
/**
 *
 * @export
 * @interface AddUserSkillsInternalBody
 */
export interface AddUserSkillsInternalBody {
  /**
   *
   * @type {string}
   * @memberof AddUserSkillsInternalBody
   */
  user_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AddUserSkillsInternalBody
   */
  skills?: Array<string>;
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  phone_no: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  pincode: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address_type?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AdminGetAllSkillsResponse
 */
export interface AdminGetAllSkillsResponse {
  /**
   *
   * @type {number}
   * @memberof AdminGetAllSkillsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof AdminGetAllSkillsResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof AdminGetAllSkillsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<CreateSkillSub>}
   * @memberof AdminGetAllSkillsResponse
   */
  skills?: Array<CreateSkillSub>;
}
/**
 *
 * @export
 * @interface AdminUpdateJobBody
 */
export interface AdminUpdateJobBody {
  /**
   *
   * @type {number}
   * @memberof AdminUpdateJobBody
   */
  job_credits?: number;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateJobBody
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateJobBody
   */
  expires_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateJobBody
   */
  is_premium?: boolean;
}
/**
 *
 * @export
 * @interface AdminUpdateTrasnsactionBody
 */
export interface AdminUpdateTrasnsactionBody {
  /**
   *
   * @type {string}
   * @memberof AdminUpdateTrasnsactionBody
   */
  overall_transaction_status?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateTrasnsactionBody
   */
  user_msg?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateTrasnsactionBody
   */
  internal_msg?: string;
}
/**
 *
 * @export
 * @interface AdminUpdateTrasnsactionResponse
 */
export interface AdminUpdateTrasnsactionResponse {
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateTrasnsactionResponse
   */
  success?: boolean;
  /**
   *
   * @type {Transaction}
   * @memberof AdminUpdateTrasnsactionResponse
   */
  transaction_details?: Transaction;
}
/**
 *
 * @export
 * @interface AdminUserUpdateBody
 */
export interface AdminUserUpdateBody {
  /**
   *
   * @type {number}
   * @memberof AdminUserUpdateBody
   */
  credits?: number;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateBody
   */
  is_active?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateBody
   */
  is_banned?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateBody
   */
  user_type?: string;
}
/**
 *
 * @export
 * @interface AdminUserUpdateResponse
 */
export interface AdminUserUpdateResponse {
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  username?: string;
  /**
   *
   * @type {number}
   * @memberof AdminUserUpdateResponse
   */
  login_streak?: number;
  /**
   *
   * @type {number}
   * @memberof AdminUserUpdateResponse
   */
  gems?: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  gender?: string;
  /**
   *
   * @type {number}
   * @memberof AdminUserUpdateResponse
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  first_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateResponse
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  picture?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateResponse
   */
  is_banned?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  phone_number?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminUserUpdateResponse
   */
  viewed_onboardings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  about?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateResponse
   */
  is_profile_hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  default_color?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminUserUpdateResponse
   */
  completed_profile_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  last_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserUpdateResponse
   */
  is_phone_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  referral_hash?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  selected_domain?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminUserUpdateResponse
   */
  completed_job_profile_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdateResponse
   */
  country?: string;
}
/**
 *
 * @export
 * @interface AdvancedUserSearchResponse
 */
export interface AdvancedUserSearchResponse {
  /**
   *
   * @type {number}
   * @memberof AdvancedUserSearchResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof AdvancedUserSearchResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof AdvancedUserSearchResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<UserDetailsSub>}
   * @memberof AdvancedUserSearchResponse
   */
  user_performance_details?: Array<UserDetailsSub>;
}
/**
 *
 * @export
 * @interface AllExamDetailsResponse
 */
export interface AllExamDetailsResponse {
  /**
   *
   * @type {number}
   * @memberof AllExamDetailsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof AllExamDetailsResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof AllExamDetailsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<ExamDetailSub>}
   * @memberof AllExamDetailsResponse
   */
  all_external_exams?: Array<ExamDetailSub>;
}
/**
 *
 * @export
 * @interface AllJobApplicantResponse
 */
export interface AllJobApplicantResponse {
  /**
   *
   * @type {number}
   * @memberof AllJobApplicantResponse
   */
  total_unfiltered_applicants?: number;
  /**
   *
   * @type {number}
   * @memberof AllJobApplicantResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<ApplicantListSub>}
   * @memberof AllJobApplicantResponse
   */
  all_applicants?: Array<ApplicantListSub>;
  /**
   *
   * @type {number}
   * @memberof AllJobApplicantResponse
   */
  total_applicants?: number;
  /**
   *
   * @type {number}
   * @memberof AllJobApplicantResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof AllJobApplicantResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface AnalyticsResponseSub
 */
export interface AnalyticsResponseSub {
  /**
   *
   * @type {Array<TagSub>}
   * @memberof AnalyticsResponseSub
   */
  tags?: Array<TagSub>;
  /**
   *
   * @type {{ [key: string]: Competition; }}
   * @memberof AnalyticsResponseSub
   */
  competitions?: { [key: string]: Competition };
  /**
   *
   * @type {{ [key: string]: DomainSub; }}
   * @memberof AnalyticsResponseSub
   */
  domains?: { [key: string]: DomainSub };
  /**
   *
   * @type {Overall}
   * @memberof AnalyticsResponseSub
   */
  overall?: Overall;
}
/**
 *
 * @export
 * @interface AnsweredQuestionResponse
 */
export interface AnsweredQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<Attempt>}
   * @memberof AnsweredQuestionResponse
   */
  all_attempts?: Array<Attempt>;
}
/**
 *
 * @export
 * @interface AnsweredQuestionSub
 */
export interface AnsweredQuestionSub {
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  total_bookmarks?: number | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof AnsweredQuestionSub
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  quality_score?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AnsweredQuestionSub
   */
  second_similar?: Array<string>;
  /**
   *
   * @type {Array<Option>}
   * @memberof AnsweredQuestionSub
   */
  options?: Array<Option>;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  attempt_accuracy?: string | null;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  total_solution_likes?: number;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  attempt_rate?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  correct_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredQuestionSub
   */
  is_credit_added?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof AnsweredQuestionSub
   */
  answer_one_word?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  question_attempts?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AnsweredQuestionSub
   */
  first_similar?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredQuestionSub
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredQuestionSub
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AnsweredQuestionSub
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  max_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredQuestionSub
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {object}
   * @memberof AnsweredQuestionSub
   */
  user_data?: object;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  completed_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  exam_type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnsweredQuestionSub
   */
  is_deleted?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof AnsweredQuestionSub
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  year?: number | null;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof AnsweredQuestionSub
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {number}
   * @memberof AnsweredQuestionSub
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnsweredQuestionSub
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface ApplicantChatUserDetails
 */
export interface ApplicantChatUserDetails {
  /**
   *
   * @type {string}
   * @memberof ApplicantChatUserDetails
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantChatUserDetails
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantChatUserDetails
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantChatUserDetails
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface ApplicantDetails
 */
export interface ApplicantDetails {
  /**
   *
   * @type {number}
   * @memberof ApplicantDetails
   */
  assessment_score?: number;
  /**
   *
   * @type {Array<AssessmentWithScoreResponse>}
   * @memberof ApplicantDetails
   */
  assessment_response?: Array<AssessmentWithScoreResponse>;
  /**
   *
   * @type {string}
   * @memberof ApplicantDetails
   */
  applied_on?: string;
  /**
   *
   * @type {ResumeDetails}
   * @memberof ApplicantDetails
   */
  resume_details?: ResumeDetails;
  /**
   *
   * @type {{ [key: string]: CompetitionSchemaWithTagScores; }}
   * @memberof ApplicantDetails
   */
  competition_details?: { [key: string]: CompetitionSchemaWithTagScores };
  /**
   *
   * @type {string}
   * @memberof ApplicantDetails
   */
  resume_analysis_status?: string;
  /**
   *
   * @type {Array<JobNotesResponse>}
   * @memberof ApplicantDetails
   */
  user_notes?: Array<JobNotesResponse>;
}
/**
 *
 * @export
 * @interface ApplicantDetailsPage
 */
export interface ApplicantDetailsPage {
  /**
   *
   * @type {number}
   * @memberof ApplicantDetailsPage
   */
  page_no?: number;
  /**
   *
   * @type {ApplicantDetails}
   * @memberof ApplicantDetailsPage
   */
  applicant_details?: ApplicantDetails;
  /**
   *
   * @type {number}
   * @memberof ApplicantDetailsPage
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface ApplicantListParam
 */
export interface ApplicantListParam {
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_assessment_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {CompetitionData}
   * @memberof ApplicantListParam
   */
  competition_data?: CompetitionData;
  /**
   *
   * @type {string}
   * @memberof ApplicantListParam
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_experience?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_rating?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantListParam
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicantListParam
   */
  applicant_status?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_rating?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantListParam
   */
  job_preference?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListParam
   */
  sort_by?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicantListParam
   */
  resume_uploaded?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_education?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_education?: number;
  /**
   *
   * @type {SortBy}
   * @memberof ApplicantListParam
   */
  sort_by_competition_data?: SortBy;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_resume_analysis_overall_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_assessment_score?: number;
  /**
   *
   * @type {Array<RequiredSkillsFilter>}
   * @memberof ApplicantListParam
   */
  required_skills?: Array<RequiredSkillsFilter>;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  notice_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicantListParam
   */
  count_only?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApplicantListParam
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface ApplicantListSub
 */
export interface ApplicantListSub {
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  rating?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  expected_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  latest_message_time?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  total_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicantListSub
   */
  is_online?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  created_at?: string;
  /**
   *
   * @type {ApplicantListUserSub}
   * @memberof ApplicantListSub
   */
  user?: ApplicantListUserSub;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  job_alias?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  user_job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  resume_analysis_status?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  hiring_status?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  modified_on?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  serial_no?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  total_assessment_score?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  latest_message?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicantListSub
   */
  resume_analysis_overall_score?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicantListSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ApplicantListUserSub
 */
export interface ApplicantListUserSub {
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  default_color?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicantListUserSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ApplicantRating
 */
export interface ApplicantRating {
  /**
   *
   * @type {string}
   * @memberof ApplicantRating
   */
  message?: string;
}
/**
 *
 * @export
 * @interface AppliedJobsSub
 */
export interface AppliedJobsSub {
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  applied_on?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  job_role?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  location_id?: string;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  total_applicants?: number;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AppliedJobsSub
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  company_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  minimum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  hiring_status?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof AppliedJobsSub
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {boolean}
   * @memberof AppliedJobsSub
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  applicant_id?: string;
  /**
   *
   * @type {Array<ResponseAndField>}
   * @memberof AppliedJobsSub
   */
  assessment_response?: Array<ResponseAndField>;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  minimum_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof AppliedJobsSub
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof AppliedJobsSub
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof AppliedJobsSub
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof AppliedJobsSub
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface AssessmentField
 */
export interface AssessmentField {
  /**
   *
   * @type {object}
   * @memberof AssessmentField
   */
  meta_details?: object;
  /**
   *
   * @type {string}
   * @memberof AssessmentField
   */
  assessment_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentField
   */
  title?: string;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentField
   */
  is_required?: boolean;
  /**
   *
   * @type {number}
   * @memberof AssessmentField
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof AssessmentField
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentField
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AssessmentFieldWithScore
 */
export interface AssessmentFieldWithScore {
  /**
   *
   * @type {object}
   * @memberof AssessmentFieldWithScore
   */
  meta_details?: object;
  /**
   *
   * @type {string}
   * @memberof AssessmentFieldWithScore
   */
  assessment_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentFieldWithScore
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentFieldWithScore
   */
  score?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssessmentFieldWithScore
   */
  is_required?: boolean;
  /**
   *
   * @type {number}
   * @memberof AssessmentFieldWithScore
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof AssessmentFieldWithScore
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentFieldWithScore
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AssessmentResponseResponse
 */
export interface AssessmentResponseResponse {
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseResponse
   */
  assessment_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseResponse
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseResponse
   */
  field_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AssessmentResponseWithScoreResponse
 */
export interface AssessmentResponseWithScoreResponse {
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseWithScoreResponse
   */
  assessment_id?: string;
  /**
   *
   * @type {number}
   * @memberof AssessmentResponseWithScoreResponse
   */
  score?: number;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseWithScoreResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseWithScoreResponse
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseWithScoreResponse
   */
  field_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentResponseWithScoreResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface AssessmentWithScoreResponse
 */
export interface AssessmentWithScoreResponse {
  /**
   *
   * @type {AssessmentResponseWithScoreResponse}
   * @memberof AssessmentWithScoreResponse
   */
  response?: AssessmentResponseWithScoreResponse;
  /**
   *
   * @type {AssessmentFieldWithScore}
   * @memberof AssessmentWithScoreResponse
   */
  field?: AssessmentFieldWithScore;
}
/**
 *
 * @export
 * @interface Attempt
 */
export interface Attempt {
  /**
   *
   * @type {number}
   * @memberof Attempt
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  bookmark?: boolean;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  created_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  attempted_answer_one_word?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_attempt_correct?: boolean;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_disliked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_solution_liked?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  question_skipped?: boolean;
  /**
   *
   * @type {number}
   * @memberof Attempt
   */
  purchased_time?: number;
  /**
   *
   * @type {number}
   * @memberof Attempt
   */
  attempt_score?: number;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  question_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_solution_unlocked?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_attempt_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_solution_disliked?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  competition_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  modified_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  custom_mocktest_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  custom_mocktest_question_num?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof Attempt
   */
  attempted_answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  user_id?: string;
  /**
   *
   * @type {AnsweredQuestionSub}
   * @memberof Attempt
   */
  question?: AnsweredQuestionSub;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  timeout?: boolean;
  /**
   *
   * @type {number}
   * @memberof Attempt
   */
  max_question_score?: number;
  /**
   *
   * @type {boolean}
   * @memberof Attempt
   */
  is_hint_unlocked?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Attempt
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface AttemptDetailsSub
 */
export interface AttemptDetailsSub {
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  bookmark?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  created_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  attempted_answer_one_word?: string | null;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_disliked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_solution_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  question_skipped?: boolean;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub
   */
  purchased_time?: number;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub
   */
  attempt_score?: number;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  question_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_solution_unlocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_attempt_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_solution_disliked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  modified_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  competition_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  custom_mocktest_id?: string;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub
   */
  custom_mocktest_question_num?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AttemptDetailsSub
   */
  attempted_answer_option_no?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  user_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  timeout?: boolean;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub
   */
  max_question_score?: number;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub
   */
  is_hint_unlocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface AttemptDetailsSub1
 */
export interface AttemptDetailsSub1 {
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub1
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  bookmark?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  attempted_answer_one_word?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_disliked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_solution_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  question_skipped?: boolean;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub1
   */
  purchased_time?: number;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub1
   */
  attempt_score?: number;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  question_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_solution_unlocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_attempt_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_solution_disliked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  custom_mocktest_id?: string;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub1
   */
  custom_mocktest_question_num?: number;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  attempted_answer_option_no?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  user_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  timeout?: boolean;
  /**
   *
   * @type {number}
   * @memberof AttemptDetailsSub1
   */
  max_question_score?: number;
  /**
   *
   * @type {boolean}
   * @memberof AttemptDetailsSub1
   */
  is_hint_unlocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptDetailsSub1
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface AttemptInfoResponse
 */
export interface AttemptInfoResponse {
  /**
   *
   * @type {Data1}
   * @memberof AttemptInfoResponse
   */
  data?: Data1;
}
/**
 *
 * @export
 * @interface AttemptQuestionBody
 */
export interface AttemptQuestionBody {
  /**
   *
   * @type {number}
   * @memberof AttemptQuestionBody
   */
  time_taken?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AttemptQuestionBody
   */
  answer_option_no?: Array<number> | null;
  /**
   *
   * @type {Array<SuspiciousActivity>}
   * @memberof AttemptQuestionBody
   */
  activity_data?: Array<SuspiciousActivity>;
  /**
   *
   * @type {string}
   * @memberof AttemptQuestionBody
   */
  question_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionBody
   */
  timeout?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptQuestionBody
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof AttemptQuestionBody
   */
  answer_one_word?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionBody
   */
  question_skipped?: boolean;
}
/**
 *
 * @export
 * @interface AttemptQuestionResponse
 */
export interface AttemptQuestionResponse {
  /**
   *
   * @type {Exam}
   * @memberof AttemptQuestionResponse
   */
  exam_details?: Exam;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  is_attempt_correct?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof AttemptQuestionResponse
   */
  max_question_score?: number;
  /**
   *
   * @type {ProgressObj}
   * @memberof AttemptQuestionResponse
   */
  progress_obj?: ProgressObj;
  /**
   *
   * @type {number}
   * @memberof AttemptQuestionResponse
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  is_disliked?: boolean;
  /**
   *
   * @type {string}
   * @memberof AttemptQuestionResponse
   */
  question_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  bookmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  timeout?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof AttemptQuestionResponse
   */
  correct_options?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof AttemptQuestionResponse
   */
  attempt_score?: number;
  /**
   *
   * @type {string}
   * @memberof AttemptQuestionResponse
   */
  answer_one_word?: string;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AttemptQuestionResponse
   */
  question_skipped?: boolean;
}
/**
 *
 * @export
 * @interface AuthBody
 */
export interface AuthBody {
  /**
   *
   * @type {string}
   * @memberof AuthBody
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof AuthBody
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AuthReponse
 */
export interface AuthReponse {
  /**
   *
   * @type {string}
   * @memberof AuthReponse
   */
  token?: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthReponse
   */
  success?: boolean;
  /**
   *
   * @type {UserResponse}
   * @memberof AuthReponse
   */
  user?: UserResponse;
}
/**
 *
 * @export
 * @interface AuthorAutoCompleteResponse
 */
export interface AuthorAutoCompleteResponse {
  /**
   *
   * @type {number}
   * @memberof AuthorAutoCompleteResponse
   */
  page_no?: number;
  /**
   *
   * @type {Array<AuthorAutoCompleteUserSub>}
   * @memberof AuthorAutoCompleteResponse
   */
  user_details?: Array<AuthorAutoCompleteUserSub>;
  /**
   *
   * @type {number}
   * @memberof AuthorAutoCompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof AuthorAutoCompleteResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface AuthorAutoCompleteUserSub
 */
export interface AuthorAutoCompleteUserSub {
  /**
   *
   * @type {string}
   * @memberof AuthorAutoCompleteUserSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof AuthorAutoCompleteUserSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof AuthorAutoCompleteUserSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface BackendConstantsSub
 */
export interface BackendConstantsSub {
  /**
   *
   * @type {string}
   * @memberof BackendConstantsSub
   */
  docstring?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BackendConstantsSub
   */
  values?: Array<string>;
}
/**
 *
 * @export
 * @interface BadgeObjSub
 */
export interface BadgeObjSub {
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  entity_id?: string;
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  last_received_date?: string;
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  badge_id?: string;
  /**
   *
   * @type {number}
   * @memberof BadgeObjSub
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BadgeObjSub
   */
  badge_name?: string;
}
/**
 *
 * @export
 * @interface BadgeSub
 */
export interface BadgeSub {
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  segment?: string;
  /**
   *
   * @type {number}
   * @memberof BadgeSub
   */
  credit_reward?: number;
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof BadgeSub
   */
  gem_reward?: number;
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof BadgeSub
   */
  badge_count?: number;
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof BadgeSub
   */
  milestone_no?: number;
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof BadgeSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface BatchPerformanceResponse
 */
export interface BatchPerformanceResponse {
  /**
   *
   * @type {number}
   * @memberof BatchPerformanceResponse
   */
  total_questions_created?: number;
  /**
   *
   * @type {number}
   * @memberof BatchPerformanceResponse
   */
  overall_avg_accuracy?: number;
  /**
   *
   * @type {{ [key: string]: MonthlyStatsSub; }}
   * @memberof BatchPerformanceResponse
   */
  yearly_overview?: { [key: string]: MonthlyStatsSub };
}
/**
 *
 * @export
 * @interface BlockStatistics
 */
export interface BlockStatistics {
  /**
   *
   * @type {number}
   * @memberof BlockStatistics
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof BlockStatistics
   */
  reason?: string;
}
/**
 *
 * @export
 * @interface BookmarkQuestionBody
 */
export interface BookmarkQuestionBody {
  /**
   *
   * @type {boolean}
   * @memberof BookmarkQuestionBody
   */
  bookmark_status?: boolean;
}
/**
 *
 * @export
 * @interface BulkAddJobApplicantMessageBody
 */
export interface BulkAddJobApplicantMessageBody {
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_assessment_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {CompetitionData}
   * @memberof BulkAddJobApplicantMessageBody
   */
  competition_data?: CompetitionData;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_experience?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkAddJobApplicantMessageBody
   */
  applicant_status?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  job_preference?: string;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  attachment_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  sort_by?: string;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkAddJobApplicantMessageBody
   */
  resume_uploaded?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_education?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkAddJobApplicantMessageBody
   */
  custom_applicant_user_ids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_education?: number;
  /**
   *
   * @type {SortBy}
   * @memberof BulkAddJobApplicantMessageBody
   */
  sort_by_competition_data?: SortBy;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_resume_analysis_overall_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_assessment_score?: number;
  /**
   *
   * @type {Array<RequiredSkillsFilter>}
   * @memberof BulkAddJobApplicantMessageBody
   */
  required_skills?: Array<RequiredSkillsFilter>;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  notice_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkAddJobApplicantMessageBody
   */
  count_only?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkAddJobApplicantMessageBody
   */
  page_size?: number;
  /**
   *
   * @type {string}
   * @memberof BulkAddJobApplicantMessageBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface BulkAddJobItemToCartBody
 */
export interface BulkAddJobItemToCartBody {
  /**
   *
   * @type {string}
   * @memberof BulkAddJobItemToCartBody
   */
  coupon_code?: string | null;
  /**
   *
   * @type {Array<AddJobItemToCartBody>}
   * @memberof BulkAddJobItemToCartBody
   */
  items?: Array<AddJobItemToCartBody>;
}
/**
 *
 * @export
 * @interface BulkApplicantActionBody
 */
export interface BulkApplicantActionBody {
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_assessment_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {CompetitionData}
   * @memberof BulkApplicantActionBody
   */
  competition_data?: CompetitionData;
  /**
   *
   * @type {string}
   * @memberof BulkApplicantActionBody
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_experience?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkApplicantActionBody
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkApplicantActionBody
   */
  applicant_status?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkApplicantActionBody
   */
  job_preference?: string;
  /**
   *
   * @type {string}
   * @memberof BulkApplicantActionBody
   */
  sort_by?: string;
  /**
   *
   * @type {string}
   * @memberof BulkApplicantActionBody
   */
  hiring_status: string;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkApplicantActionBody
   */
  resume_uploaded?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_education?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkApplicantActionBody
   */
  custom_applicant_user_ids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_education?: number;
  /**
   *
   * @type {SortBy}
   * @memberof BulkApplicantActionBody
   */
  sort_by_competition_data?: SortBy;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_resume_analysis_overall_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_assessment_score?: number;
  /**
   *
   * @type {Array<RequiredSkillsFilter>}
   * @memberof BulkApplicantActionBody
   */
  required_skills?: Array<RequiredSkillsFilter>;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  notice_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkApplicantActionBody
   */
  count_only?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkApplicantActionBody
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface BulkCompanyDeleteResponse
 */
export interface BulkCompanyDeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof BulkCompanyDeleteResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkCompanyDeleteResponse
   */
  deleted?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkCompanyDeleteResponse
   */
  skipped?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkDeleteBody
 */
export interface BulkDeleteBody {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkDeleteBody
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkDeleteBody1
 */
export interface BulkDeleteBody1 {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkDeleteBody1
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkDeleteBody2
 */
export interface BulkDeleteBody2 {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkDeleteBody2
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkDeleteBody3
 */
export interface BulkDeleteBody3 {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BulkDeleteBody3
   */
  constraints?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface BulkDeleteMockTestResponse
 */
export interface BulkDeleteMockTestResponse {
  /**
   *
   * @type {number}
   * @memberof BulkDeleteMockTestResponse
   */
  mocktest_deleted?: number;
}
/**
 *
 * @export
 * @interface BulkDeleteResponse
 */
export interface BulkDeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof BulkDeleteResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface BulkDeleteResponse1
 */
export interface BulkDeleteResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof BulkDeleteResponse1
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface BulkDeleteResponse2
 */
export interface BulkDeleteResponse2 {
  /**
   *
   * @type {boolean}
   * @memberof BulkDeleteResponse2
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface BulkResumeApplicantUserIdsBody
 */
export interface BulkResumeApplicantUserIdsBody {
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_assessment_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {CompetitionData}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  competition_data?: CompetitionData;
  /**
   *
   * @type {string}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_experience?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  applicant_status?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_rating?: number;
  /**
   *
   * @type {string}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  job_preference?: string;
  /**
   *
   * @type {string}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  sort_by?: string;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  resume_uploaded?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_education?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  custom_applicant_user_ids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_education?: number;
  /**
   *
   * @type {SortBy}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  sort_by_competition_data?: SortBy;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_resume_analysis_overall_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_assessment_score?: number;
  /**
   *
   * @type {Array<RequiredSkillsFilter>}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  required_skills?: Array<RequiredSkillsFilter>;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  notice_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  count_only?: boolean;
  /**
   *
   * @type {number}
   * @memberof BulkResumeApplicantUserIdsBody
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface BulkStatusUpdateBody
 */
export interface BulkStatusUpdateBody {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkStatusUpdateBody
   */
  job_ids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof BulkStatusUpdateBody
   */
  rejection_reason?: string;
  /**
   *
   * @type {string}
   * @memberof BulkStatusUpdateBody
   */
  status?: string;
}
/**
 *
 * @export
 * @interface BulkUpdateOrgBody
 */
export interface BulkUpdateOrgBody {
  /**
   *
   * @type {Array<string>}
   * @memberof BulkUpdateOrgBody
   */
  org_ids: Array<string>;
  /**
   *
   * @type {string}
   * @memberof BulkUpdateOrgBody
   */
  status: string;
}
/**
 *
 * @export
 * @interface CancelPaymentBody
 */
export interface CancelPaymentBody {
  /**
   *
   * @type {string}
   * @memberof CancelPaymentBody
   */
  transaction_id?: string;
}
/**
 *
 * @export
 * @interface CancelPaymentResponse
 */
export interface CancelPaymentResponse {
  /**
   *
   * @type {boolean}
   * @memberof CancelPaymentResponse
   */
  success?: boolean;
  /**
   *
   * @type {Transaction}
   * @memberof CancelPaymentResponse
   */
  transaction?: Transaction;
}
/**
 *
 * @export
 * @interface CandidateBoardingBody
 */
export interface CandidateBoardingBody {
  /**
   *
   * @type {UpsertCandidateDetailsBody}
   * @memberof CandidateBoardingBody
   */
  meta_details?: UpsertCandidateDetailsBody;
  /**
   *
   * @type {UpdateUserOnboardingBody}
   * @memberof CandidateBoardingBody
   */
  user_details?: UpdateUserOnboardingBody;
  /**
   *
   * @type {Array<WorkDetailsBody>}
   * @memberof CandidateBoardingBody
   */
  work_details?: Array<WorkDetailsBody>;
  /**
   *
   * @type {Array<EducationDetails>}
   * @memberof CandidateBoardingBody
   */
  education_details?: Array<EducationDetails>;
  /**
   *
   * @type {SocialLinksBody1}
   * @memberof CandidateBoardingBody
   */
  social_links?: SocialLinksBody1;
}
/**
 *
 * @export
 * @interface CandidateMetaDetails
 */
export interface CandidateMetaDetails {
  /**
   *
   * @type {number}
   * @memberof CandidateMetaDetails
   */
  expected_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  job_role?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  current_job_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CandidateMetaDetails
   */
  interested_skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CandidateMetaDetails
   */
  total_experience_years?: number;
  /**
   *
   * @type {number}
   * @memberof CandidateMetaDetails
   */
  current_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  currency?: string;
  /**
   *
   * @type {Location}
   * @memberof CandidateMetaDetails
   */
  preferred_location_details?: Location;
  /**
   *
   * @type {Array<Skills>}
   * @memberof CandidateMetaDetails
   */
  skills?: Array<Skills>;
  /**
   *
   * @type {number}
   * @memberof CandidateMetaDetails
   */
  tentative_notice_period?: number;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  preferred_location_id?: string;
  /**
   *
   * @type {Location}
   * @memberof CandidateMetaDetails
   */
  location_details?: Location;
  /**
   *
   * @type {Array<string>}
   * @memberof CandidateMetaDetails
   */
  interested_job_types?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  heading?: string | null;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  job_search_status?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  resume_url?: string;
  /**
   *
   * @type {string}
   * @memberof CandidateMetaDetails
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface CardSub
 */
export interface CardSub {
  /**
   *
   * @type {string}
   * @memberof CardSub
   */
  user_image?: string;
  /**
   *
   * @type {string}
   * @memberof CardSub
   */
  full_name?: string;
  /**
   *
   * @type {number}
   * @memberof CardSub
   */
  batch?: number;
  /**
   *
   * @type {Array<TagDetailsSub>}
   * @memberof CardSub
   */
  all_tag_details?: Array<TagDetailsSub>;
  /**
   *
   * @type {string}
   * @memberof CardSub
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof CardSub
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface CartCheckoutBody
 */
export interface CartCheckoutBody {
  /**
   *
   * @type {boolean}
   * @memberof CartCheckoutBody
   */
  partial_payment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CartCheckoutBody
   */
  is_test?: boolean;
  /**
   *
   * @type {string}
   * @memberof CartCheckoutBody
   */
  currency?: string;
}
/**
 *
 * @export
 * @interface CartCheckoutResponse
 */
export interface CartCheckoutResponse {
  /**
   *
   * @type {string}
   * @memberof CartCheckoutResponse
   */
  transaction_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CartCheckoutResponse
   */
  success?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CartCheckoutResponse
   */
  is_complete?: boolean;
  /**
   *
   * @type {string}
   * @memberof CartCheckoutResponse
   */
  razorpay_order_id?: string;
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {string}
   * @memberof Category
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ChangePasswordBody
 */
export interface ChangePasswordBody {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordBody
   */
  new_password: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordBody
   */
  current_password: string;
}
/**
 *
 * @export
 * @interface ChangeSubtagBody
 */
export interface ChangeSubtagBody {
  /**
   *
   * @type {string}
   * @memberof ChangeSubtagBody
   */
  matching_phrase?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeSubtagBody
   */
  tag_name?: string;
  /**
   *
   * @type {string}
   * @memberof ChangeSubtagBody
   */
  sub_tag_name?: string;
}
/**
 *
 * @export
 * @interface ChatForApplicant
 */
export interface ChatForApplicant {
  /**
   *
   * @type {Array<ChatForApplicantSub>}
   * @memberof ChatForApplicant
   */
  company_details?: Array<ChatForApplicantSub>;
  /**
   *
   * @type {number}
   * @memberof ChatForApplicant
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof ChatForApplicant
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ChatForApplicant
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface ChatForApplicantSub
 */
export interface ChatForApplicantSub {
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  latest_message_time?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  latest_message?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForApplicantSub
   */
  company_name?: string;
}
/**
 *
 * @export
 * @interface ChatForEmployers
 */
export interface ChatForEmployers {
  /**
   *
   * @type {number}
   * @memberof ChatForEmployers
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof ChatForEmployers
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ChatForEmployers
   */
  page_size?: number;
  /**
   *
   * @type {Array<ChatForEmployersSub>}
   * @memberof ChatForEmployers
   */
  all_users?: Array<ChatForEmployersSub>;
}
/**
 *
 * @export
 * @interface ChatForEmployersSub
 */
export interface ChatForEmployersSub {
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  last_message?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  hiring_status?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  last_message_time?: string;
  /**
   *
   * @type {string}
   * @memberof ChatForEmployersSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CollegeJobMapSub
 */
export interface CollegeJobMapSub {
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  created_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CollegeJobMapSub
   */
  batches?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  modified_on?: string;
  /**
   *
   * @type {JobDetailsSub}
   * @memberof CollegeJobMapSub
   */
  job_details?: JobDetailsSub;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof CollegeJobMapSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CollegeRelatedDataResponse
 */
export interface CollegeRelatedDataResponse {
  /**
   *
   * @type {Array<MocktestSub>}
   * @memberof CollegeRelatedDataResponse
   */
  recommended_exams?: Array<MocktestSub>;
  /**
   *
   * @type {string}
   * @memberof CollegeRelatedDataResponse
   */
  college_id?: string;
  /**
   *
   * @type {TrendingTopics}
   * @memberof CollegeRelatedDataResponse
   */
  trending_topics?: TrendingTopics;
  /**
   *
   * @type {number}
   * @memberof CollegeRelatedDataResponse
   */
  total_students?: number;
  /**
   *
   * @type {string}
   * @memberof CollegeRelatedDataResponse
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof CollegeRelatedDataResponse
   */
  college_image?: string;
}
/**
 *
 * @export
 * @interface CombinedUserStats
 */
export interface CombinedUserStats {
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  avg_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  total_competition_registrations?: number;
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  total_users?: number;
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  total_questions_solved?: number;
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  total_referrals?: number;
  /**
   *
   * @type {number}
   * @memberof CombinedUserStats
   */
  total_logins?: number;
  /**
   *
   * @type {string}
   * @memberof CombinedUserStats
   */
  avg_user_tag_level?: string;
}
/**
 *
 * @export
 * @interface CommentSub
 */
export interface CommentSub {
  /**
   *
   * @type {number}
   * @memberof CommentSub
   */
  total_upvotes?: number;
  /**
   *
   * @type {number}
   * @memberof CommentSub
   */
  total_downvotes?: number;
  /**
   *
   * @type {string}
   * @memberof CommentSub
   */
  comment_created_on?: string;
  /**
   *
   * @type {QuestionDetailsSub}
   * @memberof CommentSub
   */
  question_details?: QuestionDetailsSub;
  /**
   *
   * @type {string}
   * @memberof CommentSub
   */
  comment_id?: string;
  /**
   *
   * @type {string}
   * @memberof CommentSub
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface CompactCollegeDetailsSub
 */
export interface CompactCollegeDetailsSub {
  /**
   *
   * @type {string}
   * @memberof CompactCollegeDetailsSub
   */
  default_color?: string;
  /**
   *
   * @type {string}
   * @memberof CompactCollegeDetailsSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof CompactCollegeDetailsSub
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompactCollegeDetailsSub
   */
  college_id?: string;
}
/**
 *
 * @export
 * @interface CompactSalesUser
 */
export interface CompactSalesUser {
  /**
   *
   * @type {string}
   * @memberof CompactSalesUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompactSalesUser
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof CompactSalesUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompactSalesUser
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CompanyAutocompleteResponse
 */
export interface CompanyAutocompleteResponse {
  /**
   *
   * @type {number}
   * @memberof CompanyAutocompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<CompanyAutocompleteSub>}
   * @memberof CompanyAutocompleteResponse
   */
  data?: Array<CompanyAutocompleteSub>;
  /**
   *
   * @type {number}
   * @memberof CompanyAutocompleteResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof CompanyAutocompleteResponse
   */
  current_page?: number;
  /**
   *
   * @type {boolean}
   * @memberof CompanyAutocompleteResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface CompanyAutocompleteSub
 */
export interface CompanyAutocompleteSub {
  /**
   *
   * @type {string}
   * @memberof CompanyAutocompleteSub
   */
  default_color?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyAutocompleteSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyAutocompleteSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CompanySub
 */
export interface CompanySub {
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  corporate_identification_number?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  company_size?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  default_color?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface Competition
 */
export interface Competition {
  /**
   *
   * @type {string}
   * @memberof Competition
   */
  image_url?: string;
  /**
   *
   * @type {Array<Category>}
   * @memberof Competition
   */
  category_list?: Array<Category>;
  /**
   *
   * @type {string}
   * @memberof Competition
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Competition
   */
  total_participants?: number;
  /**
   *
   * @type {Overall}
   * @memberof Competition
   */
  overall?: Overall;
  /**
   *
   * @type {string}
   * @memberof Competition
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CompetitionAnalyticsResponse
 */
export interface CompetitionAnalyticsResponse {
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  avg_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  skipped?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  user_score?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  total_competition_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  total_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionAnalyticsResponse
   */
  timed_out?: number;
}
/**
 *
 * @export
 * @interface CompetitionData
 */
export interface CompetitionData {
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  min_rank?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  max_score?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  min_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  max_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  max_rank?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionData
   */
  min_score?: number;
}
/**
 *
 * @export
 * @interface CompetitionInvitationBody
 */
export interface CompetitionInvitationBody {
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionInvitationBody
   */
  email_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface CompetitionInvitationResponse
 */
export interface CompetitionInvitationResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionInvitationResponse
   */
  invitations?: Array<string>;
}
/**
 *
 * @export
 * @interface CompetitionNextQuestionResponse
 */
export interface CompetitionNextQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  total_bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  negative_score?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  next_question_id?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  quality_score?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionNextQuestionResponse
   */
  second_similar?: Array<string>;
  /**
   *
   * @type {Array<QuestionOptionsSub>}
   * @memberof CompetitionNextQuestionResponse
   */
  options?: Array<QuestionOptionsSub>;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  attempt_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  total_solution_likes?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  attempt_rate?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  created_by?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  correct_attempt_avg_time?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  how_to_answer?: string;
  /**
   *
   * @type {boolean}
   * @memberof CompetitionNextQuestionResponse
   */
  is_credit_added?: boolean;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  question_type?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  remaining_attempts?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  created_by_username?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  maxtime_passes?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  tag_id?: string;
  /**
   *
   * @type {AttemptDetailsSub1}
   * @memberof CompetitionNextQuestionResponse
   */
  attempt_details?: AttemptDetailsSub1;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  question_attempts?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  purchased_time?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  question_source_url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionNextQuestionResponse
   */
  first_similar?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof CompetitionNextQuestionResponse
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof CompetitionNextQuestionResponse
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  created_by_name?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  group_slug?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionNextQuestionResponse
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  exam_id?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  attempt_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CompetitionNextQuestionResponse
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  completed_attempt_avg_time?: number;
  /**
   *
   * @type {boolean}
   * @memberof CompetitionNextQuestionResponse
   */
  is_deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  exam_type?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  sub_question_number?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  difficulty_level?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  start_time?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CompetitionNextQuestionResponse
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  year?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  external_exam?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionNextQuestionResponse
   */
  multipart_question_count?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  parent_question_id?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionNextQuestionResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface CompetitionRewardsBody
 */
export interface CompetitionRewardsBody {
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsBody
   */
  rank_from?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsBody
   */
  rank_to?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsBody
   */
  gems?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsBody
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionRewardsBody
   */
  competition_id?: string;
}
/**
 *
 * @export
 * @interface CompetitionRewardsResponse
 */
export interface CompetitionRewardsResponse {
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsResponse
   */
  rank_from?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsResponse
   */
  rank_to?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsResponse
   */
  gems?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionRewardsResponse
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof CompetitionRewardsResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof CompetitionRewardsResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CompetitionSchemaWithTagScores
 */
export interface CompetitionSchemaWithTagScores {
  /**
   *
   * @type {string}
   * @memberof CompetitionSchemaWithTagScores
   */
  image_url?: string;
  /**
   *
   * @type {Array<MinimalTagScore>}
   * @memberof CompetitionSchemaWithTagScores
   */
  tags?: Array<MinimalTagScore>;
  /**
   *
   * @type {Array<Category>}
   * @memberof CompetitionSchemaWithTagScores
   */
  category_list?: Array<Category>;
  /**
   *
   * @type {string}
   * @memberof CompetitionSchemaWithTagScores
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CompetitionSchemaWithTagScores
   */
  total_participants?: number;
  /**
   *
   * @type {Overall}
   * @memberof CompetitionSchemaWithTagScores
   */
  overall?: Overall;
  /**
   *
   * @type {string}
   * @memberof CompetitionSchemaWithTagScores
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CompetitionStartStatisticsResponse
 */
export interface CompetitionStartStatisticsResponse {
  /**
   *
   * @type {number}
   * @memberof CompetitionStartStatisticsResponse
   */
  questions_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionStartStatisticsResponse
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof CompetitionStartStatisticsResponse
   */
  user_score?: number;
}
/**
 *
 * @export
 * @interface CompleteDashboardProfileResponse
 */
export interface CompleteDashboardProfileResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof CompleteDashboardProfileResponse
   */
  profile_milestones_completed?: Array<string>;
}
/**
 *
 * @export
 * @interface CompleteDashboardProfilebody
 */
export interface CompleteDashboardProfilebody {
  /**
   *
   * @type {CreateUserdata}
   * @memberof CompleteDashboardProfilebody
   */
  user_data?: CreateUserdata;
  /**
   *
   * @type {CreatepractiseMetaDataBody}
   * @memberof CompleteDashboardProfilebody
   */
  practise_meta_data?: CreatepractiseMetaDataBody;
  /**
   *
   * @type {CreateEducationBody}
   * @memberof CompleteDashboardProfilebody
   */
  educational_details?: CreateEducationBody;
}
/**
 *
 * @export
 * @interface ConvertSubTagBody
 */
export interface ConvertSubTagBody {
  /**
   *
   * @type {string}
   * @memberof ConvertSubTagBody
   */
  subtag_id?: string;
}
/**
 *
 * @export
 * @interface ConvertTagToDomainBody
 */
export interface ConvertTagToDomainBody {
  /**
   *
   * @type {string}
   * @memberof ConvertTagToDomainBody
   */
  tag_id?: string;
}
/**
 *
 * @export
 * @interface ConvertTagToSubTagBody
 */
export interface ConvertTagToSubTagBody {
  /**
   *
   * @type {string}
   * @memberof ConvertTagToSubTagBody
   */
  new_parent_tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof ConvertTagToSubTagBody
   */
  tag_id?: string;
}
/**
 *
 * @export
 * @interface CreatMockTestBody
 */
export interface CreatMockTestBody {
  /**
   *
   * @type {number}
   * @memberof CreatMockTestBody
   */
  no_questions: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CreatMockTestBody
   */
  tag_weightages: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  image_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  parent_mocktest_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreatMockTestBody
   */
  difficulty_level: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof CreatMockTestBody
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CreatMockTestBody
   */
  prerequisite?: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatMockTestBody
   */
  is_template?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatMockTestBody
   */
  tag_array: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  template_active_until?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  exam_mode: string;
  /**
   *
   * @type {number}
   * @memberof CreatMockTestBody
   */
  cost_in_credits: number;
  /**
   *
   * @type {string}
   * @memberof CreatMockTestBody
   */
  domain_id: string;
}
/**
 *
 * @export
 * @interface CreateApplicantNoteBody
 */
export interface CreateApplicantNoteBody {
  /**
   *
   * @type {string}
   * @memberof CreateApplicantNoteBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface CreateApplicantNoteUser
 */
export interface CreateApplicantNoteUser {
  /**
   *
   * @type {string}
   * @memberof CreateApplicantNoteUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateApplicantNoteUser
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof CreateApplicantNoteUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateApplicantNoteUser
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateAssessmentDuplicateBody
 */
export interface CreateAssessmentDuplicateBody {
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentDuplicateBody
   */
  base_job_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentDuplicateBody
   */
  new_job_id?: string;
}
/**
 *
 * @export
 * @interface CreateAssessmentFieldBody
 */
export interface CreateAssessmentFieldBody {
  /**
   *
   * @type {object}
   * @memberof CreateAssessmentFieldBody
   */
  meta_details?: object;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentFieldBody
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentFieldBody
   */
  score?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateAssessmentFieldBody
   */
  is_required?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateAssessmentFieldBody
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentFieldBody
   */
  type?: string;
}
/**
 *
 * @export
 * @interface CreateAssessmentResponse
 */
export interface CreateAssessmentResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateAssessmentResponsedBody
 */
export interface CreateAssessmentResponsedBody {
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResponsedBody
   */
  field_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateAssessmentResponsedBody
   */
  value: string;
}
/**
 *
 * @export
 * @interface CreateBadgeBody
 */
export interface CreateBadgeBody {
  /**
   *
   * @type {string}
   * @memberof CreateBadgeBody
   */
  segment?: string;
  /**
   *
   * @type {number}
   * @memberof CreateBadgeBody
   */
  credit_reward?: number;
  /**
   *
   * @type {string}
   * @memberof CreateBadgeBody
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof CreateBadgeBody
   */
  gem_reward?: number;
  /**
   *
   * @type {string}
   * @memberof CreateBadgeBody
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBadgeBody
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateBadgeBody
   */
  milestone_no?: number;
  /**
   *
   * @type {string}
   * @memberof CreateBadgeBody
   */
  type?: string;
}
/**
 *
 * @export
 * @interface CreateBulkSalesLeadResponse
 */
export interface CreateBulkSalesLeadResponse {
  /**
   *
   * @type {number}
   * @memberof CreateBulkSalesLeadResponse
   */
  existing_leads_count?: number;
  /**
   *
   * @type {number}
   * @memberof CreateBulkSalesLeadResponse
   */
  existing_users_count?: number;
  /**
   *
   * @type {Array<UserResponse>}
   * @memberof CreateBulkSalesLeadResponse
   */
  existing_users?: Array<UserResponse>;
  /**
   *
   * @type {ExistingLeadCategory}
   * @memberof CreateBulkSalesLeadResponse
   */
  existing_leads?: ExistingLeadCategory;
  /**
   *
   * @type {Array<GetSalesLeadResponse>}
   * @memberof CreateBulkSalesLeadResponse
   */
  new_leads?: Array<GetSalesLeadResponse>;
  /**
   *
   * @type {number}
   * @memberof CreateBulkSalesLeadResponse
   */
  new_leads_count?: number;
}
/**
 *
 * @export
 * @interface CreateCallAttemptBody
 */
export interface CreateCallAttemptBody {
  /**
   *
   * @type {string}
   * @memberof CreateCallAttemptBody
   */
  call_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCallAttemptBody
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCallAttemptBody
   */
  phone_number?: string;
}
/**
 *
 * @export
 * @interface CreateCertificateBody
 */
export interface CreateCertificateBody {
  /**
   *
   * @type {string}
   * @memberof CreateCertificateBody
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCertificateBody
   */
  user_id?: string;
}
/**
 *
 * @export
 * @interface CreateCertificateResponse
 */
export interface CreateCertificateResponse {
  /**
   *
   * @type {string}
   * @memberof CreateCertificateResponse
   */
  filename?: string;
}
/**
 *
 * @export
 * @interface CreateCollegeMilestonesResponse
 */
export interface CreateCollegeMilestonesResponse {
  /**
   *
   * @type {string}
   * @memberof CreateCollegeMilestonesResponse
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof CreateCollegeMilestonesResponse
   */
  student_count?: number;
  /**
   *
   * @type {number}
   * @memberof CreateCollegeMilestonesResponse
   */
  gems?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCollegeMilestonesResponse
   */
  special_rewards?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateCollegeMilestonesResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof CreateCollegeMilestonesResponse
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof CreateCollegeMilestonesResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCollegeMilestonesResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateCouponBody
 */
export interface CreateCouponBody {
  /**
   *
   * @type {string}
   * @memberof CreateCouponBody
   */
  coupon_type_use?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCouponBody
   */
  expire_on?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCouponBody
   */
  item_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateCouponBody
   */
  percent_discount?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateCouponBody
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateCouponBody
   */
  code?: string;
}
/**
 *
 * @export
 * @interface CreateDegreeBody
 */
export interface CreateDegreeBody {
  /**
   *
   * @type {string}
   * @memberof CreateDegreeBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateDegreeBody
   */
  approval_status?: string;
}
/**
 *
 * @export
 * @interface CreateDegreeResponse
 */
export interface CreateDegreeResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateDegreeResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateDegreeResponse
   */
  ignored_existing_name?: Array<string>;
  /**
   *
   * @type {Array<DegreeAutocompleteSub>}
   * @memberof CreateDegreeResponse
   */
  degrees?: Array<DegreeAutocompleteSub>;
}
/**
 *
 * @export
 * @interface CreateEducationBody
 */
export interface CreateEducationBody {
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  specialization_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  verification_doc_url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  college_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateEducationBody
   */
  is_currently_studying?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateEducationBody
   */
  batch?: number;
  /**
   *
   * @type {number}
   * @memberof CreateEducationBody
   */
  grade?: number;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  start_date?: string;
  /**
   *
   * @type {Location}
   * @memberof CreateEducationBody
   */
  institute_location?: Location;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  degree?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEducationBody
   */
  specialization?: string;
}
/**
 *
 * @export
 * @interface CreateEducationResponse
 */
export interface CreateEducationResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateEducationResponse
   */
  success?: boolean;
  /**
   *
   * @type {EducationSub}
   * @memberof CreateEducationResponse
   */
  education_obj?: EducationSub;
}
/**
 *
 * @export
 * @interface CreateEmailNotification
 */
export interface CreateEmailNotification {
  /**
   *
   * @type {string}
   * @memberof CreateEmailNotification
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEmailNotification
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEmailNotification
   */
  user_type?: string;
}
/**
 *
 * @export
 * @interface CreateEmailNotificationResponse
 */
export interface CreateEmailNotificationResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateEmailNotificationResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateEmailNotificationResponse
   */
  email_notification_id?: string;
}
/**
 *
 * @export
 * @interface CreateExamBody
 */
export interface CreateExamBody {
  /**
   *
   * @type {string}
   * @memberof CreateExamBody
   */
  next_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateExamBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateExamBody
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateExamBody
   */
  image_url?: string | null;
}
/**
 *
 * @export
 * @interface CreateExamResponse
 */
export interface CreateExamResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateExamResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateExamResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateFestBody
 */
export interface CreateFestBody {
  /**
   *
   * @type {string}
   * @memberof CreateFestBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFestBody
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFestBody
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateFestBody
   */
  is_live?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateFestBody
   */
  start_date?: string;
}
/**
 *
 * @export
 * @interface CreateFestEventBody
 */
export interface CreateFestEventBody {
  /**
   *
   * @type {string}
   * @memberof CreateFestEventBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFestEventBody
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFestEventBody
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFestEventBody
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateJobBody
 */
export interface CreateJobBody {
  /**
   *
   * @type {Location}
   * @memberof CreateJobBody
   */
  job_location?: Location;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  location_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  work_flexibility?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateJobBody
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  company_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  minimum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  gpt_job_description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  job_role_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateJobBody
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  minimum_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  additional_details?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  competition_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  domain_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateJobBody
   */
  auto_analyse?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateJobBody
   */
  required_skills?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  salary_frequency?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobBody
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobBody
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface CreateJobDescriptionBody
 */
export interface CreateJobDescriptionBody {
  /**
   *
   * @type {number}
   * @memberof CreateJobDescriptionBody
   */
  no_of_positions?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  job_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateJobDescriptionBody
   */
  maximum_experience?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateJobDescriptionBody
   */
  required_skills?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  about_company?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  work_flexibility?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  salary_frequency?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobDescriptionBody
   */
  maximum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  ref_jd?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  job_type?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobDescriptionBody
   */
  minimum_experience?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionBody
   */
  job_role_name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobDescriptionBody
   */
  minimum_salary?: number;
}
/**
 *
 * @export
 * @interface CreateJobDescriptionResponse
 */
export interface CreateJobDescriptionResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateJobDescriptionResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateJobDescriptionResponse
   */
  generated_jd_id?: string;
}
/**
 *
 * @export
 * @interface CreateJobResponse
 */
export interface CreateJobResponse {
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateJobResponse
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  job_closing_reason?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  minimum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  job_credits?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  gpt_job_description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof CreateJobResponse
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  application_credits?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  job_role_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateJobResponse
   */
  is_premium?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateJobResponse
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  is_archieved?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  minimum_qualification?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  additional_details?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof CreateJobResponse
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof CreateJobResponse
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateJobResponse
   */
  required_skills?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  salary_frequency?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobResponse
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface CreateJobRoleBody
 */
export interface CreateJobRoleBody {
  /**
   *
   * @type {string}
   * @memberof CreateJobRoleBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateJobRoleBody
   */
  approval_status?: string;
}
/**
 *
 * @export
 * @interface CreateJobRoleResponse
 */
export interface CreateJobRoleResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateJobRoleResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateJobRoleResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateLeaderboardFileResponse
 */
export interface CreateLeaderboardFileResponse {
  /**
   *
   * @type {string}
   * @memberof CreateLeaderboardFileResponse
   */
  csv_filename?: string;
}
/**
 *
 * @export
 * @interface CreateLevelBody
 */
export interface CreateLevelBody {
  /**
   *
   * @type {number}
   * @memberof CreateLevelBody
   */
  credit_reward: number;
  /**
   *
   * @type {string}
   * @memberof CreateLevelBody
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof CreateLevelBody
   */
  gem_reward: number;
  /**
   *
   * @type {string}
   * @memberof CreateLevelBody
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateLevelBody
   */
  number: number;
}
/**
 *
 * @export
 * @interface CreateLevelResponse
 */
export interface CreateLevelResponse {
  /**
   *
   * @type {number}
   * @memberof CreateLevelResponse
   */
  credit_reward?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLevelResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLevelResponse
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof CreateLevelResponse
   */
  gem_reward?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLevelResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLevelResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateLevelResponse
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLevelResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface CreateMailTemplateBody
 */
export interface CreateMailTemplateBody {
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateBody
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateBody
   */
  template_name?: string;
  /**
   *
   * @type {object}
   * @memberof CreateMailTemplateBody
   */
  template_args?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateMailTemplateBody
   */
  attachment_urls?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateBody
   */
  template_body?: string;
}
/**
 *
 * @export
 * @interface CreateMailTemplateResponse
 */
export interface CreateMailTemplateResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateMailTemplateResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateMockTestRepsonse
 */
export interface CreateMockTestRepsonse {
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  no_questions?: number;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  started_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateMockTestRepsonse
   */
  is_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateMockTestRepsonse
   */
  is_template?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  attempted_questions?: number;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  completed_on?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  parent_mocktest_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  current_score?: number;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  cost_in_credits?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CreateMockTestRepsonse
   */
  prerequisite?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof CreateMockTestRepsonse
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  maximum_score?: number;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  template_active_until?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  exam_mode?: string;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  percentile?: number;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  domain_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateMockTestRepsonse
   */
  is_template_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  image_url?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateMockTestRepsonse
   */
  tag_weightages?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateMockTestRepsonse
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateMockTestRepsonse
   */
  total_duration?: number;
  /**
   *
   * @type {string}
   * @memberof CreateMockTestRepsonse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface CreateNoteResponse
 */
export interface CreateNoteResponse {
  /**
   *
   * @type {string}
   * @memberof CreateNoteResponse
   */
  applicant_id?: string;
  /**
   *
   * @type {CreateApplicantNoteUser}
   * @memberof CreateNoteResponse
   */
  user?: CreateApplicantNoteUser;
  /**
   *
   * @type {string}
   * @memberof CreateNoteResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNoteResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateOption
 */
export interface CreateOption {
  /**
   *
   * @type {number}
   * @memberof CreateOption
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof CreateOption
   */
  option_text?: string;
}
/**
 *
 * @export
 * @interface CreateOrganizationBody
 */
export interface CreateOrganizationBody {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  company_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  team_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  verification_doc_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  company_size?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  location_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  linkedin_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  job_role_name?: string;
  /**
   *
   * @type {Location}
   * @memberof CreateOrganizationBody
   */
  location?: Location;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  website_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  company_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationBody
   */
  company_logo_url?: string | null;
}
/**
 *
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateProjectResponse
   */
  success?: boolean;
  /**
   *
   * @type {ProjectsDetail}
   * @memberof CreateProjectResponse
   */
  project_obj?: ProjectsDetail;
}
/**
 *
 * @export
 * @interface CreateQuestionBody
 */
export interface CreateQuestionBody {
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateQuestionBody
   */
  answer_option_no?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  exam_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  title?: string | null;
  /**
   *
   * @type {Array<CreateOption>}
   * @memberof CreateQuestionBody
   */
  options?: Array<CreateOption> | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  no_options?: number;
  /**
   *
   * @type {Solution}
   * @memberof CreateQuestionBody
   */
  solution?: Solution;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  tag_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  tag_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionBody
   */
  answer_one_word?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  question_source_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  weightage?: number;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  question_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionBody
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  max_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<CreateSubQuestionBody>}
   * @memberof CreateQuestionBody
   */
  sub_questions?: Array<CreateSubQuestionBody>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionBody
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  year?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionBody
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionBody
   */
  parent_question_id?: string | null;
}
/**
 *
 * @export
 * @interface CreateQuestionResponse
 */
export interface CreateQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  total_bookmarks?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateQuestionResponse
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  quality_score?: string | null;
  /**
   *
   * @type {Array<object>}
   * @memberof CreateQuestionResponse
   */
  second_similar?: Array<object>;
  /**
   *
   * @type {Array<Option>}
   * @memberof CreateQuestionResponse
   */
  options?: Array<Option>;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  attempt_accuracy?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  total_solution_likes?: number;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  attempt_rate?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  created_by?: string;
  /**
   *
   * @type {Solution}
   * @memberof CreateQuestionResponse
   */
  solution?: Solution;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  correct_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  is_credit_added?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionResponse
   */
  answer_one_word?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_attempts?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<object>}
   * @memberof CreateQuestionResponse
   */
  first_similar?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof CreateQuestionResponse
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateQuestionResponse
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionResponse
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  max_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateQuestionResponse
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  completed_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  exam_type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateQuestionResponse
   */
  is_deleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<SubQuestion>}
   * @memberof CreateQuestionResponse
   */
  sub_questions?: Array<SubQuestion>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateQuestionResponse
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  year?: number | null;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof CreateQuestionResponse
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {number}
   * @memberof CreateQuestionResponse
   */
  multipart_question_count?: number;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateQuestionResponse
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface CreateReviewTemplateBody
 */
export interface CreateReviewTemplateBody {
  /**
   *
   * @type {string}
   * @memberof CreateReviewTemplateBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateReviewTemplateBody
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateSalesLeadBody
 */
export interface CreateSalesLeadBody {
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  sales_lead_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  linkedin_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  sales_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  sales_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSalesLeadBody
   */
  job_role_name?: string;
}
/**
 *
 * @export
 * @interface CreateSkillBody
 */
export interface CreateSkillBody {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSkillBody
   */
  names?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateSkillBody
   */
  status?: string;
}
/**
 *
 * @export
 * @interface CreateSkillSub
 */
export interface CreateSkillSub {
  /**
   *
   * @type {boolean}
   * @memberof CreateSkillSub
   */
  is_self_rating?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  sub_tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSkillSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateSpecializationBody
 */
export interface CreateSpecializationBody {
  /**
   *
   * @type {string}
   * @memberof CreateSpecializationBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateSpecializationBody
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSpecializationBody
   */
  degree_id: string;
}
/**
 *
 * @export
 * @interface CreateSpecializationResponse
 */
export interface CreateSpecializationResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateSpecializationResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<any>}
   * @memberof CreateSpecializationResponse
   */
  ignored_existing_name_degree_id_pair?: Array<any>;
  /**
   *
   * @type {Array<SpecializationAutocompleteSub>}
   * @memberof CreateSpecializationResponse
   */
  specializations?: Array<SpecializationAutocompleteSub>;
}
/**
 *
 * @export
 * @interface CreateSubQuestionBody
 */
export interface CreateSubQuestionBody {
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateSubQuestionBody
   */
  answer_option_no?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  exam_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  title?: string;
  /**
   *
   * @type {Array<Option>}
   * @memberof CreateSubQuestionBody
   */
  options?: Array<Option> | null;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  no_options?: number;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  tag_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSubQuestionBody
   */
  answer_one_word?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  question_source_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  weightage?: number;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  question_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSubQuestionBody
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateSubQuestionBody
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  year?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreateSubQuestionBody
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateSubQuestionBody
   */
  parent_question_id?: string | null;
}
/**
 *
 * @export
 * @interface CreateUserBody
 */
export interface CreateUserBody {
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  referred_by_hash?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  requested_user_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  about?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUserBody
   */
  is_profile_hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  redirectTo?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  selected_domain?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserBody
   */
  email_verification_otp?: string;
}
/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateUserResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateUserResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreateUserdata
 */
export interface CreateUserdata {
  /**
   *
   * @type {string}
   * @memberof CreateUserdata
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserdata
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserdata
   */
  gender?: string;
}
/**
 *
 * @export
 * @interface CreateWorkExperienceBody
 */
export interface CreateWorkExperienceBody {
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  company_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateWorkExperienceBody
   */
  is_current_company: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  designation?: string | null;
  /**
   *
   * @type {Location}
   * @memberof CreateWorkExperienceBody
   */
  location?: Location | null;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWorkExperienceBody
   */
  employment_type?: string;
}
/**
 *
 * @export
 * @interface CreatedQuestionLevelSub
 */
export interface CreatedQuestionLevelSub {
  /**
   *
   * @type {string}
   * @memberof CreatedQuestionLevelSub
   */
  level?: string;
  /**
   *
   * @type {number}
   * @memberof CreatedQuestionLevelSub
   */
  total_bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof CreatedQuestionLevelSub
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CreatedQuestionLevelSub
   */
  total_questions_created?: number;
  /**
   *
   * @type {number}
   * @memberof CreatedQuestionLevelSub
   */
  complete_attempt_avg_time?: number;
  /**
   *
   * @type {number}
   * @memberof CreatedQuestionLevelSub
   */
  total_likes?: number;
}
/**
 *
 * @export
 * @interface CreatepractiseMetaDataBody
 */
export interface CreatepractiseMetaDataBody {
  /**
   *
   * @type {string}
   * @memberof CreatepractiseMetaDataBody
   */
  exam_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreatepractiseMetaDataBody
   */
  exam_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatepractiseMetaDataBody
   */
  goal?: string;
  /**
   *
   * @type {string}
   * @memberof CreatepractiseMetaDataBody
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreatepractiseMetaDataBody
   */
  current_status?: string;
}
/**
 *
 * @export
 * @interface CreatorDetails
 */
export interface CreatorDetails {
  /**
   *
   * @type {string}
   * @memberof CreatorDetails
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorDetails
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorDetails
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorDetails
   */
  id?: string;
}
/**
 *
 * @export
 * @interface CreatorStatsRefinedSub
 */
export interface CreatorStatsRefinedSub {
  /**
   *
   * @type {string}
   * @memberof CreatorStatsRefinedSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorStatsRefinedSub
   */
  s3_url?: string;
  /**
   *
   * @type {TagStats}
   * @memberof CreatorStatsRefinedSub
   */
  tag_stats?: TagStats;
  /**
   *
   * @type {string}
   * @memberof CreatorStatsRefinedSub
   */
  name?: string;
  /**
   *
   * @type {Array<CreatedQuestionLevelSub>}
   * @memberof CreatorStatsRefinedSub
   */
  created_question_level?: Array<CreatedQuestionLevelSub>;
  /**
   *
   * @type {{ [key: string]: BadgeObjSub; }}
   * @memberof CreatorStatsRefinedSub
   */
  badge_obj?: { [key: string]: BadgeObjSub };
}
/**
 *
 * @export
 * @interface CreatorStatsResponseSub
 */
export interface CreatorStatsResponseSub {
  /**
   *
   * @type {number}
   * @memberof CreatorStatsResponseSub
   */
  total_bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorStatsResponseSub
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorStatsResponseSub
   */
  total_questions_created?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorStatsResponseSub
   */
  overall_complete_attempt_avg_time?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorStatsResponseSub
   */
  total_likes?: number;
}
/**
 *
 * @export
 * @interface CurrencySymbolRepsonse
 */
export interface CurrencySymbolRepsonse {
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AED?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BAM?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BSD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TRY?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  VUV?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  UAH?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PAB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SHP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SCR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NZD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  UYU?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SEK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  HRK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TZS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  VND?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TRL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  DKK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ARS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  HNL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  HUF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MVR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  RMB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  IQD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KYD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  DZD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  XBT?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BWP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ERN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ALL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  IRR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NAD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TND?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TOP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PEN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  XOF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CNY?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KGS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ISK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SDG?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TVD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  JMD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CUP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CLP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GYD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MDL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PKR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LTL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TJS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  IMP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PHP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ANG?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  EUR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  IDR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MRO?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CVE?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GIP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CDF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CAD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  EEK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SRD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PYG?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KWD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  JPY?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  RSD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ETH?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KPW?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  XCD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LVL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GTQ?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  STD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PGK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SZL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BTN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BIF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  UGX?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LAK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LSL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BYN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SGD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AOA?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MYR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  RUB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KRW?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BGN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MRU?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  FKP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KES?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SOS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GHC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  THB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  DOP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  FJD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  XPF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BHD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  STN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AMD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  JOD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CRC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MAD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ZAR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TTD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MGA?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BOB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SBD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GNF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GEL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  YER?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  EGP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BDT?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BND?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BZD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GGP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SVC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CHF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KMF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AWG?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GMD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LTC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AZN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BRL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ETB?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  WST?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ZWD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TMT?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NGN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BTC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  RWF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  PLN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LYD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AUD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MWK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SAR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KZT?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CUC?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MUR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LKR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  OMR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GBP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  TWD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NPR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  UZS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MMK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SYP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  HTG?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LBP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  XAF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MOP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NIO?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  KHR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MZN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BMD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SLL?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  GHS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  DJF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MNT?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  COP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  INR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  SSP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  HKD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  JEP?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  VEF?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  QAR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  CZK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MXN?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BBD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  MKD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  RON?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  LRD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  USD?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  NOK?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  ILS?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  BYR?: string;
  /**
   *
   * @type {string}
   * @memberof CurrencySymbolRepsonse
   */
  AFN?: string;
}
/**
 *
 * @export
 * @interface DailyScoreResponseSub
 */
export interface DailyScoreResponseSub {
  /**
   *
   * @type {{ [key: string]: DatewiseData; }}
   * @memberof DailyScoreResponseSub
   */
  data?: { [key: string]: DatewiseData };
}
/**
 *
 * @export
 * @interface Data
 */
export interface Data {
  /**
   *
   * @type {Array<IndividualTag>}
   * @memberof Data
   */
  individual_tags?: Array<IndividualTag>;
  /**
   *
   * @type {OverallYearstatsSub}
   * @memberof Data
   */
  overall?: OverallYearstatsSub;
}
/**
 *
 * @export
 * @interface Data1
 */
export interface Data1 {
  /**
   *
   * @type {string}
   * @memberof Data1
   */
  attempted_answer_option_no?: string | null;
  /**
   *
   * @type {string}
   * @memberof Data1
   */
  attempted_answer_one_word?: string;
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  is_attempt_correct?: boolean;
  /**
   *
   * @type {number}
   * @memberof Data1
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof Data1
   */
  question_skipped?: boolean;
}
/**
 *
 * @export
 * @interface DateWiseActivityResponse
 */
export interface DateWiseActivityResponse {
  /**
   *
   * @type {{ [key: string]: OverallMonthstatsSub; }}
   * @memberof DateWiseActivityResponse
   */
  activity?: { [key: string]: OverallMonthstatsSub };
}
/**
 *
 * @export
 * @interface DatewiseData
 */
export interface DatewiseData {
  /**
   *
   * @type {Array<TagStatsSub>}
   * @memberof DatewiseData
   */
  individual_tags?: Array<TagStatsSub>;
  /**
   *
   * @type {OverallTagStats}
   * @memberof DatewiseData
   */
  overall?: OverallTagStats;
}
/**
 *
 * @export
 * @interface DegreeAutocompleteResponse
 */
export interface DegreeAutocompleteResponse {
  /**
   *
   * @type {number}
   * @memberof DegreeAutocompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<DegreeAutocompleteSub>}
   * @memberof DegreeAutocompleteResponse
   */
  data?: Array<DegreeAutocompleteSub>;
  /**
   *
   * @type {number}
   * @memberof DegreeAutocompleteResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof DegreeAutocompleteResponse
   */
  current_page?: number;
  /**
   *
   * @type {boolean}
   * @memberof DegreeAutocompleteResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface DegreeAutocompleteSub
 */
export interface DegreeAutocompleteSub {
  /**
   *
   * @type {string}
   * @memberof DegreeAutocompleteSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeAutocompleteSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeAutocompleteSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeAutocompleteSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeAutocompleteSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface DegreeDetailsSub
 */
export interface DegreeDetailsSub {
  /**
   *
   * @type {string}
   * @memberof DegreeDetailsSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeDetailsSub
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof DegreeDetailsSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DegreeDetailsSub
   */
  candidate_count?: number;
}
/**
 *
 * @export
 * @interface DeleteBadgeResponse
 */
export interface DeleteBadgeResponse {
  /**
   *
   * @type {string}
   * @memberof DeleteBadgeResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface DeleteCompetitionResponse
 */
export interface DeleteCompetitionResponse {
  /**
   *
   * @type {string}
   * @memberof DeleteCompetitionResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface DeleteEmailNotificationResponse
 */
export interface DeleteEmailNotificationResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteEmailNotificationResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteJobCompletelyResponse
 */
export interface DeleteJobCompletelyResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteJobCompletelyResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeleteJobCompletelyResponse
   */
  deleted_job?: string;
}
/**
 *
 * @export
 * @interface DeleteReviewTemplateResponse
 */
export interface DeleteReviewTemplateResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteReviewTemplateResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof DeleteReviewTemplateResponse
   */
  review_template_deleted?: string;
}
/**
 *
 * @export
 * @interface DeleteTemplateResponse
 */
export interface DeleteTemplateResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteTemplateResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteUserResponse
 */
export interface DeleteUserResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteUserResponse
   */
  deleted_users?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof DeleteUserResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DetailedCompetitionAnalyticsResponse
 */
export interface DetailedCompetitionAnalyticsResponse {
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  avg_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  skipped?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  gems_received?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  user_score?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  credits_recieved?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_competition_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_registered?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  score_rank?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  total_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof DetailedCompetitionAnalyticsResponse
   */
  timed_out?: number;
}
/**
 *
 * @export
 * @interface DomainSub
 */
export interface DomainSub {
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  avg_time_taken_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  avg_time_taken_correct?: number;
  /**
   *
   * @type {string}
   * @memberof DomainSub
   */
  domain_name?: string;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_domain_questions?: number;
  /**
   *
   * @type {string}
   * @memberof DomainSub
   */
  img_url?: string;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_skipped_count?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_timeout_count?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  unanswered_domain_questions?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  rank?: number;
  /**
   *
   * @type {string}
   * @memberof DomainSub
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof DomainSub
   */
  percent_accuracy?: number;
}
/**
 *
 * @export
 * @interface EducationDetails
 */
export interface EducationDetails {
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  specialization_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  college_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  degree_name?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  specialization?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  specialization_name?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  batch?: string;
  /**
   *
   * @type {number}
   * @memberof EducationDetails
   */
  grade?: number;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  college_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof EducationDetails
   */
  is_currently_studying?: boolean;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  start_date?: string;
  /**
   *
   * @type {Location}
   * @memberof EducationDetails
   */
  location_details?: Location;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  degree?: string;
  /**
   *
   * @type {string}
   * @memberof EducationDetails
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface EducationSub
 */
export interface EducationSub {
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  specialization_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  degree_name?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  verification_doc_url?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  specialization_name?: string;
  /**
   *
   * @type {number}
   * @memberof EducationSub
   */
  batch?: number;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  grade?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  college_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof EducationSub
   */
  is_currently_studying?: boolean;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  start_date?: string;
  /**
   *
   * @type {Location}
   * @memberof EducationSub
   */
  location_details?: Location;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  user_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof EducationSub
   */
  is_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof EducationSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface EmailAttachUploadFileResponse
 */
export interface EmailAttachUploadFileResponse {
  /**
   *
   * @type {string}
   * @memberof EmailAttachUploadFileResponse
   */
  signed_attachment_url?: string;
  /**
   *
   * @type {string}
   * @memberof EmailAttachUploadFileResponse
   */
  attachment_url?: string;
}
/**
 *
 * @export
 * @interface EmailLog
 */
export interface EmailLog {
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  recipient?: string;
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  template_name?: string;
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  template_args?: string;
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  attachment_urls?: string;
  /**
   *
   * @type {string}
   * @memberof EmailLog
   */
  id?: string;
}
/**
 *
 * @export
 * @interface EmailNotificationSub
 */
export interface EmailNotificationSub {
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  modified_at?: string;
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EmailNotificationSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface EmployerBulkStatusUpdateBody
 */
export interface EmployerBulkStatusUpdateBody {
  /**
   *
   * @type {Array<string>}
   * @memberof EmployerBulkStatusUpdateBody
   */
  job_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {boolean}
   * @memberof ErrorResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  error?: string;
}
/**
 *
 * @export
 * @interface ErrorResponse1
 */
export interface ErrorResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof ErrorResponse1
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse1
   */
  error?: string;
}
/**
 *
 * @export
 * @interface Exam
 */
export interface Exam {
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  no_questions?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  started_on?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Exam
   */
  is_completed?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Exam
   */
  is_template?: boolean;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  attempted_questions?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  completed_on?: string | null;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  parent_mocktest_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  current_score?: number;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  cost_in_credits?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  prerequisite?: string | null;
  /**
   *
   * @type {object}
   * @memberof Exam
   */
  subcategory_weights?: object | null;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  maximum_score?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  template_active_until?: string | null;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  exam_mode?: string;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  percentile?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  domain_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Exam
   */
  is_template_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  image_url?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Exam
   */
  tag_weightages?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Exam
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Exam
   */
  total_duration?: number;
  /**
   *
   * @type {string}
   * @memberof Exam
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface ExamAutocompleteResponse
 */
export interface ExamAutocompleteResponse {
  /**
   *
   * @type {number}
   * @memberof ExamAutocompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<MinimalExamSub>}
   * @memberof ExamAutocompleteResponse
   */
  data?: Array<MinimalExamSub>;
  /**
   *
   * @type {number}
   * @memberof ExamAutocompleteResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof ExamAutocompleteResponse
   */
  current_page?: number;
  /**
   *
   * @type {boolean}
   * @memberof ExamAutocompleteResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface ExamDetailSub
 */
export interface ExamDetailSub {
  /**
   *
   * @type {string}
   * @memberof ExamDetailSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof ExamDetailSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof ExamDetailSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExamDetailSub
   */
  next_date?: string;
  /**
   *
   * @type {number}
   * @memberof ExamDetailSub
   */
  user_count?: number;
  /**
   *
   * @type {string}
   * @memberof ExamDetailSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ExamSub
 */
export interface ExamSub {
  /**
   *
   * @type {string}
   * @memberof ExamSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof ExamSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof ExamSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExamSub
   */
  next_date?: string;
  /**
   *
   * @type {string}
   * @memberof ExamSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ExistingLeadCategory
 */
export interface ExistingLeadCategory {
  /**
   *
   * @type {Array<GetSalesLeadResponse>}
   * @memberof ExistingLeadCategory
   */
  by_email?: Array<GetSalesLeadResponse>;
  /**
   *
   * @type {Array<GetSalesLeadResponse>}
   * @memberof ExistingLeadCategory
   */
  by_linkedin_url?: Array<GetSalesLeadResponse>;
  /**
   *
   * @type {Array<GetSalesLeadResponse>}
   * @memberof ExistingLeadCategory
   */
  by_phone_number?: Array<GetSalesLeadResponse>;
}
/**
 *
 * @export
 * @interface ExperienceDetails
 */
export interface ExperienceDetails {
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  company_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExperienceDetails
   */
  is_current_company?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  designation?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  employment_type?: string;
  /**
   *
   * @type {string}
   * @memberof ExperienceDetails
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ExternalExamSub
 */
export interface ExternalExamSub {
  /**
   *
   * @type {string}
   * @memberof ExternalExamSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalExamSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface Feedback
 */
export interface Feedback {
  /**
   *
   * @type {Array<string>}
   * @memberof Feedback
   */
  media_url_list?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof Feedback
   */
  env?: object;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof Feedback
   */
  overall_rating?: number;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  feedback_type?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  area_of_issue?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  other_feedback?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  type_of_issue?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof Feedback
   */
  id?: string;
}
/**
 *
 * @export
 * @interface FeedbackBody
 */
export interface FeedbackBody {
  /**
   *
   * @type {object}
   * @memberof FeedbackBody
   */
  env?: object;
  /**
   *
   * @type {number}
   * @memberof FeedbackBody
   */
  overall_rating?: number;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  feedback_type?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  area_of_issue?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  other_feedback?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  type_of_issue?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FeedbackBody
   */
  media_url_list?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackBody
   */
  status?: string;
}
/**
 *
 * @export
 * @interface FeedbackReport
 */
export interface FeedbackReport {
  /**
   *
   * @type {Array<string>}
   * @memberof FeedbackReport
   */
  media_url_list?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof FeedbackReport
   */
  env?: object;
  /**
   *
   * @type {GetUserDetail}
   * @memberof FeedbackReport
   */
  user_detail?: GetUserDetail;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof FeedbackReport
   */
  overall_rating?: number;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  feedback_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  area_of_issue?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  other_feedback?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  type_of_issue?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackReport
   */
  id?: string;
}
/**
 *
 * @export
 * @interface FeedbackResponse
 */
export interface FeedbackResponse {
  /**
   *
   * @type {Feedback}
   * @memberof FeedbackResponse
   */
  data?: Feedback;
}
/**
 *
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
  /**
   *
   * @type {string}
   * @memberof FileUploadResponse
   */
  url?: string;
}
/**
 *
 * @export
 * @interface FinishPaymentBody
 */
export interface FinishPaymentBody {
  /**
   *
   * @type {string}
   * @memberof FinishPaymentBody
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof FinishPaymentBody
   */
  razorpay_payment_id?: string;
  /**
   *
   * @type {string}
   * @memberof FinishPaymentBody
   */
  razorpay_signature?: string;
  /**
   *
   * @type {string}
   * @memberof FinishPaymentBody
   */
  payment_mode?: string;
}
/**
 *
 * @export
 * @interface Generated
 */
export interface Generated {
  /**
   *
   * @type {number}
   * @memberof Generated
   */
  old_level?: number;
  /**
   *
   * @type {number}
   * @memberof Generated
   */
  new_level?: number;
}
/**
 *
 * @export
 * @interface Generated1
 */
export interface Generated1 {
  /**
   *
   * @type {number}
   * @memberof Generated1
   */
  old_level?: number;
  /**
   *
   * @type {number}
   * @memberof Generated1
   */
  new_level?: number;
}
/**
 *
 * @export
 * @interface GeneratedJDSub
 */
export interface GeneratedJDSub {
  /**
   *
   * @type {object}
   * @memberof GeneratedJDSub
   */
  response?: object;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  id?: string;
  /**
   *
   * @type {object}
   * @memberof GeneratedJDSub
   */
  job_meta_details?: object;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  ref_jd?: string;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GeneratedJDSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetAdminOrdersByTrasnsactionIdResponse
 */
export interface GetAdminOrdersByTrasnsactionIdResponse {
  /**
   *
   * @type {GetCartResponse}
   * @memberof GetAdminOrdersByTrasnsactionIdResponse
   */
  order_details?: GetCartResponse;
  /**
   *
   * @type {UpsertAddressResponse}
   * @memberof GetAdminOrdersByTrasnsactionIdResponse
   */
  addresses?: UpsertAddressResponse;
}
/**
 *
 * @export
 * @interface GetAdminOrdersResponse
 */
export interface GetAdminOrdersResponse {
  /**
   *
   * @type {number}
   * @memberof GetAdminOrdersResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetAdminOrdersResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetCartResponse>}
   * @memberof GetAdminOrdersResponse
   */
  all_orders?: Array<GetCartResponse>;
  /**
   *
   * @type {number}
   * @memberof GetAdminOrdersResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetAllBadgesResponse
 */
export interface GetAllBadgesResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesResponse
   */
  page?: number;
  /**
   *
   * @type {Array<GetAllBadgesSub>}
   * @memberof GetAllBadgesResponse
   */
  badges?: Array<GetAllBadgesSub>;
}
/**
 *
 * @export
 * @interface GetAllBadgesSub
 */
export interface GetAllBadgesSub {
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  segment?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesSub
   */
  credit_reward?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesSub
   */
  gem_reward?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllBadgesSub
   */
  milestone_no?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllBadgesSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetAllCommentUser
 */
export interface GetAllCommentUser {
  /**
   *
   * @type {string}
   * @memberof GetAllCommentUser
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCommentUser
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCommentUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCommentUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCommentUser
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetAllComments
 */
export interface GetAllComments {
  /**
   *
   * @type {Array<CommentSub>}
   * @memberof GetAllComments
   */
  all_comments?: Array<CommentSub>;
  /**
   *
   * @type {number}
   * @memberof GetAllComments
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllComments
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllComments
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetAllCompetitionsResponse
 */
export interface GetAllCompetitionsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetAllCompetitionsSub>}
   * @memberof GetAllCompetitionsResponse
   */
  data?: Array<GetAllCompetitionsSub>;
}
/**
 *
 * @export
 * @interface GetAllCompetitionsSub
 */
export interface GetAllCompetitionsSub {
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  additional_notes?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  end_date?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsSub
   */
  total_registringations?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  competition_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllCompetitionsSub
   */
  is_credits_updated?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  competition_header_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  rewards?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  schedule_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllCompetitionsSub
   */
  is_private?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllCompetitionsSub
   */
  competition_starts_email_sent?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  description?: string;
  /**
   *
   * @type {Array<CompetitionRewardsResponse>}
   * @memberof GetAllCompetitionsSub
   */
  rewards_list?: Array<CompetitionRewardsResponse>;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsSub
   */
  question_limit?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  reg_start_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsSub
   */
  participation_credits?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllCompetitionsSub
   */
  category_names?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllCompetitionsSub
   */
  category_list?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsSub
   */
  competition_level?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  social_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  start_date?: string;
  /**
   *
   * @type {object}
   * @memberof GetAllCompetitionsSub
   */
  subcategory_weights?: object;
  /**
   *
   * @type {number}
   * @memberof GetAllCompetitionsSub
   */
  total_coa?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  image_url?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GetAllCompetitionsSub
   */
  category_weights?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  reg_end_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllCompetitionsSub
   */
  is_registered?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  rules?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllCompetitionsSub
   */
  show_leaderboard?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllCompetitionsSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetAllCouponsResponse
 */
export interface GetAllCouponsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllCouponsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllCouponsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllCouponsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetCouponResponse>}
   * @memberof GetAllCouponsResponse
   */
  coupons?: Array<GetCouponResponse>;
}
/**
 *
 * @export
 * @interface GetAllDomainMockTestResponse
 */
export interface GetAllDomainMockTestResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetAllDomainMockTestSub>}
   * @memberof GetAllDomainMockTestResponse
   */
  mocktest?: Array<GetAllDomainMockTestSub>;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetAllDomainMockTestSub
 */
export interface GetAllDomainMockTestSub {
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestSub
   */
  no_questions?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  started_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllDomainMockTestSub
   */
  is_unlocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAllDomainMockTestSub
   */
  is_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAllDomainMockTestSub
   */
  is_template?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestSub
   */
  attempted_questions?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestSub
   */
  cost_in_credits?: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof GetAllDomainMockTestSub
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  template_active_until?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  exam_mode?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  domain_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllDomainMockTestSub
   */
  is_template_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllDomainMockTestSub
   */
  image_url?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GetAllDomainMockTestSub
   */
  tag_weightages?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestSub
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllDomainMockTestSub
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetAllDomainMockTestSub
   */
  total_duration?: number;
}
/**
 *
 * @export
 * @interface GetAllFestEventsResponse
 */
export interface GetAllFestEventsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllFestEventsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllFestEventsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllFestEventsResponse
   */
  page?: number;
  /**
   *
   * @type {Array<GetFestEventResponse>}
   * @memberof GetAllFestEventsResponse
   */
  fest_events?: Array<GetFestEventResponse>;
}
/**
 *
 * @export
 * @interface GetAllFestsResponse
 */
export interface GetAllFestsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllFestsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllFestsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllFestsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetFestDetailResponse>}
   * @memberof GetAllFestsResponse
   */
  fests?: Array<GetFestDetailResponse>;
}
/**
 *
 * @export
 * @interface GetAllInvoiceZipResponse
 */
export interface GetAllInvoiceZipResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllInvoiceZipResponse
   */
  total_invoices?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllInvoiceZipResponse
   */
  succesful_downloads?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllInvoiceZipResponse
   */
  zip_file_path?: string;
}
/**
 *
 * @export
 * @interface GetAllJobSummaryResponse
 */
export interface GetAllJobSummaryResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  under_review_job_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  closed_job_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  active_job_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  draft_job_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  total_hired_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  paused_job_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllJobSummaryResponse
   */
  job_posted_count?: number;
}
/**
 *
 * @export
 * @interface GetAllLevelResponse
 */
export interface GetAllLevelResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllLevelResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<CreateLevelResponse>}
   * @memberof GetAllLevelResponse
   */
  levels?: Array<CreateLevelResponse>;
  /**
   *
   * @type {number}
   * @memberof GetAllLevelResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllLevelResponse
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface GetAllReviewReportResponse
 */
export interface GetAllReviewReportResponse {
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  qus_creator_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetAllReviewReportResponse
   */
  report_reviews?: number | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  original_text?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  reason_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  correct_text?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  id?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GetAllReviewReportResponse
   */
  correct_values?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof GetAllReviewReportResponse
   */
  original_values?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  created_at?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  autoresolve_failure?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  tag_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  is_new?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  voting_open?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  question_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  review_summary?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetAllReviewReportResponse
   */
  pct_agreed?: number | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  reference_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  element_changed?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllReviewReportResponse
   */
  correct_ids?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  is_resolved?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAllReviewReportResponse
   */
  is_automatic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  user_id?: string;
  /**
   *
   * @type {Question}
   * @memberof GetAllReviewReportResponse
   */
  question?: Question;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllReviewReportResponse
   */
  original_ids?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof GetAllReviewReportResponse
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface GetAllSalesLeadsJobsResponse
 */
export interface GetAllSalesLeadsJobsResponse {
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<GetAllSalesLeadsJobsResponseSub>}
   * @memberof GetAllSalesLeadsJobsResponse
   */
  jobs?: Array<GetAllSalesLeadsJobsResponseSub>;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponse
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface GetAllSalesLeadsJobsResponseSub
 */
export interface GetAllSalesLeadsJobsResponseSub {
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  total_new_applicants?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  total_hired?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  total_shortlisted?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  total_applicants?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsJobsResponseSub
   */
  alias?: string;
}
/**
 *
 * @export
 * @interface GetAllSalesLeadsResponse
 */
export interface GetAllSalesLeadsResponse {
  /**
   *
   * @type {Array<GetAllSalesLeadsResponseSub>}
   * @memberof GetAllSalesLeadsResponse
   */
  sales_leads?: Array<GetAllSalesLeadsResponseSub>;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_leads_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_last_2_hours_leads?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_this_month_leads?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_last_day_leads?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllSalesLeadsResponse
   */
  total_last_week_leads?: number;
}
/**
 *
 * @export
 * @interface GetAllSalesLeadsResponseSub
 */
export interface GetAllSalesLeadsResponseSub {
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  job_role_id?: string;
  /**
   *
   * @type {CompactSalesUser}
   * @memberof GetAllSalesLeadsResponseSub
   */
  sales_user?: CompactSalesUser;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  sales_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllSalesLeadsResponseSub
   */
  other_email?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  sales_lead_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  sales_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  sales_lead_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  last_call?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllSalesLeadsResponseSub
   */
  college_name?: string;
}
/**
 *
 * @export
 * @interface GetAllTagResponse
 */
export interface GetAllTagResponse {
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  unlocked_until?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  user_level_cutoff_scores?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAllTagResponse
   */
  is_unlocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  approval_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetAllTagResponse
   */
  domain_ids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  timeout_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  difficulty_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  similarity_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  weightage_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  parent_tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  question_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  category_unlock_days?: number;
  /**
   *
   * @type {UserLevelDetailsSub}
   * @memberof GetAllTagResponse
   */
  user_level_details?: UserLevelDetailsSub;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  category_unlock_credits?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  total_category_questions?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  competition_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  accepted_question_credits?: number;
  /**
   *
   * @type {Array<object>}
   * @memberof GetAllTagResponse
   */
  sub_tags?: Array<object>;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  unanswered_category_questions?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetAllTagResponse
   */
  is_user_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  s3_url?: string;
  /**
   *
   * @type {number}
   * @memberof GetAllTagResponse
   */
  last_group_slug_id?: number;
  /**
   *
   * @type {string}
   * @memberof GetAllTagResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetAllTemplatesResponse
 */
export interface GetAllTemplatesResponse {
  /**
   *
   * @type {Array<GetAllTemplatesSub>}
   * @memberof GetAllTemplatesResponse
   */
  templates?: Array<GetAllTemplatesSub>;
}
/**
 *
 * @export
 * @interface GetAllTemplatesSub
 */
export interface GetAllTemplatesSub {
  /**
   *
   * @type {string}
   * @memberof GetAllTemplatesSub
   */
  template_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAllTemplatesSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetApplicantChatResponse
 */
export interface GetApplicantChatResponse {
  /**
   *
   * @type {Array<ApplicantChatUserDetails>}
   * @memberof GetApplicantChatResponse
   */
  all_users?: Array<ApplicantChatUserDetails>;
  /**
   *
   * @type {Array<AddApplicantMsgResponse>}
   * @memberof GetApplicantChatResponse
   */
  all_chats?: Array<AddApplicantMsgResponse>;
}
/**
 *
 * @export
 * @interface GetApplicantSkillsResponse
 */
export interface GetApplicantSkillsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetApplicantSkillsResponse
   */
  applicant_skills_list?: Array<string>;
}
/**
 *
 * @export
 * @interface GetAppliedJobResponse
 */
export interface GetAppliedJobResponse {
  /**
   *
   * @type {number}
   * @memberof GetAppliedJobResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<AppliedJobsSub>}
   * @memberof GetAppliedJobResponse
   */
  jobs?: Array<AppliedJobsSub>;
  /**
   *
   * @type {number}
   * @memberof GetAppliedJobResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAppliedJobResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface GetAssessmentResponse
 */
export interface GetAssessmentResponse {
  /**
   *
   * @type {Array<AssessmentField>}
   * @memberof GetAssessmentResponse
   */
  fields?: Array<AssessmentField>;
  /**
   *
   * @type {string}
   * @memberof GetAssessmentResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetAttemptByIdResponse
 */
export interface GetAttemptByIdResponse {
  /**
   *
   * @type {number}
   * @memberof GetAttemptByIdResponse
   */
  seconds_taken?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  bookmark?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  created_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  next_attempt_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  attempted_answer_one_word?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_attempt_correct?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_disliked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_solution_liked?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  tag_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  question_skipped?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetAttemptByIdResponse
   */
  purchased_time?: number;
  /**
   *
   * @type {number}
   * @memberof GetAttemptByIdResponse
   */
  attempt_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  question_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_solution_unlocked?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_attempt_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_solution_disliked?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  competition_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  modified_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  custom_mocktest_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  custom_mocktest_question_num?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  previous_attempt_id?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GetAttemptByIdResponse
   */
  attempted_answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  user_id?: string;
  /**
   *
   * @type {AnsweredQuestionSub}
   * @memberof GetAttemptByIdResponse
   */
  question?: AnsweredQuestionSub;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  timeout?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetAttemptByIdResponse
   */
  max_question_score?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetAttemptByIdResponse
   */
  is_hint_unlocked?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetAttemptByIdResponse
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface GetAutocompleteTagResponse
 */
export interface GetAutocompleteTagResponse {
  /**
   *
   * @type {number}
   * @memberof GetAutocompleteTagResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetAutocompleteTagResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetAutocompleteTagResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<GetAutocompleteTagSub>}
   * @memberof GetAutocompleteTagResponse
   */
  data?: Array<GetAutocompleteTagSub>;
  /**
   *
   * @type {boolean}
   * @memberof GetAutocompleteTagResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface GetAutocompleteTagSub
 */
export interface GetAutocompleteTagSub {
  /**
   *
   * @type {string}
   * @memberof GetAutocompleteTagSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAutocompleteTagSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetBackendConstantsRepsonse
 */
export interface GetBackendConstantsRepsonse {
  /**
   *
   * @type {{ [key: string]: BackendConstantsSub; }}
   * @memberof GetBackendConstantsRepsonse
   */
  constants?: { [key: string]: BackendConstantsSub };
}
/**
 *
 * @export
 * @interface GetBadgeTypeResponse
 */
export interface GetBadgeTypeResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetBadgeTypeResponse
   */
  badge_types: Array<string>;
}
/**
 *
 * @export
 * @interface GetCallAttemptListResponse
 */
export interface GetCallAttemptListResponse {
  /**
   *
   * @type {number}
   * @memberof GetCallAttemptListResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetCallAttemptListResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetCallAttemptListResponse
   */
  page?: number;
  /**
   *
   * @type {Array<GetCallAttemptResponse>}
   * @memberof GetCallAttemptListResponse
   */
  call_attempts?: Array<GetCallAttemptResponse>;
}
/**
 *
 * @export
 * @interface GetCallAttemptResponse
 */
export interface GetCallAttemptResponse {
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  call_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  sales_lead_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCallAttemptResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetCartItemDetailsSub
 */
export interface GetCartItemDetailsSub {
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  available_to?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  item_description?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  sku_size?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  item_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCartItemDetailsSub
   */
  is_active?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  created_by?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  discount_pct_for_gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  coupon_code?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  long_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  gross_price_in_gems_after_disc?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  coupon_discount?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  duration_type?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  gross_price_in_gems?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  gross_price_in_currency_after_disc?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCartItemDetailsSub
   */
  is_reward?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GetCartItemDetailsSub
   */
  image_url?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  duration_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  sku_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  gross_price_in_currency?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemDetailsSub
   */
  discount_pct_for_currency?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemDetailsSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetCartItemSub
 */
export interface GetCartItemSub {
  /**
   *
   * @type {number}
   * @memberof GetCartItemSub
   */
  total_amount_in_currency?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemSub
   */
  gst_on_item?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartItemSub
   */
  total_amount_in_gems?: number | null;
  /**
   *
   * @type {string}
   * @memberof GetCartItemSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemSub
   */
  item_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemSub
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartItemSub
   */
  modified_on?: string | null;
  /**
   *
   * @type {GetCartItemDetailsSub}
   * @memberof GetCartItemSub
   */
  item?: GetCartItemDetailsSub;
  /**
   *
   * @type {number}
   * @memberof GetCartItemSub
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartItemSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetCartResponse
 */
export interface GetCartResponse {
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  net_value_in_currency?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  invoice_no?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  total_discount_currency?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  item_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  payment_mode?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  razorpay_order_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  user_msg?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  total_gst?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  virtual_items_credited?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  invoice_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  coupon_code?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  total_discount_gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  razorpay_signature?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  coupon_discount?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  limited_discount?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  buyer_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  net_value_in_gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  payment_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  internal_msg?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  gross_total_in_gems?: number;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  discount_pct_gems?: number;
  /**
   *
   * @type {Array<GetCartItemSub>}
   * @memberof GetCartResponse
   */
  cart?: Array<GetCartItemSub>;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  gross_total_in_currency?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  razorpay_payment_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetCartResponse
   */
  discount_pct_currency?: number;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  overall_transaction_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetCartResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetCollegeDetailsResponse
 */
export interface GetCollegeDetailsResponse {
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  college_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  district_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  default_color?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  joint_director_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  address?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCollegeDetailsResponse
   */
  is_phone_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  state_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCollegeDetailsResponse
   */
  is_email_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  college_admin_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  director_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  university_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCollegeDetailsResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetCollegeMilestonesDetailsResponse
 */
export interface GetCollegeMilestonesDetailsResponse {
  /**
   *
   * @type {number}
   * @memberof GetCollegeMilestonesDetailsResponse
   */
  total_students?: number;
  /**
   *
   * @type {Array<CreateCollegeMilestonesResponse>}
   * @memberof GetCollegeMilestonesDetailsResponse
   */
  all_milestone_details?: Array<CreateCollegeMilestonesResponse>;
  /**
   *
   * @type {number}
   * @memberof GetCollegeMilestonesDetailsResponse
   */
  current_milestone_level?: number;
  /**
   *
   * @type {number}
   * @memberof GetCollegeMilestonesDetailsResponse
   */
  next_milestone_level?: number;
  /**
   *
   * @type {CreateCollegeMilestonesResponse}
   * @memberof GetCollegeMilestonesDetailsResponse
   */
  current_milestone_details?: CreateCollegeMilestonesResponse;
}
/**
 *
 * @export
 * @interface GetCompetitionAnalytics
 */
export interface GetCompetitionAnalytics {
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  questions_played?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  credits_consumed?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  competition_generated_users?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  days_played?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  new_user_added?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionAnalytics
   */
  user_count?: number;
}
/**
 *
 * @export
 * @interface GetCompetitionInvitesResponse
 */
export interface GetCompetitionInvitesResponse {
  /**
   *
   * @type {Array<GetCompetitionInvitesSub>}
   * @memberof GetCompetitionInvitesResponse
   */
  invited_users?: Array<GetCompetitionInvitesSub>;
}
/**
 *
 * @export
 * @interface GetCompetitionInvitesSub
 */
export interface GetCompetitionInvitesSub {
  /**
   *
   * @type {string}
   * @memberof GetCompetitionInvitesSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionInvitesSub
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionInvitesSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionInvitesSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetCompetitionListSub
 */
export interface GetCompetitionListSub {
  /**
   *
   * @type {string}
   * @memberof GetCompetitionListSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionListSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetCompetitionResponse
 */
export interface GetCompetitionResponse {
  /**
   *
   * @type {string}
   * @memberof GetCompetitionResponse
   */
  page_no?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionResponse
   */
  total_pages?: string;
  /**
   *
   * @type {Array<GetCompetitionListSub>}
   * @memberof GetCompetitionResponse
   */
  competitions?: Array<GetCompetitionListSub>;
  /**
   *
   * @type {string}
   * @memberof GetCompetitionResponse
   */
  page_size?: string;
}
/**
 *
 * @export
 * @interface GetCompetitionSchedulesResponse
 */
export interface GetCompetitionSchedulesResponse {
  /**
   *
   * @type {number}
   * @memberof GetCompetitionSchedulesResponse
   */
  page_no?: number;
  /**
   *
   * @type {Array<UpsertScheduleResponse>}
   * @memberof GetCompetitionSchedulesResponse
   */
  scheduled_competitions?: Array<UpsertScheduleResponse>;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionSchedulesResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetCompetitionSchedulesResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetCouponResponse
 */
export interface GetCouponResponse {
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  coupon_type_use?: string;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  expire_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  item_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetCouponResponse
   */
  percent_discount?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetCouponResponse
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetCouponResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetCreditConsumptionHistoryResponse
 */
export interface GetCreditConsumptionHistoryResponse {
  /**
   *
   * @type {number}
   * @memberof GetCreditConsumptionHistoryResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<GetUserCredits>}
   * @memberof GetCreditConsumptionHistoryResponse
   */
  data?: Array<GetUserCredits>;
  /**
   *
   * @type {number}
   * @memberof GetCreditConsumptionHistoryResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditConsumptionHistoryResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface GetCreditValuesResponse
 */
export interface GetCreditValuesResponse {
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  OnboardingCredits?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  TimeIncrease?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  PremiumJobFreeCredits?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  BuySolution?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  ApplicantResumeAIAnalysis?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  JobProfileCredits?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  StreakSaved?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  QuestionBlocked?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  QuestionReport?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  CompetitionRegister?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  SelfResumeAnalysis?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  SolutionImprove?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  UserReferral?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  SignUp?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  BuyHint?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  BuyTest?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  ThursdayCredit?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  QuestionAccepted?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  QuestionImprove?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  CategoryUnlock?: number;
  /**
   *
   * @type {number}
   * @memberof GetCreditValuesResponse
   */
  JobApplied?: number;
}
/**
 *
 * @export
 * @interface GetDashboardResponse
 */
export interface GetDashboardResponse {
  /**
   *
   * @type {Array<RecommendedTags>}
   * @memberof GetDashboardResponse
   */
  recommended_tags?: Array<RecommendedTags>;
  /**
   *
   * @type {UserStats}
   * @memberof GetDashboardResponse
   */
  user_stats?: UserStats;
  /**
   *
   * @type {OverallYearStats}
   * @memberof GetDashboardResponse
   */
  overall_year_stats?: OverallYearStats;
  /**
   *
   * @type {UserBadgesSub}
   * @memberof GetDashboardResponse
   */
  user_badges?: UserBadgesSub;
  /**
   *
   * @type {Array<LiveCompetition>}
   * @memberof GetDashboardResponse
   */
  live_competition?: Array<LiveCompetition>;
  /**
   *
   * @type {Array<RecommendedMockTests>}
   * @memberof GetDashboardResponse
   */
  recommended_mock_tests?: Array<RecommendedMockTests>;
}
/**
 *
 * @export
 * @interface GetDegreesResponse
 */
export interface GetDegreesResponse {
  /**
   *
   * @type {Array<DegreeDetailsSub>}
   * @memberof GetDegreesResponse
   */
  all_degrees?: Array<DegreeDetailsSub>;
  /**
   *
   * @type {number}
   * @memberof GetDegreesResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetDegreesResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetDegreesResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetDomainListResponse
 */
export interface GetDomainListResponse {
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  img_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  img_url_blue?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof GetDomainListResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetDomainReviewReportedResponse
 */
export interface GetDomainReviewReportedResponse {
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  reporter_credits_earned?: number;
  /**
   *
   * @type {{ [key: string]: QuestionWiseReport; }}
   * @memberof GetDomainReviewReportedResponse
   */
  question_wise_reports?: { [key: string]: QuestionWiseReport };
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  total_correct_votes?: number;
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  total_incorrect_votes?: number;
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  reviewer_credits_earned?: number | null;
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetDomainReviewReportedResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface GetEmailNotification
 */
export interface GetEmailNotification {
  /**
   *
   * @type {Array<EmailNotificationSub>}
   * @memberof GetEmailNotification
   */
  notifications?: Array<EmailNotificationSub>;
}
/**
 *
 * @export
 * @interface GetFeedbackReportsResponse
 */
export interface GetFeedbackReportsResponse {
  /**
   *
   * @type {number}
   * @memberof GetFeedbackReportsResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetFeedbackReportsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetFeedbackReportsResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<FeedbackReport>}
   * @memberof GetFeedbackReportsResponse
   */
  feedback_reports?: Array<FeedbackReport>;
}
/**
 *
 * @export
 * @interface GetFestDetailResponse
 */
export interface GetFestDetailResponse {
  /**
   *
   * @type {number}
   * @memberof GetFestDetailResponse
   */
  event_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetFestDetailResponse
   */
  openings_count?: number;
  /**
   *
   * @type {GetFestResponse}
   * @memberof GetFestDetailResponse
   */
  fest_obj?: GetFestResponse;
  /**
   *
   * @type {number}
   * @memberof GetFestDetailResponse
   */
  job_count?: number;
}
/**
 *
 * @export
 * @interface GetFestEventResponse
 */
export interface GetFestEventResponse {
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  fest_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestEventResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetFestResponse
 */
export interface GetFestResponse {
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetFestResponse
   */
  is_live?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetFestResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetFilteredApplicantUserIdsBody
 */
export interface GetFilteredApplicantUserIdsBody {
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_assessment_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {CompetitionData}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  competition_data?: CompetitionData;
  /**
   *
   * @type {string}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  user_search_query?: string;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_experience?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_rating?: number;
  /**
   *
   * @type {string}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  sort_order?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  applicant_status?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_current_ctc?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_rating?: number;
  /**
   *
   * @type {string}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  job_preference?: string;
  /**
   *
   * @type {string}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  sort_by?: string;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_experience?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  resume_uploaded?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_education?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  custom_applicant_user_ids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_education?: number;
  /**
   *
   * @type {SortBy}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  sort_by_competition_data?: SortBy;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_resume_analysis_overall_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_assessment_score?: number;
  /**
   *
   * @type {Array<RequiredSkillsFilter>}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  required_skills?: Array<RequiredSkillsFilter>;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  notice_period?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  count_only?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetFilteredApplicantUserIdsBody
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetGeneratedJDResponse
 */
export interface GetGeneratedJDResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetGeneratedJDResponse
   */
  success?: boolean;
  /**
   *
   * @type {GeneratedJDSub}
   * @memberof GetGeneratedJDResponse
   */
  jd_generation?: GeneratedJDSub;
}
/**
 *
 * @export
 * @interface GetGptResponseHistoryResponse
 */
export interface GetGptResponseHistoryResponse {
  /**
   *
   * @type {number}
   * @memberof GetGptResponseHistoryResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetGptResponseHistoryResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetGptResponseHistoryResponse
   */
  page?: number;
  /**
   *
   * @type {Array<GetGptResponseHistorySub>}
   * @memberof GetGptResponseHistoryResponse
   */
  responses?: Array<GetGptResponseHistorySub>;
}
/**
 *
 * @export
 * @interface GetGptResponseHistorySub
 */
export interface GetGptResponseHistorySub {
  /**
   *
   * @type {object}
   * @memberof GetGptResponseHistorySub
   */
  response?: object;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetGptResponseHistorySub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetHiredCandidatesResponse
 */
export interface GetHiredCandidatesResponse {
  /**
   *
   * @type {Array<HiredCandidateSub>}
   * @memberof GetHiredCandidatesResponse
   */
  hired_candidates?: Array<HiredCandidateSub>;
}
/**
 *
 * @export
 * @interface GetJobItemResponse
 */
export interface GetJobItemResponse {
  /**
   *
   * @type {{ [key: string]: Array<GetStoreItemsSub>; }}
   * @memberof GetJobItemResponse
   */
  items?: { [key: string]: Array<GetStoreItemsSub> };
}
/**
 *
 * @export
 * @interface GetJobResponse
 */
export interface GetJobResponse {
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetJobResponse
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  job_closing_reason?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  minimum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  job_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  gpt_job_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof GetJobResponse
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  application_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  job_role_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetJobResponse
   */
  is_premium?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetJobResponse
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  is_archieved?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  minimum_qualification?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  additional_details?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof GetJobResponse
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof GetJobResponse
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof GetJobResponse
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  salary_frequency?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResponse
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface GetJobResumeAnalysis
 */
export interface GetJobResumeAnalysis {
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysis
   */
  rating?: number;
  /**
   *
   * @type {object}
   * @memberof GetJobResumeAnalysis
   */
  response?: object;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysis
   */
  overall_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  job_description_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysis
   */
  skill_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  hiring_status?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysis
   */
  experience_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  resume_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysis
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetJobResumeAnalysisBase
 */
export interface GetJobResumeAnalysisBase {
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysisBase
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof GetJobResumeAnalysisBase
   */
  overall_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  job_description_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  hiring_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetJobResumeAnalysisBase
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetJobRolesResponse
 */
export interface GetJobRolesResponse {
  /**
   *
   * @type {Array<JobRoleDetailsSub>}
   * @memberof GetJobRolesResponse
   */
  all_job_roles?: Array<JobRoleDetailsSub>;
  /**
   *
   * @type {number}
   * @memberof GetJobRolesResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetJobRolesResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetJobRolesResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetJobsForCollegeResponse
 */
export interface GetJobsForCollegeResponse {
  /**
   *
   * @type {number}
   * @memberof GetJobsForCollegeResponse
   */
  total_hired?: number;
  /**
   *
   * @type {number}
   * @memberof GetJobsForCollegeResponse
   */
  total_invite_accepted?: number;
  /**
   *
   * @type {number}
   * @memberof GetJobsForCollegeResponse
   */
  total_shortlisted?: number;
  /**
   *
   * @type {Array<CollegeJobMapSub>}
   * @memberof GetJobsForCollegeResponse
   */
  jobs?: Array<CollegeJobMapSub>;
  /**
   *
   * @type {number}
   * @memberof GetJobsForCollegeResponse
   */
  total_invite_sent?: number;
}
/**
 *
 * @export
 * @interface GetMailLogsResonse
 */
export interface GetMailLogsResonse {
  /**
   *
   * @type {number}
   * @memberof GetMailLogsResonse
   */
  page_no?: number;
  /**
   *
   * @type {Array<MailLogs>}
   * @memberof GetMailLogsResonse
   */
  mail_logs?: Array<MailLogs>;
  /**
   *
   * @type {number}
   * @memberof GetMailLogsResonse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetMailLogsResonse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface GetMockTestByIdResponse
 */
export interface GetMockTestByIdResponse {
  /**
   *
   * @type {CreateMockTestRepsonse}
   * @memberof GetMockTestByIdResponse
   */
  mocktest?: CreateMockTestRepsonse;
}
/**
 *
 * @export
 * @interface GetMockTestPrerequisiteStatusResponse
 */
export interface GetMockTestPrerequisiteStatusResponse {
  /**
   *
   * @type {{ [key: string]: PrerequisiteStatusSub; }}
   * @memberof GetMockTestPrerequisiteStatusResponse
   */
  status?: { [key: string]: PrerequisiteStatusSub };
}
/**
 *
 * @export
 * @interface GetNewGptResponse
 */
export interface GetNewGptResponse {
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  response?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetNewGptResponseBody
 */
export interface GetNewGptResponseBody {
  /**
   *
   * @type {Array<string>}
   * @memberof GetNewGptResponseBody
   */
  system_contents?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponseBody
   */
  question: string;
  /**
   *
   * @type {string}
   * @memberof GetNewGptResponseBody
   */
  domain_id?: string;
}
/**
 *
 * @export
 * @interface GetOrgJobIdsByAliasResponse
 */
export interface GetOrgJobIdsByAliasResponse {
  /**
   *
   * @type {string}
   * @memberof GetOrgJobIdsByAliasResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrgJobIdsByAliasResponse
   */
  org_id?: string;
}
/**
 *
 * @export
 * @interface GetOrgListResponse
 */
export interface GetOrgListResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrgListResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetOrgListResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetOrgListResponse
   */
  page?: number;
  /**
   *
   * @type {Array<GetOrganizationListSub>}
   * @memberof GetOrgListResponse
   */
  org_list?: Array<GetOrganizationListSub>;
}
/**
 *
 * @export
 * @interface GetOrganizationListSub
 */
export interface GetOrganizationListSub {
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  team_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  verification_doc_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  org_user_role?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  modified_on?: string;
  /**
   *
   * @type {Location}
   * @memberof GetOrganizationListSub
   */
  org_location_details?: Location;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  linkedin_url?: string;
  /**
   *
   * @type {CompanySub}
   * @memberof GetOrganizationListSub
   */
  company?: CompanySub;
  /**
   *
   * @type {MiniUserDetailsSub}
   * @memberof GetOrganizationListSub
   */
  invited_by?: MiniUserDetailsSub;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  website_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationListSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetOrganizationUsersListResponse
 */
export interface GetOrganizationUsersListResponse {
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  full_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  acceptance_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  invited_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  department_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetOrganizationUsersListResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetPendingEntityCountResponse
 */
export interface GetPendingEntityCountResponse {
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  skills?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  job_role?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  user_feedback?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  college?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  organization?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  sub_tag?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  job?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  question?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  company?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  degree?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  specialization?: number;
  /**
   *
   * @type {number}
   * @memberof GetPendingEntityCountResponse
   */
  tag?: number;
}
/**
 *
 * @export
 * @interface GetPublicGroupResponse
 */
export interface GetPublicGroupResponse {
  /**
   *
   * @type {number}
   * @memberof GetPublicGroupResponse
   */
  total_questions?: number;
  /**
   *
   * @type {string}
   * @memberof GetPublicGroupResponse
   */
  category_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPublicGroupResponse
   */
  title?: string;
  /**
   *
   * @type {Array<LiveCompetitionResponse>}
   * @memberof GetPublicGroupResponse
   */
  live_competitions?: Array<LiveCompetitionResponse>;
  /**
   *
   * @type {Array<RelatedTopic>}
   * @memberof GetPublicGroupResponse
   */
  related_topics?: Array<RelatedTopic>;
  /**
   *
   * @type {Array<LinkedQuestionSet>}
   * @memberof GetPublicGroupResponse
   */
  linked_question_sets?: Array<LinkedQuestionSet>;
  /**
   *
   * @type {string}
   * @memberof GetPublicGroupResponse
   */
  seo_title?: string;
  /**
   *
   * @type {Array<PublicQuestion>}
   * @memberof GetPublicGroupResponse
   */
  questions?: Array<PublicQuestion>;
  /**
   *
   * @type {string}
   * @memberof GetPublicGroupResponse
   */
  current_tag_img?: string;
}
/**
 *
 * @export
 * @interface GetQuestionHintResponse
 */
export interface GetQuestionHintResponse {
  /**
   *
   * @type {string}
   * @memberof GetQuestionHintResponse
   */
  hint?: string;
}
/**
 *
 * @export
 * @interface GetQuestionStatisticsResponse
 */
export interface GetQuestionStatisticsResponse {
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_timeout?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  avg_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_reports?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  likes?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof GetQuestionStatisticsResponse
   */
  total_skipped?: number;
}
/**
 *
 * @export
 * @interface GetReviewBlockStatisticsResponse
 */
export interface GetReviewBlockStatisticsResponse {
  /**
   *
   * @type {Array<BlockStatistics>}
   * @memberof GetReviewBlockStatisticsResponse
   */
  block_statistics?: Array<BlockStatistics> | null;
  /**
   *
   * @type {CreatorDetails}
   * @memberof GetReviewBlockStatisticsResponse
   */
  user?: CreatorDetails;
}
/**
 *
 * @export
 * @interface GetReviewResolveReportBody
 */
export interface GetReviewResolveReportBody {
  /**
   *
   * @type {string}
   * @memberof GetReviewResolveReportBody
   */
  report_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetReviewResolveReportBody
   */
  resolution_type?: string;
}
/**
 *
 * @export
 * @interface GetReviewResolveReportResponse
 */
export interface GetReviewResolveReportResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetReviewResolveReportResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetReviewResolveReportResponse
   */
  resolution?: string;
}
/**
 *
 * @export
 * @interface GetReviewTemplateResponse
 */
export interface GetReviewTemplateResponse {
  /**
   *
   * @type {string}
   * @memberof GetReviewTemplateResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetReviewTemplateResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetReviewTemplateResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetReviewTemplateResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetReviewTemplateResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetS3FileResponse
 */
export interface GetS3FileResponse {
  /**
   *
   * @type {string}
   * @memberof GetS3FileResponse
   */
  local_path?: string;
}
/**
 *
 * @export
 * @interface GetSalesExcutiveSub
 */
export interface GetSalesExcutiveSub {
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  premium_job_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  username?: string;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  login_streak?: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  gender?: string;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  phone_number?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalesExcutiveSub
   */
  viewed_onboardings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  about?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetSalesExcutiveSub
   */
  is_profile_hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  default_color?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalesExcutiveSub
   */
  completed_profile_fields?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  job_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  last_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetSalesExcutiveSub
   */
  is_phone_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  referral_hash?: string;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  signup_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  selected_domain?: string;
  /**
   *
   * @type {number}
   * @memberof GetSalesExcutiveSub
   */
  sales_leads_count?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalesExcutiveSub
   */
  completed_job_profile_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSalesExcutiveSub
   */
  country?: string;
}
/**
 *
 * @export
 * @interface GetSalesLeadResponse
 */
export interface GetSalesLeadResponse {
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  job_role_id?: string;
  /**
   *
   * @type {CompactSalesUser}
   * @memberof GetSalesLeadResponse
   */
  sales_user?: CompactSalesUser;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  sales_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalesLeadResponse
   */
  other_email?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  sales_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  sales_lead_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetSalesLeadResponse
   */
  college_name?: string;
}
/**
 *
 * @export
 * @interface GetSalesMailSentListResponse
 */
export interface GetSalesMailSentListResponse {
  /**
   *
   * @type {number}
   * @memberof GetSalesMailSentListResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesMailSentListResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetSalesMailSentListResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<EmailLog>}
   * @memberof GetSalesMailSentListResponse
   */
  mail_list?: Array<EmailLog>;
}
/**
 *
 * @export
 * @interface GetShippingAddressResponse
 */
export interface GetShippingAddressResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetShippingAddressResponse
   */
  success?: boolean;
  /**
   *
   * @type {Address}
   * @memberof GetShippingAddressResponse
   */
  shipping_details?: Address;
}
/**
 *
 * @export
 * @interface GetSolutionResponse
 */
export interface GetSolutionResponse {
  /**
   *
   * @type {Solution}
   * @memberof GetSolutionResponse
   */
  solution?: Solution;
  /**
   *
   * @type {string}
   * @memberof GetSolutionResponse
   */
  question_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetSolutionResponse
   */
  solution_text?: string;
}
/**
 *
 * @export
 * @interface GetSpecializationsResponse
 */
export interface GetSpecializationsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSpecializationsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetSpecializationsResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetSpecializationsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<SpecializationDetailsSub>}
   * @memberof GetSpecializationsResponse
   */
  all_specializations?: Array<SpecializationDetailsSub>;
}
/**
 *
 * @export
 * @interface GetStartStatisticsResponse
 */
export interface GetStartStatisticsResponse {
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  tag_name?: string;
  /**
   *
   * @type {Array<PreferenceSub>}
   * @memberof GetStartStatisticsResponse
   */
  preferences?: Array<PreferenceSub>;
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  unlocked_until?: string;
  /**
   *
   * @type {ProgressObjSub}
   * @memberof GetStartStatisticsResponse
   */
  progress_obj?: ProgressObjSub;
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  domain_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetStartStatisticsResponse
   */
  domain_id?: string;
}
/**
 *
 * @export
 * @interface GetStoreItemsAdminResponse
 */
export interface GetStoreItemsAdminResponse {
  /**
   *
   * @type {Array<GetCartItemDetailsSub>}
   * @memberof GetStoreItemsAdminResponse
   */
  items?: Array<GetCartItemDetailsSub>;
}
/**
 *
 * @export
 * @interface GetStoreItemsResponse
 */
export interface GetStoreItemsResponse {
  /**
   *
   * @type {Array<GetStoreItemsSub>}
   * @memberof GetStoreItemsResponse
   */
  items?: Array<GetStoreItemsSub>;
}
/**
 *
 * @export
 * @interface GetStoreItemsSub
 */
export interface GetStoreItemsSub {
  /**
   *
   * @type {boolean}
   * @memberof GetStoreItemsSub
   */
  is_reward?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  duration_type?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetStoreItemsSub
   */
  image_url?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  discount_pct_for_gems?: number;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  duration_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  item_description?: string;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  gross_price_in_gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  long_description?: string;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  sku_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  gross_price_in_gems_after_disc?: number;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  gross_price_in_currency?: number;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  gross_price_in_currency_after_disc?: number;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  item_type?: string;
  /**
   *
   * @type {number}
   * @memberof GetStoreItemsSub
   */
  discount_pct_for_currency?: number;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof GetStoreItemsSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetStreakProgressResponse
 */
export interface GetStreakProgressResponse {
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  current_day_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  total_correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  total_badges?: number;
  /**
   *
   * @type {string}
   * @memberof GetStreakProgressResponse
   */
  current_day_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  current_tag_total_attempts?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetStreakProgressResponse
   */
  is_streak_save_possible?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  current_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  current_streak_target?: number;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  streak_save_cost?: number;
  /**
   *
   * @type {string}
   * @memberof GetStreakProgressResponse
   */
  current_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof GetStreakProgressResponse
   */
  current_day_streak_target?: number;
}
/**
 *
 * @export
 * @interface GetStudentCollegesResponse
 */
export interface GetStudentCollegesResponse {
  /**
   *
   * @type {Array<CompactCollegeDetailsSub>}
   * @memberof GetStudentCollegesResponse
   */
  college_list?: Array<CompactCollegeDetailsSub>;
}
/**
 *
 * @export
 * @interface GetStudentJobsResponse
 */
export interface GetStudentJobsResponse {
  /**
   *
   * @type {number}
   * @memberof GetStudentJobsResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<StudentJobSub>}
   * @memberof GetStudentJobsResponse
   */
  jobs?: Array<StudentJobSub>;
  /**
   *
   * @type {number}
   * @memberof GetStudentJobsResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetStudentJobsResponse
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface GetStudentStatsResponse
 */
export interface GetStudentStatsResponse {
  /**
   *
   * @type {Array<QuestionStats>}
   * @memberof GetStudentStatsResponse
   */
  stats?: Array<QuestionStats>;
}
/**
 *
 * @export
 * @interface GetTagByIdResponse
 */
export interface GetTagByIdResponse {
  /**
   *
   * @type {number}
   * @memberof GetTagByIdResponse
   */
  category_unlock_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  user_level_cutoff_scores?: string;
  /**
   *
   * @type {Array<SubtagMinimalSub>}
   * @memberof GetTagByIdResponse
   */
  sub_tags?: Array<SubtagMinimalSub>;
  /**
   *
   * @type {number}
   * @memberof GetTagByIdResponse
   */
  accepted_question_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  difficulty_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  last_group_slug_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  similarity_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  timeout_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  weightage_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  parent_tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetTagByIdResponse
   */
  question_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagByIdResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetTagPreferencesResponse
 */
export interface GetTagPreferencesResponse {
  /**
   *
   * @type {Array<GetTagPreferencesSub>}
   * @memberof GetTagPreferencesResponse
   */
  data?: Array<GetTagPreferencesSub>;
  /**
   *
   * @type {boolean}
   * @memberof GetTagPreferencesResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface GetTagPreferencesSub
 */
export interface GetTagPreferencesSub {
  /**
   *
   * @type {number}
   * @memberof GetTagPreferencesSub
   */
  difficulty_score?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetTagPreferencesSub
   */
  is_selected?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetTagPreferencesSub
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagPreferencesSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof GetTagPreferencesSub
   */
  question_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagPreferencesSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetTagResponse
 */
export interface GetTagResponse {
  /**
   *
   * @type {number}
   * @memberof GetTagResponse
   */
  category_unlock_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  user_level_cutoff_scores?: string;
  /**
   *
   * @type {number}
   * @memberof GetTagResponse
   */
  accepted_question_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  difficulty_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  last_group_slug_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  similarity_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  timeout_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  weightage_checked?: string;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  parent_tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetTagResponse
   */
  question_count?: number;
  /**
   *
   * @type {string}
   * @memberof GetTagResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetTagTestResponse
 */
export interface GetTagTestResponse {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetTagTestResponse
   */
  competitions_by_tag?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface GetTemplateByIdResponse
 */
export interface GetTemplateByIdResponse {
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  template_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  user_id?: string;
  /**
   *
   * @type {object}
   * @memberof GetTemplateByIdResponse
   */
  template_args?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof GetTemplateByIdResponse
   */
  signed_attachment_urls?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetTemplateByIdResponse
   */
  attachment_urls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  template_body?: string;
  /**
   *
   * @type {string}
   * @memberof GetTemplateByIdResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetTemplatesListResponse
 */
export interface GetTemplatesListResponse {
  /**
   *
   * @type {Array<GetTemplateByIdResponse>}
   * @memberof GetTemplatesListResponse
   */
  templates?: Array<GetTemplateByIdResponse>;
}
/**
 *
 * @export
 * @interface GetTodayBadgesResponse
 */
export interface GetTodayBadgesResponse {
  /**
   *
   * @type {Array<GetAllBadgesSub>}
   * @memberof GetTodayBadgesResponse
   */
  user_today_badges?: Array<GetAllBadgesSub>;
}
/**
 *
 * @export
 * @interface GetUnansweredQuestionResponse
 */
export interface GetUnansweredQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  total_bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  negative_score?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  next_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {Array<Option>}
   * @memberof GetUnansweredQuestionResponse
   */
  options?: Array<Option>;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  total_solution_likes?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  no_options?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  created_by?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  question_type?: string;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  remaining_attempts?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  created_by_username?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  tag_id?: string;
  /**
   *
   * @type {AttemptDetailsSub}
   * @memberof GetUnansweredQuestionResponse
   */
  attempt_details?: AttemptDetailsSub;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  purchased_time?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  question_source_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetUnansweredQuestionResponse
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetUnansweredQuestionResponse
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  created_by_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  question_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnansweredQuestionResponse
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  attempt_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  is_deleted?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  exam_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  sub_question_number?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  difficulty_level?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnansweredQuestionResponse
   */
  start_time?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnansweredQuestionResponse
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  year?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  external_exam?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  multipart_question_count?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnansweredQuestionResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetUnderReviewQuestion
 */
export interface GetUnderReviewQuestion {
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  total_bookmarks?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  next_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  answer_option_no?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  quality_score?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  total_dislikes?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof GetUnderReviewQuestion
   */
  options?: Array<any>;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  attempt_accuracy?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  no_options?: string | null;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  total_solution_likes?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  attempt_rate?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  created_by?: string;
  /**
   *
   * @type {Solution}
   * @memberof GetUnderReviewQuestion
   */
  solution?: Solution;
  /**
   *
   * @type {object}
   * @memberof GetUnderReviewQuestion
   */
  similars?: object;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  correct_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  is_credit_added?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnderReviewQuestion
   */
  answer_one_word?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_attempts?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_source_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetUnderReviewQuestion
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetUnderReviewQuestion
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnderReviewQuestion
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetUnderReviewQuestion
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {UserData1}
   * @memberof GetUnderReviewQuestion
   */
  user_data?: UserData1;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  completed_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  exam_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  is_deleted?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  sub_question_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  difficulty_level?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof GetUnderReviewQuestion
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewQuestion
   */
  year?: number;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof GetUnderReviewQuestion
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  multipart_question_count?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetUnderReviewQuestion
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface GetUnderReviewResponse
 */
export interface GetUnderReviewResponse {
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<Question>}
   * @memberof GetUnderReviewResponse
   */
  data?: Array<Question>;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse
   */
  current_page?: number;
}
/**
 *
 * @export
 * @interface GetUnderReviewResponse1
 */
export interface GetUnderReviewResponse1 {
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse1
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse1
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetUnderReviewResponse1
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetUnderReviewQuestion>}
   * @memberof GetUnderReviewResponse1
   */
  data?: Array<GetUnderReviewQuestion>;
}
/**
 *
 * @export
 * @interface GetUserActivityResponse
 */
export interface GetUserActivityResponse {
  /**
   *
   * @type {{ [key: string]: GetUserActivitySub; }}
   * @memberof GetUserActivityResponse
   */
  activity?: { [key: string]: GetUserActivitySub };
}
/**
 *
 * @export
 * @interface GetUserActivitySub
 */
export interface GetUserActivitySub {
  /**
   *
   * @type {number}
   * @memberof GetUserActivitySub
   */
  avg_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserActivitySub
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserActivitySub
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserActivitySub
   */
  accuracy?: number;
  /**
   *
   * @type {string}
   * @memberof GetUserActivitySub
   */
  score?: string;
  /**
   *
   * @type {number}
   * @memberof GetUserActivitySub
   */
  total_incorrect?: number;
}
/**
 *
 * @export
 * @interface GetUserCredits
 */
export interface GetUserCredits {
  /**
   *
   * @type {number}
   * @memberof GetUserCredits
   */
  no_of_credits?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserCredits
   */
  balance_gems?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserCredits
   */
  no_of_gems?: number;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  action_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  object_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetUserCredits
   */
  balance_credits?: number;
  /**
   *
   * @type {string}
   * @memberof GetUserCredits
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetUserDetail
 */
export interface GetUserDetail {
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetail
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetUserDetailByEmailResponse
 */
export interface GetUserDetailByEmailResponse {
  /**
   *
   * @type {string}
   * @memberof GetUserDetailByEmailResponse
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetailByEmailResponse
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserDetailByEmailResponse
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface GetUserListGeneralResponse
 */
export interface GetUserListGeneralResponse {
  /**
   *
   * @type {CombinedUserStats}
   * @memberof GetUserListGeneralResponse
   */
  combined_user_stats?: CombinedUserStats;
  /**
   *
   * @type {number}
   * @memberof GetUserListGeneralResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserListGeneralResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserListGeneralResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<UserDetailList>}
   * @memberof GetUserListGeneralResponse
   */
  user_detail_list?: Array<UserDetailList>;
}
/**
 *
 * @export
 * @interface GetUserListResponse
 */
export interface GetUserListResponse {
  /**
   *
   * @type {number}
   * @memberof GetUserListResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserListResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserListResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<UserDetailList>}
   * @memberof GetUserListResponse
   */
  user_detail_list?: Array<UserDetailList>;
}
/**
 *
 * @export
 * @interface GetUserNotificationSettingsResponse
 */
export interface GetUserNotificationSettingsResponse {
  /**
   *
   * @type {Array<GetUserNotificationSettingsSub>}
   * @memberof GetUserNotificationSettingsResponse
   */
  notification_settings?: Array<GetUserNotificationSettingsSub>;
}
/**
 *
 * @export
 * @interface GetUserNotificationSettingsSub
 */
export interface GetUserNotificationSettingsSub {
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  email_notification_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  modified_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetUserNotificationSettingsSub
   */
  is_enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetUserNotificationSettingsSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GetUserResumeAnalysisResponse
 */
export interface GetUserResumeAnalysisResponse {
  /**
   *
   * @type {boolean}
   * @memberof GetUserResumeAnalysisResponse
   */
  success?: boolean;
  /**
   *
   * @type {GetGptResponseHistorySub}
   * @memberof GetUserResumeAnalysisResponse
   */
  analysis?: GetGptResponseHistorySub;
}
/**
 *
 * @export
 * @interface GetUserSkillResponse
 */
export interface GetUserSkillResponse {
  /**
   *
   * @type {number}
   * @memberof GetUserSkillResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserSkillResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof GetUserSkillResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<GetUserSkillSub>}
   * @memberof GetUserSkillResponse
   */
  result?: Array<GetUserSkillSub>;
}
/**
 *
 * @export
 * @interface GetUserSkillSub
 */
export interface GetUserSkillSub {
  /**
   *
   * @type {string}
   * @memberof GetUserSkillSub
   */
  skill_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserSkillSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserSkillSub
   */
  sub_tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserSkillSub
   */
  tag_id?: string;
}
/**
 *
 * @export
 * @interface GetsalesExecutiveListResponse
 */
export interface GetsalesExecutiveListResponse {
  /**
   *
   * @type {number}
   * @memberof GetsalesExecutiveListResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<GetSalesExcutiveSub>}
   * @memberof GetsalesExecutiveListResponse
   */
  sales_executive_list?: Array<GetSalesExcutiveSub>;
  /**
   *
   * @type {number}
   * @memberof GetsalesExecutiveListResponse
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof GetsalesExecutiveListResponse
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface HiredCandidateSub
 */
export interface HiredCandidateSub {
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  fullname?: string;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  job_role?: string;
  /**
   *
   * @type {number}
   * @memberof HiredCandidateSub
   */
  batch?: number;
  /**
   *
   * @type {number}
   * @memberof HiredCandidateSub
   */
  minimum_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  user_picture?: string;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  user_id?: string;
  /**
   *
   * @type {number}
   * @memberof HiredCandidateSub
   */
  maximum_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof HiredCandidateSub
   */
  company_name?: string;
}
/**
 *
 * @export
 * @interface IndividualTag
 */
export interface IndividualTag {
  /**
   *
   * @type {string}
   * @memberof IndividualTag
   */
  tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  total_seconds_spent?: number;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  incorrect_answers?: number;
  /**
   *
   * @type {number}
   * @memberof IndividualTag
   */
  questions_attempted?: number;
  /**
   *
   * @type {string}
   * @memberof IndividualTag
   */
  average_time_total?: string;
}
/**
 *
 * @export
 * @interface InsertAssessmentScoreBody
 */
export interface InsertAssessmentScoreBody {
  /**
   *
   * @type {number}
   * @memberof InsertAssessmentScoreBody
   */
  score?: number | null;
  /**
   *
   * @type {string}
   * @memberof InsertAssessmentScoreBody
   */
  id?: string;
}
/**
 *
 * @export
 * @interface InsertAssessmentScoreResponse
 */
export interface InsertAssessmentScoreResponse {
  /**
   *
   * @type {number}
   * @memberof InsertAssessmentScoreResponse
   */
  assessment_score?: number;
}
/**
 *
 * @export
 * @interface InviteCollegeBody
 */
export interface InviteCollegeBody {
  /**
   *
   * @type {Array<string>}
   * @memberof InviteCollegeBody
   */
  college_id_list?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof InviteCollegeBody
   */
  batches?: Array<string>;
}
/**
 *
 * @export
 * @interface InviteCollegeRepsonse
 */
export interface InviteCollegeRepsonse {
  /**
   *
   * @type {Array<string>}
   * @memberof InviteCollegeRepsonse
   */
  newly_invited_college_ids?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof InviteCollegeRepsonse
   */
  already_invited_college_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface InviteOrgMembersBody
 */
export interface InviteOrgMembersBody {
  /**
   *
   * @type {string}
   * @memberof InviteOrgMembersBody
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof InviteOrgMembersBody
   */
  email?: string;
}
/**
 *
 * @export
 * @interface InviteStudentsBody
 */
export interface InviteStudentsBody {
  /**
   *
   * @type {string}
   * @memberof InviteStudentsBody
   */
  batch?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof InviteStudentsBody
   */
  emails?: Array<string>;
}
/**
 *
 * @export
 * @interface InviteStudentsResponse
 */
export interface InviteStudentsResponse {
  /**
   *
   * @type {number}
   * @memberof InviteStudentsResponse
   */
  total_users_invited?: number;
}
/**
 *
 * @export
 * @interface IsAliasAvailableResponse
 */
export interface IsAliasAvailableResponse {
  /**
   *
   * @type {string}
   * @memberof IsAliasAvailableResponse
   */
  is_alias_available?: string;
}
/**
 *
 * @export
 * @interface IsUserAvailableResponse
 */
export interface IsUserAvailableResponse {
  /**
   *
   * @type {boolean}
   * @memberof IsUserAvailableResponse
   */
  success?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IsUserAvailableResponse
   */
  is_available?: boolean;
}
/**
 *
 * @export
 * @interface JobApplicantResponse
 */
export interface JobApplicantResponse {
  /**
   *
   * @type {Array<AssessmentResponseResponse>}
   * @memberof JobApplicantResponse
   */
  assessment_response?: Array<AssessmentResponseResponse>;
  /**
   *
   * @type {JobApplicantResponseSub}
   * @memberof JobApplicantResponse
   */
  job_applicant?: JobApplicantResponseSub;
}
/**
 *
 * @export
 * @interface JobApplicantResponseSub
 */
export interface JobApplicantResponseSub {
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobApplicantResponseSub
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  hiring_status?: string;
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  modified_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof JobApplicantResponseSub
   */
  is_assessment_seen?: boolean;
  /**
   *
   * @type {string}
   * @memberof JobApplicantResponseSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface JobApplyAssessmentBody
 */
export interface JobApplyAssessmentBody {
  /**
   *
   * @type {string}
   * @memberof JobApplyAssessmentBody
   */
  assessment_id: string;
  /**
   *
   * @type {Array<CreateAssessmentResponsedBody>}
   * @memberof JobApplyAssessmentBody
   */
  assessment_response?: Array<CreateAssessmentResponsedBody>;
}
/**
 *
 * @export
 * @interface JobByIdChandidate
 */
export interface JobByIdChandidate {
  /**
   *
   * @type {GetAssessmentResponse}
   * @memberof JobByIdChandidate
   */
  assessment?: GetAssessmentResponse;
  /**
   *
   * @type {string}
   * @memberof JobByIdChandidate
   */
  org_logo_url?: string;
  /**
   *
   * @type {UpdateJobResponse}
   * @memberof JobByIdChandidate
   */
  job?: UpdateJobResponse;
  /**
   *
   * @type {number}
   * @memberof JobByIdChandidate
   */
  total_applicants?: number;
  /**
   *
   * @type {boolean}
   * @memberof JobByIdChandidate
   */
  is_bookmarked?: boolean;
  /**
   *
   * @type {string}
   * @memberof JobByIdChandidate
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof JobByIdChandidate
   */
  company_name?: string;
}
/**
 *
 * @export
 * @interface JobByIdResponse
 */
export interface JobByIdResponse {
  /**
   *
   * @type {GetJobResponse}
   * @memberof JobByIdResponse
   */
  job?: GetJobResponse;
  /**
   *
   * @type {GetAssessmentResponse}
   * @memberof JobByIdResponse
   */
  job_assessment?: GetAssessmentResponse;
}
/**
 *
 * @export
 * @interface JobDetailsSub
 */
export interface JobDetailsSub {
  /**
   *
   * @type {number}
   * @memberof JobDetailsSub
   */
  minimum_salary?: number;
  /**
   *
   * @type {number}
   * @memberof JobDetailsSub
   */
  no_of_positions?: number;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  org_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  created_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof JobDetailsSub
   */
  required_skills?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof JobDetailsSub
   */
  maximum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof JobDetailsSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface JobGetCandidatesBody
 */
export interface JobGetCandidatesBody {
  /**
   *
   * @type {string}
   * @memberof JobGetCandidatesBody
   */
  keywords?: string;
  /**
   *
   * @type {string}
   * @memberof JobGetCandidatesBody
   */
  filename?: string;
  /**
   *
   * @type {string}
   * @memberof JobGetCandidatesBody
   */
  expanded?: string;
}
/**
 *
 * @export
 * @interface JobGetCandidatesResponse
 */
export interface JobGetCandidatesResponse {
  /**
   *
   * @type {number}
   * @memberof JobGetCandidatesResponse
   */
  words_written?: number;
}
/**
 *
 * @export
 * @interface JobMigrateFilesResponse
 */
export interface JobMigrateFilesResponse {
  /**
   *
   * @type {string}
   * @memberof JobMigrateFilesResponse
   */
  file_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobMigrateFilesResponse
   */
  rows_added?: number;
  /**
   *
   * @type {string}
   * @memberof JobMigrateFilesResponse
   */
  status?: string;
}
/**
 *
 * @export
 * @interface JobNotesResponse
 */
export interface JobNotesResponse {
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  applicant_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof JobNotesResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface JobPostedResponse
 */
export interface JobPostedResponse {
  /**
   *
   * @type {number}
   * @memberof JobPostedResponse
   */
  total_unfiltered_jobs?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedResponse
   */
  page_no?: number;
  /**
   *
   * @type {Array<JobPostedSub>}
   * @memberof JobPostedResponse
   */
  jobs?: Array<JobPostedSub>;
  /**
   *
   * @type {number}
   * @memberof JobPostedResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedResponse
   */
  total_jobs?: number;
}
/**
 *
 * @export
 * @interface JobPostedSub
 */
export interface JobPostedSub {
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  job_role_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_shortlisted?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_applicants?: number;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_resumes_analysed?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof JobPostedSub
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  minimum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_new_applicants?: number;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_unseen_chats?: number;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  job_role_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof JobPostedSub
   */
  is_premium?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JobPostedSub
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JobPostedSub
   */
  is_archieved?: boolean;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  total_hired?: number;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof JobPostedSub
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof JobPostedSub
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  salary_frequency?: string;
  /**
   *
   * @type {number}
   * @memberof JobPostedSub
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof JobPostedSub
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface JobProfileBody
 */
export interface JobProfileBody {
  /**
   *
   * @type {JobProfileProject}
   * @memberof JobProfileBody
   */
  projects?: JobProfileProject;
  /**
   *
   * @type {WorkDetailsBody}
   * @memberof JobProfileBody
   */
  work_details?: WorkDetailsBody;
  /**
   *
   * @type {JobProfileEducationDetails}
   * @memberof JobProfileBody
   */
  education_details?: JobProfileEducationDetails;
  /**
   *
   * @type {Array<JobProfileSocialLink>}
   * @memberof JobProfileBody
   */
  social_links?: Array<JobProfileSocialLink>;
  /**
   *
   * @type {string}
   * @memberof JobProfileBody
   */
  resume_url?: string;
}
/**
 *
 * @export
 * @interface JobProfileEducationDetails
 */
export interface JobProfileEducationDetails {
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  college_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof JobProfileEducationDetails
   */
  is_currently_studying?: boolean;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  batch?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  specialisation?: string;
  /**
   *
   * @type {number}
   * @memberof JobProfileEducationDetails
   */
  grade?: number;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileEducationDetails
   */
  degree?: string;
}
/**
 *
 * @export
 * @interface JobProfileMilestone
 */
export interface JobProfileMilestone {
  /**
   *
   * @type {number}
   * @memberof JobProfileMilestone
   */
  next_milestone_credits?: number;
  /**
   *
   * @type {number}
   * @memberof JobProfileMilestone
   */
  total_fields?: number;
  /**
   *
   * @type {number}
   * @memberof JobProfileMilestone
   */
  percent_completed?: number;
  /**
   *
   * @type {string}
   * @memberof JobProfileMilestone
   */
  next_milestone?: string;
  /**
   *
   * @type {number}
   * @memberof JobProfileMilestone
   */
  total_completed_fields?: number;
}
/**
 *
 * @export
 * @interface JobProfileProject
 */
export interface JobProfileProject {
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  project_link?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  start_date?: string;
  /**
   *
   * @type {number}
   * @memberof JobProfileProject
   */
  serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof JobProfileProject
   */
  type?: string;
}
/**
 *
 * @export
 * @interface JobProfileResponse
 */
export interface JobProfileResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof JobProfileResponse
   */
  completed_job_profile_fields?: Array<string>;
}
/**
 *
 * @export
 * @interface JobProfileSocialLink
 */
export interface JobProfileSocialLink {
  /**
   *
   * @type {string}
   * @memberof JobProfileSocialLink
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof JobProfileSocialLink
   */
  link_type?: string;
}
/**
 *
 * @export
 * @interface JobResumeAnalysisBody
 */
export interface JobResumeAnalysisBody {
  /**
   *
   * @type {string}
   * @memberof JobResumeAnalysisBody
   */
  applicant_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobResumeAnalysisBody
   */
  resume_url: string;
}
/**
 *
 * @export
 * @interface JobReviewSub
 */
export interface JobReviewSub {
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  total_applicants?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof JobReviewSub
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  job_closing_reason?: string;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  alias?: string;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  minimum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  total_new_applicants?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  rejection_reason?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  job_credits?: number;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  gpt_job_description?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof JobReviewSub
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  application_credits?: number;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  is_archieved?: string;
  /**
   *
   * @type {boolean}
   * @memberof JobReviewSub
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JobReviewSub
   */
  is_premium?: boolean;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  additional_details?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  minimum_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  job_description?: string;
  /**
   *
   * @type {OrganizationList}
   * @memberof JobReviewSub
   */
  org_details?: OrganizationList;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  no_of_positions?: number;
  /**
   *
   * @type {ReviewUserResponse}
   * @memberof JobReviewSub
   */
  user_details?: ReviewUserResponse;
  /**
   *
   * @type {number}
   * @memberof JobReviewSub
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof JobReviewSub
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof JobReviewSub
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  salary_frequency?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof JobReviewSub
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface JobRole
 */
export interface JobRole {
  /**
   *
   * @type {string}
   * @memberof JobRole
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof JobRole
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof JobRole
   */
  id?: string;
}
/**
 *
 * @export
 * @interface JobRoleAutocompleteResponse
 */
export interface JobRoleAutocompleteResponse {
  /**
   *
   * @type {number}
   * @memberof JobRoleAutocompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<JobRoleAutocompleteSub>}
   * @memberof JobRoleAutocompleteResponse
   */
  data?: Array<JobRoleAutocompleteSub>;
  /**
   *
   * @type {number}
   * @memberof JobRoleAutocompleteResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof JobRoleAutocompleteResponse
   */
  current_page?: number;
  /**
   *
   * @type {boolean}
   * @memberof JobRoleAutocompleteResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface JobRoleAutocompleteSub
 */
export interface JobRoleAutocompleteSub {
  /**
   *
   * @type {string}
   * @memberof JobRoleAutocompleteSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof JobRoleAutocompleteSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface JobRoleDetailsSub
 */
export interface JobRoleDetailsSub {
  /**
   *
   * @type {string}
   * @memberof JobRoleDetailsSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof JobRoleDetailsSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof JobRoleDetailsSub
   */
  job_role_id?: string;
  /**
   *
   * @type {number}
   * @memberof JobRoleDetailsSub
   */
  candidate_count?: number;
}
/**
 *
 * @export
 * @interface JobRoleSub
 */
export interface JobRoleSub {
  /**
   *
   * @type {string}
   * @memberof JobRoleSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof JobRoleSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof JobRoleSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface LikeDislikeQuestionBody
 */
export interface LikeDislikeQuestionBody {
  /**
   *
   * @type {boolean}
   * @memberof LikeDislikeQuestionBody
   */
  is_liked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LikeDislikeQuestionBody
   */
  is_disliked?: boolean;
}
/**
 *
 * @export
 * @interface LikeDislikeQuestionResponse
 */
export interface LikeDislikeQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof LikeDislikeQuestionResponse
   */
  total_likes?: number;
}
/**
 *
 * @export
 * @interface LinkedQuestionSet
 */
export interface LinkedQuestionSet {
  /**
   *
   * @type {string}
   * @memberof LinkedQuestionSet
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof LinkedQuestionSet
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ListJobResumeAnalysis
 */
export interface ListJobResumeAnalysis {
  /**
   *
   * @type {Array<GetJobResumeAnalysisBase>}
   * @memberof ListJobResumeAnalysis
   */
  all_resume_analysis?: Array<GetJobResumeAnalysisBase>;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysis
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysis
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysis
   */
  total_applicant_count?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysis
   */
  total_unfiltered_applicants?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysis
   */
  page?: number;
}
/**
 *
 * @export
 * @interface ListJobResumeAnalysisBody
 */
export interface ListJobResumeAnalysisBody {
  /**
   *
   * @type {string}
   * @memberof ListJobResumeAnalysisBody
   */
  analysis_status?: string;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  max_rating?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  max_resume_analysis_overall_score?: number;
  /**
   *
   * @type {string}
   * @memberof ListJobResumeAnalysisBody
   */
  search_query?: string;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  min_resume_analysis_skill_score?: number;
  /**
   *
   * @type {string}
   * @memberof ListJobResumeAnalysisBody
   */
  sort_order?: string;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  min_rating?: number;
  /**
   *
   * @type {boolean}
   * @memberof ListJobResumeAnalysisBody
   */
  count_only?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  max_resume_analysis_experience_score?: number;
  /**
   *
   * @type {string}
   * @memberof ListJobResumeAnalysisBody
   */
  sort_by?: string;
  /**
   *
   * @type {string}
   * @memberof ListJobResumeAnalysisBody
   */
  hiring_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ListJobResumeAnalysisBody
   */
  matching_skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  min_resume_analysis_experience_score?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ListJobResumeAnalysisBody
   */
  missing_skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  max_resume_analysis_skill_score?: number;
  /**
   *
   * @type {number}
   * @memberof ListJobResumeAnalysisBody
   */
  min_resume_analysis_overall_score?: number;
}
/**
 *
 * @export
 * @interface LiveCompetition
 */
export interface LiveCompetition {
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  reg_start_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  reg_end_date?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LiveCompetition
   */
  category_list?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetition
   */
  alias?: string;
}
/**
 *
 * @export
 * @interface LiveCompetitionResponse
 */
export interface LiveCompetitionResponse {
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  reg_start_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  reg_end_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof LiveCompetitionResponse
   */
  domain_id?: string;
}
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {Array<string>}
   * @memberof Location
   */
  terms?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  secondary_text?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  created_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  place_id?: string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  id?: string | null;
}
/**
 *
 * @export
 * @interface LoginStreakResponse
 */
export interface LoginStreakResponse {
  /**
   *
   * @type {number}
   * @memberof LoginStreakResponse
   */
  week_streak?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof LoginStreakResponse
   */
  consecutive_logins?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof LoginStreakResponse
   */
  days_streak?: number;
}
/**
 *
 * @export
 * @interface MailLogs
 */
export interface MailLogs {
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  recipient?: string;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  template_name?: string;
  /**
   *
   * @type {number}
   * @memberof MailLogs
   */
  total_count?: number;
  /**
   *
   * @type {object}
   * @memberof MailLogs
   */
  template_args?: object;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  sender?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MailLogs
   */
  attachment_urls?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  email_template_id?: string;
  /**
   *
   * @type {string}
   * @memberof MailLogs
   */
  id?: string;
}
/**
 *
 * @export
 * @interface MailTemplateResponse
 */
export interface MailTemplateResponse {
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  template_name?: string;
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  user_id?: string;
  /**
   *
   * @type {object}
   * @memberof MailTemplateResponse
   */
  template_args?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof MailTemplateResponse
   */
  attachment_urls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  template_body?: string;
  /**
   *
   * @type {string}
   * @memberof MailTemplateResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface MemberAcceptanceBody
 */
export interface MemberAcceptanceBody {
  /**
   *
   * @type {string}
   * @memberof MemberAcceptanceBody
   */
  acceptance_status?: string;
}
/**
 *
 * @export
 * @interface MergeDuplicateEntityBody
 */
export interface MergeDuplicateEntityBody {
  /**
   *
   * @type {Array<string>}
   * @memberof MergeDuplicateEntityBody
   */
  duplicate_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface MergeDuplicateEntityResponse
 */
export interface MergeDuplicateEntityResponse {
  /**
   *
   * @type {boolean}
   * @memberof MergeDuplicateEntityResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface MilestoneMapping
 */
export interface MilestoneMapping {
  /**
   *
   * @type {number}
   * @memberof MilestoneMapping
   */
  gems?: number;
  /**
   *
   * @type {string}
   * @memberof MilestoneMapping
   */
  img_url?: string;
  /**
   *
   * @type {number}
   * @memberof MilestoneMapping
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof MilestoneMapping
   */
  badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof MilestoneMapping
   */
  question_count?: number;
}
/**
 *
 * @export
 * @interface MiniUserDetails
 */
export interface MiniUserDetails {
  /**
   *
   * @type {string}
   * @memberof MiniUserDetails
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetails
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetails
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface MiniUserDetailsSub
 */
export interface MiniUserDetailsSub {
  /**
   *
   * @type {string}
   * @memberof MiniUserDetailsSub
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetailsSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetailsSub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetailsSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof MiniUserDetailsSub
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface MinimalExamSub
 */
export interface MinimalExamSub {
  /**
   *
   * @type {string}
   * @memberof MinimalExamSub
   */
  exam_name?: string;
  /**
   *
   * @type {string}
   * @memberof MinimalExamSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface MinimalTagScore
 */
export interface MinimalTagScore {
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  avg_time_taken_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  avg_time_taken_correct?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  total_skipped_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  badge_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  total_timeout_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  score_rank?: number;
  /**
   *
   * @type {number}
   * @memberof MinimalTagScore
   */
  percent_accuracy?: number;
}
/**
 *
 * @export
 * @interface MockTestAnalyticsResponse
 */
export interface MockTestAnalyticsResponse {
  /**
   *
   * @type {string}
   * @memberof MockTestAnalyticsResponse
   */
  parent_exam_id?: string;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  no_questions?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  unattempted?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  attempted_questions?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  avg_duration?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  current_score?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  cost_in_credits?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  total_correct?: number;
  /**
   *
   * @type {string}
   * @memberof MockTestAnalyticsResponse
   */
  prerequisite?: string;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof MockTestAnalyticsResponse
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  maximum_score?: number;
  /**
   *
   * @type {string}
   * @memberof MockTestAnalyticsResponse
   */
  template_active_until?: string;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  percentile?: number;
  /**
   *
   * @type {boolean}
   * @memberof MockTestAnalyticsResponse
   */
  is_template_active?: boolean;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  skipped?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  timeout?: number;
  /**
   *
   * @type {number}
   * @memberof MockTestAnalyticsResponse
   */
  total_duration?: number;
}
/**
 *
 * @export
 * @interface MockTestUpdateResponse
 */
export interface MockTestUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof MockTestUpdateResponse
   */
  success?: boolean;
  /**
   *
   * @type {CreateMockTestRepsonse}
   * @memberof MockTestUpdateResponse
   */
  new_template?: CreateMockTestRepsonse;
}
/**
 *
 * @export
 * @interface MocktestSub
 */
export interface MocktestSub {
  /**
   *
   * @type {string}
   * @memberof MocktestSub
   */
  exam_id?: string;
  /**
   *
   * @type {number}
   * @memberof MocktestSub
   */
  total_questions?: number;
  /**
   *
   * @type {string}
   * @memberof MocktestSub
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof MocktestSub
   */
  exam_name?: string;
  /**
   *
   * @type {string}
   * @memberof MocktestSub
   */
  exam_type?: string;
  /**
   *
   * @type {string}
   * @memberof MocktestSub
   */
  difficulty_level?: string;
  /**
   *
   * @type {number}
   * @memberof MocktestSub
   */
  exam_duration?: number;
}
/**
 *
 * @export
 * @interface MocktestTagSub
 */
export interface MocktestTagSub {
  /**
   *
   * @type {string}
   * @memberof MocktestTagSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MocktestTagSub
   */
  tag_id?: string;
}
/**
 *
 * @export
 * @interface MonthlyStatsSub
 */
export interface MonthlyStatsSub {
  /**
   *
   * @type {number}
   * @memberof MonthlyStatsSub
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyStatsSub
   */
  question_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyStatsSub
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof MonthlyStatsSub
   */
  accuracy?: number;
}
/**
 *
 * @export
 * @interface MoveSubtagBody
 */
export interface MoveSubtagBody {
  /**
   *
   * @type {string}
   * @memberof MoveSubtagBody
   */
  target_tag_name?: string;
  /**
   *
   * @type {string}
   * @memberof MoveSubtagBody
   */
  sub_tag_name?: string;
}
/**
 *
 * @export
 * @interface NextRewardDetailsSub
 */
export interface NextRewardDetailsSub {
  /**
   *
   * @type {string}
   * @memberof NextRewardDetailsSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof NextRewardDetailsSub
   */
  gems?: number;
  /**
   *
   * @type {number}
   * @memberof NextRewardDetailsSub
   */
  credits?: number;
}
/**
 *
 * @export
 * @interface OnboardingDoneBody
 */
export interface OnboardingDoneBody {
  /**
   *
   * @type {string}
   * @memberof OnboardingDoneBody
   */
  name: string;
}
/**
 *
 * @export
 * @interface OnboardingDoneResponse
 */
export interface OnboardingDoneResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof OnboardingDoneResponse
   */
  data?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof OnboardingDoneResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface OngoingQuestionSub
 */
export interface OngoingQuestionSub {
  /**
   *
   * @type {string}
   * @memberof OngoingQuestionSub
   */
  custom_mocktest_question_num?: string;
  /**
   *
   * @type {string}
   * @memberof OngoingQuestionSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof OngoingQuestionSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface OngoingStatisticsResponse
 */
export interface OngoingStatisticsResponse {
  /**
   *
   * @type {OngoingStatisticsSub}
   * @memberof OngoingStatisticsResponse
   */
  statistics?: OngoingStatisticsSub;
  /**
   *
   * @type {Array<OngoingQuestionSub>}
   * @memberof OngoingStatisticsResponse
   */
  questions?: Array<OngoingQuestionSub>;
}
/**
 *
 * @export
 * @interface OngoingStatisticsSub
 */
export interface OngoingStatisticsSub {
  /**
   *
   * @type {number}
   * @memberof OngoingStatisticsSub
   */
  timeout?: number;
  /**
   *
   * @type {number}
   * @memberof OngoingStatisticsSub
   */
  unattempted?: number;
  /**
   *
   * @type {number}
   * @memberof OngoingStatisticsSub
   */
  attempted?: number;
  /**
   *
   * @type {number}
   * @memberof OngoingStatisticsSub
   */
  skipped?: number;
}
/**
 *
 * @export
 * @interface Option
 */
export interface Option {
  /**
   *
   * @type {number}
   * @memberof Option
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  option_id?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  option_text?: string;
}
/**
 *
 * @export
 * @interface Option1
 */
export interface Option1 {
  /**
   *
   * @type {number}
   * @memberof Option1
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof Option1
   */
  option_id?: string;
  /**
   *
   * @type {string}
   * @memberof Option1
   */
  option_text?: string;
}
/**
 *
 * @export
 * @interface OptionResponse
 */
export interface OptionResponse {
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  question_id?: string;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof OptionResponse
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  modified_on?: string;
  /**
   *
   * @type {CreateQuestionResponse}
   * @memberof OptionResponse
   */
  question?: CreateQuestionResponse;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  option_text?: string;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface OrganizationList
 */
export interface OrganizationList {
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  team_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  verification_doc_url?: string;
  /**
   *
   * @type {Array<OwnerDetailsSub>}
   * @memberof OrganizationList
   */
  owner_details?: Array<OwnerDetailsSub>;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  website_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationList
   */
  id?: string;
}
/**
 *
 * @export
 * @interface OrganizationListSub
 */
export interface OrganizationListSub {
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  team_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  verification_doc_url?: string;
  /**
   *
   * @type {Array<OwnerDetails>}
   * @memberof OrganizationListSub
   */
  owner_details?: Array<OwnerDetails>;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  website_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationListSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  team_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  verification_doc_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  company_size?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  website_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  company_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface Overall
 */
export interface Overall {
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  avg_time_taken_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  avg_time_taken_correct?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  total_skipped_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  badge_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  total_timeout_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  score_rank?: number;
  /**
   *
   * @type {number}
   * @memberof Overall
   */
  percent_accuracy?: number;
}
/**
 *
 * @export
 * @interface OverallMonthstatsSub
 */
export interface OverallMonthstatsSub {
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  avg_time_taken?: number;
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof OverallMonthstatsSub
   */
  total_incorrect?: number;
}
/**
 *
 * @export
 * @interface OverallTagStats
 */
export interface OverallTagStats {
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  total_seconds_spent?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  incorrect_answers?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  questions_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  total_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof OverallTagStats
   */
  average_time_total?: number;
}
/**
 *
 * @export
 * @interface OverallYearStats
 */
export interface OverallYearStats {
  /**
   *
   * @type {{ [key: string]: Data; }}
   * @memberof OverallYearStats
   */
  data?: { [key: string]: Data };
}
/**
 *
 * @export
 * @interface OverallYearstatsSub
 */
export interface OverallYearstatsSub {
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  total_seconds_spent?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  incorrect_answers?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  questions_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  total_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof OverallYearstatsSub
   */
  average_time_total?: number;
}
/**
 *
 * @export
 * @interface OwnerDetails
 */
export interface OwnerDetails {
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetails
   */
  id?: string;
}
/**
 *
 * @export
 * @interface OwnerDetailsSub
 */
export interface OwnerDetailsSub {
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof OwnerDetailsSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface PreferenceSub
 */
export interface PreferenceSub {
  /**
   *
   * @type {number}
   * @memberof PreferenceSub
   */
  difficulty_score?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof PreferenceSub
   */
  is_selected?: boolean;
  /**
   *
   * @type {string}
   * @memberof PreferenceSub
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof PreferenceSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PreferenceSub
   */
  question_count?: number;
  /**
   *
   * @type {string}
   * @memberof PreferenceSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface PrerequisiteStatusSub
 */
export interface PrerequisiteStatusSub {
  /**
   *
   * @type {number}
   * @memberof PrerequisiteStatusSub
   */
  remaining_question_attempts?: number;
  /**
   *
   * @type {number}
   * @memberof PrerequisiteStatusSub
   */
  attempted_question_count?: number;
}
/**
 *
 * @export
 * @interface ProfilePictureResponse
 */
export interface ProfilePictureResponse {
  /**
   *
   * @type {string}
   * @memberof ProfilePictureResponse
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface ProgressObj
 */
export interface ProgressObj {
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_day_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  total_correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  total_badges?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  overall_score?: number;
  /**
   *
   * @type {Generated}
   * @memberof ProgressObj
   */
  tag_level?: Generated;
  /**
   *
   * @type {string}
   * @memberof ProgressObj
   */
  current_day_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_tag_score?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_tag_total_attempts?: number;
  /**
   *
   * @type {Generated1}
   * @memberof ProgressObj
   */
  domain_level?: Generated1;
  /**
   *
   * @type {boolean}
   * @memberof ProgressObj
   */
  is_streak_save_possible?: boolean;
  /**
   *
   * @type {Array<object>}
   * @memberof ProgressObj
   */
  badge_win_by_this_attempt?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof ProgressObj
   */
  tag_level_changed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProgressObj
   */
  domain_level_changed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_streak_target?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  streak_save_cost?: number;
  /**
   *
   * @type {string}
   * @memberof ProgressObj
   */
  current_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof ProgressObj
   */
  current_day_streak_target?: number;
}
/**
 *
 * @export
 * @interface ProgressObjSub
 */
export interface ProgressObjSub {
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_day_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  total_correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  total_badges?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_tag_total_questions?: number;
  /**
   *
   * @type {string}
   * @memberof ProgressObjSub
   */
  current_day_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_tag_score?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_tag_total_attempts?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProgressObjSub
   */
  is_streak_save_possible?: boolean;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_streak_count?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_streak_target?: number;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  streak_save_cost?: number;
  /**
   *
   * @type {string}
   * @memberof ProgressObjSub
   */
  current_streak_badge_name?: string;
  /**
   *
   * @type {number}
   * @memberof ProgressObjSub
   */
  current_day_streak_target?: number;
}
/**
 *
 * @export
 * @interface ProjectsDetail
 */
export interface ProjectsDetail {
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  project_link?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  user_id?: string;
  /**
   *
   * @type {number}
   * @memberof ProjectsDetail
   */
  serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof ProjectsDetail
   */
  id?: string;
}
/**
 *
 * @export
 * @interface PublicProfileAnalyticsResponse
 */
export interface PublicProfileAnalyticsResponse {
  /**
   *
   * @type {DailyScoreResponseSub}
   * @memberof PublicProfileAnalyticsResponse
   */
  daily_statistics?: DailyScoreResponseSub;
  /**
   *
   * @type {boolean}
   * @memberof PublicProfileAnalyticsResponse
   */
  success?: boolean;
  /**
   *
   * @type {UserSub}
   * @memberof PublicProfileAnalyticsResponse
   */
  user?: UserSub;
  /**
   *
   * @type {AnalyticsResponseSub}
   * @memberof PublicProfileAnalyticsResponse
   */
  analytics?: AnalyticsResponseSub;
  /**
   *
   * @type {Array<CreatorStatsRefinedSub>}
   * @memberof PublicProfileAnalyticsResponse
   */
  creator_stats_refined?: Array<CreatorStatsRefinedSub>;
  /**
   *
   * @type {CreatorStatsResponseSub}
   * @memberof PublicProfileAnalyticsResponse
   */
  creator_stats?: CreatorStatsResponseSub;
}
/**
 *
 * @export
 * @interface PublicQuestion
 */
export interface PublicQuestion {
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  total_bookmarks?: number | null;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  negative_score?: number;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  next_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {Array<Option>}
   * @memberof PublicQuestion
   */
  options?: Array<Option>;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  total_solution_likes?: number;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  credits?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PublicQuestion
   */
  domain_ids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  no_options?: number;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  question_source_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PublicQuestion
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof PublicQuestion
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  question_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PublicQuestion
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PublicQuestion
   */
  is_deleted?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  exam_type?: string | null;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PublicQuestion
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  year?: string | null;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof PublicQuestion
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {number}
   * @memberof PublicQuestion
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PublicQuestion
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   *
   * @type {number}
   * @memberof Question
   */
  total_bookmarks?: number;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof Question
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  quality_score?: number | null;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  total_dislikes?: number;
  /**
   *
   * @type {Array<Option1>}
   * @memberof Question
   */
  options?: Array<Option1>;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  attempt_accuracy?: number | null;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  total_solution_likes?: number;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  attempt_rate?: number | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  created_by?: string;
  /**
   *
   * @type {Solution1}
   * @memberof Question
   */
  solution?: Solution1;
  /**
   *
   * @type {object}
   * @memberof Question
   */
  similars?: object;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  correct_attempt_avg_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  is_credit_added?: boolean;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Question
   */
  answer_one_word?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  question_attempts?: number | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_source_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Question
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {UserData}
   * @memberof Question
   */
  user_data?: UserData;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  completed_attempt_avg_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  exam_type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Question
   */
  is_deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  sub_question_number?: string | null;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Question
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  year?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  external_exam?: string | null;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  multipart_question_count?: number;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface QuestionCreationMilestoneResponse
 */
export interface QuestionCreationMilestoneResponse {
  /**
   *
   * @type {number}
   * @memberof QuestionCreationMilestoneResponse
   */
  next_milestone_credits?: number;
  /**
   *
   * @type {Array<MilestoneMapping>}
   * @memberof QuestionCreationMilestoneResponse
   */
  milestone_mapping?: Array<MilestoneMapping>;
  /**
   *
   * @type {number}
   * @memberof QuestionCreationMilestoneResponse
   */
  next_milestone_gems?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCreationMilestoneResponse
   */
  next_milestone?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCreationMilestoneResponse
   */
  question_created?: number;
}
/**
 *
 * @export
 * @interface QuestionDetailsSub
 */
export interface QuestionDetailsSub {
  /**
   *
   * @type {string}
   * @memberof QuestionDetailsSub
   */
  question_id?: string;
  /**
   *
   * @type {GetAllCommentUser}
   * @memberof QuestionDetailsSub
   */
  question_author?: GetAllCommentUser;
  /**
   *
   * @type {string}
   * @memberof QuestionDetailsSub
   */
  question_text?: string;
}
/**
 *
 * @export
 * @interface QuestionOptionsSub
 */
export interface QuestionOptionsSub {
  /**
   *
   * @type {number}
   * @memberof QuestionOptionsSub
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof QuestionOptionsSub
   */
  option_id?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionOptionsSub
   */
  option_text?: string;
}
/**
 *
 * @export
 * @interface QuestionPublicAttemptBody
 */
export interface QuestionPublicAttemptBody {
  /**
   *
   * @type {Array<QuestionPublicAttemptSub>}
   * @memberof QuestionPublicAttemptBody
   */
  public_attempt?: Array<QuestionPublicAttemptSub>;
}
/**
 *
 * @export
 * @interface QuestionPublicAttemptResponse
 */
export interface QuestionPublicAttemptResponse {
  /**
   *
   * @type {number}
   * @memberof QuestionPublicAttemptResponse
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionPublicAttemptResponse
   */
  total_score?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionPublicAttemptResponse
   */
  total_correct?: number;
}
/**
 *
 * @export
 * @interface QuestionPublicAttemptSub
 */
export interface QuestionPublicAttemptSub {
  /**
   *
   * @type {string}
   * @memberof QuestionPublicAttemptSub
   */
  answer_one_word?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof QuestionPublicAttemptSub
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof QuestionPublicAttemptSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface QuestionStats
 */
export interface QuestionStats {
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_timeout?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_questions?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  avg_accuracy?: number;
  /**
   *
   * @type {string}
   * @memberof QuestionStats
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_correct?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionStats
   */
  total_skipped?: number;
}
/**
 *
 * @export
 * @interface QuestionWiseReport
 */
export interface QuestionWiseReport {
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  total_bookmarks?: number;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof QuestionWiseReport
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  quality_score?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionWiseReport
   */
  second_similar?: Array<string> | null;
  /**
   *
   * @type {Array<object>}
   * @memberof QuestionWiseReport
   */
  options?: Array<object> | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  attempt_accuracy?: number | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  total_dislikes?: number | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  total_solution_likes?: number;
  /**
   *
   * @type {Array<Report>}
   * @memberof QuestionWiseReport
   */
  reports?: Array<Report>;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  attempt_rate?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  created_by?: string;
  /**
   *
   * @type {object}
   * @memberof QuestionWiseReport
   */
  solution?: object;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  correct_attempt_avg_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof QuestionWiseReport
   */
  is_credit_added?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  solution_text?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  tag_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionWiseReport
   */
  answer_one_word?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  question_attempts?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionWiseReport
   */
  first_similar?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof QuestionWiseReport
   */
  is_solution_available?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  total_likes?: number | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof QuestionWiseReport
   */
  is_hint_available?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionWiseReport
   */
  sub_tag_array?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof QuestionWiseReport
   */
  checked_incorrect_option?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  completed_attempt_avg_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  exam_type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof QuestionWiseReport
   */
  is_deleted?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  sub_question_number?: string | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof QuestionWiseReport
   */
  tag_array?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  year?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  external_exam?: string | null;
  /**
   *
   * @type {number}
   * @memberof QuestionWiseReport
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof QuestionWiseReport
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface RecommendedMockTests
 */
export interface RecommendedMockTests {
  /**
   *
   * @type {boolean}
   * @memberof RecommendedMockTests
   */
  is_template_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  image_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof RecommendedMockTests
   */
  no_questions?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof RecommendedMockTests
   */
  tag_weightages: Array<number>;
  /**
   *
   * @type {number}
   * @memberof RecommendedMockTests
   */
  difficulty_level?: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof RecommendedMockTests
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {boolean}
   * @memberof RecommendedMockTests
   */
  is_unlocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  name?: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof RecommendedMockTests
   */
  prerequisite?: { [key: string]: number };
  /**
   *
   * @type {boolean}
   * @memberof RecommendedMockTests
   */
  is_template?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RecommendedMockTests
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  template_active_until?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  exam_mode?: string;
  /**
   *
   * @type {number}
   * @memberof RecommendedMockTests
   */
  cost_in_credits?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedMockTests
   */
  id?: string;
}
/**
 *
 * @export
 * @interface RecommendedTags
 */
export interface RecommendedTags {
  /**
   *
   * @type {number}
   * @memberof RecommendedTags
   */
  category_unlock_credits?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendedTags
   */
  total_tag_questions?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendedTags
   */
  unlocked_until?: string | null;
  /**
   *
   * @type {number}
   * @memberof RecommendedTags
   */
  user_tag_level?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendedTags
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof RecommendedTags
   */
  number_of_subtags?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendedTags
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof RecommendedTags
   */
  total_question_solved?: number;
}
/**
 *
 * @export
 * @interface RelatedTopic
 */
export interface RelatedTopic {
  /**
   *
   * @type {string}
   * @memberof RelatedTopic
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof RelatedTopic
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof RelatedTopic
   */
  tag_image?: string;
}
/**
 *
 * @export
 * @interface RepairOptionSerialNoResponse
 */
export interface RepairOptionSerialNoResponse {
  /**
   *
   * @type {number}
   * @memberof RepairOptionSerialNoResponse
   */
  updates?: number;
  /**
   *
   * @type {boolean}
   * @memberof RepairOptionSerialNoResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface Report
 */
export interface Report {
  /**
   *
   * @type {Question}
   * @memberof Report
   */
  original_question?: Question;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  qus_creator_id?: string;
  /**
   *
   * @type {number}
   * @memberof Report
   */
  report_reviews?: number;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  original_text?: string | null;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  reason_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  correct_text?: string | null;
  /**
   *
   * @type {CreatorDetails}
   * @memberof Report
   */
  creator_details?: CreatorDetails;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  id?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Report
   */
  correct_values?: Array<number> | null;
  /**
   *
   * @type {CreatorDetails}
   * @memberof Report
   */
  reporter_details?: CreatorDetails;
  /**
   *
   * @type {Array<number>}
   * @memberof Report
   */
  original_values?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  autoresolve_failure?: boolean;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  tag_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  is_new?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  voting_open?: boolean;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  question_id?: string;
  /**
   *
   * @type {number}
   * @memberof Report
   */
  pct_agreed?: number;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  reference_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  element_changed?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof Report
   */
  correct_ids?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  is_resolved?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Report
   */
  is_automatic?: boolean;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  user_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Report
   */
  original_ids?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof Report
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface ReportQuestionBody
 */
export interface ReportQuestionBody {
  /**
   *
   * @type {Array<number>}
   * @memberof ReportQuestionBody
   */
  correct_values?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody
   */
  reference_url?: string;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody
   */
  reason_type?: string;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody
   */
  correct_text?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReportQuestionBody
   */
  correct_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface ReportQuestionBody1
 */
export interface ReportQuestionBody1 {
  /**
   *
   * @type {Array<number>}
   * @memberof ReportQuestionBody1
   */
  correct_values?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody1
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody1
   */
  reason_type?: string;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody1
   */
  correct_text?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReportQuestionBody1
   */
  correct_ids?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ReportQuestionBody1
   */
  correct_title?: string;
}
/**
 *
 * @export
 * @interface RequiredSkills
 */
export interface RequiredSkills {
  /**
   *
   * @type {string}
   * @memberof RequiredSkills
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RequiredSkills
   */
  id?: string;
}
/**
 *
 * @export
 * @interface RequiredSkillsFilter
 */
export interface RequiredSkillsFilter {
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  min_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  max_total_questions_solved?: number;
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  min_total_questions_solved?: number;
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  max_accuracy?: number;
  /**
   *
   * @type {string}
   * @memberof RequiredSkillsFilter
   */
  skill_id?: string;
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  min_level?: number;
  /**
   *
   * @type {number}
   * @memberof RequiredSkillsFilter
   */
  max_level?: number;
}
/**
 *
 * @export
 * @interface ResetPasswordBody
 */
export interface ResetPasswordBody {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordBody
   */
  new_password?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordBody
   */
  otp?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordBody
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface ResponseAndField
 */
export interface ResponseAndField {
  /**
   *
   * @type {AssessmentResponseResponse}
   * @memberof ResponseAndField
   */
  response?: AssessmentResponseResponse;
  /**
   *
   * @type {AssessmentField}
   * @memberof ResponseAndField
   */
  field?: AssessmentField;
}
/**
 *
 * @export
 * @interface ResumeAnalysisBody
 */
export interface ResumeAnalysisBody {
  /**
   *
   * @type {string}
   * @memberof ResumeAnalysisBody
   */
  resume_url: string;
}
/**
 *
 * @export
 * @interface ResumeAnalysisResponse
 */
export interface ResumeAnalysisResponse {
  /**
   *
   * @type {boolean}
   * @memberof ResumeAnalysisResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ResumeAnalysisResponse
   */
  analysis_id?: string;
}
/**
 *
 * @export
 * @interface ResumeDetails
 */
export interface ResumeDetails {
  /**
   *
   * @type {Array<ProjectsDetail>}
   * @memberof ResumeDetails
   */
  projects?: Array<ProjectsDetail>;
  /**
   *
   * @type {ResumeUserDetailSub}
   * @memberof ResumeDetails
   */
  user_details?: ResumeUserDetailSub;
  /**
   *
   * @type {Array<ExperienceDetails>}
   * @memberof ResumeDetails
   */
  work_details?: Array<ExperienceDetails>;
  /**
   *
   * @type {Array<EducationDetails>}
   * @memberof ResumeDetails
   */
  education_details?: Array<EducationDetails>;
  /**
   *
   * @type {Array<SocialLinks>}
   * @memberof ResumeDetails
   */
  social_links?: Array<SocialLinks>;
  /**
   *
   * @type {CandidateMetaDetails}
   * @memberof ResumeDetails
   */
  candidate_meta_details?: CandidateMetaDetails;
  /**
   *
   * @type {number}
   * @memberof ResumeDetails
   */
  profile_completion_percent?: number;
}
/**
 *
 * @export
 * @interface ResumeEducationSub
 */
export interface ResumeEducationSub {
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  specialization_id?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  roll_no?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  degree_name?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  verification_doc_url?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  specialization_name?: string;
  /**
   *
   * @type {number}
   * @memberof ResumeEducationSub
   */
  batch?: number;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  grade?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  college_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ResumeEducationSub
   */
  is_currently_studying?: boolean;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  start_date?: string;
  /**
   *
   * @type {Location}
   * @memberof ResumeEducationSub
   */
  location_details?: Location;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  college_image_url?: string;
  /**
   *
   * @type {boolean}
   * @memberof ResumeEducationSub
   */
  is_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof ResumeEducationSub
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface ResumeResponse
 */
export interface ResumeResponse {
  /**
   *
   * @type {Array<ProjectsDetail>}
   * @memberof ResumeResponse
   */
  projects?: Array<ProjectsDetail>;
  /**
   *
   * @type {ResumeUserDetailsSub}
   * @memberof ResumeResponse
   */
  user_details?: ResumeUserDetailsSub;
  /**
   *
   * @type {Array<WorkExperienceSub>}
   * @memberof ResumeResponse
   */
  work_details?: Array<WorkExperienceSub>;
  /**
   *
   * @type {Array<ResumeEducationSub>}
   * @memberof ResumeResponse
   */
  education_details?: Array<ResumeEducationSub>;
  /**
   *
   * @type {Array<SocialLinksResponse>}
   * @memberof ResumeResponse
   */
  social_links?: Array<SocialLinksResponse>;
  /**
   *
   * @type {CandidateMetaDetails}
   * @memberof ResumeResponse
   */
  candidate_meta_details?: CandidateMetaDetails;
  /**
   *
   * @type {number}
   * @memberof ResumeResponse
   */
  profile_completion_percent?: number;
}
/**
 *
 * @export
 * @interface ResumeUserDetailSub
 */
export interface ResumeUserDetailSub {
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ResumeUserDetailsSub
 */
export interface ResumeUserDetailsSub {
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ResumeUserDetailsSub
   */
  last_name?: string;
}
/**
 *
 * @export
 * @interface ReviewJobResponse
 */
export interface ReviewJobResponse {
  /**
   *
   * @type {number}
   * @memberof ReviewJobResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<JobReviewSub>}
   * @memberof ReviewJobResponse
   */
  jobs?: Array<JobReviewSub>;
  /**
   *
   * @type {number}
   * @memberof ReviewJobResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof ReviewJobResponse
   */
  total_pages?: number;
}
/**
 *
 * @export
 * @interface ReviewOrganizationResponse
 */
export interface ReviewOrganizationResponse {
  /**
   *
   * @type {number}
   * @memberof ReviewOrganizationResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof ReviewOrganizationResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ReviewOrganizationResponse
   */
  page?: number;
  /**
   *
   * @type {Array<OrganizationListSub>}
   * @memberof ReviewOrganizationResponse
   */
  org_list?: Array<OrganizationListSub>;
}
/**
 *
 * @export
 * @interface ReviewReportedQuestionBody
 */
export interface ReviewReportedQuestionBody {
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionBody
   */
  report_id?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionBody
   */
  review_type?: string;
}
/**
 *
 * @export
 * @interface ReviewReportedQuestionBody1
 */
export interface ReviewReportedQuestionBody1 {
  /**
   *
   * @type {Array<string>}
   * @memberof ReviewReportedQuestionBody1
   */
  question_id?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionBody1
   */
  review_type?: string;
}
/**
 *
 * @export
 * @interface ReviewReportedQuestionResponse
 */
export interface ReviewReportedQuestionResponse {
  /**
   *
   * @type {boolean}
   * @memberof ReviewReportedQuestionResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionResponse
   */
  resolution?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionResponse
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionResponse
   */
  pct_agreed?: string;
}
/**
 *
 * @export
 * @interface ReviewReportedQuestionResponse1
 */
export interface ReviewReportedQuestionResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof ReviewReportedQuestionResponse1
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReviewReportedQuestionResponse1
   */
  resolution?: string;
}
/**
 *
 * @export
 * @interface ReviewTestApiBody
 */
export interface ReviewTestApiBody {
  /**
   *
   * @type {number}
   * @memberof ReviewTestApiBody
   */
  units_consumed?: number;
  /**
   *
   * @type {string}
   * @memberof ReviewTestApiBody
   */
  object_name?: string;
}
/**
 *
 * @export
 * @interface ReviewUserResponse
 */
export interface ReviewUserResponse {
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReviewUserResponse
   */
  is_phone_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewUserResponse
   */
  country?: string;
}
/**
 *
 * @export
 * @interface RoleChangeBody
 */
export interface RoleChangeBody {
  /**
   *
   * @type {string}
   * @memberof RoleChangeBody
   */
  user_role?: string;
  /**
   *
   * @type {string}
   * @memberof RoleChangeBody
   */
  member_user_id?: string;
}
/**
 *
 * @export
 * @interface SalesLeadSignUpResponse
 */
export interface SalesLeadSignUpResponse {
  /**
   *
   * @type {string}
   * @memberof SalesLeadSignUpResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof SalesLeadSignUpResponse
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ScheduledMail
 */
export interface ScheduledMail {
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  scheduled_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  subject?: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  recipient?: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  template_name?: string;
  /**
   *
   * @type {number}
   * @memberof ScheduledMail
   */
  total_count?: number;
  /**
   *
   * @type {object}
   * @memberof ScheduledMail
   */
  template_args?: object;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  sender?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ScheduledMail
   */
  attachment_urls?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  email_template_id?: string;
  /**
   *
   * @type {string}
   * @memberof ScheduledMail
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ScheduledMailsResponse
 */
export interface ScheduledMailsResponse {
  /**
   *
   * @type {number}
   * @memberof ScheduledMailsResponse
   */
  page_no?: number;
  /**
   *
   * @type {number}
   * @memberof ScheduledMailsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof ScheduledMailsResponse
   */
  page_size?: number;
  /**
   *
   * @type {Array<ScheduledMail>}
   * @memberof ScheduledMailsResponse
   */
  scheduled_mails?: Array<ScheduledMail>;
}
/**
 *
 * @export
 * @interface ScoreDetailsResponse
 */
export interface ScoreDetailsResponse {
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  current_streak?: number;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  created_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof ScoreDetailsResponse
   */
  is_streak_save_possible?: boolean;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  score_rank?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  percent_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  user_level?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  avg_time_taken_correct?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  total_question_count?: number;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  score_type?: string;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  last_streak?: number;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  avg_time_taken_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  total_skipped_count?: number;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  time_period?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDetailsResponse
   */
  user_id?: string;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreDetailsResponse
   */
  total_timeout_count?: number;
}
/**
 *
 * @export
 * @interface SendCertificateBody
 */
export interface SendCertificateBody {
  /**
   *
   * @type {string}
   * @memberof SendCertificateBody
   */
  competition_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SendCertificateBody
   */
  user_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface SendEmailOtpBody
 */
export interface SendEmailOtpBody {
  /**
   *
   * @type {string}
   * @memberof SendEmailOtpBody
   */
  email?: string;
}
/**
 *
 * @export
 * @interface SendEmailOtpRepsonse
 */
export interface SendEmailOtpRepsonse {
  /**
   *
   * @type {string}
   * @memberof SendEmailOtpRepsonse
   */
  messsage?: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailOtpRepsonse
   */
  email?: string;
}
/**
 *
 * @export
 * @interface SendOtpBody
 */
export interface SendOtpBody {
  /**
   *
   * @type {string}
   * @memberof SendOtpBody
   */
  entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof SendOtpBody
   */
  phone_no?: string;
  /**
   *
   * @type {string}
   * @memberof SendOtpBody
   */
  entity_id?: string;
}
/**
 *
 * @export
 * @interface SendResetPasswordResponse
 */
export interface SendResetPasswordResponse {
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordResponse
   */
  messsage?: string;
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordResponse
   */
  email?: string;
}
/**
 *
 * @export
 * @interface SentEmailBody
 */
export interface SentEmailBody {
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  scheduled_datetime?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SentEmailBody
   */
  new_user_only?: boolean;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  subject: string;
  /**
   *
   * @type {boolean}
   * @memberof SentEmailBody
   */
  existing_users_only?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof SentEmailBody
   */
  attachment_s3_urls?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SentEmailBody
   */
  email_list?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SentEmailBody
   */
  exclude_hours?: number | null;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  custom_template?: string | null;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  password: string;
  /**
   *
   * @type {object}
   * @memberof SentEmailBody
   */
  template_args?: object;
  /**
   *
   * @type {boolean}
   * @memberof SentEmailBody
   */
  is_competition_completed?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SentEmailBody
   */
  exclude_list?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  from_email?: string;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  competition_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SentEmailBody
   */
  email_counts_only?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof SentEmailBody
   */
  domain_id?: string | null;
}
/**
 *
 * @export
 * @interface SentEmailResponse
 */
export interface SentEmailResponse {
  /**
   *
   * @type {number}
   * @memberof SentEmailResponse
   */
  total_email_sent?: number;
  /**
   *
   * @type {boolean}
   * @memberof SentEmailResponse
   */
  success?: boolean;
  /**
   *
   * @type {number}
   * @memberof SentEmailResponse
   */
  email_count?: number;
}
/**
 *
 * @export
 * @interface SetNewPasswordBody
 */
export interface SetNewPasswordBody {
  /**
   *
   * @type {string}
   * @memberof SetNewPasswordBody
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof SetNewPasswordBody
   */
  password_hash: string;
}
/**
 *
 * @export
 * @interface Skills
 */
export interface Skills {
  /**
   *
   * @type {string}
   * @memberof Skills
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Skills
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof Skills
   */
  id?: string;
}
/**
 *
 * @export
 * @interface SocialLinks
 */
export interface SocialLinks {
  /**
   *
   * @type {string}
   * @memberof SocialLinks
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinks
   */
  link_type?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinks
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinks
   */
  id?: string;
}
/**
 *
 * @export
 * @interface SocialLinksBody
 */
export interface SocialLinksBody {
  /**
   *
   * @type {string}
   * @memberof SocialLinksBody
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinksBody
   */
  link_type: string;
}
/**
 *
 * @export
 * @interface SocialLinksBody1
 */
export interface SocialLinksBody1 {
  /**
   *
   * @type {string}
   * @memberof SocialLinksBody1
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinksBody1
   */
  linkedin_url?: string;
}
/**
 *
 * @export
 * @interface SocialLinksResponse
 */
export interface SocialLinksResponse {
  /**
   *
   * @type {string}
   * @memberof SocialLinksResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinksResponse
   */
  link_type?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinksResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof SocialLinksResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface Solution
 */
export interface Solution {
  /**
   *
   * @type {string}
   * @memberof Solution
   */
  solution_text?: string;
}
/**
 *
 * @export
 * @interface Solution1
 */
export interface Solution1 {
  /**
   *
   * @type {string}
   * @memberof Solution1
   */
  solution_text?: string;
}
/**
 *
 * @export
 * @interface SolutionRatingBody
 */
export interface SolutionRatingBody {
  /**
   *
   * @type {number}
   * @memberof SolutionRatingBody
   */
  solution_rating?: number | null;
}
/**
 *
 * @export
 * @interface SolutionRatingResponse
 */
export interface SolutionRatingResponse {
  /**
   *
   * @type {number}
   * @memberof SolutionRatingResponse
   */
  solution_average_rating?: number;
  /**
   *
   * @type {number}
   * @memberof SolutionRatingResponse
   */
  solution_num_ratings?: number;
}
/**
 *
 * @export
 * @interface SortBy
 */
export interface SortBy {
  /**
   *
   * @type {string}
   * @memberof SortBy
   */
  sort_by?: string;
  /**
   *
   * @type {string}
   * @memberof SortBy
   */
  sort_order?: string;
}
/**
 *
 * @export
 * @interface SpecializationAutocompleteResponse
 */
export interface SpecializationAutocompleteResponse {
  /**
   *
   * @type {number}
   * @memberof SpecializationAutocompleteResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<SpecializationAutocompleteSub>}
   * @memberof SpecializationAutocompleteResponse
   */
  data?: Array<SpecializationAutocompleteSub>;
  /**
   *
   * @type {number}
   * @memberof SpecializationAutocompleteResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof SpecializationAutocompleteResponse
   */
  current_page?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpecializationAutocompleteResponse
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface SpecializationAutocompleteSub
 */
export interface SpecializationAutocompleteSub {
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationAutocompleteSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface SpecializationDetailsSub
 */
export interface SpecializationDetailsSub {
  /**
   *
   * @type {string}
   * @memberof SpecializationDetailsSub
   */
  specialization_id?: string;
  /**
   *
   * @type {number}
   * @memberof SpecializationDetailsSub
   */
  candidate_count?: number;
  /**
   *
   * @type {string}
   * @memberof SpecializationDetailsSub
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationDetailsSub
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof SpecializationDetailsSub
   */
  name?: string;
}
/**
 *
 * @export
 * @interface StartStatisticsRepsonse
 */
export interface StartStatisticsRepsonse {
  /**
   *
   * @type {number}
   * @memberof StartStatisticsRepsonse
   */
  total_questions?: number;
  /**
   *
   * @type {number}
   * @memberof StartStatisticsRepsonse
   */
  total_questions_attempted?: number;
  /**
   *
   * @type {Array<MocktestTagSub>}
   * @memberof StartStatisticsRepsonse
   */
  all_mocktest_tags?: Array<MocktestTagSub>;
}
/**
 *
 * @export
 * @interface StatisticCardsResponse
 */
export interface StatisticCardsResponse {
  /**
   *
   * @type {number}
   * @memberof StatisticCardsResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof StatisticCardsResponse
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof StatisticCardsResponse
   */
  total_pages?: number;
  /**
   *
   * @type {Array<CardSub>}
   * @memberof StatisticCardsResponse
   */
  all_cards?: Array<CardSub>;
}
/**
 *
 * @export
 * @interface StudentActivityResponse
 */
export interface StudentActivityResponse {
  /**
   *
   * @type {number}
   * @memberof StudentActivityResponse
   */
  page_no?: number;
  /**
   *
   * @type {Array<UserActivitySub>}
   * @memberof StudentActivityResponse
   */
  activities?: Array<UserActivitySub>;
  /**
   *
   * @type {number}
   * @memberof StudentActivityResponse
   */
  total_pages?: number;
  /**
   *
   * @type {number}
   * @memberof StudentActivityResponse
   */
  page_size?: number;
}
/**
 *
 * @export
 * @interface StudentJobSub
 */
export interface StudentJobSub {
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  org_logo?: string;
  /**
   *
   * @type {number}
   * @memberof StudentJobSub
   */
  minimum_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  role_name?: string;
  /**
   *
   * @type {number}
   * @memberof StudentJobSub
   */
  maximum_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof StudentJobSub
   */
  company_name?: string;
}
/**
 *
 * @export
 * @interface StudentLeaderboardResponse
 */
export interface StudentLeaderboardResponse {
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardResponse
   */
  total_pages?: number;
  /**
   *
   * @type {StudentLeaderboardSub}
   * @memberof StudentLeaderboardResponse
   */
  curr_user_rank_details?: StudentLeaderboardSub;
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardResponse
   */
  page_size?: number;
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardResponse
   */
  page?: number;
  /**
   *
   * @type {Array<StudentLeaderboardSub>}
   * @memberof StudentLeaderboardResponse
   */
  student_leaderboard?: Array<StudentLeaderboardSub>;
}
/**
 *
 * @export
 * @interface StudentLeaderboardSub
 */
export interface StudentLeaderboardSub {
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardSub
   */
  user_level?: number;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardSub
   */
  batch?: string;
  /**
   *
   * @type {StudentLeaderboardUser}
   * @memberof StudentLeaderboardSub
   */
  user?: StudentLeaderboardUser;
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardSub
   */
  score?: number;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardSub
   */
  strong_tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardSub
   */
  rank?: number;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardSub
   */
  strong_tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof StudentLeaderboardSub
   */
  percent_accuracy?: number;
}
/**
 *
 * @export
 * @interface StudentLeaderboardUser
 */
export interface StudentLeaderboardUser {
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof StudentLeaderboardUser
   */
  country?: string;
}
/**
 *
 * @export
 * @interface SubQuestion
 */
export interface SubQuestion {
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  total_bookmarks?: number | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  next_question_id?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof SubQuestion
   */
  answer_option_no?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  quality_score?: string | null;
  /**
   *
   * @type {Array<object>}
   * @memberof SubQuestion
   */
  second_similar?: Array<object>;
  /**
   *
   * @type {Array<Option>}
   * @memberof SubQuestion
   */
  options?: Array<Option>;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  attempt_accuracy?: string | null;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  total_solution_likes?: number;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  attempt_rate?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  created_by?: string;
  /**
   *
   * @type {Solution}
   * @memberof SubQuestion
   */
  solution?: Solution;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  correct_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  is_credit_added?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  maxtime_passes?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SubQuestion
   */
  answer_one_word?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_attempts?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<object>}
   * @memberof SubQuestion
   */
  first_similar?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof SubQuestion
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof SubQuestion
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SubQuestion
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  max_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  exam_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SubQuestion
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  completed_attempt_avg_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  exam_type?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SubQuestion
   */
  is_deleted?: boolean;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SubQuestion
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  year?: number | null;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof SubQuestion
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {number}
   * @memberof SubQuestion
   */
  multipart_question_count?: number;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof SubQuestion
   */
  modified_on?: string | null;
}
/**
 *
 * @export
 * @interface SubtagMinimalSub
 */
export interface SubtagMinimalSub {
  /**
   *
   * @type {string}
   * @memberof SubtagMinimalSub
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SubtagMinimalSub
   */
  difficulty_score?: number;
  /**
   *
   * @type {string}
   * @memberof SubtagMinimalSub
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof SubtagMinimalSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface SubtagPreferenceBody
 */
export interface SubtagPreferenceBody {
  /**
   *
   * @type {string}
   * @memberof SubtagPreferenceBody
   */
  sub_tag_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubtagPreferenceBody
   */
  is_selected?: boolean;
}
/**
 *
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
  /**
   *
   * @type {boolean}
   * @memberof SuccessResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof SuccessResponse
   */
  already_analysed_applicant_ids?: Array<string>;
}
/**
 *
 * @export
 * @interface SuccessResponse1
 */
export interface SuccessResponse1 {
  /**
   *
   * @type {boolean}
   * @memberof SuccessResponse1
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof SuccessResponse1
   */
  msg?: string;
}
/**
 *
 * @export
 * @interface SuccessTrueResponse
 */
export interface SuccessTrueResponse {
  /**
   *
   * @type {boolean}
   * @memberof SuccessTrueResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface SuspiciousActivity
 */
export interface SuspiciousActivity {
  /**
   *
   * @type {number}
   * @memberof SuspiciousActivity
   */
  tab_switch_seconds?: number;
  /**
   *
   * @type {number}
   * @memberof SuspiciousActivity
   */
  time_elapsed_seconds?: number;
  /**
   *
   * @type {string}
   * @memberof SuspiciousActivity
   */
  attempt_id?: string;
  /**
   *
   * @type {string}
   * @memberof SuspiciousActivity
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface TagDetailsSub
 */
export interface TagDetailsSub {
  /**
   *
   * @type {string}
   * @memberof TagDetailsSub
   */
  tag_user_level?: string;
  /**
   *
   * @type {string}
   * @memberof TagDetailsSub
   */
  tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof TagDetailsSub
   */
  question_solved_percent?: number;
  /**
   *
   * @type {string}
   * @memberof TagDetailsSub
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof TagDetailsSub
   */
  tag_image?: string;
}
/**
 *
 * @export
 * @interface TagStats
 */
export interface TagStats {
  /**
   *
   * @type {number}
   * @memberof TagStats
   */
  total_bookmarks?: number;
  /**
   *
   * @type {number}
   * @memberof TagStats
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof TagStats
   */
  total_questions_created?: number;
  /**
   *
   * @type {number}
   * @memberof TagStats
   */
  complete_attempt_avg_time?: number;
  /**
   *
   * @type {number}
   * @memberof TagStats
   */
  total_likes?: number;
}
/**
 *
 * @export
 * @interface TagStatsSub
 */
export interface TagStatsSub {
  /**
   *
   * @type {string}
   * @memberof TagStatsSub
   */
  tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  total_seconds_spent?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  correct_answers?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  questions_seen?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  incorrect_answers?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  questions_attempted?: number;
  /**
   *
   * @type {number}
   * @memberof TagStatsSub
   */
  average_time_total?: number;
}
/**
 *
 * @export
 * @interface TagSub
 */
export interface TagSub {
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  tag_name?: string;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  user_tag_level?: string;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  tag_id?: string;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  percent_accuracy?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  avg_time_taken_correct?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_category_questions?: number;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  rank?: string;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  domain_id?: string;
  /**
   *
   * @type {{ [key: string]: BadgeObjSub; }}
   * @memberof TagSub
   */
  badge_obj?: { [key: string]: BadgeObjSub };
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  unanswered_category_questions?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  avg_time_taken_incorrect?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {string}
   * @memberof TagSub
   */
  s3_url?: string;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_skipped_count?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof TagSub
   */
  total_timeout_count?: number;
}
/**
 *
 * @export
 * @interface TeamNameAvailibilityResponse
 */
export interface TeamNameAvailibilityResponse {
  /**
   *
   * @type {boolean}
   * @memberof TeamNameAvailibilityResponse
   */
  success?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TeamNameAvailibilityResponse
   */
  is_available?: boolean;
}
/**
 *
 * @export
 * @interface ToggleUserEmailNotiSub
 */
export interface ToggleUserEmailNotiSub {
  /**
   *
   * @type {boolean}
   * @memberof ToggleUserEmailNotiSub
   */
  is_enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ToggleUserEmailNotiSub
   */
  email_notification_id?: string;
}
/**
 *
 * @export
 * @interface ToggleUserEmailNotifications
 */
export interface ToggleUserEmailNotifications {
  /**
   *
   * @type {Array<ToggleUserEmailNotiSub>}
   * @memberof ToggleUserEmailNotifications
   */
  notifications?: Array<ToggleUserEmailNotiSub>;
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  net_value_in_currency?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  invoice_no?: string | null;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  item_count?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  payment_mode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  razorpay_order_id?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  user_msg?: string | null;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  virtual_items_credited?: string | null;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  invoice_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  razorpay_signature?: string | null;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  buyer_id?: string;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  net_value_in_gems?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  payment_status?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  internal_msg?: string | null;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  discount_pct_gems?: number;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  gross_total_in_gems?: number | null;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  gross_total_in_currency?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  razorpay_payment_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  discount_pct_currency?: number;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  overall_transaction_status?: string;
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface TrendingTopic
 */
export interface TrendingTopic {
  /**
   *
   * @type {number}
   * @memberof TrendingTopic
   */
  total_players?: number;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  domain_name?: string;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  s3_url?: string;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  tag_id?: string;
  /**
   *
   * @type {string}
   * @memberof TrendingTopic
   */
  domain_id?: string;
}
/**
 *
 * @export
 * @interface TrendingTopics
 */
export interface TrendingTopics {
  /**
   *
   * @type {Array<TrendingTopic>}
   * @memberof TrendingTopics
   */
  college_level_trend?: Array<TrendingTopic>;
  /**
   *
   * @type {Array<TrendingTopic>}
   * @memberof TrendingTopics
   */
  global_trend?: Array<TrendingTopic>;
}
/**
 *
 * @export
 * @interface UnansweredQuestionResponse
 */
export interface UnansweredQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  total_bookmarks?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  negative_score?: number;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  next_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  quality_score?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UnansweredQuestionResponse
   */
  second_similar?: Array<string>;
  /**
   *
   * @type {Array<Option>}
   * @memberof UnansweredQuestionResponse
   */
  options?: Array<Option>;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  attempt_accuracy?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  total_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  credits?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  no_options?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  total_solution_dislikes?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  total_solution_likes?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  attempt_rate?: number | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  created_by?: string;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  correct_attempt_avg_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UnansweredQuestionResponse
   */
  is_credit_added?: boolean;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  question_type?: string;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  remaining_attempts?: number;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  created_by_username?: string;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  maxtime_passes?: number;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  tag_id?: string;
  /**
   *
   * @type {Attempt}
   * @memberof UnansweredQuestionResponse
   */
  attempt_details?: Attempt;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  question_attempts?: string | null;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  purchased_time?: number;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UnansweredQuestionResponse
   */
  first_similar?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof UnansweredQuestionResponse
   */
  is_solution_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  total_likes?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  weightage?: number;
  /**
   *
   * @type {boolean}
   * @memberof UnansweredQuestionResponse
   */
  is_hint_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  created_by_name?: string;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  group_slug?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UnansweredQuestionResponse
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  max_time?: number;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  attempt_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UnansweredQuestionResponse
   */
  checked_incorrect_option?: boolean;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  completed_attempt_avg_time?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UnansweredQuestionResponse
   */
  is_deleted?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  exam_type?: string | null;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  sub_question_number?: number | null;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  difficulty_level?: number;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  start_time?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UnansweredQuestionResponse
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  year?: string | null;
  /**
   *
   * @type {ExternalExamSub}
   * @memberof UnansweredQuestionResponse
   */
  external_exam?: ExternalExamSub;
  /**
   *
   * @type {number}
   * @memberof UnansweredQuestionResponse
   */
  multipart_question_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  parent_question_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof UnansweredQuestionResponse
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface UnansweredQuestionsResponse
 */
export interface UnansweredQuestionsResponse {
  /**
   *
   * @type {Array<UnansweredQuestionResponse>}
   * @memberof UnansweredQuestionsResponse
   */
  question?: Array<UnansweredQuestionResponse>;
}
/**
 *
 * @export
 * @interface UpdateCartBody
 */
export interface UpdateCartBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCartBody
   */
  job_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCartBody
   */
  item_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCartBody
   */
  payment_mode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCartBody
   */
  org_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateCartBody
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface UpdateCartResponse
 */
export interface UpdateCartResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateCartResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<UpdateCartSubResponse>}
   * @memberof UpdateCartResponse
   */
  failed_items?: Array<UpdateCartSubResponse>;
}
/**
 *
 * @export
 * @interface UpdateCartSubResponse
 */
export interface UpdateCartSubResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateCartSubResponse
   */
  item_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCartSubResponse
   */
  payment_mode?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCartSubResponse
   */
  reason?: string;
}
/**
 *
 * @export
 * @interface UpdateCollegeJobInviteStatusBody
 */
export interface UpdateCollegeJobInviteStatusBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusBody
   */
  status: string;
}
/**
 *
 * @export
 * @interface UpdateCollegeJobInviteStatusResponse
 */
export interface UpdateCollegeJobInviteStatusResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  created_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  batches?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeJobInviteStatusResponse
   */
  status?: string;
}
/**
 *
 * @export
 * @interface UpdateCollegeMilestonesResponse
 */
export interface UpdateCollegeMilestonesResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeMilestonesResponse
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateCollegeMilestonesResponse
   */
  student_count?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateCollegeMilestonesResponse
   */
  gems?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegeMilestonesResponse
   */
  created_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCollegeMilestonesResponse
   */
  special_rewards?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateCollegeMilestonesResponse
   */
  credits?: number;
}
/**
 *
 * @export
 * @interface UpdateCollegePasswordBody
 */
export interface UpdateCollegePasswordBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCollegePasswordBody
   */
  new_password: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCollegePasswordBody
   */
  otp: string;
}
/**
 *
 * @export
 * @interface UpdateCommentBody
 */
export interface UpdateCommentBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCommentBody
   */
  comment_text?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentBody
   */
  comment_type?: string;
}
/**
 *
 * @export
 * @interface UpdateCommentResponse
 */
export interface UpdateCommentResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  edited_on?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  question_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateCommentResponse
   */
  is_deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  user_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateCommentResponse
   */
  comment_level?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  parent_comment_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  comment_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCommentResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UpdateDegreeBody
 */
export interface UpdateDegreeBody {
  /**
   *
   * @type {string}
   * @memberof UpdateDegreeBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDegreeBody
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDegreeBody
   */
  id: string;
}
/**
 *
 * @export
 * @interface UpdateDegreeResponse
 */
export interface UpdateDegreeResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDegreeResponse
   */
  success?: boolean;
  /**
   *
   * @type {DegreeAutocompleteSub}
   * @memberof UpdateDegreeResponse
   */
  updated_degree?: DegreeAutocompleteSub;
}
/**
 *
 * @export
 * @interface UpdateEmailNotificationsResponse
 */
export interface UpdateEmailNotificationsResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateEmailNotificationsResponse
   */
  success?: boolean;
  /**
   *
   * @type {EmailNotificationSub}
   * @memberof UpdateEmailNotificationsResponse
   */
  data?: EmailNotificationSub;
}
/**
 *
 * @export
 * @interface UpdateExamResponse
 */
export interface UpdateExamResponse {
  /**
   *
   * @type {ExamSub}
   * @memberof UpdateExamResponse
   */
  external_exam?: ExamSub;
  /**
   *
   * @type {boolean}
   * @memberof UpdateExamResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateFieldPositionBody
 */
export interface UpdateFieldPositionBody {
  /**
   *
   * @type {number}
   * @memberof UpdateFieldPositionBody
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateFieldPositionBody
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UpdateHiringStatusBody
 */
export interface UpdateHiringStatusBody {
  /**
   *
   * @type {string}
   * @memberof UpdateHiringStatusBody
   */
  hiring_status: string;
}
/**
 *
 * @export
 * @interface UpdateJobAnalysisStatusBody
 */
export interface UpdateJobAnalysisStatusBody {
  /**
   *
   * @type {number}
   * @memberof UpdateJobAnalysisStatusBody
   */
  total_resumes_analysed?: number;
}
/**
 *
 * @export
 * @interface UpdateJobAnalysisStatusResponse
 */
export interface UpdateJobAnalysisStatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobAnalysisStatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateJobApplicant
 */
export interface UpdateJobApplicant {
  /**
   *
   * @type {number}
   * @memberof UpdateJobApplicant
   */
  resume_analysis_skill_score?: number | null;
  /**
   *
   * @type {object}
   * @memberof UpdateJobApplicant
   */
  resume_analysis_response?: object | null;
  /**
   *
   * @type {number}
   * @memberof UpdateJobApplicant
   */
  resume_analysis_overall_score?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobApplicant
   */
  resume_analysis_status?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobApplicant
   */
  resume_analysis_experience_score?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobApplicant
   */
  status?: string;
}
/**
 *
 * @export
 * @interface UpdateJobBody
 */
export interface UpdateJobBody {
  /**
   *
   * @type {Location}
   * @memberof UpdateJobBody
   */
  job_location?: Location;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  location_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  work_flexibility?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateJobBody
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  job_closing_reason?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  company_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  minimum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  gpt_job_description?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  job_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  is_archieved?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobBody
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  minimum_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  additional_details?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  competition_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  domain_id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobBody
   */
  auto_analyse?: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateJobBody
   */
  required_skills?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  salary_frequency?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobBody
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBody
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface UpdateJobBookmark
 */
export interface UpdateJobBookmark {
  /**
   *
   * @type {string}
   * @memberof UpdateJobBookmark
   */
  job_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobBookmark
   */
  is_bookmarked?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBookmark
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobBookmark
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UpdateJobResponse
 */
export interface UpdateJobResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  work_flexibility?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  maximum_salary?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  minimum_experience?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateJobResponse
   */
  additional_benefits?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  job_closing_reason?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  minimum_salary?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  alias?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  job_credits?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  gpt_job_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  job_type?: string;
  /**
   *
   * @type {JobRole}
   * @memberof UpdateJobResponse
   */
  job_role_details?: JobRole;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  application_credits?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  job_role_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobResponse
   */
  is_premium?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobResponse
   */
  is_gpt_job?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  is_archieved?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  minimum_qualification?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  org_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  additional_details?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  job_duration?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  job_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  competition_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  employer_access_expires_on?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  domain_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  no_of_positions?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateJobResponse
   */
  maximum_experience?: number | null;
  /**
   *
   * @type {Location}
   * @memberof UpdateJobResponse
   */
  job_location_details?: Location;
  /**
   *
   * @type {Array<RequiredSkills>}
   * @memberof UpdateJobResponse
   */
  required_skills?: Array<RequiredSkills>;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  preferred_qualification?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  salary_frequency?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobResponse
   */
  responsibilities?: string;
}
/**
 *
 * @export
 * @interface UpdateJobRoleBody
 */
export interface UpdateJobRoleBody {
  /**
   *
   * @type {string}
   * @memberof UpdateJobRoleBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobRoleBody
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateJobRoleBody
   */
  id: string;
}
/**
 *
 * @export
 * @interface UpdateJobRoleResponse
 */
export interface UpdateJobRoleResponse {
  /**
   *
   * @type {Array<JobRoleSub>}
   * @memberof UpdateJobRoleResponse
   */
  job_roles?: Array<JobRoleSub>;
  /**
   *
   * @type {boolean}
   * @memberof UpdateJobRoleResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateLevelBody
 */
export interface UpdateLevelBody {
  /**
   *
   * @type {number}
   * @memberof UpdateLevelBody
   */
  credit_reward?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateLevelBody
   */
  image_url?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateLevelBody
   */
  gem_reward?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateLevelBody
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateLevelBody
   */
  number?: number;
}
/**
 *
 * @export
 * @interface UpdateMockTestBody
 */
export interface UpdateMockTestBody {
  /**
   *
   * @type {boolean}
   * @memberof UpdateMockTestBody
   */
  is_template_active?: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateMockTestBody
   */
  no_questions?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateMockTestBody
   */
  tag_weightages?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  image_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  parent_mocktest_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateMockTestBody
   */
  difficulty_level?: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof UpdateMockTestBody
   */
  subcategory_weights?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof UpdateMockTestBody
   */
  prerequisite?: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateMockTestBody
   */
  is_template?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateMockTestBody
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  template_active_until?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  exam_mode?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateMockTestBody
   */
  cost_in_credits?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateMockTestBody
   */
  total_duration?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateMockTestBody
   */
  domain_id?: string;
}
/**
 *
 * @export
 * @interface UpdateOptionBody
 */
export interface UpdateOptionBody {
  /**
   *
   * @type {string}
   * @memberof UpdateOptionBody
   */
  question_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateOptionBody
   */
  option_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateOptionBody
   */
  option_text?: string;
}
/**
 *
 * @export
 * @interface UpdateOptionResponse
 */
export interface UpdateOptionResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateOptionResponse
   */
  success?: boolean;
  /**
   *
   * @type {OptionResponse}
   * @memberof UpdateOptionResponse
   */
  new_option?: OptionResponse;
  /**
   *
   * @type {string}
   * @memberof UpdateOptionResponse
   */
  original_text?: string;
}
/**
 *
 * @export
 * @interface UpdateOrgUserBody
 */
export interface UpdateOrgUserBody {
  /**
   *
   * @type {string}
   * @memberof UpdateOrgUserBody
   */
  department_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrgUserBody
   */
  job_role_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrgUserBody
   */
  job_role_id?: string | null;
}
/**
 *
 * @export
 * @interface UpdateOrganizationBody
 */
export interface UpdateOrganizationBody {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  company_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  team_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  verification_doc_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  location_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  company_size?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  company_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  linkedin_url?: string | null;
  /**
   *
   * @type {Location}
   * @memberof UpdateOrganizationBody
   */
  location?: Location;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  website_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationBody
   */
  company_logo_url?: string | null;
}
/**
 *
 * @export
 * @interface UpdateQuestionBody
 */
export interface UpdateQuestionBody {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateQuestionBody
   */
  answer_option_no?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  exam_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  title?: string | null;
  /**
   *
   * @type {Array<Option>}
   * @memberof UpdateQuestionBody
   */
  options?: Array<Option>;
  /**
   *
   * @type {Solution}
   * @memberof UpdateQuestionBody
   */
  solution?: Solution;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  tag_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  how_to_answer?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  solution_text?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  question_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  tag_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateQuestionBody
   */
  answer_one_word?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  question_hint?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  question_source_url?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateQuestionBody
   */
  subtag_names?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateQuestionBody
   */
  weightage?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  question_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateQuestionBody
   */
  sub_tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateQuestionBody
   */
  max_time?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  question_text?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  exam_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateQuestionBody
   */
  external_ref_url?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateQuestionBody
   */
  difficulty_level?: number;
  /**
   *
   * @type {Array<CreateSubQuestionBody>}
   * @memberof UpdateQuestionBody
   */
  sub_questions?: Array<CreateSubQuestionBody>;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateQuestionBody
   */
  tag_array?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpdateQuestionBody
   */
  year?: number | null;
}
/**
 *
 * @export
 * @interface UpdateResumeHiringStatusBody
 */
export interface UpdateResumeHiringStatusBody {
  /**
   *
   * @type {string}
   * @memberof UpdateResumeHiringStatusBody
   */
  hiring_status?: string;
}
/**
 *
 * @export
 * @interface UpdateResumeRating
 */
export interface UpdateResumeRating {
  /**
   *
   * @type {string}
   * @memberof UpdateResumeRating
   */
  rating?: string;
}
/**
 *
 * @export
 * @interface UpdateReviewStatusBody
 */
export interface UpdateReviewStatusBody {
  /**
   *
   * @type {string}
   * @memberof UpdateReviewStatusBody
   */
  reason_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReviewStatusBody
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReviewStatusBody
   */
  rejection_reason?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateReviewStatusBody
   */
  correct_value?: number;
}
/**
 *
 * @export
 * @interface UpdateSalesLeadBody
 */
export interface UpdateSalesLeadBody {
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  company_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  sales_lead_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  college_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  college_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  linkedin_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  sales_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSalesLeadBody
   */
  job_role_name?: string;
}
/**
 *
 * @export
 * @interface UpdateSkillBody
 */
export interface UpdateSkillBody {
  /**
   *
   * @type {string}
   * @memberof UpdateSkillBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSkillBody
   */
  status?: string;
}
/**
 *
 * @export
 * @interface UpdateSkillResponse
 */
export interface UpdateSkillResponse {
  /**
   *
   * @type {CreateSkillSub}
   * @memberof UpdateSkillResponse
   */
  updated_skill?: CreateSkillSub;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSkillResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface UpdateSpecializationBody
 */
export interface UpdateSpecializationBody {
  /**
   *
   * @type {string}
   * @memberof UpdateSpecializationBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSpecializationBody
   */
  approval_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSpecializationBody
   */
  degree_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSpecializationBody
   */
  id: string;
}
/**
 *
 * @export
 * @interface UpdateSpecializationResponse
 */
export interface UpdateSpecializationResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateSpecializationResponse
   */
  success?: boolean;
  /**
   *
   * @type {SpecializationAutocompleteSub}
   * @memberof UpdateSpecializationResponse
   */
  updated_specialization?: SpecializationAutocompleteSub;
}
/**
 *
 * @export
 * @interface UpdateStoreItemResponse
 */
export interface UpdateStoreItemResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateStoreItemResponse
   */
  success?: boolean;
  /**
   *
   * @type {GetCartItemDetailsSub}
   * @memberof UpdateStoreItemResponse
   */
  updated_item?: GetCartItemDetailsSub;
}
/**
 *
 * @export
 * @interface UpdateUnderReviewBody
 */
export interface UpdateUnderReviewBody {
  /**
   *
   * @type {string}
   * @memberof UpdateUnderReviewBody
   */
  question_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUnderReviewBody
   */
  rejection_reason?: string | null;
}
/**
 *
 * @export
 * @interface UpdateUserBody
 */
export interface UpdateUserBody {
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  summary?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  linkedin_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  selected_domain?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserBody
   */
  country?: string;
}
/**
 *
 * @export
 * @interface UpdateUserEmailBody
 */
export interface UpdateUserEmailBody {
  /**
   *
   * @type {string}
   * @memberof UpdateUserEmailBody
   */
  email_verification_otp?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserEmailBody
   */
  email?: string;
}
/**
 *
 * @export
 * @interface UpdateUserOnboardingBody
 */
export interface UpdateUserOnboardingBody {
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  picture?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  about?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserOnboardingBody
   */
  is_profile_hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserOnboardingBody
   */
  country?: string;
}
/**
 *
 * @export
 * @interface UploadApplicantResumeFileResponse
 */
export interface UploadApplicantResumeFileResponse {
  /**
   *
   * @type {boolean}
   * @memberof UploadApplicantResumeFileResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof UploadApplicantResumeFileResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof UploadApplicantResumeFileResponse
   */
  analysis_id?: string;
}
/**
 *
 * @export
 * @interface UploadFeedbackResponse
 */
export interface UploadFeedbackResponse {
  /**
   *
   * @type {string}
   * @memberof UploadFeedbackResponse
   */
  s3_image_url?: string;
}
/**
 *
 * @export
 * @interface UploadImageBadgeResponse
 */
export interface UploadImageBadgeResponse {
  /**
   *
   * @type {string}
   * @memberof UploadImageBadgeResponse
   */
  s3_image_url?: string;
}
/**
 *
 * @export
 * @interface UploadImageFileResponse
 */
export interface UploadImageFileResponse {
  /**
   *
   * @type {string}
   * @memberof UploadImageFileResponse
   */
  s3_image_url?: string;
}
/**
 *
 * @export
 * @interface UploadImageLevelResponse
 */
export interface UploadImageLevelResponse {
  /**
   *
   * @type {string}
   * @memberof UploadImageLevelResponse
   */
  s3_image_url?: string;
}
/**
 *
 * @export
 * @interface UploadItemImageResponse
 */
export interface UploadItemImageResponse {
  /**
   *
   * @type {string}
   * @memberof UploadItemImageResponse
   */
  s3_image_url?: string;
}
/**
 *
 * @export
 * @interface UpsertAddressBody
 */
export interface UpsertAddressBody {
  /**
   *
   * @type {UpsertShippingAddressBody}
   * @memberof UpsertAddressBody
   */
  billing?: UpsertShippingAddressBody;
  /**
   *
   * @type {UpsertShippingAddressBody}
   * @memberof UpsertAddressBody
   */
  shipping?: UpsertShippingAddressBody;
}
/**
 *
 * @export
 * @interface UpsertAddressResponse
 */
export interface UpsertAddressResponse {
  /**
   *
   * @type {Address}
   * @memberof UpsertAddressResponse
   */
  billing?: Address;
  /**
   *
   * @type {Address}
   * @memberof UpsertAddressResponse
   */
  shipping?: Address;
}
/**
 *
 * @export
 * @interface UpsertCandidateDetails
 */
export interface UpsertCandidateDetails {
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetails
   */
  expected_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  job_role?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  summary?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  work_flexibility?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  current_job_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpsertCandidateDetails
   */
  interested_skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetails
   */
  total_experience_years?: number;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetails
   */
  current_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetails
   */
  tentative_notice_period?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  preferred_location_id?: string;
  /**
   *
   * @type {Location}
   * @memberof UpsertCandidateDetails
   */
  location_details?: Location;
  /**
   *
   * @type {Array<string>}
   * @memberof UpsertCandidateDetails
   */
  interested_job_types?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  heading?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  job_search_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  resume_url?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetails
   */
  modified_on?: string;
}
/**
 *
 * @export
 * @interface UpsertCandidateDetailsBody
 */
export interface UpsertCandidateDetailsBody {
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  current_job_status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpsertCandidateDetailsBody
   */
  interested_skills?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetailsBody
   */
  expected_ctc?: number;
  /**
   *
   * @type {Location}
   * @memberof UpsertCandidateDetailsBody
   */
  current_location?: Location;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetailsBody
   */
  total_experience_years?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UpsertCandidateDetailsBody
   */
  interested_job_types?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetailsBody
   */
  current_ctc?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  job_role?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  heading?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  job_search_status?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  summary?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertCandidateDetailsBody
   */
  tentative_notice_period?: number;
  /**
   *
   * @type {Location}
   * @memberof UpsertCandidateDetailsBody
   */
  preferred_location?: Location;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  work_flexibility?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCandidateDetailsBody
   */
  resume_url?: string;
}
/**
 *
 * @export
 * @interface UpsertCompetitionBody
 */
export interface UpsertCompetitionBody {
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  additional_notes?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  competition_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  competition_header_url?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  rewards?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  alias?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpsertCompetitionBody
   */
  is_private?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  description?: string;
  /**
   *
   * @type {Array<CompetitionRewardsBody>}
   * @memberof UpsertCompetitionBody
   */
  rewards_list?: Array<CompetitionRewardsBody>;
  /**
   *
   * @type {number}
   * @memberof UpsertCompetitionBody
   */
  question_limit?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  reg_start_date?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertCompetitionBody
   */
  participation_credits?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UpsertCompetitionBody
   */
  category_list?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  short_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  social_description?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertCompetitionBody
   */
  competition_level?: number;
  /**
   *
   * @type {object}
   * @memberof UpsertCompetitionBody
   */
  subcategory_weights?: object;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  org_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertCompetitionBody
   */
  total_coa?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  domain_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  image_url?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof UpsertCompetitionBody
   */
  category_weights?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  reg_end_date?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertCompetitionBody
   */
  rules?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpsertCompetitionBody
   */
  show_leaderboard?: boolean | null;
}
/**
 *
 * @export
 * @interface UpsertScheduleBody
 */
export interface UpsertScheduleBody {
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleBody
   */
  competition_name?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleBody
   */
  recurrence_days?: number;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleBody
   */
  next_competition_serial_no?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleBody
   */
  competition_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleBody
   */
  max_live_count?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpsertScheduleBody
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleBody
   */
  valid_until?: string;
}
/**
 *
 * @export
 * @interface UpsertScheduleResponse
 */
export interface UpsertScheduleResponse {
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleResponse
   */
  competition_name?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleResponse
   */
  recurrence_days?: number;
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleResponse
   */
  competition_id?: string;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleResponse
   */
  next_competition_serial_no?: number;
  /**
   *
   * @type {number}
   * @memberof UpsertScheduleResponse
   */
  max_live_count?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpsertScheduleResponse
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleResponse
   */
  valid_until?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertScheduleResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UpsertShippingAddressBody
 */
export interface UpsertShippingAddressBody {
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  phone_no: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  pincode: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  address_type?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressBody
   */
  country?: string;
}
/**
 *
 * @export
 * @interface UpsertShippingAddressResponse
 */
export interface UpsertShippingAddressResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpsertShippingAddressResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpsertShippingAddressResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface UseCreditsBody
 */
export interface UseCreditsBody {
  /**
   *
   * @type {number}
   * @memberof UseCreditsBody
   */
  no_of_credits?: number;
  /**
   *
   * @type {string}
   * @memberof UseCreditsBody
   */
  action_type?: string;
  /**
   *
   * @type {string}
   * @memberof UseCreditsBody
   */
  question_id?: string;
  /**
   *
   * @type {string}
   * @memberof UseCreditsBody
   */
  tag_id?: string;
}
/**
 *
 * @export
 * @interface UseCreditsResponse
 */
export interface UseCreditsResponse {
  /**
   *
   * @type {number}
   * @memberof UseCreditsResponse
   */
  purchased_time?: number;
  /**
   *
   * @type {string}
   * @memberof UseCreditsResponse
   */
  success?: string;
  /**
   *
   * @type {number}
   * @memberof UseCreditsResponse
   */
  remaining_credits?: number;
  /**
   *
   * @type {number}
   * @memberof UseCreditsResponse
   */
  credits_consumed?: number;
}
/**
 *
 * @export
 * @interface UserActivitySub
 */
export interface UserActivitySub {
  /**
   *
   * @type {MiniUserDetails}
   * @memberof UserActivitySub
   */
  user_details?: MiniUserDetails;
  /**
   *
   * @type {object}
   * @memberof UserActivitySub
   */
  meta_data?: object;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  activity_type?: string;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  activity_description?: string;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof UserActivitySub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserBadgesSub
 */
export interface UserBadgesSub {
  /**
   *
   * @type {number}
   * @memberof UserBadgesSub
   */
  total_badge_count?: number;
  /**
   *
   * @type {Array<BadgeSub>}
   * @memberof UserBadgesSub
   */
  badges?: Array<BadgeSub>;
}
/**
 *
 * @export
 * @interface UserData
 */
export interface UserData {
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  username?: string;
}
/**
 *
 * @export
 * @interface UserData1
 */
export interface UserData1 {
  /**
   *
   * @type {string}
   * @memberof UserData1
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserData1
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserData1
   */
  username?: string;
}
/**
 *
 * @export
 * @interface UserDetailList
 */
export interface UserDetailList {
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  total_competitions?: number;
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  avg_accuracy?: number;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDetailList
   */
  is_banned?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  user_tag_level?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  email?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserDetailList
   */
  viewed_onboardings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  phone_no?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  questions_created?: number;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  total_referrals?: number;
  /**
   *
   * @type {number}
   * @memberof UserDetailList
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  last_login_datetime?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserDetailList
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDetailList
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserDetailsSub
 */
export interface UserDetailsSub {
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  email?: string;
  /**
   *
   * @type {ScoreDetailsResponse}
   * @memberof UserDetailsSub
   */
  score_details?: ScoreDetailsResponse;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  last_login_time?: string;
  /**
   *
   * @type {number}
   * @memberof UserDetailsSub
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailsSub
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserLevelDetailsSub
 */
export interface UserLevelDetailsSub {
  /**
   *
   * @type {number}
   * @memberof UserLevelDetailsSub
   */
  next_level?: number;
  /**
   *
   * @type {number}
   * @memberof UserLevelDetailsSub
   */
  curr_score?: number;
  /**
   *
   * @type {number}
   * @memberof UserLevelDetailsSub
   */
  next_score_cutoff?: number;
  /**
   *
   * @type {number}
   * @memberof UserLevelDetailsSub
   */
  curr_level?: number;
  /**
   *
   * @type {string}
   * @memberof UserLevelDetailsSub
   */
  curr_level_name?: string;
  /**
   *
   * @type {NextRewardDetailsSub}
   * @memberof UserLevelDetailsSub
   */
  next_reward_details?: NextRewardDetailsSub;
}
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  username?: string;
  /**
   *
   * @type {number}
   * @memberof UserResponse
   */
  login_streak?: number;
  /**
   *
   * @type {number}
   * @memberof UserResponse
   */
  gems?: number;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  gender?: string;
  /**
   *
   * @type {number}
   * @memberof UserResponse
   */
  credits?: number;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  linkedin_url?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  phone_number?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserResponse
   */
  viewed_onboardings?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  about?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  is_profile_hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  user_type?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  default_color?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserResponse
   */
  completed_profile_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  last_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  is_phone_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  referral_hash?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  selected_domain?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserResponse
   */
  completed_job_profile_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  country?: string;
}
/**
 *
 * @export
 * @interface UserSkillsResponse
 */
export interface UserSkillsResponse {
  /**
   *
   * @type {number}
   * @memberof UserSkillsResponse
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof UserSkillsResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserSkillsResponse
   */
  skill_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserSkillsResponse
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserSkillsResponse
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof UserSkillsResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserStats
 */
export interface UserStats {
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  avg_time_taken_total?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  total_correct_count?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  total_question_count?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  total_skipped_count?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  total_incorrect_count?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  question_attempts_in_last_year?: number;
  /**
   *
   * @type {number}
   * @memberof UserStats
   */
  total_timeout_count?: number;
}
/**
 *
 * @export
 * @interface UserSub
 */
export interface UserSub {
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  picture?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSub
   */
  country?: string;
}
/**
 *
 * @export
 * @interface ValidateAndSetPhoneBody
 */
export interface ValidateAndSetPhoneBody {
  /**
   *
   * @type {string}
   * @memberof ValidateAndSetPhoneBody
   */
  phone_number_otp: string;
}
/**
 *
 * @export
 * @interface ValidateAndSetPhoneResponse
 */
export interface ValidateAndSetPhoneResponse {
  /**
   *
   * @type {boolean}
   * @memberof ValidateAndSetPhoneResponse
   */
  is_validated?: boolean;
}
/**
 *
 * @export
 * @interface ValidateCouponResponse
 */
export interface ValidateCouponResponse {
  /**
   *
   * @type {boolean}
   * @memberof ValidateCouponResponse
   */
  is_valid?: boolean;
  /**
   *
   * @type {string}
   * @memberof ValidateCouponResponse
   */
  coupon_id?: string;
  /**
   *
   * @type {string}
   * @memberof ValidateCouponResponse
   */
  item_id?: string;
  /**
   *
   * @type {number}
   * @memberof ValidateCouponResponse
   */
  percent_discount?: number;
  /**
   *
   * @type {string}
   * @memberof ValidateCouponResponse
   */
  reason?: string;
}
/**
 *
 * @export
 * @interface ValidateOtpBody
 */
export interface ValidateOtpBody {
  /**
   *
   * @type {string}
   * @memberof ValidateOtpBody
   */
  entity_type?: string;
  /**
   *
   * @type {number}
   * @memberof ValidateOtpBody
   */
  otp?: number;
  /**
   *
   * @type {string}
   * @memberof ValidateOtpBody
   */
  entity_id?: string;
}
/**
 *
 * @export
 * @interface ValidateOtpResponse
 */
export interface ValidateOtpResponse {
  /**
   *
   * @type {boolean}
   * @memberof ValidateOtpResponse
   */
  success?: boolean;
  /**
   *
   * @type {string}
   * @memberof ValidateOtpResponse
   */
  phone_no?: string;
  /**
   *
   * @type {string}
   * @memberof ValidateOtpResponse
   */
  status?: string;
}
/**
 *
 * @export
 * @interface VerifyAndSetEmailBody
 */
export interface VerifyAndSetEmailBody {
  /**
   *
   * @type {string}
   * @memberof VerifyAndSetEmailBody
   */
  email_otp: string;
  /**
   *
   * @type {string}
   * @memberof VerifyAndSetEmailBody
   */
  email: string;
}
/**
 *
 * @export
 * @interface VerifyAndSetEmailRepsonse
 */
export interface VerifyAndSetEmailRepsonse {
  /**
   *
   * @type {boolean}
   * @memberof VerifyAndSetEmailRepsonse
   */
  is_verified?: boolean;
}
/**
 *
 * @export
 * @interface VerifyEmailOtpBody
 */
export interface VerifyEmailOtpBody {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailOtpBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof VerifyEmailOtpBody
   */
  otp?: string;
}
/**
 *
 * @export
 * @interface VerifyEmailOtpResponse
 */
export interface VerifyEmailOtpResponse {
  /**
   *
   * @type {boolean}
   * @memberof VerifyEmailOtpResponse
   */
  is_email_verified?: boolean;
}
/**
 *
 * @export
 * @interface WorkDetailsBody
 */
export interface WorkDetailsBody {
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  company_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof WorkDetailsBody
   */
  is_current_company?: boolean;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  job_location_type?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  start_date?: string;
  /**
   *
   * @type {Location}
   * @memberof WorkDetailsBody
   */
  location?: Location;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof WorkDetailsBody
   */
  employment_type?: string;
}
/**
 *
 * @export
 * @interface WorkExperienceResponse
 */
export interface WorkExperienceResponse {
  /**
   *
   * @type {boolean}
   * @memberof WorkExperienceResponse
   */
  success?: boolean;
  /**
   *
   * @type {WorkExperienceSub}
   * @memberof WorkExperienceResponse
   */
  work_obj?: WorkExperienceSub;
}
/**
 *
 * @export
 * @interface WorkExperienceSub
 */
export interface WorkExperienceSub {
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  company_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof WorkExperienceSub
   */
  is_current_company?: boolean;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  end_date?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  id?: string;
  /**
   *
   * @type {Location}
   * @memberof WorkExperienceSub
   */
  location_details?: Location;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  location_id?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  job_role_id?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  modified_on?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  designation?: string | null;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  company_name?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  job_role_name?: string;
  /**
   *
   * @type {string}
   * @memberof WorkExperienceSub
   */
  employment_type?: string;
}

/**
 * BadgeApiApi - axios parameter creator
 * @export
 */
export const BadgeApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateBadgeBody} createBadgeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiCreateNewBadge: async (
      createBadgeBody: CreateBadgeBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createBadgeBody' is not null or undefined
      assertParamExists(
        'badgeApiCreateNewBadge',
        'createBadgeBody',
        createBadgeBody,
      );
      const localVarPath = `/badge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBadgeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiDeleteBadge: async (
      badgeId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/badge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (badgeId !== undefined) {
        localVarQueryParameter['badge_id'] = badgeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [milestoneNo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [segment]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiGetBadge: async (
      milestoneNo?: number,
      page?: number,
      pageSize?: number,
      query?: string,
      segment?: string,
      type?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/badge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (milestoneNo !== undefined) {
        localVarQueryParameter['milestone_no'] = milestoneNo;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (segment !== undefined) {
        localVarQueryParameter['segment'] = segment;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiGetBadgeType: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/badge/type`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiTodayBadges: async (
      badgeId?: string,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/badge/today`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (badgeId !== undefined) {
        localVarQueryParameter['badge_id'] = badgeId;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetAllBadgesSub} getAllBadgesSub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiUpdateBadge: async (
      getAllBadgesSub: GetAllBadgesSub,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getAllBadgesSub' is not null or undefined
      assertParamExists(
        'badgeApiUpdateBadge',
        'getAllBadgesSub',
        getAllBadgesSub,
      );
      const localVarPath = `/badge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getAllBadgesSub,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiUploadBadgeImage: async (
      imageFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageFile' is not null or undefined
      assertParamExists('badgeApiUploadBadgeImage', 'imageFile', imageFile);
      const localVarPath = `/badge/upload-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (imageFile !== undefined) {
        localVarFormParams.append('image_file', imageFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BadgeApiApi - functional programming interface
 * @export
 */
export const BadgeApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = BadgeApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateBadgeBody} createBadgeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiCreateNewBadge(
      createBadgeBody: CreateBadgeBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllBadgesSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiCreateNewBadge(
        createBadgeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiDeleteBadge(
      badgeId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteBadgeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiDeleteBadge(
        badgeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [milestoneNo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [segment]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiGetBadge(
      milestoneNo?: number,
      page?: number,
      pageSize?: number,
      query?: string,
      segment?: string,
      type?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllBadgesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiGetBadge(
        milestoneNo,
        page,
        pageSize,
        query,
        segment,
        type,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiGetBadgeType(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBadgeTypeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiGetBadgeType(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiTodayBadges(
      badgeId?: string,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTodayBadgesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiTodayBadges(
        badgeId,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {GetAllBadgesSub} getAllBadgesSub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiUpdateBadge(
      getAllBadgesSub: GetAllBadgesSub,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllBadgesSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiUpdateBadge(
        getAllBadgesSub,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async badgeApiUploadBadgeImage(
      imageFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadImageBadgeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.badgeApiUploadBadgeImage(
        imageFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BadgeApiApi - factory interface
 * @export
 */
export const BadgeApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BadgeApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateBadgeBody} createBadgeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiCreateNewBadge(
      createBadgeBody: CreateBadgeBody,
      options?: any,
    ): AxiosPromise<GetAllBadgesSub> {
      return localVarFp
        .badgeApiCreateNewBadge(createBadgeBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiDeleteBadge(
      badgeId?: string,
      options?: any,
    ): AxiosPromise<DeleteBadgeResponse> {
      return localVarFp
        .badgeApiDeleteBadge(badgeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [milestoneNo]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [segment]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiGetBadge(
      milestoneNo?: number,
      page?: number,
      pageSize?: number,
      query?: string,
      segment?: string,
      type?: string,
      options?: any,
    ): AxiosPromise<GetAllBadgesResponse> {
      return localVarFp
        .badgeApiGetBadge(
          milestoneNo,
          page,
          pageSize,
          query,
          segment,
          type,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiGetBadgeType(options?: any): AxiosPromise<GetBadgeTypeResponse> {
      return localVarFp
        .badgeApiGetBadgeType(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [badgeId]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiTodayBadges(
      badgeId?: string,
      tagId?: string,
      options?: any,
    ): AxiosPromise<GetTodayBadgesResponse> {
      return localVarFp
        .badgeApiTodayBadges(badgeId, tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetAllBadgesSub} getAllBadgesSub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiUpdateBadge(
      getAllBadgesSub: GetAllBadgesSub,
      options?: any,
    ): AxiosPromise<GetAllBadgesSub> {
      return localVarFp
        .badgeApiUpdateBadge(getAllBadgesSub, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    badgeApiUploadBadgeImage(
      imageFile: any,
      options?: any,
    ): AxiosPromise<UploadImageBadgeResponse> {
      return localVarFp
        .badgeApiUploadBadgeImage(imageFile, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BadgeApiApi - object-oriented interface
 * @export
 * @class BadgeApiApi
 * @extends {BaseAPI}
 */
export class BadgeApiApi extends BaseAPI {
  /**
   *
   * @param {CreateBadgeBody} createBadgeBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiCreateNewBadge(
    createBadgeBody: CreateBadgeBody,
    options?: AxiosRequestConfig,
  ) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiCreateNewBadge(createBadgeBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [badgeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiDeleteBadge(badgeId?: string, options?: AxiosRequestConfig) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiDeleteBadge(badgeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [milestoneNo]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {string} [segment]
   * @param {string} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiGetBadge(
    milestoneNo?: number,
    page?: number,
    pageSize?: number,
    query?: string,
    segment?: string,
    type?: string,
    options?: AxiosRequestConfig,
  ) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiGetBadge(
        milestoneNo,
        page,
        pageSize,
        query,
        segment,
        type,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiGetBadgeType(options?: AxiosRequestConfig) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiGetBadgeType(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [badgeId]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiTodayBadges(
    badgeId?: string,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiTodayBadges(badgeId, tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetAllBadgesSub} getAllBadgesSub
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiUpdateBadge(
    getAllBadgesSub: GetAllBadgesSub,
    options?: AxiosRequestConfig,
  ) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiUpdateBadge(getAllBadgesSub, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} imageFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgeApiApi
   */
  public badgeApiUploadBadgeImage(
    imageFile: any,
    options?: AxiosRequestConfig,
  ) {
    return BadgeApiApiFp(this.configuration)
      .badgeApiUploadBadgeImage(imageFile, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompetitionApiApi - axios parameter creator
 * @export
 */
export const CompetitionApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionAnalytics: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiCompetitionAnalytics',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/analytics`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionNextQuestion: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiCompetitionNextQuestion',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/unanswered/{competition_id}`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionRegistrations: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiCompetitionRegistrations',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/register`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateCertificateBody} createCertificateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCreateCertificate: async (
      createCertificateBody: CreateCertificateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCertificateBody' is not null or undefined
      assertParamExists(
        'competitionApiCreateCertificate',
        'createCertificateBody',
        createCertificateBody,
      );
      const localVarPath = `/competition/create-certificate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCertificateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCreateSelfCertificate: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiCreateSelfCertificate',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/create-self-certificate`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiDeleteCompetition: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiDeleteCompetition',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} scheduleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiDeleteCompetitionSchedule: async (
      scheduleId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scheduleId' is not null or undefined
      assertParamExists(
        'competitionApiDeleteCompetitionSchedule',
        'scheduleId',
        scheduleId,
      );
      const localVarPath = `/competition/schedule/{schedule_id}`.replace(
        `{${'schedule_id'}}`,
        encodeURIComponent(String(scheduleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetAllCompetitions: async (
      domainId?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      status?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/competition`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompactCompetitionAnalyticsByUser: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiGetCompactCompetitionAnalyticsByUser',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/user-analytics/compact`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionById: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiGetCompetitionById',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [name]
     * @param {string} [ongoing]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionList: async (
      endDate?: string,
      name?: string,
      ongoing?: string,
      pageNo?: string,
      pageSize?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/competition-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (ongoing !== undefined) {
        localVarQueryParameter['ongoing'] = ongoing;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [competitionScheduleId]
     * @param {string} [isActive]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionSchedules: async (
      competitionId?: string,
      competitionScheduleId?: string,
      isActive?: string,
      pageNo?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/competition/schedule`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (competitionId !== undefined) {
        localVarQueryParameter['competition_id'] = competitionId;
      }

      if (competitionScheduleId !== undefined) {
        localVarQueryParameter[
          'competition_schedule_id'
        ] = competitionScheduleId;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionStartStatistics: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiGetCompetitionStartStatistics',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/start-statistics`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetDetailedCompetitionAnalyticsByUser: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiGetDetailedCompetitionAnalyticsByUser',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/user-analytics/detailed`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetInvites: async (
      competitionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiGetInvites',
        'competitionId',
        competitionId,
      );
      const localVarPath = `/competition/{competition_id}/invitation`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {CompetitionInvitationBody} competitionInvitationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiInviteUsersCompetition: async (
      competitionId: string,
      competitionInvitationBody: CompetitionInvitationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiInviteUsersCompetition',
        'competitionId',
        competitionId,
      );
      // verify required parameter 'competitionInvitationBody' is not null or undefined
      assertParamExists(
        'competitionApiInviteUsersCompetition',
        'competitionInvitationBody',
        competitionInvitationBody,
      );
      const localVarPath = `/competition/{competition_id}/invitation`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        competitionInvitationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiIsCompetitionAliasAvailable: async (
      alias: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'alias' is not null or undefined
      assertParamExists(
        'competitionApiIsCompetitionAliasAvailable',
        'alias',
        alias,
      );
      const localVarPath = `/competition/alias/{alias}/is-available`.replace(
        `{${'alias'}}`,
        encodeURIComponent(String(alias)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiSendCertificate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/competition/send-certificate-mail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpsertCompetitionBody} upsertCompetitionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiUpsertCompetition: async (
      upsertCompetitionBody: UpsertCompetitionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'upsertCompetitionBody' is not null or undefined
      assertParamExists(
        'competitionApiUpsertCompetition',
        'upsertCompetitionBody',
        upsertCompetitionBody,
      );
      const localVarPath = `/competition-new`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertCompetitionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {UpsertScheduleBody} upsertScheduleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiUpsertCompetitionSchedule: async (
      competitionId: string,
      upsertScheduleBody: UpsertScheduleBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'competitionApiUpsertCompetitionSchedule',
        'competitionId',
        competitionId,
      );
      // verify required parameter 'upsertScheduleBody' is not null or undefined
      assertParamExists(
        'competitionApiUpsertCompetitionSchedule',
        'upsertScheduleBody',
        upsertScheduleBody,
      );
      const localVarPath = `/competition/{competition_id}/schedule`.replace(
        `{${'competition_id'}}`,
        encodeURIComponent(String(competitionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertScheduleBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompetitionApiApi - functional programming interface
 * @export
 */
export const CompetitionApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CompetitionApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiCompetitionAnalytics(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompetitionAnalytics>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiCompetitionAnalytics(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiCompetitionNextQuestion(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompetitionNextQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiCompetitionNextQuestion(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiCompetitionRegistrations(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiCompetitionRegistrations(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateCertificateBody} createCertificateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiCreateCertificate(
      createCertificateBody: CreateCertificateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateCertificateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiCreateCertificate(
        createCertificateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiCreateSelfCertificate(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateCertificateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiCreateSelfCertificate(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiDeleteCompetition(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteCompetitionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiDeleteCompetition(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} scheduleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiDeleteCompetitionSchedule(
      scheduleId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteCompetitionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiDeleteCompetitionSchedule(
        scheduleId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetAllCompetitions(
      domainId?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      status?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllCompetitionsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetAllCompetitions(
        domainId,
        page,
        pageSize,
        searchQuery,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetCompactCompetitionAnalyticsByUser(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompetitionAnalyticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetCompactCompetitionAnalyticsByUser(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetCompetitionById(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllCompetitionsSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetCompetitionById(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [name]
     * @param {string} [ongoing]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetCompetitionList(
      endDate?: string,
      name?: string,
      ongoing?: string,
      pageNo?: string,
      pageSize?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompetitionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetCompetitionList(
        endDate,
        name,
        ongoing,
        pageNo,
        pageSize,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [competitionScheduleId]
     * @param {string} [isActive]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetCompetitionSchedules(
      competitionId?: string,
      competitionScheduleId?: string,
      isActive?: string,
      pageNo?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompetitionSchedulesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetCompetitionSchedules(
        competitionId,
        competitionScheduleId,
        isActive,
        pageNo,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetCompetitionStartStatistics(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompetitionStartStatisticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetCompetitionStartStatistics(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetDetailedCompetitionAnalyticsByUser(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DetailedCompetitionAnalyticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetDetailedCompetitionAnalyticsByUser(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiGetInvites(
      competitionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompetitionInvitesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiGetInvites(
        competitionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {CompetitionInvitationBody} competitionInvitationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiInviteUsersCompetition(
      competitionId: string,
      competitionInvitationBody: CompetitionInvitationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompetitionInvitationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiInviteUsersCompetition(
        competitionId,
        competitionInvitationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiIsCompetitionAliasAvailable(
      alias: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IsAliasAvailableResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiIsCompetitionAliasAvailable(
        alias,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiSendCertificate(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendCertificateBody>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiSendCertificate(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpsertCompetitionBody} upsertCompetitionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiUpsertCompetition(
      upsertCompetitionBody: UpsertCompetitionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllCompetitionsSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiUpsertCompetition(
        upsertCompetitionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {UpsertScheduleBody} upsertScheduleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitionApiUpsertCompetitionSchedule(
      competitionId: string,
      upsertScheduleBody: UpsertScheduleBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertScheduleResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitionApiUpsertCompetitionSchedule(
        competitionId,
        upsertScheduleBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompetitionApiApi - factory interface
 * @export
 */
export const CompetitionApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompetitionApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionAnalytics(
      competitionId: string,
      options?: any,
    ): AxiosPromise<GetCompetitionAnalytics> {
      return localVarFp
        .competitionApiCompetitionAnalytics(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionNextQuestion(
      competitionId: string,
      options?: any,
    ): AxiosPromise<CompetitionNextQuestionResponse> {
      return localVarFp
        .competitionApiCompetitionNextQuestion(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCompetitionRegistrations(
      competitionId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .competitionApiCompetitionRegistrations(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateCertificateBody} createCertificateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCreateCertificate(
      createCertificateBody: CreateCertificateBody,
      options?: any,
    ): AxiosPromise<CreateCertificateResponse> {
      return localVarFp
        .competitionApiCreateCertificate(createCertificateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiCreateSelfCertificate(
      competitionId: string,
      options?: any,
    ): AxiosPromise<CreateCertificateResponse> {
      return localVarFp
        .competitionApiCreateSelfCertificate(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiDeleteCompetition(
      competitionId: string,
      options?: any,
    ): AxiosPromise<DeleteCompetitionResponse> {
      return localVarFp
        .competitionApiDeleteCompetition(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} scheduleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiDeleteCompetitionSchedule(
      scheduleId: string,
      options?: any,
    ): AxiosPromise<DeleteCompetitionResponse> {
      return localVarFp
        .competitionApiDeleteCompetitionSchedule(scheduleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetAllCompetitions(
      domainId?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      status?: string,
      options?: any,
    ): AxiosPromise<GetAllCompetitionsResponse> {
      return localVarFp
        .competitionApiGetAllCompetitions(
          domainId,
          page,
          pageSize,
          searchQuery,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompactCompetitionAnalyticsByUser(
      competitionId: string,
      options?: any,
    ): AxiosPromise<CompetitionAnalyticsResponse> {
      return localVarFp
        .competitionApiGetCompactCompetitionAnalyticsByUser(
          competitionId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionById(
      competitionId: string,
      options?: any,
    ): AxiosPromise<GetAllCompetitionsSub> {
      return localVarFp
        .competitionApiGetCompetitionById(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [name]
     * @param {string} [ongoing]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionList(
      endDate?: string,
      name?: string,
      ongoing?: string,
      pageNo?: string,
      pageSize?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<GetCompetitionResponse> {
      return localVarFp
        .competitionApiGetCompetitionList(
          endDate,
          name,
          ongoing,
          pageNo,
          pageSize,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [competitionScheduleId]
     * @param {string} [isActive]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionSchedules(
      competitionId?: string,
      competitionScheduleId?: string,
      isActive?: string,
      pageNo?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetCompetitionSchedulesResponse> {
      return localVarFp
        .competitionApiGetCompetitionSchedules(
          competitionId,
          competitionScheduleId,
          isActive,
          pageNo,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetCompetitionStartStatistics(
      competitionId: string,
      options?: any,
    ): AxiosPromise<CompetitionStartStatisticsResponse> {
      return localVarFp
        .competitionApiGetCompetitionStartStatistics(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetDetailedCompetitionAnalyticsByUser(
      competitionId: string,
      options?: any,
    ): AxiosPromise<DetailedCompetitionAnalyticsResponse> {
      return localVarFp
        .competitionApiGetDetailedCompetitionAnalyticsByUser(
          competitionId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiGetInvites(
      competitionId: string,
      options?: any,
    ): AxiosPromise<GetCompetitionInvitesResponse> {
      return localVarFp
        .competitionApiGetInvites(competitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {CompetitionInvitationBody} competitionInvitationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiInviteUsersCompetition(
      competitionId: string,
      competitionInvitationBody: CompetitionInvitationBody,
      options?: any,
    ): AxiosPromise<CompetitionInvitationResponse> {
      return localVarFp
        .competitionApiInviteUsersCompetition(
          competitionId,
          competitionInvitationBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiIsCompetitionAliasAvailable(
      alias: string,
      options?: any,
    ): AxiosPromise<IsAliasAvailableResponse> {
      return localVarFp
        .competitionApiIsCompetitionAliasAvailable(alias, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiSendCertificate(
      options?: any,
    ): AxiosPromise<SendCertificateBody> {
      return localVarFp
        .competitionApiSendCertificate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpsertCompetitionBody} upsertCompetitionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiUpsertCompetition(
      upsertCompetitionBody: UpsertCompetitionBody,
      options?: any,
    ): AxiosPromise<GetAllCompetitionsSub> {
      return localVarFp
        .competitionApiUpsertCompetition(upsertCompetitionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {UpsertScheduleBody} upsertScheduleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitionApiUpsertCompetitionSchedule(
      competitionId: string,
      upsertScheduleBody: UpsertScheduleBody,
      options?: any,
    ): AxiosPromise<UpsertScheduleResponse> {
      return localVarFp
        .competitionApiUpsertCompetitionSchedule(
          competitionId,
          upsertScheduleBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompetitionApiApi - object-oriented interface
 * @export
 * @class CompetitionApiApi
 * @extends {BaseAPI}
 */
export class CompetitionApiApi extends BaseAPI {
  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiCompetitionAnalytics(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiCompetitionAnalytics(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiCompetitionNextQuestion(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiCompetitionNextQuestion(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiCompetitionRegistrations(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiCompetitionRegistrations(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateCertificateBody} createCertificateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiCreateCertificate(
    createCertificateBody: CreateCertificateBody,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiCreateCertificate(createCertificateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiCreateSelfCertificate(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiCreateSelfCertificate(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiDeleteCompetition(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiDeleteCompetition(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} scheduleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiDeleteCompetitionSchedule(
    scheduleId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiDeleteCompetitionSchedule(scheduleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [searchQuery]
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetAllCompetitions(
    domainId?: string,
    page?: string,
    pageSize?: string,
    searchQuery?: string,
    status?: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetAllCompetitions(
        domainId,
        page,
        pageSize,
        searchQuery,
        status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetCompactCompetitionAnalyticsByUser(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetCompactCompetitionAnalyticsByUser(
        competitionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetCompetitionById(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetCompetitionById(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [endDate]
   * @param {string} [name]
   * @param {string} [ongoing]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetCompetitionList(
    endDate?: string,
    name?: string,
    ongoing?: string,
    pageNo?: string,
    pageSize?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetCompetitionList(
        endDate,
        name,
        ongoing,
        pageNo,
        pageSize,
        startDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [competitionId]
   * @param {string} [competitionScheduleId]
   * @param {string} [isActive]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetCompetitionSchedules(
    competitionId?: string,
    competitionScheduleId?: string,
    isActive?: string,
    pageNo?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetCompetitionSchedules(
        competitionId,
        competitionScheduleId,
        isActive,
        pageNo,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetCompetitionStartStatistics(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetCompetitionStartStatistics(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetDetailedCompetitionAnalyticsByUser(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetDetailedCompetitionAnalyticsByUser(
        competitionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiGetInvites(
    competitionId: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiGetInvites(competitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {CompetitionInvitationBody} competitionInvitationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiInviteUsersCompetition(
    competitionId: string,
    competitionInvitationBody: CompetitionInvitationBody,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiInviteUsersCompetition(
        competitionId,
        competitionInvitationBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} alias
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiIsCompetitionAliasAvailable(
    alias: string,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiIsCompetitionAliasAvailable(alias, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiSendCertificate(options?: AxiosRequestConfig) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiSendCertificate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpsertCompetitionBody} upsertCompetitionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiUpsertCompetition(
    upsertCompetitionBody: UpsertCompetitionBody,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiUpsertCompetition(upsertCompetitionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {UpsertScheduleBody} upsertScheduleBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitionApiApi
   */
  public competitionApiUpsertCompetitionSchedule(
    competitionId: string,
    upsertScheduleBody: UpsertScheduleBody,
    options?: AxiosRequestConfig,
  ) {
    return CompetitionApiApiFp(this.configuration)
      .competitionApiUpsertCompetitionSchedule(
        competitionId,
        upsertScheduleBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CouponApiApi - axios parameter creator
 * @export
 */
export const CouponApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiCreateCoupon: async (
      createCouponBody: CreateCouponBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCouponBody' is not null or undefined
      assertParamExists(
        'couponApiCreateCoupon',
        'createCouponBody',
        createCouponBody,
      );
      const localVarPath = `/admin/coupon`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCouponBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiDeleteCouponById: async (
      couponId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'couponId' is not null or undefined
      assertParamExists('couponApiDeleteCouponById', 'couponId', couponId);
      const localVarPath = `/admin/coupon/{coupon_id}`.replace(
        `{${'coupon_id'}}`,
        encodeURIComponent(String(couponId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiGetCouponById: async (
      couponId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'couponId' is not null or undefined
      assertParamExists('couponApiGetCouponById', 'couponId', couponId);
      const localVarPath = `/admin/coupon/{coupon_id}`.replace(
        `{${'coupon_id'}}`,
        encodeURIComponent(String(couponId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiGetCouponList: async (
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/coupon`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} couponId
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiUpdateCouponById: async (
      couponId: string,
      createCouponBody: CreateCouponBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'couponId' is not null or undefined
      assertParamExists('couponApiUpdateCouponById', 'couponId', couponId);
      // verify required parameter 'createCouponBody' is not null or undefined
      assertParamExists(
        'couponApiUpdateCouponById',
        'createCouponBody',
        createCouponBody,
      );
      const localVarPath = `/admin/coupon/{coupon_id}`.replace(
        `{${'coupon_id'}}`,
        encodeURIComponent(String(couponId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCouponBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [couponCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiValidateCoupon: async (
      couponCode?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/coupon/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (couponCode !== undefined) {
        localVarQueryParameter['coupon_code'] = couponCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CouponApiApi - functional programming interface
 * @export
 */
export const CouponApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CouponApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiCreateCoupon(
      createCouponBody: CreateCouponBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCouponResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiCreateCoupon(
        createCouponBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiDeleteCouponById(
      couponId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiDeleteCouponById(
        couponId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiGetCouponById(
      couponId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCouponResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiGetCouponById(
        couponId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiGetCouponList(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllCouponsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiGetCouponList(
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} couponId
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiUpdateCouponById(
      couponId: string,
      createCouponBody: CreateCouponBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCouponResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiUpdateCouponById(
        couponId,
        createCouponBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [couponCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponApiValidateCoupon(
      couponCode?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateCouponResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponApiValidateCoupon(
        couponCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CouponApiApi - factory interface
 * @export
 */
export const CouponApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CouponApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiCreateCoupon(
      createCouponBody: CreateCouponBody,
      options?: any,
    ): AxiosPromise<GetCouponResponse> {
      return localVarFp
        .couponApiCreateCoupon(createCouponBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiDeleteCouponById(
      couponId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .couponApiDeleteCouponById(couponId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiGetCouponById(
      couponId: string,
      options?: any,
    ): AxiosPromise<GetCouponResponse> {
      return localVarFp
        .couponApiGetCouponById(couponId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiGetCouponList(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<GetAllCouponsResponse> {
      return localVarFp
        .couponApiGetCouponList(page, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} couponId
     * @param {CreateCouponBody} createCouponBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiUpdateCouponById(
      couponId: string,
      createCouponBody: CreateCouponBody,
      options?: any,
    ): AxiosPromise<GetCouponResponse> {
      return localVarFp
        .couponApiUpdateCouponById(couponId, createCouponBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [couponCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponApiValidateCoupon(
      couponCode?: string,
      options?: any,
    ): AxiosPromise<ValidateCouponResponse> {
      return localVarFp
        .couponApiValidateCoupon(couponCode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CouponApiApi - object-oriented interface
 * @export
 * @class CouponApiApi
 * @extends {BaseAPI}
 */
export class CouponApiApi extends BaseAPI {
  /**
   *
   * @param {CreateCouponBody} createCouponBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiCreateCoupon(
    createCouponBody: CreateCouponBody,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiCreateCoupon(createCouponBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} couponId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiDeleteCouponById(
    couponId: string,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiDeleteCouponById(couponId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} couponId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiGetCouponById(
    couponId: string,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiGetCouponById(couponId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiGetCouponList(
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiGetCouponList(page, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} couponId
   * @param {CreateCouponBody} createCouponBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiUpdateCouponById(
    couponId: string,
    createCouponBody: CreateCouponBody,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiUpdateCouponById(couponId, createCouponBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [couponCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CouponApiApi
   */
  public couponApiValidateCoupon(
    couponCode?: string,
    options?: AxiosRequestConfig,
  ) {
    return CouponApiApiFp(this.configuration)
      .couponApiValidateCoupon(couponCode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CreditsApiApi - axios parameter creator
 * @export
 */
export const CreditsApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiGetCreditConsumptionHistory: async (
      page?: number,
      pageSize?: number,
      transactionType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/credits/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (transactionType !== undefined) {
        localVarQueryParameter['transaction_type'] = transactionType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiGetCreditCost: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/credits/cost`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UseCreditsBody} useCreditsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiUseCredits: async (
      useCreditsBody: UseCreditsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'useCreditsBody' is not null or undefined
      assertParamExists(
        'creditsApiUseCredits',
        'useCreditsBody',
        useCreditsBody,
      );
      const localVarPath = `/credits/use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        useCreditsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreditsApiApi - functional programming interface
 * @export
 */
export const CreditsApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CreditsApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditsApiGetCreditConsumptionHistory(
      page?: number,
      pageSize?: number,
      transactionType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCreditConsumptionHistoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditsApiGetCreditConsumptionHistory(
        page,
        pageSize,
        transactionType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditsApiGetCreditCost(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCreditValuesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditsApiGetCreditCost(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UseCreditsBody} useCreditsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creditsApiUseCredits(
      useCreditsBody: UseCreditsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UseCreditsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creditsApiUseCredits(
        useCreditsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CreditsApiApi - factory interface
 * @export
 */
export const CreditsApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CreditsApiApiFp(configuration);
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [transactionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiGetCreditConsumptionHistory(
      page?: number,
      pageSize?: number,
      transactionType?: string,
      options?: any,
    ): AxiosPromise<GetCreditConsumptionHistoryResponse> {
      return localVarFp
        .creditsApiGetCreditConsumptionHistory(
          page,
          pageSize,
          transactionType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiGetCreditCost(
      options?: any,
    ): AxiosPromise<GetCreditValuesResponse> {
      return localVarFp
        .creditsApiGetCreditCost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UseCreditsBody} useCreditsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creditsApiUseCredits(
      useCreditsBody: UseCreditsBody,
      options?: any,
    ): AxiosPromise<UseCreditsResponse> {
      return localVarFp
        .creditsApiUseCredits(useCreditsBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CreditsApiApi - object-oriented interface
 * @export
 * @class CreditsApiApi
 * @extends {BaseAPI}
 */
export class CreditsApiApi extends BaseAPI {
  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [transactionType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditsApiApi
   */
  public creditsApiGetCreditConsumptionHistory(
    page?: number,
    pageSize?: number,
    transactionType?: string,
    options?: AxiosRequestConfig,
  ) {
    return CreditsApiApiFp(this.configuration)
      .creditsApiGetCreditConsumptionHistory(
        page,
        pageSize,
        transactionType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditsApiApi
   */
  public creditsApiGetCreditCost(options?: AxiosRequestConfig) {
    return CreditsApiApiFp(this.configuration)
      .creditsApiGetCreditCost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UseCreditsBody} useCreditsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreditsApiApi
   */
  public creditsApiUseCredits(
    useCreditsBody: UseCreditsBody,
    options?: AxiosRequestConfig,
  ) {
    return CreditsApiApiFp(this.configuration)
      .creditsApiUseCredits(useCreditsBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DashboardApiApi - axios parameter creator
 * @export
 */
export const DashboardApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiDateWiseActivityStats: async (
      domainId?: string,
      endDate?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/date-wise-activity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiGetDashboard: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CompleteDashboardProfilebody} completeDashboardProfilebody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiUpdateDashboardProfile: async (
      completeDashboardProfilebody: CompleteDashboardProfilebody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'completeDashboardProfilebody' is not null or undefined
      assertParamExists(
        'dashboardApiUpdateDashboardProfile',
        'completeDashboardProfilebody',
        completeDashboardProfilebody,
      );
      const localVarPath = `/dashboard/profile-update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeDashboardProfilebody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DashboardApiApi - functional programming interface
 * @export
 */
export const DashboardApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DashboardApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardApiDateWiseActivityStats(
      domainId?: string,
      endDate?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DateWiseActivityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardApiDateWiseActivityStats(
        domainId,
        endDate,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardApiGetDashboard(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDashboardResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardApiGetDashboard(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CompleteDashboardProfilebody} completeDashboardProfilebody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardApiUpdateDashboardProfile(
      completeDashboardProfilebody: CompleteDashboardProfilebody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompleteDashboardProfileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardApiUpdateDashboardProfile(
        completeDashboardProfilebody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DashboardApiApi - factory interface
 * @export
 */
export const DashboardApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DashboardApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiDateWiseActivityStats(
      domainId?: string,
      endDate?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<DateWiseActivityResponse> {
      return localVarFp
        .dashboardApiDateWiseActivityStats(
          domainId,
          endDate,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiGetDashboard(
      options?: any,
    ): AxiosPromise<GetDashboardResponse> {
      return localVarFp
        .dashboardApiGetDashboard(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CompleteDashboardProfilebody} completeDashboardProfilebody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardApiUpdateDashboardProfile(
      completeDashboardProfilebody: CompleteDashboardProfilebody,
      options?: any,
    ): AxiosPromise<CompleteDashboardProfileResponse> {
      return localVarFp
        .dashboardApiUpdateDashboardProfile(
          completeDashboardProfilebody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DashboardApiApi - object-oriented interface
 * @export
 * @class DashboardApiApi
 * @extends {BaseAPI}
 */
export class DashboardApiApi extends BaseAPI {
  /**
   *
   * @param {string} [domainId]
   * @param {string} [endDate]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApiApi
   */
  public dashboardApiDateWiseActivityStats(
    domainId?: string,
    endDate?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return DashboardApiApiFp(this.configuration)
      .dashboardApiDateWiseActivityStats(domainId, endDate, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApiApi
   */
  public dashboardApiGetDashboard(options?: AxiosRequestConfig) {
    return DashboardApiApiFp(this.configuration)
      .dashboardApiGetDashboard(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CompleteDashboardProfilebody} completeDashboardProfilebody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApiApi
   */
  public dashboardApiUpdateDashboardProfile(
    completeDashboardProfilebody: CompleteDashboardProfilebody,
    options?: AxiosRequestConfig,
  ) {
    return DashboardApiApiFp(this.configuration)
      .dashboardApiUpdateDashboardProfile(completeDashboardProfilebody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DiscussionApiApi - axios parameter creator
 * @export
 */
export const DiscussionApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiDeleteComments: async (
      commentId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists('discussionApiDeleteComments', 'commentId', commentId);
      const localVarPath = `/comment/{comment_id}`.replace(
        `{${'comment_id'}}`,
        encodeURIComponent(String(commentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [commentType]
     * @param {string} [endDate]
     * @param {string} [nameQuery]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiGetAllComments: async (
      commentType?: string,
      endDate?: string,
      nameQuery?: string,
      page?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/comment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (commentType !== undefined) {
        localVarQueryParameter['comment_type'] = commentType;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (nameQuery !== undefined) {
        localVarQueryParameter['name_query'] = nameQuery;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} commentId
     * @param {UpdateCommentBody} updateCommentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiUpdateComment: async (
      commentId: string,
      updateCommentBody: UpdateCommentBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists('discussionApiUpdateComment', 'commentId', commentId);
      // verify required parameter 'updateCommentBody' is not null or undefined
      assertParamExists(
        'discussionApiUpdateComment',
        'updateCommentBody',
        updateCommentBody,
      );
      const localVarPath = `/comment/{comment_id}`.replace(
        `{${'comment_id'}}`,
        encodeURIComponent(String(commentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCommentBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DiscussionApiApi - functional programming interface
 * @export
 */
export const DiscussionApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DiscussionApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discussionApiDeleteComments(
      commentId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discussionApiDeleteComments(
        commentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [commentType]
     * @param {string} [endDate]
     * @param {string} [nameQuery]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discussionApiGetAllComments(
      commentType?: string,
      endDate?: string,
      nameQuery?: string,
      page?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllComments>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discussionApiGetAllComments(
        commentType,
        endDate,
        nameQuery,
        page,
        pageSize,
        sortBy,
        sortOrder,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} commentId
     * @param {UpdateCommentBody} updateCommentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discussionApiUpdateComment(
      commentId: string,
      updateCommentBody: UpdateCommentBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateCommentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discussionApiUpdateComment(
        commentId,
        updateCommentBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DiscussionApiApi - factory interface
 * @export
 */
export const DiscussionApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DiscussionApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} commentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiDeleteComments(
      commentId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .discussionApiDeleteComments(commentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [commentType]
     * @param {string} [endDate]
     * @param {string} [nameQuery]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiGetAllComments(
      commentType?: string,
      endDate?: string,
      nameQuery?: string,
      page?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<GetAllComments> {
      return localVarFp
        .discussionApiGetAllComments(
          commentType,
          endDate,
          nameQuery,
          page,
          pageSize,
          sortBy,
          sortOrder,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} commentId
     * @param {UpdateCommentBody} updateCommentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discussionApiUpdateComment(
      commentId: string,
      updateCommentBody: UpdateCommentBody,
      options?: any,
    ): AxiosPromise<UpdateCommentResponse> {
      return localVarFp
        .discussionApiUpdateComment(commentId, updateCommentBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DiscussionApiApi - object-oriented interface
 * @export
 * @class DiscussionApiApi
 * @extends {BaseAPI}
 */
export class DiscussionApiApi extends BaseAPI {
  /**
   *
   * @param {string} commentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscussionApiApi
   */
  public discussionApiDeleteComments(
    commentId: string,
    options?: AxiosRequestConfig,
  ) {
    return DiscussionApiApiFp(this.configuration)
      .discussionApiDeleteComments(commentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [commentType]
   * @param {string} [endDate]
   * @param {string} [nameQuery]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscussionApiApi
   */
  public discussionApiGetAllComments(
    commentType?: string,
    endDate?: string,
    nameQuery?: string,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return DiscussionApiApiFp(this.configuration)
      .discussionApiGetAllComments(
        commentType,
        endDate,
        nameQuery,
        page,
        pageSize,
        sortBy,
        sortOrder,
        startDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} commentId
   * @param {UpdateCommentBody} updateCommentBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscussionApiApi
   */
  public discussionApiUpdateComment(
    commentId: string,
    updateCommentBody: UpdateCommentBody,
    options?: AxiosRequestConfig,
  ) {
    return DiscussionApiApiFp(this.configuration)
      .discussionApiUpdateComment(commentId, updateCommentBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DomainApiApi - axios parameter creator
 * @export
 */
export const DomainApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [status]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    domainApiGetDomain: async (
      name?: string,
      page?: string,
      pageSize?: string,
      status?: string,
      type?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/domain`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DomainApiApi - functional programming interface
 * @export
 */
export const DomainApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DomainApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [status]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async domainApiGetDomain(
      name?: string,
      page?: string,
      pageSize?: string,
      status?: string,
      type?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetDomainListResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.domainApiGetDomain(
        name,
        page,
        pageSize,
        status,
        type,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DomainApiApi - factory interface
 * @export
 */
export const DomainApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DomainApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [status]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    domainApiGetDomain(
      name?: string,
      page?: string,
      pageSize?: string,
      status?: string,
      type?: string,
      options?: any,
    ): AxiosPromise<Array<GetDomainListResponse>> {
      return localVarFp
        .domainApiGetDomain(name, page, pageSize, status, type, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DomainApiApi - object-oriented interface
 * @export
 * @class DomainApiApi
 * @extends {BaseAPI}
 */
export class DomainApiApi extends BaseAPI {
  /**
   *
   * @param {string} [name]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [status]
   * @param {string} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DomainApiApi
   */
  public domainApiGetDomain(
    name?: string,
    page?: string,
    pageSize?: string,
    status?: string,
    type?: string,
    options?: AxiosRequestConfig,
  ) {
    return DomainApiApiFp(this.configuration)
      .domainApiGetDomain(name, page, pageSize, status, type, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExamApiApi - axios parameter creator
 * @export
 */
export const ExamApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiCreateExamDetail: async (
      createExamBody: CreateExamBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createExamBody' is not null or undefined
      assertParamExists(
        'examApiCreateExamDetail',
        'createExamBody',
        createExamBody,
      );
      const localVarPath = `/admin/exam`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createExamBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody} bulkDeleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiDeleteExamDetail: async (
      bulkDeleteBody: BulkDeleteBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody' is not null or undefined
      assertParamExists(
        'examApiDeleteExamDetail',
        'bulkDeleteBody',
        bulkDeleteBody,
      );
      const localVarPath = `/admin/exam/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiExamAutoComplete: async (
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/exam/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [examId]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiGetExamDetail: async (
      examId?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/exam`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (examId !== undefined) {
        localVarQueryParameter['exam_id'] = examId;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} examId
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiUpdateExamDetail: async (
      examId: string,
      createExamBody: CreateExamBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'examId' is not null or undefined
      assertParamExists('examApiUpdateExamDetail', 'examId', examId);
      // verify required parameter 'createExamBody' is not null or undefined
      assertParamExists(
        'examApiUpdateExamDetail',
        'createExamBody',
        createExamBody,
      );
      const localVarPath = `/admin/exam/{exam_id}`.replace(
        `{${'exam_id'}}`,
        encodeURIComponent(String(examId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createExamBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiUploadExamImage: async (
      imageFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageFile' is not null or undefined
      assertParamExists('examApiUploadExamImage', 'imageFile', imageFile);
      const localVarPath = `/exam/upload-exam-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (imageFile !== undefined) {
        localVarFormParams.append('image_file', imageFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExamApiApi - functional programming interface
 * @export
 */
export const ExamApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ExamApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiCreateExamDetail(
      createExamBody: CreateExamBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateExamResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiCreateExamDetail(
        createExamBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody} bulkDeleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiDeleteExamDetail(
      bulkDeleteBody: BulkDeleteBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkDeleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiDeleteExamDetail(
        bulkDeleteBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiExamAutoComplete(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExamAutocompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiExamAutoComplete(
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [examId]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiGetExamDetail(
      examId?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AllExamDetailsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiGetExamDetail(
        examId,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} examId
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiUpdateExamDetail(
      examId: string,
      createExamBody: CreateExamBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateExamResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiUpdateExamDetail(
        examId,
        createExamBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async examApiUploadExamImage(
      imageFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.examApiUploadExamImage(
        imageFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExamApiApi - factory interface
 * @export
 */
export const ExamApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExamApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiCreateExamDetail(
      createExamBody: CreateExamBody,
      options?: any,
    ): AxiosPromise<CreateExamResponse> {
      return localVarFp
        .examApiCreateExamDetail(createExamBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody} bulkDeleteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiDeleteExamDetail(
      bulkDeleteBody: BulkDeleteBody,
      options?: any,
    ): AxiosPromise<BulkDeleteResponse> {
      return localVarFp
        .examApiDeleteExamDetail(bulkDeleteBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiExamAutoComplete(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<ExamAutocompleteResponse> {
      return localVarFp
        .examApiExamAutoComplete(page, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [examId]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiGetExamDetail(
      examId?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<AllExamDetailsResponse> {
      return localVarFp
        .examApiGetExamDetail(
          examId,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          status,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} examId
     * @param {CreateExamBody} createExamBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiUpdateExamDetail(
      examId: string,
      createExamBody: CreateExamBody,
      options?: any,
    ): AxiosPromise<UpdateExamResponse> {
      return localVarFp
        .examApiUpdateExamDetail(examId, createExamBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    examApiUploadExamImage(
      imageFile: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .examApiUploadExamImage(imageFile, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ExamApiApi - object-oriented interface
 * @export
 * @class ExamApiApi
 * @extends {BaseAPI}
 */
export class ExamApiApi extends BaseAPI {
  /**
   *
   * @param {CreateExamBody} createExamBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiCreateExamDetail(
    createExamBody: CreateExamBody,
    options?: AxiosRequestConfig,
  ) {
    return ExamApiApiFp(this.configuration)
      .examApiCreateExamDetail(createExamBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody} bulkDeleteBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiDeleteExamDetail(
    bulkDeleteBody: BulkDeleteBody,
    options?: AxiosRequestConfig,
  ) {
    return ExamApiApiFp(this.configuration)
      .examApiDeleteExamDetail(bulkDeleteBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiExamAutoComplete(
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return ExamApiApiFp(this.configuration)
      .examApiExamAutoComplete(page, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [examId]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiGetExamDetail(
    examId?: string,
    pageNo?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return ExamApiApiFp(this.configuration)
      .examApiGetExamDetail(
        examId,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} examId
   * @param {CreateExamBody} createExamBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiUpdateExamDetail(
    examId: string,
    createExamBody: CreateExamBody,
    options?: AxiosRequestConfig,
  ) {
    return ExamApiApiFp(this.configuration)
      .examApiUpdateExamDetail(examId, createExamBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} imageFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExamApiApi
   */
  public examApiUploadExamImage(imageFile: any, options?: AxiosRequestConfig) {
    return ExamApiApiFp(this.configuration)
      .examApiUploadExamImage(imageFile, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FestApiApi - axios parameter creator
 * @export
 */
export const FestApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiCreateFest: async (
      createFestBody: CreateFestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFestBody' is not null or undefined
      assertParamExists('festApiCreateFest', 'createFestBody', createFestBody);
      const localVarPath = `/admin/fest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiCreateFestEvent: async (
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiCreateFestEvent', 'festId', festId);
      // verify required parameter 'createFestEventBody' is not null or undefined
      assertParamExists(
        'festApiCreateFestEvent',
        'createFestEventBody',
        createFestEventBody,
      );
      const localVarPath = `/admin/fest/{fest_id}/event`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFestEventBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiDeleteFestById: async (
      festId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiDeleteFestById', 'festId', festId);
      const localVarPath = `/admin/fest/{fest_id}`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiDeleteFestEventById: async (
      eventId: string,
      festId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('festApiDeleteFestEventById', 'eventId', eventId);
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiDeleteFestEventById', 'festId', festId);
      const localVarPath = `/admin/fest/{fest_id}/event/{event_id}`
        .replace(`{${'event_id'}}`, encodeURIComponent(String(eventId)))
        .replace(`{${'fest_id'}}`, encodeURIComponent(String(festId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestById: async (
      festId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiGetFestById', 'festId', festId);
      const localVarPath = `/admin/fest/{fest_id}`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventById: async (
      eventId: string,
      festId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('festApiGetFestEventById', 'eventId', eventId);
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiGetFestEventById', 'festId', festId);
      const localVarPath = `/admin/fest/{fest_id}/event/{event_id}`
        .replace(`{${'event_id'}}`, encodeURIComponent(String(eventId)))
        .replace(`{${'fest_id'}}`, encodeURIComponent(String(festId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventByIdNonAdmin: async (
      eventId: string,
      festId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('festApiGetFestEventByIdNonAdmin', 'eventId', eventId);
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiGetFestEventByIdNonAdmin', 'festId', festId);
      const localVarPath = `/fest/{fest_id}/event/{event_id}`
        .replace(`{${'event_id'}}`, encodeURIComponent(String(eventId)))
        .replace(`{${'fest_id'}}`, encodeURIComponent(String(festId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventList: async (
      festId: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiGetFestEventList', 'festId', festId);
      const localVarPath = `/admin/fest/{fest_id}/event`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventListNonAdmin: async (
      festId: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiGetFestEventListNonAdmin', 'festId', festId);
      const localVarPath = `/fest/{fest_id}/event`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestList: async (
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/fest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFests: async (
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/fest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiUpdateFestById: async (
      festId: string,
      createFestBody: CreateFestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiUpdateFestById', 'festId', festId);
      // verify required parameter 'createFestBody' is not null or undefined
      assertParamExists(
        'festApiUpdateFestById',
        'createFestBody',
        createFestBody,
      );
      const localVarPath = `/admin/fest/{fest_id}`.replace(
        `{${'fest_id'}}`,
        encodeURIComponent(String(festId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiUpdateFestEventById: async (
      eventId: string,
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('festApiUpdateFestEventById', 'eventId', eventId);
      // verify required parameter 'festId' is not null or undefined
      assertParamExists('festApiUpdateFestEventById', 'festId', festId);
      // verify required parameter 'createFestEventBody' is not null or undefined
      assertParamExists(
        'festApiUpdateFestEventById',
        'createFestEventBody',
        createFestEventBody,
      );
      const localVarPath = `/admin/fest/{fest_id}/event/{event_id}`
        .replace(`{${'event_id'}}`, encodeURIComponent(String(eventId)))
        .replace(`{${'fest_id'}}`, encodeURIComponent(String(festId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFestEventBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FestApiApi - functional programming interface
 * @export
 */
export const FestApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = FestApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiCreateFest(
      createFestBody: CreateFestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiCreateFest(
        createFestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiCreateFestEvent(
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestEventResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiCreateFestEvent(
        festId,
        createFestEventBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiDeleteFestById(
      festId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiDeleteFestById(
        festId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiDeleteFestEventById(
      eventId: string,
      festId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiDeleteFestEventById(
        eventId,
        festId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestById(
      festId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestById(
        festId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestEventById(
      eventId: string,
      festId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestEventResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestEventById(
        eventId,
        festId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestEventByIdNonAdmin(
      eventId: string,
      festId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestEventResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestEventByIdNonAdmin(
        eventId,
        festId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestEventList(
      festId: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllFestEventsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestEventList(
        festId,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestEventListNonAdmin(
      festId: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllFestEventsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestEventListNonAdmin(
        festId,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFestList(
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllFestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFestList(
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiGetFests(
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllFestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiGetFests(
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiUpdateFestById(
      festId: string,
      createFestBody: CreateFestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiUpdateFestById(
        festId,
        createFestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async festApiUpdateFestEventById(
      eventId: string,
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFestEventResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.festApiUpdateFestEventById(
        eventId,
        festId,
        createFestEventBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FestApiApi - factory interface
 * @export
 */
export const FestApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FestApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiCreateFest(
      createFestBody: CreateFestBody,
      options?: any,
    ): AxiosPromise<GetFestDetailResponse> {
      return localVarFp
        .festApiCreateFest(createFestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiCreateFestEvent(
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options?: any,
    ): AxiosPromise<GetFestEventResponse> {
      return localVarFp
        .festApiCreateFestEvent(festId, createFestEventBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiDeleteFestById(
      festId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .festApiDeleteFestById(festId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiDeleteFestEventById(
      eventId: string,
      festId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .festApiDeleteFestEventById(eventId, festId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestById(
      festId: string,
      options?: any,
    ): AxiosPromise<GetFestDetailResponse> {
      return localVarFp
        .festApiGetFestById(festId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventById(
      eventId: string,
      festId: string,
      options?: any,
    ): AxiosPromise<GetFestEventResponse> {
      return localVarFp
        .festApiGetFestEventById(eventId, festId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventByIdNonAdmin(
      eventId: string,
      festId: string,
      options?: any,
    ): AxiosPromise<GetFestEventResponse> {
      return localVarFp
        .festApiGetFestEventByIdNonAdmin(eventId, festId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventList(
      festId: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetAllFestEventsResponse> {
      return localVarFp
        .festApiGetFestEventList(festId, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestEventListNonAdmin(
      festId: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetAllFestEventsResponse> {
      return localVarFp
        .festApiGetFestEventListNonAdmin(festId, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFestList(
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetAllFestsResponse> {
      return localVarFp
        .festApiGetFestList(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiGetFests(
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetAllFestsResponse> {
      return localVarFp
        .festApiGetFests(page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} festId
     * @param {CreateFestBody} createFestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiUpdateFestById(
      festId: string,
      createFestBody: CreateFestBody,
      options?: any,
    ): AxiosPromise<GetFestDetailResponse> {
      return localVarFp
        .festApiUpdateFestById(festId, createFestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} eventId
     * @param {string} festId
     * @param {CreateFestEventBody} createFestEventBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    festApiUpdateFestEventById(
      eventId: string,
      festId: string,
      createFestEventBody: CreateFestEventBody,
      options?: any,
    ): AxiosPromise<GetFestEventResponse> {
      return localVarFp
        .festApiUpdateFestEventById(
          eventId,
          festId,
          createFestEventBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FestApiApi - object-oriented interface
 * @export
 * @class FestApiApi
 * @extends {BaseAPI}
 */
export class FestApiApi extends BaseAPI {
  /**
   *
   * @param {CreateFestBody} createFestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiCreateFest(
    createFestBody: CreateFestBody,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiCreateFest(createFestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {CreateFestEventBody} createFestEventBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiCreateFestEvent(
    festId: string,
    createFestEventBody: CreateFestEventBody,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiCreateFestEvent(festId, createFestEventBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiDeleteFestById(festId: string, options?: AxiosRequestConfig) {
    return FestApiApiFp(this.configuration)
      .festApiDeleteFestById(festId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} eventId
   * @param {string} festId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiDeleteFestEventById(
    eventId: string,
    festId: string,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiDeleteFestEventById(eventId, festId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestById(festId: string, options?: AxiosRequestConfig) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestById(festId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} eventId
   * @param {string} festId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestEventById(
    eventId: string,
    festId: string,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestEventById(eventId, festId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} eventId
   * @param {string} festId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestEventByIdNonAdmin(
    eventId: string,
    festId: string,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestEventByIdNonAdmin(eventId, festId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestEventList(
    festId: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestEventList(festId, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestEventListNonAdmin(
    festId: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestEventListNonAdmin(festId, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFestList(
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFestList(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiGetFests(
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiGetFests(page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} festId
   * @param {CreateFestBody} createFestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiUpdateFestById(
    festId: string,
    createFestBody: CreateFestBody,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiUpdateFestById(festId, createFestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} eventId
   * @param {string} festId
   * @param {CreateFestEventBody} createFestEventBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FestApiApi
   */
  public festApiUpdateFestEventById(
    eventId: string,
    festId: string,
    createFestEventBody: CreateFestEventBody,
    options?: AxiosRequestConfig,
  ) {
    return FestApiApiFp(this.configuration)
      .festApiUpdateFestEventById(eventId, festId, createFestEventBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApiApi - axios parameter creator
 * @export
 */
export const FilesApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkResumeApplicantUserIdsBody} bulkResumeApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiBulkDownloadResume: async (
      jobId: string,
      orgId: string,
      bulkResumeApplicantUserIdsBody: BulkResumeApplicantUserIdsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('filesApiBulkDownloadResume', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('filesApiBulkDownloadResume', 'orgId', orgId);
      // verify required parameter 'bulkResumeApplicantUserIdsBody' is not null or undefined
      assertParamExists(
        'filesApiBulkDownloadResume',
        'bulkResumeApplicantUserIdsBody',
        bulkResumeApplicantUserIdsBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/resume/bulk`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkResumeApplicantUserIdsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [competition]
     * @param {string} [domain]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tag]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiCreateLeaderboardFile: async (
      competition?: string,
      domain?: string,
      page?: number,
      pageSize?: number,
      tag?: string,
      timePeriod?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/create-leaderboard-csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (competition !== undefined) {
        localVarQueryParameter['competition'] = competition;
      }

      if (domain !== undefined) {
        localVarQueryParameter['domain'] = domain;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag;
      }

      if (timePeriod !== undefined) {
        localVarQueryParameter['time_period'] = timePeriod;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [fileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiDownloadFile: async (
      fileUrl?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/files/get-s3-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileUrl !== undefined) {
        localVarQueryParameter['file_url'] = fileUrl;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiGetAllInvoiceInZip: async (
      dateMax?: string,
      dateMin?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/get-invoice-zip`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (dateMax !== undefined) {
        localVarQueryParameter['date_max'] = dateMax;
      }

      if (dateMin !== undefined) {
        localVarQueryParameter['date_min'] = dateMin;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadAttachmentToBucket: async (
      file: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('filesApiUploadAttachmentToBucket', 'file', file);
      const localVarPath = `/files/upload-email-attachment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} imageFile
     * @param {boolean} [compress]
     * @param {string} [questionId]
     * @param {string} [tagId]
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadImage: async (
      imageFile: any,
      compress?: boolean,
      questionId?: string,
      tagId?: string,
      domainId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageFile' is not null or undefined
      assertParamExists('filesApiUploadImage', 'imageFile', imageFile);
      const localVarPath = `/files/upload-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (compress !== undefined) {
        localVarQueryParameter['compress'] = compress;
      }

      if (questionId !== undefined) {
        localVarFormParams.append('question_id', questionId as any);
      }

      if (tagId !== undefined) {
        localVarFormParams.append('tag_id', tagId as any);
      }

      if (domainId !== undefined) {
        localVarFormParams.append('domain_id', domainId as any);
      }

      if (imageFile !== undefined) {
        localVarFormParams.append('image_file', imageFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadJobChatAttachment: async (
      file: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('filesApiUploadJobChatAttachment', 'file', file);
      const localVarPath = `/files/upload-job-chat-attachments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {any} orgDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadOrgDocument: async (
      orgId: string,
      orgDocument: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('filesApiUploadOrgDocument', 'orgId', orgId);
      // verify required parameter 'orgDocument' is not null or undefined
      assertParamExists(
        'filesApiUploadOrgDocument',
        'orgDocument',
        orgDocument,
      );
      const localVarPath = `/files/upload-org-document`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orgId !== undefined) {
        localVarQueryParameter['org_id'] = orgId;
      }

      if (orgDocument !== undefined) {
        localVarFormParams.append('org_document', orgDocument as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadProjectImage: async (
      file: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('filesApiUploadProjectImage', 'file', file);
      const localVarPath = `/files/upload-project-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadResume: async (
      resumeFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resumeFile' is not null or undefined
      assertParamExists('filesApiUploadResume', 'resumeFile', resumeFile);
      const localVarPath = `/files/upload-resume`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (resumeFile !== undefined) {
        localVarFormParams.append('resume_file', resumeFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApiApi - functional programming interface
 * @export
 */
export const FilesApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkResumeApplicantUserIdsBody} bulkResumeApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiBulkDownloadResume(
      jobId: string,
      orgId: string,
      bulkResumeApplicantUserIdsBody: BulkResumeApplicantUserIdsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiBulkDownloadResume(
        jobId,
        orgId,
        bulkResumeApplicantUserIdsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [competition]
     * @param {string} [domain]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tag]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiCreateLeaderboardFile(
      competition?: string,
      domain?: string,
      page?: number,
      pageSize?: number,
      tag?: string,
      timePeriod?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLeaderboardFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiCreateLeaderboardFile(
        competition,
        domain,
        page,
        pageSize,
        tag,
        timePeriod,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [fileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiDownloadFile(
      fileUrl?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetS3FileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiDownloadFile(
        fileUrl,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiGetAllInvoiceInZip(
      dateMax?: string,
      dateMin?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllInvoiceZipResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiGetAllInvoiceInZip(
        dateMax,
        dateMin,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadAttachmentToBucket(
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmailAttachUploadFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadAttachmentToBucket(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} imageFile
     * @param {boolean} [compress]
     * @param {string} [questionId]
     * @param {string} [tagId]
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadImage(
      imageFile: any,
      compress?: boolean,
      questionId?: string,
      tagId?: string,
      domainId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadImageFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadImage(
        imageFile,
        compress,
        questionId,
        tagId,
        domainId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadJobChatAttachment(
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadImageFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadJobChatAttachment(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {any} orgDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadOrgDocument(
      orgId: string,
      orgDocument: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadOrgDocument(
        orgId,
        orgDocument,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadProjectImage(
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadImageFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadProjectImage(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesApiUploadResume(
      resumeFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesApiUploadResume(
        resumeFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FilesApiApi - factory interface
 * @export
 */
export const FilesApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkResumeApplicantUserIdsBody} bulkResumeApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiBulkDownloadResume(
      jobId: string,
      orgId: string,
      bulkResumeApplicantUserIdsBody: BulkResumeApplicantUserIdsBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .filesApiBulkDownloadResume(
          jobId,
          orgId,
          bulkResumeApplicantUserIdsBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [competition]
     * @param {string} [domain]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tag]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiCreateLeaderboardFile(
      competition?: string,
      domain?: string,
      page?: number,
      pageSize?: number,
      tag?: string,
      timePeriod?: string,
      options?: any,
    ): AxiosPromise<CreateLeaderboardFileResponse> {
      return localVarFp
        .filesApiCreateLeaderboardFile(
          competition,
          domain,
          page,
          pageSize,
          tag,
          timePeriod,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [fileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiDownloadFile(
      fileUrl?: string,
      options?: any,
    ): AxiosPromise<GetS3FileResponse> {
      return localVarFp
        .filesApiDownloadFile(fileUrl, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiGetAllInvoiceInZip(
      dateMax?: string,
      dateMin?: string,
      options?: any,
    ): AxiosPromise<GetAllInvoiceZipResponse> {
      return localVarFp
        .filesApiGetAllInvoiceInZip(dateMax, dateMin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadAttachmentToBucket(
      file: any,
      options?: any,
    ): AxiosPromise<EmailAttachUploadFileResponse> {
      return localVarFp
        .filesApiUploadAttachmentToBucket(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} imageFile
     * @param {boolean} [compress]
     * @param {string} [questionId]
     * @param {string} [tagId]
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadImage(
      imageFile: any,
      compress?: boolean,
      questionId?: string,
      tagId?: string,
      domainId?: string,
      options?: any,
    ): AxiosPromise<UploadImageFileResponse> {
      return localVarFp
        .filesApiUploadImage(
          imageFile,
          compress,
          questionId,
          tagId,
          domainId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadJobChatAttachment(
      file: any,
      options?: any,
    ): AxiosPromise<UploadImageFileResponse> {
      return localVarFp
        .filesApiUploadJobChatAttachment(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {any} orgDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadOrgDocument(
      orgId: string,
      orgDocument: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .filesApiUploadOrgDocument(orgId, orgDocument, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadProjectImage(
      file: any,
      options?: any,
    ): AxiosPromise<UploadImageFileResponse> {
      return localVarFp
        .filesApiUploadProjectImage(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesApiUploadResume(
      resumeFile: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .filesApiUploadResume(resumeFile, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FilesApiApi - object-oriented interface
 * @export
 * @class FilesApiApi
 * @extends {BaseAPI}
 */
export class FilesApiApi extends BaseAPI {
  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {BulkResumeApplicantUserIdsBody} bulkResumeApplicantUserIdsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiBulkDownloadResume(
    jobId: string,
    orgId: string,
    bulkResumeApplicantUserIdsBody: BulkResumeApplicantUserIdsBody,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiBulkDownloadResume(
        jobId,
        orgId,
        bulkResumeApplicantUserIdsBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [competition]
   * @param {string} [domain]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [tag]
   * @param {string} [timePeriod]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiCreateLeaderboardFile(
    competition?: string,
    domain?: string,
    page?: number,
    pageSize?: number,
    tag?: string,
    timePeriod?: string,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiCreateLeaderboardFile(
        competition,
        domain,
        page,
        pageSize,
        tag,
        timePeriod,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [fileUrl]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiDownloadFile(fileUrl?: string, options?: AxiosRequestConfig) {
    return FilesApiApiFp(this.configuration)
      .filesApiDownloadFile(fileUrl, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [dateMax]
   * @param {string} [dateMin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiGetAllInvoiceInZip(
    dateMax?: string,
    dateMin?: string,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiGetAllInvoiceInZip(dateMax, dateMin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadAttachmentToBucket(
    file: any,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadAttachmentToBucket(file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} imageFile
   * @param {boolean} [compress]
   * @param {string} [questionId]
   * @param {string} [tagId]
   * @param {string} [domainId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadImage(
    imageFile: any,
    compress?: boolean,
    questionId?: string,
    tagId?: string,
    domainId?: string,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadImage(
        imageFile,
        compress,
        questionId,
        tagId,
        domainId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadJobChatAttachment(
    file: any,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadJobChatAttachment(file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {any} orgDocument
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadOrgDocument(
    orgId: string,
    orgDocument: any,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadOrgDocument(orgId, orgDocument, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadProjectImage(file: any, options?: AxiosRequestConfig) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadProjectImage(file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} resumeFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApiApi
   */
  public filesApiUploadResume(resumeFile: any, options?: AxiosRequestConfig) {
    return FilesApiApiFp(this.configuration)
      .filesApiUploadResume(resumeFile, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GeneralApiApi - axios parameter creator
 * @export
 */
export const GeneralApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {FeedbackBody} feedbackBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiCreateFeedbackReport: async (
      feedbackBody: FeedbackBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feedbackBody' is not null or undefined
      assertParamExists(
        'generalApiCreateFeedbackReport',
        'feedbackBody',
        feedbackBody,
      );
      const localVarPath = `/feedback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        feedbackBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetAllUserList: async (
      firstName?: string,
      lastName?: string,
      pageNo?: number,
      pageSize?: number,
      userType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/get-user-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (firstName !== undefined) {
        localVarQueryParameter['first_name'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['last_name'] = lastName;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (userType !== undefined) {
        localVarQueryParameter['user_type'] = userType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetBackendConstants: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/constants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetCurrencySymbols: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/currency/symbols`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [areaOfIssue]
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {string} [feedbackType]
     * @param {string} [issueType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetFeedbackReports: async (
      areaOfIssue?: string,
      dateMax?: string,
      dateMin?: string,
      feedbackType?: string,
      issueType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/feedback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (areaOfIssue !== undefined) {
        localVarQueryParameter['area_of_issue'] = areaOfIssue;
      }

      if (dateMax !== undefined) {
        localVarQueryParameter['date_max'] = dateMax;
      }

      if (dateMin !== undefined) {
        localVarQueryParameter['date_min'] = dateMin;
      }

      if (feedbackType !== undefined) {
        localVarQueryParameter['feedback_type'] = feedbackType;
      }

      if (issueType !== undefined) {
        localVarQueryParameter['issue_type'] = issueType;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [maxJoinedOn]
     * @param {string} [maxLastLoginDate]
     * @param {number} [maxQuestionsCreated]
     * @param {number} [maxReferrals]
     * @param {string} [minJoinedOn]
     * @param {string} [minLastLoginDate]
     * @param {number} [minQuestionsCreated]
     * @param {number} [minReferrals]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetUserDetailsList: async (
      domainId?: string,
      firstName?: string,
      lastName?: string,
      maxJoinedOn?: string,
      maxLastLoginDate?: string,
      maxQuestionsCreated?: number,
      maxReferrals?: number,
      minJoinedOn?: string,
      minLastLoginDate?: string,
      minQuestionsCreated?: number,
      minReferrals?: number,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      userType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/user-detail-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['first_name'] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter['last_name'] = lastName;
      }

      if (maxJoinedOn !== undefined) {
        localVarQueryParameter['max_joined_on'] = maxJoinedOn;
      }

      if (maxLastLoginDate !== undefined) {
        localVarQueryParameter['max_last_login_date'] = maxLastLoginDate;
      }

      if (maxQuestionsCreated !== undefined) {
        localVarQueryParameter['max_questions_created'] = maxQuestionsCreated;
      }

      if (maxReferrals !== undefined) {
        localVarQueryParameter['max_referrals'] = maxReferrals;
      }

      if (minJoinedOn !== undefined) {
        localVarQueryParameter['min_joined_on'] = minJoinedOn;
      }

      if (minLastLoginDate !== undefined) {
        localVarQueryParameter['min_last_login_date'] = minLastLoginDate;
      }

      if (minQuestionsCreated !== undefined) {
        localVarQueryParameter['min_questions_created'] = minQuestionsCreated;
      }

      if (minReferrals !== undefined) {
        localVarQueryParameter['min_referrals'] = minReferrals;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      if (userType !== undefined) {
        localVarQueryParameter['user_type'] = userType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {number} [inactiveSince]
     * @param {number} [maxAccuracy]
     * @param {number} [maxCategoryLevel]
     * @param {number} [maxCredits]
     * @param {number} [maxRank]
     * @param {number} [maxScore]
     * @param {number} [minAccuracy]
     * @param {number} [minCategoryLevel]
     * @param {number} [minCredits]
     * @param {number} [minRank]
     * @param {number} [minScore]
     * @param {string} [nameQuery]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetUsersWithAdvancedSearch: async (
      competitionId?: string,
      domainId?: string,
      inactiveSince?: number,
      maxAccuracy?: number,
      maxCategoryLevel?: number,
      maxCredits?: number,
      maxRank?: number,
      maxScore?: number,
      minAccuracy?: number,
      minCategoryLevel?: number,
      minCredits?: number,
      minRank?: number,
      minScore?: number,
      nameQuery?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/advanced-user-search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (competitionId !== undefined) {
        localVarQueryParameter['competition_id'] = competitionId;
      }

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (inactiveSince !== undefined) {
        localVarQueryParameter['inactive_since'] = inactiveSince;
      }

      if (maxAccuracy !== undefined) {
        localVarQueryParameter['max_accuracy'] = maxAccuracy;
      }

      if (maxCategoryLevel !== undefined) {
        localVarQueryParameter['max_category_level'] = maxCategoryLevel;
      }

      if (maxCredits !== undefined) {
        localVarQueryParameter['max_credits'] = maxCredits;
      }

      if (maxRank !== undefined) {
        localVarQueryParameter['max_rank'] = maxRank;
      }

      if (maxScore !== undefined) {
        localVarQueryParameter['max_score'] = maxScore;
      }

      if (minAccuracy !== undefined) {
        localVarQueryParameter['min_accuracy'] = minAccuracy;
      }

      if (minCategoryLevel !== undefined) {
        localVarQueryParameter['min_category_level'] = minCategoryLevel;
      }

      if (minCredits !== undefined) {
        localVarQueryParameter['min_credits'] = minCredits;
      }

      if (minRank !== undefined) {
        localVarQueryParameter['min_rank'] = minRank;
      }

      if (minScore !== undefined) {
        localVarQueryParameter['min_score'] = minScore;
      }

      if (nameQuery !== undefined) {
        localVarQueryParameter['name_query'] = nameQuery;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MergeDuplicateEntityBody} mergeDuplicateEntityBody
     * @param {string} [entityType]
     * @param {string} [mainEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiMergeSimilarOrDuplicateEnitites: async (
      mergeDuplicateEntityBody: MergeDuplicateEntityBody,
      entityType?: string,
      mainEntityId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mergeDuplicateEntityBody' is not null or undefined
      assertParamExists(
        'generalApiMergeSimilarOrDuplicateEnitites',
        'mergeDuplicateEntityBody',
        mergeDuplicateEntityBody,
      );
      const localVarPath = `/admin/merge-duplicates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (entityType !== undefined) {
        localVarQueryParameter['entity_type'] = entityType;
      }

      if (mainEntityId !== undefined) {
        localVarQueryParameter['main_entity_id'] = mainEntityId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mergeDuplicateEntityBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiQuestionAuthorAutocomplete: async (
      domainId?: string,
      page?: string,
      pageSize?: string,
      query?: string,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/author/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiSendAbekusCoreFiles: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/abekus-core/files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [feedbackId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiUpdateUserFeedbackStatus: async (
      feedbackId?: string,
      status?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/feedback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (feedbackId !== undefined) {
        localVarQueryParameter['feedback_id'] = feedbackId;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} mediaFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiUploadFeedbackMedia: async (
      mediaFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mediaFile' is not null or undefined
      assertParamExists(
        'generalApiUploadFeedbackMedia',
        'mediaFile',
        mediaFile,
      );
      const localVarPath = `/feedback/upload-media`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (mediaFile !== undefined) {
        localVarFormParams.append('media_file', mediaFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeneralApiApi - functional programming interface
 * @export
 */
export const GeneralApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneralApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {FeedbackBody} feedbackBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiCreateFeedbackReport(
      feedbackBody: FeedbackBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeedbackResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiCreateFeedbackReport(
        feedbackBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetAllUserList(
      firstName?: string,
      lastName?: string,
      pageNo?: number,
      pageSize?: number,
      userType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetAllUserList(
        firstName,
        lastName,
        pageNo,
        pageSize,
        userType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetBackendConstants(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBackendConstantsRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetBackendConstants(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetCurrencySymbols(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CurrencySymbolRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetCurrencySymbols(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [areaOfIssue]
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {string} [feedbackType]
     * @param {string} [issueType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetFeedbackReports(
      areaOfIssue?: string,
      dateMax?: string,
      dateMin?: string,
      feedbackType?: string,
      issueType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFeedbackReportsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetFeedbackReports(
        areaOfIssue,
        dateMax,
        dateMin,
        feedbackType,
        issueType,
        name,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [maxJoinedOn]
     * @param {string} [maxLastLoginDate]
     * @param {number} [maxQuestionsCreated]
     * @param {number} [maxReferrals]
     * @param {string} [minJoinedOn]
     * @param {string} [minLastLoginDate]
     * @param {number} [minQuestionsCreated]
     * @param {number} [minReferrals]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetUserDetailsList(
      domainId?: string,
      firstName?: string,
      lastName?: string,
      maxJoinedOn?: string,
      maxLastLoginDate?: string,
      maxQuestionsCreated?: number,
      maxReferrals?: number,
      minJoinedOn?: string,
      minLastLoginDate?: string,
      minQuestionsCreated?: number,
      minReferrals?: number,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      userType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserListGeneralResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetUserDetailsList(
        domainId,
        firstName,
        lastName,
        maxJoinedOn,
        maxLastLoginDate,
        maxQuestionsCreated,
        maxReferrals,
        minJoinedOn,
        minLastLoginDate,
        minQuestionsCreated,
        minReferrals,
        pageNo,
        pageSize,
        tagId,
        userType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {number} [inactiveSince]
     * @param {number} [maxAccuracy]
     * @param {number} [maxCategoryLevel]
     * @param {number} [maxCredits]
     * @param {number} [maxRank]
     * @param {number} [maxScore]
     * @param {number} [minAccuracy]
     * @param {number} [minCategoryLevel]
     * @param {number} [minCredits]
     * @param {number} [minRank]
     * @param {number} [minScore]
     * @param {string} [nameQuery]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiGetUsersWithAdvancedSearch(
      competitionId?: string,
      domainId?: string,
      inactiveSince?: number,
      maxAccuracy?: number,
      maxCategoryLevel?: number,
      maxCredits?: number,
      maxRank?: number,
      maxScore?: number,
      minAccuracy?: number,
      minCategoryLevel?: number,
      minCredits?: number,
      minRank?: number,
      minScore?: number,
      nameQuery?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdvancedUserSearchResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiGetUsersWithAdvancedSearch(
        competitionId,
        domainId,
        inactiveSince,
        maxAccuracy,
        maxCategoryLevel,
        maxCredits,
        maxRank,
        maxScore,
        minAccuracy,
        minCategoryLevel,
        minCredits,
        minRank,
        minScore,
        nameQuery,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MergeDuplicateEntityBody} mergeDuplicateEntityBody
     * @param {string} [entityType]
     * @param {string} [mainEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiMergeSimilarOrDuplicateEnitites(
      mergeDuplicateEntityBody: MergeDuplicateEntityBody,
      entityType?: string,
      mainEntityId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MergeDuplicateEntityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiMergeSimilarOrDuplicateEnitites(
        mergeDuplicateEntityBody,
        entityType,
        mainEntityId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiQuestionAuthorAutocomplete(
      domainId?: string,
      page?: string,
      pageSize?: string,
      query?: string,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AuthorAutoCompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiQuestionAuthorAutocomplete(
        domainId,
        page,
        pageSize,
        query,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiSendAbekusCoreFiles(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiSendAbekusCoreFiles(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [feedbackId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiUpdateUserFeedbackStatus(
      feedbackId?: string,
      status?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeedbackResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiUpdateUserFeedbackStatus(
        feedbackId,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} mediaFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generalApiUploadFeedbackMedia(
      mediaFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadFeedbackResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generalApiUploadFeedbackMedia(
        mediaFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GeneralApiApi - factory interface
 * @export
 */
export const GeneralApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeneralApiApiFp(configuration);
  return {
    /**
     *
     * @param {FeedbackBody} feedbackBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiCreateFeedbackReport(
      feedbackBody: FeedbackBody,
      options?: any,
    ): AxiosPromise<FeedbackResponse> {
      return localVarFp
        .generalApiCreateFeedbackReport(feedbackBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetAllUserList(
      firstName?: string,
      lastName?: string,
      pageNo?: number,
      pageSize?: number,
      userType?: string,
      options?: any,
    ): AxiosPromise<GetUserListResponse> {
      return localVarFp
        .generalApiGetAllUserList(
          firstName,
          lastName,
          pageNo,
          pageSize,
          userType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetBackendConstants(
      options?: any,
    ): AxiosPromise<GetBackendConstantsRepsonse> {
      return localVarFp
        .generalApiGetBackendConstants(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetCurrencySymbols(
      options?: any,
    ): AxiosPromise<CurrencySymbolRepsonse> {
      return localVarFp
        .generalApiGetCurrencySymbols(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [areaOfIssue]
     * @param {string} [dateMax]
     * @param {string} [dateMin]
     * @param {string} [feedbackType]
     * @param {string} [issueType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetFeedbackReports(
      areaOfIssue?: string,
      dateMax?: string,
      dateMin?: string,
      feedbackType?: string,
      issueType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userId?: string,
      options?: any,
    ): AxiosPromise<GetFeedbackReportsResponse> {
      return localVarFp
        .generalApiGetFeedbackReports(
          areaOfIssue,
          dateMax,
          dateMin,
          feedbackType,
          issueType,
          name,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          status,
          userId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [firstName]
     * @param {string} [lastName]
     * @param {string} [maxJoinedOn]
     * @param {string} [maxLastLoginDate]
     * @param {number} [maxQuestionsCreated]
     * @param {number} [maxReferrals]
     * @param {string} [minJoinedOn]
     * @param {string} [minLastLoginDate]
     * @param {number} [minQuestionsCreated]
     * @param {number} [minReferrals]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetUserDetailsList(
      domainId?: string,
      firstName?: string,
      lastName?: string,
      maxJoinedOn?: string,
      maxLastLoginDate?: string,
      maxQuestionsCreated?: number,
      maxReferrals?: number,
      minJoinedOn?: string,
      minLastLoginDate?: string,
      minQuestionsCreated?: number,
      minReferrals?: number,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      userType?: string,
      options?: any,
    ): AxiosPromise<GetUserListGeneralResponse> {
      return localVarFp
        .generalApiGetUserDetailsList(
          domainId,
          firstName,
          lastName,
          maxJoinedOn,
          maxLastLoginDate,
          maxQuestionsCreated,
          maxReferrals,
          minJoinedOn,
          minLastLoginDate,
          minQuestionsCreated,
          minReferrals,
          pageNo,
          pageSize,
          tagId,
          userType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {number} [inactiveSince]
     * @param {number} [maxAccuracy]
     * @param {number} [maxCategoryLevel]
     * @param {number} [maxCredits]
     * @param {number} [maxRank]
     * @param {number} [maxScore]
     * @param {number} [minAccuracy]
     * @param {number} [minCategoryLevel]
     * @param {number} [minCredits]
     * @param {number} [minRank]
     * @param {number} [minScore]
     * @param {string} [nameQuery]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiGetUsersWithAdvancedSearch(
      competitionId?: string,
      domainId?: string,
      inactiveSince?: number,
      maxAccuracy?: number,
      maxCategoryLevel?: number,
      maxCredits?: number,
      maxRank?: number,
      maxScore?: number,
      minAccuracy?: number,
      minCategoryLevel?: number,
      minCredits?: number,
      minRank?: number,
      minScore?: number,
      nameQuery?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      options?: any,
    ): AxiosPromise<AdvancedUserSearchResponse> {
      return localVarFp
        .generalApiGetUsersWithAdvancedSearch(
          competitionId,
          domainId,
          inactiveSince,
          maxAccuracy,
          maxCategoryLevel,
          maxCredits,
          maxRank,
          maxScore,
          minAccuracy,
          minCategoryLevel,
          minCredits,
          minRank,
          minScore,
          nameQuery,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          tagId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MergeDuplicateEntityBody} mergeDuplicateEntityBody
     * @param {string} [entityType]
     * @param {string} [mainEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiMergeSimilarOrDuplicateEnitites(
      mergeDuplicateEntityBody: MergeDuplicateEntityBody,
      entityType?: string,
      mainEntityId?: string,
      options?: any,
    ): AxiosPromise<MergeDuplicateEntityResponse> {
      return localVarFp
        .generalApiMergeSimilarOrDuplicateEnitites(
          mergeDuplicateEntityBody,
          entityType,
          mainEntityId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiQuestionAuthorAutocomplete(
      domainId?: string,
      page?: string,
      pageSize?: string,
      query?: string,
      tagId?: string,
      options?: any,
    ): AxiosPromise<AuthorAutoCompleteResponse> {
      return localVarFp
        .generalApiQuestionAuthorAutocomplete(
          domainId,
          page,
          pageSize,
          query,
          tagId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiSendAbekusCoreFiles(options?: any): AxiosPromise<void> {
      return localVarFp
        .generalApiSendAbekusCoreFiles(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [feedbackId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiUpdateUserFeedbackStatus(
      feedbackId?: string,
      status?: string,
      options?: any,
    ): AxiosPromise<FeedbackResponse> {
      return localVarFp
        .generalApiUpdateUserFeedbackStatus(feedbackId, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} mediaFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generalApiUploadFeedbackMedia(
      mediaFile: any,
      options?: any,
    ): AxiosPromise<UploadFeedbackResponse> {
      return localVarFp
        .generalApiUploadFeedbackMedia(mediaFile, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GeneralApiApi - object-oriented interface
 * @export
 * @class GeneralApiApi
 * @extends {BaseAPI}
 */
export class GeneralApiApi extends BaseAPI {
  /**
   *
   * @param {FeedbackBody} feedbackBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiCreateFeedbackReport(
    feedbackBody: FeedbackBody,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiCreateFeedbackReport(feedbackBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [userType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetAllUserList(
    firstName?: string,
    lastName?: string,
    pageNo?: number,
    pageSize?: number,
    userType?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetAllUserList(
        firstName,
        lastName,
        pageNo,
        pageSize,
        userType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetBackendConstants(options?: AxiosRequestConfig) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetBackendConstants(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetCurrencySymbols(options?: AxiosRequestConfig) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetCurrencySymbols(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [areaOfIssue]
   * @param {string} [dateMax]
   * @param {string} [dateMin]
   * @param {string} [feedbackType]
   * @param {string} [issueType]
   * @param {string} [name]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetFeedbackReports(
    areaOfIssue?: string,
    dateMax?: string,
    dateMin?: string,
    feedbackType?: string,
    issueType?: string,
    name?: string,
    pageNo?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    userId?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetFeedbackReports(
        areaOfIssue,
        dateMax,
        dateMin,
        feedbackType,
        issueType,
        name,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [maxJoinedOn]
   * @param {string} [maxLastLoginDate]
   * @param {number} [maxQuestionsCreated]
   * @param {number} [maxReferrals]
   * @param {string} [minJoinedOn]
   * @param {string} [minLastLoginDate]
   * @param {number} [minQuestionsCreated]
   * @param {number} [minReferrals]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [tagId]
   * @param {string} [userType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetUserDetailsList(
    domainId?: string,
    firstName?: string,
    lastName?: string,
    maxJoinedOn?: string,
    maxLastLoginDate?: string,
    maxQuestionsCreated?: number,
    maxReferrals?: number,
    minJoinedOn?: string,
    minLastLoginDate?: string,
    minQuestionsCreated?: number,
    minReferrals?: number,
    pageNo?: number,
    pageSize?: number,
    tagId?: string,
    userType?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetUserDetailsList(
        domainId,
        firstName,
        lastName,
        maxJoinedOn,
        maxLastLoginDate,
        maxQuestionsCreated,
        maxReferrals,
        minJoinedOn,
        minLastLoginDate,
        minQuestionsCreated,
        minReferrals,
        pageNo,
        pageSize,
        tagId,
        userType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [competitionId]
   * @param {string} [domainId]
   * @param {number} [inactiveSince]
   * @param {number} [maxAccuracy]
   * @param {number} [maxCategoryLevel]
   * @param {number} [maxCredits]
   * @param {number} [maxRank]
   * @param {number} [maxScore]
   * @param {number} [minAccuracy]
   * @param {number} [minCategoryLevel]
   * @param {number} [minCredits]
   * @param {number} [minRank]
   * @param {number} [minScore]
   * @param {string} [nameQuery]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiGetUsersWithAdvancedSearch(
    competitionId?: string,
    domainId?: string,
    inactiveSince?: number,
    maxAccuracy?: number,
    maxCategoryLevel?: number,
    maxCredits?: number,
    maxRank?: number,
    maxScore?: number,
    minAccuracy?: number,
    minCategoryLevel?: number,
    minCredits?: number,
    minRank?: number,
    minScore?: number,
    nameQuery?: string,
    pageNo?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: string,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiGetUsersWithAdvancedSearch(
        competitionId,
        domainId,
        inactiveSince,
        maxAccuracy,
        maxCategoryLevel,
        maxCredits,
        maxRank,
        maxScore,
        minAccuracy,
        minCategoryLevel,
        minCredits,
        minRank,
        minScore,
        nameQuery,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        tagId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MergeDuplicateEntityBody} mergeDuplicateEntityBody
   * @param {string} [entityType]
   * @param {string} [mainEntityId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiMergeSimilarOrDuplicateEnitites(
    mergeDuplicateEntityBody: MergeDuplicateEntityBody,
    entityType?: string,
    mainEntityId?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiMergeSimilarOrDuplicateEnitites(
        mergeDuplicateEntityBody,
        entityType,
        mainEntityId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [query]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiQuestionAuthorAutocomplete(
    domainId?: string,
    page?: string,
    pageSize?: string,
    query?: string,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiQuestionAuthorAutocomplete(
        domainId,
        page,
        pageSize,
        query,
        tagId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiSendAbekusCoreFiles(options?: AxiosRequestConfig) {
    return GeneralApiApiFp(this.configuration)
      .generalApiSendAbekusCoreFiles(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [feedbackId]
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiUpdateUserFeedbackStatus(
    feedbackId?: string,
    status?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiUpdateUserFeedbackStatus(feedbackId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} mediaFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApiApi
   */
  public generalApiUploadFeedbackMedia(
    mediaFile: any,
    options?: AxiosRequestConfig,
  ) {
    return GeneralApiApiFp(this.configuration)
      .generalApiUploadFeedbackMedia(mediaFile, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GptApiApi - axios parameter creator
 * @export
 */
export const GptApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiApplicantResumeAnalysis: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists(
        'gptApiApplicantResumeAnalysis',
        'analysisId',
        analysisId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiApplicantResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/analysis/{analysis_id}/resume-pdf`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiBulkAnalysisUploadResume: async (
      jobId: string,
      orgId: string,
      resumeFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiBulkAnalysisUploadResume', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiBulkAnalysisUploadResume', 'orgId', orgId);
      // verify required parameter 'resumeFile' is not null or undefined
      assertParamExists(
        'gptApiBulkAnalysisUploadResume',
        'resumeFile',
        resumeFile,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/upload-resume`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (resumeFile !== undefined) {
        localVarFormParams.append('resume_file', resumeFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {JobResumeAnalysisBody} jobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiCreateApplicantResumeAnalysis: async (
      jobId: string,
      orgId: string,
      jobResumeAnalysisBody: JobResumeAnalysisBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiCreateApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiCreateApplicantResumeAnalysis', 'orgId', orgId);
      // verify required parameter 'jobResumeAnalysisBody' is not null or undefined
      assertParamExists(
        'gptApiCreateApplicantResumeAnalysis',
        'jobResumeAnalysisBody',
        jobResumeAnalysisBody,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/resume`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobResumeAnalysisBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobDescriptionBody} createJobDescriptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiCreateJobDescription: async (
      orgId: string,
      createJobDescriptionBody: CreateJobDescriptionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiCreateJobDescription', 'orgId', orgId);
      // verify required parameter 'createJobDescriptionBody' is not null or undefined
      assertParamExists(
        'gptApiCreateJobDescription',
        'createJobDescriptionBody',
        createJobDescriptionBody,
      );
      const localVarPath = `/org/{org_id}/generated-jd`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createJobDescriptionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteEmptyGptJob: async (
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiDeleteEmptyGptJob', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiDeleteEmptyGptJob', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} gptResponseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteGptResponse: async (
      gptResponseId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gptResponseId' is not null or undefined
      assertParamExists(
        'gptApiDeleteGptResponse',
        'gptResponseId',
        gptResponseId,
      );
      const localVarPath = `/query/{gpt_response_id}`.replace(
        `{${'gpt_response_id'}}`,
        encodeURIComponent(String(gptResponseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteResumeAnalysis: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists('gptApiDeleteResumeAnalysis', 'analysisId', analysisId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiDeleteResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiDeleteResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/analysis/{analysis_id}`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDownloadCsvApplicantResumeAnalysis: async (
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'gptApiDownloadCsvApplicantResumeAnalysis',
        'jobId',
        jobId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'gptApiDownloadCsvApplicantResumeAnalysis',
        'orgId',
        orgId,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/download-resume-csv`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (analysisStatus !== undefined) {
        localVarQueryParameter['analysis_status'] = analysisStatus;
      }

      if (countOnly !== undefined) {
        localVarQueryParameter['count_only'] = countOnly;
      }

      if (hiringStatus !== undefined) {
        localVarQueryParameter['hiring_status'] = hiringStatus;
      }

      if (matchingSkills) {
        localVarQueryParameter['matching_skills'] = matchingSkills;
      }

      if (maxRating !== undefined) {
        localVarQueryParameter['max_rating'] = maxRating;
      }

      if (maxResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_experience_score'
        ] = maxResumeAnalysisExperienceScore;
      }

      if (maxResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_overall_score'
        ] = maxResumeAnalysisOverallScore;
      }

      if (maxResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_skill_score'
        ] = maxResumeAnalysisSkillScore;
      }

      if (minRating !== undefined) {
        localVarQueryParameter['min_rating'] = minRating;
      }

      if (minResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_experience_score'
        ] = minResumeAnalysisExperienceScore;
      }

      if (minResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_overall_score'
        ] = minResumeAnalysisOverallScore;
      }

      if (minResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_skill_score'
        ] = minResumeAnalysisSkillScore;
      }

      if (missingSkills) {
        localVarQueryParameter['missing_skills'] = missingSkills;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDownloadCsvApplicantResumeAnalysisPost: async (
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'gptApiDownloadCsvApplicantResumeAnalysisPost',
        'jobId',
        jobId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'gptApiDownloadCsvApplicantResumeAnalysisPost',
        'orgId',
        orgId,
      );
      // verify required parameter 'listJobResumeAnalysisBody' is not null or undefined
      assertParamExists(
        'gptApiDownloadCsvApplicantResumeAnalysisPost',
        'listJobResumeAnalysisBody',
        listJobResumeAnalysisBody,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/download-resume-csv`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listJobResumeAnalysisBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetApplicantResumeAnalysis: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists(
        'gptApiGetApplicantResumeAnalysis',
        'analysisId',
        analysisId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiGetApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiGetApplicantResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/resume/{analysis_id}`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetApplicantSkills: async (
      jobId: string,
      orgId: string,
      searchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiGetApplicantSkills', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiGetApplicantSkills', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/skills`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jdId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetCreateJobDescription: async (
      jdId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jdId' is not null or undefined
      assertParamExists('gptApiGetCreateJobDescription', 'jdId', jdId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiGetCreateJobDescription', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/generated-jd/{jd_id}`
        .replace(`{${'jd_id'}}`, encodeURIComponent(String(jdId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetGptResponseHistory: async (
      endDate?: string,
      page?: number,
      pageSize?: number,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substr(0, 10)
            : endDate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetNewGptResponseBody} getNewGptResponseBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetNewGptResponse: async (
      getNewGptResponseBody: GetNewGptResponseBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getNewGptResponseBody' is not null or undefined
      assertParamExists(
        'gptApiGetNewGptResponse',
        'getNewGptResponseBody',
        getNewGptResponseBody,
      );
      const localVarPath = `/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getNewGptResponseBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetSelfResumePdf: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/self-resume-analysis/resume-pdf`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetUserResumeAnalysisById: async (
      analysisId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists(
        'gptApiGetUserResumeAnalysisById',
        'analysisId',
        analysisId,
      );
      const localVarPath = `/self-resume-analysis/analysis/{analysis_id}`.replace(
        `{${'analysis_id'}}`,
        encodeURIComponent(String(analysisId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetUserResumeAnalysisByUserid: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/self-resume-analysis/analysis`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiListApplicantResumeAnalysis: async (
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiListApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiListApplicantResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/resume`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (analysisStatus !== undefined) {
        localVarQueryParameter['analysis_status'] = analysisStatus;
      }

      if (countOnly !== undefined) {
        localVarQueryParameter['count_only'] = countOnly;
      }

      if (hiringStatus !== undefined) {
        localVarQueryParameter['hiring_status'] = hiringStatus;
      }

      if (matchingSkills) {
        localVarQueryParameter['matching_skills'] = matchingSkills;
      }

      if (maxRating !== undefined) {
        localVarQueryParameter['max_rating'] = maxRating;
      }

      if (maxResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_experience_score'
        ] = maxResumeAnalysisExperienceScore;
      }

      if (maxResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_overall_score'
        ] = maxResumeAnalysisOverallScore;
      }

      if (maxResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_skill_score'
        ] = maxResumeAnalysisSkillScore;
      }

      if (minRating !== undefined) {
        localVarQueryParameter['min_rating'] = minRating;
      }

      if (minResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_experience_score'
        ] = minResumeAnalysisExperienceScore;
      }

      if (minResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_overall_score'
        ] = minResumeAnalysisOverallScore;
      }

      if (minResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_skill_score'
        ] = minResumeAnalysisSkillScore;
      }

      if (missingSkills) {
        localVarQueryParameter['missing_skills'] = missingSkills;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiListApplicantResumeAnalysisPost: async (
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'gptApiListApplicantResumeAnalysisPost',
        'jobId',
        jobId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'gptApiListApplicantResumeAnalysisPost',
        'orgId',
        orgId,
      );
      // verify required parameter 'listJobResumeAnalysisBody' is not null or undefined
      assertParamExists(
        'gptApiListApplicantResumeAnalysisPost',
        'listJobResumeAnalysisBody',
        listJobResumeAnalysisBody,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/get-resume-list`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listJobResumeAnalysisBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResumeAnalysisBody} resumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiResumeAnalysis: async (
      resumeAnalysisBody: ResumeAnalysisBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resumeAnalysisBody' is not null or undefined
      assertParamExists(
        'gptApiResumeAnalysis',
        'resumeAnalysisBody',
        resumeAnalysisBody,
      );
      const localVarPath = `/self-resume-analysis/resume`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resumeAnalysisBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiRetryAllResumeAnalysis: async (
      jobId: string,
      orgId: string,
      force?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiRetryAllResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiRetryAllResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/resume/retry-all`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiRetryApplicantResumeAnalysis: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      force?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists(
        'gptApiRetryApplicantResumeAnalysis',
        'analysisId',
        analysisId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiRetryApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiRetryApplicantResumeAnalysis', 'orgId', orgId);
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/resume/{analysis_id}/retry`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeHiringStatusBody} updateResumeHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiUpdateResumeHiringStatus: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeHiringStatusBody: UpdateResumeHiringStatusBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists(
        'gptApiUpdateResumeHiringStatus',
        'analysisId',
        analysisId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiUpdateResumeHiringStatus', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiUpdateResumeHiringStatus', 'orgId', orgId);
      // verify required parameter 'updateResumeHiringStatusBody' is not null or undefined
      assertParamExists(
        'gptApiUpdateResumeHiringStatus',
        'updateResumeHiringStatusBody',
        updateResumeHiringStatusBody,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/analysis/{analysis_id}/hiring-status`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateResumeHiringStatusBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeRating} updateResumeRating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiUpdateResumeRating: async (
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeRating: UpdateResumeRating,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'analysisId' is not null or undefined
      assertParamExists('gptApiUpdateResumeRating', 'analysisId', analysisId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('gptApiUpdateResumeRating', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('gptApiUpdateResumeRating', 'orgId', orgId);
      // verify required parameter 'updateResumeRating' is not null or undefined
      assertParamExists(
        'gptApiUpdateResumeRating',
        'updateResumeRating',
        updateResumeRating,
      );
      const localVarPath = `/bulk-resume-analysis/org/{org_id}/job/{job_id}/analysis/{analysis_id}/update-rating`
        .replace(`{${'analysis_id'}}`, encodeURIComponent(String(analysisId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateResumeRating,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GptApiApi - functional programming interface
 * @export
 */
export const GptApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = GptApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiApplicantResumeAnalysis(
        analysisId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiBulkAnalysisUploadResume(
      jobId: string,
      orgId: string,
      resumeFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadApplicantResumeFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiBulkAnalysisUploadResume(
        jobId,
        orgId,
        resumeFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {JobResumeAnalysisBody} jobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiCreateApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      jobResumeAnalysisBody: JobResumeAnalysisBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiCreateApplicantResumeAnalysis(
        jobId,
        orgId,
        jobResumeAnalysisBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobDescriptionBody} createJobDescriptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiCreateJobDescription(
      orgId: string,
      createJobDescriptionBody: CreateJobDescriptionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateJobDescriptionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiCreateJobDescription(
        orgId,
        createJobDescriptionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiDeleteEmptyGptJob(
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiDeleteEmptyGptJob(
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} gptResponseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiDeleteGptResponse(
      gptResponseId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiDeleteGptResponse(
        gptResponseId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiDeleteResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiDeleteResumeAnalysis(
        analysisId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiDownloadCsvApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiDownloadCsvApplicantResumeAnalysis(
        jobId,
        orgId,
        analysisStatus,
        countOnly,
        hiringStatus,
        matchingSkills,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        missingSkills,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiDownloadCsvApplicantResumeAnalysisPost(
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiDownloadCsvApplicantResumeAnalysisPost(
        jobId,
        orgId,
        listJobResumeAnalysisBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetJobResumeAnalysis>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetApplicantResumeAnalysis(
        analysisId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetApplicantSkills(
      jobId: string,
      orgId: string,
      searchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetApplicantSkillsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetApplicantSkills(
        jobId,
        orgId,
        searchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jdId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetCreateJobDescription(
      jdId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetGeneratedJDResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetCreateJobDescription(
        jdId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetGptResponseHistory(
      endDate?: string,
      page?: number,
      pageSize?: number,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetGptResponseHistoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetGptResponseHistory(
        endDate,
        page,
        pageSize,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {GetNewGptResponseBody} getNewGptResponseBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetNewGptResponse(
      getNewGptResponseBody: GetNewGptResponseBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetNewGptResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetNewGptResponse(
        getNewGptResponseBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetSelfResumePdf(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetSelfResumePdf(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetUserResumeAnalysisById(
      analysisId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetUserResumeAnalysisById(
        analysisId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiGetUserResumeAnalysisByUserid(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiGetUserResumeAnalysisByUserid(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiListApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListJobResumeAnalysis>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiListApplicantResumeAnalysis(
        jobId,
        orgId,
        analysisStatus,
        countOnly,
        hiringStatus,
        matchingSkills,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        missingSkills,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiListApplicantResumeAnalysisPost(
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListJobResumeAnalysis>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiListApplicantResumeAnalysisPost(
        jobId,
        orgId,
        listJobResumeAnalysisBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ResumeAnalysisBody} resumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiResumeAnalysis(
      resumeAnalysisBody: ResumeAnalysisBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiResumeAnalysis(
        resumeAnalysisBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiRetryAllResumeAnalysis(
      jobId: string,
      orgId: string,
      force?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiRetryAllResumeAnalysis(
        jobId,
        orgId,
        force,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiRetryApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      force?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResumeAnalysisResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiRetryApplicantResumeAnalysis(
        analysisId,
        jobId,
        orgId,
        force,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeHiringStatusBody} updateResumeHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiUpdateResumeHiringStatus(
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeHiringStatusBody: UpdateResumeHiringStatusBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiUpdateResumeHiringStatus(
        analysisId,
        jobId,
        orgId,
        updateResumeHiringStatusBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeRating} updateResumeRating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gptApiUpdateResumeRating(
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeRating: UpdateResumeRating,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gptApiUpdateResumeRating(
        analysisId,
        jobId,
        orgId,
        updateResumeRating,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GptApiApi - factory interface
 * @export
 */
export const GptApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GptApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gptApiApplicantResumeAnalysis(analysisId, jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {any} resumeFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiBulkAnalysisUploadResume(
      jobId: string,
      orgId: string,
      resumeFile: any,
      options?: any,
    ): AxiosPromise<UploadApplicantResumeFileResponse> {
      return localVarFp
        .gptApiBulkAnalysisUploadResume(jobId, orgId, resumeFile, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {JobResumeAnalysisBody} jobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiCreateApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      jobResumeAnalysisBody: JobResumeAnalysisBody,
      options?: any,
    ): AxiosPromise<ResumeAnalysisResponse> {
      return localVarFp
        .gptApiCreateApplicantResumeAnalysis(
          jobId,
          orgId,
          jobResumeAnalysisBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobDescriptionBody} createJobDescriptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiCreateJobDescription(
      orgId: string,
      createJobDescriptionBody: CreateJobDescriptionBody,
      options?: any,
    ): AxiosPromise<CreateJobDescriptionResponse> {
      return localVarFp
        .gptApiCreateJobDescription(orgId, createJobDescriptionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteEmptyGptJob(
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .gptApiDeleteEmptyGptJob(jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} gptResponseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteGptResponse(
      gptResponseId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .gptApiDeleteGptResponse(gptResponseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDeleteResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .gptApiDeleteResumeAnalysis(analysisId, jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDownloadCsvApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gptApiDownloadCsvApplicantResumeAnalysis(
          jobId,
          orgId,
          analysisStatus,
          countOnly,
          hiringStatus,
          matchingSkills,
          maxRating,
          maxResumeAnalysisExperienceScore,
          maxResumeAnalysisOverallScore,
          maxResumeAnalysisSkillScore,
          minRating,
          minResumeAnalysisExperienceScore,
          minResumeAnalysisOverallScore,
          minResumeAnalysisSkillScore,
          missingSkills,
          page,
          pageSize,
          searchQuery,
          sortBy,
          sortOrder,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiDownloadCsvApplicantResumeAnalysisPost(
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gptApiDownloadCsvApplicantResumeAnalysisPost(
          jobId,
          orgId,
          listJobResumeAnalysisBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<GetJobResumeAnalysis> {
      return localVarFp
        .gptApiGetApplicantResumeAnalysis(analysisId, jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetApplicantSkills(
      jobId: string,
      orgId: string,
      searchQuery?: string,
      options?: any,
    ): AxiosPromise<GetApplicantSkillsResponse> {
      return localVarFp
        .gptApiGetApplicantSkills(jobId, orgId, searchQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jdId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetCreateJobDescription(
      jdId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<GetGeneratedJDResponse> {
      return localVarFp
        .gptApiGetCreateJobDescription(jdId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetGptResponseHistory(
      endDate?: string,
      page?: number,
      pageSize?: number,
      startDate?: string,
      options?: any,
    ): AxiosPromise<GetGptResponseHistoryResponse> {
      return localVarFp
        .gptApiGetGptResponseHistory(
          endDate,
          page,
          pageSize,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetNewGptResponseBody} getNewGptResponseBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetNewGptResponse(
      getNewGptResponseBody: GetNewGptResponseBody,
      options?: any,
    ): AxiosPromise<GetNewGptResponse> {
      return localVarFp
        .gptApiGetNewGptResponse(getNewGptResponseBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetSelfResumePdf(options?: any): AxiosPromise<void> {
      return localVarFp
        .gptApiGetSelfResumePdf(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetUserResumeAnalysisById(
      analysisId: string,
      options?: any,
    ): AxiosPromise<GetUserResumeAnalysisResponse> {
      return localVarFp
        .gptApiGetUserResumeAnalysisById(analysisId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiGetUserResumeAnalysisByUserid(
      options?: any,
    ): AxiosPromise<GetUserResumeAnalysisResponse> {
      return localVarFp
        .gptApiGetUserResumeAnalysisByUserid(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [analysisStatus]
     * @param {boolean} [countOnly]
     * @param {string} [hiringStatus]
     * @param {Array<string>} [matchingSkills]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {Array<string>} [missingSkills]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiListApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      analysisStatus?: string,
      countOnly?: boolean,
      hiringStatus?: string,
      matchingSkills?: Array<string>,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      missingSkills?: Array<string>,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      options?: any,
    ): AxiosPromise<ListJobResumeAnalysis> {
      return localVarFp
        .gptApiListApplicantResumeAnalysis(
          jobId,
          orgId,
          analysisStatus,
          countOnly,
          hiringStatus,
          matchingSkills,
          maxRating,
          maxResumeAnalysisExperienceScore,
          maxResumeAnalysisOverallScore,
          maxResumeAnalysisSkillScore,
          minRating,
          minResumeAnalysisExperienceScore,
          minResumeAnalysisOverallScore,
          minResumeAnalysisSkillScore,
          missingSkills,
          page,
          pageSize,
          searchQuery,
          sortBy,
          sortOrder,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiListApplicantResumeAnalysisPost(
      jobId: string,
      orgId: string,
      listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
      options?: any,
    ): AxiosPromise<ListJobResumeAnalysis> {
      return localVarFp
        .gptApiListApplicantResumeAnalysisPost(
          jobId,
          orgId,
          listJobResumeAnalysisBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResumeAnalysisBody} resumeAnalysisBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiResumeAnalysis(
      resumeAnalysisBody: ResumeAnalysisBody,
      options?: any,
    ): AxiosPromise<ResumeAnalysisResponse> {
      return localVarFp
        .gptApiResumeAnalysis(resumeAnalysisBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiRetryAllResumeAnalysis(
      jobId: string,
      orgId: string,
      force?: string,
      options?: any,
    ): AxiosPromise<ResumeAnalysisResponse> {
      return localVarFp
        .gptApiRetryAllResumeAnalysis(jobId, orgId, force, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiRetryApplicantResumeAnalysis(
      analysisId: string,
      jobId: string,
      orgId: string,
      force?: string,
      options?: any,
    ): AxiosPromise<ResumeAnalysisResponse> {
      return localVarFp
        .gptApiRetryApplicantResumeAnalysis(
          analysisId,
          jobId,
          orgId,
          force,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeHiringStatusBody} updateResumeHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiUpdateResumeHiringStatus(
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeHiringStatusBody: UpdateResumeHiringStatusBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .gptApiUpdateResumeHiringStatus(
          analysisId,
          jobId,
          orgId,
          updateResumeHiringStatusBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} analysisId
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateResumeRating} updateResumeRating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gptApiUpdateResumeRating(
      analysisId: string,
      jobId: string,
      orgId: string,
      updateResumeRating: UpdateResumeRating,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .gptApiUpdateResumeRating(
          analysisId,
          jobId,
          orgId,
          updateResumeRating,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GptApiApi - object-oriented interface
 * @export
 * @class GptApiApi
 * @extends {BaseAPI}
 */
export class GptApiApi extends BaseAPI {
  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiApplicantResumeAnalysis(
    analysisId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiApplicantResumeAnalysis(analysisId, jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {any} resumeFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiBulkAnalysisUploadResume(
    jobId: string,
    orgId: string,
    resumeFile: any,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiBulkAnalysisUploadResume(jobId, orgId, resumeFile, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {JobResumeAnalysisBody} jobResumeAnalysisBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiCreateApplicantResumeAnalysis(
    jobId: string,
    orgId: string,
    jobResumeAnalysisBody: JobResumeAnalysisBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiCreateApplicantResumeAnalysis(
        jobId,
        orgId,
        jobResumeAnalysisBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {CreateJobDescriptionBody} createJobDescriptionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiCreateJobDescription(
    orgId: string,
    createJobDescriptionBody: CreateJobDescriptionBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiCreateJobDescription(orgId, createJobDescriptionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiDeleteEmptyGptJob(
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiDeleteEmptyGptJob(jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} gptResponseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiDeleteGptResponse(
    gptResponseId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiDeleteGptResponse(gptResponseId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiDeleteResumeAnalysis(
    analysisId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiDeleteResumeAnalysis(analysisId, jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} [analysisStatus]
   * @param {boolean} [countOnly]
   * @param {string} [hiringStatus]
   * @param {Array<string>} [matchingSkills]
   * @param {number} [maxRating]
   * @param {number} [maxResumeAnalysisExperienceScore]
   * @param {number} [maxResumeAnalysisOverallScore]
   * @param {number} [maxResumeAnalysisSkillScore]
   * @param {number} [minRating]
   * @param {number} [minResumeAnalysisExperienceScore]
   * @param {number} [minResumeAnalysisOverallScore]
   * @param {number} [minResumeAnalysisSkillScore]
   * @param {Array<string>} [missingSkills]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [searchQuery]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiDownloadCsvApplicantResumeAnalysis(
    jobId: string,
    orgId: string,
    analysisStatus?: string,
    countOnly?: boolean,
    hiringStatus?: string,
    matchingSkills?: Array<string>,
    maxRating?: number,
    maxResumeAnalysisExperienceScore?: number,
    maxResumeAnalysisOverallScore?: number,
    maxResumeAnalysisSkillScore?: number,
    minRating?: number,
    minResumeAnalysisExperienceScore?: number,
    minResumeAnalysisOverallScore?: number,
    minResumeAnalysisSkillScore?: number,
    missingSkills?: Array<string>,
    page?: number,
    pageSize?: number,
    searchQuery?: string,
    sortBy?: string,
    sortOrder?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiDownloadCsvApplicantResumeAnalysis(
        jobId,
        orgId,
        analysisStatus,
        countOnly,
        hiringStatus,
        matchingSkills,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        missingSkills,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiDownloadCsvApplicantResumeAnalysisPost(
    jobId: string,
    orgId: string,
    listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiDownloadCsvApplicantResumeAnalysisPost(
        jobId,
        orgId,
        listJobResumeAnalysisBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetApplicantResumeAnalysis(
    analysisId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetApplicantResumeAnalysis(analysisId, jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} [searchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetApplicantSkills(
    jobId: string,
    orgId: string,
    searchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetApplicantSkills(jobId, orgId, searchQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jdId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetCreateJobDescription(
    jdId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetCreateJobDescription(jdId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [endDate]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetGptResponseHistory(
    endDate?: string,
    page?: number,
    pageSize?: number,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetGptResponseHistory(endDate, page, pageSize, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetNewGptResponseBody} getNewGptResponseBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetNewGptResponse(
    getNewGptResponseBody: GetNewGptResponseBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetNewGptResponse(getNewGptResponseBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetSelfResumePdf(options?: AxiosRequestConfig) {
    return GptApiApiFp(this.configuration)
      .gptApiGetSelfResumePdf(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetUserResumeAnalysisById(
    analysisId: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiGetUserResumeAnalysisById(analysisId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiGetUserResumeAnalysisByUserid(options?: AxiosRequestConfig) {
    return GptApiApiFp(this.configuration)
      .gptApiGetUserResumeAnalysisByUserid(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} [analysisStatus]
   * @param {boolean} [countOnly]
   * @param {string} [hiringStatus]
   * @param {Array<string>} [matchingSkills]
   * @param {number} [maxRating]
   * @param {number} [maxResumeAnalysisExperienceScore]
   * @param {number} [maxResumeAnalysisOverallScore]
   * @param {number} [maxResumeAnalysisSkillScore]
   * @param {number} [minRating]
   * @param {number} [minResumeAnalysisExperienceScore]
   * @param {number} [minResumeAnalysisOverallScore]
   * @param {number} [minResumeAnalysisSkillScore]
   * @param {Array<string>} [missingSkills]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [searchQuery]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiListApplicantResumeAnalysis(
    jobId: string,
    orgId: string,
    analysisStatus?: string,
    countOnly?: boolean,
    hiringStatus?: string,
    matchingSkills?: Array<string>,
    maxRating?: number,
    maxResumeAnalysisExperienceScore?: number,
    maxResumeAnalysisOverallScore?: number,
    maxResumeAnalysisSkillScore?: number,
    minRating?: number,
    minResumeAnalysisExperienceScore?: number,
    minResumeAnalysisOverallScore?: number,
    minResumeAnalysisSkillScore?: number,
    missingSkills?: Array<string>,
    page?: number,
    pageSize?: number,
    searchQuery?: string,
    sortBy?: string,
    sortOrder?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiListApplicantResumeAnalysis(
        jobId,
        orgId,
        analysisStatus,
        countOnly,
        hiringStatus,
        matchingSkills,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        missingSkills,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {ListJobResumeAnalysisBody} listJobResumeAnalysisBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiListApplicantResumeAnalysisPost(
    jobId: string,
    orgId: string,
    listJobResumeAnalysisBody: ListJobResumeAnalysisBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiListApplicantResumeAnalysisPost(
        jobId,
        orgId,
        listJobResumeAnalysisBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ResumeAnalysisBody} resumeAnalysisBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiResumeAnalysis(
    resumeAnalysisBody: ResumeAnalysisBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiResumeAnalysis(resumeAnalysisBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiRetryAllResumeAnalysis(
    jobId: string,
    orgId: string,
    force?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiRetryAllResumeAnalysis(jobId, orgId, force, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiRetryApplicantResumeAnalysis(
    analysisId: string,
    jobId: string,
    orgId: string,
    force?: string,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiRetryApplicantResumeAnalysis(
        analysisId,
        jobId,
        orgId,
        force,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {UpdateResumeHiringStatusBody} updateResumeHiringStatusBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiUpdateResumeHiringStatus(
    analysisId: string,
    jobId: string,
    orgId: string,
    updateResumeHiringStatusBody: UpdateResumeHiringStatusBody,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiUpdateResumeHiringStatus(
        analysisId,
        jobId,
        orgId,
        updateResumeHiringStatusBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} analysisId
   * @param {string} jobId
   * @param {string} orgId
   * @param {UpdateResumeRating} updateResumeRating
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GptApiApi
   */
  public gptApiUpdateResumeRating(
    analysisId: string,
    jobId: string,
    orgId: string,
    updateResumeRating: UpdateResumeRating,
    options?: AxiosRequestConfig,
  ) {
    return GptApiApiFp(this.configuration)
      .gptApiUpdateResumeRating(
        analysisId,
        jobId,
        orgId,
        updateResumeRating,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobApiApi - axios parameter creator
 * @export
 */
export const JobApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} jobId
     * @param {AddApplicantMessageBody} addApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddJobApplicantMsg: async (
      jobId: string,
      addApplicantMessageBody: AddApplicantMessageBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiAddJobApplicantMsg', 'jobId', jobId);
      // verify required parameter 'addApplicantMessageBody' is not null or undefined
      assertParamExists(
        'jobApiAddJobApplicantMsg',
        'addApplicantMessageBody',
        addApplicantMessageBody,
      );
      const localVarPath = `/job/{job_id}/chat`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addApplicantMessageBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {BulkAddJobItemToCartBody} bulkAddJobItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddJobItemsToCart: async (
      orgId: string,
      bulkAddJobItemToCartBody: BulkAddJobItemToCartBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiAddJobItemsToCart', 'orgId', orgId);
      // verify required parameter 'bulkAddJobItemToCartBody' is not null or undefined
      assertParamExists(
        'jobApiAddJobItemsToCart',
        'bulkAddJobItemToCartBody',
        bulkAddJobItemToCartBody,
      );
      const localVarPath = `/org/{org_id}/cart`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkAddJobItemToCartBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AddUserSkillBody} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddUserSkill: async (
      addUserSkillBody: AddUserSkillBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addUserSkillBody' is not null or undefined
      assertParamExists(
        'jobApiAddUserSkill',
        'addUserSkillBody',
        addUserSkillBody,
      );
      const localVarPath = `/job/user-skill`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addUserSkillBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AddUserSkillsInternalBody} addUserSkillsInternalBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddUserSkillInternal: async (
      addUserSkillsInternalBody: AddUserSkillsInternalBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addUserSkillsInternalBody' is not null or undefined
      assertParamExists(
        'jobApiAddUserSkillInternal',
        'addUserSkillsInternalBody',
        addUserSkillsInternalBody,
      );
      const localVarPath = `/internal/job/user-skill`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addUserSkillsInternalBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAdminGetJobSkills: async (
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/job/skills`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} competitionId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAssociateCompetitionWithJob: async (
      competitionId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'competitionId' is not null or undefined
      assertParamExists(
        'jobApiAssociateCompetitionWithJob',
        'competitionId',
        competitionId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiAssociateCompetitionWithJob', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiAssociateCompetitionWithJob', 'orgId', orgId);
      const localVarPath = `/admin/org/{org_id}/job/{job_id}/associate-competition/{competition_id}`
        .replace(
          `{${'competition_id'}}`,
          encodeURIComponent(String(competitionId)),
        )
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkApplicantActionBody} bulkApplicantActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkActionOnApplicants: async (
      jobId: string,
      orgId: string,
      bulkApplicantActionBody: BulkApplicantActionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiBulkActionOnApplicants', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiBulkActionOnApplicants', 'orgId', orgId);
      // verify required parameter 'bulkApplicantActionBody' is not null or undefined
      assertParamExists(
        'jobApiBulkActionOnApplicants',
        'bulkApplicantActionBody',
        bulkApplicantActionBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/bulk-action-applicants`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkApplicantActionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkAddJobApplicantMessageBody} bulkAddJobApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkAddJobApplicantMessage: async (
      jobId: string,
      orgId: string,
      bulkAddJobApplicantMessageBody: BulkAddJobApplicantMessageBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiBulkAddJobApplicantMessage', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiBulkAddJobApplicantMessage', 'orgId', orgId);
      // verify required parameter 'bulkAddJobApplicantMessageBody' is not null or undefined
      assertParamExists(
        'jobApiBulkAddJobApplicantMessage',
        'bulkAddJobApplicantMessageBody',
        bulkAddJobApplicantMessageBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/chat/bulk`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkAddJobApplicantMessageBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkStatusUpdateBody} bulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkJobStatusUpdate: async (
      bulkStatusUpdateBody: BulkStatusUpdateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkStatusUpdateBody' is not null or undefined
      assertParamExists(
        'jobApiBulkJobStatusUpdate',
        'bulkStatusUpdateBody',
        bulkStatusUpdateBody,
      );
      const localVarPath = `/job/bulk-status-update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkStatusUpdateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CandidateBoardingBody} candidateBoardingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCandidateOnboarding: async (
      candidateBoardingBody: CandidateBoardingBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'candidateBoardingBody' is not null or undefined
      assertParamExists(
        'jobApiCandidateOnboarding',
        'candidateBoardingBody',
        candidateBoardingBody,
      );
      const localVarPath = `/job/candidate-onboarding`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        candidateBoardingBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {JobProfileBody} jobProfileBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCompleteJobProfile: async (
      jobProfileBody: JobProfileBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobProfileBody' is not null or undefined
      assertParamExists(
        'jobApiCompleteJobProfile',
        'jobProfileBody',
        jobProfileBody,
      );
      const localVarPath = `/job/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobProfileBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateApplicantNote: async (
      applicantId: string,
      jobId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantId' is not null or undefined
      assertParamExists(
        'jobApiCreateApplicantNote',
        'applicantId',
        applicantId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiCreateApplicantNote', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiCreateApplicantNote', 'orgId', orgId);
      // verify required parameter 'createApplicantNoteBody' is not null or undefined
      assertParamExists(
        'jobApiCreateApplicantNote',
        'createApplicantNoteBody',
        createApplicantNoteBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/{applicant_id}/notes`
        .replace(`{${'applicant_id'}}`, encodeURIComponent(String(applicantId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplicantNoteBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateApplicantResumeAnalysis: async (
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiCreateApplicantResumeAnalysis', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiCreateApplicantResumeAnalysis', 'orgId', orgId);
      // verify required parameter 'getFilteredApplicantUserIdsBody' is not null or undefined
      assertParamExists(
        'jobApiCreateApplicantResumeAnalysis',
        'getFilteredApplicantUserIdsBody',
        getFilteredApplicantUserIdsBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/resume-analysis`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getFilteredApplicantUserIdsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessment: async (
      jobId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiCreateAssessment', 'jobId', jobId);
      const localVarPath = `/job/{job_id}/assessment`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateAssessmentDuplicateBody} createAssessmentDuplicateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessmentDuplicate: async (
      createAssessmentDuplicateBody: CreateAssessmentDuplicateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAssessmentDuplicateBody' is not null or undefined
      assertParamExists(
        'jobApiCreateAssessmentDuplicate',
        'createAssessmentDuplicateBody',
        createAssessmentDuplicateBody,
      );
      const localVarPath = `/assessment/duplicate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessmentDuplicateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessmentField: async (
      assessmentId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiCreateAssessmentField',
        'assessmentId',
        assessmentId,
      );
      // verify required parameter 'createAssessmentFieldBody' is not null or undefined
      assertParamExists(
        'jobApiCreateAssessmentField',
        'createAssessmentFieldBody',
        createAssessmentFieldBody,
      );
      const localVarPath = `/assessment/{assessment_id}/field`.replace(
        `{${'assessment_id'}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessmentFieldBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<CreateDegreeBody>} createDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateDegree: async (
      createDegreeBody: Array<CreateDegreeBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createDegreeBody' is not null or undefined
      assertParamExists(
        'jobApiCreateDegree',
        'createDegreeBody',
        createDegreeBody,
      );
      const localVarPath = `/admin/degree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDegreeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobBody} createJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJob: async (
      orgId: string,
      createJobBody: CreateJobBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiCreateJob', 'orgId', orgId);
      // verify required parameter 'createJobBody' is not null or undefined
      assertParamExists('jobApiCreateJob', 'createJobBody', createJobBody);
      const localVarPath = `/org/{org_id}/job`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createJobBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {JobApplyAssessmentBody} jobApplyAssessmentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobApplicants: async (
      jobId: string,
      jobApplyAssessmentBody: JobApplyAssessmentBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiCreateJobApplicants', 'jobId', jobId);
      // verify required parameter 'jobApplyAssessmentBody' is not null or undefined
      assertParamExists(
        'jobApiCreateJobApplicants',
        'jobApplyAssessmentBody',
        jobApplyAssessmentBody,
      );
      const localVarPath = `/job/{job_id}/apply`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobApplyAssessmentBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobCandidateCsv: async (
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiCreateJobCandidateCsv', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiCreateJobCandidateCsv', 'orgId', orgId);
      // verify required parameter 'getFilteredApplicantUserIdsBody' is not null or undefined
      assertParamExists(
        'jobApiCreateJobCandidateCsv',
        'getFilteredApplicantUserIdsBody',
        getFilteredApplicantUserIdsBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/download-candidates-csv`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getFilteredApplicantUserIdsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobsXml: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sitemap/jobs.xml`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateSkillBody} createSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateSkillBulk: async (
      createSkillBody: CreateSkillBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSkillBody' is not null or undefined
      assertParamExists(
        'jobApiCreateSkillBulk',
        'createSkillBody',
        createSkillBody,
      );
      const localVarPath = `/admin/job/skills`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSkillBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<CreateSpecializationBody>} createSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateSpecialization: async (
      createSpecializationBody: Array<CreateSpecializationBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSpecializationBody' is not null or undefined
      assertParamExists(
        'jobApiCreateSpecialization',
        'createSpecializationBody',
        createSpecializationBody,
      );
      const localVarPath = `/admin/specialization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSpecializationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDegreeAutoComplete: async (
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/degree/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (degreeId !== undefined) {
        localVarQueryParameter['degree_id'] = degreeId;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteApplicantNote: async (
      jobId: string,
      noteId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiDeleteApplicantNote', 'jobId', jobId);
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists('jobApiDeleteApplicantNote', 'noteId', noteId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiDeleteApplicantNote', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/notes/{note_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'note_id'}}`, encodeURIComponent(String(noteId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessment: async (
      assessmentId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists('jobApiDeleteAssessment', 'assessmentId', assessmentId);
      const localVarPath = `/assessment/{assessment_id}`.replace(
        `{${'assessment_id'}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessmentField: async (
      assessmentId: string,
      fieldId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiDeleteAssessmentField',
        'assessmentId',
        assessmentId,
      );
      // verify required parameter 'fieldId' is not null or undefined
      assertParamExists('jobApiDeleteAssessmentField', 'fieldId', fieldId);
      const localVarPath = `/assessment/{assessment_id}/field/{field_id}`
        .replace(
          `{${'assessment_id'}}`,
          encodeURIComponent(String(assessmentId)),
        )
        .replace(`{${'field_id'}}`, encodeURIComponent(String(fieldId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} responseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessmentResponse: async (
      assessmentId: string,
      responseId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiDeleteAssessmentResponse',
        'assessmentId',
        assessmentId,
      );
      // verify required parameter 'responseId' is not null or undefined
      assertParamExists(
        'jobApiDeleteAssessmentResponse',
        'responseId',
        responseId,
      );
      const localVarPath = `/assessment/{assessment_id}/response/{response_id}`
        .replace(
          `{${'assessment_id'}}`,
          encodeURIComponent(String(assessmentId)),
        )
        .replace(`{${'response_id'}}`, encodeURIComponent(String(responseId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} degreeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteDegree: async (
      degreeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'degreeId' is not null or undefined
      assertParamExists('jobApiDeleteDegree', 'degreeId', degreeId);
      const localVarPath = `/admin/degree/{degree_id}`.replace(
        `{${'degree_id'}}`,
        encodeURIComponent(String(degreeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteJob: async (
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiDeleteJob', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiDeleteJob', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteJobCompletely: async (
      jobId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiDeleteJobCompletely', 'jobId', jobId);
      const localVarPath = `/admin/delete-job-completely/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody2} bulkDeleteBody2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteSkill: async (
      bulkDeleteBody2: BulkDeleteBody2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody2' is not null or undefined
      assertParamExists(
        'jobApiDeleteSkill',
        'bulkDeleteBody2',
        bulkDeleteBody2,
      );
      const localVarPath = `/admin/job/skills/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody2,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} specializationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteSpecialization: async (
      specializationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'specializationId' is not null or undefined
      assertParamExists(
        'jobApiDeleteSpecialization',
        'specializationId',
        specializationId,
      );
      const localVarPath = `/admin/specialization/{specialization_id}`.replace(
        `{${'specialization_id'}}`,
        encodeURIComponent(String(specializationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userSkillId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteUserSkill: async (
      userSkillId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSkillId' is not null or undefined
      assertParamExists('jobApiDeleteUserSkill', 'userSkillId', userSkillId);
      const localVarPath = `/job/user-skill/{user_skill_id}`.replace(
        `{${'user_skill_id'}}`,
        encodeURIComponent(String(userSkillId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {EmployerBulkStatusUpdateBody} employerBulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiEmployerBulkJobStatusUpdate: async (
      orgId: string,
      employerBulkStatusUpdateBody: EmployerBulkStatusUpdateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiEmployerBulkJobStatusUpdate', 'orgId', orgId);
      // verify required parameter 'employerBulkStatusUpdateBody' is not null or undefined
      assertParamExists(
        'jobApiEmployerBulkJobStatusUpdate',
        'employerBulkStatusUpdateBody',
        employerBulkStatusUpdateBody,
      );
      const localVarPath = `/org/{org_id}/job/bulk-close`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        employerBulkStatusUpdateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [bookmarked]
     * @param {string} [companySize]
     * @param {string} [jobMode]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {number} [maxExperience]
     * @param {number} [minExperience]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [postedSince]
     * @param {number} [salaryMax]
     * @param {number} [salaryMin]
     * @param {string} [skills]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllActiveJobs: async (
      bookmarked?: string,
      companySize?: string,
      jobMode?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: number,
      minExperience?: number,
      page?: number,
      pageSize?: number,
      postedSince?: string,
      salaryMax?: number,
      salaryMin?: number,
      skills?: string,
      sortBy?: string,
      sortOrder?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bookmarked !== undefined) {
        localVarQueryParameter['bookmarked'] = bookmarked;
      }

      if (companySize !== undefined) {
        localVarQueryParameter['company_size'] = companySize;
      }

      if (jobMode !== undefined) {
        localVarQueryParameter['job_mode'] = jobMode;
      }

      if (jobRoleIds !== undefined) {
        localVarQueryParameter['job_role_ids'] = jobRoleIds;
      }

      if (jobType !== undefined) {
        localVarQueryParameter['job_type'] = jobType;
      }

      if (locationIds !== undefined) {
        localVarQueryParameter['location_ids'] = locationIds;
      }

      if (maxExperience !== undefined) {
        localVarQueryParameter['max_experience'] = maxExperience;
      }

      if (minExperience !== undefined) {
        localVarQueryParameter['min_experience'] = minExperience;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (postedSince !== undefined) {
        localVarQueryParameter['posted_since'] = postedSince;
      }

      if (salaryMax !== undefined) {
        localVarQueryParameter['salary_max'] = salaryMax;
      }

      if (salaryMin !== undefined) {
        localVarQueryParameter['salary_min'] = salaryMin;
      }

      if (skills !== undefined) {
        localVarQueryParameter['skills'] = skills;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ActiveJobBody} activeJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllActiveJobsPublic: async (
      activeJobBody: ActiveJobBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'activeJobBody' is not null or undefined
      assertParamExists(
        'jobApiGetAllActiveJobsPublic',
        'activeJobBody',
        activeJobBody,
      );
      const localVarPath = `/public/job`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        activeJobBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllAppliedJobs: async (
      page?: number,
      pageSize?: number,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/applied`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantUserId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetApplicantResumeAnalysisByUserId: async (
      applicantUserId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantUserId' is not null or undefined
      assertParamExists(
        'jobApiGetApplicantResumeAnalysisByUserId',
        'applicantUserId',
        applicantUserId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'jobApiGetApplicantResumeAnalysisByUserId',
        'jobId',
        jobId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'jobApiGetApplicantResumeAnalysisByUserId',
        'orgId',
        orgId,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/resume-analysis/applicant/{applicant_user_id}`
        .replace(
          `{${'applicant_user_id'}}`,
          encodeURIComponent(String(applicantUserId)),
        )
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<string>} [applicantStatus]
     * @param {CompetitionData} [competitionData]
     * @param {boolean} [countOnly]
     * @param {string} [jobPreference]
     * @param {number} [maxAssessmentScore]
     * @param {number} [maxCurrentCtc]
     * @param {number} [maxEducation]
     * @param {number} [maxExperience]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minAssessmentScore]
     * @param {number} [minCurrentCtc]
     * @param {number} [minEducation]
     * @param {number} [minExperience]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {number} [noticePeriod]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {Array<RequiredSkillsFilter>} [requiredSkills]
     * @param {boolean} [resumeUploaded]
     * @param {string} [sortBy]
     * @param {SortBy} [sortByCompetitionData]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetApplicantsDetailsByPage: async (
      jobId: string,
      orgId: string,
      applicantStatus?: Array<string>,
      competitionData?: CompetitionData,
      countOnly?: boolean,
      jobPreference?: string,
      maxAssessmentScore?: number,
      maxCurrentCtc?: number,
      maxEducation?: number,
      maxExperience?: number,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minAssessmentScore?: number,
      minCurrentCtc?: number,
      minEducation?: number,
      minExperience?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      noticePeriod?: number,
      page?: number,
      pageSize?: number,
      requiredSkills?: Array<RequiredSkillsFilter>,
      resumeUploaded?: boolean,
      sortBy?: string,
      sortByCompetitionData?: SortBy,
      sortOrder?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetApplicantsDetailsByPage', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetApplicantsDetailsByPage', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}/applicant-details-by-page`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (applicantStatus) {
        localVarQueryParameter['applicant_status'] = applicantStatus;
      }

      if (competitionData !== undefined) {
        localVarQueryParameter['competition_data'] = competitionData;
      }

      if (countOnly !== undefined) {
        localVarQueryParameter['count_only'] = countOnly;
      }

      if (jobPreference !== undefined) {
        localVarQueryParameter['job_preference'] = jobPreference;
      }

      if (maxAssessmentScore !== undefined) {
        localVarQueryParameter['max_assessment_score'] = maxAssessmentScore;
      }

      if (maxCurrentCtc !== undefined) {
        localVarQueryParameter['max_current_ctc'] = maxCurrentCtc;
      }

      if (maxEducation !== undefined) {
        localVarQueryParameter['max_education'] = maxEducation;
      }

      if (maxExperience !== undefined) {
        localVarQueryParameter['max_experience'] = maxExperience;
      }

      if (maxRating !== undefined) {
        localVarQueryParameter['max_rating'] = maxRating;
      }

      if (maxResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_experience_score'
        ] = maxResumeAnalysisExperienceScore;
      }

      if (maxResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_overall_score'
        ] = maxResumeAnalysisOverallScore;
      }

      if (maxResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'max_resume_analysis_skill_score'
        ] = maxResumeAnalysisSkillScore;
      }

      if (minAssessmentScore !== undefined) {
        localVarQueryParameter['min_assessment_score'] = minAssessmentScore;
      }

      if (minCurrentCtc !== undefined) {
        localVarQueryParameter['min_current_ctc'] = minCurrentCtc;
      }

      if (minEducation !== undefined) {
        localVarQueryParameter['min_education'] = minEducation;
      }

      if (minExperience !== undefined) {
        localVarQueryParameter['min_experience'] = minExperience;
      }

      if (minRating !== undefined) {
        localVarQueryParameter['min_rating'] = minRating;
      }

      if (minResumeAnalysisExperienceScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_experience_score'
        ] = minResumeAnalysisExperienceScore;
      }

      if (minResumeAnalysisOverallScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_overall_score'
        ] = minResumeAnalysisOverallScore;
      }

      if (minResumeAnalysisSkillScore !== undefined) {
        localVarQueryParameter[
          'min_resume_analysis_skill_score'
        ] = minResumeAnalysisSkillScore;
      }

      if (noticePeriod !== undefined) {
        localVarQueryParameter['notice_period'] = noticePeriod;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (requiredSkills) {
        localVarQueryParameter['required_skills'] = requiredSkills;
      }

      if (resumeUploaded !== undefined) {
        localVarQueryParameter['resume_uploaded'] = resumeUploaded;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortByCompetitionData !== undefined) {
        localVarQueryParameter[
          'sort_by_competition_data'
        ] = sortByCompetitionData;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAssessment: async (
      jobId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetAssessment', 'jobId', jobId);
      const localVarPath = `/job/{job_id}/assessment`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAssessmentFields: async (
      assessmentId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiGetAssessmentFields',
        'assessmentId',
        assessmentId,
      );
      const localVarPath = `/assessment/{assessment_id}/assessment_field`.replace(
        `{${'assessment_id'}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetBqCandidatesApi: async (
      jobGetCandidatesBody: JobGetCandidatesBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobGetCandidatesBody' is not null or undefined
      assertParamExists(
        'jobApiGetBqCandidatesApi',
        'jobGetCandidatesBody',
        jobGetCandidatesBody,
      );
      const localVarPath = `/job/get_candidates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobGetCandidatesBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetBqCompaniesApi: async (
      jobGetCandidatesBody: JobGetCandidatesBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobGetCandidatesBody' is not null or undefined
      assertParamExists(
        'jobApiGetBqCompaniesApi',
        'jobGetCandidatesBody',
        jobGetCandidatesBody,
      );
      const localVarPath = `/job/get_companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobGetCandidatesBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetCandidateMetaDetails: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/candidate-meta-details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetChatUsersForApplicant: async (
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/chat-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetChatUsersForEmployer: async (
      jobId: string,
      orgId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetChatUsersForEmployer', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetChatUsersForEmployer', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}/chat-users`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [approvalStatus]
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetDegree: async (
      approvalStatus?: string,
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/degree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (approvalStatus !== undefined) {
        localVarQueryParameter['approval_status'] = approvalStatus;
      }

      if (degreeId !== undefined) {
        localVarQueryParameter['degree_id'] = degreeId;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantMessage: async (
      applicantId: string,
      jobId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantId' is not null or undefined
      assertParamExists(
        'jobApiGetJobApplicantMessage',
        'applicantId',
        applicantId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobApplicantMessage', 'jobId', jobId);
      const localVarPath = `/job/{job_id}/chat/{applicant_id}`
        .replace(`{${'applicant_id'}}`, encodeURIComponent(String(applicantId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantsDetails: async (
      applicantId: string,
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantId' is not null or undefined
      assertParamExists(
        'jobApiGetJobApplicantsDetails',
        'applicantId',
        applicantId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobApplicantsDetails', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetJobApplicantsDetails', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/{applicant_id}`
        .replace(`{${'applicant_id'}}`, encodeURIComponent(String(applicantId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ApplicantListParam} applicantListParam
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantsList: async (
      jobId: string,
      orgId: string,
      applicantListParam: ApplicantListParam,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobApplicantsList', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetJobApplicantsList', 'orgId', orgId);
      // verify required parameter 'applicantListParam' is not null or undefined
      assertParamExists(
        'jobApiGetJobApplicantsList',
        'applicantListParam',
        applicantListParam,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicantListParam,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobById: async (
      jobId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobById', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetJobById', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job/{job_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobByIdForCandidate: async (
      jobId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobByIdForCandidate', 'jobId', jobId);
      const localVarPath = `/job/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobByIdForCandidatePublic: async (
      jobId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiGetJobByIdForCandidatePublic', 'jobId', jobId);
      const localVarPath = `/public/job/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {boolean} [isArchieved]
     * @param {boolean} [isCompetitive]
     * @param {boolean} [isGptJob]
     * @param {boolean} [isPremium]
     * @param {string} [jobStatus]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobPostedList: async (
      orgId: string,
      isArchieved?: boolean,
      isCompetitive?: boolean,
      isGptJob?: boolean,
      isPremium?: boolean,
      jobStatus?: string,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetJobPostedList', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job-posted`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isArchieved !== undefined) {
        localVarQueryParameter['is_archieved'] = isArchieved;
      }

      if (isCompetitive !== undefined) {
        localVarQueryParameter['is_competitive'] = isCompetitive;
      }

      if (isGptJob !== undefined) {
        localVarQueryParameter['is_gpt_job'] = isGptJob;
      }

      if (isPremium !== undefined) {
        localVarQueryParameter['is_premium'] = isPremium;
      }

      if (jobStatus !== undefined) {
        localVarQueryParameter['job_status'] = jobStatus;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobProfileMilestone: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/profile-milestone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobSummary: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiGetJobSummary', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/job-summary`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobAlias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetOrgJobIds: async (
      jobAlias: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobAlias' is not null or undefined
      assertParamExists('jobApiGetOrgJobIds', 'jobAlias', jobAlias);
      const localVarPath = `/job/{job_alias}/ids`.replace(
        `{${'job_alias'}}`,
        encodeURIComponent(String(jobAlias)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} degreeId
     * @param {string} [approvalStatus]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [specializationId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetSpecialization: async (
      degreeId: string,
      approvalStatus?: string,
      pageNo?: string,
      pageSize?: string,
      specializationId?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'degreeId' is not null or undefined
      assertParamExists('jobApiGetSpecialization', 'degreeId', degreeId);
      const localVarPath = `/admin/degree/{degree_id}/specialization`.replace(
        `{${'degree_id'}}`,
        encodeURIComponent(String(degreeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (approvalStatus !== undefined) {
        localVarQueryParameter['approval_status'] = approvalStatus;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (specializationId !== undefined) {
        localVarQueryParameter['specialization_id'] = specializationId;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [isSelfRating]
     * @param {boolean} [onlyTags]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetUserSkill: async (
      isSelfRating?: boolean,
      onlyTags?: boolean,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/user-skill`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isSelfRating !== undefined) {
        localVarQueryParameter['is_self_rating'] = isSelfRating;
      }

      if (onlyTags !== undefined) {
        localVarQueryParameter['only_tags'] = onlyTags;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInitialiseJobData: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/initialise-data-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInitialiseJobDataCompany: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/initialise-data-status-company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {InviteCollegeBody} inviteCollegeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInviteCollegesForJobApplication: async (
      jobId: string,
      orgId: string,
      inviteCollegeBody: InviteCollegeBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'jobApiInviteCollegesForJobApplication',
        'jobId',
        jobId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'jobApiInviteCollegesForJobApplication',
        'orgId',
        orgId,
      );
      // verify required parameter 'inviteCollegeBody' is not null or undefined
      assertParamExists(
        'jobApiInviteCollegesForJobApplication',
        'inviteCollegeBody',
        inviteCollegeBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/invite-college`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inviteCollegeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateData: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/migrate-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataCompany: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/migrate-data-company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataCompanyTask: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/migrate-data-company-task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataTask: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/migrate-data-task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [companySize]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {string} [maxExperience]
     * @param {string} [minExperience]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [postedSince]
     * @param {string} [salaryMax]
     * @param {string} [salaryMin]
     * @param {string} [skillsFilter]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {string} [workFlexibilityTypes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiReviewJobs: async (
      companySize?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: string,
      minExperience?: string,
      page?: string,
      pageSize?: string,
      postedSince?: string,
      salaryMax?: string,
      salaryMin?: string,
      skillsFilter?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      workFlexibilityTypes?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/job/review`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (companySize !== undefined) {
        localVarQueryParameter['company_size'] = companySize;
      }

      if (jobRoleIds !== undefined) {
        localVarQueryParameter['job_role_ids'] = jobRoleIds;
      }

      if (jobType !== undefined) {
        localVarQueryParameter['job_type'] = jobType;
      }

      if (locationIds !== undefined) {
        localVarQueryParameter['location_ids'] = locationIds;
      }

      if (maxExperience !== undefined) {
        localVarQueryParameter['max_experience'] = maxExperience;
      }

      if (minExperience !== undefined) {
        localVarQueryParameter['min_experience'] = minExperience;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (postedSince !== undefined) {
        localVarQueryParameter['posted_since'] = postedSince;
      }

      if (salaryMax !== undefined) {
        localVarQueryParameter['salary_max'] = salaryMax;
      }

      if (salaryMin !== undefined) {
        localVarQueryParameter['salary_min'] = salaryMin;
      }

      if (skillsFilter !== undefined) {
        localVarQueryParameter['skills_filter'] = skillsFilter;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      if (workFlexibilityTypes !== undefined) {
        localVarQueryParameter['work_flexibility_types'] = workFlexibilityTypes;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<InsertAssessmentScoreBody>} insertAssessmentScoreBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiSetAssessmentResponseScore: async (
      applicantId: string,
      jobId: string,
      orgId: string,
      insertAssessmentScoreBody: Array<InsertAssessmentScoreBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantId' is not null or undefined
      assertParamExists(
        'jobApiSetAssessmentResponseScore',
        'applicantId',
        applicantId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiSetAssessmentResponseScore', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiSetAssessmentResponseScore', 'orgId', orgId);
      // verify required parameter 'insertAssessmentScoreBody' is not null or undefined
      assertParamExists(
        'jobApiSetAssessmentResponseScore',
        'insertAssessmentScoreBody',
        insertAssessmentScoreBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicant/{applicant_id}/assessment-score`
        .replace(`{${'applicant_id'}}`, encodeURIComponent(String(applicantId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        insertAssessmentScoreBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [specializationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiSpecializationAutoComplete: async (
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      specializationId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/specialization/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (degreeId !== undefined) {
        localVarQueryParameter['degree_id'] = degreeId;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (specializationId !== undefined) {
        localVarQueryParameter['specialization_id'] = specializationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateApplicantNote: async (
      jobId: string,
      noteId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateApplicantNote', 'jobId', jobId);
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists('jobApiUpdateApplicantNote', 'noteId', noteId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiUpdateApplicantNote', 'orgId', orgId);
      // verify required parameter 'createApplicantNoteBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateApplicantNote',
        'createApplicantNoteBody',
        createApplicantNoteBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/notes/{note_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'note_id'}}`, encodeURIComponent(String(noteId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplicantNoteBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} ratingValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateApplicantRating: async (
      applicantId: string,
      jobId: string,
      orgId: string,
      ratingValue: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicantId' is not null or undefined
      assertParamExists(
        'jobApiUpdateApplicantRating',
        'applicantId',
        applicantId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateApplicantRating', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiUpdateApplicantRating', 'orgId', orgId);
      // verify required parameter 'ratingValue' is not null or undefined
      assertParamExists(
        'jobApiUpdateApplicantRating',
        'ratingValue',
        ratingValue,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicant/{applicant_id}/rating/{rating_value}`
        .replace(`{${'applicant_id'}}`, encodeURIComponent(String(applicantId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)))
        .replace(
          `{${'rating_value'}}`,
          encodeURIComponent(String(ratingValue)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateAssessmentField: async (
      assessmentId: string,
      fieldId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiUpdateAssessmentField',
        'assessmentId',
        assessmentId,
      );
      // verify required parameter 'fieldId' is not null or undefined
      assertParamExists('jobApiUpdateAssessmentField', 'fieldId', fieldId);
      // verify required parameter 'createAssessmentFieldBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateAssessmentField',
        'createAssessmentFieldBody',
        createAssessmentFieldBody,
      );
      const localVarPath = `/assessment/{assessment_id}/field/{field_id}`
        .replace(
          `{${'assessment_id'}}`,
          encodeURIComponent(String(assessmentId)),
        )
        .replace(`{${'field_id'}}`, encodeURIComponent(String(fieldId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessmentFieldBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentResponseId
     * @param {CreateAssessmentResponsedBody} createAssessmentResponsedBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateAssessmentResponse: async (
      assessmentResponseId: string,
      createAssessmentResponsedBody: CreateAssessmentResponsedBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentResponseId' is not null or undefined
      assertParamExists(
        'jobApiUpdateAssessmentResponse',
        'assessmentResponseId',
        assessmentResponseId,
      );
      // verify required parameter 'createAssessmentResponsedBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateAssessmentResponse',
        'createAssessmentResponsedBody',
        createAssessmentResponsedBody,
      );
      const localVarPath = `/assessment_response/{assessment_response_id}`.replace(
        `{${'assessment_response_id'}}`,
        encodeURIComponent(String(assessmentResponseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAssessmentResponsedBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateDegreeBody} updateDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateDegree: async (
      updateDegreeBody: UpdateDegreeBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDegreeBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateDegree',
        'updateDegreeBody',
        updateDegreeBody,
      );
      const localVarPath = `/admin/degree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDegreeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} assessmentId
     * @param {Array<UpdateFieldPositionBody>} updateFieldPositionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateFieldPosition: async (
      assessmentId: string,
      updateFieldPositionBody: Array<UpdateFieldPositionBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        'jobApiUpdateFieldPosition',
        'assessmentId',
        assessmentId,
      );
      // verify required parameter 'updateFieldPositionBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateFieldPosition',
        'updateFieldPositionBody',
        updateFieldPositionBody,
      );
      const localVarPath = `/assessment/{assessment_id}/field/position`.replace(
        `{${'assessment_id'}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFieldPositionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {UpdateJobAnalysisStatusBody} updateJobAnalysisStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateGptJobAnalysisStatus: async (
      jobId: string,
      updateJobAnalysisStatusBody: UpdateJobAnalysisStatusBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateGptJobAnalysisStatus', 'jobId', jobId);
      // verify required parameter 'updateJobAnalysisStatusBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateGptJobAnalysisStatus',
        'updateJobAnalysisStatusBody',
        updateJobAnalysisStatusBody,
      );
      const localVarPath = `/internal/job/{job_id}/analysis-status`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateJobAnalysisStatusBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateHiringStatusBody} updateHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateHiringStatus: async (
      jobId: string,
      orgId: string,
      userId: string,
      updateHiringStatusBody: UpdateHiringStatusBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateHiringStatus', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiUpdateHiringStatus', 'orgId', orgId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('jobApiUpdateHiringStatus', 'userId', userId);
      // verify required parameter 'updateHiringStatusBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateHiringStatus',
        'updateHiringStatusBody',
        updateHiringStatusBody,
      );
      const localVarPath = `/org/{org_id}/job/{job_id}/applicants/{user_id}/hiring-status`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateHiringStatusBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateJobBody} updateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJob: async (
      jobId: string,
      orgId: string,
      updateJobBody: UpdateJobBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateJob', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiUpdateJob', 'orgId', orgId);
      // verify required parameter 'updateJobBody' is not null or undefined
      assertParamExists('jobApiUpdateJob', 'updateJobBody', updateJobBody);
      const localVarPath = `/org/{org_id}/job/{job_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateJobBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {AdminUpdateJobBody} adminUpdateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobAdmin: async (
      jobId: string,
      orgId: string,
      adminUpdateJobBody: AdminUpdateJobBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateJobAdmin', 'jobId', jobId);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('jobApiUpdateJobAdmin', 'orgId', orgId);
      // verify required parameter 'adminUpdateJobBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateJobAdmin',
        'adminUpdateJobBody',
        adminUpdateJobBody,
      );
      const localVarPath = `/admin/org/{org_id}/job/{job_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateJobBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} userId
     * @param {UpdateJobApplicant} updateJobApplicant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobApplicationAdmin: async (
      jobId: string,
      userId: string,
      updateJobApplicant: UpdateJobApplicant,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateJobApplicationAdmin', 'jobId', jobId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('jobApiUpdateJobApplicationAdmin', 'userId', userId);
      // verify required parameter 'updateJobApplicant' is not null or undefined
      assertParamExists(
        'jobApiUpdateJobApplicationAdmin',
        'updateJobApplicant',
        updateJobApplicant,
      );
      const localVarPath = `/internal/job/{job_id}/applicant/{user_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateJobApplicant,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} jobId
     * @param {string} [bookmark]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobBookmark: async (
      jobId: string,
      bookmark?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('jobApiUpdateJobBookmark', 'jobId', jobId);
      const localVarPath = `/job/{job_id}/bookmark`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bookmark !== undefined) {
        localVarQueryParameter['bookmark'] = bookmark;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} skillId
     * @param {UpdateSkillBody} updateSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateSkill: async (
      skillId: string,
      updateSkillBody: UpdateSkillBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'skillId' is not null or undefined
      assertParamExists('jobApiUpdateSkill', 'skillId', skillId);
      // verify required parameter 'updateSkillBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateSkill',
        'updateSkillBody',
        updateSkillBody,
      );
      const localVarPath = `/admin/job/skills/{skill_id}`.replace(
        `{${'skill_id'}}`,
        encodeURIComponent(String(skillId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSkillBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateSpecializationBody} updateSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateSpecialization: async (
      updateSpecializationBody: UpdateSpecializationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateSpecializationBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateSpecialization',
        'updateSpecializationBody',
        updateSpecializationBody,
      );
      const localVarPath = `/admin/specialization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSpecializationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<AddUserSkillBody>} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateUserSkill: async (
      addUserSkillBody: Array<AddUserSkillBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addUserSkillBody' is not null or undefined
      assertParamExists(
        'jobApiUpdateUserSkill',
        'addUserSkillBody',
        addUserSkillBody,
      );
      const localVarPath = `/job/user-skill`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addUserSkillBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpsertCandidateDetailsBody} upsertCandidateDetailsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpsertCandidateMetaDetails: async (
      upsertCandidateDetailsBody: UpsertCandidateDetailsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'upsertCandidateDetailsBody' is not null or undefined
      assertParamExists(
        'jobApiUpsertCandidateMetaDetails',
        'upsertCandidateDetailsBody',
        upsertCandidateDetailsBody,
      );
      const localVarPath = `/job/candidate-meta-details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertCandidateDetailsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobApiApi - functional programming interface
 * @export
 */
export const JobApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = JobApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} jobId
     * @param {AddApplicantMessageBody} addApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAddJobApplicantMsg(
      jobId: string,
      addApplicantMessageBody: AddApplicantMessageBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddApplicantMsgResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAddJobApplicantMsg(
        jobId,
        addApplicantMessageBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {BulkAddJobItemToCartBody} bulkAddJobItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAddJobItemsToCart(
      orgId: string,
      bulkAddJobItemToCartBody: BulkAddJobItemToCartBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddItemToCartResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAddJobItemsToCart(
        orgId,
        bulkAddJobItemToCartBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {AddUserSkillBody} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAddUserSkill(
      addUserSkillBody: AddUserSkillBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSkillsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAddUserSkill(
        addUserSkillBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {AddUserSkillsInternalBody} addUserSkillsInternalBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAddUserSkillInternal(
      addUserSkillsInternalBody: AddUserSkillsInternalBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAddUserSkillInternal(
        addUserSkillsInternalBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAdminGetJobSkills(
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminGetAllSkillsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAdminGetJobSkills(
        name,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} competitionId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiAssociateCompetitionWithJob(
      competitionId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiAssociateCompetitionWithJob(
        competitionId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkApplicantActionBody} bulkApplicantActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiBulkActionOnApplicants(
      jobId: string,
      orgId: string,
      bulkApplicantActionBody: BulkApplicantActionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiBulkActionOnApplicants(
        jobId,
        orgId,
        bulkApplicantActionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkAddJobApplicantMessageBody} bulkAddJobApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiBulkAddJobApplicantMessage(
      jobId: string,
      orgId: string,
      bulkAddJobApplicantMessageBody: BulkAddJobApplicantMessageBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiBulkAddJobApplicantMessage(
        jobId,
        orgId,
        bulkAddJobApplicantMessageBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkStatusUpdateBody} bulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiBulkJobStatusUpdate(
      bulkStatusUpdateBody: BulkStatusUpdateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiBulkJobStatusUpdate(
        bulkStatusUpdateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CandidateBoardingBody} candidateBoardingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCandidateOnboarding(
      candidateBoardingBody: CandidateBoardingBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCandidateOnboarding(
        candidateBoardingBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {JobProfileBody} jobProfileBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCompleteJobProfile(
      jobProfileBody: JobProfileBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobProfileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCompleteJobProfile(
        jobProfileBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateApplicantNote(
      applicantId: string,
      jobId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateNoteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateApplicantNote(
        applicantId,
        jobId,
        orgId,
        createApplicantNoteBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateApplicantResumeAnalysis(
        jobId,
        orgId,
        getFilteredApplicantUserIdsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateAssessment(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAssessmentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateAssessment(
        jobId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateAssessmentDuplicateBody} createAssessmentDuplicateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateAssessmentDuplicate(
      createAssessmentDuplicateBody: CreateAssessmentDuplicateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAssessmentResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateAssessmentDuplicate(
        createAssessmentDuplicateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateAssessmentField(
      assessmentId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentField>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateAssessmentField(
        assessmentId,
        createAssessmentFieldBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<CreateDegreeBody>} createDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateDegree(
      createDegreeBody: Array<CreateDegreeBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateDegreeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateDegree(
        createDegreeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobBody} createJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateJob(
      orgId: string,
      createJobBody: CreateJobBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateJobResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateJob(
        orgId,
        createJobBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {JobApplyAssessmentBody} jobApplyAssessmentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateJobApplicants(
      jobId: string,
      jobApplyAssessmentBody: JobApplyAssessmentBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobApplicantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateJobApplicants(
        jobId,
        jobApplyAssessmentBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateJobCandidateCsv(
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateJobCandidateCsv(
        jobId,
        orgId,
        getFilteredApplicantUserIdsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateJobsXml(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateJobsXml(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateSkillBody} createSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateSkillBulk(
      createSkillBody: CreateSkillBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateSkillBulk(
        createSkillBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<CreateSpecializationBody>} createSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiCreateSpecialization(
      createSpecializationBody: Array<CreateSpecializationBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateSpecializationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiCreateSpecialization(
        createSpecializationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDegreeAutoComplete(
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DegreeAutocompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDegreeAutoComplete(
        degreeId,
        pageNo,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteApplicantNote(
      jobId: string,
      noteId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteApplicantNote(
        jobId,
        noteId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteAssessment(
      assessmentId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteAssessment(
        assessmentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteAssessmentField(
      assessmentId: string,
      fieldId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteAssessmentField(
        assessmentId,
        fieldId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} responseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteAssessmentResponse(
      assessmentId: string,
      responseId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteAssessmentResponse(
        assessmentId,
        responseId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} degreeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteDegree(
      degreeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteDegree(
        degreeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteJob(
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteJob(
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteJobCompletely(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteJobCompletelyResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteJobCompletely(
        jobId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody2} bulkDeleteBody2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteSkill(
      bulkDeleteBody2: BulkDeleteBody2,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkDeleteResponse2>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteSkill(
        bulkDeleteBody2,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} specializationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteSpecialization(
      specializationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteSpecialization(
        specializationId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} userSkillId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiDeleteUserSkill(
      userSkillId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiDeleteUserSkill(
        userSkillId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {EmployerBulkStatusUpdateBody} employerBulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiEmployerBulkJobStatusUpdate(
      orgId: string,
      employerBulkStatusUpdateBody: EmployerBulkStatusUpdateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiEmployerBulkJobStatusUpdate(
        orgId,
        employerBulkStatusUpdateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [bookmarked]
     * @param {string} [companySize]
     * @param {string} [jobMode]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {number} [maxExperience]
     * @param {number} [minExperience]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [postedSince]
     * @param {number} [salaryMax]
     * @param {number} [salaryMin]
     * @param {string} [skills]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetAllActiveJobs(
      bookmarked?: string,
      companySize?: string,
      jobMode?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: number,
      minExperience?: number,
      page?: number,
      pageSize?: number,
      postedSince?: string,
      salaryMax?: number,
      salaryMin?: number,
      skills?: string,
      sortBy?: string,
      sortOrder?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ActiveJobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetAllActiveJobs(
        bookmarked,
        companySize,
        jobMode,
        jobRoleIds,
        jobType,
        locationIds,
        maxExperience,
        minExperience,
        page,
        pageSize,
        postedSince,
        salaryMax,
        salaryMin,
        skills,
        sortBy,
        sortOrder,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ActiveJobBody} activeJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetAllActiveJobsPublic(
      activeJobBody: ActiveJobBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ActiveJobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetAllActiveJobsPublic(
        activeJobBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetAllAppliedJobs(
      page?: number,
      pageSize?: number,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAppliedJobResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetAllAppliedJobs(
        page,
        pageSize,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantUserId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetApplicantResumeAnalysisByUserId(
      applicantUserId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetJobResumeAnalysis>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetApplicantResumeAnalysisByUserId(
        applicantUserId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<string>} [applicantStatus]
     * @param {CompetitionData} [competitionData]
     * @param {boolean} [countOnly]
     * @param {string} [jobPreference]
     * @param {number} [maxAssessmentScore]
     * @param {number} [maxCurrentCtc]
     * @param {number} [maxEducation]
     * @param {number} [maxExperience]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minAssessmentScore]
     * @param {number} [minCurrentCtc]
     * @param {number} [minEducation]
     * @param {number} [minExperience]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {number} [noticePeriod]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {Array<RequiredSkillsFilter>} [requiredSkills]
     * @param {boolean} [resumeUploaded]
     * @param {string} [sortBy]
     * @param {SortBy} [sortByCompetitionData]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetApplicantsDetailsByPage(
      jobId: string,
      orgId: string,
      applicantStatus?: Array<string>,
      competitionData?: CompetitionData,
      countOnly?: boolean,
      jobPreference?: string,
      maxAssessmentScore?: number,
      maxCurrentCtc?: number,
      maxEducation?: number,
      maxExperience?: number,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minAssessmentScore?: number,
      minCurrentCtc?: number,
      minEducation?: number,
      minExperience?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      noticePeriod?: number,
      page?: number,
      pageSize?: number,
      requiredSkills?: Array<RequiredSkillsFilter>,
      resumeUploaded?: boolean,
      sortBy?: string,
      sortByCompetitionData?: SortBy,
      sortOrder?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicantDetailsPage>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetApplicantsDetailsByPage(
        jobId,
        orgId,
        applicantStatus,
        competitionData,
        countOnly,
        jobPreference,
        maxAssessmentScore,
        maxCurrentCtc,
        maxEducation,
        maxExperience,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minAssessmentScore,
        minCurrentCtc,
        minEducation,
        minExperience,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        noticePeriod,
        page,
        pageSize,
        requiredSkills,
        resumeUploaded,
        sortBy,
        sortByCompetitionData,
        sortOrder,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetAssessment(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAssessmentResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetAssessment(
        jobId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetAssessmentFields(
      assessmentId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AssessmentField>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetAssessmentFields(
        assessmentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetBqCandidatesApi(
      jobGetCandidatesBody: JobGetCandidatesBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobGetCandidatesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetBqCandidatesApi(
        jobGetCandidatesBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetBqCompaniesApi(
      jobGetCandidatesBody: JobGetCandidatesBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobGetCandidatesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetBqCompaniesApi(
        jobGetCandidatesBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetCandidateMetaDetails(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CandidateMetaDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetCandidateMetaDetails(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetChatUsersForApplicant(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChatForApplicant>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetChatUsersForApplicant(
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetChatUsersForEmployer(
      jobId: string,
      orgId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChatForEmployers>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetChatUsersForEmployer(
        jobId,
        orgId,
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [approvalStatus]
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetDegree(
      approvalStatus?: string,
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDegreesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetDegree(
        approvalStatus,
        degreeId,
        pageNo,
        pageSize,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobApplicantMessage(
      applicantId: string,
      jobId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetApplicantChatResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobApplicantMessage(
        applicantId,
        jobId,
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobApplicantsDetails(
      applicantId: string,
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicantDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobApplicantsDetails(
        applicantId,
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ApplicantListParam} applicantListParam
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobApplicantsList(
      jobId: string,
      orgId: string,
      applicantListParam: ApplicantListParam,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AllJobApplicantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobApplicantsList(
        jobId,
        orgId,
        applicantListParam,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobById(
      jobId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobById(
        jobId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobByIdForCandidate(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobByIdChandidate>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobByIdForCandidate(
        jobId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobByIdForCandidatePublic(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobByIdChandidate>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobByIdForCandidatePublic(
        jobId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {boolean} [isArchieved]
     * @param {boolean} [isCompetitive]
     * @param {boolean} [isGptJob]
     * @param {boolean} [isPremium]
     * @param {string} [jobStatus]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobPostedList(
      orgId: string,
      isArchieved?: boolean,
      isCompetitive?: boolean,
      isGptJob?: boolean,
      isPremium?: boolean,
      jobStatus?: string,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobPostedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobPostedList(
        orgId,
        isArchieved,
        isCompetitive,
        isGptJob,
        isPremium,
        jobStatus,
        pageNo,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobProfileMilestone(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobProfileMilestone>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobProfileMilestone(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetJobSummary(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllJobSummaryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetJobSummary(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobAlias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetOrgJobIds(
      jobAlias: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrgJobIdsByAliasResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetOrgJobIds(
        jobAlias,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} degreeId
     * @param {string} [approvalStatus]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [specializationId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetSpecialization(
      degreeId: string,
      approvalStatus?: string,
      pageNo?: string,
      pageSize?: string,
      specializationId?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSpecializationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetSpecialization(
        degreeId,
        approvalStatus,
        pageNo,
        pageSize,
        specializationId,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {boolean} [isSelfRating]
     * @param {boolean} [onlyTags]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiGetUserSkill(
      isSelfRating?: boolean,
      onlyTags?: boolean,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserSkillResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiGetUserSkill(
        isSelfRating,
        onlyTags,
        pageNo,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiInitialiseJobData(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiInitialiseJobData(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiInitialiseJobDataCompany(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiInitialiseJobDataCompany(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {InviteCollegeBody} inviteCollegeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiInviteCollegesForJobApplication(
      jobId: string,
      orgId: string,
      inviteCollegeBody: InviteCollegeBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InviteCollegeRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiInviteCollegesForJobApplication(
        jobId,
        orgId,
        inviteCollegeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiMigrateData(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobMigrateFilesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiMigrateData(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiMigrateDataCompany(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobMigrateFilesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiMigrateDataCompany(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiMigrateDataCompanyTask(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiMigrateDataCompanyTask(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiMigrateDataTask(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiMigrateDataTask(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [companySize]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {string} [maxExperience]
     * @param {string} [minExperience]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [postedSince]
     * @param {string} [salaryMax]
     * @param {string} [salaryMin]
     * @param {string} [skillsFilter]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {string} [workFlexibilityTypes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiReviewJobs(
      companySize?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: string,
      minExperience?: string,
      page?: string,
      pageSize?: string,
      postedSince?: string,
      salaryMax?: string,
      salaryMin?: string,
      skillsFilter?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      workFlexibilityTypes?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReviewJobResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiReviewJobs(
        companySize,
        jobRoleIds,
        jobType,
        locationIds,
        maxExperience,
        minExperience,
        page,
        pageSize,
        postedSince,
        salaryMax,
        salaryMin,
        skillsFilter,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        workFlexibilityTypes,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<InsertAssessmentScoreBody>} insertAssessmentScoreBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiSetAssessmentResponseScore(
      applicantId: string,
      jobId: string,
      orgId: string,
      insertAssessmentScoreBody: Array<InsertAssessmentScoreBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InsertAssessmentScoreResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiSetAssessmentResponseScore(
        applicantId,
        jobId,
        orgId,
        insertAssessmentScoreBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [specializationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiSpecializationAutoComplete(
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      specializationId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpecializationAutocompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiSpecializationAutoComplete(
        degreeId,
        pageNo,
        pageSize,
        query,
        specializationId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateApplicantNote(
      jobId: string,
      noteId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobNotesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateApplicantNote(
        jobId,
        noteId,
        orgId,
        createApplicantNoteBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} ratingValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateApplicantRating(
      applicantId: string,
      jobId: string,
      orgId: string,
      ratingValue: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicantRating>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateApplicantRating(
        applicantId,
        jobId,
        orgId,
        ratingValue,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateAssessmentField(
      assessmentId: string,
      fieldId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentField>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateAssessmentField(
        assessmentId,
        fieldId,
        createAssessmentFieldBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentResponseId
     * @param {CreateAssessmentResponsedBody} createAssessmentResponsedBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateAssessmentResponse(
      assessmentResponseId: string,
      createAssessmentResponsedBody: CreateAssessmentResponsedBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentResponseResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateAssessmentResponse(
        assessmentResponseId,
        createAssessmentResponsedBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateDegreeBody} updateDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateDegree(
      updateDegreeBody: UpdateDegreeBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateDegreeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateDegree(
        updateDegreeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} assessmentId
     * @param {Array<UpdateFieldPositionBody>} updateFieldPositionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateFieldPosition(
      assessmentId: string,
      updateFieldPositionBody: Array<UpdateFieldPositionBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateFieldPosition(
        assessmentId,
        updateFieldPositionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {UpdateJobAnalysisStatusBody} updateJobAnalysisStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateGptJobAnalysisStatus(
      jobId: string,
      updateJobAnalysisStatusBody: UpdateJobAnalysisStatusBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobAnalysisStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateGptJobAnalysisStatus(
        jobId,
        updateJobAnalysisStatusBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateHiringStatusBody} updateHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateHiringStatus(
      jobId: string,
      orgId: string,
      userId: string,
      updateHiringStatusBody: UpdateHiringStatusBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobApplicantResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateHiringStatus(
        jobId,
        orgId,
        userId,
        updateHiringStatusBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateJobBody} updateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateJob(
      jobId: string,
      orgId: string,
      updateJobBody: UpdateJobBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateJob(
        jobId,
        orgId,
        updateJobBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {AdminUpdateJobBody} adminUpdateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateJobAdmin(
      jobId: string,
      orgId: string,
      adminUpdateJobBody: AdminUpdateJobBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateJobAdmin(
        jobId,
        orgId,
        adminUpdateJobBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} userId
     * @param {UpdateJobApplicant} updateJobApplicant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateJobApplicationAdmin(
      jobId: string,
      userId: string,
      updateJobApplicant: UpdateJobApplicant,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobApplicant>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateJobApplicationAdmin(
        jobId,
        userId,
        updateJobApplicant,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} jobId
     * @param {string} [bookmark]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateJobBookmark(
      jobId: string,
      bookmark?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobBookmark>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateJobBookmark(
        jobId,
        bookmark,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} skillId
     * @param {UpdateSkillBody} updateSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateSkill(
      skillId: string,
      updateSkillBody: UpdateSkillBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateSkillResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateSkill(
        skillId,
        updateSkillBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateSpecializationBody} updateSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateSpecialization(
      updateSpecializationBody: UpdateSpecializationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateSpecializationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateSpecialization(
        updateSpecializationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<AddUserSkillBody>} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpdateUserSkill(
      addUserSkillBody: Array<AddUserSkillBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserSkillsResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpdateUserSkill(
        addUserSkillBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpsertCandidateDetailsBody} upsertCandidateDetailsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobApiUpsertCandidateMetaDetails(
      upsertCandidateDetailsBody: UpsertCandidateDetailsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertCandidateDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobApiUpsertCandidateMetaDetails(
        upsertCandidateDetailsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * JobApiApi - factory interface
 * @export
 */
export const JobApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} jobId
     * @param {AddApplicantMessageBody} addApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddJobApplicantMsg(
      jobId: string,
      addApplicantMessageBody: AddApplicantMessageBody,
      options?: any,
    ): AxiosPromise<AddApplicantMsgResponse> {
      return localVarFp
        .jobApiAddJobApplicantMsg(jobId, addApplicantMessageBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {BulkAddJobItemToCartBody} bulkAddJobItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddJobItemsToCart(
      orgId: string,
      bulkAddJobItemToCartBody: BulkAddJobItemToCartBody,
      options?: any,
    ): AxiosPromise<AddItemToCartResponse> {
      return localVarFp
        .jobApiAddJobItemsToCart(orgId, bulkAddJobItemToCartBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AddUserSkillBody} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddUserSkill(
      addUserSkillBody: AddUserSkillBody,
      options?: any,
    ): AxiosPromise<UserSkillsResponse> {
      return localVarFp
        .jobApiAddUserSkill(addUserSkillBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AddUserSkillsInternalBody} addUserSkillsInternalBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAddUserSkillInternal(
      addUserSkillsInternalBody: AddUserSkillsInternalBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiAddUserSkillInternal(addUserSkillsInternalBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAdminGetJobSkills(
      name?: string,
      pageNo?: number,
      pageSize?: number,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      tagId?: string,
      options?: any,
    ): AxiosPromise<AdminGetAllSkillsResponse> {
      return localVarFp
        .jobApiAdminGetJobSkills(
          name,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          status,
          tagId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} competitionId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiAssociateCompetitionWithJob(
      competitionId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiAssociateCompetitionWithJob(competitionId, jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkApplicantActionBody} bulkApplicantActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkActionOnApplicants(
      jobId: string,
      orgId: string,
      bulkApplicantActionBody: BulkApplicantActionBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiBulkActionOnApplicants(
          jobId,
          orgId,
          bulkApplicantActionBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {BulkAddJobApplicantMessageBody} bulkAddJobApplicantMessageBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkAddJobApplicantMessage(
      jobId: string,
      orgId: string,
      bulkAddJobApplicantMessageBody: BulkAddJobApplicantMessageBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiBulkAddJobApplicantMessage(
          jobId,
          orgId,
          bulkAddJobApplicantMessageBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkStatusUpdateBody} bulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiBulkJobStatusUpdate(
      bulkStatusUpdateBody: BulkStatusUpdateBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiBulkJobStatusUpdate(bulkStatusUpdateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CandidateBoardingBody} candidateBoardingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCandidateOnboarding(
      candidateBoardingBody: CandidateBoardingBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiCandidateOnboarding(candidateBoardingBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {JobProfileBody} jobProfileBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCompleteJobProfile(
      jobProfileBody: JobProfileBody,
      options?: any,
    ): AxiosPromise<JobProfileResponse> {
      return localVarFp
        .jobApiCompleteJobProfile(jobProfileBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateApplicantNote(
      applicantId: string,
      jobId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options?: any,
    ): AxiosPromise<CreateNoteResponse> {
      return localVarFp
        .jobApiCreateApplicantNote(
          applicantId,
          jobId,
          orgId,
          createApplicantNoteBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateApplicantResumeAnalysis(
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiCreateApplicantResumeAnalysis(
          jobId,
          orgId,
          getFilteredApplicantUserIdsBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessment(
      jobId: string,
      options?: any,
    ): AxiosPromise<CreateAssessmentResponse> {
      return localVarFp
        .jobApiCreateAssessment(jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateAssessmentDuplicateBody} createAssessmentDuplicateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessmentDuplicate(
      createAssessmentDuplicateBody: CreateAssessmentDuplicateBody,
      options?: any,
    ): AxiosPromise<Array<GetAssessmentResponse>> {
      return localVarFp
        .jobApiCreateAssessmentDuplicate(createAssessmentDuplicateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateAssessmentField(
      assessmentId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options?: any,
    ): AxiosPromise<AssessmentField> {
      return localVarFp
        .jobApiCreateAssessmentField(
          assessmentId,
          createAssessmentFieldBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<CreateDegreeBody>} createDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateDegree(
      createDegreeBody: Array<CreateDegreeBody>,
      options?: any,
    ): AxiosPromise<CreateDegreeResponse> {
      return localVarFp
        .jobApiCreateDegree(createDegreeBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {CreateJobBody} createJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJob(
      orgId: string,
      createJobBody: CreateJobBody,
      options?: any,
    ): AxiosPromise<CreateJobResponse> {
      return localVarFp
        .jobApiCreateJob(orgId, createJobBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {JobApplyAssessmentBody} jobApplyAssessmentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobApplicants(
      jobId: string,
      jobApplyAssessmentBody: JobApplyAssessmentBody,
      options?: any,
    ): AxiosPromise<JobApplicantResponse> {
      return localVarFp
        .jobApiCreateJobApplicants(jobId, jobApplyAssessmentBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobCandidateCsv(
      jobId: string,
      orgId: string,
      getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .jobApiCreateJobCandidateCsv(
          jobId,
          orgId,
          getFilteredApplicantUserIdsBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateJobsXml(options?: any): AxiosPromise<void> {
      return localVarFp
        .jobApiCreateJobsXml(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateSkillBody} createSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateSkillBulk(
      createSkillBody: CreateSkillBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiCreateSkillBulk(createSkillBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<CreateSpecializationBody>} createSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiCreateSpecialization(
      createSpecializationBody: Array<CreateSpecializationBody>,
      options?: any,
    ): AxiosPromise<CreateSpecializationResponse> {
      return localVarFp
        .jobApiCreateSpecialization(createSpecializationBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDegreeAutoComplete(
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options?: any,
    ): AxiosPromise<DegreeAutocompleteResponse> {
      return localVarFp
        .jobApiDegreeAutoComplete(degreeId, pageNo, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteApplicantNote(
      jobId: string,
      noteId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteApplicantNote(jobId, noteId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessmentField(
      assessmentId: string,
      fieldId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteAssessmentField(assessmentId, fieldId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} responseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteAssessmentResponse(
      assessmentId: string,
      responseId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteAssessmentResponse(assessmentId, responseId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} degreeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteDegree(
      degreeId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteDegree(degreeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteJob(
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteJob(jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteJobCompletely(
      jobId: string,
      options?: any,
    ): AxiosPromise<DeleteJobCompletelyResponse> {
      return localVarFp
        .jobApiDeleteJobCompletely(jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody2} bulkDeleteBody2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteSkill(
      bulkDeleteBody2: BulkDeleteBody2,
      options?: any,
    ): AxiosPromise<BulkDeleteResponse2> {
      return localVarFp
        .jobApiDeleteSkill(bulkDeleteBody2, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} specializationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteSpecialization(
      specializationId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteSpecialization(specializationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userSkillId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiDeleteUserSkill(
      userSkillId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiDeleteUserSkill(userSkillId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {EmployerBulkStatusUpdateBody} employerBulkStatusUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiEmployerBulkJobStatusUpdate(
      orgId: string,
      employerBulkStatusUpdateBody: EmployerBulkStatusUpdateBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiEmployerBulkJobStatusUpdate(
          orgId,
          employerBulkStatusUpdateBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [bookmarked]
     * @param {string} [companySize]
     * @param {string} [jobMode]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {number} [maxExperience]
     * @param {number} [minExperience]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [postedSince]
     * @param {number} [salaryMax]
     * @param {number} [salaryMin]
     * @param {string} [skills]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllActiveJobs(
      bookmarked?: string,
      companySize?: string,
      jobMode?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: number,
      minExperience?: number,
      page?: number,
      pageSize?: number,
      postedSince?: string,
      salaryMax?: number,
      salaryMin?: number,
      skills?: string,
      sortBy?: string,
      sortOrder?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<ActiveJobsResponse> {
      return localVarFp
        .jobApiGetAllActiveJobs(
          bookmarked,
          companySize,
          jobMode,
          jobRoleIds,
          jobType,
          locationIds,
          maxExperience,
          minExperience,
          page,
          pageSize,
          postedSince,
          salaryMax,
          salaryMin,
          skills,
          sortBy,
          sortOrder,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ActiveJobBody} activeJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllActiveJobsPublic(
      activeJobBody: ActiveJobBody,
      options?: any,
    ): AxiosPromise<ActiveJobsResponse> {
      return localVarFp
        .jobApiGetAllActiveJobsPublic(activeJobBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAllAppliedJobs(
      page?: number,
      pageSize?: number,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetAppliedJobResponse> {
      return localVarFp
        .jobApiGetAllAppliedJobs(page, pageSize, userSearchQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantUserId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetApplicantResumeAnalysisByUserId(
      applicantUserId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<GetJobResumeAnalysis> {
      return localVarFp
        .jobApiGetApplicantResumeAnalysisByUserId(
          applicantUserId,
          jobId,
          orgId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<string>} [applicantStatus]
     * @param {CompetitionData} [competitionData]
     * @param {boolean} [countOnly]
     * @param {string} [jobPreference]
     * @param {number} [maxAssessmentScore]
     * @param {number} [maxCurrentCtc]
     * @param {number} [maxEducation]
     * @param {number} [maxExperience]
     * @param {number} [maxRating]
     * @param {number} [maxResumeAnalysisExperienceScore]
     * @param {number} [maxResumeAnalysisOverallScore]
     * @param {number} [maxResumeAnalysisSkillScore]
     * @param {number} [minAssessmentScore]
     * @param {number} [minCurrentCtc]
     * @param {number} [minEducation]
     * @param {number} [minExperience]
     * @param {number} [minRating]
     * @param {number} [minResumeAnalysisExperienceScore]
     * @param {number} [minResumeAnalysisOverallScore]
     * @param {number} [minResumeAnalysisSkillScore]
     * @param {number} [noticePeriod]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {Array<RequiredSkillsFilter>} [requiredSkills]
     * @param {boolean} [resumeUploaded]
     * @param {string} [sortBy]
     * @param {SortBy} [sortByCompetitionData]
     * @param {string} [sortOrder]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetApplicantsDetailsByPage(
      jobId: string,
      orgId: string,
      applicantStatus?: Array<string>,
      competitionData?: CompetitionData,
      countOnly?: boolean,
      jobPreference?: string,
      maxAssessmentScore?: number,
      maxCurrentCtc?: number,
      maxEducation?: number,
      maxExperience?: number,
      maxRating?: number,
      maxResumeAnalysisExperienceScore?: number,
      maxResumeAnalysisOverallScore?: number,
      maxResumeAnalysisSkillScore?: number,
      minAssessmentScore?: number,
      minCurrentCtc?: number,
      minEducation?: number,
      minExperience?: number,
      minRating?: number,
      minResumeAnalysisExperienceScore?: number,
      minResumeAnalysisOverallScore?: number,
      minResumeAnalysisSkillScore?: number,
      noticePeriod?: number,
      page?: number,
      pageSize?: number,
      requiredSkills?: Array<RequiredSkillsFilter>,
      resumeUploaded?: boolean,
      sortBy?: string,
      sortByCompetitionData?: SortBy,
      sortOrder?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<ApplicantDetailsPage> {
      return localVarFp
        .jobApiGetApplicantsDetailsByPage(
          jobId,
          orgId,
          applicantStatus,
          competitionData,
          countOnly,
          jobPreference,
          maxAssessmentScore,
          maxCurrentCtc,
          maxEducation,
          maxExperience,
          maxRating,
          maxResumeAnalysisExperienceScore,
          maxResumeAnalysisOverallScore,
          maxResumeAnalysisSkillScore,
          minAssessmentScore,
          minCurrentCtc,
          minEducation,
          minExperience,
          minRating,
          minResumeAnalysisExperienceScore,
          minResumeAnalysisOverallScore,
          minResumeAnalysisSkillScore,
          noticePeriod,
          page,
          pageSize,
          requiredSkills,
          resumeUploaded,
          sortBy,
          sortByCompetitionData,
          sortOrder,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAssessment(
      jobId: string,
      options?: any,
    ): AxiosPromise<Array<GetAssessmentResponse>> {
      return localVarFp
        .jobApiGetAssessment(jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetAssessmentFields(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<Array<AssessmentField>> {
      return localVarFp
        .jobApiGetAssessmentFields(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetBqCandidatesApi(
      jobGetCandidatesBody: JobGetCandidatesBody,
      options?: any,
    ): AxiosPromise<JobGetCandidatesResponse> {
      return localVarFp
        .jobApiGetBqCandidatesApi(jobGetCandidatesBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {JobGetCandidatesBody} jobGetCandidatesBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetBqCompaniesApi(
      jobGetCandidatesBody: JobGetCandidatesBody,
      options?: any,
    ): AxiosPromise<JobGetCandidatesResponse> {
      return localVarFp
        .jobApiGetBqCompaniesApi(jobGetCandidatesBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetCandidateMetaDetails(
      options?: any,
    ): AxiosPromise<CandidateMetaDetails> {
      return localVarFp
        .jobApiGetCandidateMetaDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetChatUsersForApplicant(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<ChatForApplicant> {
      return localVarFp
        .jobApiGetChatUsersForApplicant(page, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetChatUsersForEmployer(
      jobId: string,
      orgId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<ChatForEmployers> {
      return localVarFp
        .jobApiGetChatUsersForEmployer(
          jobId,
          orgId,
          page,
          pageSize,
          query,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [approvalStatus]
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetDegree(
      approvalStatus?: string,
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetDegreesResponse> {
      return localVarFp
        .jobApiGetDegree(
          approvalStatus,
          degreeId,
          pageNo,
          pageSize,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantMessage(
      applicantId: string,
      jobId: string,
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<GetApplicantChatResponse> {
      return localVarFp
        .jobApiGetJobApplicantMessage(
          applicantId,
          jobId,
          page,
          pageSize,
          query,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantsDetails(
      applicantId: string,
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<ApplicantDetails> {
      return localVarFp
        .jobApiGetJobApplicantsDetails(applicantId, jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {ApplicantListParam} applicantListParam
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobApplicantsList(
      jobId: string,
      orgId: string,
      applicantListParam: ApplicantListParam,
      options?: any,
    ): AxiosPromise<AllJobApplicantResponse> {
      return localVarFp
        .jobApiGetJobApplicantsList(jobId, orgId, applicantListParam, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobById(
      jobId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<JobByIdResponse> {
      return localVarFp
        .jobApiGetJobById(jobId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobByIdForCandidate(
      jobId: string,
      options?: any,
    ): AxiosPromise<JobByIdChandidate> {
      return localVarFp
        .jobApiGetJobByIdForCandidate(jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobByIdForCandidatePublic(
      jobId: string,
      options?: any,
    ): AxiosPromise<JobByIdChandidate> {
      return localVarFp
        .jobApiGetJobByIdForCandidatePublic(jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {boolean} [isArchieved]
     * @param {boolean} [isCompetitive]
     * @param {boolean} [isGptJob]
     * @param {boolean} [isPremium]
     * @param {string} [jobStatus]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobPostedList(
      orgId: string,
      isArchieved?: boolean,
      isCompetitive?: boolean,
      isGptJob?: boolean,
      isPremium?: boolean,
      jobStatus?: string,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<JobPostedResponse> {
      return localVarFp
        .jobApiGetJobPostedList(
          orgId,
          isArchieved,
          isCompetitive,
          isGptJob,
          isPremium,
          jobStatus,
          pageNo,
          pageSize,
          query,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobProfileMilestone(
      options?: any,
    ): AxiosPromise<JobProfileMilestone> {
      return localVarFp
        .jobApiGetJobProfileMilestone(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetJobSummary(
      orgId: string,
      options?: any,
    ): AxiosPromise<GetAllJobSummaryResponse> {
      return localVarFp
        .jobApiGetJobSummary(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobAlias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetOrgJobIds(
      jobAlias: string,
      options?: any,
    ): AxiosPromise<GetOrgJobIdsByAliasResponse> {
      return localVarFp
        .jobApiGetOrgJobIds(jobAlias, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} degreeId
     * @param {string} [approvalStatus]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [specializationId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetSpecialization(
      degreeId: string,
      approvalStatus?: string,
      pageNo?: string,
      pageSize?: string,
      specializationId?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetSpecializationsResponse> {
      return localVarFp
        .jobApiGetSpecialization(
          degreeId,
          approvalStatus,
          pageNo,
          pageSize,
          specializationId,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [isSelfRating]
     * @param {boolean} [onlyTags]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiGetUserSkill(
      isSelfRating?: boolean,
      onlyTags?: boolean,
      pageNo?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<GetUserSkillResponse> {
      return localVarFp
        .jobApiGetUserSkill(
          isSelfRating,
          onlyTags,
          pageNo,
          pageSize,
          query,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInitialiseJobData(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiInitialiseJobData(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInitialiseJobDataCompany(
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiInitialiseJobDataCompany(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {InviteCollegeBody} inviteCollegeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiInviteCollegesForJobApplication(
      jobId: string,
      orgId: string,
      inviteCollegeBody: InviteCollegeBody,
      options?: any,
    ): AxiosPromise<InviteCollegeRepsonse> {
      return localVarFp
        .jobApiInviteCollegesForJobApplication(
          jobId,
          orgId,
          inviteCollegeBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateData(options?: any): AxiosPromise<JobMigrateFilesResponse> {
      return localVarFp
        .jobApiMigrateData(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataCompany(
      options?: any,
    ): AxiosPromise<JobMigrateFilesResponse> {
      return localVarFp
        .jobApiMigrateDataCompany(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataCompanyTask(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiMigrateDataCompanyTask(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiMigrateDataTask(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiMigrateDataTask(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [companySize]
     * @param {string} [jobRoleIds]
     * @param {string} [jobType]
     * @param {string} [locationIds]
     * @param {string} [maxExperience]
     * @param {string} [minExperience]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [postedSince]
     * @param {string} [salaryMax]
     * @param {string} [salaryMin]
     * @param {string} [skillsFilter]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {string} [workFlexibilityTypes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiReviewJobs(
      companySize?: string,
      jobRoleIds?: string,
      jobType?: string,
      locationIds?: string,
      maxExperience?: string,
      minExperience?: string,
      page?: string,
      pageSize?: string,
      postedSince?: string,
      salaryMax?: string,
      salaryMin?: string,
      skillsFilter?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      workFlexibilityTypes?: string,
      options?: any,
    ): AxiosPromise<ReviewJobResponse> {
      return localVarFp
        .jobApiReviewJobs(
          companySize,
          jobRoleIds,
          jobType,
          locationIds,
          maxExperience,
          minExperience,
          page,
          pageSize,
          postedSince,
          salaryMax,
          salaryMin,
          skillsFilter,
          sortBy,
          sortOrder,
          status,
          userSearchQuery,
          workFlexibilityTypes,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {Array<InsertAssessmentScoreBody>} insertAssessmentScoreBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiSetAssessmentResponseScore(
      applicantId: string,
      jobId: string,
      orgId: string,
      insertAssessmentScoreBody: Array<InsertAssessmentScoreBody>,
      options?: any,
    ): AxiosPromise<InsertAssessmentScoreResponse> {
      return localVarFp
        .jobApiSetAssessmentResponseScore(
          applicantId,
          jobId,
          orgId,
          insertAssessmentScoreBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [degreeId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {string} [specializationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiSpecializationAutoComplete(
      degreeId?: string,
      pageNo?: string,
      pageSize?: string,
      query?: string,
      specializationId?: string,
      options?: any,
    ): AxiosPromise<SpecializationAutocompleteResponse> {
      return localVarFp
        .jobApiSpecializationAutoComplete(
          degreeId,
          pageNo,
          pageSize,
          query,
          specializationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} noteId
     * @param {string} orgId
     * @param {CreateApplicantNoteBody} createApplicantNoteBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateApplicantNote(
      jobId: string,
      noteId: string,
      orgId: string,
      createApplicantNoteBody: CreateApplicantNoteBody,
      options?: any,
    ): AxiosPromise<JobNotesResponse> {
      return localVarFp
        .jobApiUpdateApplicantNote(
          jobId,
          noteId,
          orgId,
          createApplicantNoteBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} applicantId
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} ratingValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateApplicantRating(
      applicantId: string,
      jobId: string,
      orgId: string,
      ratingValue: string,
      options?: any,
    ): AxiosPromise<ApplicantRating> {
      return localVarFp
        .jobApiUpdateApplicantRating(
          applicantId,
          jobId,
          orgId,
          ratingValue,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {string} fieldId
     * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateAssessmentField(
      assessmentId: string,
      fieldId: string,
      createAssessmentFieldBody: CreateAssessmentFieldBody,
      options?: any,
    ): AxiosPromise<AssessmentField> {
      return localVarFp
        .jobApiUpdateAssessmentField(
          assessmentId,
          fieldId,
          createAssessmentFieldBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentResponseId
     * @param {CreateAssessmentResponsedBody} createAssessmentResponsedBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateAssessmentResponse(
      assessmentResponseId: string,
      createAssessmentResponsedBody: CreateAssessmentResponsedBody,
      options?: any,
    ): AxiosPromise<AssessmentResponseResponse> {
      return localVarFp
        .jobApiUpdateAssessmentResponse(
          assessmentResponseId,
          createAssessmentResponsedBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateDegreeBody} updateDegreeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateDegree(
      updateDegreeBody: UpdateDegreeBody,
      options?: any,
    ): AxiosPromise<UpdateDegreeResponse> {
      return localVarFp
        .jobApiUpdateDegree(updateDegreeBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} assessmentId
     * @param {Array<UpdateFieldPositionBody>} updateFieldPositionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateFieldPosition(
      assessmentId: string,
      updateFieldPositionBody: Array<UpdateFieldPositionBody>,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .jobApiUpdateFieldPosition(
          assessmentId,
          updateFieldPositionBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {UpdateJobAnalysisStatusBody} updateJobAnalysisStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateGptJobAnalysisStatus(
      jobId: string,
      updateJobAnalysisStatusBody: UpdateJobAnalysisStatusBody,
      options?: any,
    ): AxiosPromise<UpdateJobAnalysisStatusResponse> {
      return localVarFp
        .jobApiUpdateGptJobAnalysisStatus(
          jobId,
          updateJobAnalysisStatusBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateHiringStatusBody} updateHiringStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateHiringStatus(
      jobId: string,
      orgId: string,
      userId: string,
      updateHiringStatusBody: UpdateHiringStatusBody,
      options?: any,
    ): AxiosPromise<JobApplicantResponse> {
      return localVarFp
        .jobApiUpdateHiringStatus(
          jobId,
          orgId,
          userId,
          updateHiringStatusBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {UpdateJobBody} updateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJob(
      jobId: string,
      orgId: string,
      updateJobBody: UpdateJobBody,
      options?: any,
    ): AxiosPromise<UpdateJobResponse> {
      return localVarFp
        .jobApiUpdateJob(jobId, orgId, updateJobBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} orgId
     * @param {AdminUpdateJobBody} adminUpdateJobBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobAdmin(
      jobId: string,
      orgId: string,
      adminUpdateJobBody: AdminUpdateJobBody,
      options?: any,
    ): AxiosPromise<UpdateJobResponse> {
      return localVarFp
        .jobApiUpdateJobAdmin(jobId, orgId, adminUpdateJobBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} userId
     * @param {UpdateJobApplicant} updateJobApplicant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobApplicationAdmin(
      jobId: string,
      userId: string,
      updateJobApplicant: UpdateJobApplicant,
      options?: any,
    ): AxiosPromise<UpdateJobApplicant> {
      return localVarFp
        .jobApiUpdateJobApplicationAdmin(
          jobId,
          userId,
          updateJobApplicant,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} jobId
     * @param {string} [bookmark]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateJobBookmark(
      jobId: string,
      bookmark?: string,
      options?: any,
    ): AxiosPromise<UpdateJobBookmark> {
      return localVarFp
        .jobApiUpdateJobBookmark(jobId, bookmark, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} skillId
     * @param {UpdateSkillBody} updateSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateSkill(
      skillId: string,
      updateSkillBody: UpdateSkillBody,
      options?: any,
    ): AxiosPromise<UpdateSkillResponse> {
      return localVarFp
        .jobApiUpdateSkill(skillId, updateSkillBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateSpecializationBody} updateSpecializationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateSpecialization(
      updateSpecializationBody: UpdateSpecializationBody,
      options?: any,
    ): AxiosPromise<UpdateSpecializationResponse> {
      return localVarFp
        .jobApiUpdateSpecialization(updateSpecializationBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<AddUserSkillBody>} addUserSkillBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpdateUserSkill(
      addUserSkillBody: Array<AddUserSkillBody>,
      options?: any,
    ): AxiosPromise<Array<UserSkillsResponse>> {
      return localVarFp
        .jobApiUpdateUserSkill(addUserSkillBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpsertCandidateDetailsBody} upsertCandidateDetailsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobApiUpsertCandidateMetaDetails(
      upsertCandidateDetailsBody: UpsertCandidateDetailsBody,
      options?: any,
    ): AxiosPromise<UpsertCandidateDetails> {
      return localVarFp
        .jobApiUpsertCandidateMetaDetails(upsertCandidateDetailsBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobApiApi - object-oriented interface
 * @export
 * @class JobApiApi
 * @extends {BaseAPI}
 */
export class JobApiApi extends BaseAPI {
  /**
   *
   * @param {string} jobId
   * @param {AddApplicantMessageBody} addApplicantMessageBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAddJobApplicantMsg(
    jobId: string,
    addApplicantMessageBody: AddApplicantMessageBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAddJobApplicantMsg(jobId, addApplicantMessageBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {BulkAddJobItemToCartBody} bulkAddJobItemToCartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAddJobItemsToCart(
    orgId: string,
    bulkAddJobItemToCartBody: BulkAddJobItemToCartBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAddJobItemsToCart(orgId, bulkAddJobItemToCartBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AddUserSkillBody} addUserSkillBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAddUserSkill(
    addUserSkillBody: AddUserSkillBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAddUserSkill(addUserSkillBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AddUserSkillsInternalBody} addUserSkillsInternalBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAddUserSkillInternal(
    addUserSkillsInternalBody: AddUserSkillsInternalBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAddUserSkillInternal(addUserSkillsInternalBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [name]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAdminGetJobSkills(
    name?: string,
    pageNo?: number,
    pageSize?: number,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAdminGetJobSkills(
        name,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        tagId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} competitionId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiAssociateCompetitionWithJob(
    competitionId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiAssociateCompetitionWithJob(competitionId, jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {BulkApplicantActionBody} bulkApplicantActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiBulkActionOnApplicants(
    jobId: string,
    orgId: string,
    bulkApplicantActionBody: BulkApplicantActionBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiBulkActionOnApplicants(
        jobId,
        orgId,
        bulkApplicantActionBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {BulkAddJobApplicantMessageBody} bulkAddJobApplicantMessageBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiBulkAddJobApplicantMessage(
    jobId: string,
    orgId: string,
    bulkAddJobApplicantMessageBody: BulkAddJobApplicantMessageBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiBulkAddJobApplicantMessage(
        jobId,
        orgId,
        bulkAddJobApplicantMessageBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkStatusUpdateBody} bulkStatusUpdateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiBulkJobStatusUpdate(
    bulkStatusUpdateBody: BulkStatusUpdateBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiBulkJobStatusUpdate(bulkStatusUpdateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CandidateBoardingBody} candidateBoardingBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCandidateOnboarding(
    candidateBoardingBody: CandidateBoardingBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCandidateOnboarding(candidateBoardingBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {JobProfileBody} jobProfileBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCompleteJobProfile(
    jobProfileBody: JobProfileBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCompleteJobProfile(jobProfileBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantId
   * @param {string} jobId
   * @param {string} orgId
   * @param {CreateApplicantNoteBody} createApplicantNoteBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateApplicantNote(
    applicantId: string,
    jobId: string,
    orgId: string,
    createApplicantNoteBody: CreateApplicantNoteBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateApplicantNote(
        applicantId,
        jobId,
        orgId,
        createApplicantNoteBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateApplicantResumeAnalysis(
    jobId: string,
    orgId: string,
    getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateApplicantResumeAnalysis(
        jobId,
        orgId,
        getFilteredApplicantUserIdsBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateAssessment(jobId: string, options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateAssessment(jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateAssessmentDuplicateBody} createAssessmentDuplicateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateAssessmentDuplicate(
    createAssessmentDuplicateBody: CreateAssessmentDuplicateBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateAssessmentDuplicate(createAssessmentDuplicateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateAssessmentField(
    assessmentId: string,
    createAssessmentFieldBody: CreateAssessmentFieldBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateAssessmentField(
        assessmentId,
        createAssessmentFieldBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<CreateDegreeBody>} createDegreeBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateDegree(
    createDegreeBody: Array<CreateDegreeBody>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateDegree(createDegreeBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {CreateJobBody} createJobBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateJob(
    orgId: string,
    createJobBody: CreateJobBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateJob(orgId, createJobBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {JobApplyAssessmentBody} jobApplyAssessmentBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateJobApplicants(
    jobId: string,
    jobApplyAssessmentBody: JobApplyAssessmentBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateJobApplicants(jobId, jobApplyAssessmentBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {GetFilteredApplicantUserIdsBody} getFilteredApplicantUserIdsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateJobCandidateCsv(
    jobId: string,
    orgId: string,
    getFilteredApplicantUserIdsBody: GetFilteredApplicantUserIdsBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateJobCandidateCsv(
        jobId,
        orgId,
        getFilteredApplicantUserIdsBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateJobsXml(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateJobsXml(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateSkillBody} createSkillBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateSkillBulk(
    createSkillBody: CreateSkillBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateSkillBulk(createSkillBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<CreateSpecializationBody>} createSpecializationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiCreateSpecialization(
    createSpecializationBody: Array<CreateSpecializationBody>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiCreateSpecialization(createSpecializationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [degreeId]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDegreeAutoComplete(
    degreeId?: string,
    pageNo?: string,
    pageSize?: string,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDegreeAutoComplete(degreeId, pageNo, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} noteId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteApplicantNote(
    jobId: string,
    noteId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteApplicantNote(jobId, noteId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteAssessment(
    assessmentId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteAssessment(assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {string} fieldId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteAssessmentField(
    assessmentId: string,
    fieldId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteAssessmentField(assessmentId, fieldId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {string} responseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteAssessmentResponse(
    assessmentId: string,
    responseId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteAssessmentResponse(assessmentId, responseId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} degreeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteDegree(degreeId: string, options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteDegree(degreeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteJob(
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteJob(jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteJobCompletely(
    jobId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteJobCompletely(jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody2} bulkDeleteBody2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteSkill(
    bulkDeleteBody2: BulkDeleteBody2,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteSkill(bulkDeleteBody2, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} specializationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteSpecialization(
    specializationId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteSpecialization(specializationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userSkillId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiDeleteUserSkill(
    userSkillId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiDeleteUserSkill(userSkillId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {EmployerBulkStatusUpdateBody} employerBulkStatusUpdateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiEmployerBulkJobStatusUpdate(
    orgId: string,
    employerBulkStatusUpdateBody: EmployerBulkStatusUpdateBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiEmployerBulkJobStatusUpdate(
        orgId,
        employerBulkStatusUpdateBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [bookmarked]
   * @param {string} [companySize]
   * @param {string} [jobMode]
   * @param {string} [jobRoleIds]
   * @param {string} [jobType]
   * @param {string} [locationIds]
   * @param {number} [maxExperience]
   * @param {number} [minExperience]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [postedSince]
   * @param {number} [salaryMax]
   * @param {number} [salaryMin]
   * @param {string} [skills]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetAllActiveJobs(
    bookmarked?: string,
    companySize?: string,
    jobMode?: string,
    jobRoleIds?: string,
    jobType?: string,
    locationIds?: string,
    maxExperience?: number,
    minExperience?: number,
    page?: number,
    pageSize?: number,
    postedSince?: string,
    salaryMax?: number,
    salaryMin?: number,
    skills?: string,
    sortBy?: string,
    sortOrder?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetAllActiveJobs(
        bookmarked,
        companySize,
        jobMode,
        jobRoleIds,
        jobType,
        locationIds,
        maxExperience,
        minExperience,
        page,
        pageSize,
        postedSince,
        salaryMax,
        salaryMin,
        skills,
        sortBy,
        sortOrder,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ActiveJobBody} activeJobBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetAllActiveJobsPublic(
    activeJobBody: ActiveJobBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetAllActiveJobsPublic(activeJobBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetAllAppliedJobs(
    page?: number,
    pageSize?: number,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetAllAppliedJobs(page, pageSize, userSearchQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantUserId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetApplicantResumeAnalysisByUserId(
    applicantUserId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetApplicantResumeAnalysisByUserId(
        applicantUserId,
        jobId,
        orgId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {Array<string>} [applicantStatus]
   * @param {CompetitionData} [competitionData]
   * @param {boolean} [countOnly]
   * @param {string} [jobPreference]
   * @param {number} [maxAssessmentScore]
   * @param {number} [maxCurrentCtc]
   * @param {number} [maxEducation]
   * @param {number} [maxExperience]
   * @param {number} [maxRating]
   * @param {number} [maxResumeAnalysisExperienceScore]
   * @param {number} [maxResumeAnalysisOverallScore]
   * @param {number} [maxResumeAnalysisSkillScore]
   * @param {number} [minAssessmentScore]
   * @param {number} [minCurrentCtc]
   * @param {number} [minEducation]
   * @param {number} [minExperience]
   * @param {number} [minRating]
   * @param {number} [minResumeAnalysisExperienceScore]
   * @param {number} [minResumeAnalysisOverallScore]
   * @param {number} [minResumeAnalysisSkillScore]
   * @param {number} [noticePeriod]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {Array<RequiredSkillsFilter>} [requiredSkills]
   * @param {boolean} [resumeUploaded]
   * @param {string} [sortBy]
   * @param {SortBy} [sortByCompetitionData]
   * @param {string} [sortOrder]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetApplicantsDetailsByPage(
    jobId: string,
    orgId: string,
    applicantStatus?: Array<string>,
    competitionData?: CompetitionData,
    countOnly?: boolean,
    jobPreference?: string,
    maxAssessmentScore?: number,
    maxCurrentCtc?: number,
    maxEducation?: number,
    maxExperience?: number,
    maxRating?: number,
    maxResumeAnalysisExperienceScore?: number,
    maxResumeAnalysisOverallScore?: number,
    maxResumeAnalysisSkillScore?: number,
    minAssessmentScore?: number,
    minCurrentCtc?: number,
    minEducation?: number,
    minExperience?: number,
    minRating?: number,
    minResumeAnalysisExperienceScore?: number,
    minResumeAnalysisOverallScore?: number,
    minResumeAnalysisSkillScore?: number,
    noticePeriod?: number,
    page?: number,
    pageSize?: number,
    requiredSkills?: Array<RequiredSkillsFilter>,
    resumeUploaded?: boolean,
    sortBy?: string,
    sortByCompetitionData?: SortBy,
    sortOrder?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetApplicantsDetailsByPage(
        jobId,
        orgId,
        applicantStatus,
        competitionData,
        countOnly,
        jobPreference,
        maxAssessmentScore,
        maxCurrentCtc,
        maxEducation,
        maxExperience,
        maxRating,
        maxResumeAnalysisExperienceScore,
        maxResumeAnalysisOverallScore,
        maxResumeAnalysisSkillScore,
        minAssessmentScore,
        minCurrentCtc,
        minEducation,
        minExperience,
        minRating,
        minResumeAnalysisExperienceScore,
        minResumeAnalysisOverallScore,
        minResumeAnalysisSkillScore,
        noticePeriod,
        page,
        pageSize,
        requiredSkills,
        resumeUploaded,
        sortBy,
        sortByCompetitionData,
        sortOrder,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetAssessment(jobId: string, options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiGetAssessment(jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetAssessmentFields(
    assessmentId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetAssessmentFields(assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {JobGetCandidatesBody} jobGetCandidatesBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetBqCandidatesApi(
    jobGetCandidatesBody: JobGetCandidatesBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetBqCandidatesApi(jobGetCandidatesBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {JobGetCandidatesBody} jobGetCandidatesBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetBqCompaniesApi(
    jobGetCandidatesBody: JobGetCandidatesBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetBqCompaniesApi(jobGetCandidatesBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetCandidateMetaDetails(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiGetCandidateMetaDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetChatUsersForApplicant(
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetChatUsersForApplicant(page, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetChatUsersForEmployer(
    jobId: string,
    orgId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetChatUsersForEmployer(
        jobId,
        orgId,
        page,
        pageSize,
        query,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [approvalStatus]
   * @param {string} [degreeId]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetDegree(
    approvalStatus?: string,
    degreeId?: string,
    pageNo?: string,
    pageSize?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetDegree(
        approvalStatus,
        degreeId,
        pageNo,
        pageSize,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantId
   * @param {string} jobId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobApplicantMessage(
    applicantId: string,
    jobId: string,
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobApplicantMessage(
        applicantId,
        jobId,
        page,
        pageSize,
        query,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantId
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobApplicantsDetails(
    applicantId: string,
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobApplicantsDetails(applicantId, jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {ApplicantListParam} applicantListParam
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobApplicantsList(
    jobId: string,
    orgId: string,
    applicantListParam: ApplicantListParam,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobApplicantsList(jobId, orgId, applicantListParam, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobById(
    jobId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobById(jobId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobByIdForCandidate(
    jobId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobByIdForCandidate(jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobByIdForCandidatePublic(
    jobId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobByIdForCandidatePublic(jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {boolean} [isArchieved]
   * @param {boolean} [isCompetitive]
   * @param {boolean} [isGptJob]
   * @param {boolean} [isPremium]
   * @param {string} [jobStatus]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobPostedList(
    orgId: string,
    isArchieved?: boolean,
    isCompetitive?: boolean,
    isGptJob?: boolean,
    isPremium?: boolean,
    jobStatus?: string,
    pageNo?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobPostedList(
        orgId,
        isArchieved,
        isCompetitive,
        isGptJob,
        isPremium,
        jobStatus,
        pageNo,
        pageSize,
        query,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobProfileMilestone(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobProfileMilestone(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetJobSummary(orgId: string, options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiGetJobSummary(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobAlias
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetOrgJobIds(jobAlias: string, options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiGetOrgJobIds(jobAlias, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} degreeId
   * @param {string} [approvalStatus]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [specializationId]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetSpecialization(
    degreeId: string,
    approvalStatus?: string,
    pageNo?: string,
    pageSize?: string,
    specializationId?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetSpecialization(
        degreeId,
        approvalStatus,
        pageNo,
        pageSize,
        specializationId,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [isSelfRating]
   * @param {boolean} [onlyTags]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiGetUserSkill(
    isSelfRating?: boolean,
    onlyTags?: boolean,
    pageNo?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiGetUserSkill(
        isSelfRating,
        onlyTags,
        pageNo,
        pageSize,
        query,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiInitialiseJobData(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiInitialiseJobData(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiInitialiseJobDataCompany(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiInitialiseJobDataCompany(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {InviteCollegeBody} inviteCollegeBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiInviteCollegesForJobApplication(
    jobId: string,
    orgId: string,
    inviteCollegeBody: InviteCollegeBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiInviteCollegesForJobApplication(
        jobId,
        orgId,
        inviteCollegeBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiMigrateData(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiMigrateData(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiMigrateDataCompany(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiMigrateDataCompany(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiMigrateDataCompanyTask(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiMigrateDataCompanyTask(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiMigrateDataTask(options?: AxiosRequestConfig) {
    return JobApiApiFp(this.configuration)
      .jobApiMigrateDataTask(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [companySize]
   * @param {string} [jobRoleIds]
   * @param {string} [jobType]
   * @param {string} [locationIds]
   * @param {string} [maxExperience]
   * @param {string} [minExperience]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [postedSince]
   * @param {string} [salaryMax]
   * @param {string} [salaryMin]
   * @param {string} [skillsFilter]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {string} [userSearchQuery]
   * @param {string} [workFlexibilityTypes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiReviewJobs(
    companySize?: string,
    jobRoleIds?: string,
    jobType?: string,
    locationIds?: string,
    maxExperience?: string,
    minExperience?: string,
    page?: string,
    pageSize?: string,
    postedSince?: string,
    salaryMax?: string,
    salaryMin?: string,
    skillsFilter?: string,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    userSearchQuery?: string,
    workFlexibilityTypes?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiReviewJobs(
        companySize,
        jobRoleIds,
        jobType,
        locationIds,
        maxExperience,
        minExperience,
        page,
        pageSize,
        postedSince,
        salaryMax,
        salaryMin,
        skillsFilter,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        workFlexibilityTypes,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantId
   * @param {string} jobId
   * @param {string} orgId
   * @param {Array<InsertAssessmentScoreBody>} insertAssessmentScoreBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiSetAssessmentResponseScore(
    applicantId: string,
    jobId: string,
    orgId: string,
    insertAssessmentScoreBody: Array<InsertAssessmentScoreBody>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiSetAssessmentResponseScore(
        applicantId,
        jobId,
        orgId,
        insertAssessmentScoreBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [degreeId]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [query]
   * @param {string} [specializationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiSpecializationAutoComplete(
    degreeId?: string,
    pageNo?: string,
    pageSize?: string,
    query?: string,
    specializationId?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiSpecializationAutoComplete(
        degreeId,
        pageNo,
        pageSize,
        query,
        specializationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} noteId
   * @param {string} orgId
   * @param {CreateApplicantNoteBody} createApplicantNoteBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateApplicantNote(
    jobId: string,
    noteId: string,
    orgId: string,
    createApplicantNoteBody: CreateApplicantNoteBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateApplicantNote(
        jobId,
        noteId,
        orgId,
        createApplicantNoteBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} applicantId
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} ratingValue
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateApplicantRating(
    applicantId: string,
    jobId: string,
    orgId: string,
    ratingValue: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateApplicantRating(
        applicantId,
        jobId,
        orgId,
        ratingValue,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {string} fieldId
   * @param {CreateAssessmentFieldBody} createAssessmentFieldBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateAssessmentField(
    assessmentId: string,
    fieldId: string,
    createAssessmentFieldBody: CreateAssessmentFieldBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateAssessmentField(
        assessmentId,
        fieldId,
        createAssessmentFieldBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentResponseId
   * @param {CreateAssessmentResponsedBody} createAssessmentResponsedBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateAssessmentResponse(
    assessmentResponseId: string,
    createAssessmentResponsedBody: CreateAssessmentResponsedBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateAssessmentResponse(
        assessmentResponseId,
        createAssessmentResponsedBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateDegreeBody} updateDegreeBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateDegree(
    updateDegreeBody: UpdateDegreeBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateDegree(updateDegreeBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} assessmentId
   * @param {Array<UpdateFieldPositionBody>} updateFieldPositionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateFieldPosition(
    assessmentId: string,
    updateFieldPositionBody: Array<UpdateFieldPositionBody>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateFieldPosition(assessmentId, updateFieldPositionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {UpdateJobAnalysisStatusBody} updateJobAnalysisStatusBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateGptJobAnalysisStatus(
    jobId: string,
    updateJobAnalysisStatusBody: UpdateJobAnalysisStatusBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateGptJobAnalysisStatus(
        jobId,
        updateJobAnalysisStatusBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {string} userId
   * @param {UpdateHiringStatusBody} updateHiringStatusBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateHiringStatus(
    jobId: string,
    orgId: string,
    userId: string,
    updateHiringStatusBody: UpdateHiringStatusBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateHiringStatus(
        jobId,
        orgId,
        userId,
        updateHiringStatusBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {UpdateJobBody} updateJobBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateJob(
    jobId: string,
    orgId: string,
    updateJobBody: UpdateJobBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateJob(jobId, orgId, updateJobBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} orgId
   * @param {AdminUpdateJobBody} adminUpdateJobBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateJobAdmin(
    jobId: string,
    orgId: string,
    adminUpdateJobBody: AdminUpdateJobBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateJobAdmin(jobId, orgId, adminUpdateJobBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} userId
   * @param {UpdateJobApplicant} updateJobApplicant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateJobApplicationAdmin(
    jobId: string,
    userId: string,
    updateJobApplicant: UpdateJobApplicant,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateJobApplicationAdmin(
        jobId,
        userId,
        updateJobApplicant,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} jobId
   * @param {string} [bookmark]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateJobBookmark(
    jobId: string,
    bookmark?: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateJobBookmark(jobId, bookmark, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} skillId
   * @param {UpdateSkillBody} updateSkillBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateSkill(
    skillId: string,
    updateSkillBody: UpdateSkillBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateSkill(skillId, updateSkillBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateSpecializationBody} updateSpecializationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateSpecialization(
    updateSpecializationBody: UpdateSpecializationBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateSpecialization(updateSpecializationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<AddUserSkillBody>} addUserSkillBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpdateUserSkill(
    addUserSkillBody: Array<AddUserSkillBody>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpdateUserSkill(addUserSkillBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpsertCandidateDetailsBody} upsertCandidateDetailsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApiApi
   */
  public jobApiUpsertCandidateMetaDetails(
    upsertCandidateDetailsBody: UpsertCandidateDetailsBody,
    options?: AxiosRequestConfig,
  ) {
    return JobApiApiFp(this.configuration)
      .jobApiUpsertCandidateMetaDetails(upsertCandidateDetailsBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LevelApiApi - axios parameter creator
 * @export
 */
export const LevelApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateLevelBody} createLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiCreateLevel: async (
      createLevelBody: CreateLevelBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createLevelBody' is not null or undefined
      assertParamExists(
        'levelApiCreateLevel',
        'createLevelBody',
        createLevelBody,
      );
      const localVarPath = `/level`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLevelBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiDeleteLevel: async (
      levelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'levelId' is not null or undefined
      assertParamExists('levelApiDeleteLevel', 'levelId', levelId);
      const localVarPath = `/level/{level_id}`.replace(
        `{${'level_id'}}`,
        encodeURIComponent(String(levelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiGetAllLevels: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/level`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiGetLevelById: async (
      levelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'levelId' is not null or undefined
      assertParamExists('levelApiGetLevelById', 'levelId', levelId);
      const localVarPath = `/level/{level_id}`.replace(
        `{${'level_id'}}`,
        encodeURIComponent(String(levelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} levelId
     * @param {UpdateLevelBody} updateLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiUpdateLevel: async (
      levelId: string,
      updateLevelBody: UpdateLevelBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'levelId' is not null or undefined
      assertParamExists('levelApiUpdateLevel', 'levelId', levelId);
      // verify required parameter 'updateLevelBody' is not null or undefined
      assertParamExists(
        'levelApiUpdateLevel',
        'updateLevelBody',
        updateLevelBody,
      );
      const localVarPath = `/level/{level_id}`.replace(
        `{${'level_id'}}`,
        encodeURIComponent(String(levelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLevelBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiUploadLevelImages: async (
      image: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists('levelApiUploadLevelImages', 'image', image);
      const localVarPath = `/level/image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LevelApiApi - functional programming interface
 * @export
 */
export const LevelApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = LevelApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateLevelBody} createLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiCreateLevel(
      createLevelBody: CreateLevelBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLevelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiCreateLevel(
        createLevelBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiDeleteLevel(
      levelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiDeleteLevel(
        levelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiGetAllLevels(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllLevelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiGetAllLevels(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiGetLevelById(
      levelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLevelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiGetLevelById(
        levelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} levelId
     * @param {UpdateLevelBody} updateLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiUpdateLevel(
      levelId: string,
      updateLevelBody: UpdateLevelBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLevelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiUpdateLevel(
        levelId,
        updateLevelBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async levelApiUploadLevelImages(
      image: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadImageLevelResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.levelApiUploadLevelImages(
        image,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * LevelApiApi - factory interface
 * @export
 */
export const LevelApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LevelApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateLevelBody} createLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiCreateLevel(
      createLevelBody: CreateLevelBody,
      options?: any,
    ): AxiosPromise<CreateLevelResponse> {
      return localVarFp
        .levelApiCreateLevel(createLevelBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiDeleteLevel(
      levelId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .levelApiDeleteLevel(levelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiGetAllLevels(options?: any): AxiosPromise<GetAllLevelResponse> {
      return localVarFp
        .levelApiGetAllLevels(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} levelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiGetLevelById(
      levelId: string,
      options?: any,
    ): AxiosPromise<CreateLevelResponse> {
      return localVarFp
        .levelApiGetLevelById(levelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} levelId
     * @param {UpdateLevelBody} updateLevelBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiUpdateLevel(
      levelId: string,
      updateLevelBody: UpdateLevelBody,
      options?: any,
    ): AxiosPromise<CreateLevelResponse> {
      return localVarFp
        .levelApiUpdateLevel(levelId, updateLevelBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    levelApiUploadLevelImages(
      image: any,
      options?: any,
    ): AxiosPromise<UploadImageLevelResponse> {
      return localVarFp
        .levelApiUploadLevelImages(image, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LevelApiApi - object-oriented interface
 * @export
 * @class LevelApiApi
 * @extends {BaseAPI}
 */
export class LevelApiApi extends BaseAPI {
  /**
   *
   * @param {CreateLevelBody} createLevelBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiCreateLevel(
    createLevelBody: CreateLevelBody,
    options?: AxiosRequestConfig,
  ) {
    return LevelApiApiFp(this.configuration)
      .levelApiCreateLevel(createLevelBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} levelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiDeleteLevel(levelId: string, options?: AxiosRequestConfig) {
    return LevelApiApiFp(this.configuration)
      .levelApiDeleteLevel(levelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiGetAllLevels(options?: AxiosRequestConfig) {
    return LevelApiApiFp(this.configuration)
      .levelApiGetAllLevels(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} levelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiGetLevelById(levelId: string, options?: AxiosRequestConfig) {
    return LevelApiApiFp(this.configuration)
      .levelApiGetLevelById(levelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} levelId
   * @param {UpdateLevelBody} updateLevelBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiUpdateLevel(
    levelId: string,
    updateLevelBody: UpdateLevelBody,
    options?: AxiosRequestConfig,
  ) {
    return LevelApiApiFp(this.configuration)
      .levelApiUpdateLevel(levelId, updateLevelBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LevelApiApi
   */
  public levelApiUploadLevelImages(image: any, options?: AxiosRequestConfig) {
    return LevelApiApiFp(this.configuration)
      .levelApiUploadLevelImages(image, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MailApiApi - axios parameter creator
 * @export
 */
export const MailApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiCreateTemplate: async (
      createMailTemplateBody: CreateMailTemplateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMailTemplateBody' is not null or undefined
      assertParamExists(
        'mailApiCreateTemplate',
        'createMailTemplateBody',
        createMailTemplateBody,
      );
      const localVarPath = `/mail/template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMailTemplateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} scheduledMailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiDeleteScheduledMail: async (
      scheduledMailId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'scheduledMailId' is not null or undefined
      assertParamExists(
        'mailApiDeleteScheduledMail',
        'scheduledMailId',
        scheduledMailId,
      );
      const localVarPath = `/mail/scheduled_mail/{scheduled_mail_id}`.replace(
        `{${'scheduled_mail_id'}}`,
        encodeURIComponent(String(scheduledMailId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiDeleteTemplateById: async (
      templateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists('mailApiDeleteTemplateById', 'templateId', templateId);
      const localVarPath = `/mail/template/{template_id}`.replace(
        `{${'template_id'}}`,
        encodeURIComponent(String(templateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetAllTemplates: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/mail/template-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetMailsLogs: async (
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/mail/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (maxDate !== undefined) {
        localVarQueryParameter['max_date'] = maxDate;
      }

      if (minDate !== undefined) {
        localVarQueryParameter['min_date'] = minDate;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (recipient !== undefined) {
        localVarQueryParameter['recipient'] = recipient;
      }

      if (sender !== undefined) {
        localVarQueryParameter['sender'] = sender;
      }

      if (templateId !== undefined) {
        localVarQueryParameter['template_id'] = templateId;
      }

      if (templateName !== undefined) {
        localVarQueryParameter['template_name'] = templateName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetScheduledMails: async (
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/mail/scheduled_mails`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (maxDate !== undefined) {
        localVarQueryParameter['max_date'] = maxDate;
      }

      if (minDate !== undefined) {
        localVarQueryParameter['min_date'] = minDate;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (recipient !== undefined) {
        localVarQueryParameter['recipient'] = recipient;
      }

      if (sender !== undefined) {
        localVarQueryParameter['sender'] = sender;
      }

      if (templateId !== undefined) {
        localVarQueryParameter['template_id'] = templateId;
      }

      if (templateName !== undefined) {
        localVarQueryParameter['template_name'] = templateName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetTemplateById: async (
      templateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists('mailApiGetTemplateById', 'templateId', templateId);
      const localVarPath = `/mail/template/{template_id}`.replace(
        `{${'template_id'}}`,
        encodeURIComponent(String(templateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetTemplates: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/mail/template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SentEmailBody} sentEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiSendEmailApi: async (
      sentEmailBody: SentEmailBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sentEmailBody' is not null or undefined
      assertParamExists('mailApiSendEmailApi', 'sentEmailBody', sentEmailBody);
      const localVarPath = `/mail/send_email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sentEmailBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} templateId
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiUpdateTemplate: async (
      templateId: string,
      createMailTemplateBody: CreateMailTemplateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'templateId' is not null or undefined
      assertParamExists('mailApiUpdateTemplate', 'templateId', templateId);
      // verify required parameter 'createMailTemplateBody' is not null or undefined
      assertParamExists(
        'mailApiUpdateTemplate',
        'createMailTemplateBody',
        createMailTemplateBody,
      );
      const localVarPath = `/mail/template/{template_id}`.replace(
        `{${'template_id'}}`,
        encodeURIComponent(String(templateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMailTemplateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MailApiApi - functional programming interface
 * @export
 */
export const MailApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = MailApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiCreateTemplate(
      createMailTemplateBody: CreateMailTemplateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateMailTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiCreateTemplate(
        createMailTemplateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} scheduledMailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiDeleteScheduledMail(
      scheduledMailId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiDeleteScheduledMail(
        scheduledMailId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiDeleteTemplateById(
      templateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiDeleteTemplateById(
        templateId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiGetAllTemplates(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllTemplatesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiGetAllTemplates(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiGetMailsLogs(
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMailLogsResonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiGetMailsLogs(
        maxDate,
        minDate,
        pageNo,
        pageSize,
        recipient,
        sender,
        templateId,
        templateName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiGetScheduledMails(
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ScheduledMailsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiGetScheduledMails(
        maxDate,
        minDate,
        pageNo,
        pageSize,
        recipient,
        sender,
        templateId,
        templateName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiGetTemplateById(
      templateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTemplateByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiGetTemplateById(
        templateId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiGetTemplates(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTemplatesListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiGetTemplates(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SentEmailBody} sentEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiSendEmailApi(
      sentEmailBody: SentEmailBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SentEmailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiSendEmailApi(
        sentEmailBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} templateId
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mailApiUpdateTemplate(
      templateId: string,
      createMailTemplateBody: CreateMailTemplateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MailTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mailApiUpdateTemplate(
        templateId,
        createMailTemplateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MailApiApi - factory interface
 * @export
 */
export const MailApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MailApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiCreateTemplate(
      createMailTemplateBody: CreateMailTemplateBody,
      options?: any,
    ): AxiosPromise<CreateMailTemplateResponse> {
      return localVarFp
        .mailApiCreateTemplate(createMailTemplateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} scheduledMailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiDeleteScheduledMail(
      scheduledMailId: string,
      options?: any,
    ): AxiosPromise<DeleteTemplateResponse> {
      return localVarFp
        .mailApiDeleteScheduledMail(scheduledMailId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiDeleteTemplateById(
      templateId: string,
      options?: any,
    ): AxiosPromise<DeleteTemplateResponse> {
      return localVarFp
        .mailApiDeleteTemplateById(templateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetAllTemplates(
      options?: any,
    ): AxiosPromise<GetAllTemplatesResponse> {
      return localVarFp
        .mailApiGetAllTemplates(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetMailsLogs(
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options?: any,
    ): AxiosPromise<GetMailLogsResonse> {
      return localVarFp
        .mailApiGetMailsLogs(
          maxDate,
          minDate,
          pageNo,
          pageSize,
          recipient,
          sender,
          templateId,
          templateName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [recipient]
     * @param {string} [sender]
     * @param {string} [templateId]
     * @param {string} [templateName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetScheduledMails(
      maxDate?: string,
      minDate?: string,
      pageNo?: number,
      pageSize?: number,
      recipient?: string,
      sender?: string,
      templateId?: string,
      templateName?: string,
      options?: any,
    ): AxiosPromise<ScheduledMailsResponse> {
      return localVarFp
        .mailApiGetScheduledMails(
          maxDate,
          minDate,
          pageNo,
          pageSize,
          recipient,
          sender,
          templateId,
          templateName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} templateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetTemplateById(
      templateId: string,
      options?: any,
    ): AxiosPromise<GetTemplateByIdResponse> {
      return localVarFp
        .mailApiGetTemplateById(templateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiGetTemplates(options?: any): AxiosPromise<GetTemplatesListResponse> {
      return localVarFp
        .mailApiGetTemplates(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SentEmailBody} sentEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiSendEmailApi(
      sentEmailBody: SentEmailBody,
      options?: any,
    ): AxiosPromise<SentEmailResponse> {
      return localVarFp
        .mailApiSendEmailApi(sentEmailBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} templateId
     * @param {CreateMailTemplateBody} createMailTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mailApiUpdateTemplate(
      templateId: string,
      createMailTemplateBody: CreateMailTemplateBody,
      options?: any,
    ): AxiosPromise<MailTemplateResponse> {
      return localVarFp
        .mailApiUpdateTemplate(templateId, createMailTemplateBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MailApiApi - object-oriented interface
 * @export
 * @class MailApiApi
 * @extends {BaseAPI}
 */
export class MailApiApi extends BaseAPI {
  /**
   *
   * @param {CreateMailTemplateBody} createMailTemplateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiCreateTemplate(
    createMailTemplateBody: CreateMailTemplateBody,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiCreateTemplate(createMailTemplateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} scheduledMailId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiDeleteScheduledMail(
    scheduledMailId: string,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiDeleteScheduledMail(scheduledMailId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} templateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiDeleteTemplateById(
    templateId: string,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiDeleteTemplateById(templateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiGetAllTemplates(options?: AxiosRequestConfig) {
    return MailApiApiFp(this.configuration)
      .mailApiGetAllTemplates(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [maxDate]
   * @param {string} [minDate]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [recipient]
   * @param {string} [sender]
   * @param {string} [templateId]
   * @param {string} [templateName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiGetMailsLogs(
    maxDate?: string,
    minDate?: string,
    pageNo?: number,
    pageSize?: number,
    recipient?: string,
    sender?: string,
    templateId?: string,
    templateName?: string,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiGetMailsLogs(
        maxDate,
        minDate,
        pageNo,
        pageSize,
        recipient,
        sender,
        templateId,
        templateName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [maxDate]
   * @param {string} [minDate]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [recipient]
   * @param {string} [sender]
   * @param {string} [templateId]
   * @param {string} [templateName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiGetScheduledMails(
    maxDate?: string,
    minDate?: string,
    pageNo?: number,
    pageSize?: number,
    recipient?: string,
    sender?: string,
    templateId?: string,
    templateName?: string,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiGetScheduledMails(
        maxDate,
        minDate,
        pageNo,
        pageSize,
        recipient,
        sender,
        templateId,
        templateName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} templateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiGetTemplateById(
    templateId: string,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiGetTemplateById(templateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiGetTemplates(options?: AxiosRequestConfig) {
    return MailApiApiFp(this.configuration)
      .mailApiGetTemplates(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SentEmailBody} sentEmailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiSendEmailApi(
    sentEmailBody: SentEmailBody,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiSendEmailApi(sentEmailBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} templateId
   * @param {CreateMailTemplateBody} createMailTemplateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MailApiApi
   */
  public mailApiUpdateTemplate(
    templateId: string,
    createMailTemplateBody: CreateMailTemplateBody,
    options?: AxiosRequestConfig,
  ) {
    return MailApiApiFp(this.configuration)
      .mailApiUpdateTemplate(templateId, createMailTemplateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ManagementApiApi - axios parameter creator
 * @export
 */
export const ManagementApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    managementApiGetPendingEntitiesCounts: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/management_api/pending`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManagementApiApi - functional programming interface
 * @export
 */
export const ManagementApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ManagementApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async managementApiGetPendingEntitiesCounts(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPendingEntityCountResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.managementApiGetPendingEntitiesCounts(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ManagementApiApi - factory interface
 * @export
 */
export const ManagementApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ManagementApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    managementApiGetPendingEntitiesCounts(
      options?: any,
    ): AxiosPromise<GetPendingEntityCountResponse> {
      return localVarFp
        .managementApiGetPendingEntitiesCounts(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ManagementApiApi - object-oriented interface
 * @export
 * @class ManagementApiApi
 * @extends {BaseAPI}
 */
export class ManagementApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApiApi
   */
  public managementApiGetPendingEntitiesCounts(options?: AxiosRequestConfig) {
    return ManagementApiApiFp(this.configuration)
      .managementApiGetPendingEntitiesCounts(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MocktestApiApi - axios parameter creator
 * @export
 */
export const MocktestApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} mocktestId
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiAttemptQuestion: async (
      mocktestId: string,
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists('mocktestApiAttemptQuestion', 'mocktestId', mocktestId);
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('mocktestApiAttemptQuestion', 'questionId', questionId);
      // verify required parameter 'attemptQuestionBody' is not null or undefined
      assertParamExists(
        'mocktestApiAttemptQuestion',
        'attemptQuestionBody',
        attemptQuestionBody,
      );
      const localVarPath = `/mocktest/{mocktest_id}/question/{question_id}/attempt`
        .replace(`{${'mocktest_id'}}`, encodeURIComponent(String(mocktestId)))
        .replace(`{${'question_id'}}`, encodeURIComponent(String(questionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        attemptQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiCloseExam: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists('mocktestApiCloseExam', 'mocktestId', mocktestId);
      const localVarPath = `/mocktest/{mocktest_id}/close`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatMockTestBody} creatMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiCreateMocktest: async (
      creatMockTestBody: CreatMockTestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatMockTestBody' is not null or undefined
      assertParamExists(
        'mocktestApiCreateMocktest',
        'creatMockTestBody',
        creatMockTestBody,
      );
      const localVarPath = `/mocktest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        creatMockTestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiDeleteExam: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists('mocktestApiDeleteExam', 'mocktestId', mocktestId);
      const localVarPath = `/admin/mocktest/{mocktest_id}`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody3} bulkDeleteBody3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiDeleteMocktestBulk: async (
      bulkDeleteBody3: BulkDeleteBody3,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody3' is not null or undefined
      assertParamExists(
        'mocktestApiDeleteMocktestBulk',
        'bulkDeleteBody3',
        bulkDeleteBody3,
      );
      const localVarPath = `/admin/mocktest/bulk_delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody3,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestAnalytics: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiGetMocktestAnalytics',
        'mocktestId',
        mocktestId,
      );
      const localVarPath = `/mocktest/{mocktest_id}/analytics`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestById: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists('mocktestApiGetMocktestById', 'mocktestId', mocktestId);
      const localVarPath = `/mocktest/{mocktest_id}`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestPrerequisiteStatus: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiGetMocktestPrerequisiteStatus',
        'mocktestId',
        mocktestId,
      );
      const localVarPath = `/mocktest/{mocktest_id}/prerequisite-status`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestStartStatistics: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiGetMocktestStartStatistics',
        'mocktestId',
        mocktestId,
      );
      const localVarPath = `/mocktest/{mocktest_id}/start-statistics`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetOngoingAnalytics: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiGetOngoingAnalytics',
        'mocktestId',
        mocktestId,
      );
      const localVarPath = `/mocktest/{mocktest_id}/analytics/ongoing`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {string} [questionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetUnansweredQuestionByExam: async (
      mocktestId: string,
      questionId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiGetUnansweredQuestionByExam',
        'mocktestId',
        mocktestId,
      );
      const localVarPath = `/mocktest/{mocktest_id}/unanswered`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (questionId !== undefined) {
        localVarQueryParameter['question_id'] = questionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domainId
     * @param {string} [examType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetUserDomainMockExams: async (
      domainId: string,
      examType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'domainId' is not null or undefined
      assertParamExists(
        'mocktestApiGetUserDomainMockExams',
        'domainId',
        domainId,
      );
      const localVarPath = `/domain/{domain_id}/mocktest`.replace(
        `{${'domain_id'}}`,
        encodeURIComponent(String(domainId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (examType !== undefined) {
        localVarQueryParameter['exam_type'] = examType;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiStartExam: async (
      mocktestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists('mocktestApiStartExam', 'mocktestId', mocktestId);
      const localVarPath = `/mocktest/{mocktest_id}/start`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} mocktestId
     * @param {UpdateMockTestBody} updateMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiUpdateMocktestDetail: async (
      mocktestId: string,
      updateMockTestBody: UpdateMockTestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mocktestId' is not null or undefined
      assertParamExists(
        'mocktestApiUpdateMocktestDetail',
        'mocktestId',
        mocktestId,
      );
      // verify required parameter 'updateMockTestBody' is not null or undefined
      assertParamExists(
        'mocktestApiUpdateMocktestDetail',
        'updateMockTestBody',
        updateMockTestBody,
      );
      const localVarPath = `/admin/mocktest/{mocktest_id}`.replace(
        `{${'mocktest_id'}}`,
        encodeURIComponent(String(mocktestId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMockTestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MocktestApiApi - functional programming interface
 * @export
 */
export const MocktestApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = MocktestApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} mocktestId
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiAttemptQuestion(
      mocktestId: string,
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiAttemptQuestion(
        mocktestId,
        questionId,
        attemptQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiCloseExam(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiCloseExam(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreatMockTestBody} creatMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiCreateMocktest(
      creatMockTestBody: CreatMockTestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateMockTestRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiCreateMocktest(
        creatMockTestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiDeleteExam(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiDeleteExam(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody3} bulkDeleteBody3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiDeleteMocktestBulk(
      bulkDeleteBody3: BulkDeleteBody3,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkDeleteMockTestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiDeleteMocktestBulk(
        bulkDeleteBody3,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetMocktestAnalytics(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MockTestAnalyticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetMocktestAnalytics(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetMocktestById(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMockTestByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetMocktestById(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetMocktestPrerequisiteStatus(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMockTestPrerequisiteStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetMocktestPrerequisiteStatus(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetMocktestStartStatistics(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StartStatisticsRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetMocktestStartStatistics(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetOngoingAnalytics(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OngoingStatisticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetOngoingAnalytics(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {string} [questionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetUnansweredQuestionByExam(
      mocktestId: string,
      questionId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUnansweredQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetUnansweredQuestionByExam(
        mocktestId,
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} domainId
     * @param {string} [examType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiGetUserDomainMockExams(
      domainId: string,
      examType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllDomainMockTestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiGetUserDomainMockExams(
        domainId,
        examType,
        name,
        pageNo,
        pageSize,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiStartExam(
      mocktestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateMockTestRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiStartExam(
        mocktestId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} mocktestId
     * @param {UpdateMockTestBody} updateMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mocktestApiUpdateMocktestDetail(
      mocktestId: string,
      updateMockTestBody: UpdateMockTestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MockTestUpdateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mocktestApiUpdateMocktestDetail(
        mocktestId,
        updateMockTestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MocktestApiApi - factory interface
 * @export
 */
export const MocktestApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MocktestApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} mocktestId
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiAttemptQuestion(
      mocktestId: string,
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .mocktestApiAttemptQuestion(
          mocktestId,
          questionId,
          attemptQuestionBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiCloseExam(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .mocktestApiCloseExam(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatMockTestBody} creatMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiCreateMocktest(
      creatMockTestBody: CreatMockTestBody,
      options?: any,
    ): AxiosPromise<CreateMockTestRepsonse> {
      return localVarFp
        .mocktestApiCreateMocktest(creatMockTestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiDeleteExam(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .mocktestApiDeleteExam(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody3} bulkDeleteBody3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiDeleteMocktestBulk(
      bulkDeleteBody3: BulkDeleteBody3,
      options?: any,
    ): AxiosPromise<BulkDeleteMockTestResponse> {
      return localVarFp
        .mocktestApiDeleteMocktestBulk(bulkDeleteBody3, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestAnalytics(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<MockTestAnalyticsResponse> {
      return localVarFp
        .mocktestApiGetMocktestAnalytics(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestById(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<GetMockTestByIdResponse> {
      return localVarFp
        .mocktestApiGetMocktestById(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestPrerequisiteStatus(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<GetMockTestPrerequisiteStatusResponse> {
      return localVarFp
        .mocktestApiGetMocktestPrerequisiteStatus(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetMocktestStartStatistics(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<StartStatisticsRepsonse> {
      return localVarFp
        .mocktestApiGetMocktestStartStatistics(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetOngoingAnalytics(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<OngoingStatisticsResponse> {
      return localVarFp
        .mocktestApiGetOngoingAnalytics(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {string} [questionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetUnansweredQuestionByExam(
      mocktestId: string,
      questionId?: string,
      options?: any,
    ): AxiosPromise<GetUnansweredQuestionResponse> {
      return localVarFp
        .mocktestApiGetUnansweredQuestionByExam(mocktestId, questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domainId
     * @param {string} [examType]
     * @param {string} [name]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiGetUserDomainMockExams(
      domainId: string,
      examType?: string,
      name?: string,
      pageNo?: number,
      pageSize?: number,
      tagId?: string,
      options?: any,
    ): AxiosPromise<GetAllDomainMockTestResponse> {
      return localVarFp
        .mocktestApiGetUserDomainMockExams(
          domainId,
          examType,
          name,
          pageNo,
          pageSize,
          tagId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiStartExam(
      mocktestId: string,
      options?: any,
    ): AxiosPromise<CreateMockTestRepsonse> {
      return localVarFp
        .mocktestApiStartExam(mocktestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} mocktestId
     * @param {UpdateMockTestBody} updateMockTestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mocktestApiUpdateMocktestDetail(
      mocktestId: string,
      updateMockTestBody: UpdateMockTestBody,
      options?: any,
    ): AxiosPromise<MockTestUpdateResponse> {
      return localVarFp
        .mocktestApiUpdateMocktestDetail(
          mocktestId,
          updateMockTestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MocktestApiApi - object-oriented interface
 * @export
 * @class MocktestApiApi
 * @extends {BaseAPI}
 */
export class MocktestApiApi extends BaseAPI {
  /**
   *
   * @param {string} mocktestId
   * @param {string} questionId
   * @param {AttemptQuestionBody} attemptQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiAttemptQuestion(
    mocktestId: string,
    questionId: string,
    attemptQuestionBody: AttemptQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiAttemptQuestion(
        mocktestId,
        questionId,
        attemptQuestionBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiCloseExam(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiCloseExam(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatMockTestBody} creatMockTestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiCreateMocktest(
    creatMockTestBody: CreatMockTestBody,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiCreateMocktest(creatMockTestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiDeleteExam(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiDeleteExam(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody3} bulkDeleteBody3
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiDeleteMocktestBulk(
    bulkDeleteBody3: BulkDeleteBody3,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiDeleteMocktestBulk(bulkDeleteBody3, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetMocktestAnalytics(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetMocktestAnalytics(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetMocktestById(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetMocktestById(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetMocktestPrerequisiteStatus(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetMocktestPrerequisiteStatus(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetMocktestStartStatistics(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetMocktestStartStatistics(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetOngoingAnalytics(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetOngoingAnalytics(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {string} [questionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetUnansweredQuestionByExam(
    mocktestId: string,
    questionId?: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetUnansweredQuestionByExam(mocktestId, questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domainId
   * @param {string} [examType]
   * @param {string} [name]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiGetUserDomainMockExams(
    domainId: string,
    examType?: string,
    name?: string,
    pageNo?: number,
    pageSize?: number,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiGetUserDomainMockExams(
        domainId,
        examType,
        name,
        pageNo,
        pageSize,
        tagId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiStartExam(
    mocktestId: string,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiStartExam(mocktestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} mocktestId
   * @param {UpdateMockTestBody} updateMockTestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MocktestApiApi
   */
  public mocktestApiUpdateMocktestDetail(
    mocktestId: string,
    updateMockTestBody: UpdateMockTestBody,
    options?: AxiosRequestConfig,
  ) {
    return MocktestApiApiFp(this.configuration)
      .mocktestApiUpdateMocktestDetail(mocktestId, updateMockTestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApiApi - axios parameter creator
 * @export
 */
export const NotificationApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiCreateEmailNotifications: async (
      createEmailNotification: CreateEmailNotification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEmailNotification' is not null or undefined
      assertParamExists(
        'notificationApiCreateEmailNotifications',
        'createEmailNotification',
        createEmailNotification,
      );
      const localVarPath = `/admin/email/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEmailNotification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiDeleteEmailNotifications: async (
      emailNotificationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emailNotificationId' is not null or undefined
      assertParamExists(
        'notificationApiDeleteEmailNotifications',
        'emailNotificationId',
        emailNotificationId,
      );
      const localVarPath = `/admin/email/notifications/{email_notification_id}`.replace(
        `{${'email_notification_id'}}`,
        encodeURIComponent(String(emailNotificationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiGetEmailNotifications: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/email/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiGetUserNotificationsByUserType: async (
      userType: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userType' is not null or undefined
      assertParamExists(
        'notificationApiGetUserNotificationsByUserType',
        'userType',
        userType,
      );
      const localVarPath = `/email/{user_type}/notifications`.replace(
        `{${'user_type'}}`,
        encodeURIComponent(String(userType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ToggleUserEmailNotifications} toggleUserEmailNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiToggleEmailNotifications: async (
      toggleUserEmailNotifications: ToggleUserEmailNotifications,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'toggleUserEmailNotifications' is not null or undefined
      assertParamExists(
        'notificationApiToggleEmailNotifications',
        'toggleUserEmailNotifications',
        toggleUserEmailNotifications,
      );
      const localVarPath = `/email/notifications/toggle`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        toggleUserEmailNotifications,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiUpdateEmailNotifications: async (
      emailNotificationId: string,
      createEmailNotification: CreateEmailNotification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'emailNotificationId' is not null or undefined
      assertParamExists(
        'notificationApiUpdateEmailNotifications',
        'emailNotificationId',
        emailNotificationId,
      );
      // verify required parameter 'createEmailNotification' is not null or undefined
      assertParamExists(
        'notificationApiUpdateEmailNotifications',
        'createEmailNotification',
        createEmailNotification,
      );
      const localVarPath = `/admin/email/notifications/{email_notification_id}`.replace(
        `{${'email_notification_id'}}`,
        encodeURIComponent(String(emailNotificationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEmailNotification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApiApi - functional programming interface
 * @export
 */
export const NotificationApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiCreateEmailNotifications(
      createEmailNotification: CreateEmailNotification,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateEmailNotificationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiCreateEmailNotifications(
        createEmailNotification,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiDeleteEmailNotifications(
      emailNotificationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteEmailNotificationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiDeleteEmailNotifications(
        emailNotificationId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiGetEmailNotifications(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEmailNotification>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiGetEmailNotifications(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} userType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiGetUserNotificationsByUserType(
      userType: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserNotificationSettingsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiGetUserNotificationsByUserType(
        userType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ToggleUserEmailNotifications} toggleUserEmailNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiToggleEmailNotifications(
      toggleUserEmailNotifications: ToggleUserEmailNotifications,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteEmailNotificationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiToggleEmailNotifications(
        toggleUserEmailNotifications,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationApiUpdateEmailNotifications(
      emailNotificationId: string,
      createEmailNotification: CreateEmailNotification,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateEmailNotificationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationApiUpdateEmailNotifications(
        emailNotificationId,
        createEmailNotification,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotificationApiApi - factory interface
 * @export
 */
export const NotificationApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationApiApiFp(configuration);
  return {
    /**
     *
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiCreateEmailNotifications(
      createEmailNotification: CreateEmailNotification,
      options?: any,
    ): AxiosPromise<CreateEmailNotificationResponse> {
      return localVarFp
        .notificationApiCreateEmailNotifications(
          createEmailNotification,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiDeleteEmailNotifications(
      emailNotificationId: string,
      options?: any,
    ): AxiosPromise<DeleteEmailNotificationResponse> {
      return localVarFp
        .notificationApiDeleteEmailNotifications(emailNotificationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiGetEmailNotifications(
      options?: any,
    ): AxiosPromise<GetEmailNotification> {
      return localVarFp
        .notificationApiGetEmailNotifications(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiGetUserNotificationsByUserType(
      userType: string,
      options?: any,
    ): AxiosPromise<GetUserNotificationSettingsResponse> {
      return localVarFp
        .notificationApiGetUserNotificationsByUserType(userType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ToggleUserEmailNotifications} toggleUserEmailNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiToggleEmailNotifications(
      toggleUserEmailNotifications: ToggleUserEmailNotifications,
      options?: any,
    ): AxiosPromise<DeleteEmailNotificationResponse> {
      return localVarFp
        .notificationApiToggleEmailNotifications(
          toggleUserEmailNotifications,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} emailNotificationId
     * @param {CreateEmailNotification} createEmailNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationApiUpdateEmailNotifications(
      emailNotificationId: string,
      createEmailNotification: CreateEmailNotification,
      options?: any,
    ): AxiosPromise<UpdateEmailNotificationsResponse> {
      return localVarFp
        .notificationApiUpdateEmailNotifications(
          emailNotificationId,
          createEmailNotification,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApiApi - object-oriented interface
 * @export
 * @class NotificationApiApi
 * @extends {BaseAPI}
 */
export class NotificationApiApi extends BaseAPI {
  /**
   *
   * @param {CreateEmailNotification} createEmailNotification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiCreateEmailNotifications(
    createEmailNotification: CreateEmailNotification,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiCreateEmailNotifications(createEmailNotification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} emailNotificationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiDeleteEmailNotifications(
    emailNotificationId: string,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiDeleteEmailNotifications(emailNotificationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiGetEmailNotifications(options?: AxiosRequestConfig) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiGetEmailNotifications(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiGetUserNotificationsByUserType(
    userType: string,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiGetUserNotificationsByUserType(userType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ToggleUserEmailNotifications} toggleUserEmailNotifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiToggleEmailNotifications(
    toggleUserEmailNotifications: ToggleUserEmailNotifications,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiToggleEmailNotifications(
        toggleUserEmailNotifications,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} emailNotificationId
   * @param {CreateEmailNotification} createEmailNotification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiApi
   */
  public notificationApiUpdateEmailNotifications(
    emailNotificationId: string,
    createEmailNotification: CreateEmailNotification,
    options?: AxiosRequestConfig,
  ) {
    return NotificationApiApiFp(this.configuration)
      .notificationApiUpdateEmailNotifications(
        emailNotificationId,
        createEmailNotification,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrganizationApiApi - axios parameter creator
 * @export
 */
export const OrganizationApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {BulkUpdateOrgBody} bulkUpdateOrgBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiBulkUpdateOrganizationStatus: async (
      bulkUpdateOrgBody: BulkUpdateOrgBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkUpdateOrgBody' is not null or undefined
      assertParamExists(
        'organizationApiBulkUpdateOrganizationStatus',
        'bulkUpdateOrgBody',
        bulkUpdateOrgBody,
      );
      const localVarPath = `/org/bulk-update-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkUpdateOrgBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {RoleChangeBody} roleChangeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiChangeOrgUserRole: async (
      orgId: string,
      roleChangeBody: RoleChangeBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiChangeOrgUserRole', 'orgId', orgId);
      // verify required parameter 'roleChangeBody' is not null or undefined
      assertParamExists(
        'organizationApiChangeOrgUserRole',
        'roleChangeBody',
        roleChangeBody,
      );
      const localVarPath = `/org/{org_id}/role-change`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roleChangeBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateOrganizationBody} createOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiCreateOrganization: async (
      createOrganizationBody: CreateOrganizationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOrganizationBody' is not null or undefined
      assertParamExists(
        'organizationApiCreateOrganization',
        'createOrganizationBody',
        createOrganizationBody,
      );
      const localVarPath = `/org`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrganizationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrgUser: async (
      orgId: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiGetOrgUser', 'orgId', orgId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('organizationApiGetOrgUser', 'userId', userId);
      const localVarPath = `/org/{org_id}/user/{user_id}`
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationById: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiGetOrganizationById', 'orgId', orgId);
      const localVarPath = `/org/{org_id}`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationList: async (
      name?: string,
      page?: string,
      pageSize?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/org`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationUsers: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiGetOrganizationUsers', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/users`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetPendingOrganizationList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/org/membership-pending`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetVerificationDoc: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiGetVerificationDoc', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/verification-doc`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {InviteOrgMembersBody} inviteOrgMembersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiInviteOrganizationMembers: async (
      orgId: string,
      inviteOrgMembersBody: InviteOrgMembersBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiInviteOrganizationMembers',
        'orgId',
        orgId,
      );
      // verify required parameter 'inviteOrgMembersBody' is not null or undefined
      assertParamExists(
        'organizationApiInviteOrganizationMembers',
        'inviteOrgMembersBody',
        inviteOrgMembersBody,
      );
      const localVarPath = `/org/{org_id}/invite-member`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inviteOrgMembersBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} companyId
     * @param {string} teamName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiIsTeamNameAvailable: async (
      companyId: string,
      teamName: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'organizationApiIsTeamNameAvailable',
        'companyId',
        companyId,
      );
      // verify required parameter 'teamName' is not null or undefined
      assertParamExists(
        'organizationApiIsTeamNameAvailable',
        'teamName',
        teamName,
      );
      const localVarPath = `/company/{company_id}/{team_name}/is_available`
        .replace(`{${'company_id'}}`, encodeURIComponent(String(companyId)))
        .replace(`{${'team_name'}}`, encodeURIComponent(String(teamName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiMarkOrganizationDelete: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiMarkOrganizationDelete',
        'orgId',
        orgId,
      );
      const localVarPath = `/org/{org_id}/delete`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} email
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiRemoveOrganizationMembers: async (
      email: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'email' is not null or undefined
      assertParamExists(
        'organizationApiRemoveOrganizationMembers',
        'email',
        email,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiRemoveOrganizationMembers',
        'orgId',
        orgId,
      );
      const localVarPath = `/org/{org_id}/remove-member`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [document]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiReviewOrganizations: async (
      document?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/org/review`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (document !== undefined) {
        localVarQueryParameter['document'] = document;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} newOwnerUserId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiTransferOwnership: async (
      newOwnerUserId: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'newOwnerUserId' is not null or undefined
      assertParamExists(
        'organizationApiTransferOwnership',
        'newOwnerUserId',
        newOwnerUserId,
      );
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiTransferOwnership', 'orgId', orgId);
      const localVarPath = `/org/{org_id}/ownership/{new_owner_user_id}`
        .replace(
          `{${'new_owner_user_id'}}`,
          encodeURIComponent(String(newOwnerUserId)),
        )
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {MemberAcceptanceBody} memberAcceptanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateAcceptanceStatus: async (
      orgId: string,
      memberAcceptanceBody: MemberAcceptanceBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiUpdateAcceptanceStatus',
        'orgId',
        orgId,
      );
      // verify required parameter 'memberAcceptanceBody' is not null or undefined
      assertParamExists(
        'organizationApiUpdateAcceptanceStatus',
        'memberAcceptanceBody',
        memberAcceptanceBody,
      );
      const localVarPath = `/org/{org_id}/membership-acceptance`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memberAcceptanceBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateMemberResignation: async (
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiUpdateMemberResignation',
        'orgId',
        orgId,
      );
      const localVarPath = `/org/{org_id}/membership-resignation`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {UpdateOrganizationBody} updateOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateOrganization: async (
      orgId: string,
      updateOrganizationBody: UpdateOrganizationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('organizationApiUpdateOrganization', 'orgId', orgId);
      // verify required parameter 'updateOrganizationBody' is not null or undefined
      assertParamExists(
        'organizationApiUpdateOrganization',
        'updateOrganizationBody',
        updateOrganizationBody,
      );
      const localVarPath = `/org/{org_id}`.replace(
        `{${'org_id'}}`,
        encodeURIComponent(String(orgId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrganizationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateOrgUserBody} updateOrgUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateOrganizationUser: async (
      orgId: string,
      userId: string,
      updateOrgUserBody: UpdateOrgUserBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists(
        'organizationApiUpdateOrganizationUser',
        'orgId',
        orgId,
      );
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        'organizationApiUpdateOrganizationUser',
        'userId',
        userId,
      );
      // verify required parameter 'updateOrgUserBody' is not null or undefined
      assertParamExists(
        'organizationApiUpdateOrganizationUser',
        'updateOrgUserBody',
        updateOrgUserBody,
      );
      const localVarPath = `/org/{org_id}/user/{user_id}`
        .replace(`{${'org_id'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrgUserBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} imageFile
     * @param {string} [orgId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUploadOrganizationLogo: async (
      imageFile: any,
      orgId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageFile' is not null or undefined
      assertParamExists(
        'organizationApiUploadOrganizationLogo',
        'imageFile',
        imageFile,
      );
      const localVarPath = `/org/upload-logo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (orgId !== undefined) {
        localVarQueryParameter['org_id'] = orgId;
      }

      if (imageFile !== undefined) {
        localVarFormParams.append('image_file', imageFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApiApi - functional programming interface
 * @export
 */
export const OrganizationApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {BulkUpdateOrgBody} bulkUpdateOrgBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiBulkUpdateOrganizationStatus(
      bulkUpdateOrgBody: BulkUpdateOrgBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiBulkUpdateOrganizationStatus(
        bulkUpdateOrgBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {RoleChangeBody} roleChangeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiChangeOrgUserRole(
      orgId: string,
      roleChangeBody: RoleChangeBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiChangeOrgUserRole(
        orgId,
        roleChangeBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateOrganizationBody} createOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiCreateOrganization(
      createOrganizationBody: CreateOrganizationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrganizationListSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiCreateOrganization(
        createOrganizationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetOrgUser(
      orgId: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrganizationUsersListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetOrgUser(
        orgId,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetOrganizationById(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrganizationListSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetOrganizationById(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetOrganizationList(
      name?: string,
      page?: string,
      pageSize?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrgListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetOrganizationList(
        name,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetOrganizationUsers(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetOrganizationUsersListResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetOrganizationUsers(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetPendingOrganizationList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrgListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetPendingOrganizationList(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiGetVerificationDoc(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiGetVerificationDoc(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {InviteOrgMembersBody} inviteOrgMembersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiInviteOrganizationMembers(
      orgId: string,
      inviteOrgMembersBody: InviteOrgMembersBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiInviteOrganizationMembers(
        orgId,
        inviteOrgMembersBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} companyId
     * @param {string} teamName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiIsTeamNameAvailable(
      companyId: string,
      teamName: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TeamNameAvailibilityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiIsTeamNameAvailable(
        companyId,
        teamName,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiMarkOrganizationDelete(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrganizationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiMarkOrganizationDelete(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} email
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiRemoveOrganizationMembers(
      email: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiRemoveOrganizationMembers(
        email,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [document]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiReviewOrganizations(
      document?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReviewOrganizationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiReviewOrganizations(
        document,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} newOwnerUserId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiTransferOwnership(
      newOwnerUserId: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiTransferOwnership(
        newOwnerUserId,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {MemberAcceptanceBody} memberAcceptanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiUpdateAcceptanceStatus(
      orgId: string,
      memberAcceptanceBody: MemberAcceptanceBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiUpdateAcceptanceStatus(
        orgId,
        memberAcceptanceBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiUpdateMemberResignation(
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiUpdateMemberResignation(
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {UpdateOrganizationBody} updateOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiUpdateOrganization(
      orgId: string,
      updateOrganizationBody: UpdateOrganizationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOrganizationListSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiUpdateOrganization(
        orgId,
        updateOrganizationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateOrgUserBody} updateOrgUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiUpdateOrganizationUser(
      orgId: string,
      userId: string,
      updateOrgUserBody: UpdateOrgUserBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiUpdateOrganizationUser(
        orgId,
        userId,
        updateOrgUserBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} imageFile
     * @param {string} [orgId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationApiUploadOrganizationLogo(
      imageFile: any,
      orgId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationApiUploadOrganizationLogo(
        imageFile,
        orgId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * OrganizationApiApi - factory interface
 * @export
 */
export const OrganizationApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationApiApiFp(configuration);
  return {
    /**
     *
     * @param {BulkUpdateOrgBody} bulkUpdateOrgBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiBulkUpdateOrganizationStatus(
      bulkUpdateOrgBody: BulkUpdateOrgBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiBulkUpdateOrganizationStatus(bulkUpdateOrgBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {RoleChangeBody} roleChangeBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiChangeOrgUserRole(
      orgId: string,
      roleChangeBody: RoleChangeBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiChangeOrgUserRole(orgId, roleChangeBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateOrganizationBody} createOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiCreateOrganization(
      createOrganizationBody: CreateOrganizationBody,
      options?: any,
    ): AxiosPromise<GetOrganizationListSub> {
      return localVarFp
        .organizationApiCreateOrganization(createOrganizationBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrgUser(
      orgId: string,
      userId: string,
      options?: any,
    ): AxiosPromise<GetOrganizationUsersListResponse> {
      return localVarFp
        .organizationApiGetOrgUser(orgId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationById(
      orgId: string,
      options?: any,
    ): AxiosPromise<GetOrganizationListSub> {
      return localVarFp
        .organizationApiGetOrganizationById(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationList(
      name?: string,
      page?: string,
      pageSize?: string,
      options?: any,
    ): AxiosPromise<GetOrgListResponse> {
      return localVarFp
        .organizationApiGetOrganizationList(name, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetOrganizationUsers(
      orgId: string,
      options?: any,
    ): AxiosPromise<Array<GetOrganizationUsersListResponse>> {
      return localVarFp
        .organizationApiGetOrganizationUsers(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetPendingOrganizationList(
      options?: any,
    ): AxiosPromise<GetOrgListResponse> {
      return localVarFp
        .organizationApiGetPendingOrganizationList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiGetVerificationDoc(
      orgId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .organizationApiGetVerificationDoc(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {InviteOrgMembersBody} inviteOrgMembersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiInviteOrganizationMembers(
      orgId: string,
      inviteOrgMembersBody: InviteOrgMembersBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiInviteOrganizationMembers(
          orgId,
          inviteOrgMembersBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} companyId
     * @param {string} teamName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiIsTeamNameAvailable(
      companyId: string,
      teamName: string,
      options?: any,
    ): AxiosPromise<TeamNameAvailibilityResponse> {
      return localVarFp
        .organizationApiIsTeamNameAvailable(companyId, teamName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiMarkOrganizationDelete(
      orgId: string,
      options?: any,
    ): AxiosPromise<OrganizationResponse> {
      return localVarFp
        .organizationApiMarkOrganizationDelete(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} email
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiRemoveOrganizationMembers(
      email: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiRemoveOrganizationMembers(email, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [document]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchQuery]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiReviewOrganizations(
      document?: string,
      page?: string,
      pageSize?: string,
      searchQuery?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      options?: any,
    ): AxiosPromise<ReviewOrganizationResponse> {
      return localVarFp
        .organizationApiReviewOrganizations(
          document,
          page,
          pageSize,
          searchQuery,
          sortBy,
          sortOrder,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} newOwnerUserId
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiTransferOwnership(
      newOwnerUserId: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiTransferOwnership(newOwnerUserId, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {MemberAcceptanceBody} memberAcceptanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateAcceptanceStatus(
      orgId: string,
      memberAcceptanceBody: MemberAcceptanceBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiUpdateAcceptanceStatus(
          orgId,
          memberAcceptanceBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateMemberResignation(
      orgId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiUpdateMemberResignation(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {UpdateOrganizationBody} updateOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateOrganization(
      orgId: string,
      updateOrganizationBody: UpdateOrganizationBody,
      options?: any,
    ): AxiosPromise<GetOrganizationListSub> {
      return localVarFp
        .organizationApiUpdateOrganization(
          orgId,
          updateOrganizationBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} userId
     * @param {UpdateOrgUserBody} updateOrgUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUpdateOrganizationUser(
      orgId: string,
      userId: string,
      updateOrgUserBody: UpdateOrgUserBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .organizationApiUpdateOrganizationUser(
          orgId,
          userId,
          updateOrgUserBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} imageFile
     * @param {string} [orgId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationApiUploadOrganizationLogo(
      imageFile: any,
      orgId?: string,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .organizationApiUploadOrganizationLogo(imageFile, orgId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApiApi - object-oriented interface
 * @export
 * @class OrganizationApiApi
 * @extends {BaseAPI}
 */
export class OrganizationApiApi extends BaseAPI {
  /**
   *
   * @param {BulkUpdateOrgBody} bulkUpdateOrgBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiBulkUpdateOrganizationStatus(
    bulkUpdateOrgBody: BulkUpdateOrgBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiBulkUpdateOrganizationStatus(bulkUpdateOrgBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {RoleChangeBody} roleChangeBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiChangeOrgUserRole(
    orgId: string,
    roleChangeBody: RoleChangeBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiChangeOrgUserRole(orgId, roleChangeBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateOrganizationBody} createOrganizationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiCreateOrganization(
    createOrganizationBody: CreateOrganizationBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiCreateOrganization(createOrganizationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetOrgUser(
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetOrgUser(orgId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetOrganizationById(
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetOrganizationById(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [name]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetOrganizationList(
    name?: string,
    page?: string,
    pageSize?: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetOrganizationList(name, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetOrganizationUsers(
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetOrganizationUsers(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetPendingOrganizationList(
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetPendingOrganizationList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiGetVerificationDoc(
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiGetVerificationDoc(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {InviteOrgMembersBody} inviteOrgMembersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiInviteOrganizationMembers(
    orgId: string,
    inviteOrgMembersBody: InviteOrgMembersBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiInviteOrganizationMembers(
        orgId,
        inviteOrgMembersBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} companyId
   * @param {string} teamName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiIsTeamNameAvailable(
    companyId: string,
    teamName: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiIsTeamNameAvailable(companyId, teamName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiMarkOrganizationDelete(
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiMarkOrganizationDelete(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} email
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiRemoveOrganizationMembers(
    email: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiRemoveOrganizationMembers(email, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [document]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [searchQuery]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiReviewOrganizations(
    document?: string,
    page?: string,
    pageSize?: string,
    searchQuery?: string,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiReviewOrganizations(
        document,
        page,
        pageSize,
        searchQuery,
        sortBy,
        sortOrder,
        status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} newOwnerUserId
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiTransferOwnership(
    newOwnerUserId: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiTransferOwnership(newOwnerUserId, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {MemberAcceptanceBody} memberAcceptanceBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiUpdateAcceptanceStatus(
    orgId: string,
    memberAcceptanceBody: MemberAcceptanceBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiUpdateAcceptanceStatus(
        orgId,
        memberAcceptanceBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiUpdateMemberResignation(
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiUpdateMemberResignation(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {UpdateOrganizationBody} updateOrganizationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiUpdateOrganization(
    orgId: string,
    updateOrganizationBody: UpdateOrganizationBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiUpdateOrganization(orgId, updateOrganizationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} userId
   * @param {UpdateOrgUserBody} updateOrgUserBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiUpdateOrganizationUser(
    orgId: string,
    userId: string,
    updateOrgUserBody: UpdateOrgUserBody,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiUpdateOrganizationUser(
        orgId,
        userId,
        updateOrgUserBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} imageFile
   * @param {string} [orgId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApiApi
   */
  public organizationApiUploadOrganizationLogo(
    imageFile: any,
    orgId?: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiApiFp(this.configuration)
      .organizationApiUploadOrganizationLogo(imageFile, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentApiApi - axios parameter creator
 * @export
 */
export const PaymentApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {AddItemToCartBody} addItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAddItemToCart: async (
      addItemToCartBody: AddItemToCartBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addItemToCartBody' is not null or undefined
      assertParamExists(
        'paymentApiAddItemToCart',
        'addItemToCartBody',
        addItemToCartBody,
      );
      const localVarPath = `/cart/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addItemToCartBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAddNewItem: async (
      addNewStoreItemBody: AddNewStoreItemBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addNewStoreItemBody' is not null or undefined
      assertParamExists(
        'paymentApiAddNewItem',
        'addNewStoreItemBody',
        addNewStoreItemBody,
      );
      const localVarPath = `/admin/store/item`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addNewStoreItemBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAdminGetUserShippingDetails: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        'paymentApiAdminGetUserShippingDetails',
        'userId',
        userId,
      );
      const localVarPath = `/admin/payment/{user_id}/address`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CancelPaymentBody} cancelPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiCancelTransaction: async (
      cancelPaymentBody: CancelPaymentBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cancelPaymentBody' is not null or undefined
      assertParamExists(
        'paymentApiCancelTransaction',
        'cancelPaymentBody',
        cancelPaymentBody,
      );
      const localVarPath = `/payment/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelPaymentBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FinishPaymentBody} finishPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiCompleteTransaction: async (
      finishPaymentBody: FinishPaymentBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'finishPaymentBody' is not null or undefined
      assertParamExists(
        'paymentApiCompleteTransaction',
        'finishPaymentBody',
        finishPaymentBody,
      );
      const localVarPath = `/payment/finish`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        finishPaymentBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [cartItemId]
     * @param {number} [quantity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiDeleteCartItem: async (
      cartItemId?: string,
      quantity?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cart/remove`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (cartItemId !== undefined) {
        localVarQueryParameter['cart_item_id'] = cartItemId;
      }

      if (quantity !== undefined) {
        localVarQueryParameter['quantity'] = quantity;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiDeleteItem: async (
      itemId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('paymentApiDeleteItem', 'itemId', itemId);
      const localVarPath = `/admin/store/item/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [isActive]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllItemsAdmin: async (
      isActive?: string,
      itemId?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/store/item`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      if (itemId !== undefined) {
        localVarQueryParameter['item_id'] = itemId;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [availableTo]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllItemsPublic: async (
      availableTo?: string,
      itemId?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/store/item`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (availableTo !== undefined) {
        localVarQueryParameter['available_to'] = availableTo;
      }

      if (itemId !== undefined) {
        localVarQueryParameter['item_id'] = itemId;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllJobItems: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/store/job/item`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [paymentMode]
     * @param {string} [paymentStatus]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllOrders: async (
      endDate?: string,
      pageNo?: number,
      pageSize?: number,
      paymentMode?: string,
      paymentStatus?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (paymentMode !== undefined) {
        localVarQueryParameter['payment_mode'] = paymentMode;
      }

      if (paymentStatus !== undefined) {
        localVarQueryParameter['payment_status'] = paymentStatus;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetCart: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cart`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetOrderById: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists(
        'paymentApiGetOrderById',
        'transactionId',
        transactionId,
      );
      const localVarPath = `/admin/orders/{transaction_id}`.replace(
        `{${'transaction_id'}}`,
        encodeURIComponent(String(transactionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetUserAddressDetails: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetUserShippingDetails: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/shipping-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CartCheckoutBody} cartCheckoutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiMakePayment: async (
      cartCheckoutBody: CartCheckoutBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cartCheckoutBody' is not null or undefined
      assertParamExists(
        'paymentApiMakePayment',
        'cartCheckoutBody',
        cartCheckoutBody,
      );
      const localVarPath = `/cart/checkout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cartCheckoutBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<UpdateCartBody>} updateCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdateCartItems: async (
      updateCartBody: Array<UpdateCartBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCartBody' is not null or undefined
      assertParamExists(
        'paymentApiUpdateCartItems',
        'updateCartBody',
        updateCartBody,
      );
      const localVarPath = `/cart/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCartBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} itemId
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdatePurchasableItems: async (
      itemId: string,
      addNewStoreItemBody: AddNewStoreItemBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      assertParamExists('paymentApiUpdatePurchasableItems', 'itemId', itemId);
      // verify required parameter 'addNewStoreItemBody' is not null or undefined
      assertParamExists(
        'paymentApiUpdatePurchasableItems',
        'addNewStoreItemBody',
        addNewStoreItemBody,
      );
      const localVarPath = `/admin/store/item/{item_id}`.replace(
        `{${'item_id'}}`,
        encodeURIComponent(String(itemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addNewStoreItemBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} transactionId
     * @param {AdminUpdateTrasnsactionBody} adminUpdateTrasnsactionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdateTransactionDetails: async (
      transactionId: string,
      adminUpdateTrasnsactionBody: AdminUpdateTrasnsactionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists(
        'paymentApiUpdateTransactionDetails',
        'transactionId',
        transactionId,
      );
      // verify required parameter 'adminUpdateTrasnsactionBody' is not null or undefined
      assertParamExists(
        'paymentApiUpdateTransactionDetails',
        'adminUpdateTrasnsactionBody',
        adminUpdateTrasnsactionBody,
      );
      const localVarPath = `/admin/update-transaction/{transaction_id}`.replace(
        `{${'transaction_id'}}`,
        encodeURIComponent(String(transactionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateTrasnsactionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUploadItemImage: async (
      imageFile: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'imageFile' is not null or undefined
      assertParamExists('paymentApiUploadItemImage', 'imageFile', imageFile);
      const localVarPath = `/store/item/upload-item-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (imageFile !== undefined) {
        localVarFormParams.append('image_file', imageFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpsertAddressBody} upsertAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpsertUserAddressDetails: async (
      upsertAddressBody: UpsertAddressBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'upsertAddressBody' is not null or undefined
      assertParamExists(
        'paymentApiUpsertUserAddressDetails',
        'upsertAddressBody',
        upsertAddressBody,
      );
      const localVarPath = `/payment/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertAddressBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpsertShippingAddressBody} upsertShippingAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpsertUserShippingDetails: async (
      upsertShippingAddressBody: UpsertShippingAddressBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'upsertShippingAddressBody' is not null or undefined
      assertParamExists(
        'paymentApiUpsertUserShippingDetails',
        'upsertShippingAddressBody',
        upsertShippingAddressBody,
      );
      const localVarPath = `/payment/shipping-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertShippingAddressBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUserOrderHistory: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/order-history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentApiApi - functional programming interface
 * @export
 */
export const PaymentApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {AddItemToCartBody} addItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiAddItemToCart(
      addItemToCartBody: AddItemToCartBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddItemToCartResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiAddItemToCart(
        addItemToCartBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiAddNewItem(
      addNewStoreItemBody: AddNewStoreItemBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddNewStoreItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiAddNewItem(
        addNewStoreItemBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiAdminGetUserShippingDetails(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertAddressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiAdminGetUserShippingDetails(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CancelPaymentBody} cancelPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiCancelTransaction(
      cancelPaymentBody: CancelPaymentBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancelPaymentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiCancelTransaction(
        cancelPaymentBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {FinishPaymentBody} finishPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiCompleteTransaction(
      finishPaymentBody: FinishPaymentBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CancelPaymentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiCompleteTransaction(
        finishPaymentBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [cartItemId]
     * @param {number} [quantity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiDeleteCartItem(
      cartItemId?: string,
      quantity?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiDeleteCartItem(
        cartItemId,
        quantity,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiDeleteItem(
      itemId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiDeleteItem(
        itemId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [isActive]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetAllItemsAdmin(
      isActive?: string,
      itemId?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStoreItemsAdminResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetAllItemsAdmin(
        isActive,
        itemId,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [availableTo]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetAllItemsPublic(
      availableTo?: string,
      itemId?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStoreItemsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetAllItemsPublic(
        availableTo,
        itemId,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetAllJobItems(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetJobItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetAllJobItems(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [paymentMode]
     * @param {string} [paymentStatus]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetAllOrders(
      endDate?: string,
      pageNo?: number,
      pageSize?: number,
      paymentMode?: string,
      paymentStatus?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAdminOrdersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetAllOrders(
        endDate,
        pageNo,
        pageSize,
        paymentMode,
        paymentStatus,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetCart(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCartResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetCart(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetOrderById(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAdminOrdersByTrasnsactionIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetOrderById(
        transactionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetUserAddressDetails(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertAddressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetUserAddressDetails(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiGetUserShippingDetails(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetShippingAddressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiGetUserShippingDetails(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CartCheckoutBody} cartCheckoutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiMakePayment(
      cartCheckoutBody: CartCheckoutBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CartCheckoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiMakePayment(
        cartCheckoutBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<UpdateCartBody>} updateCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUpdateCartItems(
      updateCartBody: Array<UpdateCartBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateCartResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUpdateCartItems(
        updateCartBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} itemId
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUpdatePurchasableItems(
      itemId: string,
      addNewStoreItemBody: AddNewStoreItemBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateStoreItemResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUpdatePurchasableItems(
        itemId,
        addNewStoreItemBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} transactionId
     * @param {AdminUpdateTrasnsactionBody} adminUpdateTrasnsactionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUpdateTransactionDetails(
      transactionId: string,
      adminUpdateTrasnsactionBody: AdminUpdateTrasnsactionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminUpdateTrasnsactionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUpdateTransactionDetails(
        transactionId,
        adminUpdateTrasnsactionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUploadItemImage(
      imageFile: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadItemImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUploadItemImage(
        imageFile,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpsertAddressBody} upsertAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUpsertUserAddressDetails(
      upsertAddressBody: UpsertAddressBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertAddressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUpsertUserAddressDetails(
        upsertAddressBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpsertShippingAddressBody} upsertShippingAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUpsertUserShippingDetails(
      upsertShippingAddressBody: UpsertShippingAddressBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpsertShippingAddressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUpsertUserShippingDetails(
        upsertShippingAddressBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentApiUserOrderHistory(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetCartResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiUserOrderHistory(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentApiApi - factory interface
 * @export
 */
export const PaymentApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentApiApiFp(configuration);
  return {
    /**
     *
     * @param {AddItemToCartBody} addItemToCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAddItemToCart(
      addItemToCartBody: AddItemToCartBody,
      options?: any,
    ): AxiosPromise<AddItemToCartResponse> {
      return localVarFp
        .paymentApiAddItemToCart(addItemToCartBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAddNewItem(
      addNewStoreItemBody: AddNewStoreItemBody,
      options?: any,
    ): AxiosPromise<AddNewStoreItemResponse> {
      return localVarFp
        .paymentApiAddNewItem(addNewStoreItemBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiAdminGetUserShippingDetails(
      userId: string,
      options?: any,
    ): AxiosPromise<UpsertAddressResponse> {
      return localVarFp
        .paymentApiAdminGetUserShippingDetails(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CancelPaymentBody} cancelPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiCancelTransaction(
      cancelPaymentBody: CancelPaymentBody,
      options?: any,
    ): AxiosPromise<CancelPaymentResponse> {
      return localVarFp
        .paymentApiCancelTransaction(cancelPaymentBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FinishPaymentBody} finishPaymentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiCompleteTransaction(
      finishPaymentBody: FinishPaymentBody,
      options?: any,
    ): AxiosPromise<CancelPaymentResponse> {
      return localVarFp
        .paymentApiCompleteTransaction(finishPaymentBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [cartItemId]
     * @param {number} [quantity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiDeleteCartItem(
      cartItemId?: string,
      quantity?: number,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .paymentApiDeleteCartItem(cartItemId, quantity, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiDeleteItem(
      itemId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .paymentApiDeleteItem(itemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [isActive]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllItemsAdmin(
      isActive?: string,
      itemId?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetStoreItemsAdminResponse> {
      return localVarFp
        .paymentApiGetAllItemsAdmin(isActive, itemId, userSearchQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [availableTo]
     * @param {string} [itemId]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllItemsPublic(
      availableTo?: string,
      itemId?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetStoreItemsResponse> {
      return localVarFp
        .paymentApiGetAllItemsPublic(
          availableTo,
          itemId,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllJobItems(options?: any): AxiosPromise<GetJobItemResponse> {
      return localVarFp
        .paymentApiGetAllJobItems(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [endDate]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {string} [paymentMode]
     * @param {string} [paymentStatus]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetAllOrders(
      endDate?: string,
      pageNo?: number,
      pageSize?: number,
      paymentMode?: string,
      paymentStatus?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<GetAdminOrdersResponse> {
      return localVarFp
        .paymentApiGetAllOrders(
          endDate,
          pageNo,
          pageSize,
          paymentMode,
          paymentStatus,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetCart(options?: any): AxiosPromise<GetCartResponse> {
      return localVarFp
        .paymentApiGetCart(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetOrderById(
      transactionId: string,
      options?: any,
    ): AxiosPromise<GetAdminOrdersByTrasnsactionIdResponse> {
      return localVarFp
        .paymentApiGetOrderById(transactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetUserAddressDetails(
      options?: any,
    ): AxiosPromise<UpsertAddressResponse> {
      return localVarFp
        .paymentApiGetUserAddressDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiGetUserShippingDetails(
      options?: any,
    ): AxiosPromise<GetShippingAddressResponse> {
      return localVarFp
        .paymentApiGetUserShippingDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CartCheckoutBody} cartCheckoutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiMakePayment(
      cartCheckoutBody: CartCheckoutBody,
      options?: any,
    ): AxiosPromise<CartCheckoutResponse> {
      return localVarFp
        .paymentApiMakePayment(cartCheckoutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<UpdateCartBody>} updateCartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdateCartItems(
      updateCartBody: Array<UpdateCartBody>,
      options?: any,
    ): AxiosPromise<UpdateCartResponse> {
      return localVarFp
        .paymentApiUpdateCartItems(updateCartBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} itemId
     * @param {AddNewStoreItemBody} addNewStoreItemBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdatePurchasableItems(
      itemId: string,
      addNewStoreItemBody: AddNewStoreItemBody,
      options?: any,
    ): AxiosPromise<UpdateStoreItemResponse> {
      return localVarFp
        .paymentApiUpdatePurchasableItems(itemId, addNewStoreItemBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} transactionId
     * @param {AdminUpdateTrasnsactionBody} adminUpdateTrasnsactionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpdateTransactionDetails(
      transactionId: string,
      adminUpdateTrasnsactionBody: AdminUpdateTrasnsactionBody,
      options?: any,
    ): AxiosPromise<AdminUpdateTrasnsactionResponse> {
      return localVarFp
        .paymentApiUpdateTransactionDetails(
          transactionId,
          adminUpdateTrasnsactionBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} imageFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUploadItemImage(
      imageFile: any,
      options?: any,
    ): AxiosPromise<UploadItemImageResponse> {
      return localVarFp
        .paymentApiUploadItemImage(imageFile, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpsertAddressBody} upsertAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpsertUserAddressDetails(
      upsertAddressBody: UpsertAddressBody,
      options?: any,
    ): AxiosPromise<UpsertAddressResponse> {
      return localVarFp
        .paymentApiUpsertUserAddressDetails(upsertAddressBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpsertShippingAddressBody} upsertShippingAddressBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUpsertUserShippingDetails(
      upsertShippingAddressBody: UpsertShippingAddressBody,
      options?: any,
    ): AxiosPromise<UpsertShippingAddressResponse> {
      return localVarFp
        .paymentApiUpsertUserShippingDetails(upsertShippingAddressBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentApiUserOrderHistory(
      options?: any,
    ): AxiosPromise<Array<GetCartResponse>> {
      return localVarFp
        .paymentApiUserOrderHistory(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentApiApi - object-oriented interface
 * @export
 * @class PaymentApiApi
 * @extends {BaseAPI}
 */
export class PaymentApiApi extends BaseAPI {
  /**
   *
   * @param {AddItemToCartBody} addItemToCartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiAddItemToCart(
    addItemToCartBody: AddItemToCartBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiAddItemToCart(addItemToCartBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AddNewStoreItemBody} addNewStoreItemBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiAddNewItem(
    addNewStoreItemBody: AddNewStoreItemBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiAddNewItem(addNewStoreItemBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiAdminGetUserShippingDetails(
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiAdminGetUserShippingDetails(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CancelPaymentBody} cancelPaymentBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiCancelTransaction(
    cancelPaymentBody: CancelPaymentBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiCancelTransaction(cancelPaymentBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FinishPaymentBody} finishPaymentBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiCompleteTransaction(
    finishPaymentBody: FinishPaymentBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiCompleteTransaction(finishPaymentBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [cartItemId]
   * @param {number} [quantity]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiDeleteCartItem(
    cartItemId?: string,
    quantity?: number,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiDeleteCartItem(cartItemId, quantity, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} itemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiDeleteItem(itemId: string, options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiDeleteItem(itemId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [isActive]
   * @param {string} [itemId]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetAllItemsAdmin(
    isActive?: string,
    itemId?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetAllItemsAdmin(isActive, itemId, userSearchQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [availableTo]
   * @param {string} [itemId]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetAllItemsPublic(
    availableTo?: string,
    itemId?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetAllItemsPublic(
        availableTo,
        itemId,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetAllJobItems(options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetAllJobItems(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [endDate]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {string} [paymentMode]
   * @param {string} [paymentStatus]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetAllOrders(
    endDate?: string,
    pageNo?: number,
    pageSize?: number,
    paymentMode?: string,
    paymentStatus?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetAllOrders(
        endDate,
        pageNo,
        pageSize,
        paymentMode,
        paymentStatus,
        startDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetCart(options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetCart(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} transactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetOrderById(
    transactionId: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetOrderById(transactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetUserAddressDetails(options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetUserAddressDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiGetUserShippingDetails(options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiGetUserShippingDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CartCheckoutBody} cartCheckoutBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiMakePayment(
    cartCheckoutBody: CartCheckoutBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiMakePayment(cartCheckoutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<UpdateCartBody>} updateCartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUpdateCartItems(
    updateCartBody: Array<UpdateCartBody>,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUpdateCartItems(updateCartBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} itemId
   * @param {AddNewStoreItemBody} addNewStoreItemBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUpdatePurchasableItems(
    itemId: string,
    addNewStoreItemBody: AddNewStoreItemBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUpdatePurchasableItems(itemId, addNewStoreItemBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} transactionId
   * @param {AdminUpdateTrasnsactionBody} adminUpdateTrasnsactionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUpdateTransactionDetails(
    transactionId: string,
    adminUpdateTrasnsactionBody: AdminUpdateTrasnsactionBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUpdateTransactionDetails(
        transactionId,
        adminUpdateTrasnsactionBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} imageFile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUploadItemImage(
    imageFile: any,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUploadItemImage(imageFile, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpsertAddressBody} upsertAddressBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUpsertUserAddressDetails(
    upsertAddressBody: UpsertAddressBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUpsertUserAddressDetails(upsertAddressBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpsertShippingAddressBody} upsertShippingAddressBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUpsertUserShippingDetails(
    upsertShippingAddressBody: UpsertShippingAddressBody,
    options?: AxiosRequestConfig,
  ) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUpsertUserShippingDetails(upsertShippingAddressBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiApi
   */
  public paymentApiUserOrderHistory(options?: AxiosRequestConfig) {
    return PaymentApiApiFp(this.configuration)
      .paymentApiUserOrderHistory(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QuestionApiApi - axios parameter creator
 * @export
 */
export const QuestionApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiAssignBadgesByQuestionCountApi: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/assign-question-created-badges`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiAttemptQuestion: async (
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiAttemptQuestion', 'questionId', questionId);
      // verify required parameter 'attemptQuestionBody' is not null or undefined
      assertParamExists(
        'questionApiAttemptQuestion',
        'attemptQuestionBody',
        attemptQuestionBody,
      );
      const localVarPath = `/question/{question_id}/attempt`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        attemptQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {BookmarkQuestionBody} bookmarkQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiBookmarkQuestion: async (
      questionId: string,
      bookmarkQuestionBody: BookmarkQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        'questionApiBookmarkQuestion',
        'questionId',
        questionId,
      );
      // verify required parameter 'bookmarkQuestionBody' is not null or undefined
      assertParamExists(
        'questionApiBookmarkQuestion',
        'bookmarkQuestionBody',
        bookmarkQuestionBody,
      );
      const localVarPath = `/question/{question_id}/bookmark`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookmarkQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateQuestionBody} createQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiCreateQuestion: async (
      createQuestionBody: CreateQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createQuestionBody' is not null or undefined
      assertParamExists(
        'questionApiCreateQuestion',
        'createQuestionBody',
        createQuestionBody,
      );
      const localVarPath = `/question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiDeleteQuestion: async (
      questionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiDeleteQuestion', 'questionId', questionId);
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGenerateSitemap: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/generate-sitemap`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} attemptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetAnsweredAttemptById: async (
      attemptId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'attemptId' is not null or undefined
      assertParamExists(
        'questionApiGetAnsweredAttemptById',
        'attemptId',
        attemptId,
      );
      const localVarPath = `/question/answered/{attempt_id}`.replace(
        `{${'attempt_id'}}`,
        encodeURIComponent(String(attemptId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {string} [examId]
     * @param {string} [filterType]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchString]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetAnsweredQuestionList: async (
      competitionId?: string,
      domainId?: string,
      examId?: string,
      filterType?: string,
      page?: string,
      pageSize?: string,
      searchString?: string,
      tagId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/answered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (competitionId !== undefined) {
        localVarQueryParameter['competition_id'] = competitionId;
      }

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (examId !== undefined) {
        localVarQueryParameter['exam_id'] = examId;
      }

      if (filterType !== undefined) {
        localVarQueryParameter['filter_type'] = filterType;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchString !== undefined) {
        localVarQueryParameter['search_string'] = searchString;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetPublicQuestionByGroup: async (
      groupSlug: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupSlug' is not null or undefined
      assertParamExists(
        'questionApiGetPublicQuestionByGroup',
        'groupSlug',
        groupSlug,
      );
      const localVarPath = `/question/group/{group_slug}`.replace(
        `{${'group_slug'}}`,
        encodeURIComponent(String(groupSlug)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionAttemptData: async (
      questionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        'questionApiGetQuestionAttemptData',
        'questionId',
        questionId,
      );
      const localVarPath = `/question/{question_id}/attempt_info`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {string} [subQuestions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionById: async (
      questionId: string,
      subQuestions?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiGetQuestionById', 'questionId', questionId);
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (subQuestions !== undefined) {
        localVarQueryParameter['sub_questions'] = subQuestions;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionHint: async (
      questionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiGetQuestionHint', 'questionId', questionId);
      const localVarPath = `/question/{question_id}/hint`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionStatistics: async (
      questionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        'questionApiGetQuestionStatistics',
        'questionId',
        questionId,
      );
      const localVarPath = `/question/{question_id}/statistics`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [duplicate]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionUnderreviewList: async (
      domainId?: string,
      duplicate?: string,
      page?: string,
      pageSize?: string,
      questionStatus?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/underreview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (duplicate !== undefined) {
        localVarQueryParameter['duplicate'] = duplicate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (questionStatus !== undefined) {
        localVarQueryParameter['question_status'] = questionStatus;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetSolution: async (
      questionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiGetSolution', 'questionId', questionId);
      const localVarPath = `/question/{question_id}/solution`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [slug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetUnansweredQuestionByQuestionId: async (
      id?: string,
      slug?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/unanswered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (slug !== undefined) {
        localVarQueryParameter['slug'] = slug;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} entityId
     * @param {string} [competitionId]
     * @param {string} [isCompetition]
     * @param {string} [questionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetUnansweredQuestionByTagCompetition: async (
      entityId: string,
      competitionId?: string,
      isCompetition?: string,
      questionType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityId' is not null or undefined
      assertParamExists(
        'questionApiGetUnansweredQuestionByTagCompetition',
        'entityId',
        entityId,
      );
      const localVarPath = `/question/unanswered/{entity_id}`.replace(
        `{${'entity_id'}}`,
        encodeURIComponent(String(entityId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (competitionId !== undefined) {
        localVarQueryParameter['competition_id'] = competitionId;
      }

      if (isCompetition !== undefined) {
        localVarQueryParameter['is_competition'] = isCompetition;
      }

      if (questionType !== undefined) {
        localVarQueryParameter['question_type'] = questionType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionCreateMilestoneByTag: async (
      tagId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists(
        'questionApiQuestionCreateMilestoneByTag',
        'tagId',
        tagId,
      );
      const localVarPath = `/question/tag/{tag_id}/milestone`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {LikeDislikeQuestionBody} likeDislikeQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionLike: async (
      questionId: string,
      likeDislikeQuestionBody: LikeDislikeQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiQuestionLike', 'questionId', questionId);
      // verify required parameter 'likeDislikeQuestionBody' is not null or undefined
      assertParamExists(
        'questionApiQuestionLike',
        'likeDislikeQuestionBody',
        likeDislikeQuestionBody,
      );
      const localVarPath = `/question/{question_id}/like_dislike`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        likeDislikeQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {QuestionPublicAttemptBody} questionPublicAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionPublicAttempt: async (
      questionPublicAttemptBody: QuestionPublicAttemptBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionPublicAttemptBody' is not null or undefined
      assertParamExists(
        'questionApiQuestionPublicAttempt',
        'questionPublicAttemptBody',
        questionPublicAttemptBody,
      );
      const localVarPath = `/question/public-attempt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionPublicAttemptBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiRepairOptions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/repair_options`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody1} reportQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiReportQuestion: async (
      questionId: string,
      reportQuestionBody1: ReportQuestionBody1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiReportQuestion', 'questionId', questionId);
      // verify required parameter 'reportQuestionBody1' is not null or undefined
      assertParamExists(
        'questionApiReportQuestion',
        'reportQuestionBody1',
        reportQuestionBody1,
      );
      const localVarPath = `/question/{question_id}/report`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reportQuestionBody1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReviewReportedQuestionBody1} reviewReportedQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiReviewReportedQuestion: async (
      reviewReportedQuestionBody1: ReviewReportedQuestionBody1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reviewReportedQuestionBody1' is not null or undefined
      assertParamExists(
        'questionApiReviewReportedQuestion',
        'reviewReportedQuestionBody1',
        reviewReportedQuestionBody1,
      );
      const localVarPath = `/question/review_reported_question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reviewReportedQuestionBody1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {SolutionRatingBody} solutionRatingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiSolutionRating: async (
      questionId: string,
      solutionRatingBody: SolutionRatingBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiSolutionRating', 'questionId', questionId);
      // verify required parameter 'solutionRatingBody' is not null or undefined
      assertParamExists(
        'questionApiSolutionRating',
        'solutionRatingBody',
        solutionRatingBody,
      );
      const localVarPath = `/question/{question_id}/solution-rating`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        solutionRatingBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiTagArrayToId: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/tag_array_to_id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateAllSlug: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/update-all-slugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateAllSlugApi: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/update-all-question-slugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateBookmarkCount: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/question/update_bookmark_count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateOptionBody} updateOptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateOption: async (
      updateOptionBody: UpdateOptionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateOptionBody' is not null or undefined
      assertParamExists(
        'questionApiUpdateOption',
        'updateOptionBody',
        updateOptionBody,
      );
      const localVarPath = `/question/update_option`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOptionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateQuestionBody} updateQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateQuestion: async (
      questionId: string,
      updateQuestionBody: UpdateQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('questionApiUpdateQuestion', 'questionId', questionId);
      // verify required parameter 'updateQuestionBody' is not null or undefined
      assertParamExists(
        'questionApiUpdateQuestion',
        'updateQuestionBody',
        updateQuestionBody,
      );
      const localVarPath = `/question/{question_id}`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateUnderReviewBody} updateUnderReviewBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateUnderReviewDetails: async (
      questionId: string,
      updateUnderReviewBody: UpdateUnderReviewBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        'questionApiUpdateUnderReviewDetails',
        'questionId',
        questionId,
      );
      // verify required parameter 'updateUnderReviewBody' is not null or undefined
      assertParamExists(
        'questionApiUpdateUnderReviewDetails',
        'updateUnderReviewBody',
        updateUnderReviewBody,
      );
      const localVarPath = `/question/{question_id}/review_status`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUnderReviewBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionApiApi - functional programming interface
 * @export
 */
export const QuestionApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = QuestionApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiAssignBadgesByQuestionCountApi(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiAssignBadgesByQuestionCountApi(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiAttemptQuestion(
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AttemptQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiAttemptQuestion(
        questionId,
        attemptQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {BookmarkQuestionBody} bookmarkQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiBookmarkQuestion(
      questionId: string,
      bookmarkQuestionBody: BookmarkQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiBookmarkQuestion(
        questionId,
        bookmarkQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateQuestionBody} createQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiCreateQuestion(
      createQuestionBody: CreateQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiCreateQuestion(
        createQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiDeleteQuestion(
      questionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiDeleteQuestion(
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGenerateSitemap(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGenerateSitemap(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} attemptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetAnsweredAttemptById(
      attemptId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAttemptByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetAnsweredAttemptById(
        attemptId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {string} [examId]
     * @param {string} [filterType]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchString]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetAnsweredQuestionList(
      competitionId?: string,
      domainId?: string,
      examId?: string,
      filterType?: string,
      page?: string,
      pageSize?: string,
      searchString?: string,
      tagId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnsweredQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetAnsweredQuestionList(
        competitionId,
        domainId,
        examId,
        filterType,
        page,
        pageSize,
        searchString,
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} groupSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetPublicQuestionByGroup(
      groupSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPublicGroupResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetPublicQuestionByGroup(
        groupSlug,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetQuestionAttemptData(
      questionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AttemptInfoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetQuestionAttemptData(
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {string} [subQuestions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetQuestionById(
      questionId: string,
      subQuestions?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetQuestionById(
        questionId,
        subQuestions,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetQuestionHint(
      questionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetQuestionHintResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetQuestionHint(
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetQuestionStatistics(
      questionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetQuestionStatisticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetQuestionStatistics(
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [duplicate]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetQuestionUnderreviewList(
      domainId?: string,
      duplicate?: string,
      page?: string,
      pageSize?: string,
      questionStatus?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUnderReviewResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetQuestionUnderreviewList(
        domainId,
        duplicate,
        page,
        pageSize,
        questionStatus,
        sortBy,
        sortOrder,
        tagId,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetSolution(
      questionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSolutionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetSolution(
        questionId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [slug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetUnansweredQuestionByQuestionId(
      id?: string,
      slug?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UnansweredQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetUnansweredQuestionByQuestionId(
        id,
        slug,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} entityId
     * @param {string} [competitionId]
     * @param {string} [isCompetition]
     * @param {string} [questionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiGetUnansweredQuestionByTagCompetition(
      entityId: string,
      competitionId?: string,
      isCompetition?: string,
      questionType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UnansweredQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiGetUnansweredQuestionByTagCompetition(
        entityId,
        competitionId,
        isCompetition,
        questionType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiQuestionCreateMilestoneByTag(
      tagId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<QuestionCreationMilestoneResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiQuestionCreateMilestoneByTag(
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {LikeDislikeQuestionBody} likeDislikeQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiQuestionLike(
      questionId: string,
      likeDislikeQuestionBody: LikeDislikeQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LikeDislikeQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiQuestionLike(
        questionId,
        likeDislikeQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {QuestionPublicAttemptBody} questionPublicAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiQuestionPublicAttempt(
      questionPublicAttemptBody: QuestionPublicAttemptBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<QuestionPublicAttemptResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiQuestionPublicAttempt(
        questionPublicAttemptBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiRepairOptions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RepairOptionSerialNoResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiRepairOptions(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody1} reportQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiReportQuestion(
      questionId: string,
      reportQuestionBody1: ReportQuestionBody1,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiReportQuestion(
        questionId,
        reportQuestionBody1,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ReviewReportedQuestionBody1} reviewReportedQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiReviewReportedQuestion(
      reviewReportedQuestionBody1: ReviewReportedQuestionBody1,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReviewReportedQuestionResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiReviewReportedQuestion(
        reviewReportedQuestionBody1,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {SolutionRatingBody} solutionRatingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiSolutionRating(
      questionId: string,
      solutionRatingBody: SolutionRatingBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SolutionRatingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiSolutionRating(
        questionId,
        solutionRatingBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiTagArrayToId(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiTagArrayToId(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateAllSlug(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateAllSlug(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateAllSlugApi(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateAllSlugApi(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateBookmarkCount(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateBookmarkCount(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateOptionBody} updateOptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateOption(
      updateOptionBody: UpdateOptionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateOptionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateOption(
        updateOptionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateQuestionBody} updateQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateQuestion(
      questionId: string,
      updateQuestionBody: UpdateQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateQuestion(
        questionId,
        updateQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateUnderReviewBody} updateUnderReviewBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async questionApiUpdateUnderReviewDetails(
      questionId: string,
      updateUnderReviewBody: UpdateUnderReviewBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.questionApiUpdateUnderReviewDetails(
        questionId,
        updateUnderReviewBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * QuestionApiApi - factory interface
 * @export
 */
export const QuestionApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QuestionApiApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiAssignBadgesByQuestionCountApi(
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiAssignBadgesByQuestionCountApi(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {AttemptQuestionBody} attemptQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiAttemptQuestion(
      questionId: string,
      attemptQuestionBody: AttemptQuestionBody,
      options?: any,
    ): AxiosPromise<AttemptQuestionResponse> {
      return localVarFp
        .questionApiAttemptQuestion(questionId, attemptQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {BookmarkQuestionBody} bookmarkQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiBookmarkQuestion(
      questionId: string,
      bookmarkQuestionBody: BookmarkQuestionBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiBookmarkQuestion(questionId, bookmarkQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateQuestionBody} createQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiCreateQuestion(
      createQuestionBody: CreateQuestionBody,
      options?: any,
    ): AxiosPromise<CreateQuestionResponse> {
      return localVarFp
        .questionApiCreateQuestion(createQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiDeleteQuestion(
      questionId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiDeleteQuestion(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGenerateSitemap(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiGenerateSitemap(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} attemptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetAnsweredAttemptById(
      attemptId: string,
      options?: any,
    ): AxiosPromise<GetAttemptByIdResponse> {
      return localVarFp
        .questionApiGetAnsweredAttemptById(attemptId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [competitionId]
     * @param {string} [domainId]
     * @param {string} [examId]
     * @param {string} [filterType]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [searchString]
     * @param {string} [tagId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetAnsweredQuestionList(
      competitionId?: string,
      domainId?: string,
      examId?: string,
      filterType?: string,
      page?: string,
      pageSize?: string,
      searchString?: string,
      tagId?: string,
      options?: any,
    ): AxiosPromise<AnsweredQuestionResponse> {
      return localVarFp
        .questionApiGetAnsweredQuestionList(
          competitionId,
          domainId,
          examId,
          filterType,
          page,
          pageSize,
          searchString,
          tagId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetPublicQuestionByGroup(
      groupSlug: string,
      options?: any,
    ): AxiosPromise<GetPublicGroupResponse> {
      return localVarFp
        .questionApiGetPublicQuestionByGroup(groupSlug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionAttemptData(
      questionId: string,
      options?: any,
    ): AxiosPromise<AttemptInfoResponse> {
      return localVarFp
        .questionApiGetQuestionAttemptData(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {string} [subQuestions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionById(
      questionId: string,
      subQuestions?: string,
      options?: any,
    ): AxiosPromise<CreateQuestionResponse> {
      return localVarFp
        .questionApiGetQuestionById(questionId, subQuestions, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionHint(
      questionId: string,
      options?: any,
    ): AxiosPromise<GetQuestionHintResponse> {
      return localVarFp
        .questionApiGetQuestionHint(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionStatistics(
      questionId: string,
      options?: any,
    ): AxiosPromise<GetQuestionStatisticsResponse> {
      return localVarFp
        .questionApiGetQuestionStatistics(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [duplicate]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetQuestionUnderreviewList(
      domainId?: string,
      duplicate?: string,
      page?: string,
      pageSize?: string,
      questionStatus?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      options?: any,
    ): AxiosPromise<GetUnderReviewResponse1> {
      return localVarFp
        .questionApiGetQuestionUnderreviewList(
          domainId,
          duplicate,
          page,
          pageSize,
          questionStatus,
          sortBy,
          sortOrder,
          tagId,
          userId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetSolution(
      questionId: string,
      options?: any,
    ): AxiosPromise<GetSolutionResponse> {
      return localVarFp
        .questionApiGetSolution(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [id]
     * @param {string} [slug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetUnansweredQuestionByQuestionId(
      id?: string,
      slug?: string,
      options?: any,
    ): AxiosPromise<UnansweredQuestionResponse> {
      return localVarFp
        .questionApiGetUnansweredQuestionByQuestionId(id, slug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} entityId
     * @param {string} [competitionId]
     * @param {string} [isCompetition]
     * @param {string} [questionType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiGetUnansweredQuestionByTagCompetition(
      entityId: string,
      competitionId?: string,
      isCompetition?: string,
      questionType?: string,
      options?: any,
    ): AxiosPromise<UnansweredQuestionResponse> {
      return localVarFp
        .questionApiGetUnansweredQuestionByTagCompetition(
          entityId,
          competitionId,
          isCompetition,
          questionType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionCreateMilestoneByTag(
      tagId: string,
      options?: any,
    ): AxiosPromise<QuestionCreationMilestoneResponse> {
      return localVarFp
        .questionApiQuestionCreateMilestoneByTag(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {LikeDislikeQuestionBody} likeDislikeQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionLike(
      questionId: string,
      likeDislikeQuestionBody: LikeDislikeQuestionBody,
      options?: any,
    ): AxiosPromise<LikeDislikeQuestionResponse> {
      return localVarFp
        .questionApiQuestionLike(questionId, likeDislikeQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {QuestionPublicAttemptBody} questionPublicAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiQuestionPublicAttempt(
      questionPublicAttemptBody: QuestionPublicAttemptBody,
      options?: any,
    ): AxiosPromise<QuestionPublicAttemptResponse> {
      return localVarFp
        .questionApiQuestionPublicAttempt(questionPublicAttemptBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiRepairOptions(
      options?: any,
    ): AxiosPromise<RepairOptionSerialNoResponse> {
      return localVarFp
        .questionApiRepairOptions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody1} reportQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiReportQuestion(
      questionId: string,
      reportQuestionBody1: ReportQuestionBody1,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiReportQuestion(questionId, reportQuestionBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ReviewReportedQuestionBody1} reviewReportedQuestionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiReviewReportedQuestion(
      reviewReportedQuestionBody1: ReviewReportedQuestionBody1,
      options?: any,
    ): AxiosPromise<ReviewReportedQuestionResponse1> {
      return localVarFp
        .questionApiReviewReportedQuestion(reviewReportedQuestionBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {SolutionRatingBody} solutionRatingBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiSolutionRating(
      questionId: string,
      solutionRatingBody: SolutionRatingBody,
      options?: any,
    ): AxiosPromise<SolutionRatingResponse> {
      return localVarFp
        .questionApiSolutionRating(questionId, solutionRatingBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiTagArrayToId(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiTagArrayToId(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateAllSlug(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiUpdateAllSlug(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateAllSlugApi(options?: any): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiUpdateAllSlugApi(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateBookmarkCount(
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiUpdateBookmarkCount(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateOptionBody} updateOptionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateOption(
      updateOptionBody: UpdateOptionBody,
      options?: any,
    ): AxiosPromise<UpdateOptionResponse> {
      return localVarFp
        .questionApiUpdateOption(updateOptionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateQuestionBody} updateQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateQuestion(
      questionId: string,
      updateQuestionBody: UpdateQuestionBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiUpdateQuestion(questionId, updateQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateUnderReviewBody} updateUnderReviewBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    questionApiUpdateUnderReviewDetails(
      questionId: string,
      updateUnderReviewBody: UpdateUnderReviewBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .questionApiUpdateUnderReviewDetails(
          questionId,
          updateUnderReviewBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QuestionApiApi - object-oriented interface
 * @export
 * @class QuestionApiApi
 * @extends {BaseAPI}
 */
export class QuestionApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiAssignBadgesByQuestionCountApi(
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiAssignBadgesByQuestionCountApi(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {AttemptQuestionBody} attemptQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiAttemptQuestion(
    questionId: string,
    attemptQuestionBody: AttemptQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiAttemptQuestion(questionId, attemptQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {BookmarkQuestionBody} bookmarkQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiBookmarkQuestion(
    questionId: string,
    bookmarkQuestionBody: BookmarkQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiBookmarkQuestion(questionId, bookmarkQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateQuestionBody} createQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiCreateQuestion(
    createQuestionBody: CreateQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiCreateQuestion(createQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiDeleteQuestion(
    questionId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiDeleteQuestion(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGenerateSitemap(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGenerateSitemap(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} attemptId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetAnsweredAttemptById(
    attemptId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetAnsweredAttemptById(attemptId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [competitionId]
   * @param {string} [domainId]
   * @param {string} [examId]
   * @param {string} [filterType]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [searchString]
   * @param {string} [tagId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetAnsweredQuestionList(
    competitionId?: string,
    domainId?: string,
    examId?: string,
    filterType?: string,
    page?: string,
    pageSize?: string,
    searchString?: string,
    tagId?: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetAnsweredQuestionList(
        competitionId,
        domainId,
        examId,
        filterType,
        page,
        pageSize,
        searchString,
        tagId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} groupSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetPublicQuestionByGroup(
    groupSlug: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetPublicQuestionByGroup(groupSlug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetQuestionAttemptData(
    questionId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetQuestionAttemptData(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {string} [subQuestions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetQuestionById(
    questionId: string,
    subQuestions?: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetQuestionById(questionId, subQuestions, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetQuestionHint(
    questionId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetQuestionHint(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetQuestionStatistics(
    questionId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetQuestionStatistics(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [duplicate]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [questionStatus]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [tagId]
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetQuestionUnderreviewList(
    domainId?: string,
    duplicate?: string,
    page?: string,
    pageSize?: string,
    questionStatus?: string,
    sortBy?: string,
    sortOrder?: string,
    tagId?: string,
    userId?: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetQuestionUnderreviewList(
        domainId,
        duplicate,
        page,
        pageSize,
        questionStatus,
        sortBy,
        sortOrder,
        tagId,
        userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetSolution(
    questionId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetSolution(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [id]
   * @param {string} [slug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetUnansweredQuestionByQuestionId(
    id?: string,
    slug?: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetUnansweredQuestionByQuestionId(id, slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} entityId
   * @param {string} [competitionId]
   * @param {string} [isCompetition]
   * @param {string} [questionType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiGetUnansweredQuestionByTagCompetition(
    entityId: string,
    competitionId?: string,
    isCompetition?: string,
    questionType?: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiGetUnansweredQuestionByTagCompetition(
        entityId,
        competitionId,
        isCompetition,
        questionType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiQuestionCreateMilestoneByTag(
    tagId: string,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiQuestionCreateMilestoneByTag(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {LikeDislikeQuestionBody} likeDislikeQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiQuestionLike(
    questionId: string,
    likeDislikeQuestionBody: LikeDislikeQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiQuestionLike(questionId, likeDislikeQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {QuestionPublicAttemptBody} questionPublicAttemptBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiQuestionPublicAttempt(
    questionPublicAttemptBody: QuestionPublicAttemptBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiQuestionPublicAttempt(questionPublicAttemptBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiRepairOptions(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiRepairOptions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {ReportQuestionBody1} reportQuestionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiReportQuestion(
    questionId: string,
    reportQuestionBody1: ReportQuestionBody1,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiReportQuestion(questionId, reportQuestionBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReviewReportedQuestionBody1} reviewReportedQuestionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiReviewReportedQuestion(
    reviewReportedQuestionBody1: ReviewReportedQuestionBody1,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiReviewReportedQuestion(reviewReportedQuestionBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {SolutionRatingBody} solutionRatingBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiSolutionRating(
    questionId: string,
    solutionRatingBody: SolutionRatingBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiSolutionRating(questionId, solutionRatingBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiTagArrayToId(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiTagArrayToId(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateAllSlug(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateAllSlug(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateAllSlugApi(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateAllSlugApi(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateBookmarkCount(options?: AxiosRequestConfig) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateBookmarkCount(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateOptionBody} updateOptionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateOption(
    updateOptionBody: UpdateOptionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateOption(updateOptionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {UpdateQuestionBody} updateQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateQuestion(
    questionId: string,
    updateQuestionBody: UpdateQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateQuestion(questionId, updateQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {UpdateUnderReviewBody} updateUnderReviewBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionApiApi
   */
  public questionApiUpdateUnderReviewDetails(
    questionId: string,
    updateUnderReviewBody: UpdateUnderReviewBody,
    options?: AxiosRequestConfig,
  ) {
    return QuestionApiApiFp(this.configuration)
      .questionApiUpdateUnderReviewDetails(
        questionId,
        updateUnderReviewBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReviewApiApi - axios parameter creator
 * @export
 */
export const ReviewApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiAcceptReportingReason: async (
      reportId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reportId' is not null or undefined
      assertParamExists('reviewApiAcceptReportingReason', 'reportId', reportId);
      const localVarPath = `/review/{report_id}/accept`.replace(
        `{${'report_id'}}`,
        encodeURIComponent(String(reportId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReviewTestApiBody} reviewTestApiBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiCallTestApi: async (
      reviewTestApiBody: ReviewTestApiBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reviewTestApiBody' is not null or undefined
      assertParamExists(
        'reviewApiCallTestApi',
        'reviewTestApiBody',
        reviewTestApiBody,
      );
      const localVarPath = `/review/testapi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reviewTestApiBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateReviewTemplateBody} createReviewTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiCreateReviewTemplateApi: async (
      createReviewTemplateBody: CreateReviewTemplateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createReviewTemplateBody' is not null or undefined
      assertParamExists(
        'reviewApiCreateReviewTemplateApi',
        'createReviewTemplateBody',
        createReviewTemplateBody,
      );
      const localVarPath = `/review/template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReviewTemplateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} reviewTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiDeleteReviewTemplateApi: async (
      reviewTemplateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reviewTemplateId' is not null or undefined
      assertParamExists(
        'reviewApiDeleteReviewTemplateApi',
        'reviewTemplateId',
        reviewTemplateId,
      );
      const localVarPath = `/review/template/{review_template_id}`.replace(
        `{${'review_template_id'}}`,
        encodeURIComponent(String(reviewTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetBlockStatistics: async (
      domainId?: string,
      endDate?: string,
      startDate?: string,
      tagId?: string,
      userId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/review/block-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {number} [duplicate]
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [questionType]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {string} [withExam]
     * @param {string} [withHint]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetQuestionUnderreviewList: async (
      domainId?: string,
      duplicate?: number,
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      questionStatus?: string,
      questionType?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      withExam?: string,
      withHint?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/review/underreview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (duplicate !== undefined) {
        localVarQueryParameter['duplicate'] = duplicate;
      }

      if (maxDate !== undefined) {
        localVarQueryParameter['max_date'] = maxDate;
      }

      if (minDate !== undefined) {
        localVarQueryParameter['min_date'] = minDate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (questionStatus !== undefined) {
        localVarQueryParameter['question_status'] = questionStatus;
      }

      if (questionType !== undefined) {
        localVarQueryParameter['question_type'] = questionType;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      if (withExam !== undefined) {
        localVarQueryParameter['with_exam'] = withExam;
      }

      if (withHint !== undefined) {
        localVarQueryParameter['with_hint'] = withHint;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetReportedQuestion: async (
      domainId: string,
      page?: number,
      pageSize?: number,
      tagId?: string,
      userId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'domainId' is not null or undefined
      assertParamExists('reviewApiGetReportedQuestion', 'domainId', domainId);
      const localVarPath = `/domain/{domain_id}/review/reported`.replace(
        `{${'domain_id'}}`,
        encodeURIComponent(String(domainId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (tagId !== undefined) {
        localVarQueryParameter['tag_id'] = tagId;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetSelfReviewingHistory: async (
      domainId: string,
      page?: number,
      pageSize?: number,
      userId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'domainId' is not null or undefined
      assertParamExists(
        'reviewApiGetSelfReviewingHistory',
        'domainId',
        domainId,
      );
      const localVarPath = `/domain/{domain_id}/review/history`.replace(
        `{${'domain_id'}}`,
        encodeURIComponent(String(domainId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiListReviewReportsAdmin: async (
      domainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'domainId' is not null or undefined
      assertParamExists(
        'reviewApiListReviewReportsAdmin',
        'domainId',
        domainId,
      );
      const localVarPath = `/admin/domain/{domain_id}/review/get_all_reports`.replace(
        `{${'domain_id'}}`,
        encodeURIComponent(String(domainId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody} reportQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReportQuestion: async (
      questionId: string,
      reportQuestionBody: ReportQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists('reviewApiReportQuestion', 'questionId', questionId);
      // verify required parameter 'reportQuestionBody' is not null or undefined
      assertParamExists(
        'reviewApiReportQuestion',
        'reportQuestionBody',
        reportQuestionBody,
      );
      const localVarPath = `/review/{question_id}/report`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reportQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetReviewResolveReportBody} getReviewResolveReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiResolveReport: async (
      getReviewResolveReportBody: GetReviewResolveReportBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getReviewResolveReportBody' is not null or undefined
      assertParamExists(
        'reviewApiResolveReport',
        'getReviewResolveReportBody',
        getReviewResolveReportBody,
      );
      const localVarPath = `/admin/review/resolve_report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getReviewResolveReportBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReviewReportedQuestionBody} reviewReportedQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReviewReportedQuestion: async (
      reviewReportedQuestionBody: ReviewReportedQuestionBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reviewReportedQuestionBody' is not null or undefined
      assertParamExists(
        'reviewApiReviewReportedQuestion',
        'reviewReportedQuestionBody',
        reviewReportedQuestionBody,
      );
      const localVarPath = `/review/review_reported_question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reviewReportedQuestionBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReviewTemplateApi: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/review/template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateReviewStatusBody} updateReviewStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiUpdateUnderReviewDetails: async (
      questionId: string,
      updateReviewStatusBody: UpdateReviewStatusBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        'reviewApiUpdateUnderReviewDetails',
        'questionId',
        questionId,
      );
      // verify required parameter 'updateReviewStatusBody' is not null or undefined
      assertParamExists(
        'reviewApiUpdateUnderReviewDetails',
        'updateReviewStatusBody',
        updateReviewStatusBody,
      );
      const localVarPath = `/review/{question_id}/review_status`.replace(
        `{${'question_id'}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReviewStatusBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReviewApiApi - functional programming interface
 * @export
 */
export const ReviewApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ReviewApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiAcceptReportingReason(
      reportId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AcceptReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiAcceptReportingReason(
        reportId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ReviewTestApiBody} reviewTestApiBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiCallTestApi(
      reviewTestApiBody: ReviewTestApiBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiCallTestApi(
        reviewTestApiBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateReviewTemplateBody} createReviewTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiCreateReviewTemplateApi(
      createReviewTemplateBody: CreateReviewTemplateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiCreateReviewTemplateApi(
        createReviewTemplateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} reviewTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiDeleteReviewTemplateApi(
      reviewTemplateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteReviewTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiDeleteReviewTemplateApi(
        reviewTemplateId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiGetBlockStatistics(
      domainId?: string,
      endDate?: string,
      startDate?: string,
      tagId?: string,
      userId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetReviewBlockStatisticsResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiGetBlockStatistics(
        domainId,
        endDate,
        startDate,
        tagId,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {number} [duplicate]
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [questionType]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {string} [withExam]
     * @param {string} [withHint]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiGetQuestionUnderreviewList(
      domainId?: string,
      duplicate?: number,
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      questionStatus?: string,
      questionType?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      withExam?: string,
      withHint?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUnderReviewResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiGetQuestionUnderreviewList(
        domainId,
        duplicate,
        maxDate,
        minDate,
        page,
        pageSize,
        questionStatus,
        questionType,
        sortBy,
        sortOrder,
        tagId,
        userId,
        withExam,
        withHint,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiGetReportedQuestion(
      domainId: string,
      page?: number,
      pageSize?: number,
      tagId?: string,
      userId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDomainReviewReportedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiGetReportedQuestion(
        domainId,
        page,
        pageSize,
        tagId,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiGetSelfReviewingHistory(
      domainId: string,
      page?: number,
      pageSize?: number,
      userId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDomainReviewReportedResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiGetSelfReviewingHistory(
        domainId,
        page,
        pageSize,
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} domainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiListReviewReportsAdmin(
      domainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllReviewReportResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiListReviewReportsAdmin(
        domainId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody} reportQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiReportQuestion(
      questionId: string,
      reportQuestionBody: ReportQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiReportQuestion(
        questionId,
        reportQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {GetReviewResolveReportBody} getReviewResolveReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiResolveReport(
      getReviewResolveReportBody: GetReviewResolveReportBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetReviewResolveReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiResolveReport(
        getReviewResolveReportBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ReviewReportedQuestionBody} reviewReportedQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiReviewReportedQuestion(
      reviewReportedQuestionBody: ReviewReportedQuestionBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReviewReportedQuestionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiReviewReportedQuestion(
        reviewReportedQuestionBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiReviewTemplateApi(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetReviewTemplateResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiReviewTemplateApi(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateReviewStatusBody} updateReviewStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewApiUpdateUnderReviewDetails(
      questionId: string,
      updateReviewStatusBody: UpdateReviewStatusBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApiUpdateUnderReviewDetails(
        questionId,
        updateReviewStatusBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ReviewApiApi - factory interface
 * @export
 */
export const ReviewApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReviewApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} reportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiAcceptReportingReason(
      reportId: string,
      options?: any,
    ): AxiosPromise<AcceptReportResponse> {
      return localVarFp
        .reviewApiAcceptReportingReason(reportId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ReviewTestApiBody} reviewTestApiBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiCallTestApi(
      reviewTestApiBody: ReviewTestApiBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .reviewApiCallTestApi(reviewTestApiBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateReviewTemplateBody} createReviewTemplateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiCreateReviewTemplateApi(
      createReviewTemplateBody: CreateReviewTemplateBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .reviewApiCreateReviewTemplateApi(createReviewTemplateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} reviewTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiDeleteReviewTemplateApi(
      reviewTemplateId: string,
      options?: any,
    ): AxiosPromise<DeleteReviewTemplateResponse> {
      return localVarFp
        .reviewApiDeleteReviewTemplateApi(reviewTemplateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetBlockStatistics(
      domainId?: string,
      endDate?: string,
      startDate?: string,
      tagId?: string,
      userId?: string,
      options?: any,
    ): AxiosPromise<Array<GetReviewBlockStatisticsResponse>> {
      return localVarFp
        .reviewApiGetBlockStatistics(
          domainId,
          endDate,
          startDate,
          tagId,
          userId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {number} [duplicate]
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [questionStatus]
     * @param {string} [questionType]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {string} [withExam]
     * @param {string} [withHint]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetQuestionUnderreviewList(
      domainId?: string,
      duplicate?: number,
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      questionStatus?: string,
      questionType?: string,
      sortBy?: string,
      sortOrder?: string,
      tagId?: string,
      userId?: string,
      withExam?: string,
      withHint?: string,
      options?: any,
    ): AxiosPromise<GetUnderReviewResponse> {
      return localVarFp
        .reviewApiGetQuestionUnderreviewList(
          domainId,
          duplicate,
          maxDate,
          minDate,
          page,
          pageSize,
          questionStatus,
          questionType,
          sortBy,
          sortOrder,
          tagId,
          userId,
          withExam,
          withHint,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [tagId]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetReportedQuestion(
      domainId: string,
      page?: number,
      pageSize?: number,
      tagId?: string,
      userId?: string,
      options?: any,
    ): AxiosPromise<GetDomainReviewReportedResponse> {
      return localVarFp
        .reviewApiGetReportedQuestion(
          domainId,
          page,
          pageSize,
          tagId,
          userId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domainId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiGetSelfReviewingHistory(
      domainId: string,
      page?: number,
      pageSize?: number,
      userId?: string,
      options?: any,
    ): AxiosPromise<GetDomainReviewReportedResponse> {
      return localVarFp
        .reviewApiGetSelfReviewingHistory(
          domainId,
          page,
          pageSize,
          userId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiListReviewReportsAdmin(
      domainId: string,
      options?: any,
    ): AxiosPromise<Array<GetAllReviewReportResponse>> {
      return localVarFp
        .reviewApiListReviewReportsAdmin(domainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {ReportQuestionBody} reportQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReportQuestion(
      questionId: string,
      reportQuestionBody: ReportQuestionBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .reviewApiReportQuestion(questionId, reportQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetReviewResolveReportBody} getReviewResolveReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiResolveReport(
      getReviewResolveReportBody: GetReviewResolveReportBody,
      options?: any,
    ): AxiosPromise<GetReviewResolveReportResponse> {
      return localVarFp
        .reviewApiResolveReport(getReviewResolveReportBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ReviewReportedQuestionBody} reviewReportedQuestionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReviewReportedQuestion(
      reviewReportedQuestionBody: ReviewReportedQuestionBody,
      options?: any,
    ): AxiosPromise<ReviewReportedQuestionResponse> {
      return localVarFp
        .reviewApiReviewReportedQuestion(reviewReportedQuestionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiReviewTemplateApi(
      options?: any,
    ): AxiosPromise<Array<GetReviewTemplateResponse>> {
      return localVarFp
        .reviewApiReviewTemplateApi(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} questionId
     * @param {UpdateReviewStatusBody} updateReviewStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewApiUpdateUnderReviewDetails(
      questionId: string,
      updateReviewStatusBody: UpdateReviewStatusBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .reviewApiUpdateUnderReviewDetails(
          questionId,
          updateReviewStatusBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReviewApiApi - object-oriented interface
 * @export
 * @class ReviewApiApi
 * @extends {BaseAPI}
 */
export class ReviewApiApi extends BaseAPI {
  /**
   *
   * @param {string} reportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiAcceptReportingReason(
    reportId: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiAcceptReportingReason(reportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReviewTestApiBody} reviewTestApiBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiCallTestApi(
    reviewTestApiBody: ReviewTestApiBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiCallTestApi(reviewTestApiBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateReviewTemplateBody} createReviewTemplateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiCreateReviewTemplateApi(
    createReviewTemplateBody: CreateReviewTemplateBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiCreateReviewTemplateApi(createReviewTemplateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} reviewTemplateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiDeleteReviewTemplateApi(
    reviewTemplateId: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiDeleteReviewTemplateApi(reviewTemplateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [endDate]
   * @param {string} [startDate]
   * @param {string} [tagId]
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiGetBlockStatistics(
    domainId?: string,
    endDate?: string,
    startDate?: string,
    tagId?: string,
    userId?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiGetBlockStatistics(
        domainId,
        endDate,
        startDate,
        tagId,
        userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {number} [duplicate]
   * @param {string} [maxDate]
   * @param {string} [minDate]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [questionStatus]
   * @param {string} [questionType]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [tagId]
   * @param {string} [userId]
   * @param {string} [withExam]
   * @param {string} [withHint]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiGetQuestionUnderreviewList(
    domainId?: string,
    duplicate?: number,
    maxDate?: string,
    minDate?: string,
    page?: number,
    pageSize?: number,
    questionStatus?: string,
    questionType?: string,
    sortBy?: string,
    sortOrder?: string,
    tagId?: string,
    userId?: string,
    withExam?: string,
    withHint?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiGetQuestionUnderreviewList(
        domainId,
        duplicate,
        maxDate,
        minDate,
        page,
        pageSize,
        questionStatus,
        questionType,
        sortBy,
        sortOrder,
        tagId,
        userId,
        withExam,
        withHint,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domainId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [tagId]
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiGetReportedQuestion(
    domainId: string,
    page?: number,
    pageSize?: number,
    tagId?: string,
    userId?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiGetReportedQuestion(
        domainId,
        page,
        pageSize,
        tagId,
        userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domainId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiGetSelfReviewingHistory(
    domainId: string,
    page?: number,
    pageSize?: number,
    userId?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiGetSelfReviewingHistory(
        domainId,
        page,
        pageSize,
        userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiListReviewReportsAdmin(
    domainId: string,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiListReviewReportsAdmin(domainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {ReportQuestionBody} reportQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiReportQuestion(
    questionId: string,
    reportQuestionBody: ReportQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiReportQuestion(questionId, reportQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetReviewResolveReportBody} getReviewResolveReportBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiResolveReport(
    getReviewResolveReportBody: GetReviewResolveReportBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiResolveReport(getReviewResolveReportBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReviewReportedQuestionBody} reviewReportedQuestionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiReviewReportedQuestion(
    reviewReportedQuestionBody: ReviewReportedQuestionBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiReviewReportedQuestion(reviewReportedQuestionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiReviewTemplateApi(options?: AxiosRequestConfig) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiReviewTemplateApi(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} questionId
   * @param {UpdateReviewStatusBody} updateReviewStatusBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApiApi
   */
  public reviewApiUpdateUnderReviewDetails(
    questionId: string,
    updateReviewStatusBody: UpdateReviewStatusBody,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiApiFp(this.configuration)
      .reviewApiUpdateUnderReviewDetails(
        questionId,
        updateReviewStatusBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SalesApiApi - axios parameter creator
 * @export
 */
export const SalesApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {Array<CreateSalesLeadBody>} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateBulkSalesLead: async (
      createSalesLeadBody: Array<CreateSalesLeadBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSalesLeadBody' is not null or undefined
      assertParamExists(
        'salesApiCreateBulkSalesLead',
        'createSalesLeadBody',
        createSalesLeadBody,
      );
      const localVarPath = `/sales-lead/bulk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSalesLeadBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateCallAttempt: async (
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists(
        'salesApiCreateCallAttempt',
        'salesLeadId',
        salesLeadId,
      );
      // verify required parameter 'createCallAttemptBody' is not null or undefined
      assertParamExists(
        'salesApiCreateCallAttempt',
        'createCallAttemptBody',
        createCallAttemptBody,
      );
      const localVarPath = `/sales-lead/{sales_lead_id}/call-attempt`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCallAttemptBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateSalesLeadBody} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateSalesLead: async (
      createSalesLeadBody: CreateSalesLeadBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSalesLeadBody' is not null or undefined
      assertParamExists(
        'salesApiCreateSalesLead',
        'createSalesLeadBody',
        createSalesLeadBody,
      );
      const localVarPath = `/sales-lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSalesLeadBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiDeleteSalesLeadById: async (
      salesLeadId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists(
        'salesApiDeleteSalesLeadById',
        'salesLeadId',
        salesLeadId,
      );
      const localVarPath = `/sales-lead/{sales_lead_id}`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiEmailToSalesLead: async (
      salesLeadId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists('salesApiEmailToSalesLead', 'salesLeadId', salesLeadId);
      const localVarPath = `/sales-lead/{sales_lead_id}/signup-mail`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [phoneNumber]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetCallAttemptList: async (
      page?: number,
      pageSize?: number,
      phoneNumber?: string,
      salesLeadId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/call-attempts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter['phone_number'] = phoneNumber;
      }

      if (salesLeadId !== undefined) {
        localVarQueryParameter['sales_lead_id'] = salesLeadId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesExcutiveList: async (
      name?: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/sales-excutives`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [salesUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesExec: async (
      salesUserId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sales-executive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (salesUserId !== undefined) {
        localVarQueryParameter['sales_user_id'] = salesUserId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadById: async (
      salesLeadId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists('salesApiGetSalesLeadById', 'salesLeadId', salesLeadId);
      const localVarPath = `/sales-lead/{sales_lead_id}`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadJobDetailsList: async (
      salesLeadId: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists(
        'salesApiGetSalesLeadJobDetailsList',
        'salesLeadId',
        salesLeadId,
      );
      const localVarPath = `/sales-lead/{sales_lead_id}/jobs`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [salesLeadType]
     * @param {string} [salesUserId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadList: async (
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      query?: string,
      salesLeadType?: string,
      salesUserId?: string,
      status?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sales-lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (maxDate !== undefined) {
        localVarQueryParameter['max_date'] = maxDate;
      }

      if (minDate !== undefined) {
        localVarQueryParameter['min_date'] = minDate;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (salesLeadType !== undefined) {
        localVarQueryParameter['sales_lead_type'] = salesLeadType;
      }

      if (salesUserId !== undefined) {
        localVarQueryParameter['sales_user_id'] = salesUserId;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [email]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesMailSentList: async (
      email?: string,
      page?: number,
      pageSize?: number,
      salesLeadId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sales-lead/mail-sent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (salesLeadId !== undefined) {
        localVarQueryParameter['sales_lead_id'] = salesLeadId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} callAttemptId
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiUpdateCallAttempt: async (
      callAttemptId: string,
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'callAttemptId' is not null or undefined
      assertParamExists(
        'salesApiUpdateCallAttempt',
        'callAttemptId',
        callAttemptId,
      );
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists(
        'salesApiUpdateCallAttempt',
        'salesLeadId',
        salesLeadId,
      );
      // verify required parameter 'createCallAttemptBody' is not null or undefined
      assertParamExists(
        'salesApiUpdateCallAttempt',
        'createCallAttemptBody',
        createCallAttemptBody,
      );
      const localVarPath = `/sales-lead/{sales_lead_id}/call-attempt/{call_attempt_id}`
        .replace(
          `{${'call_attempt_id'}}`,
          encodeURIComponent(String(callAttemptId)),
        )
        .replace(
          `{${'sales_lead_id'}}`,
          encodeURIComponent(String(salesLeadId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCallAttemptBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {UpdateSalesLeadBody} updateSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiUpdateSalesLeadById: async (
      salesLeadId: string,
      updateSalesLeadBody: UpdateSalesLeadBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesLeadId' is not null or undefined
      assertParamExists(
        'salesApiUpdateSalesLeadById',
        'salesLeadId',
        salesLeadId,
      );
      // verify required parameter 'updateSalesLeadBody' is not null or undefined
      assertParamExists(
        'salesApiUpdateSalesLeadById',
        'updateSalesLeadBody',
        updateSalesLeadBody,
      );
      const localVarPath = `/sales-lead/{sales_lead_id}`.replace(
        `{${'sales_lead_id'}}`,
        encodeURIComponent(String(salesLeadId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSalesLeadBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalesApiApi - functional programming interface
 * @export
 */
export const SalesApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SalesApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Array<CreateSalesLeadBody>} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiCreateBulkSalesLead(
      createSalesLeadBody: Array<CreateSalesLeadBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateBulkSalesLeadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiCreateBulkSalesLead(
        createSalesLeadBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiCreateCallAttempt(
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCallAttemptResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiCreateCallAttempt(
        salesLeadId,
        createCallAttemptBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateSalesLeadBody} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiCreateSalesLead(
      createSalesLeadBody: CreateSalesLeadBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSalesLeadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiCreateSalesLead(
        createSalesLeadBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiDeleteSalesLeadById(
      salesLeadId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiDeleteSalesLeadById(
        salesLeadId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiEmailToSalesLead(
      salesLeadId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SalesLeadSignUpResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiEmailToSalesLead(
        salesLeadId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [phoneNumber]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetCallAttemptList(
      page?: number,
      pageSize?: number,
      phoneNumber?: string,
      salesLeadId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCallAttemptListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetCallAttemptList(
        page,
        pageSize,
        phoneNumber,
        salesLeadId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesExcutiveList(
      name?: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetsalesExecutiveListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesExcutiveList(
        name,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [salesUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesExec(
      salesUserId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSalesExcutiveSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesExec(
        salesUserId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesLeadById(
      salesLeadId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSalesLeadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesLeadById(
        salesLeadId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesLeadJobDetailsList(
      salesLeadId: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllSalesLeadsJobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesLeadJobDetailsList(
        salesLeadId,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [salesLeadType]
     * @param {string} [salesUserId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesLeadList(
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      query?: string,
      salesLeadType?: string,
      salesUserId?: string,
      status?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllSalesLeadsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesLeadList(
        maxDate,
        minDate,
        page,
        pageSize,
        query,
        salesLeadType,
        salesUserId,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [email]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiGetSalesMailSentList(
      email?: string,
      page?: number,
      pageSize?: number,
      salesLeadId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSalesMailSentListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiGetSalesMailSentList(
        email,
        page,
        pageSize,
        salesLeadId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} callAttemptId
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiUpdateCallAttempt(
      callAttemptId: string,
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCallAttemptResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiUpdateCallAttempt(
        callAttemptId,
        salesLeadId,
        createCallAttemptBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {UpdateSalesLeadBody} updateSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesApiUpdateSalesLeadById(
      salesLeadId: string,
      updateSalesLeadBody: UpdateSalesLeadBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSalesLeadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesApiUpdateSalesLeadById(
        salesLeadId,
        updateSalesLeadBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SalesApiApi - factory interface
 * @export
 */
export const SalesApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SalesApiApiFp(configuration);
  return {
    /**
     *
     * @param {Array<CreateSalesLeadBody>} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateBulkSalesLead(
      createSalesLeadBody: Array<CreateSalesLeadBody>,
      options?: any,
    ): AxiosPromise<CreateBulkSalesLeadResponse> {
      return localVarFp
        .salesApiCreateBulkSalesLead(createSalesLeadBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateCallAttempt(
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options?: any,
    ): AxiosPromise<GetCallAttemptResponse> {
      return localVarFp
        .salesApiCreateCallAttempt(salesLeadId, createCallAttemptBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateSalesLeadBody} createSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiCreateSalesLead(
      createSalesLeadBody: CreateSalesLeadBody,
      options?: any,
    ): AxiosPromise<GetSalesLeadResponse> {
      return localVarFp
        .salesApiCreateSalesLead(createSalesLeadBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiDeleteSalesLeadById(
      salesLeadId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .salesApiDeleteSalesLeadById(salesLeadId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiEmailToSalesLead(
      salesLeadId: string,
      options?: any,
    ): AxiosPromise<SalesLeadSignUpResponse> {
      return localVarFp
        .salesApiEmailToSalesLead(salesLeadId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [phoneNumber]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetCallAttemptList(
      page?: number,
      pageSize?: number,
      phoneNumber?: string,
      salesLeadId?: string,
      options?: any,
    ): AxiosPromise<GetCallAttemptListResponse> {
      return localVarFp
        .salesApiGetCallAttemptList(
          page,
          pageSize,
          phoneNumber,
          salesLeadId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesExcutiveList(
      name?: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetsalesExecutiveListResponse> {
      return localVarFp
        .salesApiGetSalesExcutiveList(name, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [salesUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesExec(
      salesUserId?: string,
      options?: any,
    ): AxiosPromise<GetSalesExcutiveSub> {
      return localVarFp
        .salesApiGetSalesExec(salesUserId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadById(
      salesLeadId: string,
      options?: any,
    ): AxiosPromise<GetSalesLeadResponse> {
      return localVarFp
        .salesApiGetSalesLeadById(salesLeadId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadJobDetailsList(
      salesLeadId: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetAllSalesLeadsJobsResponse> {
      return localVarFp
        .salesApiGetSalesLeadJobDetailsList(
          salesLeadId,
          page,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [maxDate]
     * @param {string} [minDate]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {string} [salesLeadType]
     * @param {string} [salesUserId]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesLeadList(
      maxDate?: string,
      minDate?: string,
      page?: number,
      pageSize?: number,
      query?: string,
      salesLeadType?: string,
      salesUserId?: string,
      status?: string,
      options?: any,
    ): AxiosPromise<GetAllSalesLeadsResponse> {
      return localVarFp
        .salesApiGetSalesLeadList(
          maxDate,
          minDate,
          page,
          pageSize,
          query,
          salesLeadType,
          salesUserId,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [email]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [salesLeadId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiGetSalesMailSentList(
      email?: string,
      page?: number,
      pageSize?: number,
      salesLeadId?: string,
      options?: any,
    ): AxiosPromise<GetSalesMailSentListResponse> {
      return localVarFp
        .salesApiGetSalesMailSentList(
          email,
          page,
          pageSize,
          salesLeadId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} callAttemptId
     * @param {string} salesLeadId
     * @param {CreateCallAttemptBody} createCallAttemptBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiUpdateCallAttempt(
      callAttemptId: string,
      salesLeadId: string,
      createCallAttemptBody: CreateCallAttemptBody,
      options?: any,
    ): AxiosPromise<GetCallAttemptResponse> {
      return localVarFp
        .salesApiUpdateCallAttempt(
          callAttemptId,
          salesLeadId,
          createCallAttemptBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} salesLeadId
     * @param {UpdateSalesLeadBody} updateSalesLeadBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesApiUpdateSalesLeadById(
      salesLeadId: string,
      updateSalesLeadBody: UpdateSalesLeadBody,
      options?: any,
    ): AxiosPromise<GetSalesLeadResponse> {
      return localVarFp
        .salesApiUpdateSalesLeadById(salesLeadId, updateSalesLeadBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SalesApiApi - object-oriented interface
 * @export
 * @class SalesApiApi
 * @extends {BaseAPI}
 */
export class SalesApiApi extends BaseAPI {
  /**
   *
   * @param {Array<CreateSalesLeadBody>} createSalesLeadBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiCreateBulkSalesLead(
    createSalesLeadBody: Array<CreateSalesLeadBody>,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiCreateBulkSalesLead(createSalesLeadBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {CreateCallAttemptBody} createCallAttemptBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiCreateCallAttempt(
    salesLeadId: string,
    createCallAttemptBody: CreateCallAttemptBody,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiCreateCallAttempt(salesLeadId, createCallAttemptBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateSalesLeadBody} createSalesLeadBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiCreateSalesLead(
    createSalesLeadBody: CreateSalesLeadBody,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiCreateSalesLead(createSalesLeadBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiDeleteSalesLeadById(
    salesLeadId: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiDeleteSalesLeadById(salesLeadId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiEmailToSalesLead(
    salesLeadId: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiEmailToSalesLead(salesLeadId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [phoneNumber]
   * @param {string} [salesLeadId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetCallAttemptList(
    page?: number,
    pageSize?: number,
    phoneNumber?: string,
    salesLeadId?: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetCallAttemptList(
        page,
        pageSize,
        phoneNumber,
        salesLeadId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [name]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesExcutiveList(
    name?: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesExcutiveList(name, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [salesUserId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesExec(
    salesUserId?: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesExec(salesUserId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesLeadById(
    salesLeadId: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesLeadById(salesLeadId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesLeadJobDetailsList(
    salesLeadId: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesLeadJobDetailsList(salesLeadId, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [maxDate]
   * @param {string} [minDate]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {string} [salesLeadType]
   * @param {string} [salesUserId]
   * @param {string} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesLeadList(
    maxDate?: string,
    minDate?: string,
    page?: number,
    pageSize?: number,
    query?: string,
    salesLeadType?: string,
    salesUserId?: string,
    status?: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesLeadList(
        maxDate,
        minDate,
        page,
        pageSize,
        query,
        salesLeadType,
        salesUserId,
        status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [email]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [salesLeadId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiGetSalesMailSentList(
    email?: string,
    page?: number,
    pageSize?: number,
    salesLeadId?: string,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiGetSalesMailSentList(email, page, pageSize, salesLeadId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} callAttemptId
   * @param {string} salesLeadId
   * @param {CreateCallAttemptBody} createCallAttemptBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiUpdateCallAttempt(
    callAttemptId: string,
    salesLeadId: string,
    createCallAttemptBody: CreateCallAttemptBody,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiUpdateCallAttempt(
        callAttemptId,
        salesLeadId,
        createCallAttemptBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} salesLeadId
   * @param {UpdateSalesLeadBody} updateSalesLeadBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesApiApi
   */
  public salesApiUpdateSalesLeadById(
    salesLeadId: string,
    updateSalesLeadBody: UpdateSalesLeadBody,
    options?: AxiosRequestConfig,
  ) {
    return SalesApiApiFp(this.configuration)
      .salesApiUpdateSalesLeadById(salesLeadId, updateSalesLeadBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ScoreApiApi - axios parameter creator
 * @export
 */
export const ScoreApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} userId
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreApiGetDailyScoreDataByDateRange: async (
      userId: string,
      endDate?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        'scoreApiGetDailyScoreDataByDateRange',
        'userId',
        userId,
      );
      const localVarPath = `/score/{user_id}/daily_statistics`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {string} [selectedMonth]
     * @param {string} [selectedYear]
     * @param {string} [summaryType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreApiGetUserDailyActivity: async (
      username: string,
      selectedMonth?: string,
      selectedYear?: string,
      summaryType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('scoreApiGetUserDailyActivity', 'username', username);
      const localVarPath = `/{username}/get-user-activity`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (selectedMonth !== undefined) {
        localVarQueryParameter['selected_month'] = selectedMonth;
      }

      if (selectedYear !== undefined) {
        localVarQueryParameter['selected_year'] = selectedYear;
      }

      if (summaryType !== undefined) {
        localVarQueryParameter['summary_type'] = summaryType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ScoreApiApi - functional programming interface
 * @export
 */
export const ScoreApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ScoreApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} userId
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scoreApiGetDailyScoreDataByDateRange(
      userId: string,
      endDate?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DailyScoreResponseSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scoreApiGetDailyScoreDataByDateRange(
        userId,
        endDate,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} username
     * @param {string} [selectedMonth]
     * @param {string} [selectedYear]
     * @param {string} [summaryType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scoreApiGetUserDailyActivity(
      username: string,
      selectedMonth?: string,
      selectedYear?: string,
      summaryType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserActivityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scoreApiGetUserDailyActivity(
        username,
        selectedMonth,
        selectedYear,
        summaryType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ScoreApiApi - factory interface
 * @export
 */
export const ScoreApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScoreApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} userId
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreApiGetDailyScoreDataByDateRange(
      userId: string,
      endDate?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<DailyScoreResponseSub> {
      return localVarFp
        .scoreApiGetDailyScoreDataByDateRange(
          userId,
          endDate,
          startDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {string} [selectedMonth]
     * @param {string} [selectedYear]
     * @param {string} [summaryType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreApiGetUserDailyActivity(
      username: string,
      selectedMonth?: string,
      selectedYear?: string,
      summaryType?: string,
      options?: any,
    ): AxiosPromise<GetUserActivityResponse> {
      return localVarFp
        .scoreApiGetUserDailyActivity(
          username,
          selectedMonth,
          selectedYear,
          summaryType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ScoreApiApi - object-oriented interface
 * @export
 * @class ScoreApiApi
 * @extends {BaseAPI}
 */
export class ScoreApiApi extends BaseAPI {
  /**
   *
   * @param {string} userId
   * @param {string} [endDate]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoreApiApi
   */
  public scoreApiGetDailyScoreDataByDateRange(
    userId: string,
    endDate?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return ScoreApiApiFp(this.configuration)
      .scoreApiGetDailyScoreDataByDateRange(userId, endDate, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} username
   * @param {string} [selectedMonth]
   * @param {string} [selectedYear]
   * @param {string} [summaryType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoreApiApi
   */
  public scoreApiGetUserDailyActivity(
    username: string,
    selectedMonth?: string,
    selectedYear?: string,
    summaryType?: string,
    options?: AxiosRequestConfig,
  ) {
    return ScoreApiApiFp(this.configuration)
      .scoreApiGetUserDailyActivity(
        username,
        selectedMonth,
        selectedYear,
        summaryType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SmsApiApi - axios parameter creator
 * @export
 */
export const SmsApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {SendOtpBody} sendOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsApiGenerateSms: async (
      sendOtpBody: SendOtpBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendOtpBody' is not null or undefined
      assertParamExists('smsApiGenerateSms', 'sendOtpBody', sendOtpBody);
      const localVarPath = `/sms/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendOtpBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ValidateOtpBody} validateOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsApiValidateOtp: async (
      validateOtpBody: ValidateOtpBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'validateOtpBody' is not null or undefined
      assertParamExists(
        'smsApiValidateOtp',
        'validateOtpBody',
        validateOtpBody,
      );
      const localVarPath = `/sms/validate_otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateOtpBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SmsApiApi - functional programming interface
 * @export
 */
export const SmsApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SmsApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SendOtpBody} sendOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async smsApiGenerateSms(
      sendOtpBody: SendOtpBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.smsApiGenerateSms(
        sendOtpBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ValidateOtpBody} validateOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async smsApiValidateOtp(
      validateOtpBody: ValidateOtpBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateOtpResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.smsApiValidateOtp(
        validateOtpBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SmsApiApi - factory interface
 * @export
 */
export const SmsApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SmsApiApiFp(configuration);
  return {
    /**
     *
     * @param {SendOtpBody} sendOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsApiGenerateSms(
      sendOtpBody: SendOtpBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .smsApiGenerateSms(sendOtpBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ValidateOtpBody} validateOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsApiValidateOtp(
      validateOtpBody: ValidateOtpBody,
      options?: any,
    ): AxiosPromise<ValidateOtpResponse> {
      return localVarFp
        .smsApiValidateOtp(validateOtpBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SmsApiApi - object-oriented interface
 * @export
 * @class SmsApiApi
 * @extends {BaseAPI}
 */
export class SmsApiApi extends BaseAPI {
  /**
   *
   * @param {SendOtpBody} sendOtpBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApiApi
   */
  public smsApiGenerateSms(
    sendOtpBody: SendOtpBody,
    options?: AxiosRequestConfig,
  ) {
    return SmsApiApiFp(this.configuration)
      .smsApiGenerateSms(sendOtpBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ValidateOtpBody} validateOtpBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApiApi
   */
  public smsApiValidateOtp(
    validateOtpBody: ValidateOtpBody,
    options?: AxiosRequestConfig,
  ) {
    return SmsApiApiFp(this.configuration)
      .smsApiValidateOtp(validateOtpBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StudentManagementApiApi - axios parameter creator
 * @export
 */
export const StudentManagementApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiCreateCollegeMilestoneDetails: async (
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateCollegeMilestonesResponse' is not null or undefined
      assertParamExists(
        'studentManagementApiCreateCollegeMilestoneDetails',
        'updateCollegeMilestonesResponse',
        updateCollegeMilestonesResponse,
      );
      const localVarPath = `/college/milestone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCollegeMilestonesResponse,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiDeleteCollegeMilestoneDetail: async (
      milestoneId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'milestoneId' is not null or undefined
      assertParamExists(
        'studentManagementApiDeleteCollegeMilestoneDetail',
        'milestoneId',
        milestoneId,
      );
      const localVarPath = `/college/milestone/{milestone_id}`.replace(
        `{${'milestone_id'}}`,
        encodeURIComponent(String(milestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [selectedYear]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetBatchPerformance: async (
      collegeId: string,
      batchList?: string,
      selectedYear?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetBatchPerformance',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/performance-summary`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (batchList !== undefined) {
        localVarQueryParameter['batch_list'] = batchList;
      }

      if (selectedYear !== undefined) {
        localVarQueryParameter['selected_year'] = selectedYear;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeDetailsById: async (
      collegeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeDetailsById',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batch]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeLeaderboard: async (
      collegeId: string,
      batch?: string,
      page?: string,
      pageSize?: string,
      timePeriod?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeLeaderboard',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/leaderboard`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (batch !== undefined) {
        localVarQueryParameter['batch'] = batch;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (timePeriod !== undefined) {
        localVarQueryParameter['time_period'] = timePeriod;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeMilestoneDetails: async (
      collegeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeMilestoneDetails',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/milestone`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeMilestoneDetailsById: async (
      milestoneId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'milestoneId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeMilestoneDetailsById',
        'milestoneId',
        milestoneId,
      );
      const localVarPath = `/college/milestone/{milestone_id}`.replace(
        `{${'milestone_id'}}`,
        encodeURIComponent(String(milestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeRelatedDetails: async (
      collegeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeRelatedDetails',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/details`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [batch]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeStudentActivities: async (
      collegeId: string,
      batch?: number,
      pageNo?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetCollegeStudentActivities',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/student-activity`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (batch !== undefined) {
        localVarQueryParameter['batch'] = batch;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetHiredCollegeStudents: async (
      collegeId: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetHiredCollegeStudents',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/hired`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetJobsForCollege: async (
      collegeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetJobsForCollege',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/jobs`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetJobsForStudent: async (
      collegeId: string,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetJobsForStudent',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/student/jobs`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentColleges: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-colleges`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentStatisticCards: async (
      collegeId: string,
      batchList?: string,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetStudentStatisticCards',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/student-stats`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (batchList !== undefined) {
        localVarQueryParameter['batch_list'] = batchList;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentsStats: async (
      collegeId: string,
      period?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiGetStudentsStats',
        'collegeId',
        collegeId,
      );
      const localVarPath = `/college/{college_id}/stats`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {InviteStudentsBody} inviteStudentsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiInviteStudents: async (
      collegeId: string,
      inviteStudentsBody: InviteStudentsBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiInviteStudents',
        'collegeId',
        collegeId,
      );
      // verify required parameter 'inviteStudentsBody' is not null or undefined
      assertParamExists(
        'studentManagementApiInviteStudents',
        'inviteStudentsBody',
        inviteStudentsBody,
      );
      const localVarPath = `/college/{college_id}/invite`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inviteStudentsBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} jobId
     * @param {UpdateCollegeJobInviteStatusBody} updateCollegeJobInviteStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegeJobInviteStatus: async (
      collegeId: string,
      jobId: string,
      updateCollegeJobInviteStatusBody: UpdateCollegeJobInviteStatusBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegeJobInviteStatus',
        'collegeId',
        collegeId,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegeJobInviteStatus',
        'jobId',
        jobId,
      );
      // verify required parameter 'updateCollegeJobInviteStatusBody' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegeJobInviteStatus',
        'updateCollegeJobInviteStatusBody',
        updateCollegeJobInviteStatusBody,
      );
      const localVarPath = `/college/{college_id}/job/{job_id}/invite-status`
        .replace(`{${'college_id'}}`, encodeURIComponent(String(collegeId)))
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCollegeJobInviteStatusBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} milestoneId
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegeMilestoneDetails: async (
      milestoneId: string,
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'milestoneId' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegeMilestoneDetails',
        'milestoneId',
        milestoneId,
      );
      // verify required parameter 'updateCollegeMilestonesResponse' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegeMilestoneDetails',
        'updateCollegeMilestonesResponse',
        updateCollegeMilestonesResponse,
      );
      const localVarPath = `/college/milestone/{milestone_id}`.replace(
        `{${'milestone_id'}}`,
        encodeURIComponent(String(milestoneId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCollegeMilestonesResponse,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {UpdateCollegePasswordBody} updateCollegePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegePassword: async (
      collegeId: string,
      updateCollegePasswordBody: UpdateCollegePasswordBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegePassword',
        'collegeId',
        collegeId,
      );
      // verify required parameter 'updateCollegePasswordBody' is not null or undefined
      assertParamExists(
        'studentManagementApiUpdateCollegePassword',
        'updateCollegePasswordBody',
        updateCollegePasswordBody,
      );
      const localVarPath = `/college/{college_id}/password`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCollegePasswordBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadCollegeLogo: async (
      image: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists(
        'studentManagementApiUploadCollegeLogo',
        'image',
        image,
      );
      const localVarPath = `/college/upload-logo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadMilestoneImage: async (
      image: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      assertParamExists(
        'studentManagementApiUploadMilestoneImage',
        'image',
        image,
      );
      const localVarPath = `/upload/milestone-image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadStudentVerificationDocs: async (
      files: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'files' is not null or undefined
      assertParamExists(
        'studentManagementApiUploadStudentVerificationDocs',
        'files',
        files,
      );
      const localVarPath = `/upload-student-verification-docs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (files !== undefined) {
        localVarFormParams.append('files', files as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {ValidateAndSetPhoneBody} validateAndSetPhoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiValidateAndSetPhoneNo: async (
      collegeId: string,
      validateAndSetPhoneBody: ValidateAndSetPhoneBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiValidateAndSetPhoneNo',
        'collegeId',
        collegeId,
      );
      // verify required parameter 'validateAndSetPhoneBody' is not null or undefined
      assertParamExists(
        'studentManagementApiValidateAndSetPhoneNo',
        'validateAndSetPhoneBody',
        validateAndSetPhoneBody,
      );
      const localVarPath = `/college/{college_id}/phone-no`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateAndSetPhoneBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} collegeId
     * @param {VerifyAndSetEmailBody} verifyAndSetEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiVerifyAndSetEmail: async (
      collegeId: string,
      verifyAndSetEmailBody: VerifyAndSetEmailBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'collegeId' is not null or undefined
      assertParamExists(
        'studentManagementApiVerifyAndSetEmail',
        'collegeId',
        collegeId,
      );
      // verify required parameter 'verifyAndSetEmailBody' is not null or undefined
      assertParamExists(
        'studentManagementApiVerifyAndSetEmail',
        'verifyAndSetEmailBody',
        verifyAndSetEmailBody,
      );
      const localVarPath = `/college/{college_id}/email`.replace(
        `{${'college_id'}}`,
        encodeURIComponent(String(collegeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyAndSetEmailBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StudentManagementApiApi - functional programming interface
 * @export
 */
export const StudentManagementApiApiFp = function(
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator = StudentManagementApiApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     *
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiCreateCollegeMilestoneDetails(
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateCollegeMilestonesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiCreateCollegeMilestoneDetails(
        updateCollegeMilestonesResponse,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiDeleteCollegeMilestoneDetail(
      milestoneId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiDeleteCollegeMilestoneDetail(
        milestoneId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [selectedYear]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetBatchPerformance(
      collegeId: string,
      batchList?: string,
      selectedYear?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchPerformanceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetBatchPerformance(
        collegeId,
        batchList,
        selectedYear,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeDetailsById(
      collegeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCollegeDetailsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeDetailsById(
        collegeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batch]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeLeaderboard(
      collegeId: string,
      batch?: string,
      page?: string,
      pageSize?: string,
      timePeriod?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudentLeaderboardResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeLeaderboard(
        collegeId,
        batch,
        page,
        pageSize,
        timePeriod,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeMilestoneDetails(
      collegeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCollegeMilestonesDetailsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeMilestoneDetails(
        collegeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeMilestoneDetailsById(
      milestoneId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateCollegeMilestonesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeMilestoneDetailsById(
        milestoneId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeRelatedDetails(
      collegeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CollegeRelatedDataResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeRelatedDetails(
        collegeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [batch]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetCollegeStudentActivities(
      collegeId: string,
      batch?: number,
      pageNo?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudentActivityResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetCollegeStudentActivities(
        collegeId,
        batch,
        pageNo,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetHiredCollegeStudents(
      collegeId: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetHiredCandidatesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetHiredCollegeStudents(
        collegeId,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetJobsForCollege(
      collegeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetJobsForCollegeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetJobsForCollege(
        collegeId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetJobsForStudent(
      collegeId: string,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStudentJobsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetJobsForStudent(
        collegeId,
        page,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetStudentColleges(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStudentCollegesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetStudentColleges(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetStudentStatisticCards(
      collegeId: string,
      batchList?: string,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StatisticCardsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetStudentStatisticCards(
        collegeId,
        batchList,
        page,
        pageSize,
        searchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiGetStudentsStats(
      collegeId: string,
      period?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStudentStatsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiGetStudentsStats(
        collegeId,
        period,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {InviteStudentsBody} inviteStudentsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiInviteStudents(
      collegeId: string,
      inviteStudentsBody: InviteStudentsBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InviteStudentsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiInviteStudents(
        collegeId,
        inviteStudentsBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} jobId
     * @param {UpdateCollegeJobInviteStatusBody} updateCollegeJobInviteStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUpdateCollegeJobInviteStatus(
      collegeId: string,
      jobId: string,
      updateCollegeJobInviteStatusBody: UpdateCollegeJobInviteStatusBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateCollegeJobInviteStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUpdateCollegeJobInviteStatus(
        collegeId,
        jobId,
        updateCollegeJobInviteStatusBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} milestoneId
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUpdateCollegeMilestoneDetails(
      milestoneId: string,
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateCollegeMilestonesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUpdateCollegeMilestoneDetails(
        milestoneId,
        updateCollegeMilestonesResponse,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {UpdateCollegePasswordBody} updateCollegePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUpdateCollegePassword(
      collegeId: string,
      updateCollegePasswordBody: UpdateCollegePasswordBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUpdateCollegePassword(
        collegeId,
        updateCollegePasswordBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUploadCollegeLogo(
      image: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUploadCollegeLogo(
        image,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUploadMilestoneImage(
      image: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUploadMilestoneImage(
        image,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiUploadStudentVerificationDocs(
      files: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiUploadStudentVerificationDocs(
        files,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {ValidateAndSetPhoneBody} validateAndSetPhoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiValidateAndSetPhoneNo(
      collegeId: string,
      validateAndSetPhoneBody: ValidateAndSetPhoneBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateAndSetPhoneResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiValidateAndSetPhoneNo(
        collegeId,
        validateAndSetPhoneBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} collegeId
     * @param {VerifyAndSetEmailBody} verifyAndSetEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async studentManagementApiVerifyAndSetEmail(
      collegeId: string,
      verifyAndSetEmailBody: VerifyAndSetEmailBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VerifyAndSetEmailRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.studentManagementApiVerifyAndSetEmail(
        collegeId,
        verifyAndSetEmailBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StudentManagementApiApi - factory interface
 * @export
 */
export const StudentManagementApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StudentManagementApiApiFp(configuration);
  return {
    /**
     *
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiCreateCollegeMilestoneDetails(
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options?: any,
    ): AxiosPromise<CreateCollegeMilestonesResponse> {
      return localVarFp
        .studentManagementApiCreateCollegeMilestoneDetails(
          updateCollegeMilestonesResponse,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiDeleteCollegeMilestoneDetail(
      milestoneId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .studentManagementApiDeleteCollegeMilestoneDetail(milestoneId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [selectedYear]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetBatchPerformance(
      collegeId: string,
      batchList?: string,
      selectedYear?: number,
      options?: any,
    ): AxiosPromise<BatchPerformanceResponse> {
      return localVarFp
        .studentManagementApiGetBatchPerformance(
          collegeId,
          batchList,
          selectedYear,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeDetailsById(
      collegeId: string,
      options?: any,
    ): AxiosPromise<GetCollegeDetailsResponse> {
      return localVarFp
        .studentManagementApiGetCollegeDetailsById(collegeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batch]
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [timePeriod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeLeaderboard(
      collegeId: string,
      batch?: string,
      page?: string,
      pageSize?: string,
      timePeriod?: string,
      options?: any,
    ): AxiosPromise<StudentLeaderboardResponse> {
      return localVarFp
        .studentManagementApiGetCollegeLeaderboard(
          collegeId,
          batch,
          page,
          pageSize,
          timePeriod,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeMilestoneDetails(
      collegeId: string,
      options?: any,
    ): AxiosPromise<GetCollegeMilestonesDetailsResponse> {
      return localVarFp
        .studentManagementApiGetCollegeMilestoneDetails(collegeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} milestoneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeMilestoneDetailsById(
      milestoneId: string,
      options?: any,
    ): AxiosPromise<CreateCollegeMilestonesResponse> {
      return localVarFp
        .studentManagementApiGetCollegeMilestoneDetailsById(
          milestoneId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeRelatedDetails(
      collegeId: string,
      options?: any,
    ): AxiosPromise<CollegeRelatedDataResponse> {
      return localVarFp
        .studentManagementApiGetCollegeRelatedDetails(collegeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [batch]
     * @param {number} [pageNo]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetCollegeStudentActivities(
      collegeId: string,
      batch?: number,
      pageNo?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<StudentActivityResponse> {
      return localVarFp
        .studentManagementApiGetCollegeStudentActivities(
          collegeId,
          batch,
          pageNo,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetHiredCollegeStudents(
      collegeId: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetHiredCandidatesResponse> {
      return localVarFp
        .studentManagementApiGetHiredCollegeStudents(
          collegeId,
          page,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetJobsForCollege(
      collegeId: string,
      options?: any,
    ): AxiosPromise<GetJobsForCollegeResponse> {
      return localVarFp
        .studentManagementApiGetJobsForCollege(collegeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetJobsForStudent(
      collegeId: string,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<GetStudentJobsResponse> {
      return localVarFp
        .studentManagementApiGetJobsForStudent(
          collegeId,
          page,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentColleges(
      options?: any,
    ): AxiosPromise<GetStudentCollegesResponse> {
      return localVarFp
        .studentManagementApiGetStudentColleges(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [batchList]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentStatisticCards(
      collegeId: string,
      batchList?: string,
      page?: number,
      pageSize?: number,
      searchQuery?: string,
      options?: any,
    ): AxiosPromise<StatisticCardsResponse> {
      return localVarFp
        .studentManagementApiGetStudentStatisticCards(
          collegeId,
          batchList,
          page,
          pageSize,
          searchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiGetStudentsStats(
      collegeId: string,
      period?: string,
      options?: any,
    ): AxiosPromise<GetStudentStatsResponse> {
      return localVarFp
        .studentManagementApiGetStudentsStats(collegeId, period, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {InviteStudentsBody} inviteStudentsBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiInviteStudents(
      collegeId: string,
      inviteStudentsBody: InviteStudentsBody,
      options?: any,
    ): AxiosPromise<InviteStudentsResponse> {
      return localVarFp
        .studentManagementApiInviteStudents(
          collegeId,
          inviteStudentsBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {string} jobId
     * @param {UpdateCollegeJobInviteStatusBody} updateCollegeJobInviteStatusBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegeJobInviteStatus(
      collegeId: string,
      jobId: string,
      updateCollegeJobInviteStatusBody: UpdateCollegeJobInviteStatusBody,
      options?: any,
    ): AxiosPromise<UpdateCollegeJobInviteStatusResponse> {
      return localVarFp
        .studentManagementApiUpdateCollegeJobInviteStatus(
          collegeId,
          jobId,
          updateCollegeJobInviteStatusBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} milestoneId
     * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegeMilestoneDetails(
      milestoneId: string,
      updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
      options?: any,
    ): AxiosPromise<CreateCollegeMilestonesResponse> {
      return localVarFp
        .studentManagementApiUpdateCollegeMilestoneDetails(
          milestoneId,
          updateCollegeMilestonesResponse,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {UpdateCollegePasswordBody} updateCollegePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUpdateCollegePassword(
      collegeId: string,
      updateCollegePasswordBody: UpdateCollegePasswordBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .studentManagementApiUpdateCollegePassword(
          collegeId,
          updateCollegePasswordBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadCollegeLogo(
      image: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .studentManagementApiUploadCollegeLogo(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadMilestoneImage(
      image: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .studentManagementApiUploadMilestoneImage(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiUploadStudentVerificationDocs(
      files: any,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .studentManagementApiUploadStudentVerificationDocs(files, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {ValidateAndSetPhoneBody} validateAndSetPhoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiValidateAndSetPhoneNo(
      collegeId: string,
      validateAndSetPhoneBody: ValidateAndSetPhoneBody,
      options?: any,
    ): AxiosPromise<ValidateAndSetPhoneResponse> {
      return localVarFp
        .studentManagementApiValidateAndSetPhoneNo(
          collegeId,
          validateAndSetPhoneBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} collegeId
     * @param {VerifyAndSetEmailBody} verifyAndSetEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    studentManagementApiVerifyAndSetEmail(
      collegeId: string,
      verifyAndSetEmailBody: VerifyAndSetEmailBody,
      options?: any,
    ): AxiosPromise<VerifyAndSetEmailRepsonse> {
      return localVarFp
        .studentManagementApiVerifyAndSetEmail(
          collegeId,
          verifyAndSetEmailBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StudentManagementApiApi - object-oriented interface
 * @export
 * @class StudentManagementApiApi
 * @extends {BaseAPI}
 */
export class StudentManagementApiApi extends BaseAPI {
  /**
   *
   * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiCreateCollegeMilestoneDetails(
    updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiCreateCollegeMilestoneDetails(
        updateCollegeMilestonesResponse,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} milestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiDeleteCollegeMilestoneDetail(
    milestoneId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiDeleteCollegeMilestoneDetail(milestoneId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {string} [batchList]
   * @param {number} [selectedYear]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetBatchPerformance(
    collegeId: string,
    batchList?: string,
    selectedYear?: number,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetBatchPerformance(
        collegeId,
        batchList,
        selectedYear,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeDetailsById(
    collegeId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeDetailsById(collegeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {string} [batch]
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [timePeriod]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeLeaderboard(
    collegeId: string,
    batch?: string,
    page?: string,
    pageSize?: string,
    timePeriod?: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeLeaderboard(
        collegeId,
        batch,
        page,
        pageSize,
        timePeriod,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeMilestoneDetails(
    collegeId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeMilestoneDetails(collegeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} milestoneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeMilestoneDetailsById(
    milestoneId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeMilestoneDetailsById(milestoneId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeRelatedDetails(
    collegeId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeRelatedDetails(collegeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {number} [batch]
   * @param {number} [pageNo]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetCollegeStudentActivities(
    collegeId: string,
    batch?: number,
    pageNo?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetCollegeStudentActivities(
        collegeId,
        batch,
        pageNo,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetHiredCollegeStudents(
    collegeId: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetHiredCollegeStudents(
        collegeId,
        page,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetJobsForCollege(
    collegeId: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetJobsForCollege(collegeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetJobsForStudent(
    collegeId: string,
    page?: number,
    pageSize?: number,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetJobsForStudent(collegeId, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetStudentColleges(options?: AxiosRequestConfig) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetStudentColleges(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {string} [batchList]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [searchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetStudentStatisticCards(
    collegeId: string,
    batchList?: string,
    page?: number,
    pageSize?: number,
    searchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetStudentStatisticCards(
        collegeId,
        batchList,
        page,
        pageSize,
        searchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {string} [period]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiGetStudentsStats(
    collegeId: string,
    period?: string,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiGetStudentsStats(collegeId, period, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {InviteStudentsBody} inviteStudentsBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiInviteStudents(
    collegeId: string,
    inviteStudentsBody: InviteStudentsBody,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiInviteStudents(
        collegeId,
        inviteStudentsBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {string} jobId
   * @param {UpdateCollegeJobInviteStatusBody} updateCollegeJobInviteStatusBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUpdateCollegeJobInviteStatus(
    collegeId: string,
    jobId: string,
    updateCollegeJobInviteStatusBody: UpdateCollegeJobInviteStatusBody,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUpdateCollegeJobInviteStatus(
        collegeId,
        jobId,
        updateCollegeJobInviteStatusBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} milestoneId
   * @param {UpdateCollegeMilestonesResponse} updateCollegeMilestonesResponse
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUpdateCollegeMilestoneDetails(
    milestoneId: string,
    updateCollegeMilestonesResponse: UpdateCollegeMilestonesResponse,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUpdateCollegeMilestoneDetails(
        milestoneId,
        updateCollegeMilestonesResponse,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {UpdateCollegePasswordBody} updateCollegePasswordBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUpdateCollegePassword(
    collegeId: string,
    updateCollegePasswordBody: UpdateCollegePasswordBody,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUpdateCollegePassword(
        collegeId,
        updateCollegePasswordBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUploadCollegeLogo(
    image: any,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUploadCollegeLogo(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUploadMilestoneImage(
    image: any,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUploadMilestoneImage(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} files
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiUploadStudentVerificationDocs(
    files: any,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiUploadStudentVerificationDocs(files, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {ValidateAndSetPhoneBody} validateAndSetPhoneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiValidateAndSetPhoneNo(
    collegeId: string,
    validateAndSetPhoneBody: ValidateAndSetPhoneBody,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiValidateAndSetPhoneNo(
        collegeId,
        validateAndSetPhoneBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} collegeId
   * @param {VerifyAndSetEmailBody} verifyAndSetEmailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StudentManagementApiApi
   */
  public studentManagementApiVerifyAndSetEmail(
    collegeId: string,
    verifyAndSetEmailBody: VerifyAndSetEmailBody,
    options?: AxiosRequestConfig,
  ) {
    return StudentManagementApiApiFp(this.configuration)
      .studentManagementApiVerifyAndSetEmail(
        collegeId,
        verifyAndSetEmailBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TagApiApi - axios parameter creator
 * @export
 */
export const TagApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {ChangeSubtagBody} changeSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiChangeQuestionSubtags: async (
      changeSubtagBody: ChangeSubtagBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changeSubtagBody' is not null or undefined
      assertParamExists(
        'tagApiChangeQuestionSubtags',
        'changeSubtagBody',
        changeSubtagBody,
      );
      const localVarPath = `/tag/change_question_subtags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeSubtagBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConvertSubTagBody} convertSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertSubtagIntoTag: async (
      convertSubTagBody: ConvertSubTagBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'convertSubTagBody' is not null or undefined
      assertParamExists(
        'tagApiConvertSubtagIntoTag',
        'convertSubTagBody',
        convertSubTagBody,
      );
      const localVarPath = `/tag/convert-subtag-to-tag`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        convertSubTagBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConvertTagToDomainBody} convertTagToDomainBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertTagIntoDomain: async (
      convertTagToDomainBody: ConvertTagToDomainBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'convertTagToDomainBody' is not null or undefined
      assertParamExists(
        'tagApiConvertTagIntoDomain',
        'convertTagToDomainBody',
        convertTagToDomainBody,
      );
      const localVarPath = `/tag/convert-tag-to-domain`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        convertTagToDomainBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConvertTagToSubTagBody} convertTagToSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertTagIntoSubtag: async (
      convertTagToSubTagBody: ConvertTagToSubTagBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'convertTagToSubTagBody' is not null or undefined
      assertParamExists(
        'tagApiConvertTagIntoSubtag',
        'convertTagToSubTagBody',
        convertTagToSubTagBody,
      );
      const localVarPath = `/tag/convert-tag-into-subtag`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        convertTagToSubTagBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiCreateAllMissingGeneralSubTag: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sub_tag/all_missing_general`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagById: async (
      tagId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagApiGetTagById', 'tagId', tagId);
      const localVarPath = `/tag/{tag_id}`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [active]
     * @param {string} [approvalStatus]
     * @param {string} [domainId]
     * @param {string} [ongoing]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagList: async (
      active?: string,
      approvalStatus?: string,
      domainId?: string,
      ongoing?: string,
      searchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      if (approvalStatus !== undefined) {
        localVarQueryParameter['approval_status'] = approvalStatus;
      }

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (ongoing !== undefined) {
        localVarQueryParameter['ongoing'] = ongoing;
      }

      if (searchQuery !== undefined) {
        localVarQueryParameter['search_query'] = searchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagPreference: async (
      tagId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagApiGetTagPreference', 'tagId', tagId);
      const localVarPath = `/tag/{tag_id}/preference`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagStartStatistics: async (
      tagId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagApiGetTagStartStatistics', 'tagId', tagId);
      const localVarPath = `/tag/{tag_id}/start-statistics`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MoveSubtagBody} moveSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiMoveSubtags: async (
      moveSubtagBody: MoveSubtagBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'moveSubtagBody' is not null or undefined
      assertParamExists('tagApiMoveSubtags', 'moveSubtagBody', moveSubtagBody);
      const localVarPath = `/tag/move_sub_tags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        moveSubtagBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiSearchTag: async (
      domainId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {Array<SubtagPreferenceBody>} subtagPreferenceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiSetTagPreference: async (
      tagId: string,
      subtagPreferenceBody: Array<SubtagPreferenceBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagApiSetTagPreference', 'tagId', tagId);
      // verify required parameter 'subtagPreferenceBody' is not null or undefined
      assertParamExists(
        'tagApiSetTagPreference',
        'subtagPreferenceBody',
        subtagPreferenceBody,
      );
      const localVarPath = `/tag/{tag_id}/preference`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        subtagPreferenceBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiStreakProgress: async (
      tagId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagApiStreakProgress', 'tagId', tagId);
      const localVarPath = `/tag/{tag_id}/streak-progress`.replace(
        `{${'tag_id'}}`,
        encodeURIComponent(String(tagId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiTagAutocomplete: async (
      page?: number,
      pageSize?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiTestFunction: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiUpdateSubtagDifficultyLevelsRoute: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag/update_subtag_difficulty_test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiUpdateSubtagQuestionCounts: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tag/update-subtag-question-counts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagApiApi - functional programming interface
 * @export
 */
export const TagApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = TagApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ChangeSubtagBody} changeSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiChangeQuestionSubtags(
      changeSubtagBody: ChangeSubtagBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiChangeQuestionSubtags(
        changeSubtagBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConvertSubTagBody} convertSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiConvertSubtagIntoTag(
      convertSubTagBody: ConvertSubTagBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiConvertSubtagIntoTag(
        convertSubTagBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConvertTagToDomainBody} convertTagToDomainBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiConvertTagIntoDomain(
      convertTagToDomainBody: ConvertTagToDomainBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiConvertTagIntoDomain(
        convertTagToDomainBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConvertTagToSubTagBody} convertTagToSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiConvertTagIntoSubtag(
      convertTagToSubTagBody: ConvertTagToSubTagBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiConvertTagIntoSubtag(
        convertTagToSubTagBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiCreateAllMissingGeneralSubTag(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiCreateAllMissingGeneralSubTag(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiGetTagById(
      tagId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTagByIdResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiGetTagById(
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [active]
     * @param {string} [approvalStatus]
     * @param {string} [domainId]
     * @param {string} [ongoing]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiGetTagList(
      active?: string,
      approvalStatus?: string,
      domainId?: string,
      ongoing?: string,
      searchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAllTagResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiGetTagList(
        active,
        approvalStatus,
        domainId,
        ongoing,
        searchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiGetTagPreference(
      tagId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTagPreferencesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiGetTagPreference(
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiGetTagStartStatistics(
      tagId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStartStatisticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiGetTagStartStatistics(
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MoveSubtagBody} moveSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiMoveSubtags(
      moveSubtagBody: MoveSubtagBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiMoveSubtags(
        moveSubtagBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiSearchTag(
      domainId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetTagResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiSearchTag(
        domainId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {Array<SubtagPreferenceBody>} subtagPreferenceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiSetTagPreference(
      tagId: string,
      subtagPreferenceBody: Array<SubtagPreferenceBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiSetTagPreference(
        tagId,
        subtagPreferenceBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiStreakProgress(
      tagId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStreakProgressResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiStreakProgress(
        tagId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiTagAutocomplete(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAutocompleteTagResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiTagAutocomplete(
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiTestFunction(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTagTestResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiTestFunction(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiUpdateSubtagDifficultyLevelsRoute(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiUpdateSubtagDifficultyLevelsRoute(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagApiUpdateSubtagQuestionCounts(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessTrueResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagApiUpdateSubtagQuestionCounts(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TagApiApi - factory interface
 * @export
 */
export const TagApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TagApiApiFp(configuration);
  return {
    /**
     *
     * @param {ChangeSubtagBody} changeSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiChangeQuestionSubtags(
      changeSubtagBody: ChangeSubtagBody,
      options?: any,
    ): AxiosPromise<SuccessResponse1> {
      return localVarFp
        .tagApiChangeQuestionSubtags(changeSubtagBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConvertSubTagBody} convertSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertSubtagIntoTag(
      convertSubTagBody: ConvertSubTagBody,
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiConvertSubtagIntoTag(convertSubTagBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConvertTagToDomainBody} convertTagToDomainBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertTagIntoDomain(
      convertTagToDomainBody: ConvertTagToDomainBody,
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiConvertTagIntoDomain(convertTagToDomainBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConvertTagToSubTagBody} convertTagToSubTagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiConvertTagIntoSubtag(
      convertTagToSubTagBody: ConvertTagToSubTagBody,
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiConvertTagIntoSubtag(convertTagToSubTagBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiCreateAllMissingGeneralSubTag(
      options?: any,
    ): AxiosPromise<GetTagResponse> {
      return localVarFp
        .tagApiCreateAllMissingGeneralSubTag(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagById(
      tagId: string,
      options?: any,
    ): AxiosPromise<GetTagByIdResponse> {
      return localVarFp
        .tagApiGetTagById(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [active]
     * @param {string} [approvalStatus]
     * @param {string} [domainId]
     * @param {string} [ongoing]
     * @param {string} [searchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagList(
      active?: string,
      approvalStatus?: string,
      domainId?: string,
      ongoing?: string,
      searchQuery?: string,
      options?: any,
    ): AxiosPromise<Array<GetAllTagResponse>> {
      return localVarFp
        .tagApiGetTagList(
          active,
          approvalStatus,
          domainId,
          ongoing,
          searchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagPreference(
      tagId: string,
      options?: any,
    ): AxiosPromise<GetTagPreferencesResponse> {
      return localVarFp
        .tagApiGetTagPreference(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiGetTagStartStatistics(
      tagId: string,
      options?: any,
    ): AxiosPromise<GetStartStatisticsResponse> {
      return localVarFp
        .tagApiGetTagStartStatistics(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MoveSubtagBody} moveSubtagBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiMoveSubtags(
      moveSubtagBody: MoveSubtagBody,
      options?: any,
    ): AxiosPromise<SuccessResponse1> {
      return localVarFp
        .tagApiMoveSubtags(moveSubtagBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiSearchTag(
      domainId?: string,
      options?: any,
    ): AxiosPromise<Array<GetTagResponse>> {
      return localVarFp
        .tagApiSearchTag(domainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {Array<SubtagPreferenceBody>} subtagPreferenceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiSetTagPreference(
      tagId: string,
      subtagPreferenceBody: Array<SubtagPreferenceBody>,
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiSetTagPreference(tagId, subtagPreferenceBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiStreakProgress(
      tagId: string,
      options?: any,
    ): AxiosPromise<GetStreakProgressResponse> {
      return localVarFp
        .tagApiStreakProgress(tagId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiTagAutocomplete(
      page?: number,
      pageSize?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<GetAutocompleteTagResponse> {
      return localVarFp
        .tagApiTagAutocomplete(page, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiTestFunction(options?: any): AxiosPromise<GetTagTestResponse> {
      return localVarFp
        .tagApiTestFunction(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiUpdateSubtagDifficultyLevelsRoute(
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiUpdateSubtagDifficultyLevelsRoute(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagApiUpdateSubtagQuestionCounts(
      options?: any,
    ): AxiosPromise<SuccessTrueResponse> {
      return localVarFp
        .tagApiUpdateSubtagQuestionCounts(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TagApiApi - object-oriented interface
 * @export
 * @class TagApiApi
 * @extends {BaseAPI}
 */
export class TagApiApi extends BaseAPI {
  /**
   *
   * @param {ChangeSubtagBody} changeSubtagBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiChangeQuestionSubtags(
    changeSubtagBody: ChangeSubtagBody,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiChangeQuestionSubtags(changeSubtagBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConvertSubTagBody} convertSubTagBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiConvertSubtagIntoTag(
    convertSubTagBody: ConvertSubTagBody,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiConvertSubtagIntoTag(convertSubTagBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConvertTagToDomainBody} convertTagToDomainBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiConvertTagIntoDomain(
    convertTagToDomainBody: ConvertTagToDomainBody,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiConvertTagIntoDomain(convertTagToDomainBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConvertTagToSubTagBody} convertTagToSubTagBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiConvertTagIntoSubtag(
    convertTagToSubTagBody: ConvertTagToSubTagBody,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiConvertTagIntoSubtag(convertTagToSubTagBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiCreateAllMissingGeneralSubTag(options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiCreateAllMissingGeneralSubTag(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiGetTagById(tagId: string, options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiGetTagById(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [active]
   * @param {string} [approvalStatus]
   * @param {string} [domainId]
   * @param {string} [ongoing]
   * @param {string} [searchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiGetTagList(
    active?: string,
    approvalStatus?: string,
    domainId?: string,
    ongoing?: string,
    searchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiGetTagList(
        active,
        approvalStatus,
        domainId,
        ongoing,
        searchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiGetTagPreference(tagId: string, options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiGetTagPreference(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiGetTagStartStatistics(
    tagId: string,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiGetTagStartStatistics(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MoveSubtagBody} moveSubtagBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiMoveSubtags(
    moveSubtagBody: MoveSubtagBody,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiMoveSubtags(moveSubtagBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiSearchTag(domainId?: string, options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiSearchTag(domainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {Array<SubtagPreferenceBody>} subtagPreferenceBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiSetTagPreference(
    tagId: string,
    subtagPreferenceBody: Array<SubtagPreferenceBody>,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiSetTagPreference(tagId, subtagPreferenceBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiStreakProgress(tagId: string, options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiStreakProgress(tagId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiTagAutocomplete(
    page?: number,
    pageSize?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return TagApiApiFp(this.configuration)
      .tagApiTagAutocomplete(page, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiTestFunction(options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiTestFunction(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiUpdateSubtagDifficultyLevelsRoute(options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiUpdateSubtagDifficultyLevelsRoute(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApiApi
   */
  public tagApiUpdateSubtagQuestionCounts(options?: AxiosRequestConfig) {
    return TagApiApiFp(this.configuration)
      .tagApiUpdateSubtagQuestionCounts(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TokenApiApi - axios parameter creator
 * @export
 */
export const TokenApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {AuthBody} authBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenApiGetToken: async (
      authBody: AuthBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authBody' is not null or undefined
      assertParamExists('tokenApiGetToken', 'authBody', authBody);
      const localVarPath = `/auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenApiGetTokenByUserDetails: async (
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenApiApi - functional programming interface
 * @export
 */
export const TokenApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = TokenApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AuthBody} authBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenApiGetToken(
      authBody: AuthBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthReponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenApiGetToken(
        authBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenApiGetTokenByUserDetails(
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthReponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenApiGetTokenByUserDetails(
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TokenApiApi - factory interface
 * @export
 */
export const TokenApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TokenApiApiFp(configuration);
  return {
    /**
     *
     * @param {AuthBody} authBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenApiGetToken(
      authBody: AuthBody,
      options?: any,
    ): AxiosPromise<AuthReponse> {
      return localVarFp
        .tokenApiGetToken(authBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenApiGetTokenByUserDetails(
      query?: string,
      options?: any,
    ): AxiosPromise<AuthReponse> {
      return localVarFp
        .tokenApiGetTokenByUserDetails(query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TokenApiApi - object-oriented interface
 * @export
 * @class TokenApiApi
 * @extends {BaseAPI}
 */
export class TokenApiApi extends BaseAPI {
  /**
   *
   * @param {AuthBody} authBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApiApi
   */
  public tokenApiGetToken(authBody: AuthBody, options?: AxiosRequestConfig) {
    return TokenApiApiFp(this.configuration)
      .tokenApiGetToken(authBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApiApi
   */
  public tokenApiGetTokenByUserDetails(
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return TokenApiApiFp(this.configuration)
      .tokenApiGetTokenByUserDetails(query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApiApi - axios parameter creator
 * @export
 */
export const UserApiApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} userId
     * @param {AdminUserUpdateBody} adminUserUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiAdminUserUpdate: async (
      userId: string,
      adminUserUpdateBody: AdminUserUpdateBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userApiAdminUserUpdate', 'userId', userId);
      // verify required parameter 'adminUserUpdateBody' is not null or undefined
      assertParamExists(
        'userApiAdminUserUpdate',
        'adminUserUpdateBody',
        adminUserUpdateBody,
      );
      const localVarPath = `/admin/user-update/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUserUpdateBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} educationId
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeEducationDetails: async (
      educationId: string,
      createEducationBody: CreateEducationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'educationId' is not null or undefined
      assertParamExists(
        'userApiChangeEducationDetails',
        'educationId',
        educationId,
      );
      // verify required parameter 'createEducationBody' is not null or undefined
      assertParamExists(
        'userApiChangeEducationDetails',
        'createEducationBody',
        createEducationBody,
      );
      const localVarPath = `/user/education_details/{education_id}`.replace(
        `{${'education_id'}}`,
        encodeURIComponent(String(educationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEducationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeProject: async (
      projectId: string,
      jobProfileProject: JobProfileProject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('userApiChangeProject', 'projectId', projectId);
      // verify required parameter 'jobProfileProject' is not null or undefined
      assertParamExists(
        'userApiChangeProject',
        'jobProfileProject',
        jobProfileProject,
      );
      const localVarPath = `/user/project/{project_id}`.replace(
        `{${'project_id'}}`,
        encodeURIComponent(String(projectId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobProfileProject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChangePasswordBody} changePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeUserPassword: async (
      changePasswordBody: ChangePasswordBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePasswordBody' is not null or undefined
      assertParamExists(
        'userApiChangeUserPassword',
        'changePasswordBody',
        changePasswordBody,
      );
      const localVarPath = `/user/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} workId
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeWorkDetails: async (
      workId: string,
      createWorkExperienceBody: CreateWorkExperienceBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workId' is not null or undefined
      assertParamExists('userApiChangeWorkDetails', 'workId', workId);
      // verify required parameter 'createWorkExperienceBody' is not null or undefined
      assertParamExists(
        'userApiChangeWorkDetails',
        'createWorkExperienceBody',
        createWorkExperienceBody,
      );
      const localVarPath = `/user/work_details/{work_id}`.replace(
        `{${'work_id'}}`,
        encodeURIComponent(String(workId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkExperienceBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCompanyAutocomplete: async (
      page?: string,
      pageSize?: string,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/company-autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateJobRoleBody} createJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCreateJobRole: async (
      createJobRoleBody: CreateJobRoleBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createJobRoleBody' is not null or undefined
      assertParamExists(
        'userApiCreateJobRole',
        'createJobRoleBody',
        createJobRoleBody,
      );
      const localVarPath = `/admin/job-role`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createJobRoleBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateUserBody} createUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCreateUser: async (
      createUserBody: CreateUserBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserBody' is not null or undefined
      assertParamExists('userApiCreateUser', 'createUserBody', createUserBody);
      const localVarPath = `/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteCollege: async (
      bulkDeleteBody1: BulkDeleteBody1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody1' is not null or undefined
      assertParamExists(
        'userApiDeleteCollege',
        'bulkDeleteBody1',
        bulkDeleteBody1,
      );
      const localVarPath = `/admin/college/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteCompany: async (
      bulkDeleteBody1: BulkDeleteBody1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody1' is not null or undefined
      assertParamExists(
        'userApiDeleteCompany',
        'bulkDeleteBody1',
        bulkDeleteBody1,
      );
      const localVarPath = `/admin/company/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} educationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteEducationDetails: async (
      educationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'educationId' is not null or undefined
      assertParamExists(
        'userApiDeleteEducationDetails',
        'educationId',
        educationId,
      );
      const localVarPath = `/user/education_details/{education_id}`.replace(
        `{${'education_id'}}`,
        encodeURIComponent(String(educationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteJobRole: async (
      bulkDeleteBody1: BulkDeleteBody1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bulkDeleteBody1' is not null or undefined
      assertParamExists(
        'userApiDeleteJobRole',
        'bulkDeleteBody1',
        bulkDeleteBody1,
      );
      const localVarPath = `/admin/job-role/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteBody1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteProject: async (
      projectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists('userApiDeleteProject', 'projectId', projectId);
      const localVarPath = `/user/project/{project_id}`.replace(
        `{${'project_id'}}`,
        encodeURIComponent(String(projectId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} socialLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteSocialLink: async (
      socialLinkId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'socialLinkId' is not null or undefined
      assertParamExists(
        'userApiDeleteSocialLink',
        'socialLinkId',
        socialLinkId,
      );
      const localVarPath = `/user/social_links/{social_link_id}`.replace(
        `{${'social_link_id'}}`,
        encodeURIComponent(String(socialLinkId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteUserCompletely: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userApiDeleteUserCompletely', 'userId', userId);
      const localVarPath = `/delete-user-completely/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} workId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteWorkDetails: async (
      workId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workId' is not null or undefined
      assertParamExists('userApiDeleteWorkDetails', 'workId', workId);
      const localVarPath = `/user/work_details/{work_id}`.replace(
        `{${'work_id'}}`,
        encodeURIComponent(String(workId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiEducationDetails: async (
      createEducationBody: CreateEducationBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEducationBody' is not null or undefined
      assertParamExists(
        'userApiEducationDetails',
        'createEducationBody',
        createEducationBody,
      );
      const localVarPath = `/user/education_details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEducationBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiEmailActivation: async (
      sendEmailOtpBody: SendEmailOtpBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailOtpBody' is not null or undefined
      assertParamExists(
        'userApiEmailActivation',
        'sendEmailOtpBody',
        sendEmailOtpBody,
      );
      const localVarPath = `/users/send-email-verify-otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailOtpBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [jobRoleId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetJobRole: async (
      jobRoleId?: string,
      pageNo?: string,
      pageSize?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/job-role`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (jobRoleId !== undefined) {
        localVarQueryParameter['job_role_id'] = jobRoleId;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (userSearchQuery !== undefined) {
        localVarQueryParameter['user_search_query'] = userSearchQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserAnalytics: async (
      domainId?: string,
      onlyAttempted?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/analytics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (domainId !== undefined) {
        localVarQueryParameter['domain_id'] = domainId;
      }

      if (onlyAttempted !== undefined) {
        localVarQueryParameter['only_attempted'] = onlyAttempted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserDetails: async (
      email?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (email !== undefined) {
        localVarQueryParameter['email'] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserLoginStreakDetails: async (
      endDate?: string,
      startDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/login-streak`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substr(0, 10)
            : endDate;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserPublicProfile: async (
      username: string,
      onlyAttempted?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('userApiGetUserPublicProfile', 'username', username);
      const localVarPath = `/user/{username}/profile_public`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (onlyAttempted !== undefined) {
        localVarQueryParameter['only_attempted'] = onlyAttempted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserSelf: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/self`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiIsUsernameAvailable: async (
      username: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('userApiIsUsernameAvailable', 'username', username);
      const localVarPath = `/user/username/{username}/is_available`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiJobRoleAutoComplete: async (
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job-role/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageNo !== undefined) {
        localVarQueryParameter['page_no'] = pageNo;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OnboardingDoneBody} onboardingDoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiOnboardingDone: async (
      onboardingDoneBody: OnboardingDoneBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'onboardingDoneBody' is not null or undefined
      assertParamExists(
        'userApiOnboardingDone',
        'onboardingDoneBody',
        onboardingDoneBody,
      );
      const localVarPath = `/user/onboarding-done`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        onboardingDoneBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiProject: async (
      jobProfileProject: JobProfileProject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobProfileProject' is not null or undefined
      assertParamExists(
        'userApiProject',
        'jobProfileProject',
        jobProfileProject,
      );
      const localVarPath = `/user/project`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jobProfileProject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResetUserCompletely: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('userApiResetUserCompletely', 'userId', userId);
      const localVarPath = `/reset-user-completely/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResetPasswordBody} resetPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResetUserPassword: async (
      resetPasswordBody: ResetPasswordBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordBody' is not null or undefined
      assertParamExists(
        'userApiResetUserPassword',
        'resetPasswordBody',
        resetPasswordBody,
      );
      const localVarPath = `/users/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResumeDetails: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/resume_details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiSendPasswordResetOtp: async (
      sendEmailOtpBody: SendEmailOtpBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendEmailOtpBody' is not null or undefined
      assertParamExists(
        'userApiSendPasswordResetOtp',
        'sendEmailOtpBody',
        sendEmailOtpBody,
      );
      const localVarPath = `/users/send-password-reset-otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendEmailOtpBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SetNewPasswordBody} setNewPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiSetNewPassword: async (
      setNewPasswordBody: SetNewPasswordBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setNewPasswordBody' is not null or undefined
      assertParamExists(
        'userApiSetNewPassword',
        'setNewPasswordBody',
        setNewPasswordBody,
      );
      const localVarPath = `/user/set-new-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setNewPasswordBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<UpdateJobRoleBody>} updateJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateJobRole: async (
      updateJobRoleBody: Array<UpdateJobRoleBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateJobRoleBody' is not null or undefined
      assertParamExists(
        'userApiUpdateJobRole',
        'updateJobRoleBody',
        updateJobRoleBody,
      );
      const localVarPath = `/admin/job-role`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateJobRoleBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateUserEmailBody} updateUserEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateUserEmail: async (
      updateUserEmailBody: UpdateUserEmailBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserEmailBody' is not null or undefined
      assertParamExists(
        'userApiUpdateUserEmail',
        'updateUserEmailBody',
        updateUserEmailBody,
      );
      const localVarPath = `/user/update-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserEmailBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateUserBody} updateUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateUserProfile: async (
      updateUserBody: UpdateUserBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserBody' is not null or undefined
      assertParamExists(
        'userApiUpdateUserProfile',
        'updateUserBody',
        updateUserBody,
      );
      const localVarPath = `/user-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUploadUserProfilePicture: async (
      picture: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'picture' is not null or undefined
      assertParamExists('userApiUploadUserProfilePicture', 'picture', picture);
      const localVarPath = `/user-profile/picture`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (picture !== undefined) {
        localVarFormParams.append('picture', picture as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<SocialLinksBody>} socialLinksBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpsertSocialLinks: async (
      socialLinksBody: Array<SocialLinksBody>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'socialLinksBody' is not null or undefined
      assertParamExists(
        'userApiUpsertSocialLinks',
        'socialLinksBody',
        socialLinksBody,
      );
      const localVarPath = `/user/social_links`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        socialLinksBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyEmailOtpBody} verifyEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiVerifyEmailOtp: async (
      verifyEmailOtpBody: VerifyEmailOtpBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmailOtpBody' is not null or undefined
      assertParamExists(
        'userApiVerifyEmailOtp',
        'verifyEmailOtpBody',
        verifyEmailOtpBody,
      );
      const localVarPath = `/users/email-verify-otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyEmailOtpBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiWorkDetails: async (
      createWorkExperienceBody: CreateWorkExperienceBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWorkExperienceBody' is not null or undefined
      assertParamExists(
        'userApiWorkDetails',
        'createWorkExperienceBody',
        createWorkExperienceBody,
      );
      const localVarPath = `/user/work_details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication token_auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkExperienceBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApiApi - functional programming interface
 * @export
 */
export const UserApiApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} userId
     * @param {AdminUserUpdateBody} adminUserUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiAdminUserUpdate(
      userId: string,
      adminUserUpdateBody: AdminUserUpdateBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminUserUpdateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiAdminUserUpdate(
        userId,
        adminUserUpdateBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} educationId
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiChangeEducationDetails(
      educationId: string,
      createEducationBody: CreateEducationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateEducationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiChangeEducationDetails(
        educationId,
        createEducationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiChangeProject(
      projectId: string,
      jobProfileProject: JobProfileProject,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateProjectResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiChangeProject(
        projectId,
        jobProfileProject,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ChangePasswordBody} changePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiChangeUserPassword(
      changePasswordBody: ChangePasswordBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiChangeUserPassword(
        changePasswordBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} workId
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiChangeWorkDetails(
      workId: string,
      createWorkExperienceBody: CreateWorkExperienceBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkExperienceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiChangeWorkDetails(
        workId,
        createWorkExperienceBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiCompanyAutocomplete(
      page?: string,
      pageSize?: string,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompanyAutocompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiCompanyAutocomplete(
        page,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateJobRoleBody} createJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiCreateJobRole(
      createJobRoleBody: CreateJobRoleBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateJobRoleResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiCreateJobRole(
        createJobRoleBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateUserBody} createUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiCreateUser(
      createUserBody: CreateUserBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiCreateUser(
        createUserBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteCollege(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkDeleteResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteCollege(
        bulkDeleteBody1,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteCompany(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkCompanyDeleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteCompany(
        bulkDeleteBody1,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} educationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteEducationDetails(
      educationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteEducationDetails(
        educationId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteJobRole(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BulkDeleteResponse1>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteJobRole(
        bulkDeleteBody1,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteProject(
      projectId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteProject(
        projectId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} socialLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteSocialLink(
      socialLinkId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteSocialLink(
        socialLinkId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteUserCompletely(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteUserCompletely(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} workId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiDeleteWorkDetails(
      workId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiDeleteWorkDetails(
        workId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiEducationDetails(
      createEducationBody: CreateEducationBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateEducationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiEducationDetails(
        createEducationBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiEmailActivation(
      sendEmailOtpBody: SendEmailOtpBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendEmailOtpRepsonse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiEmailActivation(
        sendEmailOtpBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [jobRoleId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetJobRole(
      jobRoleId?: string,
      pageNo?: string,
      pageSize?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetJobRolesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetJobRole(
        jobRoleId,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetUserAnalytics(
      domainId?: string,
      onlyAttempted?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnalyticsResponseSub>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetUserAnalytics(
        domainId,
        onlyAttempted,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetUserDetails(
      email?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserDetailByEmailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetUserDetails(
        email,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetUserLoginStreakDetails(
      endDate?: string,
      startDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LoginStreakResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetUserLoginStreakDetails(
        endDate,
        startDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} username
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetUserPublicProfile(
      username: string,
      onlyAttempted?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicProfileAnalyticsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetUserPublicProfile(
        username,
        onlyAttempted,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiGetUserSelf(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiGetUserSelf(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiIsUsernameAvailable(
      username: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IsUserAvailableResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiIsUsernameAvailable(
        username,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiJobRoleAutoComplete(
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobRoleAutocompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiJobRoleAutoComplete(
        pageNo,
        pageSize,
        query,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {OnboardingDoneBody} onboardingDoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiOnboardingDone(
      onboardingDoneBody: OnboardingDoneBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OnboardingDoneResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiOnboardingDone(
        onboardingDoneBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiProject(
      jobProfileProject: JobProfileProject,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateProjectResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiProject(
        jobProfileProject,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiResetUserCompletely(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiResetUserCompletely(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ResetPasswordBody} resetPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiResetUserPassword(
      resetPasswordBody: ResetPasswordBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResetPasswordResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiResetUserPassword(
        resetPasswordBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiResumeDetails(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResumeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiResumeDetails(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiSendPasswordResetOtp(
      sendEmailOtpBody: SendEmailOtpBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendResetPasswordResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiSendPasswordResetOtp(
        sendEmailOtpBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SetNewPasswordBody} setNewPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiSetNewPassword(
      setNewPasswordBody: SetNewPasswordBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiSetNewPassword(
        setNewPasswordBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<UpdateJobRoleBody>} updateJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiUpdateJobRole(
      updateJobRoleBody: Array<UpdateJobRoleBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateJobRoleResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiUpdateJobRole(
        updateJobRoleBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateUserEmailBody} updateUserEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiUpdateUserEmail(
      updateUserEmailBody: UpdateUserEmailBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SuccessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiUpdateUserEmail(
        updateUserEmailBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateUserBody} updateUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiUpdateUserProfile(
      updateUserBody: UpdateUserBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiUpdateUserProfile(
        updateUserBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {any} picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiUploadUserProfilePicture(
      picture: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ProfilePictureResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiUploadUserProfilePicture(
        picture,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<SocialLinksBody>} socialLinksBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiUpsertSocialLinks(
      socialLinksBody: Array<SocialLinksBody>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SocialLinksResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiUpsertSocialLinks(
        socialLinksBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {VerifyEmailOtpBody} verifyEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiVerifyEmailOtp(
      verifyEmailOtpBody: VerifyEmailOtpBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VerifyEmailOtpResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiVerifyEmailOtp(
        verifyEmailOtpBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userApiWorkDetails(
      createWorkExperienceBody: CreateWorkExperienceBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkExperienceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userApiWorkDetails(
        createWorkExperienceBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserApiApi - factory interface
 * @export
 */
export const UserApiApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiApiFp(configuration);
  return {
    /**
     *
     * @param {string} userId
     * @param {AdminUserUpdateBody} adminUserUpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiAdminUserUpdate(
      userId: string,
      adminUserUpdateBody: AdminUserUpdateBody,
      options?: any,
    ): AxiosPromise<AdminUserUpdateResponse> {
      return localVarFp
        .userApiAdminUserUpdate(userId, adminUserUpdateBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} educationId
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeEducationDetails(
      educationId: string,
      createEducationBody: CreateEducationBody,
      options?: any,
    ): AxiosPromise<CreateEducationResponse> {
      return localVarFp
        .userApiChangeEducationDetails(
          educationId,
          createEducationBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeProject(
      projectId: string,
      jobProfileProject: JobProfileProject,
      options?: any,
    ): AxiosPromise<CreateProjectResponse> {
      return localVarFp
        .userApiChangeProject(projectId, jobProfileProject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ChangePasswordBody} changePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeUserPassword(
      changePasswordBody: ChangePasswordBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiChangeUserPassword(changePasswordBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} workId
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiChangeWorkDetails(
      workId: string,
      createWorkExperienceBody: CreateWorkExperienceBody,
      options?: any,
    ): AxiosPromise<WorkExperienceResponse> {
      return localVarFp
        .userApiChangeWorkDetails(workId, createWorkExperienceBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [page]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCompanyAutocomplete(
      page?: string,
      pageSize?: string,
      query?: string,
      options?: any,
    ): AxiosPromise<CompanyAutocompleteResponse> {
      return localVarFp
        .userApiCompanyAutocomplete(page, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateJobRoleBody} createJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCreateJobRole(
      createJobRoleBody: CreateJobRoleBody,
      options?: any,
    ): AxiosPromise<CreateJobRoleResponse> {
      return localVarFp
        .userApiCreateJobRole(createJobRoleBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateUserBody} createUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiCreateUser(
      createUserBody: CreateUserBody,
      options?: any,
    ): AxiosPromise<CreateUserResponse> {
      return localVarFp
        .userApiCreateUser(createUserBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteCollege(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: any,
    ): AxiosPromise<BulkDeleteResponse1> {
      return localVarFp
        .userApiDeleteCollege(bulkDeleteBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteCompany(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: any,
    ): AxiosPromise<BulkCompanyDeleteResponse> {
      return localVarFp
        .userApiDeleteCompany(bulkDeleteBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} educationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteEducationDetails(
      educationId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiDeleteEducationDetails(educationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BulkDeleteBody1} bulkDeleteBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteJobRole(
      bulkDeleteBody1: BulkDeleteBody1,
      options?: any,
    ): AxiosPromise<BulkDeleteResponse1> {
      return localVarFp
        .userApiDeleteJobRole(bulkDeleteBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteProject(
      projectId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiDeleteProject(projectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} socialLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteSocialLink(
      socialLinkId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiDeleteSocialLink(socialLinkId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteUserCompletely(
      userId: string,
      options?: any,
    ): AxiosPromise<DeleteUserResponse> {
      return localVarFp
        .userApiDeleteUserCompletely(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} workId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiDeleteWorkDetails(
      workId: string,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiDeleteWorkDetails(workId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateEducationBody} createEducationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiEducationDetails(
      createEducationBody: CreateEducationBody,
      options?: any,
    ): AxiosPromise<CreateEducationResponse> {
      return localVarFp
        .userApiEducationDetails(createEducationBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiEmailActivation(
      sendEmailOtpBody: SendEmailOtpBody,
      options?: any,
    ): AxiosPromise<SendEmailOtpRepsonse> {
      return localVarFp
        .userApiEmailActivation(sendEmailOtpBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [jobRoleId]
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [sortBy]
     * @param {string} [sortOrder]
     * @param {string} [status]
     * @param {string} [userSearchQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetJobRole(
      jobRoleId?: string,
      pageNo?: string,
      pageSize?: string,
      sortBy?: string,
      sortOrder?: string,
      status?: string,
      userSearchQuery?: string,
      options?: any,
    ): AxiosPromise<GetJobRolesResponse> {
      return localVarFp
        .userApiGetJobRole(
          jobRoleId,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          status,
          userSearchQuery,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [domainId]
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserAnalytics(
      domainId?: string,
      onlyAttempted?: string,
      options?: any,
    ): AxiosPromise<AnalyticsResponseSub> {
      return localVarFp
        .userApiGetUserAnalytics(domainId, onlyAttempted, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserDetails(
      email?: string,
      options?: any,
    ): AxiosPromise<GetUserDetailByEmailResponse> {
      return localVarFp
        .userApiGetUserDetails(email, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [endDate]
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserLoginStreakDetails(
      endDate?: string,
      startDate?: string,
      options?: any,
    ): AxiosPromise<LoginStreakResponse> {
      return localVarFp
        .userApiGetUserLoginStreakDetails(endDate, startDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {string} [onlyAttempted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserPublicProfile(
      username: string,
      onlyAttempted?: string,
      options?: any,
    ): AxiosPromise<PublicProfileAnalyticsResponse> {
      return localVarFp
        .userApiGetUserPublicProfile(username, onlyAttempted, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiGetUserSelf(options?: any): AxiosPromise<UserResponse> {
      return localVarFp
        .userApiGetUserSelf(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiIsUsernameAvailable(
      username: string,
      options?: any,
    ): AxiosPromise<IsUserAvailableResponse> {
      return localVarFp
        .userApiIsUsernameAvailable(username, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [pageNo]
     * @param {string} [pageSize]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiJobRoleAutoComplete(
      pageNo?: string,
      pageSize?: string,
      query?: string,
      options?: any,
    ): AxiosPromise<JobRoleAutocompleteResponse> {
      return localVarFp
        .userApiJobRoleAutoComplete(pageNo, pageSize, query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OnboardingDoneBody} onboardingDoneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiOnboardingDone(
      onboardingDoneBody: OnboardingDoneBody,
      options?: any,
    ): AxiosPromise<OnboardingDoneResponse> {
      return localVarFp
        .userApiOnboardingDone(onboardingDoneBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {JobProfileProject} jobProfileProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiProject(
      jobProfileProject: JobProfileProject,
      options?: any,
    ): AxiosPromise<CreateProjectResponse> {
      return localVarFp
        .userApiProject(jobProfileProject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResetUserCompletely(
      userId: string,
      options?: any,
    ): AxiosPromise<Array<UserResponse>> {
      return localVarFp
        .userApiResetUserCompletely(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResetPasswordBody} resetPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResetUserPassword(
      resetPasswordBody: ResetPasswordBody,
      options?: any,
    ): AxiosPromise<ResetPasswordResponse> {
      return localVarFp
        .userApiResetUserPassword(resetPasswordBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiResumeDetails(options?: any): AxiosPromise<ResumeResponse> {
      return localVarFp
        .userApiResumeDetails(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SendEmailOtpBody} sendEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiSendPasswordResetOtp(
      sendEmailOtpBody: SendEmailOtpBody,
      options?: any,
    ): AxiosPromise<SendResetPasswordResponse> {
      return localVarFp
        .userApiSendPasswordResetOtp(sendEmailOtpBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SetNewPasswordBody} setNewPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiSetNewPassword(
      setNewPasswordBody: SetNewPasswordBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiSetNewPassword(setNewPasswordBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<UpdateJobRoleBody>} updateJobRoleBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateJobRole(
      updateJobRoleBody: Array<UpdateJobRoleBody>,
      options?: any,
    ): AxiosPromise<UpdateJobRoleResponse> {
      return localVarFp
        .userApiUpdateJobRole(updateJobRoleBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateUserEmailBody} updateUserEmailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateUserEmail(
      updateUserEmailBody: UpdateUserEmailBody,
      options?: any,
    ): AxiosPromise<SuccessResponse> {
      return localVarFp
        .userApiUpdateUserEmail(updateUserEmailBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateUserBody} updateUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpdateUserProfile(
      updateUserBody: UpdateUserBody,
      options?: any,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .userApiUpdateUserProfile(updateUserBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUploadUserProfilePicture(
      picture: any,
      options?: any,
    ): AxiosPromise<ProfilePictureResponse> {
      return localVarFp
        .userApiUploadUserProfilePicture(picture, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<SocialLinksBody>} socialLinksBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiUpsertSocialLinks(
      socialLinksBody: Array<SocialLinksBody>,
      options?: any,
    ): AxiosPromise<Array<SocialLinksResponse>> {
      return localVarFp
        .userApiUpsertSocialLinks(socialLinksBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyEmailOtpBody} verifyEmailOtpBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiVerifyEmailOtp(
      verifyEmailOtpBody: VerifyEmailOtpBody,
      options?: any,
    ): AxiosPromise<VerifyEmailOtpResponse> {
      return localVarFp
        .userApiVerifyEmailOtp(verifyEmailOtpBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateWorkExperienceBody} createWorkExperienceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userApiWorkDetails(
      createWorkExperienceBody: CreateWorkExperienceBody,
      options?: any,
    ): AxiosPromise<WorkExperienceResponse> {
      return localVarFp
        .userApiWorkDetails(createWorkExperienceBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApiApi - object-oriented interface
 * @export
 * @class UserApiApi
 * @extends {BaseAPI}
 */
export class UserApiApi extends BaseAPI {
  /**
   *
   * @param {string} userId
   * @param {AdminUserUpdateBody} adminUserUpdateBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiAdminUserUpdate(
    userId: string,
    adminUserUpdateBody: AdminUserUpdateBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiAdminUserUpdate(userId, adminUserUpdateBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} educationId
   * @param {CreateEducationBody} createEducationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiChangeEducationDetails(
    educationId: string,
    createEducationBody: CreateEducationBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiChangeEducationDetails(educationId, createEducationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} projectId
   * @param {JobProfileProject} jobProfileProject
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiChangeProject(
    projectId: string,
    jobProfileProject: JobProfileProject,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiChangeProject(projectId, jobProfileProject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ChangePasswordBody} changePasswordBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiChangeUserPassword(
    changePasswordBody: ChangePasswordBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiChangeUserPassword(changePasswordBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} workId
   * @param {CreateWorkExperienceBody} createWorkExperienceBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiChangeWorkDetails(
    workId: string,
    createWorkExperienceBody: CreateWorkExperienceBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiChangeWorkDetails(workId, createWorkExperienceBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [page]
   * @param {string} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiCompanyAutocomplete(
    page?: string,
    pageSize?: string,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiCompanyAutocomplete(page, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateJobRoleBody} createJobRoleBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiCreateJobRole(
    createJobRoleBody: CreateJobRoleBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiCreateJobRole(createJobRoleBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateUserBody} createUserBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiCreateUser(
    createUserBody: CreateUserBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiCreateUser(createUserBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody1} bulkDeleteBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteCollege(
    bulkDeleteBody1: BulkDeleteBody1,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteCollege(bulkDeleteBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody1} bulkDeleteBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteCompany(
    bulkDeleteBody1: BulkDeleteBody1,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteCompany(bulkDeleteBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} educationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteEducationDetails(
    educationId: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteEducationDetails(educationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BulkDeleteBody1} bulkDeleteBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteJobRole(
    bulkDeleteBody1: BulkDeleteBody1,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteJobRole(bulkDeleteBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteProject(projectId: string, options?: AxiosRequestConfig) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteProject(projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} socialLinkId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteSocialLink(
    socialLinkId: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteSocialLink(socialLinkId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteUserCompletely(
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteUserCompletely(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} workId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiDeleteWorkDetails(
    workId: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiDeleteWorkDetails(workId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateEducationBody} createEducationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiEducationDetails(
    createEducationBody: CreateEducationBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiEducationDetails(createEducationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SendEmailOtpBody} sendEmailOtpBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiEmailActivation(
    sendEmailOtpBody: SendEmailOtpBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiEmailActivation(sendEmailOtpBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [jobRoleId]
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [sortBy]
   * @param {string} [sortOrder]
   * @param {string} [status]
   * @param {string} [userSearchQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetJobRole(
    jobRoleId?: string,
    pageNo?: string,
    pageSize?: string,
    sortBy?: string,
    sortOrder?: string,
    status?: string,
    userSearchQuery?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiGetJobRole(
        jobRoleId,
        pageNo,
        pageSize,
        sortBy,
        sortOrder,
        status,
        userSearchQuery,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [domainId]
   * @param {string} [onlyAttempted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetUserAnalytics(
    domainId?: string,
    onlyAttempted?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiGetUserAnalytics(domainId, onlyAttempted, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [email]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetUserDetails(email?: string, options?: AxiosRequestConfig) {
    return UserApiApiFp(this.configuration)
      .userApiGetUserDetails(email, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [endDate]
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetUserLoginStreakDetails(
    endDate?: string,
    startDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiGetUserLoginStreakDetails(endDate, startDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} username
   * @param {string} [onlyAttempted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetUserPublicProfile(
    username: string,
    onlyAttempted?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiGetUserPublicProfile(username, onlyAttempted, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiGetUserSelf(options?: AxiosRequestConfig) {
    return UserApiApiFp(this.configuration)
      .userApiGetUserSelf(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} username
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiIsUsernameAvailable(
    username: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiIsUsernameAvailable(username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [pageNo]
   * @param {string} [pageSize]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiJobRoleAutoComplete(
    pageNo?: string,
    pageSize?: string,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiJobRoleAutoComplete(pageNo, pageSize, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {OnboardingDoneBody} onboardingDoneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiOnboardingDone(
    onboardingDoneBody: OnboardingDoneBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiOnboardingDone(onboardingDoneBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {JobProfileProject} jobProfileProject
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiProject(
    jobProfileProject: JobProfileProject,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiProject(jobProfileProject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiResetUserCompletely(
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiResetUserCompletely(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ResetPasswordBody} resetPasswordBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiResetUserPassword(
    resetPasswordBody: ResetPasswordBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiResetUserPassword(resetPasswordBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiResumeDetails(options?: AxiosRequestConfig) {
    return UserApiApiFp(this.configuration)
      .userApiResumeDetails(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SendEmailOtpBody} sendEmailOtpBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiSendPasswordResetOtp(
    sendEmailOtpBody: SendEmailOtpBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiSendPasswordResetOtp(sendEmailOtpBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SetNewPasswordBody} setNewPasswordBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiSetNewPassword(
    setNewPasswordBody: SetNewPasswordBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiSetNewPassword(setNewPasswordBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<UpdateJobRoleBody>} updateJobRoleBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiUpdateJobRole(
    updateJobRoleBody: Array<UpdateJobRoleBody>,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiUpdateJobRole(updateJobRoleBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateUserEmailBody} updateUserEmailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiUpdateUserEmail(
    updateUserEmailBody: UpdateUserEmailBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiUpdateUserEmail(updateUserEmailBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateUserBody} updateUserBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiUpdateUserProfile(
    updateUserBody: UpdateUserBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiUpdateUserProfile(updateUserBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {any} picture
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiUploadUserProfilePicture(
    picture: any,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiUploadUserProfilePicture(picture, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<SocialLinksBody>} socialLinksBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiUpsertSocialLinks(
    socialLinksBody: Array<SocialLinksBody>,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiUpsertSocialLinks(socialLinksBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VerifyEmailOtpBody} verifyEmailOtpBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiVerifyEmailOtp(
    verifyEmailOtpBody: VerifyEmailOtpBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiVerifyEmailOtp(verifyEmailOtpBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateWorkExperienceBody} createWorkExperienceBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiApi
   */
  public userApiWorkDetails(
    createWorkExperienceBody: CreateWorkExperienceBody,
    options?: AxiosRequestConfig,
  ) {
    return UserApiApiFp(this.configuration)
      .userApiWorkDetails(createWorkExperienceBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
