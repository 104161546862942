import createReducer from '../lib/createReducer';
import {
  CLEAR_EMAILING_LIST_STATE,
  CREATE_EMAIL_TEMPLATE_START,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_FAIL,
  DELETE_EMAIL_TEMPLATE_START,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_LOGS_LIST_FAIL,
  GET_EMAIL_LOGS_LIST_START,
  GET_EMAIL_LOGS_LIST_SUCCESS,
  GET_EMAIL_TEMPLATE_BY_ID_FAIL,
  GET_EMAIL_TEMPLATE_BY_ID_START,
  GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
  GET_EMAIL_TEMPLATE_LIST_FAIL,
  GET_EMAIL_TEMPLATE_LIST_START,
  GET_EMAIL_TEMPLATE_LIST_SUCCESS,
  GET_SCHEDULED_EMAILS_LIST_FAIL,
  GET_SCHEDULED_EMAILS_LIST_START,
  GET_SCHEDULED_EMAILS_LIST_SUCCESS,
  POST_EMAIL_ATTACHMENT_FAIL,
  POST_EMAIL_ATTACHMENT_START,
  POST_EMAIL_ATTACHMENT_SUCCESS,
  REMOVE_EMAILING_LIST_ATTACHMENT,
  SEND_EMAIL_FAIL,
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  UPDATE_EMAIL_TEMPLATE_START,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
} from '../actions/types';

const initialState = {
  fetchingTemplates: false,
  templates: [],
  fetchingSelectedTemplate: false,
  selectedTemplate: null,
  postingEmailAttachment: false,
  updatingTemplate: false,
  uploadedAttachmentUrls: [],
  deletingTemplate: false,
  error: null,
  isSendingEmail: false,
  emailSentData: {},
  scheduledEmailsData: {},
  emailLogData: {},
};

export const emailingList = createReducer(initialState, {
  [GET_EMAIL_TEMPLATE_LIST_START](state) {
    return {
      ...state,
      fetchingTemplates: true,
      emailSentData: {},
    };
  },
  [GET_EMAIL_TEMPLATE_LIST_SUCCESS](state, action) {
    return {
      ...state,
      fetchingTemplates: false,
      templates: action.data.templates,
    };
  },
  [GET_EMAIL_TEMPLATE_LIST_FAIL](state, action) {
    return {
      ...state,
      fetchingTemplates: false,
      error: action.data,
    };
  },
  [GET_SCHEDULED_EMAILS_LIST_START](state) {
    return {
      ...state,
      isFetchingScheduledMails: true,
      scheduledEmailsData: {},
    };
  },
  [GET_SCHEDULED_EMAILS_LIST_SUCCESS](state, action) {
    return {
      ...state,
      isFetchingScheduledMails: false,
      scheduledEmailsData: action.data,
    };
  },
  [GET_SCHEDULED_EMAILS_LIST_FAIL](state) {
    return {
      ...state,
      isFetchingScheduledMails: false,
      scheduledEmailsData: {},
    };
  },
  [GET_EMAIL_LOGS_LIST_START](state) {
    return {
      ...state,
      isFetchingMailLogs: true,
      emailLogData: {},
    };
  },
  [GET_EMAIL_LOGS_LIST_SUCCESS](state, action) {
    return {
      ...state,
      isFetchingMailLogs: false,
      emailLogData: action.data,
    };
  },
  [GET_EMAIL_LOGS_LIST_FAIL](state) {
    return {
      ...state,
      isFetchingMailLogs: false,
      emailLogData: {},
    };
  },
  [GET_EMAIL_TEMPLATE_BY_ID_START](state) {
    return {
      ...state,
      fetchingSelectedTemplate: true,
    };
  },
  [GET_EMAIL_TEMPLATE_BY_ID_SUCCESS](state, action) {
    return {
      ...state,
      fetchingSelectedTemplate: false,
      selectedTemplate: action.data,
    };
  },
  [GET_EMAIL_TEMPLATE_BY_ID_FAIL](state, action) {
    return {
      ...state,
      fetchingSelectedTemplate: false,
      error: action.data,
    };
  },
  [POST_EMAIL_ATTACHMENT_START](state) {
    return {
      ...state,
      postingEmailAttachment: true,
    };
  },
  [POST_EMAIL_ATTACHMENT_SUCCESS](state, action) {
    return {
      ...state,
      postingEmailAttachment: false,
      uploadedAttachmentUrls: [...state.uploadedAttachmentUrls, action.data],
    };
  },
  [POST_EMAIL_ATTACHMENT_FAIL](state, action) {
    return {
      ...state,
      postingEmailAttachment: false,
      error: action.data,
    };
  },
  [SEND_EMAIL_START](state) {
    return {
      ...state,
      isSendingEmail: true,
    };
  },
  [SEND_EMAIL_SUCCESS](state, action) {
    return {
      ...state,
      isSendingEmail: false,
      emailSentData: action.data,
    };
  },
  [SEND_EMAIL_FAIL](state, action) {
    return {
      ...state,
      isSendingEmail: false,
      error: action.data,
    };
  },
  [REMOVE_EMAILING_LIST_ATTACHMENT](state, action) {
    const { attachmentType, index } = action.data;
    if (attachmentType === 1) {
      const { uploadedAttachmentUrls } = state;
      return {
        ...state,
        uploadedAttachmentUrls: [
          ...uploadedAttachmentUrls.slice(0, index),
          ...uploadedAttachmentUrls.slice(index + 1),
        ],
      };
    } else if (attachmentType === 2) {
      const signedAttachmentUrls =
        state.selectedTemplate?.attachment_urls.signed;
      const unsignedAttachmentUrls =
        state.selectedTemplate?.attachment_urls.signed;
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          attachment_urls: {
            signed: [
              ...signedAttachmentUrls.slice(0, index),
              ...signedAttachmentUrls.slice(index + 1),
            ],
            unsigned: [
              ...unsignedAttachmentUrls.slice(0, index),
              ...unsignedAttachmentUrls.slice(index + 1),
            ],
          },
        },
      };
    } else {
      return state;
    }
  },

  [CREATE_EMAIL_TEMPLATE_START](state) {
    return {
      ...state,
    };
  },

  [CREATE_EMAIL_TEMPLATE_SUCCESS](state, action) {
    return {
      ...state,
      templates: [...state.templates, action.data],
    };
  },

  [UPDATE_EMAIL_TEMPLATE_START](state) {
    return {
      ...state,
      updatingTemplate: true,
    };
  },

  [UPDATE_EMAIL_TEMPLATE_SUCCESS](state) {
    return {
      ...state,
      updatingTemplate: false,
    };
  },

  [UPDATE_EMAIL_TEMPLATE_FAIL](state) {
    return {
      ...state,
      updatingTemplate: false,
    };
  },

  [DELETE_EMAIL_TEMPLATE_START](state) {
    return {
      ...state,
      deletingTemplate: true,
    };
  },
  [DELETE_EMAIL_TEMPLATE_SUCCESS](state, action) {
    const { templateId } = action.data;
    const templateList = [...state.templates];
    const targetIndex = templateList.findIndex(
      (template) => template.id === templateId,
    );
    targetIndex !== 1 && templateList.splice(targetIndex, 1);
    return {
      ...state,
      templates: templateList,
      selectedTemplate: null,
      deletingTemplate: false,
    };
  },
  [DELETE_EMAIL_TEMPLATE_FAIL](state) {
    return {
      ...state,
      deletingTemplate: false,
    };
  },
  [CLEAR_EMAILING_LIST_STATE]() {
    return initialState;
  },
});
