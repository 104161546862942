import React from 'react';
import { Header, StyledPracticeCardGrid } from './DashboardStyledComponent';
import PracticeCard from './PracticeCard';

const backGround = ['#70367C', '#95CFD5', '#FD7E50'];
const btnBackGround = ['#370046', '#2B9FAB', '#FB0000'];

const Practice = ({ practiceData }) => {
  return (
    <main style={{ margin: '10px 0' }}>
      <Header style={{ marginBottom: '5px' }}>Continue Practice</Header>
      <StyledPracticeCardGrid container style={{ overflowWrap: 'normal' }}>
        {practiceData.map((item, index) => {
          const level = item?.user_tag_level || 1;
          const subTopic = item?.number_of_subtags || 0;
          const totalTagQuestions = item?.total_tag_questions || 0;
          const totalSolvedQuestions = item?.total_question_solved || 0;
          const progress = (
            (totalSolvedQuestions / totalTagQuestions) *
            100
          ).toFixed(0);

          let unlockedUntilDate = item?.unlocked_until
            ? new Date(item.unlocked_until)
            : new Date();

          const currentDate = new Date();
          const daysLeft =
            Math.ceil(
              (unlockedUntilDate - currentDate) / (1000 * 60 * 60 * 24),
            ) || 0;

          return (
            <PracticeCard
              key={index}
              backGround={backGround[index % backGround.length]}
              btnBackGround={btnBackGround[index % btnBackGround.length]}
              level={level}
              title={item.name}
              daysLeft={daysLeft}
              subTopic={subTopic}
              progress={progress}
              unlockedUntil={daysLeft > 0}
              tagId={item?.tag_id}
              item={item}
            />
          );
        })}
      </StyledPracticeCardGrid>
    </main>
  );
};

export default Practice;
