import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSalesDashboard } from '../SalesStyledComponent';
import {
  postSalesLeadBulk,
  putSalesLead,
} from '../../../actions/salesDashboard';
import {
  salesStatusOptions,
  salesLeadTypeOptions,
} from '../../../constants/salesDashbord';
import { useSearchParams } from 'react-router-dom';
import {
  sendCompanyAutocompleteQuery,
  sendJobRoleAutocompleteQuery,
} from '../../../actions/user';

const AddSalesLead = ({
  open,
  handleClose,
  salesLeadId,
  selectedLead,
  filters,
  page,
}) => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const currentSalesUserId = searchParams.get('salesUserId');
  const { data } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm();

  React.useEffect(() => {
    if (Object.keys(selectedLead).length > 0) {
      reset({
        name: selectedLead.name,
        email: selectedLead.email,
        phone_number: selectedLead.phone_number,
        linkedin_url: selectedLead.linkedin_url,
        comment: selectedLead.comment,
        company: {
          company_name: selectedLead.company_name,
          id: selectedLead.company_id,
        },
        job_role_details:
          !selectedLead?.job_role_id && !selectedLead?.job_role_name
            ? undefined
            : {
                id: selectedLead?.job_role_id,
                name: selectedLead?.job_role_name,
              },
      });
    }
  }, [selectedLead]);

  React.useEffect(() => {
    if (Object.keys(selectedLead).length === 0) {
      reset({
        name: '',
        email: '',
        phone_number: '',
        linkedin_url: '',
        comment: '',
      });
    }
  }, [selectedLead]);

  const onDetailsSubmit = (body) => {
    const finalDataBody = {
      ...body,
      sales_user_id: currentSalesUserId ? currentSalesUserId : data.id,
      company: undefined,
      job_role_details: undefined,
      company_id: ['Company', 'ConsultingCompany'].includes(
        body?.sales_lead_type,
      )
        ? body?.company?.id
        : undefined,
      company_name: !body?.company?.id ? body?.company?.value : undefined,
      job_role_id: body?.job_role_details?.id,
      job_role_name: !body?.job_role_details?.id
        ? body?.job_role_details?.value
        : undefined,
    };
    finalDataBody.phone_number = body.phone_number?.replace(/\s+/g, '');

    if (!salesLeadId) {
      dispatch(
        postSalesLeadBulk([finalDataBody], currentSalesUserId, filters, page),
      );
    } else {
      delete finalDataBody.sales_user_id;
      dispatch(
        putSalesLead(
          salesLeadId,
          finalDataBody,
          '',
          currentSalesUserId,
          filters,
          page,
        ),
      );
    }
    handleClose();
  };

  return (
    <Dialog className="dialog" onClose={handleClose} open={open} fullWidth>
      <StyleSalesDashboard>
        <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          {salesLeadId ? (
            <Typography variant="h6" className={'dialog-title'} color="primary">
              Edit Sales Lead
            </Typography>
          ) : (
            <Typography variant="h6" className={'dialog-title'} color="primary">
              Add New Sales Lead
            </Typography>
          )}
        </Grid>
        <DialogContent className="dialog-content">
          <Box>
            <Grid>
              <Select
                {...register('sales_lead_type', {
                  required: 'Please select a sales lead type',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                displayEmpty
                placeholder="Sales Lead Type"
                error={!!errors.sales_lead_type}
                defaultValue={
                  salesLeadId
                    ? selectedLead.sales_lead_type || 'Company'
                    : 'Company'
                }
                sx={{
                  height: '40px',
                }}
              >
                {salesLeadTypeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.key}>
                    {option.value?.split(/(?=[A-Z])/)?.join(' ')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {['Company', 'ConsultingCompany'].includes(
              watch('sales_lead_type'),
            ) && (
              <Grid sx={{ mt: 1, mb: 1 }}>
                <Controller
                  control={control}
                  defaultValue={null}
                  name="company"
                  rules={{
                    required: 'Please enter your company name',
                  }}
                  render={({ field }) => (
                    <div>
                      <AsyncCreatableSelect
                        {...field}
                        isClearable
                        cacheOptions
                        noOptionsMessage={() =>
                          'Search Company to show options...'
                        }
                        placeholder={
                          <Typography color="#80808096">Company</Typography>
                        }
                        loadOptions={(query, callback) => {
                          dispatch(
                            sendCompanyAutocompleteQuery(query, (data) =>
                              callback(data),
                            ),
                          );
                        }}
                        getOptionLabel={(option) =>
                          option.company_name
                            ? option.company_name
                            : `Create "${option.value}"`
                        }
                        getOptionValue={(option) =>
                          option.id ? option.id : option.value
                        }
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? '#234F7D'
                              : 'transparent',
                            color: state.isFocused ? 'white' : 'black',
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: state.isFocused ? 'white' : 'black',
                          }),
                        }}
                      />
                      <FormHelperText error={!!errors.company}>
                        {errors.company?.message}
                      </FormHelperText>
                    </div>
                  )}
                />
              </Grid>
            )}
            <Grid>
              <Controller
                {...register('job_role_details', {
                  required: false,
                })}
                control={control}
                render={({ field }) => (
                  <AsyncCreatableSelect
                    {...field}
                    isClearable
                    cacheOptions
                    noOptionsMessage={() =>
                      'Enter some characters to show options...'
                    }
                    placeholder={
                      <Typography color="#80808096">Job Role</Typography>
                    }
                    loadOptions={(query, callback) => {
                      dispatch(
                        sendJobRoleAutocompleteQuery(query, (data) =>
                          callback(data),
                        ),
                      );
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : `Create "${option.value}"`
                    }
                    getOptionValue={(option) =>
                      option.id ? option.id : option.value
                    }
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? '#234F7D'
                          : 'transparent',
                        color: state.isFocused ? 'white' : 'black',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: state.isFocused ? 'white' : 'black',
                      }),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid>
              <TextField
                {...register('name', {
                  required: 'Please enter your name',
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
            <Grid>
              <TextField
                {...register('email', {
                  required: false,
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Email"
                error={!!errors.email}
                helperText={errors.email?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
            <Grid>
              <TextField
                {...register('phone_number', {
                  required: false,
                  maxLength: {
                    value: 20,
                    message: 'Phone number is invalid',
                  },
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Phone Number"
                error={!!errors.phone_number}
                helperText={errors.phone_number?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
            <Grid>
              <TextField
                {...register('linkedin_url', {
                  required: false,
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="LinkedIn Url"
                error={!!errors.linkedin_url}
                helperText={errors.linkedin_url?.message}
                InputProps={{
                  style: {
                    height: '40px',
                  },
                }}
              />
            </Grid>
            <Grid>
              <Select
                {...register('sales_status', {
                  required: 'Please select a status',
                })}
                fullWidth
                style={{ marginTop: '8px', marginBottom: '4px' }}
                variant="outlined"
                margin="dense"
                displayEmpty
                placeholder="Approval Status"
                error={!!errors.sales_status}
                defaultValue={
                  salesLeadId
                    ? selectedLead.sales_status || 'NoActionTaken'
                    : 'NoActionTaken'
                }
                sx={{
                  height: '40px',
                }}
              >
                {salesStatusOptions.map((option) => (
                  <MenuItem value={option.value} key={option.key}>
                    {option.value?.split(/(?=[A-Z])/)?.join(' ')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid>
              <TextField
                {...register('comment', {
                  required: false,
                })}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder="Comment"
                error={!!errors.comment}
                helperText={errors.comment?.message}
                multiline
                rows={4}
              />
            </Grid>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ marginTop: '20px' }}
          >
            <Button variant="outlined" type="button" onClick={handleClose}>
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={handleSubmit(onDetailsSubmit)}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </StyleSalesDashboard>
    </Dialog>
  );
};

export default AddSalesLead;
