const AllReasons = {
  Duplicate: {
    title: 'The Question is duplicated',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
  },
  Plagiarised: {
    title: 'Plagiarism Found',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Matching Source URL',
  },
  OptionIncorrect: {
    title: 'Different Option(s) is Correct',
    original_ans_bg_color: '#FFA5001A',
    color: 'black',
    suggested_title: 'Suggested Correct Option(s)',
  },
  AnswerIncorrect: {
    title: 'The Answer is Wrong',
    original_ans_bg_color: '#FFA5001A',
    color: 'black',
    suggested_title: 'Suggested Answer',
    original_title: "Author's Answer",
  },
  UnclearContent: {
    title: 'Content is not clear',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Text',
  },
  CategoryIncorrect: {
    title: 'Question Category Suggestion',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Category',
    original_title: 'Current Category',
  },
  SubcategoryIncorrect: {
    title: 'Question Subcategory Suggestion',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Subcategories',
    original_title: 'Current Subcategories',
  },
  QuestionTextImprovement: {
    title: 'Grammatical/Formatting Issues in the Question Text',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Question Text',
  },
  SolutionTextImprovement: {
    title: 'Grammatical/Formatting Issues in the Solution Text',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Solution Text',
    original_title: "Author's Solution Text",
  },
  NoOptionCorrect: {
    title: 'No Option is Correct',
    original_ans_bg_color: '#FFA5001A',
    color: 'black',
  },
  Timeout: {
    title: 'Time Limit Suggestion',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Time Limit',
    original_title: "Author's Time Limit",
  },
  OptionTextIncorrect: {
    title: 'The Option Text is Incorrect',
    original_ans_bg_color: '#388E3C1A',
    color: 'black',
    suggested_title: 'Suggested Text',
  },
  Other: {
    title: 'Other',
    original_ans_bg_color: '#FFA5001A',
    color: 'black',
  },
};

export default AllReasons;
