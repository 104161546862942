import { Grid, Button } from '@mui/material';
import * as React from 'react';
import RichTextEditor from './../RichTextEditor';
import { StyledChoiceEdit, StyledOptionButton } from './StyledComponent';

export default function ChoiceEdit({
  value = '',
  onChange,
  metaData,
  forEdit,
  handleCancel,
}) {
  const [text, setText] = React.useState(value);
  const [editing, setEditing] = React.useState(false);

  const onChangeText = ({ html }) => {
    if (html === '<p><br></p>') {
      setText('');
    } else {
      setText(html);
    }
  };

  const handleAddOption = () => {
    setEditing(true);
  };

  const handleSubmit = () => {
    onChange(text);
    setText('');
    setEditing(false);
  };

  const handleEditorCancel = () => {
    setText('');
    setEditing(false);
    if (handleCancel) {
      handleCancel();
    }
  };

  React.useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <StyledChoiceEdit>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {editing || forEdit ? (
            <RichTextEditor
              onChange={onChangeText}
              value={text}
              metaData={metaData}
              placeholder="Type your option"
              onBoxFocus={true}
            />
          ) : null}
        </Grid>
        <Grid item mb={1}>
          {!forEdit && !editing ? (
            <StyledOptionButton onClick={handleAddOption}>
              + Add Option
            </StyledOptionButton>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                disabled={!text}
              >
                Save Option
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleEditorCancel}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </StyledChoiceEdit>
  );
}
