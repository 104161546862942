import React, { useEffect, useState, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Notifications from './../Notification';
import ProfileDropdown from './ProfileDropdown';
import CreditIcon from '../Sidebar/img/credits-icon.svg';
import ComputerScience from '../../images/BlueComputerScience.svg';
import GK from '../../images/BlueGeneralKnowledge.svg';
import Civil from '../../images/BlueCivilEngineering.svg';
import Electrical from '../../images/BlueElectricalEngineering.svg';
import Electronics from '../../images/BlueElectronicsAndCommunication.svg';
import Mathematics from '../../images/BlueEngineeringMathematics.svg';
import Mechanical from '../../images/BlueMechanicalEngineering.svg';
import Gems from '../../images/Gemsnew.svg';
import profileDropdown from '../../images/profileDropdown.svg';
import {
  AppbarWrapper,
  AppbarContent,
  AppbarNotification,
  ContentPara,
  ContentDescription,
  AppbarContentData,
  StyledTooltip,
  ContentHeading,
  AnimatedHeading,
  AnimatedIcon,
  MenuButton,
} from './StyledComponents';
import { useSelector } from 'react-redux';
import { IconButton, useMediaQuery } from '@mui/material';
import config from '../../config';
import { ExitToApp } from '@mui/icons-material';

const Appbar = (props) => {
  const { analytics, credits, data, selected_domain, gems } = useSelector(
    (state) => state.user,
  );
  const { list = [] } = useSelector((state) => state.domain);
  const selectedDomain = list.find((domain) => domain.id === selected_domain);

  const creditTitle = (
    <div style={{ padding: '5px' }}>
      <Typography cvariant="caption">
        {' '}
        <strong>What are Abekus Credits?</strong>
      </Typography>
      <Typography variant="caption">
        Credits are the virtual currency of Abekus. Abekus provides multiple
        ways through which you can earn credits easily.
        <Link
          href={`${config.WEBSITE_URL}/help-center/credits.html`}
          target="_blank"
          style={{ marginLeft: 7, marginTop: 2 }}
        >
          Learn more
        </Link>
      </Typography>
    </div>
  );

  const [creditsAnimationStart, setCreditsAnimationStart] = useState(false);
  const [gemsAnimationStart, setGemsAnimationStart] = useState(false);
  const screenLoaded = useRef(false);

  useEffect(() => {
    if (screenLoaded.current) {
      setGemsAnimationStart(true);
    }
  }, [gems]);
  useEffect(() => {
    if (!screenLoaded.current) {
      screenLoaded.current = true;
    } else {
      setCreditsAnimationStart(true);
    }
  }, [credits]);

  useEffect(() => {
    if (creditsAnimationStart) {
      setTimeout(() => {
        setCreditsAnimationStart(false);
      }, 3000);
    }
    if (gemsAnimationStart) {
      setTimeout(() => {
        setGemsAnimationStart(false);
      }, 3000);
    }
  }, [creditsAnimationStart, gemsAnimationStart]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isAdminMode = JSON.parse(localStorage.getItem('isAdminMode'));
  const handleExitAdminMode = () => {
    localStorage.removeItem('isAdminMode');
    localStorage.removeItem('secondaryToken');
    window.location.replace('/l/user-management');
  };
  return (
    <AppbarWrapper open={props.open} position="fixed">
      {!isMobile && (
        <Toolbar>
          <MenuButton
            color="success"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            size="large"
          >
            <MenuIcon color="primary" />
          </MenuButton>
        </Toolbar>
      )}

      <AppbarContent>
        {isAdminMode && (
          <IconButton
            onClick={handleExitAdminMode}
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <ExitToApp color="primary" />
          </IconButton>
        )}
        <AppbarContentData>
          {(() => {
            switch (selectedDomain?.name) {
              case 'Electronics and Communication':
                return <img src={Electronics} width="18px" />;
              case 'Mechanical Engineering':
                return <img src={Mechanical} width="18px" />;
              case 'Civil Engineering':
                return <img src={Civil} width="18px" />;
              case 'Electrical Engineering':
                return <img src={Electrical} width="18px" />;
              case 'Aptitude and GK':
                return <img src={GK} width="18px" />;
              case 'Engineering Mathematics':
                return <img src={Mathematics} width="18px" />;
              default:
                return <img src={ComputerScience} width="18px" />;
            }
          })()}
          <ContentDescription>
            <div>
              <ContentHeading>
                {analytics?.domains?.[selected_domain]
                  ? Math.round(analytics.domains[selected_domain].score)
                  : 0}
              </ContentHeading>
              <ContentPara>Score</ContentPara>
            </div>
          </ContentDescription>
        </AppbarContentData>
        <AppbarContentData>
          <AnimatedIcon
            animationStart={creditsAnimationStart}
            src={CreditIcon}
            width="18px"
          />
          <StyledTooltip
            interactive={'true'}
            title={creditTitle}
            placement="bottom-end"
          >
            <ContentDescription>
              <div>
                <AnimatedHeading animationStart={creditsAnimationStart}>
                  {credits ? credits : 0}
                </AnimatedHeading>
                <ContentPara>Credits</ContentPara>
              </div>
            </ContentDescription>
          </StyledTooltip>
        </AppbarContentData>

        <AppbarContentData>
          <AnimatedIcon
            src={Gems}
            width="18px"
            animationStart={gemsAnimationStart}
          />
          <ContentDescription>
            <div>
              <AnimatedHeading animationStart={gemsAnimationStart}>
                {gems ? gems : 0}
              </AnimatedHeading>
              <ContentPara>Gems</ContentPara>
            </div>
          </ContentDescription>
        </AppbarContentData>
        <AppbarContentData>
          <AppbarNotification>
            <Notifications {...props} />
          </AppbarNotification>
        </AppbarContentData>
        <AppbarContentData>
          <ProfileDropdown
            image={data?.picture ? data?.picture : profileDropdown}
            {...props}
          />
        </AppbarContentData>
      </AppbarContent>
    </AppbarWrapper>
  );
};

export default Appbar;
