import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Icon,
  Typography,
  Grid,
  Dialog,
  LinearProgress,
} from '@mui/material';

import WrongIcon from '@mui/icons-material/Close';
import TimeoutIcon from './images/time-out.svg';
import Illustration from './images/illustration.svg';
import SegmentedProgressBar from '../PlayProgress/SegmentedProgressBar';
import CreditsIcon from '../../images/credits-icon.svg';
import InfoIcon from './images/infoIcon.svg';
import { AntSwitch } from '../Category';

const streakSaveTime = 10; // in seconds

const PlayAnswerStatusModal = ({
  type,
  open,
  onClose,
  message,
  result,
  onYes,
  onSaveStreak,
  prevStreakCount,
  prevStreakTarget,
  onNext,
  autoReveal,
  setAutoReveal,
}) => {
  const streakPossible = result?.progress_obj?.is_streak_save_possible;
  const [timer, setTimer] = useState(streakSaveTime);

  useEffect(() => {
    let intervalId;
    if (streakPossible && open) {
      intervalId = setInterval(() => {
        setTimer((timer) => {
          if (timer <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return timer - 1;
        });
      }, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [open]);

  if (type === 'success') return null;

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      fullWidth
    >
      <Grid
        p="30px"
        display="flex"
        flexDirection="column"
        alignItems={'center'}
        gap="10px"
        boxShadow={'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'}
        position={'relative'}
        borderRadius={'10px'}
      >
        {streakPossible && (
          <Box
            width="100%"
            position={'absolute'}
            top="0px"
            display={'flex'}
            justifyContent={'center'}
          >
            <LinearProgress
              variant="determinate"
              value={(timer * 100) / streakSaveTime}
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4EB035',
                },
                width: '98%',
              }}
            />
          </Box>
        )}
        <Icon
          sx={{
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            background:
              type === 'error'
                ? 'rgba(214, 5, 5, 0.10)'
                : 'rgba(238, 157, 0, 0.10)',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
          }}
        >
          {type === 'error' ? (
            <WrongIcon
              sx={{ width: '20px', height: '20px', color: '#D60505' }}
            />
          ) : (
            <img src={TimeoutIcon} alt="icon" width={20} height={20} />
          )}
        </Icon>

        <Typography variant="h6" color="black" fontWeight="600">
          {!streakPossible ? message : 'Hurry! Save Your Streak.'}
        </Typography>

        {streakPossible && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={'center'}
            gap="10px"
            mb="10px"
          >
            <Typography color="#212427">
              {`${
                type === 'error' ? 'Incorrect Answer!' : 'Timed Out!'
              } Consume `}
              <b>{result?.progress_obj?.streak_save_cost} Credits</b>&nbsp;to
              Keep Your Streak Alive.
            </Typography>
            <Typography fontSize={'12px'} color="#7B7B7B">
              Note: You can only save your streak once between the targets
            </Typography>
            <SegmentedProgressBar
              strokeColor={'#D60505'}
              value={(prevStreakCount * 100) / prevStreakTarget}
              attempt={prevStreakCount}
              total={prevStreakTarget}
              trailColor={'rgba(123, 123, 123, 0.30)'}
              width="175px"
              isStreak={true}
            />
          </Box>
        )}

        {!streakPossible && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={'center'}
            gap="10px"
          >
            <Typography color="#212427">
              Ready to Reveal the Correct Answer?
            </Typography>
            <img src={Illustration} alt="illustration" width={65} height={65} />
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={!streakPossible || !timer ? onYes : onSaveStreak}
          startIcon={
            streakPossible && timer ? (
              <img
                src={CreditsIcon}
                alt="credits-icon"
                width={20}
                height={20}
              />
            ) : null
          }
        >
          {!streakPossible || !timer ? 'Reveal Answer' : 'Save Streak'}
        </Button>
        <Box display={'flex'} alignItems={'center'}>
          <img src={InfoIcon} alt="" />
          &nbsp;
          <Typography color={'black'} fontWeight="500">
            Auto Reveal Answer
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <AntSwitch
            checked={autoReveal}
            onChange={(e) => setAutoReveal(e.target.checked)}
          />
        </Box>
        <Typography
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={onNext}
        >
          No, Take me to the Next Question
        </Typography>
      </Grid>
    </Dialog>
  );
};

export default PlayAnswerStatusModal;
